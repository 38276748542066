import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalLogo from '../../../assets/images/modal_logo.png';
import Modal from '../Modal';
import { useKeyDown } from '../../../hooks/useKeyDown';
import SecureIcon from '../../../assets/images/Error.png';

import 'animate.css';

const CustomAlertModel = (props) => {
    const { isOpen, onClose, highlightMsg, HeaderMsg,
        subHeaderMsg, image, btn1Exists, btn2Exists, btn1Text, btn2Text,
        btn1TypeAcpt, footerMsg, onAcptBtnClick, subFooter, onCancelBtnClick, isBorderNone, Fullheight, textMessage, isSubComponent, subComponent, defaultCornerRadius = true, isIframe = false } = props

    const { t } = useTranslation(); // Hook to access translation functions
    const HeadingStyles = "font-serif-pro modal-title sm:text-[18px] md:text-[32px] text-[25px] sm:leading-[24px] md:leading-[38px] leading-[31px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title sm:text-[18px] md:text-[32px] text-[20px] sm:leading-[24px] md:leading-[38px] leading-[31px] font-normal";


    const modalRef = useRef(null);
    const btn1Ref = useRef(null);
    const btn1AccRef = useRef(null);
    const btn2Ref = useRef(null);
    const [currentFocus, setCurrentFocus] = useState('');

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.classList.contains('modal')) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen, onClose]);

    // useKeyDown(() => {
    //         if (props?.btn1Focus) {
    // console.log('1')
    //             // onAcptBtnClick()
    //         } else if (props?.btn2Focus) {
    //             console.log('2')
    //             // onCancelBtnClick();
    //         } else {
    //             console.log('3')
    //             // onAcptBtnClick()
    //         }
    // }, ["Enter"]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            setTimeout(() => {
                if (currentFocus != 'btn2Ref' && currentFocus != 'btn1AccRef' && currentFocus != 'btn1Ref' && event.key == 'Enter') {
                    if (props?.btn1Focus) {
                        onAcptBtnClick()
                    } else if (props?.btn2Focus) {
                        onCancelBtnClick();
                    } else {
                        onAcptBtnClick()
                    }
                } else if (event.key == 'Enter') {
                    onAcptBtnClick()
                }
            }, 500);

        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });



    useEffect(() => {
        if (isOpen && modalRef.current) {
            modalRef.current.classList.add('animate__animated', 'animate__fadeInUpBig');
        } else if (modalRef.current) {
            modalRef.current.classList.remove('fade-in');
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }



    const handleClose = () => {
        modalRef.current.classList.remove('animate__fadeInUpBig');
        modalRef.current.classList.add('animate__fadeOutDown');
        setTimeout(() => {
            onClose();
        }, 500); // Adjust the timeout duration as needed
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center overflow-y-auto " style={{ zIndex: props.zIndex ? props.zIndex : 100 }}>

            <div className=' absolute top-0 left-0 flex w-full items-center justify-center'>
                <div className="modal-overlay fixed inset-0 bg-[#262B3A] opacity-90 transition-opacity" />

                <div
                    ref={modalRef} style={{ overflowY: 'auto' }}
                    className={`modal-container ${props?.modalBg ? props?.modalBg : 'bg-card'} absolute sm:top-32 top-0 ${defaultCornerRadius ? 'sm:rounded-tl-card-tr sm:rounded-br-card-bl' : 'sm:rounded-tr-[4rem] sm:rounded-bl-[4rem]'}  md:w-[778px] sm:w-[466px] w-[100%] mx-auto shadow-lg z-50 transition-all duration-300 ease-in-out h-[100vh] sm:h-fit`
                    }
                >


                    <div className="modal-content text-left">
                        <div className={`modal-body  h-[100%] ${isIframe ? 'flex justify-center' : 'flex sm:justify-center items-center'} flex-col sm:mt-[60px] md:mt-[110px] mt-[68px] mb-[30px]`}>
                            <div className={` ${isIframe ? 'w-[100%]' : 'md:w-[450px] sm:w-[294px] xsm:w-[240px] w-[240px]'} `}>
                                <div className='w-full flex flex-col items-center self-center '>
                                    <span
                                        className='absolute md:top-[60px] sm:top-[44px] top-[30px] right-[30px] sm:right-[50px]'
                                        tabIndex={0}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                onClose();
                                            }
                                        }}
                                        onClick={(event) => {
                                            event.target.style.opacity = 0.8;
                                            onClose(); setTimeout(() => { event.target.style.opacity = 1; },
                                                200);
                                        }}>
                                        <button className="modal-close text-gray-500 hover:text-gray-800 
                                cursor-pointer" >
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                className="h-[1rem] w-[1rem] opacity-[60%]"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor" >
                                                <path strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                                        </button>
                                    </span>
                                    <div className='md:w-[450px] sm:w-[294px] xsm:w-[240px] w-[240px]'>
                                        {highlightMsg}
                                        {HeaderMsg}
                                    </div>
                                    <div className='md:w-[450px] sm:w-[294px] xsm:w-[240px] w-[240px] flex flex-col justify-start gap-7 mt-5'>
                                        <div className='w-full flex flex-row'>
                                            <div>
                                                {props?.showErrorImg && <img src={SecureIcon} alt='SecureIcon' className='mr-[1.1rem] w-[13px] mt-1' />}
                                            </div> {subHeaderMsg}
                                        </div>
                                        {textMessage &&
                                            <div className='w-full'>

                                                {textMessage && (<div>{textMessage}</div>)}
                                            </div>
                                        }
                                        {isSubComponent &&
                                            <div className='w-full'>
                                                {subComponent}
                                            </div>
                                        }
                                        {image && (
                                            <div className={`w-full flex flex-col items-center ${props?.imageMargin && 'my-10'}`}>
                                                <img
                                                    src={image}
                                                    className={props?.largeImage ? '' : `lg:w-[120px] lg:h-[120px] md:w-[120px] 
                                        md:h-[120px] sm:w-[92px] sm:h-[92px] w-[120px] h-[120px]`}
                                                />
                                            </div>
                                        )}
                                        {subFooter && (
                                            <div className='w-full'>
                                                {subFooter}
                                            </div>
                                        )}

                                    </div>

                                </div>
                            </div>

                            {/* Footer---- */}
                            <div className={`md:w-[450px] sm:w-[294px] xsm:w-[240px] w-[240px] flex flex-col justify-start gap-5`}>
                                <div className='lg:pt-20 md:pt-18 sm:pt-10 pt-16 w-full'>
                                    {(btn1Exists && !btn1TypeAcpt) &&
                                        <button onKeyDown={(event) => { event.target.style.opacity = 0.8; onAcptBtnClick(); setTimeout(() => { event.target.style.opacity = 1; }, 200); event.stopPropagation(); }} onBlur={() => { setCurrentFocus('') }} onFocus={() => { setCurrentFocus('btn1Ref') }} id='btn1Ref' ref={btn1Ref} style={{ borderColor: props?.btn1BorderColor ? props?.btn1BorderColor : '#2965FB', color: props?.btn1TextColor ? props?.btn1TextColor : '#2965FB' }} className={`w-full border-[2.5px] p-3 font-bold focus:border-black focus:border-[#3px]`} onClick={(event) => { event.target.style.opacity = 0.8; onAcptBtnClick(); setTimeout(() => { event.target.style.opacity = 1; }, 200); }}>{btn1Text}</button>
                                    }
                                    {(btn1Exists && btn1TypeAcpt) &&
                                        <button
                                            style={{ backgroundColor: props?.btn1BgColor ? props?.btn1BgColor : '#2965FB', borderRadius: props?.btn1BrExist ? 50 : 0, color: props?.btn1TextColor ? props?.btn1TextColor : '#FFFFFF' }}
                                            className={`lg:text-[16px] md:text-[14px] sm:text-[13px] text-[14px] w-full lg:h-[60px] md:h-[55px] sm:h-[50px] h-[55px] font-hanken-grotesk font-bold leading-[16px] disabled:opacity-30 ${props?.btn1TextColor==='#2965FB' && 'border-[#2965FB] border-[2.5px]'} focus:border-[#2965FB] focus:border-[#3px]`} 
                                            tabIndex={0}
                                            id='btn1AccRef'
                                            onBlur={() => { setCurrentFocus('') }}
                                            onFocus={() => { setCurrentFocus('btn1AccRef') }}
                                            ref={btn1AccRef}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    onAcptBtnClick();
                                                    event.stopPropagation();
                                                }
                                            }}
                                            onClick={(event) => { event.target.style.opacity = 0.8; onAcptBtnClick(); setTimeout(() => { event.target.style.opacity = 1; }, 200); }}>{btn1Text}</button>
                                    }
                                    {btn2Exists &&
                                        <button
                                            tabIndex={0}
                                            id='btn2Ref'
                                            onBlur={() => { setCurrentFocus('') }}
                                            onFocus={() => { setCurrentFocus('btn2Ref') }}
                                            ref={btn2Ref}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    onCancelBtnClick();
                                                    event.stopPropagation();
                                                }
                                            }}
                                            className={` w-full mt-5 lg:h-[60px] md:h-[55px] sm:h-[50px] h-[55px] font-hanken-grotesk font-bold lg:text-[16px] md:text-[14px] sm:text-[13px] text-[14px] leading-[16px] ${isBorderNone ? 'sm:border-0 border-[#2965FB] border-[0px]' : ' border-[#2965FB] border-[2.5px]'} focus:border-[#2965FB] focus:border-[#3px]`}
                                            style={{ color: props?.btn2TextColor ? props?.btn2TextColor : '#2965FB' }}
                                            onClick={(event) => { event.target.style.opacity = 0.8; onCancelBtnClick(); setTimeout(() => { event.target.style.opacity = 1; }, 200); }}>{btn2Text}</button>
                                    }
                                </div>
                                {(footerMsg && footerMsg != '') &&
                                    <div className='w-full mb-10 md:mb-16'>
                                        {footerMsg}
                                    </div>
                                }
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div >
    );
};

export default CustomAlertModel;