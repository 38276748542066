import { withOidcSecure } from '@axa-fr/react-oidc'
import React from 'react'
import RightArrow from "../../../../../../assets/images/arrow.png"
import { useNavigate } from 'react-router'
import { encode, isValid} from 'js-base64';
import { useDispatch, useSelector } from 'react-redux'

const SavingsHabitItem = ({ data, isShow }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { amount, frequencyType, id, percentageValue, habitType } = data
    const encryptedID = encode(id); 
    const handleClick = () => {
        if (isShow) {
           
            const addSavingId = { savingsHABSelectedId: id }
            dispatch({ type: 'ADD_SAVNIGS_HAB_ID', payload: addSavingId });

            setTimeout(() => {
                navigate('/Climb/SavingsHabit/EditHabit')
            }, 1000);
           
            
        }
    }
    return (
        <>
            <div className={`flex flex-row justify-between items-center w-full my-8 cursor-pointer ${!isShow ? 'opacity-[0.5]' : 'opacity-1'
                } ${!isShow ? 'pointer-events-none' : ''}`} onClick={handleClick} tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") handleClick() }}>
                {habitType === "ByDeposit" &&

                    <p className="font-hanken-grotesk font-[700]
                      lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px] 
                       leading-[16px] text-secondary-3 " >{`${percentageValue}% Each Deposit`}</p>
                }
                {habitType === "Amount" &&
                    <p className="font-hanken-grotesk flex items-center font-[700] lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px] leading-[16px] text-secondary-3">
                        {`$${amount}`}
                        <span >/</span>
                        {`${frequencyType}`}
                    </p>
                }
                <img src={RightArrow} alt="arrow" className='mr-[.5rem]' />
            </div>
            <hr className=" h-[2px] bg-[#94C3E8] border-0 rounded opacity-[65%]" />

        </>
    )
}

export default withOidcSecure(SavingsHabitItem)
