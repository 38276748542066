import React, { useState, useEffect } from 'react'
import { width } from '../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import CustomTextInput from '../../../../CustomComponents/CustomtextInput'
import RightArrow from "../../../../../assets/images/RightArrow@2x.png"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../CustomComponents/Loader'
import DynamicCard from '../../../../CustomComponents/DynamicCard'
import { handleAmountInputLimitTo2decimal } from '../../../../../utils'
import { Alert } from '@mui/material' 
import logFirebaseEvents from '../../../../LogFirebaseEvents'

const AmountCard = ({ cardHeader, cardFooter, onBack, onNext }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [amountError, setAmountError] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const amount = useSelector(state => state.spendAlertsPayload.amount);


  const handleBackClick = () => { 
    logFirebaseEvents("SpendSense_Alert", "Back_SpendAlert_Clicked", "Back_SpendAlert_Clicked","", "")
    dispatch({ type: "RESET_ADD_SPEND_ALERTS" })
    navigate('/Climb/ManageSpendAlerts')
  }
  const handleNextClick = () => {
    logFirebaseEvents("SpendSense_Alert", "Next_SpendAlert_Clicked", "Next_SpendAlert_Clicked","", "")
    if (!amount) setAmountError('Amount Required');
    if (amount && !amountError) onNext("timeFrame");
  }

  const handleOnBlur=(e)=>{
    if (!amount) setAmountError('Amount Required');
    else if (amount <= 0){
      setAmountError('Please enter amount greater than 0')
    } 
    else setAmountError('')
  }


  const amountValidation = () => {

    if (!amount) setAmountError('Amount Required');
    else if (amount <= 0){
      setAmountError('Please enter amount greater than 0')
    } 
    else {
      setAmountError(null)
      setIsNext(true);
    }


  }
  useEffect(() => {
    if (amount) amountValidation();

    if (!amount) setIsNext(false);

  }, [amount])

  const children = () => {
    return (
      <>
        {cardHeader}
        <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4'>{t("spending_alert_amount_content1")}
          <span className='text-secondary-3'>{t("spending_alert_amount_content2")}</span>
        </div>

        <div className='w-full flex justify-center'>
          <div className="sm:w-[80%] w-full">
            {/* BODY */}
            <div className='md:my-32 sm:my-16 mt-10 mb-[10rem] sm:mx-0 mx-4'>
              <div className='sm:w-[50%] w-full flex flex-col justify-center'>
                <CustomTextInput name={t("amount")}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      event.preventDefault(); event.target.style.opacity = 0.8;
                      setTimeout(() => { event.target.style.opacity = 1; }, 200);
                    }
                  }}
                  onChange={(event) => {

                    const inputValue = event.target.value;
                    const formattedAmount=handleAmountInputLimitTo2decimal(inputValue)
                    dispatch({
                      type: 'ADD_SPEND_ALERTS',
                      payload: { 'key': "amount", 'value': formattedAmount.toString().replace('$', '') }
                    })
                  }}
                  onBlur={(event)=> handleOnBlur(event)}
                  value={amount ? `$${amount}` : ''}
                  error={amountError}
                  placeholder={"$ 0"}
                  inputWidth={`w-full`}
                  autoFocus={true}
                />
              </div>
            </div>
          </div>

        </div>
        {/* FOOTER */}

        {cardFooter(handleBackClick, handleNextClick,'','', isNext)}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>
      {isLoading && <Loader />}
    </>
  )
}

export default AmountCard