import React, { useEffect, useState } from 'react'
import HomeLayout from '../../Layout/HomeLayout'
import HomeHeader from '../../CustomComponents/HomeHeader'
import DynamicCard from '../../CustomComponents/DynamicComponent/DynamicCard.js'
import { useTranslation } from 'react-i18next'
import Loader from '../../CustomComponents/Loader'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { convertTimestamp, modifyHtmlForNavigation, retrieveData, useNotificationNavigation } from '../../../utils'
import unread from "../../../assets/images/unread.png"
import threeDots from "../../../assets/images/Ellipses Large3_dots.png"
import { GetApiHeadersWithBearer, PostRequest } from '../../../network/NetworkUtils'
import { NOTIFICATION_READ_ALERTS, NOTIFICATION_UNREAD_ALERTS, STATUS_SUCCESS_CODE } from '../../../network/URLConstants'


const NotificationItemCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;

    const [isLoading, setIsLoading] = useState(false);
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [isUnread, setIsUnread] = useState(false);
    const [isReadActive, setIsReadActive] = useState(false);
    const [isUnReadActive, setIsUnReadActive] = useState(false);

    const notificationNavigation = useNotificationNavigation();

    const notifications = useSelector(state => state.notifications)
    const accessToken = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));


    const notificationItem = notifications.filter((item) => item.ID.toString() === id);

    const { Content, CreatedDateTime, AlertTypeCode, Unread, ID } = notificationItem[0];

    const timeStamp = convertTimestamp(CreatedDateTime);
    const modifiedContent = modifyHtmlForNavigation(Content, '#2965FB', isUnread);
    // console.log("modifiedContent", modifiedContent);

    const markAsUnreadFun = async () => {
        try {
            setIsLoading(true);
            const AlertId = [ID]

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ?  cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const body = {
                cardKey: cardKey,
                AlertIds: AlertId,
            };
            const url = NOTIFICATION_UNREAD_ALERTS;
            const response = await PostRequest(url, body, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false);
                // fetchNotifications(true, true);

            }

        } catch (error) {
        }

    }
    const markAsReadFun = async () => {
        try {
            setIsLoading(true);
            const AlertId = [ID]

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ?  cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const body = {
                cardKey: cardKey,
                AlertIds: AlertId,
            };
            const url = NOTIFICATION_READ_ALERTS;
            const response = await PostRequest(url, body, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false);
            }

        } catch (error) {
        }

    }

    useEffect(() => {
        markAsReadFun();
    }, [])

    const handleUnreadClick = () => {
        // setIsUnReadActive(prev => !prev)
        setIsUnread(true)
        markAsUnreadFun()
    }

    const handleReadClick = () => {
        // setIsReadActive(prev => !prev)
        setIsUnread(false)
        markAsReadFun();
    }

    const handleBackClick = () => {
        navigate('/notifications')
    }

    const Headingcom = () => {
        return (
            <div className="relative">
                <div className="flex flex-row justify-between m-10">
                    <p className='font-hanken-grotesk font-[600] text-[16px] leading-[26px] sm:text-[15px] sm:leading-[23px] md:font-[500] md:text-[26px] md:leading-[36px]'>{t("notifications")}</p>
                    <div className="my-auto cursor-pointer">
                        <img src={threeDots} alt="Three Dots" onClick={() => setIsShowDropdown(prev => !prev)} />

                    </div>
                </div>
                {isShowDropdown &&
                    <div className=" absolute right-0 h-[57px] w-[160px] md:w-[185px] z-10 rounded-bl-[1rem] bg-blue-sky ">
                        <div className=" flex flex-col cursor-pointer w-full h-full">
                            {/* UNREAD */}
                            {isUnread &&
                                <div className={` rounded-bl-[1rem] py-5 px-6 ${isReadActive ? 'text-[#2965FB] bg-blue-sky' : 'text-white  bg-dropdown-sky '}`} onClick={handleReadClick}>
                                    <p className='font-hanken-grotesk font-[600] text-[12px] leading-[18px] sm:text-[10px] sm:leading-[15px] md:text-[14px] md:leading-[21px]'>{t("markAsRead")}</p>
                                </div>
                            }

                            {/* READ */}
                            {!isUnread &&
                                <div className={` rounded-bl-[1rem] py-5 px-6 ${isUnReadActive ? 'text-[#2965FB] bg-blue-sky' : 'text-white  bg-dropdown-sky '}`} onClick={handleUnreadClick}>
                                    <p className='font-hanken-grotesk font-[600] text-[12px] leading-[18px] sm:text-[10px] sm:leading-[15px] md:text-[14px] md:leading-[21px]'>{t("markAsUnread")}</p>
                                </div>
                            }

                        </div>
                    </div>

                }
            </div>

        )
    }

    const Footer = () => {
        return (
            <div className=" flex justify-center items-center w-full my-16">
                <div className=" flex justify-start w-[80%]">
                    <p className='font-hanken-grotesk font-[600]
                 text-[12px] leading-[20px] lg:font-[700] 
                 lg:text-[16px] lg:leading-[16px] text-[#2965FB]
                  cursor-pointer' onClick={handleBackClick}>
                        {t("back")}
                    </p>

                </div>
            </div>
        )
    }




    const children = () => {
        return (
            <div className=' flex flex-col my-10 gap-3 justify-center items-center'>
                <div className=' flex flex-row gap-4 w-[80%]'>
                    {isUnread &&
                        <div className='pt-2 w-[10px]'>
                            <img src={unread} className=' h-[10px] w-[10px]' alt="dot" />
                        </div>
                    }

                    <div className=' flex flex-col gap-3 w-[80%]'>
                        {/* <p className={`font-hanken-grotesk font-[500] text-[12px] leading-[18px] lg:text-[18px] lg:leading-[28px] text-[#262B3A] ${!Unread && 'opacity-75'}`}>
                            {Content}
                        </p> */}
                        <p className={`font-hanken-grotesk font-[600] md:text-[18px] md:leading-[27px] sm:text-[12px] sm:leading-[20px] text-[13px] leading-[19px] text-[#262B3A] ${!isUnread && 'opacity-75'}`}>
                            <div
                                dangerouslySetInnerHTML={{ __html: modifiedContent }}
                                onClick={(e) => {
                                    if (e.target.tagName === 'A') {
                                        const href = e.target.getAttribute('href');
                                        console.log("gethref---", href)
                                        notificationNavigation(href, e); // Pass the event object to handleNavigation
                                    }
                                }}
                            />
                        </p>

                        <div className=' flex flex-row gap-2'>
                            {AlertTypeCode === "WRN" &&
                                <div className=' bg-footer rounded-full py-1 px-3'>
                                    <p className='font-hanken-grotesk font-[700] text-[9px] leading-[15px] md:text-[12px] md:leading-[18px] text-white'>
                                        {t("important")}
                                    </p>

                                </div>

                            }
                            <p className='font-hanken-grotesk font-[500] text-[12px] sm:font-[600]  sm:text-[10px] sm:leading-[15px] md:text-[14px] md:leading-[14px]  text-[#262B3A] opacity-50'>
                                {timeStamp}
                            </p>

                        </div>
                    </div>

                </div>

                {/* ///unread */}

            </div>
        )
    }
    return (
        <HomeLayout>
            <HomeHeader heading={t("notification_heading")} isHeaderCaption={true} />
            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>

                <DynamicCard
                    headerContent={Headingcom()}
                    cardHeight='h-[600px]'
                    cardContent={children()}
                    footerContent={Footer()}
                />
            </div>

            {isLoading && <Loader />}


        </HomeLayout >
    )
}

export default withOidcSecure(NotificationItemCard)