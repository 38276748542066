import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../Layout/HomeLayout';
import DynamicHomeCard from '../../../CustomComponents/DynamicHomeCard';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import { GetApiHeadersWithBearer, GetRequest } from '../../../../network/NetworkUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AccountDetailsHeder, copyToClipboard, MoreServices, QuickActions } from '../../../../utils';
import { SAVINGS, GET_DDA_DETAILS, GET_DDA_DOWNLOAD_URL, STATUS_SUCCESS_CODE } from '../../../../network/URLConstants';
import { retrieveData } from '../../../../helpers/utils/Utils';
import { width } from '../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import FooterContent from '../../../CustomComponents/Footer';
import TransferImg from '../../../../assets/images/transfer.png';
import { useNavigate } from 'react-router-dom';

const AccountServicesSavingsCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkingAccountNumber, setCheckingAccountNumber] = useState('*********'); //Account Number
  const [checkingRoutingNumber, setCheckingRoutingNumber] = useState('*********');
  const [savingAccountNumber, setSavingAccountNumber] = useState('*********'); //Account Number
  const [savingRoutingNumber, setSavingRoutingNumber] = useState('*********');
  const cards = useSelector(state => JSON.parse(state.userCards));
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [accountCopied, setAccountCopied] = useState(false);
  const [routingCopied, setRoutingCopied] = useState(false);
  const [loading, setLoading] = useState(false)
  const [CardKey, setCardKey] = useState('')
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch({ type: 'SELECTED_SUB_INDEX', payload: { selectedSubIndex: -1 } });
    cards?.map((item) => {
      const { AccountTypeName, CardKey } = item;
      getAccountNumberDetails(AccountTypeName, CardKey);
      return null; // Return null from the map to avoid returning a new array
    });
  }, []);


  const getAccountNumberDetails = async (cardType, cardkey) => {
    // debugger;
    if (cardkey == undefined || cardkey == null) {
      return
    }
    setCardKey(cardkey)
    setLoading(true)
    let url = GET_DDA_DETAILS + cardkey;
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    var response = await GetRequest(url, headers)
    setTimeout(() => {
      setLoading(false)
    }, 500);
    var responseObj = JSON.parse(response);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      if (responseObj.Response != undefined) {
        if (cardType == SAVINGS) {
          setCheckingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setCheckingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
        } else {
          setSavingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setSavingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
        }

      }
    } else {
      var arrMessages = responseObj.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;
    }
  }

  const transferMoneyClicked = () => {
    global.selectedAccountServiceCardKey = CardKey
    navigate('/AccountServices/Savings/TransferMoney')
  }

  const accountNumberCopiedClicked = () => {
    copyToClipboard(checkingAccountNumber, setAccountCopied(true))
    setAccountCopied(true);
    setTimeout(() => {
      setAccountCopied(false);
    }, 2000);

  }
  const routingNumberCopiedClicked = () => {
    copyToClipboard(checkingRoutingNumber, setRoutingCopied(true))
    setRoutingCopied(true);
    setTimeout(() => {
      setRoutingCopied(false);
    }, 2000);
  }

  ///Quick Actions

  const QuickActionsButtons = [
    { name: "Transfer Money", actionType: "", path: "/AccountServices/Savings/TransferMoney" },

  ]


  ///more services

  const moreServicesArray = [
    { name: 'Edit Account Name', actionType: '', path: '/AccountServices/Savings/EditAccountName' }
  ];

  ////footer
  const footerContent = () => {
    return (
      < FooterContent highlightText={t("savingsCardHeding")}
        subHeaderText={t("savingCardSubheading")}
        image={TransferImg} buttonEnabled={true}
        buttonText={t("savingCardBtn")} 
        footerBtnAction={transferMoneyClicked}
        type={t("savingCardBtn")} 
        isSubheadingwidth={true}
        />)
  }

  return (
    <HomeLayout>
      <HomeHeader heading="Hello, Sam. You’ve spent $405 in the last week." isHeaderCaption={false} />
      <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight  '>

        <DynamicCard
          header={false}
          HeaderContent={false}
          footer={false}
          CardRadius={true}
          HeaderRadius={false}
          isWhite={true}
          cardWidth={width.homeCard}
          backgroundColor={'#FFFFFF'}

        >
          <div className=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
        lg:text-[26px] md:text-[22px] 
        sm:text-[20px] text-[20px]
            leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
            {SAVINGS}
          </div>
          <AccountDetailsHeder
            AccountNo={checkingAccountNumber}
            routingNo={checkingRoutingNumber}
            accountCopiedProps={accountCopied}
            routingCopiedProps={routingCopied}
            handleCopyClick={accountNumberCopiedClicked}
            handleCopyRoutingNumberClick={routingNumberCopiedClicked}
            contentBetween={true}
            topBorder={true}
            bottomBorder={true}
            isCopyblack={true}
          />

          <QuickActions actionsArray={QuickActionsButtons} />

          {/* /////more service */}
          <div className="border-t-[2.5px] border-[#D1E7DD] 
          flex flex-col justify-center lg:mb-16 mb-0 sm:mx-0 mx-4">
            <MoreServices actionsArray={moreServicesArray} />
          </div>


        </DynamicCard>
        {footerContent()}
      </div>
    </HomeLayout>
  )
}
export default AccountServicesSavingsCard;