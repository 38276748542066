import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SidebarLogo from '../../../../assets/images/munuLogo4x.png';
import sidebarClose from '../../../../assets/images/sidebar_close.png';
import { useOidc } from "@axa-fr/react-oidc";
import PersonalInfo from '../../../../assets/images/user.png';
import CardServices from '../../../../assets/images/card_services.png';
import Accounts from '../../../../assets/images/accounts.png';
import Security from '../../../../assets/images/security.png';
import Documents from '../../../../assets/images/documents.png';
import Settings from '../../../../assets/images/Settings.png';
import Helpcircle from '../../../../assets/images/help-circle.png';
import Logout from '../../../../assets/images/log-out.png';
import dot from '../../../../assets/images/Radial Fill.png';
import LogokBlue from "../../../../assets/images/LogoKLIghtBlue.png"
import rootReducer from '../../../../Redux/reducers'; // Import your rootReducer from the Redux reducer file
import { createStore } from 'redux';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GetApiHeadersWithBearer, GetRequest, PostRequest, endSessionGetRequest } from '../../../../network/NetworkUtils';
import { fetchConfigarationData, retrieveData } from '../../../../helpers/utils/Utils';
import { ACCEPT_E_SIGN_AGGREMENT, COVERED_BY_KUDZU, GET_ESIGN_AGREEMENT, GET_ESIGN_STATUS, GET_USER_PORFIE_INFO, SAVINGS_HABIT, SPENDING_ALERT, STATUS_SUCCESS_CODE, VALIDATE_AUTH_USER, END_SESSION } from "../../../../network/URLConstants";
import { convertTimestamp, clearStorageExceptKey, modifyHtmlForNavigation, useNotificationNavigation } from '../../../../utils';
import { Card } from '@mui/material';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import logFirebaseEvents from "../../../LogFirebaseEvents/index"
const appConfigData = await fetchConfigarationData();

const Sidebar = ({ closeSidebar, notifications }) => {
    const { logout } = useOidc();
    const store = createStore(rootReducer);
    const navigate = useNavigate();
    const notificationNavigation = useNotificationNavigation();
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const accessTokenToApi = useSelector(state => state.accessToken);
    const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
    const savingsNewClicked = useSelector(state => state.savingsNewClicked);
    const cards = useSelector(state => JSON.parse(state.userCards));

    //logout call for resetting session
    const handleLogout = async () => {

        // if (!global.isLoggedIn) {
        //     return
        // }
        global.isLoggedIn = false
        global.accessTokenReload = accessTokenToApi
        // logout()
        logoutManually();
    };

    const logoutManually = async () => {
        if (global.accessTokenReload != '' && global.accessTokenReload != undefined) {
            var url = END_SESSION(appConfigData.AUTH_URL, appConfigData.CLIENT_ID, global.accessTokenReload, appConfigData.POST_LOGOUT_REDIRECT_URI);
            window.location.replace(url);
        }

        // console.log("modified url is ",url);
        // var response = await endSessionGetRequest(url, headers);
        // var parsedResponse = JSON.parse(response);
        // console.log("parsed response is ",parsedResponse);
        // if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
        //   return true;
        // } else {
        //     var arrMessages = parsedResponse.Messages;
        //     var message = arrMessages[0];
        //     var displayText = message.DisplayText;
        //     return false;
        // }
    }

    // Function to handle navigation
    const handleNavigate = (path) => {
        navigate(path);
        closeSidebar(); // Optionally, close the sidebar after navigation
    };

    const handleNotificationClick = () => {
        if (!notifications.length) navigate('/notifications')
    }

    useKeyDown(() => {
        closeSidebar();
    }, ["Escape"])


    return (
        <>
            <div className='Sidebar  max-h-screen min-h-screen
            bg-[#28314F] text-white overflow-y-scroll w-full transition-all duration-1000'>
                {/* <div className='Sidebar fixed right-0 top-0 max-h-screen min-h-screen h transition-all duration-1000 justify-end
            md:w-[17rem] sm:w-[15rem] w-full bg-[#28314F] text-white overflow-y-scroll z-50'> */}
                <div className="pb-8 px-8 pt-6">
                    <div className='flex flex-row justify-between items-center'>
                        <img src={SidebarLogo} className='lg:w-[80px] lg:h-[20px] md:w-[80px] md:h-[20px] sm:w-[54px] sm:h-[14px] w-[67px] h-[18px]' />
                        {/* <img src={SidebarLogo} className='lg:w-[80px] lg:h-[45px] md:w-[66px] md:h-[40px] sm:w-[60px] sm:h-[30px]' /> */}
                        <img src={sidebarClose} onClick={closeSidebar}
                            className=' lg:w-[48px] lg:h-[48px] md:w-[43px] md:h-[43px] sm:w-[38px] sm:h-[38px] cursor-pointer' />

                    </div>

                    <div className='sm:hidden block'>
                        <div className='lg:pt-[4.5rem] md:pt-[3.5rem] sm:pt-[2.5rem]
                     pt-[2rem] flex justify-between w-full'>
                            <span className='font-[600] md:text-[15px] sm:text-[13px]
                             text-[16px] leading-[23px]'>{t('Menu')}</span>
                        </div>

                        <ul className='pt-8 border-[#314169] border-b-2'>
                            <li className='flex pb-10'>
                                <Link to="/Spending" onClick={closeSidebar}>

                                    <span className='font-[700]  md:text-[15px] sm:text-[13px] 
                                text-[16px] leading-[16px] font-hanken-grotesk sm:ml-2 ml-6'>
                                        {t('NavbarChecking')}</span>
                                </Link>
                            </li>
                            <li className='flex pb-10'>
                                <Link to="/Savings" onClick={closeSidebar}>

                                    <span className='font-[700] md:text-[15px] sm:text-[13px]
                                     text-[16px] leading-[16px] font-hanken-grotesk sm:ml-2 ml-6'>
                                        {t('NavbarSavings')}</span>
                                </Link>
                            </li>

                            <li className='flex pb-10'>
                                <Link to="/PayPerks" onClick={closeSidebar}>

                                    <span className='font-[700]  md:text-[15px] sm:text-[13px]
                                     text-[16px] leading-[16px] font-hanken-grotesk sm:ml-2 ml-6'>
                                        {t('NavbarPerks')}</span>
                                </Link>
                            </li>

                            <li className='flex pb-10'>
                                <Link to="/Climb" onClick={closeSidebar}>

                                    <span className='font-[700]  md:text-[15px] sm:text-[13px]
                                     text-[16px] leading-[16px] font-hanken-grotesk sm:ml-2 ml-6'>{t('NavbarClimb')}</span>
                                </Link>
                            </li>
                            <li className='flex pb-10'>
                                <Link to='/Help/FAQ' onClick={closeSidebar}>

                                    <span className='font-[700]  md:text-[15px] sm:text-[13px]
                                     text-[16px] leading-[16px] font-hanken-grotesk sm:ml-2 ml-6'>{t('helpText')}</span>
                                </Link>
                            </li>
                        </ul>
                    </div>


                    <div className=' md:pt-[3rem] pl-1 sm:pt-[2.5rem] pt-[2rem] flex justify-between w-full'>
                        <span className={`font-[600] md:text-[15px] sm:text-[13px] text-[16px] leading-[23px] ${!notifications.length && 'cursor-pointer'}`} onClick={handleNotificationClick}>{t('Notifications')}</span>
                        {(notifications.length > 0) &&
                            <span className='sm:text-[12px] text-[13px] text-[#94C3E8] font-[600] leading-[20px] font-hanken-grotesk cursor-pointer' onClick={() => navigate('/notifications')}>
                                {t('viewAll')}
                            </span>
                        }
                    </div>
                    {(notifications.length > 0) &&
                        notifications.map((notification) => {
                            return (
                                <ul className='pt-6 pl-1 md:pr-[27px] pr-[15px] border-b-2 border-[#314169] pb-4'>
                                {/* <ul className='pt-6 md:px-[27px] px-[15px] border-b-2 border-[#314169] pb-4'> */}
                                    <li className=" flex flex-row sm:gap-5 gap-6 font-[500] md:text-[14px] text-[12px] leading-[21px] font-hanken-grotesk">
                                        {/* <li className="marker:text-[#2965FB] marker:text-[20px] font-[500] md:text-[14px] text-[12px] leading-[21px] list-disc font-hanken-grotesk"> */}
                                        <img src={dot} alt='dot' className=' w-[8px] h-[8px] mt-1' />
                                        <div>


                                            <div
                                                dangerouslySetInnerHTML={{ __html: modifyHtmlForNavigation(notification.Content, '#78b3f9') }}
                                                onClick={(e) => {
                                                    if (e.target.tagName === 'A') {
                                                        const href = e.target.getAttribute('href');
                                                        notificationNavigation(href, e); // Pass the event object to notificationNavigation
                                                    }
                                                }}
                                            />
                                            <div className=' flex flex-row gap-2 mt-2 justify-start items-center'>
                                                {notification.AlertTypeCode === "WRN" &&
                                                    <div className=' bg-footer rounded-full px-2 py-2 '>
                                                        <p className='font-hanken-grotesk font-[700] text-[10px] leading-[15px] lg:text-[12px] lg:leading-[18px] text-white'>
                                                            {t("important")}
                                                        </p>

                                                    </div>

                                                }
                                                <p className='text-[#94C3E8] md:text-[12px] 
                                            sm:text-[10px] text-[11px] font-[600] font-hanken-grotesk '>{convertTimestamp(notification.CreatedDateTime)}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            )
                        })
                    }

                    {(notifications.length === 0) &&
                        <div className=" w-full flex flex-row justify-start gap-4 mt-6 border-b-2 border-[#314169] pb-8">
                            <div>
                                <img src={LogokBlue} alt="LogokGrey" className=' opacity-50 pt-1' />
                            </div>
                            <div className="flex flex-col justify-start ">
                                <p className='font-hanken-grotesk font-[500] text-[13px] leading-[19px] sm:text-[12px] sm:leading-[18px] text-[#94C3E8] opacity-50'>{t("no_notification_status1")}</p>
                                <p className='font-hanken-grotesk font-[500] text-[13px] leading-[19px] sm:text-[12px] sm:leading-[18px] text-[#94C3E8] opacity-50'>{t("no_notification_status2")}</p>
                            </div>
                        </div>
                    }

                    <ul className='pt-8 border-[#314169]  border-b-2'>
                        <li className='flex pb-10'>
                            <Link to="/PersonalInformation" onClick={()=>{closeSidebar(); logFirebaseEvents("Menu", "Personal_Info_Clicked", "Personal_Info_Clicked", "","");}}>
                                <img src={PersonalInfo} alt='PersonalInfo' className='md:h-[17px] md:w-[17px] sm:w-[16px] sm:h-[16px] w-[18px] h-[18px] inline-flex' />
                                <span className='font-[700]  md:text-[15px] sm:text-[13px] text-[16px] leading-[16px] font-hanken-grotesk sm:ml-5 ml-6'>{t('Personal_Information')}</span>
                            </Link>
                        </li>

                        <li className='flex pb-10'>
                            <Link to="/CardServices" onClick={()=>{closeSidebar(); logFirebaseEvents("Menu", "Card_Services_Clicked", "Card_Services_Clicked", "","");}}>
                                <img src={CardServices} alt='cardservices' className='w-[17px] h-[17px]  inline-flex' />
                                <span className='font-[700] md:text-[15px] sm:text-[13px] text-[16px] leading-[16px] font-hanken-grotesk sm:ml-5 ml-6'>{t('Card_Services')}</span>
                            </Link>
                        </li>

                        {/* <li className='flex pb-10'>
                            <Link to="/AccountServices" onClick={closeSidebar}>
                                <img src={Accounts} alt='accounts' className='md:w-[17px] w-[15px] md:h-[17px] h-[15px] inline-flex' />     
                                <span className='font-[700] md:text-[15px] sm:text-[13px] text-[16px] leading-[16px] font-hanken-grotesk sm:ml-2 ml-6'>{t('Account_Services')}</span> <div className="w-1 h-1 bg-blue-100 rounded-full mr-2"></div>
                            </Link>
                        </li> */}
                        <li className='flex items-center pb-10'>
                            <Link to="/AccountServices" onClick={()=>{closeSidebar(); logFirebaseEvents("Menu", "Account_Services_Clicked", "Account_Services_Clicked", "","");}} className='flex items-center'>
                                <img src={Accounts} alt='accounts' className='md:w-[17px] w-[15px] md:h-[17px] h-[15px] inline-flex' />
                                <span className='font-[700] md:text-[15px] sm:text-[13px] text-[16px] leading-[16px] font-hanken-grotesk sm:ml-5 ml-6'>
                                    {t('Account_Services')}
                                </span>
                                {(savingsCardAvailable == false && savingsNewClicked == false) && <div className="w-1 h-1 bg-blue-100 rounded-full ml-2"></div>}
                            </Link>
                        </li>

                        <li className='flex pb-10'>
                            <Link to="/Security" onClick={()=>{closeSidebar(); logFirebaseEvents("Menu", "Security_Clicked", "Security_Clicked", "","");}}>
                                <img src={Security} alt='security' className='md:w-[17px] w-[15px] md:h-[17px] h-[15px] inline-flex' />
                                <span className='font-[700]  md:text-[15px] sm:text-[13px] text-[16px] leading-[16px] font-hanken-grotesk sm:ml-5 ml-6'>{t('Security')}</span>
                            </Link>
                        </li>

                        <li className='flex pb-10'>
                            <Link to="/Documents" onClick={()=>{closeSidebar(); logFirebaseEvents("Menu", "Documents_Clicked", "Documents_Clicked", "","");}}>
                                <img src={Documents} alt='documents' className='md:w-[17px] w-[15px] md:h-[17px] h-[15px] inline-flex' />
                                <span className='font-[700]  md:text-[15px] sm:text-[13px] text-[16px] leading-[16px] font-hanken-grotesk sm:ml-5 ml-6'>{t('Documents')}</span>
                            </Link>
                        </li>

                        <li className='flex pb-10'>
                            <Link to="/Settings" onClick={()=>{closeSidebar(); logFirebaseEvents("Menu", "Settings_Clicked", "Settings_Clicked", "","");}}>
                                <img src={Settings} alt='settings' className='md:w-[17px] w-[15px] md:h-[17px] h-[15px] inline-flex' />
                                <span className='font-[700]  md:text-[15px] sm:text-[13px] text-[16px] leading-[16px] font-hanken-grotesk sm:ml-5 ml-6'>{t('Settings')}</span>
                            </Link>
                        </li>

                        <li className='flex pb-10'>
                            <Link to="/Help" onClick={()=>{closeSidebar(); logFirebaseEvents("Menu", "Help_Clicked", "Help_Clicked", "","");}}>
                                <img src={Helpcircle} alt='help' className='md:w-[17px] w-[15px] md:h-[17px] h-[15px] inline-flex' />
                                <span className='font-[700]  md:text-[15px] sm:text-[13px] text-[15px] leading-[16px] font-hanken-grotesk sm:ml-5 ml-6'>{t('Help')}</span>
                            </Link>
                        </li>
                    </ul>

                    <ul className='pt-8 pb-4'>
                        <li className='flex' onClick={()=>{handleLogout(); logFirebaseEvents("Menu", "LogOut_Clicked", "LogOut_Clicked", "","");}}>
                            <Link>
                                <img src={Logout} alt='logout' className='md:w-[17px] w-[15px] md:h-[17px] h-[15px] inline-flex' />
                                <span className='font-[700]  md:text-[15px] sm:text-[13px] text-[15px] leading-[16px] font-hanken-grotesk sm:ml-5 ml-6'>{t('log_out')}</span>
                            </Link>
                        </li>
                    </ul>

                </div>
            </div>

        </>
    );
};

export default Sidebar;
