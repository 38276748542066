import React, { useState, useEffect, useRef } from 'react'
import Arrow from '../../../../assets/images/arrow.png';
import { useTranslation } from 'react-i18next';
import Card from '../../../CustomComponents/Card';
import addLineBreaks from '../../../../utils';
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import Loader from '../../../CustomComponents/Loader';
import { useNavigate } from 'react-router';

const appConfigData = await fetchConfigarationData();
const StudentSelection = ({openYesImstudent, openImNotStudent}) => {
 const [isLoading, setIsLoading] = useState(false);
 const navigate = useNavigate()
  const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue placeholder:font-semibold md:text-[16px] sm:text-[14px] text-[16px] md:font-[700] sm:font-[700] font-[700] focus:outline-none w-[292px] h-[60px] mb-4 rounded-full";
  const { t } = useTranslation();
  //  const deviceId = useSelector(state => state.deviceId);
  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]";
   const handleStudent = () =>{
        navigate('/studentemailverfication')
   }
   const handleNotStudent = () =>{
        navigate("/createpassword")
   }
  return (
    <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0">
      <Card
        cardHeight={'697px'}
        heading={
          <>
            <span className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)] lg:text-[31px]
             md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
              md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('student_selction_heading1')}</span>
            <span className='font-serif-pro sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)] lg:text-[31px]
             md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
              md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('student_selction_heading2')}</span>
          </>
        }
        subheading={addLineBreaks(t('student_selction_heading3'), SubTittleStylesModal)}
        Cardcaption={t('student_selction_caption')} header={true} footer={true} CardRadius={true}>
        <div className="flex flex-col mt-28 items-center" >
            <button className={InputStyles} onClick={openYesImstudent}>{t('student_btn')}</button>
            <button className={InputStyles} onClick={openImNotStudent}>{t('not_student_btn')}</button> 
        </div>  
      </Card>
      {isLoading && <Loader />}
    </div>
  )
}

export default StudentSelection;