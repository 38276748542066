import React, { useEffect, useState, useRef } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HomeLayout from '../Layout/HomeLayout';
import DynamicCard from '../CustomComponents/DynamicCard';
import { width } from '../CustomComponents/DynamicCard/DynamicCardStyles';
import Loader from '../CustomComponents/Loader';
import { GET_DETAILS_FRAUD_ALERT, POST_DETAILS_FRAUD_ALERT, STATUS_SUCCESS_CODE } from '../../network/URLConstants';
import { fetchConfigarationData, retrieveData } from '../../helpers/utils/Utils';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../network/NetworkUtils';
import moment from 'moment';
import { useNavigate } from 'react-router';
import Home from '../Home';
import { GENERATE_OTP_KYC_CIP, GET_KYC_CIP_ACCESS } from '../../network/URLConstants';
import Modal from '../CustomComponents/Modal';
import Custom2FA from '../CustomComponents/Custom2FA';
import CustomTextInput from '../CustomComponents/CustomtextInput';
import { displayErrorMessage } from '../../utils';
import { LOGIN_SESSION_KEY } from '../../Redux/reducers';
import CustomAlertModel from '../CustomComponents/CustomAlertModel';
import HomeHeader from '../CustomComponents/HomeHeader';
import check from '../../assets/images/check_3x.png'
import disableCheck from '../../assets/images/disableCheck.png'
import { addLineBreaks } from '../../utils';
const appConfigData = await fetchConfigarationData();


const FraudProtection = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const headingText = 'w-[35%] font-[500] text-[#808080] lg:text-[14px] md:text-[14px] sm:text-[13px] text-[14px] uppercase font-hanken-grotesk'
    const headingTextvalue = 'font-[500] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[14px] font-hanken-grotesk'
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [CIPAccess, setCIPAccess] = useState(false);
    const [cardLast4Digits, setCardLast4Digits] = useState('');
    const [infoMessage, setinfoMessage] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [secureURL, setSecureURL] = useState('');
    const navigate = useNavigate();
    const sessionKey = useSelector(state => state.loggedSessionKey);
    const [isOTPVerifyModalOpen, setisOTPVerifyModalOpen] = useState(false);
    const currentURL = new URL(window.location.href);
    const accessParam = currentURL.searchParams.get('id');
    // const accessParam = 'cdbabc738eae';
    const dispatch = useDispatch();
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [isGetCode, setIsGetCode] = useState(false);
    const [isVerifyCode, setIsVerifyCode] = useState(false);
    const [isUploadInfo, setIsUploadInfo] = useState(false);
    const iframeRef = useRef();

    const getCIPKYCDetails = async () => {
        setLoading(true);
         const url = GET_KYC_CIP_ACCESS + `?code=${accessParam}`
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        const response = await GetRequest(url, headers)
        setTimeout(() => {
            setLoading(false)
        }, 500);

        const responseObj = JSON.parse(response);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            // debugger;
            setCIPAccess(responseObj.Response)
            generateCIPOTP();
        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("kudzu"), '', displayText, true, false, t("home"), '', true, require('../../assets/images/warning.png'), false, false)
        }
    }

    useEffect(() => {
        const isAcceptAgreement = { isAcceptAgreement: true };
        dispatch({ type: 'IS_ACCEPT_AGREEMENT', payload: isAcceptAgreement });
        getCIPKYCDetails()
    }, [])


// pass user details to iframe
    useEffect(() => {
        if(isUploadInfo){

            const iframe = iframeRef.current;
            iframe.addEventListener('load', () => {
                iframe.contentWindow.postMessage(userDetails, "*");
            });
        }
     }, [iframeRef, isUploadInfo]);

    const closeOTPVerifyModal = () => {
        setShowModel(false);
        const isAcceptAgreement = { isAcceptAgreement: false };
        dispatch({ type: 'IS_ACCEPT_AGREEMENT', payload: isAcceptAgreement });
        navigate('/home')
    }

    const verificationSuccess = (type, response) => {
        getSecureLink(response);
    }

    const getSecureLink = (response) => {
        // debugger;
        const securedlinkURL = response.Response.securedlink ? response.Response.securedlink : appConfigData.BASE_URL + `cip?lang=en&id=${accessParam}`;
        setSecureURL(securedlinkURL);
        setIsVerifyCode(true)
        let data = {     
            "variables": {
              "firstName":  response.Response.firstName,
              "lastName":  response.Response.lastName,
              "phoneNumber":  response.Response.mobile,
              "PRN":  response.Response.accountNumber,
              "customerEmail":  response.Response.emailId 
          }
        };    
        setUserDetails(data);
        setIsUploadInfo(true);
    }

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    const closeCustomAlertModal = () => {
        setShowModel(false);
        const isAcceptAgreement = { isAcceptAgreement: false };
        dispatch({ type: 'IS_ACCEPT_AGREEMENT', payload: isAcceptAgreement });
        // navigate('/home')
    }
    const onAcptBtnClick = async () => {
        if (btn1Text == t("home")) {
            const isAcceptAgreement = { isAcceptAgreement: false };
            dispatch({ type: 'IS_ACCEPT_AGREEMENT', payload: isAcceptAgreement }); 
            // navigate('/home')
        }
    }
    const onCancelBtnClick = () => {

    }
   
    const generateCIPOTP = async () => {
  
            // debugger;
            const deviceId = await retrieveData('updatedDeviceId');
            const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

            var object = {
                "lang": "en",
                "sessionkey": 0,
                "appID": appConfigData.CLIENT_ID,
                "code": accessParam
          
            }
            var url = GENERATE_OTP_KYC_CIP
            setLoading(true)
            var response = await PostRequest(url, object, headers)
            setTimeout(() => {
                setLoading(false)
            }, 500);
            let parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                setUserDetails({ ...userDetails, "mobile": parsedResponse.Response.mobile })
                // setisOTPVerifyModalOpen(true)
                const sessionIdPayload = { loggedSessionKey: parsedResponse.Response.sessionKey }
                dispatch({ type: LOGIN_SESSION_KEY, payload: sessionIdPayload });
                setIsGetCode(true);
                setLoading(false)

            } else {
                setLoading(false)
                // setisOTPVerifyModalOpen(false)
                var arrMessages = parsedResponse.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("kudzu"), '', displayText, true, false, t("home"), '', true, require('../../assets/images/warning.png'), false, false)
            }
    
    }


    return (
        <>
            <HomeLayout>
                <HomeHeader heading={'Welcome back! We just need a little more information.'} isHeaderCaption={true} />
                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        HeaderContent={false}
                        isWhite={true}
                        footer={true}>

                        <div className='pb-16 sm:px-0 px-4 my-12'>
                            <div class="lg:my-[2rem] md:my-[1.5rem] my-[1rem] ">

                                <div class="lg:my-[2rem] md:my-[1.5rem] my-[1rem] font-[500] md:font-[500] sm:font-[600] md:text-[26px] sm:text-[15px] text-[16px] md:leading-[36px] sm:leading-[23px] leading-[24px] font-hanken-grotesk ">{t('acc_verification')}</div>
                                <div className=' flex sm:flex-row flex-col sm:justify-between  justify-start item-start w-full gap-6'>
                                    <div className=' flex flex-row gap-2'>
                                        <img src={isGetCode ? `${check}` : `${disableCheck}`} className='w-[14px] h-[14px] md:w-[20px] md:h-[20px] self-center' /><p className='font-[700] md:font-[600] sm:font-[700] md:text-[18px] sm:text-[12px] text-[12px] md:leading-[28px] sm:leading-[20px] leading-[20px] font-hanken-grotesk'>{t("get_code")}</p>
                                    </div>
                                    <div className=' flex flex-row  gap-2'>
                                        <img src={isVerifyCode ? `${check}` : `${disableCheck}`} className='w-[14px] h-[14px] md:w-[20px] md:h-[20px] self-center' /><p className='font-[700] md:font-[600] sm:font-[700] md:text-[18px] sm:text-[12px] text-[12px] md:leading-[28px] sm:leading-[20px] leading-[20px] font-hanken-grotesk'>{t("verify_code")}</p>
                                    </div>
                                    <div className=' flex flex-row gap-2'>
                                        <img src={isUploadInfo ? `${check}` : `${disableCheck}`} className='w-[14px] h-[14px] md:w-[20px] md:h-[20px] self-center' /><p className='font-[700] md:font-[600] sm:font-[700] md:text-[18px] sm:text-[12px] text-[12px] md:leading-[28px] sm:leading-[20px] leading-[20px] font-hanken-grotesk'>{t("upload_info")}</p>
                                    </div>

                                </div>
                            </div>
                            <div class="border-t-[2.5px] border-[#A3CEBB] sm:mx-0 px-4"></div>
                            {/* <p className='mt-6 lg:text-[18px] md:text-[15px] sm:text-[14px] text-[16px] font-[500]
                             font-hanken-grotesk leading-[30px] w-[84%] text-[#3F4353]'>
                                Please tell us if you, or someone you authorized,
                                used your smiONE card ending in 1234 for:</p> */}
                            {(CIPAccess && secureURL && isVerifyCode) &&
                                <div className="h-[100%] sm:py-16 py-24">
                                    <iframe src={secureURL}
                                        ref={iframeRef}
                                        title="secureURL" className="w-full min-h-[700px]"
                                    />
                                </div>
                            }
                            {(CIPAccess && Object.keys(userDetails).length) &&
                                <div>
                                    <div className= {`${(secureURL && isVerifyCode) ? 'hidden' : 'flex flex-col'}  py-8`}>
                                        <p className='mt-6 mb-5 font-[600]  md:text-[16px] sm:leading-[26px] sm:text-[10px] text-[12px] leading-[20px] font-hanken-grotesk w-[84%]  tracking-[10%]'>{addLineBreaks(t("code_verification_msg"))}</p>
                                        <p className='mb-20'>{userDetails?.mobile}</p>
                                        {/* <CustomTextInput
                                            maxLength={4}
                                            autoFocus={true}
                                            name={t("last_4_digits")}
                                            onChange={(event) => {
                                                if (/^\d*$/.test(event.target.value) && event.target.value.length <= 4) {
                                                    setCardLast4Digits(event.target.value)
                                                }
                                                setinfoMessage('');
                                            }}
                                            value={cardLast4Digits} style={{ width: '100%', height: '51px' }}
                                            onKeyDown={(event) => { validateCardKey(event.target.value) }}
                                        />
                                        {(infoMessage) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        } */}

                                        <Custom2FA 
                                            accessParam={accessParam} 
                                            type={t('fraud_protection')} 
                                            securityText={<p class="font-[500] md:text-[18px] sm:text-[12px] text-[16px] md:leading-[18px] sm:leading-[18px] leading-[24px] font-hanken-grotesk ">{t("fraud_security_code_text")}</p>} 
                                            verificationSuccess={(type, response) => { verificationSuccess(type, response) }} 
                                            verificationFailure={() => { }} 
                                            onClose={() => { }} 

                                        />

                                    </div>
                                    <p className='md:font-[400] sm:font-[500] font-[400] md:text-[12px] sm:text-[10px] 
                                        text-[12px] leading-[18px] md:leading-[20px] sm:leading-[16px] font-hanken-grotesk'>{t('fraud_footer_text1')}</p>

                                    <p className='mt-4 md:font-[400] sm:font-[500] font-[400] md:text-[12px] sm:text-[10px] 
                                        text-[12px] leading-[18px] md:leading-[20px] sm:leading-[16px] font-hanken-grotesk'>
                                        {t('fraud_footer_text2')}</p>

                                </div>
                            }
                        </div>


                    </DynamicCard>

                </div>
            </HomeLayout >
            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={() => { closeOTPVerifyModal() }}
                isLogo={false}
                subHeaderMsg={<p className="font-hanken-grotesk modal-title lg:text-[18px] md:text-[17px] sm:text-[15px] text-[14px] font-normal cursor-pointer pointer-events-none">{t("standard_msg_and_data_rates_may_apply")}</p>}
                title={<p className={HighlightMsgStyles}> {t("security_check_custom2fa")}</p>}
                subtitle={<p className={HeaderMsgStyles}>{t("we_are_sending_text")}</p>}
                content={<div className="mt-10"><Custom2FA accessParam={accessParam} type={t('fraud_protection')} onClose={() => { closeOTPVerifyModal() }} verificationSuccess={(type, response) => { verificationSuccess(type, response) }} verificationFailure={() => { }} /></div>}
            />
            <CustomAlertModel isOpen={showModel}
                onClose={() => { closeCustomAlertModal() }}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={() => { onAcptBtnClick() }}
                onCancelBtnClick={() => { onCancelBtnClick() }}
            />
            {loading && <Loader />}
        </>

    )
}

export default FraudProtection