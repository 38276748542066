import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import TimeRangeSlider from '../../../CustomComponents/Slider/TimeSlider';
import { convertToTimeFormat } from '../../../../utils';
import logFirebaseEvents from '../../../LogFirebaseEvents';


const NotificationFilter = ({ NotificationFromTime, NotificationToTime, updateTimeFrame, TimeSelectionLable, isReset }) => {
    // debugger;
    const { t } = useTranslation();
    const minTime = convertToTimeFormat(NotificationFromTime)
    const maxTime = convertToTimeFormat(NotificationToTime)
    const startTime = "12:00 AM";
    const endTime = "11:59 PM"


    const [timeForReceivingText, setTimeForReceivingText] = useState(false);
    const [selectedMinTime, setSelectedMinTime] = useState(minTime);
    const [selectedMaxTime, setSelectedMaxTime] = useState(maxTime);

    useEffect(() => {
        updateTimeFrame(selectedMinTime, selectedMaxTime);
    }, [selectedMinTime, selectedMaxTime])

    useEffect(() => {
        if (!timeForReceivingText) {
            setSelectedMinTime(startTime);
            setSelectedMaxTime(endTime);
        }
    }, [timeForReceivingText])

    useEffect(() => {
        if (minTime === startTime && maxTime === endTime) setTimeForReceivingText(false);
        else setTimeForReceivingText(true);

        setSelectedMinTime(minTime);
        setSelectedMaxTime(maxTime);

    }, [isReset])



    return (
        <div className=' flex flex-col gap-10 sm:gap-0 md:gap-10'>
            <div className=" flex flex-row justify-between">
                <p className="font-hanken-grotesk font-[500] text-[14px] leading-[20px] sm:font-[700] sm:text-[12px] sm:leading-[20px] md:font-[600] md:text-[18px] md:leading-[18px] text-secondary-3 w-1/2 sm:w-[85%]">{TimeSelectionLable}</p>
                <p className='flex-grow text-right'>
                    <label class="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox"
                            checked={timeForReceivingText}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    setTimeForReceivingText(event.target.checked)
                                }
                            }}
                            onChange={(e) => {setTimeForReceivingText(e.target.checked);logFirebaseEvents("Configure_Notification", "Prefered_Time_Clicked", "Prefered_Time_Clicked", "","")}} class="sr-only peer" />
                        <div class="w-[49px] h-[34px] bg-[#94C3E8] bg-opacity-40
                                     peer-focus:outline-none   
                                      rounded-full peer dark:bg-gray-700 
                                      peer-checked:after:translate-x-full
                                     peer-checked:after:border-[#2965FB] 
                                      peer-checked:after:bg-[#2965FB]
                                      peer-checked:after:left-[-10px]
                                      after:content-[''] 
                                      after:absolute after:top-[3px]
                                      after:left-[4px] after:right-0
                                      after:bg-white
                                       after:border-white after:border-[gray-300] 
                                      after:border after:rounded-full after:h-[28px] 
                                      after:w-[28px] after:transition-all
                                       dark:border-gray-600 peer-checked:bg-[#94C3E8] peer-checked:bg-opacity-40"></div>

                        <span class="ml-3 text-sm font-medium text-gray-900
                                     dark:text-gray-300"></span>
                    </label>
                </p>
            </div>

            {timeForReceivingText &&
                <div className='flex flex-col lg:flex-row lg:justify-between pt-10'>
                    <p className='font-hanken-grotesk font-[500] text-[14px] leading-[20px] sm:font-[700] sm:text-[12px] sm:leading-[20px] md:font-[600] md:text-[18px] md:leading-[18px] text-secondary-3  pb-16'>{t("select_time")}</p>
                    <TimeRangeSlider
                        selectedMinTime={selectedMinTime}
                        selectedMaxTime={selectedMaxTime}
                        // Pass two separate arguments to the onDateChangeProp function
                        onTimeChangeProp={(minTime, maxTime) => {
                            setSelectedMinTime(minTime);
                            setSelectedMaxTime(maxTime);
                        }}
                        isReset={isReset}
                    />

                </div>
            }


        </div>
    )
}

export default NotificationFilter

