import React, { useState } from "react";
import DynamicCard from "../DynamicCard";
import { width } from "../DynamicCard/DynamicCardStyles";
const AddMoneyComponent = (props) => {
  const {
    highlightText,
    subHeaderText,
    image,
    buttonEnabled,
    buttonText,
    buttonAction,
    footerBtnAction,
    isSpace,
    isSubheadingwidth,
    backgroundColor,
    btnBgColor,
    btnTextColor,
    highlightTextColor,
    subHeaderTextColor,
    headerTitle,
    borderDirection,
    cardHeight,
    headerBgColor,
    titleTextColor,
    selectedItem,
  } = props;

  const maskedCardNumber = (
    <span className="inline-flex items-center">
      <span className="w-5 rounded-full flex items-center justify-center relative top-[2px]">
        <span
          className={`${
            props?.titleTextColor ? `${props?.titleTextColor}` : "bg-[#FFFFFF]"
          } font-bold`}
        >
          ••
        </span>
      </span>
      <span style={{ fontFamily: "Space Mono" }}>
        {selectedItem?.CardDisplayNumber.slice(4)}
      </span>
    </span>
  );

  const climbFooterHome = () => (
    <DynamicCard
      header={false}
      backgroundColor={props?.headerBgColor}
      cardWidth={width.homeCardWithFilters}
      CardRadius={borderDirection}
      HeaderRadius={borderDirection}
      isWhite={true}
      HeaderContent={
        <div
          className={` sm:flex block justify-between flex-nowrap items-center md:my-[35px]
     sm:my-[20px] my-[20px] md:mx-[4%] sm:ml-[6%] sm:mr-[8%] mr-[2.5rem] ml-[2.5rem]`}
        >
          <div
            className={`lg:text-[26px] md:text-[26px] sm:text-[20px] text-[22px] md:leading-[36px]
      sm:leading-[23px] leading-[24px] ${
        props?.titleTextColor ? `${props?.titleTextColor}` : "bg-[#FFFFFF]"
      } font-[500] md:font-[500] font-hanken-grotesk`}
          >
            {selectedItem?.AccountTypeName}
            <span
              className="md:text-[14px] sm:text-[9px] text-[10px] ml-2 md:leading-[14px] 
        sm:leading[6.93px] leading-[24px] tracking-[2px] font-[700]"
            >
              {maskedCardNumber}
            </span>
          </div>
        </div>
      }
    >
      <div
        className={`${
          props?.backgroundColor ? `${props?.backgroundColor}` : "bg-[#4B70B9]"
        } flex flex-col ${
          props?.borderDirection
            ? props?.borderDirection === false
              ? "sm:rounded-br-[4rem]"
              : " sm:rounded-bl-[4rem]"
            : " sm:rounded-br-[4rem]"
        } `}
      >
        <div className="md:py-15 sm:py-8 py-16">
          <div className="sm:px-[7%] px-10 w-[100%] flex sm:flex-row sm:justify-between flex-col items-center">
            <div
              className={`font-serif-pro ${
                props?.highlightTextColor
                  ? `${props?.highlightTextColor}`
                  : "text-[#ffffff]"
              }  font-normal sm:mb-10 lg:text-20 md:text-[18px] sm:text-[13px] mt-5 text-[15px] lg:w-[351px] md:w-[350px] sm:w-[235px] md:leading-[38px] sm:leading-[24px] leading-[21px]`}
            >
              {highlightText}
            </div>
            {/* <p className={`${props?.subHeaderTextColor ? `text-[${props?.subHeaderTextColor}]` :'text-[#ffffff]'} sm:mb-10 lg:text-20 md:text-[18px] sm:text-[13px] mt-5 text-[15px] w-[264px]  ${isSubheadingwidth ? 'lg:w-[295px] md:w-[351px] sm:w-[235px]' : 'lg:w-[404px] md:w-[404px] sm:w-[224px]'}`}>{subHeaderText}</p> */}
          </div>
          <div className="w-[100%] px-10 flex sm:flex-row sm:justify-between flex-col items-center sm:items-end mt-5">
            <div
              className="md:p-2 md:ml-10"
              style={{ height: 20, width: 172 }}
            />

            <button
              style={{ zIndex: 1 }}
              className={`sm:mr-5 font-[700] sm:mb-5 lg:w-[150px] md:w-[130px] sm:w-[118px] w-full lg:text-[18px] md:text-[18px] sm:text-[12px] text-[16px] font-hanken-grotesk ${
                props?.btnBgColor ? `bg-[${props?.btnBgColor}]` : "bg-[#ffffff]"
              } float-right rounded-full px-2 py-4 ${
                props?.btnTextColor
                  ? `text-[${props?.btnTextColor}]`
                  : "text-[#000000]"
              }`}
              onClick={() => {
                footerBtnAction();
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  footerBtnAction();
                }
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </DynamicCard>
  );

  return <>{climbFooterHome()}</>;
};

export default AddMoneyComponent;
