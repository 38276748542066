import React, { useState, useEffect } from 'react'
import DynamicCard from '../../../../../CustomComponents/DynamicHomeCard';
import { width } from '../../../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../../CustomComponents/Loader';
import CustomInput from '../../../../../CustomComponents/FormInputs';
import CustomTextInput from '../../../../../CustomComponents/CustomtextInput';
import LeftBar from '../../../../../CustomComponents/DynamicComponent/LeftBar';

import {
    STATUS_SUCCESS_CODE,
    GOOGLE_PLACE_DETAILS_WITH_PLACE_ID,
    GOOGLE_PLACES_AUTO_COMPLETE,
    VALIDATE_ALL_DETAILS_OF_USER,
    GET_BENEFICIARY_DETAILS,
    REMOVE_BENEFICIARY,
    ADD_AUTH_USER,
    UPDATE_BENEFICIARY
} from '../../../../../../network/URLConstants';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, PostRequest, GetRequest } from '../../../../../../network/NetworkUtils';
import { displayErrorMessage, displaySuccessMessage, parseGoogleLocationResponse, validateAuthorizedUser } from '../../../../../../utils';
import LineBreaks from '../../../../../../utils';
import { formatDateYYYYMMDD, validateMobileNumber, isEmailValid } from '../../../../../../utils'
import { retrieveData } from '../../../../../../helpers/utils/Utils';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import HomeLayout from '../../../../../Layout/HomeLayout';
import HomeHeader from '../../../../../CustomComponents/HomeHeader';
import Modal from '../../../../../CustomComponents/Modal';
import { fetchConfigarationData } from '../../../../../../helpers/utils/Utils';
import CustomAlertModel from '../../../../../CustomComponents/CustomAlertModel';
import address_update_Succss from '../../../../../../assets/images/address_update_Succss.png';
import { useKeyDown } from '../../../../../../hooks/useKeyDown';
import { useNavigate } from 'react-router-dom';
import benfRmveSuccess from '../../../../../../assets/images/beneficiaryrmveSucess.png';
import warningIcon from '../../../../../../assets/images/warning.png';
import DatePicker from "react-datepicker";
import moment from 'react-moment';
import transactionErrorIcon from "../../../../../../assets/images/transfer-error.png"
import Showpassword from '../../../../../../assets/images/showpassword.png'
import Hidepassword from '../../../../../../assets/images/hidepassword.png'

const appConfigData = await fetchConfigarationData();


const validationSchema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    dob: yup.string().required('Date of birth is required'),
    ssn_no: yup.string().required('Social Security Number (SSN) is required'),
    address: yup.string().required('Address is required'),
});
const EditAuthorizedUser = ({ transactionDatas, onViewAllClick, viewAll }) => {
    const ssnRef = React.createRef();
    const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue lg:text-[16px] md:text-[15px] text-[14px] font-medium focus:outline-none w-full h-[60px]";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook to access translation functions
    const moment = require('moment');
    const cards = useSelector(state => JSON.parse(state.userCards));
    //mdoal styles
    const HeadingStyles = "font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-3";

    const [isLoading, setIsLoading] = useState(false);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [editNameClicked, setEditNameClicked] = useState(false);
    const [editDobClickd, setEditDObClicked] = useState(false);
    const [editSSNClicked, seteditSSNClicked] = useState(false);
    const [addressEditClicked, setAddressEditClicked] = useState(false);
    const currentYear = new Date().getFullYear();
    const [privacyErromessage, setPrivacyErromessage] = useState('');
    const addedBenficaryDetails = useSelector(state => state.addedAuthorizedUserDetails);
    const [selectedFirstName, setSelectedFirstName] = useState(addedBenficaryDetails.firstName);
    const [selectedLastName, setSelectedLastName] = useState(addedBenficaryDetails.lastName);
    const [selecteDOB, setSelecteDOB] = useState(addedBenficaryDetails.birthDay);
    const [selectedDobToserver, setSelectedDobToServer] = useState(addedBenficaryDetails.selectedDobToServer);
    const [selectedSSN, setSelectedSSn] = useState(addedBenficaryDetails.ssn);
    const [selectedPohoneNumber, setSelectedPohoneNumber] = useState(addedBenficaryDetails.phoneNumber);
    const [selectedEmailAdress, setSelectedEmailAdress] = useState(addedBenficaryDetails.emailAddress);
    const [isAddress, setAddress] = useState(addedBenficaryDetails.formattedAddress);
    const [phoneNumberEditClicked, setPhoneNumberEditClicked] = useState(false);
    const [emailAdressEditClicked, setEmailAdressEditClicked] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    const [ssn, setSSN] = useState(addedBenficaryDetails.ssn)
    const [ssnBackup, setSSnBackUp] = useState(addedBenficaryDetails.ssn)
    const [isCursorVisible, setCursorVisibility] = useState(false);




    const [predictions, setPredictions] = useState([]);
    const [typedValue, setTypedValue] = useState("");
    const [selectedAddress, setSelectedAddress] = useState('');
    const [showModel, setShowModel] = useState(false);
    const [modelImage, setModelImage] = useState('')
    const selectedItem = cards?.[0]
    const userId = useSelector(state => state.userId);
    const selecteBenficaryId = useSelector(state => state.benficaryKey);


    const [loading, setLoading] = useState(false)
    const [infoMessage, setinfoMessage] = useState('');
    const [responseCode, setResponseCode] = useState('');
    const [selectedAddressLine2, setSelectedAddressLine2] = useState('');
    const [line1AdressFilled, setLine1AdressFilled] = useState(false);
    const [homeAddressToserver, setHomeAddressToserver] = useState('');
    const sessionKey = useSelector(state => state.loggedSessionKey);



    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title  lg:w-[283px] lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');


    const [checkedValues, setCheckedValues] = useState([]);
    const [isremoveAcceptClicked, setIsremoveAcceptClicked] = useState(false);
    const [SSNoError, setSSNoError] = useState('');
    const isEditBenificirey = useSelector(state => state.isEditBenificirey);

    const [AddressError, setAddressError] = useState('');
    const [nameChanged, setnameChanged] = useState(false);
    const [ssnChanged, setSsnChanged] = useState(false);
    const [dobChanged, setDobChanged] = useState(false);
    const [addressChanged, setAddressChanged] = useState(false)
    const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);
    const [emailChanged, setEmailChanged] = useState(false);
    const [isSSNValid, setIsSSNValid] = useState(false);

    const [benficaryDetailObject, setBenficaryDetailObject] = useState({
        firstName: '',
        lastName: '',
        ssn: '',
        statusCode: '',
        selectedDateToServer: '',
        birthDay: '',
        formattedAddress: '',
        addressLine2: '',
        phoneNumber: '',
        emailAddress: '',
    });
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        dob: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
        address: '',
        apt_etc: '',
        ssn_no: ''
    });
    const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const [checkboxes, setCheckboxes] = useState({
        privacy_user: false,
        deposit_savings: false,
        electronic_aggrement: false,
    });
    const handleCheckboxChange = (event, checkboxKey) => {
        const isChecked = event.target.checked;
        setPrivacyErromessage('');
        if (checkboxKey == 'electronic_aggrement' && event.target.checked) {

        } else {
            setCheckboxes((prevCheckboxes) => ({
                ...prevCheckboxes,
                [checkboxKey]: isChecked,
            }));
        }

        // Update checkedValues state
        setCheckedValues((prevCheckedValues) => {
            const updatedCheckedValues = [...prevCheckedValues];
            const existingCheckboxIndex = updatedCheckedValues.findIndex((checkbox) => checkbox.checkboxKey === checkboxKey);

            if (existingCheckboxIndex !== -1) {
                updatedCheckedValues[existingCheckboxIndex].checked = isChecked;
            } else {
                updatedCheckedValues.push({ checkboxKey, checked: isChecked });
            }

            return updatedCheckedValues;
        });

    };
    const toggleCursor = () => {
        setCursorVisibility(!isCursorVisible);
      };

    const handleRevertClick = () => {
        // navigate(-1)
        navigate('/AccountServices/Spending/ManageAuthorizedUsers');
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        handleSSNValue(ssnBackup)
    }, [showPassword])

    const addAuthorizedUser = async () => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var object = {
            "firstName": benficaryDetailObject.firstName,
            "lastName": benficaryDetailObject.lastName,
            "ssn": benficaryDetailObject.ssn.replace("-", "").replace("-", ""),
            "dateOfBirth": benficaryDetailObject.selectedDateToServer,
            "mobile": benficaryDetailObject.phoneNumber.replace("-", "").replace("-", ""),
            "email": benficaryDetailObject.emailAddress,
            "line1": homeAddressToserver.line1,
            "line2": homeAddressToserver.line2,
            "city": homeAddressToserver.city,
            "state": homeAddressToserver.state,
            "zipCode": homeAddressToserver.zipCode,
            "countryCode": homeAddressToserver.countryCode,
            "sessionId": sessionKey != null ? sessionKey : ''

        }
        var url = ADD_AUTH_USER.replace("{CARD_KEY}", cards[0].CardKey)
        setIsLoading(true);

        const response = await PostRequest(url, object, headers)
        setIsLoading(false)
        setTimeout(() => {
            let parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                showAlertModal(true, t('thanks'), t('auth_user_review_title'), t('auth_user_review_Description'), true, false, t('back_account_services'), '', true, benfRmveSuccess);
            } else {
                var arrMessages = parsedResponse.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t('alert'), displayText, '', true, false, t('back_account_services'), '', true, benfRmveSuccess);
            }
        }, 1000);

    }

    const handleSaveClick = async () => {
        if (nameChanged || dobChanged || ssnChanged || addressChanged || phoneNumberChanged || emailChanged) {
            const response = await validateAuthorizedUser(
                benficaryDetailObject.firstName,
                benficaryDetailObject.lastName,
                benficaryDetailObject.ssn,
                benficaryDetailObject.selectedDateToServer,
                homeAddressToserver.line1,
                homeAddressToserver.line2,
                homeAddressToserver.city != null ? homeAddressToserver.city : '',
                homeAddressToserver.state != null ? homeAddressToserver.state : '',
                homeAddressToserver.zipCode != null ? homeAddressToserver.zipCode : '',
                homeAddressToserver.countryCode != null ? homeAddressToserver.countryCode : '',
                benficaryDetailObject.phoneNumber,
                benficaryDetailObject.emailAddress,
                cards !== null ? cards[0].CardKey : 0,
                accessTokenToApi
            )
            setIsLoading(true);

            const responseObj = JSON.parse(response)
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                setIsLoading(false);
                addAuthorizedUser();
            } else {
                setIsLoading(false);
                setTimeout(() => {
                    var arrMessages = responseObj.Messages;
                    var message = arrMessages[0];
                    var displayText = message.DisplayText;
                    if (displayText === t('ssn_servor_error')) {
                        showAlertModal(true, t('oops'), t('already_authorized_ssn'), t('unable_authorized_ssn'), true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
                    } else if (displayText === t('ssn_servor_error')) {
                        showAlertModal(true, t('oops'), t('already_authorized_email'), t('unable_authorized_email'), true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
                    } else {
                        showAlertModal(true, t('oops'), displayText, "", true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
                    }


                }, 1000);
            }

        } else {
            addAuthorizedUser();
        }
    };
    const editPhoneNumberClicked = async () => {
        setinfoMessage('');
        setPhoneNumberEditClicked(true);
        setEditNameClicked(false);
        setEditDObClicked(false);
        seteditSSNClicked(false);
        setAddressEditClicked(false);
        setEmailAdressEditClicked(false);
    }
    const updatePhoneNumber = async () => {
        const errorMessage = validateMobileNumber(selectedPohoneNumber.replace(/-/g, ""))

        if (errorMessage != "Valid mobile number") {
            setSelectedPohoneNumber('');
            setinfoMessage(errorMessage);
            return
        }
        var benficaryobject = benficaryDetailObject;
        benficaryobject.phoneNumber = selectedPohoneNumber;
        setBenficaryDetailObject(benficaryobject);
        setSelectedPohoneNumber('');
        setPhoneNumberEditClicked(false)
        setPhoneNumberChanged(true)
    }
    const editEmailClicked = async () => {
        setinfoMessage('');
        setEmailAdressEditClicked(true)
        setPhoneNumberEditClicked(false);
        setEditNameClicked(false);
        setEditDObClicked(false);
        seteditSSNClicked(false);
        setAddressEditClicked(false);
    }
    const updateEmailAdressClicked = async () => {
        const errorMessage = isEmailValid(selectedEmailAdress)
        if (errorMessage != "valid") {
            setSelectedEmailAdress('');
            setinfoMessage(errorMessage);
            return
        }

        var benficaryobject = benficaryDetailObject;
        benficaryobject.emailAddress = selectedEmailAdress;
        setBenficaryDetailObject(benficaryobject);
        setEmailAdressEditClicked(false)
        setSelectedEmailAdress('');
        setEmailChanged(true)

    }




    useEffect(() => {

        // const formattedDob = addedBenficaryDetails.birthDay.replace("-", " ");


        const inputDate = addedBenficaryDetails.birthDay;

        // Parse the input date
        const parts = inputDate.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        // Create a Date object in JavaScript
        const date = new Date(year, month - 1, day);
        // Format the date as "dd/MM/yyyy"
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;



        setBenficaryDetailObject({
            firstName: addedBenficaryDetails.firstName,
            lastName: addedBenficaryDetails.lastName,
            ssn: addedBenficaryDetails.ssn,
            statusCode: "",
            selectedDateToServer: addedBenficaryDetails.birthDay,
            birthDay: formattedDate,
            formattedAddress: addedBenficaryDetails.formattedAddress,
            addressLine2: addedBenficaryDetails.line2,
            phoneNumber: addedBenficaryDetails.phoneNumber,
            emailAddress: addedBenficaryDetails.emailAddress
        });
        const dateObj = new Date(addedBenficaryDetails.birthDay);

        setFormValues((prevValues) => ({
            ...prevValues,
            dob: dateObj
        }));

        var object = {
            "line1": addedBenficaryDetails.line1 != null ? addedBenficaryDetails.line1 : "",
            "line2": addedBenficaryDetails.line2 != null ? addedBenficaryDetails.line2 : "",
            "city": addedBenficaryDetails.city != null ? addedBenficaryDetails.city : '',
            "state": addedBenficaryDetails.state != null ? addedBenficaryDetails.state : '',
            "zipCode": addedBenficaryDetails.zipCode != null ? addedBenficaryDetails.zipCode : '',
            "countryCode": addedBenficaryDetails.countryCode != null ? addedBenficaryDetails.countryCode : '',
            "country": addedBenficaryDetails.country != null ? addedBenficaryDetails.country : '',
            "cardKey": addedBenficaryDetails.cardKey != null ? addedBenficaryDetails.cardKey : ''
        }
        setHomeAddressToserver(object);

        // setSelectedFirstName(addedBenficaryDetails.firstName);
        // setSelectedLastName(addedBenficaryDetails.lastName);
        // setSelectedSSn(addedBenficaryDetails.ssn);
        // setSelecteDOB(addedBenficaryDetails.birthDay);
        // setSelectedEmailAdress(addedBenficaryDetails.emailAddress);
        // setSelectedPohoneNumber(addedBenficaryDetails.phoneNumber);
        // setAddress(addedBenficaryDetails.formattedAddress);

    }, [addedBenficaryDetails]);


    const editNameButtonClicked = async () => {
        setinfoMessage('');
        setEditNameClicked(true);
        setEditDObClicked(false);
        seteditSSNClicked(false);
        setAddressEditClicked(false);
        setPhoneNumberEditClicked(false);
        setEmailAdressEditClicked(false);

    }
    const updateNameButtonClicked = async () => {
        if (selectedFirstName.trim() == "") {
            setinfoMessage(t('enter_firstName'))
            return;
        }
        if (selectedLastName.trim() == "") {
            setinfoMessage(t('enter_lasttName'))
            return;
        }
        var benficaryobject = benficaryDetailObject;
        benficaryobject.firstName = selectedFirstName;
        benficaryobject.lastName = selectedLastName;
        setBenficaryDetailObject(benficaryobject);
        setnameChanged(true);
        setEditNameClicked(false);
    }
    const editDobButtonClicked = async () => {
        setinfoMessage('');
        setEditDObClicked(true);
        setEditNameClicked(false);
        seteditSSNClicked(false);
        setAddressEditClicked(false);
        setPhoneNumberEditClicked(false);
        setEmailAdressEditClicked(false);
    }
    const editSSNButtonClicked = async () => {
        setinfoMessage('');
        seteditSSNClicked(true);
        setEditNameClicked(false);
        setEditDObClicked(false);
        setAddressEditClicked(false);
        setPhoneNumberEditClicked(false);
        setEmailAdressEditClicked(false);
    }
    const updateSSNButtonClicked = async () => {
        if (selectedSSN.trim() == "") {
            setinfoMessage(t('enter_ssn'))
            return;
        }
        var benficaryobject = benficaryDetailObject;
        benficaryobject.ssn = selectedSSN;
        setBenficaryDetailObject(benficaryobject);
        setSsnChanged(true);
        seteditSSNClicked(false);
    }

    const updateDobButtonClicked = async () => {
        // if (selecteDOB.trim() == "") {
        //     setinfoMessage(t('select_dob'))
        //     return;
        // }
        var benficaryobject = benficaryDetailObject;
        if (benficaryobject.birthDay == selecteDOB) {

        } else {
            setEditDObClicked(false);
            const benficaryobject = benficaryDetailObject;
            benficaryDetailObject.selectedDateToServer = selectedDobToserver;
            benficaryobject.birthDay = selecteDOB;
            setBenficaryDetailObject(benficaryobject);
            setDobChanged(true);
            setEditDObClicked(false);
        }
    }
    const editAddressClicked = async () => {
        setinfoMessage('');
        setAddressEditClicked(true);
        setEditNameClicked(false);
        setEditDObClicked(false);
        seteditSSNClicked(false);
        setPhoneNumberEditClicked(false);
        setEmailAdressEditClicked(false);
    }
    const updateHomeAddress = async () => {
        var benficaryobject = benficaryDetailObject;
        benficaryDetailObject.formattedAddress = isAddress;
        setBenficaryDetailObject(benficaryobject);
        setAddressEditClicked(false);
        setAddressChanged(true);
    }


    const closeOTPVerifyModal = () => {
        setIsremoveAcceptClicked(false);
    }
    const closeModel = () => {
        setShowModel(false);
    }
    const verificationSuccess = (type) => {
        setAddressEditClicked(false);

        if (type == t('old_address')) {
            setAddressEditClicked(true);
        }
        else if (type == t('new_address')) {
            setAddressEditClicked(false);
            showAlertModal(true, t('success'), t('editAddressSuccessMsg'), '', true, false, t('done'), '', true, address_update_Succss);
        }
        // getChangePinDetails()
        // navigate('/settings/ManageExternalAccounts/AddExternalAccount')
    }
    const verificationFaiure = (type, message) => {
        setinfoMessage(message);
    }
    const closeCustomAlertModal = () => {
        setShowModel(false);
    }

    const onAcptBtnClick = async () => {
        setShowModel(false)
        if (headerMsg === t('auth_user_review_title')) {
            navigate('/AccountServices')
        }

    }
    const onCancelBtnClick = () => {
        setShowModel(false)
        navigate('/AccountServices/Spending/ManageAuthorizedUsers')
    }

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)

    }



    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };
    const handleDateChange = (date) => {
        setinfoMessage('');
        const today = new Date();
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

        if (date > eighteenYearsAgo) {
            // Date of birth is less than 18 years ago
            setinfoMessage("Date of birth should be greater than 18 years ago")
            return;
        }

        if (date > today) {
            // Date of birth is in the future
            setinfoMessage("Date of birth cannot be in the future")
            return;
        }

        clearErrors('dob'); // Clear the dob field error if it was previously set
        // const formattedDob = moment(date).format('MMMM DD YYYY');

        const dateToServer = moment(date).format('MMMM-DD-YYYY');
        setSelectedDobToServer(dateToServer);
        //  setSelecteDOB(formattedDob);
        // setValue('dob', formattedDob);
        const formattedDateWithoutDayOfWeek = moment(date).format("MM/yyyy");
        const dayOfMonth = moment(date).format("DD");
        const formattedDate = `${dayOfMonth}/${formattedDateWithoutDayOfWeek}`;
        setSelecteDOB(formattedDate)

        // setDOBError('');
        setinfoMessage('');
        setFormValues((prevValues) => ({
            ...prevValues,
            dob: date
        }));
    };

    const handleSSNChange = (event) => {
        //debugger;
        const ssnNumber = event.target.value;

        // Remove all non-digit characters from the input
        const ssnNo = ssnNumber.length !== 9 ? ssnNumber.replace(/[*-]/g, '') : ssnNumber;
        setSSnBackUp(ssnNo)
        handleSSNValue(ssnNo)
        setSelectedSSn(event.target.value)

        if (ssnNo.length == 9) {
            setSSNoError('');
            // setSelectedSSn(event.target.value)
            handleInputChange(event); // Update the form values
            setIsSSNValid(true);
            //   ValidateSSN(ssnNo, formValues); // Pass the SSN value and form values to the ValidateSSN function
            //  ValidateSSN(true);
        }
    };
    function handleSSNValue(value) {
        const formatted = showPassword ? formatSecuredValue(value) : formatValue(value)
        setSSN(formatted)
    }
    function formatValue(value) {
        const formattedValue = value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d{2})(\d{1,2})/, '$1 - $2 - $3');

        return formattedValue;
    }

    function formatSecuredValue(value) {
        const securedValue = value
            .replace(/[^0-9•]/g, '')
            .replace(/((?:\d|.){3})((?:\d|.){2})((?:\d|.){1,2})/, '$1 - $2 - $3')
            .replace(/[0-9]/g, '•');

        return securedValue;
    }

    const handleSelect = async (selectedAddress, placeId) => {

        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);

        // Get formatted address using the place ID
        const placeResults = await geocodeByPlaceId(placeId);
        const formattedAddress = placeResults[0].formatted_address;
        const addressComponents = placeResults[0].address_components;
        var selectedAddress = parseGoogleLocationResponse(addressComponents);
        const streetNumber = selectedAddress.streetNumber != null ? selectedAddress.streetNumber : ''
        const route = selectedAddress.route != null ? selectedAddress.route : ''
        var object = {
            "line1": streetNumber + " " + route,
            "line2": selectedAddressLine2,
            "city": selectedAddress.locality != null ? selectedAddress.locality : '',
            "state": selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : '',
            "zipCode": selectedAddress.postalCode != null ? selectedAddress.postalCode : '',
            "countryCode": selectedAddress.countryCode != null ? selectedAddress.countryCode : '',
            "country": selectedAddress.country != null ? selectedAddress.country : '',
            "cardKey": selectedItem?.CardKey
        }
        setHomeAddressToserver(object);

        const addressParts = [
            object.line1,
            object.line2,
            object.city,
            object.state,
            object.zipCode,
            object.countryCode,
            object.country
        ];

        const homeAddress = addressParts.filter(part => part).join(', ');
        setAddress(formattedAddress);
        setTypedValue(homeAddress);
        setLine1AdressFilled(true);

        setPredictions([]);

    };

    const heading = () => {
        return (
            <>
                <div className='lg:w-[260px] md:w-[183px] sm:w-[164px] w-full sm:mx-0 ml-[2.5rem] sm:pb-0 pb-8'>
                    <p className="text-white font-normallg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] 
                    md:leading-[33px] sm:leading-[30px] leading-[31px]
                  font-serif-pro ">{(t('add_one_auth_user_title'))}</p>
                </div>
            </>
        )
    }
    return (
        <>
            <HomeLayout>

                <LeftBar leftBarType='text' isPrimary={false} heading={heading()} />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>

                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        footer={false}
                        CardRadius={true}
                        HeaderRadius={true}
                        isWhite={true}
                        cardWidth={width.homeCard}>

                        <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                       lg:text-[26px] md:text-[22px] 
                       sm:text-[20px] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">
                            {t('add_auth_user')}</div>

                        <div className='border-t-[2.5px] border-[#A3CEBB] flex justify-center sm:mx-0 mx-4'></div>

                        <div className='justify-center sm:items-center flex flex-col mt-10 pb-20 sm:mx-0 mx-4 '>
                            {/* <div className='sm:w-full'>
                                <div className='font-serif-pro 
                        lg:text-[27px] md:text-[24px]
                        sm:text-[20px] text-[24px]
                         md:leading-[38px] sm:leading-[24px] leading-[31px] 
                         font-normal text-[#2965FB]'>
                                   
                                    <p className='text-black'>{t("auth_user_submit_title")}</p>
                                    
                                </div>
                            </div> */}
                            <div className='font-hanken-grotesk font-[500] lg:text-[16px] md:text-[15px] sm:text-[14px] text-[15px] 
                            leading-[24px] flex'>
                                <p className='lg:w-[90%] sm:w-[84%] w-full'>{t("auth_user_submit_title")}</p>
                            </div>



                            <div className='md:w-[85%] sm:w-[87%] w-full md:pt-4 pt-5'>


                                {/*  Name section*/}

                                {!editNameClicked &&
                                    <div className='md:mt-16 mt-4'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[12px] 
                                        sm:text-[11px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("full_name")}</p>

                                        <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[14px]
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                            {benficaryDetailObject.firstName + " " + benficaryDetailObject.lastName}</p>

                                        <div className="bg-[#94C3E8] h-0.5 min-h-1 w-full"></div>

                                        <button
                                            onClick={() => { editNameButtonClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    editNameButtonClicked()
                                                }
                                            }}>{<p onClick={() => { editNameButtonClicked() }}
                                                className='pt-5 font-hanken-grotesk text-[#2965FB] md:text-[16px] sm:text-[13px] text-[13px] 
                                                   font-[700]'>{t("edit_name")}</p>}</button>
                                    </div>
                                }

                                {/*  Name update section*/}
                                {editNameClicked &&
                                    <div className='md:mt-16 mt-12'>
                                        <div className='w-full'>
                                            <CustomTextInput
                                                maxLength={35}
                                                autoFocus={true}
                                                name={t("first_name_placeholder")}
                                                onChange={(event) => {
                                                    setinfoMessage('');
                                                    setSelectedFirstName(event.target.value)
                                                }}
                                                value={selectedFirstName} style={{ width: '100%', height: '51px' }} />
                                            <CustomTextInput
                                                maxLength={35}
                                                autoFocus={true}
                                                name={t("last_name_placeholder")}
                                                onChange={(event) => {
                                                    setinfoMessage('');
                                                    setSelectedLastName(event.target.value)
                                                }}
                                                value={selectedLastName} style={{ width: '100%', height: '51px' }} />
                                        </div>

                                        {(editNameClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displaySuccessMessage(infoMessage)}
                                            </div>
                                        }
                                        {(editNameClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        }
                                        <button
                                            onClick={() => { updateNameButtonClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    updateNameButtonClicked()
                                                }
                                            }}
                                            className='font-hanken-grotesk text-[#2965FB]
                                            md:text-[16px] sm:text-[13px] text-[13px]  font-[700]'>{t("save")}</button>
                                    </div>
                                }
                                {/*  DOB section*/}

                                {!editDobClickd &&
                                    <div className='md:mt-16 mt-12'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[12px] 
                                        sm:text-[11px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("dob_title")}</p>

                                        <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[14px]
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                            {benficaryDetailObject.birthDay}</p>

                                        <div className="bg-[#94C3E8] h-0.5 min-h-1 w-full"></div>

                                        <button
                                            onClick={() => { editDobButtonClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    editDobButtonClicked()
                                                }
                                            }}>{<p onClick={() => { editDobButtonClicked() }}
                                                className='pt-5 font-hanken-grotesk text-[#2965FB] md:text-[16px] sm:text-[13px] text-[13px] 
                                                   font-[700]'>{t("edit_dob")}</p>}</button>
                                    </div>
                                }

                                {/*  Dob update section*/}
                                {editDobClickd &&
                                    <div className='md:mt-16 mt-12'>
                                        <div className='mb-2' style={{ zIndex: 9999 }}>
                                            <DatePicker
                                                selected={formValues.dob}
                                                onChange={handleDateChange}
                                                // dateFormat="yyyy-MM-dd"
                                                dateFormat="dd/MM/yyyy"
                                                name="dob" // Pass the `name` prop directly
                                                label="Date of birth"
                                                className='font-hanken-grotesk 
                                                    text-secondary-2 px-4 py-2
                                                    bg-text-btn placeholder-kudzu-blue 
                                                    text-sm font-medium focus:outline-none w-full h-[60px]'
                                                showMonthDropdown
                                                showYearDropdown
                                                placeholderText='Date of birth'
                                                autoComplete='off'
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown
                                                maxDate={new Date(currentYear, 11, 31)}
                                            />
                                            <div className='mb-4 mt-4'>
                                                {(editDobClickd && infoMessage) && (
                                                    displayErrorMessage(infoMessage)
                                                )}
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => { updateDobButtonClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    updateDobButtonClicked()
                                                }
                                            }}
                                            className='font-hanken-grotesk text-[#2965FB]
                                            md:text-[16px] sm:text-[13px] text-[13px]  font-[700]'>{t("save")}</button>
                                    </div>}


                                {/*  ssn non edit section */}
                                {(!editSSNClicked && isEditBenificirey) && <div className='md:mt-16 mt-12'>
                                    <p className='font-hanken-grotesk text-[#262B3A] uppercase 
                                     lg:text-[13px] md:text-[12px] sm:text-[11px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'> {t('ssn')}</p>

                                    <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[16px] 
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                        {/* {benficaryDetailObject.ssn} */}
                                        {'*'.repeat(3)}-{'*'.repeat(2)}-{benficaryDetailObject.ssn.replace(/-/g, '').substring(5)}
                                    </p>

                                    <div className="bg-[#94C3E8] h-0.5 min-h-1 w-full"></div>

                                    <p className="font-hanken-grotesk md:text-[16px] mt-5 text-[#262B3A] sm:text-[14px] text-[16px] 
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                        {t('ssn_cant_modified')}</p>

                                </div>}

                                {/*  ssn eidt  section*/}

                                {(!editSSNClicked && !isEditBenificirey) &&
                                    <div className='md:mt-16 mt-12'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[12px] 
                                        sm:text-[11px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("ssn")}</p>

                                        <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[14px]
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                            {'*'.repeat(3)}-{'*'.repeat(2)}-{benficaryDetailObject.ssn.replace(/-/g, '').substring(5)}</p>

                                        <div className="bg-[#94C3E8] h-0.5 min-h-1 w-full"></div>

                                        <button
                                            onClick={() => { editSSNButtonClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    editSSNButtonClicked()
                                                }
                                            }}>{<p onClick={() => { editSSNButtonClicked() }}
                                                className='pt-5 font-hanken-grotesk text-[#2965FB] md:text-[16px] sm:text-[13px] text-[13px] 
                                                   font-[700]'>{t("edit_ssn")}</p>}</button>
                                    </div>
                                }

                                {/*  ssn update section*/}
                                {(editSSNClicked && !isEditBenificirey) &&
                                    <div className='md:mt-16 mt-12'>
                                        <div className='w-full'

                                            onFocus={() => { toggleCursor() }}
                                            onBlur={() => { toggleCursor() }}
                                        >
                                            {/* <CustomInput
                                                name="ssn_no"
                                                // label="Social Security Number (SSN)"
                            
                                                label={selectedSSN}
                                                register={register}
                                                error={errors.ssn_no && errors.ssn_no.message}
                                                type="ssn_no"
                                                onChange={handleSSNChange}
                                            /> */}
                                            <div className="flex items-center relative">
                                                <span
                                                    type="button"
                                                    className="text-gray-500 absolute right-4 top-5"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    {showPassword ? <img src={Showpassword} className='w-[20px] h-[20px] cursor-pointer' /> : <img src={Hidepassword} className='w-[20px] h-[20px] cursor-pointer' />}
                                                </span>
                                                <input
                                                    ref={ssnRef}
                                                    id={'ssn_no'}
                                                    {...register('ssn_no')}
                                                    value={selectedSSN}
                                                    placeholder={'Social Security Number (SSN)'}
                                                    className={`${InputStyles} ${showPassword ? 'password-input' : ''}`}
                                                    autoComplete="off"
                                                    onChange={handleSSNChange}
                                                    // maxLength={15}
                                                    maxLength={9}
                                                    style={{ color: 'transparent' }}
                                                >
                                                </input>
                                            </div>
                                            <div className='flex z-[999] opacity-100 relative top-[-51px] h-[60px] w-[80%] bg-transparent items-center'
                                                style={{ position: 'relative', top: -48 }}
                                                onClick={() => {
                                                    const input = document.getElementById("ssn_no");
                                                    input.focus()
                                                }}>
                                                <p className='h-[50px] no-underline ml-[15px] top-[10] text-[#2965FB]' >{ssn}{isCursorVisible && <span style={{ display: 'inline-block', animation: isCursorVisible ? 'blink 0.8s step-end infinite' : 'none', verticalAlign: 'text-bottom', color: '#2965FB', fontWeight: '200', fontSize: '15px' }}>|</span>}</p>
                                            </div>
                                            <style>{`@keyframes blink {0%, 100% {opacity: 1;}50% {opacity: 0;}}`}</style>


                                            {(editSSNClicked && !isEditBenificirey && infoMessage) &&
                                                <div className='mb-2'>
                                                    {displayErrorMessage(infoMessage)}
                                                </div>
                                            }
                                            <button
                                                onClick={() => { updateSSNButtonClicked() }}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Enter") {
                                                        event.preventDefault();
                                                        updateSSNButtonClicked()
                                                    }
                                                }}
                                                className='font-hanken-grotesk text-[#2965FB]
                                            md:text-[16px] sm:text-[13px] text-[13px]  font-[700]'>{t("save")}</button>
                                        </div>
                                    </div>}

                                {/*  address section */}
                                {!addressEditClicked &&
                                    <div className='md:mt-16 mt-12'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase 
                                          lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]  font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("personal_address")}</p>

                                        <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[16px] 
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                            {benficaryDetailObject.formattedAddress}</p>

                                        <div className="bg-[#94C3E8] h-0.5 min-h-1 w-full"></div>

                                        <button onClick={() => { editAddressClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    editAddressClicked()
                                                }
                                            }}
                                        >{<p onClick={() => { editAddressClicked() }} className='pt-5 
                                        font-hanken-grotesk text-[#2965FB] md:text-[16px]  sm:text-[12px] text-[14px]
                                         font-[700]'>{t("edit_address")}
                                        </p>}
                                        </button>
                                    </div>
                                }

                                {/*  address  update section */}
                                {addressEditClicked &&
                                    <div className='md:mt-16 mt-12'>
                                        <PlacesAutocomplete
                                            value={isAddress}
                                            onChange={setAddress}
                                            onSelect={handleSelect}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input {...getInputProps({ placeholder: 'Address' })} className='font-hanken-grotesk text-secondary-2 px-4 py-2 bg-text-btn placeholder-kudzu-blue text-sm font-medium focus:outline-none w-full h-[60px]' />
                                                    <div className='mb-4'>
                                                        {loading ? <div>Loading...</div> : null}
                                                        {suggestions.map((suggestion) => {
                                                            const style = {
                                                                backgroundColor: suggestion.active ? '#ffffff' : '#F5F8F9',
                                                                cursor: 'pointer',
                                                                padding: '8px',
                                                                fontSize: '16px',
                                                                fontWeight: '500',
                                                                lineHeight: '26px',
                                                            };
                                                            return (
                                                                <div
                                                                    key={suggestion.placeId}
                                                                    {...getSuggestionItemProps(suggestion, { style })}
                                                                >
                                                                    {suggestion.description}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {line1AdressFilled && <CustomTextInput
                                            name={t("apt_suite_etc")}
                                            onChange={(event) => {
                                                let tempHomeaddresToServer = homeAddressToserver;
                                                tempHomeaddresToServer.line2 = event.target.value;
                                                setHomeAddressToserver(tempHomeaddresToServer);
                                                setSelectedAddressLine2(event.target.value)
                                            }}
                                            value={selectedAddressLine2} style={{ width: '100%', height: '51px' }} />}
                                        {AddressError && (
                                            displayErrorMessage(AddressError)
                                        )}
                                        {predictions.length > 0 && (
                                            <ul className='bg-[aliceblue] max-h-60 overflow-y-auto p-2'>
                                                {predictions.map((prediction) => (
                                                    <li
                                                        key={prediction.place_id}
                                                        className='font-hanken-grotesk text-sm font-medium leading-6 p-2 cursor-pointer'
                                                        value={prediction.place_id}
                                                    >{prediction.description}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        {(addressEditClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displaySuccessMessage(infoMessage)}
                                            </div>
                                        }
                                        {(addressEditClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        }
                                        {/* {(infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displaySuccessMessage(infoMessage)}
                                            </div>
                                        }
                                        {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        } */}
                                        <button
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    updateHomeAddress()
                                                }
                                            }}
                                            onClick={() => { updateHomeAddress() }}
                                            className='font-hanken-grotesk text-[#2965FB]
                                         md:text-[16px] text-[12px] font-[700]'>{t("save")}</button>
                                    </div>
                                }

                                {!phoneNumberEditClicked &&
                                    <div className='md:mt-16 mt-12'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase  
                                          lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4
                                opacity-[50%]'>{t("phone_number")}</p>

                                        <p className="font-hanken-grotesk  lg:text-[16px] md:text-[15px] sm:text-[13px] 
                                    text-[14px] font-medium leading-[26px] md:mb-6 mb-2">
                                            {benficaryDetailObject.phoneNumber}</p>
                                        <div className="bg-[#94C3E8] h-0.5 min-h-1 w-full"></div>

                                        <button onClick={() => { editPhoneNumberClicked() }}>{<p onClick={() => { editPhoneNumberClicked() }}
                                            className='pt-5 font-hanken-grotesk text-[#2965FB]
                                            lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[13px]
                                                font-[700]'>{t("edit_phone_number")}</p>}
                                        </button>
                                    </div>
                                }

                                {/*  phoneNumber update section */}
                                {phoneNumberEditClicked &&
                                    <div className='md:mt-16 mt-12'>
                                        <div className='w-full'>
                                            <CustomTextInput
                                                maxLength={10}
                                                autoFocus={true}
                                                name={t("phone_number")}
                                                onChange={(event) => {
                                                    setinfoMessage('');
                                                    setSelectedPohoneNumber(event.target.value)
                                                }}
                                                value={selectedPohoneNumber.replace(/-/g, '')} style={{ width: '100%', height: '51px' }} />


                                            {(phoneNumberEditClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                                <div className='mb-2'>
                                                    {displaySuccessMessage(infoMessage)}
                                                </div>
                                            }
                                            {(phoneNumberEditClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                                <div className='mb-2'>
                                                    {displayErrorMessage(infoMessage)}
                                                </div>
                                            }
                                            <button onClick={() => { updatePhoneNumber() }}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Enter") {
                                                        event.preventDefault();
                                                        updatePhoneNumber();
                                                    }
                                                }}
                                                className='font-hanken-grotesk text-[#2965FB]
                                                lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[13px]
                                        font-[700]'>{t("save")}</button>
                                        </div>
                                    </div>
                                }


                                {/* email address section */}
                                {!emailAdressEditClicked &&
                                    <div className='md:mt-16 mt-12'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[12px] 
                                        sm:text-[12px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("personal_email_address")}</p>

                                        <p className="font-hanken-grotesk lg:text-[16px] md:text-[15px] sm:text-[13px] 
                                    text-[14px] font-medium leading-[26px] md:mb-6 mb-2">
                                            {benficaryDetailObject.emailAddress}</p>

                                        <div className="bg-[#94C3E8] h-0.5 min-h-1 w-full"></div>

                                        <button
                                            onClick={() => { editEmailClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    editEmailClicked()
                                                }
                                            }}>{<p onClick={() => { editEmailClicked() }}
                                                className='pt-5 font-hanken-grotesk text-[#2965FB] 
                                                lg:text-[16px] md:text-[15px] 
                                                sm:text-[13px] text-[13px]
                                                   font-[700]'>{t("edit_email_address")}</p>}</button>
                                    </div>
                                }

                                {/* email address  update section */}
                                {emailAdressEditClicked &&
                                    <div className='md:mt-16 mt-12'>
                                        <div className='w-full'>
                                            <CustomTextInput
                                                maxLength={35}
                                                autoFocus={true}
                                                name={t("personal_email_address")}
                                                onChange={(event) => {
                                                    setinfoMessage('');
                                                    setSelectedEmailAdress(event.target.value)
                                                }}
                                                value={selectedEmailAdress} style={{ width: '100%', height: '51px' }} />
                                        </div>

                                        {(emailAdressEditClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displaySuccessMessage(infoMessage)}
                                            </div>
                                        }
                                        {(emailAdressEditClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        }
                                        <button
                                            onClick={() => { updateEmailAdressClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    updateEmailAdressClicked()
                                                }
                                            }}
                                            className='font-hanken-grotesk text-[#2965FB]
                                            lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[13px]
                                             font-[700]'>{t("save")}</button>
                                    </div>
                                }
                                {/* removeBenficiery */}
                                {isEditBenificirey && <button onClick={() => { setIsremoveAcceptClicked(true) }} className='pt-5 
                                        font-hanken-grotesk text-[#2965FB] md:text-[16px]  sm:text-[12px] text-[14px]
                                         font-[700]'>{t("remove_beneficiary")}
                                </button>}


                                {/* {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                    <div className='mb-2'>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                } */}
                            </div>
                        </div>


                        {(infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                            <div className='mb-2'>
                                {displaySuccessMessage(infoMessage)}
                            </div>
                        }
                        {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                            <div className='mb-2'>
                                {displayErrorMessage(infoMessage)}
                            </div>
                        }

                        {/* save and back section */}

                        <div className='flex flex-row sm:justify-end justify-between lg:mt-20 md:mt-16 sm:mt-10 mt-10 sm:mx-0 mx-4 sm:mb-20 mb-10'>
                            <button
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        handleRevertClick()
                                    }
                                }}
                                onClick={handleRevertClick} className='font-hanken-grotesk 
                                    text-[#2965FB] font-[700] md:text-[18px] sm:text-[13px] text-[16px]'>{t("personal_back")}</button>
                            {(isEditBenificirey && (nameChanged || dobChanged || addressChanged || ssnChanged)) && <button
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        handleSaveClick()
                                    }
                                }}
                                onClick={handleSaveClick} className='font-hanken-grotesk text-[#2965FB] font-[700] md:text-[18px] sm:text-[13px] text-[16px] sm:ml-10'>{t("personal_save")}
                            </button>}
                            {(isEditBenificirey && (!nameChanged && !dobChanged && !addressChanged && !ssnChanged)) && <button
                                className='font-hanken-grotesk text-[#94C3E8] font-[700] md:text-[18px] sm:text-[13px] text-[16px] sm:ml-10'>{t("personal_save")}
                            </button>}



                            {!isEditBenificirey && <button
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        handleSaveClick()
                                    }
                                }}
                                onClick={handleSaveClick} className='font-hanken-grotesk text-[#2965FB] font-[700] md:text-[18px] sm:text-[13px] text-[16px] sm:ml-10'>{t("add_auth_user")}
                            </button>}

                        </div>
                    </DynamicCard>
                </div>
            </HomeLayout>

            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
            />
            {isLoading && <Loader />}
        </>


    )
}

export default EditAuthorizedUser;
