import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import check_img from '../../../assets/images/check_img.png';
import error_img from '../../../assets/images/error_png.png';
import { displayErrorMessage } from '../../../utils';

const CustomTextInput = (props) => {

    const { t } = useTranslation(); // Hook to access translation functions

    return (
        <div className='w-full'>
            <p className='font-hanken-grotesk text-[#262B3A] 
 uppercase  lg:text-[13px] md:text-[12px] 
 sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-6 pb-5
 opacity-[50%] '> {props?.name?.toLocaleUpperCase() || ''}</p>
            <div className='flex flex-row w-full items-center'>
                <input
                    onKeyDown={(e) => { props?.onKeyDown?.(e) }}
                    className={`h-[60px] placeholder:font-semibold font-hanken-grotesk text-16 md:font-[500] sm:font-[700] font-[500]
                     ${props?.inputWidth ? props?.inputWidth : 'md:w-[288px] sm:w-[200px] w-full'} 
                      pl-8 `}
                    type={props?.type || 'text'}
                    placeholder={props?.placeholder || ''}
                    value={props?.value || ''}
                    maxLength={props?.maxLength || undefined}
                    autoFocus={props?.autoFocus || false}
                    onChange={(e) => { props?.onChange?.(e) }}
                    onBlur={(e) => { props?.onBlur?.(e) }}
                    autoComplete="off"
                    aria-autocomplete="none"
                    style={{
                        color: '#2965FB',
                        backgroundColor: 'rgba(234, 240, 243)',
                        border: 'none',
                        outline: 'none',
                        // fontWeight: '500',
                        '::placeholder': {
                            color: '#2965FB', // Placeholder color         
                        },

                        ...props.style, // Allow additional custom styles from parent component
                    }}
                    readOnly={props?.readOnly || false}
                />
                {props?.showValidity &&
                    <div>
                        {(props?.showValidity && props?.valid) &&
                            <img
                                src={check_img}
                                // alt="Image"
                                className="w-4 h-4 mr-2 ml-5"
                            />
                        }

                        {(props?.showValidity && !(props?.valid)) &&
                            <img
                                src={error_img}
                                // alt="Image"
                                className="w-4 h-4 mr-2 ml-5"
                            />
                        }
                    </div>
                }
            </div>
            <div className='my-2 w-full'>
                {props?.error && displayErrorMessage(props?.error)}
            </div>

        </div>

    );
};

export default CustomTextInput;