import React, { useState, useEffect } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { width } from "../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../CustomComponents/Loader';
import { UPDATE_CARDNAME, STATUS_SUCCESS_CODE, CAN_CREATE_SAVINGS_ACC, STUDENT_SIGNUP_EMAIL_ACCOUNT } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { addLineBreaks, displayErrorMessage, displaySuccessMessage, isEmailValid, MoreServices } from '../../../utils';
import { checkEmailExtension, fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import LineBreaks from '../../../utils';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import Check from '../../../assets/images/Check_white.png'
import beneficiarysaving from '../../../assets/images/beneficiarysaving.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomInput from '../../CustomComponents/FormInputs';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';


const appConfigData = await fetchConfigarationData();
const AccessCode = ({ transactionDatas, onViewAllClick, viewAll }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook to access translation functions
    const cards = useSelector(state => JSON.parse(state.userCards));
    const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [isLoading, setIsLoading] = useState(false);

    const selectedItem = cards?.[0]
    const [newName, setNewName] = useState((selectedItem != undefined && selectedItem != null) ? selectedItem.NickName : "");

    const [loading, setLoading] = useState(false)
    const [infoMessage, setinfoMessage] = useState('');
    const [responseCode, setResponseCode] = useState('');
    const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [showModel, setShowModel] = useState(false);
    const [modelImage, setModelImage] = useState('')
    const [savingsTheme, setSavingsTheme] = useState(false);
    const [isBorderNone, setisBorderNone] = useState(true);
    const [isSubFooter, setIsSubFooter] = useState(false);
    const [isSubComponent, setIsSubComponent] = useState(false);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [textMessage, setTextMessage] = useState(false);
    const [btn1Focus, setBtn1Focus] = useState(false);
    const [btn2Focus, setBtn2Focus] = useState(false);

    //email state value
    const [validEmail, setValidEmail] = useState(false);
  const [email, setEmail] = useState();
  const [message, setMessage] = useState('');



    const [studentEmailScreen , setStudentEmailScreen] = useState(true);
    const [addAccessCodeScreen , setAddAccessCodeScreen] = useState(true);
    const[schoolNameScreen,setSchoolNameScreen] = useState();
    const [codeAcceptedScreen , setCodeAcceptedScreen] = useState(false);

    
    const showCustomAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        // setShowAlertModal(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage) 
      }
    const { watch } = useForm();

    const moreServicesArray = [
        { name: 'Configure Notifications', actionType: '', path: '/Settings/notificationConfigure' },
        { name: 'Manage External Accounts', actionType: '', path: '/Settings/ManageExternalAccounts' }
    ];
    const subObjectsArray = cards?.map(card => {
        return {
            AccountTypeName: card.AccountTypeName,
            CardKey: card.CardKey,
            OpenDate: card.ApplicationDate
        };
    });
    if (savingsCardAvailable == false) {
        const staticObject = {
            AccountTypeName: t('menu_savings'),
            CardKey: '',
            OpenDate: 'empty'
        };
        // subObjectsArray?.push(staticObject);
        subObjectsArray?.splice(1, 0, staticObject);
    }



    const canCreateSavingsAccount = async () => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setIsLoading(true)

        let finalUrl = CAN_CREATE_SAVINGS_ACC(cards[0].CardKey)
        var response = await GetRequest(finalUrl, headers)
        var responseObj = JSON.parse(response)
        setIsLoading(false)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            const canCreateNew = responseObj.Response.canCreateNew
            const eligibleDate = moment(responseObj.Response.eligibleDate).format('MM/DD/YYYY')
            if (canCreateNew) {
                enrollSavingsAccount()

            } else {
                showAlertModal(true, t("oh_no"), t('unable_to_open'), `${t('u_will_be_eligible')} ${eligibleDate}. `, true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false, true)
            }
        } else {
            setTimeout(() => {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oh_no"), displayText, '', true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false)

                // setError(displayText)
            }, 500);
        }
    }

    const enrollSavingsAccount = () => {
        const savingsNewPayload = { savingsNewClicked: true };
        dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
        showAlertModal(true, '', t('set_aside_money_for_the_things_that_matter'), t('add_savings_account_subTitle'), true, true, t('enroll'), t('may_be_later'), true, beneficiarysaving, true, true);
    }


    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, textMessage, isSubComponent, btn1Focus, btn2Focus) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
        setTextMessage(textMessage)
        setIsSubComponent(isSubComponent)
        setBtn1Focus(btn1Focus)
        setBtn2Focus(btn2Focus)
    }

    const schema = yup.object().shape({
        currentPassword: yup.string()
            .required("Current password is required"),
        newPassword: yup.string()
            .required("New password is required")
            // .matches(
            //     regexpression, "The password you entered does not meet the requirements as listed below."
            // )
            .notOneOf([yup.ref('oldPassword'), null], "New password must be different from the current password"),
        repeatPassword: yup.string()
            .required("Confirm new password is required")
            .oneOf([yup.ref('newPassword'), null], t("repeat_password_error_msg")),
    });

    const {
        register,
        handleSubmit,
        formState: { errors }, control, clearErrors,
        getValues,
        setFocus,
        setError
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        // defaultValues: { newPassword: "newPassword@12" }
    });

    const closeModel = () => {
        setShowModel(false);
        
    }

    useEffect(() => {
        // error popup
        showAlertModal(true, t("oh_no"), t("nolongerValid"),
            t('customerContact'),
            true, false, t('home'), t("cancel"), true,
            require('../../../assets/images/spending-alert-error.png'), '', false, true, '', false, false, true);

        // success popup
        // showAlertModal(true, t("success_exclaim"), t("accessCodeapplied"),
        //     "",
        //     true, false, t('home'), t("cancel"), true,
        //     require('../../../assets/images/illo-complete-success.png'), '', false, true, '', false, false, true);
    }, [])

    const handleAddAccessCode = () =>{
        setAddAccessCodeScreen(false)
        setStudentEmailScreen(true)
    }

    const handleEmailChange = async (event) => {
        setMessage('');
        clearErrors(); 
        const { name, value } = event.target;
        setEmail(value);
        try {
          await schema.validate({ [name]: value });
          setValidEmail(true);
          setEmail(value);
        } catch (error) {
          setValidEmail(false);
        } 
      };
      const GenerateEmailOtp = () => {
        setMessage("")
        clearErrors()
        const errorMessage = isEmailValid(email)
        if (errorMessage == 'empty') {
          setError('email', {
            type: 'manual',
            message: t('please _enter _student_email_address'),
          });
          // setMessage(t('please_enter_your_email_address'))
        } else if (errorMessage == 'not valid') {
          setError('email', {
            type: 'manual',
            message: t('please _enter _student_email_address'),
          });
          // setMessage(t('please_enter_a_valid_email_address'))
        } else if(errorMessage != 'empty' && errorMessage != 'not valid' ) {
          const isValidExtension = checkEmailExtension(email);
          if(isValidExtension){ 
            // createAccountWithStudentEmail(email); 
          }else{
            setMessage(t('please_enter_student_email_address'));
            setValidEmail(false);
          }
        }  
      };
      async function createAccountWithStudentEmail(email) {
        debugger;
        const deviceId = await retrieveData('updatedDeviceId')
        let headers = await GetApiHeadersWithOutBearer(deviceId);
        setIsLoading(true)
        var requestObject = {
          "applicationId": appConfigData.CLIENT_ID,
          "sEmail": email,
          "language": "en",
          "isLoginid": true
        }
        // if (userEmail) {
        if ("") {
          requestObject.isLoginid = false;
        }
        debugger
        var response = await PostRequest(STUDENT_SIGNUP_EMAIL_ACCOUNT(""), requestObject, headers);
        let responseObj = JSON.parse(response);
        debugger
        if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) 
        {     
          debugger; 
            const isPartnerUniversityStatus=responseObj.Response.isPartnerUniversity;
            const sessionIdPayload = { sessionId: responseObj.Response.sessionId }
            dispatch({ type: 'SESSION_ID', payload: sessionIdPayload }); 
            setIsLoading(false); 
            if(isPartnerUniversityStatus){
            //   openAccessCodewithemail();
            }else{
            //   openUniversitySelection();
            } 
        
          } else {
            setIsLoading(false);
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showCustomAlertModal(true, "Alert", displayText, "", true, true, t('login'), t('try_again'), true, '') 
          }  
      }
    

    return (
        <>
            <HomeLayout>
                <HomeHeader heading={LineBreaks(t("Add_a_promo_code"))} isHeaderCaption={true} />
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        footer={true}
                        CardRadius={true}
                        HeaderRadius={true}
                        isWhite={true}
                        cardWidth={width.homeCard}
                        footerBackgroundColor={codeAcceptedScreen ? '#30BC89' :""}
                        footerContent={
                            codeAcceptedScreen ?
                            <>
                                <div className="flex justify-between flex-nowrap h-full items-center w-[85%] m-auto">
                                    <div className="flex items-center">
                                        <span className='mr-2 ml-2'><img src={Check} size={20} alt="Check" className='text-white' /></span>
                                        <p className="font-hanken-grotesk sm:font-bold font-medium lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white cursor-pointer flex items-center"
                                        >{t('CodeAccepted')}</p>
                                    </div>
                                    <button
                                        type="button"
                                        className="font-hanken-grotesk sm:font-bold font-medium lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white cursor-pointer flex items-center"
                                        // onClick={handleOpenCreatePassword}
                                        onKeyDown={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                    >
                                        {t('continue')}
                                    </button>
                                </div>

                            </>
                             :
                             studentEmailScreen
                             ?
                             <>
                              {/* <div className='w-full flex sm:justify-end justify-between
                             md:h-[160px] sm:h-[120px] h-[140px] items-center md:gap-20 sm:gap-12 sm:px-0 px-4'>
                            <button className='lg:text-[18px] md:text-[17px] 
                                sm:text-[15px] text-[16px] font-[700]
                                 font-hanken-grotesk
                                 text-[#2965FB]' onClick={() => { navigate('/Climb/ClimbMenu') }}>
                            {t('Back')}</button>

                           <button className='lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] font-[700]
                                 font-hanken-grotesk
                                 text-[#2965FB] opacity-50'> {t('continue')} </button>
                           </div>  */}
                           <div className="flex justify-end items-center mt-12 mr-[100px]">
                <button
                  type="button"
                  className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center mr-10"
                  onClick={""}
                  onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); } }}
                // Call handleContinue instead of openPhoneOTPModal directly
                >
                  {t('back')} 
                </button>
                <button
                  type="button"
                  className={`font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]  flex items-center
                  ${validEmail ? 'text-[#2965FB] cursor-pointer' : 'text-[#94C3E8] cursor-none'}`} 
                  onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault();}}} 
                  onClick={""}
                  >
                  {t('continue')} 
                </button>
              </div> 
                             </>
                             :
                            <>
                          <div className='flex flex-col justify-center items-center md:py-20 sm:py-16 py-20 gap-8 sm:px-0 px-4'>
                            <button onClick={handleAddAccessCode}
                                className={`lg:w-[80%] sm:w-[84%] w-full h-[60px] border-[3px] 
                                border-border-submit font-hanken-grotesk text-secondary-2
                                 font-bold text-sm leading-4`}>
                                {t("addaccessCode")}
                            </button>
                        </div>

                            </>
                        } >

                        <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                    lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px]
                     leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
                            {t('connect_school')}
                        </div>

                        <div className='border-t-[2.5px] border-[#A3CEBB]
                     flex justify-center flex-col sm:mx-0 mx-4'></div>

 

                        {/* ---------1st card design------------*/}

                        

                        {/* ---------2nd card design------------*/}
                        {
                            studentEmailScreen?
                    <>
                         <div className='lg:w-[90%] md:w-[94%] w-full font-serif-pro lg:text-[27px] md:text-[24px]
                sm:text-[20px] text-[24px] md:leading-[38px] sm:leading-[24px] leading-[31px] 
                    font-normal text-[#2965FB] mt-5'>
                            <p className='sm:mx-0 mx-4 '>{t("student_selction_heading1")} <span className='text-black'>
                                {(t("enter_mail"))}</span></p>
                        </div>

                        <span className="font-hanken-grotesk text-[#262B3A]
               lg:text-[16px] md:text-[16px] py-4 inline-block sm:text-[14px] text-[16px]
                  font-medium  leading-6 sm:mx-0  mx-4 ">
                            {addLineBreaks(t("associate_school"))}</span>
                            </>
                            :
                            "" 
                        }

                         <form>
                            <div className='w-full flex justify-center lg:py-20 md:py-14 sm:pt-10 sm:pb-0 py-4'>
                                <div className='sm:w-[62%] w-full sm:mx-0 mx-4'>
                                    <div className='lg:w-[384px] md:w-[340px] sm:w-[224px]'>
                                        <div className='md:w-[282px] sm:w-[198px] flex flex-col gap-4 mt-4'>
                                            {studentEmailScreen ? 
                                            <CustomInput
                                            name="student_email_address"
                                            label={t("student_email_address")}
                                            register={register}
                                            error={errors.email}
                                            type="text"
                                            onChange={handleEmailChange}
                                            textInputValue={email}
                                            isValid={validEmail}
                                             autoFocus={true}
                                            onKeyDownFun={(event) => { if (event.key == "Enter") { event.preventDefault(); } }}
                                            /> 
                                           
                                         : 
                                            ""

                                            // <CustomInput
                                            //     name="AccessCode"
                                            //     label={t("AccessCode")}
                                            //     register={register}
                                            //     // error={errors.currentPassword}
                                            //     type="text"
                                            //     // onChange={(e) => {
                                            //     //     handleOldPasswordChange(e);
                                            //     //     clearErrors("oldPassword")
                                            //     // }}
                                            //     // isErrorShow={true}
                                            //     autoFocus={true}
                                            //     // isPasswordShow={!!oldPassword} // Set to true if there's a value
                                            //     // showPasswordToggle={!!oldPassword}
                                            //     // isValid={oldPassword ? isOldPasswordValid : undefined}
                                            //     maxLength={20}
                                            // /> 
                                            } 
                                    {message &&
                                        <div className='mb-2'>
                                        {displayErrorMessage(message)}
                                        </div> 
                                    }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form> 

                        

                        {/* <div className="md:h-[100px] sm:h-[60px] h-[100px] w-full flex flex-row justify-between lg:mt-32 md:mt-20 mt-20">
                            <div>
                                <button
                                    type="button"
                                    className="sm:block hidden font-hanken-grotesk sm:font-bold font-[600]
                                 lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]"
                                >
                                    {t('didnotreceivemail')} <button onClick={() => { navigate('/Help/FAQ') }}
                                        className="text-[#2965FB]">{t('tryagain')}</button>
                                </button>

                                <button
                                    type="button"
                                    className="sm:hidden block font-hanken-grotesk sm:font-bold font-[600]
                                 lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]"
                                >  <button onClick={() => { navigate('/Help/FAQ') }}
                                    className="text-[#2965FB]">{t('tryagain')}</button>
                                </button>
                            </div>

                            <div className='flex items-baseline md:gap-20 sm:gap-12 gap-4 sm:px-0 px-4'>
                                <button className='lg:text-[18px] md:text-[17px] 
                                sm:text-[15px] text-[16px] font-[700]
                                 font-hanken-grotesk
                                 text-[#2965FB]' onClick={() => { navigate('/Climb/ClimbMenu') }}>
                                    {t('Back')}</button>

                                <button className='lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] font-[700]
                                 font-hanken-grotesk
                                 text-[#2965FB] opacity-50'> {t('continue')} </button>
                            </div>

                        </div> */}

                        {loading && <Loader />}
                    </DynamicCard>
                </div>

                {isLoading && <Loader />}

            </HomeLayout>
            {showModel && (
                <CustomAlertModel
                    isOpen={showModel}
                    onClose={closeModel}
                    Fullheight={true}
                    image={modelImage}
                    highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                        <span className={`${HeaderMsgStyles} text-black`}> {headerMsg}</span></p>}
                    subHeaderMsg={
                        <>
                            {subHeaderMsg && (<p className={SubHeaderMsgStyles}><p>{t("pl_contact_customer")} </p>
                                <p className='sm:w-[465px]'><span className='text-[#2965FB] font-bold'>{t("support_mail")}</span><span>{t("further_assistance")}</span></p>
                            </p>)}
                            {subHeaderMsg == '' && (<p className={SubHeaderMsgStyles}>{t("accessAble")}</p>)}
                        </>
                    }
                    isSubComponent={isSubComponent}
                    textMessage={textMessage}
                    btn1Exists={btn1Exists}
                    btn2Exists={btn2Exists}
                    btn1Text={btn1Text} btn2Text={btn2Text}
                    btn1TypeAcpt={btn1TypeAcpt}
                    verificationFailure={() => { }}
                    footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                    showErrorImg={showErrorImg}
                    isBorderNone={isBorderNone}
                    btn1Focus={btn1Focus}
                    btn2Focus={btn2Focus}
                />
            )}
        </>

    )
}

export default AccessCode;
