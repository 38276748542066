import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../CustomComponents/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../../Layout/HomeLayout';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import lock_card_on from '../../../../assets/images/lock_card_on.png'
import lock_card_off from '../../../../assets/images/lock_card_off.png'
import kudzu_green_card from '../../../../assets/images/kudzu_green_card.png'
import kudzu_blue_card from '../../../../assets/images/kudzu_blue_card.png'
import address_update_Succss from '../../../../assets/images/address_update_Succss.png';
import { GENERATE_EXISTING_PHONE_EMAIL_OTP, GET_ADD_NEW_CARD_METADATA, GET_SHIPPING_ID, LOCK_CARD_API, REPLACE_CARD_API, REPORT_LOST_CARD_API, STATUS_SUCCESS_CODE, UNLOCK_CARD_API, VERIFY_UPDATE_ADDRESS_OTP } from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import { retrieveData } from '../../../../helpers/utils/Utils';
import CardMenu from '../../CardMenu';
import { REPLACE_CARD, REPORT_LOST, REPORT_STOLEN } from '../../CardActionScreen';
import Moment from 'react-moment';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../CustomComponents/Card/CardStyles";
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { displayErrorMessage, displaySuccessMessage, parseGoogleLocationResponse } from '../../../../utils';
import CustomTextInput from '../../../CustomComponents/CustomtextInput';
import { LOGIN_SESSION_KEY } from '../../../../Redux/reducers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Modal from '../../../CustomComponents/Modal';
import Custom2FA from '../../../CustomComponents/Custom2FA';
import TwoFactTypeSelctionModal from '../../../CustomComponents/TwoFactTypeSelctionModal';
import CustomInput from '../../../CustomComponents/FormInputs';
import logFirebaseEvents from '../../../LogFirebaseEvents';



const ReplaceCard = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const selectedItem = cards?.[0]
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [generateBtnClicked, setGenerateBtnClicked] = useState(true); //Routing Number
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none text-black";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [modelImage, setModelImage] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [subFooter, setSubFooter] = useState('');
    const [replacementReason, setReplacementReason] = useState('');
    const [nextClicked, setNextClicked] = useState(false);
    const [shippingId, setShippingId] = useState('');
    const [countryCode, setCountryCode] = useState('USA')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const location = useLocation();
    const [UpdatedArray, setUpdatedArray] = useState(useSelector(state => JSON.parse(state.userCards)));
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [textMessage, setTextMessage] = useState(false);
    const [isSubComponent, setIsSubComponent] = useState(false);
    const [btn1Focus, setBtn1Focus] = useState(false);
    const [btn2Focus, setBtn2Focus] = useState(false);
    const [isDisabledBtn1, setIsDisabledBtn1] = useState(false);
    const [show2faSelection, setshow2faSelection] = useState(false);
    const [otpGenerationType, setOtpGenerationType] = useState(false);

    const [addressEditClicked, setAddressEditClicked] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState(null)
    const [homeAddressToserver, setHomeAddressToserver] = useState('');
    const [line1AdressFilled, setLine1AdressFilled] = useState(false);
    const [addressChanged, setAddressChanged] = useState(false)
    const [predictions, setPredictions] = useState([]);
    const [typedValue, setTypedValue] = useState("");
    const [selectedAddressLine2, setSelectedAddressLine2] = useState('');
    const [infoMessage, setinfoMessage] = useState('');
    const [responseCode, setResponseCode] = useState('');
    const [AddressError, setAddressError] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [homeAddress, setHomeAddress] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const [zipCodeError, setZipCodeError] = useState('');
    const [isAddress, setAddress] = useState('');
    const [prevAddressState, setPrevAddressState] = useState('')

    const validationSchema = yup.object().shape({
        address: yup.string().required(t('enter_address')),
        apt_etc: yup.string(),
        city: yup.string().required('Please enter a city'),
        state: yup.string().required('Please enter a state'),
        zip_code: yup.string().required('Please enter a valid zip code'),
    });



    const [twoFactModelTitle, setTwoFactModelTitle] = useState('');
    const [twoFactModelSubTitle, setTwoFactModelSubTitle] = useState('');
    const [twoFactModelContent, setTwoFactModelContent] = useState('');
    const [showStnadradRatesMessage, setShowStnadradRatesMessage] = useState(false);

    const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
    });









    const params = location.state;
    const cardDetails = useSelector(state => state.selectedCardDetails);

    useEffect(() => {
        getCardMetaData()
    }, [])

    useKeyDown(() => { }, ["Enter"]);
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, textMessage, isSubComponent, btn1Focus, btn2Focus) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
        setTextMessage(textMessage)
        setIsSubComponent(isSubComponent)
        setBtn1Focus(btn1Focus)
        setBtn2Focus(btn2Focus)
    }
    const handleCardAction = (type) => {
        if (type == t('lock_card')) {
            showAlertModal(true, t("are_you_sure"), t("this_card_will_be_locked"), t('once_you_lock_your_card_any_new_transactions_will_be_declined'), true, true, t('lock_card'), t("cancel"), true, require('../../../../assets/images/lock_green.png'), '', false, true, '', false, false, true)
        } else if (type == t('unlock_card')) {
            showAlertModal(true, t("ready_to_unlock_questionmark"), t("unlock_your_card_to_resume_normal_use"), t('once_you_unlock_your_card_all_locked'), true, true, t('unlock_card'), t("cancel"), true, require('../../../../assets/images/unlock_green.png'), '', false, true, '', false, true, false)
        } else if (type == t('replace_card')) {
            navigate('/CardServices/CardActionScreen/ReplaceCard', { state: { item: cardDetails } })
        } else if (type == t('change_pin')) {
            navigate('/CardServices/CardActionScreen/ChangePin', { state: { item: cardDetails } })
        } else if (type == t('view_card_details')) {
            navigate('/CardServices/CardActionScreen/ViewCard', { state: { item: cardDetails } })
        } else if (type == t('activate_card')) {
            navigate('/CardServices/CardActionScreen/ActivateCard', { state: { item: cardDetails } })
        }

    }
    const onAcptBtnClick = () => {
        if (btn1Text == t("lock_card")) {
            callApiToLockCard()
        } else if (btn1Text == t("unlock_card")) {
            callApiToUnlockCard()
        } else if (btn1Text == t('back_to_card_services')) {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Replace_Card","Replace_Back_To_Services_Clicked", "Replace_Back_To_Services_Clicked","", "");
            navigate('/CardServices/CardActionScreen')
        } else if (btn1Text == t('ship_it')) { 
            callApiToGetShippingId(replacementReason == t('damaged') ? REPLACE_CARD : replacementReason == t('lost') ? REPORT_LOST : REPORT_STOLEN)
        } else if (btn1Text == t('home')) {
            navigate('/home')
        } else if (btn1Text == t('done')) {
            getCardMetaData();
            handleNext();
        }
    }


    const onCancelBtnClick = () => {
        if (btn2Text == 'Cancel') {
            setShowModel(false)
        } else if (btn2Text == t('back_to_card_services')) {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Replace_Card","Replace_Back_To_Services_Clicked", "Replace_Back_To_Services_Clicked","", "");
            navigate('/CardServices/CardActionScreen')
        }
    }

    const backToInitialValues = () => {
        setAddressError('');
        setCityError('');
        setStateError('');
        setZipCodeError('');
        clearErrors();
        setHomeAddress(prevAddressState.formattedAddress)
        setHomeAddressToserver(prevAddressState);
        setTypedValue('');
        setAddressEditClicked(false);
        setIsDisabledBtn1(false)
        setinfoMessage('')
        setAddress("")
        setSelectedAddressLine2("");
        setLine1AdressFilled(false);
    }
    const closeModel = () => {
        getCardMetaData();
        setShowModel(false);
        backToInitialValues()
        clearErrors()
        setAddressError('');
        setCityError('');
        setStateError('');
        setZipCodeError('');

    }

    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }

    // const editAddressClicked = async () => {
    //     if (!twoFactAuthenticationDone) { setshow2faSelection(true); }
    //     else {
    //         setAddressEditClicked(true);
    //     }

    // }

    const editAddressClicked = async () => {
        clearErrors();
        if (!twoFactAuthenticationDone) {

            // setSlectedFieldToEdit(t('old_address'))
            setshow2faSelection(true);
            setIsOTPVerifyModalOpen(false)
            //  generate2FAOtp(t('old_address')); 
        }
        else {
            setAddressEditClicked(true);

            setAddress(homeAddressToserver?.line1);
            setValue('address', homeAddressToserver?.line1);
            setValue('apt_etc', homeAddressToserver?.line2);
            setValue('city', homeAddressToserver?.city);
            setValue('state', homeAddressToserver?.state);
            setValue('zip_code', homeAddressToserver?.zipCode);

            setTimeout(() => {
                const inputElement = document.getElementById('addressInput');
                if (inputElement) {
                    inputElement.focus();
                }
            }, 500);
        }

    }
    // const updateHomeAddress = async () => {
    //     if (!twoFactAuthenticationDone) {
    //         if (typedValue.trim() == '') {
    //             setinfoMessage(t('please_enter_address'))
    //         } else {
    //             // generate2FAOtp(t('new_address'));
    //             generate2FAOtp(t('old_address'));
    //         }
    //     }
    //     else {
    //         if (typedValue.trim() == '') {
    //             setinfoMessage(t('please_enter_address'))
    //         } else {
    //             setSelectedType(t('new_address'));
    //             // setAddressEditClicked(true);
    //             // setIsOTPVerifyModalOpen(true);
    //             generate2FAOtp(t('new_address'));
    //         }

    //     }

    //     setTimeout(() => {
    //         setinfoMessage('');
    //     }, 5000);


    // }

    const updateHomeAddress = async (data) => {
        clearErrors();
        const formData = getValues();

        try {
            if (isAddress != '' && formData.city != '' && formData.state != '' && formData.zip_code != '') {
                clearErrors();
                setAddressError('');
                setCityError('');
                setStateError('');
                setZipCodeError('');
                console.log("formData", formData)
                const formattedAddress1 = `${isAddress && `${isAddress}, `}${formData.apt_etc && `${formData.apt_etc}, `}${formData.city && `${formData.city}, `}${formData.state && `${formData.state}, `}${formData.zip_code && `${formData.zip_code}`}`
                const updatePersonalObj = {
                    ...homeAddressToserver,
                    "line1": isAddress,
                    "line2": formData.apt_etc,
                    "city": formData.city,
                    "state": formData.state,
                    "zipCode": formData.zip_code,
                    // "formattedAddress": formattedAddress1,

                }
                console.log("updatePersonalObj", updatePersonalObj)
                setHomeAddressToserver(updatePersonalObj)

                if (!twoFactAuthenticationDone) {

                    setSelectedType(t('old_address'));
                    generate2FAOtp(t('old_address'), '');
                }
                else {

                    // setSelectedType(t('new_address'));
                    generate2FAOtp(t('new_address'), '', updatePersonalObj);
                }
                // setAddressEditClicked(false);
            }
            else {
                await validationSchema.validate(data, { abortEarly: false });
            }
            if (!isAddress) setAddressError('Address is required')
            else setAddressError('')

        } catch (error) {
            if (!isAddress) setAddressError('Address is required')
            else setAddressError('')
            if (error.inner) {
                const validationErrors = {};
                error.inner.forEach((err) => {
                    if (typeof err.message === 'string') {

                        if (err.path === 'city') {
                            setCityError(err.message);
                            if (formData.city) {
                                setCityError('');
                            }
                        }
                        else if (err.path === 'state') {
                            setStateError(err.message);
                            if (formData.state) {
                                setStateError('');
                            }
                        }
                        else if (err.path === 'zip_code') {
                            setZipCodeError(err.message);
                            if (formData.zip_code) {
                                setZipCodeError('');
                            }
                        }
                        // Handle other fields if needed
                    }
                });
            }

        }



    }



    const cancelHomeAddress = () => {
        // setAddressEditClicked(false);
        // setAddress("")
        // setTypedValue('');

        backToInitialValues();

    }

    const handleShipItClick = () => {
        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Replace_Card","Replace_Ship_IT_Clicked", "Replace_Ship_IT_Clicked","", "");  
        callApiToGetShippingId(replacementReason == t('damaged') ? REPLACE_CARD : replacementReason == t('lost') ? REPORT_LOST : REPORT_STOLEN)
    }


    const generate2FAOtp = async (otpType, requestType, objForNewAddress) => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        var object = {};
        var url = "";
        if (otpType == t('old_address')) {
            object = {
                "action": "address",
                "cardKey": selectedItem?.CardKey
            }
            if (requestType !== undefined || requestType !== null) {
                object.IsEmail = (requestType == 'Email') ? true : false
            }
            var url = GENERATE_EXISTING_PHONE_EMAIL_OTP
        }
        else if (otpType == t('new_address')) {
            object = objForNewAddress;
            object.cardKey = selectedItem?.CardKey
            if (requestType !== undefined || requestType !== null) {
                object.IsEmail = (requestType == 'Email') ? true : false
            }

            url = VERIFY_UPDATE_ADDRESS_OTP;

        }


        setLoading(true);
        try {
            const response = await PostRequest(url, object, headers);
            setLoading(false);

            const parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode === STATUS_SUCCESS_CODE) {
                //adding sessionid to redux
                const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                //adding userid to redux
                const userIdPayload = { userId: parsedResponse.Response.userId }
                dispatch({ type: 'USER_ID', payload: userIdPayload });
                if (otpType === t('new_address') && twoFactAuthenticationDone === true) {

                    const addressParts = [
                        objForNewAddress.line1,
                        objForNewAddress.line2,
                        objForNewAddress.city,
                        objForNewAddress.state,
                        // objForNewAddress.stateCode,
                        // objForNewAddress.countryCode,
                        objForNewAddress.zipCode,
                        // objForNewAddress.country,
                    ];

                    const homeAddress = addressParts.filter(part => part).join(', ');
                    setHomeAddress(homeAddress);

                    // setAddress("")

                    // setSelectedAddressLine2("");
                    // setLine1AdressFilled(false);
                    // setAddressEditClicked(true);
                    setIsOTPVerifyModalOpen(false);
                    setAddressEditClicked(false);
                    showAlertModal(true, t('success'), t('editAddressSuccessMsg'), '', true, false, t('done'), '', true, address_update_Succss, true, false);


                } else if (otpType === t('old_address')) {

                    setSelectedType(t('old_address'));

                    setTwoFactModelTitle(t('great_exclamation'));
                    setTwoFactModelSubTitle(t('we_are_sending_messsage'));
                    setShowStnadradRatesMessage(true);
                    setIsOTPVerifyModalOpen(true);
                }


            } else {
                const arrMessages = parsedResponse.Messages;
                const message = arrMessages[0];
                const displayText = message.DisplayText;
                setinfoMessage(displayText);
                setResponseCode(parsedResponse.ResponseCode);
                setTimeout(() => {
                    setinfoMessage('');
                    setResponseCode('');
                }, 5000);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const verificationSuccess = (type) => {

        setAddressEditClicked(false);
        if (type == t('old_address')) {
            setAddressEditClicked(true);
            setAddress(homeAddressToserver?.line1);
            setValue('address', homeAddressToserver?.line1);
            setValue('apt_etc', homeAddressToserver?.line2);
            setValue('city', homeAddressToserver?.city);
            setValue('state', homeAddressToserver?.state);
            setValue('zip_code', homeAddressToserver?.zipCode);
            setTimeout(() => {
                const inputElement = document.getElementById('addressInput');
                if (inputElement) {
                    inputElement.focus();
                }
            }, 500);

        }
        else if (type == t('new_address')) {
            const addressParts = [
                homeAddressToserver.line1,
                homeAddressToserver.line2,
                homeAddressToserver.city,
                // homeAddressToserver.stateCode,
                // homeAddressToserver.countryCode,
                homeAddressToserver.state,
                homeAddressToserver.zipCode,
                // homeAddressToserver.country,
            ];

            const homeAddress = addressParts.filter(part => part).join(', ');

            setHomeAddress(homeAddress);
            // setSelectedAddressLine2("");
            // setLine1AdressFilled(false);
            setAddressEditClicked(false);

            setAddressEditClicked(false);
            getCardMetaData();
            showAlertModal(true, t('success'), t('editAddressSuccessMsg'), '', true, false, t('done'), '', true, address_update_Succss, true, false);
        }
        // getChangePinDetails()
        // navigate('/settings/ManageExternalAccounts/AddExternalAccount')

    }
    const verificationFaiure = (type, message) => {
        // alert(message)
        getCardMetaData();
        setAddressEditClicked(false);
        setinfoMessage(message);

    }

    const handleAdressChange = () => {
        clearErrors();
        setAddressError('');
        setCityError('');
        setStateError('');
        setZipCodeError('');
    }


    // const handleSelect = async (selectedAddress, placeId) => {
    //     const results = await geocodeByAddress(selectedAddress);
    //     const latLng = await getLatLng(results[0]);

    //     // Get formatted address using the place ID
    //     const placeResults = await geocodeByPlaceId(placeId);
    //     const formattedAddress = placeResults[0].formatted_address;
    //     const addressComponents = placeResults[0].address_components;
    //     var selectedAddress = parseGoogleLocationResponse(addressComponents);
    //     const streetNumber = selectedAddress.streetNumber != null ? selectedAddress.streetNumber : ''
    //     const route = selectedAddress.route != null ? selectedAddress.route : ''
    //     var object = {
    //         "line1": streetNumber + " " + route,
    //         "line2": selectedAddressLine2,
    //         "city": selectedAddress.locality != null ? selectedAddress.locality : '',
    //         "state": selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : '',
    //         "zipCode": selectedAddress.postalCode != null ? selectedAddress.postalCode : '',
    //         "countryCode": selectedAddress.countryCode != null ? selectedAddress.countryCode : '',
    //         "country": selectedAddress.country != null ? selectedAddress.country : '',
    //         "cardKey": ""
    //     }
    //     setHomeAddressToserver(object);

    //     const addressParts = [
    //         object.line1,
    //         object.line2,
    //         object.city,
    //         object.state,
    //         object.zipCode,
    //         object.countryCode,
    //         object.country
    //     ];

    //     const homeAddress = addressParts.filter(part => part).join(', ');
    //     setAddress(formattedAddress);
    //     // setAddress(homeAddress);
    //     setTypedValue(homeAddress);
    //     setLine1AdressFilled(true);
    //     setAddressChanged(true);

    //     setPredictions([]);

    // };

    const handleSelect = async (selectedAddress, placeId) => {
        clearErrors();
        setAddressError('');
        setCityError('');
        setStateError('');
        setZipCodeError('');
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);

        // Get formatted address using the place ID
        const placeResults = await geocodeByPlaceId(placeId);
        const formattedAddress = placeResults[0].formatted_address;
        const addressComponents = placeResults[0].address_components;


        var selectedAddress = parseGoogleLocationResponse(addressComponents);
        const address1 = (selectedAddress.streetNumber != null ? selectedAddress.streetNumber : selectedAddress.sublocality != null ? selectedAddress.sublocality : selectedAddress.naturalFeature != null ? selectedAddress.naturalFeature : '') + (selectedAddress.route != null ? ` ${selectedAddress.route}` : '');

        var object = {
            "line1": address1,
            "line2": '',
            "city": selectedAddress.locality != null ? selectedAddress.locality : '',
            "state": selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : '',
            "zipCode": selectedAddress.postalCode != null ? selectedAddress.postalCode : '',
            "countryCode": selectedAddress.countryCode != null ? selectedAddress.countryCode : '',
            "country": selectedAddress.country != null ? selectedAddress.country : '',
            "cardKey": selectedItem?.CardKey
        }
        setHomeAddressToserver(object);

        setValue('address', address1);
        setAddress(address1)
        setValue('apt_etc', "")
        setValue('city', (selectedAddress.locality != null ? selectedAddress.locality : ''));
        setValue('state', (selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : ''));
        setValue('zip_code', (selectedAddress.postalCode != null ? selectedAddress.postalCode : ''));


        setPredictions([]);

    };



    const addressSection = () => {
        console.log("isAddress", isAddress)
        return (
            <div className=' flex flex-col w-full'>
                {/*  address section */}
                {
                    !addressEditClicked &&
                    <div className='md:mt-16 mt-12 w-full'>
                        {/* <p className='font-hanken-grotesk text-[#262B3A] uppercase 
                      lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]  font-[600] tracking-[2px] md:pb-8 pb-4 
            opacity-[50%]'>{t("personal_address")}</p> */}

                        <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[16px] 
                font-medium leading-[26px] md:mb-6 mb-2 w-[80%] md:w-[60%]">
                            {homeAddress}
                        </p>

                        <div className="bg-[#94C3E8] h-0.5 min-h-1 w-full"></div>

                        <button
                            onClick={() => { editAddressClicked();logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Replace_Card", "Replace_Update_Address_Clicked", "Replace_Update_Address_Clicked","", "");  }}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    editAddressClicked()
                                }
                            }}
                        >{<p  className='pt-5 
                    font-hanken-grotesk text-[#2965FB] md:text-[16px]  sm:text-[12px] text-[14px]
                     font-[700]'>{t("update_address")}
                        </p>}
                        </button>
                    </div>
                }

                {/*  address  update section */}
                {
                    (addressEditClicked) &&
                    <div className='w-full' style={line1AdressFilled ? { overflowY: 'scroll', height: 200 } : {}}>
                        <PlacesAutocomplete
                            value={isAddress}
                            // value={ address1 + ' ' + address2}
                            // onChange={setAddress}
                            onChange={value => {
                                setAddress(value)
                                if (value) {
                                    setAddressError('');
                                    setCityError('');
                                    setStateError('');
                                    setZipCodeError('');

                                }
                            }
                            }
                            onSelect={handleSelect}

                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input {...getInputProps({ placeholder: 'Address' })} className='font-hanken-grotesk text-secondary-2 px-4 py-2 bg-text-btn placeholder-kudzu-blue text-sm font-medium focus:outline-none w-full h-[60px]'
                                        onBlur={(e) => {
                                            if (!isAddress) setAddressError('Address is required')
                                            else setAddressError('')
                                        }}
                                    />
                                    <div className='mb-4' style={suggestions.length > 0 ? { overflowY: 'scroll', height: 100 } : {}}>
                                        {loading ? <div>Loading...</div> : null}
                                        {suggestions.map((suggestion) => {
                                            const style = {
                                                backgroundColor: suggestion.active ? '#ffffff' : '#F5F8F9',
                                                cursor: 'pointer',
                                                padding: '8px',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                lineHeight: '26px',
                                            };
                                            return (
                                                <div
                                                    key={suggestion.placeId}
                                                    {...getSuggestionItemProps(suggestion, { style })}
                                                >
                                                    {suggestion.description}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        {/* {line1AdressFilled && <CustomTextInput
                            name={t("apt_suite_etc")}
                            onChange={(event) => {
                                let tempHomeaddresToServer = homeAddressToserver;
                                tempHomeaddresToServer.line2 = event.target.value;
                                setHomeAddressToserver(tempHomeaddresToServer);
                                setSelectedAddressLine2(event.target.value)
                            }}
                            value={selectedAddressLine2} style={{ width: '100%', height: '51px' }} />} */}
                        {AddressError && (
                            displayErrorMessage(AddressError)
                        )}
                        {predictions.length > 0 && (
                            <ul className='bg-[aliceblue] max-h-60 overflow-y-auto p-2'>
                                {predictions.map((prediction) => (
                                    <li
                                        key={prediction.place_id}
                                        className='font-hanken-grotesk text-sm font-medium leading-6 p-2 cursor-pointer'
                                        value={prediction.place_id}
                                    >{prediction.description}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {(addressEditClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                            <div className='mb-2'>
                                {displaySuccessMessage(infoMessage)}
                            </div>
                        }
                        {(addressEditClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                            <div className='mb-2'>
                                {displayErrorMessage(infoMessage)}
                            </div>
                        }
                        {/* <p className='pt-1 font-hanken-grotesk lg:text-[18px] md:text-[17px] 
sm:text-[15px] text-[15px] font-medium'>{t('P.O._boxes_are_not_accepted')} </p> */}
                        <div className='pt-1'>
                            <span className='pt-1 font-hanken-grotesk lg:text-[18px] md:text-[17px] 
            sm:text-[15px] text-[15px] font-medium'>{t('P.O._boxes_are_not_accepted')} </span>
                            <CustomInput
                                name="apt_etc"
                                label="Apt, suite, etc. (optional)"
                                register={register}
                                error={errors.apt_etc}
                                type="text"
                            />
                            <CustomInput
                                name="city"
                                label="City"
                                register={register}
                                error={errors.city}
                                type="text"
                                onChange={handleAdressChange}

                            />
                            <div className='mb-2'>

                                {cityError && (
                                    displayErrorMessage(cityError)
                                )}
                            </div>

                            <CustomInput
                                name="state"
                                label="State"
                                register={register}
                                error={errors.state}
                                type="text"
                                onChange={handleAdressChange}

                            />
                            <div className='mb-2'>

                                {stateError && (
                                    displayErrorMessage(stateError)
                                )}
                            </div>

                            <CustomInput
                                name="zip_code"
                                label="Zip Code"
                                register={register}
                                error={errors.zip_code}
                                type="text"
                                onChange={handleAdressChange}

                            />
                            <div className='mb-2'>
                                {zipCodeError && (
                                    displayErrorMessage(zipCodeError)
                                )}

                            </div>
                            <button
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        updateHomeAddress()
                                    }
                                }}
                                onClick={() => updateHomeAddress()}
                                className='font-hanken-grotesk text-[#2965FB]
                     md:text-[16px] text-[12px] font-[700] cursor-pointer'>{t("save")}</button>
                            <span>

                                <button
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            cancelHomeAddress()
                                        }
                                    }}
                                    onClick={() => cancelHomeAddress()}
                                    className='font-hanken-grotesk text-[#2965FB]
                     md:text-[16px] text-[12px] font-[700] cursor-pointer pl-2'>{t("cancel")}</button>

                            </span>

                        </div>
                    </div>
                }

                <div className='lg:pt-20 md:pt-18 sm:pt-10 pt-16 w-full '>

                    <button className='lg:text-[16px] md:text-[14px] sm:text-[13px] text-[14px]
                                     w-full border-2 border-[#2965FB] lg:h-[60px] md:h-[55px] sm:h-[50px] h-[55px] text-white 
                                    font-hanken-grotesk 
                                    font-bold leading-[16px] disabled:opacity-30 '
                        disabled={addressEditClicked ? true : false}
                        style={{ backgroundColor: '#2965FB' }}
                        tabIndex={0}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                handleShipItClick();
                            }
                        }}
                        onClick={(event) => { event.target.style.opacity = 0.8; handleShipItClick(); setTimeout(() => { event.target.style.opacity = 1; }, 200); }}>{btn1Text}</button>
                </div>

            </div>
        )


    }

    const callApiToLockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails !== null ? cardDetails.CardKey : 0
        }
        PostRequest(LOCK_CARD_API, object, headers).then((response) => {

            setTimeout(() => {
                setLoading(false)
            }, 500);

            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {

                setTimeout(() => {
                    updateCardStatus(cardDetails !== null ? cardDetails.CardKey : 0, responseObj.Response)
                    showAlertModal(true, t("done_exclamation"), t("this_card_is_now_locked"), t('any_new_tx_will_be_declined'), true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/lock_green_tick.png'), '', false, false, true, false)
                }, 1000);

                // this.updateCardsInLocal(responseObj.Response, LOCK_CARD)

            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)
                setTimeout(() => {
                }, 1000);

            }

        });

    }
    const updateCardStatus = (cardKey, response, type) => {
        const updatedCardIndex = cards?.findIndex(card => card.CardKey === cardKey);
        if (updatedCardIndex !== -1) {
            const updatedCard = {
                ...cards[updatedCardIndex],
                Status: response.Status,
                SubStatus: response.SubStatus,
                StatusCode: response.StatusCode,
                SubStatusCode: response.SubStatusCode,
                // NewCardRequestedDate: response.NewCardRequestedDate !== undefined ? response.NewCardRequestedDate : Moment().format("YYYY-MM-DD") + "T" + "00:00:00",

            };
            if (type === REPLACE_CARD) {
                updatedCard.IsReplaced = response.IsReplaced;
            }
            const updatedArray = [updatedCard, ...cards.filter(card => card.CardKey !== cardKey)]
            setUpdatedArray(updatedArray);
            dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedArray) } });
            dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: updatedCard } });

        }
    };
    const callApiToUnlockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails.CardKey
        }

        PostRequest(UNLOCK_CARD_API, object, headers).then((response) => {

            setTimeout(() => {
                setLoading(false)
            }, 500);

            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                setTimeout(() => {
                    updateCardStatus(cardDetails.CardKey, responseObj.Response)
                    showAlertModal(true, t("success_heading"), t("this_card_is_now_unocked"), t('you_can_use_your_card'), true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/unlock_green_tick.png'), '', false, false, true, false)
                }, 1000);

                // this.updateCardsInLocal(responseObj.Response, UNLOCK_CARD)

            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)

                setTimeout(() => {
                }, 1000);

            }

        });

    }

    const handleNext = () => {
        setNextClicked(true)
        setSubFooter(
            <div>
                {t('if_the_card_needs_to_be_shipped')}{' '}
                <span onClick={() => { navigate('/Help/ContactUs_2') }} className='text-[#559C7E]'>{t('customer_care')}</span> at{' '}
                <span className='text-[#559C7E]'>{t('15552345678')}</span>
            </div>
        );
        setTimeout(() => {
            if (replacementReason == t('damaged')) {
                showAlertModal(true, t("no_problem_exclimation"),
                    t("we_will_ship_a_new_card"), '', false,
                    false, t('ship_it'), '', true, '', '', false, false,
                    <p className='sm:font-[500] font-[400] md:text-[16px] sm:text-[12px] text-[16px] leading-[24px] sm:leading-[18px] md:leading-[24px] font-hanken-grotesk md:font-[400] text-[#262B3A]'>{t('shipping_days')}</p>
                    , true, true, false)
                // showAlertModal(true, t("no_problem_exclimation"),
                //     t("we_will_ship_a_new_card"), address1 + ' ' + address2, true,
                //     false, t('ship_it'), '', true, '', '', false, false, <p className='md:py-6 py-4 lg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px] leading-[28px] sm:leading-[20px] md:leading-[26px] font-hanken-grotesk font-[500] text-[#262B3A]'>{t('If_the_card_needs')}<span className='text-[#2965FB]'> {t('customer_care')}</span> {t('at')} <span className='text-[#2965FB]'>{t('customercare_number')}</span>.</p>)
            }
            else if (replacementReason == t('lost')) {
                showAlertModal(true, t("no_worries_exclimation"), t("we_will_cancel_the_existing_card_and_ship"), '', false, false, t('ship_it'), '', true, '', '', false, false,
                    <p className='sm:font-[500] font-[400] md:text-[16px] sm:text-[12px] text-[16px] leading-[24px] sm:leading-[18px] md:leading-[24px] font-hanken-grotesk md:font-[400] text-[#262B3A]'>{t('shipping_days')}</p>
                    , true, true, false)
            } else if (replacementReason == t('stolen')) {
                showAlertModal(true, t("not_to_worry"), t("we_will_cancel_the_existing_card_and_ship"), '', false, false, t('ship_it'), '', true, '', '', false, false,
                    <p className='sm:font-[500] font-[400] md:text-[16px] sm:text-[12px] text-[16px] leading-[24px] sm:leading-[18px] md:leading-[24px] font-hanken-grotesk md:font-[400] text-[#262B3A]'>{t('shipping_days')}</p>
                    , true, true, false)

            }
            // else if (replacementReason == t('lost') || replacementReason == t('stolen')) {
            //     showAlertModal(true, t("no_worries_exclimation"), t("we_will_cancel_the_existing_card_and_ship"), '', false, false, t('ship_it'), '', true, '', '', false, false,
            //         <p className='sm:font-[500] font-[400] md:text-[16px] sm:text-[12px] text-[16px] leading-[24px] sm:leading-[18px] md:leading-[24px] font-hanken-grotesk md:font-[400] text-[#262B3A]'>{t('shipping_days')}</p>
            //         , true)
            // }
        }, 1000);
    }

    const getCardMetaData = async () => {
        if (cardDetails?.CardKey == undefined || cardDetails?.CardKey == null) {
            return
        }

        setLoading(true)

        var url = GET_ADD_NEW_CARD_METADATA + 'cardKey=' + cardDetails?.CardKey + '&countryCode=' + countryCode
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var response = await GetRequest(url, headers)
        try {
            setTimeout(() => {
                setLoading(false)
            }, 500);
            var parsedResponse = JSON.parse(response)
            setAddress1(parsedResponse.Response.PrimaryCardAddress.Line1 + ", " + parsedResponse.Response.PrimaryCardAddress.City + ", " + parsedResponse.Response.PrimaryCardAddress.State
                + ", " + parsedResponse.Response.PrimaryCardAddress.Country + " - " + parsedResponse.Response.PrimaryCardAddress.ZipCode)
            setAddress2(parsedResponse.Response.PrimaryCardAddress.Line2)

            setHomeAddress(parsedResponse.Response.PrimaryCardAddress.Line1 + ", " + parsedResponse.Response.PrimaryCardAddress.City + ", " + parsedResponse.Response.PrimaryCardAddress.State
                + ", " + parsedResponse.Response.PrimaryCardAddress.Country + " - " + parsedResponse.Response.PrimaryCardAddress.ZipCode + " " + parsedResponse.Response.PrimaryCardAddress.Line2)

            const addressParts = [
                parsedResponse.Response.PrimaryCardAddress?.Line1,
                parsedResponse.Response.PrimaryCardAddress?.Line2,
                parsedResponse.Response.PrimaryCardAddress?.City,
                parsedResponse.Response.PrimaryCardAddress?.State,
                // parsedResponse.Response.PrimaryCardAddress?.StateCode,
                // parsedResponse.Response.PrimaryCardAddress?.CountryCode,
                parsedResponse.Response.PrimaryCardAddress?.ZipCode,
                // parsedResponse.Response.PrimaryCardAddress?.Country,
            ];
            // debugger;

            const homeAddress = addressParts.filter(part => part).join(', ');

            setHomeAddress(homeAddress);
            setHomeAddressToserver(
                {
                    "line1": parsedResponse.Response.PrimaryCardAddress?.Line1,
                    "line2": parsedResponse.Response.PrimaryCardAddress?.Line2,
                    "city": parsedResponse.Response.PrimaryCardAddress?.City,
                    "state": parsedResponse.Response.PrimaryCardAddress?.State,
                    "zipCode": parsedResponse.Response.PrimaryCardAddress?.ZipCode,
                    "countryCode": parsedResponse.Response.PrimaryCardAddress?.CountryCode,
                    "country": parsedResponse.Response.PrimaryCardAddress?.Country,
                    "cardKey": selectedItem?.CardKey,
                    // "formattedAddress": homeAddress,
                }
            )

            setPrevAddressState(
                {
                    "line1": parsedResponse.Response.PrimaryCardAddress?.Line1,
                    "line2": parsedResponse.Response.PrimaryCardAddress?.Line2,
                    "city": parsedResponse.Response.PrimaryCardAddress?.City,
                    "state": parsedResponse.Response.PrimaryCardAddress?.State,
                    "zipCode": parsedResponse.Response.PrimaryCardAddress?.ZipCode,
                    "countryCode": parsedResponse.Response.PrimaryCardAddress?.CountryCode,
                    "country": parsedResponse.Response.PrimaryCardAddress?.Country,
                    "cardKey": selectedItem?.CardKey,
                    "formattedAddress": homeAddress,
                }
            )
        } catch (e) {
            setLoading(false)
        }
    }

    const callApiToGetShippingId = async (type) => {

        if (cardDetails == undefined || cardDetails == null) {
            return
        }
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        var url = GET_SHIPPING_ID + cardDetails?.CardKey;

        var response = await GetRequest(url, headers)
        let responseObj = JSON.parse(response);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            var shippingId = responseObj.Response[0].ShippingTypeID;
            setShippingId(shippingId)
            setTimeout(() => {
                callApiToReplaceCard(shippingId, type);
            }, 1000);
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 500);
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)
            setTimeout(() => {
                //showAlertModal(translate('alert'), '', displayText, translate('alert_ok'), '', require('../../assets/images/alert_warn.png'))
            }, 1000);
        }

    }
    const callApiToReplaceCard = async (shippingId, type) => {
        setShowModel(false)

        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {}

        if (type == REPLACE_CARD) {
            object = {
                CardKey: cardDetails?.CardKey,
                ShippingTypeID: shippingId,
                HomeAddress: true,
            }
        } else {

            object = {
                CardKey: cardDetails?.CardKey,
                ShippingTypeID: shippingId + "",
                HomeAddress: true,
                ReplaceCard: true,
                subStatusCode: type == REPORT_STOLEN ? "STL" : "LST"
            }
        }

        var url = "";
        if (type == REPORT_LOST || type == REPORT_STOLEN) {
            url = REPORT_LOST_CARD_API;
        }
        else {
            url = REPLACE_CARD_API;
        }

        var response = await PostRequest(url, object, headers)
        setLoading(false)

        let responseObj = JSON.parse(response);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            // this.updateCardsInLocal(responseObj.Response, type)
            updateCardStatus(cardDetails?.CardKey, responseObj.Response, type)
            setTimeout(() => {
                showAlertModal(true, t("confirmed"), t("your_replacement_card_is_on_the_way"), replacementReason == t('damaged') ? t('your_current_card_will_remain_active') : t('your_current_card_has_been_cancelled'), true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/card_post_new.png'), '', false, false, true, false)
            }, 1000);
        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)
            setTimeout(() => {
                // this.showAlertModal(translate('alert'), '', displayText, translate('alert_ok'), '', require('../../assets/images/alert_warn.png'))
            }, 1000);
        }
    }



    return (
        <>
            <HomeLayout>
                <CardMenu handleCardAction={handleCardAction} />
                <div className='md:w-[150px] sm:w-[30px] w-[0px]'></div>
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        CardRadius={true}
                        cardWidth={width.allpolicies}
                        HeaderRadius={true}
                        isWhite={true}
                        HeaderContent={false}>
                        <div class="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] lg:text-[26px]
                         md:text-[22px] sm:text-[20px] text-[20px] leading-[36px] font-hanken-grotesk
                          sm:mx-0 mx-4">{t('replace_card')}</div>
                        <div class="border-t-[2.5px] border-[#A3CEBB] flex justify-center 
                        flex-col sm:mx-0 mx-4"></div>
                        <div className='mt-10 sm:mx-0 mx-4'>

                            <p className='text-[#2965FB] font-serif-pro lg:text-[32px] md:text-[28px] 
                            sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] 
                            sm:leading-[30px] leading-[37px]'>{t('need_a_new_card')}</p>

                            <p className='text-black font-serif-pro lg:text-[32px] md:text-[28px] 
                            sm:text-[23px] text-[26px] lg:leading-[38px] md:leading-[32px] 
                            sm:leading-[30px] leading-[37px] sm:mt-1'>
                                {t('lets_get_you_a_replacement')}</p>
                        </div>

                        <div className='flex flex-col justify-center sm:pt-[5.5rem] pt-6
                         md:pb-56 sm:pb-40 pb-12 items-center sm:mx-0 mx-4'>
                            <div className='lg:w-[70%] sm:w-[80%] w-full'>
                                <p className='lg:text-[20px] md:text-[17px] sm:text-[14px] text-[16px]
                                 sm:leading-[27px] lg:leading-[30px] md:leading-[30px] font-hanken-grotesk sm:font-[600] font-[400]
                                 leading-[30px] pb-10'>{t('select_reason')}</p>
                            </div>
                            <div className='lg:w-[70%] sm:w-[80%] sm:flex block sm:justify-between m-auto'>
                                <div className='sm:py-0 py-4 flex flex-row self-center' onClick={() => { setReplacementReason(t('damaged'));logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Replace_Card", "Replace_Damaged_Clicked", "Replace_Damaged_Clicked","", ""); }}>
                                    <div className='lg:w-[20px] lg:h-[20px] md:w-[17px] md:h-[17px]
                                     sm:w-[16px] sm:h-[16px] w-[20px] h-[20px] rounded-full'
                                        tabIndex={0}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                setReplacementReason(t('damaged'))
                                            }
                                        }}
                                        style={{
                                            backgroundColor: replacementReason == t('damaged') ?
                                                '#2965FB' : '#D6E2E8'
                                        }}></div>
                                    <p className='font-hanken-grotesk font-[700] lg:text-sm 
                                    md:text-[15px] sm:text-[14px] text-[16px] ml-3 text-[#262B3A]'>{t('damaged')}</p>
                                </div>
                                <div className='sm:py-0 py-4 flex flex-row self-center'

                                    onClick={() => { setReplacementReason(t('lost')); logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Replace_Card", "Replace_Lost_Clicked", "Replace_Lost_Clicked","", ""); }}>
                                    <div className='lg:w-[20px] lg:h-[20px] md:w-[17px] md:h-[17px]
                                     sm:w-[16px] sm:h-[16px] w-[20px] h-[20px] rounded-full'
                                        tabIndex={0}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                setReplacementReason(t('lost'))
                                            }
                                        }}
                                        style={{
                                            backgroundColor: replacementReason == t('lost') ?
                                                '#2965FB' : '#D6E2E8'
                                        }}></div>
                                    <p className='font-hanken-grotesk font-[700] lg:text-sm 
                                    md:text-[15px] sm:text-[14px] text-[16px] ml-3 text-[#262B3A]'>{t('lost')}</p>
                                </div>
                                <div className='sm:py-0 py-4 flex flex-row self-center'
                                    onClick={() => { setReplacementReason(t('stolen')); logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Replace_Card", "Replace_Stolen_Clicked", "Replace_Stolen_Clicked","", ""); }}>
                                    <div className='lg:w-[20px] lg:h-[20px] md:w-[17px] md:h-[17px]
                                     sm:w-[16px] sm:h-[16px] w-[20px] h-[20px] rounded-full'
                                        tabIndex={0}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                setReplacementReason(t('stolen'))
                                            }
                                        }}
                                        style={{
                                            backgroundColor: replacementReason == t('stolen') ?
                                                '#2965FB' : '#D6E2E8'
                                        }}></div>
                                    <p className='font-hanken-grotesk font-[700] lg:text-sm 
                                    md:text-[15px] sm:text-[14px] text-[16px] ml-3 text-[#262B3A]'>{t('stolen')}</p>
                                </div>
                            </div>
                        </div>

                        {replacementReason != '' &&
                            <div className='flex flex-col justify-end items-end pb-12 sm:mx-0 mx-4'>
                                <button onClick={() => { handleNext();logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Replace_Card","Replace_Next_Clicked", "Replace_Next_Clicked","", ""); }} className=' 
                           font-hanken-grotesk font-[600] sm:font-[700] lg:text-[18px] md:text-[17px] 
                           sm:text-[15px] text-[16px] leading-[26px]  sm:leading-[13px] 
                           md:leading-[18px]  text-[#2965FB]'>
                                    {t('next')}
                                </button>
                            </div>
                        }
                    </DynamicCard>
                </div>
                {loading && <Loader />}
            </HomeLayout>

            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={closeOTPVerifyModal}
                isLogo={false}
                title={<p className={HighlightMsgStyles}> {twoFactModelTitle}</p>}
                subtitle={<p className={HeaderMsgStyles}>{otpGenerationType == 'Email' ? t("we_are_sending_text").replace("a text", "an email") : t("we_are_sending_text")}</p>}
                subHeaderMsg={<p className="font-hanken-grotesk modal-title md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] font-normal cursor-pointer pointer-events-none">{otpGenerationType != 'Email' && t("standard_msg_and_data_rates_may_apply")}</p>}
                content={<div className="mt-10"><Custom2FA address={homeAddressToserver} selected2FAType={otpGenerationType} type={selectedType} onClose={closeOTPVerifyModal} verificationFailure={verificationFaiure} verificationSuccess={verificationSuccess} /></div>}
            />
            <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false) }} onClickNext={(object) => { setOtpGenerationType(object.proc); setshow2faSelection(false); generate2FAOtp(t('old_address'), object.proc); }} />
            {showModel &&
                <CustomAlertModel
                    isOpen={showModel}
                    onClose={closeModel}
                    Fullheight={true}
                    image={modelImage}
                    highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                        <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                    // highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                    // HeaderMsg={<p className={`${HeaderMsgStyles} w-full sm:w-[85%] md:w-[77%]`}>{headerMsg}</p>}
                    subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                    isSubComponent={isSubComponent}
                    subComponent={addressSection()}
                    textMessage={textMessage}
                    btn1Exists={btn1Exists}
                    btn2Exists={btn2Exists}
                    btn1Text={btn1Text} btn2Text={btn2Text}
                    btn1TypeAcpt={btn1TypeAcpt}
                    verificationFailure={() => { }}
                    footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                    onAcptBtnClick={onAcptBtnClick}
                    // subFooter={<p className={SubHeaderMsgStyles}>{subFooter}</p>}
                    onCancelBtnClick={onCancelBtnClick}
                    showErrorImg={showErrorImg}
                    isBorderNone={isBorderNone}
                    isDisabledBtn1={isDisabledBtn1}
                    btn1Focus={btn1Focus}
                    btn2Focus={btn2Focus}
                />
            }

        </>
    )
}

export default withOidcSecure(ReplaceCard);
