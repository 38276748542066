import React, { useState, useEffect } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../CustomComponents/Loader';
import { UPDATE_CARDNAME, STATUS_SUCCESS_CODE, CAN_CREATE_SAVINGS_ACC, STUDENT_SIGNUP_EMAIL_ACCOUNT, GET_STUDENT_UNIVERSITY, STUDENT_EMAIL_UNIVERSITY } from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import { addLineBreaks, displayErrorMessage, displaySuccessMessage, isEmailValid, MoreServices } from '../../../../utils';
import { checkEmailExtension, fetchConfigarationData, retrieveData } from '../../../../helpers/utils/Utils';
import LineBreaks from '../../../../utils';
import HomeLayout from '../../../Layout/HomeLayout';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import Check from '../../../../assets/images/Check_white.png'
import beneficiarysaving from '../../../../assets/images/beneficiarysaving.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomInput from '../../../CustomComponents/FormInputs';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc';
import LeftBar from '../../../CustomComponents/DynamicComponent/LeftBar';


const appConfigData = await fetchConfigarationData();
const StudentEmailEntry = ({openSelectionUniversity,opeEditStudent,opeNoEditStudent,connectNullResponse,isPartnerUniversityStatus, opeAccessCode,hanldeSessionvalue }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook to access translation functions
  const cards = useSelector(state => JSON.parse(state.userCards));
  const accessTokenToApi = useSelector(state => state.accessToken);
  const studentEmailAddress = useSelector(state => state.studentEmailAddress);
  const [isLoading, setIsLoading] = useState(false);
  const [condition, setCondition] = useState('A'); // You can change this value to 'B' or 'C' to render different components
  //email state value
  const [validEmail, setValidEmail] = useState(false);
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const selectedItem = cards?.[0].CardKey;
  const [sessionIdKey , setSessionIdKey]= useState('');



  const schema = yup.object().shape({
    email: yup
      .string()
      // .required(t('please _enter _student_email_address'))
      .email(t('please_enter_a_valid_email_address')),
  });

  const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: { email: email }
  });


  ////for mail validation 
  const GenerateEmailOtp = () => {
    setMessage("")
    clearErrors()
    const errorMessage = isEmailValid(email)
    if (errorMessage == 'empty') {
      setError('email', {
        type: 'manual',
        message: t('please _enter _student_email_address'),
      });
      // setMessage(t('please_enter_your_email_address'))
    }
    if (errorMessage == 'not valid') {
      setError('email', {
        type: 'manual',
        message: t('please _enter _student_email_address'),
      });
      // setMessage(t('please_enter_a_valid_email_address'))
    } else if (errorMessage != 'empty' && errorMessage != 'not valid') {
      const isValidExtension = checkEmailExtension(email);
      if (isValidExtension) { 
        createAccountWithStudentEmail(email); 
      } else {
        setMessage(t('please_enter_student_email_address'));
        setValidEmail(false);
      }
    }
  };


  async function createAccountWithStudentEmail(email) {
    debugger;
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    const cardKey = cards !== null ? cards[0].CardKey : 0;
    var requestObject = {
      "applicationId": appConfigData.CLIENT_ID,
      "language": "en",
      "sEmail": email,
      "cardKey": cardKey
    }
    const response = await PostRequest(STUDENT_EMAIL_UNIVERSITY, requestObject, headers);
    setIsLoading(true)
    let parsedResponse = JSON.parse(response);
    if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
      debugger;

       //get the sesion if and prop
       if(parsedResponse.Response.sessionId && parsedResponse.Response.isPartnerUniversity){
        debugger;
        setIsLoading(false);
        hanldeSessionvalue(parsedResponse.Response.sessionId); 
        opeAccessCode();
       }else{
        //error
       } 

      } else {

        //unvisrsity selection
        openSelectionUniversity();

      }

  } 
  
  const handleEmailChange = async (event) => {
    setMessage('');
    clearErrors();
    const { name, value } = event.target;
    setEmail(value);
  
    if (value.length > 0) {
      try {
        await schema.validate({ [name]: value });
        setValidEmail(true);
        setEmail(value);
        dispatch({ type: 'STUDENT_EMAIL_VALUE', payload: { studentEmailAddress: value } });
      } catch (error) {
        setValidEmail(false);
      }
    } else {
      setValidEmail(false);
    }
  };
  

  const heading = () => {
    return (
      <>
        <div className='lg:w-[260px] md:w-[183px] sm:w-[164px] w-full sm:mx-0 ml-[2.5rem] sm:pb-0 pb-8'>
          <p className="text-white font-normallg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] 
                    md:leading-[33px] sm:leading-[30px] leading-[31px]
                  font-serif-pro ">{(t('settingsEmailTittle'))}</p>
        </div>
      </>
    )
  }

  const handleHome = () =>{
    navigate('/Settings/Connectschool')
  }

  useEffect(() => {
    if (studentEmailAddress) {
      setEmail(studentEmailAddress);
      setValue('email', studentEmailAddress);
      setValidEmail(true);
    }
  }, [setValue]);

  // useEffect(() => {
  //   if (studentEmailAddress) {
  //     setEmail(studentEmailAddress);
  //     setValue('email', studentEmailAddress);
  //     setValidEmail(isEmailValid(studentEmailAddress) === 'valid');
  //   }
  // }, []);

  return (
    <HomeLayout>
      <HomeHeader heading={LineBreaks(t("settingsEmailTittle"))} isHeaderCaption={true} /> 
      <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
         animate__animated animate__fadeInRight '>
        <DynamicCard
          header={false}
          footer={true}
          CardRadius={true}
          HeaderRadius={true}
          isWhite={true}
          cardWidth={width.homeCard}
        >
          <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                    lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px]
                     leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
            {t('connect_school')}
          </div>

          <div className='border-t-[2.5px] border-[#A3CEBB]
                     flex justify-center flex-col sm:mx-0 mx-4'></div>


          <div className='lg:w-[90%] md:w-[94%] w-full font-serif-pro lg:text-[27px] md:text-[24px]
                sm:text-[20px] text-[24px] md:leading-[38px] sm:leading-[24px] leading-[31px] 
                    font-normal text-[#2965FB] mt-5'>
            <p className='sm:mx-0 mx-4 '>{t("student_selction_heading1")}
              <span className='text-black'>
                {(t("enterStudentMail"))}.</span></p>
          </div>

          <span className="font-hanken-grotesk text-[#262B3A]
               lg:text-[16px] md:text-[16px] py-4 inline-block sm:text-[14px] text-[16px]
                  font-medium  leading-6 sm:mx-0  mx-4 ">
            {addLineBreaks(t("associate_school"))}</span>

          <form>
            <div className='w-full flex flex-col items-center lg:py-20 md:py-14 sm:pt-10 sm:pb-0 py-4'>
              <div className='sm:w-[62%] w-full sm:mx-0 mx-4'>
                <div className='lg:w-[384px] md:w-[340px] sm:w-[224px]'>
                  <div className='md:w-[282px] sm:w-[198px] flex flex-col gap-4 mt-4'>
                    <CustomInput
                      name="student_email_address"
                      label={t("student_email_address")}
                      register={register}
                      error={errors.email}
                      type="text"
                      onChange={handleEmailChange}
                      textInputValue={email}
                      isValid={validEmail}
                      autoFocus={true}
                      value={email}
                      onKeyDownFun={(event) => { if (event.key == "Enter") { event.preventDefault(); GenerateEmailOtp(); } }}
                    />
                  </div>
                </div>
              </div>
              
              {message &&
                      <div className='mb-2'>
                        {displayErrorMessage(message)}
                      </div>
                    }
            </div>
            <div className="flex justify-end items-center md:gap-20 sm:gap-12 gap-4 sm:px-0 px-4 mt-20">
            {/* <div className='flex items-baseline md:gap-20 sm:gap-12 gap-4 sm:px-0 px-4'> */}
            <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center"
              onClick={() => {
                if (connectNullResponse === null) {
                  navigate('/Settings');
                  dispatch({ type: 'STUDENT_EMAIL_VALUE', payload: { studentEmailAddress: "" } });
                } else {
                  isPartnerUniversityStatus ? opeNoEditStudent() : opeEditStudent();
                  dispatch({ type: 'STUDENT_EMAIL_VALUE', payload: { studentEmailAddress: "" } });
                }
              }}
                // Call handleContinue instead of openPhoneOTPModal directly
              >{t('back')}</button>
              <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center"
                onClick={GenerateEmailOtp}
                onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); GenerateEmailOtp(); } }}
              // Call handleContinue instead of openPhoneOTPModal directly
              >
                {t('continue')}
              </button>
            </div>

          </form>


        </DynamicCard>
      </div>
    </HomeLayout>
  )
}

export default withOidcSecure(StudentEmailEntry);
