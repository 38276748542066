import React from 'react'
import { useTranslation } from 'react-i18next'
import { withOidcSecure } from '@axa-fr/react-oidc'
import NotificationConfigureTableItem from '../NotificationConfigureTableItem'


const NotificationConfigureTable = ({ notifications, updateConfigObjectByAlertCode, isReset }) => {
    // debugger;
    const { t } = useTranslation();

    return (
        <div className=' flex flex-col my-5 gap-10'>
            {/* HEADING */}
            <div className=' flex flex-row justify-between w-full'>
                <div className=' w-[70%]'>
                    <p className="font-hanken-grotesk font-[500] text-[16px] leading-[24px] sm:font-[700] sm:text-[12px] sm:leading-[20px] md:font-[600] md:text-[18px] md:leading-[28px] text-secondary-3">{t("notifications")}</p>
                </div>
                <div className=' flex flex-row justify-between items-center w-[30%]'>
                    <div>
                        {" "}
                    </div>
                    <div>
                        <p className="font-hanken-grotesk font-[500] text-[16px] leading-[24px] sm:font-[700] sm:text-[12px] sm:leading-[20px] md:font-[600] md:text-[18px] md:leading-[28px] text-secondary-3 ml-6">{t("email")}</p>

                    </div>
                    <div>
                        <p className="font-hanken-grotesk font-[500] text-[16px] leading-[24px] sm:font-[700] sm:text-[12px] sm:leading-[20px] md:font-[600] md:text-[18px] md:leading-[28px] text-secondary-3">{t("text")}</p>

                    </div>
                </div>

            </div>

            {/* LIST */}
            {notifications.map((notificationSettingItem) => {
                return (
                    <NotificationConfigureTableItem
                        notificationSettingItem={notificationSettingItem}
                        updateConfigObjectByAlertCode={updateConfigObjectByAlertCode}
                        isReset={isReset}
                    />
                )
            })}


        </div>
    )
}

export default withOidcSecure(NotificationConfigureTable)