
import React, { useState, useEffect } from 'react';
import Loader from './components/CustomComponents/Loader';
import RouterLayout from './router';
import { BrowserRouter as Router } from 'react-router-dom';
import 'animate.css';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Redux/store'; // Import the store and persistor from your Redux storeimport { copyUrlAndSave } from './utils/copyUrlAndSave';
import { copyUrlAndSave } from './utils';
import DisableRightClickHOC from './disableRightClickHOC';
import { clearStorageExceptKey } from './utils';
import { HotKeys } from 'react-hotkeys';
import ScrollTop from './components/CustomComponents/ScrollTop';
import { ISDEV_ENV } from '../src/network/URLConstants';
import { useIdleTimer } from 'react-idle-timer'
import { fetchConfigarationData, retrieveData } from './helpers/utils/Utils';
import { ACCEPT_E_SIGN_AGGREMENT, COVERED_BY_KUDZU, GET_ESIGN_AGREEMENT, GET_ESIGN_STATUS, GET_USER_PORFIE_INFO, SAVINGS_HABIT, SPENDING_ALERT, STATUS_SUCCESS_CODE, VALIDATE_AUTH_USER, END_SESSION, SAVING_CARD_CODE } from "./network/URLConstants";

import { GetApiHeadersWithBearer, GetRequest, PostRequest, endSessionGetRequest } from './network/NetworkUtils';
import { useOidcAccessToken, useOidc, useOidcUser } from "@axa-fr/react-oidc";
const appConfigData = await fetchConfigarationData();
function App() {
  const { user } = useOidcUser();
  const { logout, login } = useOidc();
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const { accessToken, accessTokenPayload } = useOidcAccessToken()
  const dispatch = useDispatch();
  const accessTokenToApi = useSelector(state => state.accessToken);
  const cards = useSelector(state => JSON.parse(state.userCards));

  useEffect(() => {
    removeTrailingHash();
  }, [window.location.href]);
 
  function removeTrailingHash() {
    var currentUrl = window.location.href;
    if (currentUrl.endsWith('#')) {
      var newUrl = currentUrl.slice(0, -1);
      window.location.href = newUrl;
    }
  }
  useEffect(() => {
    if (cards != null && cards.find((item) => (item.AccountTypeBit63 == SAVING_CARD_CODE))) {
      const savingsAvailablePayload = { savingsAccontAvailable: true };
      dispatch({ type: 'SAVINSGS_AVAILABLE', payload: savingsAvailablePayload });
    } else {
      const savingsAvailablePayload = { savingsAccontAvailable: false };
      dispatch({ type: 'SAVINSGS_AVAILABLE', payload: savingsAvailablePayload });
    }


    if (accessToken && accessToken !== null && accessToken !== "") {
      const accessTokenPayload = { accessToken: accessToken };
      dispatch({ type: 'ACCESS_TOKEN', payload: accessTokenPayload });

      const twoFactAuthenticationDone = { twoFactAuthenticationDone: false }
      dispatch({ type: 'TWOFACT_DONE', payload: twoFactAuthenticationDone });
    }
  }, [accessToken])

  const handleOnIdle = event => {
    if (accessToken != null) {
      handleLogout()
    }
  }

  const handleOnActive = event => {
  }

  const handleOnAction = (event) => {
  }
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * appConfigData.IDLE_TIME,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })
  const handleLogout = async () => {

    if (!global.isLoggedIn) {
      return
    }
    global.isLoggedIn = false
    await logout()
    logoutManually();
    const deviceIdKey = 'updatedDeviceId';
    clearStorageExceptKey(sessionStorage, deviceIdKey);
    clearStorageExceptKey(localStorage, deviceIdKey);
    store.dispatch({ type: 'LOGOUT' });
    const loginPayload = { isLoggedIn: false };
    dispatch({ type: 'LOG_IN', payload: loginPayload });

  }

  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const keyMap = {
    disableDevTools: ['f12', 'ctrl+shift+i', 'ctrl+shift+c', 'ctrl+shift+j', 'ctrl+u']
  };
  const handlers = {
    disableDevTools: (event) => {
      event.preventDefault();
    }
  };

  //*****this below commented code will give alert when refresh page and  delete user data */

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);


  if (accessTokenPayload) {
    const targetTimestamp = accessTokenPayload.exp;
    const currentTime = Math.floor(Date.now() / 1000); // Convert current time to Unix timestamp
    console.log('targetTimestamp', targetTimestamp)
    console.log('currentTime', currentTime)
    console.log('timeDifferenceInSeconds', targetTimestamp - currentTime)

    const timeDifferenceInSeconds = targetTimestamp - currentTime;
    // const timeDifferenceInSeconds = 32;

    if (timeDifferenceInSeconds < appConfigData.REFRESH_TIME_BEFORE_TOKENS_EXPIRATION_IN_SECOND) {
      handleLogout()
    }
  }


  const logoutManually = async () => {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    var url = END_SESSION(appConfigData.AUTH_URL, appConfigData.CLIENT_ID, accessTokenToApi, appConfigData.POST_LOGOUT_REDIRECT_URI);
    window.location.replace(url);
  }
  const isSessionExpired = () => {

    return false; // Placeholder implementation
  };


  return (
    // <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <Router>
        <ScrollTop />
        <HotKeys keyMap={keyMap} handlers={handlers}>
          {/* <Header /> */}
          {isLoading ? <Loader /> : <RouterLayout />}
        </HotKeys>
      </Router>
    </PersistGate>
    // </Provider>
  );
}

export default ISDEV_ENV ? App : DisableRightClickHOC(App);
