import React, { useEffect, useState, useRef } from 'react';
import CustomInput from '../../CustomComponents/FormInputs';
import { useTranslation } from 'react-i18next';
import { useKeyDown } from '../../../hooks/useKeyDown';
import { displayErrorMessage, displaySuccessMessage, parseGoogleLocationResponse } from '../../../utils';

const RemoveBenficaryConfirmation = ({ removeAcceptOrcancelClicked }) => {
  const { t } = useTranslation();
  const trustDeviceButtonRef = useRef(null);
  const dontTrustButtonRef = useRef(null);
  const [checkedValues, setCheckedValues] = useState([]);
  const [privacyErromessage, setPrivacyErromessage] = useState('');
  const [checkboxes, setCheckboxes] = useState({
    privacy_user: false,
    deposit_savings: false,
    electronic_aggrement: false,
  });

  const handleCheckboxChange = (event, checkboxKey) => {
    const isChecked = event.target.checked;
    setPrivacyErromessage('');


    if (checkboxKey == 'electronic_aggrement' && event.target.checked) {

    } else {
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [checkboxKey]: isChecked,
      }));
    }


    // Update checkedValues state
    setCheckedValues((prevCheckedValues) => {
      const updatedCheckedValues = [...prevCheckedValues];
      const existingCheckboxIndex = updatedCheckedValues.findIndex((checkbox) => checkbox.checkboxKey === checkboxKey);

      if (existingCheckboxIndex !== -1) {
        updatedCheckedValues[existingCheckboxIndex].checked = isChecked;
      } else {
        updatedCheckedValues.push({ checkboxKey, checked: isChecked });
      }

      return updatedCheckedValues;
    });



  };

  const onClickedtrustDevice = (isTrusted) => {
    if (checkboxes.privacy_user === false) {
      setPrivacyErromessage(t('please_accept_auth'));
      return;
    }
    removeAcceptOrcancelClicked(isTrusted);
  };




  useKeyDown(() => {
    if (checkboxes.privacy_user === false) {
      setPrivacyErromessage(t('please_accept_auth'));
      return;
    }
    removeAcceptOrcancelClicked(true)
  }, ['Enter']);

  return (
    <>
      <div>
        <div className='text-secondary-2 flex flex-col justify-center items-center w-full h-50 '></div>

        <p className="font-hanken-grotesk my-3 text-[#262B3A] lg:text-[20px] md:text-[17px] sm:text-[12px] text-[16px] leading-[30px] font-[500]  w-full">{t('removal_of_this_person')}</p>

        {/* terms checkbox */}
        <div className='mt-2 md:w-[454px] sm:w-[301px] w-full'>
          <div class="flex items-center md:mb-8 sm:mb-6 mb-8 mt-5" tabIndex={1}>
            <input
              id="privacy-user-checkbox"
              type="checkbox"
              class="checkbox-round "
              value=""
              checked={checkboxes.privacy_user}
              onChange={(event) => handleCheckboxChange(event, 'privacy_user')}
              tabIndex={0}
            />
            <label for="privacy-user-checkbox" class="ml-6 md:text-sm sm:text-[12px] text-[14px] font-medium 
            text-[#2965FB] dark:text-gray-300"  tabIndex={1}>{t('authorize_removal')}
            </label>
          </div>
          {privacyErromessage &&
            <div className='mt-2 mb-2'>
              {displayErrorMessage(privacyErromessage)}
            </div>
          }

        </div>

        <button
          autoFocus
          onClick={() => {
            if (checkboxes.privacy_user === false) {
              setPrivacyErromessage(t('check_authorize_removal'));
              return;
            }
            removeAcceptOrcancelClicked(true)
          }
          } className='focus:outline-none   mt-5 font-hanken-grotesk w-full bg-submit-btn hover:bg-submit-btn text-white py-2 px-4 lg:text-[18px] md:text-[17px] 
        sm:text-[15px] text-[16px] font-medium h-[60px] flex justify-center items-center'>
          {t('remove_beneficiary')}
        </button>
        <button
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (checkboxes.privacy_user === false) {
                setPrivacyErromessage(t('please_accept_auth'));
                return;
              }
              removeAcceptOrcancelClicked(false);
            }
          }}
          onClick={() => removeAcceptOrcancelClicked(false)} className=' mt-5 font-hanken-grotesk w-full text-[#2965FB] py-2 px-4 lg:text-[18px] md:text-[17px] 
          sm:text-[15px] text-[16px] font-medium h-[60px] flex justify-center items-center'>
          {t('Cancel')}
        </button>
      </div>
    </>
  );
};

export default RemoveBenficaryConfirmation;
