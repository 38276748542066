import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomInput from '../../CustomComponents/FormInputs';
import Arrow from '../../../assets/images/arrow.png';
import Modal from '../../CustomComponents/Modal';
import addLineBreaks from '../../../utils';
import Card from '../../CustomComponents/Card';
import VerifyEmailCode from '../Signup/EmailVerification';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { EMAIL_OTP_GENERATION, STATUS_SUCCESS_CODE } from '../../../network//URLConstants';
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from "../../CustomComponents/Loader";
import { LOGIN_SESSION_KEY } from "../../../Redux/reducers";
import app_env from '../../../utils/app-config.json';
import { isEmailValid, retrieveData } from '../../../helpers/utils/Utils';
import { displayErrorMessage } from '../../../utils';
import { fetchConfigarationData } from '../../../helpers/utils/Utils';
import { useKeyDown } from '../../../hooks/useKeyDown';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { useNavigate } from 'react-router';
import logFirebaseEvents from '../../LogFirebaseEvents';


const appConfigData = await fetchConfigarationData();
export const CreateAccount = ({ userEmail, openCreatePasswordwithemail ,openisPartner, onCreatePasswordClick}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();


  const schema = yup.object().shape({
    email: yup
      .string()
      // .required(t('please_enter_your_email_address'))
      .email(t('please_enter_a_valid_email_address')),
  });

  const [isSendVerificationModalOpen, setIsSendVerificationModalOpen] = useState(false);
  const [email, setEmail] = useState(userEmail);
  const [isPartnerUniversity,setIsPartnerUniversity] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const deviceId = useSelector(state => state.deviceId);
  const accessToken = useSelector(state => state.accessToken);
  const sessionId = useSelector(state => state.sessionId);
  const userId = useSelector(state => state.userId);
  const [message, setMessage] = useState('');
  const [verifyEmailError, setVerifyEmailError] = useState('');
  const [validEmail, setValidEmail] = useState(false);


  const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";

  const [showAlertModal, setShowAlertModal] = useState(false)
  const [highlightMsg, setHighlightMsg] = useState(false)
  const [headerMsg, setheaderMsg] = useState(false)
  const [subHeaderMsg, setSubHeaderMsg] = useState(false)
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [modelImage, setModelImage] = useState(false)

  //text-white md:text-[31px] md:leading-[38px] sm:text-[18px] sm:leading-[24px] text-[25px] leading-[31px]
  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]";
  const [signUpMailTyagainCount, setsignUpMailTyagainCount] = useState(1);

  const showCustomAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
    setShowAlertModal(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)

  }

  const closeCustomAlertModal = () => {
    setBtn1Text('')
    setShowAlertModal(false);
  }
  const onAcptBtnClick = async () => {
    setShowAlertModal(false)
    if (btn1Text === t('login')) {
      navigate('/');
      window.location.href = window.location.origin;

    }
  }
  const onCancelBtnClick = () => {
    setBtn1Text('')
    setShowAlertModal(false)
 

  }


  useEffect(() => { }, [sessionId, userId])

  const handleCreatePasswordClick = (code) => {

    // setIsSendVerificationModalOpen(false);
    verifyEmailOtp(code);

  };

  const GenerateEmailOtp = () => {
    logFirebaseEvents("Email_Screen", "Email_Continue_Clicked", "Email_Continue_Clicked", "","");
    setMessage("")
    clearErrors()
    const errorMessage = isEmailValid(email)
    if (errorMessage == 'empty') {
      setError('email', {
        type: 'manual',
        message: t('please_enter_your_email_address'),
      });
      // setMessage(t('please_enter_your_email_address'))
    } else if (errorMessage == 'not valid') {
      setError('email', {
        type: 'manual',
        message: t('please_enter_a_valid_email_address'),
      });
      // setMessage(t('please_enter_a_valid_email_address'))
    } else {
      createAccountWithEmail(email);
    } 
  };

  const handleVerifyEmailCode = () => {
    closeSendVerificationModal(); // Close the modal by calling the closeSendVerificationModal function
  };

  const closeSendVerificationModal = () => {
    setIsSendVerificationModalOpen(false);
  };



  // On submit
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      // Perform your login logic here
    } catch (error) {
      // Handle specific errors and set form errors using setError
      setError('email', {
        type: 'manual',
        message: t('please_enter_a_valid_email_address'),
      });
    }
  };
  async function createAccountWithEmail(email) {
    const deviceId = await retrieveData('updatedDeviceId')
    let headers = await GetApiHeadersWithOutBearer(deviceId);
    var requestObject = {
      "applicationId": appConfigData.CLIENT_ID,
      "email": email,
      "language": "en",
      "isLoginid": true
    }
    if (userEmail) {
      requestObject.isLoginid = false;
    }
    var url = EMAIL_OTP_GENERATION + userId + '/emails'
    //need to add loading
    setIsLoading(true);
    PostRequest(url, requestObject, headers).then((response) => {
      let parsedResponse = JSON.parse(response);
      if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {

        debugger; 
        const sessionIdPayload = { sessionId: parsedResponse.Response.sessionId }
        setIsPartnerUniversity(parsedResponse.Response.isPartnerUniversity);
        dispatch({ type: 'SESSION_ID', payload: sessionIdPayload });

        setIsLoading(false);
        setVerifyEmailError('')
        setIsSendVerificationModalOpen(true);


      } else {
        setIsLoading(false);
        setVerifyEmailError('')
        var arrMessages = parsedResponse.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        showCustomAlertModal(true, "Alert", displayText, "", true, true, t('login'), t('try_again'), true, '')

        // setError('email', {
        //   type: 'manual',
        //   message: displayText,
        // });
        // setMessage(displayText);
      }
    })
  }

  async function verifyEmailOtp(code) {
    const deviceId = await retrieveData('updatedDeviceId')
    let headers = await GetApiHeadersWithOutBearer(deviceId);
    var requestObject = {
      "applicationId": appConfigData.CLIENT_ID,
      "code": code,
      "language": "en",
    }
    var url = EMAIL_OTP_GENERATION + userId + '/emails/' + sessionId + '/verify'
    //need to add loading
    setIsLoading(true);
    PostRequest(url, requestObject, headers).then((response) => {
      let parsedResponse = JSON.parse(response);
      if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
        setIsLoading(false);
        onCreatePasswordClick();
        openCreatePasswordwithemail(email,isPartnerUniversity); 
        setIsSendVerificationModalOpen(false);
      } else {
        setIsLoading(false);
        var arrMessages = parsedResponse.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        setVerifyEmailError(displayText);
      }
    })
  }
  async function handleEmailVerifyTryagain() {
    logFirebaseEvents("OTP_Screen", "Email_Verify_Tryagain_Clicked", "Email_Verify_Tryagain_Clicked","", "");
    if (signUpMailTyagainCount >= 4) {
      setIsSendVerificationModalOpen(false);
      setTimeout(() => {
        setVerifyEmailError(t('exceeded_max_limit'));
        setIsSendVerificationModalOpen(true);

      }, 200);
      return;
    }
    setIsSendVerificationModalOpen(false);
    setIsLoading(true)
    const deviceId = await retrieveData('updatedDeviceId')
    let headers = await GetApiHeadersWithOutBearer(deviceId);

    var object = {
      "applicationId": appConfigData.CLIENT_ID,
      "language": "en"
    }
    var url = EMAIL_OTP_GENERATION + userId + '/emails/re-send'

    PostRequest(url, object, headers).then((response) => {
      let parsedResponse = JSON.parse(response);
      if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
        setTimeout(() => {
          const sessionIdPayload = { sessionId: parsedResponse.Response.sessionId }
          dispatch({ type: 'SESSION_ID', payload: sessionIdPayload });
          const tryAgainAccount = signUpMailTyagainCount + 1;
          setsignUpMailTyagainCount(tryAgainAccount);
          setVerifyEmailError('')
          setIsLoading(false)
          setIsSendVerificationModalOpen(true);
        }, 500);

      } else {
        setTimeout(() => {
          var arrMessages = parsedResponse.Messages;
          var message = arrMessages[0];
          var displayText = message.DisplayText;
          setVerifyEmailError(displayText);
          setIsLoading(false)
        }, 500);
        setIsSendVerificationModalOpen(true);

      }
    })

  }

  const handleChange = async (event) => {
    setMessage('');
    clearErrors();

    const { name, value } = event.target;
    setEmail(value);
    try {
      await schema.validate({ [name]: value });
      setValidEmail(true);
      setEmail(value);
    } catch (error) {
      setValidEmail(false);
    }
  };
  // useKeyDown((event) => {
  //   if (isSendVerificationModalOpen == false) {
  //     GenerateEmailOtp();
  //   }

  // }, ["Enter"]);

  return (
    <>
      <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0">
        <Card
          heading=
          {
            <>
              <span className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)]
             lg:text-[31px] 
            md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] 
            md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('create_account_headin1')}</span>
              <span className='font-serif-pro sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)] lg:text-[31px]
             md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('create_account_headin2')}</span>
            </>}
          subheading={addLineBreaks(t('create_account_headin3'), SubTittleStylesModal)}
          Cardcaption={t('create_account')} header={true} footer={true} CardRadius={true} >
          <div className="flex flex-col  w-full h-246 mt-28">
            <div className="flex flex-col">
              <CustomInput
                name="email"
                label="Email Address"
                register={register}
                error={errors.email}
                type="text"
                textInputValue={email}
                onChange={handleChange}
                isValid={validEmail}
                autoFocus={true}
                onKeyDownFun={(event) => { if (event.key == "Enter") { event.preventDefault(); GenerateEmailOtp(); } }}
              />

              {/* <p className='font-semibold'>{addLineBreaks(t('choose_register'))}</p> */}


              {/* {message &&
              <div className='mb-2'>
                {displayErrorMessage(message)}
              </div>
            } */}
              <div className="flex justify-end items-center mt-20">
                <button
                  type="button"
                  className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center"
                  onClick={GenerateEmailOtp}
                  onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); GenerateEmailOtp(); } }}
                // Call handleContinue instead of openPhoneOTPModal directly
                >
                  {t('continue')}
                  <img
                    src={Arrow}
                    className="md:w-[17px] sm:w-[11px] md:h-[17px] sm:h-[11px] w-[12px] h-[12px] ml-2 animate__animated animate__flash animate__repeat-4"
                    alt="Arrow"
                  />
                </button>
              </div>
              {/* <div className="flex justify-end items-center mt-20">
              <button
                type="button"
                className="font-hanken-grotesk font-bold text-x-ss text-secondary-2 cursor-pointer flex items-center"
                onClick={GenerateEmailOtp}
              >
                {t('continue')}
                <img src={Arrow} className="w-3 h-3 ml-2 animate__animated animate__flash animate__repeat-4" alt="Arrow" />
              </button> 
            </div> */}
              {isSendVerificationModalOpen && <Modal
                isOpen={isSendVerificationModalOpen}
                onClose={closeSendVerificationModal}
                isLogo={true}
                title={<span className="font-serif-pro modal-title font-normal  text-[#2965FB] mb-5 sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px]"> {t('send_email_verify_code_great')}</span>}
                subtitle={<span className="font-serif-pro text-secondary-3 font-normal  sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px]">{addLineBreaks(t("send_email_verify_code_tittle"))}</span>}
                content={<div class="aspect-454/253 mt-10"><VerifyEmailCode errorMesssage={verifyEmailError} CloseModalOnVerify={handleVerifyEmailCode} onCreatePasswordClick={handleCreatePasswordClick} onClickEmailVerifyTryAgain={handleEmailVerifyTryagain} /></div>}
              />}
              <CustomAlertModel isOpen={showAlertModal}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
              />
            </div>
          </div>
        </Card>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default CreateAccount;
