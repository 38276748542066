import React, { useState } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { height, width } from "../CustomComponents/DynamicCard/DynamicCardStyles";
import { addLineBreaks } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HomeLayout from '../Layout/HomeLayout';
import LeftBar from '../CustomComponents/DynamicComponent/LeftBar';
//import DynamicCard from '../CustomComponents/DynamicComponent/DynamicCard.js';
import DynamicCard from '../CustomComponents/DynamicCard';
import HelpIcon from '../../assets/images/illo-help (1).png'
import FindATM from '../../assets/images/illo-ATM.png'
import { useNavigate } from 'react-router';
import FooterContent from '../CustomComponents/Footer';
import { HelpSidebar } from '../../utils';
import Loader from '../CustomComponents/Loader';
import RightArrow from '../../assets/images/Caret S.png'
import { Link } from 'react-router-dom';


const Help = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const [isLoading, setIsLoading] = useState(false); 
    
    const subMenuList = [
        {
            name: t("helpfaq"),
            url: "/Help/FAQ"
        },
        {
            name: t("findATMBtn"),
            url: "/Help/FindATM"
        },
        {
            name: t("conatctus"),
            url: "/Help/ContactUs"
        },
    ]

    const helpListItems = 'font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px] text-[#FEFAEE]'
    const [isLoading, setIsLoading] = useState(false);

    const helpChildren1 = () => {
        return (
            <>
                <div className="sm:flex block flex-row justify-between md:pt-20 pt-16 sm:pt-10 lg:pb-36 md:pb-32 sm:pb-20 pb-5 md:mx-0 mx-4">
                    {/* <div className="sm:flex flex-row justify-between md:pt-20 pt-16 lg:pb-40 md:pb-36 sm:pb-28 sm:mx-0 mx-4"> */}
                    <div className='flex flex-col lg:w-[406px] md:w-[321px] sm:w-[263px] w-full'>

                        <p className="text-[#262B3A] sm:text-[#559C7E] font-normal
                        leading-[30px] md:leading-[24px] lg:text-[32px]
                         md:text-[28px] sm:text-[18px] text-[25px] lg:leading-[38px] 
                         font-serif-pro">{t("helpCardHeading")}</p>

                        {/* <p className='font-hanken-grotesk font-[500]
                         lg:leading-[30px] md:leading-[26px] sm:leading-[20px] leading-[25px] lg:text-[20px] md:text-[16px]
                         sm:text-[13px] text-[16px] md:mt-6 mt-4
                         text-[#0C4437]'>{t("helpsubHeading")}</p> */}

                        <p className='font-hanken-grotesk font-[400] sm:font-[500] md:leading-[30px] 
                        sm:leading-[20px] leading-[24px]  md:text-[20px] sm:text-[12px] 
                         text-[16px] md:mt-6 mt-4 text-[#262B3A] sm:text-[#0C4437]
                        '>{t("helpsubHeading")}</p>
                    </div>
                    <div>
                        <img src={HelpIcon} alt="help" className='sm:block hidden lg:w-[120px] lg:h-[120px]
                         md:w-[100px] md:h-[100px] w-[92px] h-[92px]' />
                    </div>
                </div>
                <div className='sm:hidden block px-4 py-10'>
                    {/* <div className='w-full'>
                        <div class="sm:flex w-full md:mb-[3rem] sm:mb-8">
                            <div class="sm:w-[50%] w-full sm:mb-0 mb-5">
                                <div class="sm:w-[90%] w-full md:pb-[1.5rem] 
                                pb-[1.3rem] flex justify-between items-center 
                                border-b-[3px] border-[#E7E4DC] cursor-pointer border-opacity-[75%]">
                                    <p class="font-[600] text-[#262B3A] lg:text-[16px] 
                                    md:text-[15px] sm:text-[11px] text-[14px] leading-[16px]
                                     font-hanken-grotesk">About Kudzu</p><p>
                                        <img src={RightArrow}
                                            class="w-[7px] mr-[10px]" /></p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {subMenuList.map((item, index) => {
                        return (
                            <Link to={item.url} key={index}>
                                <div className='w-full'>
                                    <div class="sm:flex w-full md:mb-[3rem] sm:mb-8">
                                        <div class="sm:w-[50%] w-full sm:mb-0 mb-5">
                                            <div
                                                class="sm:w-[90%] w-full md:pb-[1.5rem] pb-[1.3rem] flex justify-between items-center border-b-[3px] border-[#E7E4DC] cursor-pointer border-opacity-[75%]"
                                                onClick={() => { 
                                                    navigate(item.url); 
                                                }}
                                            >
                                                <p class="font-[600] text-[#262B3A] lg:text-[16px] md:text-[15px] sm:text-[11px] text-[14px] leading-[16px] font-hanken-grotesk">{item.name}</p>
                                                <p>
                                                    <img src={RightArrow} class="w-[7px] mr-[10px]" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}

                </div>
            </>
        )
    }

    const footerContent = () => {
        return (
            <FooterContent highlightText={t("findNoFeeATM")}
                subHeaderText={t("findATMSubHeading")}
                type={t("findNoFeeATM")}
                isSubheadingwidth={false}
                image={FindATM}
                buttonEnabled={true}
                buttonText={t("findATMBtn")}
                footerBtnAction={() => navigate("/Help/FindATM")} />
        )
    }

    return (
        <>
            <HomeLayout>
                <HelpSidebar />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>

                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        HeaderContent={false}
                        isWhite={true}
                        footer={true}>
                        {helpChildren1()}
                    </DynamicCard>
                    {footerContent()}
                </div>
            </HomeLayout>
            {isLoading && <Loader />}
        </>

    )
}

export default withOidcSecure(Help)