import React, { useState, useEffect } from 'react'
import { width } from '../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import CustomTextInput from '../../../../CustomComponents/CustomtextInput'
import RightArrow from "../../../../../assets/images/RightArrow@2x.png"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../CustomComponents/Loader'
import DynamicCard from '../../../../CustomComponents/DynamicCard'
import { withOidcSecure } from '@axa-fr/react-oidc'
import ConfirmationModuleSubItem from './ConfirmationModuleSubItem'
import checkbox_fill from "../../../../../assets/images/Checkbox-blue.png"
import checkbox_null from "../../../../../assets/images/Checkbox-light.png"
import CustomRadioButton from '../../../../CustomComponents/CustomRadioButton'
import moment from 'moment'
import { GetApiHeadersWithBearer, PostRequest } from '../../../../../network/NetworkUtils'
import { retrieveData } from '../../../../../utils'
import { ADD_SPENDING_ALERT, STATUS_SUCCESS_CODE } from '../../../../../network/URLConstants'
import { getFrequencyAbbreviation } from '../../../../../utils'
import logFirebaseEvents from '../../../../LogFirebaseEvents'


const ConfirmationCard = ({ cardHeader, cardFooter, onBack, onEdit, onSave, onSetSpendSenseAlertFun }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [NotificationError, setNotificationError] = useState('')
  const NotificationTypeSMS = useSelector(state => state.spendAlertsPayload.sms)
  const NotificationTypeEmail = useSelector(state => state.spendAlertsPayload.email)
  const [notificationArray, setNotificationArray] = useState([{ 'displayText': t('text'), 'isSelected': NotificationTypeSMS }, { 'displayText': t('email'), 'isSelected': NotificationTypeEmail }])



  const setNotificationFun = (option) => {
    setNotificationError('');
    const selectedIndex = notificationArray.findIndex(
      (item) => item.displayText === option.displayText
    );
    notificationArray[selectedIndex] = { ...option, 'isSelected': !option?.isSelected }
    setNotificationArray([...notificationArray])
    if (option.displayText == "Email") {
      dispatch({
        type: 'ADD_SPEND_ALERTS',
        payload: { 'key': 'email', 'value': !option?.isSelected }
      });
    } else {
      dispatch({
        type: 'ADD_SPEND_ALERTS',
        payload: { 'key': 'sms', 'value': !option?.isSelected }
      });

    }
  }

  const AddedAmount = useSelector(state => state.spendAlertsPayload.amount);
  const Frequency = useSelector(state => state.spendAlertsPayload.frequencyType);
  const selectedDateCopy = useSelector(state => state.spendAlertsPayload.startDate);
  const StartDate=((new Date(selectedDateCopy).getDate() - new Date(new Date().toUTCString().substr(0, 25)).getDate()) === 1) ? moment(selectedDateCopy).format('[Tomorrow], dddd, MMMM DD, YYYY') : moment(selectedDateCopy).format('dddd, MMMM DD, YYYY') 

  const RequestObject = useSelector(state => state.spendAlertsPayload);
  const [activeIndex, setActiveIndex] = useState(-1); // Keep track of the active index
  const cards = useSelector(state => JSON.parse(state.userCards));
  const accessToken = useSelector(state => state.accessToken);


  const onEdit1 = (value, type, index) => {  
    setActiveIndex(index); // Set the active index to the clicked index 
  };


  const datas = [
    {
      'title': 'Amount',
      'value': AddedAmount,
      'edit': 'Edit Amount',
      'onEdit': () => onEdit1(AddedAmount, "amount", 0),
      "type": 'amount'

    },
    {
      'title': 'FREQUENCY',
      'value': Frequency,
      'edit': 'Edit Frequency',
      'onEdit': () => onEdit1(Frequency, "timeFrame", 1),
      "type": 'frequencyType'
    },
    {
      'title': 'Start',
      'value': StartDate,
      'edit': 'Edit Date',
      'onEdit': () => onEdit1(StartDate, "startDate", 2),
      "type": 'startDate'
    }




  ]

  const handleBackClick = () => {
    logFirebaseEvents("SpendSense_Alert", "Back_Save_SpendSense_Alert_Clicked", "Back_Save_SpendSense_Alert_Clicked","", "")
    onBack("notificationType")
  }
  const handleSaveAlertsClick = async () => {
    logFirebaseEvents("SpendSense_Alert", "Save_SpendSense_Alert_Clicked", "Save_SpendSense_Alert_Clicked","", "")
    if (!RequestObject.sms && !RequestObject.email) {
      setNotificationError('Choose Notification Type');
      return;
    }
    const modifiedData = {
      ...RequestObject,
      frequencyType: getFrequencyAbbreviation(RequestObject.frequencyType),
      startDate: moment(RequestObject.startDate).format('YYYY-MM-DD'),
    };
    const deviceId = await retrieveData('updatedDeviceId')
    const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
    const url = ADD_SPENDING_ALERT(cards[0].CardKey);
    const response = await PostRequest(url, modifiedData, headers);
    const responseJson = JSON.parse(response);
    if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
      onSave("success")
    } else {
      if(responseJson?.Messages.length>0){
        console.log("-------",responseJson.Messages[0].DisplayText)
        onSetSpendSenseAlertFun(responseJson.Messages[0].DisplayText)
      }
      onSave("error")
    }
  }


  const children = () => {
    return (
      <>
        {cardHeader}
        <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4 sm:flex sm:flex-col '>
          <span>{t("spending_alert_confirm_content1")} </span>
          <span className='text-secondary-3 lg:w-[452px] md:w-[424px] sm:w-[358px] 
          w-[264px]'>{t("spending_alert_confirm_content2")}</span>
        </div>

        <div className='w-full flex justify-center'>
          <div className="md:w-[80%] sm:w-[85%] w-full">
            {/* BODY */}
            <div className='lg:mb-[6rem] md:mb-[4rem] sm:mb-[3rem] mb-[4rem] sm:mx-0 mx-4'>
              <div className='sm:w-[80%] w-full flex flex-col justify-center'>
                {datas &&
                  datas.map((data, index) => {
                    return (
                      <ConfirmationModuleSubItem
                        key={index}
                        data={data}
                        onEdit={() => data.onEdit()} // Pass the onEdit function from data
                        isActive={activeIndex === index} // Pass whether it's active or not
                      />
                    )
                  })
                }

                <div className='mt-10 md:w-[60%]'>
                  {/* <div className='lg:py-20 md:py-10 sm:py-10 py-12'> */}
                  <CustomRadioButton
                    title={t('spending_alert_notification_title')}
                    options={notificationArray}
                    onSelect={setNotificationFun}
                    fillIcon={checkbox_fill}
                    nullIcon={checkbox_null}
                   error={NotificationError}
                  />

                  <span className="font-hanken-grotesk text-[12px] leading-[20px] 
                font-normal">{t('sms_rating')}</span>
                </div>
              </div>
            </div>



          </div>
        </div>
        {/* FOOTER */}
        {cardFooter(handleBackClick, handleSaveAlertsClick, false, `${t("save_alert")}`)}

        {/* ---------- */}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>

      {isLoading && <Loader />}
    </>
  )
}


export default withOidcSecure(ConfirmationCard)


