import React, { useEffect, useState } from "react";
import { HeaderCaption, Space, Title, Subtitle, Main, CardSection, Container, CardTitle, CardFooter, CardHeader } from "./DynamicHomeCardStyles";
const DynamicCard = ({ CardRadius, HeaderRadius,mblCardRadius, heading, isHome, subheading, isActive, Cardcaption, Cardsubcaption, children, header, HeaderContent, footer, cardHeight, footerContent, footerBackgroundColor, cardWidth, isPerk, isProfile, isWhite, backgroundColor }) => {

  return (
    <>
      <Main backgroundColor={backgroundColor}>
        <CardSection CardRadius={CardRadius} mblCardRadius={mblCardRadius} isPerk={isPerk} isProfile={isProfile} isWhite={isWhite}>
          {header &&
            <CardHeader backgroundColor={backgroundColor} HeaderRadius={HeaderRadius} isActive={isActive}  >
              {HeaderContent}
            </CardHeader>
          }
          <Container style={{ width: cardWidth }}>
            <CardTitle isPrimary>{Cardcaption}</CardTitle>
            {children}
          </Container>
          {footer &&
            <CardFooter backgroundColor={footerBackgroundColor}>
              {footerContent}
            </CardFooter>
          }
        </CardSection>
      </Main>
    </>
  );
};

export default DynamicCard;