import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomTextInput from '../../../../../../CustomComponents/CustomtextInput';
import { useTranslation } from 'react-i18next'
import CustomDropdown from '../../../../../../CustomComponents/CustomDropDown';
import CustomDatePicker from '../../../../../../CustomComponents/CustomDateInput';
import moment from 'moment';
import { withOidcSecure } from '@axa-fr/react-oidc';
import dayjs from 'dayjs';
import Calender from '../../../../../../../../src/assets/images/calender_3x.png'
import { handleAmountInputLimitTo2decimal } from '../../../../../../../utils';


const ConfirmationModuleSubItem = ({ data, onEdit, isActive }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editedValue, setEditedValue] = useState(data.value);
  const [isEditing, setIsEditing] = useState(false);
  const [frequencyArray, setFrequencyArray] = useState([{ 'Type': t('weekly'), 'Name': t('weekly') }, { 'Type': t('monthly'), 'Name': t('monthly') }])
  const [frequency, setFrequency] = useState(null)
  const [error, setError] = useState('');
  const errorMessages = {
    Amount: 'Amount is required',
    Frequency: 'Frequency type is required',
    StartDate: 'Start date is required'
  };


  // let updatedValue = useSelector(state => state.savingsHabitData[data.type]);

  // if (data.type === 'StartDate') updatedValue = moment(updatedValue).format('dddd, MMMM DD, YYYY')




  const handleSave = () => {
    debugger;
    if (!editedValue) {
      setError(errorMessages[data.type]);
    } else {
      setError('');
      setIsEditing(false);
      dispatch({ type: 'SAVINGS_HABIT_DATA', payload: { 'key': data.type, 'value': editedValue } });
      // setEditedValue(updatedValue)
      
    }
  };

  const handleOnBlur = (e) => {

    if (!editedValue) {
      setError(errorMessages[data.type]);
    } else if (data.type === "Amount" && editedValue <= 0) {
      setError('Please enter amount greater than 0');
    }
    else {
      setError('');
    }
  }


  return (
    <>
      {data.value && (
        <div className='flex flex-col justify-center md:mt-12 sm:mt-10 mt-12'>
          {!isEditing && (
            <p className='font-hanken-grotesk text-[#262B3A] 
            uppercase lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]
            font-[600] tracking-[2px] md:pb-8 pb-4
            opacity-[50%]'>
              {data.title}
            </p>
          )}
          {isEditing ? (
            <>
              {data.type === 'Amount' ? (
                <CustomTextInput
                  name={t("amount")}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      event.target.style.opacity = 0.8;
                      setTimeout(() => {
                        event.target.style.opacity = 1;
                      }, 200);
                    }
                  }}
                  value={editedValue}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const formattedAmount=handleAmountInputLimitTo2decimal(inputValue)
                    setEditedValue(formattedAmount.toString().replace('$', ''))
                    if (!formattedAmount) setError('Enter Amount');
                    else if (inputValue <= 0) {
                      setError('Please enter amount greater than 0')
                    }
                    else setError('')
                  }}
                  placeholder={"$ 0"}
                  onBlur={(event) => handleOnBlur(event)}
                  inputWidth='w-full'
                  error={error}
                />
              ) : data.type === 'Frequency' ? (

                <CustomDropdown
                  isScroll={false}
                  options={frequencyArray}
                  title="FREQUENCY"
                  onSelect={(option) => { setEditedValue(option.Name) }}
                  selectedFrequency={editedValue}
                  error={error}
                />

              ) : data.type === 'StartDate' ? (
                <>

                  <CustomDatePicker
                    selectedDate={data.value}
                    onDateChange={(date) => setEditedValue(date)}
                    minDate={new Date(new Date().toUTCString().substr(0, 25))}
                    //maxDate={''} 
                    disabledDates={[]}
                    title="Start"
                    hrbg='bg-dropdown-sky'
                  />
                  <div>{error}</div>
                </>


              ) : null}
              <p
                className='pt-5 font-hanken-grotesk font-[700] 
                cursor-pointer text-[#2965FB] lg:text-[16px] md:text-[15px] 
                sm:text-[13px] text-[13px]'
                onClick={handleSave}
                tabIndex={0}
                onKeyDown={(e) => { if (e.key === "Enter") handleSave() }}
              >
                {t("save")}
              </p>
            </>
          ) : (
            <>
              <p className={`font-hanken-grotesk lg:text-[16px] md:text-[15px] sm:text-[13px] 
 text-[14px] font-medium leading-[26px] md:mb-6 mb-2 `}>
                {/* <p className={`font-hanken-grotesk lg:text-[16px] md:text-[15px] sm:text-[13px] 
 text-[14px] font-medium leading-[26px] md:mb-6 mb-2 ${data.title === 'Start' && 'flex justify-between'}`}> */}
                {data.title === 'Amount' ? `$ ${data.value}` : data.value}
                {/* {data.title === 'Amount' ? `$ ${updatedValue}` : updatedValue} */}
                {/* {data.title === 'Start' && <div> <img src={Calender} alt="Calender" className='w-3 h-3 mr-2 ml-10' /></div>} */}
              </p>

              <hr className='h-[2px] bg-[#94C3E8] border-0 rounded border-opacity-[50%]' />
              <p
                className='pt-5 font-hanken-grotesk font-[700] 
                cursor-pointer text-[#2965FB] lg:text-[16px] md:text-[15px] 
                sm:text-[13px] text-[13px]
               '
                onClick={() => setIsEditing(true)}
                tabIndex={0}
                onKeyDown={(e) => { if (e.key === "Enter") setIsEditing(true) }}
              >
                {data.edit}
              </p>

            </>
          )}
        </div>
      )}

    </>


  )
}

export default withOidcSecure(ConfirmationModuleSubItem)