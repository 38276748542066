
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { store } from '../../Redux/store';

export async function getDeviceId() {
    let deviceId = uuidv4();
    return deviceId;
}

// export const fetchConfigarationData = async () => {
//     const response = await fetch(`${process.env.PUBLIC_URL}/app-config.json`);
//     const data = await response.json();
//     return data;

//   };
export async function fetchConfigarationData() {
    const configdatafromRedux = store.getState().appConfigData;
    if (configdatafromRedux !== null) {
        return configdatafromRedux;
    } else {
        const response = await fetch(`${process.env.PUBLIC_URL}/app-config.json`);
        const data = await response.json();
        const appConfigPayload = { appConfigData: data };
        store.dispatch({ type: 'STORE_APPCONFIGDATA', payload: appConfigPayload });
        return data;
    }
}



export async function storeData(key, value) {
    var isStored = false;
    try {
        localStorage.setItem(key, JSON.stringify(value));
        isStored = true;
        return isStored;
    } catch (error) {
        return isStored;
    }
}
export const isEmailValid = (email) => {
    if (!email || email.trim() === '') {
        return "empty";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        return "not valid"
    }
    return "valid";
};


export async function storeDataInRedux(key, value) {
    this.props.dispatch({ type: key, payload: value });
}
export async function retrieveData(key) {
    const value = localStorage.getItem(key);
    if (value !== null) {
        return JSON.parse(value);
    }
    else
        return null;
}
 
//check email extension 

export function checkEmailExtension(email){
    debugger;
    const allowedExtensions = ['.edu'];
    const domain = email.substring(email.lastIndexOf('.'));
    return allowedExtensions.includes(domain); 
}



export const autoFocusFunction = (fieldArray, getValues, setFocus) => {
    const emptyField = fieldArray.find((item) => !getValues(item))
    setFocus(emptyField);
}

// (?=.*?[#@$?])

export const generateRegexExpression = (policy) => {

    let pattern = "";
    if (policy?.UppercaseLength > 0) pattern += `(?=(?:.*?[A-Z]){${policy?.UppercaseLength},})`;
    if (policy?.LowercaseLength > 0) pattern += `(?=(?:.*?[a-z]){${policy?.LowercaseLength},})`;
    if (policy?.NonAlphaLength > 0) pattern += `(?=(?:.*?[^a-zA-Z0-9]){${policy?.NonAlphaLength},})`;
    // if (policy?.NonAlphaLength > 0) pattern += `(?=(?:.*?[!@#$%^&*()_+-={}\\[\\]\\|;:'\",.<>/?\`~]){${policy?.NonAlphaLength},})`;
    if (policy?.NumericLength > 0) pattern += `(?=(?:.*?\\d){${policy?.NumericLength},})`;
    if (policy?.AlphaLength > 0) pattern += `(?=(?:.*?[A-Za-z]){${policy?.AlphaLength},})`;
    pattern = `^${pattern}.{${policy?.MinimumLength},${policy?.MaximumLength === 0 ? 1000 : policy?.MaximumLength}}$`;
    return new RegExp(pattern);
}
export const generateRegexExpression1 = (criteria, policy) => {

    let pattern = "";
    switch (criteria) {
        case "Uppercase":
            pattern += `(?=(?:.*?[A-Z]){${policy.UppercaseLength},})`;
            break;
        case "Lowercase":
            pattern += `(?=(?:.*?[a-z]){${policy.LowercaseLength},})`;
            break;
        case "NonAlpha":
            pattern += `(?=(?:.*?[!@#$%^&*()_+-={}\\[\\]\\|;:'\",.<>/?\`~]){${policy.NonAlphaLength},})`;
            break;
        case "Numeric":
            pattern += `(?=(?:.*?\\d){${policy.NumericLength},})`;
            break;
        case "Alpha":
            pattern += `(?=(?:.*?[A-Za-z]){${policy.AlphaLength},})`;
            break;
        case "MinMax":
            pattern += `.{${policy?.MinimumLength - 1},${policy?.MaximumLength === 0 ? 1000 : policy?.MaximumLength}}`
            break;
        case "Special":  // Add this case for special characters
            pattern += `(?=(?:.*?[^a-zA-Z0-9]){${policy.NonAlphaLength},})`;
            break;

        default:
            break;
    }

    pattern = `^${pattern}.+$`;
    return new RegExp(pattern);
}

export const APP_ENV = 'APP_ENV';
export const BIOMETRIC = "biometric";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const USERNAME = "USERNAME";
export const REFRESH_TOKEN = "REFRESH_TOKEN"
export const IS_TRUSTED_DEVICE = "IsTrustedDevice";
export const USER_PROFILE = "userProfile";
export const USER_CARDS = "userCards";
export const USER_ACTIVITIES = "userActivities";
export const USER_LANGUAGE = "userLanguage";
export const SPENDING = "Spending"
export const SAVING = "Saving"
export const SAVINGS = "Savings"

export const FaceID = "Face ID"
export const TouchID = "Touch ID"
export const Biometric = "Biometric"
export const FingerPrint = "Fingerprint Recognition"
export const APP_BG_TIME = "APP_BG_TIME";
export const APP_BG_TIME_IN_SECS = 1800;
export const CHECKING = "Checking";
export const AMOUNT_MAX_FILTERS = 500;
export const AMOUNT_MIN_FILTERS = 0;
export const TIME_MAX_FILTERS = 6;
export const TIME_MIN_FILTERS = 0;
export const SLIDER_MIN_POSITION = -10;
export const SLIDER_MAX_POSITION = 230;
export const SPENT_AMOUNT_DEFAULT_DAYS = 7;
export const MIN_PASSWORD_LENGTH = 1
export const PENDING = "Pending"
export const DEVICE_VERIFICATION = "DeviceVerification";
export const LOGIN = "Login";
export const MAX_RETRY_COUNT = 3;
export const TNC = "TnC";
export const PRIVACY_POLICY = "PrivacyPolicy";
export const ESIGN = "ESign";
export const ACH = "ACH"
export const SAVING_CARD_CODE = 32;
export const CHECKING_CARD_CODE = 64
