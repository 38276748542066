import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Arrow from '../../../../assets/images/arrow.png';
import { useTranslation } from 'react-i18next';
import Card from '../../../CustomComponents/Card';
import CustomInput from '../../../CustomComponents/FormInputs';
import addLineBreaks from '../../../../utils';
import { formatDate, parseGoogleLocationResponse, formatDateYYYYMMDD } from '../../../../utils';
import { GOOGLE_PLACES_AUTO_COMPLETE, GOOGLE_PLACE_DETAILS_WITH_PLACE_ID, VALIDATE_ALL_DETAILS_OF_USER, STATUS_SUCCESS_CODE, } from '../../../../network/URLConstants';
import { GetRequest, GetApiHeadersWithOutBearer, PostRequest } from '../../../../network/NetworkUtils';
import { useSelector, connect, useDispatch } from 'react-redux';
import { height } from "../../../CustomComponents/Card/CardStyles";
import app_env from '../../../../utils/app-config.json';
import { displayErrorMessage } from '../../../../utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import SecureIcon from '../../../../assets/images/secure.png';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import InputMask from 'react-input-mask';
import Check from '../../../../assets/images/Check.png';
import Error from '../../../../assets/images/Error.png';

import Showpassword from '../../../../assets/images/showpassword.png'
import Hidepassword from '../../../../assets/images/hidepassword.png'

const appConfigData = await fetchConfigarationData();
const validationSchema = yup.object().shape({
  firstName: yup.string().required('Legal first name is required'),
  lastName: yup.string().required('Legal last name is required'),
  preferredName: yup.string(),
  dob: yup.string().required('Date of birth is required'),
  ssn_no: yup.string().required('Social Security Number (SSN) is required'),
  address: yup.string().required('Address is required'),
  apt_etc: yup.string(),
  city: yup.string().required('Please enter a city'),
  state: yup.string().required('Please enter a state'),
  zip_code: yup.string().required('Please enter a valid zip code'),
});

export const PersonalInfo = ({ openPersonalInfoSummaryClick,backToStudenEmailVerification, openTermsandConditionsonClick, getDataFromPersonalInfo, email, password,handleOpenCreatePassword }) => {
  const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue lg:text-[16px] md:text-[15px] text-[14px] placeholder:font-semibold font-medium focus:outline-none w-full h-[60px]";

  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const [isSSNValid, setIsSSNValid] = useState(false);
  const [typedValue, setTypedValue] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [selectedCity, setCity] = useState('');
  const [selectedState, setState] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [DOBError, setDOBError] = useState('');
  const [AddressError, setAddressError] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [SSNoError, setSSNoError] = useState('');
  const [finalDataToPass, setFinalDataToPass] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [ssn, setSSN] = useState('')
  const [ssnBackup, setSSnBackUp] = useState('')
  const [isCursorVisible, setCursorVisibility] = useState(false);
  const calendarInitialValue=new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())

  const [intialHighlightDate, setInitialHighlightDate]= useState()


  //
  const [selectedStreet, setStreet] = useState('');
  const [selectedRoute, setRoute] = useState('');
  const [selectedAddressLine2, setAddressLine2] = useState('');
  const [selectedLocality, setLocality] = useState('');
  const [selectedAdministartoeLevel1, setAdministartoeLevel1] = useState('');
  const [selectedPostalCode, setZipCode] = useState('');
  const [isAddress, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const providerIdentifier = useSelector(state => state.providerIdentifier);

//student university deatils 
  


  const provider = useSelector(state => state.provider);

  ///form data
  const [completeFormData, setcompleteFormData] = useState('');
  const ssnRef = React.createRef();


  const userId = useSelector(state => state.userId);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    // dob: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
    address: '',
    city: '',
    state: '',
    zip_code: '',
    apt_etc: '',
    ssn_no: '',
    preferredName: ''
  });

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);


  const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
    mode: 'onTouched' ,
    resolver: yupResolver(validationSchema),
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    handleSSNValue(ssnBackup)
  }, [showPassword])
  const toggleCursor = () => {
    setCursorVisibility(!isCursorVisible);
  };
  // useEffect(() => { console.log('suggestions', suggestions) }, [suggestions])
  const handleInputChange = (event) => {
    clearErrors();
    setFirstNameError('');
    setLastNameError('');
    setDOBError('');
    setAddressError('');
    setSSNoError('');
    setCityError('');
    setStateError('');
    setZipCodeError('');
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };
  const handleDateChange = (date) => {
    //debugger;
    clearErrors();
    setFirstNameError('');
    setLastNameError('');
    setDOBError('');
    setAddressError('');
    setSSNoError('');
    setCityError('');
    setStateError('');
    setZipCodeError('');
    const today = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    if (date > eighteenYearsAgo) {
      // Date of birth is less than 18 years ago
      // Handle the validation error as needed
      setDOBError('Date of birth should be greater than 18 years ago');
      return;
    }

    if (date > today) {
      // Date of birth is in the future
      // Handle the validation error as needed
      setDOBError('Date of birth cannot be in the future');
      return;
    }
    //debugger;
    clearErrors('dob'); // Clear the dob field error if it was previously set
    setValue('dob', formatDateYYYYMMDD(date));
    setDOBError('');
    setFormValues((prevValues) => ({
      ...prevValues,
      dob: date
    }));
    setInitialHighlightDate(date)
  };
  function formatValue(value) {
    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{2})(\d{1,2})/, '$1 - $2 - $3');

    return formattedValue;
  }

  function formatSecuredValue(value) {
    const securedValue = value
      .replace(/[^0-9•]/g, '')
      .replace(/((?:\d|.){3})((?:\d|.){2})((?:\d|.){1,2})/, '$1 - $2 - $3')
      .replace(/[0-9]/g, '•');

    return securedValue;
  }

  const handleSSNChange = (event) => {
    //debugger;
    const inputDate = event.target.value;
    // Remove all non-digit characters from the input
    const ssnNo = inputDate.replace(/[*-]/g, '');
    setSSnBackUp(ssnNo)
    handleSSNValue(ssnNo)
    setSSNoError('');
    // Remove dashes from the input 
    if (ssnNo.length === 9) {
      // setError('ssn_no', { type: 'manual', message: 'Social Security Number (SSN) should be 9 digits' });
      //return;
      setSSNoError('');
      handleInputChange(event); // Update the form values
      ValidateSSN(ssnNo, formValues); // Pass the SSN value and form values to the ValidateSSN function
    } else {

    }
  };

  function handleSSNValue(value) {
    const formatted = showPassword ? formatSecuredValue(value) : formatValue(value)
    setSSN(formatted)
  }
  const ValidateSSN = async (ssnNo, formValues) => {
    // if (ssnNo)
    // var ssn = ssnNo.replace(/-/g, '');
    let headers = await GetApiHeadersWithOutBearer();

    // var requestObj = {
    //   "applicationId": appConfigData.CLIENT_ID,
    //   "username": email,
    //   "language": "en",
    //   "firstName": formValues.firstName,
    //   "lastName": formValues.lastName,
    //   "middleInitial": "",
    //   // "ssn": ssn,
    //   "dateOfBirth": formatDateYYYYMMDD(formValues.dob),
    //   "line1": selectedStreet + " " + selectedRoute,
    //   "line2": formValues.apt_etc,
    //   "city": selectedLocality,
    //   "state": selectedAdministartoeLevel1,
    //   "zipCode": selectedPostalCode,
    // }

    // if (password !== '') {
    //   requestObj.password = password;
    // }
    // if (providerIdentifier != null && providerIdentifier != undefined) {
    //   requestObj.provider = provider;
    //   requestObj.providerId = providerIdentifier;
    // }
    // if (global.inviteCode != null && global.inviteCode != undefined) {
    //   requestObj.inviteCode = global.inviteCode
    // }

    // setMessage(''); // Clear the message when input value is valid 
    // // requestObj["formattedAddress"] = isAddress;
    // requestObj["formattedAddress"] = `${formValues.address && `${formValues.address},`} ${formValues.apt_etc && `${formValues.apt_etc},`} ${formValues.city && `${formValues.city},`} ${formValues.state && `${formValues.state},`} ${formValues.zip_code && `${formValues.zip_code},`}`;
    // console.log("requestObj", requestObj)
    // setFinalDataToPass(requestObj);
    // setIsSSNValid(true);
    // dispatch({ type: 'SIGN_UP_PERSONEL_INFO', payload: { signUpPersonelInfo: requestObj } });
    handleSubmit();
    return true;
    // var response = await PostRequest(VALIDATE_ALL_DETAILS_OF_USER(userId), requestObj, headers);
    // let responseObj = JSON.parse(response);
    // if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
    //   setMessage(''); // Clear the message when input value is valid 
    //   requestObj["formattedAddress"] = isAddress;
    //   setFinalDataToPass(requestObj);
    //   setIsSSNValid(true);
    //   const isMobileVerifiedPayload = { isMobileVerifed: true }
    //   dispatch({ type: 'MOBILE_VERIFIED', payload: isMobileVerifiedPayload });
    //   dispatch({ type: 'SIGN_UP_PERSONEL_INFO', payload: { signUpPersonelInfo: requestObj } });
    //   handleSubmit();
    //   return true;
    // } else {
    //   // debugger;
    //   var arrMessages = responseObj.Messages;
    //   if (arrMessages.length > 0) {
    //     const messageObject = arrMessages[0];
    //     const message = messageObject.DisplayText;
    //     // setSSNoError('Social security number is not valid. Please re-enter.');
    //     setSSNoError(message);
    //     setValue('ssn_no', '');
    //     setSSN('')
    //     setSSnBackUp('')
    //   }
    // }
  };

  const handleSelect = async (selectedAddress, placeId) => {
    clearErrors();
    setFirstNameError('');
    setLastNameError('');
    setDOBError('');
    setAddressError('');
    setSSNoError('');
    setCityError('');
    setStateError('');
    setZipCodeError('');
    const results = await geocodeByAddress(selectedAddress);
    const latLng = await getLatLng(results[0]);

    // Get formatted address using the place ID
    const placeResults = await geocodeByPlaceId(placeId);
    const formattedAddress = placeResults[0].formatted_address;
    const addressComponents = placeResults[0].address_components;
    console.log("addressComponents", addressComponents);
    console.log("formattedAddress", formattedAddress);

    var selectedAddress = parseGoogleLocationResponse(addressComponents);
    if (selectedAddress.streetNumber != null &&
      selectedAddress.postalCode != null &&
      selectedAddress.country != null &&
      selectedAddress.administrativeAreaLevel1 != null &&
      selectedAddress.locality != null &&
      selectedAddress.route != null) {
      setStreet(selectedAddress.streetNumber);
      setRoute(selectedAddress.route);
      setLocality(selectedAddress.locality);
      setAdministartoeLevel1(selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : '');
      setZipCode(selectedAddress.postalCode);
      // const address1=selectedAddress.streetNumber + " " + selectedAddress.route ;
      // setValue('address', address1);
      // setValue('city', selectedAddress.locality);
      // setValue('state', selectedAddress.administrativeAreaLevel1 );
      // setValue('zip_code', selectedAddress.postalCode);
      const showHeaderFooterPayload = { isShowHeaderFooter: false }
      dispatch({ type: 'HIDE_SHOW_HEADER_FOOTER', payload: showHeaderFooterPayload });
      setPredictions([]);
    } else {

      setAddress('');
    }
    
    
    
    // setAddress(formattedAddress);
    // setValue('address', formattedAddress);
    const address1 = (selectedAddress.streetNumber != null ? selectedAddress.streetNumber : selectedAddress.sublocality != null ? selectedAddress.sublocality : selectedAddress.naturalFeature != null ? selectedAddress.naturalFeature : '') + (selectedAddress.route != null ? ` ${selectedAddress.route}` : '');
    if(!address1) setAddressError(t("addressError"));
    setAddress(address1);
    setValue('address', address1);
    setValue('city', (selectedAddress.locality != null ? selectedAddress.locality : ''));
    setValue('state', (selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : ''));
    setValue('zip_code', (selectedAddress.postalCode != null ? selectedAddress.postalCode : ''));


    setPredictions([]);
  };


  //on submit
  const handleAdressChange = () => {
    clearErrors();
    setFirstNameError('');
    setLastNameError('');
    setDOBError('');
    setAddressError('');
    setSSNoError('');
    setCityError('');
    setStateError('');
    setZipCodeError('');
  }



  const handleSubmit = async (data) => {
    // alert();
    // debugger; 
    clearErrors();
    const formData = getValues();
    console.log("formData", formData)
    var requestObj = {
      "applicationId": appConfigData.CLIENT_ID,
      "username": email,
      "language": "en",
      "firstName": formData.firstName,
      "lastName": formData.lastName,
      "middleInitial": "",
      // "ssn": ssn,
      "dateOfBirth": formatDateYYYYMMDD(formValues.dob),
      "line1": isAddress,
      // "line1": formData.address,
      "line2": formData.apt_etc,
      "city": formData.city,
      "state": formData.state,
      "zipCode": formData.zip_code,
      "preferredName": formData.preferredName,

    }
 
    if (password !== '') {
      requestObj.password = password;
    }
    if (providerIdentifier != null && providerIdentifier != undefined) {
      requestObj.provider = provider;
      requestObj.providerId = providerIdentifier;
    }
    if (global.inviteCode != null && global.inviteCode != undefined) {
      requestObj.inviteCode = global.inviteCode
    }

    setMessage(''); // Clear the message when input value is valid 
    // requestObj["formattedAddress"] = isAddress;
    requestObj["formattedAddress"] = `${isAddress && `${isAddress}, `}${formData.apt_etc && `${formData.apt_etc}, `}${formData.city && `${formData.city}, `}${formData.state && `${formData.state}, `}${formData.zip_code && `${formData.zip_code}`}`;
    console.log("requestObj", requestObj)
    // setFinalDataToPass(requestObj);
    setIsSSNValid(true);
    dispatch({ type: 'SIGN_UP_PERSONEL_INFO', payload: { signUpPersonelInfo: requestObj } });


    try {

      if (formData.firstName != '' && formData.lastName != '' && formData.dob != '' && isAddress != '' && formData.city && formData.state && formData.zip_code) {
        getDataFromPersonalInfo(requestObj);
        // getDataFromPersonalInfo(finalDataToPass);
        // openTermsandConditionsonClick();
        openPersonalInfoSummaryClick();
      } else {
        await validationSchema.validate(data, { abortEarly: false });
      }
      if (!isAddress) setAddressError('Address is required')
      else setAddressError('')




    } catch (error) {
      if (!isAddress) setAddressError('Address is required')
      else setAddressError('')
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          if (typeof err.message === 'string') {
            if (err.path === 'firstName') {
              setFirstNameError(err.message);
              if (formData.firstName) {
                setFirstNameError('');
              }
            } else if (err.path === 'lastName') {
              setLastNameError(err.message);
              if (formData.lastName) {
                setLastNameError('');
              }
            } else if (err.path === 'dob') {
              setDOBError(err.message);
              if (formData.dob) {
                setDOBError('');
              }
            }
            // else if (err.path === 'address') {
            //   setAddressError(err.message);
            //   if (formData.address) {
            //     setAddressError('');
            //   }
            // } 
            // else if (err.path === 'ssn_no') {
            //   setSSNoError(err.message);
            //   if (formData.ssn_no) {
            //     setSSNoError('');
            //   }
            // }
            else if (err.path === 'city') {
              setCityError(err.message);
              if (formData.city) {
                setCityError('');
              }
            }
            else if (err.path === 'state') {
              setStateError(err.message);
              if (formData.state) {
                setStateError('');
              }
            }
            else if (err.path === 'zip_code') {
              setZipCodeError(err.message);
              if (formData.zip_code) {
                setZipCodeError('');
              }
            }
            // Handle other fields if needed
          }
        });
      }
    }
  };

  useKeyDown(() => {
    //  openTermsandConditionsonClick();  
    ValidateSSN(ssn, formValues)
  }, ["Enter"]);


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    ValidateSSN(ssn, formValues)
      
    }
  }


  return (
    <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0">
      <Card
        heading={
          <>
            <span className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)] lg:text-[31px] md:text-[26px] sm:text-[21px] text-[25px]
             lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('Perfect_Now')}</span>
            <span className='font-serif-pro sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)] lg:text-[31px] md:text-[26px] sm:text-[21px] text-[25px]
             lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('Perfect_lets_go')}</span>
          </>
        }
        subheading={addLineBreaks(t('lets_go_basic_info'), SubTittleStylesModal)}
        Cardcaption={t('Personal_info')}
        header={true}
        footer={true}
        cardHeight={'auto'}
        // cardHeight={height.personal_info}
        CardRadius={true} >
        <div className='flex items-start sm:w-[386px] w-full pt-3'>
          <img src={SecureIcon} className='mt-[6px]' />
          <p className='ml-2 lg:text-[18px] md:text-[18px] sm:text-[12px] text-[14px] font-medium font-hanken-grotesk leading-[28px]'>
            {t('via_encryption')}</p>
        </div>
        <form className="flex flex-col md:mt-16 sm:mt-12 mt-12"  onSubmit={(e) => e.preventDefault()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}>
          <CustomInput
            name="firstName"
            label="Legal First Name"
            register={register}
            error={errors.firstName}
            type="text"
            autoFocus={true}
            onChange={handleInputChange}
          />
          <div className='mb-4'>
            {firstNameError && (
              displayErrorMessage(firstNameError)
            )}
          </div>
          <CustomInput
            name="lastName"
            label="Legal Last Name"
            register={register}
            error={errors.lastName}
            type="text"
            onChange={handleInputChange}
          />
          <div className='mb-4'>
            {lastNameError && (
              displayErrorMessage(lastNameError)
            )}
          </div>
          <CustomInput
            name="preferredName"
            label={t("preferred_name")}
            register={register}
            type="text"
            onChange={handleInputChange}
          />
      
          <div className='mb-2'>
            <DatePicker
              selected={intialHighlightDate}
              highlightDates={calendarInitialValue}
              onChange={handleDateChange}
              value={formValues.dob}
              openToDate={calendarInitialValue}
              dateFormat="MMMM d, yyyy"
              name="dob" // Pass the `name` prop directly
              label="Date of birth"
              className='font-hanken-grotesk 
               text-secondary-2 px-4 py-2
                bg-text-btn placeholder-kudzu-blue 
                text-sm font-medium focus:outline-none w-full h-[60px] placeholder:font-semibold'
              showMonthDropdown
              showYearDropdown
              placeholderText='Date of Birth'
              autoComplete='off'
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              maxDate={new Date(currentYear, 11, 31)}
             
            />

            <div className='mb-4 mt-4'>
              {DOBError && (
                displayErrorMessage(DOBError)
              )}
            </div>
          </div>
          <div className='mt-2 mb-6'>
            <PlacesAutocomplete
              value={isAddress}
              onChange={value => {
                setAddress(value)
                if (value) {
                  setAddressError('');
                  setFirstNameError('');
                  setLastNameError('');
                  setDOBError('');
                  setCityError('');
                  setStateError('');
                  setZipCodeError('');

                }
              }
              }
              // onChange={setAddress}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                  <div>
                    <input
                      {...getInputProps({ placeholder: 'Address' })}
                      // className='font-hanken-grotesk text-secondary-2 px-4 py-2 bg-text-btn placeholder-kudzu-blue text-sm font-medium focus:outline-none w-full h-[60px] placeholder:font-semibold'
                      className='font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue placeholder:font-semibold md:text-[16px] sm:text-[14px] text-[16px] md:font-[500] sm:font-[700] font-[500] focus:outline-none w-full h-[60px]'
                      onBlur={(e) => {
                        if (!isAddress) setAddressError('Address is required')
                        else setAddressError('')
                    }}
                    onKeyDown={handleKeyPress}
                    />
                    <div className='mb-4'>
                      {loading ? <div>Loading...</div> : null}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active ? '#ffffff' : '#F5F8F9',
                          cursor: 'pointer',
                          padding: '8px',
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '26px',
                        };
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }}
            </PlacesAutocomplete>

            {AddressError && (
              displayErrorMessage(AddressError)
            )}
            {predictions.length > 0 && (
              <ul className='bg-[aliceblue] max-h-60 overflow-y-auto p-2'>
                {predictions.map((prediction) => (
                  <li
                    key={prediction.place_id}
                    className='font-hanken-grotesk text-sm font-medium leading-6 p-2 cursor-pointer'
                    value={prediction.place_id}
                  >{prediction.description}
                  </li>
                ))}
              </ul>
            )}
            <span className='pt-1 font-hanken-grotesk lg:text-[18px] md:text-[17px] 
            sm:text-[15px] text-[15px] font-medium'>{t('P.O._boxes_are_not_accepted')}</span>
          </div>
          <CustomInput
            name="apt_etc"
            label="Apt, suite, etc. (optional)"
            register={register}
            error={errors.apt_etc}
            type="text"
          />

          <CustomInput
            name="city"
            label="City"
            register={register}
            error={errors.city}
            type="text"
            onChange={handleAdressChange}
          />
          <div className='mb-4'>
            {cityError && (
              displayErrorMessage(cityError)
            )}

          </div>
          <CustomInput
            name="state"
            label="State"
            register={register}
            error={errors.state}
            type="text"
            onChange={handleAdressChange}
          />
          <div className='mb-4'>
            {stateError && (
              displayErrorMessage(stateError)
            )}

          </div>
          <CustomInput
            name="zip_code"
            label="Zip Code"
            register={register}
            error={errors.zip_code}
            type="text"
            onChange={handleAdressChange}
            onKeyDownFun={handleKeyPress}
          />
          <div className='mb-4 '>
            {zipCodeError && (
              displayErrorMessage(zipCodeError)
            )}

          </div>
          <div className='mb-4'>
          </div>
          {/* <CustomInput
              name="ssn_no"
              label="Social Security Number (SSN)"
              register={register}
              error={errors.ssn_no && errors.ssn_no.message}
              type="ssn_no"
              onChange={handleSSNChange}
              isSSNShow={true}
            /> */}
          {/* <div className="w-full"
            onFocus={() => { toggleCursor() }}
            onBlur={() => { toggleCursor() }}
          >
            <div className="flex items-center relative">
              <span
                type="button"
                className="text-gray-500 absolute right-4 top-5"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <img src={Showpassword} className='w-[20px] h-[20px] cursor-pointer' /> : <img src={Hidepassword} className='w-[20px] h-[20px] cursor-pointer' />}
              </span>
              <input
                ref={ssnRef}
                id={'ssn_no'}
                {...register('ssn_no')}
                // value={inputValue}
                placeholder={'Social Security Number (SSN)'}
                className={`${InputStyles} ${showPassword ? 'password-input' : ''}`}
                autoComplete="off"
                onChange={handleSSNChange}
                maxLength={9}
                style={{ color: 'transparent' }}
              // value={ssn}
              // onKeyUp={handleInputChange}
              >
              </input>
            </div>
            <div className='flex z-[999] opacity-100 relative top-[-51px] h-[60px] w-[80%] 
            bg-transparent items-center'

              style={{ position: 'relative', top: -48 }}
              onClick={() => {
                const input = document.getElementById("ssn_no");
                input.focus()
              }}>
              <p className='h-[50px] no-underline ml-[15px] top-[10] text-[#2965FB]' >{ssn}{isCursorVisible && <span style={{ display: 'inline-block', animation: isCursorVisible ? 'blink 0.8s step-end infinite' : 'none', verticalAlign: 'text-bottom', color: '#2965FB', fontWeight: '200', fontSize: '15px' }}>|</span>}</p>
            </div>

          </div>
          <style>{`@keyframes blink {0%, 100% {opacity: 1;}50% {opacity: 0;}}`}</style>


          {SSNoError && (
            displayErrorMessage(SSNoError)
          )} */}

          {/* <div className="absolute sm:right-24 right-[2.2rem] bottom-14"> */}
          <div className={`flex flex-row justify-end lg:mt-20 md:mt-16  gap-11
                    sm:mt-10 mt-10 sm:mx-0 mx-4 sm:mb-20 mb-10 md:-mr-10`}>
           <button
            type="button" 
            className="font-hanken-grotesk  font-bold text-x-ss text-secondary-2 cursor-pointer flex items-center" 
            onClick={backToStudenEmailVerification}
          >
            {t('back')}
           </button>
          
            <button
              type="button"
              className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center"
              onClick={() => { ValidateSSN(ssn, formValues) }}// Call handleContinue instead of openPhoneOTPModal directly
            >
              {t('confirm')}
              <img
                src={Arrow}
                className="md:w-[17px] sm:w-[11px] md:h-[17px] sm:h-[11px]
                 w-[12px] h-[12px] ml-2 animate__animated animate__flash animate__repeat-4"
                alt="Arrow"
              />
            </button>
          </div>

          {/* <div className="flex justify-end items-center mt-10">
          <button
            type="button" 
            className="font-hanken-grotesk  font-bold text-x-ss text-secondary-2 cursor-pointer flex items-center" 
            onClick={handleSubmit}
          >
            {t('continue')}
            <img src={Arrow} className="w-3 h-3 ml-2 animate__animated animate__flash animate__repeat-4" alt="Arrow" />
          </button>
        </div> */}
        </form>
      </Card>
    </div>
  )
}

export default PersonalInfo;