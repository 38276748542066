import React, { useEffect, useRef, useState } from 'react'
import { width, height } from "../../CustomComponents/Card/CardStyles";
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import right_dropdown_arrow from '../../../assets/images/right_dropdown_arrow.png'
import CustomTextInput from '../../CustomComponents/CustomtextInput';
import Loader from '../../CustomComponents/Loader';
import Modal from '../../CustomComponents/Modal';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { useNavigate } from 'react-router-dom';
import { fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc';
import Footer from '../../Layout/Footer';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { useKeyDown } from '../../../hooks/useKeyDown';
import statement_page from '../../../assets/images/statement_page.png'
import shredding_statement from '../../../assets/images/shredding_statement.png'
import Bell from '../../../assets/images/Bell.png'
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import arrow from '../../../assets/images/Caret_S.png'
import HtmlDropDown from '../../CustomComponents/HtmlDropDown';
import { AGREEMENTS, DOWNLOAD_STATEMENT_OF_SELECTED_MONTH, GET_FEES_SCHEDULE, GET_STATEMENTS_SELECTIONS, STATUS_SUCCESS_CODE, STATUS_SUCCESS_CODE_PDF_DOWNLOAD } from '../../../network/URLConstants';
import CustomDropdown from '../../CustomComponents/CustomDropDown';
const appConfigData = await fetchConfigarationData();



const Fees = ({ transactionDatas, onViewAllClick, viewAll }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const dispatch = useDispatch();
    const userId = useSelector(state => state.userId);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const selectedItem = cards?.[0]
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[14px] md:text-[13px] sm:text-[11px] text-[12px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [type, setType] = useState('')
    const [footerMsg, setFooterMsg] = useState('');
    const [selectedDocument, setSelectedDocument] = useState({ "CardholderStatementKey": 0, "DocumentID": '', "DisplayText": 'Select' });
    const [selectedDuration, setSelectedDuration] = useState({ "CardholderStatementKey": 0, "DocumentID": '', "DisplayText": 'Select' });
    const [feeData, setFeeData] = useState([])
    const [toNavigate, setToNavigate] = useState('');
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);

    useKeyDown(() => { }, ["Enter"]);

    const getFeesNcharges = async () => {
        if (cards == undefined || cards == null) {
            return
        }
        var url = GET_FEES_SCHEDULE + cards?.[0].CardKey;
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setLoading(true)
        var response = await GetRequest(url, headers)
        setLoading(false)
        try {
            var parsedResponse = JSON.parse(response)
            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                setFeeData(parsedResponse.Response.Data)
            } else {
                var arrMessages = parsedResponse.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
            }
        } catch (e) {
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
    }
    useEffect(() => { getFeesNcharges() }, [])

    const leftBarComp = () => {
        return (
            <div className='lg:w-[316px] md:w-[207px] sm:w-[171px] w-full sm:mx-0 ml-[2.5rem]'>

                <p className="text-white font-normal lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
                 md:leading-[33px] sm:leading-[30px] leading-[31px]
                 font-serif-pro sm:border-b-[2.5px] sm:border-[#4B70B9] lg:pb-14 md:pb-[50px] sm:border-opacity-[50%] border-b-0
                 sm:pb-[2.6rem] pb-[2.6rem] mb-10">{t("easily_access_policy")}</p>

                <div className='flex flex-col gap-10 cursor-pointer sm:pb-0 pb-10'>

                    {(cards != null && cards.length > 0 && cards[0].EnableStatements) && 
                     <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%]' onClick={() => { navigate('/Documents/Statements') }}>
                    <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] 
                    sm:text-[14px] text-[15px] leading-[20px]
                     md:font-[700] md:leading-[16px] lg:leading-[16px]
                     text-[#FEFAEE]'
                        onClick={() => { navigate('/Documents/Statements') }}
                        tabIndex={0}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                navigate('/Documents/Statements');
                            }
                        }}
                    >{t("statements")}</p>
                    <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div>}
                    <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%]' onClick={() => { navigate('/Documents/Policies') }}>
                    <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] 
                    sm:text-[14px] text-[15px] leading-[20px]
                     md:font-[700] md:leading-[16px] lg:leading-[16px] text-[#FEFAEE]'
                        onClick={() => { navigate('/Documents/Policies') }}
                        tabIndex={0}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                navigate('/Documents/Policies');
                            }
                        }}
                    >{t("privacy_and_legal")}</p>
                    <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div>




                    {/* <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] 
                    leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px]
                 text-[#FEFAEE]' onClick={() => { navigate('/Documents/TaxDocuments') }}
                        tabIndex={0}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                navigate('/Documents/TaxDocuments');
                            }
                        }}
                    >
                        {t("tax_documents")}</p> */}
     <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%]' onClick={() => { navigate('/Documents/Fees') }}>
                    <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] 
                    sm:text-[14px] text-[15px] leading-[20px]
                     md:font-[700] md:leading-[16px] lg:leading-[16px] text-[#94C3E8]'
                        onClick={() => { navigate('/Documents/Fees') }}
                        tabIndex={0}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                navigate('/Documents/Fees');
                            }
                        }}
                    >{t("fees")}</p>
                    <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div>
                </div>
            </div>
        )
    }
    const headerContent = () => {
        return (
            <div className="flex flex-row justify-between mx-10 items-center mt-10">
                <div className='ml-10'>
                    {/* Left Text */}
                    <p className="text-xl "></p>
                </div>
                <div onClick={(event) => { event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); }}
                    className='flex flex-row justify-end items-center mr-10 '>
                </div>
            </div>
        )
    }

    const urlify = (text) => {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a className="font-hanken-grotesk text-[#2965FB] md:text-sm sm:text-[11px] text-[16px] font-[700] cursor-pointer max-w-fit block" href="' + url + '" style="color:#2965FB">' + url + '</a>';
        })
        // or alternatively
        // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    const closeCustomAlertModal = () => {
        setShowModel(false);
    }
    const onAcptBtnClick = async () => {
        if (headerMsg == t('about_to_leave_kudzu')) { window.open(toNavigate, '_blank'); closeCustomAlertModal(); setToNavigate('') }
    }
    const onCancelBtnClick = () => {
        if (headerMsg == t('about_to_leave_kudzu')) { closeCustomAlertModal(); setToNavigate('') }
    }
    const handleLinkClick = (event) => {
        if (event.target.getAttribute('href')) {
            const target = event.target;
            event.preventDefault();
            const rawHref = event.target.getAttribute('href');
            setToNavigate(rawHref.endsWith('.') ? rawHref.slice(0, -1) : rawHref)
            showAlertModal(true, t("heads_up"), t("about_to_leave_kudzu"), t("redirected_from_kudzu"), true, true, t("continue"), t("cancel"), true, '', false, false)
        }

    };

    return (
        <>
            <HomeLayout>
                <LeftBar leftBarType='component' isPrimary={true} leftBarComp={leftBarComp()} />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
                animate__animated animate__fadeInRight '>
                    <DynamicCard
                        cardWidth={width.allpolicies}
                        header={false}
                        footer={false}
                        headerContent={headerContent()}
                        footerContent={() => { }}
                        CardRadius={true}
                        HeaderRadius={true}
                        isWhite={true} >

                        <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] md:font-[500] md:text-[26px]
                                  sm:text-[15px] text-[15px] md:leading-[36px] sm:leading-[23px] leading-[24px] sm:font-[600] font-[500]
                                 font-hanken-grotesk sm:mx-0 mx-4">
                            {t("fees")}
                        </div>
                        <div className='border-t-[2.5px] mb-5 border-[#A3CEBB] sm:mx-0 mx-4'></div>

                        <div style={{ overflowY: 'scroll' }} className='sm:w-full sm:h-[75vh] flex flex-col sm:mx-0 mx-4'>
                            <div className='flex flex-col'>

                                <div className='flex w-full flex-col text-[#000000] md:text-[20px] 
                                sm:text-[12px] text-[16px] mt-5 
                                font-hanken-grotesk font-[500] md:leading-[30px] sm:leading-[18px] leading-[24px]'>
                                    {t("we_are_dedicated_to_making_fees_easy")} </div>
                                <div className='flex w-full flex-col text-[#000000] md:text-[20px] 
                                sm:text-[12px] text-[16px] mt-5 
                                font-hanken-grotesk font-[500] md:leading-[30px] sm:leading-[18px] leading-[24px]'>
                                    {t("you_are_responsible_for_keeping_track")} </div>
                            </div>
                            <div className='flex flex-col mt-10'>
                                <div className='flex flex-row w-full justify-between mt-5'>
                                    <div className='text-[#262B3A] lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]  font-hanken-grotesk 
                                    font-[600] opacity-[50%]'>{t('fees_type').toLocaleUpperCase()}</div>
                                    <div className='text-[#262B3A] lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]  font-hanken-grotesk
                                     font-[600] opacity-[50%]'>{t('amount').toLocaleUpperCase()}</div>
                                </div>
                            </div>
                            <div className='mb-10'>
                                {feeData &&
                                    feeData.map((item, index) => {
                                        return (
                                            <div className='w-full flex flex-col h-auto md:mt-10 mt-6'>
                                                <div className='flex flex-col'>
                                                    <div className='flex flex-row w-full justify-between'>
                                                        <p className='text-[#30BC89] lg:text-[15px] md:text-[14px] sm:text-[13px] text-[14px]
                                                        font-hanken-grotesk font-[600]'>{item.FeeType}</p>
                                                        <p className='text-[#30BC89] lg:text-[15px] md:text-[14px] sm:text-[13px] text-[14px]
                                                         font-hanken-grotesk font-[600]'>{item.FeeAmount}</p>
                                                    </div>
                                                </div>
                                                <div className='flex flex-col md:mt-5 mt-4'>
                                                    <p onClick={handleLinkClick}
                                                        dangerouslySetInnerHTML={{ __html: urlify(item.FeeDescription) }}
                                                        className=' w-full flex-col text-[#262B3A] 
                                                        lg:text-[14px] md:text-[14px] sm:text-[13px] text-[14px]
                                                      font-hanken-grotesk font-[600] leading-loose block text-justify tracking-normal '></p>
                                                    <div className='flex w-full flex-col border-t-[3px]
                                                     mb-5 mt-5 border-[#E7E4DC] sm:mx-0 mx-4 border-opacity-[75%]'></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                        {(!loading) &&
                            <div className="flex justify-start py-[2rem]">
                                <button
                                    type="button"
                                    className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex items-center"
                                    onClick={() => navigate("/Documents/Policies")}
                                >
                                    <img
                                        src={require('../../../assets/images/leftarrow.png')}
                                        className="md:w-[17px] sm:w-[11px] md:h-[17px] sm:h-[11px] w-[12px] h-[12px] ml-2 animate__animated animate__flash animate__repeat-4 pr-1"
                                        alt="Arrow" />
                                    {t('back_to_policies')}
                                </button>

                            </div>
                        }
                    </DynamicCard>
                    {loading && <Loader />}
                </div>



            </HomeLayout >
            <CustomAlertModel isOpen={showModel}
                Fullheight={true}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
            />
        </>

    )
}

export default withOidcSecure(Fees);
