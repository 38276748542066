///header Styles dont over ride the things  
import styled from "styled-components";
import tw from "twin.macro";
const primaryFont = "Source Serif Pro, serif";
const secondaryFont = "Hanken Grotesk, sans-serif";
const fontSizes = {
  large: "24px",
  medium: "18px",
  small: "16px",
  semiSmall: "14px",
};
const bgColors = {
  onboardColor: 'var(--onbaord-navbar-bg)',
  HomeColor: 'var(----home-navbar-bg)',
};
export const Navbar = styled.header
  `${tw`sm:h-[100px] h-[90px] text-white sticky top-0 flex justify-between items-center sm:px-[6%] px-[2.5rem] z-50`}
background-color:${(props) => (props.isOnBoard ? bgColors.onboardColor : bgColors.HomeColor)}`;


export const LogoContainer = styled.image`${tw`sm:w-[130px] sm:h-[39px] w-[100px] h-[30px]`}`;

export const LoginButton = styled.button`${tw` sm:w-[162px] sm:block flex items-center justify-end
h-[50px] sm:border-2 border-white font-bold  sm:mr-[3%]`}
font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)};
font-size: ${(props) => fontSizes[props.size] || fontSizes.small}`;

