import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Arrow from '../../../../assets/images/arrow.png';
import { useTranslation } from 'react-i18next';
import CustomInput from '../../../CustomComponents/FormInputs';
import Card from '../../../CustomComponents/Card';
import addLineBreaks from '../../../../utils';
import { GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import { EMAIL_OTP_GENERATION, STATUS_SUCCESS_CODE, META_DATA_POLICIES } from '../../../../network//URLConstants';
import { useSelector } from 'react-redux';
import Loader from "../../../CustomComponents/Loader";
import { generateRegexExpression, generateRegexExpression1, retrieveData } from '../../../../helpers/utils/Utils';
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import { displayErrorMessage } from '../../../../utils';
import logFirebaseEvents from '../../../LogFirebaseEvents';
const appConfigData = await fetchConfigarationData();
const CreatePassword = ({ openPersonalInformationClick, email, openCreatePassword }) => {
  const { t } = useTranslation();
  //  const deviceId = useSelector(state => state.deviceId);
  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]";
  const userId = useSelector(state => state.userId);
  const [password, setPassword] = useState('');
  //  const [repeatPassword, setrepeatPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isMinMax, setIsMinMax] = useState(false);
  const [isNumeric, setIsNumeric] = useState(false);
  const [isAlpha, setIsAlpha] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isSpecialChar, setIsSpecialChar] = useState(false);
  const [policyCriteria, setPolicyCriteria] = useState({});
  const [policyRules, setPolicyRules] = useState('');
  const regexpression = generateRegexExpression(policyCriteria.PolicyTemplate);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isRepeatPasswordValid, setIsRepeatPasswordValid] = useState(false);
  const newPasswordRef = useRef(null);
  const repeatPasswordRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowPolicyRules, setIsShowPolicyRules] = useState(false);
  const [repeatPassword, setRepeatPassWord] = useState('');
  let policyIndex=0;




  const schema = yup.object().shape({

    newPassword: yup.string()
      .required("New password is required")
      .matches(
        regexpression, "The password you entered does not meet the requirements as listed below."
      ),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null],  t("repeat_password_error_msg"))
      .required(t('confirm_password_required')),
  });



  const {
    register,
    handleSubmit,
    formState: { errors }, clearErrors,
    getValues,
    setError,
    setFocus
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched'
  });


  const handlePasswordChange = (e) => {
    clearErrors();
    const password = e.target.value;
    const repeatPassword = getValues("repeatPassword");

    // Regular expression to validate password format
    const minMaxRegex = generateRegexExpression1("MinMax", policyCriteria?.PolicyTemplate)
    const numericRegex = generateRegexExpression1("Numeric", policyCriteria?.PolicyTemplate)
    const alphaRegex = generateRegexExpression1("Alpha", policyCriteria?.PolicyTemplate)
    const lowercaseRegex = generateRegexExpression1("Lowercase", policyCriteria?.PolicyTemplate)
    const uppercaseRegex = generateRegexExpression1("Uppercase", policyCriteria?.PolicyTemplate)
    const specialCharRegex = generateRegexExpression1("Special", policyCriteria?.PolicyTemplate)


    if (numericRegex.test(password)) {
      setIsNumeric(true);
    } else {
      setIsNumeric(false);
    }

    if (minMaxRegex.test(password)) {
      setIsMinMax(true);
    } else {
      setIsMinMax(false);
    }
    if (alphaRegex.test(password)) {
      setIsAlpha(true);
    } else {
      setIsAlpha(false);
    }
    if (lowercaseRegex.test(password)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
    if (uppercaseRegex.test(password)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
    if (specialCharRegex.test(password)) {
      setIsSpecialChar(true);
    } else {
      setIsSpecialChar(false);
    }
    if (regexpression.test(password)) {
      setIsNewPasswordValid(true);
    } else {
      setIsNewPasswordValid(false);
    }

    if (repeatPassword) {
      if (password === repeatPassword) {
        setIsRepeatPasswordValid(true);
      } else {
        setIsRepeatPasswordValid(false);
      }

    }

    setPassword(password);

  };

  const handleRepeatPasswordChange = (e) => {
    clearErrors();

    const repeatPassword = e.target.value;
    const password = getValues("newPassword");
    if (password !== "" && repeatPassword === password) {
      setIsRepeatPasswordValid(true)
    } else {
      setIsRepeatPasswordValid(false)

    }
    setRepeatPassWord(repeatPassword)

  }

  useEffect(() => {
    getPasswordPolicy();
  }, [userId])

  const getPasswordPolicy = async () => {
    setIsLoading(true);
    try {
      const applicationId = appConfigData.CLIENT_ID;
      const url = `${META_DATA_POLICIES}language=en&applicationid=${applicationId}&avn=1.0.0&isNew=true`;

      const deviceId = await retrieveData('updatedDeviceId')
      const headers = await GetApiHeadersWithOutBearer(deviceId);

      const response = await GetRequest(url, headers);
      const responseObj = JSON.parse(response);
      if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {


        const policyObj = responseObj.Response?.PasswordPolicy;
        setPolicyCriteria(policyObj);
        setPolicyRules(policyObj.PolicyRules);
        setIsShowPolicyRules(true)
        setIsLoading(false);
        // setErrorMessage('');
        clearErrors()
      } else {
        setIsLoading(false);
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        // setErrorMessage(displayText);
        setError('repeatPassword', {
          type: 'manual',
          message: displayText,
        });
        setTimeout(() => {
          clearErrors()
          // setErrorMessage('');
        }, 3000);
      }

    } catch (error) {
      setIsLoading(false);
    }
  }



  async function createPassword() {
    if (!isNewPasswordValid || !isRepeatPasswordValid) {
      return;
    }
    // debugger;
    const deviceId = await retrieveData('updatedDeviceId')
    let headers = await GetApiHeadersWithOutBearer(deviceId);

    var object = {
      "applicationId": appConfigData.CLIENT_ID,
      "language": "en",
      "username": email,
      "password": password
    }

    var url = EMAIL_OTP_GENERATION + userId + '/validate'
    setIsLoading(true)

    var response = await PostRequest(url, object, headers)

    var responseObj = JSON.parse(response);
    if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
      logFirebaseEvents("Create_Password", "Create_Password_Continue_Cliked", "Create_Password_Continue_Cliked", "","");
      openCreatePassword(password);
      openPersonalInformationClick();
      // openPatriotActClick();
      setErrorMessage('');
      clearErrors()

    } else {
      var arrMessages = responseObj.Messages;
      setIsLoading(false);
      var message = arrMessages[0];
      var displayText = message.DisplayText;
      // setErrorMessage(displayText);
      setError('repeatPassword', {
        type: 'manual',
        message: displayText,
      });
      // setTimeout(() => {
      //   setErrorMessage('');
      // }, 3000);
    }
  }



  return (
    <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0">
      <Card
        cardHeight={'auto'}
        heading={
          <>
            <span className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)] lg:text-[31px]
             md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
              md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('create_password_heading1')}</span>
            <span className='font-serif-pro sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)] lg:text-[31px]
             md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
              md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('create_password_heading2')}</span>
          </>
        }
        subheading={addLineBreaks(t('create_password__subheading3'), SubTittleStylesModal)}
        Cardcaption={t('create_password__caption')} header={true} footer={true} CardRadius={true}>
        <div className="flex flex-col mt-28" >
          <CustomInput
            name="newPassword"
            label={t("new_password")}
            register={register}
            error={errors.newPassword}
            type="password"
            onChange={handlePasswordChange}
            isErrorShow={true}
            isAutoFocus={true}
            isPasswordShow={true}
            showPasswordToggle
            isValid={isNewPasswordValid}
            autoFocus={true}
            ref={newPasswordRef} // Assign the ref to the input field
          />
          <CustomInput
            name="repeatPassword"
            label={t("repeat_password")}
            register={register}
            error={errors.repeatPassword}
            type="password"
            onChange={handleRepeatPasswordChange}
            isErrorShow={true}
            isPasswordShow={true}
            showPasswordToggle
            isValid={isRepeatPasswordValid}
            ref={repeatPasswordRef} // Assign the ref to the input field
            onKeyDownFun={(event) => { if (event.key == "Enter") { event.preventDefault(); createPassword(); } }}

          />

          {
            isShowPolicyRules &&
            <p className="lg:text-[18px] md:text-[18px] sm:text-[12px] text-[14px] lg:leading-7 md:leading-[22px] sm:leading-[22px] leading-7 font-hanken-grotesk text-secondary-3 font-medium mb-14">
              {/* <p className="font-hanken-grotesk  lg:text-md md:text-[16px] sm:text-[12px] text-[16px]  leading-7 text-secondary-3 font-medium mb-14"> */}
              {policyCriteria?.PolicyTemplate?.MinimumLength > 0 &&
                <span className={isMinMax && "text-[#30BC89]"}>
                  {/* Please choose a new password that is at least 8 characters in length. */}
                  {policyRules[policyIndex++]}{' '}

                </span>
              }
              {policyCriteria?.PolicyTemplate?.AlphaLength > 0 ?
                <span className={isAlpha && "text-[#30BC89]"}>
                  {/* "Must have at least {0} letter.", */}
                  {policyRules[policyIndex++]}{' '}

                </span>
                :
                (policyCriteria?.PolicyTemplate?.LowercaseLength > 0) &&
                <span>
                  <span className={isLowerCase && "text-[#30BC89]"}>
                    {/* "Must have at least 1 lower case letter.", */}
                    {policyRules[policyIndex++]}{' '}

                  </span>
                  {(policyCriteria?.PolicyTemplate?.UppercaseLength < 0) ?

                    <span className={!isUpperCase && "text-[#30BC89]"}>
                      {/* "New password should not contain any upper case letters.", */}
                      {policyRules[policyIndex++]}{' '}

                    </span>
                    :
                    <span className={isUpperCase && "text-[#30BC89]"}>
                      {/* "Must have at least 1 upper case letter.", */}
                      {policyRules[policyIndex++]}{' '}

                    </span>
                  }
                </span>
              }

              {(policyCriteria?.PolicyTemplate?.NumericLength > 0) &&
                <span className={isNumeric && "text-[#30BC89]"}>
                  {/*  "Must have at least 1 number." */}
                  {policyRules[policyIndex++]}{' '}
                </span>
              }
              {(policyCriteria?.PolicyTemplate?.NonAlphaLength > 0) &&
                <span className={isSpecialChar && "text-[#30BC89]"}>
                  {/* "Must have at least 1 special character.", */}
                  {policyRules[policyIndex++]}{' '}
                </span>
              }
              <span className={(password) && "text-[#30BC89]"}>
                {/* "Your new password should not be same as your username",*/}
                {policyRules[policyIndex++]}{' '}
              </span>
              
              <span className={(password) && "text-[#30BC89]"}>
                {/*"Do NOT share your password with anyone." */}
                {policyRules[policyIndex]}
              </span>
            </p>
          }
        </div>

        <div className="flex justify-end items-center mt-20 mb-24">
          <button
            type="button"
            className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center"
            // onClick={handleSubmit(handlePersonalInfo)} // Call handleContinue instead of openPhoneOTPModal directly
            onClick={createPassword}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                createPassword()
              }
            }}
          >
          {t('continue')}
            <img
              src={Arrow}
              className="md:w-[17px] sm:w-[11px] md:h-[17px] sm:h-[11px] w-[12px] h-[12px] ml-2 animate__animated animate__flash animate__repeat-4"
              alt="Arrow"
            />
          </button>
        </div>
      </Card>
      {isLoading && <Loader />}
    </div>
  )
}

export default CreatePassword;