import React, { useEffect, useRef, useState } from 'react'
import { width, height } from "../CustomComponents/Card/CardStyles";
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import right_dropdown_arrow from '../../assets/images/right_dropdown_arrow.png'
import CustomTextInput from '../CustomComponents/CustomtextInput';
import Loader from '../CustomComponents/Loader';
import Modal from '../CustomComponents/Modal';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../network/NetworkUtils';
import { useNavigate } from 'react-router-dom';
import { fetchConfigarationData, retrieveData } from '../../helpers/utils/Utils';
import CustomAlertModel from '../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../Layout/HomeLayout';
import DynamicCard from '../CustomComponents/DynamicHomeCard';
import { useKeyDown } from '../../hooks/useKeyDown';

import LeftBar from '../CustomComponents/DynamicComponent/LeftBar';
import { AGREEMENTS, STATUS_SUCCESS_CODE } from '../../network/URLConstants';
import Agreements from '../CustomComponents/Agreements';
import { AcceptEsignAgreement } from '../../utils';
const appConfigData = await fetchConfigarationData();



const AcceptTerms = ({ transactionDatas, onViewAllClick, viewAll }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const dispatch = useDispatch();
    const userId = useSelector(state => state.userId);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const selectedItem = cards?.[0]
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[26px] leading-[28px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[26px] leading-[28px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles1 = "lg:leading-[22px] md:leading-[20px] sm:leading-[17px] leading-[18px] font-hanken-grotesk modal-title lg:text-[14px] md:text-[13px] sm:text-[11px] text-[12px] font-normal"

    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [type, setType] = useState('')
    const [footerMsg, setFooterMsg] = useState('');
    const [displayContent, setDisplayContent] = useState({});
    const [accountType, setAccountType] = useState({});
    const [agreementType, setAgreementType] = useState(
        { name: 'Electronic Communications Agreement', url: 'ESign', accType: 0, CardKey: cards !== null ? cards?.[0].CardKey : 0 });
    const [toNavigate, setToNavigate] = useState('');
    const accessToken = useSelector(state => state.accessToken);
    const [ContentId, setContentId] = useState('');

    useKeyDown(() => { }, ["Enter"]);

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    const linkClickAction = (link) => {
        setToNavigate(link.link)
        showAlertModal(true, t("heads_up"), t("about_to_leave_kudzu"), t("redirected_from_kudzu"), true, true, t("continue"), t("cancel"), true, '')

    }
    const closeCustomAlertModal = () => {
        setShowModel(false);
    }
    const onAcptBtnClick = async () => {
        if (headerMsg == t('about_to_leave_kudzu')) { window.open(toNavigate, '_blank'); closeCustomAlertModal(); setToNavigate('') }
    }
    const onCancelBtnClick = () => {
        if (headerMsg == t('about_to_leave_kudzu')) { closeCustomAlertModal(); setToNavigate('') }
    }
    const leftBarComp = () => {
        return (
            <div>
                <p className="text-white md:text-[31px] md:leading-[38px] sm:text-[18px] sm:leading-[24px] text-[25px] leading-[31px]
                font-normal  lg:text-[32px]
                 lg:leading-[38px] font-serif-pro sm:mx-0 ml-[2.5rem] sm:mb-0 mb-8 ">{t('accept_terms')}</p>
            </div>
        )
    }
    const headerContent = () => {
        return (
            // <div className="flex flex-row justify-start mx-10 items-center mt-10">
            //     <div className='ml-10'>
            //         {/* Left Text */}
            //         <p className="text-xmd ">{t("external_accounts")}</p>
            //     </div>
            // </div>
            <div className="flex flex-row justify-between mx-10 items-center mt-10">
                <div className='ml-10'>
                    {/* Left Text */}
                    <p className="text-xl "></p>
                </div>
                <div onClick={(event) => { event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); }}
                    className='flex flex-row justify-end items-center mr-10 '>
                    {/* Image */}
                    {/* <img
                        src={print_img}
                        // alt="Image"
                        className="w-5 h-5 mr-2"
                    /> */}

                    {/* Right Text */}
                    {/* <p className="text-18 font-bold">{t("print")}</p> */}
                </div>
            </div>
        )
    }
    function acceptAgreement() {
        setLoading(true);
        const isPrimrayKey = cards !== null ? cards?.[0].CardKey : 0;
        AcceptEsignAgreement(isPrimrayKey, accessToken, ContentId).then(response => {

            if (response.ResponseCode == STATUS_SUCCESS_CODE) {
                // setIframe(false);
                setLoading(false)
            } else {
                // setIframe(true);
                setLoading(false)
            }

        }).catch(error => {
            setLoading(false)
        });

    }
    return (
        <>
            <HomeLayout>
                <LeftBar leftBarType='component' isPrimary={true} leftBarComp={leftBarComp()} />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>


                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        cardWidth={width.homeCard}
                        header={false}
                        footer={false}
                        headerContent={headerContent()}
                        footerContent={() => { }}
                        CardRadius={true}
                        HeaderRadius={true}
                        isWhite={true}>
                        <div className='w-full flex flex-col self-center'>
                            <div className='w-4/5 flex flex-col self-center justify-center'>
                                <div className=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
 lg:text-[26px] md:text-[22px] 
 sm:text-[20px] text-[20px]
 leading-[28px] font-hanken-grotesk sm:mx-0 mx-4">
                                    {agreementType.name}
                                </div>
                                <div className='flex w-full flex-col border-t-[2.5px] mb-5 border-[#A3CEBB] sm:mx-0 mx-4'></div>
                                <div className='pb-[5rem]'>
                                    <div className='w-full flex flex-col self-center items-center justify-center'>
                                        <Agreements type={agreementType} linkClickAction={linkClickAction} isFromEsignAccept={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DynamicCard>
                </div>
            </HomeLayout >
            <CustomAlertModel isOpen={showModel}
                Fullheight={true}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles1}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
            />
        </>

    )
}

export default withOidcSecure(AcceptTerms);
