import React, { useState, useEffect } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { width } from "../../../CustomComponents/DynamicCard/DynamicCardStyles";
import { useTranslation } from 'react-i18next';
import HomeLayout from '../../../Layout/HomeLayout';
import DynamicCard from '../../../CustomComponents/DynamicCard';
import { useLocation, useNavigate } from 'react-router';
import { ClimbManageSettingsSidebar, retrieveData } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../CustomComponents/Loader';
import { CLIMB_OPT_IN_OUT, GET_ALL_SPEND_ALERTS, GET_CLIMB_FEATURES, OPT_FEATURE_DY, OPT_IN_FEATURE, OPT_IN_FEATURE_DY, OPT_IN_FEATURE_SPEND_ALERTS, OPT_OUT_FEATURE, OPT_OUT_FEATURE_SPEND_ALERTS, SPENDING_ALERT, STATUS_SUCCESS_CODE } from '../../../../network/URLConstants';
import Modal from '../../../CustomComponents/Modal';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import SpendAlertsPause from "../../../../assets/images/illo-pause.png";
import SpendAlertsPauseSuccess from '../../../../assets/images/illo-pause-done.png';
import Success from '../Success';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import logFirebaseEvents from '../../../LogFirebaseEvents';
const ManageCoveredByKudzu = () => {
    const HeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3";

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const SettingsData = [
        {
            title: 'Covered by Kudzu',
            content: 'You can opt out of Covered by Kudzu at any time. You must opt back in to resume this coverage.'

        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const accessToken = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const isPrimrayKey = cards !== null ? cards !== null ? cards[0].CardKey : 0 : 0;
    const climbFeatures = useSelector(state => state.climbFeatures);
    const enabledClimbings =   climbFeatures?.filter((response) => response?.enabled === true);
    const isCoveredByKudzuOptedIn = enabledClimbings?.some(item => item.code === 'CoveredByKudzu' && item.optedIn)
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none text-black";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [modelImage, setModelImage] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const location = useLocation();
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);



    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }

    const closeCustomAlertModal = () => { 
        if (headerMsg == t('covered_by_kudzu_paused')) { navigate('/Climb/ClimbMenu') }
        else { setShowModel(false); }
    }

    const onAcptBtnClick = async () => {
        setShowModel(false)
        if (btn1Text == t("pause_covered_by_kudzu")) {
            optOutOfCbKApiCall()
        } else if (btn1Text == t('back_to_climb')) {
            logFirebaseEvents("Manage_Covered_By_Kudzu", "BackTo_Climb_Manage_Covered_Clicked", "BackTo_Climb_Manage_Covered_Clicked","", "");
            navigate('/Climb/ClimbMenu')
        }
    }
    const onCancelBtnClick = () => {
        logFirebaseEvents("Manage_Covered_By_Kudzu", "BackTo_Manage_Covered_Clicked", "BackTo_Manage_Covered_Clicked","", "");
        closeCustomAlertModal()
     }


    const optOutCBK = () => {
        logFirebaseEvents("Manage_Covered_By_Kudzu", "Opt_Out_Of_Covered_Clicked ", "Opt_Out_Of_Covered_Clicked ","", "")
        showAlertModal(true, t('are_you_sure'), t("CoverdByKudzuPauseMessage"), t("opting_out_of_covered_by_kudzu"), true, true, t('pause_covered_by_kudzu'), t('cancel'), true, require('../../../../assets/images/illo-covered-by-kudzu.png'), '', false, false)
    }




    const optOutOfCbKApiCall = async () => {  
        logFirebaseEvents("Manage_Covered_By_Kudzu", "Opt_Out_Of_Covered_Clicked", "Opt_Out_Of_Covered_Clicked","", "");
        setIsLoading(true)
        var url = CLIMB_OPT_IN_OUT.replace('{CARD_KEY}', isPrimrayKey) + 'CoveredByKudzu' + '/opt-out'
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const response = await PostRequest(url, {}, headers);
        const responseObj = JSON.parse(response)
        // debugger;
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            handleClimbFeatures()
            showAlertModal(true, t('done_exclamation'), t("covered_by_kudzu_paused"), t("opt_out_of_covered_by_kudzu_msg"), true, false, t('back_to_climb'), '', true, require('../../../../assets/images/covered_by_kudzu_success.png'), '', false, false)

        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('back_to_climb'), '', true, require('../../../../assets/images/warning.png'), '', false, false)

        }
    }

    const handleClimbFeatures = async () => {
        if (isPrimrayKey == undefined || isPrimrayKey == null) {
            return
        }
        //  debugger;
        setIsLoading(true);
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const url = GET_CLIMB_FEATURES.replace('{CARD_KEY}', isPrimrayKey)
        const response = await GetRequest(url, headers);
        const responseJson = JSON.parse(response);
        if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
            dispatch({ type: 'UPDATE_CLIMB_FEATURE', payload: { climbFeatures: responseJson.Response } })
            setIsLoading(false);
        } else {
            return [];
        }

    }

    ///////////////////////////////////HANDLE PAUSE AND RESUME MODAL POPUS  4

    const ManageSettingsbuttonLabels = [
        { label: 'SpendSense Alerts', link: '/Climb/ManageSpendAlerts' },
        { label: 'Covered by Kudzu', link: isCoveredByKudzuOptedIn ? '/Climb/ManageCoveredByKudzu' : '/Climb/CoveredByKudzu' },
        { label: 'Savings Habits', link: '/Climb/ManageSavingsHabit' },
    ];
    const navigateClicked = (item) => {
        navigate(item.link)
    }

    return (
        <>
            <HomeLayout>
                <ClimbManageSettingsSidebar indexBtn={2} ManageSettingsbuttonLabels={ManageSettingsbuttonLabels} headingMessage={true} navigateClicked={navigateClicked} />
                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        footer={true}
                        HeaderContent={false} isWhite={true} backgroundColor={'#E4EAF5'}>
                        <div style={{ minHeight: '660px' }}>
                            <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500]
                             lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
                              font-hanken-grotesk sm:mx-0 mx-4">{(t('manage_covered_by_kudzu'))}</div>

                            <div class="border-[#A3CEBB] border-b-[3px] sm:mx-0 mx-4"></div>

                            <div className='justify-center flex lg:mt-8 md:mt-6 sm:mx-0 mx-4'>
                                <div className='lg:w-[70%] sm:w-[81%] w-full'>
                                    {SettingsData.map((settings, index) => (
                                        <div key={index} className='mt-10 flex justify-between md:h-[123px]
                                         sm:h-[140px] h-[123px]
                                             border-b-[2px] border-[#94C3E8] border-opacity-[65%]'>
                                            <div>
                                                <p className='lg:text-[20px] md:text-[18px] 
                                                    sm:text-[17px] text-[17px] font-hanken-grotesk 
                                                    leading-[30px] sm:font-[500] font-[600]'>
                                                    {settings.title}
                                                </p>
                                                <div className='mt-4 lg:text-[14px] 
                                                    md:text-[13px] sm:text-[14px] text-[14px] 
                                                    font-hanken-grotesk lg:leading-[20px] md:leading-[18px]
                                                     sm:leading-[23px] leading-[20px] sm:font-[400] font-[500] sm:w-auto w-[240px]'>
                                                    {settings.content}
                                                </div>
                                            </div>
                                            <div>
                                                <label class="relative inline-flex items-center cursor-pointer">
                                                    <input type="checkbox"
                                                        checked={isCoveredByKudzuOptedIn}
                                                        onChange={(e) => { optOutCBK() }}
                                                        class="sr-only peer"
                                                        onClick={(e) => optOutCBK()}
                                                        disabled={!isCoveredByKudzuOptedIn}
                                                        tabIndex={0}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                optOutCBK()
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <div class="w-[38px] h-[25px]  bg-[#4B70B9] bg-opacity-[40%]
                                     peer-focus:outline-none   
                                      rounded-full peer dark:bg-gray-700 
                                      peer-checked:after:translate-x-full
                                     peer-checked:after:border-[#2965FB] 
                                      peer-checked:after:bg-[#2965FB]
                                      peer-checked:after:left-[-6px]
                                      after:content-[''] 
                                      after:absolute after:top-[2px]
                                      after:left-[2px] after:right-0
                                      after:bg-[#fff]
                                       after:border-[#fff] after:border-[gray-300] 
                                      after:border after:rounded-full after:h-[21px] 
                                      after:w-[21px] after:transition-all
                                       dark:border-gray-600 peer-checked:bg-[#94C3E8] peer-checked:bg-opacity-[40%]"></div>

                                                    <span class="ml-3 text-sm font-medium text-gray-900
                                     dark:text-gray-300"></span>
                                                </label></div>
                                        </div>
                                    ))}
                                    <p className="font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] sm:text-[14px]
                                     text-[16px] leading-[24px] text-secondary-3 w-[80%] pt-8" >{t("manage_savings_habit_content2")}
                                        <span className='text-secondary-2 cursor-pointer' onClick={() => { navigate('/PayPerks') }}
                                            tabIndex={0}
                                            onKeyDown={(e) => { if (e.key === "Enter") navigate('/PayPerks') }}
                                        >{t("NavbarPerks")}</span></p>

                                </div>

                            </div>
                        </div>
                    </DynamicCard>
                </div>
            </HomeLayout>
            <CustomAlertModel
                isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                    <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                // highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                // HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
            />
            {isLoading && <Loader />}
        </>

    )
}

export default withOidcSecure(ManageCoveredByKudzu)