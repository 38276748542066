import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../../Slider/Slider.css';
import { SAVINGS, SPENDING } from '../../../../utils';
import logFirebaseEvents from '../../../LogFirebaseEvents';

const DateRangeSlider = ({ selectedMinDate, selectedMaxDate, onDateChangeProp }) => {
  const currentPath = window.location.pathname;
  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setMonth(currentDate.getMonth() - 6); // Minimum date is 6 months ago
  const maxDate = new Date(); // Maximum date is today

  const [tooltipValues, setTooltipValues] = useState([null, null]);
  const [dragging, setDragging] = useState(false);

  const [dateRange, setDateRange] = useState([
    selectedMinDate || minDate,
    selectedMaxDate || maxDate,
  ]);

  useEffect(() => {
    // Update the dateRange state when the selectedMinDate or selectedMaxDate props change
    setDateRange([selectedMinDate || minDate, selectedMaxDate || maxDate]);

    // Update tooltip values when the selectedMinDate or selectedMaxDate props change
    setTooltipValues([
      selectedMinDate ? formatDate(selectedMinDate) : null,
      selectedMaxDate ? formatDate(selectedMaxDate) : null,
    ]);
  }, [selectedMinDate, selectedMaxDate]);

  const onDateChange = (values) => {
    if(currentPath === '/' + SPENDING){
      logFirebaseEvents("Spending", "Filter_by_Date", "Filter_by_Date","", "");
  }
else if(currentPath === '/' + SAVINGS){
  logFirebaseEvents("Savings", "Filter_by_Date", "Filter_by_Date","", "");
}

    // Convert values to Date objects if they are not already
    const dateValues = values.map((value) => (value instanceof Date ? value : new Date(value)));

    // Extract the minimum and maximum dates from the 'dateValues' array
    const [date1, date2] = dateValues;

    // Enforce the constraint: endDate should not be before startDate
    if (date2.getTime() < date1.getTime()) {
      // If the maxDate is less than the minDate, swap values to prevent reversal
      dateValues[0] = date2;
      dateValues[1] = date1;
    }

    setDateRange(dateValues);
    setTooltipValues(dateValues.map((value) => formatDate(value)));
    onDateChangeProp(dateValues[0], dateValues[1]);
  };

  const formatDate = (date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
  };

  const tooltipFormatter = (value, index) => {
    if (tooltipValues[index] !== null && dragging) {
      // If the tooltip value is not null and dragging is true, show the tooltip
      return formatDate(new Date(value));
    } else {
      // Otherwise, hide the tooltip
      return '';
    }
  };

  const handleStyle = {
    borderColor: '#2965FB',
    backgroundColor: '#2965FB',
  };

  return (
    <div className='pl-4 pr-8'>
      <div className=' flex flex-row gap-0 items-center'>
        <div className=' w-[14px] h-[14px] -mr-1 rounded-full bg-[#94C3E8]' />
        <Slider
          min={minDate.getTime()}
          max={maxDate.getTime()}
          value={dateRange.map((date) => date.getTime())}
          onChange={(values) => {
            setDateRange(values.map((value) => new Date(value)));
            onDateChange(values);
          }}
          onBeforeChange={() => setDragging(true)}
          onAfterChange={() => setDragging(false)}
          step={1}
          tipFormatter={(value, index) => tooltipFormatter(value, index)}
          range
          allowCross={false}
          handleStyle={[
            handleStyle,
            {
              ...handleStyle,
              cursor: dateRange[1].getTime() === maxDate.getTime() ? 'not-allowed' : 'pointer',
            }, // Disable handle 1 on max date
          ]}
          railStyle={{ backgroundColor: '#94C3E8' }} // Custom rail color
        />
        <div className=' w-[14px] h-[14px] -ml-1 rounded-full bg-[#94C3E8]' />

      </div>

      <div className="slider-tooltips">
        {/* Custom tooltips for the slider handles */}
        <div className="flex pt-4 justify-between">

          <div
            className="font-hanken-grotesk tooltip-text block w-[50%] md:text-[14px] text-[12px] font-medium slider-tooltip"
            style={{
              left: `${(dateRange[0].getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime()) * 100
                }%`,
            }}
          >
            {tooltipValues[1] !== null && `${tooltipValues[1]}`}
          </div>

          <div
            className="font-hanken-grotesk md:text-[14px] text-[12px] font-normal slider-tooltip"
            style={{
              left: `${(dateRange[1].getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime()) * 100
                }%`,
            }}
          >
            {tooltipValues[0] !== null && `${tooltipValues[0]}`}
          </div>

          <div className="font-hanken-grotesk tooltip-text block text-[14px] font-medium ml-[-16px]">
            {'Today'}
          </div>
          <div className="font-hanken-grotesk tooltip-text block text-[14px] font-medium mr-[-27px]">
            {'6 months'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeSlider;
