import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Arrow from '../../../../../../assets/images/arrow.png';
import { useTranslation } from 'react-i18next';
import Card from '../../../../../CustomComponents/Card';
import CustomInput from '../../../../../CustomComponents/FormInputs';
import { addLineBreaks, displaySuccessMessage, formatDate, parseGoogleLocationResponse, formatDateYYYYMMDD } from '../../../../../../utils';
import LineBreaks from '../../../../../../utils';
import CustomAlertModel from '../../../../../CustomComponents/CustomAlertModel';
import { GOOGLE_PLACES_AUTO_COMPLETE, GOOGLE_PLACE_DETAILS_WITH_PLACE_ID, VALIDATE_ALL_DETAILS_OF_USER, STATUS_SUCCESS_CODE } from '../../../../../../network/URLConstants';
import { GetRequest, GetApiHeadersWithOutBearer, PostRequest } from '../../../../../../network/NetworkUtils';
import Modal from '../../../../../CustomComponents/Modal';
import { useSelector, connect, useDispatch } from 'react-redux';
import CustomTextInput from '../../../../../CustomComponents/CustomtextInput';
import Loader from '../../../../../CustomComponents/Loader';

import { displayErrorMessage, validateAuthorizedUser, DisplaySSNMessage, ssnMessageNote } from '../../../../../../utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SecureIcon from '../../../../../../assets/images/secure.png';
import { useKeyDown } from '../../../../../../hooks/useKeyDown';
import transactionErrorIcon from "../../../../../../assets/images/transfer-error.png"

import { fetchConfigarationData } from '../../../../../../utils';
import HomeLayout from '../../../../../Layout/HomeLayout';
import LeftBar from '../../../../../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../../../../../CustomComponents/DynamicHomeCard';
import { height, width } from "../../../../../CustomComponents/Card/CardStyles";
import { useNavigate } from 'react-router';
import { withOidcSecure } from '@axa-fr/react-oidc';
import BancorpPrivacyPolicy from '../../../../../OnBoard/Signup/BancorpPrivacyPolicy';
import UserAgreement from '../../../../../OnBoard/Signup/UserAgreement';
import CustomDatePicker from '../../../../../CustomComponents/CustomDateInput';
import Showpassword from '../../../../../../assets/images/showpassword.png'
import Hidepassword from '../../../../../../assets/images/hidepassword.png'


import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';

const appConfigData = await fetchConfigarationData();

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  dob: yup.string().required('Date of birth is required'),
  ssn_no: yup.string().required('Social Security Number (SSN) is required'),
  address: yup.string().required('Address is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  emailAddress: yup.string().email('Invalid email address').required('Email address is required'),

});
export const AddAuthorizedUsers = ({ getDataFromPersonalInfo, email, password }) => {

  const ssnRef = React.createRef();
  const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue lg:text-[16px] md:text-[15px] text-[14px] font-medium focus:outline-none w-full h-[60px]";
  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal text-[#94C3E8]";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [isLoading, setIsLoading] = useState(false);
  const [isSSNValid, setIsSSNValid] = useState(false);
  const [typedValue, setTypedValue] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [selectedCity, setCity] = useState('');
  const [selectedState, setState] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [DOBError, setDOBError] = useState('');
  const [AddressError, setAddressError] = useState('');
  const [SSNoError, setSSNoError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isremoveAcceptClicked, setIsremoveAcceptClicked] = useState(false);
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [allInputsHasData, setAllInputsHasData] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [ssn, setSSN] = useState('')
  const [ssnBackup, setSSnBackUp] = useState('')
  const [isCursorVisible, setCursorVisibility] = useState(false);
  const [autofocousFirstName,setAutoFocusFirstName] = useState(false);

  //
  const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [selectedStreet, setStreet] = useState('');
  const [selectedRoute, setRoute] = useState('');
  const [selectedLocality, setLocality] = useState('');
  const [selectedAdministartoeLevel1, setAdministartoeLevel1] = useState('');
  const [selectedPostalCode, setZipCode] = useState('');
  const [isAddress, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const [checkedValues, setCheckedValues] = useState([]);

  const [showPolicys, setShowPolicys] = useState('terms');
  const [acceptAll, setAcceptAll] = useState(false);
  const [isElectronicVerifyModalOpen, setIsElectronicVerifyModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [homeAddressToserver, setHomeAddressToserver] = useState('');
  const [line1AdressFilled, setLine1AdressFilled] = useState(false);
  const cards = useSelector(state => JSON.parse(state.userCards));
  const selectedItem = cards?.[0]
  const [responseCode, setResponseCode] = useState('');
  const [infoMessage, setinfoMessage] = useState('');
  const [privacyErromessage, setPrivacyErromessage] = useState('');
  const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
  const [selectedType, setSelectedType] = useState('');
  const [selectedAddressLine2, setSelectedAddressLine2] = useState('');
  const [selectedTransferDate, setSelectedTransferDate] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [modelImage, setModelImage] = useState('')
  const [SSNBackupSecondary, setSSNBackupSecondary] = useState('')






  ///form data
  const [completeFormData, setcompleteFormData] = useState('');


  const userId = useSelector(state => state.userId);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    dob: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
    address: '',
    apt_etc: '',
    ssn_no: '',
    phoneNumber: '',
    emailAddress: ''
  });

  const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    handleSSNValue(ssnBackup)
  }, [showPassword])

  const handleInputChange = (event) => {
    setFirstNameError('');
    setLastNameError('');
    setDOBError('');
    setSSNoError('');
    setAddressError('');
    setPhoneNumberError('');
    setEmailError('');

    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    // if (name === 'firstName') {
    //   setFirstNameError('')
    // } else if (name === 'lastName') {
    //   setLastNameError('')
    // }else if (name === "phoneNumber"){
    //   setPhoneNumberError('');
    // }else if (name === "emailAddress"){
    //   setEmailError('');
    // }
    // const formData = getValues();
    // if (formData.firstName != '' && formData.lastName != '' && formData.dob != '' && formData.address != '' && formData.ssn_no != '' && formData.phoneNumber != '' && formData.emailAddress != '' && formData.address != '') {
    //  setAllInputsHasData(true);
    // }else{
    //   setAllInputsHasData(false);
    // }
  };
  const toggleCursor = () => {
    setCursorVisibility(!isCursorVisible);
  };


  const handleSSNChange = (event) => {
    //debugger;
    const ssnNumber = event.target.value;
    //debugger;
    // Remove all non-digit characters from the input
    const ssnNo = ssnNumber.length !== 9 ? ssnNumber.replace(/[*-]/g, '') : ssnNumber;
    setSSnBackUp(ssnNo)
    handleSSNValue(ssnNo)
    if(event.target.value.length < 10){setSSNBackupSecondary(event.target.value.replace(' ',"").replace('-',"").replace(' ',""))}
    if (ssnNo.length == 9) {
      setSSNoError('');
      handleInputChange(event); // Update the form values
      setIsSSNValid(true)

      //   ValidateSSN(ssnNo, formValues); // Pass the SSN value and form values to the ValidateSSN function
      //  ValidateSSN(true);
    }
  };
  const onAcptBtnClick = async () => {
    setShowModel(false);
    if (headerMsg === t('already_authorized_email')) {
      onSubmit()
    } else if (headerMsg === t('already_authorized_ssn')) {
      onSubmit()
    }
  }
  const onCancelBtnClick = () => {
    setShowModel(false);
    navigate('/AccountServices/Spending/ManageAuthorizedUsers')
  }
  function handleSSNValue(value) {
    const formatted = showPassword ? formatSecuredValue(value) : formatValue(value)
    setSSN(formatted)
  }
  function formatValue(value) {
    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{2})(\d{1,2})/, '$1 - $2 - $3');

    return formattedValue;
  }

  function formatSecuredValue(value) {
    const securedValue = value
      .replace(/[^0-9•]/g, '')
      .replace(/((?:\d|.){3})((?:\d|.){2})((?:\d|.){1,2})/, '$1 - $2 - $3')
      .replace(/[0-9]/g, '•');

    return securedValue;
  }
  const ValidateSSN = async (ssnNo, formValues) => {
    if (ssnNo)
      var ssn = ssnNo.replace(/-/g, '');
    let headers = await GetApiHeadersWithOutBearer();
    var requestObj = {
      "applicationId": appConfigData.CLIENT_ID,
      "language": "en",
    };

    var requestObj = {

      "username": email,

      "password": password,
      "firstName": formValues.firstName,
      "lastName": formValues.lastName,
      "middleInitial": "",
      "ssn": ssn,
      "dateOfBirth": formatDateYYYYMMDD(formValues.dob),
      "line1": "705 James Burgess Road",
      "line2": "Flat: 1055",
      "city": "Suwanee",
      "state": "Georgia",
      "zipCode": "30024"
      // "line1":selectedStreet + " " + selectedRoute,
      // "line2":formValues.apt_etc , 
      // "city": selectedLocality,
      // "state":selectedAdministartoeLevel1,
      // "zipCode": selectedPostalCode 
    }
    var response = await PostRequest(VALIDATE_ALL_DETAILS_OF_USER(userId), requestObj, headers);
    let responseObj = JSON.parse(response);
    if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
      setMessage(''); // Clear the message when input value is valid 
      getDataFromPersonalInfo(requestObj);
      setIsSSNValid(true);
      const isMobileVerifiedPayload = { isMobileVerifed: true }
      dispatch({ type: 'MOBILE_VERIFIED', payload: isMobileVerifiedPayload });
      return true;
    } else {
      // debugger;
      var arrMessages = responseObj.Messages;
      if (arrMessages.length > 0) {
        const messageObject = arrMessages[0];
        const message = messageObject.DisplayText;
        setSSNoError('Social security number is not valid. Please re-enter.');
        setValue('ssn_no', '');
      }
    }
  };

  const getAddress = async (event) => {


    var headers = {
      "Access-Control-Allow-Origin": "*"
    };
    const typedValue = event.target.value;
    if (typedValue.length === 3) {

      const response = await fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${typedValue}&key=AIzaSyBryeFUk3zwNrunOjI4z6k5ldFxtBvVSTM&language=en&components=country:us`, headers);
      const prdections_data = JSON.parse(response);
      if (prdections_data.predictions.length > 0) {
        var predictions = prdections_data.predictions;
        setPredictions(predictions);
        // alert(predictions.length);
      }
    } else {
      setPredictions([]);
    }
  }


  useEffect(() => {
    //  showAlertModal(true, t('oops'), t('already_authorized'), t('unable_authorized'), true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
  }, []);

  const handlePredictionClick = async (event) => {
    const selectedPlaceID = event.target.getAttribute('value');
    if (selectedPlaceID) {
      //debugger;
      var url = GOOGLE_PLACE_DETAILS_WITH_PLACE_ID(selectedPlaceID);
      var response = GetRequest(url, { 'Access-Control-Allow-Origin': '*' });
      const responseJson = await response;
      const places_data = JSON.parse(responseJson);
      var formattedAdress = places_data.result.formatted_address;
      setAddress(formattedAdress);
      setValue('address', formattedAdress);
      var selectedAddress = parseGoogleLocationResponse(places_data);
      if (selectedAddress.streetNumber != null &&
        selectedAddress.postalCode != null &&
        selectedAddress.country != null &&
        selectedAddress.administrativeAreaLevel1 != null &&
        selectedAddress.locality != null &&
        selectedAddress.route != null) {
        setStreet(selectedAddress.streetNumber);
        setRoute(selectedAddress.route);
        setLocality(selectedAddress.locality);
        setAdministartoeLevel1(selectedAddress.administrativeAreaLevel1);
        setZipCode(selectedAddress.zipCode);
        const showHeaderFooterPayload = { isShowHeaderFooter: false }
        dispatch({ type: 'HIDE_SHOW_HEADER_FOOTER', payload: showHeaderFooterPayload });
        setPredictions([]);
      } else {
        setValue('address', '');
      }

    }
  };
  const alldataFileldorNot = () => {
    // alert('')
    const formData = getValues();
    if (formData.firstName != '' && formData.lastName != '' && formData.dob != '' && formData.address != '' && formData.ssn_no != '' && formData.phoneNumber != '' && formData.emailAddress != '' && formData.address != '') {
      setAllInputsHasData(true);
    } else {
      setAllInputsHasData(false);
    }
  }
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)

  }


  //this method handles the save functionality
  const onSubmit = async (data) => {
    // navigate('/AccountServices/Checking/ManageAuthorizedUsers/EditAuthorizedUser')
    // return;
    const formData = getValues();
    // var addedAuthorizedUser = {};
    // addedAuthorizedUser.firstName = formData.firstName;
    // addedAuthorizedUser.lastName = formData.lastName;
    // addedAuthorizedUser.birthDay = formData.dob;
    // addedAuthorizedUser.ssn = formData.ssn_no;
    // addedAuthorizedUser.selectedDobToServer = formData.dob;
    // addedAuthorizedUser.formattedAddress = isAddress;
    // addedAuthorizedUser.line1 = homeAddressToserver.line1;
    // addedAuthorizedUser.line2 = homeAddressToserver.line2;
    // addedAuthorizedUser.city = homeAddressToserver.city;
    // addedAuthorizedUser.state = homeAddressToserver.state;
    // addedAuthorizedUser.zipCode = homeAddressToserver.zipCode;
    // addedAuthorizedUser.countryCode = homeAddressToserver.zipCode;
    // addedAuthorizedUser.country = homeAddressToserver.country;
    // addedAuthorizedUser.cardKey = homeAddressToserver.cardKey;
    // addedAuthorizedUser.cardKey = selectedItem.cardKey;
    // addedAuthorizedUser.apt_etc = formData.apt_etc;
    // addedAuthorizedUser.phoneNumber = formData.phoneNumber;
    // addedAuthorizedUser.emailAddress = formData.emailAddress;
    // const authorUserDetails = { addedAuthorizedUserDetails: addedAuthorizedUser };
    // dispatch({ type: 'ADDED_AUTHORZIED_USER', payload: authorUserDetails });
    // setTimeout(() => {
    //   navigate('/AccountServices/Checking/ManageAuthorizedUsers/EditAuthorizedUser');
    // }, 1500);


    try {
      if (formData.firstName != '' && formData.lastName != '' && formData.dob != '' && formData.address != '' && formData.ssn_no != '' && formData.phoneNumber != '' && formData.emailAddress != '' && formData.address != '') {
        var addedAuthorizedUser = {};
        addedAuthorizedUser.firstName = formData.firstName;
        addedAuthorizedUser.lastName = formData.lastName;
        addedAuthorizedUser.birthDay = formData.dob;
        addedAuthorizedUser.ssn = formData.ssn_no;
        addedAuthorizedUser.selectedDobToServer = formData.dob;
        addedAuthorizedUser.formattedAddress = isAddress;
        addedAuthorizedUser.line1 = homeAddressToserver.line1;
        addedAuthorizedUser.line2 = homeAddressToserver.line2;
        addedAuthorizedUser.city = homeAddressToserver.city;
        addedAuthorizedUser.state = homeAddressToserver.state;
        addedAuthorizedUser.zipCode = homeAddressToserver.zipCode;
        addedAuthorizedUser.countryCode = homeAddressToserver.countryCode;
        addedAuthorizedUser.country = homeAddressToserver.country;
        addedAuthorizedUser.cardKey = homeAddressToserver.cardKey;
        addedAuthorizedUser.cardKey = selectedItem?.cardKey;
        addedAuthorizedUser.apt_etc = formData.apt_etc;
        addedAuthorizedUser.phoneNumber = formData.phoneNumber;
        addedAuthorizedUser.emailAddress = formData.emailAddress;
        const authorUserDetails = { addedAuthorizedUserDetails: addedAuthorizedUser };
        dispatch({ type: 'ADDED_AUTHORZIED_USER', payload: authorUserDetails });

        setIsLoading(true);
        const response = await validateAuthorizedUser(
          addedAuthorizedUser.firstName,
          addedAuthorizedUser.lastName,
          addedAuthorizedUser.ssn,
          addedAuthorizedUser.selectedDobToServer,
          addedAuthorizedUser.line1,
          addedAuthorizedUser.line2,
          addedAuthorizedUser.city != null ? addedAuthorizedUser.city : '',
          addedAuthorizedUser.state != null ? addedAuthorizedUser.state : '',
          addedAuthorizedUser.zipCode != null ? addedAuthorizedUser.zipCode : '',
          addedAuthorizedUser.countryCode != null ? addedAuthorizedUser.countryCode : '',
          addedAuthorizedUser.phoneNumber,
          addedAuthorizedUser.emailAddress,
          cards !== null ? cards[0].CardKey : 0,
          accessTokenToApi
        )


        const responseObj = JSON.parse(response)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
          setIsLoading(false);
          navigate('/AccountServices/Spending/ManageAuthorizedUsers/EditAuthorizedUser')
        } else {
          setIsLoading(false);
          setTimeout(() => {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            if (displayText === t('ssn_servor_error')) {
              showAlertModal(true, t('oops'), t('already_authorized_ssn'), t('unable_authorized_ssn'), true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
            } else if (displayText === t('ssn_servor_error')) {
              showAlertModal(true, t('oops'), t('already_authorized_email'), t('unable_authorized_email'), true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
            } else {
              showAlertModal(true, t('oops'), displayText, "", true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
            }


          }, 1000);
        }


      } else {
        await validationSchema.validate(data, { abortEarly: false });
      }
    } catch (error) {
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          if (typeof err.message === 'string') {
            if (err.path === 'firstName') {
              setFirstNameError(err.message);
              if (formData.firstName) {
                setFirstNameError('');
              }
            } else if (err.path === 'lastName') {
              setLastNameError(err.message);
              if (formData.lastName) {
                setLastNameError('');
              }
            } else if (err.path === 'dob') {
              setDOBError(err.message);
              if (formData.dob) {
                setDOBError('');
              }
            } else if (err.path === 'address') {
              setAddressError(err.message);
              if (formData.address) {
                setAddressError('');
              }
            } else if (err.path === 'ssn_no') {
              setSSNoError(err.message);
              if (formData.ssn_no) {
                setSSNoError('');
              }
            } else if (err.path === 'phoneNumber') {
              setPhoneNumberError(err.message);
              if (formData.phoneNumber) {
                setPhoneNumberError('');
              }
            } else if (err.path === 'emailAddress') {
              setEmailError(err.message);
              if (formData.emailAddress) {
                setEmailError('');
              }
            }
          }
        });
      }
    }
  };

  const closeCustomAlertModal = () => {
    setShowModel(false);
  }




  const heading = () => {
    return (
      <>
        <div className='lg:w-[260px] md:w-[183px] sm:w-[164px] w-full sm:mx-0 ml-[2.5rem] sm:pb-0 pb-8'>
          <p className="text-white font-normal lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] 
          md:leading-[33px] sm:leading-[30px] leading-[31px]
              font-serif-pro ">{(t('add_one_authorized_user'))}</p>
        </div>
      </>
    )
  }
  const OpenElectricVerificationModal = () => {
    setIsElectronicVerifyModalOpen(true);

  };
  const handleSelect = async (selectedAddress, placeId) => {
    const results = await geocodeByAddress(selectedAddress);
    const latLng = await getLatLng(results[0]);

    // Get formatted address using the place ID
    const placeResults = await geocodeByPlaceId(placeId);
    const formattedAddress = placeResults[0].formatted_address;
    const addressComponents = placeResults[0].address_components;

    var selectedAddress = parseGoogleLocationResponse(addressComponents);
    const streetNumber = selectedAddress.streetNumber != null ? selectedAddress.streetNumber : ''
    const route = selectedAddress.route != null ? selectedAddress.route : ''
    var object = {
      "line1": streetNumber + " " + route,
      "line2": selectedAddressLine2,
      "city": selectedAddress.locality != null ? selectedAddress.locality : '',
      "state": selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : '',
      "zipCode": selectedAddress.postalCode != null ? selectedAddress.postalCode : '',
      "countryCode": selectedAddress.countryCode != null ? selectedAddress.countryCode : '',
      "country": selectedAddress.country != null ? selectedAddress.country : '',
      "cardKey": selectedItem?.CardKey
    }
    setHomeAddressToserver(object);

    const addressParts = [
      object.line1,
      object.line2,
      object.city,
      object.state,
      object.zipCode,
      object.countryCode,
      object.country
    ];
    const homeAddress = addressParts.filter(part => part).join(', ');
    setAddressError('');
    setValue('address', formattedAddress);
    setAddress(formattedAddress);
    setTypedValue(homeAddress);
    setLine1AdressFilled(true);
    setFormValues((prevValues) => ({
      ...prevValues,
      ['address']: formattedAddress
    }));


    setPredictions([]);
  };

  useKeyDown(() => {
    if (showModel === false) {
      onSubmit()
    }
  }, ["Enter"]);

  const onDateChange = (date, type) => {
    const today = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    if (date > eighteenYearsAgo) {
      // Date of birth is less than 18 years ago
      // Handle the validation error as needed
      setDOBError('Date of birth should be greater than 18 years ago');
      return;
    }

    if (date > today) {
      // Date of birth is in the future
      // Handle the validation error as needed
      setDOBError('Date of birth cannot be in the future');
      return;
    }
    //debugger;
    clearErrors('dob'); // Clear the dob field error if it was previously set
    setValue('dob', formatDateYYYYMMDD(date));
    setDOBError('');
    setFormValues((prevValues) => ({
      ...prevValues,
      dob: date
    }));
  }
  const handleDateChange = (date) => {

    const today = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    if (date > eighteenYearsAgo) {
      // Date of birth is less than 18 years ago
      // Handle the validation error as needed
      setDOBError('Date of birth should be greater than 18 years ago');
      return;
    }

    if (date > today) {
      // Date of birth is in the future
      // Handle the validation error as needed
      setDOBError('Date of birth cannot be in the future');
      return;
    }
    //debugger;
    clearErrors('dob'); // Clear the dob field error if it was previously set
    setValue('dob', formatDateYYYYMMDD(date));
    setDOBError('');
    setFormValues((prevValues) => ({
      ...prevValues,
      dob: date
    }));
  };
  useEffect(() => {
    // Set focus to the input element when the component mounts
    setTimeout(() => {
      setAutoFocusFirstName(true);
    }, 1000);
  }, []);
  return (
    <>
      <HomeLayout>
        <LeftBar leftBarType='text' isPrimary={false} heading={heading()} />
        <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>

        <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>
          <DynamicCard
            header={false}
            CardRadius={true}
            cardWidth={width.allpolicies}
            HeaderRadius={true}
            isWhite={true}
            HeaderContent={false}>

            <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
                 font-hanken-grotesk sm:mx-0 mx-4">{t("add_authorized_users")}</div>
            <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4'></div>

            <div className='lg:w-[543px] sm:w-[360px] w-full sm:mx-0 mx-4'>
              <div className='font-serif-pro mt-10 lg:text-[26px] md:text-[22px]
              sm:text-[20px] text-[23px]  md:leading-[38px] sm:leading-[24px]
               leading-[31px] font-normal text-[#2965FB]'>
                <p>{t("lets_get_Started")}</p>
                <p className='text-black'>{t("enter_authorized_basicInfo")}</p>
              </div>
            </div>

            <div className='justify-center flex sm:mx-0 mx-4 '>
              <div className='sm:w-[80%] w-full'>
                <form className="flex flex-col md:mt-20 sm:mt-12 mt-12">
                  {/* firstName textInput */}
                {autofocousFirstName &&<CustomInput
                    name="firstName"
                    label="First name"
                    register={register}
                    error={errors.firstName}
                    type="text"
                    autoFocus={autofocousFirstName}
                    onChange={handleInputChange}
                  />}
                  <div className='mb-4'>
                    {firstNameError && (
                      displayErrorMessage(firstNameError)
                    )}
                  </div>
                  {/* lastName textInput */}
                  <CustomInput
                    name="lastName"
                    label="Last name"
                    register={register}
                    error={errors.lastName}
                    type="text"
                    onChange={handleInputChange}
                  />
                  <div className='mb-4'>
                    {lastNameError && (
                      displayErrorMessage(lastNameError)
                    )}
                  </div>
                  {/* dob input */}
                  <div className='mb-2' style={{ zIndex: 9999 }}>
                    <DatePicker
                      selected={formValues.dob}
                      onChange={handleDateChange}
                      // dateFormat="yyyy-MM-dd"
                      dateFormat="dd/MM/yyyy"
                      name="dob" // Pass the `name` prop directly
                      label="Date of birth"
                      className='font-hanken-grotesk 
               text-secondary-2 px-4 py-2
                bg-text-btn placeholder-kudzu-blue 
                text-sm font-medium focus:outline-none w-full h-[60px]'
                      showMonthDropdown
                      showYearDropdown
                      placeholderText='Date of birth'
                      autoComplete='off'
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      maxDate={new Date(currentYear, 11, 31)}
                    />
                    <div className='mb-4 mt-4'>
                      {DOBError && (
                        displayErrorMessage(DOBError)
                      )}
                    </div>
                  </div>
                  {/* <div className='mb-4'>
                  <CustomDatePicker
                   selectedDate={selectedTransferDate} onDateChange={(date) => onDateChange(date, 'oneTime')}
                    disabledDates={[]} title={t('dob_title')} hrbg='bg-dropdown-sky' />
                 </div> */}


                  {/* ssn textInput */}
                  {/* <CustomInput
                    name="ssn_no"
                    label="Social Security Number (SSN)"
                    register={register}
                    error={errors.ssn_no && errors.ssn_no.message}
                    type="ssn_no"
                    onChange={handleSSNChange}
                  /> */}
                  <div className="w-full"
                    onFocus={() => { toggleCursor() }}
                    onBlur={() => { toggleCursor() }}
                  >
                    <div className="flex items-center relative">
                      <span
                        type="button"
                        className="text-gray-500 absolute right-4 top-5"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <img src={Showpassword} className='w-[20px] h-[20px] cursor-pointer' /> : <img src={Hidepassword} className='w-[20px] h-[20px] cursor-pointer' />}
                      </span>
                      <input
                        ref={ssnRef}
                        id={'ssn_no'}
                        {...register('ssn_no')}
                        value={SSNBackupSecondary}
                        placeholder={'Social Security Number (SSN)'}
                        className={`${InputStyles} ${showPassword ? 'password-input' : ''}`}
                        autoComplete="off"
                        onChange={handleSSNChange}
                        maxLength={9}
                        style={{ color: 'transparent', caretColor:'transparent'}}
                        >
                      </input>
                    </div>
                    <div className='flex z-[999] opacity-100 relative top-[-51px] h-[60px] w-[80%] 
            bg-transparent items-center'

                      style={{ position: 'relative', top: -48 }}
                      onClick={() => {
                        const input = document.getElementById("ssn_no");
                        input.focus()
                      }}>
                      <p className='h-[50px] no-underline ml-[15px] top-[10] text-[#2965FB]' >{ssn}{isCursorVisible && <span style={{ display: 'inline-block', animation: isCursorVisible ? 'blink 0.8s step-end infinite' : 'none', verticalAlign: 'text-bottom', color: '#2965FB', fontWeight: '200', fontSize: '15px' }}>|</span>}</p>
                    </div>

                  </div>
                  <style>{`@keyframes blink {0%, 100% {opacity: 1;}50% {opacity: 0;}}`}</style>
                  {SSNoError && <div className='mb-4 mt-4'>
                    {SSNoError && (
                      displayErrorMessage(SSNoError)
                    )}
                  </div>}
                  <div className='pb-6'>{ssnMessageNote(t('auth_ssn_note'))}</div>


                  {/* address textInput */}
                  <div className='mt-2 mb-2'>
                    <PlacesAutocomplete
                      value={isAddress}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input {...getInputProps({ placeholder: 'Address' })} className='font-hanken-grotesk text-secondary-2 px-4 py-2 bg-text-btn placeholder-kudzu-blue text-sm font-medium focus:outline-none w-full h-[60px]' />
                          <div className='mb-4'>
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? '#ffffff' : '#F5F8F9',
                                cursor: 'pointer',
                                padding: '8px',
                                fontSize: '16px',
                                fontWeight: '500',
                                lineHeight: '26px',
                              };
                              return (
                                <div
                                  key={suggestion.placeId}
                                  {...getSuggestionItemProps(suggestion, { style })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {line1AdressFilled && <CustomTextInput
                      // name={t("line_two_optional")}
                      placeholder={t("line_two_optional")}
                      onChange={(event) => {
                        let tempHomeaddresToServer = homeAddressToserver;
                        tempHomeaddresToServer.line2 = event.target.value;
                        setHomeAddressToserver(tempHomeaddresToServer);
                        setSelectedAddressLine2(event.target.value)
                      }}
                      value={selectedAddressLine2} style={{ width: '100%', height: '51px' }} />}

                    {predictions.length > 0 && (
                      <ul className='bg-[aliceblue] max-h-60 overflow-y-auto p-2'>
                        {predictions.map((prediction) => (
                          <li
                            key={prediction.place_id}
                            className='font-hanken-grotesk text-sm font-medium leading-6 p-2 cursor-pointer'
                            value={prediction.place_id}
                          >{prediction.description}
                          </li>
                        ))}
                      </ul>
                    )}
                    {AddressError && (
                      displayErrorMessage(AddressError)
                    )}
                    {/* {(infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                      <div className='mb-2'>
                        {displaySuccessMessage(infoMessage)}
                      </div>
                    }
                    {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                      <div className='mb-2'>
                        {displayErrorMessage(infoMessage)}
                      </div>
                    } */}
                    {/* {(infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displaySuccessMessage(infoMessage)}
                                            </div>
                                        }
                                        {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        } */}

                  </div>
                  <p className="font-hanken-grotesk md:text-[16px] text-[#262B3A] sm:text-[14px] text-[16px] 
                                    font-medium leading-[26px] md:mb-6 mb-2">
                    {t('post_box_not_accepted')}</p>
                  <div className='w-full'>
                    <CustomInput
                      name="phoneNumber"
                      label="Phone number"
                      register={register}
                      error={errors.phoneNumber}
                      type="phone"
                      // autoFocus={true}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className='mb-4'>
                    {phoneNumberError && (
                      displayErrorMessage(phoneNumberError)
                    )}
                  </div>

                  <CustomInput
                    name="emailAddress"
                    label="Email address"
                    register={register}
                    error={errors.emailAddress}
                    type="text"
                    // autoFocus={true}
                    onChange={handleInputChange}
                  />

                  <div className='mb-4'>
                    {emailError && (
                      displayErrorMessage(emailError)
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className='flex flex-col md:pb-16 sm:pb-12 pb-16 md:mt-20 mt-12 sm:mx-0 mx-4 '>
              <div className='flex flex-row sm:justify-end justify-between sm:mx-0 mr-[6%] ml-[4%]'>
                <button onClick={() => { navigate('/AccountServices/Spending/ManageAuthorizedUsers') }} className='font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] leading-[18px] text-[#2965FB] font-bold'>{t("back")}</button>

                {/* {alldataFileldorNot()} */}
                {/* { */}
                {/* allInputsHasData ? */}
                <button tabIndex={0} className='font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] eading-[18px] text-[#2965FB] font-bold ml-10'
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      onSubmit();
                    }
                  }}
                  onClick={onSubmit}
                >{t("next")}</button>
                {/* :  <button  className='font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px] eading-[18px] text-[#94C3E8] font-bold ml-10'
            
               >{t("next")}</button>}  */}

              </div>
            </div>

          </DynamicCard>
        </div>
      </HomeLayout>
      <div>
        {/* {showPolicys === 'privacy' && (
                <BancorpPrivacyPolicy handleClick={handleClick} />
              )}
              {showPolicys === 'agreement' && (
                <UserAgreement handleClick={handleClick} />
              )} */}
      </div>
      <CustomAlertModel isOpen={showModel}
        onClose={closeCustomAlertModal}
        image={modelImage}
        highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
        HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
        subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
        btn1Exists={btn1Exists}
        btn2Exists={btn2Exists}
        btn1Text={btn1Text} btn2Text={btn2Text}
        btn1TypeAcpt={btn1TypeAcpt}
        verificationFailure={() => { }}
        footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
        onAcptBtnClick={onAcptBtnClick}
        onCancelBtnClick={onCancelBtnClick}
      />

      {isLoading && <Loader />}

    </>
  )
}

export default AddAuthorizedUsers;