import { useEffect } from 'react';

export const useKeyDown = (callback, keys) => {
    const onKeyDown = (event) => {
        const wasAnyKeyPressed = keys.some((key) => event.key === key);
        if (wasAnyKeyPressed) {
            // event.preventDefault();
            callback();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);
};

// const handleKeyPress = (e) => {
    //     if (e.key === 'Enter') {
    //         e.preventDefault(); // Prevent form submission
    //         handleSubmit(onSubmit)();
    //     }
    // }