import React from 'react'
import transactionError from "../../../../../../assets/images/transfer-error.png"
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const TransferError = ({ onCanelTranfer, isCancelButton,fromAccountType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackToService=()=>{
    debugger;
  //  navigate('/AccountServices')
    if (fromAccountType != undefined && fromAccountType != null) {
      navigate(`/AccountServices/${fromAccountType}`);
  }else{
      navigate('/AccountServices');
  }
}
  return (
    <div className=' flex flex-col justify-center items-center w-full'>

      <img src={transactionError} alt="complete" className=' my-16 ' />
      <div className=' flex flex-col justify-center items-center w-full'>

        <div className=' flex flex-col justify-center items-center gap-4 w-full'>
          <button autoFocus class="focus:outline-none   font-hanken-grotesk text-[16px] font-[500] sm:font-bold leading-[24px] sm:text-[12px] sm:leading-[12px] md:text-[16px] md:leading-[16px] bg-[#2965FB] text-white cursor-pointer border-2 px-2 py-4 border-[#2965FB] w-full" onClick={handleBackToService}>{t("back_to_account_services")}</button>
          {isCancelButton &&
            // <p className='font-hanken-grotesk text-[16px] font-[500] sm:font-bold leading-[24px] sm:text-[12px] sm:leading-[12px] md:text-[16px] md:leading-[16px] text-secondary-2 capitalize cursor-pointer' onClick={() => onCanelTranfer(true)}>{t("cancel_tranfer")}</p>
            <button class="font-hanken-grotesk text-[16px] font-[500] sm:font-bold leading-[24px] sm:text-[12px] sm:leading-[12px] md:text-[16px] md:leading-[16px] text-[#2965FB] cursor-pointer  px-2 py-4 w-full"  onClick={() => onCanelTranfer(true)}>{t("cancel_tranfer")}</button>

          }

        </div>
      </div>

    </div>
  )
}

export default TransferError