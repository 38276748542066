import React, { useState, useRef, useEffect, useCallback } from 'react';
import FilterIcon from '../../../assets/images/Filter.png';
import GreyFilterIcon from '../../../assets/images/Filter_grey.png';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../Search';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
const Tabs = (props) => {
        global.checkIconVisibleFormGlobal = true
  const { children, onFilterClick, isEnabled, updateActiveTab, selectedTab,closeArrowIcon, SearchVal,filterEnable, handleSubmitSearch, cardType, labelDisable, activityDisable, upComingDisable,handleGotitWithSearchClick,handleGotitWithSearchClickForSaving } = props
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //debugger;

  // const defaultActiveTab = "filter";
  const defaultActiveTab = selectedTab != undefined ? selectedTab : children && children.length > 0 ? children[0].props.label : null;

  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isTooltipVisibleForSaving, setIsTooltipVisibleForSaving] = useState(false);

  const [labelsShow, setLabelsShow] = useState(true);

  const showSearchOnHome = useSelector(state => state.showSearchOnHome);
  const SearchFiltervalue = useSelector(state => state.SearchFiltervalue);
  const closeIconFromStore = useSelector(state => state.closeIconFromStore);

  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const placeholderText = `Search ${activeTab}`;

  const handleClick = (e, newActiveTab) => {
    CloseIconClick()
    // dispatch({ type: 'SEARCH_FILTER_VALUE', payload: { SearchFiltervalue: '' } });
    debugger;
    if(newActiveTab === 'Activity'){
      if(activityDisable){
        return
      }
    } else if(newActiveTab === 'Upcoming'){
      if(upComingDisable){
        setIsInputVisible(false)
        return
      }
    }
      if(newActiveTab === 'Upcoming'){
        setIsInputVisible(false)
      }
      else{
        // setIsInputVisible(true)
      }
    e.preventDefault();
    // if ((props?.cardType != t('checking') ? isEnabled : true)) {
      setActiveTab(newActiveTab);
      updateActiveTab(newActiveTab)
    // }
  };


  // const handleSearchClick = useCallback(() => {
  //   const showTooltip = { showTooltip: false } 
  //   dispatch({ type: 'SHOW_TOOLTIP', payload: showTooltip });
  //   setIsInputVisible(!isInputVisible); 
  //   const tooltipShow = sessionStorage.getItem('tooltipShown');
  //   if (!tooltipShow) {
  //     setIsTooltipVisible(true);
  //   }
  //   setLabelsShow(false);
  //  },
  //  []);



  const handleSearchClick = useCallback(() => {
    
    if (cardType === 'Spending') {
      const showTooltip = { showTooltip: false };
      handleGotitWithSearchClick()
      dispatch({ type: 'SHOW_TOOLTIP', payload: showTooltip });
    } else if (cardType === 'Savings') {
      const showTooltipForSavings = { showTooltipForSavings: false };
      dispatch({ type: 'SHOW_TOOLTIP_FOR_SAVING', payload: showTooltipForSavings });
      handleGotitWithSearchClickForSaving()
    }
    setIsInputVisible(!isInputVisible);

    const tooltipShow = sessionStorage.getItem('tooltipShown');
    const tooltipShowForSavings = sessionStorage.getItem('tooltipShownForSavings');
    if (!tooltipShow && cardType === 'Spending') {
      setIsTooltipVisible(true);
    }
    else if (!tooltipShowForSavings && cardType === 'Savings') {
      setIsTooltipVisibleForSaving(true);
    }
    setLabelsShow(false);
  }, [cardType, dispatch]);

  // setLabelsShow(true);




  const handleClear = () => {
    dispatch({ type: 'SEARCH_FILTER_VALUE', payload: { SearchFiltervalue: '' } });
    dispatch({ type: 'SHOW_SEARCH_ON_HOME', payload: { showSearchOnHome: false } });
    setInputValue('');
    setIsInputVisible(false);
    setLabelsShow(true);
    const showTooltip = { showTooltip: true }
    dispatch({ type: 'SHOW_TOOLTIP', payload: showTooltip });
    const spendingSessionStatus = sessionStorage.getItem("tooltipState_Spending");
    const savingSessionStatus = sessionStorage.getItem("tooltipState_Saving");
    if (spendingSessionStatus == null || savingSessionStatus == null) {
      const showTooltip = { showTooltip: true }
      dispatch({ type: 'SHOW_TOOLTIP', payload: showTooltip });
    } else {
      const showTooltip = { showTooltip: false }
      dispatch({ type: 'SHOW_TOOLTIP', payload: showTooltip });
    }
    // handleSubmitSearch('');
  };

  const handleSearchValue = useCallback(
    _.debounce((value) => {
      setInputValue(value);
      // alert(value);
      handleSubmitSearch(value,activeTab);
    }, 300), // 300ms debounce time
    []
  );

  const handleSearchChange = (value) => {
    global.searchValueFromGlobal = value;
    handleSearchValue(value);
  };


  const handleArrowClick = () => {
    setShowCloseIcon(true);
    closeArrowIcon(true)
  }

  useEffect(()=>{
    if(!!closeIconFromStore){
    setShowCloseIcon(true);
    }
    else{
    setShowCloseIcon(false);
    }
  },[closeIconFromStore])

  useEffect(()=>{
    dispatch({ type: 'CLOSE_ICON_FROM_STORE', payload: { closeIconFromStore: false } });
  },[selectedTab])

    

  const CloseIconClick = () => {
    setInputValue('');
  }

  const hideTootipGotIt = () => {
    setIsTooltipVisible(false);
    sessionStorage.setItem('tooltipShown', true);
  }
  const hideTootipGotItForSaving = () => {
    setIsTooltipVisibleForSaving(false)
    sessionStorage.setItem('tooltipShownForSavings', true);
  }


  useEffect(() => {
    if (SearchFiltervalue) {
      if (!isInputVisible) { // Only update if the state has actually changed
        setIsInputVisible(showSearchOnHome);
        sessionStorage.setItem('tooltipShown', true);
        handleSearchClick();
      }
    }
  }, [SearchFiltervalue, showSearchOnHome, handleSearchClick, isInputVisible]);



  return (
    <>
      <div className={`${labelsShow ? 'space-x-6' : 'sm:space-x-6'} flex justify-between items-center md:px-16 sm:px-[2.5rem]`}>

        {React.Children.map(children, (child) => (
          // <span 
          //   key={child.props.label}  
          //   className={`${labelsShow ? 'block' : 'sm:block hidden'} md:w-[60px] sm:w-[39px]  lg:text-[18px] md:text-[18px] sm:text-[11px] text-[14px] font-[600] sm:font-[700] py-3 cursor-pointer
          //      ${(props?.cardType != t('checking') ? isEnabled : true) ? (activeTab === child.props.label ? 'border-t-2 border-[#2965FB] text-[#2965FB]' : 'text-[#262B3A] ') : 'text-[#262B3A] opacity-50'}`}
          //   onClick={(e) => handleClick(e, child.props.label)}
          //   >
          //   {child.props.label}
          // </span> 

          <span
            key={child.props.label}
        //     className={`${labelsShow ? 'block' : 'sm:block hidden'} 
        // md:w-[60px] sm:w-[39px] lg:text-[18px] md:text-[18px] sm:text-[11px] text-[14px] font-[600] sm:font-[700] py-3
   
        //        ${(child.props.label === 'Upcoming' && upComingDisable) ? 'text-[#262B3A] opacity-50 cursor-not-allowed'
        //         : 
        //          (child.props.label === 'Activity' && activityDisable) ? 'text-[#262B3A] opacity-50 cursor-not-allowed' :

        //         (props?.cardType !== t('checking') ? isEnabled : true)
        //           ? (activeTab === child.props.label
        //             ? (labelDisable ? 'text-[#262B3A] opacity-50 cursor-not-allowed' : 'cursor-pointer border-t-2 border-[#2965FB] text-[#2965FB]')
        //             : 'text-[#262B3A] cursor-pointer')
        //           : 'text-[#262B3A] opacity-50 cursor-not-allowed'
        //       } first-letter:

        //     `}

        className={`${labelsShow ? 'block' : 'sm:block hidden'} 
            md:w-[60px] sm:w-[39px] lg:text-[18px] md:text-[18px] sm:text-[11px] text-[14px] font-[600] sm:font-[700] py-3
            ${
              (child.props.label === 'Upcoming' && upComingDisable) 
                ? 'text-[#262B3A] opacity-50 cursor-not-allowed' 
                : (child.props.label === 'Activity' && activityDisable) 
                ? 'text-[#262B3A] opacity-50 cursor-not-allowed'
                : (props?.cardType !== t('checking') && !isEnabled) 
                ? 'text-[#262B3A] opacity-50 cursor-not-allowed' 
                : (activeTab === child.props.label)
                ? (labelDisable ? 'text-[#262B3A] opacity-50 cursor-not-allowed' : 'cursor-pointer border-t-2 border-[#2965FB] text-[#2965FB]')
                : 'text-[#262B3A] cursor-pointer'
            }`
          }

                
    
            onClick={(e) => handleClick(e, child.props.label)}
          >
            {child.props.label}
          </span>

        ))}


        <div class="flex-grow"></div>
        {/* ///Serach */}
        <SearchInput
          placeholderText="Search..."
          isEnabled={isEnabled}
          labelsShow={labelsShow}
          handleSearchClick={handleSearchClick}
          isInputVisible={isInputVisible}
          isTooltipVisible={isTooltipVisible}
          CloseIconClick={CloseIconClick}
          showCloseIcon={showCloseIcon}
          onInputChange={handleSearchChange}
          handleArrowClick={handleArrowClick}
          handleClear={handleClear}
          selectedTab={selectedTab}
          hideTootipGotIt={hideTootipGotIt}
          hideTootipGotItForSaving={hideTootipGotItForSaving}
          isTooltipVisibleForSaving={isTooltipVisibleForSaving}
        />
        {/* ///filter */}
        <span
          onClick={() => {
            if (isEnabled) onFilterClick(activeTab)
            // onFilterClick(activeTab)
          }
          }
          className={`flex justify-between ml-auto space-x-1 ${isEnabled ? ' cursor-pointer text-[#2965FB]' : 'text-[#262B3A] opacity-50 cursor-not-allowed'}  lg:text-[18px] md:text-[18px] sm:text-[11px] text-[14px] font-[600] sm:font-[700]  items-center`}
        >
          <img
            src={isEnabled ? FilterIcon : GreyFilterIcon}
            // src={ FilterIcon }
            alt="Filter Icon"
            className="lg:w-[14px] lg:h-[14px] md:w-[11px] md:h-[11px] w-[10px] h-[10px]"
          />
          <span className="">{t('FiltersBtn')}</span>
        </span>
      </div>

      {React.Children.map(children, (child) => {
        if (child.props.label === activeTab) {
          return (
            <div
              className="md:px-16 sm:px-[2.5rem]"
              key={child.props.label}
            >
              <table className="w-full mt-[2rem]">
                <tbody>{child.props.children}</tbody>
              </table>
            </div>
          );
        } else {

        }
        return null;
      })}
    </>
  );
};



const Tab = ({ label, children }) => {
  return (
    <div label={label} className="hidden">
      {children}
    </div>
  );
};
export { Tabs, Tab };