import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../../../assets/images/home_logo.png';
import toggleImageDot from '../../../../assets/images/toggle_image.png';
import toggleImageWithoutDot from '../../../../assets/images/toggle_imagee.png';
import {  useSelector,useDispatch } from 'react-redux';
import logFirebaseEvents from '../../../LogFirebaseEvents';


const NavBar = ({ isSidebarOpen, handleSidebarToggle, unreadCount,savingEnrollClicked, ReferralLogoUri }) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isReferralLogoUri, setIsReferralLogoUri] = useState(ReferralLogoUri);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const  isAcceptAgreement  = useSelector(state => state.isAcceptAgreement)
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const savingsNewClicked = useSelector(state => state.savingsNewClicked);

  const handleClick = (page) => {
    const updatedIsSidebarOpen = true;
    handleSidebarToggle(updatedIsSidebarOpen);
    navigate(page);
  };



  const ListItemstyles = "font-[700] block py-2 sm:pl-1 sm:pr-2 md:pl-3 md:pr-4 text-white text-[14px] sm:text-[12px] md:text-[18px] leading-[18px] font-hanken-grotesk cursor-pointer"
  return (
    <>
      <nav className="bg-[#262B3A]">
        <div className="max-w-full flex flex-wrap items-center justify-between 
        sm:px-[6%] px-10 py-6">
          <div className=' flex flex-row gap-4'>
          <Link to="/home">
            <img
              src={Logo}
              onClick={()=>{
                dispatch({ type: 'SELECTED_CARD_TAB', payload: { selectedCardTab: t('ActivityTab') } })
                 dispatch({ type: 'SEARCH_FILTER_VALUE', payload: { SearchFiltervalue: '' } }); 
              }}
              className="md:h-[39px] w-[97px] h-[29px] md:w-[130px] cursor-pointer"
              alt="Logo"
            />
          </Link>
          {isReferralLogoUri &&
            <img
                src={isReferralLogoUri}
                // className="md:h-[39px] w-[26px] h-[29px] md:w-[36px] cursor-pointer"
                className="h-[29px] md:h-[39px] cursor-pointer ml-2"
                alt="Referral Logo"
                onError={event =>{
                  setIsReferralLogoUri(null)

                }}
              />
          }


          </div>
          {!isAcceptAgreement &&
            <div className="flex">
              <div
                className={`hidden ml-auto w-full sm:block sm:w-auto transition-all duration-1000 
                ${isSidebarOpen ? 'md:mr-[0rem] sm:mr-[0rem]' : 'mr-[0rem]'}`}
                id="navbar-default"
              >
                <ul
                  className="font-medium flex flex-col p-4 sm:p-0 mt-4
                             border border-gray-100 rounded-lg sm:flex-row sm:space-x-8 
                             sm:mt-0 sm:border-0 dark:bg-gray-800 
                             sm:dark:bg-gray-900 dark:border-gray-700 mr-8"
              >
                <li>
                  <Link
                    to="/Spending"
                    className={ListItemstyles}
                    onClick={()=>{
                     dispatch({ type: 'SEARCH_FILTER_VALUE', payload: { SearchFiltervalue: '' } }); 
                      logFirebaseEvents("Home", "Spending_Clicked", "Spending_Clicked","", "")
                    }}
                  >
                    {t('NavbarChecking')}
                  </Link>
                </li>
                <li>
                  <div
                   //to="/Savings"
                 
                    className={ListItemstyles}
                  >
                      <div className='flex items-center justify-between' onClick={()=>{
                         dispatch({ type: 'SEARCH_FILTER_VALUE', payload: { SearchFiltervalue: '' } }); 
                        if (savingsCardAvailable == false && savingsNewClicked == false) {
                          const savingsNewPayload = { savingsNewClicked: true };
                          dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
                          savingEnrollClicked();
                        }else if(savingsCardAvailable == false && savingsNewClicked == true){
                         savingEnrollClicked();
                        }else{
                          navigate('/Savings');
                        }
                       logFirebaseEvents("Home", "Savings_Clicked", "Savings_Clicked", "","")
                      
                        }}>
                        {/* {t('NavbarSavings')} */}
                        <span className='mr-4'>{t('NavbarSavings')}</span> 
                        {(savingsCardAvailable == false && savingsNewClicked == false) && <div
                          className={`bg-[#2965FB] flex items-center text-white
                        justify-center text-center w-auto px-[15px] md:h-[25px] sm:h-[20px] h-[20px] rounded-[89px]
                        lg:text-[16px] md:text-[12px] sm:text-[12px] text-[12px] font-[400] font-hanken-grotesk cursor-pointer`}
                          onClick={() => { 
                            
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                            }
                          }}
                          tabIndex={0} // Set tabIndex here for the button
                        >
                          New
                        </div>}
                        {(savingsCardAvailable == false && savingsNewClicked == true) && <div
                          className={` border-[1.5px]
                          border-[#A3CEBB] flex items-center text-[#A3CEBB]
                        justify-center text-center w-auto px-[15px] md:h-[25px] sm:h-[20px] h-[20px] rounded-[89px]
                        lg:text-[16px] md:text-[12px] sm:text-[12px] text-[12px] font-[400] font-hanken-grotesk cursor-pointer`}
                          onClick={() => {
                         
                           }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                            }
                          }}
                          tabIndex={0} // Set tabIndex here for the button
                        >
                          Enroll
                        </div>}
                      </div>
                   

                  </div>
                </li>
                <li>
                  <Link
                    to="/PayPerks"
                    className={ListItemstyles}
                    onClick={()=>logFirebaseEvents("Home", "PayPerks_Clicked", "PayPerks_Clicked","", "")}
                  >
                    {t('NavbarPerks')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Climb"
                    className={ListItemstyles}
                    onClick={()=>logFirebaseEvents("Home", "Climb_Clicked", "Climb_Clicked","", "")}
                  >
                    {t('NavbarClimb')}
                  </Link>
                </li>
                <li>
                  <Link
                    to='/Help/FAQ'
                    className={`${ListItemstyles}`}
                    onClick={()=>logFirebaseEvents("Home", "Help_Clicked", "Help_Clicked", "","")}
                  >
                    {t('helpText')}
                  </Link>
                </li>
               
              </ul>
            </div>
            <div
              className={`transition-all duration-1000 
                   ${isSidebarOpen ? 'mr-[13rem]' : 'mr-0'}`}
              >
                <button onClick={() => handleClick('')} className="text-white">
                  <img
                    src={(unreadCount || (!savingsCardAvailable && savingsNewClicked == false)) ? `${toggleImageDot}` : `${toggleImageWithoutDot}`}
                    className="lg:w-[19px] lg:h-[19px] md:h-[17px] md:w-[17px] sm:h-[16px] sm:w-[16px] w-[17px] h-[17px] sm:mt-2"
                    alt="toggleImage"
                  />
                </button>
              </div>
            </div>}
        </div>
      </nav>
    </>
  );
};

export default NavBar;
