import { useTranslation } from "react-i18next";
import { fetchConfigarationData, retrieveData } from "../../../helpers/utils/Utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from "../../../network/NetworkUtils";
import { GET_NOTIFICATION_SETTINGS, NOTIF_CONFIG_BANCORP, NOTIF_CONFIG_POLICY, NOTIF_CONFIG_SMS_SERVICE, PROGRAM_CONTENT, STATUS_SUCCESS_CODE, UPDATE_NOTIFICATION_SETTINGS } from "../../../network/URLConstants";
import Loader from "../../CustomComponents/Loader";
import { withOidcSecure } from "@axa-fr/react-oidc";
import NotificationConfigureTable from "./NotificationConfigureTable";
import NotificationFilter from "./NotificationFilter";
import arrow from "../../../assets/images/arrow.png"
import { convertTo24HourFormat, displayErrorMessage } from "../../../utils";
import HomeLayout from "../../Layout/HomeLayout";
import HomeHeader from "../../CustomComponents/HomeHeader";
import Modal from "../../CustomComponents/Modal";
import DynamicCard from "../../CustomComponents/DynamicCard";
import { width } from "../../CustomComponents/DynamicCard/DynamicCardStyles";
import { useNavigate } from "react-router";
import { useKeyDown } from "../../../hooks/useKeyDown";
import LineBreaks from "../../../utils";
import moment from "moment";
import logFirebaseEvents from "../../LogFirebaseEvents";

const appConfigData = await fetchConfigarationData();

const NotificationConfigure = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [notificationSettings, setNotificationSettings] = useState(null);
    const [notificationConfig, setNotificationConfig] = useState(null);
    const [updatedNotificationConfig, setUpdatedNotificationConfig] = useState([]);
    const [notificationTimeFrame, setNotificationTimeFrame] = useState(null);
    const [isShowNotificationConfigContent, setIsShowNotificationConfigContent] = useState(false)
    const [isReset, setIsReset] = useState(false);
    const [optInForTextCom, setOptInForTextCom] = useState(true);
    const [policyURL, setPolicyURL] = useState('');
    const [showAgreementsModel, setShowAgreementsModel] = useState(false);
    const [isMobile, setIsMobile] = useState(true)
    const [infoMessage, setinfoMessage] = useState('');



    const accessToken = useSelector(state => state.accessToken);
    const userProfile = useSelector(state => JSON.parse(state.userProfile));
    const cards = useSelector(state => JSON.parse(state.userCards));


    const largeScreenMediaQuery = window.matchMedia('(min-width: 768px)');
    useEffect(() => {
        setIsMobile(largeScreenMediaQuery.matches); // Execute immediately if the screen size is already at or above 'sm'

    }, [])


    const getAgreementsBasedOnType = async (url) => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        setIsLoading(true)

        let finalUrl = (PROGRAM_CONTENT + "?accountType=64&language=en&contentType=" + url + "&programKey=200")
        var response = await GetRequest(finalUrl, headers)
        var responseObj = JSON.parse(response)
        setIsLoading(false)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setPolicyURL(responseObj.Response.Data)
        } else {
            setTimeout(() => {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                // setError(displayText)
            }, 500);
        }
    }

    const LinkClick = (href, e) => {
        e.preventDefault();
        e.stopPropagation();
        switch (href) {
            case NOTIF_CONFIG_POLICY:
                logFirebaseEvents("Configure_Notification", "Kudzu_Privacy_Policy_Clicked", "Kudzu_Privacy_Policy_Clicked", "","")
                getAgreementsBasedOnType("PPrivacyPolicy")
                setShowAgreementsModel(true);

                break;
            case NOTIF_CONFIG_SMS_SERVICE:
                logFirebaseEvents("Configure_Notification", "Kudzu_Alert_Terms_Of_Services_Clicked", "Kudzu_Alert_Terms_Of_Services_Clicked", "","")
                getAgreementsBasedOnType("SMSTerms")
                setShowAgreementsModel(true);
                break;
            case NOTIF_CONFIG_BANCORP:
                logFirebaseEvents("Configure_Notification", "BanCorp_Privacy_Notice_Clicked", "BanCorp_Privacy_Notice_Clicked", "","")
                getAgreementsBasedOnType("PrivacyPolicyV2")
                setShowAgreementsModel(true);

            default:
                break;
        }
    };


    const updateConfigObjectByAlertCode = (AlertCode, updatedKeyValues) => {
        const updatedNotificationConfigArray = notificationConfig.map((configObj) => {
            if (configObj.AlertCode === AlertCode) {
                return { ...configObj, ...updatedKeyValues };
            } else {
            }
            return configObj;
        });

        setNotificationConfig(updatedNotificationConfigArray);
    }

    const updateTimeFrame = (timeFrom, timeTo) => {
        setNotificationTimeFrame({
            "timeFrom": timeFrom,
            "timeTo": timeTo
        })
    }

    const fetchNotificationSettings = async () => {
        if (cards == null || cards == undefined) {
            return
        }
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = GET_NOTIFICATION_SETTINGS(cardKey)
            const response = await GetRequest(url, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false);
                setNotificationSettings(responseJson.Response);
                const updateNotificationConfigObjWithCodeParameter = responseJson.Response.Notifications.map((obj) => {
                    return { ...obj, "Code": obj.AlertCode };
                })
                setNotificationConfig(updateNotificationConfigObjWithCodeParameter)
                setNotificationTimeFrame(
                    {
                        "timeFrom": responseJson.Response.NotificationFromTime,
                        "timeTo": responseJson.Response.NotificationToTime
                    }
                )
                setIsShowNotificationConfigContent(true);
            }

        } catch (error) {
        }
    }

    useEffect(() => {
        fetchNotificationSettings();
    }, [])

    const applyFilters = async () => {
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = UPDATE_NOTIFICATION_SETTINGS(cardKey)
            const body = {
                CardholderAlertNotifications: notificationConfig,  //to change the configurations selected by user
                NotificationFromTime: moment(notificationTimeFrame?.timeFrom, ['h:mm A', 'HH:mm:ss']).format('HH:mm'), // to change it to time selected by user
                NotificationToTime: moment(notificationTimeFrame?.timeTo, ['h:mm A', 'HH:mm:ss']).format('HH:mm')// to change it to time selected by user
            }
            const response = await PostRequest(url, body, headers);
            const responseJson = await JSON.parse(response);
            setinfoMessage('')
            setIsLoading(false);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                fetchNotificationSettings();
            }

        } catch (error) {
            setIsLoading(false);
            setinfoMessage(error.message)
        }
    }

    useKeyDown(() => {
   
        applyFilters()

    }, ["Enter"]);

    const resetFilters = () => {
        logFirebaseEvents("Configure_Notification", "Reset_Clicked", "Reset_Clicked", "","")
        const updateNotificationConfigObjWithCodeParameter = notificationSettings.Notifications.map((obj) => {
            return { ...obj, "Code": obj.AlertCode };
        })
        setNotificationConfig(updateNotificationConfigObjWithCodeParameter);
        setUpdatedNotificationConfig([]);
        setNotificationTimeFrame(
            {
                "timeFrom": notificationSettings.NotificationFromTime,
                "timeTo": notificationSettings.NotificationToTime

            })
        setIsReset(prev => !prev);
    }

    const Headingcom = () => {
        return (
            <div className="relative">
                <div className={`flex flex-col justify-start my-12 sm:my-6 md:my-10 sm:mx-8 mx-12`}>
                    <p className='font-hanken-grotesk font-[500] 
                       lg:text-[26px] md:text-[26px] 
                       sm:text-[15px] text-[16px] leading-[24px] sm:font-[600] 
                        sm:leading-[23px] md:font-[500] 
                         md:leading-[36px]'>{t("config_notification")}</p>
                </div>
            </div>
        )
    }

    const children = () => {
        return (
            <div className=" flex justify-center sm:mx-0 mx-4">
                <div className="flex flex-col gap-10 sm:gap-8 md:gap-10 sm:py-16 w-full sm:w-[90%] ">
                    {isShowNotificationConfigContent &&
                        <div className=" w-full">
                            <p className="font-hanken-grotesk font-normal text-[12px] leading-[18px] sm:font-[500] sm:text-[10px] sm:leading-[16px] md:text-[14px] md:leading-[22px] text-secondary-3"
                                dangerouslySetInnerHTML={{ __html: notificationSettings.Metadata.ShortCodeMessage.replaceAll(`style='color:#30BC89; text-decoration: none'`, `style='color:#30BC89; text-decoration: none; font-weight: 700'`)
                                        .replaceAll(`style='color:#2965FB; text-decoration: none'`, `style='color:#2965FB; text-decoration: none; font-weight: 700'`)
                                        .replaceAll(`style='color:#30BC89;text-decoration: none text-decoration: none'`,`style='color:#30BC89;text-decoration: none; font-weight: 700'`)

                                }}
                                onClick={(e) => {
                                    if (e.target.tagName === 'A') {
                                        const href = e.target.getAttribute('href');
                                        LinkClick(href, e); // Pass the event object to useLinkClick
                                    }
                                }}
                            />
                        </div>
                    }

                    <hr className=" h-[2.5px] bg-blue-sky border-0 rounded" />
                    {isShowNotificationConfigContent &&
                        <NotificationConfigureTable notifications={notificationSettings.Notifications} updateConfigObjectByAlertCode={updateConfigObjectByAlertCode} isReset={isReset} />
                    }

                    <hr className=" h-[2.5px] bg-blue-sky border-0 rounded" />

                    <div className=" flex flex-row justify-between cursor-pointer" onClick={() => {navigate("/Climb/ClimbSettings"); logFirebaseEvents("Configure_Notification", "Manage_Climb_Preference_Clicked", "Manage_Climb_Preference_Clicked", "","")}}>
                        <p className="font-hanken-grotesk font-[500] text-[14px] leading-[20px] sm:font-[700] sm:text-[12px] sm:leading-[20px] md:font-[600] md:text-[18px] md:leading-[18px] text-secondary-2"
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate("/Climb/ClimbSettings")
                                }
                            }}
                        >{t("manage_climb_preferences")}</p>
                        <img src={arrow} alt="arrow" />
                    </div>

                    <hr className=" h-[2.5px] bg-blue-sky border-0 rounded" />

                    {isShowNotificationConfigContent &&
                        <NotificationFilter
                            NotificationFromTime={notificationSettings.NotificationFromTime}
                            NotificationToTime={notificationSettings.NotificationToTime}
                            updateTimeFrame={updateTimeFrame}
                            TimeSelectionLable={notificationSettings.Metadata.TimeSelectionLable}
                            isReset={isReset}
                        />
                    }
                    {(infoMessage != '') &&
                        <div className='mb-2'>
                            {displayErrorMessage(infoMessage)}
                        </div>
                    }

                    <div class="flex flex-col-reverse justify-center gap-4 sm:gap-0 sm:flex-row items-center sm:space-x-6 mt-4 sm:justify-end mb-10 sm:mb-0">
                        <button class="font-hanken-grotesk font-[500] text-[16px] leading-[26px] sm:font-[700] sm:text-[13px] sm:leading-[13px] md:font-[700] md:text-[16px] md:leading-[26px] text-secondary-2 sm:bg-inherit bg-[#D6E2E8]   cursor-pointer px-2 sm:px-0 lg:px-2 py-4 w-full sm:w-fit lg:w-[100px] capitalize"
                            onClick={resetFilters}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    // event.preventDefault();
                                    // resetFilters();
                                    debugger;
                                    const updateNotificationConfigObjWithCodeParameter = notificationSettings.Notifications.map((obj) => {
                                        return { ...obj, "Code": obj.AlertCode };
                                    })
                                    setNotificationConfig(updateNotificationConfigObjWithCodeParameter);
                                    setUpdatedNotificationConfig([]);
                                    setNotificationTimeFrame(
                                        {
                                            "timeFrom": notificationSettings.NotificationFromTime,
                                            "timeTo": notificationSettings.NotificationToTime

                                        })
                                    setIsReset(prev => !prev);
                                }
                            }}
                        >{t("reset")}</button>
                        <button class="font-hanken-grotesk font-[500] text-[16px] leading-[26px] sm:font-[700] sm:text-[13px] sm:leading-[13px] md:font-[700] md:text-[16px] md:leading-[26px] text-secondary-2 sm:bg-inherit bg-[#2965FB] cursor-pointer px-2 sm:px-0 lg:px-2 py-4 w-full sm:w-fit lg:w-[100px] capitalize
                         text-white  lg:text-[#2965FB] sm:text-[#2965FB]  "
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    applyFilters();
                                }
                            }}
                            onClick={()=>{applyFilters(); logFirebaseEvents("Configure_Notification", "Save_Clicked", "Save_Clicked", "","")}}
                        >{t("save")}</button>
                    </div>


                </div>

            </div>

        )
    }


    return (

        <>
            {/* <HomeLayout>
                <HomeHeader heading={t("configure_notofications")} isHeaderCaption={true} />
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight animate__slow'>

                    <DynamicCard
                        headerContent={Headingcom()}
                        cardHeight='content-fit'
                        cardContent={children()}
                        cardCornerType='tr-bl'
                        isCardFooter={false}
                    />
                </div>

            </HomeLayout > */}
            <HomeLayout>
                <HomeHeader heading={LineBreaks(t("configure_notofications"))} isHeaderCaption={true} />
                <div className='flex flex-col md:w-[860px] 
                sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>

                    <DynamicCard
                        header={false}
                        HeaderContent={Headingcom()}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        footer={true}
                        isWhite={true}
                        backgroundColor={!isMobile ? '#fffff' : 'rgba(148, 195, 232, 0.25)'}
                    >

                        {children()}
                    </DynamicCard>

                </div>
            </HomeLayout >
            {isLoading && <Loader />}


            <Modal isIframe={true}
                isOpen={showAgreementsModel}
                onClose={() => setShowAgreementsModel(false)}
                title={false}
                content={<div className="h-[100%] w-full md:pt-0 sm:pt-6  pt-16" style={{ height: window.innerHeight }}>
                    <iframe style={{ height: window.innerHeight }} src={policyURL}
                        title="Embedded Website" className="w-full h-full rounded-br-[4rem]"
                    /></div>
                }
            />

        </>


    )
}

export default withOidcSecure(NotificationConfigure)