import React, { useEffect, useState, useRef } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HomeLayout from '../../Layout/HomeLayout';
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../../CustomComponents/DynamicCard';
import FindATM from '../../../assets/images/illo-ATM.png'
import { useNavigate } from 'react-router';
import Accordion from '../../CustomComponents/Accordation';
import CustomDropdown from '../../CustomComponents/CustomDropDown';
import CustomTextInput from '../../CustomComponents/CustomtextInput';
import { CHECKING, retrieveData } from '../../../utils';
import { width } from '../../CustomComponents/DynamicCard/DynamicCardStyles';
import CustomTextArea from '../../CustomComponents/CustomTextArea';
import Allpoint from '../../../assets/images/allpoint.png';
import Visa from '../../../assets/images/visa.png'
import FooterContent from '../../CustomComponents/Footer';
import Chat from '../../../assets/images/chat.png'
import { ATM_LOCATIONS } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import { HelpSidebar } from '../../../utils';

import Loader from '../../CustomComponents/Loader';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import logFirebaseEvents from '../../LogFirebaseEvents';
const ATM = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [headerContenttext, setheaderContent] = useState('');
    const [footerContentText, setfooterContentText] = useState('');
    const [AtmImages, setAtmImages] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[14px] md:text-[13px] sm:text-[11px] text-[12px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [toNavigate, setToNavigate] = useState('');
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);

    const cards = useSelector(state => JSON.parse(state.userCards));
    const userLanguage = useSelector(state => state.userLanguage);

    const accessToken = useSelector(state => state.accessToken);
    const initialCards = useRef(cards); // Store initial cards


    useEffect(() => {
        if (initialCards?.current?.length > 0) {


            const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;
            const isLanguage = 'en';
            fetchATMs(isPrimrayKey, isLanguage);

        }

    }, []);


    const fetchATMs = async (isPrimrayKey, isLanguage) => {
        //   debugger;
        if (cards == undefined || cards == null) {
            return
        }
        setIsLoading(true)
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const url = ATM_LOCATIONS(isPrimrayKey, isLanguage)
        const response = await GetRequest(url, headers);


        const responseJson = JSON.parse(response);
        setheaderContent(responseJson.Response.Text);
        setfooterContentText(responseJson.Response.Footer);
        setAtmImages(responseJson.Response.Items)
        setIsLoading(false)

    }

    // const [isLoading, setIsLoading] = useState(false);

    const footerContent = () => {
        return (
            <FooterContent highlightText={t("Questions_concerns")}
                subHeaderText={t("We’re_available")}
                image={Chat} buttonEnabled={true}
                isSubheadingwidth={true}
                isSpace={true}
                buttonText={t("Contact_Us")}
                footerBtnAction={() => {navigate('/Help/ContactUs'); logFirebaseEvents("Find_An_ATM", "Contact_Clicked", "Contact_Clicked","", "")}} />

        )
    }

    // const handleImageClick = (redirectUrl) => {
    //     const confirmRedirect = window.confirm(`This action will redirect you to an external resource.\nDo you want to continue?`);
    //     if (confirmRedirect) {
    //         window.open(redirectUrl, '_blank');
    //     }
    // };
    const handleImageClick = (event, redirectUrl) => {
        logFirebaseEvents("Find_An_ATM", "ATM_Img_Clicked", "ATM_Img_Clicked","", "")
        if (redirectUrl) {
            event.preventDefault();
            const rawHref = redirectUrl;
            setToNavigate(rawHref.endsWith('.') ? rawHref.slice(0, -1) : rawHref)
            showAlertModal(true, t("heads_up"), t("about_to_leave_kudzu"), t("redirected_from_kudzu"), true, true, t("continue"), t("cancel"), true, '', false, false)
        }

    };
    const closeCustomAlertModal = () => {
        logFirebaseEvents("Find_An_ATM", "Cancel_Clicked", "Cancel_Clicked","", "")
        setShowModel(false);
    }
    const onAcptBtnClick = async () => {
        logFirebaseEvents("Find_An_ATM", "Continue_Clicked", "Continue_Clicked","", "")
        if (headerMsg == t('about_to_leave_kudzu')) { window.open(toNavigate, '_blank'); closeCustomAlertModal(); setToNavigate('') }
    }
    const onCancelBtnClick = () => {
   
        if (headerMsg == t('about_to_leave_kudzu')) { closeCustomAlertModal(); setToNavigate('') }
    }
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }

    return (
        <>
            <HomeLayout>
                <HelpSidebar HelpselectedIndex={3} />

                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        HeaderContent={false}
                        isWhite={true}
                    >
                        <div className='w-full'>
                            <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] md:font-[500] sm:font-[600] 
                       lg:text-[26px] md:text-[22px] 
                       sm:text-[15px] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">
                                {(t('find_ATM'))}
                            </div>

                            <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4'>
                            </div>

                            <div className='flex-col justify-center sm:mx-0 mx-4'>
                                <div className='w-full flex justify-center'>
                                    <div className='sm:w-[70%] md:w-[70%] w-full lg:my-16 md:my-12 sm:my-10 my-10 md:text-[18px] md:leading-[28px]
                                     sm:text-[10px] sm:leading-[16px] text-[16px] leading-[24px] font-hanken-grotesk font-[500]'
                                        dangerouslySetInnerHTML={{ __html: headerContenttext.replace('<br/>', '<br/><br/>') }} >
                                    </div>
                                </div>
                            </div>

                            <div className='w-full flex flex-row  justify-center
                             gap-5 items-center lg:py-10 py-0'>
                                {AtmImages.map(item => (
                                    <div key={item.Name} style={{ cursor: 'pointer' }}>
                                        <img className='md:w-[158.99px] md:h-[100px] 
                                        sm:w-[124px] sm:h-[80px] w-[124px] h-[80px]'
                                            src={item.ImageUrl}
                                            alt={item.Name}
                                            onClick={(event) => handleImageClick(event, item.RedirectUrl)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className='flex-col justify-center sm:mx-0 mx-4'>
                                <div className='w-full flex justify-center'>
                                    <div className='sm:w-[70%] md:w-[70%] w-full my-16 sm:my-12 md:my-16 pb-6'>
                                        <p dangerouslySetInnerHTML={{ __html: footerContentText }}
                                            className='md:text-[12px] sm:text-[10px] text-[12px] md:leading-[18px] sm:leading-[16px] leading-[18px] font-[600] font-hanken-grotesk sm:font-[500] md:font-[600] uppercase md: tracking-wide sm:tracking-wider'></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DynamicCard>
                    {footerContent()}
                </div>
            </HomeLayout >
            <CustomAlertModel isOpen={showModel}
                Fullheight={true}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
            />
            {isLoading && <Loader />}
        </>

    )
}

export default withOidcSecure(ATM)