import React, { useEffect } from "react";
import Logo from "../../../assets/images/logo.png";
import { Navbar, LogoContainer, LoginButton } from "./HeaderStyles";
import { useTranslation } from "react-i18next";
import { useSelector, connect, useDispatch } from 'react-redux';
import { useOidc, useOidcIdToken, useOidcAccessToken } from "@axa-fr/react-oidc";
import { useKeyDown } from "../../../hooks/useKeyDown";
import { useNavigate } from "react-router";
import logFirebaseEvents from "../../LogFirebaseEvents";


const Header = ({ openMobileVerificationModal, openLoginModal, openVerifyEmailModal,savingEnrollClicked }) => {

  const { t } = useTranslation(); // Hook to access translation functions
  const dispatch = useDispatch();
  const { login, logout, isAuthenticated, renewTokens, oidcUser } = useOidc();
  const ListItemstyles = "font-bold block py-2 sm:pl-1 sm:pr-2 md:pl-3 md:pr-4 text-white text-[16px] sm:text-[16px] md:text-[16px] leading-[18px] font-hanken-grotesk cursor-pointer"
const navigate=useNavigate();

  const sessionId = useSelector(state => state.sessionId);

  useEffect(() => {
    if (sessionId != null && sessionId != '') {
    }
  }, [sessionId])

  const openLogin = () => {
    logFirebaseEvents("sign_up_flow", "login_clicked", "login_clicked","", "");
    login("/")
  };

  // useKeyDown(() => {
  //   login("/")
  // }, ["Enter"]);

  // Sample: get the local item 
  if (sessionId) {
    return (
      <Navbar isOnBoard savingEnrollClicked={()=>{savingEnrollClicked()}}>
        <LogoContainer>
          <img src={Logo} alt="Kudzu Logo" />
        </LogoContainer>
        <div className="flex flex-row justify-center items-center gap-6 sm:gap-10">
    <p className={ListItemstyles} onClick={()=>{
       logFirebaseEvents("sign_up_flow", "Help_clicked", "Help_clicked","", "");
      window.location.replace('https://www.kudzumoney.com/contact-us')}}>{t('helpText')}</p>
          <LoginButton size="small" onClick={openLogin}>
            {t("login")}
          </LoginButton>
        </div>
      </Navbar>
    );
  } else {
    return (
      <Navbar  savingEnrollClicked={()=>{}} isOnBoard>
        <LogoContainer>
          <img src={Logo} alt="Kudzu Logo" />
        </LogoContainer>
        <div className="flex flex-row justify-center items-center gap-6 sm:gap-10">
        <p className={ListItemstyles} onClick={()=> {window.location.replace('https://www.kudzumoney.com/contact-us');logFirebaseEvents("header_screen", "Help_Clicked", "Help_Clicked","", "");}}>{t('helpText')}</p>

          <LoginButton size="small" onClick={openLogin}>
            {t("login")}
          </LoginButton>
          {/* <LoginButton size="small" onClick={openMobileVerificationModal}>
            {t("sign_up")}
          </LoginButton> */}
          {/*
          removed here to make it dynamic from 2 factor authentication
          <LoginButton size="small" onClick={openVerifyEmailModal}>
            {t("forgot_pswd")}
          </LoginButton> */}
        </div>
      </Navbar>
    );
  }
};

export default Header;
