import React, { useState } from "react";
import downArrow from "../../../assets/images/downArrow.png"
import upArrow from "../../../assets/images/upArrow1.png"

const Accordion = (props) => {
  
  const { title, content, index, expandedIndex, onClick }=props;
  const [isOpen, setIsOpen] = useState(index === expandedIndex || index === 0);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    onClick(index, content.category_id);
  };

  const handleLinkClick = (event) => {
    const target = event.target; 
    event.preventDefault(); 
    props?.linkClickAction({ link: target.getAttribute('href') })
 
  };

  const extractedTitle = title.split(":")[1];

  return (
    <div className="border-b border-[#E7E4DC] mb-6">
      <button
        className="w-full pb-6 text-left font-semibold flex justify-between items-center outline-none"
        onClick={toggleAccordion}
      >
        <span className="font-hanken-grotesk lg:text-[18px] 
        md:text-[16px] sm:text-[11px] text-[14px] sm:font-bold font-[600]
         leading-[24px]">
          {extractedTitle}</span>

        <span className="transform transition-transform">
          <img src={isOpen ? upArrow : downArrow} alt="arrow" className="w-[12px] h-[6px] sm:w-[9px] sm:h-[5px] md:w-[12px] md:h-[6px]"/>
          {/* {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 inline-block -rotate-180 "
              viewBox="0 0 20 20"
              fill="#2965FB"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 inline-block"
              viewBox="0 0 20 20"
              fill="#2965FB"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )} */}
        </span>
      </button>
      {isOpen && (
        <div className="font-hanken-grotesk text-[#559C7E] mt-3 lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px] font-normal leading-[24px]">
          {content.map((faq, index) => (
            <div key={index}>
              <p className="font-hanken-grotesk text-[#262B3A]
               lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px] font-normal leading-6">{faq.title}</p>
              <div className="sm:py-6 py-5" onClick={handleLinkClick} dangerouslySetInnerHTML={{ __html: faq.description }} />
              {index !== content.length - 1 && <hr className="md:mb-8 sm:mb-6 mb-4" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Accordion;
