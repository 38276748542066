import React, { createContext, useState } from 'react';

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [deviceId, setDeviceId]= useState(null);
    var devicesId = "";

    const login = (userData) => {
        setUser(userData);
    };

    const storeDeviceId = (deviceData) => {
        setDeviceId(deviceData);
    }

    const logout = () => {
        setUser(null);
    };
    
    const clearDeviceId = () => {
        setDeviceId(null);
    }

    const contextValue = {
        user,
        login,
        logout,
        deviceId,
        storeDeviceId,
        clearDeviceId
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };