import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../../CustomComponents/Card';
import { width, height } from "../../../../CustomComponents/DynamicCard/DynamicCardStyles";
import { useTranslation } from 'react-i18next';
import print_img from '../../../../../assets/images/print_img.png';
import print from '../../../../../assets/images/print.png';
import right_dropdown_arrow from '../../../../../assets/images/right_dropdown_arrow.png'
import FooterContent from '../../../../CustomComponents/Footer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { GET_DDA_DETAILS, GET_DDA_DOWNLOAD_URL, STATUS_SUCCESS_CODE } from '../../../../../network/URLConstants';
import { useSelector } from 'react-redux';
import { GetApiHeadersWithBearer, GetRequest } from '../../../../../network/NetworkUtils';
import { retrieveData } from '../../../../../helpers/utils/Utils';
import CustomtextInput from '../../../../CustomComponents/CustomtextInput';
import Loader from '../../../../CustomComponents/Loader';
import umbrella_kudzu from '../../../../../assets/images/umbrella_kudzu.png';
import LineBreaks from '../../../../../utils';
import { displayErrorMessage, MoreServices } from '../../../../../utils';
import HomeLayout from '../../../../Layout/HomeLayout';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import DynamicCard from '../../../../CustomComponents/DynamicHomeCard';
import RightArrow from '../../../../../assets/images/right_dropdown_arrow.png'
import { AccountDetailsHeder, copyToClipboard } from '../../../../../utils';
import CustomTextInput from '../../../../CustomComponents/CustomtextInput';
import { useNavigate } from 'react-router-dom';
import logFirebaseEvents from '../../../../LogFirebaseEvents';

const ManageDirectDeposit = ({ transactionDatas, onViewAllClick, viewAll }) => {
    const navigate = useNavigate();
    const btnStyles = 'font-hanken-grotesk md:text-[15px] sm:text-[10px] md:leading-[39.71px] sm:leading-[25.8px] w-[24%] md:h-[50px] sm:h-[36px] inline-block rounded-full border-[3px] border-[#30BC89] text-kudzu-text text-xs font-semibold leading-normal text-success transition duration-150 ease-in-out';
    const moreService = 'w-[90%] pb-[1.5rem] flex justify-between items-center border-b-[3px] border-[#D1E7DD]';
    const moreserviceText = 'font-[600] text-[#262B3A] lg:text-[16px] md:text-[15px] sm:text-[11px] text-[14px] leading-[16px] font-hanken-grotesk';

    const { t } = useTranslation(); // Hook to access translation functions
    const [accountNumber, setAccountNumber] = useState('123456789'); //Account Number
    const [routingNumber, setRoutingNumber] = useState('987654321');
    const [generateBtnClicked, setGenerateBtnClicked] = useState(false); //Routing Number
    const [employerName, setEmployerName] = useState(''); // Employer name
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [accountCopied, setAccountCopied] = useState(false);
    const [routingCopied, setRoutingCopied] = useState(false);
    const userProfile = useSelector(state => JSON.parse(state.userProfile));
    const cardDetails = useSelector(state => state.selectedCardDetails);
    const AccountRoutingText = 'font-[600] sm:text-[#559C7E] text-[#262B3A] sm:opacity-100 opacity-[50%] lg:text-[16px] md:text-[15px] sm:text-[13px] text-[12px] leading-[16px] font-hanken-grotesk md:pb-8 sm:pb-4';
    const AccountRoutingNum = 'font-[600] text-[#262B3A] md:text-[16px] sm:text-[13px] text-[12px]  leading-[16px] tracking-[2px] sm:flex';

    const selectedItem = cards?.[0]

    const divRef = useRef(null);

    const [loading, setLoading] = useState(false)
    const [infoMessage, setinfoMessage] = useState('');
    const [menuList, setMenuList] = useState([{ name: 'Pay a bill', biometricEnabled: false, biometric: false },
    { name: 'Deposit Money', biometricEnabled: false, biometric: false },
    { name: 'Transfer Money', biometricEnabled: false, biometric: false },
    { name: 'wire Money', biometricEnabled: false, biometric: false },
    { name: 'Edit Account Name', biometricEnabled: false, biometric: false },
    { name: 'Order Check Book', biometricEnabled: false, biometric: false }
    ])

    ///more services

    const moreServicesArray = [
        { name: `${t('add_cash')}`, actionType: '', path: '/AccountServices/Spending/AddCash' },

    ];

    useEffect(() => {
        getDdaDetails();
    }, [accountNumber, routingNumber]);


    const getDdaDetails = async () => {
        if (selectedItem == undefined || selectedItem == null) {
            return
        }
        setLoading(true)
        let url = GET_DDA_DETAILS + selectedItem?.CardKey;
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var response = await GetRequest(url, headers)
        setTimeout(() => {
            setLoading(false)
        }, 500);
        var responseObj = JSON.parse(response);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            if (responseObj.Response != undefined) {
                setAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
                setRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
            }
        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
        }
    }



    const random = () => {
        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Set_Up_Direct_Deposit", cardDetails.AccountTypeName+"_"+"see_details", "see_details","", "");
        navigate('/Climb/ClimbMenu');
    }
    const footerContent = () => {
        return (
            < FooterContent highlightText={t("we_got_you_covered")}
                type={t("see_details")}
                subHeaderText={t("covered_by_kudzu_is_a_climb_feature")}
                image={umbrella_kudzu} buttonEnabled={true}
                buttonText={t("see_details")}
                footerBtnAction={random}
            />
        )
    }
    const headerContent = () => {
        return (
            <div className="flex flex-row justify-between mx-10 items-center mt-10">
                <div className='ml-10'>
                    {/* Left Text */}
                    <p className="text-xl ">{selectedItem?.AccountTypeName}</p>
                </div>
        <div onClick={(event) => { 
        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Set_Up_Direct_Deposit", cardDetails.AccountTypeName+"_"+"Print_clicked", "Print_clicked","", "");
            event.target.style.opacity = 0.8; if (employerName != '') { getDdaPdfUrl('print') }; setTimeout(() => { event.target.style.opacity = 1; }, 200); }}
                    className='flex flex-row justify-end items-center mr-10 '>
                    {/* Image */}
                    <img
                        src={print_img}
                        // alt="Image"
                        className="w-5 h-5 mr-2"
                    />

                    {/* Right Text */}
                    <p className="text-18 font-bold">{t("print")}</p>
                </div>
            </div>
        )
    }
    const getDdaPdfUrl = async (type) => {
        if (selectedItem == undefined || selectedItem == null) {
            return
        }
        if (employerName.length === 0) {
            setinfoMessage(t('please_enter_employer_or_payer_name'));
            //  alert("hello");
            return;
        }

        setLoading(true)
        const encodedPayerName = encodeURIComponent(employerName)
        let url = GET_DDA_DOWNLOAD_URL + selectedItem?.CardKey + '&payerName=' + encodedPayerName;
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var response = await GetRequest(url, headers)
        var responseObj = JSON.parse(response);

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            const arrUrls = responseObj.Response.Uri.split('payerName=')
            var encodedName = ''
            if (arrUrls.length > 1){
                encodedName = encodeURIComponent(arrUrls[1])
            }            
            const apiURL = arrUrls[0] + 'payerName=' + encodedName
            if (type == 'download') {
                downloadPdfFile(apiURL, headers);
            } else if (type == 'print') {
                printPdfFile(apiURL, headers)
            }
        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
    }
    const downloadPdfFile = async (url, headers) => {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        const blob = await response.blob();
        const urlObject = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = urlObject;
        link.download = 'directdeposit_form.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);

        // Open the PDF in a new hidden iframe
        const printFrame = document.createElement('iframe');
        printFrame.style.visibility = 'hidden';
        printFrame.src = urlObject;
        document.body.appendChild(printFrame);

        // Wait for the iframe to load the PDF content before triggering print
        printFrame.onload = () => {
            // Trigger the print function on the iframe
            printFrame.contentWindow.print();

            // Clean up the iframe after printing and close the popup window
            setTimeout(() => {
                document.body.removeChild(printFrame);
                setEmployerName('');
                setGenerateBtnClicked(false);
            }, 1000); // Adjust the delay if needed
        };
    };


    // printUtils.js
    const printPdfFile = async (url, headers) => {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        const blob = await response.blob();
        const urlObject = URL.createObjectURL(blob);

        // const link = document.createElement('a');
        // link.href = urlObject;
        // link.download = 'directdeposit_form.pdf';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);

        // Open the PDF in a new hidden iframe
        const printFrame = document.createElement('iframe');
        printFrame.style.visibility = 'hidden';
        printFrame.src = urlObject;
        document.body.appendChild(printFrame);

        // Wait for the iframe to load the PDF content before triggering print
        printFrame.onload = () => {
            // Trigger the print function on the iframe
            printFrame.contentWindow.print();
        };

        // Detect when the print operation is complete using the 'afterprint' event
        window.onafterprint = () => {
            // Clean up the iframe after printing and close the popup window
            setTimeout(() => {
                document.body.removeChild(printFrame);
                setEmployerName('');
                setGenerateBtnClicked(false);
            }, 500); // Adjust the delay if needed
        };
    };
    const accountNumberCopiedClicked = () => {
        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Set_Up_Direct_Deposit", cardDetails.AccountTypeName+"_"+"Account_number_copied", "Account_number_copied","", "");
    copyToClipboard(accountNumber, setAccountCopied(true))
        setAccountCopied(true);
        setTimeout(() => {
            setAccountCopied(false);
        }, 2000);

    }
    const routingNumberCopiedClicked = () => {
        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Set_Up_Direct_Deposit", cardDetails.AccountTypeName+"_"+"Routing_number_copied", "Routing_number_copied","", "");
        copyToClipboard(routingNumber, setRoutingCopied(true))
        setRoutingCopied(true);
        setTimeout(() => {
            setRoutingCopied(false);
        }, 2000);
    }
    const onclickDepositCash = () => {
        navigate('/AccountServices/Spending/DemandDepositAccount/AddCash')
    }
    const onclickDepositCheck = () => {
        alert("we will update soon")
    }


    return (
        <HomeLayout>
            <HomeHeader heading="" isHeaderCaption={false} selectedIndex={1} />
            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
   animate__animated animate__fadeInRight '>
                {/* <DynamicCard
            isHome={true}
            isActive={true}
            CardSectionRadius={false}
            header={false}
            footer={false}
            backgroundColor={'#FEFAEE'}
            cardWidth={width.homeCard}
            HeaderContent={headerContent()}
            footerContent={footerContent()}
            cardHeight={!generateBtnClicked ? '1200px' : '1370px'}
            HeaderRadius={false}> */}
                <DynamicCard
                    header={false}
                    footer={false}
                    headerContent={headerContent()}
                    footerContent={footerContent()}
                    CardRadius={true}
                    isWhite={true}
                    HeaderRadius={true}
                    cardWidth={width.homeCard}>

                    <div>
                        <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                     lg:text-[26px] md:text-[22px] 
                     sm:text-[20px] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4 flex justify-between items-center">
                            {selectedItem?.AccountTypeName}
                            <div tabIndex={0}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        setGenerateBtnClicked(true)
                                    }
                                }} onClick={() => { {}setGenerateBtnClicked(true);logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Set_Up_Direct_Deposit", cardDetails.AccountTypeName+"_"+"Print_clicked", "Print_clicked","", "");}}
                                className='lg:text-[18px] md:text-[17px] sm:text-[16px] text-[16px] font-[700] text-[#2965FB] leading-[18px] flex w-auto items-center cursor-pointer'>
                                <img src={print} className='mr-2' />
                                {t("Print")}
                            </div>

                            {/* <div className='lg:text-[18px] md:text-[17px] sm:text-[16px] text-[16px] font-[700] 
                            text-[#2965FB] leading-[18px] flex w-auto items-center cursor-pointer'>
                                <img src={print} className='mr-2' />
                                {t("Print")}
                            </div> */}
                        </div>
                        <div className='lg:h-[182px] md:h-[162px] h-[150px] border-t-[2.5px] border-[#A3CEBB]
                         flex justify-center flex-col sm:mx-0 mx-4'>
                            <div className='lg:w-[88%] md:w-[95%] sm:w-[95%] w-full'>
                                <div className='text-[#2965FB] lg:text-[26px] md:text-[25px] 
                                sm:text-[22px] text-[26px] font-serif-pro font-[400] md:pb-[1rem] pb-[.5rem] 
                                leading-[36px]'>{t("setup_Direct_deposit")}</div>
                                <div className='text-[#262B3A] lg:text-[20px] md:text-[17px] sm:text-[16px]
                                 text-[16px]
                                font-hanken-grotesk font-[500] lg:leading-[30px] md:leading-[30px] sm:leading-[24px]'>
                                    {t("give_this_information_to_your_employer")} </div>
                            </div>
                        </div>


                        <AccountDetailsHeder
                            handleCopyClick={accountNumberCopiedClicked}
                            handleCopyRoutingNumberClick={routingNumberCopiedClicked}
                            accountCopiedProps={accountCopied}
                            routingCopiedProps={routingCopied}
                            AccountNo={accountNumber}
                            routingNo={routingNumber}
                            isCopyblack={true}
                            ContentSpace={true}
                        />

                        {/* <div className="h-[216px] flex flex-col justify-center ml-[11%]">
                            <p className='font-hanken-grotesk text-[#AEAFB5] uppercasevopacity-[50%] md:text-[13px] sm:text-[9px] font-[600] tracking-[2px] pb-8'>{t("account_info").toLocaleUpperCase()}</p>
                            <div className='flex gap-[7rem]'>
                                <div>
                                    <p className='font-[600] text-[#559C7E] md:text-[16px] sm:text-[11px] leading-[16px] font-hanken-grotesk md:pb-8 sm:pb-4'>{t("accounting_number")}</p>
                                    <p className='font-[600] text-[#262B3A] md:text-[16px]  sm:text-[12px] leading-[16px] font-hanken-grotesk tracking-[2px]'>{accountNumber}
                                        <CopyToClipboard text={accountNumber}>
                                            <span className='ml-2 tracking-[0px]'
                                                onClick={(event) => {
                                                    event.target.style.opacity = 0.8;
                                                    setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                }}>Copy
                                            </span>
                                        </CopyToClipboard>
                                    </p>
                                </div>
                                <div>
                                    <p className='font-[600] text-[#559C7E] md:text-[16px] sm:text-[11px] leading-[16px] font-hanken-grotesk md:pb-8 sm:pb-4'>{t("routing_number")}</p>
                                    <p className='font-[600] text-[#262B3A] md:text-[16px] sm:text-[11px] leading-[16px] font-hanken-grotesk tracking-[2px]'> {routingNumber}
                                        <CopyToClipboard text={routingNumber}>
                                            <span className='ml-2 tracking-[0px]'
                                                onClick={(event) => {
                                                    event.target.style.opacity = 0.8;
                                                    setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                }}>Copy
                                            </span>
                                        </CopyToClipboard>
                                    </p>
                                </div>
                            </div>
                        </div>   */}

                        <div className='sm:mx-0 mx-4 h-[83px] flex justify-center flex-col'>
                            <div className='w-[87%]'>
                                <p className="font-hanken-grotesk lg:text-[18px] md:text-[17px] 
                                sm:text-[16px] text-[16px] font-[500] ">
                                    If your employer or payer doesn’t have their own direct deposit
                                    form, use the link below to generate your own.</p>
                            </div>


                            {/* this screen is fixed */}






                        </div>

                        {generateBtnClicked && <div class="border-opacity-[50%] my-8 flex flex-col justify-center lg:ml-[11%] md:ml-[10%] sm:ml-[8%] sm:mr-0 mx-4">
                            <div class="sm:flex gap-[7rem]">
                                <div class="sm:w-auto w-full sm:h-0 h-[65px] sm:block flex justify-between flex-col sm:mb-0 mb-12">
                                    <p class="font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-5  opacity-[50%]">
                                        {t('account_name')}
                                    </p>
                                    <p class="font-[600] text-[#262B3A] md:text-[16px] sm:text-[13px] text-[12px]  leading-[16px] tracking-[2px] sm:flex">
                                        {selectedItem?.AccountTypeName}..{selectedItem?.CardDisplayNumber.slice(selectedItem?.CardDisplayNumber.length - 4)}
                                    </p>
                                </div>
                                <div class="sm:w-auto w-fullsm:h-0 h-[65px] sm:block flex justify-between flex-col">
                                    <p class="font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-5  opacity-[50%]">
                                        {t('customer_name')}
                                    </p>
                                    <p class="font-[600] text-[#262B3A] md:text-[16px] sm:text-[13px] text-[12px]  leading-[16px] tracking-[2px] sm:flex">
                                        {userProfile.FirstName + " " + userProfile.LastName}
                                    </p>
                                </div>
                            </div>
                        </div>}

                        {!generateBtnClicked && <div onClick={() => { {setGenerateBtnClicked(true); logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Set_Up_Direct_Deposit", cardDetails.AccountTypeName+"_"+"Generate_Direct_Deposit_form_clicked", "Generate_Direct_Deposit_form_clicked","", "");} }} className='sm:mx-0 mx-4  sm:w-[100%] py-[3rem] flex justify-center'>
                            <button className='sm:w-[80%] w-full text-[#2965FB] h-[60px] border-[3px] border-[#2965FB] 
                            flex justify-center items-center font-[700] font-hanken-grotesk lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px]'>
                                {t("generate_direct_deposit_form")}
                            </button>
                        </div>}

                        {generateBtnClicked &&
                            <div className='flex flex-col justify-center items-center sm:w-[100%] my-8 sm:mx-0 mx-4 '>
                                <div className='sm:w-[80%] w-full  h-[60px] mt-6 mb-5 flex justify-center items-center'>

                                    <CustomTextInput
                                        autoFocus={true}
                                        style={{ width: '100%' }} name={t("employer_or_payer_name").toLocaleUpperCase()} onChange={(event) => {
                                            setinfoMessage('')
                                            setEmployerName(event.target.value)
                                        }} 
                                        onBlur={(e) => { 
                                            if(employerName ==='') setinfoMessage(t('please_enter_employer_or_payer_name')) 
                                            else setinfoMessage('');
                                        
                                        } 
                                    }
                                        value={employerName} />
                                </div>
                                {infoMessage &&
                                    <div className='mt-10 sm:w-[80%] w-full flex items-start'>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                }
                                <div onClick={() => { if (employerName != '') { getDdaPdfUrl('download') } else { setinfoMessage(t('please_enter_employer_or_payer_name')) } }} className='sm:w-[80%] w-full h-[60px] mt-10 flex justify-center items-center bg-[#2965FB]'>
                                    <button className="text-8 font-medium py-2 px-5 text-[#ffffff]" onClick={()=>logFirebaseEvents("Set_Up_Direct_Deposit", "Generate_form_clicked", "Generate_form_clicked","", "") }>
                                        {t("generate_form")}
                                    </button>
                                </div>
                            </div>
                        }

                        {/* <div className="h-[244px] flex flex-col justify-center border-t-[3px] border-[#D1E7DD]">
                            <p className='font-hanken-grotesk text-[#262B3A] uppercase uppercasevopacity-[50%] md:text-[13px] sm:text-[9px] font-[600] tracking-[2px ] pb-8'>
                                More services</p>
                            <div className='flex w-full'>
                                <div className='w-[50%]'>
                                    <div className={moreService}>
                                        <p className={moreserviceText} onClick={onclickDepositCash}>{t("deposit_cash")}</p>
                                        <p><img src={RightArrow} className="w-[7px] mr-[10px]" /></p>
                                    </div>
                                </div>
                                <div className='w-[50%] flex justify-between items-center'>
                                    <div className={moreService}>
                                        <p className={moreserviceText} onClick={onclickDepositCheck}>{t("deposit_check")}</p>
                                        <p><img src={RightArrow} className="w-[7px] mr-[10px]" /></p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* /////more service */}
                        <div className="sm:mx-0 mx-4 border-t-[2.5px] border-[#D1E7DD] flex flex-col justify-center">
                            <MoreServices actionsArray={moreServicesArray} />
                        </div>

                    </div>
                    {loading && <Loader />}
                </DynamicCard>
                {footerContent()}
            </div>
        </HomeLayout>

    )
}

export default ManageDirectDeposit;
