import React, { useEffect, useState, useRef } from 'react';
import Loader from '../../CustomComponents/Loader';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import DynamicCard from '../../CustomComponents/DynamicCard';
import { width } from '../../CustomComponents/DynamicCard/DynamicCardStyles';
import { useDispatch, useSelector, connect } from 'react-redux';
import { SAVING, fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import { STATUS_SUCCESS_CODE, GET_USED_AMOUNT_DETAILS, TRANSACTIONS, SAVINGS, CHECKING, SPENDING, GET_PAY_PERKS_API, PAYPERKS_URL } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import { withOidcSecure, useOidcUser, useOidcAccessToken } from '@axa-fr/react-oidc'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const appConfigData = await fetchConfigarationData();


const PayPerks = () => {


    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const cards = useSelector(state => JSON.parse(state.userCards));
    const [payperksURL, setPayperksURL] =useState();
    const [payperksError, setPayperksError] =useState();
    // const payperksURL = useSelector(state => state.payperksURL);   
    // const payperksURL = PAYPERKS_URL;
    // const payperksError = useSelector(state => state.payperksError);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const dispatch = useDispatch();


    const getHeaderText = () => {
        return t('welcomePayperks');

    };

    ///For Pay perks 
  const getPayperksDetails = async () => {
    if (cards == undefined || cards == null) {
      return
    }
    const deviceId = await retrieveData('updatedDeviceId');
    let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

    let url = GET_PAY_PERKS_API(cards[0].CardKey) + '&version=' + appConfigData.APP_VERSION + '&isDarkMode=true';
    const response = await GetRequest(url, headers)
    let responseObj = JSON.parse(response);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      const payperksURL = { payperksURL: responseObj.Response.URL }
      setPayperksURL(responseObj.Response.URL)
      dispatch({ type: 'PAYPERKS_DASHBOARD_URL', payload: payperksURL });
    } else {
      // PAYPERKS_ERROR
      const arrMessages = responseObj.Messages;
      const message = arrMessages[0];
      const displayText = message.DisplayText;

      const payperksError = { payperksError: displayText }
      setPayperksError(displayText)
      dispatch({ type: 'PAYPERKS_ERROR', payload: payperksError });
    }
  }
    useEffect(()=>{
        getPayperksDetails();

    },[])
    useEffect(()=>{
        console.log("payperksURL",  payperksURL);
    },[payperksURL])
    return (
        <>
            <HomeLayout>
                <HomeHeader
                    heading={getHeaderText()}
                    isHeaderCaption={true} />

                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        // HeaderContent={false}
                        isIFrame={true}
                        footer={true}>
                        <div className='w-full  sm:rounded-tr-[4rem] sm:rounded-bl-[4rem]'>
                            {(payperksURL != null && payperksURL != '') ?
                                <iframe src={payperksURL}
                                    className='sm:rounded-tr-[4rem] sm:rounded-bl-[4rem]' title="Pay Perks"
                                    width="100%" height="800px" style={{ overflow: 'hidden', padding: '0px' }}></iframe>
                                // <iframe src={payperksURL}
                                //     className='' title="Pay Perks"
                                //     width="100%" height="800px" style={{ overflow: 'hidden', padding: '30px' }}></iframe>
                                :
                                <p className='py-20 md:w-full sm:w-[200px] w-full font-hanken-grotesk font-[500]
                                md:text-[16px] sm:text-[12px] text-[14px]
                                cursor-pointer pointer-events-none flex justify-center'>
                                    {(payperksError != '' && payperksError != null) ? payperksError : t('something_went_wrong')}
                                </p>
                            }
                        </div>
                    </DynamicCard>
                </div>
            </HomeLayout>
            {isLoading && <Loader />}
        </>
    )
}

export default withOidcSecure(PayPerks);