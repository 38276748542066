


import React, { useState, useEffect } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../CustomComponents/Loader';
import { UPDATE_CARDNAME, STATUS_SUCCESS_CODE, CAN_CREATE_SAVINGS_ACC, STUDENT_SIGNUP_EMAIL_ACCOUNT, STUDENT_VERIFY_ACESSCODE, STUDENT_RESENDVERIFY_ACESSCODE, GET_CARDHOLDER_CARDS } from '../../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../../../network/NetworkUtils';
import { addLineBreaks, displayErrorMessage, displaySuccessMessage, isEmailValid, MoreServices } from '../../../../../utils';
import { checkEmailExtension, fetchConfigarationData, retrieveData } from '../../../../../helpers/utils/Utils';
import LineBreaks from '../../../../../utils';
import HomeLayout from '../../../../Layout/HomeLayout';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import Check from '../../../../../assets/images/acceptedcheck.png'
import beneficiarysaving from '../../../../../assets/images/beneficiarysaving.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomInput from '../../../../CustomComponents/FormInputs';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAlertModel from '../../../../CustomComponents/CustomAlertModel';


const appConfigData = await fetchConfigarationData();
const AccessCodeScreenForSettings = ({ sessionKeyUpdate,openStudentEmail}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook to access translation functions
  const cards = useSelector(state => JSON.parse(state.userCards));
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [isLoading, setIsLoading] = useState(false);
  const cardKey = cards !== null ? cards[0].CardKey : 0;

  // console.log("SESSIOSNSNUFIIF-------------------------"+sessionId)

  const selectedItem = cards?.[0]
  const [newName, setNewName] = useState((selectedItem != undefined && selectedItem != null) ? selectedItem.NickName : "");

  const [loading, setLoading] = useState(false)
  const [infoMessage, setinfoMessage] = useState('');
  const [responseCode, setResponseCode] = useState('');
  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [modelImage, setModelImage] = useState('')
  const [savingsTheme, setSavingsTheme] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [isSubFooter, setIsSubFooter] = useState(false);
  const [isSubComponent, setIsSubComponent] = useState(false);
  const [showErrorImg, setshowErrorImg] = useState(false);
  const [textMessage, setTextMessage] = useState(false);
  const [btn1Focus, setBtn1Focus] = useState(false);
  const [btn2Focus, setBtn2Focus] = useState(false);

  //email state value
  
  const [validAccessCode, setValidAccessCode] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeError, setAccessCodeError] = useState("");
  const [isVerifiedCode, setIsVerifiedCode] = useState(false);
  const [codeAcceptedBtnsEnable, setCodeAcceptedBtnsEnable] = useState(false);
  const [tryAgainBtnEnabled, setTryAgainBtnEnabled] = useState(true);
  const [initialContinue, setInitialContinue] = useState(true);
  const [message, setMessage] = useState('');
  const [resendCount, setResendCount] = useState(1);
  const [readonlyVal, setReadonlyVal] = useState(false);
  const [BtnEnabledForError ,setBtnEnabledForError]= useState(false);
  const [buttonEnable ,setButtonEnable]= useState(true);
  const [retryCount,setRetryCount] = useState(0);

  //let retryCount=0;
  
  const showCustomAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
    // setShowAlertModal(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
  } 
  
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, textMessage, isSubComponent, btn1Focus, btn2Focus) => {
    setshowErrorImg(showErrorImg)
    setisBorderNone(isBorderNone)
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setFooterMsg(footerMsg)
    setTextMessage(textMessage)
    setIsSubComponent(isSubComponent)
    setBtn1Focus(btn1Focus)
    setBtn2Focus(btn2Focus)
  }
 
  const validationSchema = yup.object().shape({
    accessCode: yup
    // .string()
    // .required(t('accessCode_is_required'))
  });
 
  const { register, formState: { errors }, setError, setValue,reset, clearErrors, getValues } = useForm({
    // mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  
    const handleAccessCode = (e) => { 
      setMessage('');
      clearErrors();
      const val = e.target.value;
 
      if (val.length >= 6) { 
          setValidAccessCode(true);  
          setButtonEnable(false);
          setAccessCode(val);
      } else { 
          setValidAccessCode(false); 
          setButtonEnable(true);
      } 
  }
  
  
  const handleKeyPressRestrict = (e) => {
      const char = String.fromCharCode(e.which);
      if (!/[a-zA-Z0-9]/.test(char)) {
          e.preventDefault();
      }
  }
  
  const closeModel = () => {
      setShowModel(false);
  }
  

  //resend

  const resendAccessCode = async () => {
  debugger; 
  
  reset(); 
  setAccessCode('');
  setMessage('');
  setValidAccessCode(false);
  setValue('accessCode', '');  
  
    try {
      debugger;
      const deviceId = await retrieveData('updatedDeviceId');
      const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
      setIsLoading(true) 
      const reqObj = {
        applicationId: appConfigData.CLIENT_ID,
        code: accessCode.toUpperCase() ,
        sessionId:sessionKeyUpdate,
        cardKey:cardKey
      }
      debugger;
      var response = await PostRequest(STUDENT_RESENDVERIFY_ACESSCODE, reqObj, headers);
      var responseObj = JSON.parse(response)
      setIsLoading(false)
      if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) { 
        setBtnEnabledForError(false)
        setInitialContinue(false)
        setTryAgainBtnEnabled(true)
        setIsVerifiedCode(true)
      } else {
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        setMessage(displayText);
        setTryAgainBtnEnabled(false);
        setBtnEnabledForError(true);
      }
    } catch (error) {
      setMessage(error.message); 
    }
 
    }

  

  const verifyAccessCode = async () => {
    try {
      debugger;
      const deviceId = await retrieveData('updatedDeviceId');
      const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
      setIsLoading(true) 
      const reqObj = {
        applicationId: appConfigData.CLIENT_ID,
        code: accessCode.toUpperCase() ,
        sessionId:sessionKeyUpdate,
        cardKey:cardKey
      }
      debugger;
      var response = await PostRequest(STUDENT_VERIFY_ACESSCODE, reqObj, headers);
      var responseObj = JSON.parse(response)
      setIsLoading(false)
      if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) { 
        debugger;
        callPostLoginAPI()
        setReadonlyVal(true);
        setCodeAcceptedBtnsEnable(true);
        // setBtnEnabledForError(false)
        setInitialContinue(false)
        setTryAgainBtnEnabled(false)
        setIsVerifiedCode(true)
        dispatch({ type: 'STUDENT_EMAIL_VALUE', payload: { studentEmailAddress: "" } });
      } else  {
        let retriedCount = retryCount;
        retriedCount = retriedCount + 1;
        setRetryCount(retriedCount);
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;

        if (retryCount > 2) {
          showAlertModal(true, t("oh_no"), t("nolongerValid"),t('customerContact'),true, false, t('home'), t("cancel"), true,require('../../../../../assets/images/spending-alert-error.png'), '', false, true, '', false, false, true);
          setMessage("");
          return;
        } else{
          setMessage(displayText);
        }
  
      }
      
      // else{ 
      // showAlertModal(true, t("oh_no"), t("nolongerValid"),
      //       t('customerContact'),
      //       true, false, t('home'), t("cancel"), true,
      //       require('../../../../../assets/images/spending-alert-error.png'), '', false, true, '', false, false, true); 
      // } 
    } catch (error) {
      // setMessage(error.message); 
     
    }
  } 


  const openAccessCodeScreen =() =>{
    debugger;
    reset(); 
    setValue('accessCode', '');
    setMessage('');
    setValidAccessCode(false);
    setBtnEnabledForError(false); 
    setTryAgainBtnEnabled(true); 
  }

  const openAccessSuccessModal =() =>{
    showAlertModal(true, t("success_exclaim"), t("accessCodeapplied"),
    "",
    true, false, t('home'), t("cancel"), true,
    require('../../../../../assets/images/illo-complete-success.png'), '', false, true, '', false, false, true);
  }

  const onAcptBtnClick = () => {
    navigate('/home');
  }

  useEffect(() => {
    // error popup
    // showAlertModal(true, t("oh_no"), t("nolongerValid"),
    //   t('customerContact'),
    //   true, false, t('home'), t("cancel"), true,
    //   require('../../../../../assets/images/spending-alert-error.png'), '', false, true, '', false, false, true);
 
  }, [])

  async function callPostLoginAPI() {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    const response = await GetRequest(GET_CARDHOLDER_CARDS, headers)
    var responseObj = JSON.parse(response)
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        if (responseObj.Response != undefined && responseObj.Response != null && responseObj.Response.length > 0) {
            const userCardsPayload = { userCards: JSON.stringify(responseObj.Response) };
            dispatch({ type: 'UPDATE_USER_CARDS', payload: userCardsPayload });
        }

    } 
}
const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
     if(!buttonEnable){
      if (tryAgainBtnEnabled) {
        verifyAccessCode();
      } 
    }
    else{
    }
  }
}


// useEffect(() => {
//   const inputField = document.querySelector('input[name="accessCode"]');
//   if (inputField) {
//     inputField.addEventListener('keydown', handleKeyPress);
//   }

//   return () => {
//     if (inputField) {
//       inputField.removeEventListener('keydown', handleKeyPress);
//     }
//   };
// }, [tryAgainBtnEnabled, accessCode]);

  return (
    <>
      <HomeLayout>
        <HomeHeader heading={LineBreaks(t("settingsEmailTittle"))} isHeaderCaption={true} />
        <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
          <DynamicCard
            header={false}
            footer={true}
            CardRadius={true}
            HeaderRadius={true}
            isWhite={true}
            cardWidth={width.homeCard}
            footerBackgroundColor={codeAcceptedBtnsEnable ? '#30BC89' : ""}
            footerContent={codeAcceptedBtnsEnable ?

              <>
                <div className="flex justify-between flex-nowrap h-full items-center w-[85%] m-auto">
                  <div className="flex items-center">
                    <span className='mr-2 ml-2'><img src={Check} size={20} alt="Check" className='text-white' /></span>
                    <p className="font-hanken-grotesk sm:font-bold font-medium lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white cursor-pointer flex items-center"
                    >{t('CodeAccepted')}</p>
                  </div>
                  <button
                    type="button"
                    className="font-hanken-grotesk sm:font-bold font-medium lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white cursor-pointer flex items-center"
                    onClick={openAccessSuccessModal}
                  >
                    {t('continue')}
                  </button>
                </div>
              </>
              :
              null
            }>

            <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                    lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px]
                     leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
              {t('connect_school')}
            </div>

            <div className='border-t-[2.5px] border-[#A3CEBB]
                     flex justify-center flex-col sm:mx-0 mx-4'></div>

            <>
              <div className='lg:w-[90%] md:w-[94%] w-full font-serif-pro lg:text-[27px] md:text-[24px]
                sm:text-[20px] text-[24px] md:leading-[38px] sm:leading-[24px] leading-[31px] 
                    font-normal text-[#2965FB] mt-5'>
                <p className='sm:mx-0 mx-4 '>{t("great_exclamation")}
                  <span className='text-black'>
                    {(t("enterAccesscode"))}</span></p>
              </div>

              <span className="font-hanken-grotesk text-[#262B3A]
               lg:text-[16px] md:text-[16px] py-4 inline-block sm:text-[14px] text-[16px]
                  font-medium  leading-6 sm:mx-0  mx-4 ">
                {addLineBreaks(t("checkMail"))}</span>
            </>
              <div className='w-full flex justify-center lg:py-20 md:py-14 sm:pt-10 sm:pb-0 py-4'>
                <div className='sm:w-[62%] w-full sm:mx-0 mx-4'>
                  <div className='lg:w-[384px] md:w-[340px] sm:w-[224px]'>
                    <div className='md:w-[282px] sm:w-[198px] flex flex-col gap-4 mt-4'>
                      <CustomInput
                        name="accessCode"
                        label={t("AccessCode")}
                        register={register}
                        error={errors.accessCode}
                        type="text" 
                        isErrorShow={true}
                        readOnly={readonlyVal}
                        autoFocus={true}
                        maxLength={20}
                        isValid={validAccessCode}
                        onChange={handleAccessCode}
                        onKeyPress={handleKeyPressRestrict}
                        // onKeyDown={ (event) =>{
                        //   if (event.key === 'Enter') {
                        //   event.preventDefault();
                        //     verifyAccessCode();
                        // }}}
                        onKeyDownFun={handleKeyPress}
                      />

                      {message &&
                        <div className='mb-2'>
                          {displayErrorMessage(message)}
                        </div>
                      }
                    </div> 
                  </div>
                </div>
              </div>
            
            {tryAgainBtnEnabled && (
              
              <div className="w-full flex flex-row justify-between lg:mt-32 md:mt-20 mt-20">
              <div>
                <button
                  type="button"
                  className="sm:block hidden font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] 
                  md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]"
                >
                  {t('didnotreceivemail')}
                  
                   <button
                    className="text-[#2965FB]"
                    onClick={resendAccessCode}
                    >{t('tryagain')}</button>
                </button>
 
              </div>

              <div className='flex items-baseline md:gap-20 sm:gap-12 gap-4 sm:px-0 px-4'>
                <button className='lg:text-[18px] md:text-[17px] 
                                sm:text-[15px] text-[16px] font-[700]
                                 font-hanken-grotesk
                                 text-[#2965FB]' onClick={openStudentEmail}   
                                  >
                  {t('Back')}</button>

                <button  className={`font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]  flex items-center
                  ${validAccessCode ? 'text-[#2965FB] cursor-pointer' : 'text-[#94C3E8] cursor-none'}`} 
                   onClick={verifyAccessCode}
                    disabled={buttonEnable}
                  > {t('continue')} </button>
              </div> 
            </div>
            )}

            {/* ///for error buttons */}

            {BtnEnabledForError && (
              

            <div className='flex items-end justify-end md:gap-20 sm:gap-12 gap-4 sm:px-0 px-4'>
                <button className='lg:text-[18px] md:text-[17px] 
                                sm:text-[15px] text-[16px] font-[700]
                                 font-hanken-grotesk
                                 text-[#2965FB]' onClick={openAccessCodeScreen}   
                                 >
                  {t('Back')}</button>

                <button  className={`font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]  flex items-center
                  ${validAccessCode ? 'text-[#2965FB] cursor-pointer' : 'text-[#94C3E8] cursor-none'}`} 
                  onClick={verifyAccessCode}
                  > {t('continue')} </button>
              </div> 

                )}


            {loading && <Loader />}
          </DynamicCard>
        </div>

        {isLoading && <Loader />}

      </HomeLayout>
      {showModel && (
        <CustomAlertModel
          isOpen={showModel}
          onClose={closeModel}
          Fullheight={true}
          image={modelImage}
          highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
            <span className={`${HeaderMsgStyles} text-black`}> {headerMsg}</span></p>}
          subHeaderMsg={
            <>
              {subHeaderMsg && (<p className={SubHeaderMsgStyles}><p>{t("pl_contact_customer")} </p>
                <p className='sm:w-[465px]'><a href='/ContactUs'><span className='text-[#2965FB] font-bold'>{t("support_mail")}</span></a><span>{t("further_assistance")}</span></p>
              </p>)}
              {subHeaderMsg == '' && (<p className={SubHeaderMsgStyles}>{t("accessAble")}</p>)}
            </>
          }
          isSubComponent={isSubComponent}
          textMessage={textMessage}
          btn1Exists={btn1Exists}
          btn2Exists={btn2Exists}
          btn1Text={btn1Text} btn2Text={btn2Text}
          btn1TypeAcpt={btn1TypeAcpt}
          verificationFailure={() => { }}
          footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
          showErrorImg={showErrorImg}
          isBorderNone={isBorderNone}
          btn1Focus={btn1Focus}
          btn2Focus={btn2Focus}
          onAcptBtnClick={onAcptBtnClick}
        />
      )}
    </>

  )
}

export default AccessCodeScreenForSettings;
