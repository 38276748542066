import React, { useState, useEffect, useRef,useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomInput from '../../../CustomComponents/FormInputs';
import Arrow from '../../../../assets/images/arrow.png';
import Modal from '../../../CustomComponents/Modal';
import addLineBreaks, { displayErrorMessage } from '../../../../utils';
import Card from '../../../CustomComponents/Card';
import VerifyEmailCode from '../EmailVerification';
import {  GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import { EMAIL_OTP_GENERATION, STATUS_SUCCESS_CODE, STUDENT_SIGNUP_UNIVERSITY_SELECTION } from '../../../../network/URLConstants';
import {  useDispatch, useSelector } from 'react-redux';
import Loader from "../../../CustomComponents/Loader";
import { isEmailValid, retrieveData } from '../../../../helpers/utils/Utils';
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import { useNavigate } from 'react-router';
import CustomDropdown from '../../../CustomComponents/CustomDropDown'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from 'react-select/async';
 import debounce from 'lodash/debounce'; 
import blueDropDown from "../../../../assets/images/blue_dropdown_arrow.png";
import moment from 'moment';
import { AsyncPaginate } from 'react-select-async-paginate';

const appConfigData = await fetchConfigarationData();
export const StudentUniversity = ({ userEmail,backtoEmail,openCreatPassword}) => {
  const validationSchema = yup.object().shape({
    universityName: yup.string().required('University name is required'),
    graduationYear: yup.date().required('Graduation year is required')
  });
  const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched'
  });
   const [formValues, setFormValues] = useState({ 
     dob: '' 
   });

   const [isLoading, setIsLoading] = useState(false);
   const [universityData, setUniversityData] = useState([]); 
   const [selected, setSelected] = useState(null);
   const [DateValue,setDateValue]= useState('');
   const [DateValueServer,setDateValueServer]= useState('');
   const [buttonEnable,setButtonEnable] = useState(true)
   const [universityName,setUniversityName] = useState();
   const [selectedDate,setSelectedDate] = useState(null);
  const [validData, setvalidData] = useState(false);
  const [univeristyInput,setUniveristyInput] = useState(false);
  const [universityValue,setUniversityValue] = useState('');
  const today = new Date();
  const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);


   const dispatch = useDispatch();
   const { t } = useTranslation();
   const currentYear = new Date().getFullYear();
   const [page, setPage] = useState(1);
   const [hasMore, setHasMore] = useState(true);
    const accessTokenToApi = useSelector(state => state.accessToken);
   const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]";
 
 
   const handleFormSubmit = (e) => {
     e.preventDefault();  
   }

   const handleDateChange = (date) => { 
     setSelectedDate(date);
    const dateToServer = moment(date).format('DD-MM-YYYY');
    setDateValueServer(dateToServer); 
    const formattedDateWithoutDayOfWeek = moment(date).format("MM/yyyy"); 
    dispatch({ type: 'GRADUATION_DATE', payload:  { graduationDate: formattedDateWithoutDayOfWeek.split("/")[1] + "-" + formattedDateWithoutDayOfWeek.split("/")[0] + "-01" }  });
    setDateValue(formattedDateWithoutDayOfWeek); 
   }


  const handleSelectChange = (value) => {
    setSelected(value);
    dispatch({ type: 'STUDENT_UNIVERSITY_CODE', payload: { studentUniversityCode: value }  });
    if (value === 0) {
      setUniveristyInput(true);
      setUniversityName('')
    } else {
      setUniveristyInput(false);
    } 
  }

  const handleInputChange = (e) =>{
     const universityNameVal = e.target.value
     if(universityNameVal){
      dispatch({ type: 'STUDENT_UNIVERSITY_NAME', payload: { studentUniversityName: universityNameVal }  });
      setUniversityName(universityNameVal)
      clearErrors('universityName');
     } 
  } 
 
  const customStyles = {
     
    option: (provided, state) => ({
        ...provided, 
        color:  'blue',
        padding: '18px', 
        color: state.isFocused && !state.isSelected ? 'white' : provided.color,
        color: state.isSelected && !state.isFocused ? 'black' : provided.color,
        backgroundColor:state.isFocused ? '#94C3E8' : provided.backgroundColor,
        backgroundColor: state.isSelected ? '#94C3E8' : state.isFocused ? '#94C3E8' : null, 
     }) 
    
    }
    useEffect(()=>{
      if(selectedDate && selected ){
        setvalidData(true);
        setButtonEnable(false);
      }
      else if(univeristyInput){
       if(universityName.length > 4 && selectedDate){
         setvalidData(true);
         setButtonEnable(false);
       }
        else{
         setvalidData(false);
         setButtonEnable(true);
        }
      }
      else{
       setvalidData(false);
       setButtonEnable(true);
 
      }
   },[selectedDate,selected,universityName])



   const callApi = async (inputValue, page = 1) => {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    const url = STUDENT_SIGNUP_UNIVERSITY_SELECTION(inputValue, 20, page); // Adjust the page size as needed
    const response = await GetRequest(url, headers);
    const responseObj = JSON.parse(response);
    if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
      const universities = responseObj.Response.Universities.map(uni => ({
        label: uni.Name,
        value: uni.ID
      }));
      if (universities.length === 0) {
        universities.push({ label: "Other", value: 0 });
      }
      setUniversityData(universities)
      return {
        options: universities,
        hasMore: responseObj.Response.Universities.length > 0, // Change based on the page size
        additional: {
          page: page + 1,
        },
      };
    }
  };
  
    const loadOptions = async (inputValue, _, additional) => {
      const finalVal = inputValue === "" ? "a":inputValue
      const page = additional.page || 1;
      return await callApi(finalVal, page);
    };
  
   
  
  console.log(universityData,"all data form respoibnse")
  
   

  return ( 
    <>
      <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0">
        <Card
          heading=
          {
            <>
              <span className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)]
             lg:text-[31px] 
            md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] 
            md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('student_selction_heading1')}</span>
            <span className='font-serif-pro sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)] lg:text-[31px]
             md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('university_heading1')}</span>
            </>}
          cardHeight={'auto'}
          subheading={addLineBreaks(t('university_heading2'), SubTittleStylesModal)}
          // Cardcaption={addLineBreaks(t('university_details'),)} 
          header={true} footer={true} CardRadius={true} >

            <p className='sm:block hidden text-[#262B3A] font-serif-pro lg:leading-[36px] md:leading-[24px] 
            leading-[31px] font-[400] lg:text-[32px] md:text-[29px] sm:text-[22px] text-[25px]'>
            <span className='text-[var(--text-clr-secondary2)]'>
              {t('okay')}</span>
            <span> {t('university_details1')}</span>
            <span> {addLineBreaks(t('university_details2'))}</span>
          </p>
            <p className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] lg:text-[20px] lg:w-[451px] md:text-[20px] sm:text-[12px] text-[20px] font-hanken-grotesk font-medium sm:mx-0 mx-4 text-[var(--text-clr-secondary3)]">
            {''}</p>
          <div className="flex flex-col  w-full h-246 mt-20">
            <div className="flex flex-col"> 
            <form className='md:mt-16 md:mb-16 sm:mb-10 mb-12' onSubmit={handleFormSubmit}>
            <div className='md:mt-16 mt-12 mb-12'>
            <p className='font-hanken-grotesk text-[#262B3A] 
                  uppercase lg:text-[13px] md:text-[9px] 
                  sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-6 pb-5
                  opacity-[50%] '>{t('school_name')}</p>
                  <div className=' flex w-full items-baseline'>  
                  {/* <AsyncSelect
                    cacheOptions={true}
                    loadOptions={debouncedCallApi}
                    defaultOptions={universityData}
                    placeholder='Select'
                    onInputChange={(data) => {
                    console.log(data);
                    }}
                    onMenuScrollToBottom={handleMenuScrollToBottom}
                    onChange={(data) => {
                      console.log("slected"+data.value)
                      handleSelectChange(data.value);
                      setSelected(data.value)
                    }} 
                    styles={customStyles}
                  />  */}
                  <AsyncPaginate
                      placeholder='Select'
                      // cacheOptions={true}
                      debounceTimeout={300}
                      // value={universityName}
                      loadOptions={loadOptions}
                      onChange={(data) => {
                        console.log("slected" + data.value)
                        handleSelectChange(data.value);
                        setSelected(data.value)
                      }}
                      // defaultOptions={universityData}
                      additional={{ page: 1 }}
                      isMulti={false}
                      styles={customStyles}
                    />
                </div>
             {univeristyInput && 
               <div className='md:mt-16 mt-12 mb-12'>
                {/* <p className='font-hanken-grotesk text-[#262B3A] 
                  uppercase lg:text-[13px] md:text-[9px] 
                  sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-6 pb-5
                  opacity-[50%] '>{t('selectUniversityName')}</p> */}
                      <CustomInput
                        name="universityName"
                        label="School Name"
                        register={register}
                        error={errors.universityName}
                        type="text"
                        onChange={handleInputChange}
                      />
                      {/* <div className='mb-4'>
                      {errors.universityName && (
                        displayErrorMessage(errors.universityName.message)
                      )}
                      </div> */}
               </div>   
                 }
            </div> 
            <div className='md:mt-16 mt-12 mb-12'>
            <label className="font-hanken-grotesk text-[#262B3A]  uppercase lg:text-[13px] md:text-[9px]  sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-6 pb-5 opacity-[50%] inline-block mb-4" htmlFor={'ExpectedGraduationyear'}>{t('ExpectedGraduationyear')}</label>
                 <DatePicker
                      value={DateValue}
                      onChange={handleDateChange} 
                      dateFormat="dd/MM/yyyy"
                      name="grduationYear" // Pass the `name` prop directly
                      label={t('ExpectedGraduationyear')}
                      className='font-hanken-grotesk 
                      text-secondary-2 px-4 py-2
                        bg-text-btn placeholder-kudzu-blue 
                        text-sm font-medium focus:outline-none w-full h-[60px]'
                      showMonthDropdown
                      showYearDropdown
                      selected={selectedDate}
                      placeholderText='MM/YYYY'
                      autoComplete='off'
                      minDate={minDate}
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                    />
              </div>
              <div className="flex justify-end items-center mt-20">
                <button
                  type="button"
                  className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center mr-10"
                  onClick={backtoEmail}
                  onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); } }}
                // Call handleContinue instead of openPhoneOTPModal directly
                >
                  {t('back')} 
                </button>
                <button
                  type="button"
                  disabled={buttonEnable}
                  className={`font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                    ${validData ? 'text-[#2965FB] cursor-pointer' : 'text-[#94C3E8] cursor-none'} flex items-center`}
                  onClick={openCreatPassword}
                  onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); } }}
                // Call handleContinue instead of openPhoneOTPModal directly
                >
                  {t('continue')} 
                </button>
              </div> 
              </form>
            </div>
          </div>
        </Card>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default StudentUniversity;
