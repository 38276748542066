import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalLogo from '../../../assets/images/modal_logo.png';
import Modal from '../Modal';
import { useKeyDown } from '../../../hooks/useKeyDown';
import SecureIcon from '../../../assets/images/Error.png';
import Arrow from '../../../assets/images/arrow.png';


import 'animate.css';
import CustomRadioButton from '../CustomRadioButton';
import logFirebaseEvents from '../../LogFirebaseEvents';

const TwoFactTypeSelctionModal = (props) => {
    const { isOpen, onClose, highlightMsg, HeaderMsg,
        subHeaderMsg, image, btn1Exists, btn2Exists, btn1Text, btn2Text,
        btn1TypeAcpt, footerMsg, onAcptBtnClick, subFooter, onCancelBtnClick, isBorderNone, Fullheight, textMessage, isSubComponent, subComponent, defaultCornerRadius = true, isIframe = false } = props

    const { t } = useTranslation(); // Hook to access translation functions
    const HeadingStyles = "font-serif-pro modal-title sm:text-[18px] md:text-[32px] text-[25px] sm:leading-[24px] md:leading-[38px] leading-[31px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title sm:text-[18px] md:text-[32px] text-[20px] sm:leading-[24px] md:leading-[38px] leading-[31px] font-normal";
    const procArray = [{ displayText: "Text" }, { displayText: "Email" }]
    const [Proc, setProc] = useState('');

    const modalRef = useRef(null);

    useKeyDown(() => {
        if (Proc != '') {
            props?.onClickNext({ proc: Proc }); setProc('')
        }
    }, ["Enter"]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.classList.contains('modal')) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen, onClose]);


    useEffect(() => {
        if (isOpen && modalRef.current) {
            modalRef.current.classList.remove('opacity-0','bottom-[2000px]');
            modalRef.current.classList.add('animate__animated', 'animate__fadeInUpBig', 'sm:top-36', 'top-0');
        } else if (modalRef.current) {
            // modalRef.current.classList.remove('fade-in');
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }


    const handleClose = () => {
        // modalRef.current.classList.remove('opacity-0');
        modalRef.current.classList.remove('animate__fadeInUpBig');
        modalRef.current.classList.add('animate__fadeOutDown');
        setTimeout(() => {
            onClose();
        }, 500); // Adjust the timeout duration as needed
    };
    const setProcFunction = (value) => {
        debugger;
        if(value.displayText === "Text" ){
            logFirebaseEvents("text_and_email_authentication", "Text_Checked", "Text_Checked", "","");
        }
        else if(value.displayText === "Email"){
            logFirebaseEvents("text_and_email_authentication", "Email_Checked", "Email_Checked", "","");
        }
        setProc(value.displayText)
    }
    const modalContainerClasses = ` modal-container bg-card absolute ${defaultCornerRadius ? 'sm:rounded-tl-card-tr sm:rounded-br-card-bl' : 'sm:rounded-tr-[4rem] sm:rounded-bl-[4rem]'}  md:w-[778px] sm:w-[466px] w-[100%] mx-auto shadow-lg z-50 transition-all duration-300 ease-in-out h-[100vh] sm:h-auto opacity-0 bottom-[2000px] `;

    return (
        <div className="fixed inset-0 flex items-center justify-center overflow-y-auto " style={{ zIndex: 300 }}>
            <div className=' absolute top-0 left-0 flex w-full items-center justify-center '>
                <div className="modal-overlay fixed inset-0 bg-[#262B3A] opacity-90 transition-opacity " />
                <div
                    ref={modalRef}
                    className={modalContainerClasses} 
                >
                    <div className="modal-content text-left">
                    {/* <div className="modal-content text-left h-[100%]"> */}
                        <div className={`modal-body  h-[100%] ${isIframe ? 'flex justify-center' : 'flex justify-center items-center'} flex-col sm:mt-[60px] md:mt-[110px]`}>
                            <div className={` ${isIframe ? 'w-[100%]' : 'md:w-[450px] sm:w-[294px] xsm:w-[240px] w-[320px]'} `}>
                                <div className='w-full flex flex-col items-center self-center mt-14 sm:mt-0'>
                                    <span
                                        className='absolute md:top-[60px] sm:top-[44px] top-[30px] right-[30px] sm:right-[50px]'
                                        tabIndex={0}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                onClose();
                                                setProc('')
                                            }
                                        }}
                                        onClick={(event) => {
                                            event.target.style.opacity = 0.8;
                                            onClose(); setProc(''); setTimeout(() => { event.target.style.opacity = 1; },
                                                200);
                                        }}>
                                        <button className="modal-close text-gray-500 hover:text-gray-800 cursor-pointer" >
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                className="h-[1rem] w-[1rem] opacity-[60%]"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor" >
                                                <path strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                                        </button>
                                    </span>
                                    <div className='md:w-[450px] sm:w-[294px] xsm:w-[240px] w-[320px]'>
                                        <p className='font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] sm:block lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none'>{props?.highlightedText ? props?.highlightedText : t('security_check')}</p>
                                        <p className='font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none'>{props?.headerText ? props?.headerText : t('you_ll_need_to_verify_this_action')}</p>
                                    </div>
                                    <div className='md:w-[450px] sm:w-[294px] xsm:w-[240px] w-[320px] flex flex-col justify-start gap-7 mt-5'>
                                        {props?.subHeaderText &&
                                            <div className='w-full flex flex-row'>
                                                <p className="font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none">{props?.subHeaderText}</p>
                                            </div>
                                        }
                                        <div className='w-full flex flex-row'>
                                            <p className="font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none">{t('please_select_the_delivery_method')}</p>
                                        </div>
                                    </div>
                                    <div className='md:w-[450px] sm:w-[294px] xsm:w-[240px] w-[320px] flex flex-col justify-start gap-7 mt-5 mb-12'>
                                        <div className='w-full flex flex-row'>
                                            <CustomRadioButton isRowOnMobileSrn={true} title={''} options={procArray} frequency={Proc} onSelect={setProcFunction} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Footer---- */}
                            <div className={`md:w-[600px] sm:w-[294px] xsm:w-[240px] w-[320px] flex flex-col justify-start gap-5 sm:mt-[20px] md:mt-[40px] sm:mb-[20px] md:mb-[20px]`}>
                                <div className='lg:pt-20 md:pt-18 sm:pt-10 py-16 w-full'>
                                    <div className='flex justify-end w-full'>
                                        <button type="button" className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex items-center" onClick={() => {
                                            if (Proc == '') {
                                                return;
                                            }
                                            props?.onClickNext({ proc: Proc }); setProc('');
                                            logFirebaseEvents("text_and_email_authentication", "Next_Clicked_For_Verify", "Next_Clicked_For_Verify", "","");
                                        }}>
                                            {t('next')}
                                            <img src={Arrow} className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px] h-[14px] ml-1" alt="Arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div >
    );
};

export default TwoFactTypeSelctionModal;