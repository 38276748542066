import React, { useEffect, useRef, useState } from 'react'
import { width, height } from "../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import right_dropdown_arrow from '../../assets/images/right_dropdown_arrow.png'
import CustomTextInput from '../CustomComponents/CustomtextInput';
import Loader from '../CustomComponents/Loader';
import Modal from '../CustomComponents/Modal';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../network/NetworkUtils';
import { useNavigate } from 'react-router-dom';
import { retrieveData } from '../../helpers/utils/Utils';
import CustomAlertModel from '../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc';
import Footer from '../Layout/Footer';
import HomeLayout from '../Layout/HomeLayout';
import HomeHeader from '../CustomComponents/HomeHeader';
import { useKeyDown } from '../../hooks/useKeyDown';
import statement_page from '../../assets/images/statement_page.png'
import arrow from '../../assets/images/Caret_S.png'
import shredding_statement from '../../assets/images/shredding_statement.png'
import Bell from '../../assets/images/Bell.png'
import LeftBar from '../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../CustomComponents/DynamicHomeCard';
import FooterContent from '../CustomComponents/Footer';
import logFirebaseEvents from '../LogFirebaseEvents';



const Documents = ({ transactionDatas, onViewAllClick, viewAll }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const dispatch = useDispatch();
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const selectedItem = cards?.[0]
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');


    useKeyDown(() => { }, ["Enter"]);

    const leftBarComp = () => {
        return (
            <>
                <div className='lg:w-[260px] md:w-[183px] sm:w-[164px] w-full sm:mx-0 ml-[2.5rem]'>
                    <p className="text-white font-normal 
                    lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] 
                    sm:leading-[30px] leading-[31px]
          font-serif-pro sm:border-b-[2.5px] sm:border-[#4B70B9] lg:pb-14 md:pb-[50px]  sm:border-opacity-[50%]
          sm:pb-[2.6rem] pb-[2.6rem] mb-10">
                        {t("easily_access_policy")}</p>

                    <div className=' flex flex-col gap-10 cursor-pointer sm:pb-0 pb-10'>
                        {(cards != null && cards.length > 0 && cards[0].EnableStatements) &&
                            <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%]' onClick={() => { navigate('/Documents/Statements') }}>

                                <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] 
                            leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px]
                        text-[#FEFAEE]'
                                    onClick={() => { navigate('/Documents/Statements'); logFirebaseEvents("Documents", "Statement_Clicked", "Statement_Clicked", "",""); }}
                                    tabIndex={0}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            navigate('/Documents/Statements');
                                        }
                                    }}
                                >
                                    {t("statements")}</p>
                                <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                            </div>
                        }
                        <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%] ' onClick={() => { navigate('/Documents/Policies') }}>
                        <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] 
                        leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px]
                     text-[#FEFAEE]' onClick={() => { navigate('/Documents/Policies');logFirebaseEvents("Documents", "Policies_Clicked", "Policies_Clicked", "","");  }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/Policies');
                                }
                            }}
                        >
                            {t("privacy_and_legal")}</p>
                            <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div>

                    {/* <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] 
                        leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px]
                     text-[#FEFAEE]' onClick={() => { navigate('/Documents/TaxDocuments') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/TaxDocuments');
                                }
                            }}
                        >
                            {t("tax_documents")}</p>  
 */}
                    {/* <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%]' onClick={() => { navigate('/Documents/Fees') }}>
                        <p className='font-hanken-grotesk font-[600]  lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] leading-[20px]
                         md:font-[700] md:leading-[16px] lg:leading-[16px]
                     text-[#FEFAEE]' onClick={() => { navigate('/Documents/Fees') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/Fees');
                                }
                            }}
                        >{t("fees")}</p>
                        <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                        </div> */}

                    </div>
                </div>
            </>
        )
    }
    const documentsChildren1 = () => {
        return (
            <>

                <div className="sm:flex block flex-row justify-between md:pt-20 pt-16 sm:pt-12 lg:pb-36 md:pb-32 sm:pb-24 pb-28 md:mx-0 mx-4">
                    <div className='flex flex-col lg:w-[406px] md:w-[321px] sm:w-[300px] w-[263px]'>

                        <p className="text-[#559C7E] font-normal 
                        leading-[30px] md:leading-[24px] lg:text-[32px]
                         md:text-[28px] text-[25px] lg:leading-[38px] sm:text-[18px] sm:leading-[24px]
                          font-serif-pro w-[70%] sm:w-full">{t("documents_on_demand")}</p>

                        <p className='font-hanken-grotesk font-[400] sm:font-[500] md:leading-[30px] 
                        sm:leading-[20px] leading-[20px]  md:text-[20px] sm:text-[12px] 
                         text-[14px] md:mt-6 mt-4 text-[#262B3A] sm:text-[#0C4437]
                        '>{t("access_your_documents_online_or_via_your_kudzu")}</p>
                    </div>
                    <div className='sm:block flex justify-center sm:mt-0 mt-28'>
                        <img src={statement_page} alt="setting" className=' lg:h-[120px] lg:w-[120px]
                         sm:h-[92px] sm:w-[92px] 
                         h-[120px] w-[120px]' />
                    </div>
                </div>

            </>
        )
    }
    const footerContent = () => {
        return (
            <FooterContent highlightText={t("access_your_bankstatements_at_any_time")}
                subHeaderText={t("easily_view_and_download_any_of_your_past")}
                image={shredding_statement} buttonEnabled={true}
                isSpace={true}
                buttonText={t("view_statements")}
                footerBtnAction={() => { navigate('/Documents/Statements') }}
                type={t("view_statements")}
                isSubheadingwidth={true}
            />
        )
    }

    return (
        <HomeLayout>
            <LeftBar leftBarType='component' leftBarComp={leftBarComp()} />
            <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>

            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight '>
                <DynamicCard
                    header={false}
                    CardRadius={true}
                    cardWidth={width.allpolicies}
                    HeaderRadius={true}
                    backgroundColor={'pink'}
                    isWhite={true}
                    HeaderContent={false}>
                    {documentsChildren1()}
                </DynamicCard>
                {footerContent()}
                {loading && <Loader />}
            </div>
        </HomeLayout >

    )
}

export default withOidcSecure(Documents);
