import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
// import BeneficiaryListItem from '../BeneficiaryListItem';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../../../Layout/HomeLayout';
import LineBreaks from '../../../../../utils';
import LeftBar from '../../../../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../../../../CustomComponents/DynamicHomeCard';
import { height, width } from "../../../../CustomComponents/Card/CardStyles";
import { GET_AUTH_USERS, STATUS_SUCCESS_CODE, GENERATE_OTP_ADD_AUTH_USER, RESEND_INVITE_LINK_AUTH_USER } from '../../../../../network/URLConstants';
import { connect, useDispatch, useSelector } from 'react-redux';
import { retrieveData, storeData } from '../../../../../helpers/utils/Utils';
import checkIcon from '../../../../../assets/images/check_3x.png';
import resendLink from '../../../../../assets/images/resendLink.png'
import Modal from '../../../../CustomComponents/Modal';
import Custom2FA from '../../../../CustomComponents/Custom2FA';
import Loader from '../../../../CustomComponents/Loader';
import { LOGIN_SESSION_KEY } from '../../../../../Redux/reducers';
import { displayErrorMessage, displaySuccessMessage } from '../../../../../utils';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import TwoFactTypeSelctionModal from "../../../../CustomComponents/TwoFactTypeSelctionModal";


import { useNavigate } from 'react-router';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest, toUrlEncodedString } from '../../../../../network/NetworkUtils';

const ManageAuthorizedusers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Headingcom = <p className='text-white'>Keep track of your beneficiary information here.</p>
    const [benficiriesList, setBenficiriesList] = useState([]);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const [isLoading, setIsLoading] = useState(false);
    const [authorizedUser, setAuthorizedUser] = useState({})
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title  lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none leading-[30px]";
    const [twoFactModelTitle, setTwoFactModelTitle] = useState('');
    const [twoFactModelSubTitle, setTwoFactModelSubTitle] = useState('');
    const [twoFactModelContent, setTwoFactModelContent] = useState('');
    const [showStnadradRatesMessage, setShowStnadradRatesMessage] = useState(false);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [selectedType, setSelectedType] = useState('');
    const [infoMessage, setinfoMessage] = useState('');
    const [infoStatsusCode, setInfoStausCode] = useState('')
    const [show2faSelection, setshow2faSelection] = useState(false);
    const [selectedOtpType, setSelectedOtpType] = useState('');

    async function getAuthUser() {
        if (cards == undefined || cards == null) {
            return
        }
        setIsLoading(true)
        const url = GET_AUTH_USERS.replace('{CARD_KEY}', cards[0].CardKey)
        const deviceId = await retrieveData('updatedDeviceId');
        let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        const response = await GetRequest(url, headers)

        setTimeout(() => {
            setIsLoading(false)
        }, 500);

        const responseObj = JSON.parse(response);

        if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
            const object = {
                Id: responseObj.Response.Id,
                firstName: responseObj.Response.firstName,
                inviteId: responseObj.Response.inviteId,
                lastName: responseObj.Response.lastName,
                statusCode: responseObj.Response.statusCode
            }
            setAuthorizedUser(object)
        } else {

        }

    }
    const heading = () => {
        return (
            <>
                <div className='lg:w-[260px] md:w-[183px] sm:w-[164px] w-full sm:mx-0 ml-[2.5rem] sm:pb-0 pb-8'>
                    <p className="text-white font-normal lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px]
                     lg:leading-[38px] md:leading-[33px] sm:leading-[30px] leading-[31px]
                  font-serif-pro ">{(t('add_benf_left_title'))}</p>
                </div>
            </>
        )
    }
    const verificationSuccess = (type) => {
        if (type == t('add_auth_user')) {
            // handleAuthUser()
            navigate("/AccountServices/Spending/ManageAuthorizedUsers/AddAuthorizedUsers")
        }
    }
    const verificationFaiure = (type, message) => {
        // setIsOTPVerifyModalOpen(false);
        setinfoMessage(message);
    }
    useEffect(() => {
        getAuthUser();
    }, []);
    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }
    async function generate2FA(procType) {
        const isEmail = procType === 'Email' ? true : false;
        const deviceId = await retrieveData('updatedDeviceId');
        let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        const url = GENERATE_OTP_ADD_AUTH_USER.replace('{CARD_KEY}', cards[0].CardKey)
        setIsLoading(true);

        const response = await PostRequest(url, { "IsEmail": isEmail }, headers)
        const parsedResponse = JSON.parse(response);
        setIsLoading(false);
        if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
            setTimeout(() => {
                const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                const userIdPayload = { userId: parsedResponse.Response.userId }
                dispatch({ type: 'USER_ID', payload: userIdPayload });
                setTwoFactModelTitle(t('great_exclamation'));
                setTwoFactModelSubTitle(procType === 'Email' ? t('we_are_sending_email_message') : t('we_are_sending_messsage'));
                setShowStnadradRatesMessage(procType === 'Email' ? false : true);

                setIsOTPVerifyModalOpen(true);
            }, 1000);

        } else {
            var arrMessages = parsedResponse.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            setinfoMessage(displayText);
        }
    }

    async function handleAuthUser() {
        if (twoFactAuthenticationDone) {
            navigate("/AccountServices/Spending/ManageAuthorizedUsers/AddAuthorizedUsers")
        } else {
            setshow2faSelection(true);
            //generate2FA();
        }
    }
    const resendLlinkClikced = async () => {
        setIsLoading(true)
        const url = RESEND_INVITE_LINK_AUTH_USER.replace('{CARD_KEY}', cards[0].CardKey)
        const deviceId = await retrieveData('updatedDeviceId');
        let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        const object = {
            Id: authorizedUser.inviteId,
            secondaryCardRequestId: authorizedUser.Id
        }
        const response = await PostRequest(url, object, headers);
        setIsLoading(false);
        const responseObj = JSON.parse(response);

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setinfoMessage(t('invite_shared'))
            setInfoStausCode('200')
            setTimeout(() => {
                setInfoStausCode('')
                setinfoMessage('');
            }, 5000);
        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            setInfoStausCode('')
            setinfoMessage(displayText);
            setTimeout(() => {
                setInfoStausCode('')
                setinfoMessage('');
            }, 5000);

        }
    }




    return (
        <>
            <HomeLayout>
                {/* <LeftBar leftBarType='text' isPrimary={true} heading={heading()} /> */}
                <HomeHeader heading={''} isHeaderCaption={false} />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
                 animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        CardRadius={true}
                        cardWidth={width.allpolicies}
                        HeaderRadius={true}
                        isWhite={true}
                        HeaderContent={false}>

                        <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
                 font-hanken-grotesk sm:mx-0 mx-4">{t("manage_authorized_users")}</div>
                        <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4 border-opacity-[50%]'></div>

                        <div className='justify-center items-center flex flex-col md:pb-40 sm:pb-20 pb-16 sm:mx-0 mx-4'>
                            <div className='lg:w-[80%] sm:w-[84%] w-full'>
                                {authorizedUser.firstName !== undefined && <>
                                    <p class="font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[12px] 
                                    sm:text-[12px] text-[13px]  font-[600] tracking-[2px] opacity-[50%] lg:mt-20 mt-10">
                                        {t("authorized_user_name")}
                                    </p>
                                    <div className='items-center flex border-b-[3px] 
                                    border-[#A3CEBB] justify-between border-opacity-50 my-8 pb-4'>
                                        <div>
                                            <p className='my-3 font-hanken-grotesk font-bold
                                         text-[16px] leading-4'>
                                                {authorizedUser.firstName + " " + authorizedUser.lastName}</p>

                                        </div>
                                        {authorizedUser.statusCode != "PDG" ? <div className='cursor-pointer'>
                                            <img src={checkIcon} alt="view details" className='w-[25px] h-[25px]' />
                                        </div> : <div className='cursor-pointer'>
                                            <img src={resendLink} alt="view details" className='w-[25px] h-[25px]' />
                                        </div>}
                                    </div>

                                    {authorizedUser.statusCode == "PDG" && <button onClick={() => { resendLlinkClikced() }} className='font-hanken-grotesk text-[#2965FB] mt-5 md:text-[16px] sm:text-[13px] text-[13px]  font-[700]'>{t('resend_link')}</button>}

                                    {(infoMessage && infoStatsusCode == "200") && displaySuccessMessage(infoMessage)}
                                    {(infoMessage && infoStatsusCode == "") && displayErrorMessage(infoMessage)}
                                    <div className='font-hanken-grotesk font-[500] lg:text-[20px] 
                                    md:text-[17px] sm:text-[16px] 
                                    text-[18px] flex items-center lg:leading-[30px]
                                     md:leading-[28px] leading-[25px]'>
                                        <p className='mt-14 md:w-[533px] w-full'>{(t("authorized_user_cantremove"))}</p>
                                    </div>
                                    <p className='font-hanken-grotesk text-[#2965FB] md:text-[16px] sm:text-[13px] text-[13px]  font-[700]'>1-800-751-0025.</p>
                                </>}

                                {(authorizedUser.firstName == undefined || authorizedUser.firstName == null) &&
                                    <div className='font-hanken-grotesk font-[500] lg:text-[20px] 
                                    md:text-[17px] sm:text-[16px] 
                                    text-[16px] flex justify-center items-center lg:leading-[30px]
                                     md:leading-[28px] leading-[25px]'>
                                        <p className='mt-10 md:w-[533px] w-full'>{(t("about_authorizedUser_title"))}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* paragraph */}
                        {(authorizedUser.firstName == undefined || authorizedUser.firstName == null) &&
                            <div className='flex flex-col justify-center items-center md:py-20 sm:py-16 py-12 gap-8 border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4 border-opacity-[50%]'>

                                <button
                                    onClick={() => { handleAuthUser() }}
                                    className='lg:w-[80%] sm:w-[84%] w-full h-[60px] border-[3px] 
                                border-border-submit font-hanken-grotesk text-secondary-2
                                 font-bold  lg:text-[16px] md:text-[15px] 
                                 sm:text-[14px] text-[15px] leading-4'>
                                    {t("add_authorized_users")}
                                </button>
                                {(infoMessage) &&
                                    <div className='mb-2 mt-2'>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                }

                                <div className='font-hanken-grotesk font-[500] lg:text-[16px] md:text-[15px] 
                            sm:text-[14px] text-[15px] 
                            leading-[24px] flex justify-center items-center'>
                                    <p className='lg:w-[80%] sm:w-[84%] w-full'>{t("auth_18_title")}</p>
                                </div>

                            </div>
                        }
                    </DynamicCard>

                </div>

            </HomeLayout>
            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={closeOTPVerifyModal}
                isLogo={false}
                title={<p className={HighlightMsgStyles}> {LineBreaks(twoFactModelTitle)}</p>}
                subtitle={<><p className={HeaderMsgStyles}>{LineBreaks(twoFactModelSubTitle)}</p> {showStnadradRatesMessage && <p className="font-hanken-grotesk my-3 text-[#262B3A] lg:text-[20px] md:text-[18px] sm:text-[17px] text-[18px] leading-[30px] font-[500]  w-full">{t('standrd_message_rates_apply')}</p>}</>}
                content={<div className="mt-10"><Custom2FA selected2FAType={selectedOtpType} phoneNumber={""} email={""} address={{}} type={selectedType} onClose={closeOTPVerifyModal} verificationFailure={verificationFaiure} verificationSuccess={verificationSuccess} /></div>}
            />
            <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false) }}
                onClickNext={(object) => {
                    setshow2faSelection(false);
                    setSelectedOtpType(object.proc)
                    generate2FA(object.proc);
                }} />
            {isLoading && <Loader />}
        </>

    )
}

export default ManageAuthorizedusers;