import React, { useEffect } from 'react';

const DisableRightClickHOC = (WrappedComponent) => {
  const DisableRightClickWrapper = (props) => {
    useEffect(() => {
      const handleContextMenu = (event) => {
        event.preventDefault();
      };

      const disableRightClick = () => {
        window.addEventListener('contextmenu', handleContextMenu);
      };

      const enableRightClick = () => {
        window.removeEventListener('contextmenu', handleContextMenu);
      };

      disableRightClick();

      return () => {
        enableRightClick();
      };
    }, []);

    return <WrappedComponent {...props} />;
  };

  return DisableRightClickWrapper;
};

export default DisableRightClickHOC;
