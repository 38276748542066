import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AcceptEsignAgreement, displayErrorMessage } from '../../../utils';
import { fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import Loader from '../Loader';
import { useSelector, useDispatch } from 'react-redux';
import { AGREEMENTS, PROGRAM_CONTENT, SAVINGS_AGREEMENT_POSTLOGIN, STATUS_SUCCESS_CODE } from '../../../network/URLConstants';
import { async } from 'q';
import { Document, Page } from 'react-pdf';
import Arrow from '../../../assets/images/arrow.png';
import leftArrow from '../../../assets/images/leftarrow.png';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomAlertModel from '../CustomAlertModel';
import { contextType } from 'react-copy-to-clipboard';


const appConfigData = await fetchConfigarationData();


const Agreements = (props) => {
    const { t } = useTranslation(); // Hook to access translation functions
    const accessTokenToApi = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const userId = useSelector(state => state.userId);
    const [responseContent, setResponseContent] = useState({});
    const [displayContent, setDisplayContent] = useState('');
    const [contentType, setContentType] = useState('');
    const [contentId, setContentId] = useState('');

    const [pdfData, setPdfData] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [modelImage, setModelImage] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[16px] text-[14px] font-normal cursor-pointer pointer-events-none";
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const location=useLocation();
    const param=location.state;

    useEffect(() => {
        getAgreementsBasedOnType()
    }, [])
    useEffect(() => { }, [contentType, displayContent])

    const getAgreementsBasedOnType = async () => {
        let reqCardKey
        let reqUrl
        let reqName
        if(param!==null && param !== undefined && param !== ''  ){
            reqCardKey=param?.CardKey;
            reqUrl=param?.url
            reqName=param?.name
        }
        else if (props.type.CardKey == undefined || props.type.CardKey == null) {
            return
        }
        else{
            reqCardKey=props.type.CardKey;
            reqUrl=props.type.url
            reqName=props.type.name
        }

        
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setLoading(true)

        let finalUrl
        props?.preLogin ? finalUrl = (PROGRAM_CONTENT + "?accountType=64&language=en&contentType=" + reqUrl + "&programKey=200") : reqName == 'Savings Account Agreement' ? finalUrl = SAVINGS_AGREEMENT_POSTLOGIN : finalUrl = (AGREEMENTS.replace('{contentType}', reqUrl).replace('{cardKey}', reqCardKey))
        // props?.preLogin ? finalUrl = (PROGRAM_CONTENT + "?accountType=64&language=en&contentType=" + props.type.url + "&programKey=200") : props.type.name == 'Savings Account Agreement' ? finalUrl = SAVINGS_AGREEMENT_POSTLOGIN : finalUrl = (AGREEMENTS.replace('{contentType}', props.type.url).replace('{cardKey}', props.type.CardKey))
        var response = await GetRequest(finalUrl, headers)
        var responseObj = JSON.parse(response)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setLoading(false)

            setResponseContent(responseObj.Response)
            if (responseObj.Response.ProgramContentID != undefined) {
                setContentId(responseObj.Response.ProgramContentID);
            }
            if (props?.preLogin) {
                setContentType(responseObj.Response.Data.endsWith('.pdf') ? 'pdf' : 'html')
                if (responseObj.Response.Data.endsWith('.pdf')) {
                    // renderPDF(responseObj.Response.content)
                    setDisplayContent(responseObj.Response.Data)
                    window.open(responseObj.Response.Data, '_blank');
                }
                else {
                    updateContent(responseObj)
                }
            } else {
                setContentType(responseObj.Response.RenderModel == 'Link' ? 'Link' : 'html')
                if (responseObj.Response.RenderModel == 'Link') {
                    if (!responseObj?.Response?.Content?.includes?.('kudzumoney')) {
                        props?.linkClickAction({ link: responseObj.Response.Content })
                    } else if (responseObj?.Response?.Data != undefined) {
                        if (!responseObj?.Response?.Data?.includes?.('kudzumoney')) {
                            props?.linkClickAction({ link: responseObj.Response.Data })
                        }
                    }
                }
                if (responseObj?.Response?.Content?.endsWith?.('.pdf')) {
                    // renderPDF(responseObj.Response.content)
                    setDisplayContent(responseObj.Response.Content)
                    window.open(responseObj.Response.Content, '_blank');
                } else if (responseObj.Response.RenderModel == 'Link') {
                    if (responseObj.Response.Content != undefined) {
                        setDisplayContent(responseObj.Response.Content)
                    } else if (responseObj.Response.Data != undefined) {
                        setDisplayContent(responseObj.Response.Data)
                    }

                }
                else {
                    updateContent(responseObj)
                }
            }
        } else {
            setTimeout(() => {
                setLoading(false)
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                setError(displayText)
            }, 500);
        }
    }
    const updateContent = (responseObj) => {
        var html
        props?.preLogin ? (html = responseObj.Response.Data) : (html = responseObj.Response.Content)
        var color = '#000000';
        var bgColor = '#ffffff';
        var htmlWithBackgroundColor = "";
        // if (bgColor == "white") {
        html = html.replace(/border:solid 1px black/g, "border:solid 1px #000");
        html = html.replace(/border:solid 1px white/g, "border:solid 1px #000");
        html = html.replace(/background:/g, "")
        html = html.replace(/<a/g, "<a style='color:#0077ee'")
        html = html.replace(/border:solid 1px #000/g, 'border:solid 1px black;border-collapse: collapse;border-spacing: 0px;text-align:center;color:black')
        html = html.replace(/<tbody/g, "<tbody style='color:black; font-family : HankenGrotesk, sans-serif;'")
        html = html.replace(/<strong/g, "<strong style='color:black; font-family : HankenGrotesk, sans-serif;'")
        var htmlWithTextColor = '<meta name="viewport" content="width=device-width, initial-scale=1"><div style="color: black; ">' + html + '</div>'
        // var htmlWithBackgroundColor = '<body style="background-color: transparent" zoom: 200%; text-align:center>' + htmlWithTextColor + '</body>';
        htmlWithBackgroundColor = '<body style="background-color: transparent;text-align:left; zoom: 100%; font: 16px HankenGrotesk, sans-serif;" >' + htmlWithTextColor + '</body>'

        setDisplayContent(htmlWithBackgroundColor)
        setLoading(false)

    }
    const renderPDF = async (url) => {
        let access_token = accessTokenToApi
        var headers = {
            // Authorization: 'Bearer ' + access_token,
            Accept: 'application/pdf',
        };
        try {
            setLoading(true)
            const response = await fetch(url, { method: 'GET' });
            if (response.ok) {
                const blob = await response.blob();
                const base64Data = await blobToBase64(blob);
                setLoading(false)
                const linkSource = base64Data;
                const downloadLink = document.createElement("a");
                const fileName = "file.pdf";
                downloadLink.href = linkSource;
                setTimeout(() => {
                    setLoading(false)
                    setPdfData(downloadLink)
                }, 500);
                // downloadLink.download = fileName;
                // downloadLink.click();
            } else {
                setLoading(false)
                console.error('Network response was not ok');
            }
        } catch (error) {
            setLoading(false)
            console.error(error);
        }
    }
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }
    const handleLinkClick = (event) => {
        const target = event.target;
        event.preventDefault();
        props?.linkClickAction({ link: target.getAttribute('href') })
    };
    const handleAcceptEsign = () => {
        setLoading(true);
        AcceptEsignAgreement(props.type.CardKey, accessTokenToApi, contentId).then(response => {
            setLoading(false);
            const isAcceptAgreement = { isAcceptAgreement: false };
            dispatch({ type: 'IS_ACCEPT_AGREEMENT', payload: isAcceptAgreement });

            if (response.ResponseCode == STATUS_SUCCESS_CODE) {
                navigate('/home')
            } else {
                // navigate('/home')
                const arrMessages = response.Messages;
                const message = arrMessages[0];
                const displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, false, true, '', t('cancel'), true, require('../../../assets/images/warning.png'), '', false, false)

            }
        }).catch(error => {
        });
    }
    const onAcptBtnClick = () => {
        setShowModel(false);
    }
    const closeModel = () => {
        setShowModel(false);
    }
    const onCancelBtnClick = () => {
        setShowModel(false);
    }

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
    }
    return (
        <>
            <div className={`${props.isPolicy ? 'bg-[#FFFFFF]  sm:rounded-tr-[4rem]' : 'bg-[#F5F8F9]'} relative z-10 w-full md:text-[14px] 
            sm:text-[13px] text-[14px] `}>
                {responseContent && (
                    <>
                        {contentType == 'html' ? (
                            <div onClick={handleLinkClick} dangerouslySetInnerHTML={{ __html: displayContent }} />
                        ) : contentType == 'pdf' ? (
                            displayContent && (
                                <div>
                                    {/* <h1>Base64 PDF Viewer</h1> */}
                                    {/* <Document file='https://thebancorp.com/globalassets/support/thebancorpbank/privacy-notice.pdf'>
                                        <Page pageNumber={1} />
                                    </Document> */}
                                    {/* <iframe src={pdfData} title="PDF Viewer" width="100%" height="600px" /> */}
                                    <button onClick={() => { window.open(displayContent, '_blank'); }}
                                        className='font-hanken-grotesk text-[#2965FB] md:text-sm
                                     sm:text-[11px] text-[16px] font-[700] cursor-pointer'>{t("ViewDocumnet")}</button>
                                </div>
                            )
                        ) : (contentType == 'Link' && (displayContent?.includes?.('kudzumoney'))) ? (
                            <div className="h-[100%]">
                                {displayContent &&
                                    <iframe src={displayContent}
                                        title="Embedded Website" className={`w-full min-h-[75vh] ${props.isPolicy && 'sm:rounded-tr-[4rem]'}`}
                                    />
                                }
                            </div>
                        ) : (
                            <p>Unsupported content type</p>
                        )}
                        {(props.isFromEsignAccept && !loading) &&
                            <div className="flex justify-between sm:px-[5rem] px-[2.5rem] py-[4rem]">
                                <button
                                    type="button"
                                    className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex items-center"
                                    onClick={() => {
                                        const isAcceptAgreement = { isAcceptAgreement: false };
                                        dispatch({ type: 'IS_ACCEPT_AGREEMENT', payload: isAcceptAgreement });
                                        navigate('/home')
                                    }}>
                                    {t('skip')}
                                </button>
                                <button
                                    type="button"
                                    className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex items-center"
                                    onClick={() => { handleAcceptEsign() }}>
                                    {t('accept')}
                                    <img
                                        src={Arrow}
                                        className="md:w-[17px] sm:w-[11px] md:h-[17px] sm:h-[11px] w-[12px] h-[12px] ml-2 animate__animated animate__flash animate__repeat-4"
                                        alt="Arrow" />
                                </button>
                            </div>}
                        {(props.isPolicy && !loading) &&
                         <div className="flex justify-start sm:px-[4rem] px-[2rem] py-[2rem]">
                            <button
                                type="button"
                                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex items-center"
                                onClick={() => navigate("/Documents/Policies")}
                                >
                                <img
                                    src={leftArrow}
                                    className="md:w-[17px] sm:w-[11px] md:h-[17px] sm:h-[11px] w-[12px] h-[12px] ml-2 animate__animated animate__flash animate__repeat-4 pr-1"
                                    alt="Arrow" />
                                Back to Policies
                            </button>

                         </div>
                        }
                    </>
                )}

            </div>
            <div className='my-2 w-full'>
                {error && displayErrorMessage(error)}
            </div>

            <CustomAlertModel
                isOpen={showModel}
                onClose={closeModel}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
            />

            {loading && <Loader />}
        </>
    );
};

export default Agreements;
