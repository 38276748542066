import React from 'react'
import spendingAlertError from "../../../../assets/images/spending-alert-error.png"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router';
import { withOidcSecure } from '@axa-fr/react-oidc';
import { useKeyDown } from '../../../../hooks/useKeyDown';


const Error = ({ content1, btn1, btn1Function, btn2, btn2Function }) => {
    const { t } = useTranslation();

    useKeyDown(() => {
        btn1Function();
    }, ["Enter"])
    return (
        <div className=' flex flex-col justify-center items-center w-full'>
            <p className='font-hanken-grotesk font-[400] sm:font-[500] 
            leading-[24px] sm:leading-[22px] lg:text-[20px] md:text-[18px]
             sm:text-[14px] text-[16px] md:leading-[28px] text-secondary-3 '>{content1}</p>

            <img src={spendingAlertError} alt="complete" className='lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10' />

            <div className=' flex flex-col justify-center items-center gap-8 w-full'>
                <button class="font-hanken-grotesk lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] font-[500] sm:font-bold leading-[24px] sm:leading-[12px] md:leading-[16px] bg-[#2965FB] text-white cursor-pointer border-2 px-2 py-4 border-[#2965FB] w-full" onClick={() => btn1Function()} onKeyDown={(e) => { if (e.key === "Enter") btn1Function() }}>{btn1}</button>
                {btn2 &&
                        <button class="font-hanken-grotesk lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] font-[500] sm:font-bold leading-[24px] sm:leading-[12px] md:leading-[16px] text-secondary-2 cursor-pointer border-2 px-2 py-4 border-[#2965FB] sm:border-none w-full capitalize" onClick={() => btn2Function()} onKeyDown={(e) => { if (e.key === "Enter") btn2Function() }}>{btn2}</button>
                    }
                {/* <p className='font-hanken-grotesk lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] font-[500] sm:font-bold leading-[24px] sm:leading-[12px] md:leading-[16px] text-secondary-2 capitalize cursor-pointer' onClick={() => btn2Function()}>{btn2}</p> */}

            </div>

        </div>
    )
}

export default withOidcSecure(Error)