import React, { useEffect, useState, useRef } from 'react';
import blue_drop_down from '../../../assets/images/blue_dropdown_arrow.png'
import { useTranslation } from 'react-i18next';
import { useKeyDown } from '../../../hooks/useKeyDown';
import { useSelector } from 'react-redux';
import { displayErrorMessage } from '../../../utils';
import logFirebaseEvents from '../../LogFirebaseEvents';

const CustomDropdown = (props) => {
    const { t } = useTranslation(); // Hook to access translation functions
    const [isOpen, setIsOpen] = useState(false);
    const [isInternalAccount, setIsInternalAccount] = useState(false)
    const [accountBalance, setAccountBalance] = useState(0);
    const [disabled, setDisabled] = useState(props?.disabled || 'not_disabled');
    const [showDropDown, setShowDropDown] = useState((props.showDropDown == undefined || props.showDropDown == null) ? true : props.showDropDown);
    const [selectedIndex, setSelectedIndex] = useState(null)
    const dropdownRef = useRef(); // Ref to the dropdown list 
    const cards = useSelector(state => JSON.parse(state.userCards));

    const check = () => {
        if (props?.selectedAccountType?.ACHAccountInternalId === 0) {
            const internalAccount = cards.find((card) => card.CardKey === props?.selectedAccountType?.AccountId)
            setAccountBalance(internalAccount?.Balance.toFixed(2))
            setIsInternalAccount(true);
        } else {
            setAccountBalance(0)
            setIsInternalAccount(false);

        }
    }


    useEffect(() => {
        check();
    }, [props?.onSelect])


    useEffect(() => { if (isOpen) { dropdownRef.current.focus() } }, [dropdownRef])




    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option, index) => {
        logFirebaseEvents("Contact_Us", "Select_Type_Clicked", "Select_Type_Clicked","", "")
        setSelectedIndex(index);
        props?.onSelect?.(option);
        setIsOpen(false);
    };
    // useKeyDown(() => {
    //     handleOptionSelect(props?.options?.[1],0);//need to check index
    // }, ["Enter"]);


    const handleKeyDown = (event) => {
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !/Edg|Firefox|Safari|OPR|MSIE|Trident/.test(navigator.userAgent);
        if (!isChrome) {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                setSelectedIndex((prevIndex) =>
                    prevIndex < props.options.length - 1 ? prevIndex + 1 : prevIndex
                );
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
            }
            else if (event.key === 'Tab') {
                if (event.shiftKey) {
                    if (selectedIndex > 0) {
                        event.preventDefault();
                        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                    }
                } else if (selectedIndex < props.options.length - 1) {
                    event.preventDefault();
                    setSelectedIndex((prevIndex) => prevIndex < props.options.length - 1 ? prevIndex + 1 : prevIndex);
                }
            }

            if (event.key === 'Enter') {
                //     if (selectedIndex !== -1) {
                event.preventDefault();
                handleOptionSelect(props.options[selectedIndex], selectedIndex);
            }
        }
    };
    // useEffect(() => {
    //     if (selectedIndex !== -1) {
    //       const list = dropdownRef.current;
    //       const selectedItem = list.querySelector(`[data-index="${selectedIndex}"]`);
    //       if (selectedItem) {
    //         const yOffset = selectedItem.getBoundingClientRect().top - list.getBoundingClientRect().top;
    //         list.scrollTo({ top: yOffset, behavior: 'smooth' });
    //       }
    //     }
    //   }, [selectedIndex]);



    return (
        <div className=' flex flex-col w-full'>
            <p className='font-hanken-grotesk text-[#262B3A] 
      uppercase lg:text-[13px] md:text-[9px] 
      sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-6 pb-5
       opacity-[50%] '>{props?.title?.toLocaleUpperCase() || ''}</p>

            <div className={`w-full ${isOpen && 'outline-none'}`} onClick={() => { if (props?.disabled) { if (props.disabled === 'not_disabled') { toggleDropdown(); } } else { toggleDropdown(); } }}
                tabIndex={0}
                onKeyDown={(event) => {
                    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !/Edg|Firefox|Safari|OPR|MSIE|Trident/.test(navigator.userAgent);
                    if (event.key === "Enter") {
                        event.preventDefault();
                        if (props?.disabled) { if (props.disabled === 'not_disabled') { toggleDropdown(); } } else { toggleDropdown(); }
                        if ((selectedIndex > '-1') && (selectedIndex != null) && (!isChrome) && (isOpen)) { handleOptionSelect(props.options[selectedIndex], selectedIndex); }
                    } else if (!isChrome && event.key === 'ArrowDown') {
                        if (isOpen) { event.preventDefault(); }
                        setSelectedIndex((prevIndex) => (prevIndex > '-1' && prevIndex != null && prevIndex != undefined) ? prevIndex < props.options.length - 1 ? (prevIndex > '-1' && prevIndex != null) ? (prevIndex + 1) : 0 : prevIndex : 0);
                    } else if (!isChrome && event.key === 'ArrowUp') {
                        if (isOpen) { event.preventDefault(); }
                        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                    }
                    else if ((!isChrome) && (event.key === 'Tab') && isOpen) {

                        if (event.shiftKey) {
                            if (selectedIndex > 0) {
                                event.preventDefault();
                                setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                            } else if (selectedIndex == 0) { toggleDropdown(); setSelectedIndex('-1') }
                        } else if (selectedIndex < props.options.length - 1 || selectedIndex == '-1' || selectedIndex == null || selectedIndex == undefined) {
                            event.preventDefault();
                            setSelectedIndex((prevIndex) => (prevIndex > '-1' && prevIndex != null && prevIndex != undefined) ? prevIndex < props.options.length - 1 ? (prevIndex > '-1' && prevIndex != null) ? (prevIndex + 1) : 0 : prevIndex : 0);
                        } else if (selectedIndex >= props.options.length - 1) {
                            event.preventDefault();
                        }
                    }
                }
                }
            >
                <div className={`flex justify-between flex-row w-full ${isOpen && 'outline-none'}`}>
                    <div className={`sm:flex block justify-between flex-row items-center mb-4 w-full 'outline-none'`}>
    <p contentEditable={false} className={`font-[500] font-hanken-grotesk lg:text-[16px]
 md:text-[15px] sm:text-[13px] text-[14px] cursor-none ${isOpen && 'outline-none'}`}>
                            {props?.selectedAccountType?.Name || props?.selectedAccountType?.AccountName?.slice(0,-6)|| props?.selectedAccountType?.DisplayText || props?.selectedFrequency || 'Select'}
                            <span className="font-[700] font-hanken-grotesk 
 md:text-[12px] sm:text-[9px] text-[12px]">{props?.selectedAccountType?.AccountName?.slice(-6)}</span>
                            </p>
                        {isInternalAccount &&
                            <p contentEditable={false} className={`sm:mt-0 mt-4 font-hanken-grotesk font-[500] text-[16px] 
                                    leading-[24px] md:leading-[26px]
                                     lg:text-[16px] md:text-[14px] sm:text-[12px] lg:leading-[26px] sm:text-right
                                     text-secondary-4 cursor-none ${isOpen && 'outline-none'}`}>{`$${accountBalance} Available`}</p>
                        }
                    </div>
                    <img
                        src={blue_drop_down}
                        // alt="Image"
                        className={`w-3 h-3 mr-2 ml-10 cursor-pointer outline-none}`}
                    />
                </div>
    
                <hr tabindex="-2" className={` h-[2px] ${props?.hrbg ? props?.hrbg : 'bg-[#94C3E8] opacity-[50%]'} border-0 rounded`} />
            </div>
            {isOpen && (
                <div className={`bg-[#F5F8F9] relative z-10 ${props?.isScroll ? 'max-h-[13rem] overflow-y-auto' : 'sm:max-h-[30rem] max-h-[20rem] overflow-y-auto'}`}>
                    <ul
                        onBlur={() => setSelectedIndex(-1)} // Reset selection when the dropdown loses focus
                        onKeyDown={handleKeyDown}
                        ref={dropdownRef}>
                        {props?.options?.map((option, index) => (
                            <div
                                onKeyDown={(event) => {
                                    if (event.key == "Enter") {
                                        event.preventDefault();
                                        if (option?.Name == t('no_linked_acccounts')) {
                                            return;
                                        }
                                        event.target.style.opacity = 0.8;
                                        handleOptionSelect(option, index);
                                        setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                    }
                                }}
                                className={`py-4 sm:pl-4 pl-2 hover:bg-[#D6E2E8] ${index === selectedIndex ? 'bg-[#D6E2E8]' : ''}`}
                                key={index}
                            >
                                <li
                                    className={`lg:text-[16px] md:text-[15px] sm:text-[13px] text-[14px] cursor-pointer focus:outline-none  font-hanken-grotesk font-[500] leading-[26px] ${index === selectedIndex ? 'dropdown-selected' : ''}`}
                                    data-index={index}
                                    tabIndex={0}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            debugger;
                                            if (option?.Name == t('no_linked_acccounts')) {
                                                return;
                                            }
                                            event.target.style.opacity = 0.8;
                                            handleOptionSelect(option, index);
                                            setTimeout(() => {
                                                event.target.style.opacity = 1;
                                            }, 200);
                                        }
                                    }}
                                    onClick={() =>{
                                        if (option?.Name == t('no_linked_acccounts')) {
                                            return;
                                        }
                                        handleOptionSelect(option, index)
                                    }}
                                >
                                    {option.Name || option.AccountName?.slice(0,-6) || option.DisplayText || ''}
                                    <span className="font-[700] font-hanken-grotesk 
 md:text-[12px] sm:text-[9px] text-[12px]">{option.AccountName?.slice(-6)}</span>
                                </li>
                            </div>
                        ))}
                    </ul>
                </div>
            )}
            {props?.error &&
                <div className='my-2 w-full'>
                    {displayErrorMessage(props?.error)}
                </div>
            }
        </div>
    );
};

export default CustomDropdown;
