import { withOidcSecure } from '@axa-fr/react-oidc'
import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../../Layout/HomeLayout';
import Loader from '../../../../CustomComponents/Loader';
import { useTranslation } from 'react-i18next';
import pause from "../../../../../assets/images/illo-pause.png"
import pauseDone from "../../../../../assets/images/illo-pause-done.png"
import Modal from '../../../../CustomComponents/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import DynamicCard from '../../../../CustomComponents/DynamicCard';
import { ClimbManageSettingsSidebar, retrieveData } from '../../../../../utils';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../../network/NetworkUtils';
import { GET_ALL_SAVINGS_HABIT, GET_CLIMB_FEATURES, OPT_IN_FEATURE, OPT_OUT_FEATURE, SAVINGS_HABIT, STATUS_SUCCESS_CODE } from '../../../../../network/URLConstants';
import SavingsHabitItem from './SavingsHabitItem';
import { width } from "../../../../CustomComponents/DynamicCard/DynamicCardStyles";
import Success from '../Success';
import logFirebaseEvents from '../../../../LogFirebaseEvents';


const ManageSavingsHabit = () => {
    const HeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3";

    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [savingsHabitArray, setSavingsHabitArray] = useState(null)
    const [isPauseSavingsHabit, setIsPauseSavingsHabit] = useState(null)
    const [savingHabitErrorMsg, setsavingHabitErrorMsg] = useState()
    const [savingHabitCode, setsavingHabitCode] = useState('');
    const [isClimbings, setisClimbings] = useState([]);
    const [isOptIn, setIsOptIn] = useState();
    const [showSubItems, setShowSubItems] = useState(false);
    const SavingHabitCount = useSelector(state => state.activeSavingsHabitCount)
    const climbFeatures = useSelector(state => state.climbFeatures);
    const enabledClimbings = climbFeatures?.filter((response) => response.enabled === true);
    const isCoveredByKudzuOptedIn = enabledClimbings?.some(item => item.code === 'CoveredByKudzu' && item.optedIn)



    const accessToken = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const hasConfigurations = useSelector(state => state.climbFeatures?.hasConfigurations);

    const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;

    const handleClimbFeatures = async () => {
        //alert('ji');
        // debugger;
        if (cards == undefined || cards == null) {
            return
        }
        setIsLoading(true);
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const url = GET_CLIMB_FEATURES.replace('{CARD_KEY}', isPrimrayKey)
        const response = await GetRequest(url, headers);
        const responseJson = JSON.parse(response);
        if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
            //debugger;
            const spendingAlertResponse = responseJson.Response.find((obj) => obj.code === SAVINGS_HABIT);
            if (spendingAlertResponse) {
                const isOptInValue = spendingAlertResponse.optedIn;
                setsavingHabitCode(spendingAlertResponse.code);
                setIsOptIn(isOptInValue);
                setShowSubItems(isOptInValue)
                // (isOptInValue === false) ? setShowSubItems(true) : setShowSubItems(false);

            } else {
                // Handle the case where 'SpendingAlert' is not found in the response
            }
            const enabledClimbings = responseJson.Response.filter(
                (response) => response.enabled === true
            );
            setisClimbings(enabledClimbings);
            setIsLoading(false);
        } else {
            return [];
        }

    }



    //API Call-------------------
    const getAllSavingsHabit = async () => {
        if (cards == null || cards == undefined) {
            return
        }
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = GET_ALL_SAVINGS_HABIT.replace('{CARD_KEY}', cardKey)
            const response = await GetRequest(url, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setSavingsHabitArray(responseJson.Response)
                // setSavingHabitCount(responseJson.Response.length);
                dispatch({ type: "SAVINGS_HABIT_ACTIVE_COUNT", payload: { activeCount: responseJson.Response.length } })

                setIsLoading(false);

            } else if (responseJson.Messages) {
                const displayErrorText = responseJson.Messages[0].DisplayText;

                setIsLoading(false);
            }

        } catch (error) {
        }
    }
    const updateOptInFeature = async () => {
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = OPT_IN_FEATURE.replace('{CARD_KEY}', cardKey).replace('{featureType}', savingHabitCode)
            const response = await PostRequest(url, {}, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setShowSubItems(false);
                setIsOptIn(false);
                setIsLoading(false);
            } else if (responseJson.Messages) {
                const displayErrorText = responseJson.Messages[0].DisplayText;
                setsavingHabitErrorMsg(displayErrorText)
                openErrorModal();
                setIsLoading(false);
            }

        } catch (error) {
        }
    }
    const updateOptOutFeature = async () => { 
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = OPT_OUT_FEATURE.replace('{CARD_KEY}', cardKey).replace('{featureType}', savingHabitCode)
            const response = await PostRequest(url, {}, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setShowSubItems(true);
                setIsOptIn(true);
                setIsLoading(false);
                closeOptInModal();
                openOptOutDoneModal()
                setIsLoading(false);
            } else if (responseJson.Messages) {
                const displayErrorText = responseJson.Messages[0].DisplayText;
                setsavingHabitErrorMsg(displayErrorText)
                openErrorModal();
                setIsLoading(false);
            }

        } catch (error) {
        }
    }

    useEffect(() => {
        handleClimbFeatures();
        getAllSavingsHabit();
        //alert("isOptIn+"+isOptIn);
    }, [isOptIn])


    const handleAddSavingsHabit = () => {
        logFirebaseEvents("Savings_Habit", "Add_Saving_Habit_Clicked", "Add_Saving_Habit_Clicked","", "");
        navigate("/Climb/AddSavingsHabit")
    }

    const handleToggleButton = (e) => { 
        logFirebaseEvents("Savings_Habit", "Saving_Habit_Toggle_Clicked", "Saving_Habit_Toggle_Clicked","", "");
        const checkedValue = e.target.checked;
        if (!checkedValue) openOptInModal()
        else updateOptInFeature()
    }

    const handlePauseSavingsHabit = () => {
        logFirebaseEvents("Savings_Habit", "Pause_Saving_Habit_Clicked", "Pause_Saving_Habit_Clicked","", "")
        setIsOptOutModalOpen(false)
        updateOptOutFeature();
    }

    const handleStartSavingsHabit = () => {
        setIsOptInModalOpen(false)
        updateOptInFeature();
    }

    const handleOptInCancel = () => {
        logFirebaseEvents("Savings_Habit", "Cancel_Pause_Saving_Habit_Clicked", "Cancel_Pause_Saving_Habit_Clicked","", "")
        closeOptInModal()
    }

    const handleBackToSavingsHabit = () => {   
        setIsOptOutDoneModalOpen(false)
        // if (hasConfigurations) navigate("/Climb/ManageSavingsHabit")
        // else navigate("/Climb/ClimbMenu")
    }




    // Opt In modal
    const [isOptInModalOpen, setIsOptInModalOpen] = useState(false);

    const openOptInModal = () => {
        setIsOptInModalOpen(true);
    };

    const closeOptInModal = () => {
        setIsOptIn(prev => !prev)
        setIsOptInModalOpen(false);

    };

    // Opt out modal
    const [isOptOutModalOpen, setIsOptOutModalOpen] = useState(false);

    const openOptOutModal = () => {
        setIsOptOutModalOpen(true);
    };

    const closeOptOutModal = () => {
        setIsOptIn(prev => !prev)
        setIsOptOutModalOpen(false);

    };
    // Opt out Done modal
    const [isOptOutDoneModalOpen, setIsOptOutDoneModalOpen] = useState(false);

    const openOptOutDoneModal = () => {
        setIsOptOutDoneModalOpen(true);
    };

    const closeOptOutDoneModal = () => {
        setIsOptOutDoneModalOpen(false);

    };
    // error modal
    const [isErrorModalOpen, setIsErroeModalOpen] = useState(false);

    const openErrorModal = () => {
        setIsErroeModalOpen(true);
    };

    const closeErrorModal = () => {
        setIsErroeModalOpen(false);

    };


    const children = () => {
        return (
            <>
                <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500]
                             lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
                              font-hanken-grotesk sm:mx-0 mx-4"> {t("manage_savings_habit")} </div>

                <div class="border-[#94C3E8] border-b-[3px] sm:mx-0 mx-4"></div>

                <div className='justify-center flex lg:mt-8 md:mt-6 sm:mx-0 mx-4'>
                    <div className='lg:w-[70%] sm:w-[81%] w-full'>

                        <div className="mt-10 flex justify-between md:h-[123px]
                          sm:h-[140px] h-[123px] border-b-[2px] border-[#94C3E8] border-opacity-[65%]">

                            <div>
                                <p className="lg:text-[20px] md:text-[18px] sm:text-[17px] text-[17px] 
                            font-hanken-grotesk leading-[30px] sm:font-[500] font-[600]">
                                    {t("savingsHabittext")}</p>

                                <p className="mt-4 lg:text-[14px] md:text-[13px] sm:text-[14px] text-[14px] 
                                   font-hanken-grotesk lg:leading-[20px] md:leading-[18px] sm:leading-[23px]
                                    leading-[20px] sm:font-[400] font-[500] sm:w-auto w-[240px]" >{t("savingHabiPauseMsg")}</p>
                            </div>

                            <p className='flex-grow text-right'>
                                <label class="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox"
                                        checked={isOptIn}
                                        onChange={(e) => { setIsOptIn(e.target.checked) }}
                                        class="sr-only peer"
                                        onClick={(e) => handleToggleButton(e)}
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter"){
                                                handleToggleButton(e)
                                            } 
                                        }
                                        }

                                    />
                                    <div class="w-[38px] h-[25px]  bg-[#4B70B9] bg-opacity-[40%]
                                     peer-focus:outline-none   
                                      rounded-full peer dark:bg-gray-700 
                                      peer-checked:after:translate-x-full
                                     peer-checked:after:border-[#2965FB] 
                                      peer-checked:after:bg-[#2965FB]
                                      peer-checked:after:left-[-6px]
                                      after:content-[''] 
                                      after:absolute after:top-[2px]
                                      after:left-[2px] after:right-0
                                      after:bg-[#fff]
                                       after:border-[#fff] after:border-[gray-300] 
                                      after:border after:rounded-full after:h-[21px] 
                                      after:w-[21px] after:transition-all
                                       dark:border-gray-600 peer-checked:bg-[#94C3E8]
                                        peer-checked:bg-opacity-[40%]"></div>

                                    <span class="ml-3 text-sm font-medium text-gray-900
                                     dark:text-gray-300"></span>
                                </label>
                            </p>
                        </div>

                        {savingsHabitArray &&
                            savingsHabitArray.map((data) => {
                                return (
                                    <SavingsHabitItem data={data} isShow={showSubItems} />
                                )
                            })

                        }
                        <p className="font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] sm:text-[14px]
                                     text-[16px] leading-[24px] text-secondary-3 w-[80%] pt-8" >
                            {t("manage_savings_habit_content2")}
                            <span className='text-secondary-2 cursor-pointer'
                                onClick={() => { navigate('/PayPerks'); logFirebaseEvents("Savings_Habit", "PayPerks_Saving_Habit_Clicked", "PayPerks_Saving_Habit_Clicked","", ""); }}
                                tabIndex={0}
                                onKeyDown={(e) => { if (e.key === "Enter") navigate('/PayPerks')  }}
                                >{t("NavbarPerks")}</span></p>
                    </div>
                </div>

                <div class="border-[#94C3E8] border-b-[2px] border-opacity-[50%] 
                lg:mt-16 md:mt-12 sm:mt-10 mt-12 sm:block hidden"></div>
                <div className='flex justify-center flex-col items-center md:my-16 sm:my-12 my-16'>
                    <div className='lg:w-[70%] sm:w-[81%] w-full'>
                        <button
                            className={`font-hanken-grotesk sm:font-bold leading-[24px]
                                         sm:leading-[12px] lg:text-[16px] md:text-[15px] 
                                         sm:text-[14px] text-[16px] md:leading-[16px]
                                          text-[#2965FB] font-[600]
                                         cursor-pointer border-2 px-2 py-4 border-[#2965FB]
                                          w-full ${showSubItems ? 'opacity-1' : 'opacity-[0.5]'}`}
                            onClick={handleAddSavingsHabit}
                            tabIndex={0}
                            onKeyDown={(e) => { if (e.key === "Enter") handleAddSavingsHabit()  }}
                            disabled={!showSubItems} >
                            {t('Add_Habit')}
                        </button>

                    </div>
                </div>

                {/* ---------- */}

            </>
        )
    }

    const ManageSettingsbuttonLabels = [
        { label: 'SpendSense Alerts', link: '/Climb/ManageSpendAlerts' },
        { label: 'Covered by Kudzu', link: isCoveredByKudzuOptedIn ? '/Climb/ManageCoveredByKudzu' : '/Climb/CoveredByKudzu' },
        { label: 'Savings Habits', link: '/Climb/ManageSavingsHabit' },
    ];

    const navigateClicked = (item) => {
        navigate(item.link)
    }

    return (
        <>
            <HomeLayout>
                <ClimbManageSettingsSidebar indexBtn={3} ManageSettingsbuttonLabels={ManageSettingsbuttonLabels} headingMessage={false} count={SavingHabitCount} isShow={showSubItems} navigateClicked={navigateClicked} />
                <div className='flex flex-col md:w-[860px] 
                sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>

                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        footer={true}
                        HeaderContent={false} isWhite={true}
                        backgroundColor={'#FFFFFF'}>

                        {children()}
                    </DynamicCard>

                </div>
            </HomeLayout >

            {isLoading && <Loader />}

            <Modal
                isOpen={isOptInModalOpen}
                onClose={closeOptInModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('pauseSpendAlerts')}</p>}
                subtitle={
                <p>
                <span className={`${HeadingStyles} visible sm:hidden`}>{t('pauseSpendAlerts')}</span><span className={SubHeadingStyles}>{t('SavingHabitPauseMessage')}</span>
                </p>}
                content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
                    <Success content1={t("manageHabit_pause_modal_content1")} btn1={t("pause_savings_habit")} btn1Function={handlePauseSavingsHabit} btn2={t("cancel")} btn2Function={handleOptInCancel} icon={pause} /></div>}
            />
            <Modal
                isOpen={isOptOutDoneModalOpen}
                onClose={closeOptOutModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('done_exclamation')}</p>}
                subtitle={
                <p>
                <span className={`${HeadingStyles} visible sm:hidden`}>{t('done_exclamation')}</span><span className={SubHeadingStyles}>{t('pauseSavingsHabitSucess2')}</span>
                </p>}
                content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
                    <Success content1={t("pause_savings_habit_modal_content2")} btn1={t("backtoClimb")} btn1Function={() =>{ navigate('/Climb/ClimbMenu'); logFirebaseEvents("Savings_Habit", "Back_Climb_Manage_Saving_Habit_Clicked", "Back_Climb_Manage_Saving_Habit_Clicked","", "") }}  btn2Function={handleBackToSavingsHabit} icon={pauseDone} /></div>}

            />

        </>
    )
}

export default withOidcSecure(ManageSavingsHabit)


