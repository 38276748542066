import { withOidcSecure } from '@axa-fr/react-oidc'
import React, { useEffect, useState } from 'react'
import { decode } from 'js-base64';

import HomeLayout from '../../../../Layout/HomeLayout';
import Loader from '../../../../CustomComponents/Loader';
import { useTranslation } from 'react-i18next';
import RightArrow from "../../../../../assets/images/RightArrow@2x.png"
import removeIcon from "../../../../../assets/images/illo-savings-habit-remove.png"
import Modal from '../../../../CustomComponents/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import DynamicCard from '../../../../CustomComponents/DynamicCard';
import { width } from '../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import { ClimbManageSettingsSidebar, displayErrorMessage, handleAmountInputLimitTo2decimal, retrieveData } from '../../../../../utils';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../../network/NetworkUtils';
import { GET_SAVINGS_HABIT_DETAILS, REMOVE_SAVINGS_HABIT, STATUS_SUCCESS_CODE, UPDATE_SAVINGS_HABIT } from '../../../../../network/URLConstants';
import savingsHabitSuccess from "../../../../../assets/images/savings-habit-done.png"
import CustomTextInput from '../../../../CustomComponents/CustomtextInput';
import CustomRadioButton from '../../../../CustomComponents/CustomRadioButton';
import CustomDatePicker from '../../../../CustomComponents/CustomDateInput';
import dayjs from 'dayjs';
import moment from 'moment';
import Error from '../Error';
import Success from '../Success';
import debounce from 'lodash/debounce';
import logFirebaseEvents from '../../../../LogFirebaseEvents';

const EditSavingsHabit = () => {
  const HeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
  const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3";

  const { t } = useTranslation()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  // const habitIdValue = decode(params.id);
  const habitIdValue = useSelector(state => state.savingsHABSelectedId);

  const [isLoading, setIsLoading] = useState(false);
  const [savingsHabitObj, setSavingsHabitObj] = useState(null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [frequencyArray, setFrequencyeArray] = useState([{ 'displayText': 'Monthly' }, { 'displayText': 'Weekly' }])

  const [percentError, setPercentError] = useState()
  const [amountError, setAmountError] = useState()
  const [frequencyError, setFrequencyError] = useState(null)
  const [dateError, setDateError] = useState(null)
  const [error, setError] = useState(null);
  const climbFeatures = useSelector(state => state.climbFeatures);
  const enabledClimbings = climbFeatures?.filter((response) => response.enabled === true);
  const isCoveredByKudzuOptedIn = enabledClimbings?.some(item => item.code === 'CoveredByKudzu' && item.optedIn)



  const accessToken = useSelector(state => state.accessToken);
  const cards = useSelector(state => JSON.parse(state.userCards));
  const SavingHabitCount = useSelector(state => state.activeSavingsHabitCount)



  //API Call-------------------
  const getSavingsHabitDetails = async () => {
    if (cards == undefined || cards == null) {
      return
    }
    try {
      setIsLoading(true);

      const deviceId = await retrieveData('updatedDeviceId')
      const cardKey = cards !== null ? cards[0].CardKey : 0;

      const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
      const url = GET_SAVINGS_HABIT_DETAILS.replace('{CARD_KEY}', cardKey).replace('{habitId}', habitIdValue)
      const response = await GetRequest(url, headers);
      const responseJson = await JSON.parse(response);
      if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
        setShowDatePicker(true)
        setSavingsHabitObj({ ...responseJson.Response, 'startDate': new Date(responseJson.Response.startDate) })
        setIsLoading(false);

      } else if (responseJson.Messages) {
        const displayErrorText = responseJson.Messages[0].DisplayText;
        setError(displayErrorText)
        openErrorModal();

        setIsLoading(false);
      }

    } catch (error) {
    }
  }
  const updateSavingsHabit = async () => {
    try {
      setIsLoading(true);

      const deviceId = await retrieveData('updatedDeviceId')
      const cardKey = cards !== null ? cards[0].CardKey : 0;

      const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
      const url = UPDATE_SAVINGS_HABIT.replace('{CARD_KEY}', cardKey).replace('{habitId}', habitIdValue)
      savingsHabitObj.startDate=moment(savingsHabitObj.startDate).format('YYYY-MM-DD')
      const body = savingsHabitObj;
      const response = await PostRequest(url, body, headers);
      const responseJson = await JSON.parse(response);
      if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
        setIsLoading(false);
        openSuccessfullUpdateModal();

      } else if (responseJson.Messages) {
        const displayErrorText = responseJson.Messages[0].DisplayText;
        setError(displayErrorText)
        openErrorModal();

        setIsLoading(false);
      }

    } catch (error) {
    }
  }
  const removeSavingsHabit = async () => {
    try {
      setIsLoading(true);

      const deviceId = await retrieveData('updatedDeviceId')
      const cardKey = cards !== null ? cards[0].CardKey : 0;

      const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
      const url = REMOVE_SAVINGS_HABIT.replace('{CARD_KEY}', cardKey).replace('{habitId}', habitIdValue)
      const response = await PostRequest(url, {}, headers);
      const responseJson = await JSON.parse(response);
      if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
        openSuccessfullRemoveModal();
        setIsLoading(false);

      } else if (responseJson.Messages) {
        const displayErrorText = responseJson.Messages[0].DisplayText;

        setIsLoading(false);
      }

    } catch (error) {
    }
  }


  useEffect(() => {
    getSavingsHabitDetails();
  }, [])


  const handleBackClick = () => {
    logFirebaseEvents("Edit_Savings_Habit", "BackTo_Manage_Saving_Clicked", "BackTo_Manage_Saving_Clicked", "","");
    navigate("/Climb/ManageSavingsHabit")
  }
  const handleSaveClick = () => {
    logFirebaseEvents("Edit_Savings_Habit", "Edit_Saving_habit_clicked", "Edit_Saving_habit_clicked", "","");
    //Amount based
    if (savingsHabitObj.percentageValue === 0 && savingsHabitObj.amount) {
      if (!savingsHabitObj.amount) setAmountError('Amount Required.');
      if (!amountError) updateSavingsHabit();
    }

    //Percent Based
    if (savingsHabitObj.amount === 0 && savingsHabitObj.percentageValue) {
      if (!savingsHabitObj.percentageValue) setPercentError('Percentage Required.');
      if (!percentError) updateSavingsHabit();

    }


  }

  const validation = () => {
    if (savingsHabitObj.percentageValue < 0 || savingsHabitObj.percentageValue > 100) setPercentError('Please enter percent between 0 to 100')
    else setPercentError(null)

    if (savingsHabitObj.amount === "" || savingsHabitObj.amount <= 0) setAmountError('Please enter amount greater than 0')
    else setAmountError(null)

  }

  const setFrequencyFun = (value) => {
    setSavingsHabitObj({ ...savingsHabitObj, 'frequencyType': value.displayText })
  }

  const onDateChange = (date) => {
    setSavingsHabitObj({ ...savingsHabitObj, 'startDate': date })
  }


  useEffect(() => {
    if (savingsHabitObj) {
      validation();
    }
  }, [savingsHabitObj])


  const handleRemoveHabit = () => {
 
    openRemoveModal();
  }
  const debouncedHandleRemoveHabit = debounce(handleRemoveHabit, 100);
  const handleBackToClimb = () => {
    logFirebaseEvents("Edit_Savings_Habit", "BackTo_Climb_Saving_Habit_Clicked", "BackTo_Climb_Saving_Habit_Clicked","", "");
    dispatch({ type: "RESET_ADD_SPEND_ALERTS" })
    navigate("/Climb/ClimbMenu")
  }

  const handleBackToSavingsHabit = () => {
    logFirebaseEvents("Edit_Savings_Habit", "BackTo_Saving_Habit_Clicked", "BackTo_Saving_Habit_Clicked","", "");
    navigate("/Climb/ManageSavingsHabit")
  }
  const handleRemoveSavingsHabitClick = () => {
    logFirebaseEvents("Edit_Savings_Habit", "Remove_Saving_Habit_Clicked", "Remove_Saving_Habit_Clicked","", "");
    removeSavingsHabit();
  }


  // Successfull Update modal
  const [isSuccessfullUpdateModalOpen, setIsSuccessfullUpdateModalOpen] = useState(false);

  const openSuccessfullUpdateModal = () => {
    setIsSuccessfullUpdateModalOpen(true);
  };

  const closeSuccessfullUpdateModal = () => {
    setIsSuccessfullUpdateModalOpen(false);
    navigate("/Climb")

  };

  // Remove modal
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const openRemoveModal = () => {
    setIsRemoveModalOpen(true);
  };

  const closeRemoveModal = () => {
    setIsRemoveModalOpen(false);

  };


  // Successfull Remove modal
  const [isSuccessfullRemoveModalOpen, setIsSuccessfullRemoveModalOpen] = useState(false);

  const openSuccessfullRemoveModal = () => {
    closeRemoveModal();
    setIsSuccessfullRemoveModalOpen(true);
  };

  const closeSuccessfullRemoveModal = () => {
    setIsSuccessfullRemoveModalOpen(false);
    navigate("/Climb")

  };

  const [isErrorModalOpen, setIsErroeModalOpen] = useState(false);

  const openErrorModal = () => {
    setIsErroeModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErroeModalOpen(false);

  };

  const handleOnBlur = (e) => {
    if (!savingsHabitObj.amount) setAmountError('Enter Amount');
    else if (savingsHabitObj.amount <= 0) {
      setAmountError('Please enter amount greater than 0')
    }
    else setAmountError('')
  }

  const handleOnBlurPerc = (e) => {
    if (!savingsHabitObj.percentageValue) setPercentError('Percentage Required.');
    else if (savingsHabitObj.percentageValue <= 0 || savingsHabitObj.percentageValue > 100) setPercentError('Please enter percent between 0 to 100')
    else setPercentError('')

  }



  const cardHeader = () => {
    return (
      <>
        <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500]
      lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
      font-hanken-grotesk sm:mx-0 mx-4"> {t("edit_saving_Habit")}</div>
        <hr className=" h-[2px] bg-[#94C3E8] border-0 rounded sm:mx-0 mx-4" />
      </>
    )
  }

  const cardFooter = () => {
    return (
      <div className='flex flex-row sm:justify-end justify-between 
      items-center lg:gap-12 md:gap-10 gap-9 lg:pb-[5rem] md:pb-[4rem] sm:pb-[3rem] pb-[3rem] sm:mx-0 mx-4'>

        <p className='font-hanken-grotesk font-[600] cursor-pointer sm:font-[700] lg:text-[18px] md:text-[17px] 
            sm:text-[15px] text-[16px] leading-[26px]  sm:leading-[13px] 
             md:leading-[18px] text-secondary-2 ' onClick={handleBackClick}
          tabIndex={0}
          onKeyDown={(e) => { if (e.key === "Enter") handleBackClick() }}
        >{`${t("back")}`}</p>
        <div>
          <div className='flex flex-row justify-end  gap-2 cursor-pointer items-center'>
            <p className='font-hanken-grotesk font-[600] sm:font-[700] lg:text-[18px] md:text-[17px] 
            sm:text-[15px] text-[16px] leading-[26px]  sm:leading-[13px] 
             md:leading-[18px] text-secondary-2 ' onClick={handleSaveClick}
              tabIndex={0}
              onKeyDown={(e) => { if (e.key === "Enter") handleSaveClick() }}
            >{`${t("save")}`}</p>
          </div>
        </div>
      </div>
    )
  }



  const children = () => {
    return (
      <>
        {cardHeader()}
        <div className='w-full flex justify-center my-10'>
          <div className="sm:w-[80%] w-full">

            <div className='md:mb-12 sm:mb-12 mb-[3rem] sm:mx-0 mx-4'>
              <div className='md:w-[70%] sm:w-[82%] w-full flex flex-col justify-center'>

                {(savingsHabitObj && savingsHabitObj.habitType === 'ByDeposit') &&
                  <div className='h-auto md:pt-16 sm:pt-13 pt-12'>
                    <CustomTextInput name='percent'
                      onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); } }}
                      onChange={(event) => {
                        const value = event.target.value.replace('%', '');
                        if (!value) setPercentError('Percentage Required.');
                        else if (value <= 0 || value > 100) setPercentError('Please enter percent between 0 to 100')
                        else setPercentError('')
                        setSavingsHabitObj({ ...savingsHabitObj, 'percentageValue': event.target.value.replace('%', '') })
                      }}
                      value={savingsHabitObj.percentageValue ? `${savingsHabitObj.percentageValue}%` : ''}
                      error={percentError}
                      placeholder={"0 %"}
                      inputWidth='w-full'
                      autoFocus={true}
                      maxLength={"3"}
                      onBlur={(event) => handleOnBlurPerc(event)}

                    />
                    <p className=' font-hanken-grotesk font-[500] md:text-[16px] md:leading-[24px] sm:text-[10px] sm:leading-[16px] text-[16px] leading-[14px]' >{t("editSavingsHabitMsg")}</p>
                  </div>
                }
                {(savingsHabitObj && savingsHabitObj.habitType === 'Amount') &&
                  <>

                    <div className='w-full h-auto  md:pt-16 sm:pt-13 pt-12'>
                      <CustomTextInput name={t("amount")}
                        onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); } }}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          const formattedAmount=handleAmountInputLimitTo2decimal(inputValue)
                          setSavingsHabitObj({ ...savingsHabitObj, 'amount': formattedAmount.toString().replace('$', '') })
                          if (!formattedAmount) setAmountError('Enter Amount');
                          else if (inputValue <= 0) {
                            setAmountError('Please enter amount greater than 0')
                          }
                          else setAmountError('')
                        }}
                        value={savingsHabitObj.amount ? `$${savingsHabitObj.amount}` : 0}
                        error={amountError}
                        placeholder={"$ 0"}
                        inputWidth='w-full'
                        autoFocus={true}
                        onBlur={(event) => handleOnBlur(event)}

                      />
                    </div>

                    <div className='w-full h-auto  md:pt-16 sm:pt-13 pt-12'>
                      <CustomRadioButton title={t('frequency')}
                        options={frequencyArray} frequency={savingsHabitObj.frequencyType}
                        onSelect={setFrequencyFun} error={frequencyError}
                        isTextBelow={false} />
                    </div>

                    {showDatePicker && 
                    <div className='w-full h-auto  md:pt-16 sm:pt-13 pt-12'>
                      <CustomDatePicker
                        selectedDate={(savingsHabitObj.startDate)}
                        onDateChange={(date) => onDateChange(date)}
                        minDate={new Date(new Date().toUTCString().substr(0, 25))}
                        //maxDate={''}
                        disabledDates={[]}
                        //IsDefaultPlaceholder={true}
                        title="start"
                        hrbg='bg-dropdown-sky'
                      />
                      <div className='my-2 w-full'>
                        {dateError && displayErrorMessage(dateError)}
                      </div>
                    </div>}
                  </>
                }
                {/* <span className='lg:text-[16px] md:text-[15px]
                 sm:text-[14px] text-[15px] font-[500] font-hanken-grotesk leading-[24px mt-2 mb-4'>
                  This percent will be transferred from each new incoming spending deposit.</span> */}

                <p className='font-hanken-grotesk font-[600] pt-10 sm:font-[700] lg:text-[18px] 
                md:text-[17px] sm:text-[15px] text-[16px]
                 leading-[26px]  sm:leading-[13px]  
                 md:leading-[18px] text-secondary-2 cursor-pointer capitalize'
                  onClick={debouncedHandleRemoveHabit}
                  tabIndex={0}
                  onKeyDown={(e) => { if (e.key === "Enter") debouncedHandleRemoveHabit() }}
                >{t("remove_habit")}</p>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER */}
        {cardFooter()}

        {/* ---------- */}
      </>
    )
  }
  const ManageSettingsbuttonLabels = [
    { label: 'SpendSense Alerts', link: '/Climb/ManageSpendAlerts' },
    { label: 'Covered by Kudzu', link: isCoveredByKudzuOptedIn ? '/Climb/ManageCoveredByKudzu' : '/Climb/CoveredByKudzu' },
    { label: 'Savings Habits', link: '/Climb/ManageSavingsHabit' },
  ];
  const navigateClicked = (item) => {
    navigate(item.link)
  }

  return (
    <HomeLayout>
      <ClimbManageSettingsSidebar indexBtn={3} ManageSettingsbuttonLabels={ManageSettingsbuttonLabels} headingMessage={false} count={SavingHabitCount} navigateClicked={navigateClicked} />
      <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight '>
        <DynamicCard
          header={true}
          CardRadius={true}
          cardWidth={width.homeCard}
          HeaderRadius={true}
          footer={true}
          HeaderContent={false} isWhite={true}
          backgroundColor={'#FFFFFF'} >
          {children()}
        </DynamicCard>
      </div>
      {isSuccessfullUpdateModalOpen &&
        <Modal
          isOpen={isSuccessfullUpdateModalOpen}
          onClose={closeSuccessfullUpdateModal}
          isClimbg={true}
          alignItems={'items-center'}
          title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('success')}</p>}
          subtitle={
            <p>
              <span className={`${HeadingStyles} visible sm:hidden`}>{t('success')}</span><span className={SubHeadingStyles}>Successfully updated</span>
            </p>}

          content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
            <Success btn1={t("back_to_climb")} btn1Function={handleBackToClimb} btn2={t("back_to_savings_habit")} btn2Function={handleBackToSavingsHabit} /></div>}

        />
      }
      {isRemoveModalOpen && <Modal
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        alignItems={'items-center'}
        isClimbg={true}
        title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('just_checking')}</p>}
        subtitle={
          <p>
            <span className={`${HeadingStyles} visible sm:hidden`}>{t('just_checking')}</span><span className={SubHeadingStyles}>{t('removal_modal_heading')}</span>
          </p>}
        content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
          <Success content1={t("removal_modal_content1")} btn1={t("remove_savings_habit")} btn1Function={handleRemoveSavingsHabitClick} btn2={t("cancel")} btn2Function={closeRemoveModal} icon={removeIcon} /></div>}

      />}
      {isSuccessfullRemoveModalOpen &&
        <Modal
          isOpen={isSuccessfullRemoveModalOpen}
          onClose={closeSuccessfullRemoveModal}
          alignItems={'items-center'}
          isClimbg={true}
          title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('done_exclamation')}</p>}
          subtitle={
            <p>
              <span className={`${HeadingStyles} visible sm:hidden`}>{t('done_exclamation')}</span><span className={SubHeadingStyles}>{t('edit_savings_habit_done_heading')}</span>
            </p>}

          content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
            <Success content1={t("edit_savings_habit_done_content")} btn1={t("back_to_climb")} btn1Function={handleBackToClimb} btn2={t("back_to_savings_habit")} btn2Function={handleBackToSavingsHabit} icon={savingsHabitSuccess} /></div>}

        />
      }
      {isErrorModalOpen &&
        <Modal
          isOpen={isErrorModalOpen}
          onClose={closeErrorModal}
          alignItems={'items-center'}
          isClimbg={true}
          title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('oops_exclamation')}</p>}
          subtitle={
            <p>
              <span className={`${HeadingStyles} visible sm:hidden`}>{t('oops_exclamation')}</span><span className={SubHeadingStyles}>{error}</span>
            </p>}

          content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6"><Error btn1={t("try_again")} btn1Function={closeErrorModal} btn2={t("cancel")} btn2Function={closeErrorModal} /></div>}
        />
      }
      {isLoading && <Loader />}
    </HomeLayout >
  )
}

export default withOidcSecure(EditSavingsHabit)



