import React, { useState, useEffect } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { width } from "../../../CustomComponents/DynamicCard/DynamicCardStyles";
import { useTranslation } from 'react-i18next';
import HomeLayout from '../../../Layout/HomeLayout';
import DynamicCard from '../../../CustomComponents/DynamicCard';
import HelpIcon from '../../../../assets/images/illo-help (1).png';
import LineBreaks, { ClimbManageSettingsSidebar, displayErrorMessage, generateClimbSettingsData, retrieveData } from '../../../../utils';
import Loader from '../../../CustomComponents/Loader';
import { useDispatch, useSelector, connect } from 'react-redux';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from "../../../../network/NetworkUtils";
import { AGREEMENTS, CLIMB_OPT_IN_OUT, COVERED_BY_KUDZU, GET_CLIMB_FEATURES, SAVINGS_HABIT, SAVING_CARD_CODE, SPENDING_ALERT, STATUS_SUCCESS_CODE } from "../../../../network/URLConstants";
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import Modal from '../../../CustomComponents/Modal';
import SpendAlertsPause from "../../../../assets/images/illo-pause.png";
import SpendAlertsPauseSuccess from '../../../../assets/images/illo-pause-done.png';
import RadialFill from '../../../../assets/images/Checkbox-blue.png'
import RadialNull from '../../../../assets/images/Checkbox-light.png'
import { useLocation, useNavigate } from 'react-router-dom';

const ClimbSettings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessToken = useSelector(state => state.accessToken);
    const [isClimbings, setisClimbings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;
    const [selectedItem, setSelectedItem] = useState({})
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [selectedOptin, setSelectedOptin] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [highlightMessage, setHighlightMessage] = useState('')
    const [strAlert, setStrAlert] = useState('')
    const [strMessage, setStrMessage] = useState('')
    const [strYesBtnText, setStrYesBtnText] = useState('')
    const [strCancelBtnText, setCancelBtnText] = useState('')
    const [modalHeading, setmodalHeading] = useState('');
    const [image, setImage] = useState('')
    const [alertContentText, setAlertContentText] = useState('')
    const [alertButtonText, setAlertButtonText] = useState('')
    const [isMobile, setIsMobile] = useState(true)
    const climbFeatures = useSelector(state => state.climbFeatures);
    const enabledClimbings = climbFeatures?.filter((response) => response.enabled === true);
    const isCoveredByKudzuOptedIn = enabledClimbings?.some(item => item.code === 'CoveredByKudzu' && item.optedIn)
    const [showModel, setShowModel] = useState(false);
    const [modelImage, setModelImage] = useState('')
    const userId = useSelector(state => state.userId);
    const [loading, setLoading] = useState(false)
    const [modalBg, setModalBg]=useState('')
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none text-black";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const SubHeadingStyles2 = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-2 pl-1 pr-1 mb-4";
    const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue lg:text-[16px] md:text-[15px] text-[14px] placeholder:font-semibold font-medium focus:outline-none w-full h-[60px]";
    // const SubHeadingStyles = "w-[90%] md:w-full font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-3";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [isSubComponent, setIsSubComponent] = useState(false);
    const savingsCardExist = cards?.some(item => item.AccountTypeName === "Savings")
    const savingsWithBalanceOver50 = cards.find(
        obj => obj.AccountTypeBit63 == 32 && obj.Balance >= 50
      );
    const [isCoveredByKudzuIneligibleSetupSavings, setIsCoveredByKudzuIneligibleSetupSavings] = useState(false);
    const [isCoveredByKudzuIneligibleSetupSavingsPostPopup, setIsCoveredByKudzuIneligibleSetupSavingsPostPopup] = useState(false);
    const [isCoveredByKudzuIneligible, setIsCoveredByKudzuIneligible] = useState(false);

    const navigate = useNavigate();

    const HeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3";

    const largeScreenMediaQuery = window.matchMedia('(min-width: 768px)');
    useEffect(() => {
        setIsMobile(largeScreenMediaQuery.matches); // Execute immediately if the screen size is already at or above 'sm'

    }, [])

    const [disclosureAgrmt, setDisclosureAgrmt] = useState(false);
    const [infoMessage, setinfoMessage] = useState('');
    const [showPolicys, setShowPolicys] = useState('terms');
    const [renderModel, setRenderModel] = useState('');
    const [policyURL, setPolicyURL] = useState('');
    const [showAgreementsModel, setShowAgreementsModel] = useState(false);
    const [isCoveredByKudzuDiscloserOpen, setIsCoveredByKudzuDiscloserOpen] = useState(false);



    const getAgreementsBasedOnType = async (url) => {
        if (isPrimrayKey == undefined || isPrimrayKey == null) {
            return
        }
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        setIsLoading(true)

        // let finalUrl = (PROGRAM_CONTENT + "?accountType=64&language=en&contentType=" + url + "&programKey=200")
        let finalUrl = AGREEMENTS.replace('{contentType}', url).replace('{cardKey}', isPrimrayKey)
        var response = await GetRequest(finalUrl, headers)
        var responseObj = JSON.parse(response)
        setIsLoading(false)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setRenderModel(responseObj.Response.RenderModel);
            setPolicyURL(responseObj.Response.Content);
            setShowAgreementsModel(true);
        } else {
            setTimeout(() => {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                // setError(displayText)
            }, 500);
        }
    }


    const handleClick = (type) => {
        setIsCoveredByKudzuDiscloserOpen(false);
        setShowPolicys((prevState) => {
            if (type === 'userAgreement') {
                LinkClick("user agreement");
            }
        });

    };

    const LinkClick = (href) => {
        // e.preventDefault();
        // e.stopPropagation();

        switch (href) {
            case "user agreement":
                getAgreementsBasedOnType("CoveredByKudzuTerms")
                // setShowAgreementsModel(true);
                break;

            default:
                break;
        }
    };

    const handleClimbFeatures = async () => {
        if (isPrimrayKey == undefined || isPrimrayKey == null) {
            return
        }
        //  debugger;
        setIsLoading(true);
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const url = GET_CLIMB_FEATURES.replace('{CARD_KEY}', isPrimrayKey)
        const response = await GetRequest(url, headers);
        const responseJson = JSON.parse(response);
        if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
            //   debugger;
            const enabledClimbings = responseJson.Response.filter(
                (response) => response.enabled === true

            );
            setisClimbings(enabledClimbings);
            dispatch({ type: 'UPDATE_CLIMB_FEATURE', payload: { climbFeatures: responseJson.Response } })
            setIsLoading(false);
        } else {
            return [];
        }

    }

    useEffect(() => {
        handleClimbFeatures();
    }, [])

    // debugger;

    const SettingsData = isClimbings.map(item => generateClimbSettingsData(item));

    const ManageSettingsbuttonLabels = [
        { label: 'SpendSense Alerts', link: '/Climb/ManageSpendAlerts' },
        { label: 'Covered by Kudzu', link: isCoveredByKudzuOptedIn ? '/Climb/ManageCoveredByKudzu' : '/Climb/CoveredByKudzu' },
        { label: 'Savings Habits', link: '/Climb/ManageSavingsHabit' },
    ];

    // Opt In modal
    const [isOptInModalOpen, setIsOptInModalOpen] = useState(false);

    const openOptInModal = () => {
        setIsOptInModalOpen(true);
    };

    const closeOptInModal = () => {
        // alert('fasle the optin modal');
        handleClimbFeatures();
        setIsOptInModalOpen(false)
    };

    // Opt out modal
    const [isOptOutModalOpen, setIsOptOutModalOpen] = useState(false);

    const openOptOutModal = () => {
        setIsOptOutModalOpen(true);
    };

    const closeOptOutModal = async () => {
        if (alertContentText == t('you_can_opt_back_in_covered_by_kudzu')) { setIsOptOutModalOpen(false); await handleClimbFeatures(); let coveredByKudzuDate = SettingsData[1]; toggleOnOrOff(coveredByKudzuDate.code, coveredByKudzuDate.optinStatus) }
        else {
            handleClimbFeatures();
            setIsOptOutModalOpen(false)
        }
    };


    const handleToggleButton = (index, item, optIn) => {
        // debugger;
        setSelectedIndex(index)
        setSelectedItem(item)
        setSelectedOptin(optIn)
        if (optIn) {
            if (item == SPENDING_ALERT) {
                openOptInModal();
                setmodalHeading(t('please_confirm_pause_spending_alert'));
                setAlertContentText(t('pausing_will_stop_spendsense'))
                setAlertButtonText(t('pause_sependsense_alert'))
            } else if (item == SAVINGS_HABIT) {
                openOptInModal();
                setmodalHeading(t('need_to_press_pause_savings_habit'));
                setAlertContentText(t('pausing_will_stop_these_transfers_savings_habit'))
                setAlertButtonText(t('pause_savings_habit'))
            } else {
                openOptInModal();
                setmodalHeading(t('CoverdByKudzuPauseMessage'));
                setAlertContentText(t('opting_out_of_covered_by_kudzu'))
                setAlertButtonText(t('pause_covered_by_kudzu'))
            }
        } else {
            if (item == SPENDING_ALERT) {
                toggleOnOrOff(item, optIn)
            } else if (item == SAVINGS_HABIT && !savingsCardExist) {
                closeOptInModal()
                setIsCoveredByKudzuIneligibleSetupSavings(true);
              //  showAlertModal(true, t('sorry'), t("it_looks_like_your_not_eligible_right_now"), LineBreaks(t("you_need_savings_account")), true, true, t('enroll_in_savings_account'), t('back_to_climb'), true, require('../../../../assets/images/covered_by_kudzu_ineligible.png'), '', false, true)
            } else if (item == SAVINGS_HABIT && savingsCardExist) {
                toggleOnOrOff(item, optIn)
            }
            else if (item == COVERED_BY_KUDZU) {
                if (!savingsCardExist) {
                    closeOptInModal()
                    setIsCoveredByKudzuIneligibleSetupSavings(true);
                  //  showAlertModal(true, t('sorry'), t("it_looks_like_your_not_eligible_right_now"), LineBreaks(t("you_need_savings_account")), true, true, t('enroll_in_savings_account'), t('back_to_climb'), true, require('../../../../assets/images/covered_by_kudzu_ineligible.png'), '', false, true)
                }else{
                    setIsCoveredByKudzuDiscloserOpen(true);
                }
               

            }
        }

    }

    ////call the apis based on the selections
    const toggleOnOrOff = async (item, optIn) => {
        // debugger;
        setIsLoading(true)
        var url = "";
        if (!optIn) {
            url = CLIMB_OPT_IN_OUT.replace('{CARD_KEY}', isPrimrayKey) + item + '/opt-in'
        } else {
            url = CLIMB_OPT_IN_OUT.replace('{CARD_KEY}', isPrimrayKey) + item + '/opt-out'
        }
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const response = await PostRequest(url, {}, headers);
        const responseObj = JSON.parse(response)
        // debugger;
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setIsLoading(false)
            if (item == COVERED_BY_KUDZU) {
                if (optIn) {
                    closeOptInModal();
                    openOptOutModal();
                    setmodalHeading(t('covered_by_kudzu_paused'));
                    // setAlertContentText(t('you_can_opt_back_in_covered_by_kudzu'))
                    setAlertContentText(t('opt_out_of_covered_by_kudzu_msg'))
                    setAlertButtonText(t('back_to_climb'))
                } else {
                    handleClimbFeatures();
                }
            } else if (item == SAVINGS_HABIT) {
                if (optIn) {
                    closeOptInModal();
                    openOptOutModal();
                    setmodalHeading(t('your_savings_habits_have_been_paused'));
                    setAlertContentText(t('you_can_resume_savings'))
                    setAlertButtonText(t('back_to_climb'))
                } else {
                    handleClimbFeatures();
                }
            } if (item == SPENDING_ALERT) {
                if (optIn) {
                    closeOptInModal();
                    openOptOutModal();
                    setmodalHeading(t('your_spendsense_alerts_have_been_paused'));
                    setAlertContentText(t('you_can_resume_spendsense'))
                    setAlertButtonText(t('back_to_climb'))
                } else {
                    handleClimbFeatures();
                }
            }

        }
    }

    const coveredByKudzuOptIn = async (item) => {
        if (cards == null || cards == undefined) {
            return
        }

        setIsLoading(true);
        const url = CLIMB_OPT_IN_OUT.replace('{CARD_KEY}', cards[0].CardKey) + 'CoveredByKudzu' + '/opt-in'
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const response = await PostRequest(url, {}, headers)

        setTimeout(() => {
            setIsLoading(false)
        }, 500);

        const responseObj = JSON.parse(response);

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setIsCoveredByKudzuDiscloserOpen(false);
            // setIsCoveredByKudzuOptInSuccess(true);
            showAlertModal(true, t('congrats'), t("u_r_now_cbk"), t("covered_by_kudzu_content2"), true, false, t('back_to_climb'),'', true, require('../../../../assets/images/covered_by_kudzu_success.png'), 'bg-[#E9EDF6]')

        } else {
            setIsCoveredByKudzuDiscloserOpen(false);
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("covered_by_cudzu"), displayText, '', true, false, t("home"), '', true, require('../../../../assets/images/warning.png'), false, false)
        }

    }

    const Headingcom = () => {
        return (
            <div className="relative">
                <div className={`flex flex-col justify-start my-16 sm:my-6 md:my-10 mx-8`}>

                    <p className='font-hanken-grotesk font-[500] 
                        text-[16px] leading-[24px] sm:font-[600] 
                        sm:text-[15px] sm:leading-[23px] md:font-[500] 
                        md:text-[26px] md:leading-[36px]'>{t("Climb_sttings")}</p>



                </div>

            </div>

        )
    }

    const onAcptBtnClick = async () => {
        if (btn1Text == t('enroll_in_savings_account')) { navigate('/SetupSavingsAccount'); closeModel() }
        if (btn1Text == t('back_to_climb')) { navigate('/Climb/ClimbMenu'); closeModel() }
    }
    const onCancelBtnClick = () => {
        if (btn2Text == t('back_to_climb')) { navigate('/Climb/ClimbMenu'); closeModel() }

    }
    const closeModel = () => {
        setIsCoveredByKudzuDiscloserOpen(false);
        setShowModel(false);
    }

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, modalBg) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setModalBg(modalBg)
    }


    const closeSkipModal = () => {
        navigate('/home');
      };
    
      const closeCustomAlertModal = () => {
        setShowModel(false);
        // if (highlightMsg == t('covered_by_cudzu')) { 
        navigate('/Climb/ClimbMenu');
        //  }
    }
    const clickedItem = (item) => {
    
        if (item.link.toLowerCase() == '/climb/managesavingshabit') {
           
            if (!savingsCardExist && item.label == "Savings Habits") {
                closeOptInModal()
                setIsCoveredByKudzuIneligibleSetupSavings(true);
              //  showAlertModal(true, t('sorry'), t("it_looks_like_your_not_eligible_right_now"), LineBreaks(t("you_need_savings_account")), true, true, t('enroll_in_savings_account'), t('back_to_climb'), true, require('../../../../assets/images/covered_by_kudzu_ineligible.png'), '', false, true)
            }else if (!savingsCardExist && item.label == "Covered by Kudzu") {
                closeOptInModal()
                setIsCoveredByKudzuIneligibleSetupSavings(true);
              //  showAlertModal(true, t('sorry'), t("it_looks_like_your_not_eligible_right_now"), LineBreaks(t("you_need_savings_account")), true, true, t('enroll_in_savings_account'), t('back_to_climb'), true, require('../../../../assets/images/covered_by_kudzu_ineligible.png'), '', false, true)
            }
             else if (savingsCardExist && savingsWithBalanceOver50 == undefined && item.label == "Covered by Kudzu") {
                closeOptInModal()
                setIsCoveredByKudzuIneligible(true);
            }else{
                navigate(item.link);
            }
        }else{
            navigate(item.link);
        }
       
    }
    return (
        <>
            <HomeLayout>
                <ClimbManageSettingsSidebar indexBtn={0} ManageSettingsbuttonLabels={ManageSettingsbuttonLabels} headingMessage={true} navigateClicked={clickedItem} />

                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        footer={true}
                        HeaderRadius={true}
                        backgroundColor={!isMobile ? '#fffff' : 'rgba(75, 112, 185, 0.1)'}
                        // HeaderContent={
                        //     <div className='flex justify-center h-[inherit]'>
                        //         <div className='lg:w-[70%] md:w-[86%] w-full flex items-center'>
                        //             <p className='font-[500] 
                        // lg:text-[26px] md:text-[22px] 
                        // sm:text-[18px] text-[20px] leading-[36px] font-hanken-grotesk 
                        // sm:px-0 '>{t('Climb_sttings')}</p>
                        //         </div>
                        //     </div>} 
                        HeaderContent={Headingcom()}
                        isWhite={true}
                    // backgroundColor={'#E4EAF5'}
                    >

                        <>
                            <div className='w-full justify-center flex sm:mt-1'>
                                <div className='lg:w-[70%] md:w-[86%] w-full'>
                                    <hr className='border-b-[2px] sm:hidden border-[#94C3E8] border-opacity-[65%] w-full' />
                                    {SettingsData.map((settings, index) => (
                                        <div key={index} className={`md:mt-10 mt-12 flex justify-between h-[123px] ${index < 2 && 'border-b-[2px] border-[#94C3E8] border-opacity-[50%] sm:border-opacity-[75%]'}`}>
                                            <div>
                                                <p className='md:text-[20px] md:leading-[30px] sm:text[13px] sm:leading-[19px]
                                                     text-[16px] font-hanken-grotesk 
                                                    leading-[24px] font-600 md:font-[500]'>
                                                    {settings.title}
                                                </p>
                                                <div className='lg:w-[410px] sm:w-[321px] w-[240px] mt-4 md:text-[14px] 
                                                     sm:text-[10px] text-[14px] 
                                                    font-hanken-grotesk md:leading-[22px]
                                                     sm:leading-[16px] leading-[20px] font-[500] md:font-[400]'>
                                                    {settings.content}
                                                </div>
                                            </div>
                                            <div>
                                                <label class="relative inline-flex items-center cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        checked={settings.optinStatus}
                                                        onChange={(e) => handleToggleButton(index, settings.code, settings.optinStatus)}
                                                        class="sr-only peer"
                                                        onClick={(e) => handleToggleButton(index, settings.code, settings.optinStatus)}
                                                        tabIndex={0}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") handleToggleButton(index, settings.code, settings.optinStatus)
                                                        }}
                                                    />
                                                    <div class="w-[38px] h-[25px]  bg-[#4B70B9] bg-opacity-[40%]
                                     peer-focus:outline-none   
                                      rounded-full peer dark:bg-gray-700 
                                      peer-checked:after:translate-x-full
                                     peer-checked:after:border-[#2965FB] 
                                      peer-checked:after:bg-[#2965FB]
                                      peer-checked:after:left-[-6px]
                                      after:content-[''] 
                                      after:absolute after:top-[2px]
                                      after:left-[2px] after:right-0
                                      after:bg-[#fff]
                                       after:border-[#fff] after:border-[gray-300] 
                                      after:border after:rounded-full after:h-[21px] 
                                      after:w-[21px] after:transition-all
                                       dark:border-gray-600 peer-checked:bg-[#94C3E8]
                                        peer-checked:bg-opacity-[40%]"></div>

                                                    <span class="ml-3 text-sm font-medium text-gray-900
                                     dark:text-gray-300"></span>
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='w-full flex sm:justify-end justify-between
                             md:h-[160px] sm:h-[120px] h-[140px] items-center md:gap-20 sm:gap-12'>
                                <button className='lg:text-[18px] md:text-[17px] 
                                sm:text-[15px] text-[16px] font-[700]
                                 font-hanken-grotesk
                                 text-[#2965FB]' onClick={() => { navigate('/Climb/ClimbMenu') }}>
                                    {t('Back')}</button>
                                <button className='lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] font-[700]
                                 font-hanken-grotesk
                                 text-[#2965FB]'></button>
                            </div>

                        </>
                    </DynamicCard>
                </div>
            </HomeLayout>
            <CustomAlertModel
                isOpen={showModel}
                onClose={closeModel}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text}
                btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
                isSubComponent={isSubComponent}
                modalBg={modalBg}
            // subComponent={subComponent}
            />
            {isLoading && <Loader />}
            <Modal
                Fullheight={true}
                isOpen={isOptInModalOpen}
                onClose={closeOptInModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}> {alertButtonText == t('pause_covered_by_kudzu') ? 'Are you sure? ' : t('pauseSpendAlerts')}</p>}
                subtitle={
                    <p>
                        <span className={`${HeadingStyles} visible sm:hidden`}> {alertButtonText == t('pause_covered_by_kudzu') ? 'Are you sure? ' : t('pauseSpendAlerts')}</span><span className={SubHeadingStyles}>{modalHeading}</span>
                    </p>}
                // title={<p className={HeadingStyles}>
                //     {alertButtonText == t('pause_covered_by_kudzu') ? 'Are you sure?' : t('pauseSpendAlerts')}</p>}
                // subtitle={<p className={SubHeadingStyles}>{modalHeading}</p>}
                content={
                    <div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
                        <p className='lg:w-[443px] sm:w-[301px] w-[240px] font-hanken-grotesk lg:text-[20px]
                             md:text-[18px] sm:text-[14px] text-[16px] font-medium leading-[24px]
                              sm:leading-[28px] 
                              md:leading-[30px] text-secondary-3 '>{alertContentText}</p>
                        <div className=' flex flex-col justify-center items-center w-full'>
                            <img src={alertContentText == t('opting_out_of_covered_by_kudzu') ? require('../../../../assets/images/illo-covered-by-kudzu.png') : SpendAlertsPause} alt="complete" className='lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10' />
                            <div className=' flex flex-col justify-center items-center w-full'>

                                <div className=' flex flex-col justify-center items-center gap-8 w-full'>
                                    <button autoFocus class="focus:outline-none   font-hanken-grotesk lg:text-[18px] md:text-[17px] 
                                sm:text-[15px] text-[16px] font-[500] 
                                sm:font-bold leading-[24px]  sm:leading-[12px] 
                                      md:leading-[16px] bg-[#2965FB] text-white
                                      cursor-pointer border-2 px-2 py-4 border-[#2965FB] w-full" onClick={() => toggleOnOrOff(selectedItem, selectedOptin)}
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") toggleOnOrOff(selectedItem, selectedOptin)
                                        }}
                                    >{alertButtonText}</button>
                                    <p className='font-hanken-grotesk   font-[500] 
                                    sm:font-bold leading-[24px] lg:text-[18px] md:text-[17px] 
                                    sm:text-[15px] text-[16px] sm:leading-[12px] border-[#2965FB] border-[2.5px] px-2 py-4 w-full sm:border-0 text-center
                                     md:leading-[16px] text-secondary-2 capitalize
                                      cursor-pointer' onClick={closeOptInModal}
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") closeOptInModal();
                                        }}
                                    >{t('cancel')}</p>

                                </div>
                            </div>

                        </div>
                    </div>}
            />
            <Modal
                isOpen={isOptOutModalOpen}
                onClose={closeOptOutModal}
                alignItems={'items-center'}
                isClimbg={true}
                // title={<p className={HeadingStyles}>{t('done_exclamation')}</p>}
                // subtitle={<p className={SubHeadingStyles}>{modalHeading}</p>}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('done_exclamation')}</p>}
                subtitle={
                    <p>
                        <span className={`${HeadingStyles} visible sm:hidden`}> {t('done_exclamation')}</span><span className={SubHeadingStyles}>{modalHeading}</span>
                    </p>}
                content={
                    <>
                        <p className='font-hanken-grotesk mt-4 font-[400] sm:font-[500] 
                    leading-[24px] lg:text-[20px]
                    md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[22px]
                     md:leading-[28px] text-secondary-3'>{alertContentText}</p>
                        <div class="aspect-454/253">
                            <div className=' flex flex-col justify-center items-center w-full'>
                                <img className='lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10' src={alertContentText == t('opt_out_of_covered_by_kudzu_msg') ? require('../../../../assets/images/covered_by_kudzu_success.png') : SpendAlertsPauseSuccess} alt="complete" />
                                <div className=' flex flex-col justify-center items-center w-full'>
                                    <div className=' flex flex-col justify-center items-center gap-8 w-full'>
                                        <button autoFocus class="focus:outline-none   font-hanken-grotesk lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] font-[500] sm:font-bold leading-[24px]sm:leading-[12px] md:leading-[16px] bg-[#2965FB] text-white cursor-pointer border-2 px-2 py-4 border-[#2965FB] w-full" onClick={() => { navigate('/Climb/ClimbMenu') }}
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") navigate('/Climb/ClimbMenu')
                                            }}
                                        >{alertButtonText}</button>

                                        {/* {(alertContentText == t('you_can_resume_savings') || alertContentText == t('you_can_opt_back_in_covered_by_kudzu')) && <p className='font-hanken-grotesk text-[16px] font-[500] sm:font-bold leading-[24px] sm:text-[12px] sm:leading-[12px] md:text-[16px] md:leading-[16px] text-secondary-2 capitalize cursor-pointer' onClick={closeOptOutModal}
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") closeOptOutModal();
                                            }}
                                        >{alertContentText == t('you_can_opt_back_in_covered_by_kudzu') ? t('opt_back_into_covered_by_kudzu') : t('resume_savings_habit')}</p>} */}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </>}
            />

            <Modal isIframe={true}
                isOpen={showAgreementsModel}
                onClose={() => {setShowAgreementsModel(false); setIsCoveredByKudzuDiscloserOpen(true)}}
                title={false}
                content={<div className="h-[800px] w-full pt-16">
                    {(renderModel == "Link") && <iframe src={policyURL}
                        title="Embedded Website" className="w-full h-[800px] rounded-br-[4rem]"
                    />}
                    {
                        (renderModel == "HTML") && <div>{policyURL}</div>
                    }
                </div>
                }
            />

            <Modal
                isOpen={isCoveredByKudzuDiscloserOpen}
                Fullheight={true}
                onClose={closeModel}
                isClimbg={true}
                subtitle={
                    <span className={SubHeadingStyles}>{t("coveredby_kudzu_terms")}</span>
                }
                content={
                <div className="my-20">
                  
                    <div className='flex flex-row gap-2 cursor-pointer mt-8'
                    >
                        <img src={disclosureAgrmt ? RadialFill : RadialNull} alt="Fill"
                            className={`${'h-[25px] w-[25px] sm:h-[23px] sm:w-[23px]  md:h-[25px] md:w-[25px]'}`}
                            onClick={() => { setDisclosureAgrmt(!disclosureAgrmt); if (!disclosureAgrmt) { setinfoMessage('') } }}
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key) setDisclosureAgrmt(!disclosureAgrmt);
                                if (!disclosureAgrmt) {
                                    { setinfoMessage('') }
                                }
                            }
                            }
                        />
                        <p className='sm:w-[23rem] w-full font-hanken-grotesk font-[500] sm:font-[700] text-[14px] 
                            leading-[20px] sm:text-[12px] sm:leading-[20px] md:text-[16px] md:leading-[24px]
                             text-secondary-3'>{t('i_have_read_and_agree')}
                            <span onClick={() => { handleClick('userAgreement') }} className="text-[#2965FB] font-hanken-grotesk"> {t('coveredby_kudzu_terms')}</span></p>
                    </div>
                    {infoMessage &&
                        <div className='mb-2 w-4/5 self-center justify-center'>
                            {displayErrorMessage(infoMessage)}
                        </div>
                    }
                    <div className="flex justify-center text-center w-full items-center lg:mt-14 md:mt-12 mt-8">
                        <button
                            type="button"
                            className="w-full font-hanken-grotesk sm:font-bold font-[600] 
                                lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-white
                                 bg-[#2965FB] cursor-pointer flex justify-center items-center p-4 focus:outline-none  "
                            onClick={() => { if (disclosureAgrmt) { setinfoMessage(''); coveredByKudzuOptIn() } else { setinfoMessage(t('please_accept_coveredbykudzu_terms')) } }}
                            autoFocus
                        >
                            {'Activate Covered by Kudzu'}
                        </button>
                    </div>
                </div>}
            />

             {/* these modals im working on  */}
             {isCoveredByKudzuIneligibleSetupSavings &&<Modal
                isOpen={isCoveredByKudzuIneligibleSetupSavings}
                onClose={() => { closeSkipModal(); setIsCoveredByKudzuIneligibleSetupSavings(false); }}
                isClimbg={true}
                Fullheight={true}
                subtitle={<div className="">
                    <span className={SubHeadingStyles2}>{t('sorry')}, </span>
                    <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
                    <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                        {LineBreaks(t("you_need_savings_account"))}</p>
                </div>
                }
                content={<div>

                    <img src={require('../../../../assets/images/covered_by_kudzu_ineligible.png')}
                        className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                    <div className="flex flex-col justify-center items-center gap-8 w-full">
                        <button
                            type="button"
                            className=" w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => { setIsCoveredByKudzuIneligibleSetupSavings(false); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true); }}
                            autoFocus
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setIsCoveredByKudzuIneligibleSetupSavings(false);
                                    setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true);
                                    event.stopPropagation();
                                }
                            }}
                        >
                            {t('enroll_in_savings_account')}
                        </button>
                        <button
                            type="button"
                            className={`w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center p-4  sm:border-[0px]  border-[#2965FB] border-[2.5px]`}
                            onClick={() => {
                                setIsCoveredByKudzuIneligibleSetupSavings(false);
                                navigate('/Climb/ClimbMenu');
                            }}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setIsCoveredByKudzuIneligibleSetupSavings(false);
                                    navigate('/Climb/ClimbMenu');
                                    event.stopPropagation();
                                }
                            }}
                        >
                            {t('back_to_climb')}
                        </button>
                    </div>
                </div>}
            />}
           {isCoveredByKudzuIneligibleSetupSavingsPostPopup && <Modal
                bgColor={'bg-[#FEFAEE]'}
                isOpen={isCoveredByKudzuIneligibleSetupSavingsPostPopup}
                onClose={() => { closeSkipModal(); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false); }}
                isClimbg={true}
                Fullheight={true}
                subtitle={<div className="">
                    <span className={SubHeadingStyles2}>{t('lets_get_started').replace('!', '')} </span>
                    <span className={SubHeadingStyles}>{t("with_creating_a_new_account")}</span>
                    <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                        {t("set_aside_money_for_things_that_matter")}</p>
                </div>
                }
                content={<div>

                    <img src={require('../../../../assets/images/kudzu_piggy_bank.png')} className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                    <div className="flex flex-col justify-center items-center gap-8 w-full">
                        <button
                            type="button"
                            className=" w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => {
                                setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false); dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('came_from_climb') } });
                                navigate('/SetupSavingsAccount');
                            }}
                            autoFocus
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false);
                                    dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('came_from_climb') } });
                                    navigate('/SetupSavingsAccount');
                                    event.stopPropagation();
                                }
                            }}
                        >
                            {t('continue')}
                        </button>
                    </div>
                </div>}
            />}
            {isCoveredByKudzuIneligible && <Modal
                isOpen={isCoveredByKudzuIneligible}
                onClose={closeCustomAlertModal}
                isClimbg={true}
                Fullheight={true}
                subtitle={<div className="">
                    <span className={SubHeadingStyles2}>{t('sorry')}, </span>
                    <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
                    <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                        {t("you_need_minimum_50")}</p>
                </div>
                }
                content={<div>

                    <img src={require('../../../../assets/images/covered_by_kudzu_ineligible.png')}
                        className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                    <div className="flex flex-col justify-center items-center gap-8 w-full">
                        <button
                            type="button"
                            className="focus:outline-none   w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => { navigate('/AccountServices/Spending/TransferMoney') }}
                            autoFocus
                        >
                            {'Add Money to Savings'}
                        </button>
                        <button
                            type="button"
                            className="w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => {
                                setIsCoveredByKudzuIneligible(false);
                                navigate('/Climb/ClimbMenu');
                            }}>
                            {/* {'Maybe later'} */}
                            {t('back_to_climb')}
                        </button>
                    </div>
                </div>}
            />}
        </>



    )
}

export default withOidcSecure(ClimbSettings)