import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import the useLocation hook
import NavBar from './NavBar';
import Sidebar from './SideBar';
import AccountsIcon from '../../../assets/images/accounts.png';
import BreadCrumbs from '../../CustomComponents/BreadCrumbs';
import Footer from '../Footer';
import { CAN_CREATE_SAVINGS_ACC, GET_NOTIFICATIONS, STATUS_SUCCESS_CODE } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import { retrieveData } from '../../../utils';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useTranslation } from 'react-i18next';
import Loader from '../../CustomComponents/Loader';
import { useDispatch, useSelector } from 'react-redux';
import beneficiarysaving from '../../../assets/images/beneficiarysaving.png';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const HomeLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const isAcceptAgreement = useSelector(state => state.isAcceptAgreement)


  const [isLoading, setIsLoading] = useState(false);
  const alertsObj = useSelector(state => state.userAlerts);
  const notificationUnreadCount = useSelector(state => state.notificationUnreadCount);
  const { accessToken } = useOidcAccessToken()

  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);

  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [modelImage, setModelImage] = useState('')
  const [savingsTheme, setSavingsTheme] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [isSubFooter, setIsSubFooter] = useState(false);


  const cards = useSelector(state => JSON.parse(state.userCards));
  const accessTokenToApi = useSelector(state => state.accessToken);


  const fetchNotifications = async (isRead, isUnread) => {
    if (cards == null || cards == undefined) {
      return
    }
    try {
      setIsLoading(true);

      const deviceId = await retrieveData('updatedDeviceId')
      const cardKey = cards !== null ? cards[0].CardKey : 0;  //checking cardkey
      const rowsPerPage = 4;
      const page = 1;

      const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
      const url = GET_NOTIFICATIONS(cardKey, isRead, isUnread, rowsPerPage, page)
      const response = await GetRequest(url, headers);
      const responseJson = await JSON.parse(response);
      setIsLoading(false);
      if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {

        setIsLoading(false);
        dispatch({ type: 'USER_ALERTS', payload: { 'userAlerts': responseJson.Response } })
        // setAlertsObj(responseJson.Response);

      }

    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    //get top 4 notifications
    fetchNotifications(false, true);
    // if(isAcceptAgreement) fetchNotifications(false, true);
  }, [notificationUnreadCount])

  const canCreateSavingsAccount = async () => {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    setIsLoading(true)

    let finalUrl = CAN_CREATE_SAVINGS_ACC(cards[0].CardKey)
    var response = await GetRequest(finalUrl, headers)
    var responseObj = JSON.parse(response)
    setIsLoading(false)
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        const canCreateNew = responseObj.Response.canCreateNew
        const eligibleDate = moment(responseObj.Response.eligibleDate).format('MM/DD/YYYY')
        if (canCreateNew) {
          enrollSavingsAccount()
          
        } else {
            showAlertModal(true, t("oh_no"), t('unable_to_open'), `${t('u_will_be_eligible')} ${eligibleDate}. `, true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false, true)
        }
    } else {
        setTimeout(() => {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oh_no"), displayText, '', true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false)

            // setError(displayText)
        }, 500);
    }
}

const subComponent = () => {
  return (
      <div className=' flex justify-start items-start'>
          <p className="font-hanken-grotesk font-semibold sm:font-[500] sm:text-[14px] md:text-[20px] text-[16px] md:leading-[30px] leading-[24px]">{t("pl_contact_customer")}<span className="text-[#2965FB] cursor-pointer" onClick={
              () => window.open("https://www.kudzumoney.com/contact-us", '_blank')}>{t("support_email")}</span>{t("for_access")}</p>
      </div>
  )
}

  const enrollSavingsAccount = () => {

    const savingsNewPayload = { savingsNewClicked: true };
    dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
    showAlertModal(true, '', t('set_aside_money_for_the_things_that_matter'), t('add_savings_account_subTitle'), true, true, t('enroll'), t('may_be_later'), true, beneficiarysaving, true, true);


  }

  const handleSidebarToggle = (isSidebarOpen) => {
    setIsSidebarOpen(isSidebarOpen);
  };

  const closeSideMenu = () => {
    setIsSidebarOpen(false);
  };

  // Get the current location
  const location = useLocation();
  const breadcrumbPaths = ['/AccountServices', '/Settings', '/Help', '/CardServices', '/PersonalInformation', '/Documents', '/Security', '/Climb', '/ClimbMenu'];

  // Check if the current path includes "/Settings/notificationConfigure"
  const isNotificationConfigurePage = location.pathname.toLowerCase().includes('/settings/notificationconfigure');
  // Check if the current path matches any of the breadcrumb paths
  const isAccountServicesPage = breadcrumbPaths.some(path => location.pathname.includes(path));

  const closeCustomAlertModal = () => {

    setShowModel(false);
  }

  const onAcptBtnClick = async () => {
    setShowModel(false)
    if (btn1Text == t("enroll")) {
      navigate('/SetupSavingsAccount')
      dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('account_services_savings_enrollment') } });
    }else if (btn1Text == t('home')) {
      navigate('/home')
    }
  }
  const onCancelBtnClick = () => {
    setShowModel(false)
    showAlertModal(true, '', t('savings_no_problem_title'), t('via_account_services_title'), true, false, t('okay_got_it'), '', true, beneficiarysaving, true, true);

  }
  //                      (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1Focus, btn2Focus)
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, savingsTheme, isBorderNone, isSubFooter) => {
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setSavingsTheme(savingsTheme)
    setisBorderNone(isBorderNone)
    setIsSubFooter(isSubFooter)

  }



  return (
    <>
      <div className="flex sticky top-0 z-40">
        {/* <div className={`flex-1 transition-all duration-1000 ${isSidebarOpen ? 'md:ml-[-80rem] ml-[-40rem]' : 'ml-0'}`}> */}
        <div className={`flex-1 transition-all duration-1000 ${isSidebarOpen ? 'ml-[-13rem]' : 'ml-0'}`}>
          {alertsObj &&
            <NavBar
              savingEnrollClicked={canCreateSavingsAccount}
              isSidebarOpen={isSidebarOpen}
              handleSidebarToggle={handleSidebarToggle}
              unreadCount={alertsObj.UnreadCount}
              ReferralLogoUri={cards[0].ReferralLogoUri}
            />
          }
        </div>
      </div>
      {/* Sidebar */}

      <div className={`flex fixed  md:w-[17rem] sm:w-[15rem] w-screen top-0 z-50 transition-all duration-1000 ${!isSidebarOpen ? ' sm:right-[-18rem] right-[-35rem] ' : 'right-0'}`}>
        {alertsObj &&
          <Sidebar closeSidebar={() => { closeSideMenu() }}
            notifications={alertsObj?.Alerts.Items} />
        }
      </div>

      {/* {(isSidebarOpen && alertsObj) && 
      <Sidebar closeSidebar={() => { closeSideMenu() }}
        notifications={alertsObj?.Alerts.Items}

        className='transition-all duration-1000 ' />} */}

      {/* Conditional Rendering of Breadcrumbs */}
      {isAccountServicesPage && !isNotificationConfigurePage && <BreadCrumbs />}

      <div className="scrollable-container-home loaded">
        <div className="overflow-x-hidden">
          <div className={`sm:px-[6%] px-0 sm:py-10 py-2 flex flex-wrap sm:flex-nowrap first-letter:px-4 pt-0 relative
         transition-all duration-1000 ${isSidebarOpen ? ' sm:right-[13rem] right-[30rem]' : 'right-0'}`}>
            {/* <div className={`sm:px-[6%] px-0 sm:py-10 py-2 flex flex-wrap sm:flex-nowrap first-letter:px-4 pt-0 relative
         transition-all duration-1000 ${isSidebarOpen ? 'md:right-[23rem] sm:right-[13rem] right-[30rem]' : 'right-0'}`}> */}
            {/* ///////All content of the page will be here//////// */}
            {children}
            {/* ///////All content of the page will be here//////// */}
          </div>
          {/* <div className={`flex-1 transition-all duration-1000 ${isSidebarOpen ? 'md:ml-[-80rem] ml-[-40rem]': 'ml-0'}`}> */}
          <div className={`flex-1 transition-all duration-1000 ${isSidebarOpen ? 'ml-[-13rem]' : 'ml-0'}`}>
            <Footer />
          </div>

        </div>
      </div>
      <CustomAlertModel isOpen={showModel}
        onClose={closeCustomAlertModal}
        image={modelImage}
        modalBg={!isSubFooter && 'bg-[#E4F1EB]'}
        btn1BorderColor={!isSubFooter && '#30BC89'}
        btn1TextColor={!isSubFooter && '#FEFAEE'}
        btn1BrExist={!isSubFooter && true}
        btn2TextColor={'#559C7E'}
        btn1BgColor={!isSubFooter && '#30BC89'}
        highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
          <span className={`${HeaderMsgStyles} ${savingsTheme ? 'text-[#0C4437]' : 'text-black'}`}> {headerMsg}</span></p>}
        // highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
        // HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
        subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
        btn1Exists={btn1Exists}
        btn2Exists={btn2Exists}
        btn1Text={btn1Text} btn2Text={btn2Text}
        btn1TypeAcpt={btn1TypeAcpt}
        verificationFailure={() => { }}
        footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
        onAcptBtnClick={onAcptBtnClick}
        onCancelBtnClick={onCancelBtnClick}
        isBorderNone={isBorderNone}
        subFooter={isSubFooter && subComponent()}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default HomeLayout;
