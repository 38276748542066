import React, { useState } from 'react';
import ToolTipArrow from '../../../assets/images/Tooltip_Arrow.png'

const CustomTooltip = ({ text, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {isHovered && (
        <div 
        className=" z-30 tooltip w-[124px]  text-[#262B3A] bg-[#EAF0F3] font-hanken-grotesk font-[700] text-[9px] leading-[13px] sm:font-[700] sm:text-[10px] sm:leading-[15px] md:font-[700] md:text-[16px] md:leading-[24px] text-left p-4 rounded-xl absolute top-[150%] left-1/2 transform -translate-x-1/2 transition-opacity duration-300 opacity-100  "
         >
          <img src={ToolTipArrow} alt="arroe" className=' absolute -top-[8px] left-0'/>
          {text}
        </div>

        
      )}
    </div>
  );
};

export default CustomTooltip;
