import React, { useState, useCallback, useEffect } from 'react'
import closeFilter from '../../../assets/images/filter_colse.png';
import AmountRangeSlider from './AmountSlider';
import DateRangeSlider from './DateSlider';
import { formatDateToMMDDYYYY, parseDateFromString, SAVINGS, SPENDING } from '../../../utils';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../Search';
import FilterIcon from '../../../assets/images/Filter.png';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import { Tabs } from '../TabsComponent';
import logFirebaseEvents from '../../LogFirebaseEvents';

const FilterSlider = ({ filterClose, handleReset, applyFilters, appliedFilters, selectedTab }) => {
    const currentPath = window.location.pathname;
    const currentDate = new Date();
    const minDate = new Date(currentDate);
    minDate.setMonth(currentDate.getMonth() - 6); // Minimum date is 6 months ago
    const maxDate = new Date(); // Maximum date is today
    const { t } = useTranslation();
    const [labelsShow, setLabelsShow] = useState(true);
    const dispatch = useDispatch();

    // const { children, onFilterClick, isEnabled, updateActiveTab, selectedTab, SearchVal, handleSubmitSearch } = props 
    // const defaultActiveTab = selectedTab != undefined ? selectedTab : children && children.length > 0 ? children[0].props.label : null;
    // const [activeTab, setActiveTab] = useState(defaultActiveTab);

    // alert(activeTab);

    //     //{
    //     "debit": true,
    //     "credit": false,
    //     "minAmnt": 221,
    //     "maxAmnt": "500+",
    //     "minDate": "04-16-2023",
    //     "maxDate": "07-29-2023"
    // }




    const [debitTransactions, setDebitTransactions] = useState((appliedFilters != undefined && appliedFilters.debit != undefined) ? appliedFilters.debit : true);
    const [creditTransactions, setCreditTransactions] = useState((appliedFilters != undefined && appliedFilters.credit != undefined) ? appliedFilters.credit : true);
    const [selectedMinAmount, setSelectedMinAmount] = useState(appliedFilters != undefined && appliedFilters.minAmnt != undefined ? appliedFilters.minAmnt : '0');
    const [selectedMaxAmount, setSelectedMaxAmount] = useState(appliedFilters != undefined && appliedFilters.maxAmnt != undefined ? appliedFilters.maxAmnt : '500');
    const [selectedMinDate, setSelectedMinDate] = useState(appliedFilters != undefined && appliedFilters.minDate != undefined && appliedFilters.minDate != '' ? parseDateFromString(appliedFilters.minDate) : minDate);
    const [selectedMaxDate, setSelectedMaxDate] = useState(appliedFilters != undefined && appliedFilters.maxDate != undefined && appliedFilters.minDate != '' ? parseDateFromString(appliedFilters.maxDate) : maxDate);

    //
    const SearchFiltervalue = useSelector(state => state.SearchFiltervalue);

    const [isInputVisible, setIsInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [showCloseIcon, setShowCloseIcon] = useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);



    const resetFilters = () => {
        debugger;
        // Reset the state variables to their initial values
        setSelectedMinAmount('0');
        setSelectedMaxAmount('500'); // Reset to initial value
        setSelectedMinDate(minDate); // Reset to minDate
        setSelectedMaxDate(maxDate); // Reset to maxDate
        setCreditTransactions(true); // Reset to initial value
        setDebitTransactions(true);
    };


    const handleSearchClick = () => {
        setIsInputVisible(!isInputVisible);
        setLabelsShow(false);
    };
    // alert(selectedTab);


    const handleClear = () => {
        setInputValue('');
        setIsInputVisible(false);
        setLabelsShow(true);
        // handleSubmitSearch('');
    };

    // const handleSearchValue = useCallback(
    //     _.debounce((value) => {
    //         setInputValue(value);
    //         // alert(value);
    //         //handleSubmitSearch(value);
    //     }, 300), // 300ms debounce time
    //     []
    // );

    const handleSearchChange = (value) => {
        global.searchValueFromGlobal = value
        setInputValue(value);
    };


    const handleArrowClick = () => {
        setShowCloseIcon(true);
    }

    const CloseIconClick = () => {
        global.searchValueFromGlobal = ""
        setInputValue('');
    }

    const applyFiltersNew = () => {
        
        if (inputValue) {
            dispatch({ type: 'SEARCH_FILTER_VALUE', payload: { SearchFiltervalue: inputValue } });
            dispatch({ type: 'SHOW_SEARCH_ON_HOME', payload: { showSearchOnHome: true } });
        }
        const obj = {
            'debit': debitTransactions,
            'credit': creditTransactions,
            'minAmnt': selectedMinAmount, 'maxAmnt': selectedMaxAmount,
            'minDate': formatDateToMMDDYYYY(selectedMinDate), 'maxDate': formatDateToMMDDYYYY(selectedMaxDate)
        }
        global.isFromApplyFilter = true;
        applyFilters(obj);
    }

    return (
        <div className="bg-[#EAF3FA] rounded-bl-card-tr
         sm:py-12 pt-12 pb-0 md:px-16 sm:px-[2.5rem]">

            <div className='flex justify-between sm:px-0 px-[2.5rem]'>

                <div className='space-x-6 flex'>

                    <span className={`${labelsShow ? 'block' : 'sm:block hidden'}

                    ${selectedTab == 'Activity' ? 'border-t-2 border-[#2965FB] text-[#2965FB]' : 'text-[#262B3A]'}

                     md:w-[60px] sm:w-[39px]  lg:text-[18px] md:text-[18px] sm:text-[11px] 
                text-[14px] font-[600] sm:font-[700] cursor-pointer  `} >
                        Activity
                    </span>

                    <span className={`${labelsShow ? 'block' : 'sm:block hidden'} 
                  
                  ${selectedTab == 'Upcoming' ? 'border-t-2 border-[#2965FB] text-[#2965FB]' : 'text-[#262B3A]'}
                    md:w-[60px] sm:w-[39px]  lg:text-[18px] md:text-[18px] sm:text-[11px] 
                text-[14px] font-[600] sm:font-[700] cursor-pointer `} >
                        Upcoming
                    </span>
                </div>

                <div className='flex justify-end mb-4 sm:gap-8 gap-3'>

                    <SearchInput
                        placeholderText="Search..."
                        isEnabled={true}
                        handleSearchClick={handleSearchClick}
                        isInputVisible={isInputVisible}
                        isTooltipVisible={isTooltipVisible}
                        CloseIconClick={CloseIconClick}
                        showCloseIcon={showCloseIcon}
                        onInputChange={handleSearchChange}
                        handleArrowClick={handleArrowClick}
                        handleClear={handleClear}
                        selectedTab={selectedTab}
                    />


                    <div className="flex space-x-1 text-[#2965FB] lg:text-[18px] md:text-[18px] sm:text-[11px] text-[14px] font-[600] sm:font-[700]  items-center">
                        <img
                            src={FilterIcon}
                            alt="Filter Icon"
                            className="lg:w-[14px] lg:h-[14px] md:w-[11px] md:h-[11px] w-[10px] h-[10px] cursor-pointer"
                        />
                        <span className="cursor-pointer">{t('FiltersBtn')}</span>
                    </div>
                </div>
            </div>

            <span>
                <img src={closeFilter} alt="close filter" className="sm:px-0 px-[2.5rem] mt-[2.8rem] float-right cursor-pointer"
                    onClick={filterClose} />
            </span>
            <p className='sm:px-0 mt-10 px-[2.5rem] font-hanken-grotesk text-[14px] font-semibold uppercase text-[#2965FB]
             leading-6'>{t('filterTransactions')}</p>
            <div className='sm:px-0 px-[2.5rem] flex justify-center items-center'>
                <div className='w-[600px] pt-10'>
                    <p className='font-hanken-grotesk text-[#262B3A] text-sm font-bold'>{t('filterBy')}</p>
                    <div className='pt-10'>
                        <div className='flex'>
                            <p className='font-hanken-grotesk text-[14px] font-medium text-[#262B3A]'>
                                {t('debitTransactions')}</p>
                            <p className='flex-grow text-right'>
                                <label class="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox"
                                        checked={debitTransactions}
                                        onChange={(e) => {setDebitTransactions(e.target.checked);if(currentPath === '/' + SPENDING){
                                            logFirebaseEvents("Spending", "Debit_Transaction_clicked", "Debit_Transaction_clicked","", "");
                                        }
                                            else if(currentPath === '/' + SAVINGS){
                                                logFirebaseEvents("Savings", "Debit_Transaction_clicked", "Debit_Transaction_clicked","", "");
                                            }}}
                                        class="sr-only peer" />


                                    <div class="w-[49px] h-[34px] bg-[#94C3E8] bg-opacity-[40%]
                                     peer-focus:outline-none   
                                      rounded-full peer dark:bg-gray-700 
                                      peer-checked:after:translate-x-full
                                     peer-checked:after:border-[#2965FB] 
                                      peer-checked:after:bg-[#2965FB]
                                      peer-checked:after:left-[-10px]
                                      after:content-[''] 
                                      after:absolute after:top-[3px]
                                      after:left-[2px] after:right-0
                                      after:bg-[#94C3E8]
                                       after:border-[#94C3E8] after:border-[gray-300] 
                                      after:border after:rounded-full after:h-[28px] 
                                      after:w-[28px] after:transition-all
                                       dark:border-gray-600 peer-checked:bg-[#94C3E8]
                                       peer-checked:bg-opacity-[40%]"></div>

                                    <span class="ml-3 text-sm font-medium text-gray-900
                                     dark:text-gray-300"></span>
                                </label>
                            </p>
                        </div>
                        <div className='flex mt-10'>
                            <p className='font-hanken-grotesk text-[14px] font-medium text-[#262B3A]'>
                                {t('creditTransactions')}</p>
                            <p className='flex-grow text-right'>
                                <label class="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox"
                                        checked={creditTransactions}
                                        onChange={(e) => {setCreditTransactions(e.target.checked);if(currentPath === '/' + SPENDING){
                                            logFirebaseEvents("Spending", "Credit_Transaction_clicked", "Credit_Transaction_clicked","", "");
                                        }
                                    else if(currentPath === '/' + SAVINGS){
                                        logFirebaseEvents("Savings", "Credit_Transaction_clicked", "Credit_Transaction_clicked","", "");
                                    }}}
                                        value="" class="sr-only peer" />
                                    <div class="w-[49px] h-[34px] bg-[#94C3E8] bg-opacity-[40%]
                                     peer-focus:outline-none   
                                      rounded-full peer dark:bg-gray-700 
                                      peer-checked:after:translate-x-full
                                     peer-checked:after:border-[#2965FB] 
                                      peer-checked:after:bg-[#2965FB]
                                      peer-checked:after:left-[-10px]
                                      after:content-[''] 
                                      after:absolute after:top-[3px]
                                      after:left-[2px] after:right-0
                                      after:bg-[#94C3E8]
                                       after:border-[#94C3E8] after:border-[gray-300] 
                                      after:border after:rounded-full after:h-[28px] 
                                      after:w-[28px] after:transition-all
                                       dark:border-gray-600 peer-checked:bg-[#94C3E8]
                                       peer-checked:bg-opacity-[40%]"></div>
                                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div className=' pt-10'>
                        <p className='font-hanken-grotesk text-[#262B3A] text-sm font-bold  pb-16'>{t('filterbyAmnt')}</p>
                        <AmountRangeSlider
                            selectedMinAmount={selectedMinAmount}
                            selectedMaxAmount={selectedMaxAmount}
                            onAmountChangeProp={(selectedMin, selectedMax) => {
                                setSelectedMinAmount(selectedMin);
                                setSelectedMaxAmount(selectedMax);
                            }}
                        />
                    </div>
                    <div className=' pt-10'>
                        <p className='font-hanken-grotesk text-[#262B3A] text-sm font-bold pb-[6rem]'>{t('filterbyTime')}</p>
                        <DateRangeSlider
                            selectedMinDate={selectedMinDate}
                            selectedMaxDate={selectedMaxDate}
                            // Pass two separate arguments to the onDateChangeProp function
                            onDateChangeProp={(minDate, maxDate) => {
                                setSelectedMinDate(minDate);
                                setSelectedMaxDate(maxDate);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div class="flex flex-row items-center space-x-12 sm:mt-12 mt-16 sm:py-0 py-10 
            sm:justify-end justify-between
             sm:bg-inherit bg-[#4B70B9] sm:px-0 px-[2.6rem] ">
                <button class="font-hanken-grotesk lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] font-bold 
                sm:text-[#262B3A] text-white cursor-pointer" onClick={filterClose}>{t('close')}</button>
                <button class="font-hanken-grotesk lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] font-bold  
                  sm:text-[#2965FB] text-white cursor-pointer"
                    onClick={() => {
                        resetFilters(); // Call the resetFilters function
                        handleReset(); // Call the handleReset function passed as a prop
                    }}>{t('reset')}</button>
                <button class="font-hanken-grotesk lg:text-[18px] md:text-[17px] 
             sm:text-[15px] text-[16px]  font-bold sm:text-[#2965FB] text-white
                 cursor-pointer" onClick={applyFiltersNew}>{t('apply')}</button>
            </div>
        </div>
    )
}

export default FilterSlider;