import React, { useState, useEffect } from 'react';
import Card from '../../../CustomComponents/Card';
import { useTranslation } from 'react-i18next';
import { height, width } from '../../../CustomComponents/Card/CardStyles';
import addLineBreaks from '../../../../utils';
import app_env from '../../../../utils/app-config.json';
import { GetApiHeadersWithBearer, GetRequest } from '../../../../network/NetworkUtils';
import { useSelector } from 'react-redux';
import { STATUS_SUCCESS_CODE, DEPOSIT_AGGREMENT } from '../../../../network/URLConstants';
import Loader from '../../../CustomComponents/Loader';
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import Leftarrow from '../../../../assets/images/leftarrow.png';
import TermsArrow from '../../../../assets/images/terms_arrow.png';
import GrenArrow from '../../../../assets/images/grrenArrow.png';
import HomeLayout from '../../../Layout/HomeLayout';
import LeftBar from '../../../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../../../CustomComponents/DynamicCard';

const appConfigData = await fetchConfigarationData();
const DepositAgreement = ({ handleClick }) => {
  const { t } = useTranslation();
  const userId = useSelector((state) => state.userId);
  const [userContent, setUserContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [policyURL, setPolicyURL] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        let headers = await GetApiHeadersWithBearer();
        var response = await GetRequest(DEPOSIT_AGGREMENT(userId, appConfigData.CLIENT_ID), headers);
        let responseObj = JSON.parse(response);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
          setPolicyURL(responseObj.Response.content)
          setIsLoading(false);
        } else {
          setTimeout(() => {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            // setError(displayText)
          }, 500);
        }
      } catch (error) {
        setIsLoading(false); // Set loading state to false in case of error as well
      }
    };

    fetchData(); // Call the API function on component load
  }, []);

  const leftBarTopText = () => {
    return (
      <div className='cursor-pointer flex items-center gap-2 pb-8'
        onClick={() => handleClick("terms")}>
        <div>
          <img src={TermsArrow} alt='leftArrow' className='sm:block hidden' />
          <img src={GrenArrow} alt='leftArrow' className='sm:hidden block' />
        </div>
        <p className='lg:text-[18px] md:text-[17px] sm:text-[15px]
     text-[14px] font-hanken-grotesk cursor-pointer sm:text-[#94C3E8] text-[#559C7E]
     sm:font-medium font-[600]'>{t('Back')}</p>
      </div>
    )
  }

  return (
    <>
    <>
        <HomeLayout>
          <LeftBar leftBarType='text-component' isPrimary={true} heading= {addLineBreaks(t('DepositAccount'))} leftBarTopText={leftBarTopText()} />
          <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>

          <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>
            <DynamicCard
              cardWidth={width.allpolicies}
              header={true}
              footer={false}
              footerContent={() => { }}
              CardRadius={true}
              HeaderRadius={true}
              isWhite={true}
              isIFrame={true}

            >
              <div className='w-full pb-16'>
                <div className={`bg-[#FFFFFF]  sm:rounded-tr-[4rem] relative z-10 w-full md:text-[14px] 
            sm:text-[13px] text-[14px] `}>
                  <iframe src={policyURL}
                    // title="ElectronicAggrement" className="w-full h-[800px]" 
                    title="Embedded Website" className={`w-full min-h-[75vh] sm:rounded-tr-[4rem]`}
                  />
                  <div className="flex justify-start sm:px-[4rem] px-[2rem] py-[2rem]">
                    <button
                      type="button"
                      className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex items-center"
                      onClick={() => handleClick("terms")}
                    >
                      <img
                        src={Leftarrow}
                        className="md:w-[17px] sm:w-[11px] md:h-[17px] sm:h-[11px] w-[12px] h-[12px] ml-2 animate__animated animate__flash animate__repeat-4 pr-1"
                        alt="Arrow" />
                      {t('back_to_terms_and_conditions')}
                    </button>

                  </div>

                </div>
              </div>

            </DynamicCard>
            {isLoading && <Loader />}
          </div>


        </HomeLayout >
      </>
      {/* <Card


        heading={
          <>
            <div className='cursor-pointer flex items-center gap-2 pb-8'
              onClick={() => handleClick("terms")}>
              <div>
                <img src={TermsArrow} alt='leftArrow' className='sm:block hidden' />
                <img src={GrenArrow} alt='leftArrow' className='sm:hidden block' />
              </div>
              <p className='lg:text-[18px] md:text-[17px] sm:text-[15px]
             text-[14px] font-hanken-grotesk cursor-pointer sm:text-[#94C3E8] text-[#559C7E]
             sm:font-medium font-[600]'>{t('Back')}</p></div>
            {addLineBreaks(t('DepositAccount'))}
          </>
        }


        cardHeight={'auto'}
        cardWidth={width.allpolicies}
        footer={false}
        header={true}
        CardRadius={true}
        footerContent={
          <div className='px-9 cursor-pointer flex items-center gap-2 absolute bottom-12'
            onClick={() => handleClick("terms")}>
            <div><img src={Leftarrow} alt='leftArrow' /></div>
            <p className='lg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px] font-hanken-grotesk cursor-pointer text-secondary-2 font-bold text-base'>{t('back_to_terms_and_conditions')}</p></div>
        }
      >
        <div className='w-full sm:pt-4 pb-[147px] py-10'>
          <iframe src={policyURL}
            title="Deposit Account" className="w-full h-[800px]"
          />
        </div>
        {isLoading && <Loader />}
      </Card> */}
    </>
  );
};

export default DepositAgreement;
