import React, { useEffect, useRef, useState } from 'react'
import { width, height } from "../../CustomComponents/Card/CardStyles";
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import right_dropdown_arrow from '../../../assets/images/right_dropdown_arrow.png'
import CustomTextInput from '../../CustomComponents/CustomtextInput';
import Loader from '../../CustomComponents/Loader';
import Modal from '../../CustomComponents/Modal';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { useNavigate } from 'react-router-dom';
import { fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc';
import Footer from '../../Layout/Footer';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { useKeyDown } from '../../../hooks/useKeyDown';
import statement_page from '../../../assets/images/statement_page.png'
import shredding_statement from '../../../assets/images/shredding_statement.png'
import Bell from '../../../assets/images/Bell.png'
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import arrow from '../../../assets/images/Caret_S.png'
import HtmlDropDown from '../../CustomComponents/HtmlDropDown';
import { AGREEMENTS, STATUS_SUCCESS_CODE } from '../../../network/URLConstants';
import HtmlNavigation from '../../CustomComponents/HtmlNavigation';
const appConfigData = await fetchConfigarationData();



const PrivacyAndLegal = ({ transactionDatas, onViewAllClick, viewAll }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const dispatch = useDispatch();
    const userId = useSelector(state => state.userId);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const selectedItem = cards?.[0]
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [type, setType] = useState('')
    const [footerMsg, setFooterMsg] = useState('');
    const [displayContent, setDisplayContent] = useState({});
    const [accountType, setAccountType] = useState({});
    const [agreementType, setAgreementType] = useState([
        { name: 'Electronic Communications Disclosure and Agreement', url: 'ESign', accType: 0, CardKey: cards?.[0]?.CardKey },
        { name: 'Bancorp Privacy Notice', url: 'PrivacyPolicyV2', accType: 64, CardKey: cards?.[0]?.CardKey },
        { name: 'Kudzu User Agreement', url: 'TnC', accType: 0, CardKey: cards?.[0]?.CardKey },
        { name: 'Kudzu Privacy Policy', url: 'PPrivacyPolicy', accType: 0, CardKey: cards?.[0]?.CardKey },
        { name: 'Demand Deposit Account Agreement', url: 'DepositTerms', accType: 0, CardKey: cards?.[0]?.CardKey },
        { name: 'Covered by Kudzu Terms and Conditions', url: 'CoveredByKudzuTerms', accType: 64, CardKey: cards?.[0]?.CardKey },
        { name: 'Fees', url: 'FeeMastersV2', accType: 0, CardKey: cards?.[0]?.CardKey },
        { name: 'Kudzu Alerts Terms Of Servies', url: 'SMSTerms', accType: 0, CardKey: cards?.[0]?.CardKey },
        { name: 'Official Sweepstakes Rules', url: 'PayPerkSweepstakesRules', accType: 64, CardKey: cards?.[0]?.CardKey },

    ]);
    const [toNavigate, setToNavigate] = useState('');
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);

    useKeyDown(() => { }, ["Enter"]);
    useEffect(() => {
        const hasSavingsAccount = cards?.some(item => item.AccountTypeName === "Savings");
        if (hasSavingsAccount) {
            agreementType.splice(5, 0, { name: 'Savings Account Agreement', url: 'TnC', accType: 32, CardKey: cards?.[1]?.CardKey } )
            // agreementType.push(...[{ name: 'Savings Account Agreement', url: 'TnC', accType: 32, CardKey: cards?.[1]?.CardKey }, { name: 'Savings Rate Information', url: 'SavingsRateInfo', accType: 32, CardKey: cards?.[0]?.CardKey }]);
            agreementType.push(...[{ name: 'Savings Rate Information', url: 'SavingsRateInfo', accType: 32, CardKey: cards?.[0]?.CardKey }]);
        }
    }, [])

    // const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
    //     setshowErrorImg(showErrorImg)
    //     setisBorderNone(isBorderNone)
    //     setShowModel(showModel)
    //     setHighlightMsg(highlightMsg)
    //     setheaderMsg(headerMsg)
    //     setSubHeaderMsg(subHeaderMsg)
    //     setBtn1Exists(btn1Exists)
    //     setBtn2Exists(btn2Exists)
    //     setBtn1Text(btn1Text)
    //     setBtn2Text(btn2Text)
    //     setBtn1TypeAcpt(btn1TypeAcpt)
    //     setModelImage(modelImage)
    // }
    // const linkClickAction = (link) => {
    //     if (link.link) {
    //         setToNavigate(link.link)
    //         showAlertModal(true, t("heads_up"), t("about_to_leave_kudzu"), t("redirected_from_kudzu"), true, true, t("continue"), t("cancel"), true, '', false, false)
    //     }
    // }
    // const closeCustomAlertModal = () => {
    //     setShowModel(false);
    // }
    // const onAcptBtnClick = async () => {
    //     if (headerMsg == t('about_to_leave_kudzu')) { window.open(toNavigate, '_blank'); closeCustomAlertModal(); setToNavigate('') }
    // }
    // const onCancelBtnClick = () => {
    //     if (headerMsg == t('about_to_leave_kudzu')) { closeCustomAlertModal(); setToNavigate('') }
    // }
    const leftBarComp = () => {
        return (
            <div className='lg:w-[258px] md:w-[207px] sm:w-[159px] w-full sm:px-0 pl-[2.5rem]'>

                <p className="text-white font-normal lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
                 md:leading-[33px] sm:leading-[30px] leading-[31px]
                 font-serif-pro sm:border-b-[2.5px] sm:border-[#4B70B9] lg:pb-14 md:pb-[50px] sm:border-opacity-[50%] border-b-0
                 sm:pb-[2.6rem] pb-[2.6rem] mb-10">{t('easily_access_policy')}</p>

                <div className='flex flex-col gap-10 cursor-pointer sm:pb-0 pb-10'>

                    {(cards != null && cards.length > 0 && cards[0].EnableStatements) &&
                        <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[90%]' onClick={() => { navigate('/Documents/Statements') }}>
                            <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] 
                    sm:text-[14px] text-[15px] leading-[20px]
                     md:font-[700] md:leading-[16px] lg:leading-[16px]
                       text-[#FEFAEE]' onClick={() => { navigate('/Documents/Statements') }}
                                tabIndex={0}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        navigate('/Documents/Statements');
                                    }
                                }}>
                                {t("statements")}</p>
                            <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                        </div>
                    }
                    <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[90%]' onClick={() => { navigate('/Documents/Policies') }}>
                        <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] sm:text-[14px]
                     text-[15px] leading-[20px] md:font-[700]
                     md:leading-[16px] lg:leading-[16px]
                       text-[#94C3E8]' onClick={() => { navigate('/Documents/Policies') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/Policies');
                                }
                            }}>
                            {t("privacy_and_legal")}</p>
                        <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div>


               
                </div>
            </div>
        )
    }
    // const headerContent = () => {
    //     return (
    //         // <div className="flex flex-row justify-start mx-10 items-center mt-10">
    //         //     <div className='ml-10'>
    //         //         {/* Left Text */}
    //         //         <p className="text-xmd ">{t("external_accounts")}</p>
    //         //     </div>
    //         // </div>
    //         <div className="flex flex-row justify-between mx-10 items-center mt-10">
    //             <div className='ml-10'>
    //                 {/* Left Text */}
    //                 <p className="text-xl "></p>
    //             </div>
    //             <div onClick={(event) => { event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); }}
    //                 className='flex flex-row justify-end items-center mr-10 '>
    //                 {/* Image */}
    //                 {/* <img
    //                     src={print_img}
    //                     // alt="Image"
    //                     className="w-5 h-5 mr-2"
    //                 /> */}

    //                 {/* Right Text */}
    //                 {/* <p className="text-18 font-bold">{t("print")}</p> */}
    //             </div>
    //         </div>
    //     )
    // }
    return (
        <>
            <HomeLayout>
                <LeftBar leftBarType='component' isPrimary={true} leftBarComp={leftBarComp()} />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>

                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        cardWidth={width.allpolicies}
                        header={false}
                        footer={false}
                        // headerContent={headerContent()}
                        footerContent={() => { }}
                        CardRadius={true}
                        HeaderRadius={true}
                        isWhite={true} >
                        <div className='w-full flex flex-col'>
                            <div className='w-full flex flex-col justify-center'>
                                <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] lg:text-[26px]
                                 md:text-[22px] sm:text-[20px] text-[20px] leading-[36px] 
                                 font-hanken-grotesk sm:mx-0 mx-4">
                                    {t("privacy_and_legal")}
                                </div>
                                <div className='flex flex-col border-t-[2.5px] mb-5 border-[#A3CEBB]
                                 sm:mx-0 mx-4'></div>

                                <div className='flex sm:w-full flex-col text-[#262B3A] md:text-[20px] 
                                sm:text-[12px]  text-[14px] my-5 font-hanken-grotesk 
                                sm:font-[500] font-[400] md:leading-[30px] sm:leading-[18px] leading-[20px] sm:mx-0 mx-4'>{t("select_a_document_below_to_view")}
                                </div>

                                <div className='pb-[5rem] sm:mx-0 mx-4'>
                                    {/* <p className='font-hanken-grotesk text-[#262B3A] 
      uppercase lg:text-[13px] md:text-[12px] 
      sm:text-[12px] text-[13px]  font-[600] tracking-[2px] md:pt-8 pt-6 
       opacity-[50%] '>{t("privacy").toLocaleUpperCase() || ''}</p> */}
                                    {agreementType.map((item, index) => (
                                        <div key={index} className='w-full flex flex-col'>
                                            {/* <HtmlDropDown
                                                ddName={item.name}
                                                type={item}
                                                linkClickAction={linkClickAction}
                                            /> */}
                                            <HtmlNavigation agreementType={item} />
                                        </div>
                                    ))}
                                    {/* <p className='font-hanken-grotesk text-[#262B3A] 
      uppercase lg:text-[13px] md:text-[12px] 
      sm:text-[12px] text-[13px]  font-[600] tracking-[2px] md:pt-8 pt-6
       opacity-[50%] '>{t("terms_and_conditions_card_heading").toLocaleUpperCase() || ''}</p> */}

                                </div>
                            </div>
                        </div>
                    </DynamicCard>
                    {loading && <Loader />}
                </div>


            </HomeLayout >
            {/* <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
            /> */}
        </>

    )
}

export default withOidcSecure(PrivacyAndLegal);
