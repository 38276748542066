import React, { useState, useEffect } from "react"; 
import Modal from "../Modal";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const RedirectModal = ({ url,isOpen }) => {
   
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    // Define the state variables isRedirectModalOpen and isLoading using useState
    const [redirectModalOpen, setredirectModalOpen] = useState(isOpen);
     
    // Define a function to close the modal
    const closeRedirectModal = () => {
        setredirectModalOpen(false);
    }

    useEffect(() => { }, [redirectModalOpen]);

    const RedirectionTo = (url) => {
        window.open(url, '_blank');
    }

    return (
        <> 
            {/* Redirect  MODAL */}
            <Modal
                isOpen={isOpen}
                onClose={closeRedirectModal}
                Redirection='./home'
                content={<div className="pt-10 pb-10">
                    <p className="font-serif-pro lg:text-xmd md:text-[21px] sm:text-[17px] text-[20px]
                     font-normal text-[#2965FB] cursor-pointer pointer-events-none">{t("heads_up")}</p>

                    <p className="font-serif-pro mt-1 lg:text-xmd md:text-[21px] sm:text-[17px] 
                    text-[20px] font-normal cursor-pointer pointer-events-none leading-[28px]">
                        {t("about_to_leave_kudzu")}</p>

                    <p className="font-hanken-grotesk py-2 lg:text-md md:text-[16px] sm:text-[15px] text-[16px]
                     font-normal cursor-pointer pointer-events-none">{t("redirected_from_kudzu")}</p>

                    <div autoFocus className="focus:outline-none   w-full flex items-center justify-center border-2 border-[#2965FB] 
                    mt-2 h-[50px] text-[#fff] cursor-pointer bg-[#2965FB] font-hanken-grotesk font-bold 
                    md:text-[16px] sm:text-[15px] text-[16px] leading-[16px]"
                        onClick={() => RedirectionTo(url)}>{t("continue")}</div>

                    <div className="w-full flex items-center justify-center border-2
                     border-[#2965FB] mt-2 h-[50px] text-[#2965FB] 
                    font-hanken-grotesk font-bold md:text-[16px] sm:text-[15px] 
                    text-[16px] leading-[16px] cursor-pointer" onClick={closeRedirectModal}>
                        {t("Cancel")}</div>
                </div>}
            />

            {/* <RedirectModal RedirectionTo={() => {
    // Add your redirection logic here
    // For example, using React Router: history.push("/home");}} /> */}

            {isLoading && <Loader />}
        </>
    );
};

export default RedirectModal;
