import React from 'react'
import alertMessage from '../../../../assets/images/alert_message.png';
import { useTranslation } from 'react-i18next';
import LineBreaks from '../../../../utils';

const ElectronicModal = ({ handleElectronicClick }) => {

  const { t } = useTranslation();

  return (
    <>
      <p className='font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] 
      md:leading-[32px] sm:leading-[30px] leading-[37px]  font-normal text-secondary-1'>
        {(t("paper_statement_heading"))}
      </p>

      <p className='my-2 md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px]'>
        {(t("paper_statement_subheading1"))} </p>

      <p className='md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] font-[500]'>
        {(t("paper_statement_subheading2"))}
      </p>

      <div className='my-[2rem] relative flex justify-center items-center'>
        <img src={alertMessage} alt='completeProfile' className=' ' />
        <div className='text-[68px] absolute font-[700] leading-[36px] ml-[-56px] mt-[-30px] top-1/2 left-1/2 transform-translate-[-50%,-50%]'>FPO</div>
      </div>
      <button autoFocus className="focus:outline-none   bg-[#2965FB] text-[16px] w-full 
  font-[600] h-[60px] border-2 border-[#2965FB] text-white"  onClick={() => handleElectronicClick(true)} >
        Go Paperless
      </button>
      <button className="bg-[#fff] my-4 text-[16px] w-full 
text-[#2965FB] font-[600] h-[60px] border-2 border-[#2965FB]"  onClick={() => handleElectronicClick(false)}>
        Pay for Paper Communications
      </button>
    </>
  )
}

export default ElectronicModal