import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../Layout/HomeLayout';
import DynamicHomeCard from '../../../CustomComponents/DynamicHomeCard';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import { GetApiHeadersWithBearer, GetRequest } from '../../../../network/NetworkUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AccountDetailsHeder, copyToClipboard, MoreServices, QuickActions, SPENDING } from '../../../../utils';
import { CHECKING, SAVINGS, GET_DDA_DETAILS, GET_DDA_DOWNLOAD_URL, STATUS_SUCCESS_CODE } from '../../../../network/URLConstants';
import { retrieveData } from '../../../../helpers/utils/Utils';
import { width } from '../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import FooterContent from '../../../CustomComponents/Footer';
import ClimbImg from '../../../../assets/images/illo-climb.png';
import beneficiarysaving from '../../../../assets/images/beneficiarysaving.png';
import LineBreaks from '../../../../utils';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';


import { useNavigate } from 'react-router-dom';
import logFirebaseEvents from '../../../LogFirebaseEvents';



const AccountServicesCheckingCard = () => {


  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkingAccountNumber, setCheckingAccountNumber] = useState('*********'); //Account Number
  const [checkingRoutingNumber, setCheckingRoutingNumber] = useState('*********');
  const [savingAccountNumber, setSavingAccountNumber] = useState('*********'); //Account Number
  const [savingRoutingNumber, setSavingRoutingNumber] = useState('*********');
  const cards = useSelector(state => JSON.parse(state.userCards));
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [accountCopied, setAccountCopied] = useState(false);
  const [routingCopied, setRoutingCopied] = useState(false);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();

  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [modelImage, setModelImage] = useState('')
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const [savingsTheme, setSavingsTheme] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);



  useEffect(() => {
    dispatch({ type: 'SELECTED_SUB_INDEX', payload: { selectedSubIndex: -1 } });
    cards?.map((item) => {
      const { AccountTypeName, CardKey } = item;
      getAccountNumberDetails(AccountTypeName, CardKey);
      return null; // Return null from the map to avoid returning a new array
    });
  }, []);


  ///more services

  const moreServicesArray = [
    { name: 'Edit Account Name', actionType: '', path: '/AccountServices/Spending/EditAccountName' }
  ];
  useEffect(() => {
    if (cards != null && cards?.length > 0 && cards?.[0]?.AllowAuthorizedUsers) {
      moreServicesArray.push({ name: t('menu_manage_authorized_users'), actionType: "", path: '/AccountServices/Spending/ManageAuthorizedUsers' })
    }
  }, [cards])


  const getAccountNumberDetails = async (cardType, cardkey) => {
    // debugger;
    if (cardkey == undefined || cardkey == null) {
      return
    }
    setLoading(true)
    let url = GET_DDA_DETAILS + cardkey;
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    var response = await GetRequest(url, headers)
    setTimeout(() => {
      setLoading(false)
    }, 500);
    var responseObj = JSON.parse(response);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      if (responseObj.Response != undefined) {
        if (cardType == CHECKING || SPENDING) {
          setCheckingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setCheckingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
        } else {
          setSavingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setSavingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
        }

      }
    } else {
      var arrMessages = responseObj.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;
    }
  }


  const accountNumberCopiedClicked = () => {
    logFirebaseEvents("Account_services", "account_number_copied", "account_number_copied","", "");
    copyToClipboard(checkingAccountNumber, setAccountCopied(true))
    setAccountCopied(true);
    setTimeout(() => {
      setAccountCopied(false);
    }, 2000);

  }
  const routingNumberCopiedClicked = () => {
    logFirebaseEvents("Account_services", "routing_number_copied", "routing_number_copied","", "");
    copyToClipboard(checkingRoutingNumber, setRoutingCopied(true))
    setRoutingCopied(true);
    setTimeout(() => {
      setRoutingCopied(false);
    }, 2000);
  }

  ///Quick Actions

  const QuickActionsButtons = [
    { name: `${t('add_fund')}`, actionType: "", path: '/AccountServices/Spending/AddFunds' },
    { name: "Transfer Money", actionType: "", path: '/AccountServices/Spending/TransferMoney' },
  ]

  //footer
  const footerContent = () => {
    return (
      < FooterContent
        highlightText={t("climb_heading_new")}
        subHeaderText={t("climb_subHeading_new")} image={ClimbImg}
        buttonEnabled={true} buttonText={t("climb_btn")}  footerBtnAction={() => { navigate('/Climb');}} borderDirection={cards?.[0]?.AllowPayPerks ? (cards?.length % 2 == 0) ? 'odd' : 'even' : (cards?.length % 2 == 0) ? 'even' : 'odd'} />
    )

  }
  const enrollSavingsFooterContent = () => {
    return (
      < FooterContent
        cardHeight={'200px'}
        backgroundColor={'#D3D3D3'}
        btnBgColor={'#30BC89'}
        btnTextColor={'#FFFFFF'}
        isSubheadingwidth={true}
        highlightTextColor={'#0C4437'}
        subHeaderTextColor={'#0C4437'}
        highlightText={t("grow_urMoney_withSavings")}
        subHeaderText={LineBreaks(t("set_aside_funds_savings"))}
        image={beneficiarysaving}
        buttonEnabled={true}
        buttonText={t("enroll")} isSpace={true}
        type='AddSavings'
        footerBtnAction={() => {
          const savingsNewPayload = { savingsNewClicked: true };
          dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
          showAlertModal(true, '', t('set_aside_money_for_the_things_that_matter'), t('add_savings_account_subTitle'), true, true, t('enroll'), t('may_be_later'), true, beneficiarysaving, true, true);
        }}
        borderDirection={cards?.[0]?.AllowPayPerks ? (cards?.length % 2 == 0) ? 'odd' : 'even' : (cards?.length % 2 == 0) ? 'even' : 'odd'}
      />
    )
  }
  const closeCustomAlertModal = () => {

    setShowModel(false);
  }

  const onAcptBtnClick = async () => {
    setShowModel(false)
    if (btn1Text == t("enroll")) {
      navigate('/SetupSavingsAccount')
      dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('account_services_savings_enrollment') } });
    }
    else if (btn1Text == t('home')) {
      navigate('/home')
    }
  }
  const onCancelBtnClick = () => {
    setShowModel(false)
    showAlertModal(true, '', t('savings_no_problem_title'), t('via_account_services_title'), true, false, t('okay_got_it'), '', true, beneficiarysaving, true, true);
  }
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, savingsTheme, isBorderNone) => {
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setSavingsTheme(savingsTheme)
    setisBorderNone(isBorderNone)

  }

  return (
    <HomeLayout>
      <HomeHeader heading="" isHeaderCaption={false} />
      <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight  '>

        <DynamicCard
          header={false}
          HeaderContent={false}
          footer={false}
          CardRadius={true}
          HeaderRadius={true}
          isWhite={true}
          cardWidth={width.homeCard}
          backgroundColor={'#FFFFFF'}
        >
          <div className=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
        lg:text-[26px] md:text-[22px] 
        sm:text-[20px] text-[20px]
            leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
            {SPENDING}
          </div>
          <AccountDetailsHeder
            AccountNo={checkingAccountNumber}
            routingNo={checkingRoutingNumber}
            accountCopiedProps={accountCopied}
            routingCopiedProps={routingCopied}
            handleCopyClick={accountNumberCopiedClicked}
            handleCopyRoutingNumberClick={routingNumberCopiedClicked}
            contentBetween={true}
            topBorder={true}
            bottomBorder={true}
            isCopyblack={true}
          />

          <QuickActions actionsArray={QuickActionsButtons}  />

          {/* /////more service */}
          <div className="border-t-[2.5px] border-[#D1E7DD] flex flex-col justify-center sm:mx-0 mx-4">
            <MoreServices actionsArray={moreServicesArray} />
          </div>


        </DynamicCard>
        {savingsCardAvailable == false ? enrollSavingsFooterContent() : footerContent()}
      </div>
      <CustomAlertModel isOpen={showModel}
        onClose={closeCustomAlertModal}
        image={modelImage}
        modalBg={'bg-[#E4F1EB]'}
        btn1BorderColor={'#30BC89'}
        btn1TextColor={'#FEFAEE'}
        btn1BrExist={true}
        btn2TextColor={'#559C7E'}
        btn1BgColor={'#30BC89'}
        highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
          <span className={`${HeaderMsgStyles} ${savingsTheme ? 'text-[#0C4437]' : 'text-black'}`}> {headerMsg}</span></p>}
        // highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
        // HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
        subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
        btn1Exists={btn1Exists}
        btn2Exists={btn2Exists}
        btn1Text={btn1Text} btn2Text={btn2Text}
        btn1TypeAcpt={btn1TypeAcpt}
        verificationFailure={() => { }}
        footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
        onAcptBtnClick={onAcptBtnClick}
        onCancelBtnClick={onCancelBtnClick}
        isBorderNone={isBorderNone}
      />
    </HomeLayout>
  )
}

export default AccountServicesCheckingCard;