import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../CustomComponents/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import { useKeyDown } from '../../../hooks/useKeyDown';
import lock_card_on from '../../../assets/images/lock_card_on.png'
import lock_card_off from '../../../assets/images/lock_card_off.png'
import kudzu_green_card from '../../../assets/images/kudzu_green_card.png'
import kudzu_blue_card from '../../../assets/images/kudzu_blue_card.png'
import { GENERATE_ACTIVATE_CARD_OTP, GENERATE_OTP_CHANGE_PIN, GENERATE_OTP_VIEW_CARD, GENERATE_UNLOCK_CARD_OTP, LOCK_CARD_API, STATUS_SUCCESS_CODE, UNLOCK_CARD_API } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, PostRequest } from '../../../network/NetworkUtils';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { retrieveData } from '../../../helpers/utils/Utils';
import CardMenu from '../CardMenu';
import Moment from 'react-moment';
import Modal from '../../CustomComponents/Modal';
import Custom2FA from '../../CustomComponents/Custom2FA';
import { LOGIN_SESSION_KEY } from '../../../Redux/reducers';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { width } from "../../CustomComponents/Card/CardStyles";
import FooterContent from '../../CustomComponents/Footer';
import logFirebaseEvents from '../../LogFirebaseEvents/index'

import { HotKeys } from 'react-hotkeys';
import TwoFactTypeSelctionModal from '../../CustomComponents/TwoFactTypeSelctionModal';
export const STATUS_CODE = {
    INA: 'INA'
};

export const SUBSTATUS_CODE = {
    ACP: 'ACP',
    LST: 'LST',
    STL: 'STL',
    SUS: 'SUS'
};
export const REPORT_LOST = 1
export const REPLACE_CARD = 2
export const REPORT_STOLEN = 7




const CardActionScreen = () => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const selectedItem = cards?.[0]
    const dispatch = useDispatch();
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [generateBtnClicked, setGenerateBtnClicked] = useState(true); //Routing Number
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none text-black";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [modelImage, setModelImage] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [btn1Focus, setBtn1Focus] = useState(false);
    const [btn2Focus, setBtn2Focus] = useState(false);
    const [UpdatedArray, setUpdatedArray] = useState(useSelector(state => JSON.parse(state.userCards)));
    const [selectedOption, setSelectedOption] = useState('')
    const location = useLocation();
    const params = location.state;
    const cardDetails = useSelector(state => state.selectedCardDetails);
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [show2faSelection, setshow2faSelection] = useState(false);
    const [otpGenerationType, setOtpGenerationType] = useState(false); 
    const accTypeValue = cards.map((ele)=>ele.AccountTypeName[0])
     


    const keyMap = {
        disableDevTools: ['f12', 'ctrl+shift+i', 'ctrl+shift+c', 'ctrl+shift+j', 'ctrl+u']
    };
    const handlers = {
        disableDevTools: (event) => {
            event.preventDefault();
        }
    };
    useKeyDown(() => { }, ["Enter"]);
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
   
    }
    useEffect(() => {

    }, [])

    console.log(cards,"cards data from store")

    const handleCardAction = (type) => {
        setSelectedOption(type)
        if (type == t('lock_card')) { 
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"Lock_Clicked", "Lock_Clicked","", "");
            showAlertModal(true, t("are_you_sure"), t("this_card_will_be_locked"), t('once_you_lock_your_card_any_new_transactions_will_be_declined'), true, true, t('lock_card'), t("cancel"), true, require('../../../assets/images/lock_green.png'), '', false, true, false, true)
        } else if (type == t('unlock_card')) { 
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"Un_Lock_Clicked", "Un_Lock_Clicked","", "");
            showAlertModal(true, t("ready_to_unlock_questionmark"), t("unlock_your_card_to_resume_normal_use"), t('once_you_unlock_your_card_all_locked'), true, true, t('unlock_card'), t("cancel"), true, require('../../../assets/images/unlock_green.png'), '', false, true, true, false)
        } else if (type == t('replace_card')) {  
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"Replace_Clicked", "Replace_Clicked","", "");
            navigate('/CardServices/CardActionScreen/ReplaceCard', { state: { item: cardDetails } })
        } else if (type == t('change_pin')) { 
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"Change_Pin_Clicked", "Change_Pin_Clicked","", "");
            if (!twoFactAuthenticationDone) { setshow2faSelection(true) }
            else {
                navigate('/CardServices/CardActionScreen/ChangePin', { state: { item: cardDetails } })
            }
        } else if (type == t('view_card_details')) {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"View_Card_Clicked", "View_Card_Clicked","", "");
            if (!twoFactAuthenticationDone) { setshow2faSelection(true) }
            else {
                navigate('/CardServices/CardActionScreen/ViewCard', { state: { item: cardDetails } })
            }
        } else if (type == t('activate_card')) {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"Activate_Card_Clicked", "Activate_Card_Clicked","", "");
            if (!twoFactAuthenticationDone) { setshow2faSelection(true) }
            else {
                navigate('/CardServices/CardActionScreen/ActivateCard', { state: { item: cardDetails } })
            }
        }
    }

    const verificationSuccess = (type, verifyResponse) => {
        if (selectedOption == t('change_pin')) { 
            navigate('/CardServices/CardActionScreen/ChangePin', { state: { item: cardDetails } })
        } else if (selectedOption == t('view_card_details')) {
            navigate('/CardServices/CardActionScreen/ViewCard', { state: { item: cardDetails } })
        } else if (selectedOption == t('activate_card')) {
            navigate('/CardServices/CardActionScreen/ActivateCard', { state: { item: cardDetails } })
        } else if (selectedOption == t('unlock_card')) {
            updateCardStatus(cards !== null ? cardDetails.CardKey : 0, verifyResponse.Response)
            // callApiToUnlockCard();
            showAlertModal(true, t("success_heading"), t("this_card_is_now_unocked"), t('you_can_use_your_card'), true, false, 'Ok', '', true, require('../../../assets/images/unlock_green_tick.png'), '', false, false, true, false)
        }

    }


    const generate2FAOtp = async (type) => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        const requestObject = {
            cardKey: cardDetails?.CardKey
        };
        if (type !== undefined || type !== null) {
            requestObject.IsEmail = (type == 'Email') ? true : false
        }

        let url

        switch (selectedOption) {

            case t('view_card_details'):
               
                url = GENERATE_OTP_VIEW_CARD
                break;
            case t('change_pin'): 
                url = GENERATE_OTP_CHANGE_PIN
                break;
            case t('activate_card'): 
                url = GENERATE_ACTIVATE_CARD_OTP
                break;
            case t('unlock_card'):
                url = GENERATE_UNLOCK_CARD_OTP.replace('{cardKey}', `${cardDetails.CardKey}`)
                break;
            default:
                url = GENERATE_OTP_VIEW_CARD
                break;
        }
        setLoading(true);
        try {
            const response = await PostRequest(url, requestObject, headers);
            setLoading(false);

            const parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode === STATUS_SUCCESS_CODE) {
                //adding sessionid to redux
                const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                //adding userid to redux
                const userIdPayload = { userId: parsedResponse.Response.userId }
                dispatch({ type: 'USER_ID', payload: userIdPayload });
                setTimeout(() => {
                    setIsOTPVerifyModalOpen(true);
                }, 1000);
            } else {
                const arrMessages = parsedResponse.Messages;
                const message = arrMessages[0];
                const displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../assets/images/warning.png'), '', false, false, true, false)

                // Handle the error message or displayText as needed
            }
        } catch (error) {
            // Handle errors from the API request
            console.error('Error fetching data:', error);
        }
    };


    const onAcptBtnClick = () => {
        if (btn1Text == t("lock_card")) { 
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"Lock_Card_Clicked", "Lock_Card_Clicked","", "");
            callApiToLockCard()
        } else if (btn1Text == t("unlock_card")) {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"Un_Lock_Card_Clicked", "Un_Lock_Card_Clicked","", "");
            if (!twoFactAuthenticationDone) { closeModel(); setshow2faSelection(true) }
            else callApiToUnlockCard()
        } else if (btn1Text == t('back_to_card_services')) {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"BackTo_Card_Service_Clicked", "BackTo_Card_Service_Clicked","", "");
            closeModel()
        } else if (btn1Text == t('home')) {
            navigate('/home')
        } else if (btn1Text == 'Ok') {
            closeModel()
        }
    }
    const closeModel = () => { 
        setShowModel(false);
    }

    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }

    const callApiToLockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails !== null ? cardDetails.CardKey : 0
        }
        PostRequest(LOCK_CARD_API, object, headers).then((response) => {

            setTimeout(() => {
                setLoading(false)
            }, 500);

            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {


                setTimeout(() => {
                    updateCardStatus(cardDetails !== null ? cardDetails.CardKey : 0, responseObj.Response)
                    showAlertModal(true, t("done_exclamation"), t("this_card_is_now_locked"), t('any_new_tx_will_be_declined'), true, false, t('back_to_card_services'), '', true, require('../../../assets/images/lock_green_tick.png'), '', false, false, true, false)

                }, 1000);

                // this.updateCardsInLocal(responseObj.Response, LOCK_CARD)

            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../assets/images/warning.png'), '', false, false, true, false)
                setTimeout(() => {
                }, 1000);

            }

        });

    }

    const updateCardStatus = (cardKey, response) => {
        const updatedCardIndex = cards?.findIndex(card => card.CardKey === cardKey);
        if (updatedCardIndex !== -1) {
            const updatedCard = {
                ...cards[updatedCardIndex],
                Status: response.Status,
                SubStatus: response.SubStatus,
                StatusCode: response.StatusCode,
                SubStatusCode: response.SubStatusCode,
                // NewCardRequestedDate: response.NewCardRequestedDate !== undefined ? response.NewCardRequestedDate : Moment().format("YYYY-MM-DD") + "T" + "00:00:00",

            };
            const updatedArray = [updatedCard, ...cards.filter(card => card.CardKey !== cardKey)]
            setUpdatedArray(updatedArray);
            dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedArray) } });
            dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: updatedCard } });

        }
    };


    const callApiToUnlockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails !== null ? cardDetails.CardKey : 0
        }

        PostRequest(UNLOCK_CARD_API, object, headers).then((response) => {

            setTimeout(() => {
                setLoading(false)
            }, 500);

            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                // setTimeout(() => {
                updateCardStatus(cardDetails !== null ? cardDetails.CardKey : 0, responseObj.Response)
                showAlertModal(true, t("success_heading"), t("this_card_is_now_unocked"), t('you_can_use_your_card'), true, true, t('home'), t('back_to_card_services'), true, require('../../../assets/images/unlock_green_tick.png'), '', false, false, true, false)
                // }, 1000);

                // this.updateCardsInLocal(responseObj.Response, UNLOCK_CARD)

            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../assets/images/warning.png'), '', false, false, true, false)
                setTimeout(() => {
                }, 1000);

            }

        });

    }
    const onCancelBtnClick = () => {
        if (btn2Text == 'Cancel') {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", "Cancel_Clicked", "Cancel_Clicked","", "");
            setShowModel(false)
        } else if (btn2Text == t('back_to_card_services')) {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", "Back_To_Services_Clicked", "Back_To_Services_Clicked","", "");
            closeModel()
        }

    }

    const footerContent = () => {
        return (
            <FooterContent highlightText={t('received_a_new_debit_card')}
                subHeaderText={t("click_below")}
                type={t("click_below")}
                image={kudzu_blue_card} buttonEnabled={true}
                isSpace={true}
                buttonText={t("activate_card")}
                isSubheadingwidth={true}
                footerBtnAction={() => { navigate('/CardServices/CardActionScreen/ActivateCard'); logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", "Activate_Card_Clicked", "Activate_Card_Clicked","", ""); }}
            />
        )
    }
    return (
        <>
            <HotKeys keyMap={keyMap} handlers={handlers}>
                <HomeLayout>
                    <CardMenu handleCardAction={handleCardAction} />
                    <div className='md:w-[150px] sm:w-[30px] w-[0px]'></div>
                    <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight '>
                        <div className='sm:block hidden'>
                            <DynamicCard
                                header={false}
                                CardRadius={true}
                                cardWidth={width.allpolicies}
                                HeaderRadius={true}
                                isWhite={true}
                                HeaderContent={false}>
                                <div className='md:py-20 sm:py-12 py-12 sm:mx-0 mx-4'>
                                    <div className='sm:flex justify-between block'>
                                        <div>
                                            <span className='font-serif-pro text-[#559C7E] 
                                   lg:text-[32px] md:text-[30px] sm:text-[22px] text-[25px]
                                    font-normal leading-[38px]'>
                                                {t('manage_your_kudzu')} </span>

                                            <p className='mt-4 sm:text-[#0C4437] font-[400px] text-[#262B3A]
                                    md:text-[20px] sm:text-[12px] text-[14px]
                                    md:leading-[30px] sm:leading-[20px] leading-[20px]
                                    lg:w-[380px] md:w-[333px] sm:w-[300px]
                                    lg:h-[252px] md:h-[175px] h-[170px] sm:font-medium font-hanken-grotesk
                                    '>{t('have_a_physical_kudzu_debit_card')}</p>
                                        </div>
                                        <div className='flex justify-center sm:pb-0 pb-20'>
                                            <img src={kudzu_green_card} alt="kudzu_green_card" className='lg:w-[120px] md:w-[100px] sm:w-[92px] 
                                    lg:h-[120px] md:h-[100px] sm:h-[92px] h-[120px] w-[120px]'/>
                                        </div>
                                    </div>
                                </div>
                            </DynamicCard>
                        </div>
                        {cardDetails.StatusCode==="INA" && cardDetails.SubStatusCode==="ACP" && footerContent()}
                    </div>
                    <Modal
                        isOpen={isOTPVerifyModalOpen}
                        onClose={closeOTPVerifyModal}
                        isLogo={false}
                        subHeaderMsg={<p className="font-hanken-grotesk modal-title md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] font-normal cursor-pointer pointer-events-none">{otpGenerationType != 'Email' && t("standard_msg_and_data_rates_may_apply")}</p>}
                        subtitle={<p className={HeaderMsgStyles}>{otpGenerationType == 'Email' ? t("we_are_sending_text").replace("a text", "an email") : t("we_are_sending_text")}</p>}
                        title={<p className={HighlightMsgStyles}> {t("great_exclamation")}</p>}
                        content={<div className="mt-10"><Custom2FA type={selectedOption} selected2FAType={otpGenerationType} onClose={closeOTPVerifyModal} verificationSuccess={verificationSuccess} verificationFailure={() => { }} /></div>}
                    />
                    {loading && <Loader />}
                </HomeLayout>
                <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false) }} onClickNext={(object) => { setOtpGenerationType(object.proc); setshow2faSelection(false); generate2FAOtp(object.proc) }} />
                {showModel &&
                    <CustomAlertModel
                        isOpen={showModel}
                        onClose={closeModel}
                        image={modelImage}
                        highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                            <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                        // highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                        // HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                        subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                        btn1Exists={btn1Exists}
                        btn2Exists={btn2Exists}
                        btn1Text={btn1Text} btn2Text={btn2Text}
                        btn1TypeAcpt={btn1TypeAcpt}
                        verificationFailure={() => { }}
                        footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                        onAcptBtnClick={onAcptBtnClick}
                        onCancelBtnClick={onCancelBtnClick}
                        showErrorImg={showErrorImg}
                        isBorderNone={isBorderNone}
                        btn1Focus={btn1Focus}
                        btn2Focus={btn2Focus}
                    />
                }
            </HotKeys>
        </>
    )
}

export default withOidcSecure(CardActionScreen);
