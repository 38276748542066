import React, { useState, useEffect } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { width } from "../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../CustomComponents/Loader';
import { UPDATE_CARDNAME, STATUS_SUCCESS_CODE, CAN_CREATE_SAVINGS_ACC, STUDENT_SIGNUP_EMAIL_ACCOUNT, GET_STUDENT_UNIVERSITY } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { addLineBreaks, displayErrorMessage, displaySuccessMessage, isEmailValid, MoreServices } from '../../../utils';
import { checkEmailExtension, fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import LineBreaks from '../../../utils';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import Check from '../../../assets/images/Check_white.png'
import beneficiarysaving from '../../../assets/images/beneficiarysaving.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomInput from '../../CustomComponents/FormInputs';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc';
import StudentEmail from './StudentEmail';
import EditStudentUniversity from './EditStudentUniversity';
import StudentUniversitySelection from './NonUniversityPartner/StudentUniversitySeclection';
import AccessCodeScreenForSettings from './PartnerdUniversity/AccessCode';
import NonEditUniversity from './NonEditStudentUniversity';
const appConfigData = await fetchConfigarationData();
const ConnectSchool = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook to access translation functions
    const cards = useSelector(state => JSON.parse(state.userCards)); 
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [universityName , setUniversityName]=useState('');
    const [universityOtherName , setUniversityOtherName]=useState('');
    const [universityGradutionDate , setUniversityGradutionDate]=useState('');
    const [sessionKeyUpdate , setSessionKeyUpdate]=useState();
    const [isPartnerUniversityStatus , setIsPartnerUniversityStatus]=useState();
    const [connectNullResponse , setConnectNullResponse]=useState(false);

    

    const [isLoading, setIsLoading] = useState('');
    const [condition, setCondition] = useState('A'); // You can change this value to 'B' or 'C' to render different components
 
   //university screens based on the condtions
   const renderComponent = () => {
    switch (condition) { 
        case 'edistudentuniversity':
            return <EditStudentUniversity EditUniversityName={universityOtherName} EditGradutionDate={universityGradutionDate} opentheStudentEmail={() => setCondition("studentEmail")}/>; 
        case 'studentEmail':
            return <StudentEmail openSelectionUniversity={() => setCondition("universityselection")} connectNullResponse={connectNullResponse}  isPartnerUniversityStatus={isPartnerUniversityStatus}  opeAccessCode={() => setCondition("accesscode")} opeEditStudent={() => setCondition("edistudentuniversity")} opeNoEditStudent={() => setCondition("nonedituniversity")} hanldeSessionvalue={UpdateSessionvalue}/>
        case 'universityselection':
            return <StudentUniversitySelection EditUniversityName={universityOtherName} EditGradutionDate={universityGradutionDate} openConnectSchoolEmail={() => setCondition("studentEmail")}/>    
        case 'accesscode':
            return <AccessCodeScreenForSettings sessionKeyUpdate={sessionKeyUpdate} openStudentEmail={() => setCondition("studentEmail")} /> 
        case 'nonedituniversity':
            return <NonEditUniversity SenduniversityName={universityName} opentheStudentEmail={() => setCondition("studentEmail")}/>   
        default:
            return ''; 
    }
  };

    ///university screens
  
    async function getUniversityDeatils() {
        debugger;
        const cardKey = cards !== null ? cards[0].CardKey : 0;
        var url = GET_STUDENT_UNIVERSITY(cardKey);
        const deviceId = await retrieveData('updatedDeviceId');
        let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setIsLoading(true)
        const response = await GetRequest(url, headers)
        // setTimeout(() => {
        //     setIsLoading(false)
        // }, 500);
        var responseObj = JSON.parse(response);

        if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) { 
            
            debugger;

            setIsLoading(false);

            if (responseObj.Response!= null) {
                 //partnerd university name or other name 
                 if( responseObj.Response.isPartnerUniversity === true){
                    setUniversityName(responseObj.Response?.otherName != "" ? responseObj.Response?.otherName : responseObj.Response?.universityName);
                    setCondition('nonedituniversity');
                    setUniversityGradutionDate('');   
                    setIsPartnerUniversityStatus(responseObj?.Response.isPartnerUniversity)
                 dispatch({ type: 'PARTNER_UNIVERSITY_STATUS', payload: { isPartnerUniversity:responseObj.Response.isPartnerUniversity} })
                 }
                 else{
                    setUniversityOtherName(responseObj.Response?.otherName != "" ? responseObj.Response?.otherName : responseObj.Response?.universityName);
                    setUniversityGradutionDate(responseObj.Response.graduationDate); 
                    setCondition('edistudentuniversity');   
                }
 
            } else {   
                // Partnered
                setCondition('studentEmail');  
                setConnectNullResponse(null)
            }  
        }
    }
    
    
    useEffect(() => {
        getUniversityDeatils();
    }, []);
 
    const UpdateSessionvalue = (sessionId) =>{
        setSessionKeyUpdate(sessionId)
    }
 
    return (
        <>
            

                {renderComponent()}
 
        </>

    )
}

export default withOidcSecure(ConnectSchool);
