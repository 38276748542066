import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { withOidcSecure } from '@axa-fr/react-oidc';
import DynamicCard from '../../../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";

import CustomDropdown from '../../../../../CustomComponents/CustomDropDown';
import CustomTextInput from '../../../../../CustomComponents/CustomtextInput';
import CustomRadioButton from '../../../../../CustomComponents/CustomRadioButton';
import RightArrow from '../../../../../../assets/images/RightArrow@2x.png'
import CustomDateInput from '../../../../../CustomComponents/CustomDateInput';
import { dateComparison, handleAmountInputLimitTo2decimal, retrieveData } from '../../../../../../utils';
import { GetApiHeadersWithBearer, GetRequest } from '../../../../../../network/NetworkUtils';
import { GET_ACCOUNTS_FOR_TRANSFER, GET_FREQUENCIES, GET_HOLIDAYS, SAVING_CARD_CODE, STATUS_SUCCESS_CODE, TINK_LINK_ENABLED_CHECK } from '../../../../../../network/URLConstants';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../../CustomComponents/Loader';
import CustomDatePicker from '../../../../../CustomComponents/CustomDateInput';
import moment from 'moment';
import { ACH } from '../../../../../../helpers/utils/Utils.js';
import { useLocation } from 'react-router-dom';
import logFirebaseEvents from '../../../../../LogFirebaseEvents/index.js';


const TransferMoneyForm = (props) => {
    const { onNextSelect, onConfirmDisplayData, onTransferFundPayload, isExternalAccountAddedSuccessfully, onSetIsExternalAccountAddedSuccessfully, openHeadsUpModal } = props
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const currentPath = window.location.pathname;
    const location = useLocation();


    const transferMoneyPayloadObj = useSelector(state => state.transferMoneyPayload);
    const accessToken = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const selectedItem = cards?.[0]
    const SavingsAccount = cards?.find(({ AccountTypeBit63 }) => AccountTypeBit63 === SAVING_CARD_CODE);

    const [isLoading, setIsLoading] = useState(false);
    const [recurringFrequencyArray, setRecurringFrequencyArray] = useState([])
    const [accounts, setAccounts] = useState([]);
    const [transferFromAccountOptions, setTransferFromAccountOptions] = useState([]);
    const [transferToAccountOptions, setTransferToAccountOptions] = useState([]);
    const [isShowContent, setIsShowContent] = useState(false);
    const [isReset, setIsReset] = useState(false);

    const [transferFromError, setTransferFromError] = useState(null);
    const [transferToError, setTransferToError] = useState(null);
    const [amountError, setAmountError] = useState(null);
    const [freqTypeError, setFreqTypeError] = useState(null);
    const [recurringFreqTypeError, setRecurringFreqTypeError] = useState(null)
    const [transferDateError, setTransferDateError] = useState(null);
    const [CalendarMinDate, setCalendarMinDate] = useState('');
    const [CalendarMaxDate, setCalendarMaxDate] = useState('');
    const [oneTimeSelectedDate, setOneTimeSelectedDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [CalendarDisabledDates, setCalendarDisabledDates] = useState([]);
    const cardDetails = useSelector(state => state.selectedCardDetails);
    const frequencyArray = [{ displayText: "Recurring", type: 'RC' }, { displayText: "One Time", type: 'OT' }]


    const selectedTransferFromAccount = transferMoneyPayloadObj?.FromAccount;
    const selectedTransferToAccount = transferMoneyPayloadObj?.ToAccount
    const enteredAmount = transferMoneyPayloadObj?.Amount;
    const frequency = transferMoneyPayloadObj?.Frequency?.displayText
    const recurringFrequencyName = transferMoneyPayloadObj?.FrequencyType?.displayText;
    const recurringFrequency = transferMoneyPayloadObj?.FrequencyType?.type;
    const selectedTransferDate = transferMoneyPayloadObj?.TransferDate
    const selectedStartDate = transferMoneyPayloadObj?.startDate
    const selectedEndDate = transferMoneyPayloadObj?.EndDate;
    const memo = transferMoneyPayloadObj?.Memo;

    const setFrequencyFun = (value) => {
        dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "Frequency", 'value': value } })
        setFreqTypeError("")
        setRecurringFreqTypeError("");
    }
    const setRecurringFrequencyFun = (value) => {
        setFreqTypeError("")
        setRecurringFreqTypeError("");
        dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "FrequencyType", 'value': value } });
        setEndDate(null)
        dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "EndDate", 'value': null } })
    }

    useEffect(() => {
        getHolidays();
    }, [])

    useEffect(() => {
        if (frequency === "One Time") { 
            dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "FrequencyType", 'value': "" } })
            setFreqTypeError("")
            setRecurringFreqTypeError("");
        }
        if (frequency === "Recurring" && recurringFrequency) { 
            setRecurringFreqTypeError("");
            setFreqTypeError("")
            setRecurringFreqTypeError("");
        }

    }, [frequency, recurringFrequency])

    useEffect(() => {
        if (selectedStartDate) setTransferDateError("");
    }, [selectedStartDate, selectedEndDate])

    ////API CALLS-----------------------------------------

    const fetchAccounts = async () => {
        if (cards == undefined || cards == null) {
            return
        }
        var cardKey = cards !== null ? cards[0].CardKey : 0
        var isSavingsCard = false
        for (var i = 0; i < cards.length; i++) {
            if (global.selectedAccountServiceCardKey == cards[i].CardKey) {
                cardKey = cards[i].CardKey
                if (cards[i].AccountTypeBit63 == SAVING_CARD_CODE) {
                    isSavingsCard = true
                }
            }
        }
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            // const cardKey = cards !== null ?  cards[0].CardKey : 0;  //checking cardkey
            //get verified user account
            const url = GET_ACCOUNTS_FOR_TRANSFER.replace('{CARD_KEY}', cardKey) + '?statusCode=ACT&ach=false'

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const response = await GetRequest(url, headers);
            const responseJson = await JSON.parse(response);
            setIsShowContent(true);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                dispatch({ type: 'TRANSFER_ACCOUNTS', payload: { transferAccounts: responseJson.Response.Accounts } });
                setIsLoading(false);
                if (!isSavingsCard) {
                    setAccounts(responseJson.Response.Accounts)
                    setTransferFromAccountOptions(responseJson.Response.Accounts)
                    setTransferToAccountOptions(responseJson.Response.Accounts)
                } else {
                    var accounts = []
                    for (var jIndex = 0; jIndex < responseJson.Response.Accounts.length; jIndex++) {
                        const account = responseJson.Response.Accounts[jIndex]
                        if (account.AccountType != ACH) {
                            accounts.push(account)
                        }
                    }
                    setAccounts(accounts)
                    setTransferFromAccountOptions(accounts)
                    setTransferToAccountOptions(accounts)

                }
                setIsShowContent(true);
            }

        } catch (error) {
            dispatch({ type: 'TRANSFER_ACCOUNTS', payload: { transferAccounts: null } });
        }
    }

    const fetchFrequency = async () => {
        if (cards == undefined || cards == null) {
            return
        }
        try {
            const url = GET_FREQUENCIES.replace("{CARD_KEY}", cards[0].CardKey) + '?featureType=TransferFund'
            const deviceId = await retrieveData('updatedDeviceId');
            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            setIsLoading(true)
            const response = await GetRequest(url, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false);
                setRecurringFrequencyArray(responseJson.Response)

            }

        } catch (error) {
        }

    }

    useEffect(() => {
        fetchAccounts();
        fetchFrequency();
    }, [])
    useEffect(() => {
        if(isExternalAccountAddedSuccessfully) fetchAccounts();
    }, [isExternalAccountAddedSuccessfully])



    // DropDown Logic -------
    const setTransferDropDownLogic = () => {

        //Transfer From logic
        const checkingCardKey = cards !== null ? cards[0].CardKey : 0;
        const savingCardKey = cards?.[1]?.CardKey || ''
        if (selectedTransferFromAccount) {
            //External account ---> checking
            if (selectedTransferFromAccount.ACHAccountInternalId !== 0) {
                const checkingAccount = accounts.filter((acc) => acc.ACHAccountInternalId === 0 && acc.AccountId === checkingCardKey)
                setTransferToAccountOptions([...checkingAccount])
            }
            // Internal Account
            else {
                //Checking ---> External+saving
                if (selectedTransferFromAccount.AccountId === checkingCardKey) {
                    const saving_externalAccount = accounts.filter((acc) => acc.AccountId !== checkingCardKey)
                    setTransferToAccountOptions([...saving_externalAccount])

                }
                //Saving ---> cheking
                else {
                    const checkingAccount = accounts.filter((acc) => acc.AccountId === checkingCardKey)
                    setTransferToAccountOptions([...checkingAccount])

                }

            }

        }
        //Transfer To logic
        if (selectedTransferToAccount) {
            //External account ---> checking
            if (selectedTransferToAccount.ACHAccountInternalId !== 0) {
                const checkingAccount = accounts.filter((acc) => acc.ACHAccountInternalId === 0 && acc.AccountId === checkingCardKey)
                setTransferFromAccountOptions([...checkingAccount])
            }
            // Internal Account
            else {
                //Checking ---> External+saving
                if (selectedTransferToAccount.AccountId === checkingCardKey) {
                    const saving_externalAccount = accounts.filter((acc) => acc.AccountId !== checkingCardKey)
                    setTransferFromAccountOptions([...saving_externalAccount])

                }
                //Saving ---> cheking
                else {
                    const checkingAccount = accounts.filter((acc) => acc.AccountId === checkingCardKey)
                    setTransferFromAccountOptions([...checkingAccount])

                }

            }

        }
    }

    useEffect(() => {

        if (isShowContent) setTransferDropDownLogic();
        if (selectedTransferFromAccount) setTransferFromError("")
        if (selectedTransferToAccount) setTransferToError("")

    }, [selectedTransferFromAccount, selectedTransferToAccount, isShowContent])




    // Amount Validation Logic--------
    const amountValidation = () => {
        let checkingBalance = 0
        let checkingCardKey = 0
        let savingBalance = 0
        let savingCardKey = 0
        if (cards !== null) {
            if (SavingsAccount) {
                savingBalance = SavingsAccount.Balance
                savingCardKey = SavingsAccount.CardKey
            }
            checkingBalance = cards[0].Balance
            checkingCardKey = cards[0].CardKey

        }
        //if internal account
        if (selectedTransferFromAccount && selectedTransferFromAccount.ACHAccountInternalId === 0) {
            //if checking card and amount is more than balance
            if (selectedTransferFromAccount.AccountId === checkingCardKey && enteredAmount > checkingBalance && enteredAmount !== 0) {
                setAmountError(`${t("amount_error_msg")}`)
            } else if (selectedTransferFromAccount.AccountId === checkingCardKey && enteredAmount <= checkingBalance && enteredAmount !== 0) {
                setAmountError(null);
            }
            if (SavingsAccount) {

                if (selectedTransferFromAccount.AccountId === savingCardKey && enteredAmount > savingBalance && enteredAmount !== 0) {
                    setAmountError(`${t("amount_error_msg")}`)
                }
                else if (selectedTransferFromAccount.AccountId === savingCardKey && enteredAmount <= savingBalance && enteredAmount !== 0) {
                    setAmountError(null)
                }
            }
        }
    }

    useEffect(() => {
        if (enteredAmount) amountValidation();
    }, [enteredAmount, selectedTransferFromAccount])

    const handleOnBlurAmountField = (e) => {

        if (enteredAmount) amountValidation();
        else setAmountError("Please enter amount")

    }


    //onSubmit Validation-----------
    const handleValidation = () => {
        let isProceed = true;
        const reqError = "Required"
        if (!selectedTransferFromAccount) {
            setTransferFromError('Please select Account.')
            isProceed = false
        }
        if (!selectedTransferToAccount) {
            setTransferToError('Please select Account.')
            isProceed = false
        }
        if (!enteredAmount) {
            setAmountError('Please enter amount.')
            isProceed = false
        }
        if (amountError == t('amount_error_msg')) {
            setAmountError(t('amount_error_msg'))
            isProceed = false
        }
        debugger;
        if (frequency == "" && !recurringFrequency) {
            setFreqTypeError('Please select frequency.')
            isProceed = false
        }
        if (frequency === "Recurring" && !recurringFrequency) {
            setRecurringFreqTypeError('Please select frequency.')
            isProceed = false
        }
        if (frequency !== "One Time" && !selectedStartDate) {
            setTransferDateError('Please select date.')
            isProceed = false
        }
        if (frequency === "One Time" && !selectedTransferDate) {
            setTransferDateError('Please select date.')
            isProceed = false
        }

        return isProceed;
    }

    //on successful process setData
    const setDataOnSubmit = () => {
        let formattedEndDate = ''
        let formattedTransferDate = ''
        if (frequency !== "One Time") {
            formattedTransferDate = moment(selectedStartDate).format('YYYY-MM-DD')
            formattedEndDate = moment(selectedEndDate).format('YYYY-MM-DD')
        } else {
            formattedTransferDate = moment(selectedTransferDate).format('YYYY-MM-DD')
            formattedEndDate = moment(selectedEndDate).format('YYYY-MM-DD')
        }


        let transferFundPayloadObj = {
            "FromAccount": selectedTransferFromAccount?.AccountId.toString(),
            "ToAccount": selectedTransferToAccount?.AccountId.toString(),
            "FromAccountType": selectedTransferFromAccount?.AccountType,
            "ToAccountType": selectedTransferToAccount?.AccountType,
            "Amount": enteredAmount,
            "Frequency": transferMoneyPayloadObj?.Frequency?.type,
            "TransferDate": formattedTransferDate,
            "Memo": memo
        }

        // var transferDateStatus = dateComparison(formattedTransferDate)
        var transferDateStatus = dateComparison(moment(formattedTransferDate).format('MMMM DD, YYYY'))
        console.log("transferDateStatus---", transferDateStatus)
        if (transferDateStatus === "Future") transferFundPayloadObj.IsScheduledTransfer = true
        else transferFundPayloadObj.IsScheduledTransfer = false
        if (frequency !== "One Time") {

            transferFundPayloadObj.FrequencyType = recurringFrequency;
            transferFundPayloadObj.EndDate = formattedEndDate;
        }

        console.log("transferFundPayloadObj---", transferFundPayloadObj)

        const confirmDisplayData = [
            {
                'title': 'Transfer Money FROM',
                'value': selectedTransferFromAccount?.AccountName
            },
            {
                'title': 'Transfer money to',
                'value': selectedTransferToAccount?.AccountName
            },
            {
                'title': 'Amount',
                'value': enteredAmount
            },
            {
                'title': 'Frequency',
                'value': frequency
            },
            {
                'title': 'Transfer date',
                'value': moment(frequency !== "One Time" ? selectedStartDate : selectedTransferDate).format('MMMM DD, YYYY')
            },
            {
                'title': 'Memo (Optional)',
                'value': memo
            },
            {
                'title': 'Tranfer date object',
                'value': moment(frequency !== "One Time" ? selectedStartDate : selectedTransferDate).format('MMMM DD, YYYY')
            },
            {
                'title': 'End date object',
                'value': selectedEndDate
            },
            {
                'title': 'From Account',
                'value': selectedTransferFromAccount
            },
            {
                'title': 'To Account',
                'value': selectedTransferToAccount
            },

        ]

        onTransferFundPayload(transferFundPayloadObj)
        onConfirmDisplayData(confirmDisplayData)
        onNextSelect(true);
    }

    const handleNextClick = () => {
        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Transfer_Money", cardDetails.AccountTypeName+"_"+"Next_Clicked", "Next_Clicked","", "");
        const isProceed = handleValidation();

        if (isProceed) {
            setDataOnSubmit();
        }


    }

    const resetEachFieldValue = () => {
        setTransferFromAccountOptions(accounts)
        setTransferToAccountOptions(accounts)
        setTransferFromError(null)
        setTransferToError(null)
        setAmountError(null)
        setFreqTypeError(null)
        setRecurringFreqTypeError(null)
        setTransferDateError(null)

        const transferMoneyPayload = {
            "FromAccount": null,
            "ToAccount": null,
            "Amount": null,
            "Frequency": 'One Time',
            "FrequencyType": null,
            "TransferDate": new Date(new Date().toUTCString().substr(0, 25)),
            "EndDate": null,
            "Memo": null
        }

        dispatch({ type: "TRANSFER_MONEY_PAYLOAD", payload: { transferMoneyPayload } })
    }

    const handleReset = () => {
        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Transfer_Money", cardDetails.AccountTypeName+"_"+"Reset_Account_Clicked", "Reset_Account_Clicked","", "");
        resetEachFieldValue();
        setIsReset(prev => !prev);
        dispatch({ type: "RESET_TRANSFER_PAYLOAD" })
    }


    const getHolidays = async () => {
        if (cards == undefined || cards == null) {
            return
        }
        var url = GET_HOLIDAYS + "?cardKey=" + cards[0].CardKey;

        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setIsLoading(true)
        const response = await GetRequest(url, headers);
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        // const response = { "Response": { "StartDate": "2023-08-17T00:00:00Z", "EndDate": "2024-02-18T00:00:00Z", "Holidays": ["2023-09-04T00:00:00", "2023-10-09T00:00:00", "2023-11-10T00:00:00", "2023-11-23T00:00:00", "2023-12-25T00:00:00", "2024-01-01T00:00:00", "2024-01-15T00:00:00", "2023-08-19T00:00:00Z", "2023-08-20T00:00:00Z", "2023-08-26T00:00:00Z", "2023-08-27T00:00:00Z", "2023-09-02T00:00:00Z", "2023-09-03T00:00:00Z", "2023-09-09T00:00:00Z", "2023-09-10T00:00:00Z", "2023-09-16T00:00:00Z", "2023-09-17T00:00:00Z", "2023-09-23T00:00:00Z", "2023-09-24T00:00:00Z", "2023-09-30T00:00:00Z", "2023-10-01T00:00:00Z", "2023-10-07T00:00:00Z", "2023-10-08T00:00:00Z", "2023-10-14T00:00:00Z", "2023-10-15T00:00:00Z", "2023-10-21T00:00:00Z", "2023-10-22T00:00:00Z", "2023-10-28T00:00:00Z", "2023-10-29T00:00:00Z", "2023-11-04T00:00:00Z", "2023-11-05T00:00:00Z", "2023-11-11T00:00:00Z", "2023-11-12T00:00:00Z", "2023-11-18T00:00:00Z", "2023-11-19T00:00:00Z", "2023-11-25T00:00:00Z", "2023-11-26T00:00:00Z", "2023-12-02T00:00:00Z", "2023-12-03T00:00:00Z", "2023-12-09T00:00:00Z", "2023-12-10T00:00:00Z", "2023-12-16T00:00:00Z", "2023-12-17T00:00:00Z", "2023-12-23T00:00:00Z", "2023-12-24T00:00:00Z", "2023-12-30T00:00:00Z", "2023-12-31T00:00:00Z", "2024-01-06T00:00:00Z", "2024-01-07T00:00:00Z", "2024-01-13T00:00:00Z", "2024-01-14T00:00:00Z", "2024-01-20T00:00:00Z", "2024-01-21T00:00:00Z", "2024-01-27T00:00:00Z", "2024-01-28T00:00:00Z", "2024-02-03T00:00:00Z", "2024-02-04T00:00:00Z", "2024-02-10T00:00:00Z", "2024-02-11T00:00:00Z", "2024-02-17T00:00:00Z", "2024-02-18T00:00:00Z"] }, "ResponseCode": 2000, "Messages": [], "Echo": { "RequestTime": "2023-08-18T11:28:02.1985931Z", "ResponseTime": "2023-08-18T11:28:02.2454931Z", "ITransactionID": "8317b946-2f52-45c3-a83e-426d0060e97c" } }
        // const responseObj = { "Response": { "StartDate": "2023-08-20T00:00:00Z", "EndDate": "2024-02-19T00:00:00Z", "Holidays": ["2023-09-04T00:00:00", "2023-10-09T00:00:00", "2023-11-10T00:00:00", "2023-11-23T00:00:00", "2023-12-25T00:00:00", "2024-01-01T00:00:00", "2024-01-15T00:00:00", "2023-08-19T00:00:00Z", "2023-08-20T00:00:00Z", "2023-08-26T00:00:00Z", "2023-08-27T00:00:00Z", "2023-09-02T00:00:00Z", "2023-09-03T00:00:00Z", "2023-09-09T00:00:00Z", "2023-09-10T00:00:00Z", "2023-09-16T00:00:00Z", "2023-09-17T00:00:00Z", "2023-09-23T00:00:00Z", "2023-09-24T00:00:00Z", "2023-09-30T00:00:00Z", "2023-10-01T00:00:00Z", "2023-10-07T00:00:00Z", "2023-10-08T00:00:00Z", "2023-10-14T00:00:00Z", "2023-10-15T00:00:00Z", "2023-10-21T00:00:00Z", "2023-10-22T00:00:00Z", "2023-10-28T00:00:00Z", "2023-10-29T00:00:00Z", "2023-11-04T00:00:00Z", "2023-11-05T00:00:00Z", "2023-11-11T00:00:00Z", "2023-11-12T00:00:00Z", "2023-11-18T00:00:00Z", "2023-11-19T00:00:00Z", "2023-11-25T00:00:00Z", "2023-11-26T00:00:00Z", "2023-12-02T00:00:00Z", "2023-12-03T00:00:00Z", "2023-12-09T00:00:00Z", "2023-12-10T00:00:00Z", "2023-12-16T00:00:00Z", "2023-12-17T00:00:00Z", "2023-12-23T00:00:00Z", "2023-12-24T00:00:00Z", "2023-12-30T00:00:00Z", "2023-12-31T00:00:00Z", "2024-01-06T00:00:00Z", "2024-01-07T00:00:00Z", "2024-01-13T00:00:00Z", "2024-01-14T00:00:00Z", "2024-01-20T00:00:00Z", "2024-01-21T00:00:00Z", "2024-01-27T00:00:00Z", "2024-01-28T00:00:00Z", "2024-02-03T00:00:00Z", "2024-02-04T00:00:00Z", "2024-02-10T00:00:00Z", "2024-02-11T00:00:00Z", "2024-02-17T00:00:00Z", "2024-02-18T00:00:00Z"] }, "ResponseCode": 2000, "Messages": [], "Echo": { "RequestTime": "2023-08-18T11:28:02.1985931Z", "ResponseTime": "2023-08-18T11:28:02.2454931Z", "ITransactionID": "8317b946-2f52-45c3-a83e-426d0060e97c" } }
        var responseObj = JSON.parse(response)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            let givenMinDate = new Date(responseObj.Response.StartDate.replace('Z', ''))
            var oneDayMilliseconds = 24 * 60 * 60 * 1000;
            setCalendarMinDate(new Date(new Date().toUTCString().substr(0, 25)))
            setCalendarMaxDate(new Date(responseObj.Response.EndDate))
            let holidays = responseObj.Response.Holidays.map((dateString) => new Date(dateString.replace('Z', '')))
            console.log("holiday----", holidays)
            console.log("holiday----")
            setCalendarDisabledDates(holidays)
            let todayIsAHoliday = false
            let minDateDay = givenMinDate.getDay()
            holidays.forEach((date) => {
                if (moment(date).format('YYYY-MM-DD') == moment(new Date(new Date().toUTCString().substr(0, 25))).format('YYYY-MM-DD')) {
                    todayIsAHoliday = true
                }

            })
            let currentDate = new Date()
            if (!todayIsAHoliday) {
                if (minDateDay == 0) {
                    setOneTimeSelectedDate(new Date(givenMinDate.getTime() + oneDayMilliseconds))
                    dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "TransferDate", 'value': new Date(givenMinDate.getTime() + oneDayMilliseconds) } })
                } else if (minDateDay == 6) {
                    setOneTimeSelectedDate(new Date(givenMinDate.getTime() + (oneDayMilliseconds * 2)))
                    dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "TransferDate", 'value': new Date(givenMinDate.getTime() + (oneDayMilliseconds * 2)) } })
                } else {
                    setOneTimeSelectedDate(new Date(new Date().toUTCString().substr(0, 25)))
                    dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "TransferDate", 'value': new Date(new Date().toUTCString().substr(0, 25)) } })
                    // setOneTimeSelectedDate(new Date())
                    // dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "TransferDate", 'value': new Date() } })
                }
                if (minDateDay == 0) {
                    var date1 = new Date(givenMinDate.getTime() + oneDayMilliseconds)
                    setStartDate(date1)
                    dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "startDate", 'value': date1 } })
                } else if (minDateDay == 6) {
                    var date2 = new Date(givenMinDate.getTime() + (oneDayMilliseconds * 2))
                    setStartDate(date2)
                    dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "startDate", 'value': date2 } })
                } else {
                    var date3 = new Date(new Date().toUTCString().substr(0, 25))
                    // var date3 = new Date()
                    setStartDate(date3)
                    dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "startDate", 'value': date3 } })
                }
                // setEndDate(new Date(responseObj.Response.EndDate.replace('T00:00:00', '').replace('Z', '')))
                // dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "EndDate", 'value': new Date(responseObj.Response.EndDate.replace('T00:00:00', '').replace('Z', '')) } })
            }
        }

    }
    useEffect(() => {
        setTimeout(() => {
        }, 1000);
    }, [oneTimeSelectedDate])
    const onDateChange = (date, type) => {
        if (type == 'oneTime') {
            setOneTimeSelectedDate(date)
            dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "TransferDate", 'value': date } })
        } else if (type == 'recurringStartDate') {
            setStartDate(date)
            dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "startDate", 'value': date } })
            setEndDate(null)
            dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "EndDate", 'value': null } })
        } else if (type == 'recurringEndDate') {
            setEndDate(date)
            dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "EndDate", 'value': date } })
        }
    }


    const settingChildren1 = () => {
        return (
            <>
                <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
            lg:text-[26px] md:text-[22px] 
            sm:text-[20px] text-[20px]
            leading-[36px] font-hanken-grotesk sm:mx-0 mx-4"> {t("transfer_money")}</div>
                <div class="border-t-[2.5px] border-[#A3CEBB] flex justify-center flex-col sm:mx-0 mx-4">
                </div>

                <p className='pt-4 font-serif-pro font-[400] lg:text-[26px] md:text-[24px] sm:text-[22px] text-[25px]
                         md:leading-[38px] sm:leading-[30px] leading-[34px]  text-secondary-2 
                             mt-4 sm:mx-0 mx-4'>{t("transfer_fund_subheading1")}</p>


                <p className=' font-serif-pro font-[400] lg:text-[26px] md:text-[24px] sm:text-[22px] text-[25px]
                         md:leading-[38px] sm:leading-[30px] leading-[34px]  text-secondary-3 sm:mx-0 mx-4'>

                    {location.pathname.toLowerCase() === '/accountservices/spending/transfermoney' ? t("transfer_fund_subheading2_spending") : t("transfer_fund_subheading2_savings")}
                </p>

                <div className='flex justify-center sm:w-full mt-10 sm:mx-0 mx-4'>
                    <div className='sm:w-[80%] w-full flex md:gap-8 gap-8 flex-col'>
                        {isShowContent &&
                            <CustomDropdown options={transferFromAccountOptions.length > 0 ? transferFromAccountOptions : [{ Name: t('no_linked_acccounts') }]}
                                title={t('transfer_money_from')} onSelect={(option) => {
                                    dispatch({
                                        type: 'TRANSFER_MONEY_PAYLOAD',
                                        payload: { 'key': "FromAccount", 'value': option }
                                    })
                                }}
                                selectedAccountType={selectedTransferFromAccount}
                                hrbg='bg-dropdown-sky' error={transferFromError} />
                        }
                        {isShowContent &&
                            <CustomDropdown options={transferToAccountOptions.length > 0 ? transferToAccountOptions : [{ Name: t('no_linked_acccounts') }]}
                                title={t('transfer_money_to')} onSelect={(option) => { dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "ToAccount", 'value': option } }) }}
                                selectedAccountType={selectedTransferToAccount}
                                hrbg='bg-dropdown-sky' error={transferToError} />
                        }
                        {isShowContent &&
                            <button className=' font-hanken-grotesk font-[500] md:font-[700] text-[14px] leading-[20px] md:text-[12px] md:leading-[20px] lg:text-[16px] lg:leading-[24px] text-left text-secondary-2 cursor-pointer'
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        if (twoFactAuthenticationDone) {
                                            if (props?.isTinkFlowEnabled) openHeadsUpModal()
                                            else {
                                                navigate('/Settings/ManageExternalAccounts/AddExternalAccount')
                                            }
                                        } else { props?.onClickAddExternalAcc() }

                                    }
                                }}
                                onClick={() => {
                                    if (twoFactAuthenticationDone) {
                                        if (props?.isTinkFlowEnabled) openHeadsUpModal()
                                        else {
                                            navigate('/Settings/ManageExternalAccounts/AddExternalAccount')
                                        }
                                    } else { props?.onClickAddExternalAcc() }
                                     logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Transfer_Money", cardDetails.AccountTypeName+"_"+"Add_External_Account_Clicked", "Add_External_Account_Clicked","", "");

                                    onSetIsExternalAccountAddedSuccessfully(false)
                                }}>
                                {t("add_external_account")}</button>
                        }


                        <CustomTextInput name={t("amount")}
                            onChange={(event) => {
                                const inputValue = event.target.value;
                                const formattedAmount = handleAmountInputLimitTo2decimal(inputValue)
                                console.log(formattedAmount)
                                setAmountError('');
                                dispatch({
                                    type: 'TRANSFER_MONEY_PAYLOAD',
                                    payload: { 'key': "Amount", 'value': formattedAmount.toString().replace('$', '') }
                                })
                            }}
                            value={enteredAmount ? `$${enteredAmount}` : ''}
                            onBlur={(event) => handleOnBlurAmountField(event)}
                            error={amountError}
                            placeholder={"$ 0"}
                            inputWidth='w-full'
                        />

                        <CustomRadioButton title={t('frequency')} options={frequencyArray} frequency={frequency} onSelect={setFrequencyFun} error={freqTypeError} />

                        {frequency === "Recurring" &&
                            <CustomRadioButton isTitle={false} options={recurringFrequencyArray} frequency={recurringFrequencyName} onSelect={setRecurringFrequencyFun} error={recurringFreqTypeError} />
                        }


                        {frequency === "One Time" &&
                            <CustomDatePicker selectedDate={selectedTransferDate} onDateChange={(date) => onDateChange(date, 'oneTime')} minDate={CalendarMinDate} maxDate={CalendarMaxDate} disabledDates={(selectedTransferFromAccount?.AccountType == ACH || selectedTransferToAccount?.AccountType == ACH) ? CalendarDisabledDates : []} title="Transfer Date" hrbg='bg-dropdown-sky' />
                        }
                        {frequency === "Recurring" &&
                            <CustomDatePicker selectedDate={startDate} onDateChange={(date) => onDateChange(date, 'recurringStartDate')} minDate={CalendarMinDate} maxDate={CalendarMaxDate} disabledDates={(selectedTransferFromAccount?.AccountType == ACH || selectedTransferToAccount?.AccountType == ACH) ? CalendarDisabledDates : []} title="Start Date" hrbg='bg-dropdown-sky' />
                        }
                        {frequency === "Recurring" &&
                            <CustomDatePicker selectedDate={endDate} onDateChange={(date) => onDateChange(date, 'recurringEndDate')} minDate={startDate} maxDate={CalendarMaxDate} disabledDates={[...(selectedTransferFromAccount?.AccountType === 'ACH' || selectedTransferToAccount?.AccountType === 'ACH') ? CalendarDisabledDates : [], startDate]} title="End Date" hrbg='bg-dropdown-sky' />
                        }

                        <p className=' font-hanken-grotesk font-[500] text-[16px]
                        leading-[24px] md:text-[14px] md:leading-[22px] sm:text-[12px] 
                        sm:leading-[18px] text-secondary-3 '>{
                                (!selectedTransferToAccount && !selectedTransferFromAccount) ? ""
                                    :
                                    (selectedTransferToAccount?.AccountType == ACH || selectedTransferFromAccount?.AccountType == ACH) ? t('arrive_in_ur_acc') : t('money_arrives')}</p>


                        <CustomTextInput
                            name="Memo (Optional)"
                            onChange={(event) => { dispatch({ type: 'TRANSFER_MONEY_PAYLOAD', payload: { 'key': "Memo", 'value': event.target.value } }) }}
                            value={memo}
                            inputWidth=' w-full'
                            placeholder={t("memo_placeholder")}
                        />
                        {/* <span className='font-hanken-grotesk font-[500]
                         lg:text-[14px] md:text-[13px] sm:text-[12px] text-[13px]
                          leading-[22px]'>{location.pathname === '/AccountServices/Checking/TransferMoney' ? t("adding_a_note_to_this_transfer_is_optional") : ''}</span> */}
                    </div>
                </div>

                <div className='flex sm:justify-end justify-between md:pb-20 sm:pb-10 pb-20  pt-10 gap-11 sm:mx-0 mx-4'>
                    <button className=' font-hanken-grotesk font-[600]
                                     md:font-[700] leading-[26px] 
                                     lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                                      md:leading-[13px]
                                      lg:leading-[18px] text-secondary-2 cursor-pointer 
                                      capitalize' onClick={handleReset}>{t("reset")}</button>
                    <button disabled={amountError ? true : false} className='font-hanken-grotesk flex items-center font-[600] md:font-[700] 
                         leading-[26px] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                          md:leading-[13px] 
                          lg:leading-[18px] text-secondary-2 cursor-pointer ' onClick={handleNextClick}>
                        {t("next")}<img src={RightArrow} alt="RadialNull" className='lg:h-[15px] lg:w-[14px]
                        md:h-[14px] md:w-[13px] sm:h-[12px] sm:w-[12px] h-[13px] w-[14px] ml-1 mb-[2px]' /></button>
                </div>


                {/* <p className=' font-hanken-grotesk font-[600]
                                     md:font-[700] text-[19px] leading-[26px] 
                                     md:text-[13px] md:leading-[13px] lg:text-[18px]
                                      lg:leading-[18px] text-secondary-2 cursor-pointer 
                                      capitalize' onClick={handleReset}>{t("reset")}</p>
                                        <p className=' font-hanken-grotesk font-[600] md:font-[700] text-[19px] leading-[26px] md:text-[13px] md:leading-[13px] lg:text-[18px] lg:leading-[18px] text-secondary-2 ' onClick={handleNextClick}>{t("next")}</p>
                                    <img src={RightArrow} alt="RadialNull" className='h-[7px] w-[12px] 
                                            md:h-[10px] md:w-[11px]  lg:h-[17px] lg:w-[17px] ml-1' /> */}


            </>
        )
    }




    return (

        <>
            <DynamicCard
                header={false}
                CardRadius={true}
                cardWidth={width.allpolicies}
                HeaderRadius={true}
                isWhite={true}
                HeaderContent={false}>
                {settingChildren1()}
            </DynamicCard>
 
            {isLoading && <Loader />}
        </>
    )
}


export default withOidcSecure(TransferMoneyForm)

