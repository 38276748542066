import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import rootReducer from './reducers';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['accessToken', 'userProfile', 'userCards', 'isLoggedIn', 'selectedCardDetails', 'ssnScreenThrough', 'savingsTermsConditions', 'selectedSubIndex', 'cancelTransactionDetails'],
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
const store = createStore(persistedReducer);

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
