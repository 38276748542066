import React from 'react';
import { formatDate, dashBoardformatDate } from '../../../utils';
import { useTranslation } from 'react-i18next';

const UpcomingTransactionItem = ({ transactionData ,cancelUpcomingTransaction}) => {
  const { t } = useTranslation();
  const { IconUri, Description, TransactionDate, ExternalStatus, Amount, DrCrIndicator,TransactionId, StatusCode} = transactionData;
  const formattedDate = dashBoardformatDate(TransactionDate);

  const date = formattedDate;
  // const StatusCode='APP';
  const isPending = StatusCode === 'APP';
  const externalText =  StatusCode === 'APP' ? 'Pending' : '';
  const isCredit = DrCrIndicator === "Cr" ? true : false;
  const textColor = DrCrIndicator === "Cr" ? "text-kudzu-text" : "text-secondary-3";
  const prefix = DrCrIndicator === "Cr" ? "+ " : "- ";
  //{`${isPending ? 'italic text-secondary-3 opacity-50' : 'text-secondary-3'}`}

  const styleAll = `font-[400] my-auto font-[500] font-hanken-grotesk md:text-[18px] sm:text-[11px] text-[16px] leading-[18px]`
  const styleStatus = `font-[400] y-auto font-hanken-grotesk md:text-[18px] md:text-[17px] sm:text-[11px] text-[16px] `
  //text-[#262B3A]
  return (
    // <tr>
    //   <td className='pb-5'><img src={IconUri} alt="Icon" className='md:w-[44px] sm:w-[27px]' />
    //   </td>

    //   <td className={`pb-5 font-semibold hidden sm:table-cell ${styleAll}`}>
    //     <p className={`${isPending ? 'italic text-secondary-3 opacity-50' : 'text-secondary-3'}`}>{Description}</p>
    //     <span style={{ fontSize: '14px', color: '#262B3A' }}
    //       className="block sm:block md:hidden">
    //       {date}
    //     </span>
    //   </td>


    //   <td className={`pb-5 text-center hidden sm:table-cell ${styleAll}`}>
    //     <p className={`my-auto font-hanken-grotesk text-[18px] 
    //     ${isPending ? 'italic text-secondary-3 opacity-50' : 'text-secondary-3'}`}>
    //       {date}
    //     </p>
    //   </td>


    //   <td className={`pb-5 text-center hidden sm:table-cell ${styleStatus}`}>
    //     <p className={`my-auto font-hanken-grotesk text-[18px] 
    //     ${isPending ? 'italic text-secondary-3 opacity-50' : 'text-kudzu-text'}`}>
    //       {externalText}
    //     </p>
    //   </td>

    //   <td className={`pb-5 text-right hidden sm:table-cell ${styleStatus}`}>
    //     <p className={`${textColor} my-auto font-hanken-grotesk text-[18px] 
    //     ${isPending ? 'italic text-secondary-3 opacity-50' : ''}`}>
    //       {prefix}
    //       <span className="amount-main">{parseInt(Amount)}</span>
    //       <span className="amount-decimal">.{(Amount % 1).toFixed(2).slice(2)}</span>
    //     </p>
    //   </td> 
    // </tr>



    <tr className=''>
      <td className='pb-5 pr-[.5rem]'><img src={IconUri} alt="Icon" className='lg:w-[35px] md:w-[34px] sm:w-[30px] w-[33px]' /></td>

      <td className={`pb-5 text-left font-[500] lg:max-w-[11rem] md:max-w-[10rem] sm:max-w-[6rem]
       max-w-[11rem] break-words  ${styleAll} 
       ${!isPending ? 'text-[#262B3A]' : 'text-[#262B3A]  opacity-[50%]'}`}>
        {Description}
        <p className={`
        sm:hidden block text-[13px] font-hanken-grotesk font-[400] mt-[0.3rem]`}>
         <span className={`${isPending ? 'text-[#262B3A]' : 'text-[#262B3A] opacity-[50%]'} w-[80px] inline-block`}> {date}</span>
          <span className='text-[#2965FB] ml-2 cursor-pointer' onClick={()=>{cancelUpcomingTransaction(transactionData)}}>{t('cancel')}</span>
        </p>
      </td>

      <td className={`pb-5 text-left font-[500] hidden sm:table-cell ${styleAll}
      ${!isPending ? 'text-[#262B3A]' : 'text-[#262B3A] opacity-[50%]'}`}>{date}</td>

      <td className={`pb-5 text-left hidden sm:table-cell ${styleStatus}`}>
        {/* {externalText} */}
        {/* //${isPending ? 'text-[#262B3A] opacity-[50%]' : isCredit ? 'text-kudzu-text' : 'text-[#262B3A]'} */}
        <p className={`sm:block hidden text-[#2965FB] md:text-[18px] sm:text-[11px] text-[16px] font-hanken-grotesk font-[400] cursor-pointer`} 
        onClick={()=>{cancelUpcomingTransaction(transactionData)}}>{t('cancel')}</p>

      </td>
      

      <td className={` sm:table-cell  flex justify-end pb-5 text-right font-[500] ${styleAll} ${isPending ? 'text-[#262B3A] opacity-[50%]' : isCredit ? 'text-kudzu-text' : 'text-[#262B3A]'} `}>
        {/* {prefix} */}
        {parseInt(Amount)}
        .{(Amount % 1).toFixed(2).slice(2)}
        <p className={`${isPending ? 'text-[#262B3A]' : isCredit ? 'text-kudzu-text' : 'text-[#262B3A]'}
        italic text-[13px] font-[400] sm:hidden block`}> {externalText}</p>
      </td>
    </tr>
  );
};



export default UpcomingTransactionItem;
