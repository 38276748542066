import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../../Layout/HomeLayout';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import { GetApiHeadersWithBearer, GetRequest } from '../../../../../network/NetworkUtils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AccountDetailsHeder, copyToClipboard, MoreServices, QuickActions, SPENDING } from '../../../../../utils';
import { CHECKING, SAVINGS, GET_DDA_DETAILS, GET_DDA_DOWNLOAD_URL, STATUS_SUCCESS_CODE } from '../../../../../network/URLConstants';
import { retrieveData } from '../../../../../helpers/utils/Utils';
import { width } from '../../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles';
import DynamicCard from '../../../../CustomComponents/DynamicHomeCard';
import FooterContent from '../../../../CustomComponents/Footer';
import check_scan from '../../../../../assets/images/check_scan.png';
import { useNavigate } from 'react-router-dom';
import downloadArrow from '../../../../../assets/images/download_arrow.png';
import logFirebaseEvents from '../../../../LogFirebaseEvents';



const DepositMoney = () => {

  const moreService = 'sm:w-[90%] w-full pb-[1.5rem] flex justify-between items-center border-b-[3px] border-[#D1E7DD]';
  const moreserviceText = 'font-[600] text-[#262B3A] lg:text-[16px] md:text-[15px] sm:text-[11px] text-[14px] leading-[16px] font-hanken-grotesk';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkingAccountNumber, setCheckingAccountNumber] = useState('*********'); //Account Number
  const [checkingRoutingNumber, setCheckingRoutingNumber] = useState('*********');
  const [savingAccountNumber, setSavingAccountNumber] = useState('*********'); //Account Number
  const [savingRoutingNumber, setSavingRoutingNumber] = useState('*********');
  const cards = useSelector(state => JSON.parse(state.userCards));
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [accountCopied, setAccountCopied] = useState(false);
  const [routingCopied, setRoutingCopied] = useState(false);
  const [loading, setLoading] = useState(false)
  const cardDetails = useSelector(state => state.selectedCardDetails);



  useEffect(() => {
    if (cards != undefined || cards != null) {
      
      cards.map((item) => {
        const { AccountTypeName, CardKey } = item;
        getAccountNumberDetails(AccountTypeName, CardKey);
        return null; // Return null from the map to avoid returning a new array
      });
    }
  
  }, []);


  const getAccountNumberDetails = async (cardType, cardkey) => {
    if (cardkey == undefined || cardkey == null) {
      return
    }
    setLoading(true)
    let url = GET_DDA_DETAILS + cardkey;
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    var response = await GetRequest(url, headers)
    setTimeout(() => {
      setLoading(false)
    }, 500);
    var responseObj = JSON.parse(response);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      if (responseObj.Response != undefined) {
        if (cardType == CHECKING || SPENDING) {
          setCheckingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setCheckingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
        } else {
          setSavingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setSavingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
        }

      }
    } else {
      var arrMessages = responseObj.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;
    }
  }


  const accountNumberCopiedClicked = () => {
    copyToClipboard(checkingAccountNumber, setAccountCopied(true))
    setAccountCopied(true);
    setTimeout(() => {
      setAccountCopied(false);
    }, 2000);

  }
  const routingNumberCopiedClicked = () => {
    copyToClipboard(checkingRoutingNumber, setRoutingCopied(true))
    setRoutingCopied(true);
    setTimeout(() => {
      setRoutingCopied(false);
    }, 2000);
  }

  ///Quick Actions

  const QuickActionsButtons = [
    { name: `${t('add_cash')}`, actionType: "", path: "/AccountServices/Spending/AddFunds/AddCash" },
    { name: "Manage Direct Deposit ", actionType: "", path: "/AccountServices/Spending/AddFunds/ManageDirectDeposit" }

  ]


  const depositCashClicked = () => {
    logFirebaseEvents(cardDetails.AccountTypeName+"_"+"add_cash", cardDetails.AccountTypeName+"_"+"setup_direct_deposit_clicked", "setup_direct_deposit_clicked","", "");
    navigate('/AccountServices/Spending/AddFunds/ManageDirectDeposit');
  }


  //footer
  const footerContent = () => {
    return (
      < FooterContent  
        type={t("set_up_dd_btn")}
        highlightText={t("set_up_dd_text")}
        subHeaderText={t("paper_work")} image={downloadArrow}
        isSpace={true} isSubheadingwidth={true}
        buttonEnabled={true} buttonText={t("set_up_dd_btn")}
        footerBtnAction={depositCashClicked} />
    )

  }

  return (
    <HomeLayout>
      <HomeHeader heading="Hello, Sam. You’ve spent $405 in the last week." 
      isHeaderCaption={false} 
      selectedIndex={1} />
      <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight  '>

        <DynamicCard
          header={false}
          HeaderContent={false}
          footer={false}
          CardRadius={true}
          HeaderRadius={true}
          isWhite={true}
          cardWidth={width.homeCard}
          backgroundColor={'#FFFFFF'}
        >
          <div className=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
         lg:text-[26px] md:text-[22px] 
         sm:text-[20px] text-[20px]
            leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
            {t("menu_deposit_money")}
          </div>
          <AccountDetailsHeder
            AccountNo={checkingAccountNumber}
            routingNo={checkingRoutingNumber}
            accountCopiedProps={accountCopied}
            routingCopiedProps={routingCopied}
            handleCopyClick={accountNumberCopiedClicked}
            handleCopyRoutingNumberClick={routingNumberCopiedClicked}
            contentBetween={true}
            topBorder={true}
            bottomBorder={true}
            isCopyblack={true}
          />

          <QuickActions actionsArray={QuickActionsButtons} />


        </DynamicCard>
        {footerContent()}
      </div>
    </HomeLayout>
  )
}

export default DepositMoney;