import styled from 'styled-components';
import tw from 'twin.macro';


// Font-Family
const primaryFont = 'Source Serif Pro, serif';
const secondaryFont = 'Hanken Grotesk, sans-serif';

// Font-Size
const fontSizes = {
  extralarge: '32px',
  large: '24px',
  medium: '18px',
  Small: '14px',
  semiSmall: '12px',
};

const fontWeight = {
  bold: "700",
  normal: "400",
}

export const MainFooter = styled.div` ${tw`w-full sm:px-[6%] px-[15%] py-[7rem] sm:flex sm:flex-nowrap`}`;

export const LogoContainer = styled.div` ${tw`w-[100%] sm:w-[26%] flex-row flex-col flex`}`;

export const LogoImage = styled.img` ${tw`w-[111px] h-[32.82px] sm:w-[86.67px] sm:h-[26px] md:w-[139px] md:h-[39px] mr-0`}`;

// StoreImage
// // position: absolute;
// right: 5rem;
// bottom: 23.2rem;
// LogoImage
// flex-direction: row;


export const StoreImagesContainer = styled.div`${tw`sm:mt-[5rem] sm:static  absolute right-[5rem]`}`;

export const StoreImage = styled.img` ${tw`w-[112px] sm:w-[108px] h-[30.86px] md:w-[140px] md:h-[40px] mb-3`}`;

export const Links = styled.div` ${tw`w-[100%] sm:w-[70%]`}`;

export const ListContainer = styled.div` ${tw`md:w-[100%] w-full text-[var(--text-clr-secondary)]`}`;

export const FooterList = styled.ul` ${tw`sm:inline-flex inline-block sm:py-0 sm:space-x-[4rem] space-x-0 sm:text-[10px] text-[14px] md:text-[14px]`}
font-family: ${({ isPrimaryFont }) => (isPrimaryFont ? primaryFont : secondaryFont)};
font-size: ${(props) => fontSizes.Small}; 
font-weight: ${(props) => fontWeight[props.fontWeight]};
`;

export const FooterListItem = styled.li`${tw`py-[16px] sm:py-[0px] cursor-pointer`}`;

export const CopyrightText = styled.span`
font-weight: ${(props) => fontWeight[props.fontWeight]};
 `;

export const FooterParagraph = styled.p`
  ${tw`pt-[ 5rem] relative z-[-1] w-[full] sm:w-[82%] text-[var(--text-clr-secondary);] opacity-[50%] leading-[20px]`}
  font-family: ${({ isPrimaryFont }) => (isPrimaryFont ? primaryFont : secondaryFont)};
  font-size: ${(props) => fontSizes.semiSmall};
  font-weight: ${(props) => fontWeight[props.fontWeight]};
  `;
