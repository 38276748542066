import React, { useEffect, useState } from "react";
const DynamicCard = ({ cardHeight, cardWidth, cardinnerWidth, cardBackGround, isCardHeader = true, headerContent, headerBg, headerHeight, isCardContainer = true, cardContent, isCardFooter = true, footerHeight, footerBg, footerContent, cardCornerType = 'tr-bl', customCornerStyle }) => {


    let cardRound;
    let headerCorner;
    let footerCorner;
    switch (cardCornerType) {
        case 'tr-bl':
            cardRound = 'rounded-tr-[4rem] rounded-bl-[4rem]'
            headerCorner = 'rounded-tr-[4rem]'
            footerCorner = 'rounded-bl-[4rem]'
            break;
        case 'tl-br':
            cardRound = 'rounded-tl-[4rem] rounded-br-[4rem]'
            headerCorner = 'rounded-tl-[4rem]'
            footerCorner = 'rounded-br-[4rem]'
            break;
        case 'customCorner':
            cardRound = { customCornerStyle }
            break;

    }
    return (
        <>
            <div className={` ${cardWidth ? `${cardWidth}` : 'w-full'} ${cardRound}`}>
                <div className={`w-full relative items-center flex flex-col mb-[0px] ${cardBackGround ? cardBackGround : 'bg-[#FFFFFF]'} ${cardHeight ? cardHeight : ' h-[600px] lg:h-[1000px]'} ${cardRound}`}>
                    {isCardHeader &&
                        <div className={`w-full ${headerHeight ? headerHeight : ''} ${headerBg ? headerBg : 'bg-[#94C3E8] bg-opacity-25'} ${headerCorner}`}  >
                            {headerContent}
                        </div>
                    }
                    {isCardContainer &&
                        <div className={` ${cardinnerWidth ? `${cardinnerWidth}` : 'w-full'} ${cardRound}`}>
                            {cardContent}
                        </div>
                    }

                    {isCardFooter &&
                        <div className={` w-full ${footerHeight ? footerHeight : 'h-[155px]'} ${footerBg ? footerBg : ' bg-none'} ${(cardHeight !== 'content-fit' && cardHeight !== null) && 'absolute bottom-0'} ${footerCorner}`}>
                            {footerContent}
                        </div>
                    }
                </div>
            </div>
        </>


    );
};

export default DynamicCard;