import React from 'react'
import spendingAlertError from "../../../../../assets/images/spending-alert-error.png"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router';
import { withOidcSecure } from '@axa-fr/react-oidc';
import { useDispatch, useSelector } from 'react-redux'


const SpendAlertError = ({isSubErrMsg}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleBackToSpendAlerts = () => {
        dispatch({ type: "RESET_ADD_SPEND_ALERTS" })
        navigate('/Climb/ManageSpendAlerts');
    }

    return (
        <div className=' flex flex-col justify-center items-center w-full'>
            {
                isSubErrMsg &&
                <p className='font-hanken-grotesk font-[400] sm:font-[500] 
                leading-[24px] lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[22px] md:leading-[28px]
                 text-secondary-3'>{t("spendingError")}</p>

            }

            <img src={spendingAlertError} alt="complete" className='lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10' />
            <div className=' flex flex-col justify-center items-center w-full'>
                <button autoFocus class="focus:outline-none   font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] font-[500] sm:font-bold leading-[24px] sm:leading-[12px] md:leading-[16px] bg-[#2965FB] text-white cursor-pointer border-2 px-2 py-4 border-[#2965FB] w-full" onClick={handleBackToSpendAlerts} onKeyDown={(e) => { if (e.key === "Enter") handleBackToSpendAlerts() }}>{t("back_to_spendsense_alerts")}</button>
            </div>

        </div>
    )
}

export default withOidcSecure(SpendAlertError)