import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfigarationData, retrieveData } from './helpers/utils/Utils';
import { ACCEPT_E_SIGN_AGGREMENT, COVERED_BY_KUDZU, GET_ESIGN_AGREEMENT, GET_ESIGN_STATUS, GET_USER_PORFIE_INFO, SAVINGS_HABIT, SPENDING_ALERT, STATUS_SUCCESS_CODE, VALIDATE_AUTH_USER, END_SESSION } from "./network/URLConstants";
import { GetApiHeadersWithBearer, GetRequest, PostRequest, endSessionGetRequest } from './network/NetworkUtils';
///home
import Layout from './components/Layout';
import PreLogin from './components/Layout/PreLogin';
import OnBoard from './components/OnBoard';
import Home from './components/Home';


/////Accounts Services Routes for Checking 
import AccountServicesCheckingCard from './components/Home/AccountServices/Checking';
import AccountServicesSavingCard from './components/Home/AccountServices/Savings';
import AccountServicesSharedCard from './components/Home/AccountServices/Shared';
import DepositMoney from './components/Home/AccountServices/Checking/DepositMoney';
import DepositCash from './components/Home/AccountServices/Checking/DepositCash';
import EditAccountName from './components/Home/AccountServices/Checking/EditAccountName';
import ManageDirectDeposit from './components/Home/AccountServices/Checking/ManageDirectDeposit';

//settings

///Account Services Routes for Savings
import EditSavingsAccountName from './components/Home/AccountServices/Savings/EditAccountName';
import EditSharedAccountName from './components/Home/AccountServices/Shared/EditAccountName';


import Settings from './components/Settings';
import AccessCode from './components/Settings/AccessCode'; 
import Notifications from './components/Notifications';
import NotificationConfigure from './components/Notifications/NotificationConfigure';
import ManageBeneficiaries from './components/Settings/ManageBeneficiaries';
import AddBeneficiary from './components/Settings/AddBeneficiary';
import EditBeneficiary from './components/Settings/EditBeneficiary';

import ManageAuthorizedusers from './components/Home/AccountServices/Checking/ManageAuthorizedusers';
import AddAuthorizedUsers from './components/Home/AccountServices/Checking/ManageAuthorizedusers/AddAuthorizedUsers';
import EditAuthorizedUser from './components/Home/AccountServices/Checking/ManageAuthorizedusers/EditAuthorizedUser';
import AccountServices from './components/Home/AccountServices';
import Checking from './components/Home/Checking';
import Savings from './components/Home/Savings';
import AddExternalAccount from './components/Settings/ManageExternalAccounts/AddExternalAccount';
import VerifyExternalAccount from './components/Settings/ManageExternalAccounts/VerifyExternalAccount';
import ManageExternalAccounts from './components/Settings/ManageExternalAccounts';
import TransferMoney from './components/Home/AccountServices/Checking/TransferMoney';
import CardServices from './components/CardServices';
import CardActionScreen from './components/CardServices/CardActionScreen';
import ManageAccounts from './components/Settings/ManageAccounts';
// {Help Routes}
import Help from './components/Help';
import HelpAndFAQ from './components/Help/FAQ';
import ContactUs from './components/Help/ContactUs';
import ATM from './components/Help/FindATM';
import AboutKudzu from './components/Help/AboutKudzu';

//personal info
import PersonalInformation from './components/Home/AccountServices/Checking/PersonalInformation';
import PersonalInfoSummery from './components/OnBoard/Signup/PersonalInfoSummery';
import ReplaceCard from './components/CardServices/CardActionScreen/ReplaceCard';
import ChangePin from './components/CardServices/CardActionScreen/ChangePin';
import ViewCard from './components/CardServices/CardActionScreen/ViewCard';
import ActivateCard from './components/CardServices/CardActionScreen/ActivateCard';
//import TermsandConditions from './components/OnBoard/Signup/TermsConditions';
import PersonalInfo from './components/OnBoard/Signup/PersonalInfo';
import Documents from './components/Documents';
import PrivacyAndLegal from './components/Documents/PrivacyAndLegal';
import Statements from './components/Documents/Statements';
import TaxDocuments from './components/Documents/TaxDocuments';
import Fees from './components/Documents/Fees';
//Security
import Security from './components/Security';
import TrustedDevices from './components/Security/TrustedDevices';
import ChangePassword from './components/Security/ChangePassword';
//swal alert
import SwalAlert from './components/CustomComponents/SwalAlert';
import Climb from './components/Home/Climb';
import PayPerks from './components/Home/PayPerks';
import RedirectModal from './components/CustomComponents/RedirectModal';
import NotificationItemCard from './components/Notifications/NotificationItemCard';

import FraudAlert from './components/CustomComponents/FraudAlert';
import ClimbMenu from './components/Home/Climb/ClimbMenu';
//import SavingsHabit from './components/Home/Climb/SavingsHabit';
import ManageSettings from './components/Home/Climb/ManageSettings';
import AddSavingsHabit from './components/Home/Climb/SavingsHabit/AddSavingsHabit';
import ManageSavingsHabit from './components/Home/Climb/SavingsHabit/ManageSavingsHabit';
import EditSavingsHabit from './components/Home/Climb/SavingsHabit/EditSavingsHabit';

///////spendalerts
import SpendSenseAlerts from './components/Home/Climb/SpendSenseAlerts';
import ManageSpendAlerts from './components/Home/Climb/SpendSenseAlerts/ManageSpendAlerts';

///climb settings
import ClimbSettings from './components/Home/Climb/ManageSettings';
import AcceptTerms from './components/AcceptTerms';
import EditSpendAlerts from './components/Home/Climb/SpendSenseAlerts/EditSpendAlerts';
import FraudProtection from './components/FraudProtection';
import CoveredByKudzu from './components/Home/Climb/CoveredByKudzu';
import { HotKeys } from 'react-hotkeys';
import { clearStorageExceptKey, storeData } from './utils';
import { store } from './Redux/store';
import { ISDEV_ENV } from './network/URLConstants';
import { useOidc } from '@axa-fr/react-oidc';
import AgreementCard from './components/Documents/AgreementCard';
import DownloadApp from './components/DownloadApp';
import ManageCoveredByKudzu from './components/Home/Climb/ManageCoveredByKudzu';
import SetupSavingsAccount from './components/SetupSavingsAccount';
import SavingsTermsAndConditions from './components/SetupSavingsAccount/SavingsTermsAndConditions';
import SavingsSSNSetup from './components/SetupSavingsAccount/savingsSSNSetup';
import TransactionCard from './components/Home/TransactionCard';
import DownloadTransactionHistory from './components/DownloadTransactionsHistory';
import Loader from './components/CustomComponents/Loader';
import StudentSelection from './components/OnBoard/Signup/StudentSelection';
import StudentEmailVerfication from './components/OnBoard/Signup/StudentEmailVerfication';
import StudentAccessCode from './components/OnBoard/Signup/StudentAccessCode';
import StudentUniversity from './components/OnBoard/Signup/StudentUniversity';
import ConnectSchool from './components/Settings/ConnectSchool';
import AccessCodeScreenForSettings from './components/Settings/ConnectSchool/PartnerdUniversity/AccessCode';
import StudentUniversitySelection from './components/Settings/ConnectSchool/NonUniversityPartner/StudentUniversitySeclection';
import EditStudentUniversity from './components/Settings/ConnectSchool/EditStudentUniversity';

const { pathToRegexp } = require("path-to-regexp");

const appConfigData = await fetchConfigarationData();


const Router = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessTokenToApi = useSelector(state => state.accessToken);
  const deeplinkpath = localStorage.getItem("DEEPLINK_PATH");
  const { isAuthenticated } = useOidc()

  const [isClickForgotPassword, setIsClickForgotPassword] = useState(false);
  const isUserLoggedIn = useSelector(state => JSON.parse(state.isLoggedIn));
  const { logout } = useOidc();
  const [isHome, setIsHome] = useState(false);

  let currentPath2 = location.pathname;

  let isUserLoggedIn2 = isUserLoggedIn
  useEffect(() => {
    if (location.pathname.toLowerCase() == '/logout') {
      const deviceIdKey = 'updatedDeviceId';
      clearStorageExceptKey(sessionStorage, deviceIdKey);
      clearStorageExceptKey(localStorage, deviceIdKey);
      store.dispatch({ type: 'LOGOUT' });
      const loginPayload = { isLoggedIn: false };
      dispatch({ type: 'LOG_IN', payload: loginPayload });
    }

    setIsClickForgotPassword(location.pathname === '/forgotpassword');
  }, [location]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (!isUserLoggedIn) {
      // if (currentPath != "/" && currentPath.toLowerCase() != "/login" && currentPath.toLowerCase() != "/forgotpassword" && currentPath.toLowerCase() != "/signup" && currentPath.toLowerCase() != "/hrtransactions" && !currentPath.toLowerCase().includes("/cip")  && currentPath.toLowerCase() != "/download-app" && !currentPath.toLowerCase().includes("/download")  && currentPath.toLowerCase() != '/logout') {
      if (currentPath != "/" && currentPath.toLowerCase() != "/login" && currentPath.toLowerCase() != "/forgotpassword" && currentPath.toLowerCase() != "/signup" && currentPath.toLowerCase() != "/onboard" && currentPath.toLowerCase() != "/hrtransactions" && currentPath.toLowerCase() != "/cip" && currentPath.toLowerCase() != "/download" && currentPath.toLowerCase() != "/download-app" && currentPath.toLowerCase() != '/logout') {
        if (global.isLoggedIn != false) {
          storeData('DEEPLINK_PATH', currentPath)
          const savedDeeplinkPath = { deepLinkPath: currentPath }
          dispatch({ type: 'DEEPLINK_PATH', payload: savedDeeplinkPath });
        }
        if (global.accessTokenReload != '' && global.accessTokenReload != undefined && accessTokenToApi == null) {
          const deviceIdKey = 'updatedDeviceId';
          clearStorageExceptKey(sessionStorage, deviceIdKey);
          clearStorageExceptKey(localStorage, deviceIdKey);
          var url = END_SESSION(appConfigData.AUTH_URL, appConfigData.CLIENT_ID, global.accessTokenReload, appConfigData.POST_LOGOUT_REDIRECT_URI);
          global.accessTokenReload = ''
          window.location.replace(url);
        } else if (global.accessTokenReload != '' && global.accessTokenReload != undefined) {
          setTimeout(() => {
            var url = END_SESSION(appConfigData.AUTH_URL, appConfigData.CLIENT_ID, global.accessTokenReload, appConfigData.POST_LOGOUT_REDIRECT_URI);
            global.accessTokenReload = ''
            window.location.replace(url);
          }, 1000);
        } else {
          navigate('/')
        }
      }
    }
  }, [isUserLoggedIn]);

  const keyMap = {
    disableDevTools: ['f12', 'ctrl+shift+i', 'ctrl+shift+c', 'ctrl+shift+j', 'ctrl+u']
  };
  const handlers = {
    disableDevTools: (event) => {
      event.preventDefault();
    }
  };


  function normaliseString(string) {
    return string.toLowerCase()
  }
  // const handleLogout = async () => {
  //   if (!global.isLoggedIn) {
  //     return
  //   }
  //   global.isLoggedIn = false
  //   await logout()
  //   // let logoutFromApi = await logoutManually();
  //   logoutManually();
  //   const deviceIdKey = 'updatedDeviceId';
  //   clearStorageExceptKey(sessionStorage, deviceIdKey);
  //   clearStorageExceptKey(localStorage, deviceIdKey);
  //   store.dispatch({ type: 'LOGOUT' });
  //   const loginPayload = { isLoggedIn: false };
  //   dispatch({ type: 'LOG_IN', payload: loginPayload });

  // };
  // const logoutManually = async () => {
  //   var url = END_SESSION(appConfigData.AUTH_URL, appConfigData.CLIENT_ID, accessTokenToApi, appConfigData.POST_LOGOUT_REDIRECT_URI);
  //   window.location.replace(url);
  // }

  // useEffect(() => {
  //   // window.history.pushState(null, '', '');
  //   window.addEventListener('beforeunload', function (event) {
  //     if (!ISDEV_ENV) {
  //       if (!global.isLoggedIn) {
  //         return
  //       }
  //       global.isLoggedIn = false
  //       global.accessTokenReload = accessTokenToApi
  //       store.dispatch({ type: 'LOGOUT' });
  //       const loginPayload = { isLoggedIn: false };
  //       dispatch({ type: 'LOG_IN', payload: loginPayload });
  //     }
  //   });

  //   if (location.pathname == '/home') {
  //     setIsHome(true)
  //   } else {
  //     setIsHome(false)
  //   }
  // }, [isUserLoggedIn, location]);
  // useEffect(() => {
  //   window.onpopstate = () => {
  //     if (location.pathname == '/home') {
  //       handleLogout()
  //     }
  //   }
  // }, [isHome])

  useEffect(() => {
    const handlePopState = (event) => {
      window.addEventListener('popstate', (event) => { handlePopState(event) });

      return () => {
        // Cleanup: Remove the event listener when the component unmounts
        window.removeEventListener('popstate', () => { handlePopState() });
      };
    }
  }, [location]);

  return (
    <>
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <Routes>

          {/* Common routes */}
          <Route path={normaliseString('/Summary')} element={<PersonalInfoSummery />} />
          <Route path="/" element={<PreLogin />} />
          {/* <Route path="/" element={<EditStudentUniversity />} /> */}
          <Route path="/settings/accessCode" element={<AccessCodeScreenForSettings />} />
          <Route path="/settings/universitySeclection" element={<StudentUniversitySelection />} />  
          <Route path="/settings/EditUniversity" element={<EditStudentUniversity />} />   
          <Route path="/PreLogin" element={<PreLogin />} />
          {/* <Route path="/" element={<Layout />} /> */}
          <Route path="/hrtransactions" element={<FraudAlert />} />
          <Route path={normaliseString('/hrtransactions')} element={<FraudAlert />} />
          <Route path={normaliseString('/fraudProtection')} element={<FraudProtection />} />
          {/* <Route path={normaliseString('/download')} element={<DownloadTransactionHistory />} /> */}
          <Route path={normaliseString('/login')} element={<PreLogin />} />
          {/* <Route path={normaliseString('/login')} element={<Layout />} /> */} 
          <Route path={normaliseString('/home')} element={<Home />} />
          <Route path={normaliseString('/signup')} element={<PreLogin />} />
          <Route path={normaliseString('/logout')} element={<PreLogin />} />
          <Route path={normaliseString('/forgotpassword')} element={<PreLogin />} />
          <Route path={normaliseString('/Onboard')} element={<OnBoard />} />
          {/* <Route path={normaliseString('/Signup')} element={<OnBoard />}/> */}
          {/* modifying for spending */}
          <Route path={normaliseString('/Checking')} element={<Checking />} />
          <Route path={normaliseString('/Spending')} element={<Checking />} />
          <Route path={normaliseString('/Savings')} element={<Savings />} />
          <Route path={normaliseString('/download-app')} element={<DownloadApp />} />
          <Route path={normaliseString('/ContactUs')} element={<ContactUs />} />


          {/* //testing */}
          <Route path={normaliseString('/personalInfo')} element={<PersonalInfo />} />
          <Route path={normaliseString('/AcceptTerms')} element={<AcceptTerms />} />

 


          {/* Account Services */}


          <Route path={normaliseString('/AccountServices')}>

            <Route path="" element={<AccountServices />} />
            <Route path={normaliseString('Checking')} element={<AccountServicesCheckingCard />} />
            <Route path={normaliseString('Spending')} element={<AccountServicesCheckingCard />} />
            <Route path={normaliseString('/AccountServices/Spending')} element={<AccountServicesCheckingCard />} />
            <Route path={normaliseString('Shared')} element={<AccountServicesSharedCard />} />
            <Route path={normaliseString('Spending/AddFunds')} element={<DepositMoney />} />
            <Route path={normaliseString('Shared/AddFunds')} element={<DepositMoney />} />
            <Route path={normaliseString('Spending/AddCash')} element={<DepositCash />} />
            <Route path={normaliseString('Shared/AddCash')} element={<DepositCash />} />
            <Route path={normaliseString('Spending/ManageDirectDeposit')} element={<ManageDirectDeposit />} />
            <Route path={normaliseString('Shared/ManageDirectDeposit')} element={<ManageDirectDeposit />} />
            <Route path={normaliseString('/AccountServices/Spending/TransferMoney')} element={<TransferMoney />} />
            <Route path={normaliseString('/AccountServices/Shared/TransferMoney')} element={<TransferMoney />} />
            <Route path={normaliseString('Spending/EditAccountName')} element={<EditAccountName />} />
            <Route path={normaliseString('/AccountServices/Spending/ManageDirectDeposit')} element={<ManageDirectDeposit />} />
            <Route path={normaliseString('/AccountServices/Spending/EditAccountName')} element={<EditAccountName />} />
            <Route path={normaliseString('/AccountServices/ManageExternalAccounts/')} element={<ManageExternalAccounts />} />
            <Route path={normaliseString('/AccountServices/ManageExternalAccounts/AddExternalAccount')} element={<AddExternalAccount />} />
            <Route path={normaliseString('/AccountServices/ManageExternalAccounts/VerifyExternalAccount')} element={<VerifyExternalAccount />} />


            {/* <Route path="Checking" element={<AccountServicesCheckingCard />} /> */}
            <Route path={normaliseString('/AccountServices/Spending/AddFunds/ManageDirectDeposit')}
              element={isUserLoggedIn ? <ManageDirectDeposit /> : <Navigate replace to={"/"} />}
            />
            {/* <Route path={normaliseString('/AccountServices/Spending/AddFunds/ManageDirectDeposit')} element={<ManageDirectDeposit />} /> */}
            <Route path={normaliseString('/AccountServices/Spending/AddFunds/AddCash')} element={<DepositCash />} />
            <Route path={normaliseString('Checking/EditAccountName')} element={<EditAccountName />} />
            <Route path={normaliseString('Savings')} element={<AccountServicesSavingCard />} />
            <Route path={normaliseString('Shared')} element={<AccountServicesSharedCard />} />
            <Route path={normaliseString('Savings/EditAccountName')} element={<EditSavingsAccountName />} />

            <Route path={normaliseString('/AccountServices/Savings/TransferMoney')} element={<TransferMoney />} />
            <Route path={normaliseString('Shared/EditAccountName')} element={<EditSharedAccountName />} />
            <Route path={normaliseString('/AccountServices/Shared/TransferMoney')} element={<TransferMoney />} />


          </Route>
          {/* Settings */}
          <Route path={normaliseString('/Settings')} element={<Settings />} />
          <Route path={normaliseString('/Settings/notificationConfigure')} element={<NotificationConfigure />} />
          <Route path={normaliseString('/Settings/manageBeneficiaries')} element={<ManageBeneficiaries />} />
          <Route path={normaliseString('/Settings/manageBeneficiaries/addBeneficiaries')} element={<AddBeneficiary />} />
          <Route path={normaliseString('/Settings/manageBeneficiaries/editBeneficiary')} element={<EditBeneficiary />} />
          <Route path={normaliseString('/Settings/manageBeneficiaries/editAddBeneficiary')} element={<EditBeneficiary />} />
          <Route path={normaliseString('/AccountServices/Spending/ManageAuthorizedUsers')} element={<ManageAuthorizedusers />} />
          <Route path={normaliseString('/AccountServices/Spending/ManageAuthorizedUsers/AddAuthorizedUsers')} element={<AddAuthorizedUsers />} />
          <Route path={normaliseString('/AccountServices/Spending/ManageAuthorizedUsers/EditAuthorizedUser')} element={<EditAuthorizedUser />} />
          <Route path={normaliseString('/Settings/ManageExternalAccounts')} element={<ManageExternalAccounts />} />
          <Route path={normaliseString('/Settings/ManageAccounts')} element={<ManageAccounts />} />
          <Route path={normaliseString('/Settings/ManageExternalAccounts/AddExternalAccount')} element={<AddExternalAccount />} />
          <Route path={normaliseString('/Settings/ManageExternalAccounts/VerifyExternalAccount')} element={<VerifyExternalAccount />} />         
          <Route path={normaliseString('/Settings/ConnectSchool')} element={<ConnectSchool />} />

          




          {/* Notifications */}
          <Route path={normaliseString('/notifications')} element={<Notifications />} />
          <Route path={normaliseString('/notifications/:id')} element={<NotificationItemCard />} />

          {/* <Route path="/notifications" element={<Notifications />} />
      <Route path="/notificationConfigure" element={<NotificationConfigure />} /> */}

          {/* Card Servieces */}
          <Route path={normaliseString('/CardServices')} element={<CardServices />} />
          <Route path={normaliseString('/CardServices/CardActionScreen')} element={<CardActionScreen />} />
          <Route path={normaliseString('/CardServices/CardActionScreen/ReplaceCard')} element={<ReplaceCard />} />
          <Route path={normaliseString('/CardServices/CardActionScreen/ChangePin')} element={<ChangePin />} />
          <Route path={normaliseString('/CardServices/CardActionScreen/ViewCard')} element={<ViewCard />} />
          <Route path={normaliseString('/CardServices/CardActionScreen/ActivateCard')} element={<ActivateCard />} />


          {/* {Help} */}


          <Route path={normaliseString('/Help')}>

            <Route path="" element={<Help />} />
            <Route path={normaliseString('/Help/FAQ')} element={<HelpAndFAQ />} />
            <Route path={normaliseString('/Help/FindATM')} element={<ATM />} />
            <Route path={normaliseString('/Help/ContactUs')} element={<ContactUs />} />
            <Route path={normaliseString('/Help/AboutKudzu')} element={<AboutKudzu />} />
          </Route>

          {/* {Documents} */}

          <Route path={normaliseString('/Documents')}>
            <Route path="" element={<Documents />} />
            <Route path={normaliseString('/Documents/Policies')} element={<PrivacyAndLegal />} />
            <Route path={normaliseString('/Documents/Policies/:agreementName')} element={<AgreementCard />} />
            <Route path={normaliseString('/Documents/Statements')} element={<Statements />} />
            <Route path={normaliseString('/Documents/TaxDocuments')} element={<TaxDocuments />} />
            <Route path={normaliseString('/Documents/Fees')} element={<Fees />} />
            {/* <Route path={normaliseString('/Documents/Policies/Fees')} element={<Fees />} /> */}
          </Route>

          {/* Climb */}

          <Route path={normaliseString('/Climb')}>

            <Route path=""
              element={isUserLoggedIn ? <Climb /> : <Navigate replace to={"/"} />}
            />
            {/* <Route path="" element={<Climb />} /> */}
            <Route path={normaliseString('/Climb/ClimbMenu')} element={<ClimbMenu />} />
            <Route path={normaliseString('/Climb/ClimbSettings')} element={<ClimbSettings />} />


            {/* Spend Alerts */}
            <Route path={normaliseString('/Climb/AddSpendAlerts')} element={<SpendSenseAlerts />} />
            <Route path={normaliseString('/Climb/ManageSpendAlerts')} element={<ManageSpendAlerts />} />
            <Route path={normaliseString('/Climb/SpendSenseAlertss')} element={<ManageSpendAlerts />} />
            <Route path={normaliseString('/Climb/SpendSenseAlerts/EditSpend')} element={<EditSpendAlerts />} />


            {/* Savings Habit */}
            {/* <Route path="/Climb/SavingsHabit" element={<SavingsHabit />} /> */}
            <Route path={normaliseString('/Climb/AddSavingsHabit')} element={<AddSavingsHabit />} />
            <Route path={normaliseString('/Climb/ManageSavingsHabit')} element={<ManageSavingsHabit />} />
            <Route path={normaliseString('/Climb/SavingsHabit')} element={<ManageSavingsHabit />} />
            <Route path={normaliseString('/Climb/SavingsHabit/EditHabit')} element={<EditSavingsHabit />} />
            <Route path={normaliseString('/Climb/CoveredByKudzu')} element={<CoveredByKudzu />} />
            <Route path={normaliseString('/Climb/ManageCoveredByKudzu')} element={<ManageCoveredByKudzu />} />

          </Route>

          {/* Personal Information */}

          <Route path={normaliseString('/PersonalInformation')}>
            <Route path="" element={<PersonalInformation />} />
          </Route>

          {/* Setup Savings Account */}

          <Route path={normaliseString('/SetupSavingsAccount')}>
            <Route path="" element={<SetupSavingsAccount />} />
            <Route path={normaliseString('/SetupSavingsAccount/SavingsTermsAndConditions')} element={<SavingsTermsAndConditions />} />
            <Route path={normaliseString('/SetupSavingsAccount/SavingsSSNSetup')} element={<SavingsSSNSetup />} />

          </Route>

          {/* security */}

          <Route path={normaliseString('/Security')}>
            <Route path="" element={<Security />} />
            <Route path={normaliseString('/Security/ChangePassword')} element={<ChangePassword />} />
            <Route path={normaliseString('/Security/TrustedDevice')} element={<TrustedDevices />} />
          </Route>

          {/* //Payperks */}

          <Route path={normaliseString('/PayPerks')}
            //  element={<PayPerks />} 
            element={isUserLoggedIn ? <PayPerks /> : <Navigate replace to={"/"} />}
          />




          <Route path={normaliseString('/swalalert')}>
            <Route path="" element={<SwalAlert />} />
          </Route>


          <Route path={normaliseString('/redirectModal')}>
            <Route path="" element={<RedirectModal />} />
          </Route>
          <Route path={normaliseString('/download')}>
            <Route path="" element={<DownloadTransactionHistory />} />
          </Route>


          {/* <Route path="/hrtransactions" >
        <Route path="" element={<FraudAlert />} />
      </Route> */}

          <Route path="/cip" >
            <Route path="" element={<FraudProtection />} />
          </Route>


          <Route path={normaliseString('/manageSettings')}>
            <Route path="" element={<ManageSettings />} />
          </Route>

          {/* TransactionCard */}
          <Route path={normaliseString('/transactionCard')}>
            <Route path="" element={<TransactionCard />} />
          </Route>
        </Routes>
      </HotKeys>
    </>


  );
};

export default Router;
