import React, { useState, useEffect } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { width } from "../CustomComponents/Card/CardStyles";
import { useTranslation } from 'react-i18next';
import HomeLayout from '../Layout/HomeLayout';
import DynamicCard from '../CustomComponents/DynamicHomeCard';
import SecurityLock from '../../assets/images/illo-security.png';
import { useNavigate } from 'react-router';
import FooterContent from '../CustomComponents/Footer';
import Bell from '../../assets/images/Bell.png';
import { SecuritySidebar } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import LineBreaks from '../../utils';
import { retrieveData } from '../../helpers/utils/Utils';
import { LOGIN_SESSION_KEY } from '../../Redux/reducers';
import { GetApiHeadersWithBearer, PostRequest, GetRequest } from '../../network/NetworkUtils';
import Modal from '../CustomComponents/Modal';
import Custom2FA from '../CustomComponents/Custom2FA';
import Loader from '../CustomComponents/Loader';
import LeftBar from '../CustomComponents/DynamicComponent/LeftBar';
import TwoFactTypeSelctionModal from '../CustomComponents/TwoFactTypeSelctionModal';

import { GENERATE_OTP_CHANGE_PASSWORD, STATUS_SUCCESS_CODE } from '../../network/URLConstants';
import logFirebaseEvents from '../LogFirebaseEvents';

const Security = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [selectedType, setSelectedType] = useState('');
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const cardDetails = useSelector(state => state.selectedCardDetails);
    const [loading, setLoading] = useState(false)
    const [infoMessage, setinfoMessage] = useState('');
    const [responseCode, setResponseCode] = useState('');
    const [twoFactModelTitle, setTwoFactModelTitle] = useState('');
    const [twoFactModelSubTitle, setTwoFactModelSubTitle] = useState('');
    const [twoFactModelContent, setTwoFactModelContent] = useState('');
    const [showStnadradRatesMessage, setShowStnadradRatesMessage] = useState(false);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const [isLoading, setIsLoading] = useState(false);
    const [show2faSelection,setshow2faSelection] = useState(false);
    const [selectedOtpType,setSelectedOtpType] = useState('');

    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";

    const footerContent = () => {
        return (
            <FooterContent highlightText={t("notification_settings")}
                subHeaderText={t("notifications_for_events_and_messages")}
                image={Bell} buttonEnabled={true}
                isSpace={true}
                buttonText={t("configure_notifications")}
                footerBtnAction={() => { navigate('/Settings/notificationConfigure');    logFirebaseEvents("Security", "Configuration_Notification_Clicked", "Configuration_Notification_Clicked", "",""); }}
               />
        )
    }
    const handleChangePasswordClick = () => {
        logFirebaseEvents("Security", "Change_Password_Clicked", "Change_Password_Clicked", "","");
        if (twoFactAuthenticationDone) {
            navigate('/Security/ChangePassword');
        } else {
            // alert("change password need to do")
            // generate2FAOtp(t('change_password'))
            setshow2faSelection(true)
            setIsOTPVerifyModalOpen(false)
        }
    };
    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }
    const generate2FAOtp = async (otpType,procType) => {
        if (cards == undefined || cards == null){
            return
        }
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        setIsLoading(true)

        var object = {};
        var url = "";
        const isEmail = procType === 'Email' ? true : false;
        if (otpType == t('change_password')) {
            object = {
            "IsEmail": isEmail
            }
            url = GENERATE_OTP_CHANGE_PASSWORD.replace('{CARD_KEY}', cards[0].CardKey);
        }



        try {
            const response = await PostRequest(url, object, headers);


            const parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false)
                //adding sessionid to redux
                const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                //adding userid to redux
                const userIdPayload = { userId: parsedResponse.Response.userId }
                dispatch({ type: 'USER_ID', payload: userIdPayload });
                if (otpType == t('change_password')) {
                    setSelectedType(t('change_password'));
                    setTwoFactModelTitle(t('great_exclamation'));
                    setTwoFactModelSubTitle(procType != 'Email' ? t('we_are_sending_messsage') : t('we_are_sending_email_message'));
                    setShowStnadradRatesMessage(procType != 'Email' ? true : false)
                    setIsOTPVerifyModalOpen(true);
                }


            } else {
                setIsLoading(false)
                const arrMessages = parsedResponse.Messages;
                const message = arrMessages[0];
                const displayText = message.DisplayText;
                setinfoMessage(displayText);
                setResponseCode(parsedResponse.ResponseCode);
                setTimeout(() => {
                    setinfoMessage('');
                    setResponseCode('');
                }, 5000);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const verificationSuccess = (type) => {
        if (type == t('change_password')) {
            navigate('/Security/ChangePassword');
        }

    }
    const verificationFaiure = (type, message) => {
        // alert(message)

    }
    const leftBarComp = () => {
        return (
            <div>
                <p className="text-white font-normal text-[20px] leading-[30px] md:text-[18px] md:leading-[24px] lg:text-[32px] lg:leading-[38px] font-serif-pro ">{t("account_secure")}</p>
                <hr className=" h-[2.5px] mx-auto my-4 bg-[#4B70B9] bg-opacity-50 border-0 rounded md:my-10" />

                <div className=' flex flex-col gap-10 cursor-pointer'>
                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#FEFAEE]' onClick={() => navigate("/Security/TrustedDevice")}>{t("trusted_devices")}</p>
                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#FEFAEE]' onClick={() => navigate("/Security/ChangePassword")}>{t("change_password")}</p>
                </div>
            </div>
        )
    }
    return (
        <>
            <HomeLayout>
                <SecuritySidebar changePasswordClicked={handleChangePasswordClick} />
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        CardRadius={true}
                        cardWidth={width.allpolicies}
                        HeaderRadius={true} 
                        isWhite={true}
                        HeaderContent={false}>
                        <div className='md:py-20 sm:py-12 py-12 sm:mx-0 mx-4'>
                            <div className='sm:flex justify-between block'>
                                <div>
                                    <span className='font-serif-pro text-[#559C7E] 
                                   lg:text-[32px] md:text-[30px] sm:text-[22px] text-[25px]
                                    font-normal leading-[38px]'>
                                        {t("Security")} </span>

                                    <p className='mt-4 sm:text-[#0C4437] font-[400px] text-[#262B3A]
                                    md:text-[20px] sm:text-[12px] text-[14px]
                                    md:leading-[30px] sm:leading-[20px] leading-[20px]
                                    lg:w-[406px] md:w-[333px] sm:w-[250px]
                                    lg:h-[252px] md:h-[175px] h-[170px] sm:font-medium font-hanken-grotesk
                                    '>
                                        {t("Manage_your_security")}</p>
                                </div>
                                <div className='flex justify-center sm:pb-0 pb-20'>
                                    <img src={SecurityLock} alt="Security" className='lg:w-[120px] md:w-[100px] sm:w-[92px] 
                                    lg:h-[120px] md:h-[100px] sm:h-[92px] h-[120px] w-[120px]'/>
                                </div>
                            </div>
                        </div>
                    </DynamicCard>
                    {footerContent()}
                </div>
            </HomeLayout>
            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={closeOTPVerifyModal}
                isLogo={false}
                title={<p className={HighlightMsgStyles}> {t(twoFactModelTitle)}</p>}
                subtitle={<><p className={HeaderMsgStyles}>{t(twoFactModelSubTitle)}</p> {showStnadradRatesMessage && <p className="font-hanken-grotesk my-3 text-[#262B3A]  lg:text-[20px] md:text-[18px] sm:text-[15px] text-[18px] leading-[30px] font-[500]  w-full">{t('standrd_message_rates_apply')}</p>}</>}
                content={<div className="mt-10"><Custom2FA selected2FAType={selectedOtpType} phoneNumber={""} email={""} address={{}} type={selectedType} onClose={closeOTPVerifyModal} verificationFailure={verificationFaiure} verificationSuccess={verificationSuccess} /></div>}
            />
            <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false) }}
                onClickNext={(object) => {
                    setshow2faSelection(false);
                    setSelectedOtpType(object.proc)
                    generate2FAOtp(t('change_password'),object.proc)
                }} />
            {isLoading && <Loader />}

        </>

    )
}

export default withOidcSecure(Security)