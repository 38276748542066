import React, { useState, useEffect } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../CustomComponents/Loader';
import CustomTextInput from '../../../../CustomComponents/CustomtextInput';
import {
    GENERATE_EXISTING_PHONE_EMAIL_OTP,
    UPDATE_PHONE_NUMBER,
    GENERATE_UPDATE_EMAIL_OTP,
    GENERATE_UPDATE_ADDRESS_OTP,
    GET_USER_PROFILE_V2,
    GET_USER_PROFILE_LANGUGAGE_LIST,
    GET_USER_PROFILE_TIMEZONE_LIST,
    GENERATE_OTP_CHANGE_PIN, STATUS_SUCCESS_CODE,
    OTP_USERS, POST_UPDATE_USER_PROFILE,
    GOOGLE_PLACE_DETAILS_WITH_PLACE_ID,
    GOOGLE_PLACES_AUTO_COMPLETE,
    VERIFY_UPDATE_ADDRESS_OTP,
    UPDATE_PREFERRED_NAME
} from '../../../../../network/URLConstants';
import { GetApiHeadersWithBearer, PostRequest, GetRequest } from '../../../../../network/NetworkUtils';
import { displayErrorMessage, displaySuccessMessage, parseGoogleLocationResponse, isEmailValid, validateMobileNumber } from '../../../../../utils';
import LineBreaks from '../../../../../utils';
import { retrieveData } from '../../../../../helpers/utils/Utils';
import { personalFormatPhoneNumber } from '../../../../../utils';
import HomeLayout from '../../../../Layout/HomeLayout';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import CustomDropdown from '../../../../CustomComponents/CustomDropDown';
import Custom2FA from '../../../../CustomComponents/Custom2FA';
// import DynamicCard from '../../../../CustomComponents/DynamicCard';
import { LOGIN_SESSION_KEY } from '../../../../../Redux/reducers';
import Modal from '../../../../CustomComponents/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { set } from 'react-hook-form';
import { fetchConfigarationData } from '../../../../../helpers/utils/Utils';
import { type } from '@testing-library/user-event/dist/type';
import CustomAlertModel from '../../../../CustomComponents/CustomAlertModel';
// import address_update_Succss from '../../../../../assets/images/address_update_Succss.png';
import address_update_Succss from '../../../../../assets/images/address-updated.png';
import email_update_success from '../../../../../assets/images/email_update_success.png';
import updatedphone_success from '../../../../../assets/images/updatedphone_success.png';
import fb from '../../../../../assets/images/fb.png';
import { useKeyDown } from '../../../../../hooks/useKeyDown';
import { useNavigate } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import TwoFactTypeSelctionModal from "../../../../CustomComponents/TwoFactTypeSelctionModal";
import CustomInput from '../../../../CustomComponents/FormInputs';
import logFirebaseEvents from '../../../../LogFirebaseEvents';
const appConfigData = await fetchConfigarationData();



const PersonalInformation = ({ transactionDatas, onViewAllClick, viewAll }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook to access translation functions
    const cards = useSelector(state => JSON.parse(state.userCards));
    const [isLoading, setIsLoading] = useState(false);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [editClicked, setEditClicked] = useState(false);
    const [PersonalInfoObject, setPersonalInfoObject] = useState([]);
    const [homeAddress, setHomeAddress] = useState('');
    const [prevHomeAddressObj, setPrevHomeAddressObj] = useState('');
    const [languageList, setLanguageList] = useState([]);
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [phoneNumberEditClicked, setPhoneNumberEditClicked] = useState(false);
    const [preferredNameEditClicked, setpreferredNameEditClicked] = useState(false);
    const [preferredName, setpreferredName] = useState('');
    const [emailAdressEditClicked, setEmailAdressEditClicked] = useState(false);
    const [addressEditClicked, setAddressEditClicked] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState({
        Type: "",
        Name: ""
    },);

    const [selectedTimeZone, setSelectedTimeZone] = useState({
        Type: "",
        Name: ""
    });
    const [selectedPohoneNumber, setSelectedPohoneNumber] = useState('');
    const [formatedPhoneNumber, setFormatedPhoneNumber] = useState('');
    const [selectedEmailAdress, setSelectedEmailAdress] = useState('');
    const [predictions, setPredictions] = useState([]);
    const [typedValue, setTypedValue] = useState("");
    const [selectedAddress, setSelectedAddress] = useState('');
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const [modelImage, setModelImage] = useState('')
    const [newName, setNewName] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const selectedItem = cards?.[0]
    const userId = useSelector(state => state.userId);

    const [loading, setLoading] = useState(false)
    const [infoMessage, setinfoMessage] = useState('');
    const [responseCode, setResponseCode] = useState('');
    const [selectedAddressLine2, setSelectedAddressLine2] = useState('');
    const [line1AdressFilled, setLine1AdressFilled] = useState(false);
    const [homeAddressToserver, setHomeAddressToserver] = useState('');
    const [AddressError, setAddressError] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const [zipCodeError, setZipCodeError] = useState('');
    const [isAddress, setAddress] = useState('');
    const [displayEmail, setDisplayEmail] = useState('')

    const validationSchema = yup.object().shape({
        address: yup.string().required(t('enter_address')),
        apt_etc: yup.string(),
        city: yup.string().required('Please enter a city'),
        state: yup.string().required('Please enter a state'),
        zip_code: yup.string().required('Please enter a valid zip code'),
    });



    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none text-black";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');

    const [twoFactModelTitle, setTwoFactModelTitle] = useState('');
    const [twoFactModelSubTitle, setTwoFactModelSubTitle] = useState('');
    const [twoFactModelContent, setTwoFactModelContent] = useState('');
    const [showStnadradRatesMessage, setShowStnadradRatesMessage] = useState(false);
    const [isLangAndTimeZoneClicked, setIsLangAndTimeZoneClicked] = useState(true);
    const [show2faSelection, setshow2faSelection] = useState(false);
    const [selectedOtpType, setSelectedOtpType] = useState('');
    const [slectedFieldToEdit, setSlectedFieldToEdit] = useState('');

    const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
    });


    useEffect(() => {
        console.log('874512', selectedOtpType)
    }, [selectedOtpType])

    const handleRevertClick = () => {
        // navigate(-1)
        logFirebaseEvents("Personal_Information", "Back_Personal_Info_Clicked", "Back_Personal_Info_Clicked", "","");
        navigate('/home')
    };

    const handleSaveClick = () => {
        logFirebaseEvents("Personal_Information", "Save_Personal_Info_Clicked", "Save_Personal_Info_Clicked", "","");
        onUpdateProfileClicked();
    };

    useEffect(() => {
        getPersonalInformation();
        getTimeZoneList();
        getLanguageList();

    }, []);


    const getPersonalInformation = async () => {
        if (selectedItem == undefined || selectedItem == null) {
            return
        }
        setLoading(true)
        var url = GET_USER_PROFILE_V2 + 'cardKey=' + selectedItem?.CardKey
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var response = await GetRequest(url, headers)
        try {
            setTimeout(() => {
                setLoading(false)
            }, 500);
            var responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                if (responseObj.Response != undefined) {

                    const tempLanguage = {
                        Name: responseObj.Response.PreferredLanguageText,
                        Type: responseObj.Response.PreferredLanguage
                    }
                    setSelectedLanguage(tempLanguage);
                    setpreferredName(responseObj.Response.PreferredName)

                    const tempTimeZone = {
                        Name: responseObj.Response.TimezoneText,
                        Type: responseObj.Response.Timezone
                    };
                    setSelectedTimeZone(tempTimeZone);

                    setPersonalInfoObject(responseObj.Response);
                    setDisplayEmail(responseObj.Response.EmailID)

                    // setSelectedEmailAdress(responseObj.Response.EmailID);

                    setSelectedPohoneNumber(responseObj.Response.Mobile1.includes("x") ? '' : responseObj.Response.Mobile1);
                    setFormatedPhoneNumber(responseObj.Response.Mobile1);

                    // let homeAddress = responseObj.Response.HomeAddress.Line1 + "," + responseObj.Response.HomeAddress.Line2 + "," + responseObj.Response.HomeAddress.City + "," + responseObj.Response.HomeAddress.StateCode + "," + responseObj.Response.HomeAddress.CountryCode + "," + responseObj.Response.HomeAddress.ZipCode + "," + responseObj.Response.HomeAddress.Country + "," + responseObj.Response.HomeAddress.State

                    const addressParts = [
                        responseObj.Response.HomeAddress?.Line1,
                        responseObj.Response.HomeAddress?.Line2,
                        responseObj.Response.HomeAddress?.City,
                        responseObj.Response.HomeAddress?.State,
                        // responseObj.Response.HomeAddress?.StateCode,
                        // responseObj.Response.HomeAddress?.CountryCode,
                        responseObj.Response.HomeAddress?.ZipCode,
                        // responseObj.Response.HomeAddress?.Country,
                    ];
                    debugger;

                    const homeAddress = addressParts.filter(part => part).join(', ');

                    setHomeAddress(homeAddress);
                    setHomeAddressToserver(
                        {
                            "line1": responseObj.Response.HomeAddress?.Line1,
                            "line2": responseObj.Response.HomeAddress?.Line2,
                            "city": responseObj.Response.HomeAddress?.City,
                            "state": responseObj.Response.HomeAddress?.State,
                            "zipCode": responseObj.Response.HomeAddress?.ZipCode,
                            "countryCode": responseObj.Response.HomeAddress?.CountryCode,
                            "country": responseObj.Response.HomeAddress?.Country,
                            "cardKey": selectedItem?.CardKey,
                            // "formattedAddress": homeAddress,
                        }
                    )
                }
            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
            }
        } catch (e) {
            setLoading(false)
        }
    }
    const getLanguageList = async () => {
        if (selectedItem == undefined || selectedItem == null) {
            return
        }
        setLoading(true)
        var url = GET_USER_PROFILE_LANGUGAGE_LIST + 'cardKey=' + selectedItem?.CardKey
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var response = await GetRequest(url, headers)
        try {
            setTimeout(() => {
                setLoading(false)
            }, 500);
            var responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                if (responseObj.Response != undefined) {
                    const responseLanguageList = responseObj.Response;
                    const transformedLanguageList = responseLanguageList.map(language => ({
                        Type: language.ParameterCode,
                        Name: language.DisplayText
                    }));
                    setLanguageList(transformedLanguageList);

                }
            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
            }
        } catch (e) {
            setLoading(false)
        }
    }
    const getTimeZoneList = async () => {
        if (selectedItem == undefined || selectedItem == null) {
            return
        }
        setLoading(true)
        var url = GET_USER_PROFILE_TIMEZONE_LIST + 'cardKey=' + selectedItem?.CardKey
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var response = await GetRequest(url, headers)
        try {
            setTimeout(() => {
                setLoading(false)
            }, 500);
            var responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                if (responseObj.Response != undefined) {
                    const timeZoneList = responseObj.Response;

                    const transformedTimeZones = timeZoneList.map(timeZone => ({
                        Type: timeZone.ParameterCode,
                        Name: timeZone.DisplayText
                    }));
                    setTimeZoneList(transformedTimeZones);


                }
            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
            }
        } catch (e) {
            setLoading(false)
        }
    }



    const editPreferredName = () => {
        setinfoMessage("")

        setpreferredNameEditClicked(true)
        setPhoneNumberEditClicked(false)
        setEmailAdressEditClicked(false)
        setAddressEditClicked(false)

    }
    const upadatePreferredNameApiCall = async () => {
      
        setTimeout(async () => {
            const deviceId = await retrieveData('updatedDeviceId');
            const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
            var object = {

                "preferredName": preferredName
            }
            var url = UPDATE_PREFERRED_NAME(selectedItem?.CardKey)
            setLoading(true)
            var response = await PostRequest(url, object, headers)
            setTimeout(() => {
                setLoading(false)
            }, 500);
            let parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                setpreferredNameEditClicked(false);
                getPersonalInformation();
                dispatch({type:"UPDATE_PREFERRED_NAME", payload:{preferredName:preferredName}})
                showAlertModal(true, t('success'), t('editPreferredNameSuccessMsg'), '', true, false, t('done'), '', true, address_update_Succss);
            } else {
                const arrMessages = parsedResponse.Messages;
                const message = arrMessages[0];
                const displayText = message.DisplayText;
                setinfoMessage(displayText);
                setResponseCode(parsedResponse.ResponseCode);
                setTimeout(() => {
                    setinfoMessage('');
                    setResponseCode('');
                }, 5000);
            }
        }, 500);
    }

    const updatePreferredName = () => {
        if (!preferredName) {
            setinfoMessage("Preferred name is required")
        } else {
            setinfoMessage('')
            upadatePreferredNameApiCall()
        }
    }
    const editPhoneNumberClicked = async () => {
     logFirebaseEvents("Personal_Information", "Update_Phone_Number_Clicked", "Update_Phone_Number_Clicked", "","");
        setinfoMessage("")
        if (!twoFactAuthenticationDone) {
            setSlectedFieldToEdit(t('old_mobile'))
            setshow2faSelection(true);
            setIsOTPVerifyModalOpen(false)
            // generate2FAOtp(t('old_mobile')); 
        }
        else {
            setPhoneNumberEditClicked(true)
            setpreferredNameEditClicked(false)
            setEmailAdressEditClicked(false)
            setAddressEditClicked(false)
        }
    }
    const updatePhoneNumber = async () => {
        logFirebaseEvents("Personal_Information", "Save_Phone_Number_Clicked", "Save_Phone_Number_Clicked", "","");
        const errorMessage = validateMobileNumber(selectedPohoneNumber)
        // alert(errorMessage);
        if (errorMessage == 'Mobile number field is empty') {
            setinfoMessage(t('please_enter_phone_number'))
        } else if (errorMessage == 'not valid') {
            setinfoMessage(t('please_enter_valid_phone_number'))
        } else {
            generate2FAOtp(t('new_mobile'), '');
        }
    }
    const editEmailClicked = async () => {
        logFirebaseEvents("Personal_Information", "Update_Email_Address_Clicked", "Update_Email_Address_Clicked", "","");
        setinfoMessage("")
        if (!twoFactAuthenticationDone) {
            setSlectedFieldToEdit(t('old_email'))
            setshow2faSelection(true);
            setIsOTPVerifyModalOpen(false)
            // generate2FAOtp(t('old_email'));
        }
        else {
            setEmailAdressEditClicked(true)
            setpreferredNameEditClicked(false)
            setPhoneNumberEditClicked(false)
            setAddressEditClicked(false)
        }
    }
    const updateEmailAdressClicked = async () => {
        logFirebaseEvents("Personal_Information", "Save_Email_Address_Clicked", "Save_Email_Address_Clicked", "","");
        const errorMessage = isEmailValid(selectedEmailAdress)
        if (errorMessage == 'empty') {
            setinfoMessage(t('please_enter_your_email_address'))
        } else if (errorMessage == 'not valid') {
            setinfoMessage(t('please_enter_a_valid_email_address'))
        } else {
            setSelectedOtpType('Email')
            generate2FAOtp(t('new_email'));
        }

    }
    const editAddressClicked = async () => {
        logFirebaseEvents("Personal_Information", "Update_Address_Clicked", "Update_Address_Clicked", "","");
        clearErrors();

        setinfoMessage("")
        if (!twoFactAuthenticationDone) {

            setSlectedFieldToEdit(t('old_address'))
            setshow2faSelection(true);
            setIsOTPVerifyModalOpen(false)
            //  generate2FAOtp(t('old_address')); 
        }
        else {
            setAddressEditClicked(true);
            setPhoneNumberEditClicked(false);
            setEmailAdressEditClicked(false);
            setpreferredNameEditClicked(false)

            setAddress(homeAddressToserver?.line1);
            setValue('address', homeAddressToserver?.line1);
            setValue('apt_etc', homeAddressToserver?.line2);
            setValue('city', homeAddressToserver?.city);
            setValue('state', homeAddressToserver?.state);
            setValue('zip_code', homeAddressToserver?.zipCode);

            setTimeout(() => {
                const inputElement = document.getElementById('addressInput');
                if (inputElement) {
                    inputElement.focus();
                }
            }, 500);
        }

    }
    // const updateHomeAddress = async (data) => {
    //     const formData = getValues();

    //     if (!twoFactAuthenticationDone) {
    //         if (!isAddress) setAddressError('Address is required')
    //         else {
    //             setAddressError('')
    //             generate2FAOtp(t('old_address'), '');

    //         }
    //         // if (typedValue.trim() == '') {
    //         //     setinfoMessage(t('please_enter_address'))
    //         // } else {
    //         //     // generate2FAOtp(t('new_address'));

    //         //     generate2FAOtp(t('old_address'), '');
    //         // }
    //     }
    //     else {
    //         if (typedValue.trim() == '') {
    //             setinfoMessage(t('please_enter_address'))
    //         } else {
    //             setSelectedType(t('new_address'));
    //             // setAddressEditClicked(true);
    //             // setIsOTPVerifyModalOpen(true);
    //             generate2FAOtp(t('new_address'), '');
    //         }

    //     }


    // }

    const updateHomeAddress = async (data) => {
        logFirebaseEvents("Personal_Information", "Save_Address_Clicked", "Save_Address_Clicked", "","");
        const formData = getValues();
        console.log("formdata", formData)

        try {
            if (isAddress != '' && formData.city != '' && formData.state != '' && formData.zip_code != '') {
                clearErrors();

                setAddressError('');
                setCityError('');
                setStateError('');
                setZipCodeError('');
                console.log("formData", formData)
                const formattedAddress1 = `${isAddress && `${isAddress}, `}${formData.apt_etc && `${formData.apt_etc}, `}${formData.city && `${formData.city}, `}${formData.state && `${formData.state}, `}${formData.zip_code && `${formData.zip_code}`}`
                const updatePersonalObj = {
                    ...homeAddressToserver,
                    "line1": isAddress,
                    "line2": formData.apt_etc,
                    "city": formData.city,
                    "state": formData.state,
                    "zipCode": formData.zip_code,
                    // "formattedAddress": formattedAddress1,

                }
                console.log("updatePersonalObj", updatePersonalObj)
                setHomeAddressToserver(updatePersonalObj)

                if (!twoFactAuthenticationDone) {

                    setSelectedType(t('old_address'));
                    generate2FAOtp(t('old_address'), '');
                }
                else {

                    // setSelectedType(t('new_address'));
                    generate2FAOtp(t('new_address'), '', updatePersonalObj);
                }
                // setAddressEditClicked(false);
            }
            else {
                await validationSchema.validate(data, { abortEarly: false });
            }
            if (!isAddress) setAddressError('Address is required')
            else setAddressError('')

        } catch (error) {
            if (!isAddress) setAddressError('Address is required')
            else setAddressError('')
            if (error.inner) {
                const validationErrors = {};
                error.inner.forEach((err) => {
                    if (typeof err.message === 'string') {

                        if (err.path === 'city') {
                            setCityError(err.message);
                            if (formData.city) {
                                setCityError('');
                            }
                        }
                        else if (err.path === 'state') {
                            setStateError(err.message);
                            if (formData.state) {
                                setStateError('');
                            }
                        }
                        else if (err.path === 'zip_code') {
                            setZipCodeError(err.message);
                            if (formData.zip_code) {
                                setZipCodeError('');
                            }
                        }
                        // Handle other fields if needed
                    }
                });
            }

        }



    }
    const updateLanguage = async () => {
    }
    const updateTimeZone = async () => {
    }

    const generate2FAOtp = async (otpType, procType, objForNewAddress) => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        var object = {};
        var url = "";
        if (otpType == t('new_mobile')) {
            object = {
                "mobileNumber": selectedPohoneNumber,
                "cardKey": selectedItem?.CardKey
            }
            url = UPDATE_PHONE_NUMBER
        } else if (otpType == t('old_email')) {
            let isEmail
            if (procType) {
                isEmail = procType == 'Email' ? true : false;
            }
            object = {
                "cardKey": selectedItem?.CardKey,
                "action": "email",
                "IsEmail": isEmail
            }
            url = GENERATE_EXISTING_PHONE_EMAIL_OTP;
        }
        else if (otpType == t('new_email')) {
            object = {

                "email": selectedEmailAdress,
                "cardKey": selectedItem?.CardKey,
            }
            var url = GENERATE_UPDATE_EMAIL_OTP

        } else if (otpType == t('old_address')) {
            const isEmail = selectedOtpType === 'Email' ? true : false;
            object = {
                "action": "address",
                "cardKey": selectedItem?.CardKey,
                "IsEmail": isEmail
            }
            var url = GENERATE_EXISTING_PHONE_EMAIL_OTP
        }
        else if (otpType == t('new_address')) {
            console.log("homeAddressToserver---", objForNewAddress)
            const reqObj = {
                Line1: objForNewAddress?.line1,
                Line2: objForNewAddress?.line2,
                City: objForNewAddress?.city,
                State: objForNewAddress?.state,
                CountryCode: objForNewAddress?.countryCode,
                ZipCode: objForNewAddress?.zipCode,
            }
            object = objForNewAddress;
            // object = homeAddressToserver;
            object.cardKey = selectedItem?.CardKey

            url = VERIFY_UPDATE_ADDRESS_OTP;

        }
        else {
            const isEmail = selectedOtpType === 'Email' ? true : false;
            object = {
                "cardKey": selectedItem?.CardKey,
                "action": otpType == t('old_email') ? "email" : "mobile",
                "IsEmail": isEmail
            }
            url = GENERATE_EXISTING_PHONE_EMAIL_OTP;
        }


        setLoading(true);
        try {
            const response = await PostRequest(url, object, headers);
            setLoading(false);

            const parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode === STATUS_SUCCESS_CODE) {
                //adding sessionid to redux
                const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                //adding userid to redux
                const userIdPayload = { userId: parsedResponse.Response.userId }
                dispatch({ type: 'USER_ID', payload: userIdPayload });
                if (otpType == t('old_email')) {
                    setSelectedType(t('old_email'));
                    // setEmailAdressEditClicked(true);
                    setSelectedType(t('old_email'));
                    setTwoFactModelTitle(show2faSelection == true ? t('great_exclamation') : t('security_check'));
                    setTwoFactModelSubTitle(procType != 'Email' ? t('we_are_sending_messsage') : t('we_are_sending_email_message'));
                    setShowStnadradRatesMessage(procType != 'Email' ? true : false)

                    setIsOTPVerifyModalOpen(true);
                } else if (otpType == t('old_mobile')) {
                    //setPhoneNumberEditClicked(true);
                    setSelectedType(t('old_mobile'));
                    setTwoFactModelTitle(show2faSelection == true ? t('great_exclamation') : t('security_check'));
                    setTwoFactModelSubTitle(procType != 'Email' ? t('we_are_sending_messsage') : t('we_are_sending_email_message'));
                    setShowStnadradRatesMessage(procType != 'Email' ? true : false)

                    setIsOTPVerifyModalOpen(true);
                } else if (otpType == t('new_email')) {
                    setSelectedType(t('new_email'));
                    setDisplayEmail(selectedEmailAdress);
                    setTwoFactModelTitle('');
                    setTwoFactModelSubTitle(t('sending_code_newEmail'));
                    setShowStnadradRatesMessage(false);
                    setshow2faSelection(false);
                    setIsOTPVerifyModalOpen(true);
                } else if (otpType == t('new_mobile')) {
                    setSelectedType(t('new_mobile'));
                    setTwoFactModelTitle('');
                    setTwoFactModelSubTitle(t('sending_code_newNumber'));
                    setShowStnadradRatesMessage(true);
                    setshow2faSelection(false);
                    setIsOTPVerifyModalOpen(true);
                } else if (otpType === t('new_address') && twoFactAuthenticationDone === true) {


                    const addressParts = [
                        objForNewAddress.line1,
                        objForNewAddress.line2,
                        objForNewAddress.city,
                        objForNewAddress.state,
                        // objForNewAddress.stateCode,
                        // objForNewAddress.countryCode,
                        objForNewAddress.zipCode,
                        // objForNewAddress.country,
                    ];

                    const homeAddress = addressParts.filter(part => part).join(', ');
                    // const homeAddress = homeAddressToserver.filter(part => part).join(', ');
                    setHomeAddress(homeAddress);
                    // setTypedValue(homeAddress);
                    // setSelectedAddressLine2("");
                    // setLine1AdressFilled(false);
                    // setAddressEditClicked(true);
                    setIsOTPVerifyModalOpen(false);
                    setAddressEditClicked(false);
                    setshow2faSelection(false);
                    showAlertModal(true, t('success'), t('editAddressSuccessMsg'), '', true, false, t('done'), '', true, address_update_Succss);


                } else if (otpType === t('old_address')) {

                    setSelectedType(t('old_address'));

                    setTwoFactModelTitle(show2faSelection == true ? t('great_exclamation') : t('security_check'));
                    setTwoFactModelSubTitle(procType != 'Email' ? t('we_are_sending_messsage') : t('we_are_sending_email_message'));
                    setShowStnadradRatesMessage(procType != 'Email' ? true : false)
                    setIsOTPVerifyModalOpen(true);
                }


            } else {
                const arrMessages = parsedResponse.Messages;
                const message = arrMessages[0];
                const displayText = message.DisplayText;
                setinfoMessage(displayText);
                setResponseCode(parsedResponse.ResponseCode);
                setTimeout(() => {
                    setinfoMessage('');
                    setResponseCode('');
                }, 5000);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }
    const closeModel = () => {
        setShowModel(false);
    }
    const verificationSuccess = (type) => {
        setPhoneNumberEditClicked(false);
        setEmailAdressEditClicked(false);
        setpreferredNameEditClicked(false)
        setAddressEditClicked(false);
        if (type == t('old_mobile')) {
            setPhoneNumberEditClicked(true);
        }
        else if (type == t('old_email')) {
            setEmailAdressEditClicked(true);
        }
        else if (type == t('old_address')) {
            setAddressEditClicked(true);
            setAddress(homeAddressToserver?.line1);
            setValue('address', homeAddressToserver?.line1);
            setValue('apt_etc', homeAddressToserver?.line2);
            setValue('city', homeAddressToserver?.city);
            setValue('state', homeAddressToserver?.state);
            setValue('zip_code', homeAddressToserver?.zipCode);
            setTimeout(() => {
                const inputElement = document.getElementById('addressInput');
                if (inputElement) {
                    inputElement.focus();
                }
            }, 500);
        }
        else if (type == t('new_email')) {
            setEmailAdressEditClicked(false);
            getPersonalInformation();
            showAlertModal(true, t('success'), t('editEmailSuccessMsg'), '', true, false, t('done'), '', true, email_update_success);
        } else if (type == t('new_mobile')) {
            setPhoneNumberEditClicked(false);
            getPersonalInformation();
            showAlertModal(true, t('success'), t('your_number_has_been_changed'), '', true, false, t('done'), '', true, updatedphone_success);
        } else if (type == t('new_address')) {

            const addressParts = [
                homeAddressToserver.line1,
                homeAddressToserver.line2,
                homeAddressToserver.city,
                // homeAddressToserver.stateCode,
                // homeAddressToserver.countryCode,
                homeAddressToserver.state,
                homeAddressToserver.zipCode,
                // homeAddressToserver.country,
            ];

            const homeAddress = addressParts.filter(part => part).join(', ');

            setHomeAddress(homeAddress);
            // setSelectedAddressLine2("");
            // setLine1AdressFilled(false);
            setAddressEditClicked(false);
            getPersonalInformation();
            showAlertModal(true, t('success'), t('editAddressSuccessMsg'), '', true, false, t('done'), '', true, address_update_Succss);
        }
        // getChangePinDetails()
        // navigate('/settings/ManageExternalAccounts/AddExternalAccount')
    }
    const verificationFaiure = (type, message) => {
        // alert(message)
        getPersonalInformation();
        setPhoneNumberEditClicked(false);
        setEmailAdressEditClicked(false);
        setpreferredNameEditClicked(false)
        setAddressEditClicked(false);
        setinfoMessage(message);
    }
    const closeCustomAlertModal = () => {
        getPersonalInformation();
        setShowModel(false);
    }

    const onAcptBtnClick = async () => {
        setShowModel(false)

    }
    const onCancelBtnClick = () => {
        setShowModel(false)
    }

    const onUpdateProfileClicked = async () => {
        if (selectedLanguage == "" || selectedLanguage == undefined || selectedLanguage == null || selectedLanguage == {}) {
            setinfoMessage(t('select_language'))
            setTimeout(() => {
                setinfoMessage('')
            }, 5000);
            return
        }
        if (selectedTimeZone == "" || selectedTimeZone == undefined || selectedTimeZone == null || selectedTimeZone == {}) {
            setinfoMessage(t('select_time_zone'))
            setTimeout(() => {
                setinfoMessage('')
            }, 5000);
            return
        }
        if (!preferredName) {
            setinfoMessage("Preferred name is required")
            setTimeout(() => {
                setinfoMessage('')
            }, 5000);
            return;
        } 
        setLoading(true);
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var request = {
            "PreferredLanguage": selectedLanguage.Type != undefined ? selectedLanguage.Type : selectedLanguage,
            "TimeZone": selectedTimeZone.Type != undefined ? selectedTimeZone.Type : selectedTimeZone,
        }
        let finalUrl = POST_UPDATE_USER_PROFILE + 'cardKey=' + selectedItem?.CardKey;
        var response = await PostRequest(finalUrl, request, headers);
        var parsedResponse = JSON.parse(response)
        if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
            setLoading(false);
            setTimeout(() => {
                getPersonalInformation();
                showAlertModal(true, t('success'), t('prefs_updated'), '', true, false, t('done'), '', true, updatedphone_success);
            }, 1000);
        } else {
            setLoading(false);
            var arrMessages = parsedResponse.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            setinfoMessage(displayText);
        }

    }
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)

    }
    const getAddress = async (event) => {
        //debugger;
        const typedValue = event.target.value;

        setTypedValue(typedValue);
        if (typedValue.length === 3) {
            debugger;
            var url = GOOGLE_PLACES_AUTO_COMPLETE(typedValue);
            var response = GetRequest(url, {});
            const responseJson = await response;
            const prdections_data = JSON.parse(responseJson);
            if (prdections_data.predictions.length > 0) {
                var predictions = prdections_data.predictions;
                setPredictions(predictions);
                // alert(predictions.length);
            }
        } else {
            setPredictions([]);
        }
    }
    const handlePredictionClick = async (event) => {
        const selectedPlaceID = event.target.getAttribute('value');
        if (selectedPlaceID) {
            debugger;
            var url = GOOGLE_PLACE_DETAILS_WITH_PLACE_ID(selectedPlaceID);
            var response = GetRequest(url, {});
            const responseJson = await response;
            const places_data = JSON.parse(responseJson);
            var formattedAdress = places_data.result.formatted_address;
            //     setAddress(formattedAdress); 
            //    setValue('address',formattedAdress);
            var selectedAddress = parseGoogleLocationResponse(places_data);
            //   alert(JSON.stringify(selectedAddress));


            const streetNumber = selectedAddress.streetNumber != null ? selectedAddress.streetNumber : ''
            const route = selectedAddress.route != null ? selectedAddress.route : ''
            var object = {
                "line1": streetNumber + " " + route,
                "line2": selectedAddressLine2,
                "city": selectedAddress.locality != null ? selectedAddress.locality : '',
                "state": selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : '',
                "zipCode": selectedAddress.postalCode != null ? selectedAddress.postalCode : '',
                "countryCode": selectedAddress.countryCode != null ? selectedAddress.countryCode : '',
                "country": selectedAddress.country != null ? selectedAddress.country : '',
                "cardKey": selectedItem?.CardKey
            }
            setHomeAddressToserver(object);

            const addressParts = [
                object.line1,
                object.line2,
                object.city,
                object.state,
                object.zipCode,
                object.countryCode,
                object.country
            ];

            const homeAddress = addressParts.filter(part => part).join(', ');

            setTypedValue(homeAddress);
            setLine1AdressFilled(true);

            setPredictions([]);
        } else {
            //setValue('address', ''); 
        }


    };

    const handleAdressChange = () => {
        clearErrors();
        setAddressError('');
        setCityError('');
        setStateError('');
        setZipCodeError('');
    }

    const handleSelect = async (selectedAddress, placeId) => {
        clearErrors();
        setAddressError('');
        setCityError('');
        setStateError('');
        setZipCodeError('');
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);

        // Get formatted address using the place ID
        const placeResults = await geocodeByPlaceId(placeId);
        const formattedAddress = placeResults[0].formatted_address;
        const addressComponents = placeResults[0].address_components;
        console.log("addressComponents", addressComponents);
        console.log("formattedAddress", formattedAddress);


        var selectedAddress = parseGoogleLocationResponse(addressComponents);


        const address1 = (selectedAddress.streetNumber != null ? selectedAddress.streetNumber : selectedAddress.sublocality != null ? selectedAddress.sublocality : selectedAddress.naturalFeature != null ? selectedAddress.naturalFeature : '') + (selectedAddress.route != null ? ` ${selectedAddress.route}` : '');
        if (!address1) setAddressError(t("addressError"));
        var object = {
            "line1": address1,
            "line2": '',
            "city": selectedAddress.locality != null ? selectedAddress.locality : '',
            "state": selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : '',
            "zipCode": selectedAddress.postalCode != null ? selectedAddress.postalCode : '',
            "countryCode": selectedAddress.countryCode != null ? selectedAddress.countryCode : '',
            "country": selectedAddress.country != null ? selectedAddress.country : '',
            "cardKey": selectedItem?.CardKey
        }
        setHomeAddressToserver(object);

        // const addressParts = [
        //     object.line1,
        //     object.line2,
        //     object.city,
        //     object.state,
        //     object.zipCode,
        //     object.countryCode,
        //     object.country
        // ];

        // const homeAddress = addressParts.filter(part => part).join(', ');
        // // setAddress(addressComponents);
        // setAddress(formattedAddress);
        // setTypedValue(homeAddress);
        // setLine1AdressFilled(true);

        setValue('address', address1);
        setAddress(address1)
        setValue('apt_etc', "")
        setValue('city', (selectedAddress.locality != null ? selectedAddress.locality : ''));
        setValue('state', (selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : ''));
        setValue('zip_code', (selectedAddress.postalCode != null ? selectedAddress.postalCode : ''));

        setPredictions([]);

    };

    const handlePhoneBlurEvent = (e) => {
        const errorMessage = validateMobileNumber(selectedPohoneNumber)
        // alert(errorMessage);
        if (errorMessage == 'Mobile number field is empty') {
            setinfoMessage(t('please_enter_phone_number'))
        } else if (errorMessage == 'Invalid mobile number') {
            setinfoMessage(t('please_enter_valid_phone_number'))
        }
        // else {
        //     generate2FAOtp(t('new_mobile'), '');
        // }
    }
    const handlePreferredNameBlurEvent = (e) => {
        if (!e.target.value) {
            setinfoMessage("Preferred name is required")
        } else {
            setinfoMessage('')
        }
    }
    const handleEmailBlurEvent = (e) => {
        const errorMessage = isEmailValid(selectedEmailAdress)
        if (errorMessage == 'empty') {
            setinfoMessage(t('please_enter_your_email_address'))
        } else if (errorMessage == 'not valid') {
            setinfoMessage(t('please_enter_a_valid_email_address'))
        }
        // else {

        //     generate2FAOtp(t('new_email'));
        // }
    }



    useKeyDown(() => {
        if (!showAlertModal && !showModel) {
            onUpdateProfileClicked();
        }

    }, ["Enter"]);

    return (
        <>
            <HomeLayout>
                <HomeHeader heading={t('keep_personal_info')} isHeaderCaption={true} />
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        footer={false}
                        CardRadius={true}
                        HeaderRadius={true}
                        isWhite={true}
                        cardWidth={width.homeCard}>

                        <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] md:font-[500] sm:font-[600] font-[500]
                     lg:text-[26px] md:text-[26px] 
                     sm:text-[15px] text-[16px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">
                            {t('menu_personal_information')}</div>

                        <div className='border-t-[2.5px] border-[#A3CEBB] 
                        flex justify-center sm:mx-0 mx-4'>


                            <div className='md:w-[85%] sm:w-[87%] w-full md:pt-16 pt-10'>
                                {/*  Name section*/}
                                <>
                                    <p className='font-hanken-grotesk text-[#262B3A] uppercase 
                                       lg:text-[13px] md:text-[12px] sm:text-[9px] text-[13px] 
                                        font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("full_name")}</p>

                                    <p className="font-hanken-grotesk lg:text-[16px] md:text-[15px] sm:text-[13px] 
                                    text-[14px] md:font-[500] sm:font-[700] font-[500]leading-[26px] md:mb-6 mb-2">
                                        {PersonalInfoObject.FirstName} {PersonalInfoObject.LastName}</p>

                                    <div className="bg-[#94C3E8] bg-opacity-50 h-0.5 min-h-1 w-full"></div>
                                    <div onClick={(event) => {
                                        event.target.style.opacity = 0.8;
                                        setTimeout(() => {
                                            event.target.style.opacity = 1;
                                        }, 200);
                                    }} className="flex items-center pt-5">

                                        <p className="font-hanken-grotesk lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[12px] font-[500]">
                                            {t("perosnal_make_change_title")} <button onClick={() => {
                                                navigate('/Help/ContactUs');logFirebaseEvents("Personal_Information", "Customer_Care_Clicked", "Customer_Care_Clicked", "","");
                                            }} className='font-hanken-grotesk lg:text-[16px] md:text-[15px] 
                                            sm:text-[12px] text-[13px] text-[#2965FB] font-[500] sm:font-[700]
                                            cursor-pointer md:ml-1'>
                                                {t("customerCare")}
                                            </button>
                                        </p>
                                    </div>
                                </>

                                {/*  preferred name section */}
                                {!preferredNameEditClicked &&
                                    <div className='md:mt-16 sm:mt-10 mt-12'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase  
                                          lg:text-[13px] md:text-[12px] sm:text-[9px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4
                                opacity-[50%]'>{t("preferred_name_placeholder")}</p>

                                        <p className="font-hanken-grotesk  lg:text-[16px] md:text-[15px] sm:text-[13px] 
                                    text-[14px] md:font-[500] sm:font-[700] font-[500] leading-[26px] md:mb-6 mb-2">
                                            {preferredName}</p>
                                        <div className="bg-[#94C3E8] bg-opacity-50 h-0.5 min-h-1 w-full"></div>

                                        <button onClick={() => { editPreferredName() }}>{<p onClick={() => { editPreferredName() }}
                                            className='pt-5 font-hanken-grotesk text-[#2965FB]
                                            lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[13px]
                                                font-[700]'>{t("edit_preferred_name")}</p>}
                                        </button>
                                    </div>
                                }

                                {/*  preferred name update section */}
                                {preferredNameEditClicked &&
                                    <div className='md:mt-16 sm:mt-10 mt-12'>
                                        <div className='w-full'>
                                            <CustomTextInput
                                                maxLength={21}
                                                autoFocus={true}
                                                name={t("preferred_name1")}
                                                onChange={(event) => {
                                                    setinfoMessage('');
                                                    setpreferredName(event.target.value)
                                                }}
                                                onBlur={(e) => handlePreferredNameBlurEvent(e)}

                                                value={preferredName} style={{ width: '100%', height: '51px' }} />

                                            {(preferredNameEditClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                                <div className='mb-2'>
                                                    {displaySuccessMessage(infoMessage)}
                                                </div>
                                            }
                                            {(preferredNameEditClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                                <div className='mb-2'>
                                                    {displayErrorMessage(infoMessage)}
                                                </div>
                                            }
                                            <button onClick={() => { updatePreferredName() }}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Enter") {
                                                        event.preventDefault();
                                                        updatePreferredName();
                                                    }
                                                }}
                                                className='font-hanken-grotesk text-[#2965FB]
                                                lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[13px]
                                        font-[700]'>{t("personal_save")}</button>
                                        </div>
                                    </div>
                                }
                                {/*  phoneNumber section */}
                                {!phoneNumberEditClicked &&
                                    <div className='md:mt-16 sm:mt-10 mt-12'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase  
                                          lg:text-[13px] md:text-[12px] sm:text-[9px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4
                                opacity-[50%]'>{t("phone_number")}</p>

                                        <p className="font-hanken-grotesk  lg:text-[16px] md:text-[15px] sm:text-[13px] 
                                    text-[14px] md:font-[500] sm:font-[700] font-[500] leading-[26px] md:mb-6 mb-2">
                                            {personalFormatPhoneNumber(formatedPhoneNumber.toString())}</p>
                                        <div className="bg-[#94C3E8] bg-opacity-50 h-0.5 min-h-1 w-full"></div>

                                        <button onClick={() => { editPhoneNumberClicked() }}>{<p
                                            className='pt-5 font-hanken-grotesk text-[#2965FB]
                                            lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[13px]
                                                font-[700]'>{t("update_phone_number")}</p>}
                                        </button>
                                    </div>
                                }

                                {/*  phoneNumber update section */}
                                {phoneNumberEditClicked &&
                                    <div className='md:mt-16 sm:mt-10 mt-12'>
                                        <div className='w-full'>
                                            <CustomTextInput
                                                maxLength={10}
                                                autoFocus={true}
                                                name={t("phone_number")}
                                                onChange={(event) => {
                                                    setinfoMessage('');
                                                    setSelectedPohoneNumber(event.target.value)
                                                }}
                                                onBlur={(e) => handlePhoneBlurEvent(e)}

                                                value={selectedPohoneNumber} style={{ width: '100%', height: '51px' }} />

                                            {(phoneNumberEditClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                                <div className='mb-2'>
                                                    {displaySuccessMessage(infoMessage)}
                                                </div>
                                            }
                                            {(phoneNumberEditClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                                <div className='mb-2'>
                                                    {displayErrorMessage(infoMessage)}
                                                </div>
                                            }
                                            <button onClick={() => { updatePhoneNumber() }}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Enter") {
                                                        event.preventDefault();
                                                        updatePhoneNumber();
                                                    }
                                                }}
                                                className='font-hanken-grotesk text-[#2965FB]
                                                lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[13px]
                                        font-[700]'>{t("personal_save")}</button>
                                        </div>
                                    </div>
                                }


                                {/* email address section */}
                                {!emailAdressEditClicked &&
                                    <div className='md:mt-16 sm:mt-10 mt-12'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[12px] 
                                        sm:text-[9px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("personal_email_address")}</p>

                                        <p className="font-hanken-grotesk lg:text-[16px] md:text-[15px] sm:text-[13px] 
                                    text-[14px] md:font-[500] sm:font-[700] font-[500] leading-[26px] md:mb-6 mb-2">
                                            {displayEmail}</p>

                                        <div className="bg-[#94C3E8] bg-opacity-50 h-0.5 min-h-1 w-full"></div>

                                        <button
                                            onClick={() => { editEmailClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    editEmailClicked()
                                                }
                                            }}>{<p
                                                className='pt-5 font-hanken-grotesk text-[#2965FB] 
                                                lg:text-[16px] md:text-[15px] 
                                                sm:text-[13px] text-[13px]
                                                   font-[700]'>{t("update_email_address")}</p>}</button>
                                    </div>
                                }

                                {/* email address  update section */}
                                {emailAdressEditClicked &&
                                    <div className='md:mt-16 sm:mt-10 mt-12'>
                                        <div className='w-full'>
                                            <CustomTextInput
                                                maxLength={35}
                                                autoFocus={true}
                                                name={t("personal_email_address")}
                                                onChange={(event) => {
                                                    setinfoMessage('');
                                                    setSelectedEmailAdress(event.target.value)
                                                }}
                                                onBlur={(e) => handleEmailBlurEvent(e)}

                                                value={selectedEmailAdress} style={{ width: '100%', height: '51px' }} />
                                        </div>

                                        {(emailAdressEditClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displaySuccessMessage(infoMessage)}
                                            </div>
                                        }
                                        {(emailAdressEditClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        }
                                        <button
                                            onClick={() => { updateEmailAdressClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    updateEmailAdressClicked()
                                                }
                                            }}
                                            className='font-hanken-grotesk text-[#2965FB]
                                            lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[13px]
                                             font-[700]'>{t("personal_save")}</button>
                                    </div>
                                }


                                {/*  address section */}
                                {!addressEditClicked &&
                                    <div className='md:mt-16 sm:mt-10 mt-12'>
                                        <p className='font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[12px] 
                                        sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("personal_address")}</p>

                                        <p className="font-hanken-grotesk
                                         lg:text-[16px]
                                         md:text-[15px] sm:text-[13px] 
                                    text-[14px]
                                    md:font-[500] sm:font-[700] font-[500]leading-[26px] md:mb-6 mb-2">
                                            {homeAddress}</p>

                                        <div className="bg-[#94C3E8] bg-opacity-50 h-0.5 min-h-1 w-full"></div>

                                        <button onClick={() => { editAddressClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    editAddressClicked()
                                                }
                                            }}
                                        >{<p  className='pt-5 
                                        font-hanken-grotesk text-[#2965FB]  lg:text-[16px] md:text-[15px] 
                                        sm:text-[13px] text-[13px]
                                         font-[700]'>{t("update_address")}
                                        </p>}
                                        </button>
                                    </div>
                                }

                                {/*  address  update section */}
                                {addressEditClicked &&
                                    <div className='md:mt-16 sm:mt-10 mt-12'>
                                        {/* <div className='w-full'>
                                            <CustomTextInput
                                                autoFocus={true}
                                                name={t("personal_address")}
                                                onChange={(event) => {
                                                    setinfoMessage('');
                                                    setTypedValue('')
                                                     getAddress(event)
                                                     }}
                                                value={typedValue} style={{ width: '100%', height: '51px' }} />

                                               {line1AdressFilled &&<CustomTextInput
                                                name={t("apt_suite_etc")}
                                                onChange={(event) => { 
                                                    let tempHomeaddresToServer = homeAddressToserver;
                                                    tempHomeaddresToServer.line2 = event.target.value;
                                                    setHomeAddressToserver(tempHomeaddresToServer);
                                                    setSelectedAddressLine2(event.target.value)
                                                 }}
                                                value={selectedAddressLine2} style={{ width: '100%', height: '51px' }} />}

                                            {predictions.length > 0 && (
                                                <ul className='bg-[aliceblue] max-h-60 overflow-y-auto p-2'>
                                                    {predictions.map((prediction) => (
                                                        <li
                                                            key={prediction.place_id}
                                                            className='font-hanken-grotesk 
                                                            text-sm font-medium leading-6 p-2 cursor-pointer'
                                                            value={prediction.place_id}
                                                            onClick={handleSelect}>{prediction.description}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div> */}
                                        <PlacesAutocomplete
                                            value={isAddress}
                                            // onChange={setAddress}
                                            onChange={value => {
                                                setAddress(value)
                                                if (value) {
                                                    clearErrors();
                                                    setAddressError('');
                                                    setCityError('');
                                                    setStateError('');
                                                    setZipCodeError('');

                                                }
                                            }
                                            }

                                            onSelect={handleSelect}

                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input id='addressInput' {...getInputProps({ placeholder: 'Address' })}
                                                        className='font-hanken-grotesk text-secondary-2 px-4 py-2 bg-text-btn placeholder-kudzu-blue text-sm font-medium focus:outline-none w-full h-[60px]'
                                                        onBlur={(e) => {
                                                            if (!isAddress) setAddressError('Address is required')
                                                            else setAddressError('')
                                                        }}
                                                    />
                                                    <div className='mb-4'>
                                                        {loading ? <div>Loading...</div> : null}
                                                        {suggestions.map((suggestion) => {
                                                            const style = {
                                                                backgroundColor: suggestion.active ? '#ffffff' : '#F5F8F9',
                                                                cursor: 'pointer',
                                                                padding: '8px',
                                                                fontSize: '16px',
                                                                fontWeight: '500',
                                                                lineHeight: '26px',
                                                            };
                                                            return (
                                                                <div
                                                                    key={suggestion.placeId}
                                                                    {...getSuggestionItemProps(suggestion, { style })}
                                                                >
                                                                    {suggestion.description}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {/* {line1AdressFilled && <CustomTextInput
                                            name={t("apt_suite_etc")}
                                            onChange={(event) => {
                                                let tempHomeaddresToServer = homeAddressToserver;
                                                tempHomeaddresToServer.line2 = event.target.value;
                                                setHomeAddressToserver(tempHomeaddresToServer);
                                                setSelectedAddressLine2(event.target.value)
                                            }}
                                            value={selectedAddressLine2} style={{ width: '100%', height: '51px' }} />} */}
                                        {AddressError && (
                                            displayErrorMessage(AddressError)
                                        )}
                                        {predictions.length > 0 && (
                                            <ul className='bg-[aliceblue] max-h-60 overflow-y-auto p-2'>
                                                {predictions.map((prediction) => (
                                                    <li
                                                        key={prediction.place_id}
                                                        className='font-hanken-grotesk text-sm font-medium leading-6 p-2 cursor-pointer'
                                                        value={prediction.place_id}
                                                    >{prediction.description}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        {(addressEditClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displaySuccessMessage(infoMessage)}
                                            </div>
                                        }
                                        {(addressEditClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        }
                                        <span className='pt-1 font-hanken-grotesk lg:text-[18px] md:text-[17px] 
            sm:text-[15px] text-[15px] font-medium'>{t('P.O._boxes_are_not_accepted')} </span>
                                        <CustomInput
                                            name="apt_etc"
                                            label="Apt, suite, etc. (optional)"
                                            register={register}
                                            error={errors.apt_etc}
                                            type="text"
                                        />
                                        <CustomInput
                                            name="city"
                                            label="City"
                                            register={register}
                                            error={errors.city}
                                            type="text"
                                            onChange={handleAdressChange}

                                        />
                                        <div className='mb-2'>

                                            {cityError && (
                                                displayErrorMessage(cityError)
                                            )}
                                        </div>

                                        <CustomInput
                                            name="state"
                                            label="State"
                                            register={register}
                                            error={errors.state}
                                            type="text"
                                            onChange={handleAdressChange}

                                        />
                                        <div className='mb-2'>

                                            {stateError && (
                                                displayErrorMessage(stateError)
                                            )}
                                        </div>

                                        <CustomInput
                                            name="zip_code"
                                            label="Zip Code"
                                            register={register}
                                            error={errors.zip_code}
                                            type="text"
                                            onChange={handleAdressChange}

                                        />
                                        <div className='mb-2'>
                                            {zipCodeError && (
                                                displayErrorMessage(zipCodeError)
                                            )}

                                        </div>


                                        <button
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    updateHomeAddress()
                                                }
                                            }}
                                            onClick={() => { updateHomeAddress() }}
                                            className='font-hanken-grotesk text-[#2965FB]
                                            lg:text-[16px] md:text-[15px] 
                                            sm:text-[13px] text-[13px] font-[700]'>{t("personal_save")}</button>
                                    </div>
                                }
                                <div className='w-full md:mt-16 sm:mt-10 mt-12 h-auto '>
                                    {/* <div className='w-full md:mt-16 sm:mt-10 mt-12 lg:h-[150px] md:h-[130px] sm:h-[95px] h-auto '> */}
                                    {languageList.length === 1 ?
                                        <div>
                                            <p className='font-hanken-grotesk text-[#262B3A] 
                                                uppercase lg:text-[13px] md:text-[12px] 
                                                sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-6 pb-5
                                                opacity-[50%] '>{t('preferred_language')}</p>
                                            <div className={`flex justify-between flex-row w-full`}>
                                                <div className={`sm:flex block justify-between flex-row items-center mb-4 w-full 'outline-none'`}>
                                                    <p contentEditable={false} className={`font-[500] font-hanken-grotesk lg:text-[16px]
                                                        md:text-[15px] sm:text-[13px] text-[14px] cursor-none`}>
                                                        {languageList[0]?.Name}
                                                    </p>

                                                </div>

                                            </div>

                                            <hr tabindex="-2" className={` h-[2px] bg-[#94C3E8] opacity-[50%] border-0 rounded`} />
                                        </div>
                                        :
                                        <CustomDropdown options={languageList} title={t('preferred_language')}
                                            onSelect={(option) => {
                                                setIsLangAndTimeZoneClicked(false);
                                                setSelectedLanguage(option)
                                            }}
                                            selectedAccountType={selectedLanguage} />
                                    }
                                </div>

                                <div className='w-full md:mt-16 sm:mt-10 mt-12 lg:h-[150px] md:h-[130px] sm:h-[95px] h-auto 
                                    sm:pb-0 pb-12'>
                                    <CustomDropdown
                                        isScroll={true}
                                        options={timeZoneList}
                                        title={t('time_zone')} onSelect={(option) => {
                                            setIsLangAndTimeZoneClicked(false);
                                            setSelectedTimeZone(option)
                                        }}
                                        selectedAccountType={selectedTimeZone} />
                                </div>

                                {/* {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                    <div className='mb-2'>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                } */}
                            </div>
                        </div>

                        {(isLangAndTimeZoneClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                            <div className='mb-2'>
                                {displaySuccessMessage(infoMessage)}
                            </div>
                        }
                        {(isLangAndTimeZoneClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                            <div className='mb-2'>
                                {displayErrorMessage(infoMessage)}
                            </div>
                        }

                        {/* save and back section */}

                        <div className='flex flex-row sm:justify-end justify-between lg:mt-20 md:mt-16 sm:mt-6
                         mt-10 sm:mx-0 mx-4 lg:mb-20 sm:mb-12 mb-10'>
                            <button
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        handleRevertClick()
                                    }
                                }}
                                onClick={handleRevertClick} className='font-hanken-grotesk 
                                    text-[#2965FB] font-[700] lg:text-[18px] md:text-[17px] sm:text-[15px] 
                                    text-[16px]'>{t("personal_back")}</button>
                            <button
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        handleSaveClick()
                                    }
                                }}
                                onClick={handleSaveClick} className='font-hanken-grotesk 
                                    text-[#2965FB] font-[700] lg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px] sm:ml-10'>{t("personal_save")}</button>
                        </div>



                    </DynamicCard>
                </div>
            </HomeLayout>
            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={closeOTPVerifyModal}
                isLogo={false}
                title={<p className={HighlightMsgStyles}> {twoFactModelTitle}</p>}
                subtitle={<><p className={HeaderMsgStyles}>{twoFactModelSubTitle}</p> {showStnadradRatesMessage && <p className="font-hanken-grotesk my-3 text-[#262B3A]  lg:text-[20px] md:text-[18px] sm:text-[15px] text-[18px] leading-[30px] font-[500]  w-full">{t('standrd_message_rates_apply')}</p>}</>}
                content={<div className="mt-10"><Custom2FA selected2FAType={selectedOtpType} phoneNumber={selectedPohoneNumber} email={selectedEmailAdress} address={homeAddressToserver} type={selectedType} onClose={closeOTPVerifyModal} verificationFailure={verificationFaiure} verificationSuccess={verificationSuccess} /></div>}
            />
            <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false) }}
                onClickNext={(object) => {
                    setshow2faSelection(false);
                    setSelectedOtpType(object.proc)
                    if (slectedFieldToEdit == t('old_mobile')) {
                        generate2FAOtp(t('old_mobile'), object.proc);
                    }
                    if (slectedFieldToEdit == t('old_email')) {
                        generate2FAOtp(t('old_email'), object.proc);
                    }
                    if (slectedFieldToEdit == t('old_address')) {
                        generate2FAOtp(t('old_address'), object.proc);
                    }

                }} />
            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                    <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                // highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                // HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
            />
            {loading && <Loader />}
        </>


    )
}

export default PersonalInformation;
