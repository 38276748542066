import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AccountServiceIcon from '../../../assets/images/accounts.png';
import HelpIcon from '../../../assets/images/help-circle.png';
import CardServicesIcon from '../../../assets/images/card_services.png';
import SettingsIcon from '../../../assets/images/Settings.png';
import PersonalInfoImg from '../../../assets/images/user.png';
import SecurityIcon from '../../../assets/images/security.png';
import documentsImg from '../../../assets/images/documents.png'
import SmartRoots from '../../../assets/images/SmartRoots.png';
import { isBase64 } from '../../../utils';
import { useDispatch } from 'react-redux';

const Breadcrumbs = () => {
  const location = useLocation();
  const encryptedID = location.pathname.split('/').filter((segment) => segment.includes('='));
  const pathSegments = location.pathname.split('/').filter((segment) => segment !== '' && !segment.includes('='));
  const dispatch = useDispatch();


  return (
    <nav>
      <ul className='flex flex-wrap sm:px-[6%] px-10 md:py-10 sm:py-6 py-4 gap-2'>
        {pathSegments.map((segment, index) => {
          const path = `/${pathSegments.slice(0, index + 1).join('/')}`;
          const isActive = ((index === pathSegments.length - 1) && (pathSegments.length !== 1)) ? true : false;
          const isNotFirstSegment = segment.toLowerCase() === 'climbmenu' ? false : index !== 0;



          return (
            <li
              className={`font-hanken-grotesk flex lg:text-[20px] md:text-[18px] sm:text-[14px] text-[14px]
               leading-7 font-medium ${isActive ? 'text-[#94C3E8]' : 'text-white'
                }`}
              key={path}
            >
              {isNotFirstSegment && <span className="slash mr-1">/</span>}
              <Link to={path} className='lg:text-[20px] md:text-[18px] sm:text-[14px] text-[14px]  font-hanken-grotesk font-[500]'>
                {segment.toLowerCase() === 'accountservices' && (
                  <div className=' flex flex-row justify-start items-center'>
                    {/* <span className="flex self-center"> */}
                    <img src={AccountServiceIcon} alt="Account Services"
                      className='sm:w-[18px] sm:h-[20px] w-[16px] h-[16px] md:w-[20px] md:h-[20px] ' />
                    {/* </span> */}
                    <p className='sm:ml-3 ml-2 font-hanken-grotesk'>Account Services</p>
                    {/* <span className="inline-block self-center">
                      <img src={AccountServiceIcon} alt="Account Services"
                        className='sm:w-[18px] sm:h-[20px] w-[16px] h-[16px] ' />
                    </span>
                    <span className='sm:ml-3 ml-2 font-hanken-grotesk'>Account Services</span> */}
                  </div>
                )}
                {(segment.toLowerCase() === 'checking' || segment.toLowerCase() == 'spending') && (
                  <>
                    Spending
                  </>
                )}

                {segment.toLowerCase() === 'manageauthorizedusers' && (
                  <>
                    Manage Authorized Users
                  </>
                )}
                {segment.toLowerCase() === 'addauthorizedusers' && (
                  <>
                    Add Authorized User
                  </>
                )}
                {segment.toLowerCase() === 'editauthorizeduser' && (
                  <>
                    Add Authorized User
                  </>
                )}
                {segment.toLowerCase() === 'addfunds' && (
                  <>
                    Add Funds
                  </>
                )}
                {segment.toLowerCase() === 'addcash' && (
                  <>
                    Add Cash
                  </>
                )}
                {segment.toLowerCase() === 'editaccountname' && (
                  <>
                    Edit Account Name
                  </>
                )}
                {segment.toLowerCase() === 'managedirectdeposit' && (
                  <>
                    Set Up Direct Deposit
                  </>
                )}
                {segment.toLowerCase() === 'transfermoney' && (
                  <>
                    Transfer Money
                  </>
                )}
                {segment.toLowerCase() === 'personalinformation' && (
                  <div className=' flex flex-row justify-start items-center'>

                    <img src={PersonalInfoImg} alt="PersonalInformation" className='sm:w-[18px] sm:h-[20px] w-[16px] h-[16px] md:w-[20px] md:h-[20px]' />

                    <p className='sm:ml-3 ml-2'>Personal Information</p>
                  </div>
                )}
                {segment.toLowerCase() === 'savings' && (
                  <>
                    Savings
                  </>
                )}
                {segment.toLowerCase() === 'shared' && (
                  <>
                    Shared
                  </>
                )}


                {/* Add more conditions for other sections as needed */}
              </Link>


              {/* Card Services */}
              <Link to={path} className='lg:text-[20px] md:text-[18px] sm:text-[14px] text-[14px] font-hanken-grotesk font-[500]'>
                {segment.toLowerCase() === 'cardservices' && (
                  <div className=' flex flex-row justify-start items-center'>

                    <img src={CardServicesIcon} alt="Account Services" className='sm:w-[18px] sm:h-[20px] w-[16px] h-[16px] md:w-[20px] md:h-[20px]' />

                    <p className='sm:ml-3 ml-2'>Card Services</p>
                  </div>
                )}
                {segment.toLowerCase() === 'cardactionscreen' && (
                  <>
                    Debit Card
                  </>
                )}
                {segment.toLowerCase() === 'replacecard' && (
                  <>
                    Replace Card
                  </>
                )}
                {segment.toLowerCase() === 'changepin' && (
                  <>
                    Change Pin
                  </>
                )}
                {segment.toLowerCase() === 'viewcard' && (
                  <>
                    View Card
                  </>
                )}
                {segment.toLowerCase() === 'activatecard' && (
                  <>
                    Activate Card
                  </>
                )}

              </Link>


              {/* settings adding here */}
              <Link to={path} className='lg:text-[20px] md:text-[18px] sm:text-[14px] text-[14px] font-hanken-grotesk font-[500]'>
                {segment.toLowerCase() === 'settings' && (
                  <div className=' flex flex-row justify-start items-center'>

                    <img src={SettingsIcon}
                      alt="Settings" className='sm:w-[16px] sm:h-[16px] w-[16px] h-[16px] md:w-[20px] md:h-[20px]' />

                    <p className='sm:ml-3 ml-2'>Settings</p>
                  </div>
                )}
                {segment.toLowerCase() === 'manageexternalaccounts' && (
                  <>
                    Manage External Accounts
                  </>
                )}
                {segment.toLowerCase() === 'manageaccounts' && (
                  <>
                    Manage Accounts
                  </>
                )}
                {segment.toLowerCase() === 'connectschool' && (
                  <>
                    Connect School
                  </>
                )}

                {segment.toLowerCase() === 'accesscode' && (
                  <>
                    Connect School
                  </>
                )}

                {segment.toLowerCase() === 'edituniversity' && (
                  <>
                    Connect School
                  </>
                )}


                {segment.toLowerCase() === 'universityseclection' && (
                  <>
                    Connect School
                  </>
                )}

                {segment.toLowerCase() === 'addexternalaccount' && (
                  <>
                    Add External Account
                  </>
                )}
                {segment.toLowerCase() === 'verifyexternalaccount' && (
                  <>
                    Verify External Account
                  </>
                )}
                {segment.toLowerCase() === 'managebeneficiaries' && (
                  <>
                    Manage Beneficiaries
                  </>
                )}
                {segment.toLowerCase() === 'addbeneficiaries' && (
                  <>
                    Add Beneficiary
                  </>
                )}
                {segment.toLowerCase() === 'editaddbeneficiary' && (
                  <>
                    Add Beneficiary
                  </>
                )}
                {segment.toLowerCase() === 'editbeneficiary' && (
                  <>
                    Edit Beneficiary
                  </>
                )}
              </Link>

              {/* {help section} */}

              <Link to={path} className='lg:text-[20px] md:text-[18px] sm:text-[14px] text-[14px] font-hanken-grotesk font-[500]'>
                {segment.toLowerCase() === 'help' && (
                  <div className=' flex flex-row justify-start items-center'>

                    <img src={HelpIcon} alt="Help" className='md:w-[20px] md:h-[20px] sm:w-[16px] sm:h-[16px] w-[14px] h-[14px]' />

                    <p className='sm:ml-3 ml-2'>Help</p>
                  </div>
                )}
                {segment.toLowerCase() === 'aboutkudzu' && (
                  <>
                    About Kudzu
                  </>
                )}
                {segment.toLowerCase() === 'faq' && (
                  <>
                    Help & FAQ
                  </>
                )}
                {segment.toLowerCase() === 'findatm' && (
                  <>
                    Find an ATM
                  </>
                )}
                {segment.toLowerCase() === 'contactus' && (
                  <>
                    Contact Us
                  </>
                )}
              </Link>
              {/* Documents adding here */}
              <Link to={path} className='lg:text-[20px] md:text-[18px] sm:text-[14px] text-[14px] font-hanken-grotesk font-[500]'>
                {segment.toLowerCase() === 'documents' && (
                  <div className=' flex flex-row justify-start items-center'>

                    <img src={documentsImg}
                      alt="Documents" className='sm:w-[14px] sm:h-[15px] w-[16px] h-[16px] md:h-[20px] md:w-[20px]' />

                    <p className='sm:ml-3 ml-2'>Documents</p>
                  </div>
                )}
                {segment.toLowerCase() === 'policies' && (
                  <>
                    Policies
                  </>
                )}
                {segment.toLowerCase() === 'fees' && (
                  <>
                    Fees
                  </>
                )}
                {segment.toLowerCase() === 'statements' && (
                  <>
                    Statements
                  </>
                )}
                {segment.toLowerCase() === 'taxdocuments' && (
                  <>
                    Tax Documents
                  </>
                )}

                {segment.toLowerCase() === 'electronic%20communications%20disclosure%20and%20agreement' && (
                  <>
                    Electronic Communications Agreement
                  </>
                )}
                {segment.toLowerCase() === 'kudzu%20user%20agreement' && (
                  <>
                    User Agreement
                  </>
                )}
                {segment.toLowerCase() === 'demand%20deposit%20account%20agreement' && (
                  <>
                    Demand Deposit Account Agreement
                  </>
                )}
                {segment.toLowerCase() === 'official%20sweepstakes%20rules' && (
                  <>
                    Official Sweepstakes Rules
                  </>
                )}
                {segment.toLowerCase() === 'savings%20rate%20information' && (
                  <>
                    Savings Rate Information
                  </>
                )}
                {segment.toLowerCase() === 'savings%20account%20agreement' && (
                  <>
                    Savings Account Agreement
                  </>
                )}
                {segment.toLowerCase() === 'kudzu%20privacy%20policy' && (
                  <>
                    Kudzu Privacy Policy
                  </>
                )}
                {segment.toLowerCase() === 'bancorp%20privacy%20notice' && (
                  <>
                    Bancorp Privacy Policy
                  </>
                )}
                {segment.toLowerCase() === 'covered%20by%20kudzu%20terms%20and%20conditions' && (
                  <>
                    Covered by Kudzu Terms and Conditions
                  </>
                )}
                {segment.toLowerCase() === 'kudzu%20alerts%20terms%20of%20servies' && (
                  <>
                    Kudzu Alerts Terms Of Servies
                  </>
                )}
                {segment.toLowerCase() === 'deposit%20account%20agreement' && (
                  <>
                    Deposit Account Agreement
                  </>
                )}
              </Link>
              {/* security adding here */}
              <Link to={path} className='lg:text-[20px] md:text-[18px] sm:text-[14px] text-[14px] font-hanken-grotesk font-[500]'>
                {segment.toLowerCase() === 'security' && (
                  <div className=' flex flex-row justify-start items-center'>

                    <img src={SecurityIcon}
                      alt="security" className='sm:w-[18px] sm:h-[20px] w-[16px] h-[16px]' />

                    <span className='sm:ml-3 ml-2'>Security</span>
                  </div>
                )}
                {segment.toLowerCase() === 'changepassword' && (
                  <>
                    Change Password
                  </>
                )}
                {segment.toLowerCase() === 'trusteddevice' && (
                  <>
                    Trusted Devices
                  </>
                )}
              </Link>
              <Link
                to={encryptedID.length !== 0 && segment === 'EditHabit' ? `${path}/${encryptedID}` : path}
                className='lg:text-[20px] md:text-[18px] sm:text-[14px] text-[14px] font-hanken-grotesk font-[500]'
              >
                {segment.toLowerCase() === 'climbmenu' || segment.toLowerCase() === 'climb' && (
                  <div className=' flex flex-row justify-start items-center'>

                    <img
                      src={SmartRoots}
                      alt="SmartRoots"
                      className='sm:w-[16px] sm:h-[16px] w-[16px] h-[16px]'
                    />

                    <p className='sm:ml-3 ml-2 font-hanken-grotesk'>Climb</p>
                  </div>
                )}
                {segment.toLowerCase() === 'climbsettings' && (
                  <>
                    {/* Manage Settings */}
                    Settings
                  </>
                )}
                {segment.toLowerCase() === 'spendsensealerts' && (
                  <>
                    SpendSense Alerts
                  </>
                )}

                {segment.toLowerCase() === 'managespendalerts' && (
                  <>
                    SpendSense Alerts
                  </>
                )}
                {segment.toLowerCase() === 'managecoveredbykudzu' && (
                  <>
                    Manage Covered by Kudzu
                  </>
                )}
                {segment.toLowerCase() === 'addspendalerts' && (
                  <>
                    SpendSense Alerts
                  </>
                )}

                {segment.toLowerCase() === 'editspend' && (
                  <>
                    Edit SpendSense Alert
                  </>
                )}
                {segment.toLowerCase() === 'managesavingshabit' && (
                  <>
                    Savings Habits
                  </>
                )}
                {segment.toLowerCase() === 'addsavingshabit' && (
                  <>
                    Savings Habits
                  </>
                )}
                {segment.toLowerCase() === 'savingshabit' && (
                  <>
                    Savings Habits
                  </>
                )}
                {segment.toLowerCase() === 'edithabit' && (
                  <>
                    Edit Savings Habit
                  </>
                )}
              </Link>


            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
