import { PublicClientApplication } from "@azure/msal-browser";
import React from 'react'

const MicrosoftLoginAuth = ({
    graphScopes,
    clientId,
    tenantUrl,
    redirectUri,
    children,
    withUserData = true,
    authCallback,
    prompt,
    useLocalStorageCache,
}) => {

    const msalInstance = new PublicClientApplication(
        {
            auth: {
                redirectUri:redirectUri,
                authority:tenantUrl,
                clientId: clientId,
    
            },
            cache: {
                cacheLocation: useLocalStorageCache ? "localStorage" : "sessionStorage",
                storeAuthStateInCookie: false
            },
        }
    )

    const scopes=graphScopes
    // const scopes = (graphScopes) => {
    //     const scope = graphScopes || [];
    //     if (!scope.find((el) => el.toLowerCase() === "user.read")) {
    //       scope.push("user.read");
    //     }
    //     return scope;
    // };
    // console.log(graphScopes)

    const login=()=>{
        popupLogin();
    }

    const getUserData = async (
        AuthenticationResultWithAccessToken
      ) => {
        const { accessToken } = AuthenticationResultWithAccessToken;
        console.log("Fetch Graph API user data STARTED");
        const options = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await fetch(
          "https://graph.microsoft.com/v1.0/me",
          options
        );
        const userData = await response.json();
        console.log("Fetch Graph API user data SUCCEEDED", userData);
        console.log("Login SUCCEEDED");
        authCallback(
          null,
          {
            ...userData,
            ...AuthenticationResultWithAccessToken,
          },
          msalInstance
        );
      };

    const finalStep = (AuthenticationResultWithAccessToken) => {
        console.log(
          "Fetch Graph API 'access_token' SUCCEEDED",
          AuthenticationResultWithAccessToken
        );
        if (withUserData) {
          getUserData(AuthenticationResultWithAccessToken);
        } else {
            console.log("Login SUCCEEDED");
          authCallback(null, AuthenticationResultWithAccessToken, msalInstance);
        }
      };


    const getGraphAPITokenAndUser=async()=>{
        try {
            const popupRes = await msalInstance.acquireTokenPopup({ scopes });
            finalStep(popupRes);
        } catch (error) {
            console.log("Login FAILED", error, true);
            authCallback(error);
        }
    }

    const popupLogin=async()=>{
        console.log("Fetch Azure AD 'token' with popup STARTED");
        console.log(scopes)
        try {
          const AuthenticationResult = await msalInstance.loginPopup({
            scopes,
            prompt,
          });
          console.log("Fetch Azure AD 'token' with popup SUCCEEDED", AuthenticationResult);
          finalStep(AuthenticationResult);
        //   getGraphAPITokenAndUser();
        } catch (err) {
            console.log("Fetch Azure AD 'token' with popup FAILED", err, true);
          authCallback(err);
        }
    }

    return (
        <div onClick={login}>{children}</div>
    )
}

export default MicrosoftLoginAuth