import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../CustomComponents/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../../Layout/HomeLayout';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import card_front from '../../../../assets/images/card_front.png'
import card_back from '../../../../assets/images/card_back.png'
import { GENERATE_EXISTING_PHONE_EMAIL_OTP, GENERATE_OTP_CHANGE_PIN, GENERATE_OTP_VIEW_CARD, GET_ADD_NEW_CARD_METADATA, GET_CARD_DESIGN, GET_PIN_CHANGE_DETAILS, GET_SHIPPING_ID, LOCK_CARD_API, REPLACE_CARD_API, REPORT_LOST_CARD_API, STATUS_SUCCESS_CODE, UNLOCK_CARD_API, VERIFY_OTP_VIEW_CARD } from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import { retrieveData } from '../../../../helpers/utils/Utils';
import CardMenu from '../../CardMenu';
import { REPLACE_CARD, REPORT_LOST, REPORT_STOLEN } from '../../CardActionScreen';
import Custom2FA from '../../../CustomComponents/Custom2FA';
import Modal from '../../../CustomComponents/Modal';
import { LOGIN_SESSION_KEY } from '../../../../Redux/reducers';
import Moment from 'react-moment';
import DynamicCard from '../../../CustomComponents/DynamicCard';
import { width } from "../../../CustomComponents/DynamicCard/DynamicCardStyles";





const ViewCardDetails = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const selectedItem = cards?.[0]
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[25px] text-[28px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[30px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[25px] text-[28px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[30px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title text-md font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [modelImage, setModelImage] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [btn1Focus, setBtn1Focus] = useState(false);
    const [btn2Focus, setBtn2Focus] = useState(false);
    const [subFooter, setSubFooter] = useState('');
    const [ViewCardAccess, setViewCardAccess] = useState(false);
    const [cardFrontImage, setCardFrontImage] = useState('');
    const [cardBackImage, setCardBackImage] = useState('');
    const location = useLocation();
    const [UpdatedArray, setUpdatedArray] = useState(useSelector(state => JSON.parse(state.userCards)));


    const userId = useSelector(state => state.userId);
    const sessionKey = useSelector(state => state.loggedSessionKey);

    const params = location.state;
    const cardDetails = useSelector(state => state.selectedCardDetails);
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);


    useEffect(() => {
        // if (!twoFactAuthenticationDone) { generate2FAOtp(); }
        // else {
        getCardFrontImage(cardDetails?.CardDesignID)
        getCardBackImage(cardDetails?.CardKey)
        setTimeout(() => {
            setViewCardAccess(true)
        }, 1000);
        // }
    }, []);


    useKeyDown(() => { }, ["Enter"]);
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1Focus, btn2Focus) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
        setBtn1Focus(btn1Focus)
        setBtn2Focus(btn2Focus)
    }
    const handleCardAction = (type) => {
     
        if (type == t('lock_card')) {
            showAlertModal(true, t("are_you_sure"), t("this_card_will_be_locked"), t('once_you_lock_your_card_any_new_transactions_will_be_declined'), true, true, t('lock_card'), t("cancel"), true, require('../../../../assets/images/lock_green.png'), '', false, true, false, true)
        } else if (type == t('unlock_card')) {
            showAlertModal(true, t("ready_to_unlock_questionmark"), t("unlock_your_card_to_resume_normal_use"), t('once_you_unlock_your_card_all_locked'), true, true, t('unlock_card'), t("cancel"), true, require('../../../../assets/images/unlock_green.png'), '', false, true, true, false)
        } else if (type == t('replace_card')) {
            navigate('/CardServices/CardActionScreen/ReplaceCard', { state: { item: cardDetails } })
        } else if (type == t('change_pin')) {
            navigate('/CardServices/CardActionScreen/ChangePin', { state: { item: cardDetails } })
        } else if (type == t('view_card_details')) {
            navigate('/CardServices/CardActionScreen/ViewCard', { state: { item: cardDetails } })
        } else if (type == t('activate_card')) {
            navigate('/CardServices/CardActionScreen/ActivateCard', { state: { item: cardDetails } })
        }

    }
    const onAcptBtnClick = () => { 
        if (btn1Text == t("lock_card")) {
            callApiToLockCard()
        } else if (btn1Text == t("unlock_card")) {
            callApiToUnlockCard()
        } else if (btn1Text == t('back_to_card_services')) {
            navigate('/CardServices/CardActionScreen')
        } else if (btn1Text == t('home')) {
            navigate('/home')
        }
    }
    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }
    const closeModel = () => {
        setShowModel(false);
    }
    const verificationSuccess = () => {
        getCardFrontImage(cardDetails?.CardDesignID)
        getCardBackImage(cardDetails?.CardKey)
        setTimeout(() => {
            setViewCardAccess(true)
        }, 1000);
    }
    const getCardBackImage = async (cardKey) => {
        if (cardKey == undefined) {
            return
        }

        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        const object = { cardKey: cardKey }
        setLoading(true)
        const response = await PostRequest(VERIFY_OTP_VIEW_CARD, object, headers);
        setTimeout(() => {
            setLoading(false)
        }, 500);
        setTimeout(() => {
            const responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                const image = responseObj.Response.url
                setCardBackImage(image)
            } else {
            }
        }, 1000);
    }
    const getCardFrontImage = async (cardDesignIds) => {
        if (cardDesignIds == undefined || cardDesignIds == null) {
            return;
        }
        var url = GET_CARD_DESIGN + cardDesignIds;
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setLoading(true)
        let response = await GetRequest(url, headers);
        let responseObj = JSON.parse(response);
        setTimeout(() => {
            setLoading(false)
        }, 500);
        setTimeout(() => {
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                let imagesArray = responseObj.Response
                for (var i = 0; i < imagesArray.length; i++) {
                    var object = imagesArray[i];
                    setCardFrontImage(object.Type1ImageUri + '3x.png')
                }
            }
        }, 1000);
    }
    const callApiToLockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails !== null ? cardDetails.CardKey : 0
        }
        PostRequest(LOCK_CARD_API, object, headers).then((response) => {

            setTimeout(() => {
                setLoading(false)
            }, 500);

            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {

                setTimeout(() => {
                    updateCardStatus(cardDetails !== null ? cardDetails.CardKey : 0, responseObj.Response)
                    showAlertModal(true, t("done_exclamation"), t("this_card_is_now_locked"), t('any_new_tx_will_be_declined'), true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/lock_green_tick.png'), '', false, false, true, false)
                }, 1000);

                // this.updateCardsInLocal(responseObj.Response, LOCK_CARD)

            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, true, false)
                setTimeout(() => {
                }, 1000);

            }

        });

    }
    const updateCardStatus = (cardKey, response) => {
        const updatedCardIndex = cards?.findIndex(card => card.CardKey === cardKey);
        if (updatedCardIndex !== -1) {
            const updatedCard = {
                ...cards[updatedCardIndex],
                Status: response.Status,
                SubStatus: response.SubStatus,
                StatusCode: response.StatusCode,
                SubStatusCode: response.SubStatusCode,
                // NewCardRequestedDate: response.NewCardRequestedDate !== undefined ? response.NewCardRequestedDate : Moment().format("YYYY-MM-DD") + "T" + "00:00:00",

            };
            const updatedArray = [updatedCard, ...cards.filter(card => card.CardKey !== cardKey)]
            setUpdatedArray(updatedArray);
            dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedArray) } });
            dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: updatedCard } });

        }
    };
    const callApiToUnlockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails.CardKey
        }

        PostRequest(UNLOCK_CARD_API, object, headers).then((response) => {

            setTimeout(() => {
                setLoading(false)
            }, 500);

            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                setTimeout(() => {
                    updateCardStatus(cardDetails.CardKey, responseObj.Response)
                    showAlertModal(true, t("success_heading"), t("this_card_is_now_unocked"), t('you_can_use_your_card'), true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/unlock_green_tick.png'), '', false, false, true, false)
                }, 1000);

                // this.updateCardsInLocal(responseObj.Response, UNLOCK_CARD)

            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)

                setTimeout(() => {
                }, 1000);

            }

        });

    }
    const onCancelBtnClick = () => {
        if (btn2Text == 'Cancel') {
            setShowModel(false)
        } else if (btn2Text == t('back_to_card_services')) {
            navigate('/CardServices/CardActionScreen')
        }
    }

    const Headingcom = () => {
        return (

            <div className={`flex flex-col justify-start my-12 sm:my-6 md:my-10 `}>
                <p className='font-hanken-grotesk font-[500] 
                       lg:text-[26px] md:text-[26px] 
                       sm:text-[15px] text-[20px] leading-[24px] sm:font-[600] text-[#FFFFFF]
                        sm:leading-[23px] md:font-[500] 
                         md:leading-[36px]'>{t("view_card_details")}</p>
            </div>

        )
    }



    return (
        <HomeLayout>
            <CardMenu handleCardAction={handleCardAction} />
            <div className='md:w-[150px] sm:w-[55px] w-[0px]'></div>
            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight '>


                <DynamicCard
                    header={true}
                    // HeaderContent={Headingcom()}
                    CardRadius={true}
                    cardWidth={width.homeCard}
                    HeaderRadius={true}
                    footer={true}
                    isDark={true}
                    backgroundColor={'rgba(148, 195, 232, 0.25)'}
                >
                    <>
                        {Headingcom()}
                        <hr className=' h-[2.5px] bg-[#A3CEBB] w-[100%]' />
                        <p className='font-hanken-grotesk text-[#94C3E8] 
      uppercase lg:text-[13px] md:text-[13px] 
      sm:text-[9px] text-[13px]  font-[600] tracking-[2px] my-10
       opacity-[50%] '>{t("debit_card").toLocaleUpperCase() || ''}</p>
                        <div >

                            {ViewCardAccess &&
                                // <div className='flex sm:flex-nowrap flex-wrap gap-4 sm:mx-0 mx-10'>
                                <div className=' flex flex-col justify-center items-center w-full my-32 md:my-40 '>
                                    {/* <div>
                                <img src={cardFrontImage} className='sm:w-[420px] lg:h-[290px] md:h-[240px] sm:h-[190px] w-full h-full lg:rounded-[30px] md:rounded-[25px] sm:rounded-[20px] rounded-[15px]' />
                            </div> */}
                                    <div className=''>
                                        <img src={cardBackImage} id='card-image' className='md:w-[435px] sm:w-[250px] lg:h-[290px] md:h-[275px] sm:h-[158px] w-full h-full lg:rounded-[30px] md:rounded-[25px] sm:rounded-[20px] rounded-[15px]' />
                                    </div>

                                </div>

                            }
                        </div>
                    </>

                </DynamicCard>
            </div>

            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={closeOTPVerifyModal}
                isLogo={false}
                subHeaderMsg={<p className="font-hanken-grotesk modal-title lg:text-[18px] md:text-[17px] sm:text-[15px] text-[14px] font-normal cursor-pointer pointer-events-none">{t("standard_msg_and_data_rates_may_apply")}</p>}
                title={<p className={HighlightMsgStyles}> {t("security_check_custom2fa")}</p>}
                subtitle={<p className={HeaderMsgStyles}>{t("we_are_sending_text")}</p>}
                content={<div className="mt-10"><Custom2FA type={t("view_card_details")} onClose={closeOTPVerifyModal} verificationSuccess={() => { }} verificationFailure={() => { }} /></div>}
            />
            {showModel &&
                <CustomAlertModel
                    isOpen={showModel}
                    onClose={closeModel}
                    image={modelImage}
                    highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                    HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                    subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                    btn1Exists={btn1Exists}
                    btn2Exists={btn2Exists}
                    btn1Text={btn1Text} btn2Text={btn2Text}
                    btn1TypeAcpt={btn1TypeAcpt}
                    verificationFailure={() => { }}
                    footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                    onAcptBtnClick={onAcptBtnClick}
                    subFooter={<p className={SubHeaderMsgStyles}>{subFooter}</p>}
                    onCancelBtnClick={onCancelBtnClick}
                    showErrorImg={showErrorImg}
                    isBorderNone={isBorderNone}
                    btn1Focus={btn1Focus}
                    btn2Focus={btn2Focus}
                />
            }
            {loading && <Loader />}
        </HomeLayout>
    )
}

export default withOidcSecure(ViewCardDetails);
