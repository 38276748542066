import React, { useEffect, useState, useRef } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HomeLayout from '../../Layout/HomeLayout';
import DynamicCard from '../../CustomComponents/DynamicCard';
import { width } from '../../CustomComponents/DynamicCard/DynamicCardStyles';
import Loader from '../../CustomComponents/Loader';
import HomeHeader from '../HomeHeader';
import { GET_DETAILS_FRAUD_ALERT, POST_DETAILS_FRAUD_ALERT, STATUS_SUCCESS_CODE } from '../../../network/URLConstants';
import { fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import CustomAlertModel from '../CustomAlertModel';
import moment from 'moment';
import { useNavigate } from 'react-router';
import Home from '../../Home';
const appConfigData = await fetchConfigarationData();


const FraudAlert = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const headingText = 'w-[35%] font-[500] text-[#808080] lg:text-[14px] md:text-[14px] sm:text-[13px] text-[14px] uppercase font-hanken-grotesk'
    const headingTextvalue = 'font-[500] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[14px] font-hanken-grotesk'
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [transactionDetails, setTransactionDetails] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [ErrorMessage, setErrorMessage]=useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [ErrorDisplayText , setErrorDisplayText]=useState('');
 
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);


    useEffect(() => {

        const isAcceptAgreement = { isAcceptAgreement: true };
        dispatch({ type: 'IS_ACCEPT_AGREEMENT', payload: isAcceptAgreement });

        const currentURL = new URL(window.location.href);
        const codeParam = "cdbacf783e";
        // const codeParam = currentURL.searchParams.get('code');

        const getTransactionDetails = async () => {

            setLoading(true);
            const url = GET_DETAILS_FRAUD_ALERT.replace('{code}', codeParam) + `?appId=${appConfigData.CLIENT_ID}&lang=en`
            const deviceId = await retrieveData('updatedDeviceId');
            const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

            const response = await GetRequest(url, headers)


            setTimeout(() => {
                setLoading(false)
            }, 500);

            const responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                setTransactionDetails(responseObj.Response)
            } else {
                setErrorMessage(true) 
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                setErrorDisplayText(displayText);
                //showAlertModal(true, t("kudzu"), t("high_risk_transaction_alert"), displayText, true, false, t("home"), '', true, require('../../../assets/images/warning.png'), false, false)
            }
        }
        getTransactionDetails()
    }, [])
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    const closeCustomAlertModal = () => {

        const isAcceptAgreement = { isAcceptAgreement: false };
        dispatch({ type: 'IS_ACCEPT_AGREEMENT', payload: isAcceptAgreement });
        setShowModel(false);
        navigate('/home')
    }
    const onAcptBtnClick = async () => {
        if (btn1Text == t("home")) {
            const isAcceptAgreement = { isAcceptAgreement: false };
            dispatch({ type: 'IS_ACCEPT_AGREEMENT', payload: isAcceptAgreement }); navigate('/home')
        }
    }
    const onCancelBtnClick = () => {

    }
    const sendConfirmation = async (selectedConfirmation) => {
        setTimeout(async () => {
            const deviceId = await retrieveData('updatedDeviceId');
            const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
            var object = {
                "code": transactionDetails?.[0]?.code,
                "isValidTransaction": selectedConfirmation
            }
            var url = POST_DETAILS_FRAUD_ALERT + `?appId=${appConfigData.CLIENT_ID}&lang=en`
            setLoading(true)
            var response = await PostRequest(url, object, headers)
            setTimeout(() => {
                setLoading(false)
            }, 500);
            let parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                var arrMessages = parsedResponse.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("kudzu"), t("high_risk_transaction_alert"), displayText, true, false, t("home"), '', true, '', false, false)
            } else {
                var arrMessages = parsedResponse.Messages;
                var message = arrMessages[0];
                var displayText = 'smiONE: Response window has closed for this alert. Your response has not been recorded. If you do not recognize this activity on your account, login <a href=\"/\">here</a> or contact smiONE at the phone number on the back of your card.';
                showAlertModal(true, t("kudzu"), t("high_risk_transaction_alert"), displayText, true, false, t("home"), '', true, require('../../../assets/images/warning.png'), false, false)
            }
        }, 500);
    }


    return (
        <>
            <HomeLayout>
                <HomeHeader heading={t('kudzu_fraud_alert_heading')}
                    isHeaderCaption={true} />
                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated '>


                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        HeaderContent={false}
                        isWhite={true}
                        footer={true}>

                        <div className='pb-16'>
                            <div class="lg:my-[2rem] md:my-[1.5rem] my-[1rem] font-[500] lg:text-[26px] md:text-[22px] sm:text-[18px] text-[20px] leading-[36px] font-hanken-grotesk sm:px-0 px-4">{t('Fraud_Alert')}</div>
                            <div class="border-t-[2.5px] border-[#A3CEBB] sm:mx-0 px-4"></div>
                            
                        {!ErrorMessage ? (
                            <div>
                                <p className='mt-6 lg:text-[18px] md:text-[15px] sm:text-[14px] text-[16px] font-[500] font-hanken-grotesk leading-[30px] w-[84%] text-[#3F4353]'>
                                {t('farud_subheading')}
                                </p>

                                <div className='w-full py-8'>
                                <div className='w-full flex py-2'>
                                    <div className={headingText}>AMOUNT</div>
                                    <div className={headingTextvalue}>${transactionDetails?.[0]?.amt || '0'}</div>
                                </div>

                                <div className='w-full flex py-2'>
                                    <div className={headingText}>TRANSACTION DATE</div>
                                    <div className={headingTextvalue}>
                                    {moment(transactionDetails?.[0]?.transTime).format('YYYY-MM-DD') || ''}
                                    </div>
                                </div>

                                <div className='w-full flex py-2'>
                                    <div className={headingText}>MERCHANT NAME</div>
                                    <div className={headingTextvalue}>{transactionDetails?.[0]?.mercName || ''}</div>
                                </div>

                                <div className='w-full flex py-2'>
                                    <div className={headingText}>MERCHANT LOCATION</div>
                                    <div className={headingTextvalue}>{transactionDetails?.[0]?.mercLoc || ''}</div>
                                </div>
                                </div>

                                <div className='w-full justify-center flex gap-5 h-[40px] mb-20'>
                                <button
                                    className='px-12 bg-[#2965FB] text-white font-[700] font-hanken-grotesk'
                                    onClick={() => {
                                    sendConfirmation(true);
                                    }}
                                >
                                    YES
                                </button>
                                <button
                                    className='px-12 border-[2px] border-[#2965FB] text-[#2965FB] font-[600] font-hanken-grotesk'
                                    onClick={() => {
                                    sendConfirmation(false);
                                    }}
                                >
                                    No
                                </button>
                                </div>
                            </div>
                            ) : (
                                    <p className='pt-20 pb-20 m-8 text-left font-[500] lg:text-[16px] md:text-[16px] sm:text-[12px] text-[14px] font-hanken-grotesk text-[#5D6054]'
                                    dangerouslySetInnerHTML={{ __html: ErrorDisplayText.replace('<a href="/">', `<a href="/" style="color: blue;">`) }}>
                                    </p>
                            )}

                            <p className='font-[500] lg:text-[14px] md:text-[12px] sm:text-[12px] 
                            text-[14px] font-hanken-grotesk text-[#5D6054] opacity-[50%]'>{t('footer_par')}</p>

                            <p className='mt-4 font-[500] lg:text-[12px] md:text-[11px] 
                            sm:text-[10px] text-[12px] font-hanken-grotesk text-[#5D6054] opacity-[50%]'>
                                {t('fraud_alert_message1')}</p> 

                            <p className="pt-4 pb-4 text-[#5D6054] font-[500]  lg:text-[14px] md:text-[10px] sm:text-[12px]  text-[14px] font-hanken-grotesk opacity-[50%]">
                                {t("notice_1")}
                            </p>
                            <p className="pb-4 text-[#5D6054] font-[500]  lg:text-[14px] md:text-[10px] sm:text-[12px] 
 text-[14px] font-hanken-grotesk opacity-[50%]">
                                {t("notice_parg_1")}
                            </p>
                            

                        </div>



                    </DynamicCard>

                </div>

                {loading && <Loader />}
            </HomeLayout >
            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
            />
        </>

    )
}

export default FraudAlert