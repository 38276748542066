import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import tw from 'twin.macro';
import { Collapse, Ripple, initTE } from "tw-elements";
import { Link, useLocation } from 'react-router-dom'; // Make sure to import Link from react-router-dom
import { CHECKING_CARD_CODE, SAVING_CARD_CODE } from '../../../helpers/utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { useNavigate } from 'react-router-dom';
import beneficiarysaving from '../../../assets/images/beneficiarysaving.png';
import { retrieveData } from '../../../utils';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import { CAN_CREATE_SAVINGS_ACC, STATUS_SUCCESS_CODE } from '../../../network/URLConstants';
import moment from 'moment';
import Loader from '../Loader';
import logFirebaseEvents from '../../LogFirebaseEvents';

initTE({ Collapse, Ripple });

const HeaderCaption = styled.div`
  ${tw`md:w-[283px] sm:w-[133px] w-[300px] h-auto sm:px-0 pl-[2.5rem] mb-[2rem]`}
`;

const Title = styled.div`
  ${tw`text-white md:text-[32px] sm:text-[16px] text-[27px] font-[400] md:leading-[38px] sm:leading-[24px] leading-[31px]`}
  font-family: ${(props) => (props.isPrimary ? 'Source Serif Pro, serif' : 'Hanken Grotesk, sans-serif')};
`;

const Subtitle = styled.span`
  ${tw`text-[var(--text-clr-secondary)]`};
`;

const LeftsidemenuBtn = 'md:w-[227px] sm:w-[145px] w-full cursor-pointer md:mb-[1.5rem] sm:mb-[1rem] mb-[1.5rem] md:h-[50px] sm:h-[40px] h-[48px] rounded-[36px] md:pl-[30px] sm:pl-[13px] pl-[25px] flex border-[3px] items-center md:text-[16px] sm:text-[13px] text-[14px] font-[700] font-hanken-grotesk leading-[16px]  transition duration-150 ease-in-out cursor-pointer';

const SidemenuList = 'sm:border-0 border-b-[2px] border-[#4B70B9] border-opacity-[25%] rounded-[0px] my-[.5rem] sm:pb-0 pb-[.7rem] cursor-pointer bg-${bgColor} md:w-[227px] sm:w-[145px] w-full md:h-[50px] sm:h-[40px] h-[55px] rounded-[36px] flex md:pl-[30px] sm:pl-[13px] sm:ml-0  ml-[25px] items-center  md:text-[16px] sm:text-[13px] text-[14px] font-[700] font-hanken-grotesk leading-[16px] text-[#fff] hover:text-[#94C3E8] transition duration-150 ease-in-out';
const lastIndexItem = 'border-0 rounded-[0px] my-[.5rem] sm:pb-0 pb-[.7rem] cursor-pointer bg-${bgColor} md:w-[227px] sm:w-[145px] w-full md:h-[50px] sm:h-[40px] h-[55px] rounded-[36px] flex md:pl-[30px] sm:pl-[13px] sm:ml-0  ml-[25px] items-center  md:text-[16px] sm:text-[13px] text-[14px] font-[700] font-hanken-grotesk leading-[16px] text-[#fff] hover:text-[#94C3E8] transition duration-150 ease-in-out';

const SidemenuListSelectItem = 'sm:border-0 border-b-[2px] border-[#4B70B9] border-opacity-[25%] rounded-[0px] my-[.5rem] sm:pb-0 pb-[.7rem] cursor-pointer bg-${bgColor} md:w-[227px] sm:w-[145px] w-full md:h-[50px] sm:h-[40px] h-[55px] rounded-[36px] flex md:pl-[30px] sm:pl-[13px]  sm:ml-0  ml-[25px] items-center  md:text-[16px] sm:text-[13px] text-[14px] font-[700] font-hanken-grotesk leading-[16px] text-[#94C3E8] hover:text-[#94C3E8] transition duration-150 ease-in-out';


export default function HomeHeader({ heading, subheading, isHeaderCaption, selectedIndex, Width }) {
  const location = useLocation();
  const navigate = useNavigate();
  const cards = useSelector(state => JSON.parse(state.userCards));
  const savingsCardExist = cards?.some(item => item.AccountTypeName === "Savings")
  const savingsNewClicked = useSelector(state => state.savingsNewClicked);
  const selectedSubIndex = useSelector(state => state.selectedSubIndex);
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [isSubFooter, setIsSubFooter] = useState(false);

  const { t } = useTranslation();

  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [modelImage, setModelImage] = useState('')
  const [savingsTheme, setSavingsTheme] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const [isLoading, setIsLoading] = useState(false);
  const cardDetails = useSelector(state => state.selectedCardDetails);

  const dispatch = useDispatch();


  var arrMenu = []

  for (var i = 0; i < cards?.length; i++) {
    if (cards?.[i]?.AccountTypeBit63 == CHECKING_CARD_CODE) {
      var arrSubItems = [];
      arrSubItems.push({
        label: t('menu_deposit_money'),
        path: '/AccountServices/Spending/AddFunds',
      })
      if (cards?.[i]?.AllowFundTransfer) {
        arrSubItems.push({
          label: t('menu_transfer_money'),
          path: '/AccountServices/Spending/TransferMoney',
        })
      }

      arrSubItems.push({
        label: t('menu_edit_account_name'),
        path: '/AccountServices/Spending/EditAccountName',
      })
      if (cards?.[i]?.AllowAuthorizedUsers) {
        arrSubItems.push({
          label: t('manage_authorized_users'),
          path: '/AccountServices/Spending/ManageAuthorizedUsers',
        })
      }
      arrMenu.push({
        title: cards?.[i]?.AccountTypeName,
        state: 'Checkingexpanded',
        collapseFunction: 'checkingCollapse',
        ariaControl: 'checkingCollapse',
        path: '/AccountServices/Spending', // Replace this with your desired path for the Link
        subItems: arrSubItems,
        cardKey: cards?.[i]?.CardKey,
        AccountTypeBit63: cards[i].AccountTypeBit63
      })
    } else if (cards?.[i]?.AccountTypeBit63 == SAVING_CARD_CODE) {
      var arrSubItems = []
      if (cards?.[i]?.AllowFundTransfer) {
        arrSubItems.push({
          label: t('menu_transfer_money'),
          path: '/AccountServices/Savings/TransferMoney',
        })
      }
      arrSubItems.push({
        label: t('menu_edit_account_name'),
        path: '/AccountServices/Savings/EditAccountName',
      })

      arrMenu.push({
        title: cards?.[i]?.AccountTypeName,
        state: 'Savingsexpanded',
        collapseFunction: 'savingCollapse',
        ariaControl: 'savingCollapse',
        path: '/AccountServices/Savings', // Replace this with your desired path for the Link
        subItems: arrSubItems,
        cardKey: cards?.[i]?.CardKey,
        AccountTypeBit63: cards?.[i]?.AccountTypeBit63
      },)
    }
    else {
      var arrSubItems = []

      if (cards?.[i]?.AllowFundTransfer) {
        arrSubItems.push({
          label: t('menu_transfer_money'),
          path: '/AccountServices/Shared/TransferMoney',
        })
      }
      arrSubItems.push({
        label: t('menu_edit_account_name'),
        path: '/AccountServices/Shared/EditAccountName',
      })


      arrMenu.push({
        title: cards?.[i].AccountTypeName,
        state: 'Savingsexpanded',
        collapseFunction: 'savingCollapse',
        ariaControl: 'savingCollapse',
        path: '/AccountServices/Shared', // Replace this with your desired path for the Link
        subItems: arrSubItems,
        cardKey: cards?.[i]?.CardKey,
        AccountTypeBit63: cards[i].AccountTypeBit63
      },)
    }
  }

  // pusing savings when savings account is no enrolled
  if (!(arrMenu?.some(item => item.title == "Savings"))) {
    arrMenu.push({
      title: 'Savings',
      state: 'Savingsexpanded',
      collapseFunction: 'savingCollapse',
      ariaControl: 'savingCollapse',
      path: '', // Replace this with your desired path for the Link
      subItems: arrSubItems,
      cardKey: '',
      AccountTypeBit63: '32'
    },)
  }

  arrMenu.push({
    title: t('menu_external_accounts'),
    state: 'Exiternalexpanded',
    collapseFunction: 'externalCollapse',
    ariaControl: 'externalCollapse',
    path: '/AccountServices/ManageExternalAccounts', // Replace this with your desired path for the Link
    subItems: [],
    cardKey: cards?.[0]?.CardKey,
    AccountTypeBit63: cards?.[0]?.AccountTypeBit63
  },)
  // const [expandedItems, setExpandedItems] = useState({ Checkingexpanded: true });

  const canCreateSavingsAccount = async () => {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    setIsLoading(true)

    let finalUrl = CAN_CREATE_SAVINGS_ACC(cards[0].CardKey)
    var response = await GetRequest(finalUrl, headers)
    var responseObj = JSON.parse(response)
    setIsLoading(false)
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      const canCreateNew = responseObj.Response.canCreateNew
      const eligibleDate = moment(responseObj.Response.eligibleDate).format('MM/DD/YYYY')
      if (canCreateNew) {
        enrollSavingsAccount()

      } else {
        showAlertModal(true, t("oh_no"), t('unable_to_open'), `${t('u_will_be_eligible')} ${eligibleDate}. `, true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false, true)
      }
    } else {
      setTimeout(() => {
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        showAlertModal(true, t("oh_no"), displayText, '', true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false)

        // setError(displayText)
      }, 500);
    }
  }

  const subComponent = () => {
    return (
      <div className=' flex justify-start items-start'>
        <p className="font-hanken-grotesk font-semibold sm:font-[500] sm:text-[14px] md:text-[20px] text-[16px] md:leading-[30px] leading-[24px]">{t("pl_contact_customer")}<span className="text-[#2965FB] cursor-pointer" onClick={
          () => window.open("https://www.kudzumoney.com/contact-us", '_blank')}>{t("support_email")}</span>{t("for_access")}</p>
      </div>
    )
  }

  const enrollSavingsAccount = () => {

    const savingsNewPayload = { savingsNewClicked: true };
    dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
    showAlertModal(true, '', t('set_aside_money_for_the_things_that_matter'), t('add_savings_account_subTitle'), true, true, t('enroll'), t('may_be_later'), true, beneficiarysaving, true, true);


  }

  const handleMenuClick = (item, index) => {
    if (!(cards?.some(item => item.AccountTypeName === "Savings")) && item.title == 'Savings') {
      canCreateSavingsAccount()
    }
    else {
      // setExpandedItems((prevExpandedItems) => {
      //   const newExpandedItems = { ...prevExpandedItems };
      //   Object.keys(newExpandedItems).forEach((key) => {
      //     if (key !== item.state) {
      //       newExpandedItems[key] = false; // Close other menu items
      //     }
      //   });
      //   // newExpandedItems[item.state] = !prevExpandedItems[item.state]; // Toggle the clicked item
      //   return newExpandedItems;
      // })
      dispatch({ type: 'SELECTED_SUB_INDEX', payload: { selectedSubIndex: 1 } });
      if (item.title == t('menu_external_accounts')) {
        global.isManageExternalAccs = true
        global.selectedAccountServiceCardKey = -1
      } else {
        global.selectedAccountServiceCardKey = item.cardKey
        global.isManageExternalAccs = false
      }
    }
  };
  const closeCustomAlertModal = () => {

    setShowModel(false);
  }

  const onAcptBtnClick = async () => {
    setShowModel(false)
    if (btn1Text == t("enroll")) {
      navigate('/SetupSavingsAccount')
      dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('account_services_savings_enrollment') } });
    }else if (btn1Text == t('home')) {
      navigate('/home')
    }

  }
  const onCancelBtnClick = () => {
    setShowModel(false)
    showAlertModal(true, '', t('savings_no_problem_title'), t('via_account_services_title'), true, false, t('okay_got_it'), '', true, beneficiarysaving, true, true);
  }
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, savingsTheme, isBorderNone, isSubFooter) => {
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setSavingsTheme(savingsTheme)
    setisBorderNone(isBorderNone)
    setIsSubFooter(isSubFooter)
  }
  return (
    <>
      {isHeaderCaption ? (
        <>
          <HeaderCaption>
            <Title isPrimary>
              {heading}
              <Subtitle>{subheading}</Subtitle>
            </Title>
          </HeaderCaption>
          <div className='md:w-[150px] sm:w-[78px] w-[0px]'></div>
        </>
      ) : (
        <>
          <div className="md:w-[293px] sm:w-[150px] w-full sm:px-0 sm:py-0 px-[2.5rem] py-[2rem]">
            {arrMenu.map((item, index) => (
              <div key={item.title}>
                <Link to={item.path}>
                  <div
                    onClick={() => {handleMenuClick(item, index);}}
                    aria-expanded={item.cardKey == global.selectedAccountServiceCardKey}
                    aria-controls={item.ariaControl}
                  >
                    {item.title == t('menu_external_accounts') && <button
                      className=
                      {`${LeftsidemenuBtn} ${global.isManageExternalAccs ? "text-[#fff] bg-[#30BC89] hover:bg-[#30BC89] border-[#30BC89] hover:border-[#30BC89] hover:text-[#fff]" : 'border-[#A3CEBB] text-[#A3CEBB] hover:border-[#30BC89] hover:bg-[#30BC89] hover:text-[#fff]'}`}
                      // style={global.isManageExternalAccs ? { border: '3px solid #FEFAEE' } : {}}
                      type="button"
                    >
                      {item.title}
                      {isMobile && <img src={require('../../../assets/images/white_down_arrow_acc_services.png')} alt='down Icon' className={`mr-[1.1rem] w-[8px] h-[6px] ${(item.title == 'Savings') ? "ml-[5px]" : "ml-auto"}`} />}
                    </button>}
                    {item.title != t('menu_external_accounts') &&
                      <button
                        onClick={() => {
                          if (item.title == 'Savings' && !savingsNewClicked) { 
                            dispatch({ type: 'SAVINSGS_DISABLED', payload: { savingsNewClicked: true } });
                          }
                        }}
                        className=
                        {`${LeftsidemenuBtn} ${((global.selectedAccountServiceCardKey != undefined) ? (item.cardKey == (global.selectedAccountServiceCardKey)) : (location?.pathname?.toLocaleLowerCase()?.includes(item?.title?.toLocaleLowerCase()) || location?.pathname?.toLocaleLowerCase()?.includes(item?.title?.toLocaleLowerCase() == 'spending' ? 'checking' : 'random'))) ?
                          item.AccountTypeBit63 == SAVING_CARD_CODE ?
                            "text-[#fff] bg-[#30BC89] hover:bg-[#30BC89] border-[#30BC89] hover:border-[#30BC89] hover:text-[#fff]"
                            : "text-[#fff] bg-[#30BC89] hover:bg-[#30BC89] border-[#30BC89] hover:border-[#30BC89] hover:text-[#fff]"
                          : item.AccountTypeBit63 == SAVING_CARD_CODE ?
                            'border-[#A3CEBB] text-[#A3CEBB] hover:border-[#30BC89] hover:bg-[#30BC89] hover:text-[#fff]' : 'border-[#A3CEBB] text-[#A3CEBB] hover:border-[#30BC89] hover:bg-[#30BC89] hover:text-[#fff]'}`}
                        type="button"
                      >
                        {item.title}
                        {(!savingsCardExist && (item.title == 'Savings') && !savingsNewClicked) && <img src={require('../../../assets/images/Info-helper.png')} alt='Info Icon' className='mr-[0.5rem] w-[20px] h-[20px] ml-auto' />}
                        {(!savingsCardExist && (item.title == 'Savings') && savingsNewClicked) && <span className='mr-[1.1rem] ml-auto md:text-[14px] sm:text-[11px] text-[12px] font-[700] font-hanken-grotesk leading-[16px]  transition duration-150 ease-in-out cursor-pointer text-[#A3CEBB]  hover:bg-[#A3CEBB] hover:text-[#0C4437]'>Enroll</span>}
                        {isMobile && <img src={require('../../../assets/images/white_down_arrow_acc_services.png')} alt='down Icon' className={`mr-[1.1rem] w-[8px] h-[6px] ${(item.title == 'Savings') ? (!savingsCardExist ? "ml-[3px]" : "ml-auto") : "ml-auto"}`} style={(item.title === 'Savings' && !savingsCardExist) ? { transform: 'rotate(270deg)' } : {}} />}
                      </button>}

                  </div>
                </Link>
                {/* <Link to={item.path}>
                  <div
                    onClick={() => handleMenuClick(item, index)}
                    aria-expanded={item.cardKey == global.selectedAccountServiceCardKey}
                    aria-controls={item.ariaControl}
                  >
                    {item.title == t('menu_external_accounts') && <button
                      className=
                      {`${LeftsidemenuBtn} ${global.isManageExternalAccs ? 'text-[#FEFAEE] border-[3px] border-[#FEFAEE] hover:bg-transparent hover:border-[#FEFAEE]' : ' hover:border-[#FEFAEE] hover:bg-transparent text-[#FEFAEE] border-[3px] border-[#FEFAEE]'}`}
                      style={global.isManageExternalAccs ? { border: '3px solid #FEFAEE' } : {}}
                      type="button"
                    >
                      {item.title}
                      {isMobile && <img src={require('../../../assets/images/white_down_arrow_acc_services.png')} alt='down Icon' className={`mr-[1.1rem] w-[8px] h-[6px] ${(item.title == 'Savings') ? "ml-[5px]" : "ml-auto"}`} />}
                    </button>}
                    {item.title != t('menu_external_accounts') && <button
                      onClick={() => {
                        if (item.title == 'Savings' && !savingsNewClicked) { dispatch({ type: 'SAVINSGS_DISABLED', payload: { savingsNewClicked: true } }) }
                      }}
                      className=
                      {`${LeftsidemenuBtn} ${((global.selectedAccountServiceCardKey != undefined) ? (item.cardKey == (global.selectedAccountServiceCardKey)) : (location?.pathname?.toLocaleLowerCase()?.includes(item?.title?.toLocaleLowerCase()) || location?.pathname?.toLocaleLowerCase()?.includes(item?.title?.toLocaleLowerCase() == 'spending' ? 'checking' : 'random'))) ?
                        item.AccountTypeBit63 == SAVING_CARD_CODE ?
                          "text-[#0C4437] bg-[#A3CEBB] hover:bg-[#A3CEBB] border-[#A3CEBB] hover:border-[#A3CEBB] hover:text-[#0C4437]"
                          : 'bg-[#30BC89] border-[3px] border-[#30BC89] text-white hover:text-white'
                        : item.AccountTypeBit63 == SAVING_CARD_CODE ?
                          'border-[#A3CEBB] text-[#A3CEBB] hover:border-[#A3CEBB] hover:bg-[#A3CEBB] hover:text-[#0C4437]' : 'border-[#30BC89] hover:bg-[#30BC89] hover:text-[#fff] bg-[transparent] text-[#30BC89]'}`}
                      type="button"
                    >
                      {item.title}
                      {(!savingsCardExist && (item.title == 'Savings') && !savingsNewClicked) && <img src={require('../../../assets/images/Info-helper.png')} alt='Info Icon' className='mr-[0.5rem] w-[20px] h-[20px] ml-auto' />}
                      {(!savingsCardExist && (item.title == 'Savings') && savingsNewClicked) && <span className='mr-[1.1rem] ml-auto md:text-[14px] sm:text-[11px] text-[12px] font-[700] font-hanken-grotesk leading-[16px]  transition duration-150 ease-in-out cursor-pointer text-[#A3CEBB]  hover:bg-[#A3CEBB] hover:text-[#0C4437]'>Enroll</span>}
                      {isMobile && <img src={require('../../../assets/images/white_down_arrow_acc_services.png')} alt='down Icon' className={`mr-[1.1rem] w-[8px] h-[6px] ${(item.title == 'Savings') ? (!savingsCardExist ? "ml-[3px]" : "ml-auto") : "ml-auto"}`} style={(item.title === 'Savings' && !savingsCardExist) ? { transform: 'rotate(270deg)' } : {}} />}
                    </button>}

                  </div>
                </Link> */}
                <div className={`relative overflow-hidden ${((global.selectedAccountServiceCardKey != undefined) ? (item.cardKey == (global.selectedAccountServiceCardKey)) : (location?.pathname?.toLocaleLowerCase()?.includes(item?.title?.toLocaleLowerCase()) || location?.pathname?.toLocaleLowerCase()?.includes(item?.title?.toLocaleLowerCase() == 'spending' ? 'checking' : 'random'))) ? 'max-h-full' : 'max-h-0'}`}
                  id={item.ariaControl}>
                  <ul className='mb-7'>
                    {item.subItems.map((subItem, index) => (
                      <li key={index} style={{ borderRadius: '0px' }}
                        className={selectedSubIndex === index ? SidemenuListSelectItem : SidemenuList}
                      >
                        <Link to={subItem.path} onClick={() => { dispatch({ type: 'SELECTED_SUB_INDEX', payload: { selectedSubIndex: index } }); if(subItem.path === "/AccountServices/Spending/AddFunds"){
                        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Account_Services", cardDetails.AccountTypeName+"_"+"Add_funds", "Add_funds","", "");
                        }
                        else if (subItem.path === "/AccountServices/Spending/TransferMoney"){
                        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Account_Services", cardDetails.AccountTypeName+"_"+"Transfer_Money", "Transfer_Money","", "");
                        } 
                        else if (subItem.path === "/AccountServices/Spending/EditAccountName"){
                          logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Account_Services", cardDetails.AccountTypeName+"_"+"Edit_Account_Name", "Edit_Account_Name","", "");
                        }}}>{subItem.label}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
          <CustomAlertModel isOpen={showModel}
            modalBg={!isSubFooter && 'bg-[#E4F1EB]'}
            btn1BorderColor={!isSubFooter && '#30BC89'}
            btn1TextColor={!isSubFooter && '#FEFAEE'}
            btn1BrExist={!isSubFooter && true}
            btn2TextColor={!isSubFooter && '#559C7E'}
            btn1BgColor={!isSubFooter && '#30BC89'}

            onClose={closeCustomAlertModal}
            image={modelImage}
            highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
              <span className={`${HeaderMsgStyles} ${savingsTheme ? 'text-[#0C4437]' : 'text-black'}`}> {headerMsg}</span></p>}
            subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
            btn1Exists={btn1Exists}
            btn2Exists={btn2Exists}
            btn1Text={btn1Text} btn2Text={btn2Text}
            btn1TypeAcpt={btn1TypeAcpt}
            verificationFailure={() => { }}
            footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
            onAcptBtnClick={onAcptBtnClick}
            onCancelBtnClick={onCancelBtnClick}
            isBorderNone={isBorderNone}
            subFooter={isSubFooter && subComponent()}
          />
          {isLoading && <Loader />}
        </>
      )}
    </>
  );
}
