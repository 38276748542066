import { withOidcSecure } from '@axa-fr/react-oidc'
import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../../Layout/HomeLayout';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import Loader from '../../../../CustomComponents/Loader';
import { useTranslation } from 'react-i18next';
import AmountCard from './AmountCard';
import ConfirmationCard from './ConfirmationCard';
import RightArrow from "../../../../../assets/images/RightArrow@2x.png"
import Modal from '../../../../CustomComponents/Modal';
import SavingHabitTypeCard from './SavingHabitTypeCard';
import PercentageCard from './PercentageCard';
import FrequencyCard from './FrequencyCard';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveData } from '../../../../../utils';
import { GetApiHeadersWithBearer, PostRequest } from '../../../../../network/NetworkUtils';
import { useNavigate } from 'react-router';
import Success from '../Success';
import Error from '../Error';
import { ADD_SAVINGS_HABIT, STATUS_SUCCESS_CODE } from '../../../../../network/URLConstants';
import moment from 'moment';
import logFirebaseEvents from '../../../../LogFirebaseEvents';


const AddSavingsHabit = () => {
    const HeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3";

    const { t } = useTranslation()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [savingHabitCard, setSavingHabitCard] = useState("savingHabitType");
    const [savingHabitStatus, setSavingHabitStatus] = useState("")
    const [savingHabitErrorMsg, setsavingHabitErrorMsg] = useState("")
    const [savingHabitErrorMsgKey, setsavingHabitErrorMsgKey] = useState("")
    const [isAddSavingsHabit, setIsAddSavingsHabit] = useState("initial")

    const savingsHabitData = useSelector(state => state.savingsHabitData)
    const savingsHabitType = useSelector(state => state.savingsHabitData.SavingHabitType)
    const accessToken = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));



    //API Call-------------------
    const addSavingsHabit = async (addSavingsHabitPayload) => {
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = ADD_SAVINGS_HABIT.replace('{CARD_KEY}', cardKey)
            const body = addSavingsHabitPayload
            // {
            //     "amount": 20,
            //     "frequencyType": "Weekly",
            //     "startDate": "2023-07-12T00:00:00.000",
            //     "HabitType": "Weekly or Monthly",
            //     "percentageValue": 10
            //   }
            const response = await PostRequest(url, body, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {

                setIsLoading(false);
                // debugger;
                setSavingHabitStatus("success");
                // dispatch({ type: "RESET_SAVINGS_HABIT_DATA" })
            } else if (responseJson.Messages) {
                const displayErrorText = responseJson.Messages[0].DisplayText;
                const displayErrorTextKey = responseJson.Messages[0].MessageOrField;
                setIsLoading(false);
                setsavingHabitErrorMsg(displayErrorText);
                setsavingHabitErrorMsgKey(displayErrorTextKey);
                // dispatch({ type: "RESET_SAVINGS_HABIT_DATA" })
                if (savingsHabitType === 'Percent-based (%)') {
                    setSavingHabitStatus("percentError");

                } else if (savingsHabitType === 'Amount-based ($)') {
                    setSavingHabitStatus("amountError");
                }
            }

        } catch (error) {
        }
    }

    useEffect(() => {
        let payload;
        if (savingsHabitType === 'Percent-based (%)') {
            payload = {
                "HabitType": "ByDeposit",
                "percentageValue": savingsHabitData.Percent
            }
        } else if (savingsHabitType === 'Amount-based ($)') {
            payload = {
                "HabitType": "Amount",
                "amount": savingsHabitData.Amount,
                "frequencyType": savingsHabitData.Frequency,
                "startDate": moment(savingsHabitData.StartDate).format('YYYY-MM-DD'),
            }

        }
        if (isAddSavingsHabit !="initial") addSavingsHabit(payload)
    }, [isAddSavingsHabit])




    const cardHeader = () => {
        return (
            <>
                <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500]
lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
font-hanken-grotesk sm:mx-0 mx-4"> {t("saving_habit_heading")}</div>
                <div class="border-[#94C3E8] border-b-[3px] sm:mx-0 mx-4"></div>
            </>
        )
    }

    const cardFooter = (handleBackClick, handleNextClick, button1, button2) => {
        return (
            <div className='flex flex-row sm:justify-end justify-between items-center md:gap-16 sm:gap-10 sm:mx-0 mx-4 lg:pb-[5rem] md:pb-[4rem] sm:pb-[3rem] pb-[3rem]'>
                <p className='font-hanken-grotesk font-[600] sm:font-[700] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                leading-[26px] sm:leading-[13px]  md:leading-[18px] 
                text-secondary-2 cursor-pointer capitalize' onClick={handleBackClick}
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") handleBackClick();
                    }}
                >{button1 || `${t("back")}`}</p>
                <div>
                    <div className='flex flex-row justify-end  gap-2 cursor-pointer items-center'>
                        <p className='font-hanken-grotesk font-[600] sm:font-[700] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                         leading-[26px]  sm:leading-[13px] 
                          md:leading-[18px] text-secondary-2 ' onClick={handleNextClick}
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleNextClick();
                            }}
                        >
                            {button2 || `${t("next")}`}</p>
                        <img src={RightArrow} alt="arrow" className='h-[12px] w-[12px] sm:h-[10px]
                         sm:w-[11px]  md:h-[15px] md:w-[17px]' />
                    </div>
                </div>
            </div>
        )
    }
    const setSavingHabitCardFun = (value) => {
        setSavingHabitCard(value)
    }
    const setIsAddSavingsHabitFun = (value) => {
        setIsAddSavingsHabit(value)
    }
    const handleBackToClimb = () => {  
        logFirebaseEvents("Savings_Habit", "BackTo_Climb_Saving_Habit_Clicked", "BackTo_Climb_Saving_Habit_Clicked","", ""); 
        setIsSuccessfullModalOpen(false);
        navigate('/Climb/ClimbMenu')
    }
    const handleBackToSavingsHabit = () => {  
        logFirebaseEvents("Savings_Habit", "BackTo_Saving_Habit_Clicked", "BackTo_Saving_Habit_Clicked","", ""); 
        // if (hasConfigurations) navigate("/Climb/ManageSavingsHabit")
        // else 
        navigate("/Climb/ManageSavingsHabit")
    }


    useEffect(() => {
        switch (savingHabitCard) {
            case "savingHabitType":
                displaySavingHabitTypeCard();
                hideAmountCard();
                hidePercentageCard()
                hideFrequencyCard()
                hideConfimationCard()

                break;
            case "percentage":
                displayPercentageCard();
                hideAmountCard();
                hideSavingHabitTypeCard();
                hideFrequencyCard()
                hideConfimationCard()

                break;
            case "amount":
                displayAmountCard();
                hideSavingHabitTypeCard();
                hidePercentageCard()
                hideFrequencyCard()
                hideConfimationCard()

                break;
            case "frequency":
                displayFrequencyCard();
                hideAmountCard();
                hideSavingHabitTypeCard();
                hidePercentageCard()
                hideConfimationCard()

                break;
            case "confimation":
                displayConfimationCard();
                hideAmountCard();
                hideSavingHabitTypeCard();
                hidePercentageCard()
                hideFrequencyCard()

                break;

        }
    }, [savingHabitCard])

    useEffect(() => {
        switch (savingHabitStatus) {
            case "success":
                openSuccessfullModal();
                break;
            case "percentError":
                openPercentErrorModal();
                break;
            case "amountError":
                openAmountErrorModal();
                break;
        }
    }, [savingHabitStatus])





    // DISPLAY CARDS--------


    //SavingHabitType  card
    const [isSavingHabitTypeCard, setIsSavingHabitTypeCard] = useState(false);

    const displaySavingHabitTypeCard = () => {
        setIsSavingHabitTypeCard(true);
    }

    const hideSavingHabitTypeCard = () => {
        setIsSavingHabitTypeCard(false);
    }

    //Percentage card
    const [isPercentageCard, setIsPercentageCard] = useState(false);

    const displayPercentageCard = () => {
        setIsPercentageCard(true);
    }

    const hidePercentageCard = () => {
        setIsPercentageCard(false);
    }

    //Amount card
    const [isAmountCard, setIsAmountCard] = useState(false);

    const displayAmountCard = () => {
        setIsAmountCard(true);
    }

    const hideAmountCard = () => {
        setIsAmountCard(false);
    }

    //Frequency card
    const [isFrequencyCard, setIsFrequencyCard] = useState(false);

    const displayFrequencyCard = () => {
        setIsFrequencyCard(true);
    }

    const hideFrequencyCard = () => {
        setIsFrequencyCard(false);
    }
    //Confimation card
    const [isConfimationCard, setIsConfimationCard] = useState(false);

    const displayConfimationCard = () => {
        setIsConfimationCard(true);
    }

    const hideConfimationCard = () => {
        setIsConfimationCard(false);
    }


    // Successfull transfer modal
    const [isSuccessfullModalOpen, setIsSuccessfullModalOpen] = useState(false);

    const openSuccessfullModal = () => {
        setIsSuccessfullModalOpen(true);
    };

    const closeSuccessfullModal = () => {
        // dispatch({ type: "RESET_SAVINGS_HABIT_DATA" })
        setIsSuccessfullModalOpen(false);
        // navigate("/Climb/ClimbMenu")

    };

    // Percent Error modal
    const [isPercentErrorModalOpen, setIsPercentErrorModalOpen] = useState(false);

    const openPercentErrorModal = () => {
        setIsPercentErrorModalOpen(true);
    };

    const closePercentErrorModal = () => {
        dispatch({ type: "RESET_SAVINGS_HABIT_DATA" })
        setIsPercentErrorModalOpen(false);
        // navigate("/Climb/ClimbMenu")

    };
    // Amount Error modal
    const [isAmountErrorModalOpen, setIsAmountErrorModalOpen] = useState(false);

    const openAmountErrorModal = () => {
        setIsAmountErrorModalOpen(true);
    };

    const closeAmountErrorModal = () => {
        dispatch({ type: "RESET_SAVINGS_HABIT_DATA" })

        setIsAmountErrorModalOpen(false);
        navigate("/Climb/ClimbMenu")

    };
    return (
        <HomeLayout>
            <HomeHeader heading={t("saving_habit_left_heading")} isHeaderCaption={true} />
            <div className='flex flex-col lg:w-[860px] md:w-[860px] 
            sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>
                {isSavingHabitTypeCard && <SavingHabitTypeCard cardHeader={cardHeader()} cardFooter={cardFooter} onBack={setSavingHabitCardFun} onNext={setSavingHabitCardFun} />}
                {(savingsHabitType === 'Percent-based (%)' && isPercentageCard) && <PercentageCard cardHeader={cardHeader()} cardFooter={cardFooter} onBack={setSavingHabitCardFun} onSave={setIsAddSavingsHabitFun} />}

                {isAmountCard && <AmountCard cardHeader={cardHeader()} cardFooter={cardFooter} onBack={setSavingHabitCardFun} onNext={setSavingHabitCardFun} />}
                {isFrequencyCard && <FrequencyCard cardHeader={cardHeader()} cardFooter={cardFooter} onBack={setSavingHabitCardFun} onNext={setSavingHabitCardFun} />}
                {isConfimationCard && <ConfirmationCard cardHeader={cardHeader()} cardFooter={cardFooter} onBack={setSavingHabitCardFun} onEdit={setSavingHabitCardFun} onSave={setIsAddSavingsHabitFun} />}

            </div>


            <Modal
                isOpen={isSuccessfullModalOpen}
                onClose={closeSuccessfullModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('success')}</p>}
                subtitle={
                    <p>
                        <span className={`${HeadingStyles} visible sm:hidden`}>{t('success')}</span><span className={SubHeadingStyles}>{t('add_savings_habit_modal_heading')}</span>
                    </p>}
                content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
                    <Success content1={t("add_savings_habit_modal_content1")} btn1={t("back_to_climb")} btn1Function={handleBackToClimb} btn2={t("back_to_savings_habit")} btn2Function={handleBackToSavingsHabit} /></div>}
            />

            <Modal
                isOpen={isPercentErrorModalOpen}
                onClose={closePercentErrorModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('oops_exclamation')}</p>}
                subtitle={
                    <p>
                        <span className={`${HeadingStyles} visible sm:hidden`}>{t('oops_exclamation')}</span>
                        <span className={SubHeadingStyles}>{savingHabitErrorMsgKey==="AlertStartDate" ? savingHabitErrorMsg : t('error_savings_habit_modal_content1')}</span>
                    </p>}

                content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
                    <Error content1={savingHabitErrorMsgKey!=="AlertStartDate" && savingHabitErrorMsg} btn1={t("back_to_savings_habit")}
                        btn1Function={handleBackToSavingsHabit} btn2={t("cancel")}
                        btn2Function={closePercentErrorModal} /></div>}
            />
            <Modal
                Fullheight={true}
                isOpen={isAmountErrorModalOpen}
                onClose={closeAmountErrorModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('oops_exclamation')}</p>}
                subtitle={
                    <p>
                        <span className={`${HeadingStyles} visible sm:hidden`}>{t('oops_exclamation')}</span><span className={SubHeadingStyles}>{savingHabitErrorMsgKey==="AlertStartDate" ? savingHabitErrorMsg : t('error_savings_habit_modal_content2')}</span>
                    </p>}
                content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6"><Error content1={savingHabitErrorMsgKey!=="AlertStartDate" && t("savingsHabitErrorMsg")} btn1={t("try_again")} btn1Function={closeAmountErrorModal} btn2={t("cancel")} btn2Function={closeAmountErrorModal} /></div>}
            />

            {isLoading && <Loader />}


        </HomeLayout >
    )
}

export default withOidcSecure(AddSavingsHabit)

