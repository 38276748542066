import React, { useEffect, useRef, useState } from 'react'
import { width, height } from "../CustomComponents/Card/CardStyles";
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import right_dropdown_arrow from '../../assets/images/right_dropdown_arrow.png'
import CustomTextInput from '../CustomComponents/CustomtextInput';
import Loader from '../CustomComponents/Loader';
import Modal from '../CustomComponents/Modal';
import { GENERATE_EXISTING_PHONE_EMAIL_OTP, GET_ACCOUNTS_FOR_TRANSFER, REMOVE_EXTERNAL_ACCOUNT, SAVINGS_CARD, STATUS_SUCCESS_CODE } from '../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../network/NetworkUtils';
import { useNavigate } from 'react-router-dom';
import { retrieveData } from '../../helpers/utils/Utils';
import CustomAlertModel from '../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc';
import Footer from '../Layout/Footer';
import HomeLayout from '../Layout/HomeLayout';
import HomeHeader from '../CustomComponents/HomeHeader';
import DynamicCard from '../CustomComponents/DynamicHomeCard';
import LeftBar from '../CustomComponents/DynamicComponent/LeftBar';

import { useKeyDown } from '../../hooks/useKeyDown';
import CardComponent from './Cards';



const CardServices = ({ transactionDatas, onViewAllClick, viewAll }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const dispatch = useDispatch();
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const selectedItem = cards?.[0]
    const [generateBtnClicked, setGenerateBtnClicked] = useState(true); //Routing Number
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const divRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [selectedExternalAccount, setSelectedExternalAccount] = useState({})
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([
        { "AccountType": "ACH", "AccountName": "Checking Account .. 0318", "AccountId": 564199, "ACHAccountInternalId": 10131, "StatusCode": "PDG" },
        // { "AccountType": "ACH", "AccountName": "Checking Account .. 0318", "AccountId": 564199, "ACHAccountInternalId": 10131, "StatusCode": "" },
        // { "AccountType": "ACH", "AccountName": "Checking Account .. 0318", "AccountId": 564199, "ACHAccountInternalId": 10131, "StatusCode": "PDG" }
    ])
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);

    useKeyDown(() => { }, ["Enter"]);
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    const leftBarComp = () => {
        return (
            <>
                <div className='lg:w-[270px] md:w-[210px] sm:w-[164px] w-full sm:mx-0 ml-[2.5rem]'>
                    <p className="text-white font-normal 
                    lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] 
                    sm:leading-[30px] leading-[31px]
          font-serif-pro lg:pb-14 md:pb-[50px]  border-opacity-[50%]
          sm:pb-[2.6rem] pb-[2.6rem] sm:mb-10">{t("click_on_the_card_to_manage_your_account")}</p>
                </div>
            </>
        )
    }
    return (
        <HomeLayout>
            <LeftBar leftBarType='component' isPrimary={true} leftBarComp={leftBarComp()} />
            <div className='lg:w-[0px] md:w-[70px] sm:w-[70px] w-[0px]'></div>
            <div className='flex flex-col md:w-[860px] sm:w-[464px] 
      w-[100%] animate__animated animate__fadeInRight pb-40'>
                <div className={`flex flex-col w-full gap-4 sm:flex-row sm:items-center space-y-[.5rem]
       ${cards?.length > 3 ? 'flex-wrap' : 'flex-nowrap'}`}>
                    {cards?.map((item, index) => (item.AccountTypeBit63 != SAVINGS_CARD &&
                        <div className='cursor-pointer sm:px-0 px-10 sm:w-[31%] w-full' onClick={() => {
                      
                            dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: item } });
                            navigate('/CardServices/CardActionScreen', { state: { item: item } })
                        }}>
                            <CardComponent item={item} index={index} />
                        </div>)
                    )}
                </div>
            </div>
            {loading && <Loader />}
        </HomeLayout>

    )
}

export default withOidcSecure(CardServices);
