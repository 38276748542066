import React from 'react'
import Card from '../../../CustomComponents/Card'
import { useTranslation } from 'react-i18next';
import { addLineBreaks } from '../../../../utils';
import Arrow from '../../../../assets/images/arrow.png';
import Loader from '../../../CustomComponents/Loader';


const PatriotAct = ({openTermsandConditionsonClick}) => {
  const { t } = useTranslation();
  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]";


    return (
        <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0">
          <Card
            cardHeight={'auto'}
            heading={
              <>
                <span className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)] lg:text-[31px]
                 md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
                  md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('patriot_act_heading1')}</span>
                <span className='font-serif-pro sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)] lg:text-[31px]
                 md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
                  md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('patriot_act_heading2')}</span>
              </>
            }
            // subheading={addLineBreaks(t('create_password__subheading3'), SubTittleStylesModal)}
            Cardcaption={t('patriot_act_cardheading')} header={true} footer={true} CardRadius={true}>
           <div className='flex flex-col mt-20 gap-8'>
            <p className=' font-400 text-[12px] leading-[18px] sm:font-500 sm:text-[12px] sm:leading-[20px] md:text-[20px] md:leading-[30px]  capitalize'>{t("patriot_act_imp_information")}</p>
            <p className=' font-600 text-[12px] leading-[18px] sm:font-500 sm:text-[12px] sm:leading-[20px] md:font-400 md:text-[18px] md:leading-[28px]'>{t("patriot_act_to_help_the_govrn")}</p>
           </div>
    
            <div className="flex justify-end items-center mt-20 mb-24">
              <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                  text-[#2965FB] cursor-pointer flex items-center"
                onClick={()=>openTermsandConditionsonClick()} // Call handleContinue instead of openPhoneOTPModal directly
              >
                {t('continue')}
                <img
                  src={Arrow}
                  className="md:w-[17px] sm:w-[11px] md:h-[17px] sm:h-[11px] w-[12px] h-[12px] ml-2 animate__animated animate__flash animate__repeat-4"
                  alt="Arrow"
                />
              </button>
            </div>
          </Card>
          {/* {isLoading && <Loader />} */}
        </div>
      )
}

export default PatriotAct