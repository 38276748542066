import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../CustomComponents/Card';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import right_dropdown_arrow from '../../../../assets/images/right_dropdown_arrow.png'
import CustomTextInput from '../../../CustomComponents/CustomtextInput';
import Loader from '../../../CustomComponents/Loader';
import Modal from '../../../CustomComponents/Modal';
import { ADD_ACH_ACCOUNT, GET_ACCOUNTS_FOR_TRANSFER, GET_ACCOUNT_TYPES, GET_USED_AMOUNT_DETAILS, STATUS_SUCCESS_CODE } from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import CustomDropDown from '../../../CustomComponents/CustomDropDown';
import { retrieveData } from '../../../../helpers/utils/Utils';
import { displayErrorMessage, MoreServices } from '../../../../utils';
import blue_drop_down from '../../../../assets/images/blue_dropdown_arrow.png'
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc'
import Footer from '../../../Layout/Footer';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import { width, height } from "../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import HomeLayout from '../../../Layout/HomeLayout';
import { useLocation, useNavigate } from 'react-router-dom';


const AddExternalAccount = ({ transactionDatas, onViewAllClick, viewAll }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);

    const location = useLocation();
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const selectedItem = cards?.[0]
    const [generateBtnClicked, setGenerateBtnClicked] = useState(true); //Routing Number
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const divRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [routingNumberDetails, setRoutingNumberDetails] = useState({ routingNumber: '', routingNumberTouched: false, routingNumberValid: false })
    const [accountNumberDetails, setAccountNumberDetails] = useState({ accountNumber: '', accountNumberTouched: false, accountNumberValid: false })
    const [confirmAccountNumberDetails, setConfirmAccountNumberDetails] = useState({ confirmAccountNumber: '', confirmAccountNumberTouched: false, confirmAccountNumberValid: false })
    const [routingNumberError, setRoutingNumberError] = useState('')
    const [accountNumberError, setAccountNumberError] = useState('')
    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState('')
    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [infoMessage, setinfoMessage] = useState('');
    const [nextClicked, setNextClicked] = useState(false)
    const isInitialRenderRef = useRef(true);
    const [modelType, setModelType] = useState('')
    const [accountId, setAccountId] = useState(0)
    const [selectedAccountType, setSelectedAccountType] = useState({ "Type": "", "Name": "Select" })
    const [accountTypes, setAccountTypes] = useState([
    ])
    const HighlightMsgStyles = "sm:w-auto w-[240px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "text-black md:w-[400px] sm:w-[239px] w-[200px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[16px] md:text-[15px] sm:text-[14px] text-[14px] font-normal cursor-pointer pointer-events-none";
    const footerMsgstyles = "font-hanken-grotesk modal-title lg:text-[14px] md:text-[13px] sm:text-[13px] text-[14px] font-normal cursor-pointer pointer-events-none";
    const [showModel, setShowModel] = useState(false);
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [isOpen, setIsOpen] = useState('0');
    const [footerMsg, setFooterMsg] = useState('');
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [btn1BgColor, setBtn1BgColor] = useState(false);
    const [btn1TextColor, setBtn1TextColor] = useState(false);

    const moreServicesArray = [
        { name: t('configure_notifications'), actionType: '', path: '/Settings/notificationConfigure' }
        // { name: t('Manage_Membership'), actionType: '', path: '' },
        // { name: t('Set_Up_Beneficiariess'), actionType: '', path: '/Settings/manageBeneficiaries' }

    ];
    useEffect(() => { if (cards != null && cards?.length > 0 && cards?.[0]?.AllowBeneficiaries) { moreServicesArray.push({ name: t('Set_Up_Beneficiariess'), actionType: '', path: '/Settings/manageBeneficiaries' }); } }, [cards])

    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleGoBack = () => {
        navigate('/Settings/ManageExternalAccounts')
    };

    const handleOptionSelect = (option) => {
        // onSelect(option);
        setIsOpen(false);
    };
    // acts as componentDidMount
    useEffect(() => {
        acquireAccountTypes()
    }, []);
    const headerContent = () => {
        return (
            <div className="flex flex-row justify-start mx-10 items-center mt-10">
                <div className='ml-10'>
                    {/* Left Text */}
                    <p className="text-xmd ">{t("external_accounts")}</p>
                </div>
            </div>
        )
    }
    // useKeyDown(() => {
    //     if (!nextClicked) {
    //         setNextClicked(true)
    //     } else if (nextClicked) {
    //         handleAddAccount()
    //     }
    // }, ["Enter"]);
    const acquireAccountTypes = async () => {
        if (selectedItem?.CardKey == null || selectedItem?.CardKey == undefined) {
            return
        }
        var url = GET_ACCOUNT_TYPES.replace("{cardKey}", selectedItem?.CardKey)
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setLoading(true)
        GetRequest(url, headers).then((response) => {

            try {
                var parsedResponse = JSON.parse(response)
                setAccountTypes(parsedResponse?.Response?.AccountTypes)
            } catch (e) {
            }
            setTimeout(() => {
                setLoading(false)
            }, 500);
        })
    }
    const handleAddAccount = async () => {
        if (lastName == '') setFirstNameError("First Name is required")
        if (firstName == '') setLastNameError("Last Name is required")
        if (accountNumberDetails.accountNumber == '') setRoutingNumberError("Routing number is required")
        if (routingNumberDetails.routingNumber == '') setAccountNumberError("Account numberis required")
        if (confirmAccountNumberDetails.confirmAccountNumber == '') setinfoMessage("Please enter valid details.")
        if (firstNameError != '' || lastNameError != '' || accountNumberError != '' || routingNumberError != '' || infoMessage != '') {
            return;
        }
        if (routingNumberDetails.routingNumber === accountNumberDetails.accountNumber) {
            setAccountNumberError(t('acc_num_error'))
            return;
        }
        if (routingNumberDetails.routingNumberValid && accountNumberDetails.accountNumberValid && confirmAccountNumberDetails.confirmAccountNumberValid && lastName != '' && firstName != '') {
            setModelType('verify_account_initiation')
            showAlertModal(true, '', t('we_need_to_verify_your_account'), t("by_adding_your_external_account"), true, true, t("confirm"), t("cancel"), true, '', t("to_make_payments_on_or_transfer"), false, true)
        } else {
            if (accountNumberDetails.accountNumber !== accountNumberDetails.confirmAccountNumber) {
                setinfoMessage(t('the_account_numbers_you_provided_do_not_match_please_try_again'))
                return;
            }
            setinfoMessage('Please enter valid details.')
        }
    }
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1TextColor, btn1BgColor) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
        setBtn1TextColor(btn1TextColor)
        setBtn1BgColor(btn1BgColor)
    }
    const onChangeHandle = (type, event) => {
        if (type == t('last_name')) {
            clearAllError()
            const val = event.target.value;
            setLastName(val)
        }
        else if (type == t('first_name')) {
            clearAllError()
            const val = event.target.value;
            setFirstName(val)
        }

        else if (type == t('routing_number')) {
            clearAllError()
            if (event.target.value != "") {
                const regexPattern = /^[0-9]{1,9}$/;
                if (regexPattern.test(event.target.value)) {
                    setRoutingNumberDetails(prevState => ({ ...prevState, routingNumber: event.target.value, routingNumberTouched: true }))
                } else {
                }
            } else if (event.target.value == "") {
                setRoutingNumberDetails(prevState => ({ ...prevState, routingNumber: '', routingNumberTouched: true, routingNumberValid: false }))
            }
        } else if (type == t('account_number')) {
            clearAllError()
            if (event.target.value != "") {
                const regexPattern = /^[0-9]{1,17}$/;
                if (regexPattern.test(event.target.value)) {
                    setAccountNumberDetails(prevState => ({ ...prevState, accountNumber: event.target.value, accountNumberTouched: true }))
                }
            } else if (event.target.value == "") {
                setAccountNumberDetails(prevState => ({ ...prevState, accountNumber: '', accountNumberTouched: true, accountNumberValid: false }))
            }
        } else if (type == t('confirm_account_number')) {
            clearAllError()
            if (event.target.value != "") {
                const regexPattern = /^[0-9]{1,17}$/;
                if (regexPattern.test(event.target.value)) {
                    setConfirmAccountNumberDetails(prevState => ({ ...prevState, confirmAccountNumber: event.target.value, confirmAccountNumberTouched: true }))
                }
            } else if (event.target.value == "") {
                setConfirmAccountNumberDetails(prevState => ({ ...prevState, confirmAccountNumber: '', confirmAccountNumberTouched: true, confirmAccountNumberValid: false }))
            }
        }

    }
    const validateInputLength = (input, setInput, exists, length) => {
        let isValid = routingNumberDetails.routingNumber.length == 9 ? true : false;
        if (input == 'routingNumber') {
            setInput((prevState) => ({
                ...prevState,
                [`${input}Valid`]: isValid,
                [`${input}Touched`]: true,
            }));
        } else if (input == 'accountNumber') {
            if (accountNumberDetails.accountNumber.length <= 17 && accountNumberDetails.accountNumber.length >= 9) {
                setAccountNumberDetails(prevState => ({ ...prevState, accountNumberTouched: true, accountNumberValid: true }))
            } else {
                setAccountNumberDetails(prevState => ({ ...prevState, accountNumberTouched: true, accountNumberValid: false }))
                setinfoMessage(t('add_valid_external_account'))
            }
            if (confirmAccountNumberDetails.confirmAccountNumberTouched) {
                if (accountNumberDetails.accountNumber == confirmAccountNumberDetails.confirmAccountNumber && confirmAccountNumberDetails.confirmAccountNumber != '' && confirmAccountNumberDetails.confirmAccountNumber.length >= 9 && confirmAccountNumberDetails.confirmAccountNumber.length <= 17) {
                    setConfirmAccountNumberDetails(prevState => ({ ...prevState, confirmAccountNumberTouched: true, confirmAccountNumberValid: true }))
                } else {
                    setConfirmAccountNumberDetails(prevState => ({ ...prevState, confirmAccountNumberTouched: true, confirmAccountNumberValid: false }))
                }
            }
        } else if (input == 'confirmAccountNumber') {
            if (accountNumberDetails.accountNumber == confirmAccountNumberDetails.confirmAccountNumber && confirmAccountNumberDetails.confirmAccountNumber != '' && confirmAccountNumberDetails.confirmAccountNumber.length >= 9 && confirmAccountNumberDetails.confirmAccountNumber.length <= 17) {
                setConfirmAccountNumberDetails(prevState => ({ ...prevState, confirmAccountNumberTouched: true, confirmAccountNumberValid: true }))
            } else {
                setConfirmAccountNumberDetails(prevState => ({ ...prevState, confirmAccountNumberTouched: true, confirmAccountNumberValid: false }))
            }
        }
        if (accountNumberDetails.accountNumber !== confirmAccountNumberDetails.confirmAccountNumber && accountNumberDetails.accountNumber.length == confirmAccountNumberDetails.confirmAccountNumber.length) {
            setinfoMessage(t('the_account_numbers_you_provided_do_not_match_please_try_again'))
        } else {
            setinfoMessage('')
        }
    };

    useEffect(() => {
        if (isInitialRenderRef.current <= 3) {
            // Skip the first render
            isInitialRenderRef.current = isInitialRenderRef.current + 1;
        } else {
            validateInputLength('routingNumber', setRoutingNumberDetails, 9, routingNumberDetails.routingNumber.length);
        }
    }, [routingNumberDetails.routingNumber]);
    useEffect(() => {
        if (isInitialRenderRef.current <= 3) {
            // Skip the first render
            isInitialRenderRef.current = isInitialRenderRef.current + 1;
        } else {
            validateInputLength('accountNumber', setAccountNumberDetails, 9, accountNumberDetails.accountNumber.length);
        }
    }, [accountNumberDetails.accountNumber])
    useEffect(() => {
        if (isInitialRenderRef.current <= 3) {
            // Skip the first render
            isInitialRenderRef.current = isInitialRenderRef.current + 1;
        } else {
            validateInputLength('confirmAccountNumber', setConfirmAccountNumberDetails, 9, confirmAccountNumberDetails.confirmAccountNumber.length);
        }
    }, [confirmAccountNumberDetails.confirmAccountNumber])


    const closeOTPVerifyModal = () => {
        setIsOTPVerifyModalOpen(false);
    }
    const closeModel = () => {
        setShowModel(false);
    }
    const onAcptBtnClick = async () => {
        setShowModel(false)
        if (subHeaderMsg == t("your_external_bank_account_has_been_added_verify_your_acc_to_complete_link")) {
            navigate('/Settings/ManageExternalAccounts/VerifyExternalAccount', { state: { externalAccountDetails: { "AccountType": selectedAccountType.Type, "AccountName": `${selectedAccountType.Name} .. ${confirmAccountNumberDetails.confirmAccountNumber.slice(-4)}`, "AccountId": 0, "ACHAccountInternalId": accountId, "StatusCode": "PDG" } } })
        } else if (subHeaderMsg == t('by_adding_your_external_account')) {

            setTimeout(async () => {
                const deviceId = await retrieveData('updatedDeviceId');
                const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

                var object = {
                    ACHAccountNo: accountNumberDetails.accountNumber,
                    achroutingNo: routingNumberDetails.routingNumber,
                    type: selectedAccountType.Type,
                    cardkey: selectedItem?.CardKey,
                    firstName: firstName,
                    lastName: lastName
                }
                var url = ADD_ACH_ACCOUNT
                setLoading(true)
                var response = await PostRequest(url, object, headers)
                setTimeout(() => {
                    setLoading(false)
                }, 500);
                let parsedResponse = JSON.parse(response);
                if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                    setShowModel(false)
                    setAccountId(parsedResponse.Response.ACHAccountId)
                    setTimeout(() => {
                        setModelType(t("your_account_has_been_verified"))
                        showAlertModal(true, t("great_exclamation"), t('your_external_bank_account_has_been_added'), t("your_external_bank_account_has_been_added_verify_your_acc_to_complete_link"), true, true, t('verify_account'), t('i_will_do_it_later'), true, '', t("to_make_payments_on_or_transfer"), true, true)
                    }, 1000);

                } else {
                    var arrMessages = parsedResponse.Messages;
                    var message = arrMessages[0];
                    var displayText = message.DisplayText;
                    var subHeaderMessage = "";
                    var acceptText = "";
                    if (displayText == t("ach_already_exists")) {
                        setShowModel(false)
                        setTimeout(() => {
                            setModelType(t("this_type_of_account_cannot_be_added"))
                            showAlertModal(true, t("oops_exclamation"), t("this_external_bank_account_has"), t('the_bank_account_number_and_routing'), true, true, t('back_to_ext_accounts'), t("cancel"), true, '', '', true, true, '#2965FB', 'none' )
                        }, 1000);
                    } else if (displayText == t("already_added_ach")) {
                        setShowModel(false)
                        setTimeout(() => {
                            showAlertModal(true, t("uh_oh"), t("this_type_of_account_cannot_be_added"), '', true, true, 'Accept', 'Decline', false, '', '', false, false)
                        }, 500);
                    } else {
                        showAlertModal(true, t("oops_exclamation"), displayText, '', true, false, t('ok'), '', false, '', '', false, false)
                    }
                }
            }, 500);
        } else if (btn1Text == t('back_to_ext_accounts')) {
            // navigate('/Settings/ManageExternalAccounts/VerifyExternalAccount', { state: { externalAccountDetails: item } })
            navigate('/Settings/ManageExternalAccounts')
        }
    }
    const onCancelBtnClick = () => {
        if (btn2Text == t('back_to_ext_accounts')) {
            navigate('/Settings/ManageExternalAccounts')
        } else if (btn2Text == t('i_will_do_it_later')) {
            navigate('/Settings/ManageExternalAccounts')
        } else if (btn2Text == 'Cancel') {
            closeModel()
        }
    }
    const footerBtnClick = (type) => {
        if (type == t('configure_notifications')) { navigate("/Settings/notificationConfigure") }
        // if (type == t('manage_membership')) { }
        if (type == t('setup_beneficiaries')) { navigate("/Settings/manageBeneficiaries") }
    }

    const clearAllError = () => {
        setLastNameError('')
        setFirstNameError('')
        setRoutingNumberError('');
        setAccountNumberError('')
        setinfoMessage('')
    }

    const HeadingComponent = () => {
        // Check the screen width using window.innerWidth or use a media query
        const isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed

        return (
            <div>
                {isMobile ? (
                    <p className="block sm:hidden md:lg:w-[615px] font-[400] font-serif-pro text-white lg:text-[26px] md:text-[22px] sm:text-[22px] text-[25px]">
                        {(nextClicked ? t('next') : t('lets_get_started'))}, <span className='text-[#94C3E8]'>{(nextClicked ? t('please_enter_the_routing_account_number') : t('what_type_of_account_would_you_like_to_add'))}</span>
                    </p>
                ) : (
                    t('complte_add_external_steps')
                )}
            </div>
        );
    };


    return (
        <>
            <HomeLayout>
                <HomeHeader heading={HeadingComponent()} isHeaderCaption={location.pathname == '/Settings/ManageExternalAccounts/AddExternalAccount' ? true : false} />
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        cardWidth={width.homeCard}
                        header={false}
                        footer={false}
                        headerContent={headerContent()}
                        footerContent={() => { }}
                        CardRadius={true}
                        HeaderRadius={true}
                        isWhite={true}
                    >
                        <div class="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                 lg:text-[26px] md:text-[22px] 
                 sm:text-[15px] text-[20px]
                        leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">{t("external_accounts")}</div>

                        <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4 sm:pt-6 pt-0'>
                            <div className='sm:block hidden'>
                                {nextClicked ? (
                                    <p className="hidden sm:block md:lg:w-[615px] font-[400] font-serif-pro text-[#2965FB] lg:text-[26px] md:text-[22px] sm:text-[22px] text-[18px]">
                                        {t('next')}, <span className='text-[#262B3A]'>{t('please_enter_the_routing_account_number')}</span>
                                    </p>
                                ) : (
                                    <>
                                        <span className="font-[400] font-serif-pro text-[#2965FB] lg:text-[26px] md:text-[22px] sm:text-[22px] text-[18px]">
                                            {t("lets_get_started")}
                                        </span>
                                        <p className="lg:text-[26px] md:text-[22px] sm:text-[22px]
                                     text-[18px] font-serif-pro font-[400]">
                                            {t('what_type_of_account_would_you_like_to_add')}

                                        </p>
                                    </>

                                )}


                                {/* <span className="font-[400]
                             font-serif-pro text-[#2965FB] lg:text-[26px] md:text-[22px] sm:text-[22px] text-[18px]">
                                {nextClicked ? `${t('next')},` : t("lets_get_started")}
                            </span>
                            <span className="sm:block hidden lg:text-[26px] md:text-[22px] sm:text-[22px] text-[18px] font-serif-pro font-[400]">
                                {nextClicked ? t('please_enter_the_routing_account_number') : t("transfer_fund_subheading2")}
                            </span> */}
                            </div>
                        </div>

                        <div className={`sm:w-full flex justify-center my-10 sm:mx-0 pt-4 ${nextClicked ? 'pb-6' : 'pb-24'}`}>
                            {/* <div className='sm:w-full md:h-[150px] sm:h-[80px] h-[100px] flex justify-center my-10 sm:mx-0 mx-4'> */}
                            <div className='sm:w-[79%] w-full sm:px-0'>
                                <CustomDropDown
                                    disabled={nextClicked ? 'disabled' : 'not_disabled'}
                                    options={accountTypes}
                                    title={t('external_account_type')}
                                    onSelect={(option) => {
                                        if (option?.Type) {
                                            setSelectedAccountType({ "Type": option.Type, "Name": option.Name })
                                        }
                                        //debugger;
                                        // setSelectedAccountType({ "Type": option.Type, "Name": option.Name })

                                    }}
                                    selectedAccountType={selectedAccountType} />
                            </div>
                        </div>

                        <div className="flex flex-col w-full h-full">

                            {(selectedAccountType.Type != "" && !nextClicked) &&
                                <div className='flex flex-row sm:justify-end justify-between sm:w-full mt-5 mb-5 sm:mx-0 mx-4'>
                                    {/* <p onClick={() => { }} className='font-hanken-grotesk text-[#2965FB] font-semibold'>{t("back")}</p>
<p onClick={() => { nextClicked(true) }} className='font-hanken-grotesk text-[#2965FB] font-semibold ml-10'>{t("next")}</p> */}
                                    <button disabled={!nextClicked} className='font-hanken-grotesk text-[#2965FB] 
                                    font-semibold sm:ml-10 cursor-pointer lg:text-[18px] md:text-[17px] 
                                    sm:text-[15px] text-[16px]' onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; handleGoBack(); setTimeout(() => { event.target.style.opacity = 1; }, 200); } }} onClick={(event) => { event.target.style.opacity = 0.8; if (nextClicked) { handleGoBack() }; setTimeout(() => { event.target.style.opacity = 1; }, 200); }} style={{ opacity: nextClicked != '' ? 1 : 0.5 }}>{t("back")}</button>
                                    <button className='font-hanken-grotesk text-[#2965FB] font-semibold 
                                    sm:ml-10 cursor-pointer lg:text-[18px] md:text-[17px] sm:text-[15px]
                                     text-[16px]' onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; setNextClicked(true); setTimeout(() => { event.target.style.opacity = 1; }, 200); } }} onClick={(event) => { event.target.style.opacity = 0.8; setNextClicked(true); setTimeout(() => { event.target.style.opacity = 1; }, 200); }} >{t("next")}</button>
                                </div>
                            }
                            {nextClicked &&
                                <div className='flex flex-col justify-center mt-5 sm:mx-0 mx-4 '>

                                    <div className='flex flex-col sm:w-4/5 w-full self-center justify-center mb-10'>
                                        <div className='sm:w-2/4 w-full flex'>
                                            <CustomTextInput autoFocus={true} maxLength={40} onKeyDown={(event) => {
                                                if (event.key == "Enter") {
                                                    event.preventDefault(); event.target.style.opacity = 0.8;
                                                    onChangeHandle(t('first_name'),
                                                        event); setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                }
                                            }}
                                                name={t("first_name")} onChange={(event) => { onChangeHandle(t('first_name'), event) }}
                                                value={firstName}
                                                // error={firstNameError}
                                                onBlur={() => {
                                                    if (firstName === '') setFirstNameError("First Name is required")
                                                    else clearAllError()
                                                }}
                                            />
                                        </div>

                                        {firstNameError &&
                                            <div className='mb-2 w-4/5 '>
                                                {displayErrorMessage(firstNameError)}
                                            </div>
                                        }

                                    </div>
                                    <div className='flex flex-col sm:w-4/5 w-full self-center justify-center mb-10'>
                                        <div className='sm:w-2/4 w-full flex'>
                                            <CustomTextInput maxLength={40} onKeyDown={(event) => {
                                                if (event.key == "Enter") {
                                                    event.preventDefault(); event.target.style.opacity = 0.8;
                                                    onChangeHandle(t('last_name'),
                                                        event); setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                }
                                            }}

                                                name={t("last_name")} onChange={(event) => { onChangeHandle(t('last_name'), event) }}
                                                value={lastName}
                                                // error={lastNameError}
                                                onBlur={() => {
                                                    if (lastName === '') setLastNameError("Last Name is required")
                                                    else clearAllError()
                                                }}
                                            />
                                        </div>
                                        {lastNameError &&
                                            <div className='mb-2 w-4/5 '>
                                                {displayErrorMessage(lastNameError)}
                                            </div>
                                        }


                                    </div>

                                    <div className='flex flex-col sm:w-4/5 w-full self-center justify-center mb-10'>
                                        <div className='sm:w-2/4 w-full flex'>
                                            <CustomTextInput maxLength={9} onKeyDown={(event) => {
                                                if (event.key == "Enter") {
                                                    event.preventDefault(); event.target.style.opacity = 0.8;
                                                    onChangeHandle(t('routing_number'),
                                                        event); setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                }
                                            }}
                                                showValidity={routingNumberDetails.routingNumberTouched}
                                                valid={routingNumberDetails.routingNumberValid}
                                                name={t("routing_number")} onChange={(event) => { onChangeHandle(t('routing_number'), event) }}
                                                onBlur={() => {
                                                    if (routingNumberDetails.routingNumber === '') setRoutingNumberError("Routing Number is required")
                                                    else if (routingNumberDetails.routingNumber === accountNumberDetails.accountNumber) {
                                                        setAccountNumberError(t('acc_num_error'))
                                                    } else {
                                                        clearAllError()
                                                    }
                                                }}
                                                // error={routingNumberError}
                                                value={routingNumberDetails.routingNumber} />
                                        </div>
                                        {/* {!routingNumberDetails.routingNumberValid &&
                                            <p className='md:w-full sm:w-[200px] w-full font-hanken-grotesk font-[500]
                                         md:text-[16px] sm:text-[12px] text-[14px]
                                         cursor-pointer pointer-events-none'>
                                                {t("must_be_of_numerical_value")}
                                            </p>
                                        } */}
                                        <p className='md:w-full sm:w-[200px] w-full font-hanken-grotesk font-[500]
                                         md:text-[16px] sm:text-[12px] text-[14px]
                                         cursor-pointer pointer-events-none'>
                                            {t("must_be_of_numerical_value")}
                                        </p>

                                        {routingNumberError &&
                                            <div className='mb-2 w-4/5 '>
                                                {displayErrorMessage(routingNumberError)}
                                            </div>}

                                    </div>
                                    <div className='flex flex-col sm:w-4/5 w-full self-center 
                                    justify-center mb-10'>
                                        <div className='sm:w-2/4 w-full flex mt-5'>
                                            <CustomTextInput maxLength={17}
                                                onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; onChangeHandle(t('account_number'), event); setTimeout(() => { event.target.style.opacity = 1; }, 200); } }}
                                                showValidity={accountNumberDetails.accountNumberTouched}
                                                valid={accountNumberDetails.accountNumberValid}
                                                name={t("account_number")}
                                                onChange={(event) => { onChangeHandle(t('account_number'), event) }}
                                                onBlur={() => {
                                                    if (accountNumberDetails.accountNumber === '') setAccountNumberError("Account Number is required")
                                                    else if (routingNumberDetails.routingNumber === accountNumberDetails.accountNumber) {
                                                        setAccountNumberError(t('acc_num_error'))
                                                    } else {
                                                        setAccountNumberError('')
                                                    }
                                                }}
                                                // error={accountNumberError}
                                                value={accountNumberDetails.accountNumber} />
                                        </div>
                                        {/* {!accountNumberDetails.accountNumberValid &&
                                            <p className='md:w-full sm:w-[200px] w-full font-hanken-grotesk font-[500]
                                         md:text-[16px] sm:text-[12px] text-[14px]
                                         cursor-pointer pointer-events-none'>
                                                {t("must_be_of_numerical_value_1")}
                                            </p>
                                        } */}

                                        <p className='md:w-full sm:w-[200px] w-full font-hanken-grotesk font-[500]
                                         md:text-[16px] sm:text-[12px] text-[14px]
                                         cursor-pointer pointer-events-none'>
                                            {t("must_be_of_numerical_value_1")}
                                        </p>
                                        {accountNumberError &&
                                            <div className='mb-2 w-4/5'>
                                                {displayErrorMessage(accountNumberError)}
                                            </div>}

                                        {/* <p className='md:w-full sm:w-[200px] w-full font-hanken-grotesk font-[500]
                                         md:text-[16px] sm:text-[12px] text-[14px]
                                         cursor-pointer pointer-events-none'>
                                            {t("must_be_of_numerical_value").replace('9','22')}
                                        </p> */}
                                        {/* <p className='md:w-full sm:w-[200px]  w-full font-hanken-grotesk font-[500] 
                                        md:text-[16px] sm:text-[12px]  text-[14px]
                                         cursor-pointer pointer-events-none mb-5'>{t("must_be_of_numerical_value").replace('and no more than 9',' of 22')}</p> */}
                                    </div>
                                    <div className='flex flex-col sm:w-4/5 w-full self-center justify-center'>
                                        <div className='sm:w-2/4 w-full flex mt-5'>
                                            <CustomTextInput maxLength={17} onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; onChangeHandle(t('confirm_account_number'), event); setTimeout(() => { event.target.style.opacity = 1; }, 200); handleAddAccount(); } }}
                                                readOnly={!accountNumberDetails.accountNumberTouched} showValidity={confirmAccountNumberDetails.confirmAccountNumberTouched}
                                                valid={confirmAccountNumberDetails.confirmAccountNumberValid} name={t("confirm_account_number")}
                                                onChange={(event) => { onChangeHandle(t('confirm_account_number'), event) }}
                                                value={confirmAccountNumberDetails.confirmAccountNumber}
                                                onBlur={(e) => {
                                                    if (e.target.value === '') setinfoMessage('Please enter valid details.')
                                                    else if (accountNumberDetails.accountNumber !== e.target.value) {
                                                        setinfoMessage(t('the_account_numbers_you_provided_do_not_match_please_try_again'))

                                                    }
                                                    else setinfoMessage('')
                                                }}
                                            />
                                        </div>
                                    </div>


                                    {infoMessage &&
                                        <div className='mb-2 w-4/5 self-center justify-center'>
                                            {displayErrorMessage(infoMessage)}
                                        </div>
                                    }
                                    <div className='flex flex-row sm:justify-end justify-between w-full md:mt-20 sm:mt-[2.5rem] mt-20 mb-5'>
                                        <button className='font-hanken-grotesk text-[#2965FB] font-semibold ml-10 cursor-pointer lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]' onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); handleGoBack(); } }} onClick={(event) => { handleGoBack(); }} style={{}}>{t("back")}</button>
                                        <button
                                            className='font-hanken-grotesk font-semibold ml-10 cursor-pointer lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]'
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    const focused = document.activeElement;
                                                    if (lastName && firstName && confirmAccountNumberDetails.confirmAccountNumberValid && routingNumberDetails.routingNumberValid) {
                                                        handleAddAccount();
                                                    }
                                                    if (focused && typeof focused.blur === 'function') {
                                                        focused.blur();
                                                    }
                                                }
                                            }}
                                            onClick={(event) => {
                                                if (lastName && firstName && confirmAccountNumberDetails.confirmAccountNumberValid && routingNumberDetails.routingNumberValid) {
                                                    handleAddAccount();
                                                }
                                            }}
                                            style={{ color: (lastName && firstName && confirmAccountNumberDetails.confirmAccountNumberValid && routingNumberDetails.routingNumberValid) ? '#2965FB' : '#94C3E8' }}
                                        >
                                            {t("add_account")}
                                        </button>
                                    </div>
                                </div>
                            }
                            <div className="h-auto
                        border-t-[2.5px] border-[#D1E7DD] flex flex-col justify-center sm:mx-0 mx-4 mt-6">
                                <MoreServices actionsArray={moreServicesArray} isMoresetting={true} />
                            </div>
                        </div>
                    </DynamicCard>

                </div >

                {loading && <Loader />
                }
            </HomeLayout >
            {showModel &&
                <CustomAlertModel
                    Fullheight={true}
                    isOpen={showModel}
                    onClose={closeModel}
                    image={modelImage}
                    highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                        <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                    subHeaderMsg={<p className={`${SubHeaderMsgStyles} flex items-baseline`}>{subHeaderMsg}</p>}
                    btn1Exists={btn1Exists}
                    btn2Exists={btn2Exists}
                    btn1Text={btn1Text} btn2Text={btn2Text}
                    btn1TypeAcpt={btn1TypeAcpt}
                    verificationFailure={() => { }}
                    footerMsg={<p className={footerMsgstyles}>{footerMsg}</p>}
                    onAcptBtnClick={onAcptBtnClick}
                    onCancelBtnClick={onCancelBtnClick}
                    showErrorImg={showErrorImg}
                    isBorderNone={isBorderNone}
                    btn1BgColor={btn1BgColor}
                    btn1TextColor={btn1TextColor}

                />
            }
        </>
    )
}

export default withOidcSecure(AddExternalAccount);
