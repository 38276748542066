import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LuClock3 } from 'react-icons/lu';
import CustomInput from '../../CustomComponents/FormInputs';
import Check from '../../../assets/images/Check.png';
import Error from '../../../assets/images/Error.png';
import { useSelector, connect, useDispatch } from 'react-redux';
import { displayErrorMessage } from '../../../utils';
import { useKeyDown } from '../../../hooks/useKeyDown';
import Time from '../../../assets/images/Time.png';
import logFirebaseEvents from '../../LogFirebaseEvents';


const VerifyOtp = ({ handleTryAgain, handleloginVerifyOtp, loginOtpErrorMessage, selectedOtpType, onCancelClick }) => {

    const { t } = useTranslation();

    const schema = yup.object().shape({
        verifyCode: yup
            .string()
            .required(t('verification_code_required'))
            .matches(/^\d{6}$/, t('verification_code_invalid')),
    });
    const {
        register,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [validOTP, setValidOTP] = useState(false);
    const [isValid, setvalidCode] = useState(false);
    const [verifyCode, setCode] = useState(''); // State variable to store the input value
    const [errorMessage, setErrorMessage] = useState(loginOtpErrorMessage != undefined ? loginOtpErrorMessage : '');
    const deviceId = useSelector(state => state.deviceId);
    const inputRef = useRef(null);
    const submitButtonRef = useRef(null);
    useEffect(() => {
        //alert(selectedOtpType);
        // Focus the input on mount
        const codeInput = document.getElementById('verifyCode');
        codeInput.focus();
    }, []);
    useEffect(() => {
        if (verifyCode.length === 6 && submitButtonRef.current) {
            //     // Focus on the submit button when 6 digits are entered
            submitButtonRef.current.focus();
        }

    }, [verifyCode])
    const VerfiyCode = () => {
        handleloginVerifyOtp(verifyCode);
    }
    const handleVerifyCodeChange = async (event) => {
     
        const { name, value } = event.target;

        const sanitizedValue = value.replace(/\D/g, '');
        const truncatedValue = sanitizedValue.slice(0, 6);
        event.target.value = truncatedValue;
        if (value !== sanitizedValue) {
            setCode('')
            setErrorMessage(t('enter_numaric_only'));
        } else {
            setCode(value)
            setErrorMessage("");
        }
        if (event.target.value.length == 6) {
            setvalidCode(true);
        } else {
            setvalidCode(false);
        }
    };
    useKeyDown(() => {
        handleVerify()
    }, ["Enter"]);

    const handleVerify = async () => {
        if (verifyCode.length == 6) {
            handleloginVerifyOtp(verifyCode);
        } else {
            setErrorMessage(t('enter_verify_code'))
        }

    };
    const handleTryAgainClick = () => {
        handleTryAgain(verifyCode); // Call the handleTryAgain function passed as a prop
    };
    const handleSubmit = (event) => {

        if (verifyCode.length == 6) {
            event.preventDefault();
            handleloginVerifyOtp(verifyCode);
        } else {
            //   alert("hello");
            //  setErrorMessage(t('otp_required'))
        }
    };
    return (
        <>
            {/* <form className="flex flex-col" onSubmit={handleSubmit}> */}
            <div className=" flex flex-row sm:w-[322px] w-full items-center mt-14">
                <div className=" w-[45%]">
                    <CustomInput
                        name="verifyCode"
                        value={verifyCode}
                        label="6-digit code"
                        register={register}
                        error={errors.verifyCode}
                        type="6Code"
                        onChange={handleVerifyCodeChange}
                        isValid={isValid}
                        autoFocus={true}
                        ref={inputRef}
                    />
                </div>
                <div div className=" w-[45%]  mb-4">
                    <button
                        type={'submit'}
                        ref={submitButtonRef}
                        className="font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px] w-full bg-submit-btn hover:bg-submit-btn text-white py-2 px-4 font-medium lg:h-[60px] md:h-[50px] sm:h-[45px] h-[50px]"
                        onClick={handleVerify}
                        onKeyDown={(event) => {
                            if (event.key == "Enter") {
                                event.preventDefault();
                                handleVerify();
                                event.target.style.opacity = 0.8;
                                setTimeout(() => {
                                    event.target.style.opacity = 1;
                                }, 200);
                            }
                        }}
                    >
                        {t('verify')}
                    </button>
                </div>
                <div className="w-[10%] ml-4 mb-4">
                    {isValid === true ? (
                        <img src={Check} size={20} alt="Check" />
                    ) : (
                        verifyCode.length != 0 && <img src={Error} size={20} alt="Error" />
                    )}
                </div>
            </div>

            {errorMessage &&
                <div className='mb-2'>
                    {displayErrorMessage(errorMessage)}
                </div>
            }
            <div className=" flex flex-row gap-3 items-center md:mb-28 sm:mb-12 mb-28">
                <span className=" text-secondary">
                    <img src={Time} className="md:w-[20px] md:h-[20px] w-[14px] h-[14px]" />
                </span>
                <p className="font-hanken-grotesk lg:text-md md:text-[16px]
                 sm:text-[12px] text-[14px] leading-7 text-secondary-3 
                font-medium">
                    {t("security_check_notification")}
                </p>
            </div>

            <p className="font-hanken-grotesk md:text-[18px] sm:text-[12px] text-[16px] leading-7 text-secondary-3 font-medium">
                {selectedOtpType === 'Email' ? t("didnt_recieve_email") : t("security_check_subheading2")}{" "}
                <span
                    tabIndex={0}
                    className="font-hanken-grotesk font-bold md:text-[18px] sm:text-[12px] text-[16px] text-[#2965FB] cursor-pointer"
                    onClick={handleTryAgainClick}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            handleTryAgainClick();
                        }
                    }}
                >
                    {t("try_again")}
                </span>
                {/* <span>{" "}or{" "}</span>
                <span
                    tabIndex={0}
                    className="font-hanken-grotesk font-bold sm:text-[14px] md:text-md text-[#2965FB] cursor-pointer"
                    onClick={onCancelClick}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            onCancelClick();
                        }
                    }}
                >
                    {t("cancel")}

                </span> */}
                .
            </p>
        </>
    )
}

export default VerifyOtp