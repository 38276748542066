import React from 'react'
import { useTranslation } from 'react-i18next'
import rightArrow from '../../../assets/images/right_dropdown_arrow.png';
import moment from 'react-moment';

const BeneficiaryListItem = ({ nameObj, nextButtonClicked, listSize, currentIndex }) => {
    const { t } = useTranslation();
    const moment = require('moment');
    const showBorder = currentIndex < listSize - 1; // Adjust the condition based on your needs

    return (
        <>
            <p class="font-hanken-grotesk text-[#262B3A] 
                uppercase lg:text-[13px] md:text-[12px] 
                sm:text-[12px] text-[13px]  font-[600] 
                    tracking-[2px] opacity-[50%] lg:mt-20 mt-10">{t("beneficiary_name")}</p>
            <div className='md:h-[146px] h-[120px] items-center flex 
                                justify-between'>
                <div>
                    <p className='my-3 font-hanken-grotesk font-bold
                                         text-[16px] leading-4'>
                        {nameObj.FirstName + " " + nameObj.LastName}</p>
                    <p className='my-3 font-hanken-grotesk font-medium text-[12px] leading-4'>
                        {t("date_added")} <span>{moment(nameObj.AddedTime).format('MMMM, DD, YYYY')}</span> </p>
                </div>
                <div className='cursor-pointer'
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        event.preventDefault();
                        nextButtonClicked(nameObj)
                    }
                }}
                 onClick={() => { nextButtonClicked(nameObj) }}>
                    <img src={rightArrow} alt="view details" className='w-[9px]' />
                </div>
            </div>



        </>
    )
}

export default BeneficiaryListItem

// w-[90%] md:w-[533px]