import React, { useEffect, useState } from 'react';
import Logo from "../../../assets/images/footer_logo.png";
import App_Store from "../../../assets/images/App_Store.png";
import Play_Store from "../../../assets/images/Play_Store.png";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { isAndroid, isIOS, BrowserView } from 'react-device-detect';


import { MainFooter, Links, LogoContainer, LogoImage, StoreImagesContainer, CopyrightText, StoreImage, ListContainer, FooterList, FooterListItem, FooterParagraph } from './FooterStyles'
import { ANDROID_STORE_URL, APPLE_STORE_URL } from '../../../network/URLConstants';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import logFirebaseEvents from '../../LogFirebaseEvents';
const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  console.log("currentPath", currentPath)

  const [showModel, setShowModel] = useState(false);
  const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[14px] md:text-[13px] sm:text-[11px] text-[12px] font-normal cursor-pointer pointer-events-none";
  const [modelImage, setModelImage] = useState('')
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [toNavigate, setToNavigate] = useState('');
  const [isBorderNone, setisBorderNone] = useState(true);
  const [showErrorImg, setshowErrorImg] = useState(false);

  const FooterImages = [
    {
      Name: "App Store",
      ImageUrl: App_Store,
      RedirectUrl: APPLE_STORE_URL
    },
    {
      Name: "Play Store",
      ImageUrl: Play_Store,
      RedirectUrl: ANDROID_STORE_URL
    }
  ]

  const handleImageClick = (event, redirectUrl,name) => {
    debugger;
    if (redirectUrl) {
      event.preventDefault();
      if(name === "App Store"){
        logFirebaseEvents("Home", "AppStore_Clicked", "AppStore_Clicked","", "");
      }
      else if(name === "Play Store"){
        logFirebaseEvents("Home", "PlayStore_Clicked", "PlayStore_Clicked", "");
        }
      const rawHref = redirectUrl;
      console.log(rawHref,"selected link from footer");
      setToNavigate(rawHref);
      showAlertModal(true, t("heads_up"), t("about_to_leave_kudzu"), t("redirected_from_kudzu"), true, true, t("continue"), t("cancel"), true, '', false, false)
    }

  };
  const closeCustomAlertModal = () => {
    setShowModel(false);
  }

  const onAcptBtnClick = async () => {
    if (headerMsg == t('about_to_leave_kudzu')) {
      if (isIOS) {
        // Redirect to Apple App Store URL
        window.open(`${APPLE_STORE_URL}`, '_blank');
      } else if (isAndroid) {
        // Redirect to Google Play Store URL
        window.open(`${ANDROID_STORE_URL}`, '_blank');
      }
      
      window.open(toNavigate, '_blank');
      closeCustomAlertModal(); setToNavigate('')
    }
  }
  const onCancelBtnClick = () => {
    if (headerMsg == t('about_to_leave_kudzu')) { closeCustomAlertModal(); setToNavigate('') }
  }
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
    setshowErrorImg(showErrorImg)
    setisBorderNone(isBorderNone)
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
  }

  return (
    <>

      <div class="sm:px-[6%] sm:pt-40 pt-20 pb-20 sm:pb-16 flex px-[2.5rem]
      flex-wrap sm:flex-nowrap first-letter:px-4 
       transition-all duration-1000 ">
        <div className="md:w-[293px] sm:w-[133px] w-[232px] sm:pb-0 pb-10">
          <LogoImage src={Logo} alt="Kudzu" />
          <StoreImagesContainer>
            <div className='sm:mt-0 mt-[61px]'>
              {FooterImages.map(item => (
                <div key={item.Name} style={{ cursor: 'pointer' }}>
                  <StoreImage
                    src={item.ImageUrl}
                    alt={item.Name}
                    onClick={(event) => handleImageClick(event, item.RedirectUrl,item.Name)}
                  />
                </div>
              ))}

            </div>
          </StoreImagesContainer>
        </div>
        <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
        <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]'>
          <ListContainer>
            <FooterList fontWeight="bold">
              <FooterListItem>
                <Link to="/Help/AboutKudzu" onClick={()=>logFirebaseEvents("Home", "About_Kudzu_Clicked", "About_Kudzu_Clicked", "","")}>
                  {t('footer_about')}
                </Link>
              </FooterListItem>
              <FooterListItem>
                <Link to="/Help/FAQ" onClick={()=>logFirebaseEvents("Home", "Help_And_Faq_Clicked", "Help_And_Faq_Clicked", "","")}>
                  {t('footer_help_about')}
                </Link>
              </FooterListItem>
              <FooterListItem>
                <Link to="/Help/ContactUs" onClick={()=>logFirebaseEvents("Home", "ContactUs_Clicked", "ContactUs_Clicked","", "")}>
                  {t('footer_contact')}
                </Link>
              </FooterListItem>
              <FooterListItem>
                <Link to="/Documents/Policies" onClick={()=>logFirebaseEvents("Home", "Polices_Clicked", "Polices_Clicked", "","")}>
                  {t('footer_policies')}
                </Link>
              </FooterListItem>
            </FooterList>
            <FooterParagraph fontWeight="normal">
              <CopyrightText fontWeight="bold">
                {t('copyright')}
              </CopyrightText>
              {t('footer_par')}
              {
                (currentPath.toLowerCase() == "/climb/climbmenu") && (

                  <div className=' flex flex-col mt-10'>
                    <p>
                      {t('cbk_disclaimer1')}
                    </p>
                    <p>
                      {t('cbk_disclaimer2')}
                    </p>
                  </div>
                )
              }
            </FooterParagraph>
          </ListContainer>
        </div>
      </div>

      <CustomAlertModel isOpen={showModel}
        Fullheight={true}
        onClose={closeCustomAlertModal}
        image={modelImage}
        highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
        HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
        subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
        btn1Exists={btn1Exists}
        btn2Exists={btn2Exists}
        btn1Text={btn1Text} btn2Text={btn2Text}
        btn1TypeAcpt={btn1TypeAcpt}
        verificationFailure={() => { }}
        footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
        onAcptBtnClick={onAcptBtnClick}
        onCancelBtnClick={onCancelBtnClick}
        showErrorImg={showErrorImg}
        isBorderNone={isBorderNone}
      />

    </>
  );
};
export default Footer;