import React, { useEffect, useState } from 'react';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import klogoGreen from '../../../assets/images/LogokLogoGreen.png';
import klogoWhite from '../../../assets/images/LogokLogoLite.png';
import fpo from '../../../assets/images/externalAccountsImg.png';
import { CHECKING, ChooseCard, SAVINGS, SPENDING, retrieveData } from '../../../utils';
import { Link } from 'react-router-dom';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { addLineBreaks } from '../../../utils';
import beneficiarysaving from '../../../assets/images/beneficiarysaving.png';
import { useNavigate } from 'react-router-dom';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import { CAN_CREATE_SAVINGS_ACC, STATUS_SUCCESS_CODE } from '../../../network/URLConstants';
import moment from 'moment';
import Loader from '../../CustomComponents/Loader';

const AccountServices = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const heading = t('choose_account_sidHeading');
  const cards = useSelector(state => JSON.parse(state.userCards));
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const accessTokenToApi = useSelector(state => state.accessToken);

  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [showModel, setShowModel] = useState(false);
  const dispatch = useDispatch();
  const [modelImage, setModelImage] = useState('')
  const [savingsTheme, setSavingsTheme] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubFooter, setIsSubFooter] = useState(false);
  // Create an array of sub-objects from the cards array
  const subObjectsArray = cards?.map(card => {
    return {
      NickName: card.NickName,
      AccountTypeName: card.AccountTypeName,
      CardKey: card.CardKey
    };
  });
  if (savingsCardAvailable == false) {
    const staticObject = {
      AccountTypeName: t('menu_savings'),
      CardKey: ''
    };
    // subObjectsArray?.push(staticObject);
    subObjectsArray?.splice(1, 0, staticObject);
  }

  // Add a static object with empty values for the last position
  const staticObject = {
    AccountTypeName: t('external_account'),
    CardKey: ''
  };
  subObjectsArray?.push(staticObject);


  const borderColors = {
    Checking: '#30BC89',
    Spending: '#30BC89',
    Savings: '#A3CEBB',
  };

  const textColors = {
    Checking: '#30BC89',
    Spending: '#30BC89',
    Savings: '#A3CEBB',
  };


  const canCreateSavingsAccount = async () => {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    setIsLoading(true)

    let finalUrl = CAN_CREATE_SAVINGS_ACC(cards[0].CardKey)
    var response = await GetRequest(finalUrl, headers)
    var responseObj = JSON.parse(response)
    setIsLoading(false)
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        const canCreateNew = responseObj.Response.canCreateNew
        const eligibleDate = moment(responseObj.Response.eligibleDate).format('MM/DD/YYYY')
        if (canCreateNew) {
          enrollSavingsAccount()
          
        } else {
            showAlertModal(true, t("oh_no"), t('unable_to_open'), `${t('u_will_be_eligible')} ${eligibleDate}. `, true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false, true)
        }
    } else {
        setTimeout(() => {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oh_no"), displayText, '', true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false)

            // setError(displayText)
        }, 500);
    }
}

const subComponent = () => {
  return (
      <div className=' flex justify-start items-start'>
          <p className="font-hanken-grotesk font-semibold sm:font-[500] sm:text-[14px] md:text-[20px] text-[16px] md:leading-[30px] leading-[24px]">{t("pl_contact_customer")}<span className="text-[#2965FB] cursor-pointer" onClick={
              () => window.open("https://www.kudzumoney.com/contact-us", '_blank')}>{t("support_email")}</span>{t("for_access")}</p>
      </div>
  )
}

  const enrollSavingsAccount = () => {
    const savingsNewPayload = { savingsNewClicked: true };
    dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
    showAlertModal(true, '', t('set_aside_money_for_the_things_that_matter'), t('add_savings_account_subTitle'), true, true, t('enroll'), t('may_be_later'), true, beneficiarysaving, true, true);
  }


  const closeCustomAlertModal = () => {

    setShowModel(false);
  }

  const onAcptBtnClick = async () => {
    setShowModel(false)
    if (btn1Text == t("enroll")) {
      navigate('/SetupSavingsAccount')
      dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('account_services_savings_enrollment') } });
    }else if (btn1Text == t('home')) {
      navigate('/home')
    }

  }
  const onCancelBtnClick = () => {
    setShowModel(false)
    showAlertModal(true, '', t('savings_no_problem_title'), t('via_account_services_title'), true, false, t('okay_got_it'), '', true, beneficiarysaving, true, true);
  }
  //                      showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, savingsTheme, isBorderNone, isSubFooter
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, savingsTheme, isBorderNone, isSubFooter) => {
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setSavingsTheme(savingsTheme)
    setisBorderNone(isBorderNone)
    setIsSubFooter(isSubFooter)

  }
  const isMobile = window.innerWidth < 768


  return (
    <HomeLayout>
      <HomeHeader heading={addLineBreaks(t("acc_services_heading"))} isHeaderCaption={true} />

      <div className='flex flex-col md:w-[860px] sm:w-[464px] 
      w-[100%] animate__animated animate__fadeInRight '>

        <div className={`flex flex-col w-full gap-1 
      bg-[#262B3A] sm:flex-row sm:items-center space-y-[.5rem] sm:space-y-[1px] md:space-y-[.5rem]
      sm:px-0 px-10 ${subObjectsArray?.length > 3 ? 'flex-wrap' : 'flex-nowrap'}`}>

          {/* Rendering ChooseCard components with responsive space between */}
          {subObjectsArray?.map((subObject, index) => (
            <React.Fragment key={index}>
              <ChooseCard
                index={index}
                // Pass the values as props to ChooseCard 
                checkingName={subObject.NickName ? subObject.NickName.slice(0, 13)  : subObject.AccountTypeName}
                LinkTo={subObject.AccountTypeName === t('external_accounts') ? `/AccountServices/ManageExternalAccounts/` : `/AccountServices/${subObject.AccountTypeName == SPENDING ? SPENDING : subObject.AccountTypeName}`} // Corrected LinkTo prop 
                value={subObject.CardKey}
                const Servicecard={{
                  border: borderColors[subObject.AccountTypeName] || '#fff',
                  color: textColors[subObject.AccountTypeName] || '#fff',
                }}
                imageSource={subObject.AccountTypeName === (SPENDING || CHECKING) ? klogoGreen : subObject.AccountTypeName === SAVINGS ? klogoWhite : fpo}
                enrollClicked={canCreateSavingsAccount}
              />
              {index < subObjectsArray?.length - 1 && (
                <div className="divider" />
              )} {/* Space */}
            </React.Fragment>
          ))}

        </div>
      </div>
      <CustomAlertModel isOpen={showModel}
        modalBg={!isSubFooter && 'bg-[#E4F1EB]'}
        btn1BorderColor={!isSubFooter && '#30BC89'}
        btn1TextColor={!isSubFooter && '#FEFAEE'}
        btn1BrExist={!isSubFooter && true}
        btn2TextColor={!isSubFooter && '#559C7E'}
        btn1BgColor={!isSubFooter && '#30BC89'}
        onClose={closeCustomAlertModal}
        image={modelImage}
        highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
          <span className={`${HeaderMsgStyles} ${savingsTheme ? 'text-[#0C4437]' : 'text-black'}`}> {headerMsg}</span></p>}
        subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
        btn1Exists={btn1Exists}
        btn2Exists={btn2Exists}
        btn1Text={btn1Text} btn2Text={btn2Text}
        btn1TypeAcpt={btn1TypeAcpt}
        verificationFailure={() => { }}
        //footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
        onAcptBtnClick={onAcptBtnClick}
        onCancelBtnClick={onCancelBtnClick}
        isBorderNone={isBorderNone}
        subFooter={isSubFooter && subComponent()}
      />
      {isLoading && <Loader />}
    </HomeLayout>
  )
}

export default AccountServices;