import React, { useEffect, useRef, useState } from 'react';
import Card from '../../../../CustomComponents/Card';
//  import { width, height } from "../../../../CustomComponents/Card/CardStyles";
import { Tabs, Tab } from '../../../../CustomComponents/TabsComponent';
import TransactionItemWidget from '../../../../CustomComponents/TransactionItemWidget';
import DepositCashImage from '../../../../../assets/images/depositCash.png';
import { useTranslation } from "react-i18next";
import right_dropdown_arrow from '../../../../../assets/images/right_dropdown_arrow.png';
import FooterContent from '../../../../CustomComponents/Footer';
import downloadArrow from '../../../../../assets/images/download_arrow.png';
import { GET_DDA_DETAILS, GET_RELOAD_DETAILS, STATUS_SUCCESS_CODE } from '../../../../../network/URLConstants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { retrieveData } from '../../../../../helpers/utils/Utils';
import { GetApiHeadersWithBearer, GetRequest } from '../../../../../network/NetworkUtils';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../../../Layout/HomeLayout';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import DynamicCard from '../../../../CustomComponents/DynamicCard';
import { width, height } from '../../../../CustomComponents/DynamicCard/DynamicCardStyles';
import AccountDetailView from '../../../../CustomComponents/AccountDetailView';
import RightArrow from '../../../../../assets/images/right_dropdown_arrow.png'
import { useNavigate } from 'react-router-dom';
import { AccountDetailsHeder, copyToClipboard, QuickActions, MoreServices } from '../../../../../utils';
import CustomAlertModel from '../../../../CustomComponents/CustomAlertModel';
import logFirebaseEvents from '../../../../LogFirebaseEvents';
const DepositCash = () => {
    const moreService = 'w-[90%] pb-[1.5rem] flex justify-between items-center border-b-[3px] border-[#D1E7DD]';
    const moreserviceText = 'font-[600] text-[#262B3A] md:text-[16px] sm:text-[11px] leading-[16px] font-hanken-grotesk';
    const { t } = useTranslation();
    const navigate = useNavigate();
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);

    const [accountNumber, setAccountNumber] = useState(''); //Account Number
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountCopied, setAccountCopied] = useState(false);
    const [routingCopied, setRoutingCopied] = useState(false);
    const [imageLink, setImageLink] = useState('');
    const [infoMessage, setinfoMessage] = useState('');
    const [infoMessage2, setinfoMessage2] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [cardData, setCardData] = useState([]);
    const [loading, setLoading] = useState(false)
    const selectedItem = cards?.[0]

    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[14px] md:text-[13px] sm:text-[11px] text-[12px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [type, setType] = useState('')
    const [footerMsg, setFooterMsg] = useState('');

    const moreServicesArray = [
        { name: 'Manage Direct Deposit', actionType: '', path: "/AccountServices/Spending/ManageDirectDeposit" },

    ];

    useEffect(() => {
        getDdaDetails();
        getLinkandImageData();
    }, []);


    const getDdaDetails = async () => {
        if (selectedItem == undefined || selectedItem == null) {
            return
        }
        setLoading(true)
        let url = GET_DDA_DETAILS + selectedItem?.CardKey;
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var response = await GetRequest(url, headers)
        setTimeout(() => {
            setLoading(false)
        }, 500);
        var responseObj = JSON.parse(response);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            if (responseObj.Response != undefined) {
                setAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
                setRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
            }
        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;

        }
    }

    const getLinkandImageData = async () => {
        if (selectedItem?.CardKey == undefined) {
            return
        }
        const url = GET_RELOAD_DETAILS + "?cardKey=" + selectedItem?.CardKey;
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setLoading(false)
        var response = await GetRequest(url, headers);
        var responseObj = JSON.parse(response);

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setTimeout(() => {
                setImageLink(responseObj.Response.Items[0].ImageUrl);
                setinfoMessage(responseObj.Response.text_Part1);
                setinfoMessage2(responseObj.Response.text_Part2);
                setRedirectUrl(responseObj.Response.Items[0].RedirectUrl);
                setCardData(responseObj.Response.Items);
                setLoading(false);
            }, 1000);

        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;

        }
    }
    const depositCashClicked = () => {
        navigate('/AccountServices/Spending/AddFunds/ManageDirectDeposit');
    }
    const findLocationClicked = () => {
        logFirebaseEvents("Account_services", "Find_location_cliked", "Find_location_cliked","", "");
        showAlertModal(true, t("heads_up"), t("about_to_leave_kudzu"), t("redirected_from_kudzu"), true, true, t("continue"), t("cancel"), true, '')
    }

    const commonHeadingStyle = {
        fontSize: '20px',
        color: 'blue',
    };

    const leftHeadingStyle = {
        color: '#559C7E',
        marginTop: '10px',
        fontSize: '16px',
        lineHeight: '1.5',
        fontWeight: 'bold',
    };

    const leftContentStyle = {
        color: 'black',
        marginTop: '10px',
        fontSize: '16px',
        lineHeight: '1.8',
        fontWeight: 'normal',
    };

    const rightHeadingStyle = {
        color: '#559C7E',
        margin: '10px 0px 0px 150px',
        fontSize: '16px',
        lineHeight: '1.5',
        fontWeight: 'bold',
    };

    const rightContentStyle = {
        color: 'black',
        margin: '10px 0px 0px 150px',
        fontSize: '16px',
        lineHeight: '1.8',
        fontWeight: 'normal',
    };

    const exploreClimb = () => {
        // navigate('/Climb');
          navigate('/AccountServices/Spending/AddFunds/ManageDirectDeposit')
    }
    const footerContent = () => {
        return (
            < FooterContent highlightText={t("set_up_dd_text")}
                subHeaderText={t("paper_work")} image={downloadArrow}
                buttonEnabled={true} isSubheadingwidth={true} buttonText={t("set_up_dd_btn")}
                footerBtnAction={exploreClimb} />
        )

    }
    const headerContent = () => {
        const dynamicColor = "#262B3A";
        return (

            <div className="flex flex-row justify-between mx-0 items-center mt-12">
                <p className="font-hanken-grotesk font-medium text-${dynamicColor}`} ">
                    {t("add_cash")}
                </p>
            </div>
        )
    }
    const accountNumberCopiedClicked = () => {
        logFirebaseEvents("Account_services", "account_Number_copied", "account_Number_copied","", "");
        copyToClipboard(accountNumber, setAccountCopied(true))
        setAccountCopied(true);
        setTimeout(() => {
            setAccountCopied(false);
        }, 2000);

    }
    const routingNumberCopiedClicked = () => {
        logFirebaseEvents("Account_services", "routing_Number_copied", "routing_Number_copied","", "");
        copyToClipboard(routingNumber, setRoutingCopied(true))
        setRoutingCopied(true);
        setTimeout(() => {
            setRoutingCopied(false);
        }, 2000);
    }
    const onclickManageDirectDeposit = () => {
        navigate('/AccountServices/Spending/ManageDirectDeposit')
    }
    const onclickDepositCheck = () => {
        alert("we will update soon")
    }

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }

    const closeCustomAlertModal = () => {
        setShowModel(false);
    }
    const onAcptBtnClick = async () => {
        setShowModel(false);
        if (redirectUrl) {
            window.open(redirectUrl, "_blank");
        }

    }
    const onCancelBtnClick = () => {
        setShowModel(false);

    }
    return (
        <>

            <HomeLayout>
                <HomeHeader heading="" isHeaderCaption={false} selectedIndex={1} />
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight  '>
                    <DynamicCard
                        header={true}
                        HeaderContent={false}
                        footer={true}
                        CardRadius={true}
                        HeaderRadius={false}
                        isWhite={true}
                        headerContent={headerContent()}
                        cardWidth={width.homeCard}
                        cardHeight={height.depositMoney}
                    >

                        <div>
                            <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                     lg:text-[26px] md:text-[22px] 
                     sm:text-[20px] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">
                                {/* {selectedItem.AccountTypeName} */}
                                {t("add_cash")}
                            </div>


                            <AccountDetailsHeder
                                handleCopyClick={accountNumberCopiedClicked}
                                handleCopyRoutingNumberClick={routingNumberCopiedClicked}
                                accountCopiedProps={accountCopied}
                                routingCopiedProps={routingCopied}
                                AccountNo={accountNumber}
                                routingNo={routingNumber}
                                contentBetween={true}
                                topBorder={true}
                                bottomBorder={true}
                                isCopyblack={true}
                            />

                            {/* <QuickActions /> */}

                        </div>
                        {/* <AccountDetailView heading={headerContent()} lineHeight="3" hrThickness={3} hrColor="#559C7E" headingPadding="0px" headingFontWeight="bold" headingFontSize="16px" subHeading="ACCOUNT INFO"
                        subHeadingFloat="none" subHeadingColor="#262B3A" subHeadingMargin="20px 0px 20px 0px"
                        subHeadingPadding="0px"
                        subHeadingFontSize="13px"
                        subheadingFontWeight="normal"
                        leftHeading={t("account_number")}
                        leftContent={accountNumber}
                        rightHeading={t("routing_number")}
                        rightContent={routingNumber}
                        leftHeadingStyle={leftHeadingStyle}
                        leftContentStyle={leftContentStyle}
                        rightHeadingStyle={rightHeadingStyle}
                        rightContentStyle={rightContentStyle}
                        hr2Thickness={2} // Set thickness for hr2
                        hr2Color="#559C7E" // Set color for hr2
                        hr2Margin="30px 0" // Set margin for hr2
                    /> */}

                        <div>

                            <p className='font-hanken-grotesk text-[#262B3A] 
      uppercase opacity-[50%]  lg:text-[13px] md:text-[12px] 
      sm:text-[11px] text-[13px] 
      font-[600] tracking-[2px] md:pb-8 py-8 sm:mx-0 mx-4'>visa readylink</p>

                            {imageLink && <div class="flex justify-center items-center ">
                                <img src={imageLink} alt="AddCash"
                                    class="lg:w-[198px] lg:h-[118px] md:w-[168px] 
                                    md:h-[98px] sm:w-[120px] sm:h-[72px] w-[120px] h-[70px]" />
                            </div>}

                            {infoMessage && <div className='w-full flex justify-center items-center'>
                                <div className='md:w-[533px] w-[295px] py-10'>
                                    {/* <p className='font-[700] text-[18px] 
                                leading-[28px] font-hanken-grotesk mb-[3rem]'>Need to deposit cash? Head to one of over
                                    40,000 participating Visa ReadyLink merchants
                                    and ATM locations.</p> */}

                                    <p className='font-[500] mb-[2rem] lg:text-[20px] md:text-[20px] 
                                sm:text-[13px] md:leading-[30px] sm:leading-[19px] text-[16px] leading-[24px] font-hanken-grotesk'>
                                        {infoMessage}
                                    </p>
                                    <div className=' flex sm:flex-col flex-col-reverse gap-12'>
                                        <div className='font-[400] sm:font-[500]  lg:text-[18px] md:text-[18px] 
                                sm:text-[10px] md:leading-[28px] sm:leading-[16px] text-[12px] leading-[18px] font-hanken-grotesk' dangerouslySetInnerHTML={{ __html: infoMessage2 }}>
                                        </div>
                                        <div
                                            tabIndex={0}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    findLocationClicked();
                                                }
                                            }}
                                            onClick={findLocationClicked} >
                                            <div className="md:text-[16px] sm:text-[12px] font-[600] sm:font-[700] text-[16px]
                            h-[60px] text-[#FFFFFF] sm:text-[#2965FB] sm:bg-[#FFFFFF] w-full flex border-[2.5px] border-[#2965FB] bg-[#2965FB]
                             justify-center items-center cursor-pointer">
                                                {t("find_location")}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>}

                            {/* <div onClick={findLocationClicked} 
                        className='flex border-2 border-[#2965FB] 
                        justify-center items-center w-[533px] '>
                            <p className="text-8 justify-center font-medium
                             py-2 px-5 text-[#2965FB]">
                                {t("find_location")}
                            </p>
                        </div> */}

                            {/* <div class="flex justify-center text-justify items-center ">
                            <p class=" w-[533px] h-[188px] mt-[50px]">{infoMessage}</p>
                        </div>

                        <div onClick={findLocationClicked} className='flex mt-10 border-2 border-[#2965FB] justify-center items-center w-1/2 mx-auto'>
                            <p className="text-8 justify-center font-medium py-2 px-5 text-[#2965FB]">
                                {t("find_location")}
                            </p>
                        </div> */}

                            {/* <div className='mt-10'>
                            <div className="bg-[#A3CEBB] h-0.5 min-h-1 mt-4"></div>
                        </div> */}
                            {/* <div className='pb-[5rem] pt-[3rem]'>
                            <p className='font-hanken-grotesk text-actions-text uppercase opacity-50'>{t("more_services").toLocaleUpperCase()}</p>
                            <div className='flex justify-center'>
                                <div className='flex flex-row justify-between self-center
                                 items-center mt-10 w-full'>
                                    <div className='w-1/3'>
                                        <div className='flex flex-row justify-between items-center'>
                                            <p className="font-medium text-16">{t("deposit_check")}</p>
                                            <img
                                                src={right_dropdown_arrow}
                                                // alt="Image"
                                                className="w-1.5 h-3 mr-2"
                                            />
                                        </div>

                                        <div
                                            className="bg-[#A3CEBB] h-0.5 mt-4 mx-auto"
                                        ></div>
                                    </div>
                                    <div className='w-1/3' onClick={onclickManageDirectDeposit}>
                                        <div className='flex justify-between flex-row items-center'>
                                            <p className="font-medium text-16">{t("manage_direct_deposit")}</p>
                                            <img
                                                src={right_dropdown_arrow}
                                                // alt="Image"
                                                className="w-1.5 h-3 mr-2"
                                            />
                                        </div>
                                        <div
                                            className="bg-[#A3CEBB] h-0.5 mt-4 mx-auto"
                                        ></div>
                                    </div>
                                </div>
                            </div>

                        </div> */}

                            {/* <div className="h-[244px] flex flex-col justify-center border-t-[3px] border-[#D1E7DD]">
                            <p className='font-hanken-grotesk text-[#262B3A] uppercase uppercasevopacity-[50%] md:text-[13px] sm:text-[9px] font-[600] tracking-[2px ] pb-8'>
                                More services</p>
                            <div className='flex w-full'>
                                <div className='w-[50%]'>
                                    <div className={moreService}>
                                        <p className={moreserviceText}>{t("deposit_check")}</p>
                                        <p><img src={RightArrow} className="w-[7px] mr-[10px]" /></p>
                                    </div>
                                </div>
                                <div className='w-[50%] flex justify-between items-center' onClick={onclickManageDirectDeposit}>
                                    <div className={moreService}>
                                        <p className={moreserviceText}>{t("manage_direct_deposit")}</p>
                                        <p><img src={RightArrow} className="w-[7px] mr-[10px]" /></p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                            {/* /////more service */}
                            <div className="h-auto border-t-[2.5px] border-[#D1E7DD] flex flex-col justify-center sm:mx-0 mx-4">
                                <MoreServices actionsArray={moreServicesArray} />
                            </div>
                        </div>

                    </DynamicCard>
                    {footerContent()}
                </div>

            </HomeLayout>
            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
            />
        </>

    )
}
export default withOidcSecure(DepositCash);


