import React, { useEffect, useRef } from 'react'

const TinkLinkModal = ({ isOpen, onClose, content, zIndex }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.classList.contains('modal')) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen, onClose]);


    useEffect(() => {
        if (isOpen && modalRef.current) {
            modalRef.current.classList.add('animate__animated', 'animate__fadeInUpBig');
        } else if (modalRef.current) {
            modalRef.current.classList.remove('fade-in');
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }



    const handleClose = () => {
        modalRef.current.classList.remove('animate__fadeInUpBig');
        modalRef.current.classList.add('animate__fadeOutDown');
        setTimeout(() => {
            onClose();
        }, 500); // Adjust the timeout duration as needed
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center overflow-y-auto " style={{ zIndex: zIndex ? zIndex : 100 }}>

            <div className=' absolute top-0 left-0 flex w-full items-center justify-center'>
                <div className="modal-overlay fixed inset-0 bg-[#262B3A] opacity-90 transition-opacity" />

                <div
                    ref={modalRef} style={{ overflowY: 'auto' }}
                    className={`modal-container bg-[#FFFFFF] absolute sm:top-32 top-0  rounded-sm  md:w-[400px] sm:w-[400px] w-[100%] mx-auto shadow-lg z-50 transition-all duration-300 ease-in-out h-[100vh] sm:h-fit`
                    }
                >
                    {content}
                </div>
            </div>
        </div >
    );
};

export default TinkLinkModal;