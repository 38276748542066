import React, { useEffect, useRef, useState } from 'react';
import ModalLogo from '../../../assets/images/modal_logo.png';
import 'animate.css';

const Modal = (props) => {
  const { isOpen, onClose, title, content, subtitle, isLogo, isClimbg, isIframe, Fullheight, defaultCornerRadius = true, isFooter = false, button1, button2} = props
  const modalRef = useRef(null);
  const [isclosed, setIsclosed] = useState(0);
  const [loaded, setLoaded] = useState(0);

  const isiPhoneOriPad = () => /(iphone|ipad)/i.test(navigator.userAgent);


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains('modal')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      // modalRef.current.classList.add('animate__animated', 'animate__fadeInUpBig');
      setIsclosed(2)
      setTimeout(() => {
        setLoaded(1)
      }, 500);
    } else if (modalRef.current) {
      setIsclosed(3)
      modalRef.current.classList.remove('fade-in');
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleClose = () => {
    // modalRef.current.classList.remove('animate__fadeInUpBig');
    // modalRef.current.classList.add('animate__fadeOutDown');
    setIsclosed(1)
    setTimeout(() => {
      onClose();
    }, 500); // Adjust the timeout duration as needed
  };

  // const modalContainerClasses = `modal-container ${props?.bgColor ? props?.bgColor : isClimbg ? 'bg-climb-modal-bg opacity-50' :  'bg-card'}`;
  const modalContainerClasses = ` modal-container ${props?.bgColor ? props?.bgColor : isClimbg ? ' bg-climb-modal-bg ' : 'bg-card'} ${defaultCornerRadius ? ' sm:rounded-tl-card-tr sm:rounded-br-card-bl ' : ' sm:rounded-tr-[4rem] sm:rounded-bl-[4rem]'} md:w-[778px] sm:w-[466px] w-[100%] mx-auto shadow-lg z-50 h-[100vh] sm:h-fit sm:mb-10  ${loaded ? ' overflow-y-auto ' : ''} `;


  return (
    // <div className=" w-full h-full flex items-center justify-center overflow-y-auto " style={{ zIndex: 200 }}>
    //   <div className=' absolute top-0 left-0 flex w-full items-center justify-center'>
    //     <div className="modal-overlay fixed inset-0 bg-[#262B3A] opacity-90 transition-opacity" />
    //     {/* <div className='w-full h-[100vh]'> */}
    //     <div
    //       ref={modalRef} style={{ overflowY: 'auto' }}
    //       className={(isclosed == 2 && isOpen && modalRef.current) ? ' animate__animated animate__fadeInUpBig ' + modalContainerClasses : (isclosed == 1) ? ' animate__animated animate__fadeOutDown ' + modalContainerClasses : 'hidden'} >
    //       <div className="modal-content text-left">
    //         <div className={`modal-body  h-[100%] ${isIframe ? 'flex justify-center' : 'flex justify-center items-center '} sm:my-[60px] md:my-[110px] mt-[68px] mb-[20%] `}>
    //           <div className={` ${isIframe ? 'w-[100%]' : 'md:w-[430px] sm:w-[294px] xsm:w-[240px] w-[320px]'} `}>
    //             <span className='absolute md:top-[60px] sm:top-[44px] top-[30px] right-[30px] sm:right-[50px]'>
    //               <button className="modal-close text-gray-500 hover:text-gray-800 
    //             cursor-pointer"
    //                 onClick={handleClose}>
    //                 <svg xmlns="http://www.w3.org/2000/svg"
    //                   className="h-[1rem] w-[1rem] opacity-[60%]"
    //                   fill="none"
    //                   viewBox="0 0 24 24"
    //                   stroke="currentColor" >
    //                   <path strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
    //               </button></span>
    //             {(isLogo != undefined && isLogo) && <img src={ModalLogo}
    //               className="md:w-[130px] sm:w-[106px] w-[111px] sm:h-[32px] h-[33.43px] md:h-[39.15px] mb-[35px] md:mb-[51px]" alt="Modal Logo" />}
    //             <div className='md:w-[430px] sm:w-[294px] xsm:w-[240px] w-[320px]'>
    //               {title}
    //               {subtitle}
    //             </div>
    //             {props?.subHeaderMsg &&
    //               <div className='md:w-[430px] sm:w-[294px] xsm:w-[240px] w-[320px] mt-3'>
    //                 {props.subHeaderMsg}
    //               </div>
    //             }
    //             {content}
    //           </div>
    //         </div>
    //       {/* </div> */}
    //     </div>
    //   </div>
    //   </div>
    // </div>

    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#262B3A] bg-opacity-90"
      style={{ zIndex: 200 }}
    >
      {isOpen && (
        <div
          className={`absolute sm:pt-[130px] bottom-0 left-1/2 w-full transform -translate-x-1/2 rounded-md ${loaded ? ' overflow-y-auto ' : ''}`}
          // className={`absolute sm:pt-[130px] bottom-0 left-1/2 w-full transform -translate-x-1/2 rounded-md ${loaded ? ' overflow-y-auto ' : ''}`}
          style={{
            position: 'absolute',
            top: isclosed == 2 ? '0px' : '2000px',
            transition: 'top 1s ease-in-out'
          }}
        >
          <div
            ref={modalRef}
            className={modalContainerClasses} >
            {/* className={modalContainerClasses} style={{ overflowY: 'auto' }} > */}
            <div className="modal-content text-left" >
              <div className={`modal-body  h-[100%] ${isIframe ? 'flex justify-center' : 'flex justify-center items-center '} sm:my-[60px] md:mt-[110px] mt-[68px] mb-[20%] `}>
                <div className={` ${isIframe ? ' w-[85%] xsm:w-[100%]' : 'md:w-[430px] sm:w-[294px] xsm:w-[240px] w-[320px]'} `}>
                  <div style={{ position: 'relative' }}> {/* Add this line */}
                    <span className={`absolute md:top-[-50px] sm:top-[-30px]  ${isIframe ? 'right-[9%] xsm:right-[9%] sm:right-[5%] md:right-[5%] lg:right-[5%] xl:right-[5%] top-[-68px]' : 'right-[2%] xsm:right-[-18%] sm:right-[-18%] md:right-[-24%] lg:right-[-27%] xl:right-[-25%] top-[-30px]'}`}>
                      <button className="modal-close text-gray-500 hover:text-gray-800 
                cursor-pointer"
                        onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                          className="h-[1rem] w-[1rem] opacity-[60%]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor" >
                          <path strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                      </button></span>
                    {(isLogo != undefined && isLogo) && <img src={ModalLogo}
                      className="md:w-[130px] sm:w-[106px] w-[111px] sm:h-[32px] h-[33.43px] md:h-[39.15px] mb-[35px] md:mb-[51px]" alt="Modal Logo" />}
                    <div className='md:w-[430px] sm:w-[294px] xsm:w-[240px] w-[320px] '>
                      {title}
                      {subtitle}
                    </div>
                    {props?.subHeaderMsg &&
                      <div className='md:w-[430px] sm:w-[294px] xsm:w-[240px] w-[320px] mt-3 '>
                        {props.subHeaderMsg}
                      </div>
                    }
                    {content}

                  </div>
                </div>
              </div>
                  {isFooter &&
                    <div className='w-full  hidden sm:flex justify-center items-center p-6 bg-[#30BC89] '>
                      {button1}

                    </div>
                  }

            </div> 
          </div>
        </div>
      )}
      {isOpen && isFooter &&
        <div className='w-full fixed bottom-0 left-0 sm:hidden justify-center items-center p-6 bg-[#30BC89] animate__animated animate__fadeInUpBig'>
          {button1}
        </div>
      }
    </div>


  );
};

export default Modal;