import React, { useState, useEffect, useRef } from 'react'
// import Card from '../../../CustomComponents/Card';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { width } from "../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import Loader from '../../CustomComponents/Loader';
import CustomTextInput from '../../CustomComponents/CustomtextInput';
import {
    GENERATE_EXISTING_PHONE_EMAIL_OTP,
    UPDATE_PHONE_NUMBER,
    GENERATE_UPDATE_EMAIL_OTP,
    GENERATE_UPDATE_ADDRESS_OTP,
    GET_USER_PROFILE_V2,
    GET_USER_PROFILE_LANGUGAGE_LIST,
    GET_USER_PROFILE_TIMEZONE_LIST,
    GENERATE_OTP_CHANGE_PIN, STATUS_SUCCESS_CODE,
    OTP_USERS, POST_UPDATE_USER_PROFILE,
    GOOGLE_PLACE_DETAILS_WITH_PLACE_ID,
    GOOGLE_PLACES_AUTO_COMPLETE,
    VERIFY_UPDATE_ADDRESS_OTP,
    SAVINGS_VALIDATE_SSN,
    CREATE_SAVINGS_ACCOUNT,
    GET_CARDHOLDER_CARDS
} from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, PostRequest, GetRequest, GetApiHeadersWithOutBearer } from '../../../network/NetworkUtils';
import { displayErrorMessage, displaySuccessMessage, parseGoogleLocationResponse, isEmailValid, validateMobileNumber, formatDateYYYYMMDD, LineBreaksSpan } from '../../../utils';
import LineBreaks from '../../../utils';
import { retrieveData } from '../../../helpers/utils/Utils';
import { personalFormatPhoneNumber } from '../../../utils';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import CustomDropdown from '../../CustomComponents/CustomDropDown';
import Custom2FA from '../../CustomComponents/Custom2FA';
// import DynamicCard from '../../CustomComponents/DynamicCard';
import { LOGIN_SESSION_KEY } from '../../../Redux/reducers';
import Modal from '../../CustomComponents/Modal';
import { set, useForm } from 'react-hook-form';
import { fetchConfigarationData } from '../../../helpers/utils/Utils';
import { type } from '@testing-library/user-event/dist/type';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
// import address_update_Succss from '../../../assets/images/address_update_Succss.png';
import address_update_Succss from '../../../assets/images/address-updated.png';
import email_update_success from '../../../assets/images/email_update_success.png';
import updatedphone_success from '../../../assets/images/updatedphone_success.png';
import fb from '../../../assets/images/fb.png';
import { useKeyDown } from '../../../hooks/useKeyDown';
import { useLocation, useNavigate } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import TwoFactTypeSelctionModal from "../../CustomComponents/TwoFactTypeSelctionModal";
import ShowSSNPic from '../../../assets/images/showpassword.png'
import HideSSNPic from '../../../assets/images/hidepassword.png'
import { yupResolver } from '@hookform/resolvers/yup';
import Arrow from '../../../assets/images/arrow.png';

const appConfigData = await fetchConfigarationData();



const SavingsSSNSetup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook to access translation functions
    const cards = useSelector(state => JSON.parse(state.userCards));
    const [isLoading, setIsLoading] = useState(false);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const ssnScreenThrough = useSelector(state => state.ssnScreenThrough);
    const savingsTermsConditions = useSelector(state => state.savingsTermsConditions);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [showModel, setShowModel] = useState(false);
    const [modelImage, setModelImage] = useState('')
    const selectedItem = cards?.[0]
    const userId = useSelector(state => state.userId);
    const [loading, setLoading] = useState(false)
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none text-black";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[16px] text-[18px] font-normal cursor-pointer pointer-events-none";
    const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue lg:text-[16px] md:text-[15px] text-[14px] placeholder:font-semibold font-medium focus:outline-none w-full h-[60px]";
    const SubHeadingStyles = "w-[90%] md:w-full font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-3";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [isCursorVisible, setCursorVisibility] = useState(false);
    const [showSSN, setShowSSN] = useState(true);
    const ssnRef = React.createRef();
    const [SSNoError, setSSNoError] = useState('');
    const [ssnBackup, setSSnBackUp] = useState('');
    const [isSSNValid, setIsSSNValid] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [infoMessage, setinfoMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const checkbox1Ref = useRef(null);
    const button1Ref = useRef(null);
    const [SSNBackupSecondary, setSSNBackupSecondary] = useState('')


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                const elements = [checkbox1Ref, button1Ref, ssnRef];
                const isAnyElementFocused = elements.some(ref => ref.current === document.activeElement);
                if (!isAnyElementFocused) { handleConfirm() }
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };


    }, []);



    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        address: '',
        apt_etc: '',
        ssn_no: ''
    });
    const [ssn, setSSN] = useState('')





    useEffect(() => {
        handleSSNValue(ssnBackup)
    }, [showSSN])
    function formatValue(value) {
        const formattedValue = value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d{2})(\d{1,2})/, '$1 - $2 - $3');

        return formattedValue;
    }

    function formatSecuredValue(value) {
        const securedValue = value
            .replace(/[^0-9•]/g, '')
            .replace(/((?:\d|.){3})((?:\d|.){2})((?:\d|.){1,2})/, '$1 - $2 - $3')
            .replace(/[0-9]/g, '•');

        return securedValue;
    }
    const validationSchema = yup.object().shape({
        ssn_no: yup.string().required('Social Security Number (SSN) is required')
    });
    const footerContent = () => {
        return (
            <div>
            </div>
        )

    }



    const getLocationType = () => {

        if (ssnScreenThrough == t('climb_savings_enrollment')) {
            return t('back_to_climb');
        } else if (ssnScreenThrough == t('account_services_savings_enrollment')) {
            return t('back_to_account_services');
        } else if (ssnScreenThrough == t('home_savings_enrollment')) {
            return t('back_to_home');
        } else if (ssnScreenThrough == t('came_from_climb')) {
            return t('back_to_climb');
            
        } 
        else {
            // If neither 'Climb' nor 'accountServices' is found
            return 'Back'; // or any other default value
        }
    };

    const onAcptBtnClick = async () => {
        if (btn1Text == t('go_to_savings_account')) { navigate('/Savings'); }
        else if (btn1Text == t('back_to_climb')) { navigate('/Climb/ClimbMenu'); }
        else if (btn1Text == t('back_to_account_services')) { navigate('/AccountServices') }
        else if (btn1Text == t('back_to_home')) { navigate('/home') }
        else if (btn1Text == t('back_to_climb')) { navigate('/Climb') }
    }
    const onCancelBtnClick = () => {
        if (btn2Text == t('back_to_climb')) { navigate('/Climb/ClimbMenu'); }
        else if (btn2Text == t('back_to_account_services')) { navigate('/AccountServices') }
        else if (btn2Text == t('back_to_home')) { navigate('/home') }
        else if (btn2Text == t('back_to_climb')) { navigate('/Climb') }

    }
    const closeModel = () => {
        setShowModel(false);
    }

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }

    useEffect(() => { console.log('87451', ssnScreenThrough) }, [ssnScreenThrough])


    useKeyDown(() => {


    }, ["Enter"]);

    const validateSSN = async (ssn) => {
        setIsLoading(true)
        debugger;
        const deviceId = await retrieveData('updatedDeviceId')
        let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var requestObj;
        var url;
        // switch (ssnScreenThrough) {
        //     case t('climb_savings_enrollment'):
        //         requestObj = { ssn: ssn }
        //         url = SAVINGS_VALIDATE_SSN.replace('{cardkey}', cards?.[0]?.CardKey)
        //         break;
        //     default:
        //         break;
        // }
        requestObj = { ssn: ssn }
        url = SAVINGS_VALIDATE_SSN.replace('{cardkey}', cards?.[0]?.CardKey)
        var response = await PostRequest(url, requestObj, headers);
        let responseObj = JSON.parse(response);
        if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
            setIsLoading(false)
            setIsSSNValid(true)
        } else {
            setIsLoading(false)
            setIsSSNValid(false)
            var arrMessages = responseObj.Messages;
            if (arrMessages.length > 0) {
                var messageObject = arrMessages[0];
                const message = messageObject.DisplayText;
                setinfoMessage(message);
            }
        }
    };

    const finalApiHit = async () => {
        setIsLoading(true)
        const deviceId = await retrieveData('updatedDeviceId')
        let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var requestObj;
        var url;
        // switch (ssnScreenThrough) {
        //     case t('climb_savings_enrollment'):
        //         requestObj = { ssn: ssnBackup }
        //         url = CREATE_SAVINGS_ACCOUNT.replace('{cardkey}', cards?.[0]?.CardKey)
        //         break;
        //     default:

        //         break;
        // }
        requestObj = { ssn: ssnBackup }
        url = CREATE_SAVINGS_ACCOUNT.replace('{cardkey}', cards?.[0]?.CardKey)
        var response = await PostRequest(url, requestObj, headers);
        let responseObj = JSON.parse(response);
        if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
            setIsLoading(false)
            dispatch({ type: 'SAVINGS_TERMS_CONDITIONS', payload: { savingsTermsConditions: null } });
            dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: null } });
            await callPostLoginAPI()

        } else {
            setIsLoading(false)
            setIsSSNValid(false)
            var arrMessages = responseObj.Messages;
            if (arrMessages.length > 0) {
                var messageObject = arrMessages[0];
                const message = messageObject.DisplayText;
                showAlertModal(true, t("oops_exclamation"), message, '', true, true, getLocationType(), '', true, require('../../../assets/images/warning.png'), '', false, true)
                // setErrorMessage(message);
            }
        }
    };
    async function callPostLoginAPI() {
        setIsLoading(true);
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        const response = await GetRequest(GET_CARDHOLDER_CARDS, headers)
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
        var responseObj = JSON.parse(response)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            if (responseObj.Response != undefined && responseObj.Response != null && responseObj.Response.length > 0) {
                const userCardsPayload = { userCards: JSON.stringify(responseObj.Response) };
                dispatch({ type: 'UPDATE_USER_CARDS', payload: userCardsPayload });
            }


            if (responseObj?.Response.some(item => item.AccountTypeName === "Savings")) {
                const savingsAvailablePayload = { savingsAccontAvailable: true };
                dispatch({ type: 'SAVINSGS_AVAILABLE', payload: savingsAvailablePayload });
                const savingsNewPayload = { savingsNewClicked: true };
                dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
                showAlertModal(true, t("congrats"), t("your_kudzu_savings_account_is_active"), t('savings_acount_ready_to_use'), true, true, t('go_to_savings_account'), getLocationType(), true, require('../../../assets/images/savings_optin_success.png'), '', false, true)
            } else {
                showAlertModal(true, t("congrats"), t("your_kudzu_savings_account_is_active"), t('savings_acount_ready_to_use'), true, false, t('back_to_home'), getLocationType(), true, require('../../../assets/images/savings_optin_success.png'), '', false, true)
            }

        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oops_exclamation"), displayText, '', true, false, t('back_to_home'), '', true, require('../../../assets/images/warning.png'), '', false, true)
        }
    }

    const toggleCursor = () => {
        setCursorVisibility(!isCursorVisible);
    };
    const togglePasswordVisibility = () => {
        setShowSSN(!showSSN);
    };
    const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
    });
    const handleSSNChange = (event) => {
        //debugger;
        const ssnNumber = event.target.value;
        // Remove all non-digit characters from the input
        // const ssnNum = ssnNumber.length !== 9 ? ssnNumber.replace(/[*-]/g, '') : ssnNumber;
        const ssnNum = ssnNumber.replace(/[*-]/g, '').replace(/\D/g, '');
        setSSnBackUp(ssnNum)
        handleSSNValue(ssnNum)
        setinfoMessage('');
        if(event.target.value.length < 10){setSSNBackupSecondary(event.target.value.replace(' ',"").replace('-',"").replace(' ',""))}
        // Remove dashes from the input 
        if (ssnNum.length === 9) {
            // setError('ssn_no', { type: 'manual', message: 'Social Security Number (SSN) should be 9 digits' });
            //return;
            setinfoMessage('');
            handleInputChange(event);
            validateSSN(ssnNum)
            // Update the form values
            // ValidateSSN(ssnNo, formValues); // Pass the SSN value and form values to the ValidateSSN function
        } else {
            setIsSSNValid(false)
        }
    };
    const handleInputChange = (event) => {
        setinfoMessage('');
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };
    function handleSSNValue(value) {
        const formatted = showSSN ? formatSecuredValue(value) : formatValue(value)
        setSSN(formatted)
    }
    const handleConfirm = () => {
        clearErrors();
        if (ssnBackup == '' || !isSSNValid) {

            setinfoMessage(t('please_enter_valid_ssn')); setErrorMessage(t(''));
            return;
        }
        // else if (ssnBackup != '' && (savingsTermsConditions == false)) { setinfoMessage(''); setErrorMessage(t(t('please_review_certify_under_penalty_of_perjury'))); }
        else if ((savingsTermsConditions == true) && isSSNValid) {

            if (termsAccepted) { finalApiHit() }
            else {

                setErrorMessage(t('please_review_certify_under_penalty_of_perjury'))
            }
        }
        // else if () {}

    }
    const handlePaste = (event) => {
        event.preventDefault();
        return false;
    };

    return (
        <>
            <HomeLayout>
                <div className='sm:contents hidden'>
                    <HomeHeader heading={<p className={`text-white md:text-[32px] sm:text-[18px]
                 text-[27px] font-[400] md:leading-[38px] sm:leading-[24px] leading-[31px]
                  font-family: Hanken Grotesk`}>{t('finally_comma')} <span className={`text-[#94C3E8] md:text-[32px] sm:text-[18px] text-[27px] font-[400] md:leading-[38px] sm:leading-[24px] leading-[31px] font-family: Hanken Grotesk`}>{(t('please_confirm_your_social_security_number'))}</span></p>} isHeaderCaption={true} />
                </div>
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        footer={false}
                        CardRadius={true}
                        HeaderRadius={true}
                        mblCardRadius={true}
                        isWhite={false}
                        cardWidth={width.homeCard}>
                        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '560px', width: '75%', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }} className='mx-auto sm:mt-10'>
                            <div style={{ minHeight: '560px' }}>

                                <div className="sm:hidden block lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] lg:text-[32px] md:text-[28px]
                                 sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[32px] font-serif-pro w-[249px] 
                                 h-auto mb-[2rem] sm:px-0 px-4">
                                    <p className='text-[#2965FB]'>{t('finally_comma')}
                                        <span className='text-[#262B3A]'> {(t('please_confirm_your_social_security_number'))}</span>
                                    </p>
                                </div>

                                <div className="sm:block hidden lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                     md:text-[32px] sm:text-[18px] text-[27px]                                                                                                                                                                                                                                                      44444444444Q            Q
                     md:leading-[38px] sm:leading-[24px] leading-[31px] w-full font-serif-pro
                        sm:mx-0 mx-4">
                                    {t('social_security_number')}</div>


                                {/* <div className='border-t-[2.5px] w-full border-[#A3CEBB] 
                        flex justify-center sm:mx-0 mx-4'></div> */}

                                <div className="w-full mt-[4rem] sm:px-0 px-4"
                                    onFocus={() => { toggleCursor() }}
                                    onBlur={() => { toggleCursor() }}
                                >
                                    <div className="flex items-center relative">
                                        <span
                                            type="button"
                                            className="text-gray-500 absolute right-4 top-5"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showSSN ? <img src={ShowSSNPic} className='w-[20px] h-[20px] cursor-pointer' /> : <img src={HideSSNPic} className='w-[20px] h-[20px] cursor-pointer' />}
                                        </span>
                                        <input
                                            ref={ssnRef}
                                            onPaste={handlePaste}
                                            id={'ssn_no'}
                                            {...register('ssn_no')}
                                            value={SSNBackupSecondary}
                                            placeholder={'Social Security Number (SSN)'}
                                            className={`${InputStyles} ${showSSN ? 'password-input' : ''}`}
                                            autoComplete="off"
                                            onChange={handleSSNChange}
                                            maxLength={9}
                                            style={{ color: 'transparent', caretColor:'transparent'}}
                                            
                                            // value={ssn}
                                        // onKeyUp={handleInputChange}
                                        >
                                        </input>
                                    </div>
                                    <div className='flex z-[999] opacity-100 relative top-[-51px] h-[60px] w-[80%] 
            bg-transparent items-center'

                                        style={{ position: 'relative', top: -48 }}
                                        onClick={() => {
                                            const input = document.getElementById("ssn_no");
                                            input.focus()
                                        }}>
                                        <p className='h-[50px] no-underline ml-[15px] top-[10] text-[#2965FB]' >{ssn}{isCursorVisible && <span style={{ display: 'inline-block', animation: isCursorVisible ? 'blink 0.8s step-end infinite' : 'none', verticalAlign: 'text-bottom', color: '#2965FB', fontWeight: '200', fontSize: '15px' }}>|</span>}</p>
                                    </div>

                                </div>
                                <style>{`@keyframes blink {0%, 100% {opacity: 1;}50% {opacity: 0;}}`}</style>
                                {infoMessage &&
                                    <div className={`-mt-12 sm:-mt-30 md:-mt-12 mb-2 pb-[3rem]`}>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                }
                                {errors.ssn_no &&
                                    <div className={`-mt-12 sm:-mt-30 md:-mt-12 mb-2 pb-[3rem]`}>
                                        {displayErrorMessage(errors.ssn_no.message)}
                                    </div>
                                }
                                <div className='sm:px-0 px-4'>
                                    <div class="flex lg:mb-[2rem] md:mb-[2rem] mb-[2rem]" >
                                        <input
                                            id="deposit-savings-checkbox"
                                            ref={checkbox1Ref}
                                            type="checkbox"
                                            class="checkbox-round md:mt-[6px] mt-[4px]"
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') { setTermsAccepted(!termsAccepted); if (!termsAccepted) { setErrorMessage(''); button1Ref.current.focus(); } }
                                            }}
                                            value=""
                                            checked={termsAccepted}
                                            onChange={(event) => { setTermsAccepted(!termsAccepted); if (!termsAccepted) { setErrorMessage(''); button1Ref.current.focus(); }; }}
                                            
                                        />
                                        <label for="deposit-savings-checkbox" class="font-hanken-grotesk ml-4 sm:ml-2 md:ml-4 md:text-sm sm:text-[12px] text-[14px] font-medium">{t('under_penalty_of_perjury_iwill_clarify')}
                                        </label>
                                    </div>
                                    <p className='pl-[3.5rem] md:text-sm sm:text-[12px] text-[14px] font-hanken-grotesk font-medium ' dangerouslySetInnerHTML={{ __html: t('under_penalty_of_perjury_ssn').replace(/\n/g, "<br />") }}></p>
                                  
                                </div>
                                {errorMessage &&
                                    <div className={`mt-2 mb-2 pb-[2rem]`}>
                                        {displayErrorMessage(errorMessage)}
                                    </div>
                                }
                                <p className="lg:my-[4rem] mt-20 md:my-[4rem] my-[4rem] md:text-sm sm:text-[12px] text-[14px] font-hanken-grotesk  sm:mx-0 mx-4 sm:font-medium font-[600] sm:w-auto w-[290px]">
                                    {t('by_clicking_continue_i_understand')}
                                </p>
                                <div className='lg:my-[4rem] md:my-[4rem] my-[4rem] flex flex-row justify-end lg:mt-20 md:mt-16 sm:mt-6 sm:mx-0 mx-4 lg:mb-10:mb-6 mb-14'>
                                    <button
                                        type="button"
                                        // autoFocus
                                        ref={button1Ref}
                                        className={`font-hanken-grotesk sm:font-bold font-[600]
                                lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] ${isSSNValid ? 'text-[#2965FB]' : 'text-[#94C3E8]'}
                                 cursor-pointer flex items-center`}
                                        onClick={() => { handleConfirm() }}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                handleConfirm()
                                            }
                                        }}
                                    >
                                        {t('continue')}<img
                                            src={Arrow}
                                            className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px] h-[14px] ml-1"
                                            style={{ opacity: isSSNValid ? 1 : 0.5 }}
                                            alt="Arrow" />
                                    </button>
                                </div>
                            </div>
                        </div>

                    </DynamicCard>

                </div>
                <CustomAlertModel
                    isOpen={showModel}
                    onClose={closeModel}
                    image={modelImage}
                    highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                    <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                    // HeaderMsg={<p className={`${HeaderMsgStyles} hidden sm:flex`}>{headerMsg}</p>}
                    subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                    btn1Exists={btn1Exists}
                    btn2Exists={btn2Exists}
                    btn1Text={btn1Text}
                    btn2Text={btn2Text}
                    btn1TypeAcpt={btn1TypeAcpt}
                    footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                    onAcptBtnClick={onAcptBtnClick}
                    onCancelBtnClick={onCancelBtnClick}
                    showErrorImg={showErrorImg}
                    isBorderNone={isBorderNone}
                    largeImage={highlightMsg == t('congrats') ? true : undefined}
                />
                {isLoading && <Loader />}
            </HomeLayout>
        </>
    )
}

export default SavingsSSNSetup;
