import React, { useEffect, useRef, useState } from 'react';
import datePickerRight from '../../../assets/images/datePickerRight.png'
import datePickerLeft from '../../../assets/images/datePickerLeft.png'
import moment from 'moment';
import calendarImg from '../../../assets/images/calender_3x.png'
import { t } from 'i18next';
import { min } from 'moment/moment';

const CustomDatePicker = (props) => {
    const { minDate, maxDate, disabledDates, onDateChange, selectedDate, title, hrbg } = props;
    const IsDefaultPlaceholder = props.IsDefaultPlaceholder || false; // Default to false if not provided as a prop


    const today = new Date();
    const [currentDate, setCurrentDate] = useState(today);
    const [selectedDateCopy, setSelectedDateCopy] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const componentRef = useRef(null);
    useEffect(() => { setSelectedDateCopy(selectedDate) }, [selectedDate])
    // console.log("dates----",minDate, maxDate)


    useEffect(() => {
        const handleActionOutside = (event) => {
            if (
                componentRef.current &&
                !componentRef.current.contains(event.target) &&
                isOpen
            ) {
                // Click or keyboard action occurred outside the component, so close it
                setIsOpen(false);
            }
        };

        const handleKeyDown = (event) => {
            if (event.keyCode === 27 && isOpen) {
                // Escape key pressed and component is open, so close it
                setIsOpen(false);
            }
        };

        const handleClick = () => {
            // Open the component when the button is clicked
            setIsOpen(!isOpen);
        };

        // Attach event listeners for mouse clicks and keyboard events when the component is open
        if (isOpen) {
            document.addEventListener('mousedown', handleActionOutside);
            document.addEventListener('keydown', handleKeyDown);
        }

        // Remove event listeners when the component is closed or unmounted
        return () => {
            document.removeEventListener('mousedown', handleActionOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen]);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const isDateDisabled = (date) => {
       
        return (
            (minDate && moment(`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`).isBefore(`${minDate.getFullYear()}-${('0' + (minDate.getMonth() + 1)).slice(-2)}-${('0' + minDate.getDate()).slice(-2)}`)) ||
            (maxDate && date > maxDate) ||
            disabledDates.some((disabledDate) => date.toDateString() === new Date(disabledDate).toDateString())
        );
        // return (
        //     (minDate && date < minDate) ||
        //     (maxDate && date > maxDate) ||
        //     disabledDates.some((disabledDate) => date.toDateString() === new Date(disabledDate).toDateString())
        // );
    };

    const prevMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() - 1);
        if (!(newDate < minDate)) {
            setCurrentDate(newDate);
        }
    };

    const nextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + 1);
        if (!(new Date(currentDate).setMonth(new Date(currentDate).getMonth()) > maxDate)) {
            setCurrentDate(newDate);
        }
    };

    const renderCalendar = () => {
        const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const firstDayOfWeek = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
        const lastDayOfWeek = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDay();
        const nextMonthForLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        const lastDayOfCurrentMonth = new Date(nextMonthForLastDay.getTime() - 1).getDate();
        const nextMonthDays = lastDayOfWeek === 0 ? 6 : 5 - lastDayOfWeek; // Update this line
        let nextMonthDay = 1; // Update this line

        const matrix = [];
        let dayCounter = 1;

        // Calculate the number of days from the previous month to display
        const prevMonthDays = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;
        const prevMonthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
        let prevMonthDay = prevMonthLastDay - prevMonthDays;

        for (let i = 0; i < 6; i++) {
            const week = [];

            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDayOfWeek) {
                    week.push({ date: new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, prevMonthDay), disabled: true });
                    prevMonthDay++;
                } else if (dayCounter > daysInMonth) {
                    if (i === (firstDayOfWeek > 4 ? 5 : 4) && j > lastDayOfWeek) { // Display next month's days in the last row
                        week.push({ date: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, nextMonthDay), disabled: true });
                        nextMonthDay++;
                    } else {
                        week.push(null);
                    }
                } else {
                    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), dayCounter);
                    week.push({ date, disabled: isDateDisabled(date) });
                    dayCounter++;
                }
            }

            matrix.push(week);
        }

        const handleOnChange = (date) => {
            setSelectedDateCopy(date)
        }

        return (
            <div className=' sm:absolute left-[27%] top-[25%] w-full sm:min-h-[300px] flex justify-center'>
                <div ref={componentRef} className='bg-[#F5F8F9] w-full absolute sm:w-[300px] sm:h-[330px] flex flex-col self-center 
                justify-between items-center sm:p-6 py-16 px-8 sm:rounded-bl-[50px] sm:rounded-tr-[50px]'>
                    <div className='sm:hidden flex flex-col justify-center sm:pb-0 pb-10 text-[26px]
                     font-serif-pro font-[400] text-center w-[218px]'>
                        {t('please_select_a_start_date')}</div>
                    <div className='w-full flex flex-col self-center sm:pb-0 pb-8'>
                        <div className='flex flex-row self-center justify-between font-hanken-grotesk 
                        text-[#2965FB] text-[13px] font-[700] cursor-pointer'>
                            <button onClick={prevMonth}><img src={datePickerLeft} className='w-2 h-3' /></button>
                            <p className='px-[10px] font-hanken-grotesk font-[700] lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px] leading-[20px]'>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</p>
                            <button onClick={nextMonth}><img src={datePickerRight} className='w-2 h-3' /></button>
                        </div>
                    </div>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th colSpan="7">
                                </th>
                            </tr>
                            <tr>
                                <th className='text-[#262B3A] text-[14px] font-[500]'>Sun</th>
                                <th className='text-[#262B3A] text-[14px] font-[500]'>Mon</th>
                                <th className='text-[#262B3A] text-[14px] font-[500]'>Tue</th>
                                <th className='text-[#262B3A] text-[14px] font-[500]'>Wed</th>
                                <th className='text-[#262B3A] text-[14px] font-[500]'>Thu</th>
                                <th className='text-[#262B3A] text-[14px] font-[500]'>Fri</th>
                                <th className='text-[#262B3A] text-[14px] font-[500]'>Sat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {matrix.map((week, rowIndex) => (
                                <tr key={rowIndex}>
                                    {week.map((day, colIndex) => (
                                        <td
                                            key={colIndex}
                                            style={{
                                                textAlign: 'center',
                                                color: day && day.date.getMonth() !== currentDate.getMonth() ? '#aaa' : 'inherit',
                                                cursor: day && day.disabled ? 'not-allowed' : 'pointer'
                                            }}
                                        >
                                            {day && (
                                                <button
                                                    className='text-[#262B3A] text-[14px] font-[500] py-2 px-2'
                                                    style={{
                                                        backgroundColor: moment(day.date).format('DD-MM-YYYY') === moment(selectedDateCopy).format('DD-MM-YYYY') ? '#2965FB' : 'transparent',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        color: day.disabled ? '#94C3E8' : moment(day.date).format('DD-MM-YYYY') === moment(selectedDateCopy).format('DD-MM-YYYY') ? '#ffffff' : '#262B3A',
                                                        position: 'relative',
                                                        borderRadius: 50
                                                    }}
                                                    onClick={() => { if (!(day.disabled)) { handleOnChange(day.date); onDateChange(day.date); toggleDropdown(); } }}
                                                >
                                                    {moment(day.date).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') && <div className='w-1 h-1' style={{ borderRadius: 50, position: 'absolute', right: 15, top: 3, backgroundColor: moment(day.date).format('DD-MM-YYYY') === moment(selectedDateCopy).format('DD-MM-YYYY') ? '#ffffff' : '#2965FB' }}></div>}
                                                    {/* {moment(day.date).format('DD-MM-YYYY') === moment(new Date(new Date().toUTCString().substr(0, 25))).format('DD-MM-YYYY') && <div className='w-1 h-1' style={{ borderRadius: 50, position: 'absolute', right: 15, top: 3, backgroundColor: moment(day.date).format('DD-MM-YYYY') === moment(selectedDateCopy).format('DD-MM-YYYY') ? '#ffffff' : '#2965FB' }}></div>} */}
                                                    <p className='w-5 h-5' >{day.date.getDate()}</p>
                                                </button>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className='w-full flex flex-col self-center sm:mt-0 mt-6'>
                        <div className='sm:px-0 px-8 w-full flex sm:flex-row flex-col sm:gap-0 gap-4 self-end justify-end'>
                            <button className='sm:w-auto w-full sm:h-auto sm:bg-inherit bg-[#2965FB] 
                            h-[55px] font-hanken-grotesk sm:text-[#2965FB] sm:text-[13px] text-[15px] font-[700] cursor-pointer text-white' onClick={() => { setSelectedDateCopy(selectedDate) }}>{t('Reset')}</button>

                            <button className='sm:border-[0] border-[3px] border-[#2965FB] sm:w-auto w-full sm:h-auto sm:bg-inherit bg-[fff] 
                            h-[55px] sm:ml-5 font-hanken-grotesk text-[#2965FB] sm:text-[13px] text-[15px] font-[700] cursor-pointer' onClick={() => { onDateChange(selectedDateCopy); toggleDropdown(); }}>{t('Close')}</button>
                        </div>
                    </div>

                </div>
            </div>
        );
    };

    return (
        <div className='flex flex-col w-full'>
            <p className='font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[12px] sm:text-[9px] text-[13px]  
             font-[600] tracking-[2px] md:pb-6 pb-5 opacity-[50%] '>{title.toLocaleUpperCase() || ''}</p>

            <button className='w-full' onClick={() => { toggleDropdown() }}>
                <div>
                    <div className='flex justify-between flex-row items-center w-full'>
                        <div className='flex justify-between flex-row items-center mb-4'>

                            <p className="md:font-[500] sm:font-[700] font-[500] font-hanken-grotesk lg:text-[16px]
 md:text-[16px] sm:text-[14px] text-[14px] mr-3">
                                {selectedDateCopy ? ((new Date(selectedDateCopy).getDate() - new Date(new Date().toUTCString().substr(0, 25)).getDate()) === 1) ? moment(selectedDateCopy).format('[Tomorrow], dddd, MMMM DD, YYYY') : moment(selectedDateCopy).format('dddd, MMMM DD, YYYY') : (IsDefaultPlaceholder ? t('date_format_placeholder') : (props?.placeHolder || 'Please select a date'))}
                                {/* {selectedDateCopy ? moment(selectedDateCopy).format('dddd, MMMM DD, YYYY') : (IsDefaultPlaceholder ? t('date_format_placeholder') : (props?.placeHolder || 'Please select a date'))} */}
                            </p>
                        </div>
                        <img
                            src={calendarImg}
                            className="xlg:w-4 lg:w-3 md:w-3 w-3 xlg:h-4 lg:h-3 md:h-3 h-3 mr-2 ml-auto mb-4"
                        />
                    </div>
                    <hr className={` h-[2px] ${hrbg ? hrbg : 'bg-[#94C3E8] opacity-[50%]'} border-0 rounded`} />
                </div>
            </button>

            {isOpen && (
                <div className='sm:relative z-10'>
                    {renderCalendar()}
                </div>
            )}
        </div>
    );
};

export default CustomDatePicker;
