import React, { useEffect } from 'react'; 
import { useLocation } from 'react-router-dom'; // Assuming you're using react-router

const ScrollTop = () => { 
    const location = useLocation();
    useEffect(() => {
        //alert('Scrool Top');
      // Scroll the body to the top when the component mounts
      window.scrollTo(0, 0);
    }, [location]);
  
    // Return null because this component doesn't render anything
    return null;
  };

export default ScrollTop;