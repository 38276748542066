import React, { useEffect, useState, useRef } from 'react';
import Loader from '../../CustomComponents/Loader';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import DynamicCard from '../../CustomComponents/DynamicCard';
import { width } from '../../CustomComponents/DynamicCard/DynamicCardStyles';
import { useDispatch, useSelector, connect } from 'react-redux';
import { SAVING, retrieveData } from '../../../helpers/utils/Utils';
import { STATUS_SUCCESS_CODE, GET_USED_AMOUNT_DETAILS, TRANSACTIONS, SAVINGS, CHECKING, SPENDING, GET_PAY_PERKS_API } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import { withOidcSecure, useOidcUser, useOidcAccessToken } from '@axa-fr/react-oidc'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HelpSidebar } from '../../../utils';

const AboutKudzu = () => {


    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const cards = useSelector(state => JSON.parse(state.userCards));
    const [isPayPerksURL, setPayPerksURL] = useState('');
    const navigate = useNavigate();
    const iframeSrc = `data:text/html;charset=utf-8,${encodeURIComponent(htmlContent)}`;

    const accessToken = useSelector(state => state.accessToken);
    const initialCards = useRef(cards); // Store initial cards


    useEffect(() => {
        if (initialCards !== null && initialCards?.current?.length > 0) {
            const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;
            fetchPayperks(isPrimrayKey);
        }
    }, []);


    const fetchPayperks = async (isPrimrayKey) => {
        if (cards == undefined || cards == null) {
            return
        }
       // debugger;
        setIsLoading(true)
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const url = GET_PAY_PERKS_API(isPrimrayKey)
        const response = await GetRequest(url, headers);
        const responseJson = JSON.parse(response);
        setIsLoading(false)

        if (responseJson.ResponseCode == STATUS_SUCCESS_CODE) {
            const payperks_url = responseJson.Response.URL;
            setPayPerksURL(payperks_url);
        } else {

        }

    }

    const getHeaderText = () => {
        return t('welcomekudzu');
    };

    return (
        <>
            <HomeLayout>
                <HelpSidebar HelpselectedIndex={1} />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
                animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCardWithFilters}
                        HeaderRadius={true}
                        HeaderContent={false}
                        footer={true}>
                        <div className='sm:py-0'>
                        {/* <div className='sm:py-10'> */}
                            <iframe src='https://www.kudzumoney.com/about'
                                className=' rounded-tr-[4rem] rounded-bl-[4rem] ' title="PayPerks"
                                width="100%" height="800px" style={{ overflow: 'hidden'}}>
                                {/* width="100%" height="800px" style={{ overflow: 'hidden', padding: '30px' }}> */}
                            </iframe>
                        </div>
                    </DynamicCard>
                </div>
            </HomeLayout>
            {isLoading && <Loader />}
        </>
    )
}

export default withOidcSecure(AboutKudzu);