import React, { useEffect, useRef, useState } from 'react'
import { width, height } from "../../CustomComponents/DynamicCard/DynamicCardStyles";
import { useTranslation } from 'react-i18next';
import Loader from '../../CustomComponents/Loader';
import Modal from '../../CustomComponents/Modal';
import { GENERATE_EXISTING_PHONE_EMAIL_OTP, GENERATE_OTP_ADD_ACH_ACCOUNT, GET_ACCOUNTS_FOR_TRANSFER, REMOVE_EXTERNAL_ACCOUNT, STATUS_SUCCESS_CODE, TINK_LINK_ENABLED_CHECK } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import Custom2FA from "../../../components/CustomComponents/Custom2FA/index";
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import DynamicCard from '../../CustomComponents/DynamicCard';
import { useKeyDown } from '../../../hooks/useKeyDown';
import { useSelector, connect, useDispatch } from 'react-redux';
import { LOGIN_SESSION_KEY } from "../../../Redux/reducers";
import LineBreaks, { MoreServices, addLineBreaks, createTinkUrl } from '../../../utils';
import error_img from '../../../assets/images/error_3x.png'
import check_img from '../../../assets/images/check_3x.png'
import TwoFactTypeSelctionModal from '../../CustomComponents/TwoFactTypeSelctionModal';
import TinkLinkModal from '../../CustomComponents/TinkLinkModal';
import { ADD_ACH_ACCOUNT } from '../../../network/URLConstants';
import logFirebaseEvents from '../../LogFirebaseEvents';

const appConfigData = await fetchConfigarationData();


const ManageExternalAccounts = ({ transactionDatas, onViewAllClick, viewAll }) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const currentPath = location.pathname;
    // alert(currentPath);
    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const selectedItem = cards?.[0]
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [generateBtnClicked, setGenerateBtnClicked] = useState(true); //Routing Number
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const divRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [selectedExternalAccount, setSelectedExternalAccount] = useState({})
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([
        // { "AccountType": "ACH", "AccountName": "Checking Account .. 0318", "AccountId": 564199, "ACHAccountInternalId": 10131, "StatusCode": "PDG" },
        // { "AccountType": "ACH", "AccountName": "Checking Account .. 0318", "AccountId": 564199, "ACHAccountInternalId": 10131, "StatusCode": "" },
        // { "AccountType": "ACH", "AccountName": "Checking Account .. 0318", "AccountId": 564199, "ACHAccountInternalId": 10131, "StatusCode": "PDG" }
    ])
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "text-black font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] font-normal cursor-pointer pointer-events-none";
    const footerMsgstyles = "font-hanken-grotesk modal-title lg:text-[14px] md:text-[13px] sm:text-[13px] text-[14px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [isBorderNone, setisBorderNone] = useState(false);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [btn1BgColor, setBtn1BgColor] = useState(false);
    const [btn1TextColor, setBtn1TextColor] = useState(false);
    const [show2faSelection, setshow2faSelection] = useState(false);
    const [otpGenerationType, setOtpGenerationType] = useState(false);
    const isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
    const [btn1Focus, setBtn1Focus] = useState(false);
    const [btn2Focus, setBtn2Focus] = useState(false);
    const [isTinkModal, setIsTinkModal] = useState(false)
    const [isTinkFlowEnabled, setIsTinkFlowEnabled] = useState(false)



    let YOUR_AUTHENTICATION_LINK = createTinkUrl(appConfigData.TINK_LINK_ACCOUNT_CHECK_URL, appConfigData.TINK_CLIENT_ID, appConfigData.TINK_REDIRECT_URI)


    const moreServicesArray = [
        { name: 'Configure_Notifications', actionType: '', path: '/Settings/notificationConfigure' },
        // { name: t('Manage_Membership'), actionType: '', path: '' },
        // { name: t('Set_Up_Beneficiariess'), actionType: '', path: '/Settings/manageBeneficiaries' }
    ];

    useEffect(() => {
        window.addEventListener('message', receiveMessage, false);

        return () => {
            window.removeEventListener('message', receiveMessage, false); // Cleanup on unmount
        };

    }, [isTinkModal])


    function receiveMessage(event) {
        if (event.origin !== appConfigData.TINK_BASE_URL) {
            return;
        }

        // Read more about the message format in the Reference below
        const { type, data, error } = JSON.parse(event.data);

        console.log("type---", type)
        console.log("data---", data)


        if (type === 'account_verification_report_id') { // type differ between products
            // This is the authorization code that should be exchanged for an access token
            const reportId = data;
            addExternalAccount(reportId)
            setIsTinkModal(false)
            console.log(`Tink Link returned with reportId: ${reportId}`);
        } else if (type === 'code') { // type differ between products
            // This is the authorization code that should be exchanged for an access token
            const code = data;
            console.log(`Tink Link returned with authorization code: ${code}`);
        } else if (type === 'error') {
            if (error.status === 'USER_CANCELLED') {
                showAlertModal(true, t("no_problem_exclimation"), t('user_cancelled_heading'), t("user_cancelled_subheading"), true, false, t('add_via_direct_deposit'), '', true, require('../../../assets/images/user_cancelled_icon.png'), '', false, true)

            }
            else if (error.status === 'BAD_REQUEST') {
                showAlertModal(true, t("oh_no"), t('tink_bad_req_heading'), t("tink_bad_req_subheading"), true, false, t('home'), '', true, require('../../../assets/images/profileError.png'), '', false, true)
            }
            else if (error.status === 'AUTHENTICATION_ERROR') {
                showAlertModal(true, t("oh_no"), t('tink_auth_error_heading'), t("tink_auth_error_subheading"), true, true, t('try_again1'), t('home'), true, require('../../../assets/images/profileError.png'), '', false, true)
            }
            else if (error.status === 'INTERNAL_ERROR') {
                showAlertModal(true, t("oh_no"), t('tink_internal_error_heading'), <>{t("tink_internal_error_subheading")}<span className=' text-[#2965FB] cursor-pointer pointer-events-auto' onClick={() => navigate('/Help/ContactUs')}>{t('signup_customer_care')}</span>{t('for_help')}</>, true, true, t('try_again1'), t('home'), true, require('../../../assets/images/profileError.png'), '', false, true)
            }
            else if (error.status === 'TEMPORARY_ERROR') {
                showAlertModal(true, t("oh_no"), t('tink_temp_error_heading'), t("tink_temp_error_subheading"), true, true, t('try_again1'), t('home'), true, require('../../../assets/images/profileError.png'), '', false, true)
            }

            // Handle error response from Tink Link
            console.log(`Tink Link returned with error status: ${error.status} and error message: ${error.message}.`);
            setIsTinkModal(false)
        } else if (type === 'credentials') {
            // Handle credentials error response from Tink Link
            const credentialsId = data;
            console.log(`Authentication failed with credentials identifier: ${credentialsId} with error status: ${error.status} and error message: ${error.message}.`);
        } else if (type === 'status') {
            // Observe Tink Link loading state (optional)
            const { loading } = data;
            console.log(`Tink Link has ${loading ? 'shown' : 'hidden'} the loading overlay.`);
        } 
        else if (type === 'application-event') {
            // Observe Tink Link loading state (optional)
            const { event } = data;
            if (event === 'CREDENTIALS_VALIDATION_FAILED') {
                showAlertModal(true, t("oh_no"), t('tink_auth_error_heading'), t("tink_auth_error_subheading"), true, true, t('try_again1'), t('home'), true, require('../../../assets/images/profileError.png'), '', false, true)
                setIsTinkModal(false)
            }
            console.log(`Tink Link has authentication the loading overlay.`);
        } 
        else {
            // More message types may be sent or added in the future (these can safely be ignored)
            console.log(error)
        }
    }

    const isTinkLinkFlowEnabled = async () => {

        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        let url = `${TINK_LINK_ENABLED_CHECK}?cardKey=${selectedItem?.CardKey}`
        setLoading(true)
        var response = await GetRequest(url, headers)
        setTimeout(() => {
            setLoading(false)
        }, 500);
        let parsedResponse = JSON.parse(response);

        if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {

            setIsTinkFlowEnabled(parsedResponse.Response.enabled)

        } else {
            var arrMessages = parsedResponse.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;

            showAlertModal(true, t("oops_exclamation"), displayText, '', true, false, t('ok'), '', false, '', '', false, false)

        }

    }


    const addExternalAccount = async (reportId) => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        var object = {
            cardkey: selectedItem?.CardKey,
            reportId: reportId

        }
        var url = ADD_ACH_ACCOUNT
        setLoading(true)
        var response = await PostRequest(url, object, headers)
        setTimeout(() => {
            setLoading(false)
        }, 500);
        let parsedResponse = JSON.parse(response);
        if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
            setShowModel(false)
            getExternalAccounts();
            setTimeout(() => {
                showAlertModal(true, t("success"), t('your_ext_acc'), t("your_ext_acc_subheading"), true, false, 'Ok','', true, require('../../../assets/images/success.png'), '', false, true)
            }, 1000);

        } else {
            var arrMessages = parsedResponse.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;

            if (displayText == t("ach_already_exists")) {
                setShowModel(false)
                setTimeout(() => {
                    showAlertModal(true, t("oops_exclamation"), t("this_external_bank_account_has"), t('the_bank_account_number_and_routing'), true, true, t('back_to_ext_accounts'), t("cancel"), true, '', '', true, true, '#2965FB', 'none')
                }, 1000);
            } else if (displayText == t("already_added_ach")) {
                setShowModel(false)
                setTimeout(() => {
                    showAlertModal(true, t("uh_oh"), t("this_type_of_account_cannot_be_added"), '', true, true, 'Accept', 'Decline', false, '', '', false, false)
                }, 500);
            } else {
                showAlertModal(true, t("oops_exclamation"), displayText, '', true, false, t('ok'), '', false, '', '', false, false)
            }
        }
    }



    // acts as componentDidMount
    useEffect(() => {
        getExternalAccounts()
        isTinkLinkFlowEnabled();
    }, []);


    useEffect(() => { if (cards != null && cards?.length > 0 && cards?.[0]?.AllowBeneficiaries) { moreServicesArray.push({ name: t('Set_Up_Beneficiariess'), actionType: '', path: '/Settings/manageBeneficiaries' }); } }, [cards])
    const headerContent = () => {
        return (
            // <div className="flex flex-row justify-start mx-10 items-center mt-10">
            //     <div className='ml-10'>
            //         {/* Left Text */}
            //         <p className="text-xmd ">{t("external_accounts")}</p>
            //     </div>
            // </div>
            <div className="flex flex-row justify-between mx-10 items-center mt-10">
                <div className='ml-10'>
                    {/* Left Text */}
                    <p className="text-xl "></p>
                </div>
                <div onClick={(event) => { event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); }}
                    className='flex flex-row justify-end items-center mr-10 '>
                    {/* Image */}
                    {/* <img
                        src={print_img}
                        // alt="Image"
                        className="w-5 h-5 mr-2"
                    /> */}

                    {/* Right Text */}
                    {/* <p className="text-18 font-bold">{t("print")}</p> */}
                </div>
            </div>
        )
    }
    useKeyDown(() => {
        // onClickAddExternalAcc();
    }, ["Enter"]);



    ///based on the account services show the sidebar menu if setting then show text

    const breadcrumbPaths = ['/AccountServices'];

    // Check if the current path matches any of the breadcrumb paths
    const isAccountServicesPage = breadcrumbPaths.some(path => location.pathname.includes(path));



    async function getExternalAccounts() {
        // if (!global.isNetworkConnected) {
        //     // SimpleToast.show(translate("no_network"));
        //     return;
        // }
        if (selectedItem?.CardKey == null || selectedItem?.CardKey == undefined) {
            return
        }
        setLoading(true);
        const url = GET_ACCOUNTS_FOR_TRANSFER.replace('{CARD_KEY}', selectedItem?.CardKey) + '?statusCode=&ach=true'
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);


        const response = await GetRequest(url, headers)

        setTimeout(() => {
            setLoading(false)
        }, 500);

        const responseObj = JSON.parse(response);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setTimeout(() => {
                var arrAccounts = []
                if (responseObj.Response != null || responseObj.Response != undefined) {
                    if (responseObj.Response.Accounts != null || responseObj.Response.Accounts != undefined) {
                        for (var i = 0; i < responseObj?.Response?.Accounts?.length; i++) {
                            if (responseObj.Response.Accounts[i].ACHAccountInternalId != 0) {
                                arrAccounts.push(responseObj.Response.Accounts[i])
                            }
                        }
                        setAccounts(arrAccounts)
                    }
                }
            }, 1000);
        } else {

        }
    }
    const handleAccAction = async (type, item) => {
        if (type == 'PDG') {
            navigate('/Settings/ManageExternalAccounts/VerifyExternalAccount', { state: { externalAccountDetails: item } })
        }
        else if (type != 'PDG') {
            setBtn2Focus(true);
            showAlertModal(true, t("are_you_sure"), t("this_bank_acc_will_be_removed"), t("once_removed_any_feature_recurring_scheduled"), true, true, t("confirm"), t("cancel"), true, '', false, true)
        }
    }

    const openHeadsUpModal=()=>{
        showAlertModal(true, t("heads_up"), t('u_r_abt_to_leave'), t("u_r_being_directed"), true, true, 'Continue',t("cancel"), true, '', '', false, true)

    }

    const verificationSuccess = () => {
        if (isTinkFlowEnabled) openHeadsUpModal();
        else {
            if (location.pathname.includes('/AccountServices/ManageExternalAccounts')) navigate('/AccountServices/ManageExternalAccounts/AddExternalAccount')
            else navigate('/Settings/ManageExternalAccounts/AddExternalAccount')

        }
    }
    const onClickAddExternalAcc = async (type) => {

        if (twoFactAuthenticationDone) {
            if (isTinkFlowEnabled) openHeadsUpModal()
            else {
                if (location.pathname.includes('/AccountServices/ManageExternalAccounts')) navigate('/AccountServices/ManageExternalAccounts/AddExternalAccount')
                else navigate('/Settings/ManageExternalAccounts/AddExternalAccount')

            }

        }
        else {
            if (selectedItem?.CardKey == undefined) {
                return
            }
            const deviceId = await retrieveData('updatedDeviceId');
            const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

            var object = {
                // "cardKey": selectedItem?.CardKey,
                // "action": "mobile"
            }
            if (type !== undefined || type !== null) {
                object.IsEmail = (type == 'Email') ? true : false
            }
            var url = GENERATE_OTP_ADD_ACH_ACCOUNT.replace('{CARDKEY}', selectedItem?.CardKey)
            setLoading(true)
            const response = await PostRequest(url, object, headers)
            setTimeout(() => {
                setLoading(false)
            }, 500);

            let parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                // adding session key to redux
                const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                //adding userid to redux
                const userIdPayload = { userId: parsedResponse.Response.userId }
                dispatch({ type: 'USER_ID', payload: userIdPayload });

                setTimeout(() => {
                    setIsOTPVerifyModalOpen(true)
                }, 1000);
            } else {
                var arrMessages = parsedResponse.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
            }
        }
    }
    const onAcptBtnClick = async () => {
        setShowModel(false)
        if (headerMsg == t("this_bank_acc_will_be_removed")) {
            handleRemoveAccount()
        } else if (btn1Text == t("home")) {
            navigate('/home')
        } else if (btn1Text === 'Ok') {
            closeCustomAlertModal()
        } else if (btn1Text === t('add_via_direct_deposit')) {
            navigate('/AccountServices/Spending/ManageDirectDeposit')
        }else if (btn1Text == t('back_to_ext_accounts')) {
            navigate('/Settings/ManageExternalAccounts')
        }
        else if (btn1Text == t('try_again1')) {
            closeCustomAlertModal()
            setIsTinkModal(true)
        }
        else if (btn1Text == 'Continue') {
            logFirebaseEvents("Manage_External_Account", "Continue_Clicked", "Continue_Clicked", "","");
            closeCustomAlertModal()
            setIsTinkModal(true)
        }
    }
    const onCancelBtnClick = () => {
        if (btn2Text == t('back_to_ext_accounts')) {
            navigate('/Settings/ManageExternalAccounts')
        } else if (btn2Text == 'Cancel') {
            logFirebaseEvents("Manage_External_Account", "Cancel_Clicked", "Cancel_Clicked", "","");
            closeCustomAlertModal()
        } else if (btn2Text == t("back_to_settings")) {
            navigate('/Settings')
        }else if (btn2Text == t("home")) {
            navigate('/home')
        } 
        else{
            closeCustomAlertModal()
        }
    }
    const handleRemoveAccount = async () => {
        if (selectedItem?.CardKey == undefined) {
            return
        }
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        const object = {
            achAccountId: selectedExternalAccount.AccountId,
            cardKey: selectedItem?.CardKey,
            achaccountInternalId: selectedExternalAccount.ACHAccountInternalId
        }
        setLoading(true)
        const response = await PostRequest(REMOVE_EXTERNAL_ACCOUNT, object, headers)
        setTimeout(() => {
            setLoading(false)
        }, 500);
        const responseObj = JSON.parse(response);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setTimeout(() => {
                setBtn2Focus(false);
                showAlertModal(true, t("done_exclamation"), t("this_bank_acc_has_been_removed"), t("to_send_and_recieve_money_from_this_account"), true, false, 'Ok', '', true, require('../../../assets/images/remove_acc.png'), false, false)
                getExternalAccounts()
            }, 1000);
        } else {
            setBtn2Focus(false);
        }
    }
    const closeCustomAlertModal = () => {
        setShowModel(false);
    }
    const closeOTPVerifyModal = () => {
        setIsOTPVerifyModalOpen(false);
    }
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1TextColor, btn1BgColor) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(false)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
        setBtn1TextColor(btn1TextColor)
        setBtn1BgColor(btn1BgColor)

    }
    const footerBtnClick = (type) => {
        if (type == t('configure_notifications')) { navigate("/Settings/notificationConfigure") }
        // if (type == t('manage_membership')) { }
        if (type == t('setup_beneficiaries')) { navigate("/Settings/manageBeneficiaries") }
    }
    return (
        <>
            <HomeLayout>
                <HomeHeader heading={addLineBreaks(t('here_are_your_existing_accounts'))} isHeaderCaption={(isAccountServicesPage) ? false : true} />
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        cardWidth={width.homeCard}
                        header={true}
                        footer={true}
                        headerContent={headerContent()}
                        footerContent={() => { }}
                        CardRadius={true}
                        HeaderRadius={true}
                        isWhite={true}>
                        <div class="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                      lg:text-[26px] md:text-[22px] 
                      sm:text-[15px] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">
                            {t("external_accounts")}</div>
                        <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4'></div>
                        {accounts.map((item, index) => (
                            <div className='md:h-[180px] sm:h-[135px] h-[155px] border-b-[3px] 
                                     flex justify-center border-[#D1E7DD] items-center sm:mx-0 mx-4'>
                                <div className='sm:w-[83%] w-full'>
                                    <div key={index}>
                                        <p className='font-hanken-grotesk text-[#262B3A] 
                                        uppercase lg:text-[13px] md:text-[12px] 
                                        sm:text-[11px] text-[13px]  font-[600] tracking-[2px] md:pb-8 pb-5 opacity-[50%] '>
                                            {t("account_name").toLocaleUpperCase()}</p>
                                        <div className='sm:flex flex-row justify-between'>
                                            <div className='flex flex-row items-center sm:pb-0 pb-4 sm:justify-self-auto justify-between'>
                                                <p className="font-[700] md:text-[12px] sm:text-[10px] text-[14px] mr-2 
                                             cursor-pointer pointer-events-none">
                                                    {item.AccountName.replace(" Account", "")}
                                                </p>
                                                <img src={item.StatusCode == 'PDG' ? error_img : check_img} className="lg:w-[20px] lg:h-[20px] md:w-[16px] md:h-[16px] sm:w-[13px] sm:h-[13px] w-[15px] h-[15px] sm:ml-5" />
                                            </div>
                                            <button className='font-hanken-grotesk text-[#2965FB] lg:text-sm md:text-[14px] sm:text-[11px] 
                                            text-[14px] font-[700] cursor-pointer'
                                                onKeyDown={(event) => {
                                                    if (event.key == "Enter") {
                                                        event.preventDefault();
                                                        event.target.style.opacity = 0.8;
                                                        handleAccAction(item.StatusCode, item);
                                                        setSelectedExternalAccount(item);
                                                        setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                    }
                                                }} onClick={(event) => {
                                                    event.target.style.opacity = 0.8;
                                                    handleAccAction(item.StatusCode, item);
                                                    setSelectedExternalAccount(item);
                                                    setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                }} style={{}}>{item.StatusCode == 'PDG' ? t("verify_account") : t("remove_account")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='md:h-[231px] h-[135px]  
                         flex justify-center border-[#D1E7DD] items-center sm:mx-0 mx-4'>
                            <div className='sm:w-[83%] w-full'>
                                <button onKeyDown={(event) => {
                                    if (event.key == "Enter") {
                                        event.preventDefault(); event.target.style.opacity = 0.8; if (twoFactAuthenticationDone) {
                                            if (isTinkFlowEnabled) openHeadsUpModal();
                                            else {
                                                if (location.pathname.includes('/AccountServices/ManageExternalAccounts')) navigate('/AccountServices/ManageExternalAccounts/AddExternalAccount')
                                                else navigate('/Settings/ManageExternalAccounts/AddExternalAccount')

                                            }
                                        } else { setshow2faSelection(true) } setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                    }
                                }}
                                    onClick={() => {
                                        logFirebaseEvents("Manage_External_Account", "Add_External_Account_Clicked", "Add_External_Account_Clicked", "","");
                                        if (twoFactAuthenticationDone) {
                                            if (isTinkFlowEnabled) openHeadsUpModal();
                                            else {
                                                if (location.pathname.includes('/AccountServices/ManageExternalAccounts')) navigate('/AccountServices/ManageExternalAccounts/AddExternalAccount')
                                                else navigate('/Settings/ManageExternalAccounts/AddExternalAccount')

                                            }
                                        }
                                        else { setshow2faSelection(true) }
                                    }}
                                    className="font-hanken-grotesk flex border-2 border-[#2965FB] 
                                    justify-center self-center items-center w-full
                                    lg:text-[16px] md:text-[15px]  sm:text-[12px] text-[15px] md:h-[60px]
                                     sm:h-[40px] h-[55px] font-[700] 
                                    text-[#2965FB] cursor-pointer" tabIndex="0" // Add this tabIndex attribute to make the div focusable
                                >
                                    {t("add_external_account")}

                                </button>

                            </div>
                        </div>


                        {location.pathname === '/AccountServices/ManageExternalAccounts/' ? (
                            <div className="h-auto border-t-[2.5px] border-[#D1E7DD] flex flex-col justify-center sm:mx-0 mx-4">
                                <MoreServices actionsArray={moreServicesArray} isMoresetting={false} />
                            </div>
                        ) : <div className="h-auto border-t-[2.5px] border-[#D1E7DD] flex flex-col justify-center sm:mx-0 mx-4">
                            <MoreServices actionsArray={moreServicesArray} isMoresetting={true} />
                        </div>}


                        {/* <div className="md:h-[313px] sm:h-[176px] h-auto
                        border-t-[3px] border-[#D1E7DD] flex flex-col justify-center sm:mx-0 mx-4">
                            <MoreServices actionsArray={moreServicesArray} isMoresetting={true} />
                        </div> */}

                        {/* <div className='flex flex-row h-3/6 w-11/12' style={{ marginTop: 20 }} > */}
                        {/* <p className="font-medium self-center w-4/5 font-serif-pro text-black text-xl
                             font-normal">{t("external_accounts")}</p> */}
                        {/* <div className='w-full flex flex-col self-center'>
                                <div className="bg-[#A3CEBB] h-0.5 min-h-1 mt-4"></div>
                            </div> */}
                        {/* <div className='w-full  border-t-[3px] border-[#A3CEBB]'> </div> */}

                        {/* <div className='w-4/5 flex flex-col self-center'>
                                {accounts.map((item, index) => (
                                    <div key={index}>
                                        <p className='font-hanken-grotesk w-3/4
                                         text-actions-text uppercase opacity-50 
                                         pt-8 cursor-grab pointer-events-none'>
                                            {t("account_name").toLocaleUpperCase()}
                                        </p>
                                        <div className='flex flex-row justify-between mt-10'>
                                            <p className="font-medium font-semibol text-xm
                                             font-medium mr-2 cursor-grab pointer-events-none">
                                                {item.AccountName.replace(" Account", "")}
                                            </p>
                                            <button className='font-hanken-grotesk text-[#2965FB] 
                                            text-sm font-semibold cursor-grab'
                                                onKeyDown={(event) => {
                                                    if (event.key == "Enter") {
                                                        event.preventDefault();
                                                        event.target.style.opacity = 0.8;
                                                        handleAccAction(item.StatusCode, item);
                                                        setSelectedExternalAccount(item);
                                                        setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                    }
                                                }} onClick={(event) => {
                                                    event.target.style.opacity = 0.8;
                                                    handleAccAction(item.StatusCode, item);
                                                    setSelectedExternalAccount(item);
                                                    setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                }} style={{}}>{item.StatusCode == 'PDG' ? t("verify_account") : t("remove_account")}
                                            </button>
                                        </div>

                                    </div>
                                ))}
                            </div> */}

                        {/* <div
                                onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; onClickAddExternalAcc(); setTimeout(() => { event.target.style.opacity = 1; }, 200); } }}
                                onClick={() => { onClickAddExternalAcc(); }}
                                className="flex mt-10 border-2 border-[#2965FB] justify-center self-center items-center mb-10 w-1/2 cursor-pointer"
                                tabIndex="0" // Add this tabIndex attribute to make the div focusable
                            >
                                <p className="text-8 font-medium py-2 px-5 text-[#2965FB] cursor-pointer">
                                    {t("add_external_account")}
                                </p>
                            </div> */}
                        {/* </div> */}
                    </DynamicCard>
                </div>
                {isTinkModal &&

                    <TinkLinkModal
                        isOpen={isTinkModal}
                        onClose={() => setIsTinkModal(false)}
                        content={

                            <iframe src={YOUR_AUTHENTICATION_LINK} width='100%' height="700px" title="Tink Link Account Check" />

                        }
                    />

                }
                {isOTPVerifyModalOpen &&
                    <Modal
                        isOpen={isOTPVerifyModalOpen}
                        onClose={closeOTPVerifyModal}
                        isLogo={false}
                        subHeaderMsg={<p className="font-hanken-grotesk modal-title md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] font-normal cursor-pointer pointer-events-none">{otpGenerationType != 'Email' && t("standard_msg_and_data_rates_may_apply")}</p>}
                        title={<p className={HighlightMsgStyles}> {t("great_exclamation")}</p>}
                        subtitle={<p className={HeaderMsgStyles}>{otpGenerationType == 'Email' ? t("we_are_sending_text").replace("a text", "an email") : t("we_are_sending_text")}</p>}
                        content={<div className="mt-10"><Custom2FA type={t('add_external_account')} selected2FAType={otpGenerationType} onClose={closeOTPVerifyModal} verificationSuccess={verificationSuccess} verificationFailure={() => { }} /></div>}
                    />
                }
                {loading && <Loader />}
            </HomeLayout>
            <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false) }} onClickNext={(object) => { setOtpGenerationType(object.proc); setshow2faSelection(false); onClickAddExternalAcc(object.proc); }} />
            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                    <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={footerMsgstyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
                btn2Focus={btn2Focus}
                btn1BgColor={btn1BgColor}
                btn1TextColor={btn1TextColor}
            />
        </>

    )
}

export default withOidcSecure(ManageExternalAccounts);
