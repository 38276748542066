import React, { useState, useEffect, createRef, useRef } from 'react'
import Arrow from '../../../../assets/images/arrow.png';
import ArrowLightBlue from '../../../../assets/images/Arrow_light_blue.png';
import { useTranslation } from 'react-i18next';
import Card from '../../../CustomComponents/Card';
import addLineBreaks, { LineBreaksSpan } from '../../../../utils';
import { height } from "../../../CustomComponents/Card/CardStyles";
import BancorpPrivacyPolicy from '../BancorpPrivacyPolicy';
import UserAgreement from '../UserAgreement';
import DepositAgreement from '../DepositAgreement';
import SavingsAgreement from '../SavingsAgreement';
import ElectronicAgreement from '../ElectronicAgreement';
import Modal from '../../../CustomComponents/Modal';
import RegisterSuccessModal from '../RegisterSuccessModal';
import ElectronicModal from '../ElectronicModal';
import LineBreaks from '../../../../utils';
import { GetApiHeadersWithOutBearer, PostRequest } from '../../../../network/NetworkUtils';
import { useSelector, connect, useDispatch } from 'react-redux';
import app_env from '../../../../utils/app-config.json';
import { STATUS_SUCCESS_CODE, REGISTER_USER } from '../../../../network/URLConstants';
import { retrieveData } from '../../../../helpers/utils/Utils';
import { displayErrorMessage } from '../../../../utils';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import Loader from '../../../CustomComponents/Loader';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import logFirebaseEvents from '../../../LogFirebaseEvents';


export const TermsandConditions = ({ finalRegistrationClick, openPersonalInfoSummaryClick, getDataFromPersonalInfo }) => {

  const providerIdentifier = useSelector(state => state.providerIdentifier);
  const personalInfo = useSelector(state => state.signUpPersonelInfo);
  const button1Ref = useRef(null);
  const provider = useSelector(state => state.provider);
  const { t } = useTranslation();
  const [isElectronicVerifyModalOpen, setIsElectronicVerifyModalOpen] = useState(false);
  const [isRegisterSucessModalOpen, setRegisterSucessModalModalOpen] = useState(false);
  const [showPolicys, setShowPolicys] = useState('terms');
  const [checkedValues, setCheckedValues] = useState([]);
  const dispatch = useDispatch();
  const userId = useSelector(state => state.userId);
  const deviceId = useSelector(state => state.deviceId);
  const [errorMessage, setErrorMessage] = useState('');
  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]";
  const [isLoading, setIsLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[18px] md:text-[16px] sm:text-[15px] text-[17px] font-medium cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [modelImage, setModelImage] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [quickActions, setQuickActions] = useState(true)
  const SelectedCardDetails = useSelector(state => state.selectedCardDetails);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [showErrorImg, setshowErrorImg] = useState(false);
  const [isEnroll, setIsEnroll] = useState(false)
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  // const deviceId = useSelector(state => state.deviceId);


  const [acceptAll, setAcceptAll] = useState(false);
  const [checkboxesState, setCheckboxesState] = useState({
    privacy_user: false,
    deposit_savings: false,
    electronic_aggrement: false,
    savings_account_aggrement: false,
    penalty_perjury: false
    // paperless_statement: false
    // Add more checkboxes as needed
  });

  const checkboxes = useSelector(state => state.signupTermsAndConditionCheckbox)
  console.log("checkboxes", checkboxes)


  const trueCount = Object.values(checkboxes).reduce((acc, curr) => (curr === true ? acc + 1 : acc), 0);
  useEffect(() => {
  }, [trueCount, checkboxes])

  useEffect(() => {
    const allChecked = Object.values(checkboxes).every((value) => value);
    setAcceptAll(allChecked);
  }, [checkboxes]);

  const handleAcceptAllChange = (event) => {
    debugger;
    // alert('accept all');
    const isChecked = event.target.checked;
    setAcceptAll(isChecked);

    // Update the state of each checkbox
    // setCheckboxes((prevCheckboxes) => {
    //   const updatedCheckboxes = { ...prevCheckboxes };
    //   for (const checkboxKey in updatedCheckboxes) {
    //     if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, checkboxKey)) {
    //       updatedCheckboxes[checkboxKey] = isChecked;
    //     }
    //   }
    //   return updatedCheckboxes;
    // });
  };

  useEffect(() => { if ((checkboxes.savings_account_aggrement && trueCount >= 4) || (!checkboxes.savings_account_aggrement && trueCount >= 3)) { console.log('checkboxes', checkboxes, 'truecount', trueCount); button1Ref.current.focus(); } }, [trueCount, checkboxes])


  const handleCheckboxChange = (event, checkboxKey) => {
    const isChecked = event.target.checked;
    dispatch({
      type: 'UPDATE_SIGN_UP_TERMS_CHECKBOX',
      payload: { 'key': checkboxKey, 'value': isChecked }
    })

  }

  const handleCheckboxKeyDown=(keyArray)=>{
    let updatedKeyObj={}
    keyArray.forEach((key)=>
    {
      updatedKeyObj[key]=!checkboxesState[key]
      dispatch({
        type: 'UPDATE_SIGN_UP_TERMS_CHECKBOX',
        payload: { 'key': key, 'value': !checkboxesState[key] }
      })
    }
    )
    // for(let key in keyArray){
    //   updatedKeyObj[key]=!checkboxesState[key]
    //   dispatch({
    //     type: 'UPDATE_SIGN_UP_TERMS_CHECKBOX',
    //     payload: { 'key': key, 'value': !checkboxesState[key] }
    //   })
    // }
    const updateObj={...checkboxesState, ...updatedKeyObj}
    // const updateObj={...checkboxesState, [key]: !checkboxesState[key]}
    setCheckboxesState(updateObj)
   
  }

  // const handleCheckboxChange = (event, checkboxKey) => {
  //   //alert();
  //   const isChecked = !checkboxes[checkboxKey];
  //   // const isChecked = event.target.checked;
  //   dispatch({
  //     type: 'UPDATE_SIGN_UP_TERMS_CHECKBOX',
  //     payload: { 'key': checkboxKey, 'value': isChecked }
  //   })
  //   console.log(checkboxKey, isChecked)

  //   if (checkboxKey === 'electronic_agreement') {
  //     if (event.key === 'Enter') {
  //       OpenElectricVerificationModal();
  //     }
  //   } else {
  //     if (event.key === 'Enter') {
  //       event.preventDefault(); // Prevent the default behavior (toggling the checkbox)
  //       // setCheckboxes((prevCheckboxes) => ({
  //       //   ...prevCheckboxes,
  //       //   [checkboxKey]: !isChecked, // Manually toggle the checkbox state
  //       // }));
  //       dispatch({
  //         type: 'UPDATE_SIGN_UP_TERMS_CHECKBOX',
  //         payload: { 'key': checkboxKey, 'value': !isChecked }
  //       })

  //       // if (checkboxKey === "savings_account_aggrement") {
  //       //   setCheckboxes((prevCheckboxes) => ({
  //       //     ...prevCheckboxes,
  //       //     penalty_perjury: isChecked
  //       //   }));
  //       //   if (!isChecked) { setErrorMessage(t('please_review_certify_under_penalty_of_perjury')) } else { setErrorMessage("") }
  //       // }
  //       // if (checkboxKey === "penalty_perjury") {
  //       //   if (isChecked) { setErrorMessage(t('please_review_certify_under_penalty_of_perjury')) } else { setErrorMessage("") }
  //       // }
  //     } else {
  //       // Handle changes caused by clicks or other interactions
  //       // setCheckboxes((prevCheckboxes) => ({
  //       //   ...prevCheckboxes,
  //       //   [checkboxKey]: isChecked,
  //       // }));
  //       dispatch({
  //         type: 'UPDATE_SIGN_UP_TERMS_CHECKBOX',
  //         payload: { 'key': checkboxKey, 'value': !isChecked }
  //       })

  //       // if (checkboxKey === "savings_account_aggrement") {
  //       //   setCheckboxes((prevCheckboxes) => ({
  //       //     ...prevCheckboxes,
  //       //     penalty_perjury: !isChecked
  //       //   }));
  //       //   if (isChecked) { setErrorMessage(t('please_review_certify_under_penalty_of_perjury')) } else { setErrorMessage("") }
  //       // }
  //       // if (checkboxKey === "penalty_perjury") {
  //       //   if (!isChecked) { setErrorMessage(t('please_review_certify_under_penalty_of_perjury')) } else { setErrorMessage("") }
  //       // }
  //     }
  //   }


  //   // Update checkedValues state
  //   setCheckedValues((prevCheckedValues) => {
  //     const updatedCheckedValues = [...prevCheckedValues];
  //     const existingCheckboxIndex = updatedCheckedValues.findIndex((checkbox) => checkbox.checkboxKey === checkboxKey);

  //     if (existingCheckboxIndex !== -1) {
  //       updatedCheckedValues[existingCheckboxIndex].checked = isChecked;
  //     } else {
  //       updatedCheckedValues.push({ checkboxKey, checked: isChecked });
  //     }

  //     return updatedCheckedValues;
  //   });

  //   // Check if all checkboxes are checked
  //   const allChecked = Object.values(checkboxes).every((value) => value);
  //   setAcceptAll(allChecked);
  // };


  const handleClick = (type) => {
    setShowPolicys((prevState) => {
      if (type === 'privacy') {
        logFirebaseEvents("Terms_and_conditions", "bancorp’s_privacy_notice_clicked", "bancorp’s_privacy_notice_clicked","", "");
        return 'privacy';
      } else if (type === 'agreement') {
        return 'agreement';
      } else if (type === 'deposit') {
        logFirebaseEvents("Terms_and_conditions", "deposit_account_agreement", "deposit_account_agreement","", "");
        return 'deposit';
      } else if (type === 'savings') {
        return 'savings';
      } else if (type === 'electronic') {
        logFirebaseEvents("Terms_and_conditions", "electronic_communications_agreement_clicked", "electronic_communications_agreement_clicked","", "");
        return 'electronic';
      } else {
        return 'terms';
      }
    });

  };


  // const OnClickElectronicPapperless= (value) => {
  //   //debugger;
  //   const updatedCheckedValues = checkedValues.map((checkbox) => {
  //     if (checkbox.checkboxKey === 'electronic_aggrement') {
  //       return { ...checkbox, checked: value };
  //     }
  //     return checkbox;
  //   }); 
  //   setCheckedValues(updatedCheckedValues); 

  //   closeMobileVerificationModal();
  // };

  const OnClickElectronicPapperless = (value) => {
    const updatedCheckboxes = { ...checkboxes, electronic_aggrement: value };
    // setCheckboxes(updatedCheckboxes);
    dispatch({
      type: 'UPDATE_SIGN_UP_TERMS_CHECKBOX',
      payload: { 'key': 'electronic_aggrement', 'value': value }
    })



    const allChecked = Object.values(checkboxes).every((value) => value);
    // alert("allchecked"+ allChecked);
    setAcceptAll(allChecked);
    closeMobileVerificationModal();
  };


  const handleContinueClick = async () => {

    //debugger;
    // Get the values of the checked checkboxes
    const checkedValues = Object.entries(checkboxes).map(([checkboxKey, checked]) => ({
      checkboxKey,
      checked,
    }));

    // Find the value of 'electronic_aggrement' checkbox

    const privacyAggrement = checkedValues.find(
      (checkbox) => checkbox.checkboxKey === 'privacy_user'
    );

    const depositAgreement = checkedValues.find(
      (checkbox) => checkbox.checkboxKey === 'deposit_savings'
    );

    const electronicAgreement = checkedValues.find(
      (checkbox) => checkbox.checkboxKey === 'electronic_aggrement'
    );

    const paperLessAgreement = checkedValues.find(
      (checkbox) => checkbox.checkboxKey === 'paperless_statement'
    );
    const savingsAccountAggrement = checkedValues.find(
      (checkbox) => checkbox.checkboxKey === 'savings_account_aggrement'
    );

    // if (electronicAgreement.checked) {
    //   finalRegistrationClick, ();
    // }

    // if (electronicAgreement.checked) {
    dispatch({ type: "SIGN_UP_TERMS_AND_CONDITIONS", payload: { signUpTermsAndConditions: checkboxes } });
    const deviceId = await retrieveData('updatedDeviceId');
    let headers = await GetApiHeadersWithOutBearer(deviceId);
    const requestObj = {
      "applicationId": personalInfo?.applicationId,
      "language": "en",
      "username": personalInfo?.username,
      "firstName": personalInfo?.firstName,
      "lastName": personalInfo?.lastName,
      "preferredName": personalInfo?.preferredName,
      "middleInitial": "",
      "ssn": personalInfo?.ssn,
      "dateOfBirth": personalInfo?.dateOfBirth,
      "line1": personalInfo?.line1,
      "line2": personalInfo?.line2,
      "city": personalInfo?.city,
      "state": personalInfo?.state,
      "zipCode": personalInfo?.zipCode,
      "isEsignAccepted": electronicAgreement.checked,
      "IsPrivacyPolicyAccepted": privacyAggrement.checked,
      "IsCHAgreementAccepted": true,
      "CreateSavings": checkboxes.savings_account_aggrement
    };
    if (personalInfo?.password !== undefined && personalInfo?.password !== '') {
      requestObj.password = personalInfo?.password;
    }

    if (providerIdentifier != null && providerIdentifier != undefined) {
      requestObj.provider = provider;
      requestObj.providerId = providerIdentifier;
    }
    requestObj.formattedAddress = personalInfo?.formattedAddress;

    if (global.inviteCode != null && global.inviteCode != undefined) {
      requestObj.inviteCode = global.inviteCode
    }
    dispatch({ type: 'SIGN_UP_PERSONEL_INFO', payload: { signUpPersonelInfo: requestObj } });
    getDataFromPersonalInfo(requestObj);
    // openPersonalInfoSummaryClick();
    finalRegistrationClick()
    return;
    // setIsLoading(true);

    // var response = await PostRequest(REGISTER_USER(userId), requestObj, headers);
    // let responseObj = JSON.parse(response);
    // if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
    //   global.inviteCode = null;
    //   setIsLoading(false);
    //   closeMobileVerificationModal();
    //   openRegisterSuccessModalOpen();
    // } else {
    //   setIsLoading(false);
    //   var arrMessages = responseObj.Messages;
    //   if (arrMessages.length > 0) {
    //     var messageObject = arrMessages[0];
    //     const message = messageObject.DisplayText;
    //     // const formattedMessage = "<span className='font-hanken-grotesk text-md leading-7 text-oops_error pr-2'>Oops! </span>" + message;
    //     // alert(message);
    //     setErrorMessage(message);
    //   }
    // }
    // }
  }

  useKeyDown(() => {
    const trueCount = Object.values(checkboxes).reduce((acc, curr) => (curr === true ? acc + 1 : acc), 0);
    if (!(button1Ref.current)) {
      if ((checkboxes.savings_account_aggrement && trueCount >= 4) || (!checkboxes.savings_account_aggrement && trueCount >= 3)) {
        if (!checkboxes.savings_account_aggrement) { showAlertModal(true, t(""), t("great_would_u_like_two_enroll"), t('set_aside_money_for_the_things_that_matter'), true, true, t('enroll_savings_account'), t('maybe_later'), true, require('../../../../assets/images/illo_beneficiary_4x.png'), false, true) }
        else {
          handleContinueClick();
        }
      }
    }
  }, ["Enter"]);




  const OpenElectricVerificationModal = () => {
    setIsElectronicVerifyModalOpen(true);

  };

  const closeMobileVerificationModal = () => {
    setIsElectronicVerifyModalOpen(false);
  };

  const openRegisterSuccessModalOpen = () => {
    setRegisterSucessModalModalOpen(true);

  };

  const closeRegisterSuccessModal = () => {
    setRegisterSucessModalModalOpen(false);
  };

  const onCancelBtnClick = () => {
    if (btn2Text == t('maybe_later')) {
      closeModel()
      handleContinueClick()
      logFirebaseEvents("Enroll_Savings_Account", "Maybe_later_Clicked", "Maybe_later_Clicked", "","");
    }

  }


  const onAcptBtnClick = () => {
    if (btn1Text == t('enroll_savings_account')) { setIsEnroll(true); closeModel(); 
      logFirebaseEvents("Enroll_Savings_Account", "Enroll_Savings_Account_Clicked", "Enroll_Savings_Account_Clicked", ""); }
  }

  useEffect(() => { }, [isEnroll])
  const closeModel = () => {
    setShowModel(false);
  }
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
    setshowErrorImg(showErrorImg)
    setisBorderNone(isBorderNone)
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
  }



  return (
    
      <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0">

        {/* {open ElectronicVerify verify modal} */}

        <Modal
          isOpen={isElectronicVerifyModalOpen}
          onClose={closeMobileVerificationModal}
          isLogo={false}
          Fullheight={true}
          title={null}
          subtitle={null}
          content={<div><ElectronicModal handleElectronicClick={OnClickElectronicPapperless} /></div>}
        />

        {/* {open Sucess verify modal} */}

        <Modal
          isOpen={isRegisterSucessModalOpen}
          onClose={closeRegisterSuccessModal}
          isLogo={false}
          subHeaderMsg={<p className="font-hanken-grotesk modal-title lg:text-[18px] 
        md:text-[17px] sm:text-[15px] text-[14px] font-normal cursor-pointer pointer-events-none">{t("standard_msg_and_data_rates_may_apply")}</p>}
          title={null}
          subtitle={null}
          content={<div><RegisterSuccessModal /></div>}
        />


        {showPolicys === 'terms' && (
          <Card
            footerBgDisabled={true}
            heading={
              <>
                <span className='md:contents sm:flex font-serif-pro sm:text-white text-[var(--text-clr-secondary2)] lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] 
 sm:leading-[30px] leading-[31px] font-[400]'>{isEnroll ? t('great_exclamation') : t('almost_there')}</span>
              </>
            }
            subheading={isEnroll ? t("terms_and_condition_saving_heading") : t('terms_and_condition_heading5')}
            Cardcaption={isEnroll ? LineBreaksSpan(t("terms_and_condition_saving_subheading")) :t('terms_and_conditions_card_heading')} header={true} footer={false}
            backgroundColor={'#EAEEEB'}
            cardHeight={'auto'} CardRadius={true}
            footerContent={
              <div className="flex justify-end 
            md:px-[5rem] sm:px-[4rem] px-[2.5rem] py-[4rem]">
                {/* <div>
                <div class="flex items-center mb-4">
                  <input
                    id="all-checkbox"
                    type="checkbox"
                    tabIndex={5}
                    class="checkbox-round acceptterms"
                    checked={acceptAll}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault(); // Prevent the default behavior (toggling the checkbox)
                        event.stopPropagation(); // Stop the event propagation to prevent interference
                        const isChecked = true;
                        setAcceptAll(isChecked);
                        // Update the state of each checkbox
                        setCheckboxes((prevCheckboxes) => {
                          const updatedCheckboxes = { ...prevCheckboxes };
                          for (const checkboxKey in updatedCheckboxes) {
                            if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, checkboxKey)) {
                              updatedCheckboxes[checkboxKey] = isChecked;
                            }
                          }
                          return updatedCheckboxes;
                        });
                      }
                    }}
                    onChange={handleAcceptAllChange}
                    value=""
                  />
                  <label for="all-checkbox" tabIndex={6}
                    class="ml-2 md:text-[18px] sm:text-[13px] text-[16px] 
                    text-[#2965FB] font-[700] ">Accept All Terms
                  </label>
                </div>
              </div> */}
                {((checkboxes.savings_account_aggrement && trueCount >= 4) || (!checkboxes.savings_account_aggrement && trueCount >= 3)) ? <div className="">

                  <button
                    ref={button1Ref}
                    type="button"
                    className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                text-[#2965FB] cursor-pointer flex items-center"
                    onKeyDown={(event) => {
                      if (event.key == 'Enter') {
                        event.preventDefault();
                        if (!(checkboxes.savings_account_aggrement)) {
                          setTimeout(() => {
                            showAlertModal(true, t(""), t("great_would_u_like_two_enroll"), t('set_aside_money_for_the_things_that_matter'), true, true, t('enroll_savings_account'), t('maybe_later'), true, require('../../../../assets/images/illo_beneficiary_4x.png'), false, true)
                          }, 500);
                        }
                        else {
                          handleContinueClick();
                        }
                      }
                    }}
                    // tabIndex={0} 
                    onClick={
                      () => {
                        if (!(checkboxes.savings_account_aggrement)) { showAlertModal(true, t(""), t("great_would_u_like_two_enroll"), t('set_aside_money_for_the_things_that_matter'), true, true, t('enroll_savings_account'), t('maybe_later'), true, require('../../../../assets/images/illo_beneficiary_4x.png'), false, true); 
                          logFirebaseEvents("Terms_and_Conditions", "Terms_and_Conditions_Continue_Clicked", "Terms_and_Conditions_Continue_Clicked", "",""); }
                        else {
                          handleContinueClick();
                        }
                      }
                    } // Call handleContinue instead of openPhoneOTPModal directly
                  >
                    {t('continue')}
                    <img
                      src={Arrow}
                      className="md:w-[17px] sm:w-[11px] md:h-[17px] sm:h-[11px] w-[12px] h-[12px] ml-2 animate__animated animate__flash animate__repeat-4"
                      alt="Arrow"
                    />
                  </button>
                </div> : <div className="">
                  <button
                    type="button"
                    className="font-hanken-grotesk font-bold lg:text-[18px] md:text-[15px] sm:text-[14px] text-[16px] text-[#94C3E8] cursor-pointer 
                flex items-center"
                 onClick={()=>{alert("continur clicked")}}
                >
                    {t('continue')}
                    <img src={Arrow} className="w-3 h-3 ml-2 opacity-50 animate__animated animate__flash animate__repeat-4"
                      alt="Arrow" />
                  </button>
                </div>}
              </div>
            }
          >
            <p className='h-[43px] font-[500] md:text-[20px] sm:text-[12px] text-[16px] font-hanken-grotesk sm:mt-[10px] mt-[25px]'>{t('accept_terms_signup')}</p>
            <div className='mt-10 md:w-[460px] sm:w-[301px] w-full'>
              {!isEnroll && 
              <div class="flex mb-4" >
                <input
                  id="privacy-user-checkbox"
                  type="checkbox"
                  class="checkbox-round md:mt-[6px] mt-[4px]"
                  value=""
                  checked={checkboxes.privacy_user}
                  onKeyDown={(event) => {
                    console.log("event----", event)
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleCheckboxKeyDown(['privacy_user', 'electronic_aggrement'])
                    }
                  }}
                  autoFocus
                  onChange={() => {
                    handleCheckboxKeyDown(['privacy_user', 'electronic_aggrement'])
                  
                  }}
                  tabIndex="0"
                />
                <label for="privacy-user-checkbox" class="font-hanken-grotesk ml-4 sm:ml-2 md:ml-4 md:text-sm sm:text-[12px] 
              text-[14px] md:font-[540] sm:font-medium font-normal
            text-gray-900 dark:text-gray-300" >{t('read_and_agree')}
                  <span
                    tabIndex="0"
                    className=' ml-1 text-blue-500 font-bold cursor-pointer'
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleClick('electronic')
                      }
                    }}
                    onClick={() => handleClick("electronic")}>{t('electronic_communications_agreement')}</span>{t('and')}
                  <span
                    className=' ml-1 text-blue-500 cursor-pointer font-bold'
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleClick('privacy')
                      }
                    }}
                    tabIndex="0"
                    onClick={() => handleClick("privacy")}>{t('bancorp’s_privacy_notice')}</span>.
                  {/* <span
                    className=' ml-1 text-blue-500 cursor-pointer font-bold'
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        // alert('hi')
                        handleClick('agreement')
                      }
                    }}
                    tabIndex={1}
                    onClick={() => handleClick("agreement")}>{t('user_agreement')}</span> */}
                </label>
              </div>
              }

              {!isEnroll && 
              <div class="flex mb-4 mt-10">
                <input
                  id="deposit-savings-checkbox"
                  type="checkbox"
                  class="checkbox-round md:mt-[6px] mt-[4px]"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
            
                      event.preventDefault();
                      handleCheckboxKeyDown(['deposit_savings'])
                    }
                  }}
                  value=""
                  checked={checkboxes.deposit_savings}
                  onChange={(event) =>
                    //  handleCheckboxChange(event, 'deposit_savings')
                     handleCheckboxKeyDown(['deposit_savings'])

                  }
                  tabIndex="0"
                />
                <label for="deposit-savings-checkbox" class="font-hanken-grotesk ml-4 sm:ml-2 md:ml-4  md:text-sm sm:text-[12px] text-[14px] md:font-[540]
                sm:font-medium font-normal
             text-gray-900 dark:text-gray-300 cu">{t('electing_to_spending_account')}
                  <span tabIndex="0" className=' ml-1 text-blue-500 cursor-pointer font-bold'
                    onClick={() => handleClick("deposit")}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleClick("deposit")
                      }
                    }}>{t('deposit_account_agreement')}</span>.
                </label>
              </div>
              }


              {isEnroll &&
                <div class="flex mb-4 mt-16">
                  <input
                    id="deposit-savings-checkbox"
                    type="checkbox"
                    class="checkbox-round md:mt-[6px] mt-[4px]"
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleCheckboxKeyDown(['savings_account_aggrement'])

                      }
                    }}
                    value=""
                    checked={checkboxes.savings_account_aggrement}
                    onChange={(event) => 
                      // handleCheckboxChange(event, 'savings_account_aggrement')
                      handleCheckboxKeyDown(['savings_account_aggrement'])
                    }
                    tabIndex="0"
                    autoFocus
                  />
                  <label for="deposit-savings-checkbox" class="font-hanken-grotesk ml-4 sm:ml-2 md:ml-4 md:font-[540]  md:text-sm sm:text-[12px] text-[14px]
               sm:font-medium font-normal
             text-gray-900 dark:text-gray-300 cu">{t('electing_open_savings_account')}
                    <span tabIndex="0" className=' ml-1 text-blue-500 cursor-pointer font-bold'
                      onClick={() => {handleClick("savings");logFirebaseEvents("TermsConditions", "SavingsAccountAgreementClicked.", "SavingsAccountAgreementClicked", "");}}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleClick("savings")
                        }
                      }}>{t('savings_account_aggrement')}</span>.
                  </label>
                </div>
              }


              {/* {checkboxes.savings_account_aggrement && <div class="flex mb-4 mt-10" tabIndex={3}>
                <input
                  id="electronic-agreement-checkbox"
                  type="checkbox"
                  class="checkbox-round md:mt-[6px] mt-[4px]"
                  value=""
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      // alert('hi')
                      handleCheckboxChange(event, 'penalty_perjury')
                    }
                  }}
                  checked={checkboxes.penalty_perjury}
                  onChange={(event) => handleCheckboxChange(event, 'penalty_perjury')}
                  tabIndex={3}
                />
                <label for="electronic-agreement-checkbox" class="font-hanken-grotesk ml-4 sm:ml-2 md:ml-4 md:text-sm sm:text-[12px]
               text-[15px] font-normal text-gray-900 dark:text-gray-300">
                  {t('penalty_of_perjury')}
                  <ul>
                    <li className='ml-[31px] list-[square] pt-[2rem] md:text-sm sm:text-[12px]
 text-[14px]'>{t('i_have_provided')}</li>
                    <li className='ml-[31px] list-[square] pt-[2rem] md:text-sm sm:text-[12px]
 text-[14px]'>{t('I_am_not_subject_to_backup')}</li>
                    <li className='ml-[31px] list-[square] pt-[2rem] md:text-sm sm:text-[12px]
 text-[14px]'>{t('i_am_a_U.S_citizen')}</li>
                    <li className='ml-[31px] list-[square] pt-[2rem] md:text-sm sm:text-[12px]
 text-[14px]'>{t('i_am_exempt_from_Foreign_account_tax')}</li>
                    <li className='ml-[31px] pt-[2rem] md:text-sm sm:text-[12px]
 text-[14px] md:pb-[3rem] pb-[2rem]'>{t('if_i_am_subject_to_backup_withoutholding')}</li>
                  </ul>
                </label>
              </div>
              } */}
              {errorMessage &&
                <div className={`mt-2 mb-2 `}>
                  {displayErrorMessage(errorMessage)}
                </div>
              }
              <p className={`font-hanken-grotesk sm:font-[540] font-normal md:text-[16px] sm:text-[12px] text-[14px] text-gray-900 dark:text-gray-300 pb-[15rem] pt-12`}>
                {t('by_tapping_continue_i_am_confirming')} </p>
                {/* {checkboxes.savings_account_aggrement ? t('by_clicking_continue_i_understand') : t('by_tapping_continue_i_am_confirming')} </p> */}


            </div>
          </Card>
        )
        }
        {
          showPolicys === 'privacy' && (
            <BancorpPrivacyPolicy handleClick={handleClick} />
          )
        }
        {
          showPolicys === 'agreement' && (
            <UserAgreement handleClick={handleClick} />
          )
        }
        {
          showPolicys === 'deposit' && (
            <DepositAgreement handleClick={handleClick} />
          )
        }
        {
          showPolicys === 'savings' && (
            <SavingsAgreement handleClick={handleClick} />
          )
        }
        {
          showPolicys === 'electronic' && (
            <ElectronicAgreement handleClick={handleClick} />
          )
        }
        <CustomAlertModel
          isOpen={showModel}
          onClose={closeModel}
          image={modelImage}
          highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
          HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
          subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
          btn1Exists={btn1Exists}
          btn2Exists={btn2Exists}
          btn1Text={btn1Text}
          btn2Text={btn2Text}
          btn1TypeAcpt={btn1TypeAcpt}
          // footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
          onAcptBtnClick={onAcptBtnClick}
          onCancelBtnClick={onCancelBtnClick}
          showErrorImg={showErrorImg}
          isBorderNone={isBorderNone}
          imageMargin={subHeaderMsg == t('set_aside_money_for_the_things_that_matter') ? true : false}
        />
        {isLoading && <Loader />}
      </div >
  
  )
}

export default TermsandConditions;