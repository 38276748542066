
import styled from "styled-components";
import tw from "twin.macro";
import AppScreenShot from '../../../assets/images/app_screenshot.png';

const primaryFont = "Source Serif Pro, serif";
const secondaryFont = "Hanken Grotesk, sans-serif";
//font-hanken-grotesk 

const fontSizes = {
  extralarge: "38px",
  large: "32px",
  medium: "20px",
  small: "16px",
  semiSmall: "14px",
};

const fontWeight = {
  bold: "700",
  normal: "400",
}

export const SectionWrapper = styled.div`
  ${tw`flex flex-wrap sm:flex-nowrap md:mt-[8rem] sm:mt-[5rem]`}
`;

export const LeftSection = styled.div`
  ${tw`w-full md:w-[50%] h-[600px] lg:h-[700px]`}
`;

export const AppScreenshot = styled.div`
  ${tw`w-full h-[600px] lg:h-[100%]`}
  background-image: url(${AppScreenShot}); 
  background-size: cover;
  background-position: center;
`;

export const RightSection = styled.div`
  ${tw`w-full md:w-[50%] h-[600px] lg:h-[700px] bg-[var(--notify-bg)] flex flex-col items-center justify-center`}
`;

export const TextWrapper = styled.div`
  ${tw`lg:w-[454px] sm:w-[78%] w-full sm:px-0 px-[2.5rem]`}
`;

export const MainText = styled.span`
  ${tw`text-[var(--text-clr-primary)] lg:text-[38px] md:text-[31px] sm:text-[22px] text-[25px] 
  lg:leading-[50px] md:leading-[40px] sm:leading-[32px] leading-[31px] font-[400]`}
  font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)};
  
  `;

export const AboutText = styled.p`
  ${tw`mt-[2.5rem] text-[var(--text-clr-primary)] font-[500] sm:w-auto w-[240px]
  lg:text-[20px] md:text-[18px] md:leading-[30px] sm:text-[12px] md:leading-[20px] text-[14px] leading-[22px] `}
  font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)}; 
`;

export const ActionButton = styled.button`
  ${tw`bg-white w-full h-[60px] mt-[2.5rem] text-center text-[var(--text-clr-secondary2)] 
  font-bold`};
  font-size: ${(props) => fontSizes.small};

`;

export const ActionButtonIcon = styled.img`
  ${tw`inline-block mr-[1rem]`}
`;

export const ActionButtonText = styled.span`
  ${tw`inline-block mr-[1rem]`}
`;

export const DownloadButtonWrapper = styled.div`
  ${tw`sm:flex block mt-[1.5rem] w-full justify-center gap-[0.5rem]`}
`;

export const DownloadButton = styled.button`
  ${tw`mb-[0.5rem] sm:mb-0 bg-[var(--footer-bg)] flex items-center justify-center gap-2 px-2 sm:w-[50%] w-full 
  h-[60px] text-white font-bold py-[8px] text-[12px] lg:text-[16px] md:text-[14px] sm:text-[12px] text-[16px]`}
  font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)};

`;

export const DownloadButtonIcon = styled.img`
  ${tw``}
`;
export const DownloadButtonText = styled.span`
  ${tw``}
`;


