import React from 'react'
import { HeaderCaption, Subtitle, Title } from './LeftBarStyles'

const LeftBar = ({ isHeaderCaption = true, leftBarType = 'text', isPrimary = true, heading, leftBarComp, leftBarTopText }) => {

    let leftBarElement;
    switch (leftBarType) {
        case 'text':
            leftBarElement = (
                <Title isPrimary={isPrimary}>
                    {heading}
                </Title>

            );
            break;
        case 'text-component':
            leftBarElement = (
                <>
                    {leftBarTopText}
                    <Title isPrimary={isPrimary}>
                        {heading}
                    </Title>
                    {leftBarComp}
                </>

            );
            break;
        case 'component':
            leftBarElement = (
                <>{leftBarComp}</>
            )
            break;
    }
    return (

        <>
            {isHeaderCaption ?

                <div >
                    {leftBarElement}
                </div>
                // <HeaderCaption>
                //     {leftBarElement}
                // </HeaderCaption>
                :
                { leftBarElement }
            }
        </>

    )
}

export default LeftBar