import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withOidcSecure } from '@axa-fr/react-oidc';
import { useKeyDown } from '../../../hooks/useKeyDown';
import white_kudzu_logo from '../../../assets/images/white_kudzu_logo.png'


const CardComponent = ({ item, index }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [generateBtnClicked, setGenerateBtnClicked] = useState(true); //Routing Number
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const cardDetails = item ? item : null;
    const indexNumber = index ? index : null;
    const divRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [modelImage, setModelImage] = useState('')
    const [footerMsg, setFooterMsg] = useState('');
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);

    useKeyDown(() => { }, ["Enter"]);
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    return (
        <div className='bg-[#30BC89] sm:mb-10 sm:mt-[-15px] mr-10 font-hanken-grotesk 
        text-20 text-white cursor-pointer lg:h-[420px] md:h-[300px] 
       sm:h-[198px] h-[300px] w-full md:rounded-[32px]
         sm:rounded-[18px] rounded-[32px] lg:p-[33px]
         md:p-[28px] sm:p-6 p-8'>

            <div className='flex flex-col h-full justify-between'>
                <div className='flex flex-row justify-between items-center'>
                    <div className='lg:text-[20px] md:text-[18px] sm:text-[12px] text-[16px]
                     font-hanken-grotesk font-[500px] leading-[30px]'>
                        {(cardDetails?.AccountTypeName === 'Checking' || cardDetails?.AccountTypeName === 'Spending') ? 'Debit Card' : cardDetails?.AccountTypeName}</div>
                    <p className='lg:text-[14px] md:text-[12px] sm:text-[10px] text-[14px] font-[700]'>
                        <span className='relative top-[0.11rem] right-1 leading-[24px]'>••</span>
                        {cardDetails?.CardDisplayNumber.replace('xxxx', '')}</p>
                </div>
                <div>
                    <img src={white_kudzu_logo} alt='white_kudzu_logo' className='lg:w-[95px] lg:h-[106px]
                     md:w-[71px] md:h-[76px] sm:w-[41px] sm:h-[46px] w-[55px] h-[55px]' />
                </div>
            </div>

        </div>
    )
}

export default withOidcSecure(CardComponent);
