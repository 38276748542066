
export var AUTH_URL = "";
export var ENVIRONMENT = "";
export var IDM_SERVICE_URL = "";
export var CLIENT_ID = "";
export var APP_URL = "";
export var BUSINESS_CODE = "";
export var CLIENT_SECRET = "";
export var USER_SCOPE = "";
export var isDEV = false;
export var IS_PROTECTION_SDK_AVAILABLE = false;
export var REDIRECT_URL = ""
export var GOOGLE_PLACES_API_KEY = "AIzaSyDSnvnrNEsJaOdNyzcOkLfjomZ8WVLiajw"
export const OTP_USERS = '/api/v1/users/';
export const SAVINGS_CARD = 32;
export const SAVING_CARD_CODE = 32;
export const ISDEV_ENV = false;
export const SPENDING_ALERT = "SpendingAlert";
export const COVERED_BY_KUDZU = "CoveredByKudzu";
export const SAVINGS_HABIT = "SavingsHabit";
export const SAVINGS_GOALS = "SavingsGoals";
export const NOTIF_CARD_SERVICES = "CARD_SERVICES"
export const NOTIF_CUSTOMER_CARE = "CUSTOMER_CARE"
export const NOTIF_TRANSACTIONS = "TRANSACTIONS"
export const NOTIF_STATEMENTS = "STATEMENTS"
export const NOTIF_FEES = "Fees"
export const NOTIF_CONFIG_POLICY = "KudzuPrivacyPolicy"
export const NOTIF_CONFIG_SMS_SERVICE = "SMSTermsofService"
export const NOTIF_CONFIG_BANCORP = "BancorpPrivacyNotice"
export const NOTIF_MANAGE_SAVINGS_HABIT = "manage-savings-habits"
export const NOTIF_SPENDSENSE_ALERT = "spendsense-alerts"
export const NOTIF_SPENDSENSE_ALERT2 = "SPENDSENSE_ALERT"
export const NOTIF_COVERED_BY_KUDZU_TERMS = "covered-by-kudzu-terms"
export const NOTIF_COVERED_BY_KUDZU_OPT_IN = "covered-by-kudzu-opt-in"
export const NOTIF_PERSONAL_INFO = "personal_info"
export const NOTIF_PAYPERKS = "payperks"
export const NOTIF_MANAGE_DIRECT_DEPOSIT = "DDA"

//https://maps.googleapis.com/maps/api/place/details/json?place_id={PLACE_ID}&key=" + GOOGLE_PLACES_API_KEY
export const CHECKING = 'Checking';
export const SAVINGS = 'Savings';
export const SPENDING = 'Spending';
export const STATUS_SUCCESS_CODE = 2000;
export const STATUS_SUCCESS_CODE_PDF_DOWNLOAD = 200;
export const LOGIN_TOKEN = "connect/token";
export const POST_REGISTER_FCM_TOKEN = "/api/cardholders/push-notifications/register";
export const POST_LOGIN = "api/cardholders/post-login?";
export const GENERATE_OTP = "api/cardholders/mobile/generate-otp";
export const TWO_AUTH_VERIFY_OTP = "api/cardholders/mobile/verify-otp";
export const SIGNUP_PHN_NEW_ACCOUNT = '/api/v1/users/create-new-account';

//unviersity apis
 export const STUDENT_SIGNUP_EMAIL_ACCOUNT = (userId) => `api/v1/users/${userId}/students/emails`; 
 export const STUDENT_SIGNUP_VERIFY_NEW_ACCOUNT = (userId, sessionId) => `api/v1/users/${userId}/students/emails/${sessionId}/verify`;
 export const STUDENT_RESEND_EMAIL_CODE= (userId) => `api/v1/users/${userId}/students/emails/re-send`; 



 //
//  export const STUDENT_SIGNUP_UNIVERSITY_SELECTION = (searchText, rows,page) => `api/v1/masters/universities/searchText=${searchText}/rows=${rows}/page=${page}`;
 export const STUDENT_SIGNUP_UNIVERSITY_SELECTION = (searchText, rows,page) => `api/v1/masters/universities/${searchText}/${rows}/${page}`;
                                                 

 

export const SIGNUP_VERIFY_NEW_ACCOUNT = (userId, sessionId) => `api/v1/users/${userId}/phone-numbers/${sessionId}/verify`;
export const EMAIL_OTP_GENERATION = "/api/v1/users/";
export const GENERATE_OTP_FORGOT_PASSWORD = "api/v1/users/generate-otp/forgot-password"
export const RESEND_OTP = (userid) => `api/v1/users/${userid}/phone-numbers/re-send/forgot-password`;
export const VERIFY_OTP = (userid, sessionid) => `api/v1/users/${userid}/sessions/${sessionid}/verify/forgot-password`;
export const UPDATE_PASSWORD = (userid, sessionId) => `api/v1/users/${userid}/sessions/${sessionId}/update-password`;
export const META_DATA_POLICIES = '/api/masters/registration/metadata/v3?';
export const GOOGLE_PLACES_AUTO_COMPLETE = (TEXT) => `/maps/api/place/autocomplete/json?input=${TEXT}&key=${GOOGLE_PLACES_API_KEY}&language=en&components=country:us`;
export const GOOGLE_PLACE_DETAILS_WITH_PLACE_ID = (PLACE_ID) => `/maps/api/place/details/json?place_id=${PLACE_ID}&key=${GOOGLE_PLACES_API_KEY}`;
export const PRIVACY_POLICY = (userId, applicationId) => `/api/v1/users/${userId}/programs/content?contentType=PrivacyPolicyV2&language=en&applicationId=${applicationId}&accountType=0`;
export const USER_AGGREMENT = (userId, applicationId) => `/api/v1/users/${userId}/programs/content?contentType=TnC&language=en&applicationId=${applicationId}@ist&accountType=0`;
export const DEPOSIT_AGGREMENT = (userId, applicationId) => `/api/v1/users/${userId}/programs/content?contentType=DepositTerms&language=en&applicationId=${applicationId}@ist&accountType=0`;
export const SAVINGS_AGGREMENT = (userId, applicationId) => `/api/v1/users/${userId}/programs/content?contentType=TnC&language=en&applicationId=${applicationId}@ist&accountType=32`;
export const ELECTRONIC_AGGREMENT = (userId, applicationId) => `/api/v1/users/${userId}/programs/content?contentType=ESign&language=en&applicationId=${applicationId}@ist&accountType=0`;
export const VALIDATE_ALL_DETAILS_OF_USER = (userId) => `/api/v1/users/${userId}/user-info/validate`;
export const REGISTER_USER = (userId) => `/api/v1/users/${userId}/registration`;
export const PROGRAM_CONTENT = "/api/masters/program-content"
export const SIGN_UP_FEATURE = "/api/v1/users/feature"
export const VERIFY_ACCESS_CODE = "/api/v1/users/signup/access-code"
export const CAN_CREATE_SAVINGS_ACC =(cardkey)=> `api/cardholders/cards/${cardkey}/savings/cancreate`


////CONATCT US
export const GET_ESIGN_STATUS = (cardKey) => `/api/cardholders/policy/latest?CardKey=${cardKey}&contentType=Esign`;
export const GET_ESIGN_AGREEMENT = (cardKey) => `/api/cardholders/policy/pending-to-accept?CardKey=${cardKey}&contentType=Esign`;
export const ACCEPT_E_SIGN_AGGREMENT = (cardKey) => `/api/cardholders/policy/opt-out?CardKey=${cardKey}`;
export const CREATE_TICKET = (cardKey) => `/api/cardholders/${cardKey}/create/ticket`;


///profile get info
export const GET_USER_PORFIE_INFO = (cardKey) => `/api/cardholders/profile?CardKey=${cardKey}`;


//var finalUrl = GET_COMPLETED_AND_PENDING_TRANSACTIONS + "minAmount=" + minAmount + "&maxAmount=" + max_amount + "&debits=" + showSpends + "&credits=" + showLoads + '&fromDate=' + fromDate + '&toDate=' + toDate + '&cardKey=' + cardKey + '&rowsPerPage=10' + '&page=' + currentPage + '&statusCode=STL,APP'

export const TRANSACTIONS = (cardkey, debits, credits, minAmount, maxAmount, fromDate, toDate, rowsPerPage, page,SearchV) => `/api/cardholders/transactions/v2/?cardKey=${cardkey}&debits=${debits}&credits=${credits}&minAmount=${minAmount}&maxAmount=${maxAmount}&fromDate=${fromDate}&toDate=${toDate}&rowsPerPage=${rowsPerPage}&page=${page}&statusCode=STL,APP&search=${SearchV}`;
export const UPCOMINGTRANSACTIONS = (cardkey, debits, credits, minAmount, maxAmount, rowsPerPage, page,SearchV) => `/api/cardholders/upcoming/transactions/?cardKey=${cardkey}&debits=${debits}&credits=${credits}&minAmount=${minAmount}&maxAmount=${maxAmount}&rowsPerPage=${rowsPerPage}&page=${page}&statusCode=STL,APP&search=${SearchV}`;
export const GETTRANSACTIONDETAILS = (cardkey,transactionId) => `api/cardholders/upcoming/transaction/${transactionId}/details?cardKey=${cardkey}`;
export const GET_DDA_DETAILS = '/api/cardholders/dda?cardKey=';
export const GET_DDA_DOWNLOAD_URL = '/api/cardholders/dda/pdf?CardKey=';
export const GET_RELOAD_DETAILS = '/api/reload-cash';
export const GET_NOTIFICATIONS = (cardKey, isRead, isUnread, rowsPerPage, page) => `/api/cardholders/alerts/v2?CardKey=${cardKey}&read=${isRead}&unread=${isUnread}&rowsPerPage=${rowsPerPage}&page=${page}`;
export const NOTIFICATION_READ_ALERTS = "/api/cardholders/alerts/read";
export const NOTIFICATION_UNREAD_ALERTS = "/api/cardholders/alerts/unread";
export const NOTIFICATION_UNREAD_READ_ALERTS = (cardKey, isReadAll) => `/api/cardholders/alerts/${cardKey}/${isReadAll}`;
export const GET_NOTIFICATION_SETTINGS = (cardKey) => `/api/cardholders/notifications?cardKey=${cardKey}`;
export const UPDATE_NOTIFICATION_SETTINGS = (cardKey) => `/api/cardholders/notifications?cardKey=${cardKey}`;

export const UPDATE_CARDNAME = "/api/cardholders/cards/update-nickname/";
export const GET_USED_AMOUNT_DETAILS = "api/cardholders/spendamount?cardKey="

//ACHA
export const GET_ACCOUNT_TYPES = "/api/v1/users/cards/{cardKey}/accountTypes"
export const ADD_ACH_ACCOUNT = "/api/v1/users/addACHAccount"
export const GENERATE_OTP_ADD_ACH_ACCOUNT = '/api/v1/users/cards/{CARDKEY}/generate-otp/add-achAccount'
export const VERIFY_OTP_ADD_ACH_ACCOUNT = '/api/v1/users/verify/add-achAccount'
export const RESEND_OTP_ADD_ACH_ACCOUNT = '/api/v1/users/cards/{CARDKEY}/re-send/add-achAccount'
export const GET_ACCOUNTS_FOR_TRANSFER = '/api/v1/users/cards/{CARD_KEY}/accounts'
export const TRANSFER_FUNDS = "/api/v1/users/cards/{CARD_KEY}/accounts/transfer-funds"
export const GET_HOLIDAYS = "/api/v1/masters/holidays"
export const CANCEL_TRANSFER = "/api/v1/users/cards/{CARD_KEY}/transactions/cancel"
export const VERIFY_EXTERNAL_ACCOUNT = "/api/v1/users/cards/{cardKey}/accounts/verify"
export const REMOVE_EXTERNAL_ACCOUNT = "/api/v1/users/remove-ACHAccount"
export const GENERATE_EXISTING_PHONE_EMAIL_OTP = "/api/v1/users/phonenumbers/generate-otp"
export const VERIFY_OTP_EXISTING_PHONE_EMAIL_NUMBER = "/api/v1/users/phoneNumbers/verify-otp"
export const RESEND_EXISTING_PHONE_EMAIL_NUMBER = "/api/v1/users/phonenumbers/re-send"
export const GET_FREQUENCIES = "/api/cardholders/cards/{CARD_KEY}/frequencies"
export const TINK_LINK_ENABLED_CHECK = "/api/cardholders/cards/tink"



//utilities
export const LOCK_CARD_API = "/api/cardholders/cards/suspend";  // lock api
export const UNLOCK_CARD_API = "/api/v1/users/cards/verify/unsuspend";  // unlock api
//Replace Card
export const GET_SHIPPING_ID = '/api/masters/programs/shipping-types?cardKey='; //  to get customer support number
export const REPORT_LOST_CARD_API = "/api/cardholders/cards/lost-stolen";
export const REPLACE_CARD_API = "/api/cardholders/cards/replace";
export const GET_ADD_NEW_CARD_METADATA = '/api/cardholders/secondary-cards/new/metadata?'; // to get address details for cardholder
//Change PIN
export const GENERATE_OTP_CHANGE_PIN = "/api/v1/users/generate-otp/change-pin";
export const VERIFY_CHANGE_PIN = "/api/v1/users/verify/change-pin";
export const RESENT_OTP_CHANGE_PIN = "/api/v1/users/phone-numbers/re-send/change-pin";
export const GET_PIN_CHANGE_DETAILS = "/api/cardholders/cards/change-pin";
//View Card
export const GENERATE_OTP_VIEW_CARD = "/api/v1/users/generate-otp/card-details";
export const RESEND_OTP_VIEW_CARD = "/api/v1/users/phone-numbers/re-send/card-details";
export const VERIFY_OTP_VIEW_CARD = "/api/v1/users/verify/card-details"
export const GET_CARD_DESIGN = '/api/cardholders/cards/designs?ids=';
//Activate Card
export const RESEND_ACTIVATE_CARD_OTP = "/api/v1/users/phone-numbers/re-send/activate-card"
export const GENERATE_ACTIVATE_CARD_OTP = "/api/v1/users/generate-otp/activate-card"
export const ACTIVATE_CARD_INLIFE = "/api/cardholders/cards/activate-inlife/v4"
export const VERIFY_OTP_ACTIVATE_CARD = '/api/v1/users/verify/activate-card'

//Unlock/UnSuspend Card
export const RESEND_UNLOCK_CARD_OTP = "/api/v1/users/cards/{cardKey}/re-send/unsuspend"
export const GENERATE_UNLOCK_CARD_OTP = "/api/v1/users/cards/{cardKey}/generate-otp/unsuspend"
export const VERIFY_OTP_UNLOCK_CARD = '/api/v1/users/cards/verify/unsuspend'


// export const GET_NOTIFICATION_SETTINGS = "/api/cardholders/notifications?";

//user profile
export const GET_USER_PROFILE_V2 = "/api/cardholders/profile/v2?";
export const GET_USER_PROFILE_LANGUGAGE_LIST = '/api/cardholders/masters/languages?'
export const GET_USER_PROFILE_TIMEZONE_LIST = '/api/cardholders/masters/time-zones?'


export const UPDATE_PREFERRED_NAME =(cardKey) => `api/cardholders/cards/${cardKey}/update-preferredname`
export const UPDATE_PHONE_NUMBER = "/api/v1/users/generate-otp/mobile-update"
export const RESEND_UPDATE_PHONE_NUMBER = "/api/v1/users/re-send/mobile-update"
export const VERIFY_OTP_UPDATE_PHONE_NUMBER = "/api/v1/users/verify-otp/mobile-update"
export const GENERATE_UPDATE_EMAIL_OTP = "/api/v1/users/generate-otp/email-update"
export const RESEND_UPDATE_EMAIL_OTP = "/api/v1/users/re-send/email-update"
export const VERIFY_UPDATE_EMAIL_OTP = "/api/v1/users/verify-otp/email-update"
export const GENERATE_UPDATE_ADDRESS_OTP = "/api/v1/users/generate-otp/address-update"
export const RESEND_UPDATE_ADDRESS_OTP = "/api/v1/users/re-send/address-update"
// export const VERIFY_UPDATE_ADDRESS_OTP =  "/api/v1/users/verify-otp/address-update"
export const VERIFY_UPDATE_ADDRESS_OTP = "/api/v1/users/address-update"
export const POST_UPDATE_USER_PROFILE = '/api/cardholders/profile/update?'

export const AGREEMENTS = "/api/cardholders/policy/latest?CardKey={cardKey}&contentType={contentType}";
export const SAVINGS_AGREEMENT_POSTLOGIN = "/api/masters/program-content?programKey=200&accountType=32&contentType=TnC&lang=en"
//statements
export const DOWNLOAD_STATEMENT_OF_SELECTED_MONTH = '/api/cardholders/statements/download?';
export const GET_STATEMENTS_SELECTIONS = '/api/cardholders/statements/selections?cardKey=';
//fees
export const GET_FEES_SCHEDULE = "/api/masters/program/fee-details?CardKey=";
//trusted device
export const GET_TRUSTED_DEVICES = "/api/cardholders/devices";
export const UNTRUSTED_DEVICES = "/api/cardholders/devices/{deviceId}/untrust"
//change password 
export const GENERATE_OTP_CHANGE_PASSWORD = "/api/v1/users/cards/{CARD_KEY}/generate-otp/change-password"
export const VERIFY_OTP_CHANGE_PASSWORD = "/api/v1/users/verify/change-password";
export const CHANGE_PASSWORD = "/api/v1/users/change-password";
export const RESEND_CHANGE_PASSWORD_TOP = "/api/v1/users/cards/{cardKey}/re-send/change-password";
//payperks 
export const GET_PAY_PERKS_API = (cardKey) => `/api/cardholders/payperks/details?cardKey=${cardKey}`;
export const GET_PAYPERKS_DASHBOARD = (cardKey) => `/api/cardholders/payperks/dashboard?cardKey=${cardKey}`;
///atm locations
export const ATM_LOCATIONS = (cardKey, lang) => `/api/atm-locations/v2/?cardKey=${cardKey}&language=${lang}`;

//Beneficiaries
export const GET_BENEFICIARIES = "/api/cardholders/cards/{cardKey}/beneficiaries"
export const GET_BENEFICIARY_DETAILS = "/api/cardholders/cards/{cardKey}/beneficiaries/{beneficiaryKey}/details"
export const ADD_BENEFICIARY = "/api/cardholders/cards/{cardKey}/add-beneficiary"
export const UPDATE_BENEFICIARY = "/api/cardholders/cards/{cardKey}/beneficiaries/{beneficiaryKey}/update"
export const REMOVE_BENEFICIARY = "/api/cardholders/cards/{cardKey}/beneficiaries/{beneficiaryKey}/remove"

export const ACCEPT_E_SIGN_AGREEMENT = "api/cardholders/policy/accept?"
export const GET_PENDING_PRIVACY_POLICY = "api/cardholders/policy/pending-to-accept?";

export const VALIDATE_AUTH_USER = "/api/cardholders/cards/{CARD_KEY}/validate-user"
export const GENERATE_OTP_ADD_AUTH_USER = '/api/v1/users/cards/{CARD_KEY}/generate-otp/add-authorizeUser'
export const RESEND_OTP_ADD_AUTH_USER = '/api/v1/users/cards/{CARD_KEY}/re-send/add-authorizeUser'
export const VERIFY_OTP_ADD_AUTH_USER = '/api/v1/users/verify/add-authorizeUser'
export const ADD_AUTH_USER = "/api/cardholders/cards/{CARD_KEY}/add-authorizeuser"
export const GET_AUTH_USERS = "/api/cardholders/cards/{CARD_KEY}/authorized-users"
export const RESEND_INVITE_LINK_AUTH_USER = "/api/cardholders/cards/{CARD_KEY}/authorized-user/resend-link"
export const VALIDATE_INVITE_CODE = "/api/cardholders/validate-inviteCode";

//climb

//export const GET_CLIMB_FEATURES  = (cardKey) => `/api/cardholders/cards/cardKey=${cardKey}/climb/features`;
export const GET_CLIMB_FEATURES = "/api/cardholders/cards/{CARD_KEY}/climb/features"
export const POST_PIN_CHANGE = "/api/cardholder/card/pinchange/commit?";

export const ADD_SAVINGS_HABIT = "/api/cardholders/cards/{CARD_KEY}/savings-habit/add";
export const GET_ALL_SAVINGS_HABIT = "/api/cardholders/cards/{CARD_KEY}/savings-habits";
export const OPT_IN_FEATURE = "/api/cardholders/cards/{CARD_KEY}/climb/{featureType}/opt-in";
export const OPT_OUT_FEATURE = "/api/cardholders/cards/{CARD_KEY}/climb/{featureType}/opt-out";
export const CLIMB_OPT_IN_OUT = "/api/cardholders/cards/{CARD_KEY}/climb/";
export const UPDATE_SAVINGS_HABIT = "/api/cardholders/cards/{CARD_KEY}/savings-habits/{habitId}/update";
export const GET_SAVINGS_HABIT_DETAILS = "/api/cardholders/cards/{CARD_KEY}/savings-habits/{habitId}/details";
export const REMOVE_SAVINGS_HABIT = "/api/cardholders/cards/{CARD_KEY}/savings-habits/{habitId}/remove";


export const GET_DETAILS_FRAUD_ALERT = "api/cardholders/transactions/high-risk/{code}";
export const POST_DETAILS_FRAUD_ALERT = "api/cardholders/transactions/high-risk/identify";
///spend cards
export const ADD_SPENDING_ALERT = (cardKey) => `/api/cardholders/cards/${cardKey}/spending-alerts/add`;
export const GET_ALL_SPEND_ALERTS = (cardKey) => `/api/cardholders/cards/${cardKey}/spending-alerts`;
export const GET_SPEND_ALERT_DETAILS = "/api/cardholders/cards/{CARD_KEY}/spending-alerts/{spendId}/details";
export const UPDATE_SPEND_ALERT = "/api/cardholders/cards/{CARD_KEY}/spending-alerts/{spendId}/update";
export const REMOVE_SPEND_ALERT = "/api/cardholders/cards/{CARD_KEY}/spending-alerts/{spendId}/remove";




// kyc/cip
export const GET_KYC_CIP_ACCESS = "api/cardholders/cip/access"
export const GENERATE_OTP_KYC_CIP = "/api/cardholders/enroll/cip/verify/generate-otp"
export const VERIFY_OTP_KYC_CIP = "/api/cardholders/enroll/cip/verify-otp"

//download transaction history
export const DOWNLOAD_TRANSACTION_HISTORY = "api/cardholders/download/document"


export const GET_CARDHOLDER_CARDS = '/api/cardholders/cards/true/true';
export const END_SESSION = (BASEURL, CLIENT_ID, TOKEN, LOGOUT_REDIDRECT) => `${BASEURL}connect/endsession?client_id_hint=${CLIENT_ID}&token_hint=${TOKEN}&post_logout_redirect_uri=${LOGOUT_REDIDRECT}`;
export const GET_ACTIVITY_PRIVACY_POLICY = 'api/cardholders/policy/latest/';

// export const PAYPERKS_URL="https://payperksb-uat.kudzumoney.com/en/kd/website/dashboard?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk2NjYyNjMxLCJpYXQiOjE2OTY1NzYyMzEsImp0aSI6ImIyMTI5YTVjNWIzNDRhMmI4ZmEwNjM5NDcxZTk1MjMwIiwidXNlcl9pZCI6MTEsInNlc3Npb25faWQiOiIzMjkiLCJzZXNzaW9uX2tleSI6IjExQDE2OTY1NzYyMzEiLCJ1c2VybmFtZSI6ImEyYmRjOTk4LTNmYWMtNDlkMS04ZTI5LTQyZDNmMTk0NTA3YSIsImp3dF9zZWNyZXQiOiI5OWE1N2JhYi1iZDdiLTRiNWQtYjRjZS0wZjdjYmE4MmFhZjAiLCJzaXRlX2lkZW50aWZpZXIiOiJrZCJ9.vmuXX3ACVLPcvhaiTjGgI9qx6iSVH_nich_JuuTJyBY&is_dark_mode=True"
export const APPLE_STORE_URL = "https://apps.apple.com/us/app/kudzu-money/id6449820905"
export const ANDROID_STORE_URL = "https://play.google.com/store/apps/details?id=com.kudzumoneyllc.kudzu"
export const CONTACT_US_URL = "https://www.kudzumoney.com/contact-us"

//Savings validate ssn
export const SAVINGS_VALIDATE_SSN = "/api/cardholders/cards/{cardkey}/validate-ssn"
export const CREATE_SAVINGS_ACCOUNT = "/api/cardholders/create-savings?cardKey={cardkey}"

//cancel upcoming transactions
export const CANCEL_UPCOMING_TRANSACTION ="/api/cardholders/upcoming/transaction/{transactionId}/cancel"

//connect school
 export const GET_STUDENT_UNIVERSITY = (cardKey) => `api/cardholders/${cardKey}/university`;
 export const STUDENT_EMAIL_UNIVERSITY ="api/v1/users/connect-school/generate-otp";
 export const STUDENT_UNIVERSITY_UPDATE = (cardKey) => `api/cardholders/${cardKey}/university`;
 export const STUDENT_VERIFY_ACESSCODE = "api/v1/users/connect-school/verify";
 export const STUDENT_RESENDVERIFY_ACESSCODE = "api/v1/users/connect-school/re-send";



 




