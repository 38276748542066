import React, { useState, useEffect, useRef } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HomeLayout from '../../Layout/HomeLayout';
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../../CustomComponents/DynamicCard';
import FindATM from '../../../assets/images/illo-ATM.png'
import { useNavigate } from 'react-router';
import Accordion from '../../CustomComponents/Accordation';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import { STATUS_SUCCESS_CODE } from '../../../network/URLConstants';
import { getCategories, getfaqsdata } from '../../../utils';
import { HelpSidebar } from '../../../utils';
import { height, width } from "../../CustomComponents/DynamicCard/DynamicCardStyles";
import FooterContent from '../../CustomComponents/Footer';
import Loader from '../../CustomComponents/Loader';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import logFirebaseEvents from '../../LogFirebaseEvents';

const HelpAndFAQ = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [faqs, setFaqs] = useState({});
    const [expandedIndex, setExpandedIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[14px] md:text-[13px] sm:text-[11px] text-[12px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [type, setType] = useState('')
    const [footerMsg, setFooterMsg] = useState('');
    const [toNavigate, setToNavigate] = useState('');
    // const [isLoading, setIsLoading] = useState(false);

    const cards = useSelector(state => JSON.parse(state.userCards));
    const initialCards = useRef(cards); // Store initial cards

    useEffect(() => {
        if (initialCards?.current?.length > 0) {
            setIsLoading(true)
            const freshDeskDomain = initialCards.current[0].FreshDeskDomain;
            const freshDeskCategoryID = initialCards.current[0].FreshDeskCategoryID;
            const freshDeskAPIKey = initialCards.current[0].FreshDeskAPIKey;

            getCategories(freshDeskDomain, freshDeskCategoryID, freshDeskAPIKey)
                .then(response => {
                    // debugger;
                    setCategories(response);
                    setExpandedIndex(0);
                    loadFaqs(response[0].id, freshDeskDomain, freshDeskAPIKey);
                })
                .catch(error => {
                    console.error("Error fetching categories:", error);
                });
        }
    }, []);

    const handleAccordionClick = (index, categoryId) => {
        setExpandedIndex(index);
        if (categoryId) {
            loadFaqs(categoryId, cards?.[0]?.FreshDeskDomain, cards?.[0]?.FreshDeskAPIKey);
        }
    };

    const loadFaqs = (categoryId, domain, apiKey) => {

        if (!faqs[categoryId]) {
            getfaqsdata(domain, categoryId, apiKey, "100")
                .then(response => {
                    setFaqs(prevFaqs => ({
                        ...prevFaqs,
                        [categoryId]: response,
                    }));
                    setIsLoading(false)

                })
                .catch(error => {
                });
        }
    };


    const Headingcom = () => {
        return (
            <>
                <div className="relative">
                    <div className="flex flex-row justify-between m-10">
                        <div className="flex flex-row gap-2 ">
                            <p className='font-hanken-grotesk font-[600] text-[15px] leading-[23px] lg:font-[500] lg:text-[26px] lg:leading-[36px]'>{t("helpfaq")}</p>
                        </div>
                    </div>
                </div>
                <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4'>

                </div>
            </>

        )
    }
    const helpListItems = 'font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px] text-[#FEFAEE]'


    const leftBarComp = () => {
        return (
            <div className='lg:w-[238px] md:w-[187px] sm:w-[157px]'>
                <p className="text-white font-normal
            lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-serif-pro border-b-[3px]
     border-[#4B70B9] lg:pb-14 md:pb-[50px] sm:pb-[2.6rem] mb-10">{t("helpHeading")}</p>

                <div className='flex flex-col md:gap-10 gap-8 cursor-pointer'>

                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#FEFAEE]' onClick={() => navigate("/Help")}>{t("aboutKudzu")}</p>
                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#94C3E8]' onClick={() => navigate("/Help/FAQ")}>{t("helpfaq")}</p>
                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#FEFAEE]' onClick={() => navigate("/Help/FindATM")}>{t("findATMBtn")}</p>
                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#FEFAEE]' onClick={() => navigate("/Help/ContactUs")}>{t("conatctus")}</p>

                </div>
            </div>
        )
    }

    const helpFaqChildren1 = () => {
        return (
            <>
                <div className="w-full flex flex-row mb-10 lg:mb-32">
                    <div className='flex flex-col w-full sm:px-0 px-4'>

                        <div>
                            {categories.map((category, index) => (
                                <Accordion
                                    key={category.id}
                                    title={category.name}
                                    content={faqs[category.id] || []}
                                    index={index}
                                    categoryId={category.id}
                                    linkClickAction={linkClickAction}
                                    onClick={() => handleAccordionClick(index, category.id)}
                                />
                            ))}
                        </div>
                    </div>
                </div>

            </>
        )
    }


    const footerContent = () => {
        return (
            <FooterContent highlightText={t("findNoFeeATM")}
                subHeaderText={t("findATMSubHeading")}
                image={FindATM} buttonEnabled={true}
                buttonText={t("findATMBtn")}
                footerBtnAction={() => {navigate('/Help/FindATM');logFirebaseEvents("Help", "Find_ATM_Clicked", "Find_ATM_Clicked","", "");}}
                type={t("findNoFeeATM")}
                isSubheadingwidth={false} />
        )
    }

    const linkClickAction = (link) => {

        if (link.link) {

            if (link.link.includes('kudzumoney')) {
                window.open(link.link, '_blank');
            }
            else {
                setToNavigate(link.link)
                showAlertModal(true, t("heads_up"), t("about_to_leave_kudzu"), t("redirected_from_kudzu"), true, true, 'Continue', 'Cancel', true, '')
            }
        }

    }


    //////
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {

        setShowModel(showModel)

        setHighlightMsg(highlightMsg)

        setheaderMsg(headerMsg)

        setSubHeaderMsg(subHeaderMsg)

        setBtn1Exists(btn1Exists)

        setBtn2Exists(btn2Exists)

        setBtn1Text(btn1Text)

        setBtn2Text(btn2Text)

        setBtn1TypeAcpt(btn1TypeAcpt)

        setModelImage(modelImage)

    }

    const closeCustomAlertModal = () => {

        setShowModel(false);

    }

    const onAcptBtnClick = async () => {

        if (headerMsg == t('about_to_leave_kudzu')) { window.open(toNavigate, '_blank'); closeCustomAlertModal(); setToNavigate('') }

    }

    const onCancelBtnClick = () => {

        if (headerMsg == t('about_to_leave_kudzu')) { closeCustomAlertModal(); setToNavigate('') }

    }

    return (
        <>
            <HomeLayout>
                <HelpSidebar HelpselectedIndex={2} />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        footer={true}
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                       // footer={true}
                        HeaderRadius={true}
                        HeaderContent={false}
                        isWhite={true}>
                        <div class="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] md:font-[500]
                       lg:text-[26px] md:text-[22px] 
                       sm:text-[15px] sm:font-[600] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">{t("helpfaq")}</div>
                        <div className='border-t-[2.5px] border-[#A3CEBB]
                        sm:mx-0 mx-4 mb-6'></div>
                        {helpFaqChildren1()}
                    </DynamicCard>
                    {footerContent()}

                </div>


                {/* <div className='flex flex-col justify-center animate__animated animate__fadeInRight animate__slow'>

                <DynamicCard
                    isCardHeader={true}
                    cardHeight='content-fit'
                    cardContent={helpFaqChildren1()}
                    isCardFooter={false}
                    headerContent={Headingcom()}
                    headerBg={'#ffffff'}

                />
                <DynamicCard
                    isCardHeader={false}
                    cardHeight='content-fit'
                    cardContent={helpFaqChildren2()}
                    isCardFooter={false}
                    cardCornerType='tl-br'
                    cardBackGround="bg-[#E0E9EE]" 
                />

            </div> */}
                {/* {isLoading && <Loader />} */}

            </HomeLayout >
            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
            />

            {isLoading && <Loader />}
        </>


    )
}

export default withOidcSecure(HelpAndFAQ)