
import styled from "styled-components";
import tw from "twin.macro";

const primaryFont = "Source Serif Pro, serif";
const secondaryFont = "Hanken Grotesk, sans-serif";
//font-hanken-grotesk 

const fontSizes = {
  large: "20px",
  medium: "17px",
  small: "15px",
  semiSmall: "14px",
};

const fontWeight = {
  bold: "700",
  normal: "400",
}

export const SocialLoginButtons = styled.div`
  ${tw`flex flex-col  w-full sm:mt-[6rem] mt-[2.5rem]`}
 `;

export const LoginButtonImage = styled.img`${tw`w-auto h-auto mr-[4px]`}`;

export const GoogleButton = styled.button`
 ${tw`w-full border-b-0 text-blue-500 border-2 lg:text-[15px] md:text-[16px] sm:text-[14px] text-[15px]
   border-[var(--social-btn-border)] h-[60px] inline-flex justify-center items-center`}
 font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)};
 font-size: ${(props) => fontSizes.small};
  font-weight: ${(props) => fontWeight[props.fontWeight]};

 `;


export const AppleLoginButton = styled.button`${tw`w-full border-b-0 text-blue-500 border-2 
border-[var(--social-btn-border)] h-[60px] inline-flex justify-center items-center 
lg:text-[15px] md:text-[16px] sm:text-[14px] text-[15px]`}
font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)};
font-weight: ${(props) => fontWeight[props.fontWeight]}; 
font-size: ${(props) => fontSizes.small}`;


export const FBLoginButton = styled.button`${tw`w-full border-b-0 text-blue-500 border-2 
border-[var(--social-btn-border)] h-[60px] inline-flex justify-center items-center
lg:text-[15px] md:text-[16px] sm:text-[14px] text-[15px]`}
font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)};
font-weight: ${(props) => fontWeight[props.fontWeight]}; 
font-size: ${(props) => fontSizes.small}`;

export const MicrosoftLoginButton = styled.button`${tw`w-full text-blue-500 border-2 
border-[var(--social-btn-border)] h-[60px] inline-flex justify-center items-center
lg:text-[15px] md:text-[16px] sm:text-[14px] text-[15px]`}
font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)};
font-weight: ${(props) => fontWeight[props.fontWeight]};

font-size: ${(props) => fontSizes.small}`;

export const EmailloginBtn = styled.button`${tw`bg-[var(--email-btn-bg)] mt-[50px] text-blue-500 
  h-[60px] inline-flex justify-center items-center gap-3 lg:text-[15px] md:text-[16px] sm:text-[14px] text-[15px]
`}font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)};
font-weight: ${(props) => fontWeight[props.fontWeight]};

font-size: ${(props) => fontSizes.small}`;

export const EmaillLoginButtonImage = styled.img
  `${tw`md:w-[20.75px] md:h-[13.66px] sm:w-[17.04px] sm:h-[12px] w-[20.75px] h-[13.66px]`}`;







