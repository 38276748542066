import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../CustomComponents/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../../Layout/HomeLayout';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import { ACTIVATE_CARD_INLIFE, GENERATE_ACTIVATE_CARD_OTP, GENERATE_EXISTING_PHONE_EMAIL_OTP, GENERATE_OTP_CHANGE_PIN, GENERATE_OTP_VIEW_CARD, GET_ADD_NEW_CARD_METADATA, GET_CARDHOLDER_CARDS, GET_PIN_CHANGE_DETAILS, GET_SHIPPING_ID, LOCK_CARD_API, POST_LOGIN, POST_PIN_CHANGE, REPLACE_CARD_API, REPORT_LOST_CARD_API, STATUS_SUCCESS_CODE, UNLOCK_CARD_API } from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import { retrieveData } from '../../../../helpers/utils/Utils';
import Custom2FA from '../../../CustomComponents/Custom2FA';
import Modal from '../../../CustomComponents/Modal';
import { LOGIN_SESSION_KEY } from '../../../../Redux/reducers';
import CustomTextInput from '../../../CustomComponents/CustomtextInput';
import Moment from 'react-moment';
import { addLineBreaks, displayErrorMessage } from '../../../../utils';
import CardMenu from '../../CardMenu';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../CustomComponents/Card/CardStyles";



const ActivateCard = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const selectedItem = cards?.[0]
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none text-black";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [modelImage, setModelImage] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [btn1Focus, setBtn1Focus] = useState(false);
    const [btn2Focus, setBtn2Focus] = useState(false);
    const [subFooter, setSubFooter] = useState('');
    const [showValidity, setShowValidity] = useState(false);
    const [activationCodeDetails, setActivationCodeDetails] = useState({ activationCode: '', activationCodeTouched: false, activationCodeValid: false })
    const location = useLocation();
    const [setupPinURL, setSetupPinURL] = useState('');
    const [showSetupPinWebPage, setShowSetupPinWebPage] = useState(false);
    const [showActivateCard, setShowActivateCard] = useState(false);
    const [UpdatedArray, setUpdatedArray] = useState(useSelector(state => JSON.parse(state.userCards)));
    const [infoMessage, setinfoMessage] = useState('');
    const [showResponse, setShowResponse] = useState(false);
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);





    const userId = useSelector(state => state.userId);
    const sessionKey = useSelector(state => state.loggedSessionKey);

    const params = location.state;
    const cardDetails = useSelector(state => state.selectedCardDetails);


    useEffect(() => {
        // if (!twoFactAuthenticationDone) { generate2FAOtp(); }
        // else { 
        setShowActivateCard(true)



        //  }

    }, []);
    useEffect(() => {
        if (activationCodeDetails.activationCodeTouched) {
            if (activationCodeDetails.activationCode.length == 5) {
                setActivationCodeDetails(prevState => ({ ...prevState, activationCodeTouched: true, activationCodeValid: true }))
                setShowValidity(true)
            } else {
                setActivationCodeDetails(prevState => ({ ...prevState, activationCodeTouched: true, activationCodeValid: false }))
                setShowValidity(true)
            }
        }
    }, [activationCodeDetails.activationCode]);
    useEffect(() => {
        // Listen for messages from the embedded iframe
        const handleMessage = (event) => {
            if (event.data == 0) {
                setSetupPinURL('')
                // updateCardStatus(selectedItem.CardKey, responseObj.Response)
                setShowSetupPinWebPage(true);
                callPostPinChangeApi(event.data)
                // Handle success case
            } else if (event.data == -1) {
                setShowSetupPinWebPage(true);
                setSetupPinURL('')
                callPostPinChangeApi(event.data)
                // Handle failure case
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            // Clean up the event listener
            window.removeEventListener('message', handleMessage);
        };
    }, []);


    useKeyDown(() => { }, ["Enter"]);
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1Focus, btn2Focus) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
        setBtn1Focus(btn1Focus)
        setBtn2Focus(btn2Focus)
    }
    const handleCardAction = (type) => {
        if (type == t('lock_card')) {
            showAlertModal(true, t("are_you_sure"), t("this_card_will_be_locked"), t('once_you_lock_your_card_any_new_transactions_will_be_declined'), true, true, t('lock_card'), t("cancel"), true, require('../../../../assets/images/lock_green.png'), '', false, true, false, true)
        } else if (type == t('unlock_card')) {
            showAlertModal(true, t("ready_to_unlock_questionmark"), t("unlock_your_card_to_resume_normal_use"), t('once_you_unlock_your_card_all_locked'), true, true, t('unlock_card'), t("cancel"), true, require('../../../../assets/images/unlock_green.png'), '', false, true, true, false)
        } else if (type == t('replace_card')) {
            navigate('/CardServices/CardActionScreen/ReplaceCard', { state: { item: cardDetails } })
        } else if (type == t('change_pin')) {
            navigate('/CardServices/CardActionScreen/ChangePin', { state: { item: cardDetails } })
        } else if (type == t('view_card_details')) {
            navigate('/CardServices/CardActionScreen/ViewCard', { state: { item: cardDetails } })
        } else if (type == t('activate_card')) {
            navigate('/CardServices/CardActionScreen/ActivateCard', { state: { item: cardDetails } })
        }

    }
    const onAcptBtnClick = () => {
        if (btn1Text == t("lock_card")) {
            callApiToLockCard()
        } else if (btn1Text == t("unlock_card")) {
            callApiToUnlockCard()
        } else if (btn1Text == t('back_to_card_services')) {
            navigate('/CardServices/CardActionScreen')
        } else if (btn1Text == t('home')) {
            navigate('/home')
        }
    }
    const onCancelBtnClick = () => {
        if (btn2Text == 'Cancel') {
            setShowModel(false)
        } else if (btn2Text == t('back_to_card_services')) {
            navigate('/CardServices/CardActionScreen')
        }
    }
    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }
    const closeModel = () => {
        if (showModel && (btn1Text == t('back_to_card_services') || btn2Text == t('back_to_card_services'))) { navigate('/CardServices') } else { setShowModel(false); }
    }
    const verificationSuccess = () => {
        setShowActivateCard(true)
        setShowResponse(false); setShowSetupPinWebPage(false);
    }
    const generate2FAOtp = async () => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        const requestObject = {
            cardKey: cards !== null ? cards[0].CardKey : 0
        };

        const url = GENERATE_ACTIVATE_CARD_OTP;
        setLoading(true);

        try {
            const response = await PostRequest(url, requestObject, headers);
            setLoading(false);

            const parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode === STATUS_SUCCESS_CODE) {
                //adding sessionid to redux
                const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                //adding userid to redux
                const userIdPayload = { userId: parsedResponse.Response.userId }
                dispatch({ type: 'USER_ID', payload: userIdPayload });
                setTimeout(() => {
                    setIsOTPVerifyModalOpen(true);
                }, 1000);
            } else {
                const arrMessages = parsedResponse.Messages;
                const message = arrMessages[0];
                const displayText = message.DisplayText;
                setShowActivateCard(false)
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)

                // Handle the error message or displayText as needed
            }
        } catch (error) {
            // Handle errors from the API request
            console.error('Error fetching data:', error);
        }
    };
    const callApiToLockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails !== null ? cardDetails.CardKey : 0
        }
        PostRequest(LOCK_CARD_API, object, headers).then((response) => {

            setTimeout(() => {
                setLoading(false)
            }, 500);

            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {

                setTimeout(() => {
                    updateCardStatus(cardDetails !== null ? cardDetails.CardKey : 0, responseObj.Response)
                    showAlertModal(true, t("done_exclamation"), t("this_card_is_now_locked"), t('any_new_tx_will_be_declined'), true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/lock_green_tick.png'), '', false, false, true, false)
                }, 1000);

                // this.updateCardsInLocal(responseObj.Response, LOCK_CARD)

            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)
                setTimeout(() => {
                }, 1000);

            }

        });

    }
    const callApiToUnlockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails.CardKey
        }

        PostRequest(UNLOCK_CARD_API, object, headers).then((response) => {
            setTimeout(() => {
                setLoading(false)
            }, 500);
            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                setTimeout(() => {
                    updateCardStatus(cardDetails.CardKey, responseObj.Response)
                    showAlertModal(true, t("success_heading"), t("this_card_is_now_unocked"), t('you_can_use_your_card'), true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/unlock_green_tick.png'), '', false, false, true, false)
                }, 1000);
                // this.updateCardsInLocal(responseObj.Response, UNLOCK_CARD)
            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)
                setTimeout(() => {
                }, 1000);
            }
        });
    }
    async function callPostLoginAPI(isTrusted) {

        setLoading(true);

        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        const response = await GetRequest(GET_CARDHOLDER_CARDS, headers)
        setTimeout(() => {
            setLoading(false);
        }, 500);



        var responseObj = JSON.parse(response)

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {

            if (responseObj.Response != undefined && responseObj.Response != null && responseObj.Response.length > 0) {

                const userCardsPayload = { userCards: JSON.stringify(responseObj.Response) };
                dispatch({ type: 'UPDATE_USER_CARDS', payload: userCardsPayload });
                dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: responseObj.Response[0] } });

            }

        } else {

            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
        }
    }

    const updateCardStatus = (cardKey, response, type) => {
        const updatedCardIndex = cards?.findIndex(card => card.CardKey === cardKey);
        if (updatedCardIndex !== -1) {
            const updatedCard = {
                ...cards[updatedCardIndex],
                Status: response.Status,
                SubStatus: response.SubStatus,
                StatusCode: response.StatusCode,
                SubStatusCode: response.SubStatusCode,
            };
            if (type == t('activate_card') && response.CardDisplayNumber != undefined && response.CardDisplayNumber != null) { updatedCard.CardDisplayNumber = response.CardDisplayNumber }
            if (type == t('activate_card')) {
                updatedCard.CardKey = response.CardKey;
            }
            const updatedArray = [updatedCard, ...cards.filter(card => card.CardKey !== cardKey)]
            setUpdatedArray(updatedArray);
            dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedArray) } });
            dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: updatedCard } });

        }
    };
    const handleNext = async () => {
        if (activationCodeDetails.activationCode.length < 5) {
            setinfoMessage('Enter a valid activation code')
        } else {

            const deviceId = await retrieveData('updatedDeviceId');
            const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

            var object = { Code: activationCodeDetails.activationCode, Cardkey: selectedItem.CardKey }
            var url = ACTIVATE_CARD_INLIFE
            setLoading(true)

            var response = await PostRequest(url, object, headers)
            let parsedResponse = JSON.parse(response);
            setLoading(false)

            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {

                // updateCardStatus(selectedItem.CardKey, responseObj.Response,t('activate_card'))

                setSetupPinURL(parsedResponse.Response.PinChangeDetails.URL + '/' + parsedResponse.Response.PinChangeDetails.SubmitterID + '/' + parsedResponse.Response.PinChangeDetails.Token)
                if (parsedResponse.Response.PinChangeDetails.URL) {
                    setShowSetupPinWebPage(true);
                }
                await callPostLoginAPI()


            } else {
                var arrMessages = parsedResponse.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                setShowSetupPinWebPage(false);
                setinfoMessage(displayText)
                setinfoMessage(displayText)
            }
        }

    }

    async function callPostPinChangeApi(status) {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        const url = POST_PIN_CHANGE + "cardKey=" + cardDetails?.CardKey + "&language=en";

        const body = {
            Success: status == 0 ? true : false,
            ProviderResponse: status == 0 ? "200-0" : ("400-" + status)
        }

        const response = await PostRequest(url, body, headers)
        const responseObj = JSON.parse(response)

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            if (responseObj.Response.Success) {
                showAlertModal(true, t("perfect_exclimation"), addLineBreaks(t("your_4_digit_pin_has_been_set_you_may_now")), '', true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/activate_change_lock.png'), '', false, false, true, false)
            } else {
                showAlertModal(true, t("oops_exclamation"), '', 'pin Setup Failed', true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)
            }

        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)

        }
    }


    return (
        <HomeLayout>
            <CardMenu handleCardAction={handleCardAction} />
            <div className='md:w-[150px] sm:w-[30px] w-[0px]'></div>
            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight '>
                {showActivateCard &&
                    <DynamicCard
                        header={false}
                        CardRadius={true}
                        cardWidth={width.allpolicies}
                        HeaderRadius={true}
                        isWhite={true}
                        HeaderContent={false}>
                        {!showSetupPinWebPage &&
                            <>
                                <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
 lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px] font-hanken-grotesk 
 sm:mx-0 mx-4">{t("activate_card")}</div>
                                <div className="bg-[#A3CEBB] h-0.5 min-h-1 mt-4 sm:mx-0 mx-4 "></div>

                                <div className='flex flex-col items-between self-between 
                                justify-between w-full h-full pb-8'>
                                    <div className='flex flex-col w-full h-auto'>

                                        <p className='mt-4 font-serif-pro modal-title 
                                           lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px]
                                            sm:leading-[30px] leading-[37px] text-black 
                                           sm:mx-0 mx-4'>{t('Let’s_activate_your_new_card')}</p>

                                        <p className='lg:w-[492px] md:w-[415px] sm:w-[330px] mt-4 font-hanken-grotesk modal-title 
                                         lg:text-[20px] md:text-[18px]
                                             sm:text-[14px] text-[16px] lg:leading-[30px] md:leading-[30px]
                                             sm:leading-[26px] leading-[24px]
                                             font-normal text-black cursor-pointer pointer-events-none sm:mx-0 mx-4'>
                                            {t('you_can_find_your_cards_activation_code_activation')}</p>


                                        <div className='flex flex-col w-full sm:w-3/5 self-center justify-center'>
                                            <div className='sm:w-1/2 flex lg:my-20 md:my-18 md:my-10 sm:my-6 mt-10 mb-20'>
                                                <CustomTextInput autoFocus={true} placeholder={t("activation_code")}
                                                    showValidity={showValidity}
                                                    valid={activationCodeDetails.activationCodeValid}
                                                    onChange={(event) => {
                                                        if (event.target.value.toString().length < 6) {
                                                            setActivationCodeDetails(prevState =>
                                                            ({
                                                                ...prevState, activationCode: event.target.value.toString(),
                                                                activationCodeTouched: true
                                                            }))
                                                        };
                                                    }}
                                                    value={activationCodeDetails.activationCode} />
                                            </div>
                                        </div>
                                        {infoMessage &&
                                            <div className='mb-2 sm:w-3/5 self-center justify-center'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        }
                                    </div>

                                    <div className='flex flex-row justify-between h-auto mb-10'>
                                        <div className='mt-10'>
                                            <span className='font-hanken-grotesk modal-title lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] leading-[26px] sm:leading-[13px] font-normal text-black spanointer-events-none cursor-default'>
                                                {t("need_help_questionmark")} {t('reach_out_to')}  <button className=' lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] leading-[26px]  sm:leading-[13px] font-hanken-grotesk text-[#2965FB] 
                                                font-semibold cursor-pointer' onKeyDown={(event) => {
                                                        if (event.key == "Enter") {
                                                            event.preventDefault();
                                                            navigate('/Help/ContactUs_2'); event.target.style.opacity = 0.8;
                                                            setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                                        }
                                                    }}
                                                    onClick={(event) => {
                                                        event.target.style.opacity = 0.8; navigate('/Help/ContactUs');
                                                        setTimeout(() => { event.target.style.opacity = 1; },
                                                            200);
                                                    }} style={{}}> {t('customer_care')}!</button></span>
                                        </div>
                                        <button className='font-hanken-grotesk mt-10 text-[#2965FB] lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] leading-[26px]  sm:leading-[13px]
                                         font-semibold cursor-pointer' onKeyDown={(event) => {
                                                if (event.key == "Enter") {
                                                    event.preventDefault();
                                                    handleNext();
                                                }
                                            }} onClick={(event) => { handleNext() }}
                                            style={{ opacity: activationCodeDetails.activationCodeValid ? 1 : 0.5 }}>{t('next')}
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                        {showSetupPinWebPage &&
                            <div>
                                {setupPinURL && <iframe
                                    src={setupPinURL}
                                    title="Setup Pin Page" height="870px"></iframe>}
                            </div>
                        }

                    </DynamicCard>
                }
            </div>

            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={closeOTPVerifyModal}
                isLogo={false}
                subHeaderMsg={<p className="font-hanken-grotesk modal-title lg:text-[18px] md:text-[17px] sm:text-[15px] text-[14px] font-normal cursor-pointer pointer-events-none">{t("standard_msg_and_data_rates_may_apply")}</p>}
                title={<p className={HighlightMsgStyles}> {t("security_check_custom2fa")}</p>}
                subtitle={<p className={HeaderMsgStyles}>{t("we_are_sending_text")}</p>}
                content={<div className="mt-10"><Custom2FA type={t("activate_card")} onClose={closeOTPVerifyModal} verificationSuccess={verificationSuccess} verificationFailure={() => { }} /></div>}
            />
            {showModel &&
                <CustomAlertModel
                    isOpen={showModel}
                    onClose={closeModel}
                    image={modelImage}
                    highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                        <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                    // highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                    // HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                    subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                    btn1Exists={btn1Exists}
                    btn2Exists={btn2Exists}
                    btn1Text={btn1Text} btn2Text={btn2Text}
                    btn1TypeAcpt={btn1TypeAcpt}
                    verificationFailure={() => { }}
                    footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                    onAcptBtnClick={onAcptBtnClick}
                    subFooter={<p className={SubHeaderMsgStyles}>{subFooter}</p>}
                    onCancelBtnClick={onCancelBtnClick}
                    showErrorImg={showErrorImg}
                    isBorderNone={isBorderNone}
                    btn1Focus={btn1Focus}
                    btn2Focus={btn2Focus}
                />
            }
            {loading && <Loader />}
        </HomeLayout>
    )
}

export default withOidcSecure(ActivateCard);
