import React, { useEffect, useState } from 'react'
import Help from "../../../../assets/images/Help.png"
import CheckboxLight from "../../../../assets/images/Checkbox-light.png"
import CheckboxBlue from "../../../../assets/images/Checkbox-blue.png"
import CustomTooltip from '../../../CustomComponents/CustomTooltip'
import logFirebaseEvents from '../../../LogFirebaseEvents'


const NotificationConfigureTableItem = ({ notificationSettingItem, updateConfigObjectByAlertCode, isReset }) => {
    // debugger;
    const { AlertCode, Category, Description, Email, SMS, Title } = notificationSettingItem;
    const [emailAndSms, setEmailAndSms] = useState(
        {
            "Email": Email,
            "SMS": SMS
        });

    useEffect(() => {
        setEmailAndSms({
            "Email": Email,
            "SMS": SMS
        })
    }, [isReset])

    const handleEmailClick = () => {
        logFirebaseEvents("Configure_Notification", "Email_Notification_Clicked", "Email_Notification_Clicked", "","")
        setEmailAndSms({ ...emailAndSms, "Email": !emailAndSms.Email })
        updateConfigObjectByAlertCode(AlertCode, { ...emailAndSms, "Email": !emailAndSms.Email })
    }
    const handleSMSClick = () => {
        logFirebaseEvents("Configure_Notification", "Text_Notification_Clicked", "Text_Notification_Clicked", "","")
        setEmailAndSms({ ...emailAndSms, "SMS": !emailAndSms.SMS })
        updateConfigObjectByAlertCode(AlertCode, { ...emailAndSms, "SMS": !emailAndSms.SMS })
    }


    return (
        <div className=' flex flex-row justify-between w-full'>
            <div className=' w-[70%]'>
                <p className='font-hanken-grotesk font-[500] text-[13px] leading-[19px] sm:font-[500] sm:text-[12px] sm:leading-[18px] md:font-[500] md:text-[16px] md:leading-[24px] text-secondary-3'>{Title}</p>
            </div>
            <div className=' flex flex-row justify-between items-center w-[30%]'>

                {Description ?
                    <CustomTooltip text={Description}>
                        <img src={Help} alt="Image" class="object-cover" />
                    </CustomTooltip>
                    :
                    <div className='h-[20px] w-[23px]' />

                }


                <button onClick={handleEmailClick} className=' cursor-pointer'>
                    {emailAndSms.Email ?
                        <div>
                            <img src={CheckboxBlue} alt='help' />
                        </div>
                        :
                        <div>
                            <img src={CheckboxLight} alt='help' />
                        </div>
                    }
                </button>
                <button onClick={handleSMSClick} className=' cursor-pointer'>
                    {emailAndSms.SMS ?
                        <div>
                            <img src={CheckboxBlue} alt='help' />
                        </div>
                        :
                        <div>
                            <img src={CheckboxLight} alt='help' />
                        </div>
                    }
                </button>

            </div>

        </div >
    )
}

export default NotificationConfigureTableItem

