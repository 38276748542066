import React from 'react'
import Card from '../CustomComponents/Card'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { height, width } from "../CustomComponents/DynamicCard/DynamicCardStyles";
import { addLineBreaks } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HomeLayout from '../Layout/HomeLayout';
import LeftBar from '../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../CustomComponents/DynamicCard';
import SettingGreen from '../../assets/images/Settings_green.png'
import Bell from '../../assets/images/Bell.png'
import arrow from '../../assets/images/Caret_S.png'
import { useNavigate } from 'react-router';
import FooterContent from '../CustomComponents/Footer';

// import HomeLayout from '../Layout/HomeLayout';
import HomeHeader from '../CustomComponents/HomeHeader';
import DynamicHomeCard from '../CustomComponents/DynamicHomeCard';
import { useKeyDown } from '../../hooks/useKeyDown';
import logFirebaseEvents from '../LogFirebaseEvents';


const Settings = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const cards = useSelector(state => JSON.parse(state.userCards));

    // const [isLoading, setIsLoading] = useState(false);
    const settingListItem = 'cursor-pointer font-hanken-grotesk font-[600]  lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px] text-[#FEFAEE]';

    const leftBarComp = () => {
        return (
            <div className='md:w-[270px] sm:w-[159px]  w-full sm:px-0 pl-[2.5rem]' >

                <p className="text-white font-normal 
               lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px]
                sm:leading-[30px] leading-[31px]
          font-serif-pro sm:border-b-[2.5px] sm:border-[#4B70B9] lg:pb-14 md:pb-[50px] sm:border-opacity-[50%] border-b-0
          sm:pb-[2.6rem] pb-[2.6rem] mb-10">{t("setting_heading")}</p>

                <div className='flex flex-col md:gap-10 gap-8 sm:pb-0 pb-10'>
                    <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%] ' onClick={() => navigate("/Settings/notificationConfigure")}>
                        <p className={settingListItem}
                            onClick={() => {navigate("/Settings/notificationConfigure"); logFirebaseEvents("Setting", "Configuration_Notification_Clicked", "Configuration_Notification_Clicked", "","");}}>
                            {t("configure_notifications")}</p>
                            <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div>
                    <p className={settingListItem}
                        onClick={() => {navigate("/Settings/ManageAccounts"); logFirebaseEvents("Setting", "Manage_Account_Clicked", "Manage_Account_Clicked", "","");}}>
                        {t("manage_accounts")}</p>
                    {cards != null && cards.length > 0 && cards[0].AllowBeneficiaries &&
                        <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%] ' onClick={() => navigate("/Settings/manageBeneficiaries")}>
                            <p className={settingListItem}
                                onClick={() => navigate("/Settings/manageBeneficiaries")}>
                                {t("manage_beneficiaries")}</p>
                                <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                        </div>}


                    <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%] ' onClick={() => navigate("/Settings/ManageExternalAccounts")}>
                        <p className={settingListItem}
                            onClick={() => {navigate("/Settings/ManageExternalAccounts"); logFirebaseEvents("Setting", "Manage_External_Account_Clicked", "Manage_External_Account_Clicked", "","")}}>
                            {t("manage_external_accounts")}</p>
                            <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div>


                    <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 w-[80%] ' 
                    onClick={() => navigate("/Settings/ConnectSchool")}>
                        <p className={settingListItem}
                            onClick={() => navigate("/Settings/ConnectSchool")}>
                            {/* {t("AccessCode")} </p> */}
                            {t("connect_school")} </p>
                            <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div>



                    {/* <p className={settingListItem}>
                        {t("manage_membership")}</p> */}
                </div>
            </div >
        )
    }

    const settingChildren1 = () => {
        return (
            <>
                <div className="sm:flex block flex-row justify-between  md:py-20 sm:py-8 py-12 md:mx-0 mx-4">
                {/* <div className="w-full sm:flex block flex-row justify-between md:py-20 sm:py-8 py-12 md:mx-0 mx-4"> */}
                    <div className='flex flex-col lg:w-[406px] md:w-[321px] w-[263px]'>

                        <p className="text-[#559C7E] font-normal 
                        leading-[30px] md:leading-[24px] lg:text-[32px] md:text-[30px]
                         sm:text-[18px] text-[25px] lg:leading-[38px] 
                          font-serif-pro">{t("settings")}</p>

                        <p className='mt-4 sm:text-[#0C4437] font-[400px] text-[#262B3A]
                                    md:text-[20px] sm:text-[12px] text-[14px]
                                    md:leading-[30px] sm:leading-[20px] leading-[20px]
                       lg:w-[406px] md:w-[333px] sm:w-[250px]
                      lg:h-[252px] md:h-[175px] h-[170px] sm:font-medium font-hanken-grotesk
                        '>{t("settings_card1_content")}</p>
                    </div>
                    <div className='flex justify-center sm:pb-0 pb-20'>
                        <img src={SettingGreen} alt="setting" className='lg:w-[120px] md:w-[100px] sm:w-[92px] 
                                    lg:h-[120px] md:h-[100px] sm:h-[92px] h-[120px] w-[120px]' />
                    </div>
                </div>

            </>
        )
    }

    useKeyDown(() => {
        navigate("/Settings/notificationConfigure")
    }, ["Enter"])

    const footerContent = () => {
        return (
            <FooterContent highlightText={t("settings_card2_content1")}
                subHeaderText={t("settings_card2_content2")} isSpace={true}
                image={Bell} buttonEnabled={true}
                buttonText={t("configure_notifications")}
                footerBtnAction={() => { navigate("/Settings/notificationConfigure") }}
                isSubheadingwidth={false}
                type={t("settings_card2_content1")}
            />
        )
    }
    return (
        <HomeLayout>
            <LeftBar leftBarType='component' isPrimary={true} leftBarComp={leftBarComp()} />

            <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>

            <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight '>

                <DynamicCard
                    header={true}
                    CardRadius={true}
                    cardWidth={width.homeCard}
                    HeaderRadius={true}
                    HeaderContent={false}
                    isWhite={true}>
                    {settingChildren1()}
                </DynamicCard>
                {footerContent()}
            </div>
        </HomeLayout >



    )
}

export default withOidcSecure(Settings)