import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import unread from "../../../assets/images/unread.png"
import { convertTimestamp } from '../../../utils'
import { useNavigate } from 'react-router'
import { modifyHtmlForNavigation, useNotificationNavigation } from '../../../utils';


const NotificationItem = ({ notification }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isRead, setIsRead] = useState(false);
    const { Content, CreatedDateTime, AlertTypeCode, Unread, ID } = notification;
    const timeStamp = convertTimestamp(CreatedDateTime);
    const modifiedContent = modifyHtmlForNavigation(notification.Content, "#2965FB", notification.Unread);
    const notificationNavigation = useNotificationNavigation();






    const handleItemClick = () => { 
        navigate(`/notifications/${ID}`)
    }


    return (
        <>
            <div className=' flex flex-col' key={ID}>
                <div className=' flex flex-col mt-10 gap-3 cursor-pointer' onClick={handleItemClick}>
                    <div className=' flex flex-row gap-4'>
                        {Unread ?
                            <div className='pt-2'>
                                <img src={unread} className=' h-[10px] w-[10px]' alt="dot" />
                            </div>
                            :
                            <div className='pt-2 w-[10px]'></div>
                        }
                        <div className=' flex flex-col w-[80%] '>
                            <div className=' flex flex-col gap-5 w-[80%] mb-10'>
                                <div className={`font-hanken-grotesk font-[600] md:text-[18px] md:leading-[27px] sm:text-[12px] sm:leading-[20px] text-[13px] leading-[19px] text-[#262B3A] ${!notification.Unread && 'opacity-75'}`}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: modifiedContent }}
                                        onClick={(e) => {
                                            if (e.target.tagName === 'A') {
                                                const href = e.target.getAttribute('href');
                                                notificationNavigation(href, e); // Pass the event object to handleNavigation
                                            }
                                        }}
                                    />
                                </div>
                                <div className=' flex flex-row gap-2'>
                                    {AlertTypeCode === "WRN" &&
                                        <div className=' bg-footer rounded-full py-1 px-3'>
                                            <p className='font-hanken-grotesk font-[700] text-[9px] leading-[15px] md:text-[12px] md:leading-[18px] text-white'>
                                                {t("important")}
                                            </p>

                                        </div>

                                    }
                                    <p className='font-hanken-grotesk font-[500] text-[12px] sm:font-[600]  sm:text-[10px] sm:leading-[15px] md:text-[14px] md:leading-[14px]  text-[#262B3A] opacity-50'>
                                        {timeStamp}
                                    </p>

                                </div>
                            </div>
                            <hr className=" h-[2.5px]  bg-blue-sky border-0 rounded" />

                        </div>

                    </div>

                </div>
                {/* <hr className=" h-[2.5px]  bg-blue-sky border-0 rounded" /> */}

            </div>

        </>
    )
}

export default NotificationItem