 
import { ISDEV_ENV, isDEV } from "./URLConstants";  
import { fetchConfigarationData } from "../helpers/utils/Utils";
import { Base64 } from 'js-base64';
const appConfigData = await fetchConfigarationData();

export async function checkJSON(response) {

    try {
        const responseJson = await response.json();
        return responseJson
    } catch (error) {
        return ""
    }
}

export async function PostRequest(full_url, inputobject, headers) {
    var url = "";

   if (ISDEV_ENV) {
    url = full_url;
    console.log(url + "--->" + JSON.stringify(inputobject) + "headers==>" + JSON.stringify(headers));
   }else{
    url = appConfigData.APP_URL + full_url;
   }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(inputobject),
        });
        if (ISDEV_ENV) {
        console.log(url + "--->" + response.status);
        }
        if (response.status == 200 || response.status == 412) {
            const responseJson = await response.json();
            if (ISDEV_ENV) {
            console.log(url + "--->" + JSON.stringify(responseJson));
            }
            return JSON.stringify(responseJson)
        } else {
            var message = "";
            var responseObj = await checkJSON(response)

            if (responseObj != "") {
                var arrMessages = responseObj.Messages;
                if (arrMessages.length > 0) {
                    var messageObject = arrMessages[0];
                    message = messageObject.DisplayText;
                }
                if (message != "" && message != null && message != undefined) {
                    return constructFailureObject(message);
                }
            }
            if (response.status == 404) {
                return constructFailureObject("No Http resource found");
            } else if (response.status == 401) {
                return constructFailureObject("Unauthorised Request");
            } else if (response.status == 500) {
                return constructFailureObject("Internal Server Error");
            } else if (response.status == 503) {
                return constructFailureObject("Server down");
            } else if (response.status == 504) {
                return constructFailureObject("Request Timed out");
            }
            else {
                // return constructFailureObject(translate('something_went_wrong'));
            }
        }
    } catch (error) {
        if (ISDEV_ENV) {
        console.log(error.message);
        }
        return constructFailureObject(error.message)
    }
}

export function toUrlEncodedString(obj) {
    const keyValuePairs = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            keyValuePairs.push(
                encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
            );
        }
    }

    return keyValuePairs.join('&');
}
export async function PostRequestIdmUrl(full_url, inputobject, headers) {
    var url = appConfigData.AUTH_URL + full_url;
    if (ISDEV_ENV) {
    console.log(url + "--->" + JSON.stringify(inputobject));
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: inputobject
        });
        if (ISDEV_ENV) {
        console.log(url + "--->" + JSON.stringify(response));
        }

        //  console.log(url + "--->" + JSON.stringify(response));

        if (response.status == 200 || response.status == 400) {
            var responseObj = await checkJSON(response)
            if (responseObj != ""){
                if (ISDEV_ENV) {
               console.log(url + "--->" + JSON.stringify(responseObj));
                }
                return JSON.stringify(responseObj);    
            }else{
             return constructFailureObject("502 - Failed to retrieve requested data.")   
            }
        }
        else {
            var message = "";
            var responseObj = await checkJSON(response)

            if (responseObj != "") {
                var arrMessages = responseObj.Messages;
                if (arrMessages.length > 0) {
                    var messageObject = arrMessages[0];
                    message = messageObject.DisplayText;
                }
                if (message != "" && message != null && message != undefined) {
                    return constructFailureObject(message);
                }

            }
            if (response.status == 403) {
                return constructFailureObjectLogin("403 - Your request could not be processed.")
            } else {
                //  return constructFailureObjectLogin(translate('something_went_wrong'))
            }
        }
    } catch (error) {
        if (ISDEV_ENV) {
        console.log(error.message);
        }
        return constructFailureObject(error.message)
    }
}

export async function PostRequestUrlEncoded(full_url, inputobject, headers) {
    var url = appConfigData.AUTH_URL + full_url;
    if (ISDEV_ENV) {
    console.log(url + "--->" + JSON.stringify(inputobject));
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: inputobject
        });
        if (ISDEV_ENV) {
        console.log(url + "--->" + response.status);
        }
        if (response.status == 200 || response.status == 400){
            var responseObj = await checkJSON(response)
            if (responseObj != ""){
                if (ISDEV_ENV) {
                console.log(url + "--->" + JSON.stringify(responseObj));
                }
                return JSON.stringify(responseObj);    
            }else{
             return constructFailureObject("502 - Failed to retrieve requested data.")   
            }
        }
        else {
            var message = "";
            var responseObj = await checkJSON(response)

            if (responseObj != "") {
                var arrMessages = responseObj.Messages;
                if (arrMessages.length > 0) {
                    var messageObject = arrMessages[0];
                    message = messageObject.DisplayText;
                }
                if (message != "" && message != null && message != undefined) {
                    return constructFailureObject(message);
                }

            }
            if (response.status == 403) {
                return constructFailureObjectLogin("403 - Your request could not be processed.")
            } else {
                //   return constructFailureObjectLogin(translate('something_went_wrong'))
            }
        }

    } catch (error) {
        if (ISDEV_ENV) {
        console.log("error", error);
        }
        // return "error-network";
        return constructFailureObjectLogin(error.message)

    }
}


export async function GetRequest(full_url, headers) {
    var url = "";
   
    console.log(url + "test log2--->" + JSON.stringify(full_url) + "headers==>" + JSON.stringify(headers));
    if (ISDEV_ENV) {
        url = full_url;
        console.log(url + "---->headers:" + JSON.stringify(headers));
    } else {
        url = appConfigData.APP_URL + full_url;
    }

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers
        });
        // console.log(url + "--->" + response.status);

        if (response.status == 200 || response.status == 412) {
            const responseJson = await response.json();
            if (ISDEV_ENV) {
           console.log(url + "--->" + JSON.stringify(responseJson));
            }
            return JSON.stringify(responseJson);
        } else {
            var message = "";
            var responseObj = await checkJSON(response);

            if (responseObj != "") {
                var arrMessages = responseObj.Messages;
                if (arrMessages.length > 0) {
                    var messageObject = arrMessages[0];
                    message = messageObject.DisplayText;
                }
                if (message != "" && message != null && message != undefined) {
                    return constructFailureObject(message);
                }

            }
            if (response.status == 404) {
                return constructFailureObject("No Http resource found");
            } else if (response.status == 401) {
                return constructFailureObject("Unauthorised Request");
            } else if (response.status == 500) {
                return constructFailureObject("Internal Server Error");
            } else if (response.status == 503) {
                return constructFailureObject("Server down");
            } else if (response.status == 504) {
                return constructFailureObject("Request Timed out");
            } else {
                // return constructFailureObject(translate('something_went_wrong'));
            }
        }
    } catch (error) {
        if (ISDEV_ENV) {
        console.log(error.message);
        }
        return constructFailureObject(error.message);
    }
}
export async function endSessionGetRequest(full_url, headers) {
    var url = "";
   
    
        url = appConfigData.AUTH_URL + full_url;

    try {
        const response = await fetch(url, {
            method: 'GET'
        });
        // console.log(url + "--->" + response.status);

        if (response.status == 200 || response.status == 412) {
            const responseJson = await response.json();
            if (ISDEV_ENV) {
           console.log(url + "--->" + JSON.stringify(responseJson));
            }
            return JSON.stringify(responseJson);
        } else {
            var message = "";
            var responseObj = await checkJSON(response);

            if (responseObj != "") {
                var arrMessages = responseObj.Messages;
                if (arrMessages.length > 0) {
                    var messageObject = arrMessages[0];
                    message = messageObject.DisplayText;
                }
                if (message != "" && message != null && message != undefined) {
                    return constructFailureObject(message);
                }

            }
            if (response.status == 404) {
                return constructFailureObject("No Http resource found");
            } else if (response.status == 401) {
                return constructFailureObject("Unauthorised Request");
            } else if (response.status == 500) {
                return constructFailureObject("Internal Server Error");
            } else if (response.status == 503) {
                return constructFailureObject("Server down");
            } else if (response.status == 504) {
                return constructFailureObject("Request Timed out");
            } else {
                // return constructFailureObject(translate('something_went_wrong'));
            }
        }
    } catch (error) {
        if (ISDEV_ENV) {
        console.log(error.message);
        }
        return constructFailureObject(error.message);
    }
}

function errorThrow(message) {
    message = (message === undefined) ? 'Some thing Went Wrong' : message;
    const newResponse = {
        errors: true,
        description: message
    };
    return JSON.stringify(newResponse);
}



export async function getRequestfreshDeskDomian(url, headers) {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers
        });


        switch (response.status) {
            case 200:
                const responseJson = await response.json();
                return JSON.stringify(responseJson);
            case 400:
                return errorThrow("Invalid Input");
            case 401:
                return errorThrow("Unauthorised Request");
            case 404:
                return errorThrow("No Http resource found");
            case 429:
                return errorThrow("Number of requests exceeded");
            case 500:
                return errorThrow("Internal Server Error");
            case 502:
                return errorThrow("Network Error");
            case 503:
                return errorThrow("Service temporarily unavailable");
            case 504:
                return errorThrow("Request Timed out");
            default:
                return errorThrow("Something went wrong");
        }
    } catch (error) {
        console.log(error.message);
        return errorThrow(error.message);
    }
}





function constructFailureObjectLogin(message) {
    var newResponse = {
        ResponseCode: 0,
        message: message
    }
    if (ISDEV_ENV) {
    console.log(newResponse);
    }

    return JSON.stringify(newResponse)
}

function constructFailureObject(message) {
    var array = [{
        DisplayText: message
    }];

    var newResponse = {
        ResponseCode: 0,
        Messages: array
    }
    if (ISDEV_ENV) {
    console.log(newResponse);
    }

    return JSON.stringify(newResponse)
}


export async function GetApiHeadersWithBearer(deviceId, access_token) {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
        Device: deviceId
    };
    if (isDEV) {
        headers ["Access-Control-Allow-Origin"] = appConfigData.APP_URL
    }
    return headers;
}

export async function GetApiHeadersWithOutBearer(deviceId) {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Device: deviceId
    };
    return headers;
}


///faqs

export async function GetApiHeadersWithBearerForSupport(key){

    var encrypted = Base64.encode(key); 
    var headers = { 
            Accept: 'application/json', 
            'Content-Type': 'application/json', 
            Authorization : 'Basic ' + encrypted, 
        }; 
        console.log("GetApiHeadersWithBearerForSupport ", headers); 
        return headers; 
}









