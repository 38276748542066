import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../../Layout/HomeLayout';
import TransferMoneyForm from './TransferMoneyForm';
import TransferMoneyConfirm from './TransferMoneyConfirm';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import { withOidcSecure } from '@axa-fr/react-oidc';
import SuccessTransfer from './SuccessTransfer';
import Modal from '../../../../CustomComponents/Modal';
import { useTranslation } from 'react-i18next';
import ScheduledTransfer from './ScheduledTransfer';
import TransferError from './TransferError';
import { createTinkUrl, dateComparison, retrieveData } from '../../../../../utils';
import { ADD_ACH_ACCOUNT, CANCEL_TRANSFER, GENERATE_EXISTING_PHONE_EMAIL_OTP, GENERATE_OTP_ADD_ACH_ACCOUNT, TINK_LINK_ENABLED_CHECK } from '../../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../../network/NetworkUtils';
import { STATUS_SUCCESS_CODE } from '../../../../../network/URLConstants';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Loader from '../../../../CustomComponents/Loader';
import CustomAlertModel from '../../../../CustomComponents/CustomAlertModel';
import { useNavigate } from 'react-router';
import { LOGIN_SESSION_KEY } from '../../../../../Redux/reducers';
import TwoFactTypeSelctionModal from '../../../../CustomComponents/TwoFactTypeSelctionModal';
import Custom2FA from '../../../../CustomComponents/Custom2FA';
import { Alert } from '@mui/material';
import moment from 'moment';
import { fetchConfigarationData } from '../../../../../helpers/utils/Utils';
import TinkLinkModal from '../../../../CustomComponents/TinkLinkModal';
import logFirebaseEvents from '../../../../LogFirebaseEvents';

const appConfigData = await fetchConfigarationData();

const TransferMoney = () => {
    const HeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3";

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isNext, setIsNext] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [transferMoneyConfirmDisplayData, setTransferMoneyConfirmDisplayData] = useState(null)
    const [transferFundPayload, setTransferFundPayload] = useState(null)
    const [fundTransferStatus, setFundTransferStatus] = useState("");
    const [isCancelTransfer, setIsCancelTransfer] = useState(false);
    const [transferFundErrorMsg, setTransferFundErrorMsg] = useState("");
    const [transactionID, setTransactionId] = useState();
    const [scheduleTransactionId, setScheduleTransactionId] = useState(null);
    const [isShowCancelButton, setIsShowCancelButton] = useState(false);
    const [showModel, setShowModel] = useState(false);
    // const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    // const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    // const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "text-black font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] font-normal cursor-pointer pointer-events-none";
    const footerMsgstyles = "font-hanken-grotesk modal-title lg:text-[14px] md:text-[13px] sm:text-[13px] text-[14px] font-normal cursor-pointer pointer-events-none";
  
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [type, setType] = useState('')
    const [footerMsg, setFooterMsg] = useState('');
    const [isBorderNone, setisBorderNone] = useState(false);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [btn1BgColor, setBtn1BgColor] = useState(false);
    const [btn1TextColor, setBtn1TextColor] = useState(false);
    const [btn1Focus, setBtn1Focus] = useState(false);
    const [btn2Focus, setBtn2Focus] = useState(false);
    const [isTinkModal, setIsTinkModal] = useState(false)
    const [isTinkFlowEnabled, setIsTinkFlowEnabled] = useState(false)
    const [isExternalAccountAddedSuccessfully, setIsExternalAccountAddedSuccessfully] = useState(false)
    


    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const transferAccounts = useSelector(state => state.transferAccounts);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [show2faSelection, setshow2faSelection] = useState(false);
    const [otpGenerationType, setOtpGenerationType] = useState(false);
    const [fromAccount, setFromAccount] = useState('');
    const successfulTransferResponse = useSelector(state => state.successfulTransferResponse);


    const navigate = useNavigate();

    const accessToken = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const selectedItem = cards?.[0]
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const cardDetails = useSelector(state => state.selectedCardDetails);
    let YOUR_AUTHENTICATION_LINK = createTinkUrl(appConfigData.TINK_LINK_ACCOUNT_CHECK_URL, appConfigData.TINK_CLIENT_ID, appConfigData.TINK_REDIRECT_URI)

    useEffect(() => {
        window.addEventListener('message', receiveMessage, false);

        return () => {
            window.removeEventListener('message', receiveMessage, false); // Cleanup on unmount
        };

    }, [isTinkModal])


    function receiveMessage(event) {
        if (event.origin !== appConfigData.TINK_BASE_URL) {
            return;
        }

        // Read more about the message format in the Reference below
        const { type, data, error } = JSON.parse(event.data);

        console.log("type---", type)
        console.log("data---", data)


        if (type === 'account_verification_report_id') { // type differ between products
            // This is the authorization code that should be exchanged for an access token
            const reportId = data;
            addExternalAccount(reportId)
            setIsTinkModal(false)
            console.log(`Tink Link returned with reportId: ${reportId}`);
        } else if (type === 'state') {
            console.log(`Tink Link returned with authorization code: ${data}`);
        }
        else if (type === 'code') { // type differ between products
            // This is the authorization code that should be exchanged for an access token
            const code = data;
            console.log(`Tink Link returned with authorization code: ${code}`);
        } else if (type === 'error') {
            if (error.status === 'USER_CANCELLED') {
                showAlertModal(true, t("no_problem_exclimation"), t('user_cancelled_heading'), t("user_cancelled_subheading"), true, false, t('add_via_direct_deposit'), '', true, require('../../../../../assets/images/user_cancelled_icon.png'), '', false, true)
            }
            else if (error.status === 'BAD_REQUEST') {
                showAlertModal(true, t("oh_no"), t('tink_bad_req_heading'), t("tink_bad_req_subheading"), true, false, t('home'), '', true, require('../../../../../assets/images/profileError.png'), '', false, true)
            }
            else if (error.status === 'AUTHENTICATION_ERROR') {
                showAlertModal(true, t("oh_no"), t('tink_auth_error_heading'), t("tink_auth_error_subheading"), true, true, t('try_again1'), t('home'), true, require('../../../../../assets/images/profileError.png'), '', false, true)
            }
            else if (error.status === 'INTERNAL_ERROR') {
                showAlertModal(true, t("oh_no"), t('tink_internal_error_heading'), <>{t("tink_internal_error_subheading")}<span className=' text-[#2965FB] cursor-pointer pointer-events-auto' onClick={() => navigate('/Help/ContactUs')}>{t('signup_customer_care')}</span>{t('for_help')}</>, true, true, t('try_again1'), t('home'), true, require('../../../../../assets/images/profileError.png'), '', false, true)
            }
            else if (error.status === 'TEMPORARY_ERROR') {
                showAlertModal(true, t("oh_no"), t('tink_temp_error_heading'), t("tink_temp_error_subheading"), true, true, t('try_again1'), t('home'), true, require('../../../../../assets/images/profileError.png'), '', false, true)
            }

            // Handle error response from Tink Link
            console.log(`Tink Link returned with error status: ${error.status} and error message: ${error.message}.`);
            setIsTinkModal(false)
        } else if (type === 'credentials') {
            // Handle credentials error response from Tink Link
            const credentialsId = data;
            console.log(`Authentication failed with credentials identifier: ${credentialsId} with error status: ${error.status} and error message: ${error.message}.`);
        } else if (type === 'status') {
            // Observe Tink Link loading state (optional)
            const { loading } = data;
            console.log(`Tink Link has ${loading ? 'shown' : 'hidden'} the loading overlay.`);
        } 
        else if (type === 'application-event') {
            // Observe Tink Link loading state (optional)
            const { event } = data;
            if (event === 'CREDENTIALS_VALIDATION_FAILED') {
                showAlertModal(true, t("oh_no"), t('tink_auth_error_heading'), t("tink_auth_error_subheading"), true, true, t('try_again1'), t('home'), true, require('../../../../../assets/images/profileError.png'), '', false, true)
                setIsTinkModal(false)
            }
            console.log(`Tink Link has authentication the loading overlay.`);
        } 
        else {
            // More message types may be sent or added in the future (these can safely be ignored)
            console.log(error)
            console.log("type---", type)
            console.log("data---", data)
        }
    }

    const isTinkLinkFlowEnabled = async () => {

        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        let url = `${TINK_LINK_ENABLED_CHECK}?cardKey=${selectedItem?.CardKey}`
        setIsLoading(true)
        var response = await GetRequest(url, headers)
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        let parsedResponse = JSON.parse(response);

        if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {

            setIsTinkFlowEnabled(parsedResponse.Response.enabled)

        } else {
            var arrMessages = parsedResponse.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;

            showAlertModal(true, t("oops_exclamation"), displayText, '', true, false, t('ok'), '', false, '', '', false, false)

        }

    }


    const addExternalAccount = async (reportId) => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

        var object = {
            cardkey: selectedItem?.CardKey,
            reportId: reportId

        }
        var url = ADD_ACH_ACCOUNT
        setIsLoading(true)
        var response = await PostRequest(url, object, headers)
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        let parsedResponse = JSON.parse(response);
        if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
            setIsExternalAccountAddedSuccessfully(true);
            setShowModel(false)
            setTimeout(() => {
                showAlertModal(true, t("success"), t('your_ext_acc'), t("your_ext_acc_subheading"), true, false, 'Ok','', true, require('../../../../../assets/images/success.png'), '', false, true)
            }, 1000);

        } else {
            var arrMessages = parsedResponse.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;

            if (displayText == t("ach_already_exists")) {
                setShowModel(false)
                setTimeout(() => {
                    showAlertModal(true, t("oops_exclamation"), t("this_external_bank_account_has"), t('the_bank_account_number_and_routing'), true, true, t('back_to_ext_accounts'), t("cancel"), true, '', '', true, true, '#2965FB', 'none')
                }, 1000);
            } else if (displayText == t("already_added_ach")) {
                setShowModel(false)
                setTimeout(() => {
                    showAlertModal(true, t("uh_oh"), t("this_type_of_account_cannot_be_added"), '', true, true, 'Accept', 'Decline', false, '', '', false, false)
                }, 500);
            } else {
                showAlertModal(true, t("oops_exclamation"), displayText, '', true, false, t('ok'), '', false, '', '', false, false)
            }
        }
    }

    const openHeadsUpModal=()=>{
        showAlertModal(true, t("heads_up"), t('u_r_abt_to_leave'), t("u_r_being_directed"), true, true, 'Continue','Cancel', true, '', '', false, true)

    }

    useEffect(() => {
        isTinkLinkFlowEnabled();
    }, [])

    useEffect(() => {
        switch (fundTransferStatus) {
            case "success":
                openSuccessfullTransferModal();
                break;
            case "scheduled":
                openScheduledTransferModal();
                break;
            case "error":
                openErrorTransferModal();
                break;
        }
        dispatch({ type: "RESET_TRANSFER_PAYLOAD" })
    }, [fundTransferStatus])

    useEffect(() => {
        if (transferAccounts != null && transferAccounts.length <= 1) {
            showAlertModal(true, t("oops_exclamation"), t("there_currently_arent_any_linked_accounts"), t('to_perform_a_transfer_please_add_an_external_account'), true, true, t('add_external_account'), t('dismiss'), true, require('.././../../../../assets/images/spending-alert-error.png'))
        }
    }, [transferAccounts])

    //                     showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1TextColor, btn1BgColor
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1TextColor, btn1BgColor) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
        setBtn1TextColor(btn1TextColor)
        setBtn1BgColor(btn1BgColor)
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
    }
    const closeCustomAlertModal = () => {
        setShowModel(false);
        if (subHeaderMsg == 'Please try again later') {
            if (fromAccount != '') {
                navigate(`/AccountServices/${fromAccount}`);
            }
        }

    }
    const onAcptBtnClick = async () => {
        if (btn1Text == 'Back to Account Services') {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Transfer_Money", cardDetails.AccountTypeName+"_"+"Back_To_Account_Services_Clicked", "Back_To_Account_Services_Clicked","", "");
            if (fromAccount != '') {
                navigate(`/AccountServices/${fromAccount}`);
            } else {
                navigate('/AccountServices')
            }

        }
        if (btn1Text == 'Back to Home') { navigate('/home') }
        else if (btn1Text == t('add_external_account')) {
            if (twoFactAuthenticationDone) {
                if (isTinkFlowEnabled) openHeadsUpModal()
                else {
                    navigate('/Settings/ManageExternalAccounts/AddExternalAccount')
                }
            }else{
                setshow2faSelection(true)
                closeCustomAlertModal()
            }
        }
        else if (btn1Text == 'Continue') {
            setIsTinkModal(true)
            closeCustomAlertModal()
           
        }
        else if (btn1Text == t("home")) {
            navigate('/home')
        } else if (btn1Text === 'Ok') {
            closeCustomAlertModal()
        } else if (btn1Text === t('add_via_direct_deposit')) {
            navigate('/AccountServices/Spending/ManageDirectDeposit')
        }else if (btn1Text == t('back_to_ext_accounts')) {
            navigate('/Settings/ManageExternalAccounts')
        }else if (btn1Text == t('try_again1')) {
            closeCustomAlertModal()
            setIsTinkModal(true)
        }
    }
    const onCancelBtnClick = () => {
        if (btn2Text == 'Back to Home') { navigate('/home') }
        if (btn2Text == 'Back to Account Services') { navigate('/AccountServices') }
        if (btn2Text == 'Cancel Transfer') { closeErrorTransferModal() }
        else if (btn2Text == t('dismiss')) { closeCustomAlertModal() }
        if (btn2Text == t('back_to_ext_accounts')) {
            navigate('/Settings/ManageExternalAccounts')
        } else if (btn2Text == 'Cancel') {
            closeCustomAlertModal()
        } else if (btn2Text == t("back_to_settings")) {
            navigate('/Settings')
        }else if (btn2Text == t("home")) {
            navigate('/home')
        } 
        else{
            closeCustomAlertModal()
        }


    }

    const onCompletion = () => {
        setIsNext(false);
    }

    const onClickAddExternalAcc = async (type) => {

        if (twoFactAuthenticationDone) {
            if (isTinkFlowEnabled) openHeadsUpModal()
            else {
                navigate('/Settings/ManageExternalAccounts/AddExternalAccount')
            }
        }
        else {
            if (selectedItem?.CardKey == undefined) {
                return
            }
            const deviceId = await retrieveData('updatedDeviceId');
            const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

            var object = {
                // "cardKey": selectedItem?.CardKey,
                // "action": "mobile"
            }
            if (type !== undefined || type !== null) {
                object.IsEmail = (type == 'Email') ? true : false
            }
            var url = GENERATE_OTP_ADD_ACH_ACCOUNT.replace('{CARDKEY}', selectedItem?.CardKey)
            setIsLoading(true)
            const response = await PostRequest(url, object, headers)
            setTimeout(() => {
                setIsLoading(false)
            }, 500);

            let parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                // adding session key to redux
                const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                //adding userid to redux
                const userIdPayload = { userId: parsedResponse.Response.userId }
                dispatch({ type: 'USER_ID', payload: userIdPayload });

                setTimeout(() => {
                    setIsOTPVerifyModalOpen(true)
                }, 1000);
            } else {
                var arrMessages = parsedResponse.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
            }
        }
    }

    const cancelTransferRequest = async () => {
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = CANCEL_TRANSFER.replace('{CARD_KEY}', cardKey)
            const body = transactionID;
            // if (scheduleTransactionId) { body.scheduleTransactionId = scheduleTransactionId }
            // console.log('56789', body)

            const response = await PostRequest(url, body, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false);
                if (successfulTransferResponse && transferFundPayload.TransferDate == moment().format('YYYY-MM-DD')) {
                    const fromBalance = successfulTransferResponse.Response.FromAccountBalance_Old;
                    const toBalance = successfulTransferResponse.Response.ToAccountBalance_Old;
                    updateUserCardBalance(fromBalance, toBalance)
                }
                closeSuccessfullTransferModal();
                closeScheduledTransferModal();
                showAlertModal(true, t("cancelled_exclimation"), t("your_transfer_was_cancelled"), '', true, true, 'Back to Account Services', 'Back to Home', true, require('.././../../../../assets/images/transaction-completed.png'))
            } else {
                setIsLoading(false);
                var arrMessages = responseJson.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                closeSuccessfullTransferModal();
                closeScheduledTransferModal();
                showAlertModal(true, t("oops_exclamation"), displayText, '', true, true, 'Back to Account Services', 'Back to Home', true, require('.././../../../../assets/images/transaction-completed.png'))
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const updateUserCardBalance = (fromBalance, toBalance) => {
        const isFromInternal = transferMoneyConfirmDisplayData[8].value.ACHAccountInternalId === 0
        const isToInternal = transferMoneyConfirmDisplayData[9].value.ACHAccountInternalId === 0

        //checking / saving
        if (isFromInternal) {
            const isChecking = ["Checking", "Spending"].includes(transferMoneyConfirmDisplayData[8].value.AccountType);
            const updatedCardData = [...cards]; // Create a copy of the array
            if (isChecking) {
                updatedCardData[0].Balance = fromBalance; // Update the Balance property of the checking object
            }
            else {
                updatedCardData[1].Balance = fromBalance; // Update the Balance property of the saving object
            }
            dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedCardData) } });
        }
        //checking / saving
        if (isToInternal) {
            //  const isChecking = transferMoneyConfirmDisplayData[9].value.AccountType === "Checking"
            const isChecking = ["Checking", "Spending"].includes(transferMoneyConfirmDisplayData[9].value.AccountType);
            const updatedCardData = [...cards]; // Create a copy of the array
            if (isChecking) {
                updatedCardData[0].Balance = toBalance; // Update the Balance property of the checking object
            }
            else {
                updatedCardData[1].Balance = toBalance; // Update the Balance property of the saving object
            }
            dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedCardData) } });

        }
        dispatch({ type: 'SUCCESSFUL_TRANSFER_RESPONSE', payload: { successfulTransferResponse: null } });

    }


    useEffect(() => {
        if (isCancelTransfer) cancelTransferRequest();
    }, [isCancelTransfer])

    const setIsNextFun = (value) => {
        setIsNext(value);
    }

    const setTransferMoneyConfirmDisplayDataFun = (array) => {
        setTransferMoneyConfirmDisplayData(array)
    }
    const setTransferFundPayloadFun = (obj) => {
        setTransferFundPayload(obj)
    }
    const setFundTransferStatusFun = (value, fromAccount) => {
        setFundTransferStatus(value)
        setFromAccount(fromAccount);
    }

    const setIsCancelTransferFun = (value) => {
        setIsCancelTransfer(value);
    }

    const setTransferFundErrorMsgFun = (errMsg, fromAccount) => {
        setTransferFundErrorMsg(errMsg);
        setFromAccount(fromAccount);
    }
    const setTransactionIdFun = (value) => {
        setTransactionId(value);
    }
    const setScheduledTransactionId = (transferDetails, transferResponse) => { if (transferDetails?.Frequency == 'RC') { setScheduleTransactionId(transferResponse?.ScheduleTransactionId) } else { setScheduleTransactionId(transferResponse?.ScheduleTransactionId) } }

    // Successfull transfer modal
    const [isSuccessfullTransferModalOpen, setIsSuccessfullTransferModalOpen] = useState(false);

    const openSuccessfullTransferModal = () => {
        const transferDateStatus = dateComparison(transferMoneyConfirmDisplayData[6]?.value)
        const isCancelBtn = transferDateStatus === "Future"
        setIsShowCancelButton(true)
        setIsSuccessfullTransferModalOpen(true);
    };

    const closeSuccessfullTransferModal = () => {
        onCompletion();
        setIsSuccessfullTransferModalOpen(false);

    };
    // Scheduled Transfer modal
    const [isScheduledTransferModalOpen, setIsScheduledTransferModalOpen] = useState(false);

    const openScheduledTransferModal = () => {
        const transferDateStatus = dateComparison(transferMoneyConfirmDisplayData[6]?.value)
        const isCancelBtn = transferDateStatus === "Future"
        setIsShowCancelButton(true)
        setIsScheduledTransferModalOpen(true);
    };

    const closeScheduledTransferModal = () => {
        const transferDateStatus = dateComparison(transferMoneyConfirmDisplayData[6]?.value)
        const isCancelBtn = transferDateStatus === "Future"
        setIsShowCancelButton(true)
        onCompletion();
        setIsScheduledTransferModalOpen(false);

    };
    // Error modal
    const [isErrorTransferModalOpen, setIsErrorTransferModalOpen] = useState(false);

    const openErrorTransferModal = () => {
        // setIsErrorTransferModalOpen(true);
        setIsLoading(false)
        showAlertModal(true, <p className='sm:flex block flex-col'>{t("oops_exclamation")}<span className="text-secondary-3"> {transferFundErrorMsg}</span></p>, '', 'Please try again later', true, false, 'Back to Account Services', '', true, require('.././../../../../assets/images/transfer-error.png'))
    };

    const closeErrorTransferModal = () => {
        onCompletion();
        // setIsErrorTransferModalOpen(false);
        setShowModel(false)

    };

    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false); }
    const verificationSuccess = () => {
            if (isTinkFlowEnabled) openHeadsUpModal()
            else {
                navigate('/Settings/ManageExternalAccounts/AddExternalAccount')
            }

        
    }

    return (
        <>
            <HomeLayout>
                <HomeHeader heading="Hello, Sam. You’ve spent $405 in the last week."
                    isHeaderCaption={false}
                    selectedIndex={2} />

                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight '>

                    {isNext ?
                        <TransferMoneyConfirm
                            datas={transferMoneyConfirmDisplayData}
                            transferFundPayload={transferFundPayload}
                            onSetStatus={setFundTransferStatusFun}
                            onSetErrorMsg={setTransferFundErrorMsgFun}
                            onSetTransactionId={setTransactionIdFun}
                            setScheduledTransactionId={(transferDetails, transferResponse) => { setScheduledTransactionId(transferDetails, transferResponse) }}
                            onCancelSelect={setIsNextFun}

                        />
                        :
                        <TransferMoneyForm
                            onNextSelect={setIsNextFun}
                            onConfirmDisplayData={setTransferMoneyConfirmDisplayDataFun}
                            onTransferFundPayload={setTransferFundPayloadFun}
                            onClickAddExternalAcc={() => { setshow2faSelection(true) }}
                            isTinkFlowEnabled={isTinkFlowEnabled}
                            openHeadsUpModal={openHeadsUpModal}
                            isExternalAccountAddedSuccessfully={isExternalAccountAddedSuccessfully}
                            onSetIsExternalAccountAddedSuccessfully={(val)=>setIsExternalAccountAddedSuccessfully(val)}
                        />
                    }

                </div>



                {isLoading && <Loader />}

            </HomeLayout >
            <Modal
                isOpen={isSuccessfullTransferModalOpen}//isLoginTrustDeviceOpen
                onClose={closeSuccessfullTransferModal}
                alignItems={'items-center'}
                title={<p className={`${HeadingStyles} sm:flex block flex-col`}>{t('confirmed')}<span className="text-secondary-3">{t('success_fund_transfer_msg')}</span></p>}
                // subtitle={<p className={SubHeadingStyles}>{t('success_fund_transfer_msg')}</p>}
                content={<div class="aspect-454/253 mt-10">
                    <SuccessTransfer fromAccountType={fromAccount} onCanelTranfer={setIsCancelTransferFun} isCancelButton={isShowCancelButton} />
                </div>}
            />
            <Modal
                isOpen={isScheduledTransferModalOpen}//isLoginTrustDeviceOpen
                onClose={closeScheduledTransferModal}
                alignItems={'items-center'}
                // title={<p className={`${HeadingStyles} sm:flex block flex-col`}>{t('confirmed')}<span className="text-secondary-3">{t('scheduled_fund_transfer_msg')}</span></p>}
                title={<p className={`${HeadingStyles} sm:flex block flex-col`}>{t('confirmed')}<span className="text-secondary-3">{t('your_funds_are_schduled_for_transfer')}</span></p>}
                
                // subtitle={<p className={SubHeadingStyles}>{t('scheduled_fund_transfer_msg')}</p>}
                content={<div class="aspect-454/253 mt-10">
                    <ScheduledTransfer fromAccountType={fromAccount} onCanelTranfer={setIsCancelTransferFun} isCancelButton={isShowCancelButton} />
                </div>}
            />
            <Modal
                isOpen={isErrorTransferModalOpen}//isLoginTrustDeviceOpen
                onClose={closeErrorTransferModal}
                alignItems={'items-center'}
                title={
                    <>
                        <p className={`${HeadingStyles} sm:flex block flex-col`}>{t('oops_exclamation')}
                            <span>{transferFundErrorMsg}</span></p>

                    </>
                }
                // subtitle={<p className={SubHeadingStyles}>{transferFundErrorMsg}</p>}
                content={<div class="aspect-454/253 mt-10">
                    <TransferError fromAccountType={fromAccount} onCanelTranfer={setIsCancelTransferFun} isCancelButton={isShowCancelButton} />
                </div>}
            />

            {isTinkModal &&

                <TinkLinkModal
                    isOpen={isTinkModal}
                    onClose={() => setIsTinkModal(false)}
                    content={

                        <iframe src={YOUR_AUTHENTICATION_LINK} width='100%' height="700px" title="Tink Link Account Check" />

                    }
                />

            }

            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                // highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                // HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                // subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                    <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={footerMsgstyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                isBorderNone={false}
                showErrorImg={showErrorImg}
                btn2Focus={btn2Focus}
                btn1BgColor={btn1BgColor}
                btn1TextColor={btn1TextColor}
            />
            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={closeOTPVerifyModal}
                isLogo={false}
                title={<p className={HighlightMsgStyles}> {t("great_exclamation")}</p>}
                subtitle={<p className={HeaderMsgStyles}>{otpGenerationType == 'Email' ? t("we_are_sending_text").replace("a text", "an email") : t("we_are_sending_text")}</p>}
                subHeaderMsg={<p className="font-hanken-grotesk modal-title md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] font-normal cursor-pointer pointer-events-none">{otpGenerationType != 'Email' && t("standard_msg_and_data_rates_may_apply")}</p>}
                content={<div className="mt-10"><Custom2FA type={t('add_external_account')} selected2FAType={otpGenerationType} onClose={closeOTPVerifyModal} verificationSuccess={verificationSuccess} verificationFailure={() => { }} /></div>}
            />
            <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false) }} onClickNext={(object) => { setOtpGenerationType(object.proc); setshow2faSelection(false); onClickAddExternalAcc(object.proc); }} />

        </>
    )
}

export default withOidcSecure(TransferMoney) 