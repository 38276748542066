import React, { useEffect, useState, useRef } from "react";
import CustomInput from "../../CustomComponents/FormInputs";
import { useTranslation } from "react-i18next";
import { useKeyDown } from "../../../hooks/useKeyDown";
import logFirebaseEvents from "../../LogFirebaseEvents";

const TrustDevice = ({ loginTrustDeviceClicked }) => {
  const { t } = useTranslation();
  const trustDeviceButtonRef = useRef(null);
  const dontTrustButtonRef = useRef(null);
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);
  const trustbtnRef = useRef(null);

  const onClickedtrustDevice = (isTrusted) => {
    loginTrustDeviceClicked(isTrusted);
  };

  useEffect(() => {
    //     // Focus on the submit button when 6 digits are entered
    trustbtnRef.current.focus();
  });

  // useKeyDown(() => {
  //   onClickedtrustDevice(true)
  // }, ['Enter']);

  return (
    <>
      <div>
        <div className="text-secondary-2 flex flex-col justify-center items-center w-full h-50 "></div>

        {/* <CustomInput
        ref={trustDeviceButtonRef}
          name={null}
          label={t('trust_device')}
          register={null}
          error={null}
          type='submit'
          onClick={() => onClickedtrustDevice(true)}
        />

        <CustomInput
        ref={dontTrustButtonRef}
          name={null}
          label={t('dont_trust')}
          register={null}
          error={null}
          type='btn_withoutBg'
          onClick={() => onClickedtrustDevice(false)}
        /> */}

        <button
          ref={trustbtnRef}
          onClick={() => onClickedtrustDevice(true)}
          className="font-hanken-grotesk w-full bg-submit-btn hover:bg-submit-btn text-white py-2 px-4 lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] font-medium h-[50px] md:h-[60px]  flex justify-center items-center"
        >
          {t("trust_device")}
        </button>
        <button
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              onClickedtrustDevice(false);
            }
          }}
          onClick={() => onClickedtrustDevice(false)}
          className=" mt-5 font-hanken-grotesk w-full  border-2 border-btn-border text-[#2965FB] py-2 px-4 lg:text-[18px] md:text-[17px] 
          sm:text-[15px] text-[16px] font-medium h-[50px] md:h-[60px]  flex justify-center items-center"
        >
          {t("dont_trust")}
        </button>
      </div>
    </>
  );
};

export default TrustDevice;
