import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from '../../CustomComponents/FormInputs'
import { useNavigate } from 'react-router'
import { withOidcSecure } from '@axa-fr/react-oidc';
import Modal from "../../CustomComponents/Modal";
import ClimbIcon from '../../../assets/images/illo-climb.png';
import { useDispatch, useSelector, connect } from 'react-redux';
import { SAVING, retrieveData } from '../../../helpers/utils/Utils';
import { STATUS_SUCCESS_CODE, GET_CLIMB_FEATURES, CLIMB_OPT_IN_OUT, CAN_CREATE_SAVINGS_ACC } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import Loader from '../../CustomComponents/Loader';
import Tickicon from '../../../assets/images/Check_white.png';
import lock from '../../../assets/images/lock.png';
import SpendIcon1 from '../../../assets/images/illo-spendsense.png';
import SpendIcon2 from '../../../assets/images/spend_alert_2-1.png';
import SavingHabitIcon1 from '../../../assets/images/savings-habits-1.png';
import RadialFill from '../../../assets/images/Radial Fill.png'
import RadialNull from '../../../assets/images/Radial Null.png'
import Arrow from '../../../assets/images/arrow.png';

import CoveredByKudzuIcon1 from '../../../assets/images/savings-habits.png';
import LineBreaks, { addLineBreaks } from "../../../utils";
import moment from "moment";
import CustomAlertModel from "../../CustomComponents/CustomAlertModel";
import logFirebaseEvents from "../../LogFirebaseEvents";


const Climb = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isClimbModalOpen, setIsClimbModalOpen] = useState(true);
  const cards = useSelector(state => JSON.parse(state.userCards));
  const accessToken = useSelector(state => state.accessToken);
  const [isClimbings, setisClimbings] = useState([]);
  const [disclosureAgrmt, setDisclosureAgrmt] = useState(false);

  //spend alerts
  const [isClimbFeaturesModalOpen, setIsClimbFeaturesModalOpen] = useState(false);
  const [isSpendModalOpen1, setSpendModalOpen1] = useState(false);
  const [isSpendModalOpen2, setSpendModalOpen2] = useState(false);
  const [isSpendModalOpen3, setSpendModalOpen3] = useState(false);

  //saving habits
  const [isSavingHabitModalOpen1, setIsSavingHabitModalOpen1] = useState(false);
  const [isSavingHabitModalOpen2, setIsSavingHabitModalOpen2] = useState(false);
  const [isSavingHabitModalOpen3, setIsSavingHabitModalOpen3] = useState(false);

  //Covered By Kudzu
  const [IsCoveredByKudzuModalOpen1, setIsCoveredByKudzuModalOpen1] = useState(false);
  const [isCoveredByKudzuModalOpen2, setIsCoveredByKudzuModalOpen2] = useState(false);
  const [isCoveredByKudzuModalOpen3, setIsCoveredByKudzuModalOpen3] = useState(false);
  const [isCoveredByKudzuDiscloserOpen, setIsCoveredByKudzuDiscloserOpen] = useState(false);
  const [isCoveredByKudzuOptInSuccess, setIsCoveredByKudzuOptInSuccess] = useState(false);
  const [isCoveredByKudzuIneligible, setIsCoveredByKudzuIneligible] = useState(false);
  const [isCoveredByKudzuIneligibleSetupSavings, setIsCoveredByKudzuIneligibleSetupSavings] = useState(false);
  const [isCoveredByKudzuIneligibleSetupSavingsPostPopup, setIsCoveredByKudzuIneligibleSetupSavingsPostPopup] = useState(false);

  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [modelImage, setModelImage] = useState('')
  const [savingsTheme, setSavingsTheme] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [isSubFooter, setIsSubFooter] = useState(false);

  const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;
  const accessTokenToApi = useSelector(state => state.accessToken);

  const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3";
  const SubHeadingStyles2 = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-2 pl-1 pr-1 mb-4";
  const SubHeadingStyles3 = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[30px] lg:leading-[38px] md:leading-[30px] leading-[30px] font-normal text-secondary-3";
  const savingsCardExist = cards?.some(item => item.AccountTypeName === "Savings")
  const savingsWithBalanceOver50 = cards.find(
    obj => obj.AccountTypeBit63 == 32 && obj.Balance >= 50
  );
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  console.log(cards, '6846')

  const closeClimbVerificationModal = () => {
    setIsClimbModalOpen(false);
  };

  //close modal redirection to manage cards

  const closeSkipModal = () => {
    navigate('/home');
  };

  ////open modal setp wise
  const OpenClimbFeaturesModal = () => {
    setIsClimbFeaturesModalOpen(true);

  };


  //climb features modal


  const handleClimbFeatures = async () => { 
    logFirebaseEvents("climb", "Continue_Clicked", "Continue_Clicked", "",""); 
    if (isPrimrayKey == undefined || isPrimrayKey == null) {
      return
    }
    setIsLoading(true);
    const deviceId = await retrieveData('updatedDeviceId')
    const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
    const url = GET_CLIMB_FEATURES.replace('{CARD_KEY}', isPrimrayKey)
    const response = await GetRequest(url, headers);
    const responseJson = JSON.parse(response);
    if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
      const enabledClimbings = responseJson.Response.filter(
        (response) => response.enabled === true

      );
      setisClimbings(enabledClimbings);
      dispatch({ type: 'UPDATE_CLIMB_FEATURE', payload: { climbFeatures: responseJson.Response } })
      closeClimbVerificationModal();
      OpenClimbFeaturesModal();
      setIsLoading(false);
    } else {
      return [];
    }

  }


  const canCreateSavingsAccount = async () => {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    setIsLoading(true)

    let finalUrl = CAN_CREATE_SAVINGS_ACC(cards[0].CardKey)
    var response = await GetRequest(finalUrl, headers)
    var responseObj = JSON.parse(response)
    setIsLoading(false)
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        const canCreateNew = responseObj.Response.canCreateNew
        const eligibleDate = moment(responseObj.Response.eligibleDate).format('MM/DD/YYYY')
        if (canCreateNew) {
          enrollSavingsAccount()
          
        } else {
            setIsCoveredByKudzuIneligibleSetupSavings(false); 
            showAlertModal(true, t("oh_no"), t('unable_to_open'), `${t('u_will_be_eligible')} ${eligibleDate}. `, true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false, true)
        }
    } else {
        setTimeout(() => {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oh_no"), displayText, '', true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false)

            // setError(displayText)
        }, 500);
    }
}

const subComponent = () => {
  return (
      <div className=' flex justify-start items-start'>
          <p className="font-hanken-grotesk font-semibold sm:font-[500] sm:text-[14px] md:text-[20px] text-[16px] md:leading-[30px] leading-[24px]">{t("pl_contact_customer")}<span className="text-[#2965FB] cursor-pointer" onClick={
              () => window.open("https://www.kudzumoney.com/contact-us", '_blank')}>{t("support_email")}</span>{t("for_access")}</p>
      </div>
  )
}

  const enrollSavingsAccount = () => { 
    setIsCoveredByKudzuIneligibleSetupSavings(false); 
    setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true); 
  }

  const closeCustomAlertModal1 = () => {
    setShowModel(false);
  }

  const onAcptBtnClick = async () => {
    setShowModel(false)
    if (btn1Text == t('home')) {
      navigate('/home')
    }
  }
  const onCancelBtnClick = () => {
    setShowModel(false)

  }

  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, savingsTheme, isBorderNone, isSubFooter) => {
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setSavingsTheme(savingsTheme)
    setisBorderNone(isBorderNone)
    setIsSubFooter(isSubFooter)

  }

  const coveredByKudzuOptIn = async (item) => {

    setIsClimbFeaturesModalOpen(false);
    setIsCoveredByKudzuModalOpen3(false);

    setIsLoading(true);
    const url = CLIMB_OPT_IN_OUT.replace('{CARD_KEY}', cards[0].CardKey) + 'CoveredByKudzu' + '/opt-in'
    const deviceId = await retrieveData('updatedDeviceId')
    const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
    const response = await PostRequest(url, {}, headers)

    setTimeout(() => {
      setIsLoading(false)
    }, 500);

    const responseObj = JSON.parse(response);

    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {

      setIsClimbFeaturesModalOpen(false);
      setIsCoveredByKudzuDiscloserOpen(false);
      setIsCoveredByKudzuOptInSuccess(true);
    } else {

      setIsClimbFeaturesModalOpen(true);
      setIsCoveredByKudzuDiscloserOpen(false);
      var arrMessages = responseObj.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;
    }

  }

  const MangageSpendAlerts =  () => {
    logFirebaseEvents("climb", "Managed_Skip_Clicked", "Managed_Skip_Clicked", "","");
    // alert('HIASHS');
    navigate('/Climb/ClimbMenu');
  }


  const handleSpendAlertIntoro2 = () => {
    setIsClimbFeaturesModalOpen(false);
    setSpendModalOpen1(false);
    setSpendModalOpen2(true);

  };

  const handleSpendAlertIntoro3 = () => {
    setIsClimbFeaturesModalOpen(false);
    setSpendModalOpen1(false);
    setSpendModalOpen2(false);
    setSpendModalOpen3(true);
  };

  // savings Habit
  const handleSavingsHabitIntro2 = () => {
    logFirebaseEvents("climb", "Learn_More_Saving_Habit_Clicked", "Learn_More_Saving_Habit_Clicked","", "");

    setIsClimbFeaturesModalOpen(false);
    setIsSavingHabitModalOpen1(false);
    setIsSavingHabitModalOpen2(true);
  }

  const handleSavingsHabitIntro3 = () => {
    logFirebaseEvents("climb", "Get_Start_Saving_Habit_Clicked", "Get_Start_Saving_Habit_Clicked","", "");
    setIsClimbFeaturesModalOpen(false);
    setIsSavingHabitModalOpen1(false);
    setIsSavingHabitModalOpen2(false);
    setIsSavingHabitModalOpen3(true);
  };

  const handleButtonClick = (item) => {
    //debugger;
    switch (item.code) {
      case "SpendingAlert":
        logFirebaseEvents("climb", "Spending_Alert_Clicked", "Spending_Alert_Clicked", "","");
        if (item.hasConfigurations) {
          navigate('/Climb/ManageSpendAlerts');
        } else {
          setIsClimbFeaturesModalOpen(false);
          setSpendModalOpen1(true);
        }
        break;
      case "CoveredByKudzu":
        logFirebaseEvents("climb", "Covered_By_Kudzu_Clicked", "Covered_By_Kudzu_Clicked","", "");
        if (item.code != "SpendingAlert" && savingsCardAvailable == false) {
          setIsSavingHabitModalOpen3(false); setIsCoveredByKudzuIneligibleSetupSavings(true); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false)
        } else {
          if (item.optedIn) {
            // navigate('/Climb/SpendSenseAlerts/ManageSpendAlerts');
          }
          else {
            navigate('/Climb/CoveredByKudzu');
          }
        }

        break;
      // Add more cases for other displayText values
      case "SavingsHabit":
        logFirebaseEvents("climb", "Savings_Habit_Clicked", "Savings_Habit_Clicked","", "");
        if (item.code != "SpendingAlert" && !savingsCardExist) {
          setIsSavingHabitModalOpen3(false); setIsCoveredByKudzuIneligibleSetupSavings(true); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false)
        }
        // else if ( savingsCardExist && savingsWithBalanceOver50 == undefined){
        //   callAddMoneyToSavingsAccount();
        // }
        else {

          if (item.hasConfigurations) {
            navigate('/Climb/ManageSavingsHabit');
          } else {
            setIsClimbFeaturesModalOpen(false);
            setIsSavingHabitModalOpen1(true);
          }
        }
        break;
      // Add more cases for other displayText values
      case "SavingsGoals":
        // Handle click for Option 2
        break;
      // Add more cases for other displayText values
      default:
        // Default click handler
        break;
    }
  }



  const callAddMoneyToSavingsAccount = () => {
    setIsCoveredByKudzuIneligibleSetupSavings(false);
    setIsClimbFeaturesModalOpen(false);
    setIsCoveredByKudzuIneligible(true)
  }
  const closeCustomAlertModal = () => {
    // setIsCoveredByKudzuIneligible(false);
    setIsCoveredByKudzuIneligible(false);
    setIsClimbFeaturesModalOpen(true);

  }

  return (
    <div>
      <Modal
        isOpen={isClimbModalOpen}
        onClose={closeSkipModal}
        isClimbg={true}
        subtitle={<>
          <span className={SubHeadingStyles}>{t("climbInfo")}</span>
          <span className={SubHeadingStyles2}>{t("climb")}</span>
          <span className={SubHeadingStyles}>{t("climbinfo2")}</span>
        </>
        }
        content={<div className="pt-10 pb-10">
          <img src={ClimbIcon} className="block sm:m-auto mx-auto lg:w-[220px] lg:h-[220px] 
          md:w-[170px] md:h-[170px] w-[129px] h-[129px] my-16" />
          <div className="sm:absolute md:right-[-50px] sm:right-[0px] bottom-[0.5rem] w-full">
            <div className="flex sm:justify-end justify-between md:gap-14 sm:gap-8">
              <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
     text-[#99AED7] flex items-center"
                onClick={MangageSpendAlerts}

              >
                {t('skip')}
              </button>
              <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
     text-[#2965FB] cursor-pointer flex items-center"
                onClick={handleClimbFeatures}
              >
                {t('continue')}<img
                  src={Arrow}
                  className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px]
                     h-[14px] ml-1"
                  alt="Arrow" />
              </button>

            </div>

          </div>
        </div>}
      />
      {/* 
      ////climb features */}
      <Modal
        isOpen={isClimbFeaturesModalOpen}
        onClose={closeSkipModal}
        isClimbg={true}
        subtitle={<>
          {/* <span className={SubHeadingStyles}>{t("climbInfo")}</span> */}
          <span className={SubHeadingStyles2}>{t("lets_get_climbing")}</span>
          <p className={SubHeadingStyles}>{t("where_woukd_you_like_to_start")}</p>
        </>
        }
        content={
          <div className="pt-20 pb-10">
            <div className="flex flex-col justify-center items-center gap-4">
              {isClimbings.map(item => (
                <button
                  key={item.id}
                  className={`w-[255px] md:h-[65px] sm:h-[60px] h-[60px] md:text-[16px] text-[15px] font-hanken-grotesk bg-[#2965FB]
                   rounded-full px-4 py-2 text-white ${(item.code == "CoveredByKudzu" && item.optedIn ? 'opacity-50 cursor-not-allowed' : '')}`}
                  disabled={(item.code == "CoveredByKudzu" && item.optedIn ? true : false)}
                  onClick={() => handleButtonClick(item)}
                >
                  <div className="flex flex-row align-center justify-center items-center">
                    <div className="flex w-[150px]">{item.displayText}</div>
                    {item.code != "CoveredByKudzu" && item.optedIn && (
                      <div className="inline-block pl-3 align-middle">
                        <img src={Tickicon} alt="Tick Icon" />
                      </div>
                    )}
                    {(item.code != "SpendingAlert" && savingsCardAvailable == false) && (
                      <div className="flex align-middle items-center">
                        <img src={lock} alt="lock Icon" />
                      </div>
                    )}
                  </div>

                </button>
              ))}
            </div>
            <div className="sm:absolute sm:right-[-1rem] right-[4rem] bottom-[-1rem]">

              <div className="flex mt-32">
                <button
                  type="button"
                  className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
     text-[#99AED7] flex items-center"
                  onClick={MangageSpendAlerts}

                >
                  {t('skip')}
                </button>
              </div>
            </div>
          </div>
        }
      />

      {/* //spending alerts intro 1 */}

      <Modal
        isOpen={isSpendModalOpen1}
        onClose={closeSkipModal}
        isClimbg={true}
        subtitle={<>
          <span className={SubHeadingStyles}>{t("Setup")}</span>
          <span className={SubHeadingStyles2}>{t("SpendSenseAlerts")}</span>
          <span className={SubHeadingStyles}>{t("spendAlertsHeading")}</span>
        </>
        }
        content={
          <div>
            <p className="font-400 leading-[18px] text-[12px] sm:leading-[20px] md:text-[16px] md:leading-[24px] text-[#4B70B9] mt-4">{t("climb_menu_note")}</p>
            <div className="pt-10 pb-10">
              <img src={SpendIcon1} className="block m-auto mb-20 sm:mb-10 w-[100px] h-[100px] md:w-[155px] md:h-[155px]" />
              <div className="sm:absolute md:right-[-50px] sm:right-[0px] bottom-[0.5rem] w-full">
                <div className="flex sm:justify-end justify-between md:gap-14 sm:gap-8">
                  <button
                    type="button"
                    className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
     text-[#99AED7] flex items-center disabled"
                    onClick={MangageSpendAlerts}
                  >
                    {t('skip')}
                  </button>
                  <button
                    type="button"
                    className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
             text-[#2965FB] cursor-pointer flex items-center"
                    onClick={() => handleSpendAlertIntoro2()}
                  >
                    {t('Learn_More')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      />



      {/* //spending alerts intro 2 */}


      <Modal
        isOpen={isSpendModalOpen2}
        onClose={closeSkipModal}
        isClimbg={true}
        subtitle={<>
          <span className={SubHeadingStyles2}>{t("SpendSenseAlerts")}</span>
          <span className={SubHeadingStyles}>{t("spendAlerts2Heading")}</span>
        </>
        }
        content={<div className="pt-10 pb-10">
          <img src={SpendIcon2} className="block m-auto md:w-[265px] md:h-[175px] w-[175px] h-[117px] mb-20 sm:mb-10 " />
          {/* <div className="absolute sm:right-[-1rem] right-[-1rem] bottom-[0.5rem] flex gap-6"> */}
          {/* <div className="sm:absolute md:right-[-50px] sm:right-[0px] bottom-[0.5rem] w-full">
            <div className="flex sm:justify-end justify-between md:gap-14 sm:gap-8">

              <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
     text-[#99AED7] flex items-center disabled"
                onClick={MangageSpendAlerts}
              >
                {t('skip')}
              </button>
              <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
             text-[#2965FB] cursor-pointer flex items-center"
                onClick={() => handleSpendAlertIntoro3()}
              >
                {t('continue')}
              </button>
            </div>

          </div> */}

<div className="w-full flex flex-row justify-between lg:mt-32 md:mt-20 mt-20">
              <div>
                <button
                  type="button"
                  className="sm:block hidden font-hanken-grotesk sm:font-bold font-[600]
                                 lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]"
                >
                  {t('questions_see_our')} <button onClick={() => { navigate('/Help/FAQ') }}
                    className="text-[#99AED7]">{t('faq')}</button>
                </button>

                <button
                  type="button"
                  className="sm:hidden block font-hanken-grotesk sm:font-bold font-[600]
                                 lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]"
                >  <button onClick={() => { navigate('/Help/FAQ') }}
                  className="text-[#99AED7]">{t('faq')}</button>
                </button>
              </div>

              <div className="">
                <button type="button" className="font-hanken-grotesk sm:font-bold font-[600]
 lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB]
 cursor-pointer flex items-center" onClick={() => handleSpendAlertIntoro3()}>  {t('get_started')}<img src={Arrow} className="md:w-[14px] sm:w-[11px]
  md:h-[14px] sm:h-[11px] w-[14px] h-[14px] ml-1" alt="Arrow" /> </button>
              </div>
            </div>


        </div>}
      />





      {/* //spending alerts intro 3 */}

      <Modal
        isOpen={isSpendModalOpen3}
        onClose={closeSkipModal}
        isClimbg={true}
        subtitle={<>
          <p className={SubHeadingStyles2} style={{ width: '453px' }}> {addLineBreaks(t('letsStartSpendAlerts'))}</p>
          <p className={SubHeadingStyles3}>{t("settingSpendAlerts")}</p>
        </>
        }
        content={<div className="pt-5 pb-10">
          <img src={SpendIcon1} className="block m-auto mb-20 sm:mb-10 w-[100px] h-[100px] md:w-[155px] md:h-[155px]" />
          <div className="flex justify-center text-center w-full items-center">
            <button
              type="button"
              className="w-full font-hanken-grotesk sm:font-bold font-[600] 
              lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px]
             text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
              onClick={() => { navigate('/Climb/AddSpendAlerts') }}
            >
              {t('spendBtnText')}
            </button>
          </div>
        </div>}
      />

      {/* //Saving Habits intro 1 */}

      <Modal
        isOpen={isSavingHabitModalOpen1}
        onClose={closeSkipModal}
        isClimbg={true}
        subtitle={<div className="w-[90%] md:w-full">
          <span className={SubHeadingStyles}>{t("Setup")}</span>
          <span className={SubHeadingStyles2}>{t("saving_habits")} </span>
          <span className={SubHeadingStyles}>{t("saving_habits_heading")}</span>
        </div>
        }
        content={<div className="pt-10 pb-10">
          <img src={SavingHabitIcon1} className="block m-auto w-2/5 h-2/5" />
          <div className="absolute sm:right-[-1rem] right-[-1rem] bottom-[0.5rem] flex gap-6">
            <button
              type="button"
              className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#99AED7] flex items-center disabled"
              onClick={MangageSpendAlerts}
            >
              {t('skip')}
            </button>
            <button
              type="button"
              className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center"
              onClick={() => handleSavingsHabitIntro2()}
            >
              {t('Learn_More')}<img
                src={Arrow}
                className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px]
                     h-[14px] ml-1"
                alt="Arrow" />
            </button>
          </div>
        </div>}
      />


      {/* //Saving Habits intro 2 */}
      <Modal
        isOpen={isSavingHabitModalOpen2}
        onClose={closeSkipModal}
        isClimbg={true}
        subtitle={<>
          <span className={SubHeadingStyles}>{t("saving_habits2_heading1")}</span>
          <span className={SubHeadingStyles2}>{t("saving_habits2_heading2")}</span>
        </>
        }
        content={
          <>

            <div className=" m-auto pt-20 pb-20 h-2/5 w-2/5">
              <img src={require('../../../assets/images/savings_habit_2_icon.png')} className="block m-auto" />
            </div>
            <div className="w-full flex flex-row justify-between lg:mt-32 md:mt-20 mt-20">
              <div>
                <button
                  type="button"
                  className="sm:block hidden font-hanken-grotesk sm:font-bold font-[600]
                                 lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]"
                >
   
    {t('questions_see_our')} <button onClick={() => { navigate('/Help/FAQ');  logFirebaseEvents("climb", "Faq_Saving_Alert_Skip_Clicked", "Faq_Saving_Alert_Skip_Clicked","", "");  }}
                    className="text-[#99AED7]">{t('faq')}</button>
                </button>

                <button
                  type="button"
                  className="sm:hidden block font-hanken-grotesk sm:font-bold font-[600]
                                 lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]"
                >  <button onClick={() => { navigate('/Help/FAQ') }}
                  className="text-[#99AED7]">{t('faq')}</button>
                </button>
              </div>

              <div className="">
                <button type="button" className="font-hanken-grotesk sm:font-bold font-[600]
 lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB]
 cursor-pointer flex items-center" onClick={() => handleSavingsHabitIntro3()}>  {t('get_started')}<img src={Arrow} className="md:w-[14px] sm:w-[11px]
  md:h-[14px] sm:h-[11px] w-[14px] h-[14px] ml-1" alt="Arrow" /> </button>
              </div>
            </div>


            {/* <div className="absolute sm:right-[-1rem] right-[-1rem] bottom-[0.5rem] flex gap-6">
              <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
           text-[#99AED7] flex items-center disabled"
              // onClick={MangageSpendAlerts}
              >
                {t('skip')}
              </button>
              <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                   text-[#2965FB] cursor-pointer flex items-center"
                onClick={() => handleSavingsHabitIntro3()}
              >
                {t('get_started')}<img
                  src={Arrow}
                  className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px]
                     h-[14px] ml-1"
                  alt="Arrow" />
              </button>
            </div> */}


          </>}
      />

      {/* //Saving Habits intro 3 */}

      <Modal
        isOpen={isSavingHabitModalOpen3}
        onClose={closeSkipModal}
        isClimbg={true}
        subtitle={<>
          <p className={SubHeadingStyles2}>{t('saving_habits3_content1')}</p>
          <p className='font-hanken-grotesk font-[500] md:text-[20px] sm:text-[12px] text-[14px] sm:leading-[18px] md:leading-[24px] leading-[25px]'>{t("saving_habits3_content2")}</p>
          <p className='font-hanken-grotesk font-[500] sm:text-[10px] md:text-[16px] text-[16px] sm:leading-[16px] pt-5  text-secondary-3'>{'Learn more about Climb in '}<span className="text-secondary-2" onClick={() => navigate("/PayPerks")}>{'PayPerks'}</span>.</p>
        </>
        }
        content={<div className="pb-10">
          <img src={require('../../../assets/images/savings-habits-1.png')} className="block m-auto h-2/5 w-2/5 py-5" />
          <div className="flex justify-center text-center w-full items-center">
            <button
              type="button"
              className="w-full font-hanken-grotesk sm:font-bold font-[600] 
              lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px]
             text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
              onClick={() => { if (savingsCardExist) { navigate('/Climb/AddSavingsHabit') } else { setIsSavingHabitModalOpen3(false); setIsCoveredByKudzuIneligibleSetupSavings(true); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false) } logFirebaseEvents("climb", "Set_Saving_Habit_Clicked", "Set_Saving_Habit_Clicked","", ""); }}
            >
              {t('savings_habit_btm_text')}<img
                src={Arrow}
                className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px]
                     h-[14px] ml-1"
                alt="Arrow" />
            </button>
          </div>
        </div>}
      />

      <Modal
        isOpen={isCoveredByKudzuIneligibleSetupSavings}
        onClose={() => { closeSkipModal(); setIsCoveredByKudzuIneligibleSetupSavings(false); }}
        isClimbg={true}
        Fullheight={true}
        subtitle={<div className="">
          <span className={SubHeadingStyles2}>{t('sorry')}, </span>
          <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
          <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
            {LineBreaks(t("you_need_savings_account"))}</p>
        </div>
        }
        content={<div>

          <img src={require('../../../assets/images/covered_by_kudzu_ineligible.png')}
            className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

          <div className="flex flex-col justify-center items-center gap-8 w-full">
            <button
              type="button"
              className=" w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
              onClick={canCreateSavingsAccount}
              autoFocus
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  canCreateSavingsAccount()
                  // setIsCoveredByKudzuIneligibleSetupSavings(false);
                  // setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true);
                  event.stopPropagation();
                }
              }}
            >
              {t('enroll_in_savings_account')}
            </button>
            <button
              type="button"
              className={`w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center p-4 sm:border-[0px]  border-[#2965FB] border-[2.5px]`}
              onClick={() => {
                setIsCoveredByKudzuIneligibleSetupSavings(false);
                navigate('/Climb/ClimbMenu');
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setIsCoveredByKudzuIneligibleSetupSavings(false);
                  navigate('/Climb/ClimbMenu');
                  event.stopPropagation();
                }
              }}
            >
              {t('back_to_climb')}
            </button>
          </div>
        </div>}
      />
      <Modal
        bgColor={'bg-[#FEFAEE]'}
        isOpen={isCoveredByKudzuIneligibleSetupSavingsPostPopup}
        onClose={() => { closeSkipModal(); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false); }}
        isClimbg={true}
        Fullheight={true}
        subtitle={<div className="">
          <span className={SubHeadingStyles2}>{t('lets_get_started').replace('!', '')} </span>
          <span className={SubHeadingStyles}>{t("with_creating_a_new_account")}</span>
          <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
            {t("set_aside_money_for_things_that_matter")}</p>
        </div>
        }
        content={<div>

          <img src={require('../../../assets/images/kudzu_piggy_bank.png')} className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

          <div className="flex flex-col justify-center items-center gap-8 w-full">
            <button
              type="button"
              className=" w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
              onClick={() => {
                setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false); dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('came_from_climb') } });
                navigate('/SetupSavingsAccount');
              }}
              autoFocus
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false);
                  navigate('/SetupSavingsAccount');
                  event.stopPropagation();
                }
              }}
            >
              {t('continue')}
            </button>
          </div>
        </div>}
      />
      <Modal
        isOpen={isCoveredByKudzuIneligible}
        onClose={closeCustomAlertModal}
        isClimbg={true}
        Fullheight={true}
        subtitle={<div className="">
          <span className={SubHeadingStyles2}>{t('sorry')}, </span>
          <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
          <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
            {t("you_need_minimum_50")}</p>
        </div>
        }
        content={<div>

          <img src={require('../../../assets/images/covered_by_kudzu_ineligible.png')}
            className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

          <div className="flex flex-col justify-center items-center gap-8 w-full">
            <button
              type="button"
              className="focus:outline-none   w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
              onClick={() => { navigate('/AccountServices/Spending/TransferMoney') }}
              autoFocus
            >
              {'Add Money to Savings'}
            </button>
            <button
              type="button"
              className="w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center p-4"
              onClick={() => {
                setIsCoveredByKudzuIneligible(false);
                setIsClimbFeaturesModalOpen(true);
                // navigate('/Climb/ClimbMenu');
              }}>
              {/* {'Maybe later'} */}
              {t('back_to_climb')}
            </button>
          </div>
        </div>}
      />
       <CustomAlertModel isOpen={showModel}
        onClose={closeCustomAlertModal1}
        image={modelImage}
        zIndex={300}
        highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
          <span className={`${HeaderMsgStyles} ${savingsTheme ? 'text-[#0C4437]' : 'text-black'}`}> {headerMsg}</span></p>}
        subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
        btn1Exists={btn1Exists}
        btn2Exists={btn2Exists}
        btn1Text={btn1Text} btn2Text={btn2Text}
        btn1TypeAcpt={btn1TypeAcpt}
        verificationFailure={() => { }}
        footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
        onAcptBtnClick={onAcptBtnClick}
        onCancelBtnClick={onCancelBtnClick}
        isBorderNone={isBorderNone}
        subFooter={isSubFooter && subComponent()}
      />
      {isLoading && <Loader />}
    </div>

  )
}

export default withOidcSecure(Climb)