import React, { useState } from 'react'
const FooterContent = (props) => {
    const { highlightText, subHeaderText, image, buttonEnabled, buttonText, buttonAction, footerBtnAction, isSpace, isSubheadingwidth,backgroundColor,btnBgColor,btnTextColor,highlightTextColor,subHeaderTextColor, subHeadingVisiblity } = props



    const climbFooterHome = () =>
        <div className={`${props?.backgroundColor ? `bg-[#EEF5F1]` :'bg-[#4B70B9]'} flex flex-col ${props?.borderDirection ? (props?.borderDirection === 'even') ? 'sm:rounded-tl-[4rem] sm:rounded-br-[4rem]' : 'sm:rounded-tr-[4rem] sm:rounded-bl-[4rem]' : 'sm:rounded-tl-[4rem] sm:rounded-br-[4rem]'}`}>
            <div className='md:py-15 sm:py-8 py-16'>
                <div className="sm:px-[7%] px-10 w-[100%] flex sm:flex-row sm:justify-between flex-col ">
                    <div className={`font-serif-pro ${props?.highlightTextColor ? `text-[${props?.highlightTextColor}]` :'text-[#ffffff]'} lg:w-[300px] md:w-[210px] sm:w-[138px] w-[250px] md:text-[32px] sm:text-[22px] text-[25px] font-normal md:leading-[38px] sm:leading-[32px] leading-[35px]`}>
                        {highlightText}
                    </div>
                    <p className={`${props?.subHeaderTextColor ? `text-[${props?.subHeaderTextColor}]` :'text-[#ffffff]'} ${subHeadingVisiblity && 'hidden sm:flex' } sm:mb-10 lg:text-18 font-[500] sm:leading-[19px] md:leading-[28px] md:text-[18px] sm:text-[13px] mt-2 text-[15px] w-[264px]  ${isSubheadingwidth ? 'lg:w-[400px] md:w-[351px] sm:w-[235px]' : 'lg:w-[404px] md:w-[404px] sm:w-[224px]'}`}>{subHeaderText}</p>
                </div>
                <div className='w-[100%] px-10 flex sm:flex-row sm:justify-between flex-col items-center sm:items-end md:mt-5'>
                    <img src={image} className='md:p-2 md:ml-10 md:w-[172px] md:h-[172px] sm:w-[100px] sm:h-[100px] w-[90px] h-[90px]'/>

                    <button className={`md:mr-5 font-[700] sm:mb-5 lg:w-[150px] md:w-[130px] sm:w-[118px] w-full lg:text-[18px] md:text-[18px] sm:text-[11px] text-[16px] font-hanken-grotesk ${props?.btnBgColor ? `bg-[${props?.btnBgColor}]`  : 'bg-[#ffffff]'} float-right rounded-full px-1 md:px-2 py-4 ${props?.btnTextColor ? `text-[${props?.btnTextColor}]` :'text-[#000000]'}`}
                        onClick={() => { footerBtnAction() }}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                footerBtnAction();
                            }
                        }}>{buttonText}</button>
                </div>
            </div>
        </div>

    return (
        <>
            {props?.type == 'climbFooterHome' ?
                climbFooterHome()
                :
                props?.type == 'AddSavings' ?
            //     <div className={`${props?.backgroundColor ? `bg-[#EEF5F1]`  : 'bg-[#E0E9EE]'} flex flex-col ${props?.borderDirection ? (props?.borderDirection === 'even') ? 'sm:rounded-tl-[4rem] sm:rounded-br-[4rem]' : 'sm:rounded-tr-[4rem] sm:rounded-bl-[4rem]' : 'sm:rounded-tl-[4rem] sm:rounded-br-[4rem]'}`}>
            //         <div className='sm:flex flex-row justify-around items-center w-full sm:px-0 px-[13%] pb-10 md:pt-16 pt-12'>
            //             <div className='md:w-[60%] sm:w-[40%] w-[100%]'>

            //                 <p className={`${props?.subHeaderTextColor ? `text-[${props?.subHeaderTextColor}]` : 'text-[#2965FB]'}  md:text-[32px] sm:text-[18px] text-[25px] md:leading-[38px] sm:leading-[24px] leading-[21px]
            //          font-normal font-serif-pro
            //           sm:w-[200px] w-full

            //            ${isSpace ? 'lg:w-[318px] md:w-[309px]' : 'lg:w-[350px] md:w-[350px]'}`}>

            //                     {highlightText}</p>

            //                 <p className={` font-hanken-grotesk md:text-[18px] sm:text-[12px] text-[14px] md:leading-[30px] sm:leading-[20px] leading-[22px] font-medium lg:mt-5 
            //        md:mt-4 sm:mt-4 mt-6 w-[264px]  
            //         ${isSubheadingwidth ? ' md:w-[351px] sm:w-[235px]' : ' md:w-[380px] sm:w-[220px]'}`}>{subHeaderText}</p>


            //             </div>
            //             <div className='sm:block flex justify-center'>
            //                 <img
            //                     src={image} alt="Image"
            //                     className="mr-2 lg:h-[120px] lg:w-[120px] md:h-[100px] md:w-[100px]  
            //             sm:h-[92px] sm:w-[92px] h-[120px] w-[120px]"
            //                 />
            //             </div>
            //         </div>

            //         <div className='flex flex-row justify-end w-full md:mt-10 sm:mt-0
            // sm:ml-[-49px] ml-0 sm:px-0 px-[13%] md:pb-16  pb-12'>
            //             {buttonEnabled && (
            //                 <div
            //                     className={`${props?.btnBgColor ? `bg-[${props?.btnBgColor}]`  : 'bg-[#2965FB]' } flex items-center text-white
            //             justify-center text-center w-auto px-[30px] md:h-[55px] sm:h-[30px] h-[30px] rounded-[89px]
            //             lg:text-[18px] md:text-[15px] sm:text-[13px] text-[14px] leading-[20px]
            //             md:leading-[46.09px] sm:leading-[22.8px] font-[700] font-hanken-grotesk cursor-pointer`}
            //                     onClick={footerBtnAction}
            //                     onKeyDown={(event) => {
            //                         if (event.key === "Enter") {
            //                             event.preventDefault();
            //                             footerBtnAction();
            //                         }
            //                     }}
            //                     tabIndex={0} // Set tabIndex here for the button
            //                 >
            //                     {buttonText}
            //                 </div>
            //             )}
            //         </div>

            //     </div> 
                    <div className={`${props?.backgroundColor ? `bg-[#EEF5F1]` : 'bg-[#4B70B9]'} flex flex-col ${props?.borderDirection ? (props?.borderDirection === 'even') ? 'sm:rounded-tl-[4rem] sm:rounded-br-[4rem]' : 'sm:rounded-tr-[4rem] sm:rounded-bl-[4rem]' : 'sm:rounded-tl-[4rem] sm:rounded-br-[4rem]'}`}>
                        <div className='md:py-15 sm:py-8 py-16'>
                            <div className="sm:px-[7%] px-10 w-[100%] flex sm:flex-row sm:justify-between flex-col items-center">
                                <div className={`font-serif-pro ${props?.highlightTextColor ? `text-[${props?.highlightTextColor}]` : 'text-[#ffffff]'} lg:w-[300px] md:w-[210px] sm:w-[138px] w-[250px] md:text-[32px] sm:text-[18px] text-[25px] font-normal md:leading-[38px] sm:leading-[24px] leading-[35px]`}>
                                    {highlightText}
                                </div>
                                <p className={`${props?.subHeaderTextColor ? `text-[${props?.subHeaderTextColor}]` : 'text-[#ffffff]'} font-medium sm:mb-10 lg:text-18 md:text-[18px] sm:text-[13px] mt-5 text-[15px] w-[264px]  ${isSubheadingwidth ? 'lg:w-[400px] md:w-[351px] sm:w-[160px]' : 'lg:w-[404px] md:w-[404px] sm:w-[224px]'} sm:block hidden `}>{subHeaderText}</p>
                            </div>
                            <div className='w-[100%] px-10 flex sm:flex-row sm:justify-between flex-col items-center sm:items-end mt-5'>
                                <img src={image} className='md:p-2 md:ml-10 ' style={{ height: 172, width: 172 }} />

                                <button className={`sm:mr-5 font-[700] sm:mb-5 lg:w-[165px] md:w-[150px] sm:w-[130px] w-full lg:text-[18px] md:text-[18px] sm:text-[11px] text-[16px] font-hanken-grotesk ${props?.btnBgColor ? `bg-[${props?.btnBgColor}]` : 'bg-[#ffffff]'} float-right rounded-full px-2 py-3 ${props?.btnTextColor ? `text-[${props?.btnTextColor}]` : 'text-[#000000]'}`}
                                    onClick={() => { footerBtnAction() }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            footerBtnAction();
                                        }
                                    }}>{buttonText}</button>
                            </div>
                        </div>
                    </div>
                :
                <div className={`${props?.backgroundColor ? `bg-[#EEF5F1]`  : 'bg-[#E0E9EE]'} flex flex-col ${props?.borderDirection ? (props?.borderDirection === 'even') ? 'sm:rounded-tl-[4rem] sm:rounded-br-[4rem]' : 'sm:rounded-tr-[4rem] sm:rounded-bl-[4rem]' : 'sm:rounded-tl-[4rem] sm:rounded-br-[4rem]'}`}>
                <div className='sm:flex flex-row justify-around items-center w-full sm:px-0 px-[13%] pb-8 md:pb-10 md:pt-16 pt-10'>
                    <div className='md:w-[60%] sm:w-[40%] w-[100%]'>

                        <p className={`${props?.subHeaderTextColor ? `text-[${props?.subHeaderTextColor}]` : 'text-[#2965FB]'}  md:text-[32px] sm:text-[18px] text-[25px] md:leading-[38px] sm:leading-[24px] leading-[30px]
                 font-normal font-serif-pro
                  sm:w-[200px] w-full

                   ${isSpace ? 'lg:w-[318px] md:w-[309px]' : 'lg:w-[430px] md:w-[430px]'}`}>

                            {highlightText}</p>

                        <p className={` font-hanken-grotesk md:text-20 sm:text-[12px] text-[14px] md:leading-[30px] sm:leading-[20px] leading-[22px] font-medium lg:mt-5 
               md:mt-4 sm:mt-4 mt-6 w-[264px]  
                ${isSubheadingwidth ? ' md:w-[351px] sm:w-[235px]' : ' md:w-[407px] sm:w-[220px]'}`}>{subHeaderText}</p>


                    </div>
                    <div className='sm:block flex justify-center'>
                        <img
                            src={image} alt="Image"
                            className="mr-2 lg:h-[120px] lg:w-[120px] md:h-[100px] md:w-[100px]  
                    sm:h-[92px] sm:w-[92px] w-[90px] h-[90px] my-10"
                        />
                    </div>
                </div>

                <div className='flex flex-row justify-end w-full md:mt-10 sm:mt-0
        sm:ml-[-35px] md:ml-[-49px] ml-0 sm:px-0 px-[13%] md:pb-12  pb-12'>
                    {buttonEnabled && (
                        <div
                            className={`${props?.btnBgColor ? `bg-[${props?.btnBgColor}]`  : 'bg-[#2965FB]' } flex items-center text-white
                    justify-center text-center w-auto px-[30px] sm:px-[20px] md:px-[30px] md:h-[55px] sm:h-[30px] h-[40px] rounded-[89px]
                    lg:text-[18px] md:text-[15px] sm:text-[11px] text-[14px] leading-[20px]
                    md:leading-[46.09px] sm:leading-[22.8px] font-[700] font-hanken-grotesk cursor-pointer`}
                            onClick={footerBtnAction}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    footerBtnAction();
                                }
                            }}
                            tabIndex={0} // Set tabIndex here for the button
                        >
                            {buttonText}
                        </div>
                    )}
                </div>

            </div>
            }
        </>
    )
}


export default FooterContent;
