import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../CustomComponents/Loader'
import DynamicCard from '../../../../CustomComponents/DynamicCard';
import { width } from '../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import { withOidcSecure } from '@axa-fr/react-oidc'
import CustomRadioButton from '../../../../CustomComponents/CustomRadioButton'
import checkbox_fill from "../../../../../assets/images/Checkbox-blue.png"
import checkbox_null from "../../../../../assets/images/Checkbox-light.png"
import logFirebaseEvents from '../../../../LogFirebaseEvents'

const NotificationTypeCard = ({ cardHeader, cardFooter, onBack, onNext }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [NotificationError, setNotificationError] = useState(null)
  const [notification, setNotification] = useState('')
  const [notificationError, setnotificationError] = useState(null)
  const [isNext, setIsNext] = useState(false);


  const NotificationTypeSMS = useSelector(state => state.spendAlertsPayload.sms)
  const NotificationTypeEmail = useSelector(state => state.spendAlertsPayload.email)
  const [notificationArray, setNotificationArray] = useState([{ displayText: t('text'), isSelected: NotificationTypeSMS }, { displayText: t('email'), isSelected: NotificationTypeEmail }])



  const [isLoading, setIsLoading] = useState(false);


  const setNotificationFun = (option) => {
    const selectedIndex = notificationArray.findIndex(
      (item) => item.displayText === option.displayText
    );
    notificationArray[selectedIndex] = { ...option, 'isSelected': !option?.isSelected }
    setNotificationArray([...notificationArray])
    if (option.displayText === "Email") {
      dispatch({
        type: 'ADD_SPEND_ALERTS',
        payload: { 'key': 'email', 'value': !option?.isSelected }
      });
    } else {
      dispatch({
        type: 'ADD_SPEND_ALERTS',
        payload: { 'key': 'sms', 'value': !option?.isSelected }
      });

    }
  }

  const handleBackClick = () => {
    logFirebaseEvents("SpendSense_Alert", "Back_Notification_Clicked", "Back_Notification_Clicked","", "")
    onBack("date")
  }
  const handleNextClick = () => {
    logFirebaseEvents("SpendSense_Alert", "Next_Notification_Clicked", "Next_Notification_Clicked","", "")
    //debugger;
    if (!NotificationTypeSMS && !NotificationTypeEmail) {
      setnotificationError('Choose Notification Type');
    } else {
      setnotificationError('');
      onNext("confimation")
    }

  }

  useEffect(()=>{
    if(!NotificationTypeSMS && !NotificationTypeEmail) setIsNext(false);
    else setIsNext(true);
  },[NotificationTypeSMS,NotificationTypeEmail])




  const children = () => {
    return (
      <>
        {cardHeader}

        <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4 sm:flex sm:flex-col '>
          <span>{t("sounds_good")} </span>
          <span className='text-secondary-3'>{t("spending_alert_notification_content2")}</span>
        </div>

        <div className='w-full flex justify-center'>
          <div className="sm:w-[80%] w-full">
            {/* BODY */}
            <div className='md:my-32 sm:my-16 mt-10 mb-[9rem] sm:mx-0 mx-4'>
              <div className='w-full flex flex-col justify-center'>
                <div className='sm:w-[60%] md:w-[50%] '>
                  <CustomRadioButton
                    title={t('spending_alert_notification_title')}
                    options={notificationArray}
                    onSelect={setNotificationFun}
                    fillIcon={checkbox_fill}
                    nullIcon={checkbox_null}
                    error={notificationError}
                  />

                </div>


                <p className='mt-4 font-hanken-grotesk text-[#262B3A] 
                 text-[12px] leading-[30px] 
                  font-normal'>{t('standard_message')}</p>
              </div>
            </div>
          </div>

        </div >
        {/* FOOTER */}

        {cardFooter(handleBackClick, handleNextClick,'','', isNext)}
        {/* ---------- */}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>
      {isLoading && <Loader />}
    </>
  )
}


export default withOidcSecure(NotificationTypeCard)


