import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../CustomComponents/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../../Layout/HomeLayout';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import { GENERATE_EXISTING_PHONE_EMAIL_OTP, GENERATE_OTP_CHANGE_PIN, GET_ADD_NEW_CARD_METADATA, GET_PIN_CHANGE_DETAILS, GET_SHIPPING_ID, LOCK_CARD_API, POST_PIN_CHANGE, REPLACE_CARD_API, REPORT_LOST_CARD_API, STATUS_SUCCESS_CODE, UNLOCK_CARD_API } from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import { retrieveData } from '../../../../helpers/utils/Utils';
import CardMenu from '../../CardMenu';
import Custom2FA from '../../../CustomComponents/Custom2FA';
import Modal from '../../../CustomComponents/Modal';
import { LOGIN_SESSION_KEY } from '../../../../Redux/reducers';
import Moment from 'react-moment';

import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../CustomComponents/Card/CardStyles";
import logFirebaseEvents from '../../../LogFirebaseEvents';


const ChangePin = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const selectedItem = cards?.[0]
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [generateBtnClicked, setGenerateBtnClicked] = useState(true); //Routing Number
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none w-[90%] md:w-[80%]";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [modelImage, setModelImage] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [subFooter, setSubFooter] = useState('');
    const [changepinUrl, setChangepinUrl] = useState('');
    const [ChangePinSuccess, setChangePinSuccess] = useState(false);
    const [shippingId, setShippingId] = useState('');
    const [countryCode, setCountryCode] = useState('USA')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const location = useLocation();
    const [UpdatedArray, setUpdatedArray] = useState(useSelector(state => JSON.parse(state.userCards)));


    const userId = useSelector(state => state.userId);
    const sessionKey = useSelector(state => state.loggedSessionKey);

    const params = location.state;
    const cardDetails = useSelector(state => state.selectedCardDetails);
    const [isBorderNone, setisBorderNone] = useState(true);
    const [btn1Focus, setBtn1Focus] = useState(false);
    const [btn2Focus, setBtn2Focus] = useState(false);
    const [showErrorImg, setshowErrorImg] = useState(false);


    useEffect(() => {
        getChangePinDetails()


    }, []);

    useEffect(() => {
        // Listen for messages from the embedded iframe
        const handleMessage = (event) => {
            // alert('1',event, event?.data)
            
            if (event.data == 0) {
                // Handle success case
                // alert('2',event, event?.data)

                setChangepinUrl('')
                setChangePinSuccess(true)
                callPostPinChangeApi(event.data)
                // showAlertModal(true, t("success_heading"), t("your_new_4_digit_pin_has_been_set"), '', true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/activate_change_lock.png'), '')   
            } else {
                // alert('3',event, event?.data)

                // Handle failure case
                setChangePinSuccess(false)
                callPostPinChangeApi(event.data)
                // showAlertModal(true, t("oops_exclamation"), '', 'pinchange Failed', true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '')
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            // Clean up the event listener
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    async function callPostPinChangeApi(status) {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        const url = POST_PIN_CHANGE + "cardKey=" + cardDetails?.CardKey + "&language=en";

        const body = {
            Success: status == 0 ? true : false,
            ProviderResponse: status == 0 ? "200-0" : ("400-" + status)
        }

        const response = await PostRequest(url, body, headers)
        const responseObj = JSON.parse(response)

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            if (responseObj.Response.Success) {
                showAlertModal(true, t("success_heading"), t("your_new_4_digit_pin_has_been_set"), '', true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/activate_change_lock.png'), '', false, false, true, false)
            } else {
                showAlertModal(true, t("oops_exclamation"), '', 'pinchange Failed', true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)
            }

        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)

        }
    }

    useKeyDown(() => { }, ["Enter"]);
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1Focus, btn2Focus) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setFooterMsg(footerMsg)
        setBtn1Focus(btn1Focus)
        setBtn2Focus(btn2Focus)
    }
    const handleCardAction = (type) => {
        if (type == t("lock_card")) {
            showAlertModal(true, t("are_you_sure"), t("this_card_will_be_locked"), t('once_you_lock_your_card_any_new_transactions_will_be_declined'), true, true, t('lock_card'), t("cancel"), true, require('../../../../assets/images/lock_green.png'), '', false, true, false, true)
        } else if (type == t("unlock_card")) {
            showAlertModal(true, t("ready_to_unlock_questionmark"), t("unlock_your_card_to_resume_normal_use"), t('once_you_unlock_your_card_all_locked'), true, true, t('unlock_card'), t("cancel"), true, require('../../../../assets/images/unlock_green.png'), '', false, true, true, false)
        } else if (type == t('replace_card')) {
            navigate('/CardServices/CardActionScreen/ReplaceCard', { state: { item: cardDetails } })
        } else if (type == t('change_pin')) {
            navigate('/CardServices/CardActionScreen/ChangePin', { state: { item: cardDetails } })
        } else if (type == t('view_card_details')) {
            navigate('/CardServices/CardActionScreen/ViewCard', { state: { item: cardDetails } })
        } else if (type == t('activate_card')) {
            navigate('/CardServices/CardActionScreen/ActivateCard', { state: { item: cardDetails } })
        }

    }
    const onAcptBtnClick = () => {
        if (btn1Text == t("lock_card")) {
            callApiToLockCard()
        } else if (btn1Text == t("unlock_card")) {
            callApiToUnlockCard()
        } else if (btn1Text == t('home')) {
            navigate('/home')
        } else if (btn1Text == t('back_to_card_services')) {
            navigate('/CardServices/CardActionScreen')
        }
    }
    const onCancelBtnClick = () => {
        if (btn2Text == 'Cancel') {
            setShowModel(false)
        } else if (btn2Text == t('back_to_card_services')) {
            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Card_Services", cardDetails.AccountTypeName+"_"+"BackTo_Services_Clicked", "BackTo_Services_Clicked","", "");
            navigate('/CardServices/CardActionScreen')
        }
    }
    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }
    const closeModel = () => {
        if (showModel && (btn1Text == t('back_to_card_services') || btn2Text == t('back_to_card_services'))) { navigate('/CardServices') } else { setShowModel(false); }
    }
    const verificationSuccess = () => {
        getChangePinDetails()
        // navigate('/settings/ManageExternalAccounts/AddExternalAccount')
    }
    const getChangePinDetails = async () => {

        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        if (cardDetails == null || cardDetails == undefined) {
            return;
        }
        setLoading(true)
        var url = GET_PIN_CHANGE_DETAILS + "?cardkey=" + cardDetails?.CardKey + "&sessionid="
        var response = await GetRequest(url, headers);

        setTimeout(() => {
            setLoading(false)
        }, 500);

        var parsedResponse = JSON.parse(response);
        if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
            setTimeout(() => {
                if (parsedResponse.Response.URL != "") {
                    const url = parsedResponse.Response.URL + '/' + parsedResponse.Response.SubmitterID + '/' + parsedResponse.Response.Token
                    setChangepinUrl(url)

                }
            }, 1000);

        } else {
            var arrMessages = parsedResponse.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)
            setTimeout(() => {
                // this.setState({
                //     loading: false,
                //     sixDigitCodeValidity: false,
                //     errorMessage: displayText
                // })
                setLoading(false)

                // this.animateTheView(false)
            }, 1000)
        }
    }
    const callApiToLockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails !== null ? cardDetails.CardKey : 0
        }
        PostRequest(LOCK_CARD_API, object, headers).then((response) => {

            setTimeout(() => {
                setLoading(false)
            }, 500);

            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {

                setTimeout(() => {
                    updateCardStatus(cardDetails !== null ? cardDetails.CardKey : 0, responseObj.Response)
                    showAlertModal(true, t("done_exclamation"), t("this_card_is_now_locked"), t('any_new_tx_will_be_declined'), true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/lock_green_tick.png'), '', false, false, true, false)
                }, 1000);

                // this.updateCardsInLocal(responseObj.Response, LOCK_CARD)

            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)
                setTimeout(() => {
                }, 1000);

            }

        });

    }
    const updateCardStatus = (cardKey, response) => {
        const updatedCardIndex = cards?.findIndex(card => card.CardKey === cardKey);
        if (updatedCardIndex !== -1) {
            const updatedCard = {
                ...cards[updatedCardIndex],
                Status: response.Status,
                SubStatus: response.SubStatus,
                StatusCode: response.StatusCode,
                SubStatusCode: response.SubStatusCode,
                // NewCardRequestedDate: response.NewCardRequestedDate !== undefined ? response.NewCardRequestedDate : Moment().format("YYYY-MM-DD") + "T" + "00:00:00",

            };
            const updatedArray = [updatedCard, ...cards.filter(card => card.CardKey !== cardKey)]
            setUpdatedArray(updatedArray);
            dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedArray) } });
            dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: updatedCard } });

        }
    };
    const callApiToUnlockCard = async () => {
        setLoading(true)
        setShowModel(false)


        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let object = {
            CardKey: cardDetails.CardKey
        }

        PostRequest(UNLOCK_CARD_API, object, headers).then((response) => {

            setTimeout(() => {
                setLoading(false)
            }, 500);

            let responseObj = JSON.parse(response);
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                setTimeout(() => {
                    updateCardStatus(cardDetails.CardKey, responseObj.Response)
                    showAlertModal(true, t("success_heading"), t("this_card_is_now_unocked"), t('you_can_use_your_card'), true, false, t('back_to_card_services'), '', true, require('../../../../assets/images/unlock_green_tick.png'), '', false, false, true, false)
                }, 1000);

                // this.updateCardsInLocal(responseObj.Response, UNLOCK_CARD)

            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, require('../../../../assets/images/warning.png'), '', false, false, true, false)

                setTimeout(() => {
                }, 1000);

            }

        });

    }

    const htmlpage = `<html>
    <head>
        <meta name="viewport" content="width=device-width" />
        <title>Pin</title>
    
        <style>
            @media only screen and (max-width: 600px) {
                h1 {
                    text-align: left;
                    font-size: 25px;
                    margin-left: 80px;
                    margin-top: 45px;
                }
    
                input {
                    color: darkblue;
                    background-color: lightblue;
                    border: none;
                    border-radius: 1px;
                    padding: 5px;
                    width: 30%;
                    height: 40px;
                    box-sizing: border-box;
                    margin-top: 10px;
                    margin-left: 41px;
                }
    
                button {
                    color: blue;
                    background-color: transparent;
                    border: none;
                    border-radius: 3px;
                    padding: 10px;
                    float: right;
                    margin-top: 200px;
                    margin-right: 60px;
                }
            }
    
            @media only screen and (min-width: 601px) {
                h1 {
                    text-align: center;
                    font-size: 24px;
                }
    
                input {
                    color: darkblue;
                    background-color: lightblue;
                    border: none;
                    border-radius: 1px;
                    padding: 5px;
                    width: 30%;
                    height: 40px;
                    box-sizing: border-box;
                    margin-top: 20px;
                    margin-left: 50px;
                }
    
                button {
                    color: blue;
                    background-color: transparent;
                    border: none;
                    border-radius: 3px;
                    padding: 10px;
                    float: right;
                    margin-top: 120px;
                    margin-right: 620px;
                }
            }
    
            ::placeholder {
                color: blue;
            }
    
            .blue {
                color: blue;
            }
    
            .black {
                color: black;
            }
        </style>
        <script>
            function setPin() {
                const pin = document.getElementById("pin").value;
                const repeatPin = document.getElementById("repeatPin").value;
    
                if (pin === null || pin === "" || repeatPin === null || repeatPin === "") {
                    alert('Please enter the details.')
                    return;
                }
    
                if (pin !== repeatPin) {
                    alert('Pin not match.')
                    return;
                }
    
                if (pin === "1234" && repeatPin === "1234") {
                    
                    if (/Android/i.test(navigator.userAgent)) {
                        Android.postMessage(0);
                    } else {
                        if (window.parent && window.parent.postMessage) {
                            parent.postMessage(0, "*");
                        }
                        window.webkit.messageHandlers.IOS.postMessage(0);
                    }
                } else {
                    if (/Android/i.test(navigator.userAgent)) {
                        Android.postMessage(-1);
                    } else {
                        if (window.parent && window.parent.postMessage) {
                            parent.postMessage(-1, "*");
                        }
                        window.webkit.messageHandlers.IOS.postMessage(-1);
                    }
                }
            }
    
        </script>
    </head>
    <body>
        <div>
            <h1>
                <span class="blue">Success!</span><span class="black"> Now let's<br />set your 4 digit PIN</span>
            </h1>
            <div style="width: 300px; margin: 0 auto; margin-top: 50px;">
                <input type="password" id="pin" placeholder="PIN" maxlength="4" style="margin-bottom: 10px;"><br />
                <input type="password" id="repeatPin" maxlength="4" placeholder="Repeat PIN"><br />
            </div>
            <div><button onclick="setPin()">Submit > </button></div>
        </div>
    </body>
    </html>`



    return (
        <>
            <HomeLayout>
                <CardMenu handleCardAction={handleCardAction} />
                <div className='md:w-[150px] sm:w-[30px] w-[0px]'></div>
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        CardRadius={true}
                        cardWidth={width.allpolicies}
                        HeaderRadius={true}
                        isWhite={true}
                        footer={true}
                        HeaderContent={false}>
                        {changepinUrl &&
                            <iframe className='py-20'
                                src={changepinUrl}
                                // srcDoc={htmlpage}
                                title="Embedded HTML"
                                width="100%"
                                height="800px" // Adjust height as needed
                            ></iframe>
                        }

                    </DynamicCard>
                </div>
                <Modal
                    isOpen={isOTPVerifyModalOpen}
                    onClose={closeOTPVerifyModal}
                    isLogo={false}
                    subHeaderMsg={<p className="font-hanken-grotesk modal-title lg:text-[18px] md:text-[17px] sm:text-[15px] text-[14px] font-normal cursor-pointer pointer-events-none">{t("standard_msg_and_data_rates_may_apply")}</p>}
                    title={<p className={HighlightMsgStyles}> {t("security_check_custom2fa")}</p>}
                    subtitle={<p className={HeaderMsgStyles}>{t("we_are_sending_text")}</p>}
                    content={<div className="mt-10"><Custom2FA type={t("change_pin")} onClose={closeOTPVerifyModal} verificationSuccess={verificationSuccess} verificationFailure={() => { }} /></div>}
                />

                {loading && <Loader />}
            </HomeLayout>
            {showModel &&
                <CustomAlertModel
                    isOpen={showModel}
                    onClose={closeModel}
                    image={modelImage}
                    highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                    HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                    subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                    btn1Exists={btn1Exists}
                    btn2Exists={btn2Exists}
                    btn1Text={btn1Text} btn2Text={btn2Text}
                    btn1TypeAcpt={btn1TypeAcpt}
                    verificationFailure={() => { }}
                    footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                    onAcptBtnClick={onAcptBtnClick}
                    subFooter={<p className={SubHeaderMsgStyles}>{subFooter}</p>}
                    onCancelBtnClick={onCancelBtnClick}
                    showErrorImg={showErrorImg}
                    isBorderNone={isBorderNone}
                    btn1Focus={btn1Focus}
                    btn2Focus={btn2Focus}
                />
            }
        </>
    )
}

export default withOidcSecure(ChangePin);
