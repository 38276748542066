import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../../Slider/Slider.css';
import { compareTime, convertFromMinutesSinceMidnight, convertToMinutesSinceMidnight, convertToTimeFormat } from '../../../../utils';
import timeDiffernce from '../../../../utils';


const TimeRangeSlider = ({ selectedMinTime, selectedMaxTime, onTimeChangeProp, isReset }) => {


  // debugger;
  const minTime = convertToTimeFormat("00:00:00")
  const maxTime = convertToTimeFormat("23:59:00")

  const [tooltipValues, setTooltipValues] = useState([null, null]);
  const [dragging, setDragging] = useState(false);

  const [timeRange, setTimeRange] = useState([
    selectedMinTime || minTime,
    selectedMaxTime || maxTime,
  ]);

  useEffect(() => {
    // Update the timeRange state when the selectedMinTime or selectedMaxTime props change
    // debugger;
    setTimeRange([selectedMinTime || minTime, selectedMaxTime || maxTime]);

    // Update tooltip values when the selectedMinTime or selectedMaxTime props change
    setTooltipValues([
      selectedMinTime ? selectedMinTime : null,
      selectedMaxTime ? selectedMaxTime : null,
    ]);
  }, [selectedMinTime, selectedMaxTime]);


  const onTimeChange = (values) => {
    // debugger;
    // Convert values to time objects
    const timeValues = values.map((value) => convertFromMinutesSinceMidnight(value));
    // Extract the minimum and maximum dates from the 'timeValues' array
    const [time1, time2] = timeValues;

    // Enforce the constraint: emdTime should not be before startTime
    if (compareTime(time1, time2) === 1) {
      // debugger;
      // If the maxTime is less than the minTime, swap values to prevent reversal
      timeValues[0] = time2;
      timeValues[1] = time1;
    }

    setTimeRange(timeValues);
    setTooltipValues(timeValues.map((value) => value));
    onTimeChangeProp(timeValues[0], timeValues[1]);
  };

  const tooltipFormatter = (value, index) => {
    // debugger;
    if (tooltipValues[index] !== null && dragging) {
      // If the tooltip value is not null and dragging is true, show the tooltip
      return value;
    } else {
      // Otherwise, hide the tooltip
      return '';
    }
  };


  //Calculate left % for Slider1 and Slider2
  const time1 = convertToMinutesSinceMidnight(timeRange[0]);
  const time2 = convertToMinutesSinceMidnight(timeRange[1]);
  const timeMin = convertToMinutesSinceMidnight(minTime);
  const timeMax = convertToMinutesSinceMidnight(maxTime);
  // const leftPercentageSlider1 = ((time1 - timeMin) / (time2-(120) - timeMin)) * 100;
  // const leftPercentageSlider2 = ((time2 - timeMin) / (timeMax - timeMin)) * 100;
  const leftPercentageSlider1 = ((time1 - timeMin) / (timeMax - timeMin)) * 100;
  const leftPercentageSlider2 = ((time2 - timeMin) / (timeMax - timeMin)) * 100;


  const handleStyle = {
    borderColor: '#007bff',
    backgroundColor: '#007bff',
    cursor: 'pointer'
  };

  return (
    <div className='flex justify-center w-full lg:w-[60%]'>
      <div className='w-[90%] lg:w-full '>
        <div className=' flex flex-row gap-0 items-center'>
          <div className=' w-[14px] h-[14px] -mr-1 rounded-full bg-[#94C3E8]' />

          <Slider
            min={convertToMinutesSinceMidnight(minTime)}
            max={convertToMinutesSinceMidnight(maxTime)}
            value={timeRange.map((time) => convertToMinutesSinceMidnight(time))}
            onChange={(values) => {
              setTimeRange(values.map((value) => convertFromMinutesSinceMidnight(value)));
              onTimeChange(values);
            }}
            onBeforeChange={() => setDragging(true)}
            onAfterChange={() => setDragging(false)}
            step={1}
            tipFormatter={(value, index) => tooltipFormatter(value, index)}
            range
            allowCross={false}
            handleStyle={[
              handleStyle,
              {
                ...handleStyle,
                cursor: compareTime(timeRange[1], maxTime) === 1 ? 'not-allowed' : 'pointer',
              }, // Disable handle 1 on max date
            ]}
            railStyle={{ backgroundColor: '#94C3E8' }} // Custom rail color
          />

          <div className=' w-[14px] h-[14px] -ml-1 rounded-full bg-[#94C3E8]' />
        </div>


        <div className="slider-tooltips">
          {/* Custom tooltips for the slider handles */}
          <div className="flex pt-4">
            <div
              className=" font-mono text-[14px] font-normal slider-tooltip"
              style={{
                left: `${leftPercentageSlider1}%`,

              }}
            >
              {tooltipValues[0] !== null && `${tooltipValues[0]}`}
            </div>
            <div
              className="font-mono tooltip-text block w-[50%] text-[14px] font-medium slider-tooltip"
              style={{
                left: `${leftPercentageSlider2}%`,
              }}
            >
              {tooltipValues[1] !== null && `${tooltipValues[1]}`}
            </div>
            <div className="font-mono tooltip-text block w-[50%] text-[12px] leading-[12px] font-[900] md:text-[14px] md:leading-[14px]  sm:text-[11px] sm:leading-[11px] sm:font-medium">
              {'12 AM'}
            </div>
            <div className="font-mono tooltip-text block w-[50%] text-[12px] leading-[12px] font-[900] md:text-[14px] md:leading-[14px]  sm:text-[11px] sm:leading-[11px] sm:font-medium text-right">
              {'11:59 PM'}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default TimeRangeSlider;
