import React, { useEffect, useRef, useState } from 'react'
import { width, height } from "../../CustomComponents/Card/CardStyles";
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import right_dropdown_arrow from '../../../assets/images/right_dropdown_arrow.png'
import CustomTextInput from '../../CustomComponents/CustomtextInput';
import Loader from '../../CustomComponents/Loader';
import Modal from '../../CustomComponents/Modal';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { useNavigate } from 'react-router-dom';
import { fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc';
import Footer from '../../Layout/Footer';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { useKeyDown } from '../../../hooks/useKeyDown';
import statement_page from '../../../assets/images/statement_page.png'
import shredding_statement from '../../../assets/images/shredding_statement.png'
import Bell from '../../../assets/images/Bell.png'
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import blue_drop_down from '../../../assets/images/blue_dropdown_arrow.png'
import HtmlDropDown from '../../CustomComponents/HtmlDropDown';
import { AGREEMENTS, DOWNLOAD_STATEMENT_OF_SELECTED_MONTH, GET_STATEMENTS_SELECTIONS, STATUS_SUCCESS_CODE, STATUS_SUCCESS_CODE_PDF_DOWNLOAD } from '../../../network/URLConstants';
import CustomDropdown from '../../CustomComponents/CustomDropDown';
const appConfigData = await fetchConfigarationData();



const TaxDocuments = ({ transactionDatas, onViewAllClick, viewAll }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const dispatch = useDispatch();
    const userId = useSelector(state => state.userId);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const selectedItem = cards?.[0]
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [type, setType] = useState('')
    const [footerMsg, setFooterMsg] = useState('');
    const [selectedDocument, setSelectedDocument] = useState({ "CardholderStatementKey": 0, "DocumentID": '', "DisplayText": 'Select' });
    const [selectedDuration, setSelectedDuration] = useState({ "CardholderStatementKey": 0, "DocumentID": '', "DisplayText": 'Select' });
    const [durationList, setDurationList] = useState([]);
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    useKeyDown(() => { }, ["Enter"]);

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    useEffect(() => { getTaxDocListFromServer() }, [])

    const leftBarComp = () => {
        return (
            <>
                <div className='lg:w-[260px] md:w-[183px] sm:w-[164px] w-full sm:mx-0 ml-[2.5rem]'>
                    <p className="text-white font-normal 
                lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] 
                sm:leading-[30px] leading-[31px]
      font-serif-pro border-b-[2.5px] border-[#4B70B9] lg:pb-14 md:pb-[50px]  border-opacity-[50%]
      sm:pb-[2.6rem] pb-[2.6rem] mb-10">
                        {t("tax_doc_left_Title")}</p>

                    <div className=' flex flex-col gap-10 cursor-pointer sm:pb-0 pb-10'>
                        {(cards != null && cards.length > 0 && cards[0].EnableStatements) && <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] 
                    leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px]
                 text-[#FEFAEE]'
                            onClick={() => { navigate('/Documents/Statements') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/Statements');
                                }
                            }}
                        >
                            {t("statements")}</p>}
                        <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] 
                    leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px]
                 text-[#FEFAEE]' onClick={() => { navigate('/Documents/Policies') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/Policies');
                                }
                            }}
                        >
                            {t("privacy_and_legal")}</p>

                        <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] 
                    leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px]
                 text-[#94C3E8]' onClick={() => { navigate('/Documents/TaxDocuments') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/TaxDocuments');
                                }
                            }}
                        >
                            {t("tax_documents")}</p>


                        <p className='font-hanken-grotesk font-[600]  lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] leading-[20px]
                     md:font-[700] md:leading-[16px] lg:leading-[16px]
                 text-[#FEFAEE]' onClick={() => { navigate('/Documents/Fees') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/Fees');
                                }
                            }}
                        >{t("fees")}</p>
                    </div>
                </div>
            </>
        )
    }
    const headerContent = () => {
        return (
            // <div className="flex flex-row justify-start mx-10 items-center mt-10">
            //     <div className='ml-10'>
            //         {/* Left Text */}
            //         <p className="text-xmd ">{t("external_accounts")}</p>
            //     </div>
            // </div>
            <div className="flex flex-row justify-between mx-10 items-center mt-10">
                <div className='ml-10'>
                    {/* Left Text */}
                    <p className="text-xl "></p>
                </div>
                <div onClick={(event) => { event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); }}
                    className='flex flex-row justify-end items-center mr-10 '>
                    {/* Image */}
                    {/* <img
                        src={print_img}
                        // alt="Image"
                        className="w-5 h-5 mr-2"
                    /> */}

                    {/* Right Text */}
                    {/* <p className="text-18 font-bold">{t("print")}</p> */}
                </div>
            </div>
        )
    }

    const getTaxDocListFromServer = async () => {
        if (cards == undefined || cards == null) {
            return
        }
        var url = GET_STATEMENTS_SELECTIONS + cards[0].CardKey;
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setLoading(true)
        GetRequest(url, headers).then((response) => {
            var parsedResponse = JSON.parse(response)
            if (parsedResponse.ResponseCode != STATUS_SUCCESS_CODE) {
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                let responseArr = parsedResponse.Response;
                setDurationList(responseArr)
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            }
        })

    }

    return (
        <HomeLayout>
            <LeftBar leftBarType='component' isPrimary={true} leftBarComp={leftBarComp()} />
            <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>
                <DynamicCard
                    cardWidth={width.allpolicies}
                    header={false}
                    footer={false}
                    headerContent={headerContent()}
                    footerContent={() => { }}
                    CardRadius={true}
                    HeaderRadius={true}
                    isWhite={true}>

                    <div className="flex sm:w-full lg:my-[2rem] md:my-[1.5rem]
                                         my-[1.5rem] font-[500] lg:text-[26px] md:text-[23px] sm:text-[21px]
                                          text-[20px]
                                          leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
                        {t("tax_documents")}
                    </div>
                    <div className='flex border-t-[2px] mb-5 border-[#A3CEBB] 
                    sm:mx-0 mx-4'></div>

                    <div className='flex sm:w-full flex-col text-[#2965FB] 
                           lg:text-[26px] md:text-[24px] sm:text-[22px] text-[25px]
                           md:leading-[38px] sm:leading-[30px] leading-[34px]  font-serif-pro font-[500] sm:mx-0 mx-4'>
                        {t("looking_for_a_tax_doc")} </div>

                    <div className='lg:w-[615px] flex sm:w-full flex-col text-[#262B3A] 
                          lg:text-[26px] md:text-[24px] sm:text-[22px] text-[25px]
                          md:leading-[38px] sm:leading-[30px] leading-[34px] font-serif-pro font-[500] sm:mx-0 mx-4'>{t("select_the_year_of_the_tax_doc")}
                    </div>

                    <div className='flex justify-center w-full'>
                        <div className='sm:w-3/4 w-full flex'>
                            <div className='md:pb-60 sm:pb-32 pb-40 items-center sm:mx-0 mx-4 w-full'>
                                <div className={`w-full flex flex-col self-center md:mt-16 mt-10 md:h-auto sm:h-auto h-auto sm:pb-0 pb-8`}>
                                    <CustomDropdown
                                        options={durationList}
                                        title={t('year')}
                                        onSelect={(option) => { setSelectedDuration(option) }}
                                        selectedAccountType={selectedDuration} />
                                </div>
                                {selectedDuration.DocumentID != '' &&
                                    <div className={`w-full flex flex-col self-center md:mt-16 mt-10 md:h-auto sm:h-auto h-auto sm:pb-0 pb-8`}>
                                        <CustomDropdown
                                            options={durationList}
                                            title={t('document')}
                                            onSelect={(option) => { setSelectedDocument(option) }}
                                            selectedAccountType={selectedDocument} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {(selectedDuration.DisplayText != 'Select' && selectedDocument.DisplayText != 'Select') &&
                        <div className='flex flex-col items-end md:pb-16 sm:pb-12 pb-8'>
                            <button className='font-hanken-grotesk w-auto h-auto m-5 text-[#2965FB] md:text-sm sm:text-[11px] text-[16px] font-[700] cursor-pointer'
                                onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); } }} onClick={(event) => { }} style={{}}>{t('view_document')}</button>
                        </div>
                    }
                </DynamicCard>
            </div>

            {/* <div className=' w-full'>

                <div className=' flex flex-col justify-center'>
                    <div className=' flex flex-row gap-32'>


                      
                    </div>
                </div>
                {loading && <Loader />}
            </div> */}
        </HomeLayout >

    )
}

export default withOidcSecure(TaxDocuments);
