import { withOidcSecure } from "@axa-fr/react-oidc";
import { useTranslation } from "react-i18next";
import threeDots from "../../assets/images/Ellipses Large3_dots.png"
import LogokGrey from "../../assets/images/LogokGrey.png"
import NotificationItem from "./NotificationItem";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchConfigarationData, retrieveData } from "../../helpers/utils/Utils";
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from "../../network/NetworkUtils";
import { GET_NOTIFICATIONS, NOTIFICATION_READ_ALERTS, NOTIFICATION_UNREAD_ALERTS, NOTIFICATION_UNREAD_READ_ALERTS, STATUS_SUCCESS_CODE } from "../../network/URLConstants";
import Loader from "../CustomComponents/Loader";
import HomeLayout from "../Layout/HomeLayout";
import DynamicCard from "../CustomComponents/DynamicComponent/DynamicCard.js";
import HomeHeader from "../CustomComponents/HomeHeader";
import { addLineBreaks } from "../../utils";
import logFirebaseEvents from "../LogFirebaseEvents/index.js"

const appConfigData = await fetchConfigarationData();

const Notifications = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [notifications, setNotifications] = useState(null);
    const [alerts, setAlerts] = useState(null);
    const [isShowNotificationContent, setIsShowNotificationContent] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [unreadCounts, setUnreadCounts] = useState(0);
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [isReadAllActive, setIsReadAllActive] = useState(false);
    const [isUnReadAllActive, setIsUnReadAllActive] = useState(false);
    const [userName, setUserName] = useState('');
    // const [rowsCountPerPage, setRowsCountPerPage] = useState(8);
    const [isFooter, setIsFooter] = useState(true);

    const accessToken = useSelector(state => state.accessToken);
    const userProfile = useSelector(state => JSON.parse(state.userProfile));
    const cards = useSelector(state => JSON.parse(state.userCards));
    const rowsCountPerPage = useSelector(state => JSON.parse(state.notificationCountPerPage));


    const fetchNotifications = async (isRead, isUnread) => { 
        logFirebaseEvents("Notification", "View_More_Notification_Clicked", "View_More_Notification_Clicked","", "");
        if (cards == null || cards == undefined) {
            return
        }
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;  //checking cardkey
            const rowsPerPage = rowsCountPerPage;
            const page = 1;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = GET_NOTIFICATIONS(cardKey, isRead, isUnread, rowsPerPage, page)
            const response = await GetRequest(url, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false);
                setAlerts(responseJson.Response.Alerts);
                dispatch({ type: 'UPDATE_NOTIFICATIONS', payload: { notifications: responseJson.Response.Alerts.Items } })
                dispatch({ type: 'UPDATE_NOTIFICATIONS_UNREAD_COUNT', payload: { notificationUnreadCount: responseJson.Response?.UnreadCount } })
                setNotifications(responseJson.Response.Alerts.Items);
                setUnreadCounts(responseJson.Response.UnreadCount)
                setIsShowNotificationContent(true);
                if(responseJson.Response.Alerts.TotalItemsCount<=8) setIsFooter(false)
            }

        } catch (error) {
        }
    }

    const markAllAsUnreadFun = async () => { 
      
        try {
            setIsLoading(true);
            const AlertIds = notifications.map((notification) => notification.ID);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            // const body = {
            //     cardKey: cardKey,
            //     AlertIds: AlertIds,
            // };
            const url = NOTIFICATION_UNREAD_READ_ALERTS(cardKey, false);
            // const url = NOTIFICATION_UNREAD_ALERTS;
            const response = await PostRequest(url, "", headers);
            // const response = await PostRequest(url, body, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false);
                fetchNotifications(true, true);

            }

        } catch (error) {
        }

    }
    const markAllAsReadFun = async () => { 

        try {
            setIsLoading(true);
            // const AlertIds = notifications.map((notification) => notification.ID);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            // const body = {
            //     cardKey: cardKey,
            //     AlertIds: AlertIds,
            // };
            // const url = NOTIFICATION_READ_ALERTS;
            const url = NOTIFICATION_UNREAD_READ_ALERTS(cardKey, true);

            const response = await PostRequest(url, "", headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false);
                fetchNotifications(true, true);
            }

        } catch (error) {
        }

    }



    const handleUnreadClick = () => {
        logFirebaseEvents("Notification", "Mark_Unread_Clicked", "Mark_Unread_Clicked","", "");
        if((notifications !== null || alerts.TotalItemsCount !== 0)){
            setIsUnReadAllActive(prev => !prev)
            setIsReadAllActive(false)
            if (notifications) {
                markAllAsUnreadFun();
            }

        }
    }

    const handleReadClick = () => {
        logFirebaseEvents("Notification", "Mark_Allread_Clicked", "Mark_Allread_Clicked","", "");
        if((notifications !== null || alerts.TotalItemsCount !== 0)){
            setIsReadAllActive(prev => !prev)
            setIsUnReadAllActive(false)
            if (notifications) {
                markAllAsReadFun();
            }

        }
    }

    const handleViewMoreClick = () => {  
        const totalItemsCount = alerts?.TotalItemsCount;
        const remainingItemCounts = totalItemsCount - rowsCountPerPage;
        let updatedRowCounts;
  
         if (remainingItemCounts > 8) {
            updatedRowCounts = rowsCountPerPage + 8;
        } else {
            updatedRowCounts = rowsCountPerPage + remainingItemCounts;
            setIsFooter(false);

        }
        // setRowsCountPerPage(updatedRowCounts);
        dispatch({ type: 'UPDATE_NOTIFICATIONS_COUNT_PER_PAGE', payload: { notificationCountPerPage: updatedRowCounts } })


    }


    useEffect(() => {
        if (userProfile !== undefined && userProfile != null) {
            setUserName(userProfile.FirstName);
        }
        //get read and unread notifications
        fetchNotifications(true, true);
    }, [rowsCountPerPage])

    useEffect(() => {
        if (alerts && alerts.TotalItemsCount === 0) setIsFooter(false)
    }, [alerts])

    const Headingcom = () => {
        return (
            <div className="relative">
                <div className="flex flex-row justify-between mx-10 my-6 md:m-10">
                    <div className="flex flex-row gap-2 items-center ">
                        <p className='font-hanken-grotesk font-[600] text-[16px] leading-[26px] sm:text-[15px] sm:leading-[23px] md:font-[500] md:text-[26px] md:leading-[36px]'>{t("notifications")}</p>
                        {unreadCounts !== 0 &&
                            <div className="md:h-[40px] md:w-[40px] h-[32px] w-[32px] bg-submit-btn 
                            rounded-full flex justify-center items-center">
                                <p className='font-hanken-grotesk font-[700] sm:font-[900] 
                                text-[12px] leading-[12px] md:text-[19.2px]
                                 md:leading-[19.2px] text-white'>{unreadCounts}</p>
                            </div>
                        }

                    </div>
                    <div className="my-auto cursor-pointer">
                        <img src={threeDots} alt="Three Dots" onClick={() => setIsShowDropdown(prev => !prev)} />

                    </div>
                </div>
                {isShowDropdown &&
                    <div className=" absolute right-0 h-[114px] w-[160px] md:w-[185px] z-50 rounded-bl-[1rem] bg-blue-sky ">
                        <div className=" flex flex-col cursor-pointer w-full h-full">
                            <div className={`h-1/2  py-5 px-6 ${(notifications === null || alerts.TotalItemsCount === 0) ? 'text-secondary-3 opacity-25 bg-[#E6E6E6]' : isUnReadAllActive ? 'text-[#2965FB] bg-blue-sky' : 'text-white  bg-dropdown-sky '} `} onClick={handleUnreadClick}>
                                <p className='font-hanken-grotesk font-[600] text-[12px] leading-[18px] sm:text-[10px] sm:leading-[15px] md:text-[14px] md:leading-[21px]'>{t("mark_all_unread")}</p>
                            </div>
                            <div className={` h-1/2  rounded-bl-[1rem] py-5 px-6 ${(notifications === null || alerts.TotalItemsCount === 0) ? 'text-secondary-3 opacity-25 bg-[#E6E6E6]' : isReadAllActive ? 'text-[#2965FB] bg-blue-sky' : 'text-white  bg-dropdown-sky '}`} onClick={handleReadClick}>
                                <p className='font-hanken-grotesk font-[600]  text-[12px] leading-[18px] sm:text-[10px] sm:leading-[15px] md:text-[14px] md:leading-[21px]'>{t("mark_all_read")}</p>
                            </div>

                        </div>
                    </div>

                }
            </div>

        )
    }

    const children = () => {
        return (
            <>
                {(isShowNotificationContent) &&
                    <div className="flex justify-center sm:mx-0 mx-10">
                        <div className='flex flex-col justify-center w-full sm:w-[80%] md:w-[80%] z-0'>
                            {
                                notifications?.map((notification) => {
                                    return <NotificationItem notification={notification} />
                                })

                            }

                        </div>
                    </div>
                }

                {(notifications === null || alerts.TotalItemsCount === 0) &&
                    <div className=" w-full h-[600px] flex flex-col justify-center items-center gap-8 ">
                        <div>
                            <img src={LogokGrey} alt="LogokGrey" />
                        </div>
                        <div className="flex flex-col justify-center items-center ">
                            <p className='font-hanken-grotesk font-[500] text-[12px] leading-[18px] lg:text-[18px] lg:leading-[28px] text-secondary-3 opacity-40'>{t("no_notification_status1")}</p>
                            <p className='font-hanken-grotesk font-[500] text-[12px] leading-[18px] lg:text-[18px] lg:leading-[28px] text-secondary-3 opacity-40'>{t("no_notification_status2")}</p>
                        </div>
                    </div>
                }
            </>
        )
    }

    const Footer = () => {
        return (
            <div className=" flex justify-center my-16">
                <p className='font-hanken-grotesk font-[600]
                 text-[12px] leading-[20px] md:font-[700] 
                 md:text-[16px] md:leading-[16px] text-[#262B3A]
                  cursor-pointer' onClick={handleViewMoreClick}>
                    {t("viewAll")}
                </p>
            </div>
        )
    }

    const getHeaderText = () => {
        return (addLineBreaks(t("notification_heading")));
        // return (`${t('hello_user').replace("{USER}", userName)}\n${t("notification_heading")}`);
    };


    return (

        <HomeLayout>
            <HomeHeader heading={getHeaderText()} isHeaderCaption={true} />
            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight '>

                <DynamicCard
                    headerContent={Headingcom()}
                    cardHeight='content-fit'
                    cardContent={children()}
                    footerContent={Footer()}
                    isCardFooter={isFooter}
                />
            </div>

            {isLoading && <Loader />}


        </HomeLayout >


    )
}

export default withOidcSecure(Notifications)