import React, { useState, useEffect, useRef } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { width } from "../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {PROGRAM_CONTENT, STATUS_SUCCESS_CODE} from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import { LineBreaksSpan } from '../../../utils';
import { retrieveData } from '../../../helpers/utils/Utils';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import Modal from '../../CustomComponents/Modal';
import { useKeyDown } from '../../../hooks/useKeyDown';
import { useNavigate } from 'react-router-dom';
import Arrow from '../../../assets/images/arrow.png';
import Loader from '../../CustomComponents/Loader';


const SavingsTermsAndConditions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook to access translation functions
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [showAgreementsModel, setShowAgreementsModel] = useState(false);
    const [policyURL, setPolicyURL] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const [termsAccepted, setTermsAccepted] = useState(false)
    const checkbox1Ref = useRef(null);
    const button1Ref = useRef(null);

    const [infoMessage, setinfoMessage] = useState()



    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                const elements = [checkbox1Ref, button1Ref];
                const isAnyElementFocused = elements.some(ref => ref.current === document.activeElement);
                if (!isAnyElementFocused) { handleConfirm() }
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);




    const handleConfirm = () => {
        alert("final continue clicked")
        if (termsAccepted) {
            setinfoMessage('')
            dispatch({ type: 'SAVINGS_TERMS_CONDITIONS', payload: { savingsTermsConditions: true } });
            setTimeout(() => {
                navigate('/SetupSavingsAccount/SavingsSSNSetup')
            }, 1000);
        } else { setinfoMessage(t('please_review_certify_under_penalty_of_perjury')) }

    }



   
    const getAgreementsBasedOnType = async (selectedAgreementType) => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setIsLoading(true)

        let finalUrl = (PROGRAM_CONTENT + "?accountType=32&language=en&contentType=" + selectedAgreementType.url + "&programKey=200")
        var response = await GetRequest(finalUrl, headers)
        var responseObj = JSON.parse(response)
        setIsLoading(false)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setPolicyURL(responseObj.Response.Data)
        } else {
            setTimeout(() => {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                // setError(displayText)
            }, 500);
        }
    }



    useKeyDown(() => {

    }, ["Enter"]);

    return (
        <>
            <HomeLayout>
                <Modal
                    isIframe={true}
                    isOpen={showAgreementsModel}
                    onClose={() => { setShowAgreementsModel(false); }}
                    title={false}
                    content={<div className="w-full h-full   md:pt-0 sm:pt-6  pt-16" style={{ height: window.innerHeight }}>
                        <iframe src={policyURL} id="policyIframe" title="Embedded Website" className="w-full" style={{ height: window.innerHeight }} />
                    </div>
                    } />

                <div className='sm:contents hidden'>
                    <HomeHeader heading={<p className={`text-white md:text-[32px] sm:text-[18px] 
                text-[27px] font-[400] md:leading-[38px] sm:leading-[24px] leading-[31px] font-family:
                 Hanken Grotesk`}>Next, <span className={`text-[#94C3E8] md:text-[32px] sm:text-[18px]
                  text-[27px] font-[400] md:leading-[38px] sm:leading-[24px] leading-[31px] 
                  font-family:Hanken Grotesk`}>{(t('to_proceed_review_your_information'))}</span></p>}
                        isHeaderCaption={true} />
                </div>

                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        footer={false}
                        CardRadius={true}
                        HeaderRadius={true}
                        mblCardRadius={true}
                        cardWidth={width.homeCard}>
                        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '560px', width: '75%', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }} className='mx-auto sm:mt-10'>
                            <div style={{ minHeight: '560px' }}>

                                <div className="sm:hidden block lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] lg:text-[32px] md:text-[28px]
                                 sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[32px] font-serif-pro w-[260px] 
                                 h-auto mb-[2rem] sm:px-0 px-4">
                                    <p className='text-[#2965FB]'>{t('next')},
                                        <span className='text-[#262B3A]'> {(t('to_proceed_review_your_information'))}</span>
                                    </p>
                                </div>


                                <div className="sm:block hidden lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                    lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-serif-pro sm:mx-0 mx-4">
                                    {LineBreaksSpan(t('savings_account_terms'))}</div>



                                {/* <div className='border-t-[2.5px] border-[#A3CEBB] 
                        flex justify-center sm:mx-0 mx-4'></div> */}

                                <p className="lg:my-[2rem] mt-10 md:my-[1.5rem] my-[1.5rem] lg:text-[20px] lg:w-[451px] md:text-sm sm:text-[12px] text-[14px] font-hanken-grotesk font-medium sm:mx-0 mx-4">
                                    {t('please_review_and_accept')}</p>
                                <div class="flex mb-4 lg:my-[4rem] md:my-[4rem] my-[4rem] sm:pl-[0px] pl-[20px]" >
                                    <div>
                                        <input
                                            id="deposit-savings-checkbox"
                                            type="checkbox"
                                            class="checkbox-round md:mt-[6px] mt-[4px]"
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') { 
                                                    event.preventDefault();
                                                    setTermsAccepted(!termsAccepted); 
                                                    if (termsAccepted) { 
                                                        setinfoMessage(t('please_review_certify_under_penalty_of_perjury')) } 
                                                    else { setinfoMessage(''); button1Ref.current.focus(); } }
                                            }}
                                            value=""
                                            ref={checkbox1Ref}
                                            checked={termsAccepted}
                                            autoFocus
                                            onChange={(event) => { setTermsAccepted(!termsAccepted); if (termsAccepted) { setinfoMessage(t('please_review_certify_under_penalty_of_perjury')) } else { setinfoMessage(''); button1Ref.current.focus(); } }}
                                        ></input>
                                    </div>
                                    <div className='ml-4 sm:ml-2 md:ml-4'>
                                        <label onClick={() => { setTermsAccepted(!termsAccepted); }} onKeyDown={(event) => { if (event.key == 'Enter') { setTermsAccepted(!termsAccepted); } }} for="deposit-savings-checkbox" class="font-hanken-grotesk  md:text-sm sm:text-[12px] text-[14px] md:font-[540] font-medium text-gray-900 dark:text-gray-300 cu">{t('electing_open_savings_account')}
                                            <span tabIndex={2} className=' ml-1 text-blue-500 cursor-pointer font-bold'
                                                onClick={() => { setTermsAccepted(!termsAccepted); setShowAgreementsModel(true); getAgreementsBasedOnType({ name: 'Savings Account Agreement', url: 'TnC', accType: 32, CardKey: cards?.[0]?.CardKey }) }}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Enter") {
                                                        event.preventDefault();
                                                        setShowAgreementsModel(true);
                                                        setTermsAccepted(!termsAccepted);
                                                        getAgreementsBasedOnType({ name: 'Savings Account Agreement', url: 'TnC', accType: 32, CardKey: cards?.[0]?.CardKey })
                                                    }
                                                }}>{t('savings_account_aggrement')}</span>.
                                        </label>
                                    </div>
                                </div>
                                {/* {infoMessage &&
                                    <div className={`mt-2 mb-2`}>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                } */}
                                <p className="lg:my-[4rem] mt-20 md:my-[4rem] my-[4rem] md:text-sm sm:text-[12px] md:font-[540] sm:font-medium  text-[14px] font-hanken-grotesk font-[600] sm:mx-0 mx-4 sm:w-auto w-[291px]">
                                    {t('by_tapping_continue_i_am_confirming')}
                                </p>
                                <div className='lg:mb-[4rem] md:mb-[4rem] mb-[4rem] flex flex-row justify-end lg:mt-20 md:mt-16 sm:mt-6 sm:mx-0 mx-4 lg:mb-10'>
                                    <button
                                        type="button"
                                        // autoFocus
                                        ref={button1Ref}
                                        className={`font-hanken-grotesk sm:font-bold font-[600]
                                lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] ${termsAccepted ? 'text-[#2965FB]' : 'text-[#94C3E8]'}
                                 cursor-pointer flex items-center`}
                                        onClick={() => { handleConfirm() }}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                handleConfirm()
                                            }
                                        }}
                                    >
                                        {t('continue')}<img
                                            src={Arrow}
                                            className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px] h-[14px] ml-1"
                                            style={{ opacity: termsAccepted ? 1 : 0.5 }}
                                            alt="Arrow" />
                                    </button>
                                </div>

                            </div>
                        </div>

                    </DynamicCard>

                </div>

                {isLoading && <Loader />}
            </HomeLayout>
        </>
    )
}

export default SavingsTermsAndConditions;
