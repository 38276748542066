

import React, { useState, useEffect } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../CustomComponents/Loader';
import { UPDATE_CARDNAME, STATUS_SUCCESS_CODE, CAN_CREATE_SAVINGS_ACC, STUDENT_SIGNUP_EMAIL_ACCOUNT, STUDENT_SIGNUP_UNIVERSITY_SELECTION, STUDENT_UNIVERSITY_UPDATE } from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import { addLineBreaks, displayErrorMessage, displaySuccessMessage, isEmailValid, MoreServices } from '../../../../utils';
import { checkEmailExtension, fetchConfigarationData, retrieveData } from '../../../../helpers/utils/Utils';
import LineBreaks from '../../../../utils';
import HomeLayout from '../../../Layout/HomeLayout';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import Check from '../../../../assets/images/check_3x.png'
import beneficiarysaving from '../../../../assets/images/beneficiarysaving.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomInput from '../../../CustomComponents/FormInputs';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import AsyncSelect from 'react-select/async';


const appConfigData = await fetchConfigarationData();
const NonEditUniversity = ({SenduniversityName,opentheStudentEmail}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook to access translation functions
  const cards = useSelector(state => JSON.parse(state.userCards));
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [isLoading, setIsLoading] = useState(false);

  const selectedItem = cards?.[0]
  const [newName, setNewName] = useState((selectedItem != undefined && selectedItem != null) ? selectedItem.NickName : "");

  const [loading, setLoading] = useState(false)
  const [infoMessage, setinfoMessage] = useState('');
  const [responseCode, setResponseCode] = useState('');
  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [modelImage, setModelImage] = useState('')
  const [savingsTheme, setSavingsTheme] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [isSubFooter, setIsSubFooter] = useState(false);
  const [isSubComponent, setIsSubComponent] = useState(false);
  const [showErrorImg, setshowErrorImg] = useState(false);
  const [textMessage, setTextMessage] = useState(false);
  const [btn1Focus, setBtn1Focus] = useState(false);
  const [btn2Focus, setBtn2Focus] = useState(false);

  //email state value
  const [message, setMessage] = useState('');
  const [editSchoolNameClicked,setEditSchoolNameClicked] = useState(false);
  const [universityData, setUniversityData] = useState([]); 
  const [universityName,setUniversityName] = useState();
  const [selected, setSelected] = useState(null);


  const customStyles = {

    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: '18px',
      color: state.isFocused && !state.isSelected ? 'white' : provided.color,
      color: state.isSelected && !state.isFocused ? 'black' : provided.color,
      backgroundColor: state.isFocused ? '#94C3E8' : provided.backgroundColor,
      backgroundColor: state.isSelected ? '#94C3E8' : state.isFocused ? '#94C3E8' : null,
    })

  }

  const showCustomAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
    // setShowAlertModal(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
  }
  const { watch } = useForm();

  const moreServicesArray = [
    { name: 'Configure Notifications', actionType: '', path: '/Settings/notificationConfigure' },
    { name: 'Manage External Accounts', actionType: '', path: '/Settings/ManageExternalAccounts' }
  ];
  const subObjectsArray = cards?.map(card => {
    return {
      AccountTypeName: card.AccountTypeName,
      CardKey: card.CardKey,
      OpenDate: card.ApplicationDate
    };
  });
  if (savingsCardAvailable == false) {
    const staticObject = {
      AccountTypeName: t('menu_savings'),
      CardKey: '',
      OpenDate: 'empty'
    };
    // subObjectsArray?.push(staticObject);
    subObjectsArray?.splice(1, 0, staticObject);
  }



  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, textMessage, isSubComponent, btn1Focus, btn2Focus) => {
    setshowErrorImg(showErrorImg)
    setisBorderNone(isBorderNone)
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setFooterMsg(footerMsg)
    setTextMessage(textMessage)
    setIsSubComponent(isSubComponent)
    setBtn1Focus(btn1Focus)
    setBtn2Focus(btn2Focus)
  }

  const schema = yup.object().shape({
    currentPassword: yup.string()
      .required("Current password is required"),
    newPassword: yup.string()
      .required("New password is required")
      // .matches(
      //     regexpression, "The password you entered does not meet the requirements as listed below."
      // )
      .notOneOf([yup.ref('oldPassword'), null], "New password must be different from the current password"),
    repeatPassword: yup.string()
      .required("Confirm new password is required")
      .oneOf([yup.ref('newPassword'), null], t("repeat_password_error_msg")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }, control, clearErrors,
    getValues,
    setFocus,
    setError
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    // defaultValues: { newPassword: "newPassword@12" }
  });



  const editPreferredName = () => {
    setinfoMessage("")
    setEditSchoolNameClicked(true)

}

async function callApi(value) {
  if(value.length>=3){
  const deviceId = await retrieveData('updatedDeviceId');
  const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
  const url = STUDENT_SIGNUP_UNIVERSITY_SELECTION(value, 4, 1);
  const response = await GetRequest(url, headers); 
  const responseObj = JSON.parse(response);
  if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) { 
    const universities = responseObj.Response.Universities.map(uni => ({
      label: uni.Name,
      value: uni.ID
    }));
    universities.push({label: "Other" ,value: 0});  
    setUniversityData(universities);
    console.log("unviersites"+JSON.stringify(universities));
    return universities;
  } 
  } 
}


const handleSelectChange = (value) => {
  dispatch({ type: 'STUDENT_UNIVERSITY_CODE', payload: { studentUniversityCode: value }  });
  if (value === 0) {
    setUniversityName(true);
  } else {
    setUniversityName(false);
  } 
}

const handleInputChange = (e) =>{
  const universityNameVal = e.target.value
  if(universityNameVal){
   dispatch({ type: 'STUDENT_UNIVERSITY_NAME', payload: { studentUniversityName: universityNameVal }  });
   setUniversityName(universityNameVal)
   clearErrors('universityName');
  } 
}

async function studentUniversityUpdateApi() {
  debugger;
   if(selected > 0 || universityName !== ""){
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    setIsLoading(true);
  
    var dataObject = {
      // 'GraduationDate': graduationDate.split("/")[1] + "-" + graduationDate.split("/")[0] + "-01"
      "applicationId": appConfigData.CLIENT_ID,
      "language": "en",
      'GraduationDate': ""
  }
        if (universityName != "" && universityName != undefined) {
            dataObject.OtherName = universityName 
        } 
        else {
            dataObject.Code = selected  
        }
    debugger;
    const cardKey = cards !== null ? cards[0].CardKey : 0;
    const url = STUDENT_UNIVERSITY_UPDATE(cardKey);
    const response = await PostRequest(url, dataObject, headers);
    debugger;
    let parsedResponse = JSON.parse(response);
    setIsLoading(false);
    if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
      showAlertModal(true, t("success"), t("detilsudated"),
        "", true, false, t('home'), t("cancel"), true,
        require('../../../../assets/images/K.png'), '', false, true, '', false, false, true);
    } else {
      var arrMessages = parsedResponse.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;
      setMessage(displayText);
      setIsLoading(false);
    }
  
   }
}

const closeModel = () => {
  setShowModel(false); 
}
///modal navigation
const onAcptBtnClick = () => {
  navigate('/home');
}


  return (
    <>
      <HomeLayout>
        <HomeHeader heading={LineBreaks(t("settingsEmailTittle"))} isHeaderCaption={true} />
        <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
          <DynamicCard
            header={false}
            footer={false}
            CardRadius={true}
            HeaderRadius={true}
            isWhite={true}
            cardWidth={width.homeCard} >

            <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                    lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px]
                     leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
              {t('connect_school')}
            </div>

            <div className='border-t-[2.5px] border-[#A3CEBB] flex justify-center
             flex-col sm:mx-0 mx-4'></div>
           
            <div className='h-[400px] sm:px-0 px-4'>
              <div class="md:my-16 sm:my-10 my-12">

                <div className='flex w-full items-center justify-between md:pb-4 pb-4'>
                  <p class="font-serif-pro text-[#262B3A] lg:text-[26px] 
                md:text-[22px] sm:text-[20px] text-[20px]">Student</p>
                  <img src={Check} className='w-[20px] h-[20px]' />
                </div>

                {!editSchoolNameClicked &&
                  <><p class="font-hanken-grotesk  
                lg:text-[16px] md:text-[15px] sm:text-[13px] 
                text-[14px] md:font-[500] sm:font-[700] font-[500] leading-[26px] md:mb-6 mb-2">
                 {SenduniversityName}</p>
                <div class="bg-[#94C3E8] bg-opacity-50 h-0.5 min-h-1 w-full">
                </div>
                {/* <button onClick={editPreferredName}> */}
                <button onClick={opentheStudentEmail}>
                  <p class="pt-5 font-hanken-grotesk text-[#2965FB] lg:text-[16px] md:text-[15px] sm:text-[13px] text-[13px] font-[700]">Edit</p>
                </button>
                </>}

          {editSchoolNameClicked &&
            <div className=' flex w-full items-baseline'>  
              <AsyncSelect
                cacheOptions={true}
                loadOptions={callApi}
                defaultOptions={universityData}
                placeholder='Select'
                onInputChange={(data) => {
                console.log(data);
                }}
                onChange={(data) => {
                  console.log("slected"+data.value)
                  handleSelectChange(data.value);
                  setSelected(data.value)
                }} 
                styles={customStyles}
              /> 
            </div>}

             {universityName && 
               <div className='md:mt-16 mt-12 mb-12'>
                {/* <p className='font-hanken-grotesk text-[#262B3A] 
                  uppercase lg:text-[13px] md:text-[9px] 
                  sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-6 pb-5
                  opacity-[50%] '>{t('selectUniversityName')}</p> */}
                      <CustomInput
                        name="universityName"
                        label="School Name"
                        register={register}
                        error={errors.universityName}
                        type="text"
                        onChange={handleInputChange}
                        value={SenduniversityName}
                      />
                      {/* <div className='mb-4'>
                      {errors.universityName && (
                        displayErrorMessage(errors.universityName.message)
                      )}
                      </div> */}
               </div>   
                 }

       {editSchoolNameClicked &&
                <button onClick={() => { studentUniversityUpdateApi() }}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Enter") {
                                                        event.preventDefault();
                                                        studentUniversityUpdateApi();
                                                    }
                                                }}
                                                className="pt-5 font-hanken-grotesk text-[#2965FB]
              lg:text-[16px] md:text-[15px] 
              sm:text-[13px] text-[13px]
                font-[700]">{t("update")}
                  </button>
                  }


                  </div> 
            </div>

            {loading && <Loader />}
          </DynamicCard>
        </div>

        {isLoading && <Loader />}

      </HomeLayout>
      {showModel && (
        <CustomAlertModel
          isOpen={showModel}
          onClose={closeModel}
          Fullheight={true}
          image={modelImage}
          highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
            <span className={`${HeaderMsgStyles} text-black`}> {headerMsg}</span></p>}
          subHeaderMsg={
            <>
              {subHeaderMsg && (<p className={SubHeaderMsgStyles}><p>{t("pl_contact_customer")} </p>
                <p className='sm:w-[465px]'><span className='text-[#2965FB] font-bold'>{t("support_mail")}</span><span>{t("further_assistance")}</span></p>
              </p>)}
              {subHeaderMsg == '' && (<p className={SubHeaderMsgStyles}>{t("accessAble")}</p>)}
            </>
          }
          isSubComponent={isSubComponent}
          textMessage={textMessage}
          btn1Exists={btn1Exists}
          btn2Exists={btn2Exists}
          btn1Text={btn1Text} btn2Text={btn2Text}
          btn1TypeAcpt={btn1TypeAcpt}
          verificationFailure={() => { }}
          footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
          showErrorImg={showErrorImg}
          onAcptBtnClick={onAcptBtnClick}
          isBorderNone={isBorderNone}
          btn1Focus={btn1Focus}
          btn2Focus={btn2Focus}
        />
      )}
    </>

  )
}

export default NonEditUniversity;
