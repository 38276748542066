import React, { useEffect, useState, useRef, useCallback } from 'react';
import Loader from '../../CustomComponents/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { CHECKING_CARD_CODE, retrieveData } from '../../../helpers/utils/Utils';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { STATUS_SUCCESS_CODE, TRANSACTIONS, CHECKING, GET_DDA_DETAILS, LOCK_CARD_API, UNLOCK_CARD_API, GENERATE_UNLOCK_CARD_OTP, GENERATE_OTP_VIEW_CARD, UPCOMINGTRANSACTIONS, GETTRANSACTIONDETAILS } from '../../../network/URLConstants';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import DynamicCard from '../../CustomComponents/DynamicCard';
import { width } from '../../CustomComponents/DynamicCard/DynamicCardStyles';
import TransactionItemWidget from '../../CustomComponents/TransactionItemWidget';
import UpcomingTransactionItemWidget from '../../CustomComponents/UpcomingTransactionItemWidget';
import { Tabs, Tab } from '../../CustomComponents/TabsComponent';
import { CheckingAndSavingsHeader, AccountDetailsHeder, copyToClipboard, formatDateToMMDDYYYY, SPENDING, addLineBreaks } from '../../../utils';
import { useTranslation } from 'react-i18next';
import FilterSlider from '../../CustomComponents/Slider';
import { useNavigate, useLocation } from 'react-router-dom';
import { set } from 'date-fns';
import { STATUS_CODE, SUBSTATUS_CODE } from '../../CardServices/CardMenu';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { withOidcSecure, useOidcUser, useOidcAccessToken, useOidc } from '@axa-fr/react-oidc'
import { LOGIN_SESSION_KEY } from '../../../Redux/reducers';
import Modal from '../../CustomComponents/Modal';
import Custom2FA from '../../CustomComponents/Custom2FA';
import lockCardGreenTick from '../../../assets/images/lock_green_tick.png'
import lockCardGreen from '../../../assets/images/lock_green.png'
import unLockCardGreen from '../../../assets/images/unlock_green.png'
import unLockCardGreenTick from '../../../assets/images/unlock_green_tick.png'
import warningImg from '../../../assets/images/warning.png'
import FooterContent from '../../CustomComponents/Footer';
import ClimbImg from '../../../assets/images/illo-climb.png';
import TwoFactTypeSelctionModal from '../../CustomComponents/TwoFactTypeSelctionModal';
import moment from 'moment';
import logFirebaseEvents from '../../LogFirebaseEvents';
import Tooltip from '../../Tootltip';
import _ from 'lodash';


const Checking = (props) => {
  const { hideLayout, Radius } = props
  const currentPath = window.location.pathname;
  const location = useLocation();
  const pageSize = location.pathname === '/home' ? 5 : 10;
  const dispatch = useDispatch();
  const isMountedRef = useRef(false);
  const navigate = useNavigate();
  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setMonth(currentDate.getMonth() - 6); // Minimum date is 6 months ago
  const maxDate = new Date(); // Maximum date is today
  const { t } = useTranslation(); // Hook to access translation functions
  const userProfile = useSelector(state => JSON.parse(state.userProfile));
  const selectedCardTabFromRedux = useSelector(state => state.selectedCardTab);
  const CardButton = 'font-hanken-grotesk lg:text-[16px] md:text-[14px] sm:text-[11px] md:leading-[39.71px] sm:leading-[25.8px] w-[24%] md:h-[50px] sm:h-[35px] inline-block rounded-full border-[3px] border-[#30BC89] text-kudzu-text text-xs font-semibold leading-normal text-success transition duration-150 ease-in-out';
  const accessTokenToApi = useSelector(state => state.accessToken);
  const cards = useSelector(state => JSON.parse(state.userCards));
  const selectedItem = cards?.[0]
  const [cardData, setCardData] = useState([]);
  const defaultFilterData = { minAmnt: '0', maxAmnt: '500', debit: true, credit: true, minDate: moment(new Date(currentDate).setMonth(currentDate.getMonth() - 6)).format('MM-DD-YYYY'), maxDate: moment(new Date()).format('MM-DD-YYYY') };
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [totalPages, setTotalPages] = useState('');
  const [CardNumber, setCardNumber] = useState('');
  const [CardBalance, setCardBalance] = useState('');
  const [showItems, setShowItems] = useState(false);
  const [CardText, setCardText] = useState('');
  const [checkingAccountNumber, setCheckingAccountNumber] = useState('*********'); //Account Number
  const [checkingRoutingNumber, setCheckingRoutingNumber] = useState('*********');
  const [accountCopied, setAccountCopied] = useState(false);
  const [routingCopied, setRoutingCopied] = useState(false);
  //const [pageSize, setPageSize] = useState(5);
  const [isViewAllClicked, setIsViewAllClicked] = useState(false);
  const [showFilterOverlay, setShowFilterOverlay] = useState(false);
  const [userName, setUserName] = useState('');
  const [cardOptionsList, setCardOptionsList] = useState([])
  const [showModel, setShowModel] = useState(false);
  const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [modelImage, setModelImage] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [btn1Focus, setBtn1Focus] = useState(false);
  const [btn2Focus, setBtn2Focus] = useState(false);
  const [quickActions, setQuickActions] = useState(true)
  const SelectedCardDetails = useSelector(state => state.selectedCardDetails);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [showErrorImg, setshowErrorImg] = useState(false);
  const [cardDetails, setCardDetails] = useState({})
  const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
  const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
  const [selectedOption, setSelectedOption] = useState('')
  const [show2faSelection, setshow2faSelection] = useState(false);
  const [otpGenerationType, setOtpGenerationType] = useState(false);
  const [labelDisable, setLabelDisable] = useState(false);
  const [selectedTab, setSelectedTab] = useState( t('ActivityTab'));
  // const [selectedTab, setSelectedTab] = useState('');
  const [SearchV, setSearchV] = useState('');
  const [upComingDisable, setUpcomingDisable] = useState(false);
  const [activityDisable, setActivityDisable] = useState(false);
  const [filterEnable, setFilterEnable] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const showSearchOnHome = useSelector(state => state.showSearchOnHome);
  const SearchFiltervalue = useSelector(state => state.SearchFiltervalue);

  // Effect for fetching data userProfile
  useEffect(() => {
    if (userProfile !== undefined && userProfile != null) {
      setUserName(userProfile.FirstName);
    }


  }, []);
  useEffect(() => {
    if (SelectedCardDetails != null) { getCardActions() }
  }, [SelectedCardDetails, cardDetails])

  ///navigation
  const handleNavigateToChecking = () => {
    //debugger; 
    navigate('/' + SPENDING + '')
  };


  const footerStyles = "underline font-hanken-grotesk cursor-pointer text-center pt-8 text-[#262B3A] font-bold lg:text-[18px] md:text-[17px] sm:text-[14px] text-[14px] leading-[18px] absolute md:bottom-24 bottom-[3rem] left-1/2 -translate-x-1/2 cursor-pointer";
  const footerStylesNoRecords = "font-hanken-grotesk cursor-pointer pt-8 font-[500] md:font-[500]  lg:text-[18px] md:text-[18px] sm:text-[12px] text-[14px] leading-[18px] opacity-[75%]";

 useEffect(()=>{
  global.selectedType = 'Activity';
 },[])



  // search
  const debouncedSubmitSearch = useCallback(
    _.debounce(async (SearchVal, currentFilterData) => {
    debugger;
    if(global.isFromApplyFilter !== undefined){
      if (global.isFromApplyFilter){
        global.isFromApplyFilter = false
        return
      }
    }
    dispatch({ type: 'CLOSE_ICON_FROM_STORE', payload: { closeIconFromStore: false } });
      setIsLoading(true);
      setSearchV(SearchVal);
      // const cardKey = cards?.[0].CardKey;
      var cardKey;
        for (var i = 0; i < cards?.length; i++) {
          if (cards?.[i].AccountTypeBit63 == CHECKING_CARD_CODE) {
            cardKey = cards?.[i].CardKey;
            break;
          }
        }

        if (global.selectedType === "Activity") {
          const transactionData = await fetchTransactionsData(cardKey, currentFilterData, 1, SearchVal);
          setCardData(transactionData);
        } else {
          const transactionData = await fetchUpcomingTransactionsData(cardKey, currentFilterData, 1, SearchVal);
          setCardData(transactionData);
        }
        setTimeout(()=>{
          dispatch({ type: 'CLOSE_ICON_FROM_STORE', payload: { closeIconFromStore: true } });
        },200)

      setIsLoading(false);

    }, 600), // 3000ms debounce time (30 seconds)
    []
  );

  

    

  
  const handleSubmitSearch = (value) => {
    if (value === '') {
      debouncedSubmitSearch.cancel();
      debouncedSubmitSearch('', filterData);
    } else {
      debouncedSubmitSearch(value, filterData);
    }
  };

  const Footer = () => {
    // alert(cardData.length);
    // debugger;
    return (

      <div>
        {showItems && (currentPath === '/home') && cardData.length > 0 && (
          <div className={footerStyles} onClick={handleNavigateToChecking}>
            {(t('viewAll'))}
          </div>
        )}
        {showItems && (currentPath === '/' + SPENDING + '') && totalPages !== 0 && cardData.length > 0 && (
          <div className={footerStyles} onClick={handleLoadMore}>
            {(t('viewAll'))}
          </div>
        )}
        {/* {cardData.length === 0 && totalPages == 0 && (currentPath === '/home' || '/' + SPENDING + '') && ( */}
        {showItems && cardData.length === 0 && totalPages == 0 && (
          <div className={`${footerStylesNoRecords} ${currentPath === '/home' ? 'text-center text-[#559C7E]' : 'text-left  text-[#4B70B9]'}`}>
            {(t('notransaction'))}
          </div>
        )}
      </div>
    );
  };

  const getCardActions = async (index) => {

    const selectedCard = cards?.[0];
    const { StatusCode, SubStatusCode, IsReplaced } = selectedCard ? selectedCard : [];
    let cardOptionsList = [];

    switch (StatusCode) {
      case STATUS_CODE.INA:
        switch (SubStatusCode) {
          case SUBSTATUS_CODE.ACP:
            cardOptionsList.push({
              name: t('view_card'),
            }, {
              name: t('lock_card')
            }, {
              name: t('QucikBtnTransfermoney')
            }, {
              name: t('QucikBtnMoreActions'),
            });
            break;
          case SUBSTATUS_CODE.LST:
          case SUBSTATUS_CODE.STL:
            cardOptionsList.push({
              name: t('view_card'),
            }, {
              name: t('lock_card')
            }, {
              name: t('QucikBtnTransfermoney')
            }, {
              name: t('QucikBtnMoreActions'),
            })
            // do nothing
            break;
          case SUBSTATUS_CODE.SUS:
            cardOptionsList.push({
              name: t('view_card'),
            }, {
              name: t('unlock_card')
            }, {
              name: t('QucikBtnTransfermoney')
            }, {
              name: t('QucikBtnMoreActions'),
            });
            break;
          default:
            cardOptionsList.push({
              name: t('view_card'),
            }, {
              name: t('lock_card')
            }, {
              name: t('QucikBtnTransfermoney')
            }, {
              name: t('QucikBtnMoreActions'),
            });
            break;
        }
        break;
      default:
        if (!IsReplaced) {
          cardOptionsList.push({
            name: t('view_card'),
          }, {
            name: t('lock_card')
          }, {
            name: t('QucikBtnTransfermoney')
          }, {
            name: t('QucikBtnMoreActions'),
          });
        } else {
          cardOptionsList.push({
            name: t('view_card'),
          }, {
            name: t('lock_card')
          }, {
            name: t('QucikBtnTransfermoney')
          }, {
            name: t('QucikBtnMoreActions'),
          });
        }
        break;
    }
    setCardOptionsList(cardOptionsList)
  }
  const closeModel = () => {
    setShowModel(false);
  }


  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1Focus, btn2Focus) => {
    setshowErrorImg(showErrorImg)
    setisBorderNone(isBorderNone)
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setFooterMsg(footerMsg)
    setBtn1Focus(btn1Focus)
    setBtn2Focus(btn2Focus)
  }





  /////get account details
  const getAccountNumberDetails = async (cardType, cardkey) => {
    if (cardkey == undefined || cardkey == null) {
      return
    }
    setIsLoading(true);
    let url = GET_DDA_DETAILS + cardkey;
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    var response = await GetRequest(url, headers)
    setTimeout(() => {
      setIsLoading(false)
    }, 500);
    var responseObj = JSON.parse(response);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      if (responseObj.Response != undefined) {
        if (cardType == CHECKING || SPENDING) {
          setCheckingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setCheckingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--');
        }

      }
    } else {
      var arrMessages = responseObj.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;
    }
  }

  //// 
  // Function to fetch transaction data with filters 
  const fetchTransactionsData = async (cardKey, filterData, currentPage, SearchV) => {
    if (cardKey == undefined) {
      return
    }
    if (SearchV == undefined) {
      SearchV = '';
    }
    try {
      if (cardKey == undefined || cardKey == null) {
        return
      }
      console.log(filterData,"------")
      setIsLoading(true);
      const deviceId = await retrieveData('updatedDeviceId');
      const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
      const formattedMaxAmt = filterData.maxAmnt == '500' ? `${filterData.maxAmnt}+` : filterData.maxAmnt;
      const response = await GetRequest(
        TRANSACTIONS(cardKey, filterData.debit, filterData.credit, filterData.minAmnt, formattedMaxAmt, filterData.minDate, filterData.maxDate, pageSize, currentPage, SearchV),
        headers
      );
      const responseObj = JSON.parse(response);

      if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
        setShowItems(true);
        setIsLoading(false);
        const totalPages = responseObj.Response.TotalPages;
        const RespcurrentPage = responseObj.Response.CurrentPage;
        const TotalItemsCount = responseObj.Response.TotalItemsCount;
        if (responseObj.Response.Items.length > 0) {
          setLabelDisable(false);
          setActivityDisable(false); 
        } else if(responseObj.Response.Items.length === 0) {
          setLabelDisable(false);
          if (isFilterApplied) {
            setActivityDisable(false);
          } else if (SearchV != undefined && SearchV != "") {
            setActivityDisable(false);
          }else{
            setActivityDisable(true);
          }
        }

        setTotalPages(totalPages);
        setCurrentPage(RespcurrentPage);
        setTotalItemsCount(TotalItemsCount);

        if (currentPage * pageSize <= TotalItemsCount) setIsViewAllClicked(false); // Show "Load More" button
        else setIsViewAllClicked(true);

        // displayViewAllBasedOnTotalItemCounts();
        // if (totalPages < 0) {
        //   setIsViewAllClicked(true); // Show "Load More" button
        // } else {
        //   setIsViewAllClicked(false); // Hide "Load More" button
        // }
        const transactionItems = responseObj?.Response?.Items;
        return transactionItems;
      } else {
        setIsLoading(false);
        setLabelDisable(false);
        setActivityDisable(false);

        return [];
      }
    } catch (error) {
      setIsLoading(false);
      return [];
    }
  };

  const cancelTransaction = async (transactionData) => {
    debugger;
    var cardKey;
    for (var i = 0; i < cards?.length; i++) {
      if (cards?.[i].AccountTypeBit63 == CHECKING_CARD_CODE) {
        setCardText(cards?.[i].NickName ? cards?.[i].NickName : cards?.[i].AccountTypeName);
        setCardNumber(cards?.[i].CardDisplayNumber);
        setCardBalance(cards?.[i].Balance);
        cardKey = cards?.[i].CardKey;
        // setCardDetails(cards?.[i])
      }
    }

    if (cardKey == undefined) {
      return
    }

    try {
      if (cardKey == undefined || cardKey == null) {
        return
      }
      setIsLoading(true);
      const deviceId = await retrieveData('updatedDeviceId');
      const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
      var temmpUrl = GETTRANSACTIONDETAILS(cardKey, transactionData.TransactionId);
      const response = await GetRequest(
        GETTRANSACTIONDETAILS(cardKey, transactionData.TransactionId),
        headers
      );
      const responseObj = JSON.parse(response);
      if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
        const responceObject = responseObj.Response;
        setIsLoading(false);
        const spendingCardDetails = cards?.find(item => item.AccountTypeBit63 === CHECKING_CARD_CODE);
        if (spendingCardDetails) { responceObject['cardDetails'] = spendingCardDetails }
        dispatch({ type: 'CANCEL_TRANSFER_DETAILS', payload: { cancelTransactionDetails: JSON.stringify(responceObject) } });
        navigate('/transactionCard')
      } else {
        setIsLoading(false);
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, 'Ok', '', true, warningImg, '', false, false, true, false)
      }
    } catch (error) {
      setIsLoading(false);
      return [];
    }
  };

  const fetchUpcomingTransactionsData = async (cardKey, filterData, currentPage,SearchV) => {
    if (cardKey == undefined) {
      return
    }

    try {
      if (cardKey == undefined || cardKey == null) {
        return
      }
      setIsLoading(true);
      const deviceId = await retrieveData('updatedDeviceId');
      const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
      const formattedMaxAmt = filterData.maxAmnt == '500' ? `${filterData.maxAmnt}+` : filterData.maxAmnt;
      const response = await GetRequest(
        UPCOMINGTRANSACTIONS(cardKey, filterData.debit, filterData.credit, filterData.minAmnt, formattedMaxAmt, pageSize, currentPage,SearchV),
        headers
      );
      // debugger;
      const responseObj = JSON.parse(response);
      if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
        debugger;
        setShowItems(true);
        setIsLoading(false);
        const totalPages = responseObj.Response.TotalPages;
        const RespcurrentPage = responseObj.Response.CurrentPage;
        const TotalItemsCount = responseObj.Response.TotalItemsCount;
        debugger;
        if (responseObj.Response.Items.length > 0) {
          setLabelDisable(false);
          setUpcomingDisable(false); 
          setFilterEnable(true)
        } else if(responseObj.Response.Items.length === 0) {
          setLabelDisable(false);
          if (isFilterApplied) {
            setUpcomingDisable(false);
          } else if (SearchV != undefined && SearchV != "") {
            setUpcomingDisable(false);
          }else{
            setUpcomingDisable(true);
          }
        }

        setTotalPages(totalPages);
        setCurrentPage(RespcurrentPage);
        setTotalItemsCount(TotalItemsCount);

        if (currentPage * pageSize <= TotalItemsCount) setIsViewAllClicked(false); // Show "Load More" button
        else setIsViewAllClicked(true);
        const transactionItems = responseObj?.Response?.Items;
        return transactionItems;
      } else {
        setLabelDisable(true);
        setIsLoading(false);
        setUpcomingDisable(true);

        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        // showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, 'Ok', '', true, warningImg, '', false, false, true, false)
        return [];
      }
    } catch (error) {
      setIsLoading(false);
      return [];
    }
  };

  const displayViewAllBasedOnTotalItemCounts = () => {
    if (showItems) {
      if (currentPage * pageSize <= totalItemsCount) setIsViewAllClicked(false); // Show "Load More" button
      else setIsViewAllClicked(true);

    }
  }



  ///on load get the data
  useEffect(() => {
    // debugger;
    if (!isMountedRef.current) {
      const fetchData = async () => {
        // defaultFilterData.maxAmnt='500+'
        await fetchInitialData(CHECKING, currentPage, defaultFilterData, SearchV);
      };
      fetchData();
      isMountedRef.current = true;
    }
  }, []);



  ///get transactions data

  const fetchInitialData = async (cardType, currentPage, filterData, SearchV) => {
     debugger;
    setIsLoading(true);
    if (SearchV == undefined) {
      SearchV = '';
    }
    // const cardKey = cardType === CHECKING ? cards !== null ?  cards[0].CardKey : 0 : '';
    // setCardText(cardType === CHECKING ? cards[0].AccountTypeName : '');
    // setCardNumber(cardType === CHECKING ? cards[0].CardDisplayNumber : '');
    // setCardBalance(cardType === CHECKING ? cards[0].Balance : '');
    var cardKey;
    for (var i = 0; i < cards?.length; i++) {
      if (cards?.[i].AccountTypeBit63 == CHECKING_CARD_CODE) {
        setCardText(cards?.[i].NickName ? cards?.[i].NickName : cards?.[i].AccountTypeName);
        setCardNumber(cards?.[i].CardDisplayNumber);
        setCardBalance(cards?.[i].Balance);
        cardKey = cards?.[i].CardKey;
        setCardDetails(cards?.[i])
      }
    }

    // Fetch account details only once and store them in state
    if (checkingAccountNumber !== '' && checkingRoutingNumber !== '') {
      await getAccountNumberDetails(cardType, cardKey);
    }
    var transactionData;
    if (selectedTab == t('ActivityTab')) {
      transactionData = await fetchTransactionsData(cardKey, filterData, currentPage, SearchV);
    } else {
      transactionData = await fetchUpcomingTransactionsData(cardKey, filterData, currentPage, SearchV);
    }

    // If it's the first page, set the transaction data as it is
    if (currentPage === 1) {
      setCardData(transactionData);
      setIsLoading(false);
    } else {
      // If it's not the first page, append the new transactions to the existing data
      setCardData((prevCardData) => [...prevCardData, ...transactionData]);
      setIsLoading(false);
    }

  };


  //load more data

  const handleLoadMore = async () => {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    try {
      await fetchInitialData(CHECKING, newCurrentPage, filterData,SearchV);
    } catch (error) {
      // Handle error, if any
      console.error(error);
      setIsLoading(false);
    }
  };

  const onCloseFilter =()=>{
    // setIsViewAllClicked(true);
    setQuickActions(!quickActions);
    if (currentPath === '/home') {
      setShowFilterOverlay(true);
      handleNavigateToChecking();
      logFirebaseEvents("Home", "filter_Closed", "filter_Closed", "", "");

    } else {
      setShowFilterOverlay((prevState) => !prevState);
      logFirebaseEvents("Spendings", "filter_Closed", "filter_Closed", "", "");
      // alert(showFilterOverlay)
      // if(showFilterOverlay) setIsViewAllClicked(false)

    }

  }

  ////filters 
  const handleFilterClick = (activeTab) => {

    dispatch({ type: 'SEARCH_FILTER_VALUE', payload: { SearchFiltervalue:  global.searchValueFromGlobal } });
    // setIsViewAllClicked(true);
    setQuickActions(!quickActions);
    if (currentPath === '/home') {
      setShowFilterOverlay(true);
      handleNavigateToChecking();
      logFirebaseEvents("Home", "filter_Clicked", "filter_Clicked", "", "");

    } else {
      setShowFilterOverlay((prevState) => !prevState);
      logFirebaseEvents("Spendings", "filter_Clicked", "filter_Clicked", "", "");
      // alert(showFilterOverlay)
      // if(showFilterOverlay) setIsViewAllClicked(false)

    }
  };

  useEffect(() => {
    if (showFilterOverlay) setIsViewAllClicked(true)
    else displayViewAllBasedOnTotalItemCounts();

  }, [showFilterOverlay])
  //load more data

  const aplliedTabFunction = async (appliedTab) => {
    debugger;
    // alert(appliedTab)
    setCurrentPage(1);
    setSelectedTab(appliedTab);
     global.selectedType = appliedTab;
    if (appliedTab == t('ActivityTab')) {
      if (currentPath === "/home") {
        logFirebaseEvents("Home", "Activity_Clicked", "Activity_Clicked", "", "");
      }
      else {
        logFirebaseEvents("Spendings", "Activity_Clicked", "Activity_Clicked", "", "");
      }
      dispatch({ type: 'SELECTED_CARD_TAB', payload: { selectedCardTab: t('ActivityTab') } });
      var cardKey;
      for (var i = 0; i < cards?.length; i++) {
        if (cards?.[i].AccountTypeBit63 == CHECKING_CARD_CODE) {
          setCardText(cards?.[i].NickName ? cards?.[i].NickName : cards?.[i].AccountTypeName);
          setCardNumber(cards?.[i].CardDisplayNumber);
          setCardBalance(cards?.[i].Balance);
          cardKey = cards?.[i].CardKey;
          setCardDetails(cards?.[i])
        }
      }

      const tabFreshFilterData = {
        minAmnt: '0',
        maxAmnt: '500',
        debit: true,
        credit: true,
        minDate: formatDateToMMDDYYYY(minDate),
        maxDate: formatDateToMMDDYYYY(maxDate)
      };
      setFilterData(tabFreshFilterData);
      const transactionData = await fetchTransactionsData(cardKey, tabFreshFilterData, 1);

      setCardData(transactionData);
      setIsLoading(false);
    } else if (appliedTab == t('UpcomingTab')) {
      setActivityDisable(false);
      if (currentPath === "/home") {
        logFirebaseEvents("Home", "Upcoming_Clicked", "Upcoming_Clicked", "", "");
      }
      else {
        logFirebaseEvents("Spendings", "Upcoming_Clicked", "Upcoming_Clicked", "", "");
      }
      dispatch({ type: 'SELECTED_CARD_TAB', payload: { selectedCardTab: t('UpcomingTab') } });
      var cardKey;
      for (var i = 0; i < cards?.length; i++) {
        if (cards?.[i].AccountTypeBit63 == CHECKING_CARD_CODE) {
          setCardText(cards?.[i].NickName ? cards?.[i].NickName : cards?.[i].AccountTypeName);
          setCardNumber(cards?.[i].CardDisplayNumber);
          setCardBalance(cards?.[i].Balance);
          cardKey = cards?.[i].CardKey;
          setCardDetails(cards?.[i])
        }
      }

      const tabFreshFilterData = {
        minAmnt: '0',
        maxAmnt: '500',
        debit: true,
        credit: true,
      };
      setFilterData(tabFreshFilterData);

      const transactionData = await fetchUpcomingTransactionsData(cardKey, tabFreshFilterData, 1);

      if(transactionData && transactionData.length < 0){
        setUpcomingDisable(true); 
      }
      

      setCardData(transactionData);
      setIsLoading(false);

    }
  }

  const handleApplyFilters = async (data) => {
    debugger;
    setUpcomingDisable(false);
    setActivityDisable(false);
    dispatch({ type: 'SEARCH_FILTER_VALUE', payload: { SearchFiltervalue: global.searchValueFromGlobal } });
    setIsFilterApplied(true)
    logFirebaseEvents("Spendings", "Apply_filters_clicked", "Apply_filters_clicked", "", "");
    setFilterData(data);
    setSearchV(global.searchValueFromGlobal)

    // Convert boolean values to "Y" (true) or "N" (false)
    const formattedDebit = data.debit ? 'true' : 'false';
    const formattedCredit = data.credit ? 'true' : 'false';


    // Ensure that minAmnt and maxAmnt are numbers
    const formattedMinAmnt = Number(data.minAmnt);
    const formattedMaxAmnt = Number(data.maxAmnt) === 500 ? `${Number(data.maxAmnt)}+` : Number(data.maxAmnt);
    const formattedMinDt = data.minDate;
    const formattedMaxDt = data.maxDate;


    // Construct the data object to be passed to the API
    const formattedFilterData = {
      minAmnt: formattedMinAmnt,
      // maxAmnt: formattedMaxAmnt + '+',
      maxAmnt: formattedMaxAmnt,
      debit: formattedDebit,
      credit: formattedCredit,
      minDate: formattedMinDt,
      maxDate: formattedMaxDt
    };

    // Clear existing data
    setCardData([]);
    setCurrentPage(1);

    // Fetch data for each card type (e.g., checking) with the updated filterData
    await fetchInitialData(CHECKING, 1, formattedFilterData,global.searchValueFromGlobal );
    setShowFilterOverlay(false);
    setQuickActions(!quickActions)

  };

  const handleClearFilters = async () => {
    logFirebaseEvents("Spendings", "Reset_filters", "Reset_filters", "", "");
    //alert('Clear Filters');
  };


  const handleCardAction = (type) => {
    if (type == t('view_card')) {
      logFirebaseEvents("Spendings", "View_card_Clicked", "View_card_Clicked", "", "");
      setSelectedOption(t('view_card_details'))
    } else {
      setSelectedOption(type)
    }
    if (type == t('lock_card')) {
      logFirebaseEvents("Spendings", "Lock_card_Clicked", "Lock_card_Clicked", "", "");
      showAlertModal(true, t("are_you_sure"), t("this_card_will_be_locked"), t('once_you_lock_your_card_any_new_transactions_will_be_declined'), true, true, t('lock_card'), t("cancel"), true, lockCardGreen, '', false, true, false, true)
    } else if (type == t('unlock_card')) {
      logFirebaseEvents("Spendings", "Unlock_card_Clicked", "Unlock_card_Clicked", "", "");
      dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: cards?.[0] } });
      // if (!twoFactAuthenticationDone) { setshow2faSelection(true) }
      // else { showAlertModal(true, t("ready_to_unlock_questionmark"), t("unlock_your_card_to_resume_normal_use"), t('once_you_unlock_your_card_all_locked'), true, true, t('unlock_card'), t("cancel"), true, unLockCardGreen, '', false, true, true, false) }
      showAlertModal(true, t("ready_to_unlock_questionmark"), t("unlock_your_card_to_resume_normal_use"), t('once_you_unlock_your_card_all_locked'), true, true, t('unlock_card'), t("cancel"), true, unLockCardGreen, '', false, true, true, false)
    } else if (type == t('view_card')) {
      logFirebaseEvents("Spendings", "View_card_Clicked", "View_card_Clicked", "", "");
      dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: cards?.[0] } });
      if (!twoFactAuthenticationDone) { setshow2faSelection(true) }
      else {
        setTimeout(() => {
          navigate('/CardServices/CardActionScreen/ViewCard')
        }, 500);
      }
    } else if (type == t('QucikBtnTransfermoney')) {
      logFirebaseEvents("Spendings", "Transfer_Money_Clicked", "Transfer_Money_Clicked", "", "");
      dispatch({ type: 'SELECTED_SUB_INDEX', payload: { selectedSubIndex: 1 } });
      global.selectedAccountServiceCardKey = cards !== null ? cards[0].CardKey : 0
      navigate('/AccountServices/Spending/TransferMoney')
    } else if (type == t('QucikBtnMoreActions')) {
      logFirebaseEvents("Spendings", "More_Actions_Clicked", "More_Actions_Clicked", "", "");
      navigate('/AccountServices')
    }
  }
  const callApiToLockCard = async () => {
    setIsLoading(true)
    setShowModel(false)


    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    let object = {
      CardKey: cards !== null ? cards[0].CardKey : 0
    }
    PostRequest(LOCK_CARD_API, object, headers).then((response) => {

      setTimeout(() => {
        setIsLoading(false)
      }, 500);

      let responseObj = JSON.parse(response);
      if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {


        setTimeout(() => {
          updateCardStatus(cards !== null ? cards[0].CardKey : 0, responseObj.Response)
          showAlertModal(true, t("done_exclamation"), t("this_card_is_now_locked"), t('any_new_tx_will_be_declined'), true, false, 'Ok', '', true, lockCardGreenTick, '', false, false, true, false)
        }, 1000);

        // this.updateCardsInLocal(responseObj.Response, LOCK_CARD)

      } else {
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, 'Ok', '', true, warningImg, '', false, false, true, false)
        setTimeout(() => {
        }, 1000);
      }

    });

  }

  const updateCardStatus = (cardKey, response) => {
    const updatedCardIndex = cards?.findIndex(card => card.CardKey === cardKey);
    if (updatedCardIndex !== -1) {
      const updatedCard = {
        ...cards[updatedCardIndex],
        Status: response.Status,
        SubStatus: response.SubStatus,
        StatusCode: response.StatusCode,
        SubStatusCode: response.SubStatusCode,
        // NewCardRequestedDate: response.NewCardRequestedDate !== undefined ? response.NewCardRequestedDate : Moment().format("YYYY-MM-DD") + "T" + "00:00:00",

      };
      const updatedArray = [updatedCard, ...cards.filter(card => card.CardKey !== cardKey)]
      dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedArray) } });
      dispatch({ type: 'SELECTED_USER_CARD_DETAILS', payload: { selectedCardDetails: updatedCard } });

    }
  };
  const callApiToUnlockCard = async () => {
    setIsLoading(true)
    setShowModel(false)


    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    let object = {
      CardKey: cards !== null ? cards[0].CardKey : 0
    }

    PostRequest(UNLOCK_CARD_API, object, headers).then((response) => {

      setTimeout(() => {
        setIsLoading(false)
      }, 500);

      let responseObj = JSON.parse(response);
      if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        setTimeout(() => {
          updateCardStatus(cards !== null ? cards[0].CardKey : 0, responseObj.Response)
          showAlertModal(true, t("success_heading"), t("this_card_is_now_unocked"), t('you_can_use_your_card'), true, false, 'Ok', '', true, unLockCardGreenTick, '', false, false, true, false)

        }, 1000);

        // this.updateCardsInLocal(responseObj.Response, UNLOCK_CARD)

      } else {
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, 'Ok', '', true, warningImg, '', false, false, true, false)

        setTimeout(() => {
        }, 1000);

      }

    });

  }
  const generate2FAOtp = async (type) => {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

    const requestObject = {
      cardKey: SelectedCardDetails?.CardKey
    };
    if (type !== undefined || type !== null) {
      requestObject.IsEmail = (type == 'Email') ? true : false
    }


    let url
    setIsLoading(true);

    switch (selectedOption) {

      case t('view_card'):
        url = GENERATE_OTP_VIEW_CARD
        break;
      case t('unlock_card'):
        url = GENERATE_UNLOCK_CARD_OTP.replace('{cardKey}', `${SelectedCardDetails.CardKey}`)
        break;
      default:
        url = GENERATE_OTP_VIEW_CARD
        break;
    }
    try {
      const response = await PostRequest(url, requestObject, headers);
      setIsLoading(false);

      const parsedResponse = JSON.parse(response);
      if (parsedResponse.ResponseCode === STATUS_SUCCESS_CODE) {
        //adding sessionid to redux
        const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
        dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
        //adding userid to redux
        const userIdPayload = { userId: parsedResponse.Response.userId }
        dispatch({ type: 'USER_ID', payload: userIdPayload });
        setTimeout(() => {
          setIsOTPVerifyModalOpen(true);
        }, 1000);
      } else {
        const arrMessages = parsedResponse.Messages;
        const message = arrMessages[0];
        const displayText = message.DisplayText;
        showAlertModal(true, t("oops_exclamation"), '', displayText, true, true, t('home'), t('back_to_card_services'), true, warningImg, '', false, false, true, false)

        // Handle the error message or displayText as needed
      }
    } catch (error) {
      // Handle errors from the API request
      console.error('Error fetching data:', error);
    }
  };
  const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }
  const verificationSuccess = (type, verifyResponse) => {
    if (selectedOption == t('unlock_card')) {
      // callApiToUnlockCard();
      updateCardStatus(cards !== null ? SelectedCardDetails.CardKey : 0, verifyResponse.Response)
      showAlertModal(true, t("success_heading"), t("this_card_is_now_unocked"), t('you_can_use_your_card'), true, false, 'Ok', '', true, lockCardGreenTick, '', false, false, true, false)
    } else if (selectedOption == t('view_card_details')) {
      setTimeout(() => {
        navigate('/CardServices/CardActionScreen/ViewCard')
      }, 500);
    }


  }
  const cancelUpcomingTransactionKilled = (cancelObject) => {

  }
  const onCancelBtnClick = () => {
    if (btn2Text == 'Cancel') {
      logFirebaseEvents("Spendings", "Lock_card_Closed", "Lock_card_Closed", "", "");
      setShowModel(false)
    }

  }
  const onAcptBtnClick = () => {
    if (btn1Text == t("lock_card")) {
      logFirebaseEvents("Spendings", "Lock_Card_Clicked", "Lock_Card_Clicked", "", "");
      callApiToLockCard()
    } else if (btn1Text == t("unlock_card")) {
      logFirebaseEvents("Spendings", "Unlock_card_Clicked", "Unlock_card_Clicked", "", "");
      if (!twoFactAuthenticationDone) { closeModel(); setshow2faSelection(true) }
      else callApiToUnlockCard()
    } else if (btn1Text == 'Ok') {
      logFirebaseEvents("Spendings", "Ok_Clicked", "Ok_Clicked", "", "");
      closeModel()
    }
  }



  ////////////end of the transactions date fetching

  const accountNumberCopiedClicked = () => {
    if (currentPath === '/' + 'Spending' + '') {
      logFirebaseEvents("Spendings", "Account_number_copied", "Account_number_copied", "", "");
    } else {
    }
    copyToClipboard(checkingAccountNumber)
    setAccountCopied(true);
    setTimeout(() => {
      setAccountCopied(false);
    }, 2000);

  }
  const routingNumberCopiedClicked = () => {
    if (currentPath === '/' + 'Spending' + '') {
      logFirebaseEvents("Spendings", "routing_Number_Copied", "routing_Number_Copied", "", "");
    } else {
    }
    copyToClipboard(checkingRoutingNumber)
    setRoutingCopied(true);
    setTimeout(() => {
      setRoutingCopied(false);
    }, 2000);
  }


  ///home header 
  const getHeaderText = () => {
    return (
      <>
        {selectedTab == t('UpcomingTab') ? t('here_all_upcoming_trans_details') : addLineBreaks(t('basic_account_sideHeading').replace("{TYPE}", 'spending'))}
      </>
    )
    // return (`${t('hello_user').replace("{USER}", userName)}\n${t('basic_account_sideHeading').replace("{TYPE}", 'spending')}`)
  };

  ///quck actions text
  const quickBtnTextClr = (currentPath === '/home') ? 'opacity-[50%]' : '';

  const steps = [
    'Tap here to search by transaction name',
    'Tap here for more search tool',
  ];
  const [currentStepSpending, setCurrentStepSpending] = useState(0);
  const [isTooltipVisibleSpending, setIsTooltipVisibleSpending] = useState(false);

  useEffect(() => {
    const tooltipStateSpending = sessionStorage.getItem("tooltipState_Spending");
    console.log('Initial tooltipStateSpending:', tooltipStateSpending);

    if (tooltipStateSpending === null || parseInt(tooltipStateSpending) < steps.length) {
      setCurrentStepSpending(parseInt(tooltipStateSpending) || 0);
      setIsTooltipVisibleSpending(true);
    } else {
      setIsTooltipVisibleSpending(false);
    }

  }, [steps.length]);


  const handleNextSpending = () => {
    const nextStep = currentStepSpending + 1;
    console.log('Current Spending Step:', currentStepSpending);
    console.log('Next Spending Step:', nextStep);
    if (nextStep < steps.length) {
      setCurrentStepSpending(nextStep);
      sessionStorage.setItem("tooltipState_Spending", nextStep.toString());
    } else {
      handleGotItSpending();
    }
  };

  const handlePreviousSpending = () => {
    const prevStep = currentStepSpending - 1;
    console.log('Current Spending Step:', currentStepSpending);
    console.log('Previous Spending Step:', prevStep);

    if (prevStep >= 0) {
      setCurrentStepSpending(prevStep);
      sessionStorage.setItem("tooltipState_Spending", prevStep.toString());
    }
  };

  const handleGotItSpending = () => {
    console.log('Got It Spending');
    setIsTooltipVisibleSpending(false);
    sessionStorage.setItem("tooltipState_Spending", steps.length.toString());
  };

  const handleGotitWithSearchClick = () =>{
    setIsTooltipVisibleSpending(false);
    sessionStorage.setItem("tooltipState_Spending", 2);
  }

  const showTooltipStatus = useSelector(state => state.showTooltip);


  useEffect(()=>{
    debugger;
    if(cardData.length === 0 && SearchV && global.searchValueFromGlobal){
      setUpcomingDisable(false);
      setActivityDisable(false);
    }
  },[cardData,SearchV,global.searchValueFromGlobal,])

  useEffect(()=>{
    setUpcomingDisable(false);
    setActivityDisable(false);
  },[selectedTab])
  // alert(showTooltipStatus);

  const dynamicHomeCard = (
    <DynamicCard
      header={false}
      footer={isViewAllClicked}
      footerContent={<Footer />}
      CardRadius={true}
      cardWidth={width.homeCardWithFilters}
      HeaderRadius={true}
      backgroundColor={'#30BC89'}
      isWhite={true}
      HeaderContent={
        <CheckingAndSavingsHeader
          cardText={CardText}
          amount={CardBalance}
          cardNum={CardNumber}
          cardDetails={cardDetails}
          accountNumber={checkingAccountNumber}
          routingNumber={checkingRoutingNumber}
          handleCopyClick={accountNumberCopiedClicked}
          handleCopyRoutingNumberClick={routingNumberCopiedClicked}
          isHeightAuto={true}
          cardOptionsList={cardOptionsList}
          handleCardAction={props.handleCardAction != undefined ? props.handleCardAction : handleCardAction}
        />
      } >
      <div className='flex flex-col w-full mt-[1rem]'>
        {currentPath === '/' + 'Spending' + '' && (
          <div className='hidden sm:block md:block lg:h-[216px] md:h-[190px] h-auto md:px-16
           sm:px-[2.5rem] pl-[2.6rem] pr-8 '>
            <AccountDetailsHeder
              AccountNo={checkingAccountNumber}
              routingNo={checkingRoutingNumber}
              accountCopiedProps={accountCopied}
              routingCopiedProps={routingCopied}
              handleCopyClick={accountNumberCopiedClicked}
              handleCopyRoutingNumberClick={routingNumberCopiedClicked}
              contentBetween={true}
              topBorder={false}
              bottomBorder={showFilterOverlay ? false : true}
              isCopyblack={true}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col w-full sm:mt-0 lg:px-16 md:px-12 sm:px-[2.5rem] pl-[2.6rem] pr-4">
        {quickActions &&
          <div className='hidden sm:block md:block lg:h-[178px] md:h-[150px] sm:h-[140px] h-[115px]'>
            <p className={`md:mt-[31px] sm:mt-[35px] mt-[25px]  
                sm:block hidden font-hanken-grotesk text-[#262B3A] 
                uppercase  lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]
                font-[600] tracking-[2px]  
                opacity-[50%]
                
            ${quickBtnTextClr}`}>{t('QuickActions')}</p>
            <div className='flex flex-nowrap pt-6 justify-between'>
              {cardOptionsList != [] &&
                cardOptionsList.map((item, index) => (
                  <button
                    type="button"
                    className={CardButton}
                    onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); const focused = document.activeElement; if (focused && typeof focused.blur === 'function') { focused.blur(); }; props.handleCardAction != undefined ? props.handleCardAction(item.name) : handleCardAction(item.name) } }}
                    // data-te-ripple-init
                    onClick={() => { props.handleCardAction != undefined ? props.handleCardAction(item.name) : handleCardAction(item.name) }}
                  >
                    {item.name}
                  </button>
                ))
              }
            </div>
          </div>
        }
      </div>

      {showItems && !showFilterOverlay && (
        <div className='md:pb-[9rem] pb-[5rem] mt-6 sm:px-0 px-10 relative'>
          {isTooltipVisibleSpending && showTooltipStatus && (
            <>
              {(cardData && cardData.length > 0) ? (
                <Tooltip
                  steps={steps}
                  onNext={handleNextSpending}
                  onPrevious={handlePreviousSpending}
                  onGotIt={handleGotItSpending}
                  currentStep={currentStepSpending}
                />
              ) : null}
            </>

          )}
          <Tabs selectedTab={selectedTab} labelDisable={labelDisable} upComingDisable={upComingDisable} activityDisable={activityDisable} updateActiveTab={aplliedTabFunction} onFilterClick={handleFilterClick} cardType={t('checking')}   isEnabled={(!isFilterApplied && cardData.length == 0 && SearchV == "")? false :  true}//
            filterEnable={filterEnable} handleSubmitSearch={handleSubmitSearch} handleGotitWithSearchClick={handleGotitWithSearchClick} SearchVal={global.searchValueFromGlobal}>
            <Tab label={t('ActivityTab')} >

              {/* {(cardData && cardData.length > 0) ? cardData?.map((data) => {
                return (
                  <>
                    <TransactionItemWidget transactionData={data} />

                  </>


                )
              }) : 
              <div className={`${footerStylesNoRecords} ${currentPath === '/home' ? 'text-center text-[#559C7E]' : 'text-left text-[#4B70B9]'}`}>

                  {(t('notransaction'))}
                </div>
                } */}
                {cardData && cardData.length > 0 ? (
                  cardData.map((data) => (
                    <TransactionItemWidget key={data.id} transactionData={data} />
                  ))
                ) :  (cardData.length === 0 && SearchV) ? <div
                   className={`${footerStylesNoRecords} ${currentPath === '/home' ? 'text-center text-[#559C7E]' : 'text-left text-[#4B70B9]'}`}
                  >{t('no_Transactions_Found_Invalid')}</div> : (
                  <div
                    className={`${footerStylesNoRecords} ${currentPath === '/home' ? 'text-center text-[#559C7E]' : 'text-left text-[#4B70B9]'}`}
                  >
                    {t('notransaction')}
                  </div>
                )}
                 
            </Tab>
            <Tab label={t('UpcomingTab')} >
              {(cardData && cardData.length > 0) ? 
              
              cardData?.map((data) => {
                return (
                  <UpcomingTransactionItemWidget cancelUpcomingTransaction={cancelTransaction} transactionData={data} />
                ) 
             }) :
             (cardData.length === 0 && SearchV) ? <div
             className={`${footerStylesNoRecords} ${currentPath === '/home' ? 'text-center text-[#559C7E]' : 'text-left text-[#4B70B9]'}`}
            >{t('no_Transactions_Found_Invalid')}</div> :
            
             <div className={`${footerStylesNoRecords} ${currentPath === '/home' ? 'text-center text-[#559C7E]' : ' text-[#4B70B9] text-left'}`}>
                  {(t('no_upcoming_transaction'))}
                </div>}
            </Tab>
          </Tabs>

        </div>
      )}
      {showFilterOverlay &&
        (
          <FilterSlider
            selectedTab={selectedTab}
            applyFilters={handleApplyFilters}
            filterClose={onCloseFilter}
            handleReset={handleClearFilters}
            appliedFilters={filterData} />

        )
      }

    </DynamicCard>

  );



  return (
    <div>
      <>
        {hideLayout ? (
          dynamicHomeCard
        ) : (
          <HomeLayout>
            <HomeHeader heading={getHeaderText()} isHeaderCaption={true} />
            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
          animate__animated animate__fadeInRight '>
              {dynamicHomeCard}
              {/* < FooterContent highlightText={t("climb_heading")}
                subHeaderText={t("climb_subheading")} image={ClimbImg}
                buttonEnabled={true} buttonText={t("climb_btn")} isSpace={true} footerBtnAction={() => { navigate('/Climb') }} /> */}

            </div>
          </HomeLayout>
        )}
      </>
      <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false); logFirebaseEvents("Quick_Security_check", "Close_Clicked", "Close_Clicked", "", ""); }} onClickNext={(object) => { setOtpGenerationType(object.proc); setshow2faSelection(false); generate2FAOtp(object.proc); }} />
      <>
        {showModel &&
          <CustomAlertModel
            isOpen={showModel}
            onClose={closeModel}
            image={modelImage}
            highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
            HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
            subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
            btn1Exists={btn1Exists}
            btn2Exists={btn2Exists}
            btn1Text={btn1Text}
            btn2Text={btn2Text}
            btn1TypeAcpt={btn1TypeAcpt}
            footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
            onAcptBtnClick={onAcptBtnClick}
            onCancelBtnClick={onCancelBtnClick}
            showErrorImg={showErrorImg}
            isBorderNone={isBorderNone}
            btn1Focus={btn1Focus}
            btn2Focus={btn2Focus}
          />
        }
      </>
      <>
        <Modal
          isOpen={isOTPVerifyModalOpen}
          onClose={closeOTPVerifyModal}
          isLogo={false}
          title={<p className={HighlightMsgStyles}> {t("great_exclamation")}</p>}
          subtitle={<p className={HeaderMsgStyles}>{otpGenerationType == 'Email' ? t("we_are_sending_text").replace("a text", "an email") : t("we_are_sending_text")}</p>}
          subHeaderMsg={<p className="font-hanken-grotesk modal-title md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] font-normal cursor-pointer pointer-events-none">{otpGenerationType != 'Email' && t("standard_msg_and_data_rates_may_apply")}</p>}
          content={<div className="mt-10"><Custom2FA type={selectedOption} selected2FAType={otpGenerationType} onClose={closeOTPVerifyModal} verificationSuccess={verificationSuccess} verificationFailure={() => { }} /></div>}
        />
      </>
      {isLoading && <Loader />}
    </div>
  );
};

export default withOidcSecure(Checking);
