
import React, { useState, useEffect, useCallback } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../CustomComponents/Loader';
import { UPDATE_CARDNAME, STATUS_SUCCESS_CODE, CAN_CREATE_SAVINGS_ACC, STUDENT_SIGNUP_EMAIL_ACCOUNT, STUDENT_SIGNUP_UNIVERSITY_SELECTION, STUDENT_UNIVERSITY_UPDATE, GET_CARDHOLDER_CARDS } from '../../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../../../network/NetworkUtils';
import { addLineBreaks, displayErrorMessage, displaySuccessMessage, isEmailValid, MoreServices } from '../../../../../utils';
import { checkEmailExtension, fetchConfigarationData, retrieveData } from '../../../../../helpers/utils/Utils';
import LineBreaks from '../../../../../utils';
import HomeLayout from '../../../../Layout/HomeLayout';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import Check from '../../../../../assets/images/Check_white.png'
import beneficiarysaving from '../../../../../assets/images/beneficiarysaving.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomInput from '../../../../CustomComponents/FormInputs';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAlertModel from '../../../../CustomComponents/CustomAlertModel';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AsyncPaginate } from 'react-select-async-paginate';
import debounce from 'lodash.debounce';


const appConfigData = await fetchConfigarationData();
const StudentUniversitySelection = ({ openConnectSchoolEmail,EditUniversityName,EditGradutionDate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook to access translation functions
  const cards = useSelector(state => JSON.parse(state.userCards));
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [isLoading, setIsLoading] = useState(false);
  const selectedItem = cards?.[0].CardKey;

  const [newName, setNewName] = useState((selectedItem != undefined && selectedItem != null) ? selectedItem.NickName : "");

  const [loading, setLoading] = useState(false)
  const [infoMessage, setinfoMessage] = useState('');
  const [responseCode, setResponseCode] = useState('');
  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [showModel, setShowModel] = useState(false);
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [modelImage, setModelImage] = useState('')
  const [savingsTheme, setSavingsTheme] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [isSubFooter, setIsSubFooter] = useState(false);
  const [isSubComponent, setIsSubComponent] = useState(false);
  const [showErrorImg, setshowErrorImg] = useState(false);
  const [textMessage, setTextMessage] = useState(false);
  const [btn1Focus, setBtn1Focus] = useState(false);
  const [btn2Focus, setBtn2Focus] = useState(false);
  const [universityData, setUniversityData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [DateValueServer, setDateValueServer] = useState('');
  const [buttonEnable, setButtonEnable] = useState(true);
  const [message, setMessage] = useState('');


  const formattedDateWithoutDayOfWeek = moment(EditGradutionDate).format("MM/yyyy");


  const [universityName,setUniversityName] = useState(EditUniversityName);
  const [DateValue, setDateValue] = useState(formattedDateWithoutDayOfWeek);

  const [selectedDate, setSelectedDate] = useState('');


  const [schoolName,setSchoolName] = useState(EditUniversityName);
  const [univeristyInput,setUniveristyInput] = useState(false);
  const [validData, setvalidData] = useState(false);

  const [universityValue,setUniversityValue] = useState('');









  const {
    register,
    handleSubmit,
    formState: { errors }, control, clearErrors,
    getValues,
    setFocus,
    setError
  } = useForm({
    mode: 'onTouched'
    // defaultValues: { newPassword: "newPassword@12" }
  });

  const closeModel = () => {
    setShowModel(false);

  }


  const handleSelectChange = (value) => {
    debugger;
    console.log(value,"dropdwon value")
    dispatch({ type: 'STUDENT_UNIVERSITY_CODE', payload: { studentUniversityCode: value }  });
    if (value === 0) {
      setUniveristyInput(true);
      setUniversityName('')
    } else {
      setUniveristyInput(false);
    } 
  }

  const handleInputChange = (e) =>{
    const universityNameVal = e.target.value
    if(universityNameVal){
      clearErrors('universityName');
     dispatch({ type: 'STUDENT_UNIVERSITY_NAME', payload: { studentUniversityName: universityNameVal }  });
     setUniversityName(universityNameVal)
     clearErrors('universityName');
    } else if(universityNameVal === "" & universityNameVal === undefined ){
      setError('universityName', {
        type: 'manual',
        message: t('please _enter _student_email_address'),
      });

    }
 }
  const customStyles = {

    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: '18px',
      backgroundColor: state.isFocused ? '#94C3E8' : provided.backgroundColor,
      backgroundColor: state.isSelected ? '#94C3E8' : state.isFocused ? '#94C3E8' : null,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: 500, 
      color:'#262B3A'
    })
    

  }

  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, textMessage, isSubComponent, btn1Focus, btn2Focus) => {
    setshowErrorImg(showErrorImg)
    setisBorderNone(isBorderNone)
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setFooterMsg(footerMsg)
    setTextMessage(textMessage)
    setIsSubComponent(isSubComponent)
    setBtn1Focus(btn1Focus)
    setBtn2Focus(btn2Focus)
  }

  
  const ChecktheForm = () => { 
    debugger;
    setMessage("")
    clearErrors();
   if((universityName || selected) && DateValue){ 
    studentUniversityUpdateApi();
   }
   else{
    setvalidData(false)
   }
     
  }
  
  const handleKeyDown = (event) => {
     if (event.key == "Enter") { 
      if(selectedDate.length >= 7 && selected ){
      event.preventDefault(); ChecktheForm() 
      }
  } }


  // async function SubmitTheDetails() {



  //   debugger;
  //   const deviceId = await retrieveData('updatedDeviceId');
  //   const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
  //   setIsLoading(true);
  //   var requestObject = {
  //     "applicationId": appConfigData.CLIENT_ID,
  //     "language": "en",
  //     "Code": selected,
  //     "OtherName": schoolName,
  //     "GraduationDate": DateValueServer
  //   }
  //   debugger;
  //   const url = STUDENT_UNIVERSITY_UPDATE(selectedItem);
  //   const response = await PostRequest(url, requestObject, headers);
  //   debugger;
  //   let parsedResponse = JSON.parse(response);
  //   setIsLoading(false);
  //   if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
  //     callPostLoginAPI()
  //     showAlertModal(true, t("allset"), t("accessCodeavailableurschool"),
  //       t("continuetoUse"), true, false, t('home'), t("cancel"), true,
  //       require('../../../../../assets/images/K.png'), '', false, true, '', false, false, true);
  //   } else {
  //     var arrMessages = parsedResponse.Messages;
  //     var message = arrMessages[0];
  //     var displayText = message.DisplayText;
  //     setMessage(displayText);
  //     setIsLoading(false);
  //   }

  // }



  async function studentUniversityUpdateApi() {
 
    debugger;
      const deviceId = await retrieveData('updatedDeviceId');
      const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
      setIsLoading(true);
  
      var dataObject = {
        "applicationId": appConfigData.CLIENT_ID,
        "language": "en",
        'GraduationDate': selectedDate.split("/")[1] + "-" + selectedDate.split("/")[0] + "-01" 
    }
      if (universityName && selected <= 0) {
      dataObject.OtherName = universityName;
      } else {
      dataObject.Code = selected;
      }
     
      const cardKey = cards !== null ? cards[0].CardKey : 0;
      const url = STUDENT_UNIVERSITY_UPDATE(cardKey);
      const response = await PostRequest(url, dataObject, headers);
      debugger;
      let parsedResponse = JSON.parse(response);
      setIsLoading(false);
      if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
      dispatch({ type: 'STUDENT_EMAIL_VALUE', payload: { studentEmailAddress: "" } });
        setSchoolName(universityName)
        callPostLoginAPI()
        showAlertModal(true, t("allset"), t("non_partner_success_msg"),
      "", true, false, t('home'), t("cancel"), true,
      require('../../../../../assets/images/K.png'), '', false, true, '', false, false, true);
      } else {
        var arrMessages = parsedResponse.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        setMessage(displayText);
        setIsLoading(false);
      }
  }
 
  async function callPostLoginAPI() {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    const response = await GetRequest(GET_CARDHOLDER_CARDS, headers)
    var responseObj = JSON.parse(response)
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        if (responseObj.Response != undefined && responseObj.Response != null && responseObj.Response.length > 0) {
            const userCardsPayload = { userCards: JSON.stringify(responseObj.Response) };
            dispatch({ type: 'UPDATE_USER_CARDS', payload: userCardsPayload });
        }

    } 
}

  const onAcptBtnClick = () => {
    navigate('/home');
  }



  const handleDateChange = (e) => {
    const input = e.target.value;
  
    let sanitizedInput = input.replace(/[^\d]/g, '');
    if (sanitizedInput.length >= 2) {
      let month = sanitizedInput.slice(0, 2);
      // Convert the month part to a number and check if it is within the range 01-12
      if (parseInt(month, 10) > 12) {
        month = '12';
      } else if (month === '00') {
        month = '01';
      }
      sanitizedInput = month + sanitizedInput.slice(2);
    }
  
    if (sanitizedInput.length > 2) {
      // Insert a '/' after the second digit if not already present
      sanitizedInput = sanitizedInput.slice(0, 2) + '/' + sanitizedInput.slice(2);
    }
  
    // Restrict the input length to 7 characters (MM/YYYY)
    if (sanitizedInput.length === 7) {
      const [inputMonth, inputYear] = sanitizedInput.split('/').map(Number);
  
      // Get current month and year
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11
      const currentYear = currentDate.getFullYear();
  
      // Check if the input date is not in the past
      if (inputYear < currentYear || (inputYear === currentYear && inputMonth < currentMonth)) {
        setSelectedDate("");
        setMessage(t("enterd_date_Should_be_in_future"))
      } else {
        setSelectedDate(sanitizedInput);
        setMessage("")
      }
    } else if (sanitizedInput.length <= 7) {
      setSelectedDate(sanitizedInput);
    }
  };

  

  useEffect(()=>{
     if(selectedDate.length >= 7 && selected ){
       setvalidData(true);
       setButtonEnable(false);
     }
     else if(univeristyInput){
      if(universityName.length > 4 && selectedDate.length >= 7 ){
        setvalidData(true);
        setButtonEnable(false);
      }
       else{
        setvalidData(false);
        setButtonEnable(true);
       }
     }
     else{
      setvalidData(false);
      setButtonEnable(true);

     }
  },[selectedDate,selected,universityName,univeristyInput])



const callApi = async (inputValue, page = 1) => {
  const deviceId = await retrieveData('updatedDeviceId');
  const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
  const url = STUDENT_SIGNUP_UNIVERSITY_SELECTION(inputValue, 20, page); // Adjust the page size as needed
  const response = await GetRequest(url, headers);
  const responseObj = JSON.parse(response);
  if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
    const universities = responseObj.Response.Universities.map(uni => ({
      label: uni.Name,
      value: uni.ID
    }));
    if (universities.length === 0) {
      universities.push({ label: "Other", value: 0 });
    }
    setUniversityData(universities)
    return {
      options: universities,
      hasMore: responseObj.Response.Universities.length > 0, // Change based on the page size
      additional: {
        page: page + 1,
      },
    };
  }
};

  const loadOptions = async (inputValue, _, additional) => {
    const finalVal = inputValue === "" ? "a":inputValue
    const page = additional.page || 1;
    return await callApi(finalVal, page);
  };

 

console.log(universityData,"all data form respoibnse")

  
  
  return (
    <>
      <HomeLayout>
        <HomeHeader heading={LineBreaks(t("settingsEmailTittle"))} isHeaderCaption={true} />
        <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
          <DynamicCard
            header={false}
            footer={false}
            CardRadius={true}
            HeaderRadius={true}
            isWhite={true}
            cardWidth={width.homeCard} >

            <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                    lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px]
                     leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
              {t('connect_school')}
            </div>

            <div className='border-t-[2.5px] border-[#A3CEBB]
                     flex justify-center flex-col sm:mx-0 mx-4'></div>

            <>
              <div className='lg:w-[90%] md:w-[94%] w-full font-serif-pro lg:text-[27px] md:text-[24px]
                sm:text-[20px] text-[24px] md:leading-[38px] sm:leading-[24px] leading-[31px] 
                    font-normal text-[#2965FB] mt-5'>
                <p className='sm:mx-0 mx-4 '>{t("oh_no")}</p>

                <span className='text-black'>
                  {(t("thereisnotAccescode"))}</span>
              </div>

              <span className="font-hanken-grotesk text-[#262B3A]
               lg:text-[16px] md:text-[16px] py-4 inline-block sm:text-[14px] text-[16px]
                  font-medium  leading-6 sm:mx-0  mx-4 ">
                {addLineBreaks(t("helptoconnect"))}</span>
            </>
            <form>
              <div className='w-full justify-start lg:py-20 md:py-14 sm:pt-10 sm:pb-0 py-4'>
                <div className='sm:w-[62%] w-full sm:mx-0 mx-4' id="bgforDatePicker">
                  <p class="font-hanken-grotesk text-[#262B3A] uppercase 
 lg:text-[13px] md:text-[12px] sm:text-[9px] text-[13px] 
 font-[600] tracking-[2px] md:pb-8 pb-4 
 opacity-[50%]">SCHOOL NAME</p> 

                  {/* <AsyncSelect
                    cacheOptions={true}
                    loadOptions={callApi}
                    name='university_code'
                    defaultOptions={universityData}
                    placeholder='Select'
                    onInputChange={(data) => {
                      console.log(data);
                    }}
                    onChange={(data) => {
                      console.log("slected" + data.value)
                      handleSelectChange(data.value);
                      setSelected(data.value)
                    }}
                    styles={customStyles}
                  /> */}
                  <AsyncPaginate
                      placeholder='Select'
                      // cacheOptions={true}
                      debounceTimeout={300}
                      // value={universityName}
                      loadOptions={loadOptions}
                      onChange={(data) => {
                        console.log("slected" + data.value)
                        handleSelectChange(data.value);
                        setSelected(data.value)
                      }}
                      // defaultOptions={universityData}
                      additional={{ page: 1 }}
                      isMulti={false}
                      styles={customStyles}
                    />

                  {univeristyInput &&
                    <div className='md:mt-16 mt-12 mb-12'>
                      <CustomInput
                        name="universityName"
                        label="School Name"
                        register={register}
                        error={errors.universityName}
                        type="text"
                        onChange={handleInputChange}
                      />

                    </div>
                  }

                  <div className='md:mt-16 mt-12 mb-12'>
                    <label className="font-hanken-grotesk text-[#262B3A]  uppercase lg:text-[13px] md:text-[9px]  sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-6 pb-5 opacity-[50%] inline-block mb-4" htmlFor={'ExpectedGraduationyear'}>{t('ExpectedGraduationyear')}</label>
                  <CustomInput
                        name="graduationSelection"
                        label="MM/YYYY"
                        register={register}
                        error={errors.graduationDate}
                        type="text"
                        onChange={handleDateChange}
                        value={selectedDate ? selectedDate : "" }
                        maxLength={7}
                        onKeyDownFun={handleKeyDown}
                      />
                      <div className='mb-4'>
                      {/* {errors.graduationDate && (
                        displayErrorMessage(errors.graduationDate.message)
                      )} */}
                          {message &&
                            <div className='mb-2'>
                              {displayErrorMessage(message)}
                            </div>
                          }
                      </div>
   
                  </div>
                </div>
             {/* 
                {message &&
                  <div className='mb-2'>
                    {displayErrorMessage(message)}
                  </div>
                } */}
              </div>

            </form>

            <div className="md:h-[160px] sm:h-[120px] h-[140px] flex justify-end items-center mt-12">
              <button
                type="button"
                className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center mr-10"
                onClick={openConnectSchoolEmail}
                onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); openConnectSchoolEmail(); } }}
              >
                {t('back')}
              </button>
              <button
                type="button"
                disabled={buttonEnable}
                className={`font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]  flex items-center
                ${validData ? 'text-[#2965FB] cursor-pointer' : 'text-[#94C3E8] cursor-none'}`}
                onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); ChecktheForm() } }}
                onClick={ChecktheForm}
              >
                {t('continue')}
              </button>
            </div>


            {loading && <Loader />}
          </DynamicCard>
        </div>

        {isLoading && <Loader />}

      </HomeLayout>

      {showModel && (
        <CustomAlertModel
          isOpen={showModel}
          onClose={closeModel}
          Fullheight={true}
          image={modelImage}
          highlightMsg={<p className={`${HighlightMsgStyles}`}>{highlightMsg}
            <span className={`${HeaderMsgStyles} text-black`}> {headerMsg}</span></p>}
          // subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
          subHeaderMsg={
            <>
              {subHeaderMsg == '' && (<p className={SubHeaderMsgStyles}>{t("non_partner_success_sub_head")}</p>)}
            </>
          }
          isSubComponent={isSubComponent}
          textMessage={textMessage}
          btn1Exists={btn1Exists}
          btn2Exists={btn2Exists}
          btn1Text={btn1Text} btn2Text={btn2Text}
          btn1TypeAcpt={btn1TypeAcpt}
          verificationFailure={() => { }}
          footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
          showErrorImg={showErrorImg}
          isBorderNone={isBorderNone}
          btn1Focus={btn1Focus}
          btn2Focus={btn2Focus}
          onAcptBtnClick={onAcptBtnClick}
        />
      )}
    </>

  )
}

export default StudentUniversitySelection;
