import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../Layout/HomeLayout';
import DynamicHomeCard from '../../../CustomComponents/DynamicHomeCard';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import { GetApiHeadersWithBearer, GetRequest } from '../../../../network/NetworkUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AccountDetailsHeder, copyToClipboard, MoreServices, QuickActions, SHARED ,SPENDING} from '../../../../utils';
import { CHECKING, SAVINGS, GET_DDA_DETAILS, GET_DDA_DOWNLOAD_URL, STATUS_SUCCESS_CODE } from '../../../../network/URLConstants';
import { retrieveData } from '../../../../helpers/utils/Utils';
import { width } from '../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import FooterContent from '../../../CustomComponents/Footer';
import ClimbImg from '../../../../assets/images/illo-climb.png';
import { useNavigate } from 'react-router-dom';



const AccountServicesSharedCard = () => {


  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkingAccountNumber, setCheckingAccountNumber] = useState('*********'); //Account Number
  const [checkingRoutingNumber, setCheckingRoutingNumber] = useState('*********');
  const [savingAccountNumber, setSavingAccountNumber] = useState('*********'); //Account Number
  const [savingRoutingNumber, setSavingRoutingNumber] = useState('*********');
  const cards = useSelector(state => JSON.parse(state.userCards));
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [accountCopied, setAccountCopied] = useState(false);
  const [routingCopied, setRoutingCopied] = useState(false);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();




  useEffect(() => {
    dispatch({ type: 'SELECTED_SUB_INDEX', payload: { selectedSubIndex: -1 } });
    cards?.map((item) => {
      const { AccountTypeName, CardKey } = item;
      getAccountNumberDetails(AccountTypeName, CardKey);
      return null; // Return null from the map to avoid returning a new array
    });
  }, []);


  ///more services

  const moreServicesArray = [
    { name: 'Edit Account Name', actionType: '', path: '/AccountServices/Shared/EditAccountName' }
  ];
  useEffect(() => {
    if (cards != null && cards?.length > 0 && cards?.[0]?.AllowAuthorizedUsers) {
      moreServicesArray.push({ name: t('menu_manage_authorized_users'), actionType: "", path: '/AccountServices/Shared/ManageAuthorizedUsers' })
    }
  }, [cards])


  const getAccountNumberDetails = async (cardType, cardkey) => {
    // debugger;
    if (cardkey == undefined || cardkey == null) {
      return
    }
    setLoading(true)
    let url = GET_DDA_DETAILS + cardkey;
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    var response = await GetRequest(url, headers)
    setTimeout(() => {
      setLoading(false)
    }, 500);
    var responseObj = JSON.parse(response);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      if (responseObj.Response != undefined) {
        if (cardType == CHECKING || SPENDING) {
          setCheckingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setCheckingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
        } else {
          setSavingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setSavingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
        }

      }
    } else {
      var arrMessages = responseObj.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;
    }
  }


  const accountNumberCopiedClicked = () => {
    copyToClipboard(checkingAccountNumber, setAccountCopied(true))
    setAccountCopied(true);
    setTimeout(() => {
      setAccountCopied(false);
    }, 2000);

  }
  const routingNumberCopiedClicked = () => {
    copyToClipboard(checkingRoutingNumber, setRoutingCopied(true))
    setRoutingCopied(true);
    setTimeout(() => {
      setRoutingCopied(false);
    }, 2000);
  }

  ///Quick Actions

  const QuickActionsButtons = [
    { name: `${t('add_fund')}`, actionType: "", path: '/AccountServices/Shared/AddFunds' },
    { name: "Transfer Money", actionType: "", path: '/AccountServices/Shared/TransferMoney' },
  ]

  //footer
  const footerContent = () => {
    return (
      < FooterContent highlightText={t("climb_heading")}
        subHeaderText={t("climb_subheading")} image={ClimbImg}
        buttonEnabled={true} buttonText={t("climb_btn")} isSpace={true} footerBtnAction={() => { navigate('/Climb') }} borderDirection={cards?.[0]?.AllowPayPerks ? (cards?.length % 2 == 0) ? 'odd' : 'even' : (cards?.length % 2 == 0) ? 'even' : 'odd'} />
    )

  }

  return (
    <HomeLayout>
      <HomeHeader heading="" isHeaderCaption={false} />
      <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated animate__fadeInRight  '>

        <DynamicCard
          header={false}
          HeaderContent={false}
          footer={false}
          CardRadius={true}
          HeaderRadius={true}
          isWhite={true}
          cardWidth={width.homeCard}
          backgroundColor={'#FFFFFF'}
        >
          <div className=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
        lg:text-[26px] md:text-[22px] 
        sm:text-[20px] text-[20px]
            leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
            {SHARED}
          </div>
          <AccountDetailsHeder
            AccountNo={checkingAccountNumber}
            routingNo={checkingRoutingNumber}
            accountCopiedProps={accountCopied}
            routingCopiedProps={routingCopied}
            handleCopyClick={accountNumberCopiedClicked}
            handleCopyRoutingNumberClick={routingNumberCopiedClicked}
            contentBetween={true}
            topBorder={true}
            bottomBorder={true}
            isCopyblack={true}
          />

          <QuickActions actionsArray={QuickActionsButtons} />

          {/* /////more service */}
          <div className="border-t-[2.5px] border-[#D1E7DD] flex flex-col justify-center sm:mx-0 mx-4">
            <MoreServices actionsArray={moreServicesArray} />
          </div>


        </DynamicCard>
        {footerContent()}
      </div>
    </HomeLayout>
  )
}

export default AccountServicesSharedCard;