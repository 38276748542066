import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../../CustomComponents/Loader'
import DynamicCard from '../../../../../CustomComponents/DynamicCard'
import { width } from '../../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import { withOidcSecure } from '@axa-fr/react-oidc'
import ConfirmationModuleSubItem from './ConfirmationModuleSubItem'
import moment from 'moment'
import logFirebaseEvents from '../../../../../LogFirebaseEvents'

const ConfirmationCard = ({ cardHeader, cardFooter, onBack, onEdit, onSave }) => {

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const AddedAmount=useSelector(state=>state.savingsHabitData.Amount);
  const Frequency=useSelector(state=>state.savingsHabitData.Frequency);
  const selectedDateCopy=useSelector(state=>state.savingsHabitData.StartDate); 
  const StartDate=((new Date(selectedDateCopy).getDate() - new Date(new Date().toUTCString().substr(0, 25)).getDate()) === 1) ? moment(selectedDateCopy).format('[Tomorrow], dddd, MMMM DD, YYYY') : moment(selectedDateCopy).format('dddd, MMMM DD, YYYY') 
  const [activeIndex, setActiveIndex] = useState(-1); // Keep track of the active index
  const [onSaveVal, setOnSaveVal]= useState(false)


  const onEdit1 = (value, type, index) => { 
    setActiveIndex(index); // Set the active index to the clicked index 
  };


  const datas = [
    {
      'title': 'Amount',
      'value': AddedAmount,
      'edit': 'Edit Amount',
      'onEdit': () => onEdit1(AddedAmount, "amount", 0),
      "type": 'Amount'

    },
    {
      'title': 'Frequency',
      'value': Frequency,
      'edit': 'Edit Frequency',
      'onEdit': () => onEdit1(Frequency, "timeFrame", 1),
      "type": 'Frequency'
    },
    {
      'title': 'Start',
      'value': StartDate,
      'edit': 'Edit Start',
      'onEdit': () => onEdit1(StartDate, "startDate", 2),
      "type": 'StartDate'
    }



  ]

  useEffect(()=>{
    console.log("datas-----", datas)
  },[datas])

  const handleBackClick = () => {
    logFirebaseEvents("Savings_Habit", "BackTo_Frequency_Clicked", "BackTo_Frequency_Clicked","", ""); 
    onBack("frequency")
  }


  const handleSaveClick = () => {
    logFirebaseEvents("Savings_Habit", "Save_Habit_BtnClicked", "Save_Habit_BtnClicked","", ""); 
    // onSave(true)
    onSave(!onSaveVal);
    setOnSaveVal(prev=>!prev)
  }


  const children = () => {
    return (
      <>
        {cardHeader}
        <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4 sm:flex sm:flex-col '>
          <span>{t("spending_alert_confirm_content1")} </span>
          <span className='text-secondary-3  md:w-[600px] sm:w-[358px] w-[264px]'>{t("add_savings_habit_confirmation_content1")}</span>
        </div>
        <div className='w-full flex justify-center'>
          <div className="md:w-[80%] sm:w-[85%] w-full">
            {/* BODY */}
            <div className='lg:mb-[6rem] md:mb-[4rem] sm:mb-[3rem] mb-[4rem] sm:mx-0 mx-4'>

              <div className='sm:w-[80%] w-full flex flex-col justify-center'>
                {datas && 
                  datas.map((data, index) => {
                    return (
                      <ConfirmationModuleSubItem
                        key={index}
                        data={data}
                        onEdit={() => data.onEdit()} // Pass the onEdit function from data
                        isActive={activeIndex === index} // Pass whether it's active or not
                      />
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER */}
        {cardFooter(handleBackClick, handleSaveClick, false, `${t("save")}`)}

        {/* ---------- */}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>
      {isLoading && <Loader />}
    </>
  )
}


export default withOidcSecure(ConfirmationCard)


