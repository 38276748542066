import React, { useState, useEffect } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { width } from "../../../../CustomComponents/DynamicCard/DynamicCardStyles";
import { useTranslation } from 'react-i18next';
import HomeLayout from '../../../../Layout/HomeLayout';
import DynamicCard from '../../../../CustomComponents/DynamicCard';
import { useNavigate } from 'react-router';
import LineBreaks, { ClimbManageSettingsSidebar, retrieveData } from '../../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../CustomComponents/Loader';
import SpendAlertsItem from './SpendAlertsItem';
import { GET_ALL_SPEND_ALERTS, GET_CLIMB_FEATURES, OPT_FEATURE_DY, OPT_IN_FEATURE, OPT_IN_FEATURE_DY, OPT_IN_FEATURE_SPEND_ALERTS, OPT_OUT_FEATURE, OPT_OUT_FEATURE_SPEND_ALERTS, SPENDING_ALERT, STATUS_SUCCESS_CODE } from '../../../../../network/URLConstants';
import Modal from '../../../../CustomComponents/Modal';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../../network/NetworkUtils';
import SpendAlertsPause from "../../../../../assets/images/illo-pause.png";
import SpendAlertsPauseSuccess from '../../../../../assets/images/illo-pause-done.png';
import Success from '../../Success';
import logFirebaseEvents from '../../../../LogFirebaseEvents';
const ManageSpendAlerts = () => {
    const HeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3";

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const SettingsData = [
        {
            title: 'SpendSense Alerts',
            content: 'You can pause/resume SpendSense Alerts at any time. Your alert settings will be preserved while paused.'

        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [spendAlertsArray, setspendAlertsArray] = useState(null)
    const [showSubItems, setShowSubItems] = useState(false);
    const [isClimbings, setisClimbings] = useState([]);
    const [isOptIn, setIsOptIn] = useState('');
    const [SpendingAlertCode, setSpendingAlertCode] = useState('');
    const [SpendingCount, setSpendingCount] = useState(0);
    const climbFeatures = useSelector(state => state.climbFeatures);
    const enabledClimbings = climbFeatures?.filter((response) => response.enabled === true);
    const isCoveredByKudzuOptedIn = enabledClimbings?.some(item => item.code === 'CoveredByKudzu' && item.optedIn)
    const accessToken = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const isPrimrayKey = cards !== null ? cards !== null ? cards[0].CardKey : 0 : 0;
    const [isCoveredByKudzuIneligibleSetupSavings, setIsCoveredByKudzuIneligibleSetupSavings] = useState(false);
    const [isCoveredByKudzuIneligibleSetupSavingsPostPopup, setIsCoveredByKudzuIneligibleSetupSavingsPostPopup] = useState(false);
    const [isCoveredByKudzuIneligible, setIsCoveredByKudzuIneligible] = useState(false);
    const SubHeadingStyles2 = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-2 pl-1 pr-1 mb-4";
    const savingsCardExist = cards?.some(item => item.AccountTypeName === "Savings")
    const savingsWithBalanceOver50 = cards.find(
        obj => obj.AccountTypeBit63 == 32 && obj.Balance >= 50
      );

    const handleClimbFeatures = async () => {
        //alert('ji');
        //debugger;
        if (cards == null || cards == undefined) {
            return
        }
        setIsLoading(true);
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const url = GET_CLIMB_FEATURES.replace('{CARD_KEY}', isPrimrayKey)
        const response = await GetRequest(url, headers);
        const responseJson = JSON.parse(response);
        if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
            //  debugger;
            const spendingAlertResponse = responseJson.Response.find((obj) => obj.code === SPENDING_ALERT);
            if (spendingAlertResponse) {
                const isOptInValue = spendingAlertResponse.optedIn;
                // (isOptInValue === false) ? setShowSubItems(true) : setShowSubItems(false);
                setSpendingAlertCode(spendingAlertResponse.code);
                setIsOptIn(isOptInValue);
                setShowSubItems(isOptInValue)
            } else {
                // Handle the case where 'SpendingAlert' is not found in the response
            }
            const enabledClimbings = responseJson.Response.filter(
                (response) => response.enabled === true

            );
            setisClimbings(enabledClimbings);
            setIsLoading(false);
        } else {
            return [];
        }

    }
    const closeCustomAlertModal = () => {
        setIsCoveredByKudzuIneligible(false);
        navigate('/Climb/ClimbMenu');
      
    }

    const closeSkipModal = () => {
        navigate('/home');
      };

    //API Call-------------------
    const getAllSpendAlerts = async () => {
        if (isPrimrayKey == null || isPrimrayKey == undefined) {
            return
        }
        try {
            //  debugger;
            setIsLoading(true);
            const deviceId = await retrieveData('updatedDeviceId')
            // const cardKey = cards !== null ?  cards[0].CardKey : 0;
            const cardKey = isPrimrayKey;
            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = GET_ALL_SPEND_ALERTS(cardKey);
            const response = await GetRequest(url, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                debugger;
                setspendAlertsArray(responseJson.Response)
                setSpendingCount(responseJson.Response.length);
                 const SPENDSONSCOUNTPAYLOAD = { spendAlertCount: responseJson.Response.length > 0 ? responseJson.Response.length : 0 }
                dispatch({ type: 'SPENDS_ALERT_COUNT', payload: SPENDSONSCOUNTPAYLOAD });
                setIsLoading(false);

            } else if (responseJson.Messages) {
                const displayErrorText = responseJson.Messages[0].DisplayText;
                setIsLoading(false);
            }

        } catch (error) {
        }
    }
    const updateOptInFeature = async () => {
        try {
            // alert('hi');
            // debugger;
            setIsLoading(true);
            const deviceId = await retrieveData('updatedDeviceId')
            // const cardKey = cards !== null ?  cards[0].CardKey : 0;
            const cardKey = isPrimrayKey;
            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = OPT_IN_FEATURE.replace('{CARD_KEY}', cardKey).replace('{featureType}', SpendingAlertCode)
            const response = await PostRequest(url, {}, headers);
            const responseJson = await JSON.parse(response);
            // debugger;
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                setShowSubItems(false);
                setIsOptIn(false);
                setIsLoading(false);
            } else if (responseJson.Messages) {
                const displayErrorText = responseJson.Messages[0].DisplayText;
                setIsLoading(false);
            }

        } catch (error) {
        }
    }
    const updateOptOutFeature = async () => {
        try {
            setIsLoading(true);
            //  alert('opout');

            // debugger;

            const deviceId = await retrieveData('updatedDeviceId')
            // const cardKey = cards !== null ?  cards[0].CardKey : 0;
            const cardKey = isPrimrayKey;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = OPT_OUT_FEATURE.replace('{CARD_KEY}', cardKey).replace('{featureType}', SpendingAlertCode)
            const response = await PostRequest(url, {}, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                //   debugger;
                setShowSubItems(true);
                setIsOptIn(true);
                setIsLoading(false);
                closeOptInModal();
                openOptOutModal();
            } else if (responseJson.Messages) {
                const displayErrorText = responseJson.Messages[0].DisplayText;
                setIsLoading(false);
            }

        } catch (error) {
        }
    }



    useEffect(() => {
        // if (isOptIn) setSpendingCount(1)
        // else setSpendingCount(0);

        handleClimbFeatures();
        getAllSpendAlerts();
        // alert("SpendingCount"+SpendingCount);
    }, [showSubItems, isOptIn, SpendingCount])


    const handleAddSpendAlerts = () => {
        logFirebaseEvents("SpendSense_Alert", "Add_Spend_Alert_Clicked", "Add_Spend_Alert_Clicked","", "")
        navigate("/Climb/AddSpendAlerts")
    }

    const handleToggleButton = (e) => { 
        logFirebaseEvents("SpendSense_Alert", "spendSense_Toggle_Clicked", "spendSense_Toggle_Clicked","", "");
        const checkedValue = e.target.checked;
        if (!checkedValue) openOptInModal()
        else updateOptInFeature()
    }

    ///////////////////////////////////HANDLE PAUSE AND RESUME MODAL POPUS 

    const handlePauseSpendAlerts = () => {
        logFirebaseEvents("SpendSense_Alert", "Paused_ManageSpendAlert_Clicked", "Paused_ManageSpendAlert_Clicked","", "");
        //alert('on Pause'); 
        updateOptOutFeature();
    }

    const handleOptInCancel = () => { 
        logFirebaseEvents("SpendSense_Alert", "Cancel_ManageSpendAlert_Clicked", "Cancel_ManageSpendAlert_Clicked","", "");
        closeOptInModal();
        handleOptOutCancel()
    }

    //updateOptInFeature();


    const handleOptOutCancel = () => {
        closeOptOutModal()
    }


    // Opt In modal
    const [isOptInModalOpen, setIsOptInModalOpen] = useState(false);

    const openOptInModal = () => {
        setIsOptInModalOpen(true);
    };

    const closeOptInModal = () => {
        setIsOptIn(prev => !prev)
        setIsOptInModalOpen(false);

    };

    // Opt out modal
    const [isOptOutModalOpen, setIsOptOutModalOpen] = useState(false);

    const openOptOutModal = () => {
        setIsOptOutModalOpen(true);
    };

    const closeOptOutModal = () => {
        setIsOptIn(prev => !prev)
        setIsOptOutModalOpen(false);
    };

    ///////////////////////////////////HANDLE PAUSE AND RESUME MODAL POPUS  4

    const ManageSettingsbuttonLabels = [
        { label: 'SpendSense Alerts', link: '/Climb/ManageSpendAlerts' },
        { label: 'Covered by Kudzu', link: isCoveredByKudzuOptedIn ? '/Climb/ManageCoveredByKudzu' : '/Climb/CoveredByKudzu' },
        { label: 'Savings Habits', link: '/Climb/ManageSavingsHabit' },
    ];


    const navigateClicked = (item) => {
        if (item.link == '/Climb/ManageSavingsHabit') {
            if (!savingsCardExist && item.label == "Savings Habits") {
                closeOptInModal()
                setIsCoveredByKudzuIneligibleSetupSavings(true);
              //  showAlertModal(true, t('sorry'), t("it_looks_like_your_not_eligible_right_now"), LineBreaks(t("you_need_savings_account")), true, true, t('enroll_in_savings_account'), t('   _to_climb'), true, require('../../../../assets/images/covered_by_kudzu_ineligible.png'), '', false, true)
            } else if (savingsCardExist && savingsWithBalanceOver50 == undefined && item.label != "Savings Habits") {
                closeOptInModal()
                setIsCoveredByKudzuIneligible(true);
            }else{
                navigate(item.link);
            }
        }else{
            navigate(item.link);
        }
       
    }
    return (
        <>
            <HomeLayout>
                {SpendingAlertCode &&
                    <ClimbManageSettingsSidebar indexBtn={1} ManageSettingsbuttonLabels={ManageSettingsbuttonLabels} headingMessage={false} count={SpendingCount} isShow={showSubItems} navigateClicked={navigateClicked} />
                }
                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        footer={true}
                        HeaderContent={false} isWhite={true} backgroundColor={'#E4EAF5'}>
                        <>
                            <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500]
                             lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
                              font-hanken-grotesk sm:mx-0 mx-4">{(t('manageSpendAlerts'))}</div>

                            <div class="border-[#94C3E8] border-b-[3px] sm:mx-0 mx-4"></div>

                            <div className='justify-center flex lg:mt-8 md:mt-6 sm:mx-0 mx-4'>
                                <div className='lg:w-[70%] sm:w-[81%] w-full'>
                                    {SettingsData.map((settings, index) => (
                                        <div key={index} className='mt-10 flex justify-between md:h-[123px]
                                         sm:h-[140px] h-[123px]
                                             border-b-[2px] border-[#94C3E8] border-opacity-[65%]'>
                                            <div>
                                                <p className='lg:text-[20px] md:text-[18px] 
                                                    sm:text-[17px] text-[17px] font-hanken-grotesk 
                                                    leading-[30px] sm:font-[500] font-[600]'>
                                                    {settings.title}
                                                </p>
                                                <div className='mt-4 lg:text-[14px] 
                                                    md:text-[13px] sm:text-[14px] text-[14px] 
                                                    font-hanken-grotesk lg:leading-[20px] md:leading-[18px]
                                                     sm:leading-[23px] leading-[20px] sm:font-[400] font-[500] sm:w-auto w-[240px]'>
                                                    {settings.content}
                                                </div>
                                            </div>
                                            <div>
                                                <label class="relative inline-flex items-center cursor-pointer">
                                                    <input type="checkbox"
                                                        checked={isOptIn}
                                                        onChange={(e) => { setIsOptIn(e.target.checked) }}
                                                        class="sr-only peer"
                                                        onClick={(e) => handleToggleButton(e)}
                                                        //disabled={!isOptIn}
                                                        tabIndex={0}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                handleToggleButton(e)
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <div class="w-[38px] h-[25px]  bg-[#4B70B9] bg-opacity-[40%]
                                     peer-focus:outline-none   
                                      rounded-full peer dark:bg-gray-700 
                                      peer-checked:after:translate-x-full
                                     peer-checked:after:border-[#2965FB] 
                                      peer-checked:after:bg-[#2965FB]
                                      peer-checked:after:left-[-6px]
                                      after:content-[''] 
                                      after:absolute after:top-[2px]
                                      after:left-[2px] after:right-0
                                      after:bg-[#fff]
                                       after:border-[#fff] after:border-[gray-300] 
                                      after:border after:rounded-full after:h-[21px] 
                                      after:w-[21px] after:transition-all
                                       dark:border-gray-600 peer-checked:bg-[#94C3E8] peer-checked:bg-opacity-[40%]"></div>

                                                    <span class="ml-3 text-sm font-medium text-gray-900
                                     dark:text-gray-300"></span>
                                                </label></div>
                                        </div>
                                    ))}

                                    {spendAlertsArray &&
                                        spendAlertsArray.map((data) => {
                                            return (
                                                <SpendAlertsItem data={data} isShow={showSubItems} type={"SpendingAlert"} />
                                            )
                                        })
                                    }
                                    <p className="font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] sm:text-[14px]
                                     text-[16px] leading-[24px] text-secondary-3 w-[80%] pt-8" >{t("manage_savings_habit_content2")}
                                        <span className='text-secondary-2 cursor-pointer' onClick={() => { navigate('/PayPerks'); logFirebaseEvents("SpendSense_Alert", "PayPerks_Clicked", "PayPerks_Clicked","", ""); }}
                                            tabIndex={0}
                                            onKeyDown={(e) => { if (e.key === "Enter") navigate('/PayPerks') }}
                                        >{t("NavbarPerks")}</span></p>

                                </div>

                            </div>

                            <div class="border-[#94C3E8] border-b-[2px] border-opacity-[50%] lg:mt-16 md:mt-12 sm:mt-10 mt-12 sm:block hidden"></div>

                            <div className='flex justify-center flex-col items-center md:my-16 sm:my-12 my-16'>
                                <div className='lg:w-[70%] sm:w-[81%] w-full'>
                                    <button
                                        className={`font-hanken-grotesk sm:font-bold leading-[24px]
                                         sm:leading-[12px] lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px] md:leading-[16px]
                                          text-[#2965FB] font-[600]
                                         cursor-pointer border-2 px-2 py-4 border-[#2965FB] w-full ${showSubItems ? 'opacity-1' : 'opacity-[0.1]'}`}
                                        onClick={handleAddSpendAlerts}
                                        tabIndex={0}
                                        onKeyDown={(e) => { if (e.key === "Enter") handleAddSpendAlerts() }}
                                        disabled={!showSubItems}
                                    >
                                        {t('Add_Alert')}
                                    </button>
                                </div>
                            </div>

                        </>
                    </DynamicCard>
                </div>
            </HomeLayout>
            {isLoading && <Loader />}
            <Modal
                isOpen={isOptInModalOpen}
                onClose={closeOptInModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('pauseSpendAlerts')}</p>}
                subtitle={
                    <p>
                        <span className={`${HeadingStyles} visible sm:hidden`}>{t('pauseSpendAlerts')}</span><span className={SubHeadingStyles}>{t('SpendAlertsPauseMessage')}</span>
                    </p>}
                content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
                    <Success content1={t("spendAlertsPause")} btn1={t("pauseSpendAlertsBtn")} btn1Function={handlePauseSpendAlerts} btn2={t("cancel")} btn2Function={handleOptInCancel} icon={SpendAlertsPause} /></div>}

            />
            <Modal
                isOpen={isOptOutModalOpen}
                onClose={closeOptOutModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('pauseSpendAlertsSucess')}</p>}
                subtitle={
                    <p>
                        <span className={`${HeadingStyles} visible sm:hidden`}>{t('pauseSpendAlertsSucess')}</span><span className={SubHeadingStyles}>{t('pauseSpendingAlertSucess2')}</span>
                    </p>}
                content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
                    <Success content1={t("pasuseSpendAlertsSucessMessage")} btn1={t("back_to_climb")} btn1Function={() => navigate('/Climb/ClimbMenu' ,logFirebaseEvents("SpendSense_Alert", "Back_To_Climb_ManageSpendAlert_Clicked", "Back_To_Climb_ManageSpendAlert_Clicked","", ""))} icon={SpendAlertsPauseSuccess} /></div>}

            />
              {isCoveredByKudzuIneligibleSetupSavings &&<Modal
                isOpen={isCoveredByKudzuIneligibleSetupSavings}
                onClose={() => { closeSkipModal(); setIsCoveredByKudzuIneligibleSetupSavings(false); }}
                isClimbg={true}
                Fullheight={true}
                subtitle={<div className="">
                    <span className={SubHeadingStyles2}>{t('sorry')}, </span>
                    <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
                    <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                        {LineBreaks(t("you_need_savings_account"))}</p>
                </div>
                }
                content={<div>

                    <img src={require('../../../../../assets/images/covered_by_kudzu_ineligible.png')}
                        className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                    <div className="flex flex-col justify-center items-center gap-8 w-full">
                        <button
                            type="button"
                            className=" w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => { setIsCoveredByKudzuIneligibleSetupSavings(false); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true); }}
                            autoFocus
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setIsCoveredByKudzuIneligibleSetupSavings(false);
                                    setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true);
                                    event.stopPropagation();
                                }
                            }}
                        >
                            {t('enroll_in_savings_account')}
                        </button>
                        <button
                            type="button"
                            className={`w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center p-4  sm:border-[0px]  border-[#2965FB] border-[2.5px]`}
                            onClick={() => {
                                setIsCoveredByKudzuIneligibleSetupSavings(false);
                                navigate('/Climb/ClimbMenu');
                            }}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setIsCoveredByKudzuIneligibleSetupSavings(false);
                                    navigate('/Climb/ClimbMenu');
                                    event.stopPropagation();
                                }
                            }}
                        >
                            {t('back_to_climb')}
                        </button>
                    </div>
                </div>}
            />}
             {isCoveredByKudzuIneligibleSetupSavingsPostPopup && <Modal
                bgColor={'bg-[#FEFAEE]'}
                isOpen={isCoveredByKudzuIneligibleSetupSavingsPostPopup}
                onClose={() => { closeSkipModal(); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false); }}
                isClimbg={true}
                Fullheight={true}
                subtitle={<div className="">
                    <span className={SubHeadingStyles2}>{t('lets_get_started').replace('!', '')} </span>
                    <span className={SubHeadingStyles}>{t("with_creating_a_new_account")}</span>
                    <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                        {t("set_aside_money_for_things_that_matter")}</p>
                </div>
                }
                content={<div>

                    <img src={require('../../../../../assets/images/kudzu_piggy_bank.png')} className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                    <div className="flex flex-col justify-center items-center gap-8 w-full">
                        <button
                            type="button"
                            className=" w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => {
                                setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false); dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('came_from_climb') } });
                                navigate('/SetupSavingsAccount');
                            }}
                            autoFocus
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false);
                                    dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('came_from_climb') } });
                                    navigate('/SetupSavingsAccount');
                                    event.stopPropagation();
                                }
                            }}
                        >
                            {t('continue')}
                        </button>
                    </div>
                </div>}
            />}
            {isCoveredByKudzuIneligible && <Modal
                isOpen={isCoveredByKudzuIneligible}
                onClose={closeCustomAlertModal}
                isClimbg={true}
                Fullheight={true}
                subtitle={<div className="">
                    <span className={SubHeadingStyles2}>{t('sorry')}, </span>
                    <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
                    <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                        {t("you_need_minimum_50")}</p>
                </div>
                }
                content={<div>

                    <img src={require('../../../../../assets/images/covered_by_kudzu_ineligible.png')}
                        className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                    <div className="flex flex-col justify-center items-center gap-8 w-full">
                        <button
                            type="button"
                            className="focus:outline-none   w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => { navigate('/AccountServices/Spending/TransferMoney') }}
                            autoFocus
                        >
                            {'Add Money to Savings'}
                        </button>
                        <button
                            type="button"
                            className="w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => {
                                setIsCoveredByKudzuIneligible(false);
                                navigate('/Climb/ClimbMenu');
                            }}>
                            {/* {'Maybe later'} */}
                            {t('back_to_climb')}
                        </button>
                    </div>
                </div>}
            />}

        </>

    )
}

export default withOidcSecure(ManageSpendAlerts)