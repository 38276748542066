import React, { useEffect, useState, useRef, useCallback } from 'react';
import Loader from '../../CustomComponents/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { SAVING, retrieveData } from '../../../helpers/utils/Utils';
import { GetApiHeadersWithBearer, GetRequest } from '../../../network/NetworkUtils';
import { STATUS_SUCCESS_CODE, TRANSACTIONS, CHECKING, GET_DDA_DETAILS, SAVINGS, SAVING_CARD_CODE } from '../../../network/URLConstants';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import DynamicCard from '../../CustomComponents/DynamicCard';
import { width } from '../../CustomComponents/DynamicCard/DynamicCardStyles';
import TransactionItemWidget from '../../CustomComponents/TransactionItemWidget';
import { Tabs, Tab } from '../../CustomComponents/TabsComponent';
import LineBreaks, { CheckingAndSavingsHeader, AccountDetailsHeder, copyToClipboard, formatDateToMMDDYYYY, addLineBreaks } from '../../../utils';
import { useTranslation } from 'react-i18next';
import FilterSlider from '../../CustomComponents/Slider';
import { useNavigate, useLocation } from 'react-router-dom';
import { withOidcSecure, useOidcUser, useOidcAccessToken, useOidc } from '@axa-fr/react-oidc'
import moment from 'moment';
import logFirebaseEvents from '../../LogFirebaseEvents';
import Tooltip from '../../Tootltip';
import _ from 'lodash';

const Savings = ({ hideLayout }, Radius) => {
  const currentPath = window.location.pathname;
  const location = useLocation();
  const pageSize = location.pathname === '/home' ? 5 : 10;

  const isMountedRef = useRef(false);
  const navigate = useNavigate();
  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setMonth(currentDate.getMonth() - 6); // Minimum date is 6 months ago
  const maxDate = new Date(); // Maximum date is today
  const { t } = useTranslation(); // Hook to access translation functions
  const userProfile = useSelector(state => JSON.parse(state.userProfile));



  const accessTokenToApi = useSelector(state => state.accessToken);
  const cards = useSelector(state => JSON.parse(state.userCards));
  const [cardData, setCardData] = useState([]);
  const defaultFilterData = { minAmnt: '0', maxAmnt: '500', debit: true, credit: true, minDate: moment(new Date(currentDate).setMonth(currentDate.getMonth() - 6)).format('MM-DD-YYYY'), maxDate: moment(new Date()).format('MM-DD-YYYY') };
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [CardNumber, setCardNumber] = useState('');
  const [CardBalance, setCardBalance] = useState('');
  const [CardText, setCardText] = useState('');
  const [showItems, setShowItems] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [savingsAccountNumber, setCheckingAccountNumber] = useState('*********'); //Account Number
  const [savingsRoutingNumber, setCheckingRoutingNumber] = useState('*********');
  const [accountCopied, setAccountCopied] = useState(false);
  const [routingCopied, setRoutingCopied] = useState(false);
  //const [pageSize, setPageSize] = useState(10);
  const [isViewAllClicked, setIsViewAllClicked] = useState(false);
  const [showFilterOverlay, setShowFilterOverlay] = useState(false);
  const [userName, setUserName] = useState('');
  // const cardDetails = useSelector(state => state.selectedCardDetails);
  const [cardDetails, setCardDetails] = useState({})
  const [selectedTab, setSelectedTab] = useState(t('ActivityTab'));
  const SearchFiltervalue = useSelector(state => state.SearchFiltervalue);
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const [SearchV, setSearchV] = useState('');
  const dispatch = useDispatch();




  // Effect for fetching data userProfile
  useEffect(() => {
    if (userProfile !== undefined && userProfile != null) {
      setUserName(userProfile.FirstName);
    }

  }, []);


  ///navigation
  const handleNavigateToSavings = () => {
    // debugger;
    navigate('/' + SAVINGS + '')
  };

  const aplliedTabFunction = async (appliedTab) => {

  }

  const footerStyles = "font-hanken-grotesk cursor-pointer text-center pt-8 text-[#262B3A] font-bold lg:text-[18px] md:text-[17px] sm:text-[14px] text-[14px] leading-[18px] underline absolute md:bottom-24 bottom-[3rem] left-1/2 -translate-x-1/2 cursor-pointer";
  const footerStylesNoRecords = "font-hanken-grotesk cursor-pointer text-left pt-8 text-[#4B70B9] font-[500] md:font-[500] md:font-[600] lg:text-[18px] md:text-[18px] sm:text-[12px] text-[14px] leading-[18px] opacity-[75%]";

  //search
  const debouncedSubmitSearch = useCallback(
    _.debounce(async (SearchVal, currentFilterData) => {
      setIsLoading(true);
      setSearchV(SearchVal);
      // const cardKey = cards?.[0].CardKey;
      var cardKey;
    for (var i = 0; i < cards?.length; i++) {
      if (cards?.[i].AccountTypeBit63 == SAVING_CARD_CODE) {
        cardKey = cards?.[i].CardKey;
        break;
      }
    }
      const transactionData = await fetchTransactionsData(cardKey, currentFilterData, 1, SearchVal);
      setCardData(transactionData);
      setIsLoading(false);

      setTimeout(()=>{
        dispatch({ type: 'CLOSE_ICON_FROM_STORE', payload: { closeIconFromStore: true } });
      },200)
    }, 600), // 3000ms debounce time (30 seconds)
    [cards, filterData]
  );

  const handleSubmitSearch = (value) => {
    if (value === '') {
      debouncedSubmitSearch.cancel();
      debouncedSubmitSearch('', filterData);
    } else {
      debouncedSubmitSearch(value, filterData);
    }
  };
  const Footer = () => {
    return (
      <div>
        {currentPath === '/home' && (
          <div className={footerStyles} onClick={handleNavigateToSavings}>
            {(t('viewAll'))}
          </div>
        )}

        {currentPath === '/' + SAVINGS + '' && totalPages !== 0 && cardData.length > 0 && (
          <div className={footerStyles} onClick={handleLoadMore}>
            {(t('viewAll'))}
          </div>
        )}
        {/* {currentPath === '/' + SAVINGS + '' && !isViewAllClicked && totalPages === 0 && cardData.length === 0 && ( */}
        {showItems && cardData.length === 0 && totalPages == 0 && (
           <div className={`${footerStylesNoRecords} ${currentPath === '/home' ? 'text-center text-[#559C7E]' : ' text-[#4B70B9] text-left'}`}>
            {(t('notransaction'))}
          </div>
        )}
      </div>
    );
  };



  /////get account details
  const getAccountNumberDetails = async (cardType, cardkey) => {
    if (cardkey == null || cardkey == undefined) {
      return
    }
    //debugger;
    setIsLoading(true);
    let url = GET_DDA_DETAILS + cardkey;
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    var response = await GetRequest(url, headers)
    setTimeout(() => {
      setIsLoading(false)
    }, 500);
    var responseObj = JSON.parse(response);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      if (responseObj.Response != undefined) {
        if (cardType == SAVINGS) {
          setCheckingAccountNumber(responseObj.Response.ExternalSVAID != undefined ? responseObj.Response.ExternalSVAID : '--');
          setCheckingRoutingNumber(responseObj.Response.RoutingNumber != undefined ? responseObj.Response.RoutingNumber : '--')
        }

      }
    } else {
      var arrMessages = responseObj.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;
    }
  }

  //// 
  // Function to fetch transaction data with filters 
  const fetchTransactionsData = async (cardKey, filterData, currentPage, SearchV) => {
    debugger;
    if (cardKey == null || cardKey == undefined) {
      return
    }
    if (SearchV == undefined) {
      SearchV = '';
    }
    try {
      setIsLoading(true);
      const deviceId = await retrieveData('updatedDeviceId');
      const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
      const formattedMaxAmt = filterData.maxAmnt == '500' ? `${filterData.maxAmnt}+` : filterData.maxAmnt;
      const response = await GetRequest(
        TRANSACTIONS(cardKey, filterData.debit, filterData.credit, filterData.minAmnt, formattedMaxAmt, filterData.minDate, filterData.maxDate, pageSize, currentPage, SearchV),
        headers
      );
      const responseObj = JSON.parse(response);
      if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
        setIsLoading(false);
        setShowItems(true);
        const totalPages = responseObj.Response.TotalPages;
        const RespcurrentPage = responseObj.Response.CurrentPage;
        const TotalItemsCount = responseObj.Response.TotalItemsCount;
        setTotalPages(totalPages);
        setCurrentPage(RespcurrentPage);
        setTotalItemsCount(TotalItemsCount);

        if (currentPage * pageSize <= TotalItemsCount) setIsViewAllClicked(false); // Show "Load More" button
        else setIsViewAllClicked(true);

        // if (totalPages < 0) {
        //   setIsViewAllClicked(true); // Show "Load More" button
        // } else {
        //   setIsViewAllClicked(false); // Hide "Load More" button
        // }
        const transactionItems = responseObj?.Response?.Items;
        return transactionItems;
      } else {
        setIsLoading(false);
        return [];
      }
    } catch (error) {
      setIsLoading(false);
      return [];
    }
  };

  const displayViewAllBasedOnTotalItemCounts = () => {
    if (showItems) {
      if (currentPage * pageSize <= totalItemsCount) setIsViewAllClicked(false); // Show "Load More" button
      else setIsViewAllClicked(true);

    }
  }

  ///on load get the data
  useEffect(() => {
    // debugger;
    if (!isMountedRef.current) {
      const fetchData = async () => {
        await fetchInitialData(SAVINGS, currentPage, defaultFilterData, SearchV);
      };
      fetchData();
      isMountedRef.current = true;
    }
  }, []);


  ///get transactions data

  const fetchInitialData = async (cardType, currentPage, filterData, SearchV) => {
    // debugger;
    setIsLoading(true);
    if (SearchV == undefined) {
      SearchV = '';
    }
    var cardKey;
    for (var i = 0; i < cards?.length; i++) {
      if (cards?.[i].AccountTypeBit63 == SAVING_CARD_CODE) {
        setCardText(cards?.[i].NickName ? cards?.[i].NickName : cards?.[i].AccountTypeName);
        setCardNumber(cards?.[i].CardDisplayNumber);
        setCardBalance(cards?.[i].Balance);
        cardKey = cards?.[i].CardKey;
        setCardDetails(cards?.[i])
      }
    }
    //  alert(SAVINGS)
    // Fetch account details only once and store them in state
    if (savingsAccountNumber !== '' && savingsRoutingNumber !== '') {
      await getAccountNumberDetails(cardType, cardKey);
    }

    const transactionData = await fetchTransactionsData(cardKey, filterData, currentPage, SearchV);

    // If it's the first page, set the transaction data as it is
    if (currentPage === 1) {
      setCardData(transactionData);
    } else {
      // If it's not the first page, append the new transactions to the existing data
      setCardData((prevCardData) => [...prevCardData, ...transactionData]);
    }

    setIsLoading(false);
  };


  //load more data

  const handleLoadMore = async () => {
    //  debugger;
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    try {
      await fetchInitialData(SAVINGS, newCurrentPage, filterData);
    } catch (error) {
      // Handle error, if any
      console.error(error);
      setIsLoading(false);
    }
  };

  const onCloseFilter =()=>{
    // setIsViewAllClicked(true);
    if (currentPath === '/home') {
      handleNavigateToSavings();
      setShowFilterOverlay(true);
    } else {
      setShowFilterOverlay((prevState) => !prevState);
      // if(showFilterOverlay) setIsViewAllClicked(false)
    }
  }

  ////filters 
  const handleFilterClick = () => {
    dispatch({ type: 'SEARCH_FILTER_VALUE', payload: { SearchFiltervalue: SearchV } });
    logFirebaseEvents("Savings", "filter_Clicked", "filter_Clicked", "", "");
    // setIsViewAllClicked(true);
    if (currentPath === '/home') {
      handleNavigateToSavings();
      setShowFilterOverlay(true);
    } else {
      setShowFilterOverlay((prevState) => !prevState);
      // if(showFilterOverlay) setIsViewAllClicked(false)
    }
  };

  useEffect(() => {
    if (showFilterOverlay) setIsViewAllClicked(true)
    else displayViewAllBasedOnTotalItemCounts();
  }, [showFilterOverlay])
  //load more data

  const handleApplyFilters = async (data) => {
    logFirebaseEvents("Savings", "Apply_filters_clicked", "Apply_filters_clicked", "", "");
    //debugger;
    setFilterData(data);
    setIsFilterApplied(true)

    // Convert boolean values to "Y" (true) or "N" (false)
    const formattedDebit = data.debit ? 'true' : 'false';
    const formattedCredit = data.credit ? 'true' : 'false';


    // Ensure that minAmnt and maxAmnt are numbers
    const formattedMinAmnt = Number(data.minAmnt);
    const formattedMaxAmnt = Number(data.maxAmnt) === 500 ? `${Number(data.maxAmnt)}+` : Number(data.maxAmnt);

    // const formattedMaxAmnt = Number(data.maxAmnt);

    const formattedMinDt = data.minDate;
    const formattedMaxDt = data.maxDate;


    // Construct the data object to be passed to the API
    const formattedFilterData = {
      minAmnt: formattedMinAmnt,
      maxAmnt: formattedMaxAmnt,
      debit: formattedDebit,
      credit: formattedCredit,
      minDate: formattedMinDt,
      maxDate: formattedMaxDt
    };

    // Clear existing data
    setCardData([]);
    setCurrentPage(1);

    // Fetch data for each card type (e.g., checking) with the updated filterData
    await fetchInitialData(SAVINGS, 1, formattedFilterData,SearchV);
    setShowFilterOverlay(false);

  };

  const handleClearFilters = async () => {
    logFirebaseEvents("Savings", "Reset_filters", "Reset_filters", "", "");
    //alert('Clear Filters');
  };


  ////////////end of the transactions date fetching

  const accountNumberCopiedClicked = () => {
    //debugger;
    logFirebaseEvents("savings", "Account_number_copied", "Account_number_copied", "", "");
    copyToClipboard(savingsAccountNumber)
    setAccountCopied(true);
    setTimeout(() => {
      setAccountCopied(false);
    }, 2000);

  }
  const routingNumberCopiedClicked = () => {
    logFirebaseEvents("savings", "routing_Number_Copied", "routing_Number_Copied", "", "");
    // debugger;
    copyToClipboard(savingsRoutingNumber)
    setRoutingCopied(true);
    setTimeout(() => {
      setRoutingCopied(false);
    }, 2000);
  }

  ///home header 
  const getHeaderText = () => {
    return (
      <>
        {(addLineBreaks(t('basic_account_sideHeading').replace("{TYPE}", 'savings')))}
      </>
    )
    // return (`${t('hello_user').replace("{USER}", userName)}\n${t('basic_account_sideHeading').replace("{TYPE}", SAVINGS)}`)
  };


  const steps = [
    'Tap here to search by transaction name',
    'Tap here for more search tool',
  ];
  const [currentStepSaving, setCurrentStepSaving] = useState(0);
  const [isTooltipVisibleSaving, setIsTooltipVisibleSaving] = useState(false);

  useEffect(() => {
    const tooltipStateSaving = sessionStorage.getItem("tooltipState_Saving");
    console.log('Initial tooltipSaving:', tooltipStateSaving);

    if (tooltipStateSaving === null || parseInt(tooltipStateSaving) < steps.length) {
      setCurrentStepSaving(parseInt(tooltipStateSaving) || 0);
      setIsTooltipVisibleSaving(true);
    } else {
      setIsTooltipVisibleSaving(false);
    }

  }, [steps.length]);


  const handleNextSaving = () => {
    const nextStep = currentStepSaving + 1;
    console.log('Current Saving Step:', currentStepSaving);
    console.log('Next Saving Step:', nextStep);
    if (nextStep < steps.length) {
      setCurrentStepSaving(nextStep);
      sessionStorage.setItem("tooltipState_Saving", nextStep.toString());
    } else {
      handleGotItSaving();
    }
  };

  const handlePreviousSaving = () => {
    const prevStep = currentStepSaving - 1;
    console.log('Current Saving Step:', currentStepSaving);
    console.log('Previous Saving Step:', prevStep);

    if (prevStep >= 0) {
      setCurrentStepSaving(prevStep);
      sessionStorage.setItem("tooltipState_Saving", prevStep.toString());
    }
  };

  const handleGotItSaving = () => {
    console.log('Got It Saving');
    setIsTooltipVisibleSaving(false);
    sessionStorage.setItem("tooltipState_Saving", steps.length.toString());
  };

  const handleGotitWithSearchClickForSaving = () =>{
    setIsTooltipVisibleSaving(false);
    sessionStorage.setItem("tooltipState_Saving", 2);
  }

  const showTooltipStatusForSaving = useSelector(state => state.showTooltipForSavings);



  //  alert(showTooltipStatus);
  const dynamicHomeCard = (
    <DynamicCard
      header={false}
      footer={isViewAllClicked}
      footerContent={<Footer />}

      CardRadius={currentPath === '/' + SAVINGS + '' ? Radius : false}
      cardWidth={width.homeCardWithFilters}
      HeaderRadius={currentPath === '/' + SAVINGS + '' ? Radius : false}
      backgroundColor={'#559C7E'}
      isWhite={true}
      HeaderContent={
        <CheckingAndSavingsHeader
          cardText={CardText}
          amount={CardBalance}
          cardNum={CardNumber}
          handleCopyClick={accountNumberCopiedClicked}
          handleCopyRoutingNumberClick={routingNumberCopiedClicked}
          accountNumber={savingsAccountNumber}
          routingNumber={savingsRoutingNumber}
          cardDetails={cardDetails}

          onClick={() => { }}
        />
      }
    >
      <div className='flex flex-col w-full mt-[1rem]'>
        {currentPath === '/' + SAVINGS + '' && (
          <div className='hidden sm:block md:block md:h-[216px] h-[145px] 
          md:px-16 sm:px-[2.5rem] pl-[2.6rem] pr-8'>
            <AccountDetailsHeder
              AccountNo={savingsAccountNumber}
              routingNo={savingsRoutingNumber}
              accountCopiedProps={accountCopied}
              routingCopiedProps={routingCopied}
              handleCopyClick={accountNumberCopiedClicked}
              handleCopyRoutingNumberClick={routingNumberCopiedClicked}
              contentBetween={true}
              topBorder={false}
              bottomBorder={showFilterOverlay ? false : true}
              isCopyblack={true}
            />
          </div>
        )}
      </div>


      {showItems && !showFilterOverlay && (
        <div className='md:pb-[9rem] pb-[5rem] mt-6 sm:px-0 px-10 relative'>
          {isTooltipVisibleSaving && showTooltipStatusForSaving && (
            <>
              {(cardData && cardData.length > 0) ? (
                <Tooltip
                  steps={steps}
                  onNext={handleNextSaving}
                  onPrevious={handlePreviousSaving}
                  onGotIt={handleGotItSaving}
                  currentStep={currentStepSaving}
                />
              ) : null}
            </>

          )}
          <Tabs selectedTab={selectedTab} updateActiveTab={aplliedTabFunction} cardType={t('savings')} isEnabled={(!isFilterApplied && cardData.length == 0 && SearchV == "")? false :  true} onFilterClick={handleFilterClick} handleSubmitSearch={handleSubmitSearch} handleGotitWithSearchClickForSaving={handleGotitWithSearchClickForSaving}>
            <Tab label={t('ActivityTab')} >
              {(cardData && cardData.length > 0) ? cardData?.map((data) => {
                return (
                  <TransactionItemWidget transactionData={data} />
                )
              }) : 
              (cardData.length === 0 && SearchV) ? <div
              className={`${footerStylesNoRecords} ${currentPath === '/home' ? 'text-center text-[#559C7E]' : 'text-left text-[#4B70B9]'}`}
             >{t('no_Transactions_Found_Invalid')}</div> :
               <div className={`${footerStylesNoRecords} ${currentPath === '/home' ? 'text-center text-[#559C7E]' : ' text-[#4B70B9] text-left'}`}>
                {(t('notransaction'))}
              </div>}
            </Tab>
          </Tabs>
        </div>
      )}

      {showFilterOverlay &&
        (
          <FilterSlider
            applyFilters={handleApplyFilters}
            filterClose={onCloseFilter}
            handleReset={handleClearFilters}
            appliedFilters={filterData}
            selectedTab={selectedTab}
          />

        )
      }
    </DynamicCard>

  );



  return (
    <>
      {hideLayout ? (
        dynamicHomeCard
      ) : (
        <HomeLayout>
          <HomeHeader heading={getHeaderText()} isHeaderCaption={true} />
          <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
          animate__animated animate__fadeInRight '>
            {dynamicHomeCard}
            {isLoading && <Loader />}
          </div>
        </HomeLayout>
      )}
    </>
  );
};

export default withOidcSecure(Savings);
