import React, { useEffect, useState } from 'react';
import blue_drop_down from '../../../assets/images/blue_dropdown_arrow.png'
import blue_right_arrow from '../../../assets/images/RightArrow@2x.png'
import { useTranslation } from 'react-i18next';
import { useKeyDown } from '../../../hooks/useKeyDown';
import { useDispatch, useSelector } from 'react-redux';
import Agreements from '../Agreements';
import { useNavigate } from 'react-router';
import logFirebaseEvents from '../../LogFirebaseEvents';

const HtmlNavigation = ({ agreementType }) => {
    const { t } = useTranslation(); // Hook to access translation functions
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { name, url } = agreementType;
    const cards = useSelector(state => JSON.parse(state.userCards));

    useEffect(() => { }, [])

    const handleNavigation = () => {
        
        dispatch({ type: "UPDATE_AGREEMENT_TYPE", payload: { "documentAgreementType": agreementType } })
        // if (name === "Fees") { navigate('/Documents/Policies/Fees') }
        // else { navigate(`/Documents/Policies/${name}`) }
         navigate(`/Documents/Policies/${name}`);
         logFirebaseEvents("Policies", name.replace(/ /g, '_'), name.replace(/ /g, '_'), "","");
       
    }

    useKeyDown(() => { }, ["Enter"]);


    return (
        <>

            <button className='w-full md:my-5 my-0' onClick={handleNavigation}>
                <div>
                    <div className='flex justify-between flex-row items-center w-full my-5'>

                        <p className="sm:font-[700] font-[600] font-hanken-grotesk  md:text-[16px] sm:text-[11px] text-[16px] leading-[24px] sm:leading-[14px] mr-3 text-left">
                            {name || 'Select'}</p>
                        <img
                            src={blue_right_arrow}
                            className="w-3 h-3 mr-2 ml-10"
                        />
                    </div>
                    <div className='bg-[#E7E4DC] h-0.5 mt-6 md:mt-4 mx-auto'></div>
                </div>
            </button>
            {/* {isOpen && (
                <div className='mt-5'>
                    <Agreements type={props.type} linkClickAction={props.linkClickAction} isFromEsignAccept={false} />
                </div>
            )} */}
        </>
    );
};

export default HtmlNavigation;
