import React from 'react'
import { useTranslation } from 'react-i18next';

const ExistingErrorModal = ({onLoginClick, onTryAgainClick}) => {
    const {t}=useTranslation()
    return (
        <div className="my-10 w-full">
            <div className={`md:w-[450px] sm:w-[294px] xsm:w-[240px] w-[240px] flex flex-col justify-start gap-5`}>
                <div className='lg:pt-20 md:pt-18 sm:pt-10 pt-16 w-full'>


                    <button
                        style={{ backgroundColor: '#2965FB' }}
                        className=' lg:text-[16px] md:text-[14px] sm:text-[13px] text-[14px] w-full lg:h-[60px] md:h-[55px] sm:h-[50px] h-[55px] text-white font-hanken-grotesk font-bold leading-[16px] disabled:opacity-30 focus:border-[#2965FB] focus:border-[#3px]'
                        tabIndex={0}
                        id='btn1AccRef'
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                onLoginClick()
                                event.stopPropagation();
                            }
                        }}
                        onClick={(event) => { event.target.style.opacity = 0.8; onLoginClick(); setTimeout(() => { event.target.style.opacity = 1; }, 200); }}>{t("login")}</button>

                    <button
                        tabIndex={0}
                        id='btn2Ref'
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                onTryAgainClick()
                                event.stopPropagation();
                            }
                        }}
                        className={` w-full mt-5 lg:h-[60px] md:h-[55px] sm:h-[50px] h-[55px] font-hanken-grotesk font-bold lg:text-[16px] md:text-[14px] sm:text-[13px] text-[14px] leading-[16px]  border-[#2965FB] border-[2.5px] focus:border-[#2965FB] focus:border-[#3px]`}
                        style={{ color: '#2965FB' }}
                        onClick={(event) => {
                            event.target.style.opacity = 0.8;
                            onTryAgainClick()
                        }}>{t("try_again1")}</button>

                </div>

            </div>
        </div>
    )
}

export default ExistingErrorModal