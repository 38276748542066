import styled from 'styled-components';
import tw from 'twin.macro';

export const HeaderCaption = styled.div`
  ${tw`lg:w-[340px] md:w-[226px] sm:w-[160px] w-[232px] sm:mt-[-15px]`}
`;


export const Title = styled.div`
  ${tw`text-white font-[400] md:text-[31px] md:leading-[38px] sm:text-[18px] sm:leading-[24px] text-[25px] leading-[31px]`}
  font-family: ${(props) => (props.isPrimary ? 'Source Serif Pro, serif' : 'Hanken Grotesk, sans-serif')};
`;
