import React, { useState, useEffect } from 'react';
import LoaderImg from '../../../assets/images/loader.gif';
import './loader.css'; // Import the CSS file with loader styles

const Loader = () => {
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 12000);

  //   return () => clearTimeout(timeout);
  // }, []);

  if (isLoading) {
    return (
      <div className="loader-overlay">
        <div className="loader-container">
          <img src={LoaderImg} className="loader-img" alt="Kudzu Loader" />
        </div>
      </div>
    );
  }

  return null;
};

export default Loader;