import React from "react";
// import AppScreenShot from "../../../assets/images/app_screenshot.png";
import MessageIcon from "../../../assets/images/message.png";
import AndriodWhiteIcon from "../../../assets/images/android_white.png";
import AppleWhiteIcon from "../../../assets/images/apple_white.png";
import { useTranslation } from "react-i18next";
import LineBreaks from '../../../utils'
import { SectionWrapper, LeftSection, AppScreenshot, RightSection, TextWrapper, MainText, ActionButtonText, AboutText, ActionButton, ActionButtonIcon, DownloadButtonWrapper, DownloadButtonIcon, DownloadButton, DownloadButtonText } from './Aboutstyles'
export const About = () => {
    const { t } = useTranslation();
    const learnSavegrow1 = t('learn_save_grow1');
    const learnSavegrow5 = t('learn_save_grow5');

    return (
        <SectionWrapper>
            <LeftSection>
                <AppScreenshot />
            </LeftSection>
            <RightSection>
                <TextWrapper>
                    <MainText isPrimary>
                        {LineBreaks(learnSavegrow1)} <i>{t("learn_save_grow2")}</i>&nbsp;
                        <i>{t("learn_save_grow3")}</i> {t("learn_save_grow4")}&nbsp;
                        {LineBreaks(learnSavegrow5)}
                    </MainText>
                    <AboutText>
                        {t("about_kudzu")}
                    </AboutText>
                    <ActionButton type="button">
                        <ActionButtonIcon src={MessageIcon} alt="MessageIcon" />
                        <ActionButtonText>{t("text_me_link")}</ActionButtonText>
                    </ActionButton>
                    <DownloadButtonWrapper>
                        <DownloadButton type="button">
                            <DownloadButtonIcon src={AppleWhiteIcon} alt="Apple" />
                            <DownloadButtonText>
                                <span className="">{t("download_iphone")}</span>
                                {/* <span className="md:block sm:hidden block">{t("download_iphone")}</span> */}
                                {/* <span className="md:hidden sm:block hidden">{t("iphoneApp")}</span> */}
                            </DownloadButtonText>
                        </DownloadButton>
                        <DownloadButton type="button">
                            <DownloadButtonIcon src={AndriodWhiteIcon} alt="Android"  />
                            <DownloadButtonText> 
                                <span className=""> {t("download_android")}</span>
                                {/* <span className="md:block sm:hidden block"> {t("download_android")}</span> */}
                                {/* <span className="md:hidden sm:block hidden">{t("Android_App")}</span>  */}
                            </DownloadButtonText>
                        </DownloadButton>
                    </DownloadButtonWrapper>
                </TextWrapper>
            </RightSection>
        </SectionWrapper>



    );
};

export default About;
