import React, { useState, useEffect, useRef } from 'react'
import Arrow from '../../../../assets/images/arrow.png';
import { useTranslation } from 'react-i18next';
import Card from '../../../CustomComponents/Card';
import addLineBreaks, { displayErrorMessage, retrieveData } from '../../../../utils';
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import Loader from '../../../CustomComponents/Loader';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import CustomInput from '../../../CustomComponents/FormInputs';
import { GetApiHeadersWithOutBearer, PostRequest } from '../../../../network/NetworkUtils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { STATUS_SUCCESS_CODE, STUDENT_RESEND_EMAIL_CODE, STUDENT_SIGNUP_VERIFY_NEW_ACCOUNT, VERIFY_ACCESS_CODE } from '../../../../network/URLConstants';
import { useDispatch, useSelector } from 'react-redux';
import Error from '../../../../assets/images/Error.png';
import Check from '../../../../assets/images/acceptedcheck.png';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';


const appConfigData = await fetchConfigarationData();
const StudentAccessCode = ({ handleBackOpenEmail, handleOpenCreatePassword }) => {
  const [isLoading, setIsLoading] = useState(false);
  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [isValid, setvalidCode] = useState(false);
  const sessionId = useSelector(state => state.sessionId);
  const userId = useSelector(state => state.userId);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue placeholder:font-semibold md:text-[16px] sm:text-[14px] text-[16px] md:font-[700] sm:font-[700] font-[700] focus:outline-none w-[292px] h-[60px] mb-4 rounded-full";
  const { t } = useTranslation();
  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]";
  const [validAccessCode, setValidAccessCode] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeError, setAccessCodeError] = useState("");
  const [isVerifiedCode, setIsVerifiedCode] = useState(false);
  const [codeAcceptedBtnsEnable, setCodeAcceptedBtnsEnable] = useState(false);
  const [tryAgainBtnEnabled, setTryAgainBtnEnabled] = useState(true);
  const [initialContinue, setInitialContinue] = useState(true);
  const [message, setMessage] = useState('');
  const [resendCount, setResendCount] = useState(1);
  const [readonlyVal, setReadonlyVal] = useState(false);
  const [showErrorImg, setshowErrorImg] = useState(false);
  const [textMessage, setTextMessage] = useState(false);
  const [btn1Focus, setBtn1Focus] = useState(false);
  const [btn2Focus, setBtn2Focus] = useState(false);
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [showModel, setShowModel] = useState(false);
  const [headerMsg, setheaderMsg] = useState('');
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('');
  const [modelImage, setModelImage] = useState('');
  const [footerMsg, setFooterMsg] = useState('');
  const [isSubComponent, setIsSubComponent] = useState(false);
  const [buttonEnable,setButtonEnable] = useState(true)
  let retryCount = 0;



  const validationSchema = yup.object().shape({
    accessCode: yup.string().required('')
    // accessCode: yup
    // .string()
    // .required(t('accessCode_is_required'))
  });

  const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
    // mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });


  const handleAccessCode = (e) => {
    setMessage('');
    clearErrors();
    const val = e.target.value;
    if (val.length >= 6) {
      //   onAccessCodeValid(true);
      setValidAccessCode(true); 
      setTryAgainBtnEnabled(false);
      setButtonEnable(false)
      

    }
    else {
      //   onAccessCodeValid(false);
      setValidAccessCode(false);
      setTryAgainBtnEnabled(true)
      setButtonEnable(true)
    }
    setAccessCode(val)

  }


  const resendAccessCode = async () => {
    debugger;
    setValue('access Code', '');
    setMessage('');
    setValidAccessCode(false);
    const otpresendNumber = resendCount;
    // if (otpresendNumber >= 4) {
    //   setMessage(t('exceeded_max_limit'))
    //   return;
    // }
    try {
      debugger;
      const deviceId = await retrieveData('updatedDeviceId');
      const headers = await GetApiHeadersWithOutBearer(deviceId);
      setIsLoading(true)
      const reqObj = {
        applicationId: appConfigData.CLIENT_ID,
        "language": "en"
      }
      var response = await PostRequest(STUDENT_RESEND_EMAIL_CODE(userId), reqObj, headers);
      var responseObj = JSON.parse(response);
      if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        debugger;
        const sessionIdPayload = { sessionId: responseObj.Response.sessionId }
        dispatch({ type: 'SESSION_ID', payload: sessionIdPayload });
        setTimeout(() => {
          const resendNumber = resendCount + 1;
          setResendCount(resendNumber);
          setMessage('')
          setIsLoading(false)
        }, 500);
      } else {
        setTimeout(() => {
          var arrMessages = responseObj.Messages;
          var message = arrMessages[0];
          var displayText = message.DisplayText;
          setMessage(displayText);
          setIsLoading(false)
        }, 500);
      }
    } catch (error) {
      setMessage(error.message);
    }
  }


  const verifyAccessCode = async () => {

    if (retryCount >= 3) {
      setMessage(t('exceeded_max_limit'));
      setTryAgainBtnEnabled(true)
      return;
    } 
    try {
      debugger;
      const deviceId = await retrieveData('updatedDeviceId');
      const headers = await GetApiHeadersWithOutBearer(deviceId);
      setIsLoading(true)
      const reqObj = {
        applicationId: appConfigData.CLIENT_ID,
        code: accessCode.toUpperCase()
      }
      var response = await PostRequest(STUDENT_SIGNUP_VERIFY_NEW_ACCOUNT(userId, sessionId), reqObj, headers);
      var responseObj = JSON.parse(response)
      setIsLoading(false)
      if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        setReadonlyVal(true);
        setCodeAcceptedBtnsEnable(true);
        setInitialContinue(false)
        setIsVerifiedCode(true)
      } else {
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        // setMessage(t("access_code_error"));
        setMessage(displayText);
        retryCount++;
        // showAlertModal(true, t("oh_no"), t("nolongerValid"),
        // t('customerContact'),
        // true, false, t('home'), t("cancel"), true,
        // require('../../../../assets/images/spending-alert-error.png'), '', false, true, '', false, false, true); 
        // setTryAgainBtnEnabled(true)
      }
    } catch (error) {
      // setMessage(error.message);
     
      setTryAgainBtnEnabled(true);
    }
  }




  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, textMessage, isSubComponent, btn1Focus, btn2Focus) => {
    setshowErrorImg(showErrorImg)
    setisBorderNone(isBorderNone)
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setFooterMsg(footerMsg)    
    setTextMessage(textMessage)
    setIsSubComponent(isSubComponent)
    setBtn1Focus(btn1Focus)
    setBtn2Focus(btn2Focus)
  }


  const handlAddLater = () => {
    showAlertModal(true, t("not_to_worry"), t("add_latertime"),
      t('add_latertime_info'),
      true, false, t('continue'), t("cancel"), true,
      require('../../../../assets/images/later.png'), '', false, true, '', false, false, true);
  }
  // for popup

  const closeModel = () => {
    setShowModel(false);
  }

  
  useEffect(()=>{
 
  },[])
  


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if(!buttonEnable){
        if (initialContinue) {
          verifyAccessCode();
        } else {
          handleOpenCreatePassword();
          setCodeAcceptedBtnsEnable(false)
        }
      }
    }
  }

  useEffect(() => {
    const inputField = document.querySelector('input[name="accessCode"]');
    if (inputField) {
      inputField.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (inputField) {
        inputField.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [initialContinue, accessCode]); 

  const handleKeyRestrict = (e) => {
    const char = String.fromCharCode(e.which);
    if (!/[a-zA-Z0-9]/.test(char)) {
        e.preventDefault();
    }
}



  return (
    <>
      <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0">
        <Card
          heading={
            <>
              <span className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)] lg:text-[31px]
             md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
              md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('student_selction_heading1')} </span>
              <span className='font-serif-pro sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)] lg:text-[31px]
             md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px]
              md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('student_acces_code_sub-heading2')}</span>
            </>
          }
          subheading={addLineBreaks(t('student_acces_code_sub-heading3'), SubTittleStylesModal)}
          header={true} footer={false} CardRadius={true}
          backgroundColor={codeAcceptedBtnsEnable ? "var(--onbaord-navbar-bg)" : ""}
          cardHeight={'700px'}
          footerContent={
            <>
              {tryAgainBtnEnabled ? (
                <div className="flex sm:flex-row sm:gap-0 gap-3 flex-col justify-between sm:mx-0 mx-4">
                  <p className="font-hanken-grotesk md:text-[18px] sm:text-[12px] text-[16px] leading-7 text-secondary-3 font-medium md:ml-[181px] sm:ml-[20px]">
                    {t("didnt_recieve_email")}{" "}
                    <span
                      tabIndex={0}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          // resendVerificationCode();
                        }
                      }}
                      className="font-hanken-grotesk font-semibold sm:text-[14px] text-[16px] md:text-md text-[#2965FB] cursor-pointer"
                      onClick={resendAccessCode}
                    >
                      {t("try_again")}<span className="dot"></span>
                    </span>
                  </p>
                  <button
                    type="button"
                    onClick={handlAddLater}
                    className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex items-center md:mr-[65px] sm:ml-[10px]"
                    onKeyDown={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                  >
                    {t("add_later")}
                  </button>
                </div>
              ) :
                codeAcceptedBtnsEnable ? (
                  <div className="flex justify-between flex-nowrap h-full items-center w-[85%] m-auto">
                    <div className="flex items-center">
                      <span className='mr-2 ml-2'><img src={Check} size={20} alt="Check" className='text-white' /></span>
                      <p className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white cursor-pointer flex items-center"
                      >{t('CodeAccepted')}</p>
                    </div>
                    <button
                      type="button"
                      className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white cursor-pointer flex items-center"
                      onClick={handleOpenCreatePassword}
                    >
                      {t('continue')}
                    </button>
                  </div>
                ) : initialContinue?
                  (
                    <div className="flex md:justify-end sm:justify-center items-center md:mb-28 sm:mb-12 mb-28">
                      <button
                        type="button"
                        className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex items-center mr-32"
                        onClick={handleBackOpenEmail}
                        onKeyDown={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                      >
                        {t('back')}
                      </button>
                      <button
                        type="button"
                        disabled={buttonEnable}
                        className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex items-center md:mr-14"
                        onClick={verifyAccessCode}
                      >
                        {t('continue')}
                      </button>
                    </div>
                  ):
                  ""}
            </>
          }
        >

          <p className='sm:block hidden text-[#262B3A] font-serif-pro lg:leading-[36px] md:leading-[24px] 
            leading-[31px] font-[400] lg:text-[32px] md:text-[29px] sm:text-[22px] text-[25px]'>
            <span className='text-[var(--text-clr-secondary2)]'>
              {t('sign_easy')}</span><span> {t('pleaseenteraccessCode')}</span>
            <p>{t('access_code')}</p>
          </p>

          <p className='lg:text-[18px] md:text-[18px] sm:text-[12px] 
          text-[14px] lg:leading-7 md:leading-[22px] sm:leading-[22px] 
          leading-7 font-hanken-grotesk text-secondary-3
           font-medium mt-4'>{t('checkMail')}</p>

          <div className="flex flex-col mt-36   sm:my-20 " >
            {/* <div className="flex flex-row sm:w-[322px] w-full items-center mt-14 " > */}
            <CustomInput
              name="accessCode"
              label={t('access_code')}
              value={accessCode}
              error={errors.accessCode}
              autoFocus={true}
              readOnly={readonlyVal}
              type="text"
              register={register}
              onChange={handleAccessCode}
              placeholder={t('access_code')}
              isValid={validAccessCode}
              additionalStyle={'tracking-widest placeholder:tracking-normal uppercase placeholder:normal-case'}
              onKeyDown={handleKeyPress}
              onKeyPress={handleKeyRestrict}
            />
            {message &&
              <div className='mb-2'>
                {displayErrorMessage(message)}
              </div>
            }
          </div>

        </Card>
        {isLoading && <Loader />}
      </div>

      {showModel && (
        <CustomAlertModel
          isOpen={showModel}
          onClose={closeModel}
          Fullheight={true}
          image={modelImage}
          highlightMsg={<p className={`${HighlightMsgStyles}`}>{highlightMsg}
            <span className={`${HeaderMsgStyles} text-black`}> {headerMsg}</span></p>}
          subHeaderMsg={
            <p className={SubHeaderMsgStyles}>{subHeaderMsg} </p>
          }
          isSubComponent={isSubComponent}
          textMessage={textMessage}
          btn1Exists={btn1Exists}
          btn2Exists={btn2Exists}
          btn1Text={btn1Text} btn2Text={btn2Text}
          btn1TypeAcpt={btn1TypeAcpt}
          verificationFailure={() => { }}
          footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
          showErrorImg={showErrorImg}
          isBorderNone={isBorderNone}
          btn1Focus={btn1Focus}
          btn2Focus={btn2Focus}
          onAcptBtnClick={handleOpenCreatePassword}
        />
      )}
    </>
  )
}

export default StudentAccessCode;