import React, { useState, useEffect } from 'react'
import { width } from '../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import CustomTextInput from '../../../../CustomComponents/CustomtextInput'
import RightArrow from "../../../../../assets/images/RightArrow@2x.png"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../CustomComponents/Loader'
import DynamicCard from '../../../../CustomComponents/DynamicCard'
import { withOidcSecure } from '@axa-fr/react-oidc'
import CustomDatePicker from '../../../../CustomComponents/CustomDateInput'
import { displayErrorMessage } from '../../../../../utils'
import dayjs from 'dayjs'
import logFirebaseEvents from '../../../../LogFirebaseEvents'
const DateCard = ({ cardHeader, cardFooter, onBack, onNext }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [dateError, setDateError] = useState(null)
  const [isNext, setIsNext] = useState(false);

  const selectedDate = useSelector(state => state.spendAlertsPayload.startDate)


  const handleBackClick = () => {
    logFirebaseEvents("SpendSense_Alert", "Back_DateCard_Clicked", "Back_DateCard_Clicked","", "")
    onBack("timeFrame")
  }
  const handleNextClick = () => {
    logFirebaseEvents("SpendSense_Alert", "Next_DateCard_Clicked", "Next_DateCard_Clicked","", "")
    if (!selectedDate) {
      setDateError('please select start date');
    } else {
      setDateError('');
      if (selectedDate && !dateError) onNext("notificationType");
    }
  }

  const onDateChange = (date) => {
    dispatch({ type: 'ADD_SPEND_ALERTS', payload: { 'key': "startDate", 'value': date } })
  }


  useEffect(() => {
    if (selectedDate){
      setDateError(null)
      setIsNext(true);
    } else{
      setIsNext(false);

    }
  }, [selectedDate])


  const children = () => {
    return (
      <>
        {cardHeader}

        <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4 sm:flex sm:flex-col '>
          <span>{t("spending_alert_date_content1")} </span>
          <span className='text-secondary-3'>{t("spending_alert_date_content2")}</span>
        </div>

        <div className='w-full flex justify-center'>
          <div className="sm:w-[66%] w-full">
            <div className='md:my-32 sm:my-16 mt-10 mb-[9rem] sm:mx-0 mx-4'>
              <div className='md:w-[80%] w-full flex flex-col-reverse gap-14 sm:flex-col justify-center'>
                <div>
                  <CustomDatePicker
                    selectedDate={selectedDate}
                    onDateChange={(date) => onDateChange(date)}
                    minDate={new Date(new Date().toUTCString().substr(0, 25))}
                    // maxDate={''}
                    placeHolder={'Select Date'}
                    disabledDates={[]}
                    title="Start"
                    hrbg='bg-dropdown-sky'
                  />
                  <div className='my-2 w-full'>
                    {dateError && displayErrorMessage(dateError)}
                  </div>
                </div>
                <p className=' font-hanken-grotesk text-[#262B3A] sm:font-[500]
                  lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px] leading-[30px] 
                  font-normal'>{t("spendingAlertsDateMsg")}</p>
              </div>
            </div>
          </div>
        </div>
        {cardFooter(handleBackClick, handleNextClick,'','', isNext)}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>
      {isLoading && <Loader />}
    </>

  )
}


export default withOidcSecure(DateCard)



