import React, { useEffect, useState } from 'react';
import blue_drop_down from '../../../assets/images/blue_dropdown_arrow.png'
import { useTranslation } from 'react-i18next';
import { useKeyDown } from '../../../hooks/useKeyDown';
import RadialFill from '../../../assets/images/Radial Fill.png'
import RadialNull from '../../../assets/images/Radial Null.png'
import { displayErrorMessage } from '../../../utils';


const CustomRadioButton = ({ isTitle = true, title, options = [], fillIcon, nullIcon, frequency, iconStyle, onSelect, error, isRow = 'default', isRowOnMobileSrn = false, isTextBelow = false }) => {
    const { t } = useTranslation(); // Hook to access translation functions

    const evenArrayOptions = options.filter((option, index) => index % 2 === 0)
    const oddArrayOptions = options.filter((option, index) => index % 2 !== 0)


    return (
        <>
            {isRow === 'default' &&
                <div className='w-full flex flex-col'>

                    {isTitle &&
                        <p className=' font-hanken-grotesk font-[700] sm:font-[600]
                        leading-[12px] lg:text-[13px] md:text-[12px] sm:text-[9px] text-[13px] 
                         sm:leading-[9px] md:leading-[13px] 
                        text-secondary-3 uppercase opacity-50 tracking-[2px] md:pb-6 pb-5'>
                            {title?.toLocaleUpperCase() || ''}</p>
                    }

                    <div className={`flex ${isRowOnMobileSrn ? 'flex-col sm:flex-row' : 'flex-row'} w-full justify-between`}>
                        <div className={`${isRowOnMobileSrn ? 'w-full' : 'w-[50%]'}`}>
                            {oddArrayOptions.reverse().map((option, index) => {
                                return (
                                    <div className='flex flex-col mb-5 sm:mb-0 gap-4 w-full mt-3'>
                                        {/* //odd */}

                                        {/* <div className='flex flex-row  gap-4  sm:py-0 py-4' onClick={() => onSelect(option, index)} key={index}> */}
                                        <div className='flex flex-row justify-start items-start gap-1 sm:gap-4' onClick={() => onSelect(option, index)} key={index}>
                                            {(frequency === (option.displayText) || option?.isSelected) ?
                                                <button>
                                                    <img src={fillIcon ? fillIcon : RadialFill} alt="Fill" className={`${iconStyle ? iconStyle : 'cursor-pointer h-[20px] w-[20px] sm:h-[18px] sm:w-[18px] md:h-[20px] md:w-[20px]'}`} />

                                                </button>
                                                :
                                                <button>
                                                    <img src={nullIcon ? nullIcon : RadialNull} alt="Null" className={`${iconStyle ? iconStyle : 'cursor-pointer h-[20px] w-[20px] sm:h-[18px] sm:w-[18px] md:h-[20px] md:w-[20px] '}`} />

                                                </button>
                                            }
                                            <div className='flex flex-col'>
                                                <p className='font-hanken-grotesk font-[600] sm:font-[700] lg:text-[16px] md:text-[16px] sm:text-[13px] text-[14px] leading-[20px]
                                            sm:leading-[20px]  md:leading-[24px] text-secondary-3'>{option.displayText}</p>
                                                {isTextBelow && (
                                                    <p className='font-hanken-grotesk font-[500]
                  leading-[20px] sm:leading-[20px] lg:text-[14px] md:text-[13px] sm:text-[13px] text-[14px] 
                  md:leading-[22px] text-secondary-3  md:w-[201px] sm:w-[155px] w-[201px] mt-4'>{t("percent_based_text")}</p>

                                                )}
                                            </div>
                                        </div>

                                    </div>

                                )
                            })}

                        </div>
                        <div className={`${isRowOnMobileSrn ? 'w-full' : 'w-[50%]'}`}>
                            {evenArrayOptions.map((option, index) => {
                                return (
                                    <div className='flex flex-col gap-4 w-full mt-3'>
                                        {/* //even */}
                                        <div className='flex flex-row justify-start items-start gap-1 sm:gap-4' onClick={() => onSelect(option, index)} key={index}>

                                            {(frequency === (option.displayText) || option?.isSelected) ?
                                                <button>

                                                    <img src={fillIcon ? fillIcon : RadialFill} alt="Fill" className={`${iconStyle ? iconStyle : 'cursor-pointer h-[20px] w-[20px] sm:h-[18px] sm:w-[18px] md:h-[22px] md:w-[22px]'}`} />
                                                </button>
                                                :
                                                <button>
                                                    <img src={nullIcon ? nullIcon : RadialNull} alt="Null" className={`${iconStyle ? iconStyle : ' cursor-pointer h-[20px] w-[20px] sm:h-[18px] sm:w-[18px] md:h-[22px] md:w-[22px]'}`} />

                                                </button>
                                            }
                                            <div className='flex flex-col'>
                                                <p className=' font-hanken-grotesk font-[600] sm:font-[700] lg:text-[16px] md:text-[16px] sm:text-[13px] text-[14px] leading-[20px]
                                            sm:leading-[20px]  md:leading-[24px] text-secondary-3'>{option.displayText}</p>
                                                {isTextBelow && (
                                                    <p className='font-hanken-grotesk font-[500]
                  leading-[20px] sm:leading-[20px] lg:text-[14px] md:text-[13px] sm:text-[13px] text-[14px] 
                  md:leading-[22px] text-secondary-3  md:w-[201px] sm:w-[155px] w-[201px] mt-4'>{t("amount_based_text")}</p>
                                                )}
                                            </div>

                                        </div>


                                    </div>

                                )
                            })}

                        </div>


                    </div>
                    <div className='mt-[10px] w-full'>
                        {error && displayErrorMessage(error)}
                    </div>
                </div>

            }
            {isRow === 'row' &&

                <div className='w-full flex flex-col'>

                    {isTitle &&
                        <p className=' font-hanken-grotesk font-[700] sm:font-[600] 
                         leading-[12px] lg:text-[13px] md:text-[12px] sm:text-[9px] text-[13px] 
                          sm:leading-[9px] 
                         md:leading-[13px] text-secondary-3 uppercase opacity-50 tracking-[2px] 
                         md:pb-6 pb-5'>{title?.toLocaleUpperCase() || ''}</p>
                    }

                    <div className='flex flex-row w-full justify-between'>
                        <div className=' w-full'>
                            <div className='flex flex-col sm:flex-row gap-10 sm:gap-2 w-full mt-3 justify-between'>
                                {options.map((option, index) => {

                                    return (

                                        <div className='flex flex-row justify-start items-start gap-4 sm:gap-4' onClick={() => onSelect(option, index)} key={index}>
                                            {frequency === (option.displayText) ?
                                                <button>
                                                    <img src={fillIcon ? fillIcon : RadialFill} alt="Fill" className={`${iconStyle ? iconStyle : 'h-[20px] w-[20px] sm:h-[18px] sm:w-[18px] md:h-[20px] md:w-[20px]'}`} />
                                                </button>
                                                :
                                                <button>
                                                    <img src={nullIcon ? nullIcon : RadialNull} alt="Null" className={`${iconStyle ? iconStyle : 'h-[20px] w-[20px] sm:h-[18px] sm:w-[18px] md:h-[20px] md:w-[20px]'}`} />
                                                </button>
                                            }
                                            <p className=' font-hanken-grotesk font-[600] sm:font-[700] lg:text-[16px] md:text-[16px] sm:text-[13px] text-[14px] leading-[20px]
                                            sm:leading-[20px]  md:leading-[24px] text-secondary-3'>{option.displayText}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='mt-[10px] w-full'>
                        {error && displayErrorMessage(error)}
                    </div>
                </div>
            }

        </>

    );
};

export default CustomRadioButton;
