import { withOidcSecure } from '@axa-fr/react-oidc'
import React from 'react'
import RightArrow from "../../../../../../assets/images/arrow.png"
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

const SpendAlertsItem = ({ data, isShow, type }) => {
    //alert("isShow"+isShow);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { amount, frequencyType, id } = data
    const handleClick = () => {
        if (type === 'SpendingAlert') {
            const addSpendId = { spendsSelctedId: id }
            dispatch({ type: 'ADD_SPEND_ID', payload: addSpendId });
            navigate('/Climb/SpendSenseAlerts/EditSpend/');
        } else {
            const addSavingId = { savingsHABSelectedId: id }
            dispatch({ type: 'ADD_SAVNIGS_HAB_ID', payload: addSavingId });
            navigate('/Climb/SavingsHabit/EditHabit');
        }
    };
    return (
        <>
            <div
                className={`flex flex-row justify-between items-center w-full my-8 cursor-pointer ${!isShow ? 'opacity-[0.1]' : 'opacity-1'
                    } ${!isShow ? 'pointer-events-none' : ''}`}
                onClick={handleClick} tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") handleClick() }}
            >
                <p className="font-hanken-grotesk flex items-center font-[700] lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px] leading-[16px] text-secondary-3">
                {`$${amount}`}
                <span >/</span>
                {`${frequencyType}`}
                </p>
                <img src={RightArrow} alt="arrow" className='mr-[.5rem]' />
            </div>
            <hr className="h-[2px] bg-[#94C3E8] border-0 rounded opacity-[65%]" />
        </>
    )
}

export default withOidcSecure(SpendAlertsItem)
