import React, { useEffect, useState } from "react";
import { HeaderCaption, Space, Title, Subtitle, Main, CardSection, Container, CardTitle, CardFooter, CardHeader } from "./DynamicCardStyles";
const DynamicCard = ({ CardRadius,Radiusnone, HeaderRadius, isHeaderCaption, isSpace, heading, isHome, subheading, isActive, Cardcaption, Cardsubcaption, children, header, HeaderContent, footer, cardHeight, footerContent, cardWidth, isPerk, isProfile, isWhite, backgroundColor, isIFrame=false, isDark }) => {

  return (
    <>
      {/* {!isHeaderCaption &&
        <HeaderCaption>
          <Title>
            {heading}
            {subheading}
          </Title>
        </HeaderCaption>
      }
      {!isSpace &&
        <Space></Space>
      } */}
      <Main>
        {/* <Main style={{width:"80%"}}> */}
        <CardSection CardRadius={CardRadius} isPerk={isPerk} isProfile={isProfile} isWhite={isWhite} isDark={isDark} Radiusnone={Radiusnone}>
          {!header &&
            <CardHeader backgroundColor={backgroundColor} HeaderRadius={HeaderRadius} isActive={isActive}  >
              {HeaderContent}
            </CardHeader>
          }
          {isIFrame ? 
            <div className={`w-full ${HeaderRadius ? 'rounded-tl-[4rem] rounded-br-[4rem]': 'rounded-tr-[4rem] rounded-bl-[4rem]'} `}>
              {children}
            </div>
          :

          <Container style={{ width: cardWidth }} isWhite={isWhite}>
            <CardTitle isPrimary>{Cardcaption}</CardTitle>
            {children}
          </Container>
          }

          {!footer &&
            <CardFooter style={{ position: (cardHeight !== 'content-fit' && cardHeight !== null) ? 'absolute' : '', bottom: (cardHeight !== 'content-fit' && cardHeight !== null) ? '0' : '' }}>
              {footerContent}
            </CardFooter>
          }
        </CardSection>
      </Main>
    </>
  );
};

export default DynamicCard;