import React, { useEffect, useState } from 'react'
import Card from '../Card';
import { height, width } from '../Card/CardStyles';
import PayPerksImg from '../../../assets/images/homepayperks.png';
import DynamicCard from '../DynamicCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PayPerksDays from '../../../assets/images/payperks_days.png';
import PayPerksArrow from '../../../assets/images/payperkArrow.png';
import PayPerksStar from '../../../assets/images/payperkStar.png';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PAYPERKS_DASHBOARD, GET_PAY_PERKS_API, STATUS_SUCCESS_CODE } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { fetchConfigarationData } from '../../../helpers/utils/Utils';
import { retrieveData } from '../../../utils';
import logFirebaseEvents from '../../LogFirebaseEvents';

const appConfigData = await fetchConfigarationData();

const PayPerks = (Radius) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cards = useSelector(state => JSON.parse(state.userCards));
  const payperksDays = useSelector(state => state.payperksDays);
  const [payperksUrl, setpayperksUrl] = useState('');
  const [pendingDaysToSweep, setpendingDaysToSweep] = useState('');
  const [pointBalance, setpointBalance] = useState('');
  const [payperkStatus, setpayperkStatus] = useState('');
  const [isOpted, setisOpted] = useState(false);
  const accessTokenToApi = useSelector(state => state.accessToken);

  ///For Pay perks 
  const getPayperksDetails = async () => {
    if (cards == undefined || cards == null) {
      return
    }
    const deviceId = await retrieveData('updatedDeviceId');
    let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

    let url = GET_PAY_PERKS_API(cards[0].CardKey) + '&version=' + appConfigData.APP_VERSION + '&isDarkMode=true';
    const response = await GetRequest(url, headers)
    let responseObj = JSON.parse(response);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      const payperksURL = { payperksURL: responseObj.Response.URL }
      dispatch({ type: 'PAYPERKS_DASHBOARD_URL', payload: payperksURL });
      GetPayperkDashboardDetails(responseObj.Response.AuthToken, cards[0].CardKey)
    } else {
      // PAYPERKS_ERROR
      const arrMessages = responseObj.Messages;
      const message = arrMessages[0];
      const displayText = message.DisplayText;

      const payperksError = { payperksError: displayText }
      dispatch({ type: 'PAYPERKS_ERROR', payload: payperksError });
    }
  }

  const GetPayperkDashboardDetails = async (authToken, cardKey) => {
    if (cardKey == undefined) {
      return
    }

    const deviceId = await retrieveData('updatedDeviceId');
    let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

    var obj = { "authToken": authToken };
    let finalUrl = GET_PAYPERKS_DASHBOARD(cardKey)
    const response = await PostRequest(finalUrl, obj, headers)
    var parsedResponse = JSON.parse(response)
    if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
      if (parsedResponse.Response != null) {
        const payperksDays = { payperksDays: parsedResponse.Response.PendingDaysToSweep.toString().length == 1 ? "0" + parsedResponse.Response.PendingDaysToSweep.toString() : parsedResponse.Response.PendingDaysToSweep.toString() }
        dispatch({ type: 'PAYPERKS_DAYS', payload: payperksDays });

        setpendingDaysToSweep(parsedResponse.Response.PendingDaysToSweep.toString().length == 1 ? "0" + parsedResponse.Response.PendingDaysToSweep.toString() : parsedResponse.Response.PendingDaysToSweep.toString());
        setpointBalance(parsedResponse.Response.Profile != null ? parsedResponse.Response.Profile.PointBalance : 0);
        setpayperkStatus(parsedResponse.Response.Profile != null ? parsedResponse.Response.Profile.Status : '');
        setisOpted(parsedResponse.Response.Profile != null ? parsedResponse.Response.Profile.IsOpted : false);
      } else {
        const payperksError = { payperksError: t('something_went_wrong') }
        dispatch({ type: 'PAYPERKS_ERROR', payload: payperksError });
      }
    } else {
      const arrMessages = parsedResponse.Messages;
      const message = arrMessages[0];
      const displayText = message.DisplayText;
      const payperksError = { payperksError: displayText }
      dispatch({ type: 'PAYPERKS_ERROR', payload: payperksError });
    }
  }

  useEffect(() => {
    getPayperksDetails()
  }, [])

  return (
    <DynamicCard header={true}
      footer={true} isHome={true} cardHeight={height.perks_profile}
      cardWidth={width.homeCard} isPerk={true} HeaderRadius={Radius} Radiusnone={true}
      CardRadius={Radius}>
      <div className='md:py-20 sm:py-12 py-16'>
        {isOpted ?
          <>
            <div className=' sm:flex sm:flex-row sm:justify-between sm:items-start md:px-16 sm:px-[2.5rem] px-6 '>
              <div className=' flex flex-col '>
                <div className=' flex flex-row justify-between items-start'>
                  <div className="font-serif-pro text-[#0C4437] lg:w-[205px] md:w-[177px] sm:w-[138px]
                w-[200px] 
              lg:text-[38px] md:text-[25px] sm:text-[22px] text-[26px]
              font-normal pb-8">
                    {t('NavbarPerks')}
                  </div>

                <img src={PayPerksImg} className=' flex sm:hidden mt-2 w-[70px] h-[43px] sm:w-[80px] sm:h-[48px] md:w-[132px] md:h-[80px]' />


                </div>
                <div className=' flex flex-col md:flex-row '>
                  {/* box1 */}
                  <div className='flex flex-col justify-center items-center md:pr-10 md:border-r-4 border-[#A3CEBB] border-b-4 md:border-b-0'>
                    <div className=' flex flex-row justify-start items-start gap-2 md:gap-4 w-full sm:w-[240px] md:w-auto'>
                      <img src={PayPerksArrow} alt='arrow' className=' md:w-[20px] md:h-[18px] sm:w-[17px] sm:h-[16px] w-[16px] h-[15px] md:mt-2 sm:mt-4 mt-5' />
                      <div className='flex flex-row md:flex-col justify-between md:justify-center items-start w-full gap-4 md:gap-0'>
                        <p className=' font-hanken-grotesk text-[#00654F] md:text-[20px] md:font-[500] sm:text-[14px] sm:font-[600] text-[16px] font-[600] mt-4 md:mt-0 w-1/3 sm:w-1/2 md:w-auto'>{t("nxt_sweep")}</p>
                        <div className=' flex flex-row gap-4 items-end justify-end w-2/3 sm:w-1/2 md:w-auto'>
                          <p className=' font-hanken-grotesk text-[#00654F] md:text-[92px] sm:text-[48px] p-0 text-[56px] font-[600] tracking-wider '>
                            {pendingDaysToSweep}
                            {/* 10 */}
                          </p>
                          <p className=' font-hanken-grotesk text-[#00654F] md:text-[20px] md:font-[700] sm:text-[12px] sm:font-[700] text-[16px] font-[700] pb-8'>
                            {t("days")}
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                  {/* box2 */}
                  <div className='flex flex-col justify-center items-center md:pl-10 border-0 '>
                    <div className=' flex flex-row justify-start items-start gap-2 md:gap-4 w-full sm:w-[240px] md:w-auto'>
                      <img src={PayPerksStar} alt='arrow' className=' md:w-[20px] md:h-[20px] sm:w-[17px] sm:h-[17px] w-[16px] h-[16px] md:mt-2 sm:mt-4 mt-5' />
                      <div className='flex flex-row md:flex-col justify-between md:justify-center items-start w-full gap-4 md:gap-0'>
                        <p className=' font-hanken-grotesk text-[#00654F] md:text-[20px] md:font-[500] sm:text-[14px] sm:font-[600] text-[16px] font-[600] mt-4 md:mt-0 w-1/3 sm:w-1/2 md:w-auto'>{t("pt_balance")}</p>
                        <div className=' flex flex-row gap-4 items-end justify-end w-2/3 sm:w-1/2 md:w-auto'>
                          <p className=' font-hanken-grotesk text-[#00654F] md:text-[92px] sm:text-[48px]  text-[56px] font-[600] tracking-wider '>
                            {pointBalance}
                            {/* 0 */}
                          </p>
                          <p className=' font-hanken-grotesk text-[#00654F] md:text-[20px] md:font-[700] sm:text-[12px] sm:font-[700] text-[16px] font-[700] pb-8'>
                            {t("points")}
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                 
                </div>

              </div>
              <div>
                <img src={PayPerksImg} className='hidden sm:block w-[70px] h-[43px] sm:w-[80px] sm:h-[48px] md:w-[132px] md:h-[80px]' />
              </div>

            </div>
          </>
          :
          <div className=' flex justify-start flex-col'>
          <div className="sm:flex block sm:px-[7%] px-10 w-[100%] justify-between items-center sm:items-start md:items-center">
            <div>
              <div className="font-serif-pro text-[#0C4437] lg:w-[205px] md:w-[177px] sm:w-[138px]
              w-[200px] 
            lg:text-[32px] md:text-[25px] sm:text-[22px] text-[25px]
            font-normal lg:leading-[38px] leading-[33px]">
                {t('don’t_miss_our_next_payPerks')}
              </div>
              <div className='md:block hidden lg:w-[300px] md:w-[300px] sm:w-[160px] py-4 font-hanken-grotesk text-[#00654F] lg:text-[18px]
            md:text-[16px] sm:text-[13px] font-medium'>
                {t('payPerks_promo_copy')}</div>
            </div>

            <div className='relative sm:block flex justify-center sm:items-start sm:my-0 my-6'>
              <img src={PayPerksDays} className='lg:w-[180px] lg:h-[160.83px] md:w-[150px] md:h-[140.83px]
              sm:w-[100.01px] sm:h-[110px] w-[128px] h-[130px]' alt="PayPerksDays" />
              <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center'>

                <span className='lg:text-[84px] md:text-[64px] sm:text-[48px] text-[56px] 
                text-[#00654F] font-hanken-grotesk
                lg:leading-[60.04px] md:leading-[40.04px] sm:leading-[31.36px] leading-[44px] font-[600]'>{payperksDays != null ? payperksDays : 0}</span>

                <p className='mt-2 lg:text-[20px] md:text-[17px] sm:text-[12px] text-[16px] 
                text-[#00654F] font-hanken-grotesk leading-[19.3px] font-[700]'>Days</p>
              </div>
            </div>

            <div className='sm:sticky absolute top-[4.8rem] right-16'>
              <img src={PayPerksImg} className='lg:w-[139px] lg:h-[84px] md:w-[110px] 
              md:h-[80px] sm:w-[80px] sm:h-[50px] w-[70px] h-[43px] md:p-2 sm:mt-8 mt-0 md:mt-0' />
            </div>
          </div>

          <div className='sm:flex md:hidden hidden sm:w-[350px] py-4 font-hanken-grotesk text-[#00654F] lg:text-[18px]
            md:text-[16px] sm:text-[13px] font-medium sm:px-[7%]'>
                {t('payPerks_promo_copy')}</div>
            

          </div>
        }
        <div className='flex justify-end sm:px-[7%] px-10 sm:-mt-6'>
          <button className='lg:w-[170px] md:w-[170px] sm:w-[118px] w-full lg:text-[18px] md:text-[18px] sm:text-[15px] text-[16px] font-hanken-grotesk
             bg-[#00654F] float-right rounded-full px-3 py-2 md:py-4 font-[700] text-white'
            onClick={() => { navigate('/PayPerks'); logFirebaseEvents("Home", "get_Start_Clicked", "get_Start_Clicked", "",""); }}>{isOpted ? 'Earn Points' : t('Get_Started')}</button>
        </div>
      </div>

    </DynamicCard>
  )
}

export default PayPerks;