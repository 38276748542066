import React, { useEffect, useState } from 'react'
import CustomInput from '../../../CustomComponents/FormInputs';
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import { useTranslation } from 'react-i18next';
import Loader from '../../../CustomComponents/Loader';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const AccessCode = ({ openAccessCodeNoWorriesModal, onAccessCodeValid, onAccessCode, onAccessCodeError, verifyAccessCode }) => {
    const { t } = useTranslation();
    const [accessCode, setAccessCode] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [validAccessCode, setValidAccessCode] = useState(false)

    const validationSchema = yup.object().shape({
        accessCode: yup.string().required(t("access_code_error"))
    });

    const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
    });


    const handleAccessCode = (e) => {
        clearErrors();
        const val = e.target.value;
        if (val.length >= 8) {
            onAccessCodeValid(true)
            setValidAccessCode(true)
        }
        else {
            onAccessCodeValid(false)
            setValidAccessCode(false)
        }
        setAccessCode(val)
        onAccessCode(val)

    }

    useEffect(() => {
        onAccessCode("")
    }, [])


    // useEffect(() => {
    //     if (onAccessCodeError) {

    //         setError('accessCode', {
    //             type: 'manual',
    //             message: onAccessCodeError,
    //         });
    //     }
    // }, [onAccessCodeError])

  const handlAddLater = () =>{

  }

    return (
        <div className=''>
            <div className="flex flex-col mt-20 mb-32 sm:my-20 " >
                <CustomInput
                    name="accessCode"
                    label={t('access_code')}
                    value={accessCode}
                    error={errors.accessCode}
                    autoFocus={true}
                    type="text"
                    register={register}
                    onChange={handleAccessCode}
                    placeholder={t('access_code')}
                    isValid={validAccessCode}
                    additionalStyle={'tracking-widest placeholder:tracking-normal uppercase placeholder:normal-case'}
                    onKeyDownFun={(event) => {
                        if (event.key === "Enter") {
                            console.log("entered")

                            if (accessCode.length >= 1) verifyAccessCode()
                            else {
                                setError('accessCode', {
                                    type: 'manual',
                                    message: t("access_code_error"),
                                });
                            }

                        }
                        event.stopPropagation();
                    }}

                />

            </div>

            {/* <p tabIndex={0}
                onKeyDown={(event) => {
                    if (event.key == "Enter") {
                        event.preventDefault();
                        openAccessCodeNoWorriesModal()
                    }
                }}
                className="font-hanken-grotesk font-semibold sm:text-[14px] text-[16px] md:text-md text-[#2965FB]  cursor-pointer underline"
                onMouseDown={() => {
                    openAccessCodeNoWorriesModal()
                }
                }>
                {t("no_access_code")}</p> */}

                <div className="flex justify-between  mt-20">
                    <p className="font-hanken-grotesk  md:text-[18px] sm:text-[12px] text-[16px]  leading-7 text-secondary-3 font-medium">
                        {t("didnt_recieve_email")}{" "}
                        <span tabIndex={0}
                        onKeyDown={(event) => {
                            if (event.key == "Enter") {
                            event.preventDefault();
                            // resendVerificationCode();
                            }
                        }}
                        className="font-hanken-grotesk font-semibold sm:text-[14px] text-[16px] md:text-md text-[#2965FB]  cursor-pointer" onClick={""}>
                        {t("try_again")}<span className="dot"></span>
                        </span>

                    </p>
                <button
                  type="button"
                  className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center"
                  onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault();} }}
                // Call handleContinue instead of openPhoneOTPModal directly
                >
                  {t("add_later")}
                  
                </button>
              </div>

            {isLoading && <Loader />}
        </div>
    )
}

export default AccessCode