import React, { useEffect } from 'react';
import swal from 'sweetalert';

const SweetAlert = ({ heading, icon, button, time,confirmButtonClicked }) => {
    const showAlert = () => {
        swal({
            text: heading,
            buttons: button && {
                confirm: {
                  text: "Login",
                  value: true,
                  visible: true,
                  className: "custom-confirm-class",
                },
              },
            timer: time,
            icon: icon,
          }).then((confirmed) => {
            if (confirmed) {
              // This code will be executed when the confirm button is clicked
              // alert("Confirm button clicked");
              confirmButtonClicked();
            }
          });
          
          
          
    };

    useEffect(() => {
        showAlert(); // Call showAlert when the component mounts
    }, []);

};

export default SweetAlert;
