import React, { useEffect, useState } from "react";
import Loader from "../CustomComponents/Loader";
import HomeLayout from "../Layout/HomeLayout";
import Footer from "../Layout/Footer";
// import Sidebar from './SideBar';

import { useDispatch, useSelector, connect } from "react-redux";
import {
  CHECKING_CARD_CODE,
  SAVING,
  retrieveData,
} from "../../helpers/utils/Utils";
import {
  STATUS_SUCCESS_CODE,
  GET_USED_AMOUNT_DETAILS,
  TRANSACTIONS,
  SAVINGS,
  CHECKING,
  SPENDING,
  isDEV,
  ISDEV_ENV,
  SAVING_CARD_CODE,
  GENERATE_OTP_VIEW_CARD,
  GENERATE_UNLOCK_CARD_OTP,
  LOCK_CARD_API,
  UNLOCK_CARD_API,
  CAN_CREATE_SAVINGS_ACC,
} from "../../network/URLConstants";
import {
  GetApiHeadersWithBearer,
  GetRequest,
  PostRequest,
} from "../../network/NetworkUtils";
import { fetchConfigarationData } from "../../helpers/utils/Utils";
import HomeHeader from "../CustomComponents/HomeHeader";
import Checking from "./Checking";
import Savings from "./Savings";
import PayPerks from "../CustomComponents/PayPerks";
import {
  withOidcSecure,
  useOidcUser,
  useOidcAccessToken,
  useOidc,
} from "@axa-fr/react-oidc";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  clearStorageExceptKey,
  convertTimestamp,
  storeData,
} from "../../utils";
import { store } from "../../Redux/store";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CustomAlertModel from "../CustomComponents/CustomAlertModel";
import Modal from "../CustomComponents/Modal";
import Custom2FA from "../CustomComponents/Custom2FA";
import lockCardGreenTick from "../../assets/images/lock_green_tick.png";
import lockCardGreen from "../../assets/images/lock_green.png";
import unLockCardGreen from "../../assets/images/unlock_green.png";
import warningImg from "../../assets/images/warning.png";
import { LOGIN_SESSION_KEY } from "../../Redux/reducers";
import FooterContent from "../CustomComponents/Footer";
import AddMoneyComponent from "../CustomComponents/AddMoneyComponent";
import ClimbImg from "../../assets/images/illo-climb-home.png";
import beneficiarysaving from "../../assets/images/beneficiarysaving.png";
import LineBreaks from "../../utils";
import TwoFactTypeSelctionModal from "../CustomComponents/TwoFactTypeSelctionModal";
import moment from "moment";
import logFirebaseEvents from "../LogFirebaseEvents";

const Home = ({ children }) => {
  const { t } = useTranslation();
  const oidcUser = useOidcUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [checkingSpentAmmount, setCheckingSpentAmmount] = useState("");
  const [savingsSpentAmmount, setSavingsSpentAmmount] = useState("");
  const [userName, setUserName] = useState("");
  const [spendAmountDays, setSpendAmountDays] = useState(7);
  const [showWelcomHeader, setShowWelcomeHeader] = useState(false);

  const accessTokenToApi = useSelector(state => state.accessToken);
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const cards = useSelector(state => JSON.parse(state.userCards));
  const userProfile = useSelector(state => JSON.parse(state.userProfile));
  const userPreferredName = useSelector(state => state.preferredName);
  console.log("userPreferredName", userPreferredName)
  //test comment

  const { logout } = useOidc();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  var isUserLoggedIn = isLoggedIn;
  const isEvenLength = cards !== null && cards.length % 2 === 0;
  const [selectedOption, setSelectedOption] = useState("");

  const [showModel, setShowModel] = useState(false);
  const HighlightMsgStyles =
    "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles =
    "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles =
    "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState("");
  const [headerMsg, setheaderMsg] = useState("");
  const [subHeaderMsg, setSubHeaderMsg] = useState("");
  const [btn1Exists, setBtn1Exists] = useState(false);
  const [btn2Exists, setBtn2Exists] = useState(false);
  const [btn1Text, setBtn1Text] = useState(false);
  const [btn2Text, setBtn2Text] = useState("");
  const [modelImage, setModelImage] = useState("");
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false);
  const [footerMsg, setFooterMsg] = useState("");
  const [btn1Focus, setBtn1Focus] = useState(false);
  const [btn2Focus, setBtn2Focus] = useState(false);
  const [modalBg, setModalBg] = useState("");
  const [btn1TextColor, setBtn1TextColor] = useState("");
  const [btn1BrExist, setBtn1BrExist] = useState(false);
  const [btn2TextColor, setBtn2TextColor] = useState("");
  const [btn1BgColor, setBtn1BgColor] = useState("");
  const [btn1BorderColor, setBtn1BorderColor] = useState("");
  const [quickActions, setQuickActions] = useState(true);
  const SelectedCardDetails = useSelector((state) => state.selectedCardDetails);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [showErrorImg, setshowErrorImg] = useState(false);
  const [cardDetails, setCardDetails] = useState({});
  const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
  const twoFactAuthenticationDone = useSelector(
    (state) => state.twoFactAuthenticationDone
  );
  const [show2faSelection, setshow2faSelection] = useState(false);
  const [otpGenerationType, setOtpGenerationType] = useState(false);
  const [savingsTheme, setSavingsTheme] = useState(false);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  // const savingsCardExist = cards?.some(item => item.AccountTypeName === "Savings")
  const savingsWithBalanceOver0 = cards?.find((obj) => obj?.Balance > 0);
  const savingsCardExist = cards?.find((obj) => obj?.AccountTypeBit63 == 32);
  const [isSubFooter, setIsSubFooter] = useState(false);

  const checkNewAccountBasedOnApplicationDate = () => {
    const spendingCardAppDate = cards[0]?.ApplicationDate;
    // const spendingCardAppDate="2023-12-08T00:00:00";
    if (convertTimestamp(spendingCardAppDate, true) === "lessThan7days")
      setShowWelcomeHeader(true);
    else setShowWelcomeHeader(false);
  };

  useEffect(() => {
    logFirebaseEvents("Home", "Home_Screen_visited", "Home_Screen_visited", "","");
    checkNewAccountBasedOnApplicationDate();
  }, []);

  // const handleLogout = () => {

  //   if (isUserLoggedIn) {
  //     alert('1')
  //     isUserLoggedIn = false
  //     logout().then(isSuccess => {
  //       const loginPayload = { isLoggedIn: false };
  //       dispatch({ type: 'LOG_IN', payload: loginPayload });
  //       const deviceIdKey = 'updatedDeviceId';
  //       clearStorageExceptKey(sessionStorage, deviceIdKey);
  //       clearStorageExceptKey(localStorage, deviceIdKey);
  //       store.dispatch({ type: 'LOGOUT' });
  //       navigate('/');
  //       window.location.href = window.location.origin;
  //     })
  //   }
  // };

  // useEffect(() => {
  //   if (!ISDEV_ENV) {
  //     window.addEventListener('popstate', function (event) {
  //       handleLogout()
  //     });
  //     window.addEventListener('beforeunload', function (event) {
  //       handleLogout()
  //     });
  //   }
  // }, []);

  // Effect for fetching data userProfile

  useEffect(() => {
    if (userPreferredName !== undefined && userPreferredName != null) {
      setUserName(userPreferredName);
    }
    if (cards !== null && cards !== undefined) {
      cards.map((item) => {
        const { AccountTypeName, CardKey } = item;
        if (AccountTypeName == SPENDING || AccountTypeName == CHECKING) {
          getAmountUsedDetails(AccountTypeName, CardKey);
        }
      });
    }
    // setTimeout(() => {
    //   checkAndNavigateToPath()
    // }, 2000);
  }, []);

  async function checkAndNavigateToPath() {
    const path = await retrieveData("DEEPLINK_PATH");
    if (path != "" && path != null && path.toLowerCase() != "/home") {
      storeData("DEEPLINK_PATH", "");
      if (
        path.toLowerCase().includes("viewcard") ||
        path.toLowerCase().includes("changepin")
      ) {
        navigate("/CardServices/CardActionScreen");
      } else {
        navigate(path);
      }
    }
  }

  // Effect for fetching getAmountUsedDetails
  async function getAmountUsedDetails(AccountTypeName, cardKey) {
    if (cardKey != 0 && cardKey != undefined) {
      setIsLoading(true);
      var url = GET_USED_AMOUNT_DETAILS + cardKey + "&days=" + spendAmountDays;
      const deviceId = await retrieveData("updatedDeviceId");
      const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
      var response = await GetRequest(url, headers);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      var responseObj = JSON.parse(response);
      if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        if (responseObj.Response != undefined) {
          if (responseObj.Response.amount != undefined) {
            const amount = parseFloat(responseObj.Response.amount).toFixed(2);
            if (AccountTypeName == CHECKING || AccountTypeName == SPENDING) {
              setCheckingSpentAmmount(
                amount == 0.0 ? "0.00" : amount.toString()
              );
            } else {
              setSavingsSpentAmmount(
                amount == 0.0 ? "0.00" : amount.toString()
              );
            }
          }
        }
      }
    }
  }

  const handleCardAction = (type) => {
    if (type == t("view_card")) {
      logFirebaseEvents(
        "Home",
        "view_Card_Clicked",
        "view_Card_Clicked",
        "",""
      );
      setSelectedOption(t("view_card_details"));
    } else {
      setSelectedOption(type);
    }
    // alert(type);
    if (type == t("lock_card")) {
      logFirebaseEvents(
        "Home",
        "Lock_Card_Clicked",
        "Lock_Card_Clicked",
        "",""
      );
      showAlertModal(
        true,
        t("are_you_sure"),
        t("this_card_will_be_locked"),
        t("once_you_lock_your_card_any_new_transactions_will_be_declined"),
        true,
        true,
        t("lock_card"),
        t("cancel"),
        true,
        lockCardGreen,
        "",
        false,
        true,
        false,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        false
      );
    } else if (type == t("unlock_card")) {
      dispatch({
        type: "SELECTED_USER_CARD_DETAILS",
        payload: { selectedCardDetails: cards[0] },
      });
      // if (!twoFactAuthenticationDone) { setshow2faSelection(true) }
      // else { showAlertModal(true, t("ready_to_unlock_questionmark"), t("unlock_your_card_to_resume_normal_use"), t('once_you_unlock_your_card_all_locked'), true, true, t('unlock_card'), t("cancel"), true, unLockCardGreen, '', false, true, true, false, undefined, undefined, undefined, undefined, undefined, undefined, false) }
      showAlertModal(
        true,
        t("ready_to_unlock_questionmark"),
        t("unlock_your_card_to_resume_normal_use"),
        t("once_you_unlock_your_card_all_locked"),
        true,
        true,
        t("unlock_card"),
        t("cancel"),
        true,
        unLockCardGreen,
        "",
        false,
        true,
        true,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        false
      );
    } else if (type == t("view_card")) {
      logFirebaseEvents(
        "Home",
        "Spending_Clicked",
        "Spending_Clicked",
        "",
        ""
      );
      dispatch({
        type: "SELECTED_USER_CARD_DETAILS",
        payload: { selectedCardDetails: cards[0] },
      });
      if (!twoFactAuthenticationDone) {
        setshow2faSelection(true);
      } else {
        setTimeout(() => {
          navigate("/CardServices/CardActionScreen/ViewCard");
        }, 500);
      }
    } else if (type == t("QucikBtnTransfermoney")) {
      logFirebaseEvents(
        "Home",
        "Transfer_Money_Clicked",
        "Transfer_Money_Clicked",
        "",
        ""
      );
      dispatch({
        type: "SELECTED_SUB_INDEX",
        payload: { selectedSubIndex: 1 },
      });
      global.selectedAccountServiceCardKey =
        cards !== null ? cards[0].CardKey : 0;
      navigate("/AccountServices/Spending/TransferMoney");
    } else if (type == t("QucikBtnMoreActions")) {
      logFirebaseEvents(
        "Home",
        "More_Actions_Clicked",
        "More_Actions_Clicked",
        "",
        ""
      );
      navigate("/AccountServices");
    }
  };
  const callApiToLockCard = async () => {
    setIsLoading(true);
    setShowModel(false);
    const deviceId = await retrieveData("updatedDeviceId");
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    let object = {
      CardKey: cards !== null ? cards[0].CardKey : 0,
    };
    PostRequest(LOCK_CARD_API, object, headers).then((response) => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);

      let responseObj = JSON.parse(response);
      if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        setTimeout(() => {
          updateCardStatus(
            cards !== null ? cards[0].CardKey : 0,
            responseObj.Response
          );
          showAlertModal(
            true,
            t("done_exclamation"),
            t("this_card_is_now_locked"),
            t("any_new_tx_will_be_declined"),
            true,
            false,
            "Ok",
            "",
            true,
            lockCardGreenTick,
            "",
            false,
            false,
            true,
            false,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            false
          );
        }, 1000);

        // this.updateCardsInLocal(responseObj.Response, LOCK_CARD)
      } else {
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        showAlertModal(
          true,
          t("oops_exclamation"),
          "",
          displayText,
          true,
          false,
          "Ok",
          "",
          true,
          warningImg,
          "",
          false,
          false,
          true,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false
        );
        setTimeout(() => {}, 1000);
      }
    });
  };

  const updateCardStatus = (cardKey, response) => {
    const updatedCardIndex = cards?.findIndex(
      (card) => card.CardKey === cardKey
    );
    if (updatedCardIndex !== -1) {
      const updatedCard = {
        ...cards[updatedCardIndex],
        Status: response.Status,
        SubStatus: response.SubStatus,
        StatusCode: response.StatusCode,
        SubStatusCode: response.SubStatusCode,
        // NewCardRequestedDate: response.NewCardRequestedDate !== undefined ? response.NewCardRequestedDate : Moment().format("YYYY-MM-DD") + "T" + "00:00:00",
      };
      const updatedArray = [
        updatedCard,
        ...cards.filter((card) => card.CardKey !== cardKey),
      ];
      dispatch({
        type: "UPDATE_USER_CARDS",
        payload: { userCards: JSON.stringify(updatedArray) },
      });
      dispatch({
        type: "SELECTED_USER_CARD_DETAILS",
        payload: { selectedCardDetails: updatedCard },
      });
    }
  };
  const callApiToUnlockCard = async () => {
    if (cards == null || cards == undefined) {
      return;
    }
    setIsLoading(true);
    setShowModel(false);

    const deviceId = await retrieveData("updatedDeviceId");
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    let object = {
      CardKey: cards !== null ? cards[0].CardKey : 0,
    };

    PostRequest(UNLOCK_CARD_API, object, headers).then((response) => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);

      let responseObj = JSON.parse(response);
      if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        setTimeout(() => {
          setShowModel(false);
          updateCardStatus(
            cards !== null ? cards[0].CardKey : 0,
            responseObj.Response
          );
          showAlertModal(
            true,
            t("success_heading"),
            t("this_card_is_now_unocked"),
            t("you_can_use_your_card"),
            true,
            false,
            "Ok",
            "",
            true,
            unLockCardGreen,
            "",
            false,
            false,
            true,
            false,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            false
          );
        }, 1000);

        // this.updateCardsInLocal(responseObj.Response, UNLOCK_CARD)
      } else {
        setShowModel(false);
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        showAlertModal(
          true,
          t("oops_exclamation"),
          "",
          displayText,
          true,
          false,
          "Ok",
          "",
          true,
          warningImg,
          "",
          false,
          false,
          true,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false
        );
        setTimeout(() => {}, 1000);
      }
    });
  };
  const generate2FAOtp = async (type) => {
    const deviceId = await retrieveData("updatedDeviceId");
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);

    const requestObject = {
      cardKey: SelectedCardDetails?.CardKey,
    };
    if (type !== undefined || type !== null) {
      requestObject.IsEmail = type == "Email" ? true : false;
    }

    let url;
    setIsLoading(true);

    switch (selectedOption) {
      case t("view_card"):
        url = GENERATE_OTP_VIEW_CARD;
        break;
      case t("unlock_card"):
        url = GENERATE_UNLOCK_CARD_OTP.replace(
          "{cardKey}",
          `${SelectedCardDetails.CardKey}`
        );
        break;
      default:
        url = GENERATE_OTP_VIEW_CARD;
        break;
    }
    try {
      const response = await PostRequest(url, requestObject, headers);
      setIsLoading(false);

      const parsedResponse = JSON.parse(response);
      if (parsedResponse.ResponseCode === STATUS_SUCCESS_CODE) {
        //adding sessionid to redux
        const loginSessionPayLoad = {
          loggedSessionKey: parsedResponse.Response.sessionId,
        };
        dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
        //adding userid to redux
        const userIdPayload = { userId: parsedResponse.Response.userId };
        dispatch({ type: "USER_ID", payload: userIdPayload });
        setTimeout(() => {
          setIsOTPVerifyModalOpen(true);
        }, 1000);
      } else {
        const arrMessages = parsedResponse.Messages;
        const message = arrMessages[0];
        const displayText = message.DisplayText;
        showAlertModal(
          true,
          t("oops_exclamation"),
          "",
          displayText,
          true,
          true,
          t("home"),
          t("back_to_card_services"),
          true,
          warningImg,
          "",
          false,
          false,
          true,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false
        );

        // Handle the error message or displayText as needed
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const canCreateSavingsAccount = async () => {
    const deviceId = await retrieveData("updatedDeviceId");
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    setIsLoading(true);

    let finalUrl = CAN_CREATE_SAVINGS_ACC(cards[0].CardKey);
    var response = await GetRequest(finalUrl, headers);
    var responseObj = JSON.parse(response);
    setIsLoading(false);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      const canCreateNew = responseObj.Response.canCreateNew;
      const eligibleDate = moment(responseObj.Response.eligibleDate).format(
        "MM/DD/YYYY"
      );
      if (canCreateNew) {
        enrollSavingsAccount();
      } else {
        showAlertModal(
          true,
          t("oh_no"),
          t("unable_to_open"),
          `${t("u_will_be_eligible")} ${eligibleDate}. `,
          true,
          false,
          t("home"),
          "",
          true,
          require("../../assets/images/illo-report.png"),
          "",
          false,
          false,
          false,
          false,
          "",
          "",
          false,
          "",
          "",
          "",
          false,
          true
        );
      }
    } else {
      setTimeout(() => {
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        showAlertModal(
          true,
          t("oh_no"),
          displayText,
          "",
          true,
          false,
          t("home"),
          "",
          true,
          require("../../assets/images/illo-report.png")
        );

        // setError(displayText)
      }, 500);
    }
  };

  const subComponent = () => {
    return (
      <div className=" flex justify-start items-start">
        <p className="font-hanken-grotesk font-semibold sm:font-[500] sm:text-[14px] md:text-[20px] text-[16px] md:leading-[30px] leading-[24px]">
          {t("pl_contact_customer")}
          <span
            className="text-[#2965FB] cursor-pointer"
            onClick={() =>
              window.open("https://www.kudzumoney.com/contact-us", "_blank")
            }
          >
            {t("support_email")}
          </span>
          {t("for_access")}
        </p>
      </div>
    );
  };

  const enrollSavingsAccount = () => {
    const savingsNewPayload = { savingsNewClicked: true };
    dispatch({ type: "SAVINSGS_DISABLED", payload: savingsNewPayload });
    showAlertModal(
      true,
      "",
      t("set_aside_money_for_the_things_that_matter"),
      t("add_savings_account_subTitle"),
      true,
      true,
      t("enroll"),
      t("may_be_later"),
      true,
      beneficiarysaving,
      "",
      false,
      true,
      true,
      false,
      "bg-[#E4F1EB]",
      "#FEFAEE",
      true,
      "#559C7E",
      "#30BC89",
      "#30BC89",
      true
    );
  };
  const closeOTPVerifyModal = () => {
    setIsOTPVerifyModalOpen(false);
  };
  const verificationSuccess = (type, verifyResponse) => {
    if (selectedOption == t("unlock_card")) {
      updateCardStatus(
        cards !== null ? SelectedCardDetails.CardKey : 0,
        verifyResponse.Response
      );
      // callApiToUnlockCard();
      showAlertModal(
        true,
        t("success_heading"),
        t("this_card_is_now_unocked"),
        t("you_can_use_your_card"),
        true,
        false,
        "Ok",
        "",
        true,
        lockCardGreenTick,
        "",
        true,
        false,
        true,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
    } else if (selectedOption == t("view_card_details")) {
      setTimeout(() => {
        navigate("/CardServices/CardActionScreen/ViewCard");
      }, 500);
    }
  };
  const onCancelBtnClick = () => {
    if (btn2Text == "Cancel") {
      setShowModel(false);
    } else if (btn2Text == t("may_be_later")) {
      setShowModel(false);
      showAlertModal(
        true,
        "",
        t("savings_no_problem_title"),
        t("via_account_services_title"),
        true,
        false,
        t("okay_got_it"),
        "",
        true,
        beneficiarysaving,
        "",
        false,
        true,
        true,
        false,
        "bg-[#E4F1EB]",
        "#FEFAEE",
        true,
        "#559C7E",
        "#30BC89",
        "#30BC89",
        true
      );
    } else if (btn1Text == t("back_to_card_services")) {
      closeModel();
      navigate("/CardServices");
    }
  };
  const onAcptBtnClick = () => {
    if (btn1Text == t("lock_card")) {
      callApiToLockCard();
    } else if (btn1Text == t("unlock_card")) {
      if (!twoFactAuthenticationDone) {
        closeModel();
        setshow2faSelection(true);
      } else callApiToUnlockCard();
    } else if (btn1Text == t("enroll")) {
      //need to show
      closeModel();
      navigate("/SetupSavingsAccount");
      dispatch({
        type: "SSN_SCREEN_THROUGH",
        payload: { ssnScreenThrough: t("account_services_savings_enrollment") },
      });
    } else if (btn1Text == t("okay_got_it")) {
      closeModel();
    } else if (btn1Text == "Ok") {
      closeModel();
    } else if (btn1Text == t("home")) {
      closeModel();
    }
  };
  const closeModel = () => {
    setShowModel(false);
  };

  //                      showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, savingsTheme, isBorderNone, isSubFooter
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, showErrorImg, isBorderNone, btn1Focus, btn2Focus, modalBg, btn1TextColor, btn1BrExist, btn2TextColor, btn1BgColor, btn1BorderColor, savingsTheme, isSubFooter) => {
    setshowErrorImg(showErrorImg)
    setisBorderNone(isBorderNone)
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setFooterMsg(footerMsg)
    setBtn1Focus(btn1Focus)
    setBtn2Focus(btn2Focus)
    setModalBg(modalBg)
    setBtn1TextColor(btn1TextColor)
    setBtn1BrExist(btn1BrExist)
    setBtn2TextColor(btn2TextColor)
    setBtn1BgColor(btn1BgColor)
    setBtn1BorderColor(btn1BorderColor)
    setSavingsTheme(savingsTheme)
    setIsSubFooter(isSubFooter)
  }
 
  ///home header
  const getHeaderText = () => {
    if (showWelcomHeader)
      return LineBreaks(
        `${t("hello_user").replace("{USER}", userName)}\n${t("welcm_to_kudzu")}`
      );
    return LineBreaks(
      `${t("hello_user").replace("{USER}", userName)}\n${t(
        "spent_on_card"
      ).replace("{AMOUNT}", checkingSpentAmmount)}`
    );
  };
  useEffect(() => {
    console.log();
  });

  return (
    <HomeLayout>
      <HomeHeader heading={getHeaderText()} isHeaderCaption={true} />
      <div
        className="flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight"
      >
        {cards !== null &&
          cards?.find(
            (item) =>
              item.AccountTypeBit63 == CHECKING_CARD_CODE && item.Balance != 0
          ) && (
            <Checking
              hideLayout
              Radius={isEvenLength}
              handleCardAction={handleCardAction}
            />
          )}

        {savingsCardExist && savingsWithBalanceOver0 && (
          <Savings hideLayout Radius={isEvenLength} />
        )}

        {savingsCardAvailable == false && (
          <FooterContent
            cardHeight={"200px"}
            backgroundColor={"#D3D3D3"}
            btnBgColor={"#30BC89"}
            btnTextColor={"#FFFFFF"}
            isSubheadingwidth={true}
            highlightTextColor={"#0C4437"}
            subHeaderTextColor={"#0C4437"}
            highlightText={t("grow_urMoney_withSavings")}
            subHeaderText={LineBreaks(t("set_aside_funds_savings"))}
            image={beneficiarysaving}
            buttonEnabled={true}
            buttonText={t("enroll")}
            isSpace={true}
            footerBtnAction={canCreateSavingsAccount}
            type={"AddSavings"}
            borderDirection={
              cards?.[0]?.AllowPayPerks
                ? cards?.length % 2 == 0
                  ? "odd"
                  : "even"
                : cards?.length % 2 == 0
                ? "even"
                : "odd"
            }
          />
        )}

        {/* these are new requirement changes */}

        {cards !== null &&
          cards?.find(
            (item) =>
              item.AccountTypeBit63 == CHECKING_CARD_CODE && item.Balance == 0
          ) && (
            <AddMoneyComponent
              selectedItem={cards.find(
                (item) => item.AccountTypeBit63 == CHECKING_CARD_CODE
              )}
              headerBgColor={"#30BC89"}
              titleTextColor={"text-[#FFFFFF]"}
              backgroundColor={"bg-[#C8EBDB]"}
              btnBgColor={"#30BC89"}
              highlightTextColor={"text-[#00654F]"}
              highlightText={t("spending_empty_account")}
              buttonText={t("addMoneyTittle")}
              btnTextColor={"#FFFFFF"}
              footerBtnAction={() => {
                let cardObject = cards.find(
                  (item) => item.AccountTypeBit63 == CHECKING_CARD_CODE
                );
                global.selectedAccountServiceCardKey = cardObject.CardKey;
                logFirebaseEvents(
                  "Home",
                  "Spending_add_Money_Clicked",
                  "Spending_add_Money_Clicked",
                  "",""
                );
                navigate("/AccountServices/Spending/TransferMoney");
              }}
              borderDirection={true}
            />
          )}
        {savingsCardExist && !savingsWithBalanceOver0 && (
          <AddMoneyComponent
            selectedItem={cards.find(
              (item) => item.AccountTypeBit63 == SAVING_CARD_CODE
            )}
            headerBgColor={"#A3CEBB"}
            titleTextColor={"text-[#00654F]"}
            cardHeight={"200px"}
            backgroundColor={"bg-[#FEFAEE]"}
            btnBgColor={"#30BC89"}
            highlightTextColor={"text-[#00654F]"}
            highlightText={t("saving_empty_account")}
            buttonText={t("addMoneyTittle")}
            btnTextColor={"#FFFFFF"}
            footerBtnAction={() => {
              let cardObject = cards.find(
                (item) => item.AccountTypeBit63 == SAVING_CARD_CODE
              );
              global.selectedAccountServiceCardKey = cardObject.CardKey;
              logFirebaseEvents(
                "Home",
                "Savings_add_Money_Clicked",
                "Savings_add_Money_Clicked",
                "",
                ""
              );
              navigate("/AccountServices/Savings/TransferMoney");
            }}
            borderDirection={false}
          />
        )}

        {cards !== null && cards[0]?.AllowPayPerks && (
          <PayPerks Radius={isEvenLength} />
        )}

        <FooterContent
          highlightText={t("build_your_money_mindfulness")}
          subHeaderText={t("climb_subheading")}
          image={ClimbImg}
          buttonEnabled={true}
          buttonText={t("climb_btn")}
          isSpace={true}
          footerBtnAction={() => {
            navigate("/Climb");
            logFirebaseEvents("Home", "Explore_Climb_Clicked", "Explore_Climb_Clicked", "","");
          }}
          type={"climbFooterHome"}
          borderDirection={
            cards?.[0]?.AllowPayPerks
              ? cards?.length % 2 == 0
                ? "odd"
                : "even"
              : cards?.length % 2 == 0
              ? "even"
              : "odd"
          }
          subHeadingVisiblity={true}
        />

        {isLoading && <Loader />}
      </div>
      <TwoFactTypeSelctionModal
        isOpen={show2faSelection}
        onClose={() => {
          setshow2faSelection(false);
        }}
        onClickNext={(object) => {
          setOtpGenerationType(object.proc);
          setshow2faSelection(false);
          generate2FAOtp(object.proc);
        }}
      />
      <>
        {showModel && (
          <CustomAlertModel
            isOpen={showModel}
            onClose={closeModel}
            image={modelImage}
            highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
            HeaderMsg={
              <p
                className={`${HeaderMsgStyles} ${
                  savingsTheme ? "text-[#0C4437]" : "text-black"
                }`}
              >
                {headerMsg}
              </p>
            }
            subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
            btn1Exists={btn1Exists}
            btn2Exists={btn2Exists}
            btn1Text={btn1Text}
            btn2Text={btn2Text}
            btn1TypeAcpt={btn1TypeAcpt}
            footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
            onAcptBtnClick={onAcptBtnClick}
            onCancelBtnClick={onCancelBtnClick}
            showErrorImg={showErrorImg}
            isBorderNone={isBorderNone}
            btn1Focus={btn1Focus}
            btn2Focus={btn2Focus}
            modalBg={modalBg}
            btn1TextColor={btn1TextColor}
            btn1BrExist={btn1BrExist}
            btn2TextColor={btn2TextColor}
            btn1BgColor={btn1BgColor}
            btn1BorderColor={btn1BorderColor}
            subFooter={isSubFooter && subComponent()}
          />
        )}
      </>
      <>
        {isOTPVerifyModalOpen && (
          <Modal
            isOpen={isOTPVerifyModalOpen}
            onClose={closeOTPVerifyModal}
            isLogo={false}
            title={
              <p className={HighlightMsgStyles}> {t("great_exclamation")}</p>
            }
            subtitle={
              <p className={HeaderMsgStyles}>
                {otpGenerationType == "Email"
                  ? t("we_are_sending_text").replace("a text", "an email")
                  : t("we_are_sending_text")}
              </p>
            }
            subHeaderMsg={
              <p className="font-hanken-grotesk modal-title md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] font-normal cursor-pointer pointer-events-none">
                {otpGenerationType != "Email" &&
                  t("standard_msg_and_data_rates_may_apply")}
              </p>
            }
            content={
              <div className="mt-10">
                <Custom2FA
                  type={selectedOption}
                  selected2FAType={otpGenerationType}
                  onClose={closeOTPVerifyModal}
                  verificationSuccess={verificationSuccess}
                  verificationFailure={() => {}}
                />
              </div>
            }
          />
        )}
      </>
      {isLoading && <Loader />}
    </HomeLayout>
  );
};

export default withOidcSecure(Home);
