import React, { useEffect, useState } from 'react'
import { DOWNLOAD_TRANSACTION_HISTORY, ISDEV_ENV } from '../../network/URLConstants';
import { useTranslation } from 'react-i18next';
import Loader from '../CustomComponents/Loader';
import DynamicCard from '../CustomComponents/DynamicCard';
import { width } from '../CustomComponents/DynamicCard/DynamicCardStyles';
import Logo from '../../assets/images/modal_logo.png';
import { fetchConfigarationData } from '../../helpers/utils/Utils';
const appConfigData = await fetchConfigarationData();


const DownloadTransactionHistory = () => {
    const currentURL = new URL(window.location.href);
    const accessParam = currentURL.searchParams.get('id');
    // const accessTokenToApi = useSelector(state => state.accessToken);
    const { t } = useTranslation();


    const [loading, setLoading] = useState(false)
    const [error, serError] = useState('')
    const [showEle, setShowEle] = useState(false);

    const downloadDocument = async () => {

        const url = (!ISDEV_ENV ? appConfigData.APP_URL : '') + DOWNLOAD_TRANSACTION_HISTORY + `/${accessParam}`
        var headers = {
            // Authorization: 'Bearer ' + access_token,
            Accept: 'application/pdf',
        };
        setLoading(true)
        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (response.ok) {
                console.log("response ok")
                const blob = await response.blob();
                const base64Data = await blobToBase64(blob);
                const linkSource = base64Data;
                const downloadLink = document.createElement("a");
                let fileName;
                if (accessParam && accessParam.toString().substring(accessParam.toString().length - 2) == "th")
                    fileName = "Transaction_history.pdf";
                else
                    fileName = "DDAForm.pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                setLoading(false)
                downloadLink.click();
                setShowEle(true);
            } else {
                setShowEle(true);
                setLoading(false)


                console.error('Network response was not ok');
                serError("Document link is invalid or has expired. Please call the number on the back of your card to request another link.",);

            }
        } catch (error) {
            setLoading(false)

            console.error("bad request", error);
            serError(error);
            setShowEle(true);

        }
    }

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    useEffect(() => {
        downloadDocument();
    }, [])
    return (
        <div className=' absolute sm:pt-[130px] top-0 left-1/2 w-full sm:w-2/3 md:w-1/2 transform -translate-x-1/2 h-screen sm:h-auto'>
            <DynamicCard
                header={true}
                CardRadius={true}
                cardWidth="100%"
                HeaderRadius={true}
                HeaderContent={false}
                Radiusnone={true}
                // isWhite={true}
                footer={true}>

                <div className='flex flex-col justify-start items-center w-full h-screen sm:h-auto'>
                    <div className='flex flex-col justify-center items-center w-2/3'>
                        <div className=' flex flex-col justify-start items-start w-full mt-20   '>
                            <img src={Logo}
                                class="md:w-[130px] sm:w-[106px] w-[111px] sm:h-[32px] h-[33.43px] md:h-[39.15px] mb-[35px] md:mb-[51px]" alt="Logo" />
                        </div>
                        {
                            showEle && (

                                <div className='flex flex-col justify-start items-start w-full'>

                                    {error ?
                                        <div className='flex flex-col justify-start items-start'>
                                            <p className='font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-[#2965FB] '>Error. </p >
                                            <p className='font-[500] md:text-[16px] sm:text-[14px] text-[12px] md:leading-[28px] sm:leading-[20px] leading-[20px] font-hanken-grotesk mt-10 '>{error}</p>
                                        </div>
                                        :
                                        <div className='flex flex-col justify-start items-start'>
                                            <p className='font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-[#2965FB]'>Success! </p>

                                        </div>

                                    }
                                </div>
                            )
                        }
                        <p className='font-[500] md:text-[16px] sm:text-[14px] text-[12px] md:leading-[28px] sm:leading-[20px] leading-[20px] font-hanken-grotesk my-16 '>{t('copyright')}{t('footer_par')}</p>

                    </div>

                </div>



            </DynamicCard>
            {loading && <Loader />}

        </div>
    )
}

export default DownloadTransactionHistory