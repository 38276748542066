import React, { useState, useEffect } from 'react'
import { width } from '../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import CustomTextInput from '../../../../CustomComponents/CustomtextInput'
import RightArrow from "../../../../../assets/images/RightArrow@2x.png"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../CustomComponents/Loader'
import DynamicCard from '../../../../CustomComponents/DynamicCard'
import { withOidcSecure } from '@axa-fr/react-oidc'
import CustomRadioButton from '../../../../CustomComponents/CustomRadioButton'
import { displayErrorMessage } from '../../../../../utils'
import logFirebaseEvents from '../../../../LogFirebaseEvents'

const TimeFrameCard = ({ cardHeader, cardFooter, onBack, onNext }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [frequencyArray, setFrequencyArray] = useState([{ 'displayText': t('daily') }, { 'displayText': t('weekly') }, { 'displayText': t('monthly') }])
  const [frequencyError, setFrequencyError] = useState(null)
  const [isNext, setIsNext] = useState(false);


  const frequency = useSelector(state => state.spendAlertsPayload.frequencyType)

  const setFrequencyFun = (value) => {
    dispatch({ type: 'ADD_SPEND_ALERTS', payload: { 'key': "frequencyType", 'value': value.displayText } })
  }

  const handleBackClick = () => { 
    logFirebaseEvents("SpendSense_Alert", "Back_TimeFrameCard_Clicked", "Back_TimeFrameCard_Clicked","", "")
    onBack("amountCard")
  }
  const handleNextClick = () => { 
    logFirebaseEvents("SpendSense_Alert", "Next_TimeFrameCard_Clicked", "Next_TimeFrameCard_Clicked","", "")
    if (!frequency) {
      setFrequencyError('Time Frame Required');
    } else {
      setFrequencyError('');
      onNext("date");
    }
  }


  useEffect(() => {
    if (frequency){
      setFrequencyError(null)
      setIsNext(true)
    } 
    else setIsNext(false);
  }, [frequency])




  const children = () => {
    return (
      <>
        {cardHeader}
     
        <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4  '>
          <span>{t("spending_alert_time_content1")} </span>
          <span className='text-secondary-3 '>{t("spending_alert_time_content2")}</span>
          </div>

        <div className='w-full flex justify-center'>
          <div className="sm:w-[80%] w-full">
            {/* BODY */}
            <div className='md:my-32 sm:my-16 mt-10 mb-[9rem] sm:mx-0 mx-4'>
              <div className='w-full md:w-[80%] flex flex-col justify-center'>

                <CustomRadioButton title={t('FREQUENCY')}
                  options={frequencyArray} frequency={frequency}
                  onSelect={setFrequencyFun} isRow={'row'} error={frequencyError}
                  inputWidth={`lg:w-[61%] md:w-[74%] w-full`} />

              </div>
            </div>
          </div>


        </div>
        {/* FOOTER */}
        {cardFooter(handleBackClick, handleNextClick,'','', isNext)}
        {/* ---------- */}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>

      {isLoading && <Loader />}
    </>

  )
}


export default withOidcSecure(TimeFrameCard)

