import React, { useState, useEffect } from 'react'
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import Card from '../../../CustomComponents/Card';
import { width } from '../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../CustomComponents/Loader';

import CustomInput from '../../../CustomComponents/FormInputs';
import CustomTextInput from '../../../CustomComponents/CustomtextInput';
import LeftBar from '../../../CustomComponents/DynamicComponent/LeftBar';
import { height } from "../../../CustomComponents/Card/CardStyles";
import RegisterSuccessModal from '../RegisterSuccessModal';
import {
    STATUS_SUCCESS_CODE,
    GOOGLE_PLACE_DETAILS_WITH_PLACE_ID,
    GOOGLE_PLACES_AUTO_COMPLETE,
    VALIDATE_ALL_DETAILS_OF_USER,
    GET_BENEFICIARY_DETAILS,
    REMOVE_BENEFICIARY,
    ADD_BENEFICIARY,
    UPDATE_BENEFICIARY,
    REGISTER_USER,
    CONTACT_US_URL
} from '../../../../network/URLConstants';
import addLineBreaks from '../../../../utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, PostRequest, GetRequest } from '../../../../network/NetworkUtils';
import { displayErrorMessage, displaySuccessMessage, parseGoogleLocationResponse, DisplaySSNMessage, validateAuthorizedUser } from '../../../../utils';
import LineBreaks from '../../../../utils';
import { formatDateYYYYMMDD } from '../../../../utils'
import { retrieveData } from '../../../../helpers/utils/Utils';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import HomeLayout from '../../../Layout/HomeLayout';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import Modal from '../../../CustomComponents/Modal';
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import address_update_Succss from '../../../../assets/images/address_update_Succss.png';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import { useNavigate } from 'react-router-dom';
import benfRmveSuccess from '../../../../assets/images/beneficiaryrmveSucess.png';
import warningIcon from '../../../../assets/images/warning.png';
import checkIcon from '../../../../assets/images/check_3x.png';
import Arrow from '../../../../assets/images/arrow.png';
import ArrowLightBlue from '../../../../assets/images/Arrow_light_blue.png';
import transactionErrorIcon from "../../../../assets/images/transfer-error.png"
import Showpassword from '../../../../assets/images/showpassword.png'
import Hidepassword from '../../../../assets/images/hidepassword.png'
import pendingfile from '../../../../assets/images/pendingfile.png'
import profileError from '../../../../assets/images/profileError.png'

import DatePicker from "react-datepicker";
import moment from 'react-moment';
import logFirebaseEvents from '../../../LogFirebaseEvents';
const appConfigData = await fetchConfigarationData();



const PersonalInfoSummery = ({ openPatriotActClick, transactionDatas }) => {
    const ssnRef = React.createRef();
    const addressRef = React.createRef();
    const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue lg:text-[16px] md:text-[15px] text-[14px] font-medium focus:outline-none w-full h-[60px]";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook to access translation functions
    const moment = require('moment');
    //mdoal styles
    const HeadingStyles = "font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-3";
    const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal text-[#94C3E8]";
    const [isLoading, setIsLoading] = useState(false);
    const providerIdentifier = useSelector(state => state.providerIdentifier);
    const personalInfo = useSelector(state => state.signUpPersonelInfo);
    const provider = useSelector(state => state.provider);

    //university data
    const studentEmailAddress = useSelector(state => state.studentEmailAddress);
    const studentUniversityCode = useSelector(state => state.studentUniversityCode);
    const studentUniversityName = useSelector(state => state.studentUniversityName);
    const graduationDate = useSelector(state => state.graduationDate);

    const [editNameClicked, setEditNameClicked] = useState(false);
    const [editPreferredNameClicked, setEditPreferredNameClicked] = useState(false);
    const [editSSNClicked, seteditSSNClicked] = useState(false);
    const [addressEditClicked, setAddressEditClicked] = useState(false);
    const currentYear = new Date().getFullYear();

    const [privacyErromessage, setPrivacyErromessage] = useState('');
    const [selectedFirstName, setSelectedFirstName] = useState('');
    const [selectedLastName, setSelectedLastName] = useState('');
    const [selectedPreferredName, setSelectedPreferredName] = useState('');
    const [selectedSSN, setSelectedSSn] = useState('');
    const [predictions, setPredictions] = useState([]);
    const [typedValue, setTypedValue] = useState("");
    const [selectedAddress, setSelectedAddress] = useState('');
    const [showModel, setShowModel] = useState(false);
    const [modelImage, setModelImage] = useState('')
    const userId = useSelector(state => state.userId);
    const singedUpMobileNumber = useSelector(state => state.signUpMobile);
    const [isElectronicVerifyModalOpen, setIsElectronicVerifyModalOpen] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const [zipCodeError, setZipCodeError] = useState('');

    const [loading, setLoading] = useState(false)
    const [infoMessage, setinfoMessage] = useState('');
    const [responseCode, setResponseCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedAddressLine2, setSelectedAddressLine2] = useState('');
    const [line1AdressFilled, setLine1AdressFilled] = useState(false);
    const [homeAddressToserver, setHomeAddressToserver] = useState('');
    const validationSchema = yup.object().shape({
        firstName: yup.string().required(t('enter_firstName')),
        lastName: yup.string().required(t('enter_lasttName')),
        ssn_no: yup.string().required(t('enter_ssn')),
        address: yup.string().required(t('enter_address')),
        apt_etc: yup.string(),
        city: yup.string().required('Please enter a city'),
        state: yup.string().required('Please enter a state'),
        zip_code: yup.string().required('Please enter a valid zip code'),
    });
    const [isRegisterSucessModalOpen, setRegisterSucessModalModalOpen] = useState(false);

    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none text-[#262B3A]";
    const SubHeaderMsgStyles = "sm:w-auto w-[200px] font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-[500] cursor-pointer pointer-events-none";
    const FailSubHeaderMsgStyles = "md:w-[317px] sm:w-[257px] font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [cipStatus, setCipStatus] = useState('');
    const [firstNameError, setfirstNameError] = useState('');
    const [lastNameError, setlastNameError] = useState('');
    const [preferredNameError, setPreferredNameError] = useState('');



    const [SSNoError, setSSNoError] = useState('');
    // const [isAddress, setAddress] = useState(personalInfo?.line1);
    const [isAddress, setAddress] = useState(personalInfo?.formattedAddress);
    const [AddressError, setAddressError] = useState('');
    const [nameChanged, setnameChanged] = useState(false);
    const [ssnChanged, setSsnChanged] = useState(false);
    const [dobChanged, setDobChanged] = useState(false);
    const [addressChanged, setAddressChanged] = useState(false)
    const [isSSNValid, setIsSSNValid] = useState(false);
    const [personaInfoObject, setPersonalInfoObject] = useState({
        "applicationId": personalInfo?.applicationId !== null ? personalInfo?.applicationId : "",
        "language": "en",
        "username": personalInfo?.usernamem !== null ? personalInfo?.username : "",
        "password": (personalInfo?.password !== undefined && personalInfo?.password !== null && personalInfo?.password !== '') ? personalInfo?.password : "",
        "firstName": personalInfo?.firstName !== null ? personalInfo?.firstName : "",
        "lastName": personalInfo?.lastName !== null ? personalInfo?.lastName : "",
        "preferredName": personalInfo?.preferredName !== null ? personalInfo?.preferredName : "",
        "middleInitial": "",
        "ssn": personalInfo?.ssn != null ? personalInfo?.ssn : "",
        "dateOfBirth": personalInfo?.dateOfBirth !== null ? personalInfo?.dateOfBirth : "",
        "line1": personalInfo?.line1 !== null ? personalInfo?.line1 : "",
        "line2": personalInfo?.line2 !== null ? personalInfo?.line2 : "",
        "city": personalInfo?.city !== null ? personalInfo?.city : "",
        "state": personalInfo?.state !== null ? personalInfo?.state : "",
        "zipCode": personalInfo?.zipCode !== null ? personalInfo?.zipCode : "",
        "isEsignAccepted": personalInfo?.isEsignAccepted !== null ? personalInfo?.isEsignAccepted : "",
        "IsPrivacyPolicyAccepted": personalInfo?.IsPrivacyPolicyAccepted !== null ? personalInfo?.IsPrivacyPolicyAccepted : "",
        "IsCHAgreementAccepted": true,
        // "Provider": personalInfo?.provider !== null ?  personalInfo?.provider : "",
        // "ProviderIdentifier": personalInfo?.providerIdentifier !== null ? personalInfo?.providerIdentifier : "",
        "formattedAddress": personalInfo?.formattedAddress !== null ? personalInfo?.formattedAddress : "",
        "CreateSavings": personalInfo !== null ? personalInfo?.CreateSavings : false
    })
    const [showPassword, setShowPassword] = useState(true);
    const [ssn, setSSN] = useState(personalInfo?.ssn != null ? personalInfo?.ssn : "")
    const [ssnBackup, setSSnBackUp] = useState(personalInfo?.ssn != null ? personalInfo?.ssn : "")
    const [isCursorVisible, setCursorVisibility] = useState(false);


    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        address: '',
        apt_etc: '',
        ssn_no: ''
    });
    const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
    });



    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        handleSSNValue(ssnBackup)
    }, [showPassword])
    const toggleCursor = () => {
        setCursorVisibility(!isCursorVisible);
    };

    const handleSaveClick = async () => {
          
        let headers = await GetApiHeadersWithOutBearer();
        var requestObj = {
            "applicationId": personaInfoObject.applicationId,
            "username": personaInfoObject.username,
            "language": "en",
            "firstName": personaInfoObject.firstName,
            "lastName": personaInfoObject.lastName,
            "preferredName": personaInfoObject.preferredName != null ? personaInfoObject.preferredName : "",
            "middleInitial": "",
            // "ssn": personaInfoObject.ssn.replace(/[*-]/g, ''),
            "dateOfBirth": personaInfoObject.dateOfBirth,
            "line1": homeAddressToserver.line1 != null ? homeAddressToserver.line1 : "",
            "line2": homeAddressToserver.line2 != null ? homeAddressToserver.line2 : "",
            "city": homeAddressToserver.city != null ? homeAddressToserver.city : "",
            "state": homeAddressToserver.state != null ? homeAddressToserver.state : "",
            "zipCode": homeAddressToserver.zipCode != null ? homeAddressToserver.zipCode : "",
        }
        if (personaInfoObject.password !== undefined && personaInfoObject.password !== '') {
            requestObj.password = personaInfoObject.password;
        }
        if (providerIdentifier != null && providerIdentifier != undefined) {
            requestObj.provider = provider
            requestObj.providerId = providerIdentifier;
        }
        if (global.inviteCode != null && global.inviteCode != undefined) {
            requestObj.inviteCode = global.inviteCode
        }
        var response = await PostRequest(VALIDATE_ALL_DETAILS_OF_USER(userId), requestObj, headers);
        let responseObj = JSON.parse(response);
        if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
            logFirebaseEvents("Personal_Informatin", "Personal_InfoSummary_Submited", "Personal_InfoSummary_Submited", "","");
            setIsLoading(false);
            // handleContinueClick();
            const isMobileVerifiedPayload = { isMobileVerifed: true }
            dispatch({ type: 'MOBILE_VERIFIED', payload: isMobileVerifiedPayload });
            dispatch({ type: 'SIGN_UP_PERSONEL_INFO', payload: { signUpPersonelInfo: requestObj } });
            openPatriotActClick();
        } else {
            // debugger;
            var arrMessages = responseObj.Messages;
            if (arrMessages.length > 0) {
                const messageObject = arrMessages[0];
                const message = messageObject.DisplayText;
                showAlertModal(true, t('oops'), message, "", true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
                // setSSNoError('Social security number is not valid. Please re-enter.');
                // setValue('ssn_no', '');
            }
        }

        // } else {
        //     // handleContinueClick();
        //     openTermsandConditionsonClick();
        // }
    }
    const openRegisterSuccessModalOpen = () => {
        setRegisterSucessModalModalOpen(true);

    };

    const closeRegisterSuccessModal = () => {
        setRegisterSucessModalModalOpen(false);
    };
    const closeMobileVerificationModal = () => {
        setIsElectronicVerifyModalOpen(false);
    };


    const handleContinueClick = async () => {
        const deviceId = await retrieveData('updatedDeviceId');
        let headers = await GetApiHeadersWithOutBearer(deviceId);
        const requestObj = {
            "applicationId": personaInfoObject.applicationId,
            "language": "en",
            "username": personaInfoObject.username,
            "firstName": personaInfoObject.firstName,
            "lastName": personaInfoObject.lastName,
            "middleInitial": "",
            "ssn": personaInfoObject.ssn.replace(/[*-]/g, ''),
            "dateOfBirth": personaInfoObject.dateOfBirth,
            "line1": homeAddressToserver.line1 != null ? homeAddressToserver.line1 : "",
            "line2": homeAddressToserver.line2 != null ? homeAddressToserver.line2 : "",
            "city": homeAddressToserver.city != null ? homeAddressToserver.city : "",
            "state": homeAddressToserver.state != null ? homeAddressToserver.state : "",
            "zipCode": homeAddressToserver.zipCode != null ? homeAddressToserver.zipCode : "",
            "isEsignAccepted": personaInfoObject.isEsignAccepted,
            "IsPrivacyPolicyAccepted": personaInfoObject.IsPrivacyPolicyAccepted,
            "IsCHAgreementAccepted": true,
            "CreateSavings": personalInfo !== null ? personalInfo?.CreateSavings : false
        };
        if (personaInfoObject.password !== undefined && personaInfoObject.password !== '') {
            requestObj.password = personaInfoObject.password;
        }
        if (providerIdentifier != null && providerIdentifier != undefined) {
            requestObj.provider = provider;
            requestObj.providerId = providerIdentifier;
        }
        if (global.inviteCode != null && global.inviteCode != undefined) {
            requestObj.inviteCode = global.inviteCode
        }


        setIsLoading(true);

        var response = await PostRequest(REGISTER_USER(userId), requestObj, headers);
        let responseObj = JSON.parse(response);
        if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
            global.inviteCode = null;
            setIsLoading(false);
            setCipStatus(responseObj.Response.cipStatus);
            if (responseObj.Response.cipStatus == 'Success') {
                setIsRegistered(true);
                closeMobileVerificationModal();
                openRegisterSuccessModalOpen();
            } else if (responseObj.Response.cipStatus == 'Refer') {
                setIsRegistered(true);
                closeMobileVerificationModal();
                showAlertModal(true, t('thanks'), t('reviewing_profile'), t('notify_via_email'), true, false, t('login'), "", false, pendingfile);
                //  openRegisterSuccessModalOpen();
            }

        } else if (responseObj.ResponseCode === 4122) {
            setIsLoading(false);
            var arrMessages = responseObj.Messages;
            var messageObject = arrMessages[0];
            const message = messageObject.MessageOrField;
            if (message == 'AccountEnrollmentFailed') {
                setIsRegistered(true);
                setErrorMessage(message);
                showAlertModal(true, t('we_are_sorry'), t('unable_to_approve'), t('please_contact'), true, false, t('login'), '', false, profileError);
            } else {
                const displayText = messageObject.DisplayText;
                showAlertModal(true, t('oops'), displayText, "", true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
            }
        }
        else {
            setIsLoading(false);
            var arrMessages = responseObj.Messages;
            if (arrMessages.length > 0) {
                var messageObject = arrMessages[0];
                const message = messageObject.DisplayText;
                // const formattedMessage = "<span className='font-hanken-grotesk text-md leading-7 text-oops_error pr-2'>Oops! </span>" + message;
                // alert(message);
                setIsRegistered(true);
                setErrorMessage(message);
                showAlertModal(true, t('oops'), message, "", true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);


            }
        }


    }




    useEffect(() => {
        console.log("personaInfoObject", personaInfoObject);
        var object = {
            "line1": personaInfoObject.line1 != null ? personaInfoObject.line1 : "",
            "line2": personaInfoObject.line2 != null ? personaInfoObject.line2 : "",
            "city": personaInfoObject.city != null ? personaInfoObject.city : '',
            "state": personaInfoObject.state != null ? personaInfoObject.state : '',
            "zipCode": personaInfoObject.zipCode != null ? personaInfoObject.zipCode : '',
            "countryCode": personaInfoObject.countryCode != null ? personaInfoObject.countryCode : '',
            "country": personaInfoObject.country != null ? personaInfoObject.country : '',
            "cardKey": personaInfoObject.cardKey != null ? personaInfoObject.cardKey : ''
        }
        setHomeAddressToserver(object);
        setSelectedFirstName(personaInfoObject.firstName);
        setSelectedLastName(personaInfoObject.lastName);
        setSelectedPreferredName(personaInfoObject?.preferredName)
        setSelectedSSn(personaInfoObject.ssn);
    }, [personaInfoObject]);


    const editNameButtonClicked = async () => {
        setinfoMessage('');
        setEditNameClicked(true);
        setEditPreferredNameClicked(false)
        seteditSSNClicked(false);
        setAddressEditClicked(false);
    }
    const editPreferredNameButtonClicked = async () => {
        setinfoMessage('');
        setEditPreferredNameClicked(true);
        setEditNameClicked(false);
        seteditSSNClicked(false);
        setAddressEditClicked(false);
    }
    const updateNameButtonClicked = async () => {

        if (selectedFirstName.trim() == "") {
            setfirstNameError(t('enter_firstName'))
            // setinfoMessage(t('enter_firstName'))
            return;
        }
        if (selectedLastName.trim() == "") {
            setlastNameError(t('enter_lasttName'))
            // setinfoMessage(t('enter_lasttName'))
            return;
        }
        var personalobject = personaInfoObject;
        personalobject.firstName = selectedFirstName;
        personalobject.lastName = selectedLastName;
        setPersonalInfoObject(personalobject);
        setnameChanged(true);
        setEditNameClicked(false);
    }
    const updatePreferredNameButtonClicked = async () => {
        if (selectedPreferredName.trim() == "") {
            setPreferredNameError(t('Enter Prefered Name'))
            // setinfoMessage(t('enter_lasttName'))
            return;
        }
        var personalobject = personaInfoObject;
        personalobject.preferredName = selectedPreferredName;
        setPersonalInfoObject(personalobject);
        setEditPreferredNameClicked(false);
    }
    const editDobButtonClicked = async () => {
        setinfoMessage('');
        setEditNameClicked(false);
        seteditSSNClicked(false);
        setAddressEditClicked(false);
    }
    const editSSNButtonClicked = async () => {
        setinfoMessage('');
        seteditSSNClicked(true);
        setEditNameClicked(false);
        setAddressEditClicked(false);
        setTimeout(() => {
            const inputElement = document.getElementById('ssn_no');
            if (inputElement) {
                inputElement.focus();
            }
        }, 500);

    }
    const updateSSNButtonClicked = async () => {
        if (selectedSSN.trim() == "") {
            setinfoMessage(t('enter_ssn'))
            return;
        }
        var personalObject = personaInfoObject;
        personalObject.ssn = selectedSSN;
        setPersonalInfoObject(personalObject);
        setSsnChanged(true);
        seteditSSNClicked(false);
    }


    const editAddressClicked = async () => {
        setinfoMessage('');
        clearErrors();
        setAddressEditClicked(true);
        setAddress(personaInfoObject?.line1);
        setValue('address', personaInfoObject?.line1);
        setValue('apt_etc', personaInfoObject?.line2);
        setValue('city', personaInfoObject?.city);
        setValue('state', personaInfoObject?.state);
        setValue('zip_code', personaInfoObject?.zipCode);

        setEditNameClicked(false);
        seteditSSNClicked(false);
        setTimeout(() => {
            const inputElement = document.getElementById('addressInput');
            if (inputElement) {
                inputElement.focus();
            }
        }, 500);
    }
    const updateHomeAddress = async (data) => {
        // if (addressChanged) {
        //     var personalObject = personaInfoObject;
        //     personalObject.formattedAddress = isAddress;
        //     setPersonalInfoObject(personalObject);
        //     setAddressEditClicked(false);
        // } else {
        //     setAddressEditClicked(false)
        // }
        clearErrors();
        const formData = getValues();

        try {
            if (isAddress != '' && formData.city != '' && formData.state != '' && formData.zip_code != '') {
                clearErrors();
                setAddressError('');
                setCityError('');
                setStateError('');
                setZipCodeError('');
                console.log("formData", formData)
                const formattedAddress1 = `${isAddress && `${isAddress}, `}${formData.apt_etc && `${formData.apt_etc}, `}${formData.city && `${formData.city}, `}${formData.state && `${formData.state}, `}${formData.zip_code && `${formData.zip_code}`}`
                const updatePersonalObj = {
                    ...personaInfoObject,
                    "line1": isAddress,
                    "line2": formData.apt_etc,
                    "city": formData.city,
                    "state": formData.state,
                    "zipCode": formData.zip_code,
                    "formattedAddress": formattedAddress1,

                }


                // setAddress(formattedAddress1)
                setPersonalInfoObject(updatePersonalObj)
                setAddressEditClicked(false);
            }
            else {
                await validationSchema.validate(data, { abortEarly: false });
            }
            if (!isAddress) setAddressError('Address is required')
            else setAddressError('')

        } catch (error) {
            if (!isAddress) setAddressError('Address is required')
            else setAddressError('')
            if (error.inner) {
                const validationErrors = {};
                error.inner.forEach((err) => {
                    if (typeof err.message === 'string') {

                        if (err.path === 'city') {
                            setCityError(err.message);
                            if (formData.city) {
                                setCityError('');
                            }
                        }
                        else if (err.path === 'state') {
                            setStateError(err.message);
                            if (formData.state) {
                                setStateError('');
                            }
                        }
                        else if (err.path === 'zip_code') {
                            setZipCodeError(err.message);
                            if (formData.zip_code) {
                                setZipCodeError('');
                            }
                        }
                        // Handle other fields if needed
                    }
                });
            }

        }



    }



    const closeOTPVerifyModal = () => {

    }
    const closeModel = () => {
        setShowModel(false);
    }
    const verificationSuccess = (type) => {
        setAddressEditClicked(false);

        if (type == t('old_address')) {
            setAddressEditClicked(true);
        }
        else if (type == t('new_address')) {
            setAddressEditClicked(false);
            showAlertModal(true, t('success'), t('editAddressSuccessMsg'), '', true, false, t('done'), '', true, address_update_Succss);
        }
        // getChangePinDetails()
        // navigate('/settings/ManageExternalAccounts/AddExternalAccount')
    }
    const verificationFaiure = (type, message) => {
        setinfoMessage(message);
    }
    const closeCustomAlertModal = () => {
        debugger;
        setShowModel(false);
        if (isRegistered) {
            window.location.replace('/');
        }
    }

    const onAcptBtnClick = async () => {
        setShowModel(false)
        if (cipStatus == 'Refer' || cipStatus == 'Success' || subHeaderMsg == t('please_contact')) {
            window.location.replace('/');
        }


    }
    const onCancelBtnClick = () => {
        setShowModel(false)
        navigate('/signup');
    }

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)

    }



    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };


    const handleSSNChange = (event) => {
        //debugger;
        const ssnNumber = event.target.value;

        // Remove all non-digit characters from the input
        const ssnNo = ssnNumber.length !== 9 ? ssnNumber.replace(/[*-]/g, '') : ssnNumber;
        setSSnBackUp(ssnNo)
        handleSSNValue(ssnNo)
        setSelectedSSn(event.target.value)
        setSSNoError('');
        setinfoMessage('');

        if (ssnNo.length == 9) {
            setSSNoError('');
            // setSelectedSSn(event.target.value)
            handleInputChange(event); // Update the form values
            setIsSSNValid(true);
            //   ValidateSSN(ssnNo, formValues); // Pass the SSN value and form values to the ValidateSSN function
            //  ValidateSSN(true);
        }
    };
    function handleSSNValue(value) {
        const formatted = showPassword ? formatSecuredValue(value) : formatValue(value)
        setSSN(formatted)
    }
    function formatValue(value) {
        const formattedValue = value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d{2})(\d{1,2})/, '$1 - $2 - $3');

        return formattedValue;
    }

    function formatSecuredValue(value) {
        const securedValue = value
            .replace(/[^0-9•]/g, '')
            .replace(/((?:\d|.){3})((?:\d|.){2})((?:\d|.){1,2})/, '$1 - $2 - $3')
            .replace(/[0-9]/g, '•');

        return securedValue;
    }


    // const handleSelect = async (selectedAddress, placeId) => {
    //     const results = await geocodeByAddress(selectedAddress);
    //     const latLng = await getLatLng(results[0]);

    //     // Get formatted address using the place ID
    //     const placeResults = await geocodeByPlaceId(placeId);
    //     const formattedAddress = placeResults[0].formatted_address;
    //     const addressComponents = placeResults[0].address_components;
    //     var selectedAddress = parseGoogleLocationResponse(addressComponents);
    //     const streetNumber = selectedAddress.streetNumber != null ? selectedAddress.streetNumber : ''
    //     const route = selectedAddress.route != null ? selectedAddress.route : ''
    //     var object = {
    //         "line1": streetNumber + " " + route,
    //         "line2": selectedAddressLine2,
    //         "city": selectedAddress.locality != null ? selectedAddress.locality : '',
    //         "state": selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : '',
    //         "zipCode": selectedAddress.postalCode != null ? selectedAddress.postalCode : '',
    //         "countryCode": selectedAddress.countryCode != null ? selectedAddress.countryCode : '',
    //         "country": selectedAddress.country != null ? selectedAddress.country : '',
    //         "cardKey": ""
    //     }
    //     setHomeAddressToserver(object);

    //     const addressParts = [
    //         object.line1,
    //         object.line2,
    //         object.city,
    //         object.state,
    //         object.zipCode,
    //         object.countryCode,
    //         object.country
    //     ];

    //     const homeAddress = addressParts.filter(part => part).join(', ');
    //     setAddress(formattedAddress);
    //     setTypedValue(homeAddress);
    //     setLine1AdressFilled(true);
    //     setAddressChanged(true);

    //     setPredictions([]);

    // };

    const handleAdressChange = () => {
        clearErrors();
        setAddressError('');
        setCityError('');
        setStateError('');
        setZipCodeError('');
    }

    const handleSelect = async (selectedAddress, placeId) => {
        clearErrors();
        setAddressError('');
        setCityError('');
        setStateError('');
        setZipCodeError('');
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);

        // Get formatted address using the place ID
        const placeResults = await geocodeByPlaceId(placeId);
        const formattedAddress = placeResults[0].formatted_address;
        const addressComponents = placeResults[0].address_components;
        console.log("addressComponents", addressComponents);
        console.log("formattedAddress", formattedAddress);

        var selectedAddress = parseGoogleLocationResponse(addressComponents);

        // setAddress(formattedAddress);
        // setValue('address', formattedAddress);
        const address1 = (selectedAddress.streetNumber != null ? selectedAddress.streetNumber : selectedAddress.sublocality != null ? selectedAddress.sublocality : selectedAddress.naturalFeature != null ? selectedAddress.naturalFeature : '') + (selectedAddress.route != null ? ` ${selectedAddress.route}` : '');
        if (!address1) setAddressError(t("addressError"));

        setValue('address', address1);
        setAddress(address1)
        setValue('apt_etc', "")
        setValue('city', (selectedAddress.locality != null ? selectedAddress.locality : ''));
        setValue('state', (selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : ''));
        setValue('zip_code', (selectedAddress.postalCode != null ? selectedAddress.postalCode : ''));


        setPredictions([]);
    };

    const handleBlurEventFName = (e) => {
        if (selectedFirstName.trim() == "") setfirstNameError("Legal first name required")
        else setfirstNameError('')
    }
    const handleBlurEventLName = (e) => {
        if (selectedLastName.trim() == "") setlastNameError("Legal last name required")
        else setlastNameError('')
    }
    const handleBlurPreferedtLName = (e) => {
        if (selectedPreferredName.trim() == "") setPreferredNameError("Legal Prefered name required")
        else setPreferredNameError('')
    }



    const heading = () => {
        return (
            <>
                <span className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)] lg:text-[31px] md:text-[26px] sm:text-[21px] text-[25px]
             lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('great_exclamation')}</span>
                <span className='font-serif-pro sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)] lg:text-[31px] md:text-[26px] sm:text-[21px] text-[25px]
             lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('please_review')}</span>


            </>
        )
    }


    return (

        <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0">

            <Card
                heading={
                    <>
                        <span className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)] lg:text-[31px] md:text-[26px] sm:text-[21px] text-[25px] font-[400]
             lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('great_exclamation')}</span>
                        <span className='font-serif-pro sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)] lg:text-[31px] md:text-[26px] sm:text-[21px] text-[25px] font-[400]
             lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('please_review')}</span>
                    </>
                }
                // subheading={addLineBreaks(t('lets_go_basic_info'), SubTittleStylesModal)}
                header={true}
                footer={true}
                CardRadius={true}
                cardHeight={'auto'}
            >

                <div className='justify-center sm:items-center flex flex-col pb-20 sm:mx-0 mx-4 '>
                    <div className='md:w-[85%] sm:w-[87%] w-full md:pt-12 sm:pt-6 pt-2'>
                        <div className="sm:block hidden font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px]
                             text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]">
                            {t('menu_personal_information')}
                        </div>
                        {/*  Name section*/}

                        {!editNameClicked &&
                            <div className='md:mt-20 mt-12'>
                                <p className='font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[13px] 
                                        sm:text-[11px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("full_name")}</p>

                                <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[14px]
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                    {personaInfoObject.firstName + " " + personaInfoObject.lastName}</p>

                                <div className="bg-[#D6E2E8] h-0.5 min-h-1 w-full"></div>

                                <button
                                    onClick={() => { editNameButtonClicked() }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            editNameButtonClicked()
                                        }
                                    }}>{<p onClick={() => { editNameButtonClicked() }}
                                        className='pt-5 font-hanken-grotesk text-[#2965FB] md:text-[16px] sm:text-[13px] text-[13px] 
                                                   font-[700]'>{t("edit_name")}</p>}</button>
                            </div>
                        }

                        {/*  Name update section*/}
                        {editNameClicked &&
                            <div className='md:mt-16 mt-12'>
                                <div className='w-full'>
                                    <CustomTextInput
                                        maxLength={35}
                                        autoFocus={true}
                                        name={t("first_name_placeholder")}
                                        onChange={(event) => {
                                            setinfoMessage('');
                                            setfirstNameError('')
                                            setlastNameError('')
                                            setSelectedFirstName(event.target.value)
                                        }}
                                        onBlur={(e) => handleBlurEventFName(e)}
                                        value={selectedFirstName} style={{ width: '100%', height: '51px' }}
                                        error={firstNameError}
                                    />
                                    <CustomTextInput
                                        maxLength={35}
                                        autoFocus={true}
                                        name={t("last_name_placeholder")}
                                        onChange={(event) => {
                                            setinfoMessage('');
                                            setfirstNameError('')
                                            setlastNameError('')
                                            setSelectedLastName(event.target.value)
                                        }}
                                        onBlur={(e) => handleBlurEventLName(e)}
                                        error={lastNameError}

                                        value={selectedLastName} style={{ width: '100%', height: '51px' }} />
                                </div>

                                {/* {(editNameClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                        <div className='mb-2'>
                                            {displaySuccessMessage(infoMessage)}
                                        </div>
                                    }
                                    {(editNameClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                        <div className='mb-2'>
                                            {displayErrorMessage(infoMessage)}
                                        </div>
                                    } */}
                                <button
                                    onClick={() => { updateNameButtonClicked() }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            updateNameButtonClicked()
                                        }
                                    }}
                                    className='font-hanken-grotesk text-[#2965FB]
                                            md:text-[16px] sm:text-[13px] text-[13px]  font-[700]'>{t("save")}</button>
                            </div>
                        }

                        {/* Preferred name section */}
                        {!editPreferredNameClicked &&
                            <div className='md:mt-20 mt-12'>
                                <p className='font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[13px] 
                                        sm:text-[11px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("preferred_name1")}</p>

                                <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[14px]
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                    {personaInfoObject.preferredName}</p>

                                <div className="bg-[#D6E2E8] h-0.5 min-h-1 w-full"></div>

                                <button
                                    onClick={() => { editPreferredNameButtonClicked() }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            editPreferredNameButtonClicked()
                                        }
                                    }}>{<p onClick={() => { editPreferredNameButtonClicked() }}
                                        className='pt-5 font-hanken-grotesk text-[#2965FB] md:text-[16px] sm:text-[13px] text-[13px] 
                                                   font-[700]'>{t("edit_preferred_name")}</p>}</button>
                            </div>
                        }

                        {/* Preferred Name update section*/}
                        {editPreferredNameClicked &&
                            <div className='md:mt-16 mt-12'>
                                <div className='w-full'>
                                    <CustomTextInput
                                        maxLength={35}
                                        name={t("preferred_name1")}
                                        onChange={(event) => {
                                            setinfoMessage('');
                                            setPreferredNameError('')
                                            setSelectedPreferredName(event.target.value)
                                        }}
                                        onBlur={(e) => handleBlurPreferedtLName(e)}
                                        error={preferredNameError}
                                        value={selectedPreferredName} style={{ width: '100%', height: '51px' }}
                                    />
                                </div>

                
                                <button
                                    onClick={() => { updatePreferredNameButtonClicked() }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            updatePreferredNameButtonClicked()
                                        }
                                    }}
                                    className='font-hanken-grotesk text-[#2965FB]
                                            md:text-[16px] sm:text-[13px] text-[13px]  font-[700]'>{t("save")}</button>
                            </div>
                        }


                        {/* phone section */}
                        <div className='md:mt-16 mt-12'>
                            <>
                                <p class="font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]  font-[600] tracking-[2px] opacity-[50%] lg:mt-20 mt-10">
                                    {t("singup_phone_number")}
                                </p>
                                <div className='items-center flex border-b-[3px] 
                                    border-[#D6E2E8] justify-between border-opacity-50 my-8 pb-4'>
                                    <div>
                                        <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[16px] 
                                    font-medium leading-[26px] md:mb-4 mb-2">
                                            {/* {authorizedUser.firstName + " " + authorizedUser.lastName} */}
                                            {singedUpMobileNumber}
                                        </p>

                                    </div>
                                    {/* <div className='cursor-pointer md:mb-4 mb-2'>
                                                <img src={checkIcon} alt="view details" className='w-[25px] h-[25px]' />
                                            </div> */}
                                </div>
                                <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[16px] 
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                    {t('phone_cant_modify')}
                                </p>
                            </>

                        </div>

                        {/* email section */}
                        <div className='md:mt-16 mt-12'>
                            <>
                                <p class="font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]  font-[600] tracking-[2px] opacity-[50%] lg:mt-20 mt-10">
                                    {t("singup_email")}
                                </p>
                                <div className='items-center flex border-b-[3px] 
                                    border-[#D6E2E8] justify-between border-opacity-50 my-8 pb-4'>
                                    <div>
                                        <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[16px] 
                                    font-medium leading-[26px] md:mb-4 mb-2">
                                            {personaInfoObject.username}
                                        </p>

                                    </div>
                                    {/* <div className='cursor-pointer md:mb-4 mb-2'>
                                                <img src={checkIcon} alt="view details" className='w-[25px] h-[25px]' />
                                            </div> */}
                                </div>
                                <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[16px] 
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                    {t('email_cant_modify')}

                                </p>
                            </>

                        </div>











                        {/*  address section */}
                        {!addressEditClicked &&
                            <div className='md:mt-16 mt-12'>
                                <p className='font-hanken-grotesk text-[#262B3A] uppercase 
                                          lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]  font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("personal_address")}</p>

                                <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[16px] 
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                    {personaInfoObject.formattedAddress}</p>

                                <div className="bg-[#94C3E8] h-0.5 min-h-1 w-full"></div>

                                <button onClick={() => { editAddressClicked() }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            editAddressClicked()
                                        }
                                    }}
                                >{<p onClick={() => { editAddressClicked() }} className='pt-5 
                                        font-hanken-grotesk text-[#2965FB] md:text-[16px]  sm:text-[12px] text-[14px]
                                         font-[700]'>{t("edit_address")}
                                </p>}
                                </button>
                            </div>
                        }

                        {/*  address  update section */}
                        {addressEditClicked &&
                            <div className='md:mt-16 mt-12'>
                                <PlacesAutocomplete
                                    ref={addressRef}
                                    value={isAddress}
                                    // onChange={setAddress}
                                    onChange={value => {
                                        setAddress(value)
                                        if (value) {
                                            setAddressError('');
                                            setCityError('');
                                            setStateError('');
                                            setZipCodeError('');

                                        }
                                    }
                                    }
                                    onSelect={handleSelect}

                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input ref={addressRef} id='addressInput'  {...getInputProps({ placeholder: 'Address' })} className='font-hanken-grotesk text-secondary-2 px-4 py-2 bg-text-btn placeholder-kudzu-blue text-sm font-medium focus:outline-none w-full h-[60px]'
                                                onBlur={(e) => {
                                                    if (!isAddress) setAddressError('Address is required')
                                                    else setAddressError('')
                                                }}
                                            />
                                            <div className='mb-4'>
                                                {loading ? <div>Loading...</div> : null}
                                                {suggestions.map((suggestion) => {
                                                    const style = {
                                                        backgroundColor: suggestion.active ? '#ffffff' : '#F5F8F9',
                                                        cursor: 'pointer',
                                                        padding: '8px',
                                                        fontSize: '16px',
                                                        fontWeight: '500',
                                                        lineHeight: '26px',
                                                    };
                                                    return (
                                                        <div
                                                            key={suggestion.placeId}
                                                            {...getSuggestionItemProps(suggestion, { style })}
                                                        >
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                {/* {line1AdressFilled && <CustomTextInput
                                        name={t("apt_suite_etc")}
                                        onChange={(event) => {
                                            let tempHomeaddresToServer = homeAddressToserver;
                                            tempHomeaddresToServer.line2 = event.target.value;
                                            setHomeAddressToserver(tempHomeaddresToServer);
                                            setSelectedAddressLine2(event.target.value)
                                        }}
                                        value={selectedAddressLine2} style={{ width: '100%', height: '51px' }} />} */}
                                {AddressError && (
                                    displayErrorMessage(AddressError)
                                )}
                                {predictions.length > 0 && (
                                    <ul className='bg-[aliceblue] max-h-60 overflow-y-auto p-2'>
                                        {predictions.map((prediction) => (
                                            <li
                                                key={prediction.place_id}
                                                className='font-hanken-grotesk text-sm font-medium leading-6 p-2 cursor-pointer'
                                                value={prediction.place_id}
                                            >{prediction.description}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {(addressEditClicked && infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                    <div className='mb-2'>
                                        {displaySuccessMessage(infoMessage)}
                                    </div>
                                }
                                {(addressEditClicked && infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                    <div className='mb-2'>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                }
                                <span className='pt-1 font-hanken-grotesk lg:text-[18px] md:text-[17px] 
            sm:text-[15px] text-[15px] font-medium'>{t('P.O._boxes_are_not_accepted')} </span>
                                <CustomInput
                                    name="apt_etc"
                                    label="Apt, suite, etc. (optional)"
                                    register={register}
                                    error={errors.apt_etc}
                                    type="text"
                                />
                                <CustomInput
                                    name="city"
                                    label="City"
                                    register={register}
                                    error={errors.city}
                                    type="text"
                                    onChange={handleAdressChange}

                                />
                                <div className='mb-2'>

                                    {cityError && (
                                        displayErrorMessage(cityError)
                                    )}
                                </div>

                                <CustomInput
                                    name="state"
                                    label="State"
                                    register={register}
                                    error={errors.state}
                                    type="text"
                                    onChange={handleAdressChange}

                                />
                                <div className='mb-2'>

                                    {stateError && (
                                        displayErrorMessage(stateError)
                                    )}
                                </div>

                                <CustomInput
                                    name="zip_code"
                                    label="Zip Code"
                                    register={register}
                                    error={errors.zip_code}
                                    type="text"
                                    onChange={handleAdressChange}

                                />
                                <div className='mb-2'>
                                    {zipCodeError && (
                                        displayErrorMessage(zipCodeError)
                                    )}

                                </div>
                                <button
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            updateHomeAddress()
                                        }
                                    }}
                                    onClick={() => { updateHomeAddress() }}
                                    className='font-hanken-grotesk text-[#2965FB]
                                         md:text-[16px] text-[12px] font-[700]'>{t("save")}</button>
                            </div>
                        }

                        {/*  ssn eidt  section*/}

                        {/* {!editSSNClicked &&
                                <div className='md:mt-16 mt-12'>
                                    <p className='font-hanken-grotesk text-[#262B3A] uppercase  lg:text-[13px] md:text-[12px] 
                                        sm:text-[11px] text-[13px] font-[600] tracking-[2px] md:pb-8 pb-4 
                                opacity-[50%]'>{t("ssn")}</p>

                                    <p className="font-hanken-grotesk md:text-[16px] sm:text-[14px] text-[14px]
                                    font-medium leading-[26px] md:mb-6 mb-2">
                                        {'*'.repeat(3)}-{'*'.repeat(2)}-{(personaInfoObject.ssn != null && personaInfoObject.ssn != "") ? personaInfoObject.ssn.replace(/-/g, '').substring(5) : ""}

                                    </p>

                                    <div className="bg-[#D6E2E8] h-0.5 min-h-1 w-full"></div>

                                    <button
                                        onClick={() => { editSSNButtonClicked() }}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                editSSNButtonClicked()
                                            }
                                        }}>{<p onClick={() => { editSSNButtonClicked() }}
                                            className='pt-5 font-hanken-grotesk text-[#2965FB] md:text-[16px] sm:text-[13px] text-[13px] 
                                                   font-[700]'>{t("edit_ssn")}</p>}</button>
                                </div>
                            } */}

                        {/*  ssn update section*/}
                        {/* {(editSSNClicked) &&
                                <div className='md:mt-16 mt-12'>
                                    <div className='w-full'

                                        onFocus={() => { toggleCursor() }}
                                        onBlur={() => { toggleCursor() }}
                                    >
                                        
                                        <div className="flex items-center relative">
                                            <span
                                                type="button"
                                                className="text-gray-500 absolute right-4 top-5"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <img src={Showpassword} className='w-[20px] h-[20px] cursor-pointer' /> : <img src={Hidepassword} className='w-[20px] h-[20px] cursor-pointer' />}
                                            </span>
                                            <input
                                                ref={ssnRef}
                                                id={'ssn_no'}
                                                {...register('ssn_no')}
                                                value={selectedSSN}
                                                placeholder={'Social Security Number (SSN)'}
                                                className={`${InputStyles} ${showPassword ? 'password-input' : ''}`}
                                                autoComplete="off"
                                                onChange={handleSSNChange}
                                                // maxLength={15}
                                                maxLength={9}
                                                style={{ color: 'transparent' }}
                                            >
                                            </input>
                                        </div>
                                        <div className='flex z-[999] opacity-100 relative top-[-51px] h-[60px] w-[80%] bg-transparent items-center'
                                            style={{ position: 'relative', top: -48 }}
                                            onClick={() => {
                                                const input = document.getElementById("ssn_no");
                                                input.focus()
                                            }}>
                                            <p className='h-[50px] no-underline ml-[15px] top-[10] text-[#2965FB]' >{ssn}{isCursorVisible && <span style={{ display: 'inline-block', animation: isCursorVisible ? 'blink 0.8s step-end infinite' : 'none', verticalAlign: 'text-bottom', color: '#2965FB', fontWeight: '200', fontSize: '15px' }}>|</span>}</p>
                                        </div>
                                        <style>{`@keyframes blink {0%, 100% {opacity: 1;}50% {opacity: 0;}}`}</style>


                                        {(editSSNClicked && infoMessage) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        }
                                        <button
                                            onClick={() => { updateSSNButtonClicked() }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    updateSSNButtonClicked()
                                                }
                                            }}
                                            className='font-hanken-grotesk text-[#2965FB]
                                            md:text-[16px] sm:text-[13px] text-[13px]  font-[700]'>{t("save")}</button>
                                    </div>
                                </div>} */}


                        {/* {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                    <div className='mb-2'>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                } */}
                    </div>
                </div>


                {(infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                    <div className='mb-2'>
                        {displaySuccessMessage(infoMessage)}
                    </div>
                }
                {/* {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                        <div className='mb-2'>
                            {displayErrorMessage(infoMessage)}
                        </div>
                    } */}

                {/* save and back section */}

                <div className={`flex flex-row justify-end lg:mt-20 md:mt-16 
                    sm:mt-10 mt-10 sm:mx-0 mx-4 sm:mb-20 mb-10 md:-mr-10 ${(addressEditClicked || editNameClicked) ? "opacity-50" : "opacity-100"}`}>
                    {/* <button
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        handleSaveClick()
                                    }
                                }}
                                onClick={handleSaveClick} className='font-hanken-grotesk text-[#2965FB] font-[700] md:text-[18px] sm:text-[13px] text-[16px] sm:ml-10'>{t("benf_confirm")}
                            </button> */}
                    <button
                        type="button"
                        className="font-hanken-grotesk sm:font-bold font-[600]
                                lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB]
                                 cursor-pointer flex items-center"
                        onClick={() => { if (!addressEditClicked && !editNameClicked) { handleSaveClick(); } }}
                        onKeyDown={(event) => {
                            if (event.key == "Enter") {
                                event.preventDefault();
                                if (!addressEditClicked && !editNameClicked) { handleSaveClick() }
                            }
                        }
                        }
                    >
                        {t('confirm')}<img
                            src={(!addressEditClicked && !editNameClicked) ? Arrow : ArrowLightBlue}
                            className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px]
                     h-[14px] ml-1"
                            alt="Arrow" />
                    </button>

                </div>


            </Card>

            <Modal
                isOpen={isRegisterSucessModalOpen}
                onClose={closeRegisterSuccessModal}
                isLogo={false}
                title={null}
                subtitle={null}
                content={<div><RegisterSuccessModal subtitle={personalInfo?.CreateSavings == true ? t('spending_saving_account_ready') : t('spending_account_ready')} /></div>}
            />

            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}

                highlightMsg={headerMsg == t('reviewing_profile') ?
                    <p className='font-serif-pro modal-title  sm:flex block flex-col
                lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px]
                 sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none'>{highlightMsg}

                        {headerMsg == t('reviewing_profile') ? <span className='md:w-[287px] sm:w-[200px] font-serif-pro modal-title
                lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px]
                leading-[37px] font-normal cursor-pointer pointer-events-none text-[#262B3A]'> {headerMsg}</span> : <span className={HeaderMsgStyles}>
                            {headerMsg}</span>} </p> :
                    <p className={HighlightMsgStyles}>{highlightMsg}</p>}


                HeaderMsg={headerMsg != t('reviewing_profile') && <p className={`${HeaderMsgStyles} md:w-[357px] sm:w-[335px]`}>{headerMsg}</p>}

                subHeaderMsg={subHeaderMsg == t('please_contact') ?
                    <p className={FailSubHeaderMsgStyles}>{t("please_contact")}
                        {<button onClick={() => {
                            window.open(CONTACT_US_URL, '_blank');
                        }} className='font-hanken-grotesk lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] text-[#2965FB] font-semibold cursor-pointer md:ml-1'>
                            {t("signup_customer_care")}
                        </button>}
                        {t("for_access")}
                    </p>
                    : <p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
            />

            {isLoading && <Loader />}
        </div>




    )
}

export default PersonalInfoSummery;
