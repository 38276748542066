import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Arrow from '../../../assets/images/arrow.png';
import { useTranslation } from 'react-i18next';
import Card from '../../CustomComponents/Card';
import CustomInput from '../../CustomComponents/FormInputs';
import { addLineBreaks, displaySuccessMessage } from '../../../utils';
import LineBreaks from '../../../utils';
import { formatDate, parseGoogleLocationResponse, formatDateYYYYMMDD } from '../../../utils';
import InputMask from 'react-input-mask';


import { GOOGLE_PLACES_AUTO_COMPLETE, GOOGLE_PLACE_DETAILS_WITH_PLACE_ID, VALIDATE_ALL_DETAILS_OF_USER, STATUS_SUCCESS_CODE, PROGRAM_CONTENT, NOTIF_CONFIG_POLICY, NOTIF_CONFIG_SMS_SERVICE, AGREEMENTS } from '../../../network/URLConstants';
import { GetRequest, GetApiHeadersWithOutBearer, GetApiHeadersWithBearer, PostRequest } from '../../../network/NetworkUtils';

import { useSelector, connect, useDispatch } from 'react-redux';
import CustomTextInput from '../../CustomComponents/CustomtextInput';


import { displayErrorMessage, DisplaySSNMessage } from '../../../utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SecureIcon from '../../../assets/images/secure.png';
import { useKeyDown } from '../../../hooks/useKeyDown';

import { fetchConfigarationData, retrieveData } from '../../../utils';

import HomeLayout from '../../Layout/HomeLayout';
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { height, width } from "../../CustomComponents/Card/CardStyles";
import { useNavigate } from 'react-router';
import { withOidcSecure } from '@axa-fr/react-oidc';
import BancorpPrivacyPolicy from '../../OnBoard/Signup/BancorpPrivacyPolicy';
import UserAgreement from '../../OnBoard/Signup/UserAgreement';
import CustomDatePicker from '../../CustomComponents/CustomDateInput';
import Loader from "../../CustomComponents/Loader";
import Modal from "../../CustomComponents/Modal";
import Showpassword from '../../../assets/images/showpassword.png'
import Hidepassword from '../../../assets/images/hidepassword.png'

import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';

const appConfigData = await fetchConfigarationData();


export const AddBeneficiary = ({ getDataFromPersonalInfo, email, password }) => {


  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal text-[#94C3E8]";
  const ssnRef = React.createRef();
  const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue lg:text-[16px] md:text-[15px] text-[14px] font-medium focus:outline-none w-full h-[60px]";

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [isSSNValid, setIsSSNValid] = useState(false);
  const [typedValue, setTypedValue] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [selectedCity, setCity] = useState('');
  const [selectedState, setState] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [DOBError, setDOBError] = useState('');
  const [AddressError, setAddressError] = useState('');
  const [SSNoError, setSSNoError] = useState('');
  const [privacycheckboxEnabled, setPrivacycheckboxEnabled] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [ssn, setSSN] = useState('')
  const [ssnBackup, setSSnBackUp] = useState('')
  const [autofocousFirstName,setAutoFocusFirstName] = useState(false);

  //
  const [selectedStreet, setStreet] = useState('');
  const [selectedRoute, setRoute] = useState('');
  const [selectedLocality, setLocality] = useState('');
  const [selectedAdministartoeLevel1, setAdministartoeLevel1] = useState('');
  const [selectedPostalCode, setZipCode] = useState('');
  const [isAddress, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const [checkedValues, setCheckedValues] = useState([]);

  const [showPolicys, setShowPolicys] = useState('terms');
  const [acceptAll, setAcceptAll] = useState(false);
  const [isElectronicVerifyModalOpen, setIsElectronicVerifyModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [homeAddressToserver, setHomeAddressToserver] = useState('');
  const [line1AdressFilled, setLine1AdressFilled] = useState(false);
  const cards = useSelector(state => JSON.parse(state.userCards));
  const selectedItem = cards?.[0]
  const [responseCode, setResponseCode] = useState('');
  const [infoMessage, setinfoMessage] = useState('');
  const [privacyErromessage, setPrivacyErromessage] = useState('');
  const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
  const [selectedType, setSelectedType] = useState('');
  const [selectedAddressLine2, setSelectedAddressLine2] = useState('');
  const [selectedTransferDate, setSelectedTransferDate] = useState('');
  const [showAgreementsModel, setShowAgreementsModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [policyURL, setPolicyURL] = useState('');
  const [renderModel, setRenderModel] = useState('');
  const accessToken = useSelector(state => state.accessToken);
  const inputRef = useRef(null);
  const [isCursorVisible, setCursorVisibility] = useState(false);
  const [SSNBackupSecondary, setSSNBackupSecondary] = useState('')



  const toggleCursor = () => {
    setCursorVisibility(!isCursorVisible);
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t('enter_firstName')),
    lastName: yup.string().required(t('enter_lasttName')),
    dob: yup.string().required(t('select_dob')),
    ssn_no: yup.string().required(t('enter_ssn')),
    address: yup.string().required(t('enter_address'))
  });
  const [checkboxes, setCheckboxes] = useState({
    privacy_user: false,
    deposit_savings: false,
    electronic_aggrement: false,
    // paperless_statement: false
    // Add more checkboxes as needed
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    handleSSNValue(ssnBackup)
  }, [showPassword])

  const handleCheckboxChange = (event, checkboxKey) => {
    const isChecked = event.target.checked;
    setPrivacyErromessage('');
    if (checkboxKey == 'electronic_aggrement' && event.target.checked) {
      // OpenElectricVerificationModal();
    } else {
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [checkboxKey]: isChecked,
      }));
    }

    // Update checkedValues state
    setCheckedValues((prevCheckedValues) => {
      const updatedCheckedValues = [...prevCheckedValues];
      const existingCheckboxIndex = updatedCheckedValues.findIndex((checkbox) => checkbox.checkboxKey === checkboxKey);

      if (existingCheckboxIndex !== -1) {
        updatedCheckedValues[existingCheckboxIndex].checked = isChecked;
      } else {
        updatedCheckedValues.push({ checkboxKey, checked: isChecked });
      }

      return updatedCheckedValues;
    });

    // Check if all checkboxes are checked
    const allChecked = Object.values(checkboxes).every((value) => value);
    setAcceptAll(allChecked);
  };


  const handleClick = (type) => {
    setShowPolicys((prevState) => {
      if (type === 'privacy') {
        return 'privacy';
      } else if (type === 'bancrop') {
        LinkClick("bancorp");
        //window.open("https://www.kudzumoney.com/policies/bancorp-privacy",'_blank');

        // return 'agreement';
        // need to show  bancrop link here
      } else if (type === 'userAgreement') {
        LinkClick("user agreement");
        // window.open("https://www.kudzumoney.com/policies/user-agreement", '_blank');

        // return 'deposit';
        // need to show user agreement here
      } else if (type === 'savings') {
        return 'savings';
      } else if (type === 'electronic') {
        return 'electronic';
      } else {
        return 'terms';
      }
    });

  };

  ///form data
  const [completeFormData, setcompleteFormData] = useState('');
  useEffect(() => {
    // Set focus to the input element when the component mounts
    setTimeout(() => {
      setAutoFocusFirstName(true);
    }, 1000);
  }, []);


  const userId = useSelector(state => state.userId);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    dob: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
    address: '',
    apt_etc: '',
    ssn_no: ''
  });

  const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleInputChange = (event) => {
    setFirstNameError('');
    setLastNameError('');
    setDOBError('');
    setSSNoError('');
    setAddressError('');

    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    // if (name === 'firstName') {
    //   setFirstNameError('')
    // } else if (name === 'lastName') {
    //   setLastNameError('')
    // }
  };


  const handleSSNChange = (event) => {
    //debugger;
    const ssnNumber = event.target.value;
    //debugger;
    // Remove all non-digit characters from the input
    const ssnNo = ssnNumber.length !== 9 ? ssnNumber.replace(/[*-]/g, '') : ssnNumber;
    setSSnBackUp(ssnNo)
    handleSSNValue(ssnNo)
    if(event.target.value.length < 10){setSSNBackupSecondary(event.target.value.replace(' ',"").replace('-',"").replace(' ',""))}
    if (ssnNo.length == 9) {
      setSSNoError('');
      handleInputChange(event); // Update the form values
      setIsSSNValid(true)

      //   ValidateSSN(ssnNo, formValues); // Pass the SSN value and form values to the ValidateSSN function
      //  ValidateSSN(true);
    }
  };

  function handleSSNValue(value) {
    const formatted = showPassword ? formatSecuredValue(value) : formatValue(value)
    setSSN(formatted)
  }
  function formatValue(value) {
    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{2})(\d{1,2})/, '$1 - $2 - $3');

    return formattedValue;
  }

  function formatSecuredValue(value) {
    const securedValue = value
      .replace(/[^0-9•]/g, '')
      .replace(/((?:\d|.){3})((?:\d|.){2})((?:\d|.){1,2})/, '$1 - $2 - $3')
      .replace(/[0-9]/g, '•');

    return securedValue;
  }

  const ValidateSSN = async (ssnNo, formValues) => {
    if (ssnNo)
      var ssn = ssnNo.replace(/-/g, '');
    let headers = await GetApiHeadersWithOutBearer();
    var requestObj = {
      "applicationId": appConfigData.CLIENT_ID,
      "language": "en",
    };

    var requestObj = {

      "username": email,

      "password": password,
      "firstName": formValues.firstName,
      "lastName": formValues.lastName,
      "middleInitial": "",
      "ssn": ssn,
      "dateOfBirth": formatDateYYYYMMDD(formValues.dob),
      "line1": "705 James Burgess Road",
      "line2": "Flat: 1055",
      "city": "Suwanee",
      "state": "Georgia",
      "zipCode": "30024"
      // "line1":selectedStreet + " " + selectedRoute,
      // "line2":formValues.apt_etc , 
      // "city": selectedLocality,
      // "state":selectedAdministartoeLevel1,
      // "zipCode": selectedPostalCode 
    }
    var response = await PostRequest(VALIDATE_ALL_DETAILS_OF_USER(userId), requestObj, headers);
    let responseObj = JSON.parse(response);
    if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
      setMessage(''); // Clear the message when input value is valid 
      getDataFromPersonalInfo(requestObj);
      setIsSSNValid(true);
      const isMobileVerifiedPayload = { isMobileVerifed: true }
      dispatch({ type: 'MOBILE_VERIFIED', payload: isMobileVerifiedPayload });
      return true;
    } else {
      // debugger;
      var arrMessages = responseObj.Messages;
      if (arrMessages.length > 0) {
        const messageObject = arrMessages[0];
        const message = messageObject.DisplayText;
        setSSNoError('Social security number is not valid. Please re-enter.');
        setValue('ssn_no', '');
      }
    }
  };

  const getAddress = async (event) => {


    var headers = {
      "Access-Control-Allow-Origin": "*"
    };
    const typedValue = event.target.value;
    if (typedValue.length === 3) {

      const response = await fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${typedValue}&key=AIzaSyBryeFUk3zwNrunOjI4z6k5ldFxtBvVSTM&language=en&components=country:us`, headers);

      const prdections_data = JSON.parse(response);
      if (prdections_data.predictions.length > 0) {
        var predictions = prdections_data.predictions;
        setPredictions(predictions);
        // alert(predictions.length);
      }
    } else {
      setPredictions([]);
    }
  }

  const handlePredictionClick = async (event) => {
    const selectedPlaceID = event.target.getAttribute('value');
    if (selectedPlaceID) {
      //debugger;
      var url = GOOGLE_PLACE_DETAILS_WITH_PLACE_ID(selectedPlaceID);
      var response = GetRequest(url, { 'Access-Control-Allow-Origin': '*' });
      const responseJson = await response;
      const places_data = JSON.parse(responseJson);
      var formattedAdress = places_data.result.formatted_address;
      setAddress(formattedAdress);
      setValue('address', formattedAdress);
      var selectedAddress = parseGoogleLocationResponse(places_data);
      if (selectedAddress.streetNumber != null &&
        selectedAddress.postalCode != null &&
        selectedAddress.country != null &&
        selectedAddress.administrativeAreaLevel1 != null &&
        selectedAddress.locality != null &&
        selectedAddress.route != null) {
        setStreet(selectedAddress.streetNumber);
        setRoute(selectedAddress.route);
        setLocality(selectedAddress.locality);
        setAdministartoeLevel1(selectedAddress.administrativeAreaLevel1);
        setZipCode(selectedAddress.postalCode);
        const showHeaderFooterPayload = { isShowHeaderFooter: false }
        dispatch({ type: 'HIDE_SHOW_HEADER_FOOTER', payload: showHeaderFooterPayload });
        setPredictions([]);
      } else {
        setValue('address', '');
      }

    }
  };

  const handleRevertClick = () => {
    // navigate(-1)
    navigate('/Settings/manageBeneficiaries')
  };

  //this method handles the save functionality
  const onSubmit = async (data) => {
    const formData = getValues();


    try {
      if (formData.firstName != '' && formData.lastName != '' && formData.dob != '' && formData.address != '' && formData.ssn_no != '' && formData.address != "") {
        if (privacycheckboxEnabled === false) {
          setPrivacyErromessage(t('accept_privacy_policy'));
          return;
        } else {
          var addedBenficary = {};
          addedBenficary.firstName = formData.firstName;
          addedBenficary.lastName = formData.lastName;
          addedBenficary.birthDay = formData.dob;
          addedBenficary.ssn = formData.ssn_no;
          addedBenficary.selectedDobToServer = formData.dob;
          addedBenficary.formattedAddress = isAddress;
          addedBenficary.line1 = homeAddressToserver.line1;
          addedBenficary.line2 = homeAddressToserver.line2;
          addedBenficary.city = homeAddressToserver.city;
          addedBenficary.state = homeAddressToserver.state;
          addedBenficary.zipCode = homeAddressToserver.zipCode;
          addedBenficary.countryCode = homeAddressToserver.countryCode;
          addedBenficary.country = homeAddressToserver.country;
          addedBenficary.cardKey = homeAddressToserver.cardKey;
          addedBenficary.cardKey = selectedItem?.cardKey;
          addedBenficary.apt_etc = formData.apt_etc;

          const addedBenficaryDetails = { addedBenficaryDetails: addedBenficary };
          dispatch({ type: 'ADDED_BENFICARYDETAILS', payload: addedBenficaryDetails });
          navigate('/Settings/manageBeneficiaries/editAddBeneficiary');

          const editBenficireyform = { isEditBenificirey: false };
          dispatch({ type: 'EDIT_BENFICIRIES', payload: editBenficireyform });

          navigate('/Settings/manageBeneficiaries/editAddBeneficiary');
        }


      } else {
        await validationSchema.validate(data, { abortEarly: false });
      }
    } catch (error) {
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          if (typeof err.message === 'string') {
            if (err.path === 'firstName') {
              setFirstNameError(err.message);
              if (formData.firstName) {
                setFirstNameError('');
              }
            } else if (err.path === 'lastName') {
              setLastNameError(err.message);
              if (formData.lastName) {
                setLastNameError('');
              }
            } else if (err.path === 'dob') {
              setDOBError(err.message);
              if (formData.dob) {
                setDOBError('');
              }
            } else if (err.path === 'address') {
              setAddressError(err.message);
              if (formData.address) {
                setAddressError('');
              }
            } else if (err.path === 'ssn_no') {
              setSSNoError(err.message);
              if (formData.ssn_no) {
                setSSNoError('');
              }
            }
          }
        });
      }
    }
  };

  const heading = () => {
    return (
      <>
        <div className='lg:w-[260px] md:w-[183px] sm:w-[164px] w-full sm:mx-0 ml-[2.5rem] sm:pb-0 pb-8'>
          <p className="text-white font-normal lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] 
          lg:leading-[38px] md:leading-[33px] sm:leading-[30px] leading-[31px]
              font-serif-pro ">{(t('manage_benificirey_title'))}</p>
        </div>
      </>
    )
  }
  const OpenElectricVerificationModal = () => {
    setIsElectronicVerifyModalOpen(true);

  };
  const handleSelect = async (selectedAddress, placeId) => {
    const results = await geocodeByAddress(selectedAddress);
    const latLng = await getLatLng(results[0]);

    // Get formatted address using the place ID
    const placeResults = await geocodeByPlaceId(placeId);
    const formattedAddress = placeResults[0].formatted_address;
    const addressComponents = placeResults[0].address_components;

    var selectedAddress = parseGoogleLocationResponse(addressComponents);
    const streetNumber = selectedAddress.streetNumber != null ? selectedAddress.streetNumber : ''
    const route = selectedAddress.route != null ? selectedAddress.route : ''
    var object = {
      "line1": streetNumber + " " + route,
      "line2": selectedAddressLine2,
      "city": selectedAddress.locality != null ? selectedAddress.locality : '',
      "state": selectedAddress.administrativeAreaLevel1 != null ? selectedAddress.administrativeAreaLevel1 : '',
      "zipCode": selectedAddress.postalCode != null ? selectedAddress.postalCode : '',
      "countryCode": selectedAddress.countryCode != null ? selectedAddress.countryCode : '',
      "country": selectedAddress.country != null ? selectedAddress.country : '',
      "cardKey": selectedItem?.CardKey
    }
    setHomeAddressToserver(object);

    const addressParts = [
      object.line1,
      object.line2,
      object.city,
      object.state,
      object.zipCode,
      object.countryCode,
      object.country
    ];
    const homeAddress = addressParts.filter(part => part).join(', ');
    setAddressError('');
    setValue('address', formattedAddress);
    setAddress(formattedAddress);
    setTypedValue(homeAddress);
    setLine1AdressFilled(true);

    setPredictions([]);
  };

  useKeyDown(() => {
    onSubmit()
  }, ["Enter"]);

  const onDateChange = (date, type) => {
    const today = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    if (date > eighteenYearsAgo) {
      // Date of birth is less than 18 years ago
      // Handle the validation error as needed
      setDOBError('Date of birth should be greater than 18 years ago');
      return;
    }

    if (date > today) {
      // Date of birth is in the future
      // Handle the validation error as needed
      setDOBError('Date of birth cannot be in the future');
      return;
    }
    //debugger;
    clearErrors('dob'); // Clear the dob field error if it was previously set
    setValue('dob', formatDateYYYYMMDD(date));
    setDOBError('');
    setFormValues((prevValues) => ({
      ...prevValues,
      dob: date
    }));
  }
  const handleDateChange = (date) => {

    const today = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    if (date > eighteenYearsAgo) {
      // Date of birth is less than 18 years ago
      // Handle the validation error as needed
      setDOBError('Date of birth should be greater than 18 years ago');
      return;
    }

    if (date > today) {
      // Date of birth is in the future
      // Handle the validation error as needed
      setDOBError('Date of birth cannot be in the future');
      return;
    }
    //debugger;
    clearErrors('dob'); // Clear the dob field error if it was previously set
    setValue('dob', formatDateYYYYMMDD(date));
    setDOBError('');
    setFormValues((prevValues) => ({
      ...prevValues,
      dob: date
    }));
  };

  const getAgreementsBasedOnType = async (url) => {
    if (selectedItem?.CardKey == null || selectedItem?.CardKey == undefined) {
      return
    }
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
    setIsLoading(true)

    // let finalUrl = (PROGRAM_CONTENT + "?accountType=64&language=en&contentType=" + url + "&programKey=200")
    let finalUrl = AGREEMENTS.replace('{contentType}', url).replace('{cardKey}', selectedItem?.CardKey)
    var response = await GetRequest(finalUrl, headers)
    var responseObj = JSON.parse(response)
    setIsLoading(false)
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      setRenderModel(responseObj.Response.RenderModel);
      setPolicyURL(responseObj.Response.Content);
      setShowAgreementsModel(true);
    } else {
      setTimeout(() => {
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        // setError(displayText)
      }, 500);
    }
  }

  const LinkClick = (href) => {
    // e.preventDefault();
    // e.stopPropagation();

    switch (href) {
      case "bancorp":
        getAgreementsBasedOnType("PrivacyPolicyV2")
        //setShowAgreementsModel(true);

        break;
      case "user agreement":
        getAgreementsBasedOnType("TnC")
        // setShowAgreementsModel(true);
        break;

      default:
        break;
    }
  };
  const cursorStyle = {
    display: 'inline-block',
    animation: isCursorVisible ? 'blink 0.8s step-end infinite' : 'none',
    margin: '0 0px 6px', // Adjust the cursor position as needed
    verticalAlign: 'text-bottom',
    color: '#2965FB', // Cursor color,
    fontWeight: '200',
    fontSize: '20px'
  };

  return (
    <>
      <HomeLayout>
        <LeftBar leftBarType='text' isPrimary={false} heading={heading()} />
        <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>

        <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
                 animate__animated animate__fadeInRight '>
          <DynamicCard
            header={false}
            CardRadius={true}
            cardWidth={width.allpolicies}
            HeaderRadius={true}
            isWhite={true}
            HeaderContent={false}>


            <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
                 font-hanken-grotesk sm:mx-0 mx-4">{t("add_beneficiary")}</div>
            <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4'></div>


            <div className='lg:w-[90%] md:w-[94%] w-full font-serif-pro lg:text-[27px] md:text-[24px]
                sm:text-[20px] text-[24px] md:leading-[38px] sm:leading-[24px] leading-[31px] 
                    font-normal text-[#2965FB] mt-5'>
              <p className='sm:mx-0 mx-4 '>{t("first_comma")}<span className='text-black'>
                {(t("fill_benficary_info"))}</span></p>
            </div>

            <div className='justify-center flex sm:mx-0 mx-4 '>
              <div className='sm:w-[80%] w-full'>
                <form className="flex flex-col md:mt-20 sm:mt-12 mt-12">
                  {/* firstName textInput */}
                 {autofocousFirstName &&<CustomInput
                    name="firstName"
                    label="First name"
                    register={register}
                    error={errors.firstName}
                    type="text"
                    autoFocus={autofocousFirstName}
                    onChange={handleInputChange}
                  />}
                  <div className='mb-4'>
                    {firstNameError && (
                      displayErrorMessage(firstNameError)
                    )}
                  </div>
                  {/* lastName textInput */}
                  <CustomInput
                    name="lastName"
                    label="Last name"
                    register={register}
                    error={errors.lastName}
                    type="text"
                    onChange={handleInputChange}
                  />
                  <div className='mb-4'>
                    {lastNameError && (
                      displayErrorMessage(lastNameError)
                    )}
                  </div>
                  {/* dob input */}
                  <div className='mb-2' style={{ zIndex: 9999 }}>
                    <DatePicker
                      selected={formValues.dob}
                      onChange={handleDateChange}
                      // dateFormat="yyyy-MM-dd"
                      dateFormat="dd/MM/yyyy"
                      name="dob" // Pass the `name` prop directly
                      label="Date of birth"
                      className='font-hanken-grotesk 
               text-secondary-2 px-4 py-2
                bg-text-btn placeholder-kudzu-blue 
                text-sm font-medium focus:outline-none w-full h-[60px]'
                      showMonthDropdown
                      showYearDropdown
                      placeholderText='Date of birth'
                      autoComplete='off'
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      maxDate={new Date(currentYear, 11, 31)}
                    />
                    {/* <div className='mb-4 mt-4'>
                      {DOBError && (
                        displayErrorMessage(DOBError)
                      )}
                    </div> */}
                  </div>
                  {/* <div className='mb-4'>
                  <CustomDatePicker
                   selectedDate={selectedTransferDate} onDateChange={(date) => onDateChange(date, 'oneTime')}
                    disabledDates={[]} title={t('dob_title')} hrbg='bg-dropdown-sky' />
                 </div> */}

                  {DOBError && <div className='mb-4 mt-4'>
                    {DOBError && (
                      displayErrorMessage(DOBError)
                    )}
                  </div>}
                  {/* ssn textInput */}
                  <div className='mt-10'></div>
                  {/* <CustomInput
                    name="ssn_no"
                    label="Social Security Number (SSN)"
                    register={register}
                    error={errors.ssn_no && errors.ssn_no.message}
                    type="ssn_no"
                    onChange={handleSSNChange}
                  /> */}
                  <div className="w-full"

                    onFocus={() => { toggleCursor() }}
                    onBlur={() => { toggleCursor() }}
                  >
                    <div className="flex items-center relative">
                      <span
                        type="button"
                        className="text-gray-500 absolute right-4 top-5"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <img src={Showpassword} className='w-[20px] h-[20px] cursor-pointer' /> : <img src={Hidepassword} className='w-[20px] h-[20px] cursor-pointer' />}
                      </span>
                      <input
                        ref={ssnRef}
                        id={'ssn_no'}
                        {...register('ssn_no')}
                        value={SSNBackupSecondary}
                        placeholder={'Social Security Number (SSN)'}
                        className={`${InputStyles} ${showPassword ? 'password-input' : ''}`}
                        autoComplete="off"
                        onChange={handleSSNChange}
                        maxLength={9}
                        style={{ color: 'transparent', caretColor:'transparent'}}
                        >
                      </input>

                    </div>
                    <div className='flex z-[999] opacity-100 relative top-[-51px] h-[60px] w-[80%] bg-transparent items-center'

                      style={{ position: 'relative', top: -48 }}
                      onClick={() => {
                        const input = document.getElementById("ssn_no");
                        input.focus()
                      }}>
                      <p className='h-[50px] no-underline ml-[15px] top-[10] text-[#2965FB]' >{ssn}{isCursorVisible && <span style={{ display: 'inline-block', animation: isCursorVisible ? 'blink 0.8s step-end infinite' : 'none', verticalAlign: 'text-bottom', color: '#2965FB', fontWeight: '200', fontSize: '15px' }}>|</span>}</p>
                    </div>

                  </div>
                  <style>{`@keyframes blink {0%, 100% {opacity: 1;}50% {opacity: 0;}}`}</style>

                  {SSNoError && (
                    displayErrorMessage(SSNoError)
                  )}
                  <div className='pb-6'>{DisplaySSNMessage()}</div>



                  {/* address textInput */}
                  <div className='mt-2 mb-2'>
                    <PlacesAutocomplete
                      value={isAddress}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input {...getInputProps({ placeholder: 'Address' })} className='font-hanken-grotesk text-secondary-2 px-4 py-2 bg-text-btn placeholder-kudzu-blue text-sm font-medium focus:outline-none w-full h-[60px]' />
                          <div className='mb-4'>
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? '#ffffff' : '#F5F8F9',
                                cursor: 'pointer',
                                padding: '8px',
                                fontSize: '16px',
                                fontWeight: '500',
                                lineHeight: '26px',
                              };
                              return (
                                <div
                                  key={suggestion.placeId}
                                  {...getSuggestionItemProps(suggestion, { style })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {line1AdressFilled && <CustomTextInput
                      // name={t("line_two_optional")}
                      placeholder={t("line_two_optional")}
                      onChange={(event) => {
                        let tempHomeaddresToServer = homeAddressToserver;
                        tempHomeaddresToServer.line2 = event.target.value;
                        setHomeAddressToserver(tempHomeaddresToServer);
                        setSelectedAddressLine2(event.target.value)
                      }}
                      value={selectedAddressLine2} style={{ width: '100%', height: '51px' }} />}
                    {AddressError && (
                      displayErrorMessage(AddressError)
                    )}
                    {predictions.length > 0 && (
                      <ul className='bg-[aliceblue] max-h-60 overflow-y-auto p-2'>
                        {predictions.map((prediction) => (
                          <li
                            key={prediction.place_id}
                            className='font-hanken-grotesk text-sm font-medium leading-6 p-2 cursor-pointer'
                            value={prediction.place_id}
                          >{prediction.description}
                          </li>
                        ))}
                      </ul>
                    )}
                    {/* {(infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                      <div className='mb-2'>
                        {displaySuccessMessage(infoMessage)}
                      </div>
                    }
                    {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                      <div className='mb-2'>
                        {displayErrorMessage(infoMessage)}
                      </div>
                    } */}
                    {/* {(infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displaySuccessMessage(infoMessage)}
                                            </div>
                                        }
                                        {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                            <div className='mb-2'>
                                                {displayErrorMessage(infoMessage)}
                                            </div>
                                        } */}

                  </div>
                  <p className="font-hanken-grotesk md:text-[16px] md:mt-5 mt-0 sm:mt-0 text-[#262B3A] sm:text-[14px] 
                  text-[16px] font-medium leading-[26px] md:mb-6 mb-2">
                    {t('post_box_not_accepted')}</p>

                  <div className='mt-2 md:w-[454px] sm:w-[301px] w-full'>
                    <div class="flex items-center mb-4 mt-5">
                      <input
                        tabIndex="0"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            setPrivacyErromessage('');
                            setPrivacycheckboxEnabled(!privacycheckboxEnabled);
                          }
                        }}
                        type="checkbox"
                        className="checkbox-round mt-[6px]"
                        value=""
                        checked={privacycheckboxEnabled}
                        onChange={() => {
                          setPrivacyErromessage('');
                          setPrivacycheckboxEnabled(!privacycheckboxEnabled);
                          // setIsLoading(false)
                        }}
                      />
                      <label class="ml-6 md:text-sm sm:text-[12px] text-[14px] font-medium  text-gray-900 dark:text-gray-300" tabIndex={1}>I have read and agree to
                        <span className=' ml-1 text-blue-500 cursor-pointer'
                          tabIndex={0}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              handleClick("bancrop")
                            }
                          }}
                          onClick={() => handleClick("bancrop")}>{t('bancorp_Notice')}</span> and
                        <span className=' ml-1 text-blue-500 cursor-pointer'
                          tabIndex={0}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              handleClick("userAgreement")
                            }
                          }}
                          onClick={() => handleClick("userAgreement")}>Kudzu’s User Agreement.</span>
                      </label>
                    </div>



                    {privacyErromessage &&
                      <div className='mt-2 mb-2'>
                        {displayErrorMessage(privacyErromessage)}
                      </div>
                    }
                  </div>
                </form>
              </div>
            </div>

            <div className='flex flex-col md:pb-16 sm:pb-12 pb-16 md:mt-20 mt-12 sm:mx-0 mx-4 '>
              <div className='flex flex-row sm:justify-end justify-between sm:mx-0 mr-[6%] ml-[4%]'>
                <button onClick={handleRevertClick} className='font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] leading-[18px] text-[#2965FB] font-bold'>{t("back")}</button>
                <button tabIndex={0} className='font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] eading-[18px] text-[#2965FB] font-bold ml-10 flex items-center'
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      onSubmit();
                    }
                  }}
                  onClick={onSubmit}
                >{t("next")}  <img
                    src={Arrow}
                    className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px]
                     h-[14px] ml-1 animate__animated animate__flash animate__repeat-4"
                    alt="Arrow" /></button>
              </div>
            </div>

          </DynamicCard>
        </div>
      </HomeLayout>
      <div>

      </div>
      {isLoading && <Loader />}
      <Modal isIframe={true}
        isOpen={showAgreementsModel}
        onClose={() => setShowAgreementsModel(false)}
        title={false}
        content={<div className="h-[100%] w-full pt-16">
          {(renderModel == "Link") && <iframe src={policyURL}
            title="Embedded Website" className="w-full h-full rounded-br-[4rem]"
          />}
          {
            (renderModel == "HTML") && <div>{policyURL}</div>
          }
        </div>
        }
      />
    </>
  )
}

export default AddBeneficiary;