import React, { useState } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import Check from '../../../assets/images/Check.png';
import Error from '../../../assets/images/Error.png';
import InputMask from 'react-input-mask';
import { displayErrorMessage } from '../../../utils';
import { ref } from 'yup';
import Showpassword from '../../../assets/images/showpassword.png'
import Hidepassword from '../../../assets/images/hidepassword.png'

const CustomInput = ({ name, label,textInputValue,value, register, error, type,onKeyPress, options, onChange, isValid, onClick, maxLength, isPasswordShow = false, isErrorShow = true, onKeyDownFun, autoFocus, isvalidImagehide, onBlur, additionalStyle='',readOnly=false }) => {
  const isSuccess = !error; // Assuming error is a boolean indicating success or failure
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputValueLen, setInputValueLen] = useState('');

  const placeholderText = 'Your Placeholder Text';
  //styles for input
  const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue placeholder:font-semibold md:text-[16px] sm:text-[14px] text-[16px] md:font-[500] sm:font-[700] font-[500] focus:outline-none w-full h-[60px]";
  const inputContainerClass = isPasswordShow ? " items-center" : " items-center";
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // const handleKeyDown = (e) => {
  //   if (e.key === 'Enter') {
  //     e.preventDefault();
  //     e.stopPropagation();
  //   }
  // };
  // Function to format the input value as "999-999-999"
  const formatInput = (value) => {
  //  debugger;
    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, '');

    // Add hyphens after every three characters
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
      formattedValue += numericValue[i];
      if ((i + 1) % 3 === 0 && i !== numericValue.length - 1) {
        formattedValue += '-';
      }
    }

    return formattedValue.slice(0, 9); // Limit the length to 9 characters
  };

  // Event handler for input changes
  const handleInputChange = (e) => {
    const newValue = formatInput(e.target.value);
    setInputValue(newValue);
  };

  const TextInputChange = (e) => {
    //debugger;
    const newValue = e.target.value;
    setInputValueLen(newValue.length);
  }


  let inputElement;
  switch (type) {
    case 'text':
      inputElement = (
        <div className="w-full relative">
          <div className="items-center">
            <input
              type="text"
              id={name}
              {...register(name)}
              className={`${InputStyles} ${additionalStyle} `}
              autoComplete="off"
              aria-autocomplete="none"
              placeholder={label}
              onChange={onChange}
              value={value}
              defaultValue={textInputValue !== undefined ? textInputValue : ''}
              onKeyDown={onKeyDownFun}
              onKeyUp={TextInputChange}
              maxLength={maxLength !== undefined ? maxLength : '50'}
              autoFocus={autoFocus}
              readOnly={readOnly}
              onKeyPress={onKeyPress} 
              // onBlur={onBlur}
            />

            {isValid === true ? (
              <div className="float-right absolute right-[-2rem] bottom-5">
                <img src={Check} size={20} alt="Check" />
              </div>
            ) : (
              isValid === false && inputValueLen > 0 && <div className="float-right absolute right-[-2rem] bottom-5">
                <img src={Error} size={20} alt="Error" /></div>
            )}
          </div>
        </div>
      );
      break;
    case 'number':
      inputElement = (
        <div className="w-full">
          <div className="flex items-center">
            <input
              type="number"
              id={name}
              {...register(name)}
              className={InputStyles}
              autoComplete="off"
              placeholder={label}
              onChange={onChange}
              onKeyDown={(e) => {
                if (e.target.value.length >= 6 && e.keyCode !== 8) {
                  e.preventDefault();
                }
              }}
              // onBlur={onBlur}

            />
          </div>
        </div>
      );
      break;
    case 'ssn_no':
      inputElement = (
        <div className="w-full">
          <div className="flex items-center relative">
            <span
              type="button"
              className="text-gray-500 absolute right-4 top-5"
              onClick={togglePasswordVisibility}
            >
              {!showPassword ? <img src={Showpassword} className='w-[20px] h-[20px] cursor-pointer' /> : <img src={Hidepassword} className='w-[20px] h-[20px] cursor-pointer' />}
            </span>
            <InputMask
              //type="text"
              mask='999-999-999'
              maskChar="*"
              id={name}
              {...register(name)}
             // value={inputValue}
              placeholder={label}
              className={`${InputStyles} ${showPassword ? 'password-input' : ''}`}
              autoComplete="off"
              onChange={onChange}
              // onBlur={onBlur}

             // onKeyUp={handleInputChange}
            >
            </InputMask>
            {isValid === true ? (
              <div className="ml-4"><img src={Check} size={20} alt="Check" /></div>
            ) : (
              isValid === false && <div className="ml-4"><img src={Error} size={20} alt="Error" /></div>
            )}
          </div>
        </div>

      );
      break;
    case 'dob':
      inputElement = (
        <div className="w-full">
          <div className="flex items-center">
            <input
              type="date"
              id={name}
              style={{ border: "10px solid #eeee" }}
              {...register(name)}
              className={InputStyles}
              autoComplete="off"
              placeholder={label}
              onChange={onChange}
            />
            {isValid === true ? (
              <div className="ml-4"><img src={Check} size={20} alt="Check" /></div>
            ) : (
              isValid === false && <div className="ml-4"><img src={Error} size={20} alt="Error" /></div>
            )}

            {/* <DatePicker
          selected={selectedDate}
          onChange={onChange}
          dateFormat="yyyy-MM-dd"
          className={InputStyles}
          placeholderText={label}
          showMonthDropdown
          showYearDropdown
          id={name} 
          {...register(name)}
          value={value !== undefined ? value : ""} 
          dropdownMode="select" 
        /> */}
            {isValid === true ? (
              <div className="ml-4"><img src={Check} size={20} alt="Check" /></div>
            ) : (
              isValid === false && <div className="ml-4"><img src={Error} size={20} alt="Error" /></div>
            )}
          </div>
        </div>
      );
      break;
    case 'phone':
      inputElement = (
        <div className="w-full">
          <div className="flex items-center">
            <InputMask
               mask={`999-999-9999`}
              // mask={`9 9 9 - 9 9 9 - 9 9 9 9`}
              id={name}
              {...register(name)}
              className={InputStyles}
              //className={`${InputStyles} tracking-wide`}
              autoFocus={autoFocus}
              onChange={onChange}
              placeholder={label}
              onKeyUp={TextInputChange}
              onBlur={onBlur}
              autoComplete="off"
              aria-autocomplete="none"
              // autoComplete="new-password"


            >
            </InputMask>
            {isValid === true ? (
              <div className="ml-4"><img src={Check} size={20} alt="Check" /></div>
            ) : (
              isValid === false && inputValueLen > 0 && <div className="ml-4"><img src={Error} size={20} alt="Error" /></div>
            )}
          </div>
        </div>
      );
      break;
    case '6Code':
      inputElement = (
        <div className="w-full code">
          <div className="flex items-center">
            <input
              type="text"
              id={name}
              maxLength={6}
              {...register(name)}
              // className={InputStyles}
              className='font-hanken-grotesk placeholder:font-semibold placeholder:tracking-normal text-secondary-2 text-left pl-3 py-2 bg-text-btn placeholder-kudzu-blue lg:text-sm md:text-[14px] sm:text-[14px] font-medium focus:outline-none w-full lg:h-[60px] md:h-[50px] sm:h-[45px] h-[50px] tracking-widest'
              autoComplete="off"
              placeholder={label}
              onChange={onChange}
              autoFocus={autoFocus}
              onKeyUp={TextInputChange}
              // onBlur={onBlur}

            // onKeyDown={(e) => {
            //   if (e.target.value.length >= 6 && e.keyCode !== 8) {
            //     e.preventDefault();
            //   }
            // }}
            />
          </div>
        </div>
      );
      break;
    // case 'phone':
    //   inputElement = (
    //     <div className="w-full">
    //       <div className="flex items-center">
    //         <InputMask
    //           mask='999-999-9999'
    //           id={name}
    //           {...register(name)}
    //           className={InputStyles}
    //           autoComplete="off"
    //           onChange={onChange}
    //           placeholder={placeholderText}
    //         >
    //         </InputMask>
    //         <div className="ml-4">
    //         {isValid === true ? (
    //           <div className="ml-4"><img src={Check} size={20} alt="Check" /></div>
    //         ) : (
    //           (isValid === false && inputValueLen > 0) && <div className="ml-4"><img src={Error} size={20} alt="Error" /></div>
    //         )}
    //         </div>
    //       </div>
    //     </div>
    //   );
    //   break;
    case 'checkbox':
      inputElement = (
        <div className="flex items-center">
          <input
            type="checkbox"
            id={name}
            {...register(name)}
            className="form-checkbox text-blue-500 h-4 w-4"
          />
          <label htmlFor={name} className="ml-2">{label}</label>
        </div>
      );
      break;
    case 'radio':
      inputElement = options.map((option) => (
        <div key={option.value} className="flex items-center">
          <input
            type="radio"
            {...register(name)}
            value={option.value}
            className="form-radio text-blue-500 h-4 w-4"
          />
          <label htmlFor={option.value} className="ml-2">{option.label}</label>
        </div>
      ));
      break;
    case 'dropdown':
      inputElement = (
        <div>
          <label htmlFor={name}>{label}</label>
          <select
            id={name}
            {...register(name)}
            className="font-hanken-grotesk w-full text-blue-500 px-4 py-2 bg-text-btn placeholder-kudzu-blue text-sm font-medium focus:outline-none"
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      );
      break;
    case 'buttonGroup':
      inputElement = (
        <div>
          <label>{label}</label>
          <div className="flex">
            {options.map((option) => (
              <button
                key={option.value}
                type="button"
                {...register(name)}
                value={option.value}
                className="px-4 py-2 mr-2 lg:text-[18px] md:text-[17px] 
                sm:text-[15px] text-[16px] font-medium bg-blue-500 text-white rounded-md focus:outline-none"
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      );
      break;
    case 'button':
    case 'submit':
      inputElement = (
        <>
          <button
            type={type}
            className="font-hanken-grotesk lg:text-[18px] md:text-[17px] 
            sm:text-[15px] text-[16px] w-full bg-submit-btn hover:bg-submit-btn text-white py-2 px-4 md:font-[500] sm:font-[700] font-[500] lg:h-[60px] md:h-[50px] sm:h-[45px] h-[50px]"
            onClick={onClick}
            onKeyDown={onKeyDownFun}
          >
            {label}
          </button>
        </>
      );
      break;
    case 'btn_withoutBg':
      inputElement = (
        <>
          {/* <button
            type={type}
            className="font-hanken-grotesk w-full bg-submit-btn  text-white py-2 px-4 text-sm font-bold h-[60px]"
            onClick={onClick}
          >
            {label}
          </button>  */}
          <button
            type={type}
            className="font-hanken-grotesk w-full bg-white border-submit-btn text-submit-btn py-2 px-4 text-sm border-2 border-btn-border  text-secondary-2 font-bold h-[60px]"
            onClick={onClick}
            onKeyDown={onKeyDownFun}
          >
            {label}
          </button>
        </>
      );
      break;
    case 'password':
      inputElement = (
        <div className={`${inputContainerClass} relative`}>
          <input
            type={showPassword ? "text" : "password"}
            id={name}
            {...register(name)}
            className={InputStyles}
            autoComplete="off"
            placeholder={label}
            onChange={onChange}
            autoFocus={autoFocus}
            onKeyUp={TextInputChange}
            onKeyDown={onKeyDownFun}
            // onKeyDown={handleKeyDown}
          />
          {isPasswordShow !== false && inputValueLen > 0 && (
            <span
              type="button"
              className="text-gray-500 ml-2 absolute md:right-4 right-4 top-5"
              onClick={togglePasswordVisibility}
            >
              {!showPassword ? <img src={Showpassword} /> : <img src={Hidepassword} className='w-[20px] h-[20px]' />}
            </span>
          )}

          {isvalidImagehide ?
            '' : <div className="ml-2">
              {isValid === true ? (
                <img src={Check} size={20} alt="Check" className='float-right relative bottom-10 sm:left-10 left-6' />
              ) : (
                isValid === false && <img src={Error} size={20} alt="Error" className='float-right relative bottom-10 sm:left-10 left-6' />
              )}
            </div>
          }
        </div>
      );
      break;
    default:
      inputElement = null;
  }

  return (
    <div className="mb-4">
      {inputElement}
      {(['text', 'checkbox', 'dropdown', 'buttonGroup', 'password', 'number', 'dob', 'ssn_no'].includes(type) && isErrorShow) && (
        // <div style={{ height: '1rem' }}>
        //   {error && <span className="text-red-500">{error.message}</span>}
        // </div>
        <div className='my-2 mt-4 md:w-[370px] sm:w-[213px]'>
          {error && displayErrorMessage(error.message)}

        </div>
      )}
    </div>
  );
};

export default CustomInput;