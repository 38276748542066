import React, { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withOidcSecure } from '@axa-fr/react-oidc'
import { width } from "../../CustomComponents/Card/CardStyles";
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { useTranslation } from 'react-i18next';
import HomeLayout from '../../Layout/HomeLayout';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { useNavigate } from 'react-router';
import { SecuritySidebar } from '../../../utils';
import CustomInput from '../../CustomComponents/FormInputs';
import { autoFocusFunction, generateRegexExpression, generateRegexExpression1, retrieveData } from '../../../helpers/utils/Utils';
import * as yup from 'yup';
import { EMAIL_OTP_GENERATION, STATUS_SUCCESS_CODE, META_DATA_POLICIES, CHANGE_PASSWORD, ISDEV_ENV } from '../../../network//URLConstants';
import { fetchConfigarationData } from '../../../helpers/utils/Utils';
import { useSelector } from 'react-redux';
import { GetApiHeadersWithOutBearer, GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import PasswordReset from '../../../assets/images/activate_change_lock.png';
import { displayErrorMessage } from '../../../utils';
import { useKeyDown } from '../../../hooks/useKeyDown';
import Loader from "../../CustomComponents/Loader";
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import logFirebaseEvents from '../../LogFirebaseEvents';


const appConfigData = await fetchConfigarationData();

const ChangePassword = () => {
    const { t } = useTranslation();
    const userId = useSelector(state => state.userId);
    const navigate = useNavigate();
    const [policyCriteria, setPolicyCriteria] = useState({});
    const [isShowPolicyRules, setIsShowPolicyRules] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [policyRules, setPolicyRules] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isOldPasswordValid, setIsOldPasswordValid] = useState(false)
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(false)
    const [isRepeatPasswordValid, setIsRepeatPasswordValid] = useState(false)
    const regexpression = generateRegexExpression(policyCriteria?.PolicyTemplate);
    const { watch } = useForm();


    const sessionKey = useSelector(state => state.loggedSessionKey);

    ///password change modal
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px]  font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title font-normal cursor-pointer pointer-events-none lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] text-black ";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none leading-[30px]";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [modelImage, setModelImage] = useState('')
    const [showModel, setShowModel] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassWord] = useState('');
    const [repeatPassword, setRepeatPassWord] = useState('');
    let policyIndex=0;

    const accessTokenToApi = useSelector(state => state.accessToken);


    const showAlertModal = (showModel, highlightMsg, headerMsg,
        subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text,
        btn1TypeAcpt, modelImage) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }

    const closeCustomAlertModal = () => {
        setShowModel(false);
    }

    const onAcptBtnClick = async () => {
        setShowModel(false)
        navigate('/Security')

    }
    const onCancelBtnClick = () => {
        setShowModel(false)

    }

    const resetSuccess = async () => {

        chnagePasswordClicked();
        // showAlertModal(true, t('Success!'), t('Your new password has been set.'),
        //     '', true,
        //     true, t('Back to Security'), '',
        //     true, PasswordReset);
    }

    ///Form
    const schema = yup.object().shape({
        currentPassword: yup.string()
            .required("Current password is required"),
        newPassword: yup.string()
            .required("New password is required")
            .matches(
                regexpression, "The password you entered does not meet the requirements as listed below."
            )
            .notOneOf([yup.ref('oldPassword'), null], "New password must be different from the current password"),
        repeatPassword: yup.string()
            .required("Confirm new password is required")
            .oneOf([yup.ref('newPassword'), null],  t("repeat_password_error_msg")),
    });


    const {
        register,
        handleSubmit,
        formState: { errors }, control, clearErrors,
        getValues,
        setFocus,
        setError
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        // defaultValues: { newPassword: "newPassword@12" }
    });

    //ON CHANGE VALIDATION
    const [isMinMax, setIsMinMax] = useState(false);
    const [isNumeric, setIsNumeric] = useState(false);
    const [isAlpha, setIsAlpha] = useState(false);
    const [isLowerCase, setIsLowerCase] = useState(false);
    const [isUpperCase, setIsUpperCase] = useState(false);
    const [isSpecialChar, setIsSpecialChar] = useState(false);
    const [isNewPassword, setIsNewPassword] = useState(false);

    const handleOldPasswordChange = (e) => {
        if (e.target.value != "") {
            clearErrors()
        }


        setErrorMessage('');
        const oldPassword = e.target.value;
        if(newPassword){
            if (newPassword != oldPassword) {
                setIsNewPassword(true);
            } else {
                setIsNewPassword(false);
            }

        }
        setOldPassword(oldPassword);
        setIsOldPasswordValid(true)
    }

    const handlePasswordChange = (e) => {
        if (e.target.value != "") {
            clearErrors()
        }
        setErrorMessage('');
        const password = e.target.value;

        // Regular expression to validate password format
        const minMaxRegex = generateRegexExpression1("MinMax", policyCriteria?.PolicyTemplate)
        const numericRegex = generateRegexExpression1("Numeric", policyCriteria?.PolicyTemplate)
        const alphaRegex = generateRegexExpression1("Alpha", policyCriteria?.PolicyTemplate)
        const lowercaseRegex = generateRegexExpression1("Lowercase", policyCriteria?.PolicyTemplate)
        const uppercaseRegex = generateRegexExpression1("Uppercase", policyCriteria?.PolicyTemplate)
        const specialCharRegex = generateRegexExpression1("Special", policyCriteria?.PolicyTemplate)


        if (numericRegex.test(password)) {
            setIsNumeric(true);
        } else {
            setIsNumeric(false);
        }

        if (minMaxRegex.test(password)) {
            setIsMinMax(true);
        } else {
            setIsMinMax(false);
        }
        if (alphaRegex.test(password)) {
            setIsAlpha(true);
        } else {
            setIsAlpha(false);
        }
        if (lowercaseRegex.test(password)) {
            setIsLowerCase(true);
        } else {
            setIsLowerCase(false);
        }
        if (uppercaseRegex.test(password)) {
            setIsUpperCase(true);
        } else {
            setIsUpperCase(false);
        }
        if (specialCharRegex.test(password)) {
            setIsSpecialChar(true);
        } else {
            setIsSpecialChar(false);
        }
        if (password != oldPassword) {
            setIsNewPassword(true);
        } else {
            setIsNewPassword(false);
        }
        if (regexpression.test(password)) {
            setIsNewPasswordValid(true);
        } else {
            setIsNewPasswordValid(false);
        }
        if (repeatPassword) {
            if (password === repeatPassword) {
                setIsRepeatPasswordValid(true);
            } else {
                setIsRepeatPasswordValid(false);
            }

        }

        setNewPassWord(password);

    };
    const handleRepeatPasswordChange = (e) => {
        if (e.target.value != "") {
            clearErrors()
        }
        setErrorMessage('');
        const repeatPassword = e.target.value;
        setRepeatPassWord(repeatPassword);
        if (newPassword === repeatPassword) {
            setIsRepeatPasswordValid(true);
        } else {
            setIsRepeatPasswordValid(false);
        }
    }
    const fieldArray = ["password", "newPassword", "repeatPassword"]
    autoFocusFunction(fieldArray, getValues, setFocus);

    useEffect(() => {
        getPasswordPolicy();
    }, [userId])

    const getPasswordPolicy = async () => {
        try {
            setIsLoading(true);
            const applicationId = appConfigData.CLIENT_ID;
            const url = `${META_DATA_POLICIES}language=en&applicationid=${applicationId}&avn=1.0.0&isNew=false`;

            const deviceId = await retrieveData('updatedDeviceId')
            const headers = await GetApiHeadersWithOutBearer(deviceId);

            const response = await GetRequest(url, headers);
            const responseObj = JSON.parse(response);
            if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
                setIsLoading(false);
                const policyObj = responseObj.Response?.PasswordPolicy;
                setPolicyCriteria(policyObj);
                setPolicyRules(policyObj.PolicyRules);
                setIsShowPolicyRules(true)
                setErrorMessage('');
                clearErrors();
            } else {
                setIsLoading(false);
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                // setErrorMessage(displayText);
                setError("repeatPassword", {
                    type: "manual",
                    message: displayText,
                  })

            }

        } catch (error) {
            setIsLoading(false);
        }
    }

    const chnagePasswordClicked = async (data) => {
        logFirebaseEvents("Change_Password", "Update_Change_Password_Clicked", "Update_Change_Password_Clicked", "","");
        // if (
        //     this.state.isPLengthCaseValid &&
        //     this.state.isAlphaNumeric &&
        //     this.state.isLowerCaseValid &&
        //     this.state.isUpperCaseValid &&
        //     this.state.isNumberCaseValid &&
        //     this.state.isNonAlphaLengthMacthed &&
        //     this.state.isBothPasswordsAreSame
        // ) {
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);


        var object = {
            // sessionId: sessionKey,
            "applicationId": appConfigData.CLIENT_ID,
            "oldPassword": oldPassword,
            "newPassword": newPassword,
        };

        var url = CHANGE_PASSWORD;
        setIsLoading(true);
        var response = await PostRequest(url, object, headers);
        var responseObj = JSON.parse(response);
        setIsLoading(false);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            showAlertModal(true, t('Success!'), t('Your new password has been set.'), '', true, false, t('Back to Security'), '', true, PasswordReset);
        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            // setErrorMessage(displayText);
            setError("repeatPassword", {
                type: "manual",
                message: displayText,
              })

        }
        //}
    }
    const leftBarComp = () => {
        return (
            <div>
                <p className="text-white font-normal text-[20px] leading-[30px] md:text-[18px] md:leading-[24px] lg:text-[32px] lg:leading-[38px] font-serif-pro ">{t("account_secure")}</p>
                <hr className=" h-[2.5px] mx-auto my-4 bg-[#4B70B9] bg-opacity-50 border-0 rounded md:my-10" />

                <div className=' flex flex-col gap-10 cursor-pointer'>
                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#FEFAEE]' onClick={() => navigate("/Security/TrustedDevice")}>{t("trusted_devices")}</p>
                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#FEFAEE]' onClick={() => navigate("/Security/ChangePassword")}>{t("change_password")}</p>
                </div>
            </div>
        )
    }
    const onSubmit = async (data) => {
        if (Object.keys(errors).length > 0) {
            //   alert(errors[Object.keys(errors)[0]].message);
        } else {
            try {

                // Call your change password logic here
                resetSuccess();
            } catch (error) {
                // Handle specific errors and set form errors using setError
            }
        }
    };


    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // setValue('newPassword', repeatPassword)
            // setValue('repeatPassword', repeatPassword)
            handleSubmit(onSubmit)();

        }
    };

    useKeyDown(() => {
        handleSubmit(onSubmit)();
    }, ["Enter"]);
    const handleChangePasswordClick = () => {

        // if (twoFactAuthenticationDone) {
        //     navigate('/Security/ChangePassword');
        // } else {
        //     // alert("change password need to do")
        //     generate2FAOtp(t('change_password'))
        // }
    };

    return (
        <>

            <HomeLayout>

                <SecuritySidebar changePasswordClicked={handleChangePasswordClick} selectedIndex={1} />

                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
                      animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        CardRadius={true}
                        cardWidth={width.allpolicies}
                        HeaderRadius={true}
                        backgroundColor={'pink'}
                        isWhite={true}
                        HeaderContent={false}>

                        <div class="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                      lg:text-[26px] md:text-[22px] 
                      sm:text-[20px] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">{t("change_password")}</div>
                        <div className='border-t-[2.5px] border-[#A3CEBB] pb-10 
                        sm:mx-0 mx-4'></div>

                        <div className='sm:w-full w-[264px] sm:mx-0 mx-4'>
                            <div className='font-serif-pro 
                        lg:text-[32px] md:text-[26px] sm:text-[16px] text-[25px] 
                         md:leading-[38px] sm:leading-[24px] leading-[31px] 
                         font-normal text-[#2965FB]'>
                                <p>{t("let’s_lock")}</p>
                                <p className='text-black'>{t("Set_new_password")}</p>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => handleKeyDown(e)}>
                            <div className='w-full flex justify-center lg:py-16 md:py-14 sm:pt-10 sm:pb-0 py-4'>
                                <div className='sm:w-[62%] w-full sm:mx-0 mx-4'>
                                    <div className='lg:w-[384px] md:w-[340px] sm:w-[224px]'>
                                        <div className='md:w-[282px] sm:w-[198px] flex flex-col gap-4 mt-4'>
                                            <CustomInput
                                                name="currentPassword"
                                                label={t("currentPassword")}
                                                register={register}
                                                error={errors.currentPassword}
                                                type="password"
                                                onChange={(e) => {
                                                    handleOldPasswordChange(e);
                                                    clearErrors("oldPassword")
                                                }}
                                                isErrorShow={true}
                                                autoFocus={true}
                                                isPasswordShow={!!oldPassword} // Set to true if there's a value
                                                showPasswordToggle={!!oldPassword}
                                                isValid={oldPassword ? isOldPasswordValid : undefined}
                                                maxLength={20}
                                            />

                                            <CustomInput
                                                name="newPassword"
                                                label={t("newPassword")}
                                                register={register}
                                                error={errors.newPassword}
                                                type="password"
                                                onChange={(e) => {
                                                    handlePasswordChange(e);
                                                    clearErrors("newPassword")
                                                }}
                                                isErrorShow={true}
                                                // isAutoFocus={true}
                                                isPasswordShow={!!newPassword} // Set to true if there's a value
                                                showPasswordToggle={!!newPassword}
                                                isValid={newPassword ? isNewPasswordValid : undefined}
                                                maxLength={20}
                                            />


                                            <CustomInput
                                                name="repeatPassword"
                                                label={t("confirm_newPassword")}
                                                register={register}
                                                error={errors.repeatPassword}
                                                type="password"
                                                onChange={(e) => {
                                                    handleRepeatPasswordChange(e);
                                                    clearErrors("repeatPassword")

                                                }}
                                                isErrorShow={true}
                                                //isAutoFocus={true}
                                                isPasswordShow={!!repeatPassword} // Set to true if there's a value
                                                showPasswordToggle={!!repeatPassword}
                                                isValid={repeatPassword ? isRepeatPasswordValid : undefined}
                                                maxLength={20}
                                            />
                                        </div>
                                        {/* {Object.keys(errors).length > 0 && (
                                            <div className='mb-2'>
                                                {displayErrorMessage(errors[Object.keys(errors)[0]].message)}
                                            </div>
                                        )}
                                        {errorMessage && (
                                            <div className='mb-2'>
                                                {displayErrorMessage(errorMessage)}
                                            </div>
                                        )} */}
                                        {
                                            isShowPolicyRules &&
                                            <p className="font-hanken-grotesk  lg:text-md md:text-[16px] sm:text-[12px] text-[16px]  leading-7 text-secondary-3 font-medium mb-14">
                                                {policyCriteria?.PolicyTemplate?.MinimumLength > 0 &&
                                                    <span className={isMinMax && "text-[#30BC89]"}>
                                                        {/* Please choose a new password that is at least 8 characters in length. */}
                                                        {policyRules[policyIndex++]}{' '}

                                                    </span>
                                                }
                                                {policyCriteria?.PolicyTemplate?.AlphaLength > 0 ?
                                                    <span className={isAlpha && "text-[#30BC89]"}>
                                                        {/* "Must have at least {0} letter.", */}
                                                        {policyRules[policyIndex++]}{' '}

                                                    </span>
                                                    :
                                                    (policyCriteria?.PolicyTemplate?.LowercaseLength > 0) &&
                                                    <span>
                                                        <span className={isLowerCase && "text-[#30BC89]"}>
                                                            {/* "Must have at least 1 lower case letter.", */}
                                                            {policyRules[policyIndex++]}{' '}

                                                        </span>
                                                        {(policyCriteria?.PolicyTemplate?.UppercaseLength < 0) ?

                                                            <span className={!isUpperCase && "text-[#30BC89]"}>
                                                                {/* "New password should not contain any upper case letters.", */}
                                                                {policyRules[policyIndex++]}{' '}

                                                            </span>
                                                            :
                                                            <span className={isUpperCase && "text-[#30BC89]"}>
                                                                {/* "Must have at least 1 upper case letter.", */}
                                                                {policyRules[policyIndex++]}{' '}

                                                            </span>
                                                        }
                                                    </span>
                                                }

                                                {(policyCriteria?.PolicyTemplate?.NumericLength > 0) &&
                                                    <span className={isNumeric && "text-[#30BC89]"}>
                                                        {/*  "Must have at least 1 number." */}
                                                        {policyRules[policyIndex++]}{' '}
                                                    </span>
                                                }
                                                {(policyCriteria?.PolicyTemplate?.NonAlphaLength > 0) &&
                                                    <span className={isSpecialChar && "text-[#30BC89]"}>
                                                        {/* "Must have at least 1 special character.", */}
                                                        {policyRules[policyIndex++]}{' '}
                                                    </span>
                                                }
                                                <span className={(isNewPassword) && "text-[#30BC89]"}>
                                                    {/* "Your new password should not be same as your username or as your last password.",*/}
                                                    {policyRules[policyIndex++]}{' '}
                                                </span>
                                                <span className={(isNewPassword) && "text-[#30BC89]"}>
                                                    {/*"Choose a password that is different from your last 5 passwords."*/}
                                                    {policyRules[policyIndex++]}{' '}
                                                </span>
                                                <span className={(newPassword ) && "text-[#30BC89]"}>
                                                    {/*"Do NOT share your password with anyone." */}
                                                    {policyRules[policyIndex]}
                                                </span>
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className='flex flex-col pb-16'>
                            <div className='flex flex-row sm:justify-end justify-between sm:mx-0 mr-[6%] ml-[4%]'>
                                <button onClick={() => { navigate('/Security'); logFirebaseEvents("Change_Password", "Back_Clicked", "Back_Clicked", "",""); }} className='font-hanken-grotesk 
                                leading-[18px] text-[#2965FB] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                                 font-bold'>{t("back")}</button>

                                {(!oldPassword || !newPassword || !repeatPassword) && <button tabIndex={0} className='font-hanken-grotesklg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px]
                                leading-[18px] text-[#94C3E8]
                                 font-bold ml-14'>{t("save")}</button>}

                                {(oldPassword && newPassword && repeatPassword) && <button tabIndex={0} className='font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                                leading-[18px] text-[#2965FB]
                                 font-bold ml-14' onClick={() => {
                                        handleSubmit(onSubmit)();
                                    }}>{t("save")}</button>}

                            </div>
                        </div>
                        {/* <div className='pb-16 text-[18px] text-[#2965FB] font-hanken-grotesk
                        font-medium leading-[28px] cursor-pointer'>{t("Cancel")}</div> */}

                    </DynamicCard>
                </div>
            </HomeLayout>

            <CustomAlertModel isOpen={showModel} isBorderNone={true}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                    <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                // highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                // HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
            />
            {isLoading && <Loader />}

        </>
    )
}

export default withOidcSecure(ChangePassword)