import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import check_img from '../../../assets/images/check_img.png';
import error_img from '../../../assets/images/error_png.png';
import { displayErrorMessage } from '../../../utils';

const CustomTextArea = (props) => {
    const textareaRef = useRef(null);
    const { t } = useTranslation(); // Hook to access translation functions

    return (
        <div className='w-full'>
            <p className='font-hanken-grotesk text-[#262B3A] 
 uppercase  lg:text-[13px] md:text-[12px] 
 sm:text-[9px] text-[13px]  font-[600] tracking-[2px] md:pb-6 pb-5
 opacity-[50%] '> {props?.name?.toLocaleUpperCase() || ''}</p>
            <div className='flex flex-row w-full items-center'>
                <textarea
                ref={textareaRef}
                    onKeyDown={(e) => { props?.onKeyDown?.(e) }}
                    className='font-hanken-grotesk text-16 md:w-full w-full md:p-6 p-5 md:font-[500] sm:font-[700] font-[500]'
                    placeholder={props?.placeholder || ''}
                    value={props?.value || ''}
                    cols={props?.cols || 30} // Default to 30 columns if not specified
                    rows={props?.rows || 6}  // Default to 4 rows if not specified
                    maxLength={props?.maxLength || undefined}
                    autoFocus={props?.autoFocus || false}
                    onChange={(e) => { 
                        props?.onChange?.(e) 
                        if (props?.multilineEnabled == true) {
                            if (textareaRef.current) {
                                textareaRef.current.style.height = 'auto'; // Reset height to auto
                                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
                            }
                        }
                    }}
                    // onBlur={(e)=> { props?.onBlur?.(e)}}
                    multilineEnabled={props?.multilineEnabled || false}
                    style={{
                        resize: props?.multilineEnabled == true?'none':'',
                       // overflowY:'hidden',
                        color: '#2965FB',
                        backgroundColor: 'rgba(234, 240, 243)',
                        border: 'none',
                        outline: 'none',
                        '::placeholder': {
                            color: '#2965FB', // Placeholder color
                        },
                        ...props.style, // Allow additional custom styles from parent component
                    }}
                    readOnly={props?.readOnly || false}
                />
                {props?.showValidity && (
                    <div>
                        {props?.valid ? (
                            <img
                                src={check_img}
                                // alt="Image"
                                className="w-4 h-4 mr-2 ml-5"
                            />
                        ) : (
                            <img
                                src={error_img}
                                // alt="Image"
                                className="w-4 h-4 mr-2 ml-5"
                            />
                        )}
                    </div>
                )}
            </div>
            <div className='my-2 w-full'>
                {props?.error && displayErrorMessage(props?.error)}
            </div>
        </div>
    );
};

export default CustomTextArea;