import React, { useState, useEffect } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../CustomComponents/Loader';
import CustomTextInput from '../../../../CustomComponents/CustomtextInput';
import { UPDATE_CARDNAME, STATUS_SUCCESS_CODE } from '../../../../../network/URLConstants';
import { GetApiHeadersWithBearer, PostRequest } from '../../../../../network/NetworkUtils';
import { displayErrorMessage, displaySuccessMessage } from '../../../../../utils';
import { retrieveData } from '../../../../../helpers/utils/Utils';
import LineBreaks from '../../../../../utils';
import HomeLayout from '../../../../Layout/HomeLayout';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import checkIcon from '../../../../../assets/images/Error.png';
import disableCheck from '../../../../../assets/images/disableCheck.png';

// import DynamicCard from '../../../../CustomComponents/DynamicCard';

const EditSharedAccountName = ({ transactionDatas, onViewAllClick, viewAll }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(); // Hook to access translation functions
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [editClicked, setEditClicked] = useState(false);
    const [showCheckImage, setshowCheckImage] = useState(false);

    // const selectedItem = cards?.[0]
    const selectedItem = cards?.find(item => item.AccountTypeName === 'Shared');

    const [newName, setNewName] = useState(selectedItem?.NickName);

    const [loading, setLoading] = useState(false)
    const [infoMessage, setinfoMessage] = useState('');
    const [responseCode, setResponseCode] = useState('');


    const handleRevertClick = () => {
        setinfoMessage('');
        setEditClicked(false);
    };

    const handleSaveClick = () => {
        if (newName.length !== 0) {
            updateCardName();
        } else {
            setResponseCode('400')
            setinfoMessage('Please enter account name');
        }
    };

    useEffect(() => {
        console.log('8745', cards)
    }, [cards, dispatch]);

    const handleOnBlurNameField=(e)=>{
        if(newName=='') setinfoMessage('Please enter account name');
        else setinfoMessage('');
    }

    async function updateCardName() {
        if (selectedItem?.CardKey == undefined) {
            return
        }
        setLoading(true);
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        const cardKey = selectedItem?.CardKey;

        var url = UPDATE_CARDNAME + cards?.[0].CardKey;

        const object = [
            {
                CardKey: cardKey,
                NickName: newName,
            },
        ];
        setLoading(true);
        const response = await PostRequest(url, object, headers);
        let parsedResponse = JSON.parse(response);

        if (parsedResponse.ResponseCode === STATUS_SUCCESS_CODE) {
            setResponseCode(parsedResponse.ResponseCode)
            setLoading(false);
            setinfoMessage('Account name updated successfully');

            setTimeout(() => {

                setinfoMessage(t(''));
                // updateCardsInLocal();
                // setNickName(newName);
                const updatedCardData = [...cards]; // Create a copy of the array
                updatedCardData[updatedCardData.findIndex(item => item.AccountTypeName === "Shared")].NickName = newName; // Update the NickName property of the first object
                dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedCardData) } });

              
                setEditClicked(false);
            }, 2000);
        } else {
            var arrMessages = parsedResponse.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            setEditClicked(false);
            setinfoMessage(displayText);
            setLoading(false);
        }


    }

    return (

        <HomeLayout>
            <HomeHeader heading="" isHeaderCaption={false} />
            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
                <DynamicCard
                    header={false}
                    footer={false}
                    CardRadius={true}
                    HeaderRadius={true}
                    isWhite={true}
                    cardWidth={width.homeCard} >

                    <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px] font-hanken-grotesk
                 sm:mx-0 mx-4">{t('account_name')}</div>

                    <div className='border-t-[2.5px] border-[#A3CEBB]
                     flex justify-center flex-col sm:mx-0 mx-4'> </div>


                    <div className="flex flex-col w-full h-full md:mb-[15rem] sm:mb-[175px] mb-[60px]">
                        <div >
                            <div className="bg-[#fff] h-0.5 min-h-1 mt-4"></div>
                        </div>
                        <div className='mt-5 lg:w-[479px] md:w-[450px] sm:w-[300px] sm:mx-0 mx-4'>
                            <p className='font-serif-pro lg:text-[26px] md:text-[22px] sm:text-[20px] text-[26px]
                            lg:leading-[36px]  md:leading-[31px] sm:leading-[29px] leading-[36px]
                            font-medium text-[#262B3A]'>{(t('give_your_account_a_nickname'))}</p>
                            <p className='text-[#262B3A] font-hanken-grotesk
                            lg:text-[16px] md:text-[15px] sm:text-[14px] text-[16px]
                            sm:mt-[10px] mt-[25px] leading-24'>{(t('these_will_only_be_viewable_by_you'))}</p>
                        </div>
                        {!editClicked &&
                            <div className='flex flex-col sm:w-4/5 w-full self-center justify-center mt-10 sm:mx-0 mx-4'>

                                <p className=' font-hanken-grotesk text-[#262B3A] 
      uppercase  lg:text-[13px] md:text-[12px] sm:text-[11px] text-[12px] font-[600]
       tracking-[2px] opacity-[50%] pt-8 sm:mx-0 mx-4'>{t("account_ending_in")}
                                    {selectedItem?.CardDisplayNumber}</p>

                                <p className="flex justify-between items-center sm:mx-0 mx-4 font-hanken-grotesk
 lg:text-[16px] md:text-[15px] sm:text-[13px]
  text-[14px] font-medium leading-[26px] mt-8 mb-2">{selectedItem?.NickName}

                                    {/* <img src={checkIcon} alt="view details" className='w-[20px] h-[20px]' /> */}
                                    {showCheckImage && <img src={disableCheck} alt="view details" className='w-[20px] h-[20px]' />}
                                </p>

                                <div className='full'>
                                    <div className="bg-[#94C3E8] h-0.5 min-h-1 mt-4 opacity-[50%] sm:mx-0 mx-4"></div>
                                </div>
                                <div className='flex'>
                                    <button onClick={(event) => {
                                        event.target.style.opacity = 0.8;
                                        setTimeout(() => { event.target.style.opacity = 1; }, 200);
                                        setEditClicked(true)
                                    }}>{<p onClick={() => { setEditClicked(true); setNewName(selectedItem?.NickName) }} className='pt-5 font-hanken-grotesk text-[#2965FB]
                                    lg:text-[16px] md:text-[15px] 
                                    sm:text-[13px] text-[13px] sm:mx-0 mx-4
                                    font-[700]'>{t("edit_account_name")}</p>}</button>
                                </div>
                            </div>
                        }
                        {editClicked &&
                            <div className='flex flex-col sm:w-4/5 w-full self-center justify-center'>
                                <div className='flex mt-10 sm:mx-0 mx-4'>
                                    <CustomTextInput
                                        autoFocus={true}
                                        name={t("account_ending_in") + " " + selectedItem?.CardDisplayNumber}
                                        onChange={(event) => {
                                            setinfoMessage('');
                                            setNewName(event.target.value)
                                        }}
                                        maxLength={22}
                                        value={newName} inputWidth={`w-[100%]`} 
                            onBlur={(event)=>handleOnBlurNameField(event)}
                                        
                                        />
                                </div>

                                {(infoMessage && responseCode === STATUS_SUCCESS_CODE) &&
                                    <div className='mb-2'>
                                        {displaySuccessMessage(infoMessage)}
                                    </div>
                                }
                                {(infoMessage && responseCode !== STATUS_SUCCESS_CODE) &&
                                    <div className='mb-2'>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                }
                                <p className='font-hanken-grotesk text-[#94C3E8] lg:text-[16px] md:text-[15px] 
                                            sm:text-[13px] text-[13px] sm:mx-0 mx-4
                                font-semibold mt-5'>{t("edit_account_name")}</p>
                            </div>
                        }
                        {editClicked &&
                            <div className='flex flex-col sm:mt-20 mt-12 sm:mx-0 mx-4'>
                                <div className='flex flex-row sm:justify-end justify-between'>
                                    <button onClick={handleRevertClick} className='font-hanken-grotesk 
                                    text-[#2965FB] font-semibold lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] cursor-pointer'>{t("revert")}</button>
                                    <button onClick={handleSaveClick} className='cursor-pointer font-hanken-grotesk
                                     text-[#2965FB] font-semibold lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] ml-10'>{t("save")}</button>
                                </div>
                            </div>
                        }
                    </div>
                    {loading && <Loader />}
                </DynamicCard>
            </div>
        </HomeLayout>


    )
}

export default EditSharedAccountName;
