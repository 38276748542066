import React, { useEffect } from 'react'
import Banner from '../../assets/images/banner.png'
import App_Store from "../../assets/images/App_Store.png";
import Play_Store from "../../assets/images/Play_Store.png";
import whiteLogo from '../../assets/images/Logo H Warm White S.png'
import { withOidcSecure } from '@axa-fr/react-oidc';
import DynamicCard from '../CustomComponents/DynamicCard';
import { isAndroid, isIOS, BrowserView } from 'react-device-detect';
import { ANDROID_STORE_URL, APPLE_STORE_URL } from '../../network/URLConstants';



const DownloadApp = () => {

    const BannerImage = {
        height: '100vh',
        // backgroundImage: `url(${Banner})`,
        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${Banner})`,
        // background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${Banner})`,
        backgroundSize: 'cover',  // This makes the image cover the entire container
        backgroundPosition: 'center',
        maxWidth: '100%',
    };


    // Function to redirect based on platform
    const redirectToAppStore = () => {
        if (isIOS) {
            // Redirect to Apple App Store URL
            window.open(`${APPLE_STORE_URL}`, '_blank');
        } else if (isAndroid) {
            // Redirect to Google Play Store URL
            window.open(`${ANDROID_STORE_URL}`, '_blank');
        }
    }

    useEffect(() => {
        redirectToAppStore();
    }, [])


    return (
        <BrowserView className='relative z-0'>
            <div className="flex justify-start p-12 bg-[#30BC89] bg-gradient-to-r from-black/50 to-black/50">
                <img src={whiteLogo} alt="" id="kudzuImg" />
            </div>
            <div className="h-[100vh] flex item-center justify-center w-full" style={BannerImage}>
                <div className='flex flex-col w-hull sm:w-[60%] mt-32 opacity-100 z-50'>

                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={"100%"}
                        HeaderRadius={true}
                        footer={true}
                        HeaderContent={false}
                        backgroundColor={'#FFFFFF'}

                    >

                        <div className='flex flex-col justify-center items-center gap-4 p-11 py-20'>
                            <p>Go mobile with kudzu </p>
                            <p>Access your card wherever you go. Download the full featured kudzu </p>
                            <div className=' flex sm:flex-col flex-row gap-4'>
                                <a href={APPLE_STORE_URL}  target="_blank" className=' cursor-pointer'>
                                    <img src={App_Store} alt="App Store" />
                                </a>
                                <a href={ANDROID_STORE_URL}  target="_blank" className=' cursor-pointer'>
                                    <img src={Play_Store} alt="Play Store" />

                                </a>
                            </div>

                        </div>
                    </DynamicCard>

                </div>
            </div>
        </BrowserView>
    )
}

export default DownloadApp