import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomInput from '../../../CustomComponents/FormInputs';
import Arrow from '../../../../assets/images/arrow.png';
import Modal from '../../../CustomComponents/Modal';
import addLineBreaks, { displayErrorMessage } from '../../../../utils';
import Card from '../../../CustomComponents/Card';
import VerifyEmailCode from '../../Signup/EmailVerification';
import { GetApiHeadersWithOutBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import { EMAIL_OTP_GENERATION, STATUS_SUCCESS_CODE, STUDENT_SIGNUP_EMAIL_ACCOUNT } from '../../../../network//URLConstants';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../../CustomComponents/Loader";
import { checkEmailExtension, isEmailValid, retrieveData } from '../../../../helpers/utils/Utils';
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import { useNavigate } from 'react-router';


const appConfigData = await fetchConfigarationData();
export const StudentEmailVerfication = ({ userEmail, openAccessCodewithemail, backtoUserType, openUniversitySelection }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();


  const schema = yup.object().shape({
    email: yup
      .string()
      // .required(t('please _enter _student_email_address'))
      .email(t('please_enter_a_valid_email_address')),
  });

  const [isSendVerificationModalOpen, setIsSendVerificationModalOpen] = useState(false);
  const [email, setEmail] = useState(userEmail);
  const [isLoading, setIsLoading] = useState(false);
  // const deviceId = useSelector(state => state.deviceId);
  const accessToken = useSelector(state => state.accessToken);
  const sessionId = useSelector(state => state.sessionId);
  const userId = useSelector(state => state.userId);
  const [message, setMessage] = useState('');
  const [verifyEmailError, setVerifyEmailError] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const studentEmailAddress = useSelector(state => state.studentEmailAddress);

  const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";

  const [showAlertModal, setShowAlertModal] = useState(false)
  const [highlightMsg, setHighlightMsg] = useState(false)
  const [headerMsg, setheaderMsg] = useState(false)
  const [subHeaderMsg, setSubHeaderMsg] = useState(false)
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [modelImage, setModelImage] = useState(false)

  //text-white md:text-[31px] md:leading-[38px] sm:text-[18px] sm:leading-[24px] text-[25px] leading-[31px]
  const SubTittleStylesModal = "font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px] font-normal sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]";
  const [signUpMailTyagainCount, setsignUpMailTyagainCount] = useState(1);

  const showCustomAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
    setShowAlertModal(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
  }

  useEffect(() => { }, [sessionId, userId])


  // On submit
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
    reset
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const GenerateEmailOtp = () => {
    setMessage("")
    clearErrors()
    const errorMessage = isEmailValid(email)
    if (errorMessage == 'empty') {
      setError('email', {
        type: 'manual',
        message: t('please _enter _student_email_address'),
      });
      // setMessage(t('please_enter_your_email_address'))
    } 
      if (errorMessage == 'not valid') {
      setError('email', {
        type: 'manual',
        message: t('please _enter _student_email_address'),
      });
      // setMessage(t('please_enter_a_valid_email_address'))
    } else if (errorMessage != 'empty' && errorMessage != 'not valid') {
      const isValidExtension = checkEmailExtension(email);
      if (isValidExtension) {
        createAccountWithStudentEmail(email);
      } else {
        setMessage(t('please_enter_student_email_address'));
        setValidEmail(false);
      }
    }
  };


  async function createAccountWithStudentEmail(email) {
    const deviceId = await retrieveData('updatedDeviceId')
    let headers = await GetApiHeadersWithOutBearer(deviceId);
    setIsLoading(true)
    var requestObject = {
      "applicationId": appConfigData.CLIENT_ID,
      "sEmail": email,
      "language": "en",
      "isLoginid": true
    }
    if (userEmail) {
      requestObject.isLoginid = false;
    }
    var response = await PostRequest(STUDENT_SIGNUP_EMAIL_ACCOUNT(userId), requestObject, headers);
    let responseObj = JSON.parse(response);
    if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) 
    {     
        const isPartnerUniversityStatus=responseObj.Response.isPartnerUniversity;
        setIsLoading(false); 
        if(isPartnerUniversityStatus){
        const sessionIdPayload = { sessionId: responseObj.Response.sessionId }
        dispatch({ type: 'SESSION_ID', payload: sessionIdPayload });
          openAccessCodewithemail();
        }else{
          openUniversitySelection();
        } 
    
      } else {
        setIsLoading(false);
        setVerifyEmailError('')
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        setMessage(displayText)
        // showCustomAlertModal(true, "Alert", displayText, "", true, true, t('login'), t('try_again'), true, '') 
      }  
  }



  const handleChange = async (event) => {
    setMessage('');
    clearErrors();
    const { name, value } = event.target;
    setEmail(value);
    try {
      await schema.validate({ [name]: value });
      setValidEmail(true);
      setEmail(value);
      dispatch({ type: 'STUDENT_EMAIL_VALUE', payload: { studentEmailAddress: value } });
    } catch (error) {
      setValidEmail(false);
    } 
  }; 

  const handleBack = () => {
    dispatch({ type: 'STUDENT_EMAIL_VALUE', payload: { studentEmailAddress: "" } });
    reset(); 
  clearErrors(); 
  setMessage('');
  backtoUserType(); 
  setError('');
  };

  useEffect(() => {
    if (studentEmailAddress) {
      setEmail(studentEmailAddress);
      setValue('email', studentEmailAddress);
      setValidEmail(true);
    }
  }, [setValue]);

  // const closeCustomAlertModal = () => {
  //   setBtn1Text('')
  //   setShowAlertModal(false);
  // }
  // const onAcptBtnClick = async () => {
  //   setShowAlertModal(false)
  //   if (btn1Text === t('login')) {
      

  //   }
  // }
  // const onCancelBtnClick = () => {
  //   setBtn1Text('')
  //   setShowAlertModal(false)
  // }
  return (
    <>
      <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap scroll-mt-0" onClick={(e)=> { e.stopPropagation();  
    e.preventDefault()}}>
        <Card
          heading=
          {
            <>
              <p className='font-serif-pro sm:text-white text-[var(--text-clr-secondary2)]
             lg:text-[31px] 
            md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] 
            md:leading-[33px] sm:leading-[29px] leading-[31px]'>{t('student_selction_heading1')} 
            <span className='sm:text-[var(--text-clr-secondary)]
               text-[var(--text-clr-secondary3)]'>{t('tellUS')}
               </span> 
            </p>  
              <span className='font-serif-pro sm:text-[var(--text-clr-secondary)]
               text-[var(--text-clr-secondary3)] lg:text-[31px]
             md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]'>
              {addLineBreaks(t('student_email_account_headin2'))}</span> 

            </>}
            
          // subheading={addLineBreaks(t('student_email_account_headin3'), SubTittleStylesModal)}
          header={true} footer={true} CardRadius={true} >
          {/* <p className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] lg:text-[20px] lg:w-[451px] md:text-[20px] sm:text-[12px] text-[20px] font-hanken-grotesk font-medium sm:mx-0 mx-4 text-[var(--text-clr-secondary3)]">
            {t('student_email_account_headin1-heading')}</p> */}


          <p className='sm:block hidden text-[#262B3A] font-serif-pro lg:leading-[36px] md:leading-[24px] 
            leading-[31px] font-[400] lg:text-[32px] md:text-[29px] sm:text-[22px] text-[25px]'>
            <span className='text-[var(--text-clr-secondary2)]'>
              {t('send_email_verify_code_great')}</span>
            <span> {t('enterStudentMail')}</span>
          </p>

          <p className='lg:text-[18px] md:text-[18px] sm:text-[12px] 
          text-[14px] lg:leading-7 md:leading-[22px] sm:leading-[22px] 
          leading-7 font-hanken-grotesk text-secondary-3
           font-medium mt-4'>{t('proudToPartner')}</p>

          <div className="flex flex-col  w-full h-246 mt-20">
            <div className="flex flex-col">
              <CustomInput
                name="email"
                label="Student Email Address"
                register={register}
                error={errors.email}
                type="text"
                textInputValue={email}
                onChange={handleChange}
                isValid={validEmail}
                autoFocus={true}
                value={email}
                onKeyDownFun={(event) => { if (event.key == "Enter") { event.preventDefault();GenerateEmailOtp(); } }}
              />
              {message &&
                <div className='mb-2'>
                  {displayErrorMessage(message)}
                </div>
              }
              <div className="flex justify-end items-center mt-20">
                <button
                  type="button"
                  className="font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
              text-[#2965FB] cursor-pointer flex items-center mr-10"
                  onClick={handleBack}
                  onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); } }}
                // Call handleContinue instead of openPhoneOTPModal directly
                >
                  {t('back')}
                </button>
                <button
                  type="button"
                  className={`font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]  flex items-center
                  ${validEmail ? 'text-[#2965FB] cursor-pointer' : 'text-[#94C3E8] cursor-none'}`}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      GenerateEmailOtp();
                    }
                  }}
                  onClick={GenerateEmailOtp}
                >
                  {t('continue')}
                </button>
              </div> 
              {/* <CustomAlertModel isOpen={showAlertModal}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
              /> */}
            </div>
          </div>
        </Card>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default StudentEmailVerfication;
