import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomInput from '../../../CustomComponents/FormInputs';
import { useTranslation } from 'react-i18next';
import { LuClock3 } from 'react-icons/lu';
import { displayErrorMessage } from '../../../../utils';
import Check from '../../../../assets/images/Check.png';
import Error from '../../../../assets/images/Error.png';
import TimerIcon from '../../../../assets/images/timer.png';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import logFirebaseEvents from '../../../LogFirebaseEvents';
const schema = yup.object().shape({
    verifyCode: yup
        .string()
        .required('otp_required')
        .matches(/^\d{6}$/, ('otp_required')),
});

const VerifyEmailCode = ({ CloseModalOnVerify, onCreatePasswordClick, errorMesssage, onClickEmailVerifyTryAgain }) => {
    const { t } = useTranslation();
    const [validEmail, setValidEmail] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [emailOtp, setemailOtp] = useState('');
    const [validOTP, setValidOTP] = useState(false);
    const [isValid, setvalidCode] = useState(false);
    const [verifyCode, setCode] = useState(''); // State variable to store the input value
    const [message, setMessage] = useState(errorMesssage != undefined ? errorMesssage : '');
    const inputRef = useRef(null);
    const submitButtonRef = useRef(null);

    useEffect(() => {
        if (verifyCode.length === 6 && submitButtonRef.current) {
            //     // Focus on the submit button when 6 digits are entered
            submitButtonRef.current.focus();
        }

    }, [verifyCode])

    const {
        register,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    // Validate email as email field changes
    const handleChange = async (event) => {
        const { name, value } = event.target;

        try {
            await schema.validate({ [name]: value });
            setValidEmail(true);
        } catch (error) {
            setValidEmail(false)
        }
    };

    const handleVerifyCodeChange = async (event) => {
        const { name, value } = event.target;
        setCode(value);
        try {
            await schema.validate({ [name]: value });
            setvalidCode(true);
            setCode(value);
            setMessage(''); // Clear the message when input value is valid
        } catch (error) {
            setvalidCode(false);
        }
    };

    const onSubmit = async (data) => {
        try {
        } catch (error) {
            // Handle error
        }
    };

    const verifyEmailOtpCode = () => {
        if (verifyCode.length == 6 && isValid !== false) {
            logFirebaseEvents("OTP_Screen", "verify_Email_OTP_Clicked", "verify_Email_OTP_Clicked", "","");
            CloseModalOnVerify();
            onCreatePasswordClick(verifyCode);
        }
        else {
            setMessage(t('otp_required'))
        }
    };
    const handleSubmit = (event) => {

        event.preventDefault();
        if (verifyCode.length == 6) {
            verifyEmailOtpCode();
        } else {
            setMessage(t('otp_required'))
        }

    };
    // useKeyDown(() => {
    //     verifyEmailCode();
    // }, ["Enter"]);

    return (
        <>
            <div className="flex flex-row sm:w-[322px] w-full items-center">
                <div className=" flex flex-row w-[322px] items-center">
                    <div className=" w-[45%]">
                        <CustomInput
                            name="verifyCode"
                            value={verifyCode}
                            label="6-digit code"
                            register={register}
                            error={errors.verifyCode}
                            type="6Code"
                            onChange={handleVerifyCodeChange}
                            isValid={isValid}
                            autoFocus={true}
                            ref={inputRef}
                        />
                    </div>
                    <div div className=" w-[45%] mb-4">
                        <button
                            type={'button'}
                            ref={submitButtonRef}
                            className="font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px] w-full bg-submit-btn hover:bg-submit-btn text-white py-2 px-4 font-medium lg:h-[60px] md:h-[50px] sm:h-[45px] h-[50px]"
                            onClick={verifyEmailOtpCode}
                            onKeyDown={(event) => {
                                if (event.key == "Enter") {
                                    event.preventDefault();
                                    setTimeout(() => {
                                        verifyEmailOtpCode()
                                    }, 1000);
                                 
                                    event.target.style.opacity = 0.8;
                                    setTimeout(() => {
                                        event.target.style.opacity = 1;
                                    }, 200);
                                }
                            }}
                        >
                            {t('verify')}
                        </button>
                    </div>
                    <div className="w-[10%] ml-4 mb-4">
                        {isValid === true ? (
                            <img src={Check} size={20} alt="Check" />
                        ) : (
                            isValid === false && verifyCode.length != 0 && <img src={Error} size={20} alt="Error" />
                        )}
                    </div>
                </div>
            </div>
            {message &&
                <div className='mb-2'>
                    {displayErrorMessage(message)}
                </div>
            }
            <div className=" flex flex-row gap-3 items-center md:mb-28 sm:mb-12 mb-28">
                <span className=" text-secondary">
                    <img src={TimerIcon} className="lg:w-[20px] lg:h-[20px] md:w-[17px] md:h-[17px] sm:w-[14px] sm:h-[14px] w-[14px] h-[14px]" />
                </span>
                <p className="font-hanken-grotesk lg:text-md md:text-[14px] sm:text-[12px] text-[14px] leading-7 text-secondary-3 font-medium">
                    {t("security_check_notification")}
                </p>
            </div>
            <p className="font-hanken-grotesk  md:text-[18px] sm:text-[12px] text-[16px]  leading-7 text-secondary-3 font-medium">
                {t("didnt_recieve_email")}{" "}
                <span
                    tabIndex={0}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            onClickEmailVerifyTryAgain();
                        }
                    }}
                    className="font-hanken-grotesk font-semibold sm:text-[14px] text-[16px] md:text-md text-[#2965FB]  cursor-pointer" onClick={onClickEmailVerifyTryAgain}>
                    {t("try_again")}<span className="dot"></span>
                </span> 

            </p>


            {/* <p className="font-hanken-grotesk text-md leading-7 text-secondary-3 font-medium">
                {t("security_check_subheading2")}{' '}
                <span className="font-hanken-grotesk font-bold text-md text-secondary-2 underline cursor-pointer" onClick={onClickEmailVerifyTryAgain}>
                    {t("try_again")}
                </span>
            </p> */}
        </>
    );
};

export default VerifyEmailCode;
