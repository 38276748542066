import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './en.json';
import translationFr from './fr.json';

// Add translations
const resources = {
  en: {
    translation: translationEn
  },
  fr: {
    translation: translationFr
  }
};

// Initialize i18next
i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false // React already escapes values
  }
});

export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};

export const translationGetters = {
  en: () => translationEn,
  fr: () => translationFr
};

export default i18n;