import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from 'react-redux';
import SocialLogins from "../OnBoard/Signup/SocailLogins";
import CreateAccount from "../OnBoard/Signup";
import CreatePassword from '../OnBoard/Signup/CreatePassword';
import PersonalInfo from "./Signup/PersonalInfo";
import TermsandConditions from "./Signup/TermsConditions";
import PersonalInfoSummery from "./Signup/PersonalInfoSummery";
import FinalRegistrationThroughSSN from "./Signup/finalRegistrationThroughSSN";
import PatriotAct from "./Signup/PatriotAct";
import logFirebaseEvents from "../LogFirebaseEvents";
import { checkEmailExtension } from "../../helpers/utils/Utils";
import StudentSelection from "./Signup/StudentSelection";
import StudentEmailVerfication from "./Signup/StudentEmailVerfication";
import { StudentUniversity } from "./Signup/StudentUniversity";
import StudentAccessCode from "./Signup/StudentAccessCode";
export const OnBoard = () => {
  const [activeComponent, setActiveComponent] = useState(null);
  const [signUpEmail, setsignUpEmail] = useState('');
  const [isPartner, setisPartner] = useState('');
  const [signUpPassword, setPassword] = useState('');
  const [personalInfo, setpersonalInfo] = useState({});
  const providerIdentifier = useSelector(state => state.providerIdentifier);
  const provider = useSelector(state => state.provider);

  useEffect(() => { 
    // Scroll to the top when the number of cards changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeComponent]);
 


  
  const renderComponent = () => {
    switch (activeComponent) {
      case "createAccount":
        return <CreateAccount 
          userEmail={signUpEmail}
          openCreatePasswordwithemail={createPasswordEmail} 
           onCreatePasswordClick={() => {
            debugger
            if (providerIdentifier != null && providerIdentifier != undefined && providerIdentifier != '') {
              handleSocailSignupClick("personal") 
            }  
          
      }} />;

      case "studentUserType":
         return <StudentSelection openYesImstudent={() => handleSocailSignupClick("studentEmail")} 
         openImNotStudent={() => {

           
          if (providerIdentifier != null && providerIdentifier != undefined && providerIdentifier != '') {
            handleSocailSignupClick("personal")
          } else{
            handleSocailSignupClick("createPassword"); 
          }
        
        }}/>;
      case "studentEmail":
         return <StudentEmailVerfication  backtoUserType={() => handleSocailSignupClick("studentUserType")} openAccessCodewithemail={() => handleSocailSignupClick("studentAccessCode")} openUniversitySelection={() => handleSocailSignupClick("universitySelection")} />;  
      case "studentAccessCode":
      return <StudentAccessCode  handleBackOpenEmail={() => handleSocailSignupClick("studentEmail")} handleOpenCreatePassword={() =>{
        if (providerIdentifier != null && providerIdentifier != undefined && providerIdentifier != '') {
          handleSocailSignupClick("personal")
        } else{
          handleSocailSignupClick("createPassword"); 
        }
        }} />;  
      case 'universitySelection':
      return <StudentUniversity  backtoEmail={() => handleSocailSignupClick("studentEmail")} openCreatPassword={() => {
        if (providerIdentifier != null && providerIdentifier != undefined && providerIdentifier != '') {
          handleSocailSignupClick("personal")
        } else{
          handleSocailSignupClick("createPassword"); 
        }
      }}/>
      case "createPassword":
        // return <CreatePassword email={signUpEmail}  openCreatePassword={createPasswordForEmail} openPatriotActClick={() => handleSocailSignupClick("patriotAct")} />;
        return <CreatePassword email={signUpEmail}  openCreatePassword={createPasswordForEmail} openPersonalInformationClick={() => handleSocailSignupClick("personal")} />;
      case "patriotAct" :
        return <PatriotAct openTermsandConditionsonClick={() => {handleSocailSignupClick("termsandconditions"); logFirebaseEvents("USA_PATRIOT _Act_Notice", "USA_Ptriot_Act_Notice_Continue_Clicked", "USA_Ptriot_Act_Notice_Continue_Clicked","", "");}}/>
      case "personal":
        return <PersonalInfo backToStudenEmailVerification={() => {
          if (providerIdentifier != null && providerIdentifier != undefined && providerIdentifier != '') {
            handleSocailSignupClick("studentAccessCode")
          } else{
            handleSocailSignupClick("createPassword"); 
          }
        }}  email={signUpEmail} password={signUpPassword} openPersonalInfoSummaryClick={() => handleSocailSignupClick("personalInfoSummary")} getDataFromPersonalInfo={getDataFromPersonalInfo}  openTermsandConditionsonClick={() => handleSocailSignupClick("termsandconditions")} handleOpenCreatePassword={() => handleSocailSignupClick("createPassword")}/>;
      case "termsandconditions":
        return <TermsandConditions  getDataFromPersonalInfo={getDataFromPersonalInfo} personalInfo={personalInfo} finalRegistrationClick={() => handleSocailSignupClick("finalRegistration")} />;
      case "personalInfoSummary":
        return <PersonalInfoSummery personalInfo={personalInfo}  openPatriotActClick={() => handleSocailSignupClick("patriotAct")}/>
      case "finalRegistration":
        return <FinalRegistrationThroughSSN />
      default:
        return null ;

    }
  };


  const createPasswordEmail = (email,isPartnerUniversity) => {
    setsignUpEmail(email); 
    const isValidExtension = checkEmailExtension(email); 
    if(!isValidExtension){   
      handleSocailSignupClick("studentUserType");
    }else{
      CkeckPartnerOrNot(isPartnerUniversity);
    }
  };

  const CkeckPartnerOrNot = (isPartner) => {
    debugger
    setisPartner(isPartner);  
    if(isPartner){
      handleSocailSignupClick("createPassword");
    }else{
      handleSocailSignupClick("universitySelection"); 
    }  
  }

  const createPasswordForEmail = (password) => { 
    setPassword(password);
  };

  const getDataFromPersonalInfo = (personalData) => {
    setpersonalInfo(personalData);
    
  };
    const handleSocailSignupClick = (componentName) => {
    setActiveComponent(componentName);
  };



  return (

    <Layout>
      {!activeComponent && (
        <SocialLogins onSocailSignupClick={(email) =>{
          setsignUpEmail(email)
          handleSocailSignupClick("createAccount")
        }} 
          onSocialAuthClick={(email) => {
          handleSocailSignupClick("createPassword")
          setsignUpEmail(email);  
        }} 
        />
      )}
   
        {renderComponent()}

    </Layout>

  );
};

export default OnBoard;
