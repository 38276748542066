import React, { useEffect } from "react";
import PreLogin from './PreLogin';
import About from '../OnBoard/About';
import Footer from '../Layout/Footer';
import Header from './Header';
import { useSelector, connect, useDispatch } from 'react-redux';
const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const sessionId = useSelector(state => state.sessionId);
  const isMobileVerifed = useSelector(state => state.isMobileVerifed);

  useEffect(() => {
    if (sessionId != null && sessionId != '' && isMobileVerifed == true) {
    }
  }, [sessionId, isMobileVerifed])

  if (sessionId != null && sessionId != '' && isMobileVerifed == true) {
    return (
      <>
        <Header savingEnrollClicked={()=>{}}/>
        <div className="scrollable-container-home loaded">
          {/* <main className="main-div"> */}
          <main className="">
            {children}
            <About />
          </main>
          <Footer />
        </div>
      </>
    );
  } else {
    return <PreLogin />;
  }
};

export default Layout;
