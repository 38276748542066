import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { AuthProvider } from './AuthContext';
import './index.css';
import App from './App';
import i18n from './locales/i18n';
import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc";
import app_env from './utils/app-config.json';
import { fetchConfigarationData } from './helpers/utils/Utils';
import SweetAlert from './components/CustomComponents/SwalAlert';
import Loader from './components/CustomComponents/Loader';
import { clearStorageExceptKey } from './utils';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import { ISDEV_ENV } from './network/URLConstants';
import warningImg from './assets/images/warning.png'
import { Navigate } from 'react-router';
import { t } from 'i18next';

const appConfigData = await fetchConfigarationData();

//global.isLoggedIn = false;

if (!ISDEV_ENV) {
  // Disable console logs in production
  console.log = function () { };
  console.warn = function () { };
  console.error = function () { };
}
const configuration = {
  client_id: appConfigData.CLIENT_ID,
  redirect_uri: appConfigData.REDIRECT_URI,
  post_logout_redirect_uri: appConfigData.POST_LOGOUT_REDIRECT_URI,
  silent_redirect_uri: appConfigData.SILENT_REDIRECT_URI,
  scope: appConfigData.TWO_FACT_SCOPE, // offline_access scope allow your client to retrieve the refresh_token
  authority: appConfigData.AUTH_URL,
  extras: { usc: appConfigData.USER_SCOPE, response_mode: "query" },
  token_request_extras: { client_secret: appConfigData.CLIENT_SECRET},
  monitor_session: false,
  // service_worker_relative_url: "/OidcServiceWorker.js",
  refresh_time_before_tokens_expiration_in_second: appConfigData.REFRESH_TIME_BEFORE_TOKENS_EXPIRATION_IN_SECOND,
  service_worker_only: true,
  storage: sessionStorage, //if it not working need to change to local storage so user can access local storage
  token_renew_mode: 'access_token_invalid',
  logout_tokens_to_invalidate: []
};

const Authenticating = () => { <Loading /> };
const Loading = () => { <Loading /> };

const handleModalOK = () => {
  const deviceIdKey = 'updatedDeviceId';
  clearStorageExceptKey(sessionStorage, deviceIdKey);
  clearStorageExceptKey(localStorage, deviceIdKey);
  window.location.href = window.location.origin
};

const CallBackSuccess = () => <Loading />;
const AuthenticatingError = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center scrollable-container-home loaded h-auto w-full"
      style={{ zIndex: 100, minHeight: '600px' }}>
      <div className="modal-overlay fixed inset-0 bg-[#262B3A] opacity-90 transition-opacity"
        style={{ minHeight: '600px' }} />

      <div
        // ref={modalRef}
        className={
          `modal-container bg-card sm:rounded-tl-card-tr sm:rounded-br-card-bl md:w-[778px] sm:w-[466px] w-[100%] mx-auto shadow-lg z-50 transition-all duration-300  ease-in-out `}>
        <div className='w-full h-full'>
          <div className='flex flex-col items-center self-center justify-between h-full w-full '>
            <div className='flex justify-end items-end w-11/12 lg:mt-10 md:mt-8 sm:mt-5 mt-10'
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key == "Enter") {
                  event.preventDefault();
                  handleModalOK()
                }
              }}
              onClick={(event) => {
                handleModalOK();
              }}>
              <button className="modal-close text-gray-500 hover:text-gray-800 cursor-pointer" >
                <svg xmlns="http://www.w3.org/2000/svg"
                  className="h-[1rem] w-[1rem] opacity-[60%]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor" >
                  <path strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
              </button>
            </div>

            <div className='pt-5 w-3/5'>
              <span className='font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]'>{t('oops')} </span>
              <span className='font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal'>{t('unfroseen_error')}</span>
            </div>
            <div className='w-full mt-5 flex flex-col items-center'>
              <img
                src={warningImg}
                // alt="Image"
                className="lg:w-[120px] lg:h-[120px] md:w-[100px] md:h-[100px] sm:w-[92px] sm:h-[92px] w-[120px] h-[120px]"
              />
            </div>
            <div className='lg:pt-20 md:pt-18 sm:pt-10 lg:mb-20 md:mb-20 sm:mb-8 mb-20 pt-16 w-3/5 '>
              <button className='lg:text-[16px] md:text-[14px] sm:text-[13px] text-[14px] w-full border-2 border-[#2965FB] lg:h-[60px] md:h-[55px] sm:h-[50px] h-[55px] text-white font-hanken-grotesk font-bold leading-[16px] disabled:opacity-30'
                style={{ backgroundColor: '#2965FB' }}
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleModalOK()
                  }
                }}
                onClick={(event) => { event.target.style.opacity = 0.8; handleModalOK(); setTimeout(() => { event.target.style.opacity = 1; }, 200); }}>OK</button>
            </div>
          </div>

        </div>

      </div>
    </div >
  );
}


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
      })
      .catch((error) => {
      });
  });
}

createRoot(document.getElementById('root')).render(
  <OidcProvider
    authenticatingComponent={Authenticating}
    onLogoutFromAnotherTab={handleModalOK}
    authenticatingErrorComponent={AuthenticatingError}
    sessionLostComponent={AuthenticatingError}
    loadingComponent={Loading}
    serviceWorkerNotSupportedComponent={AuthenticatingError}
    callbackSuccessComponent={CallBackSuccess}
    configuration={configuration}
    onSessionLost={()=>console.log('sessionLost')}
  // monitor_session={true}
  >    
    <AuthProvider>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </AuthProvider>
  </OidcProvider>
);