import React, { useState } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import Next from '../assets/images/Caret.png';
import Downarrow from '../assets/images/white_down_arrow.png';
import UpArrow from '../assets/images/upArrow.png';
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import RightArrow from '../assets/images/right_dropdown_arrow.png';
import Copy_Text from '../assets/images/Copy Text.png'
import MblCopytext from '../assets/images/mblCopytext.png'
import RadialFill from '../assets/images/Checkbox-blue.png'
import RadialNull from '../assets/images/Checkbox-light.png'
import { t } from "i18next";
import Oops from '../assets/images/Oops.png';
import { GetApiHeadersWithBearer, GetApiHeadersWithBearerForSupport, GetRequest, PostRequest, getRequestfreshDeskDomian } from '../network/NetworkUtils';
import { ACCEPT_E_SIGN_AGGREMENT, AGREEMENTS, COVERED_BY_KUDZU, GET_ESIGN_AGREEMENT, GET_ESIGN_STATUS, GET_USER_PORFIE_INFO, NOTIF_COVERED_BY_KUDZU_OPT_IN, NOTIF_COVERED_BY_KUDZU_TERMS, NOTIF_MANAGE_DIRECT_DEPOSIT, NOTIF_MANAGE_SAVINGS_HABIT, NOTIF_PAYPERKS, NOTIF_PERSONAL_INFO, NOTIF_SPENDSENSE_ALERT, NOTIF_SPENDSENSE_ALERT2, SAVINGS_HABIT, SPENDING_ALERT, STATUS_SUCCESS_CODE, VALIDATE_AUTH_USER } from "../network/URLConstants";
import Check from '../assets/images/Checkwhite.png';
import Addalerts from '../assets/images/add_alerts.png';
import lock from '../assets/images/lock.png';
import CoveredByKudzuImg from '../assets/images/illo-covered-by-kudzu.png';
import SavingsHabitImg from '../assets/images/illo-savings-habits.png';
import { NOTIF_CARD_SERVICES, NOTIF_CUSTOMER_CARE, NOTIF_FEES, NOTIF_STATEMENTS, NOTIF_TRANSACTIONS } from '../network/URLConstants'
import { CHECKING_CARD_CODE } from "../helpers/utils/Utils";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import logFirebaseEvents from "../components/LogFirebaseEvents";



export async function getDeviceId() {
  let deviceId = uuidv4();
  return deviceId;
}

export const fetchConfigarationData = async () => {
  const response = await fetch(`${process.env.PUBLIC_URL}/app-config.json`);
  const data = await response.json();
  return data;
};


export async function storeData(key, value) {
  var isStored = false;
  try {
    localStorage.setItem(key, JSON.stringify(value));
    isStored = true;
    return isStored;
  } catch (error) {
    return isStored;
  }
}
export const isEmailValid = (email) => {
  if (!email || email.trim() === '') {
    return "empty";
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return "not valid"
  }
  return "valid";
};
export const validateMobileNumber = (mobileNumber) => {
  const mobilePattern = /^[0-9]{10}$/;
  if (mobileNumber.trim() === '') {
    return ('Mobile number field is empty');
  } else if (mobilePattern.test(mobileNumber)) {
    return ('Valid mobile number');
  } else {
    return ('Invalid mobile number');
  }
};

export async function validateAuthorizedUser(firstName, lastName, ssn, dateOfBirth, line1, line2, city, state, zipCode, countryCode, mobile, email, cardKey, accessToken) {

  const deviceId = await retrieveData('updatedDeviceId')
  const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
  const url = VALIDATE_AUTH_USER.replace('{CARD_KEY}', cardKey)
  var object = {
    "firstName": firstName,
    "lastName": lastName,
    "dateOfBirth": dateOfBirth
  }

  if (ssn)
    object.ssn = ssn.replace("-", "").replace("-", "")
  if (line1)
    object.line1 = line1
  if (line2)
    object.line2 = line2
  if (city)
    object.city = city
  if (state)
    object.state = state
  if (zipCode)
    object.zipCode = zipCode
  if (countryCode)
    object.countryCode = countryCode
  if (mobile)
    object.mobile = mobile.replace("-", "").replace("-", "")
  if (email)
    object.email = email

  const response = await PostRequest(url, object, headers)

  return response
}


export async function storeDataInRedux(key, value) {
  this.props.dispatch({ type: key, payload: value });
}


export async function retrieveData(key) {
  const value = await localStorage.getItem(key);
  if (value !== null) {
    return JSON.parse(value);
  }
  else
    return null;
}


export const autoFocusFunction = (fieldArray, getValues, setFocus) => {
  const emptyField = fieldArray.find((item) => !getValues(item))
  setFocus(emptyField);

}



export const generateRegexExpression = (policy) => {
  let pattern = "";
  if (policy?.UppercaseLength > 0) pattern += `(?=(?:.*?[A-Z]){${policy.UppercaseLength},})`;
  if (policy?.LowercaseLength > 0) pattern += `(?=(?:.*?[a-z]){${policy.LowercaseLength},})`;
  if (policy?.NonAlphaLength > 0) pattern += `(?=(?:.*?[!@#$%^&*()_+-={}\\[\\]\\|;:'",.<>/?\`~]){${policy.NonAlphaLength},})`;
  if (policy?.NumericLength > 0) pattern += `(?=(?:.*?\\d){${policy.NumericLength},})`;
  if (policy?.AlphaLength > 0) pattern += `(?=(?:.*?[A-Za-z]){${policy.AlphaLength},})`;
  pattern = `^${pattern}.{${policy?.MinimumLength},${policy?.MaximumLength}}$`;
  return new RegExp(pattern);
}


export const generateRegexExpression1 = (criteria, policy) => {
  let pattern = "";
  switch (criteria) {
    case "Uppercase":
      pattern += `(?=(?:.*?[A-Z]){${policy.UppercaseLength},})`;
      break;
    case "Lowercase":
      pattern += `(?=(?:.*?[a-z]){${policy.LowercaseLength},})`;
      break;
    case "NonAlpha":
      pattern += `(?=(?:.*?[!@#$%^&*()_+-={}\\[\\]\\|;:'",.<>/?\`~]){${policy.NonAlphaLength},})`;
      break;
    case "Numeric":
      pattern += `(?=(?:.*?\\d){${policy.NumericLength},})`;
      break;
    case "Alpha":
      pattern += `(?=(?:.*?[A-Za-z]){${policy.AlphaLength},})`;
      break;
    case "MinMax":
      pattern += `.{${policy?.MinimumLength - 1},${policy?.MaximumLength}}`
      break;

    default:
      break;
  }

  pattern = `^${pattern}.+$`;
  return new RegExp(pattern);
}


export default function LineBreaks(element, classDefined) {
  const lines = element.split("\n");
  return lines.map((line, index) => <p className={classDefined} key={index} >{line}</p>);
}

export function LineBreaksSpan(element, classDefined) {
  const lines = element.split("\n");
  return lines.map((line, index) => {
    if (index == 0) {
      return <span className={classDefined} key={index}>{line}</span>;
    } else {
      return <p className={classDefined} key={index}>{line}</p>;
    }
  });
}

export function addLineBreaks(element) {
  const lines = element.split("\n");
  return lines.map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
}

export function createSuperscript(text, superscriptArr) {
  if (superscriptArr.length === 1) return;

  createSuperscript(superscriptArr[0], superscriptArr.slice(1, superscriptArr.length))

  return <span>{text}<sup>{superscriptArr[0]}</sup></span>;
}


export function isBase64(str) {
  // Remove any white spaces, which might be present in some Base64 encodings
  str = str.replace(/\s/g, '');

  // Check if the string consists of valid Base64 characters
  return /^[A-Za-z0-9+/=]+$/.test(str);
}

export const displayErrorMessage = (errorMessage) => {
  return (
    <div className="flex sm:mx-0 flex-row gap-4 justify-start">
      <img src={Oops} alt="oops" className="md:w-[20px] md:h-[20px] sm:w-[14px]
        sm:h-[14px] w-[15px] h-[15px] mt-1 sm:mt-0 md:mt-1  self-start" />
      <p className="font-hanken-grotesk font-[500] text-[16px]
       leading-[24px] md:text-[18px] md:leading-[28px] sm:text-[12px] 
       sm:leading-[18px] text-secondary-3 ">
        <span className="text-[#30BC89] font-[600]
        lg:font-[600]"> Oops! </span>{errorMessage}</p>
    </div>
  )
}
export const displaySignupErrorMessage = (errorMessage, holdOn) => {
  return (
    <div className="flex sm:mx-0 flex-row gap-4 justify-start">
      <img src={Oops} alt="oops" className="md:w-[20px] md:h-[20px] sm:w-[14px]
        sm:h-[14px] w-[15px] h-[15px] mt-1 sm:mt-0 md:mt-1 self-start" />
      <p className="font-hanken-grotesk font-[500] text-[16px]
       leading-[24px] md:text-[18px] md:leading-[28px] sm:text-[12px] 
       sm:leading-[18px] text-secondary-3 ">
        <span className="text-[#30BC89] font-[600]
        lg:font-[600]"> {holdOn ? 'Hold on!' : 'Oops!'}  </span>{errorMessage}</p>
    </div>
  )
}


// export const displaySignupErrorMessage = (errorMessage, holdOn) => {
//   return (
//     <div className="flex sm:mx-0 mx-4">
//       <img src={Oops} alt="oops" className="md:w-[20px] md:h-[20px] sm:w-[14px]
//         sm:h-[14px] w-[13px] h-[13px] mt-1" />
//       <p className="font-hanken-grotesk font-[500] text-[16px]
//        leading-[20px] sm:text-[12px] md:text-[14px] md:leading-[18px] lg:text-[14px] 
//        lg:leading-[22px] text-secondary-3 ">
//         <span className="text-[#30BC89] font-[600] ml-2 
//         lg:font-[600]"> {holdOn ? 'Hold on!' : 'Oops!'} </span>{errorMessage}</p>
//     </div>
//   )
// }
export const personalFormatPhoneNumber = (phoneNumber) => {
  if (phoneNumber === '') {
    return '';
  }
  // Use regular expressions to format the phone number
  // const formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  const formattedNumber = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;

  return formattedNumber;
}

export const DisplaySSNMessage = () => { // Rename the function to start with an uppercase letter
  // const { t } = useTranslation();

  return (
    <div className="flex sm:mx-0 mx-4">
      <img src={Oops} alt="oops" className="lg:w-[14px] lg:h-[14px] sm:w-[14px]
        sm:h-[14px] w-[13px] h-[13px] mt-1" />
      <p className="font-hanken-grotesk font-[500] md:w-[432px] text-[14px] leading-[20px] md:text-[14px] md:leading-[18px] lg:text-[14px] lg:leading-[22px] text-secondary-3 ">
        <span className="text-[#30BC89] font-[600] ml-2 
        lg:font-[600] ">{t('please_check')} </span>
        {t('ssn_note')}
      </p>
    </div>
  );
}
export const ssnMessageNote = (text) => { // Rename the function to start with an uppercase letter


  return (
    <div className="flex sm:mx-0 mx-4">
      <img src={Oops} alt="oops" className="lg:w-[14px] lg:h-[14px] sm:w-[14px]
        sm:h-[14px] w-[13px] h-[13px] mt-1" />
      <p className="font-hanken-grotesk font-[500] md:w-[432px] text-[14px] leading-[20px] md:text-[14px] md:leading-[18px] lg:text-[14px] lg:leading-[22px] text-secondary-3 ">
        <span className="text-[#30BC89] font-[600] ml-2 
        lg:font-[600] ">{t('please_check')} </span>
        {text}
      </p>
    </div>
  );
}
export const displaySuccessMessage = (errorMessage) => {
  return (
    <div className="flex flex-row gap-3 md:w-[314px] sm:w-[213px]">
      <span className="text-[#30BC89] py-1">
        <BsExclamationCircle className="md:w-[20px] w-[14px] md:h-[18px] h-[20px]" /></span>
      <p className="font-hanken-grotesk sm:text-[12px] md:text-[18px] text-[15px] 
      leading-[28px] text-secondary-3 font-[500]">
        <span className="text-[#30BC89]">Success! </span>{errorMessage}</p>
    </div>
  )
}

export const CheckingAndSavingsHeader = ({ cardText, amount, cardNum, handleRedirect, cardDetails, accountNumber, routingNumber, handleCopyClick, handleCopyRoutingNumberClick, cardOptionsList, handleCardAction }) => {
  const isMobile = window.innerWidth < 768

  const navigate = useNavigate();
  const location = useLocation();
  // const showAccountDetails = false;
  const [showAccountDetails, setShowAccountDetails] = useState(false);

  const handleshowAccountDetails = () => {
    //  showAccountDetails = !showAccountDetails // Call the handleTryAgain function passed as a prop
    setShowAccountDetails(!showAccountDetails)
  };

  const handleClick = () => {
    if (location.pathname === '/home') {
      // if (cardText === CHECKING || cardText === SPENDING) {
      if (cardDetails.AccountTypeBit63 === CHECKING_CARD_CODE) {
        navigate('/' + SPENDING + '');
      } else {
        navigate('/' + SAVINGS + '');
      }
    }
  };

  const maskedCardNumber = (
    <span className="inline-flex items-center">
      <span className="w-5 rounded-full flex items-center justify-center relative top-[2px]">
        <span className="text-white font-bold">••</span>
      </span>
      <span style={{ fontFamily: 'Space Mono' }}>{cardNum.slice(4)}</span>
    </span>
  );
  //split the string

  const stringValue = amount.toString();
  const parts = stringValue.split('.');

  const beforeDecimal = parts[0]; // "100"
  const afterDecimal = parts[1] || '0'; // "5"

  let formattedValue = `${beforeDecimal}.`;

  // Add zeros based on the decimal part count
  if (afterDecimal.length === 1) {
    formattedValue += `${afterDecimal}0`; // "100.50"
  } else if (afterDecimal.length === 0) {
    formattedValue += '00'; // "100.00"
  } else {
    formattedValue += afterDecimal; // "100.5" (if there are more than one decimal places)
  }


  const balnceSplit = formattedValue.toString().split('.');
  const { t } = useTranslation();

  const handleNextIconClick = () => {
    // debugger;
    handleClick();
  };
  ///MBL Quick Action Styles
  const CardButton = 'w-[48%] h-[40px] text-[#30BC89] bg-white font-hanken-grotesk lg:text-[16px] md:text-[14px] sm:text-[11px] md:leading-[39.71px] sm:leading-[25.8px] md:h-[50px] sm:h-[35px] inline-block rounded-full border-[3px] border-white text-xs font-semibold leading-normal transition duration-150 ease-in-out';
  const currentPath = window.location.pathname;

  return (
    <div className='sm:flex block justify-between flex-nowrap items-center md:my-[35px]
     sm:my-[20px] my-[20px] md:mx-[4%] sm:ml-[6%] sm:mr-[8%] mr-[2.5rem] ml-[2.5rem]'>

      <div className='lg:text-[26px] md:text-[26px] sm:text-[15px] text-[16px] md:leading-[36px]
      sm:leading-[23px] leading-[24px] text-[#FFFFFF] font-[500] md:font-[500] sm:font-[600] font-hanken-grotesk capitalize'>
        {isMobile? cardText.slice(0, 13) : cardText.slice(0, 23)}
        <span className='md:text-[14px] sm:text-[9px] text-[10px] ml-2 md:leading-[14px] 
        sm:leading[6.93px] leading-[24px] tracking-[2px] font-[700]'>{maskedCardNumber}</span></div>
      <div className='flex items-center justify-between sm:my-[0px] mt-[30px]'>
        <button type="button" className="lg:w-[165px] md:w-[150px] sm:w-[100px] w-[96px] 
                      lg:h-[55px] md:h-[50px] sm:h-[34px] h-[34px] 
                      rounded-full sm:border-[1.5px] md:border-[2.5px] border-[2.5px] border-[#fff] 
                        lg:text-[16px] md:text-[18px] sm:text-[11px] text-[14px]
                       md:leading-[46.09px] sm:leading-[22.8px] 
                        leading-[26px]  text-[#ffff] font-[700] 
                        font-hanken-grotesk sm:static absolute right-[35px] top-[22px]"
          data-te-ripple-init
          onClick={() => {
            if(currentPath === "/Savings"){
            }
            else if(currentPath === "/home"){
            logFirebaseEvents("Home", "Add_Money_Clicked", "Add_Money_Clicked", "");
            }
            else{
            logFirebaseEvents("Spendings", "Add_Money_Clicked", "Add_Money_Clicked", "");
            }
            global.selectedAccountServiceCardKey = cardDetails.CardKey
            navigate('/AccountServices/Spending/TransferMoney');
          }}> {t('addMoneyTittle')} </button>

        <div className='flex md:font-[500] sm:font-[400] font-[500]
         md:leading-[56px] sm:leading-[28.38px] leading-[37.49px]  text-[#FFFFFF] sm:ml-4 
         ml-0 font-hanken-grotesk'>
          <div className="lg:text-[48px] md:text-[40px] sm:text-[23px] text-[44px]">${balnceSplit[0]}</div>
          <div className="lg:text-[43px] md:text-[30px] sm:text-[20px] text-[35px] lg:mt-[-12px] md:mt-[-7px] sm:mt-[-5px] mt-[-11px] md:font-[500] font-[300]">.</div>
          <div className='lg:text-[32px] md:text-[23px] sm:text-[15px] text-[22px] lg:mt-[-6px] md:mt-[-4px] sm:mt-[-3px] mt-[-5px] md:font-[500] font-[300]
           font-hanken-grotesk'>{balnceSplit[1]}</div>
        </div>


        <div className='ml-6'>
          {location.pathname === '/home' ? (
            <img
              src={Next}
              alt="next"
              className="md:w-[auto] sm:w-[12px] cursor-pointer"
              onClick={handleNextIconClick}
            />
          ) : (
            <>
              <div className="flex items-center gap-[7px]" onClick={handleshowAccountDetails}>
                <span className="sm:hidden block text-[12px] text-white
               font-hanken-grotesk">{t('more_actions')}</span>
                <div className="sm:hidden block">
                  {showAccountDetails ? <img src={UpArrow} alt="UpArrow" className="w-[auto] cursor-pointer" /> :
                    <img src={Downarrow} alt="Downarrow" className="w-[auto] cursor-pointer" />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="sm:hidden block">
        {showAccountDetails &&
          <>
            <AccountDetailsHeder
              mblaccountDetails={true}
              AccountNo={accountNumber}
              routingNo={routingNumber}
              handleCopyClick={handleCopyClick}
              handleCopyRoutingNumberClick={handleCopyRoutingNumberClick}
              contentBetween={true}
              topBorder={false}
              bottomBorder={true}
              isCopyblack={true} />
            {currentPath === '/' + SPENDING + '' && (
              <div className='h-auto'>
                <div className='px-2 pb-4 flex justify-between flex-row gap-y-4 w-full flex-wrap'>
                  {cardOptionsList != [] &&
                    cardOptionsList.map((item, index) => (
                      <button
                        type="button"
                        className={CardButton}
                        data-te-ripple-init
                        onClick={() => { handleCardAction(item.name) }}
                      >
                        {item.name}
                      </button>
                    ))
                  }
                </div>
              </div>
            )}
          </>
        }
      </div>

    </div>
  );
};

///for home page MM-DD-YYYY
export const formatDateToMMDDYYYY = (dateString, separator) => {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  const result = separator ? `${month}${separator}${day}${separator}${year}` : `${month}-${day}-${year}`;

  return result;

};
export const formatDateToMMDDYY = (dateString, separator) => {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear() % 100;
  const result = separator ? `${month}${separator}${day}${separator}${year}` : `${month}-${day}-${year}`;

  return result;

};

export const formatDateToMonthDayYear = (inputDateStr, isDayOfWeek = true) => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const inputDate = new Date(inputDateStr);
  const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][inputDate.getDay()];
  const month = months[inputDate.getMonth()];
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();

  const result = isDayOfWeek ? `${dayOfWeek}, ${month} ${day}, ${year}` : `${month} ${day}, ${year}`

  return result;
}

export const dateComparison = (inputDate) => {

  // const inputDateObj = new Date(new Date().toUTCString().substr(0, 25));
  const currentDate = new Date();
  const inputDateObj = new Date(inputDate);
  console.log("currentDate", currentDate)
  console.log("inputDateObj", inputDateObj)
  console.log("inputDate", inputDate)
  // Set the time to midnight to compare only dates
  currentDate.setHours(0, 0, 0, 0);
  inputDateObj.setHours(0, 0, 0, 0);
  // if(moment(`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`).isSame(`${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`)){
  //   return "Today";
  // }
  // else if(moment(`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`).isBefore(`${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`)){
  //   return "Past";
  // }
  // else{
  //   return "Future"
  // }
  console.log("currentDate", currentDate.toDateString())
  console.log("inputDateObj", inputDateObj.toDateString())

  if (inputDateObj.toDateString() === currentDate.toDateString()) {
    return "Today";
  } else if (inputDateObj > currentDate) {
    return "Future";
  } else {
    return "Past";
  }
}




export const parseDateFromString = (dateString) => {
  const [month, day, year] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  return date;
};

// HH:MM AM/PM
export const getTimeFromDate = (dateString) => {
  try {
    const dateObj = new Date(dateString);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  } catch (error) {
    return null;
  }
}

// HH:MM AM/PM
export const convertToTimeFormat = (timeString) => {
  try {
    const [hours, minutes] = timeString.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  } catch (error) {
    return null;
  }
}

export const convertToMinutesSinceMidnight = (timeString) => {
  const [time, ampm] = timeString.split(" ");
  const [hours, minutes] = time.split(":").map(Number);
  return hours % 12 * 60 + minutes + (ampm.toLowerCase() === "pm" ? 12 * 60 : 0);
}

export const convertFromMinutesSinceMidnight = (minutes) => {
  const hours = Math.floor(minutes / 60) % 12 || 12;
  const minutesOfDay = minutes % 60;
  const ampm = minutes < 720 ? "AM" : "PM";
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutesOfDay.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const convertTo24HourFormat = (timeString) => {
  const [time, ampm] = timeString.split(" ");
  const [hours, minutes] = time.split(":").map(Number);

  let hours24 = hours % 12; // Convert to 24-hour format
  if (ampm.toLowerCase() === "pm") {
    hours24 += 12; // Add 12 hours for PM times (except 12 PM)
  }

  const formattedHours = hours24.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export const compareTime = (time1, time2) => {
  const minutes1 = convertToMinutesSinceMidnight(time1);
  const minutes2 = convertToMinutesSinceMidnight(time2);

  if (minutes1 < minutes2) {
    return -1; // time1 is before time2
  } else if (minutes1 > minutes2) {
    return 1; // time1 is after time2
  } else {
    return 0; // time1 is equal to time2
  }
}
export const timeDiffernce = (time1, time2) => {
  const minutes1 = convertToMinutesSinceMidnight(time1);
  const minutes2 = convertToMinutesSinceMidnight(time2);

  if (minutes1 < minutes2) {
    return (minutes2 - minutes1)
  } else {
    return (minutes1 - minutes2)
  }
}

export const convertTimestamp = (timestamp, isHome = false) => {
  // Convert the input timestamp to a Date object
  const givenTime = new Date(timestamp);

  // Get the current time in UTC
  // "2023-10-25T23:59:00"
  const currentDate = new Date();
  const compareDateTo = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}T23:59:00`
  const currentTime = new Date(compareDateTo);

  // Calculate the time difference between the given time and the current time in milliseconds
  const timeDifference = currentTime.getTime() - givenTime.getTime();

  // Calculate the days and weeks difference
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
  const weeksDifference = Math.floor(daysDifference / 7);

  // Get the time part of the given timestamp (without date)
  const timePart = givenTime.toLocaleTimeString("en-US", {
    hour12: true,
    hourCycle: "h24",
    hour: "numeric",
    minute: "numeric",
    // timeZone: "America/New_York", // Replace with your desired time zone
  });

  if (isHome) {
    if (weeksDifference < 1) return "lessThan7days";
  }

  if (weeksDifference === 1) {
    return "1 week ago";
  } else if (weeksDifference > 1) {
    return weeksDifference + " weeks ago";
  } else if (daysDifference === 1) {
    return "Yesterday at " + timePart;
  } else if (daysDifference > 1) {
    return daysDifference + " days ago";
  } else {
    return "Today at " + timePart + " ET";
  }
}

export const handleAmountInputLimitTo2decimal = (amount) => {


  const formattedAmount = amount.replace(/[^\d.]/g, '') // Limit to 10 characters (optional, adjust as needed)
    .replace(/^(\d+\.?)(.*)$/, ($0, $1, $2) => // Limit decimal places
    {
      console.log($0, $1, $2)
      if (!$2) return `${$1}`
      else return `${$1}${$2.replaceAll('.', '').slice(0, 2)}`
    }
    )

  return formattedAmount;
}


export const createTinkUrl = (baseUrl, clientId, redirectUri) => {
  const url = new URL(baseUrl);
  url.searchParams.append('client_id', clientId);
  url.searchParams.append('redirect_uri', redirectUri);
  url.searchParams.append('market', 'US');
  url.searchParams.append('locale', 'en_US');
  url.searchParams.append('iframe', true);
  url.searchParams.append('refreshable_items', 'IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS');
  console.log("url----", url.href)
  return url.href;

}

export const copyToClipboard = (text, setCopiedState) => {
  //debugger;
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};


const sidemenuItem = 'cursor-pointer font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px] text-[#FEFAEE]';
export const ClimbMenuSidebar = () => {
  const navigate = useNavigate();
  return (
    <div className='lg:w-[210px] md:w-[270px] sm:w-[163px] w-full sm:mx-0 ml-[2.5rem]'>

      <p className="text-[#94C3E8] font-normal
       lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px]
  leading-[30px] md:leading-[33px] lg:leading-[38px] font-serif-pro border-b-[2.5px]
   border-[#4B70B9]  lg:pb-14 md:pb-[50px] sm:pb-[2.6rem] pb-[2.6rem] mb-10  border-opacity-[50%] w-[80%] sm:w-full">



        {t("Choose_a")} <span className="text-white"> {t("Climb")} </span>
        {t("feature_to_get_started")}</p>

      <div className='flex flex-col md:gap-10 gap-8 sm:pb-0 pb-10'>
        <p className={sidemenuItem}
          onClick={() => navigate("/Climb/ClimbSettings")}>
          {/* {t("Manage_Settings")} */}
          {t('Settings')}
        </p>
      </div>
    </div>

  )
}
export const ClimbManageSettingsSidebar = ({ indexBtn, ManageSettingsbuttonLabels, count, headingMessage, isShow = true, navigateClicked }) => {
  // const climbFeatures = useSelector(state => state.climbFeatures);
  // const enabledClimbings = climbFeatures.filter((response) => response.enabled === true);
  // const isCoveredByKudzuOptedIn = enabledClimbings.some(item => item.code === 'CoveredByKudzu' && item.optedIn)

  // debugger
  const navigate = useNavigate();
  const location = useLocation();



  const normalBtn = "md:w-[210px] sm:w-[166px] w-full lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] text-[#94C3E8] font-hanken-grotesk font-[700] h-[50px] border-[#94C3E8] border-[2.5px] border-opacity-[25%] rounded-[36px]"
  const highlightBtn = "md:w-[210px] sm:w-[166px] w-full lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] font-hanken-grotesk font-[700] h-[50px] border-[#4B70B9] border-[2.5px] border-opacity-[25%] rounded-[36px] bg-[#4B70B9] text-white"

  return (
    <>
      <div className=" flex  flex-col justify-center items-center w-full sm:flex-none sm:justify-normal sm:item-start sm:w-auto">
        <div className='lg:w-[282px] md:w-[250px] sm:w-[163px] w-[264px]'>
          {/* <div className='lg:w-[282px] md:w-[250px] sm:w-[163px] w-[264px] sm:mx-0 ml-[2.5rem]'> */}
          <p className="text-[#FFFF] font-normal
          lg:text-[32px] md:text-[25px] sm:text-[21px] text-[20px] 
          leading-[30px] md:leading-[33px] lg:leading-[38px] font-serif-pro border-b-[2.5px]
          border-[#4B70B9] lg:pb-14 md:pb-[50px] sm:pb-[2.6rem] pb-[2.6rem] mb-10  border-opacity-[50%]">
            {headingMessage ? (
              t('climbSettings')
            ) : (
              (location.pathname.toLowerCase() == '/climb/managesavingshabit' || location.pathname.toLowerCase().includes('climb/savingshabit/edithabit')) ? (
                t('active_alerts_savings_habit').replace("{ACTIVE_ALERTS}", (count !== undefined && isShow) ? count : 0)
              ) : (
                t('active_alerts').replace("{ACTIVE_ALERTS}", (count !== undefined && isShow) ? count : 0)
              )


            )}
          </p>
          <div className="pb-6 flex flex-col gap-6">
            {ManageSettingsbuttonLabels.map((item, index) => (
              <button
                key={index}
                className={`${indexBtn === index + 1 ? highlightBtn : normalBtn}`}
                // ${((item.label == 'Covered by Kudzu' && isCoveredByKudzuOptedIn) ? 'opacity-50 cursor-not-allowed' : '')}
                // onClick={() => {navigate(item.link)}}
                onClick={() => navigateClicked(item)}
              // disabled={((item.label == 'Covered by Kudzu' && isCoveredByKudzuOptedIn) ? true : false)}
              >
                {item.label}
              </button>
            ))}
          </div>

          <div className="bg-[#4B70B9] h-[2px] opacity-[50%] mb-10"></div>
          <div className='flex flex-col md:gap-10 gap-8 sm:pb-0 pb-10'>
            <p className={`cursor-pointer font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px] text-[#94C3E8]`} onClick={() => navigate("/Climb/ClimbSettings")}>
              {/* {t("Manage_Settings")} */}
              {t('Settings')}
            </p>
          </div>
        </div>

      </div>
      <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
    </>
  )
};

export const ClimbCard = ({ image, heading, subheading, buttonText, backgrounblue, RadiusTrue, disabled, navigateClick, note, savingsCardExist }) => {


  const navigate = useNavigate();
  return (
    <>
      <div className={`${backgrounblue ? 'bg-[#E9EEF7]' : 'bg-[#FFFFFF]'}
  ${RadiusTrue ? 'rounded-tl-[4rem] rounded-br-[4rem]' : 'rounded-tr-[4rem] rounded-bl-[4rem]'} 
  flex flex-col md:py-20 py-16`}>
        <div className="flex justify-center">
          <div className='flex sm:justify-between justify-center sm:gap-0 gap-6 items-center md:w-[85%] sm:w-[90%] w-full'>
            <div>
              <img alt={image} src={image} className="lg:w-[120px] lg:h-[120px] md:w-[90px] md:h-[90px] sm:w-[64px]
       sm:h-[64px] h-[65px] w-[65px]" />
            </div>
            <div>
              <p className="lg:text-[28px] md:text-[28px] sm:text-[15px] text-[16px] font-hanken-grotesk
       md:leading-[28px] sm:leading-[23px] font-[500] sm:font-[600] md:font-[500] sm:w-auto w-[127px] leading-[24px]">{heading}</p>
              {/* <p className="lg:w-[324px] md:w-[262px] w-[228px] lg:mt-4
               sm:mt-2 mt-3 md:text-[18px] sm:text-[10px] text-[16px] font-hanken-grotesk
               md:leading-[28px] sm:leading-[16px] font-[500] sm:block hidden">{createSuperscript("hi", [2,3,4])}</p> */}
              <p className="lg:w-[324px] md:w-[262px] w-[228px] lg:mt-4
               sm:mt-2 mt-3 md:text-[18px] sm:text-[10px] text-[16px] font-hanken-grotesk
               md:leading-[28px] sm:leading-[16px] font-[500] sm:block hidden">{subheading}</p>
              {note &&
                <p className="sm:block hidden md:font-[400] md:text-[12px] md:leading-[20px] sm:font-[500] sm:text-[10px] sm:leading-[16px] mt-8 text-[#4B70B9]">{note}</p>
              }


              <button
                className={`sm:mt-0 mt-4 lg:h-[55px] md:h-[50px] sm:h-[36px] h-[40px]
    lg:w-[180px] md:w-[120px] sm:w-[90px] w-[120px] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[13px] font-[700] text-white rounded-[74px] sm:hidden flex  justify-center 
    items-center font-hanken-grotesk ${disabled ? 'opacity-50 cursor-not-allowed bg-[#4B70B9]' : 'bg-[#2965FB]'}`}
                disabled={disabled}
                onClick={() => {
                  if (typeof navigateClick == 'function') {
                    navigateClick(); // If it's a function, call it
                  } else if (navigateClick) {
                    navigate(navigateClick); // If it's a URL or some other value, use it with `navigate`
                  }
                }}
              >
                {buttonText} {(savingsCardExist != undefined && savingsCardExist == false && heading !== 'SpendSense Alerts') ? <img src={lock} className="ml-2 sm:w-auto w-[13px]" alt="Check" /> : (heading === 'Covered by Kudzu' && disabled) && <img src={Check} alt="check" className="ml-2 sm:w-auto w-[13px]" />}
              </button>
            </div>
            <button
              className={`lg:h-[55px] md:h-[50px] sm:h-[36px] h-[40px]
    lg:w-[180px] md:w-[120px] sm:w-[90px] w-[150px] lg:text-[17px] md:text-[15px] sm:text-[12px]
    text-[14px] font-[700] text-white rounded-[74px] sm:flex hidden justify-center 
    items-center font-hanken-grotesk ${disabled ? 'opacity-50 cursor-not-allowed bg-[#4B70B9]' : 'bg-[#2965FB]'}`}
              disabled={disabled}
              onClick={() => {
                if (typeof navigateClick == 'function') {
                  navigateClick(); // If it's a function, call it
                } else if (navigateClick) {
                  navigate(navigateClick); // If it's a URL or some other value, use it with `navigate`
                }
              }}
            >
              {buttonText} {(savingsCardExist != undefined && savingsCardExist == false && heading !== 'SpendSense Alerts') ? <img src={lock} className="ml-2" alt="Check" /> : (heading === 'Covered by Kudzu' && disabled) && <img src={Check} className="ml-2" alt="Check" />}
            </button>
          </div>
        </div>
      </div>
    </>
  )
};


export const AccountDetailsHeder = ({ AccountNo, routingNo, accountCopiedProps, routingCopiedProps,
  handleCopyClick, handleCopyRoutingNumberClick, contentBetween, topBorder, bottomBorder, isblock,
  isCopyblack, mblaccountDetails }) => {

  const { t } = useTranslation();
  const [ContentSpace] = useState(contentBetween);
  const [BorderTop] = useState(topBorder);
  // const [BorderBottom] = useState(bottomBorder);

  ///////styles 
  const AccountRoutingText = 'md:font-[600] font-[500] sm:text-[#559C7E] text-[#262B3A] sm:opacity-100 opacity-[50%] lg:text-[16px] md:text-[16px] sm:text-[11px] text-[10px] leading-[16px] font-hanken-grotesk md:pb-8 sm:pb-4';
  const AccountRoutingNum = 'font-[700] text-[#262B3A] sm:font-[700] md:text-[16px] sm:text-[12px] text-[12px]  leading-[16px] tracking-[2px] sm:flex';

  return (
    <>
      {mblaccountDetails ? (
        <>
          <div className="flex justify-between py-10">
            <div class="h-[50px] sm:block flex justify-between flex-col">
              <p class="font-[600] text-[#FFFFFF] text-[12px] leading-[16px] font-hanken-grotesk 
             md:pb-8 sm:pb-4">{t("accounting_number")}</p>
              <p class="font-[600] text-[#FFFFFF] 
             md:text-[16px] sm:text-[13px] text-[12px]  
             leading-[16px] tracking-[2px] sm:flex" style={{ fontFamily: 'Space Mono' }}>
                {AccountNo} <span className="float-right sm:hidden block ml-3" onClick={handleCopyClick}>
                  <img src={MblCopytext} alt="Copy_Text" />
                </span>
              </p>
            </div>

            <div class="h-[50px] sm:block flex justify-between flex-col">
              <p class="font-[600] text-[#FFFFFF] text-[12px] leading-[16px] font-hanken-grotesk 
             md:pb-8 sm:pb-4">{t("routing_number")}</p>
              <p class="font-[600] text-[#FFFFFF] 
             md:text-[16px] sm:text-[13px] text-[12px]  
             leading-[16px] tracking-[2px] sm:flex" style={{ fontFamily: 'Space Mono' }}>
                {routingNo}<span className="float-right sm:hidden block ml-3" onClick={handleCopyRoutingNumberClick}>
                  <img src={MblCopytext} alt="Copy_Text" />
                </span>
              </p>
            </div>
          </div>
        </>
      ) :
        <div className={`border-opacity-[50%] lg:h-[216px] md:h-[190px] sm:h-[150px] h-[258px] flex flex-col justify-center
     ${BorderTop ? 'border-t-[2.5px] border-[#A3CEBB]' : ''}
     ${bottomBorder ? 'border-b-[2.5px] border-[#A3CEBB]' : ''}
     ${ContentSpace ? 'sm:mx-0 mx-4' : 'lg:ml-[11%] md:ml-[10%] sm:ml-[8%] sm:mr-0 mx-4'}`}>
          <p className={`sm:block hidden font-hanken-grotesk text-[#262B3A] 
      uppercase  lg:text-[13px] md:text-[12px] sm:text-[9px] text-[13px]
      font-[600] tracking-[2px] md:pb-8 pb-5 
      ${isblock ? 'opacity-1' : 'opacity-[50%]'}
      `}>{t("account_info").toLocaleUpperCase()}</p>


          <div className={`sm:flex ${ContentSpace ? '' : 'gap-[7rem]'}`}>
            <div className={`${ContentSpace ? 'sm:w-1/2 w-full' : 'sm:w-auto w-full'} sm:h-0 h-[65px] sm:block flex justify-between flex-col sm:mb-0 mb-12`}>
              <p className={AccountRoutingText}>{t("accounting_number")}</p>
              <p className={AccountRoutingNum} style={{ fontFamily: 'Space Mono' }}>{AccountNo}

                <span className={`sm:block hidden ml-2 tracking-[0px] cursor-pointer
             lg:text-[16px] md:text-[13px] font-hanken-grotesk underline
             sm:text-[10px] ${isCopyblack ? 'text-[black]' : 'text-[#559C7E]'}`}
                  onClick={handleCopyClick}> {accountCopiedProps ? t("Copy") : t("Copy")}
                </span>

                <span className="float-right sm:hidden block" onClick={handleCopyClick}>
                  <img src={Copy_Text} alt="Copy_Text" />
                </span>
              </p>
              {(accountCopiedProps) &&
                <p className='font-[600] text-[#2864fa] text-left text-[16px] 
                leading-[16px] font-hanken-grotesk pt-4 '>{t('copied')}</p>
              }
            </div>

            <div className={`${ContentSpace ? 'sm:w-1/2 w-full' : 'sm:w-auto w-full'}sm:h-0 h-[65px] sm:block flex justify-between flex-col`}>
              <p className={AccountRoutingText}>{t("routing_number")}</p>
              <p className={AccountRoutingNum} style={{ fontFamily: 'Space Mono' }}>{routingNo}

                <span className={`sm:block hidden ml-2 tracking-[0px] text-[#559C7E] cursor-pointer
             lg:text-[16px] md:text-[13px] font-hanken-grotesk
             sm:text-[10px] underline
             ${isCopyblack ? 'text-[black]' : 'text-[#559C7E]'}`}
                  onClick={handleCopyRoutingNumberClick}> {routingCopiedProps ? t("Copy") : t("Copy")}
                </span>

                <span className="float-right sm:hidden block" onClick={handleCopyRoutingNumberClick}>
                  <img src={Copy_Text} alt="Copy_Text" />
                </span>

              </p>
              {(routingCopiedProps) &&
                <p className='font-[600] text-[#2864fa] text-left text-[16px] leading-[16px] font-hanken-grotesk pt-4 '>{t('copied')}</p>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};

export const QuickActions = ({ actionsArray,buttonName }) => {
  const navigate = useNavigate();
  const QuickActionsButtons = actionsArray;
  const buttonCount = QuickActionsButtons.length;
  return (
    <>
      <div className='lg:h-[206px] md:h-[190px] sm:h-[155px] h-auto flex justify-center flex-col sm:py-0 sm:mx-0 
      py-8 mx-4'>
        <p className='font-hanken-grotesk text-[#262B3A]  uppercase opacity-50 lg:text-[13px] md:text-[12px] sm:text-[12px] text-[12px] font-[600] tracking-[2px] md:pb-8 pb-4'>
          Quick Actions
        </p>
        <div className='flex flex-wrap justify-between'>
          {QuickActionsButtons.map((object, index) => (
            <button
              // onClick={() => {object.path !== "" ? navigate(object.path) : alert("Coming Soon..")}}

              onClick={()=>{
                if(object.path !== ""){
                  navigate(object.path);logFirebaseEvents("Transfer_Money",object.name.replace(/ /g, '_'), object.name.replace(/ /g, '_'),"", "");
              }
            else {
              alert("Coming Soon..")
            }}}
              key={index}
              type="button"
              className={`font-hanken-grotesk mb-5 lg:text-[15px] md:text-[14px] sm:text-[12px] text-[13px]
         md:leading-[39.71px] sm:leading-[25.8px] 
         md:h-[50px] sm:h-[36px] h-[40px] inline-block rounded-full 
         border-[3px] border-[#30BC89] text-kudzu-text 
         text-xs font-semibold leading-normal text-success 
         transition duration-150 ease-in-out ${buttonCount === 3 ? 'sm:w-[32%] w-full' : (buttonCount >= 4 ? 'sm:w-1/4 w-full' : 'sm:w-[48%]  w-full')}`}
              data-te-ripple-init="true">
              {object.name}
            </button>
          ))}
        </div>
      </div>
    </>
  )
}

//////More Services
export const MoreServices = ({ actionsArray, isMoresetting }) => {
  const navigate = useNavigate();

  const moreService = 'sm:w-[90%] w-full md:pb-[1.5rem] pb-[1.3rem] flex justify-between items-center border-b-[2.5px] border-[#D1E7DD] cursor-pointer';
  const moreserviceText = 'font-[600] text-[#262B3A] lg:text-[16px] md:text-[15px] sm:text-[11px] text-[14px] leading-[16px] font-hanken-grotesk';
  return (
    <>
      <div className="md:py-20 sm:py-10 py-8 flex flex-col justify-center">

        {isMoresetting ? (
          <p className='font-hanken-grotesk text-[#262B3A] uppercase opacity-50 lg:text-[13px] md:text-[12px] sm:text-[9px] text-[12px] font-[600] tracking-[2px] md:pb-8 pb-4'>
            {t('more_settings')}
          </p>) :
          < p className='font-hanken-grotesk text-[#262B3A] uppercase opacity-50 lg:text-[13px] md:text-[12px] sm:text-[9px] text-[12px] font-[600] tracking-[2px] md:pb-8 pb-4'>
            {t('more_service')}
          </p>
        }

        <div className='sm:flex w-full md:mb-[3rem] sm:mb-8' >
          {actionsArray.slice(0, 2).map((object, index) => (
            <div key={index} className='sm:w-[50%] w-full sm:mb-0 mb-5'
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  navigate(object.path)
                }
              }}
              onClick={() => {navigate(object.path); logFirebaseEvents("Manage_Account", object.name, object.name, "","");}}>
              <div className={moreService}>
                <p className={moreserviceText}>{object.name}</p>
                <p><img src={RightArrow} className="w-[7px] mr-[10px]" alt="RightArrow" /></p>
              </div>
            </div>
          ))}
        </div>
        <div className='sm:flex  w-full'>
          {actionsArray.slice(2, 4).map((object, index) => (
            <div key={index} className='sm:w-[50%] w-full flex justify-between items-center sm:mb-0 mb-5'
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  navigate(object.path)
                }
              }}
              onClick={() => navigate(object.path)}>
              <div className={moreService}>
                <p className={moreserviceText}>{object.name}</p>
                <p><img src={RightArrow} className="w-[7px] mr-[10px]" alt="RightArrow" /></p>
              </div>
            </div>
          ))}
        </div>
      </div >
    </>
  );
};


export function formatDateForTrustDevice(date) {
  if (!date) return "";
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  const tempDate = new Date(date);
  const formattedDate = tempDate.toLocaleDateString('en-US', options);
  return formattedDate;
}


export function dashBoardformatDate(inputDate) {
  if (!inputDate) {
    return null; // Return null if the input date is missing or empty
  }
  const parsedDate = new Date(inputDate);
  if (isNaN(parsedDate.getTime())) {
    return null; // Return null if the parsed date is invalid
  }
  // Format the date as "Month Day"
  const options = { month: "long" };
  const options1 = { day: 'numeric' };

  const month = parsedDate.toLocaleDateString(undefined, options);
  const day = parsedDate.toLocaleDateString(undefined, options1);
  const formattedDate = `${month} ${day}`
  return formattedDate;
}

export function formatDateYYYYMMDD(date) {
  if (!date) return "";

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}




export function parseGoogleLocationResponse(data) {
  // debugger;
  const addressComponents = data;
  const getComponent = type => {
    const component = addressComponents.find(component => component.types.includes(type));
    return component ? component.long_name : null;

  };
  const getShortNameFromComponent = type => {
    const component = addressComponents.find(component => component.types.includes(type));
    return component ? component.short_name : null;
  };
  const streetNumber = getComponent('street_number') || getComponent('administrative_area_level_4');
  const route = getComponent('route');
  const sublocality = getComponent('sublocality_level_1');
  const naturalFeature = getComponent('natural_feature');
  const locality = getComponent('locality') || getComponent('administrative_area_level_2');
  const administrativeAreaLevel1 = getComponent('administrative_area_level_1');
  const postalCode = getComponent('postal_code');
  const country = getComponent('country');
  const countryCode = getShortNameFromComponent('country');
  var response = {
    streetNumber: streetNumber,
    route: route,
    sublocality: sublocality,
    naturalFeature: naturalFeature,
    locality: locality,
    administrativeAreaLevel1: administrativeAreaLevel1,
    postalCode: postalCode,
    country: country,
    countryCode: countryCode === "US" ? "USA" : countryCode
  }
  return response
}



///////chosse card in accounts services page
export const ChooseCard = ({ imageSource, value, checkingName,
  checkingNameColor, valueColor, LinkTo, Servicecard, index, enrollClicked }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const savingsNewClicked = useSelector(state => state.savingsNewClicked);

  // const cardBorderStyle = { borderColor: checkingNameColor }; // Set border color same as checkingNameColor

  return (
    <div className="sm:w-[30%] w-full">
      {checkingName == t('menu_savings') && savingsCardAvailable == false ?
        <Link className="sm:w-[30%] w-full">
          <div className={`relative cursor-pointer 
   lg:rounded-[36px] md:rounded-[25px] rounded-[18px] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] 
   box-border lg:h-[400px] md:h-[270px] h-[210px] border-[2.5px] lg:p-[33px] md:p-[28px] sm:p-4 p-8
   border-[${Servicecard.border}]
  `} onClick={() => {
              dispatch({ type: 'SELECTED_SUB_INDEX', payload: { selectedSubIndex: -1 } });
              if (checkingName === t('menu_external_accounts')) {
                global.isManageExternalAccs = true
                global.selectedAccountServiceCardKey = -1
                navigate(LinkTo)
              } else {
                if (checkingName === t('menu_savings')) {
                  if (savingsCardAvailable == false && savingsNewClicked == false) {
                    const savingsNewPayload = { savingsNewClicked: true };
                    dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
                    enrollClicked();
                  } else if (savingsCardAvailable == false && savingsNewClicked == true) {
                    //alert("enroll clicked");
                    enrollClicked();
                  } else {
                    global.selectedAccountServiceCardKey = value
                    global.isManageExternalAccs = false
                    navigate(LinkTo)
                  }


                } else {
                  global.selectedAccountServiceCardKey = value
                  global.isManageExternalAccs = false
                  navigate(LinkTo)
                }

              }
            }}>

            <div className="flex justify-between">
              <span className={` font-medium md:text-[14px] sm:text-[11px] 
     font-hanken-grotesk`} style={{ color: `${Servicecard.color}` }}>{(checkingName === 'Checking' || checkingName == 'Spending') ? 'Kudzu Checking' : checkingName === 'Savings' ? 'Kudzu Savings' : checkingName}</span>

              {(checkingName.toLowerCase() != "savings") ? <span className={`md:text-[14px] sm:text-[11px] before-dots font-[700]
     
   ` } style={{ color: `${Servicecard.color}` }}>{value}</span> :
                (checkingName.toLowerCase() == "savings" && savingsCardAvailable == false && savingsNewClicked == false) ? <div
                  className={`bg-[#2965FB] flex items-center text-white
                   justify-center text-center w-auto px-[15px] md:h-[25px] sm:h-[20px] h-[20px] rounded-[89px]
                   lg:text-[16px] md:text-[12px] sm:text-[12px] text-[12px] font-[400] font-hanken-grotesk cursor-pointer`}
                  onClick={() => {
                    const savingsNewPayload = { savingsNewClicked: true };
                    dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();

                    }
                  }}
                  tabIndex={0} // Set tabIndex here for the button
                >
                  New
                </div> : (checkingName.toLowerCase() == "savings" && savingsCardAvailable == false && savingsNewClicked == true) ? <div
                  className={`border-[1.5px]
                  border-[#A3CEBB] flex items-center text-[#A3CEBB]
                   justify-center text-center w-auto px-[15px] md:h-[25px] sm:h-[20px] h-[20px] rounded-[89px]
                   lg:text-[16px] md:text-[12px] sm:text-[12px] text-[12px] font-[400] font-hanken-grotesk cursor-pointer`}
                  onClick={() => {
                    // alert("enroll clicked");
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();

                    }
                  }}
                  tabIndex={0} // Set tabIndex here for the button
                >
                  Enroll
                </div> : <span className={`md:text-[14px] sm:text-[11px] before-dots font-[700]`} style={{ color: `${Servicecard.color}` }}>{value}</span>}
            </div>

            <img className="lg:w-[82px] lg:h-[92.25px] md:w-[55px] md:h-[55px] sm:w-[40px] sm:h-[40px] w-[45px] h-[45px]
   overflow-hidden cursor-pointer absolute lg:bottom-10  md:bottom-7 sm:bottom-5 bottom-8"
              alt=""
              src={imageSource}
            />
          </div></Link> :
        <Link to={LinkTo} className="sm:w-[30%] w-full">
          <div className={`relative cursor-pointer 
        lg:rounded-[36px] md:rounded-[25px] rounded-[18px] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] 
        box-border lg:h-[400px] md:h-[270px] h-[210px] border-[2.5px] lg:p-[33px] md:p-[28px] sm:p-4 p-8 
        border-[${Servicecard.border}]
       `} onClick={() => {
              dispatch({ type: 'SELECTED_SUB_INDEX', payload: { selectedSubIndex: -1 } });
              if (checkingName === t('menu_external_accounts')) {
                global.isManageExternalAccs = true
                global.selectedAccountServiceCardKey = -1
                navigate(LinkTo)
              } else {
                global.selectedAccountServiceCardKey = value
                global.isManageExternalAccs = false
                navigate(LinkTo)
              }
            }}>

            <div className="flex justify-between">
              <span className={` font-medium md:text-[14px] sm:text-[11px] 
          font-hanken-grotesk`} style={{ color: `${Servicecard.color}` }}>{(checkingName === 'Checking' || checkingName == 'Spending') ? 'Kudzu Spending' : checkingName === 'Savings' ? 'Kudzu Savings' : checkingName}</span>

              {(checkingName.toLowerCase() != "savings") ? <span className={`md:text-[14px] sm:text-[11px] before-dots font-[700]
          
        ` } style={{ color: `${Servicecard.color}` }}>{value}</span> :
                (checkingName.toLowerCase() == "savings" && savingsCardAvailable == false && savingsNewClicked == false) ? <div
                  className={`bg-[#2965FB] flex items-center text-white
                        justify-center text-center w-auto px-[15px] md:h-[25px] sm:h-[20px] h-[20px] rounded-[89px]
                        lg:text-[16px] md:text-[12px] sm:text-[12px] text-[12px] font-[400] font-hanken-grotesk cursor-pointer`}
                  onClick={() => {
                    const savingsNewPayload = { savingsNewClicked: true };
                    dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();

                    }
                  }}
                  tabIndex={0} // Set tabIndex here for the button
                >
                  New
                </div> : (checkingName.toLowerCase() == "savings" && savingsCardAvailable == false && savingsNewClicked == true) ? <div
                  className={`border-[.5px]
                  border-[#A3CEBB] flex items-center text-[#A3CEBB]
                        justify-center text-center w-auto px-[15px] md:h-[25px] sm:h-[20px] h-[20px] rounded-[89px]
                        lg:text-[16px] md:text-[12px] sm:text-[12px] text-[12px] font-[400] font-hanken-grotesk cursor-pointer`}
                  onClick={() => {
                    // alert("enroll clicked");
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();

                    }
                  }}
                  tabIndex={0} // Set tabIndex here for the button
                >
                  Enroll
                </div> : <span className={`md:text-[14px] sm:text-[11px] before-dots font-[700]`} style={{ color: `${Servicecard.color}` }}>{value}</span>}
            </div>

            <img className="lg:w-[82px] lg:h-[92.25px] md:w-[55px] md:h-[55px] sm:w-[40px] sm:h-[40px] w-[45px] h-[45px]
        overflow-hidden cursor-pointer absolute lg:bottom-10  md:bottom-7 sm:bottom-5 bottom-8"
              alt=""
              src={imageSource}
            />
          </div>
        </Link>}
    </div>

  );
};


///get all categeories for faqs
export const getCategories = async (freshDeskDomain, freshDeskCategoryID, freshDeskAPIKey) => {
  let faqList = [];
  var url = "https://" + freshDeskDomain + "/api/v2/solutions/categories/" + freshDeskCategoryID + "/folders";
  let headers = await GetApiHeadersWithBearerForSupport(freshDeskAPIKey);
  var response = await getRequestfreshDeskDomian(url, headers);
  var responseObj = JSON.parse(response);
  if (responseObj !== undefined && responseObj.length > 0) {
    faqList = responseObj.filter(obj => obj.name.startsWith('FAQ:'));
  }
  return faqList;
}




///get all categeories for faqs
export const getfaqsdata = async (freshDeskDomain, freshDeskCategoryID, freshDeskAPIKey, perPageCount) => {
  var url = "https://" + freshDeskDomain + "/api/v2/solutions/folders/" + freshDeskCategoryID + "/articles?per_page=" + perPageCount;
  let headers = await GetApiHeadersWithBearerForSupport(freshDeskAPIKey)
  var response = await getRequestfreshDeskDomian(url, headers)
  var responseObj = JSON.parse(response);
  var parseresp = parseFaqs(responseObj);
  return parseresp;
}

export const parseFaqs = (parsedResponse) => {

  var faqs = [];
  for (var i = 0; i < parsedResponse.length; i++) {
    var item = parsedResponse[i];
    item.description = item.description.replace(/color:/g, "")
    item.description = item.description.replace(/font-size:/g, "color:#559C7E;")
    item.description = '<meta name="viewport" content="width=device-width, initial-scale=1"><div style="color: #559C7E">' + item.description + '</div>'
    item.description = '<body style="background-color: transparent" zoom: 100%;>' + item.description + '</body>'
    item.description = item.description.replace(/font-family/g, "")
    item.description = item.description.replace(/text-decoration-thickness/g, "")
    item.description = item.description.replace(/text-decoration-style/, "");
    item.description = item.description.replace(/line-height/g, "")
    item.description = item.description.replace(/<a/g, '<a style="color: blue;"');
    item.description = item.description.replace(/<p/g, '<p style="text-align: justify; line-height: 1.5;font-size:16px"');
    item.description = item.description.replace(/<div/g, '<div style="text-align: justify; line-height: 1.5;;font-size:16px"');


    faqs.push(item);

  }

  return faqs

}

///get all categeories for faqs
export const contactUsTypes = async (freshDeskDomain, freshDeskAPIKey) => {
  // debugger;
  var url = "https://" + freshDeskDomain + "/api/v2/ticket_fields?type=default_ticket_type";
  let headers = await GetApiHeadersWithBearerForSupport(freshDeskAPIKey)
  var response = await getRequestfreshDeskDomian(url, headers)
  var responseObj = JSON.parse(response);
  if (responseObj[0].choices.length > 0) {

    var feedbackObj = responseObj[0].choices;
    return feedbackObj;

  } else {
    return [];
  }

}


///get all EsignStatus  
export const EsignStatus = async (cardKey, accessToken) => {
  //debugger;
  if (cardKey == null || cardKey == undefined) {
    return
  }
  const deviceId = await retrieveData('updatedDeviceId')
  const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
  const url = GET_ESIGN_STATUS(cardKey)
  const response = await GetRequest(url, headers);
  const responseJson = JSON.parse(response);
  if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
    return responseJson.Response.OptOut;
  } else {
    return '';
  }
}



///get all EsignStatus  
export const EsignAgreement = async (cardKey, accessToken) => {
  // debugger;
  if (cardKey == null || cardKey == undefined) {
    return
  }

  const deviceId = await retrieveData('updatedDeviceId')
  const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
  const url = GET_ESIGN_AGREEMENT(cardKey)
  const response = await GetRequest(url, headers);
  const responseJson = JSON.parse(response);
  if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
    return responseJson.Response;
  } else {
    return '';
  }
}



///get all EsignStatus  
export const AcceptEsignAgreement = async (cardKey, accessToken, ProgramId) => {
  // debugger;
  let data = [{
    ContentID: ProgramId,
    UserAction: "A"
  }];
  const deviceId = await retrieveData('updatedDeviceId')
  const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
  const url = ACCEPT_E_SIGN_AGGREMENT(cardKey);
  const response = await PostRequest(url, data, headers);
  const responseJson = JSON.parse(response);
  return responseJson;
}


//get User Profile Info

export const getUserProfileInfo = async (cardKey, accessToken) => {
  //  debugger;
  if (cardKey == null || cardKey == undefined) {
    return
  }
  const deviceId = await retrieveData('updatedDeviceId')
  const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
  const url = GET_USER_PORFIE_INFO(cardKey)
  const response = await GetRequest(url, headers);
  const responseJson = JSON.parse(response);
  if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
    return responseJson.Response;
  } else {
    return '';
  }
}


///////////////////////WRITTEN BY KIRAN S


///////SecuritySidebarContent

const SecuritySidebaritem = 'cursor-pointer font-hanken-grotesk font-[600] md:font-[700] lg:leading-[16px] md:leading-[16px] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] text-[#FEFAEE]'
const SecuritySidebaritemSelected = 'cursor-pointer font-hanken-grotesk font-[600] md:font-[700] lg:leading-[16px] md:leading-[16px] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] text-[#94C3E8]'

export const clearStorageExceptKey = (storage, keyToPreserve) => {
  for (let i = 0; i < storage.length; i++) {
    const key = storage.key(i);
    if (key !== keyToPreserve) {
      storage.removeItem(key);
    }
  }
};

export const SecuritySidebar = ({ changePasswordClicked, selectedIndex }) => {
  const handleChangePasswordClicked = () => {
    changePasswordClicked()
  };




  return (
    <>
      <div className='lg:w-[260px] md:w-[290px] w-[185px] sm:mx-0 ml-[2.5rem]'>
        <p className="text-white font-normal
        lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] 
        sm:leading-[30px] leading-[31px]
         font-serif-pro border-b-[2.5px]
         border-[#4B70B9] lg:pb-14 md:pb-[50px] sm:pb-[2.6rem] pb-[2.6rem] mb-10
           border-opacity-[50%]">{t("account_secure")}</p>

        <div className="flex flex-col sm:pb-0 pb-10">

          <p onClick={handleChangePasswordClicked} className={`${selectedIndex === 1 ? SecuritySidebaritemSelected : SecuritySidebaritem} p-8 pl-0 font-hanken-grotesk md:text-[16px] font-[600] text-[11px] sm:font-[700]`}>
            {t("change_password")}</p>

          <Link to="/Security/TrustedDevice" onClick={ ()=> logFirebaseEvents("Trust_Device", "Trust_Devices_Clicked", "Trust_Devices_Clicked","", "")}>
            <p className={`${selectedIndex === 2 ? SecuritySidebaritemSelected : SecuritySidebaritem} p-8 pl-0 font-hanken-grotesk md:text-[16px] font-[600] text-[11px] sm:font-[700]`}>
              {t("trusted_devices")}
            </p>
          </Link>
        </div>
      </div>
      <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
    </>
  )
}

///////Help Sidebar Content  
const helpListItems = 'cursor-pointer font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px] text-[#FEFAEE]'
const helpListItemsSelected = 'cursor-pointer font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px] text-[#94C3E8]'

export const HelpSidebar = ({ HelpselectedIndex }) => {
  const navigate = useNavigate();
  return (
    <div className='hidden sm:block lg:w-[262px] md:w-[275px] sm:w-[173px] w-[192px] sm:mx-0 ml-[2.5rem]'>
      <p className="text-white font-normal
            lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[30px]
             leading-[31px] font-serif-pro border-b-[2.5px]
   border-[#4B70B9] lg:pb-14 md:pb-[50px] sm:pb-[2.6rem] pb-[2.6rem] mb-10  border-opacity-[50%]">{t("helpHeading")}</p>

      <div className='flex flex-col md:gap-10 gap-8 sm:pb-0 pb-10'>

        {/* <p className={HelpselectedIndex === 1 ? helpListItemsSelected : helpListItems}
          onClick={() => navigate("/Help/AboutKudzu")}>{t("aboutKudzu")}</p> */}

        <p className={HelpselectedIndex === 2 ? helpListItemsSelected : helpListItems}
          onClick={() =>{ navigate("/Help/FAQ");logFirebaseEvents("Help", "Help_And_Faq_Clicked", "Help_And_Faq_Clicked","", "");}}>{t("helpfaq")}</p>

        <p className={HelpselectedIndex === 3 ? helpListItemsSelected : helpListItems}
          onClick={() => {navigate("/Help/FindATM");logFirebaseEvents("Help", "Find_ATM_Clicked", "Find_ATM_Clicked","", "")}}>{t("findATMBtn")}</p>

        <p className={HelpselectedIndex === 4 ? helpListItemsSelected : helpListItems}
          onClick={() =>{ navigate("/Help/ContactUs");logFirebaseEvents("Help", "Contact_Us_Clicked", "Contact_Us_Clicked","", "")}}>{t("conatctus")}</p>
      </div>
    </div>
  )
}


export function formatDate(timestamp) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString('en-US', options);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedTime = `${(hours % 12) || 12}:${minutes.toString().padStart(2, '0')} ${period}`;

  return `${formattedDate} at ${formattedTime}`;
}

//modifcation of frequency values
export function getFrequencyAbbreviation(frequencyType) {
  //alert(frequencyType);
  switch (frequencyType) {
    case "Weekly":
      return "WY";
    case "Daily":
      return "DY";
    case "Monthly":
      return "MY";
    default:
      return frequencyType;
  }
}

///climb object creations
///climb object creation for setting screen
export const generateClimbData = (item, savingsAccount) => {

  let image = '';
  let desc = '';
  let optinStatus = '';
  let buttonText = '';
  let link = '';
  let headingText = item.displayText;
  let note = '';

  if (item.code === SPENDING_ALERT) {
    desc = t('spendingAlertMessage');
    note = t("climb_menu_note")
    image = Addalerts
    buttonText = (item.hasConfigurations) ? t('manageAlerts') : t('addAlerts');
    optinStatus = (item.code === SPENDING_ALERT ? false : false)
    if (item.hasConfigurations) { 
      debugger; 
      link = '/Climb/ManageSpendAlerts'
    } else {
      link = '/Climb/AddSpendAlerts'
    }
  }
  // if (item.code === SPENDING_ALERT) {
  //   image = Addalerts
  //   desc = t('spendingAlertMessage');
  //   note = t("climb_menu_note")
  //   optinStatus = (item.code === SPENDING_ALERT ? !item.optedIn : false)
  //   if (item.optedIn && item.hasConfigurations) {
  //     buttonText = t('manageAlerts')
  //     link = '/Climb/ManageSpendAlerts'
  //   } else {
  //     buttonText = t('addAlerts')
  //     link = '/Climb/AddSpendAlerts'
  //   }
  // } 
  else if (item.code === COVERED_BY_KUDZU) {
    image = CoveredByKudzuImg;
    optinStatus = item.optedIn ? true : false;
    link = '/Climb/CoveredByKudzu'
    if (item.optedIn) {
      buttonText = t('active')
    } else {
      buttonText = t('activate')
    }
    // desc = t('converdByKudzuDesc');
    desc = <p>{t('converdByKudzuDescSuperScriptPart1')}Kudzu<sup className="md:text-[10px] sm:text-[6px] text-[10px] font-semibold">TM<sup className="md:text-[10px] sm:text-[6px] text-[10px] font-semibold">2</sup></sup>{t('converdByKudzuDescSuperScriptPart2')}</p>;

  } else if (item.code === SAVINGS_HABIT) {
    desc = t('savigHbitDesc');
    image = SavingsHabitImg
    buttonText = (item.hasConfigurations) ? t('manageHabits') : t('addHabits');
    optinStatus = (item.code === SAVINGS_HABIT ? false : false)
    if (item.hasConfigurations) {
 
      link = '/Climb/ManageSavingsHabit'
    } else {
      link = '/Climb/AddSavingsHabit'
    }
  }

  return {
    image: image,
    code: item.code,
    heading: headingText,
    subheading: desc,
    buttonText: buttonText,
    Disabled: optinStatus,
    link: link,
    note: note
  };
};

export const generateClimbSettingsData = (item) => {
  // debugger;
  // debugger;
  let title = '';
  let content = '';
  let optinStatus = '';

  if (item.code === SPENDING_ALERT) {
    title = item.displayText
    content = t('spedningAlertPauseMsg');
    optinStatus = item.optedIn;
  } else if (item.code === COVERED_BY_KUDZU) {
    title = item.displayText
    content = t('coverdBykudzuDesc');
    optinStatus = item.optedIn;
  } else if (item.code === SAVINGS_HABIT) {
    title = item.displayText
    optinStatus = item.optedIn;
    content = t('savingHabiPauseMsg');
  }

  return {
    code: item.code,
    title: title,
    optinStatus: optinStatus,
    content: content
  };

};



export const modifyHtmlForNavigation = (htmlString, color, isUnread = true) => {
  console.log('----', typeof htmlString)
  if (typeof htmlString !== 'string' || !isUnread) {
    return htmlString;
  }

  // Add inline style with the provided color to anchor tags
  const modifiedHtml = htmlString.replace(/<a\s+([^>]*)>/g, (href) => {
    console.log("href---", href.slice(0, href.length - 1))
    return `${href.slice(0, href.length - 1)} style="color: ${color}">`;
  });
  // const modifiedHtml = htmlString.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>/gi, (match, href) => {
  //   return `<a href="${href}" style="color: ${color};">`;
  // });

  console.log("modifiedHtml", modifiedHtml)

  return modifiedHtml;
};

export const delayForDemo = (promise) => {
  return new Promise(resolve => {
    setTimeout(resolve, 1000);
  }).then(() => promise);
}


////notification navigations
export const useNotificationNavigation = () => {
  const navigate = useNavigate();
  const cards = useSelector(state => JSON.parse(state.userCards));


  const notificationNavigation = (href, e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("navigatehref---", href)

    switch (href) {
      case NOTIF_CUSTOMER_CARE:
        navigate('/Help/ContactUs');
        break;
      case NOTIF_CARD_SERVICES:
        navigate('/CardServices');
        break;
      case NOTIF_TRANSACTIONS:
        navigate('/home');
        break;
      case NOTIF_STATEMENTS:
        navigate('/Documents/Statements');
        break;
      case NOTIF_FEES:
        navigate('/Documents/Fees');
        break;
      case NOTIF_MANAGE_SAVINGS_HABIT:
        navigate('/Climb/ManageSavingsHabit');
        break;
      case NOTIF_SPENDSENSE_ALERT:
        navigate('/Climb/ManageSpendAlerts');
        break;
      case NOTIF_SPENDSENSE_ALERT2:
        navigate('/Climb/ManageSpendAlerts');
        break;
      case NOTIF_PAYPERKS:
        navigate('/PayPerks');
        break;
      case NOTIF_PERSONAL_INFO:
        navigate('/PersonalInformation');
        break;
      case NOTIF_COVERED_BY_KUDZU_OPT_IN:
        navigate('/Climb/ClimbMenu');
        break;
      case NOTIF_COVERED_BY_KUDZU_TERMS:
        navigate('/Documents/Policies/Covered%20by%20Kudzu%20Terms%20and%20Conditions', { state: { name: 'Covered by Kudzu Terms and Conditions', url: 'CoveredByKudzuTerms', accType: 64, CardKey: cards?.[0]?.CardKey } });
        break;
      case NOTIF_MANAGE_DIRECT_DEPOSIT:
        navigate('/AccountServices/Spending/AddFunds/ManageDirectDeposit');
        break;
      default:
        break;
    }
  };

  return notificationNavigation;
};

export const APP_ENV = 'APP_ENV';
export const BIOMETRIC = "biometric";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const USERNAME = "USERNAME";
export const REFRESH_TOKEN = "REFRESH_TOKEN"
export const IS_TRUSTED_DEVICE = "IsTrustedDevice";
export const USER_PROFILE = "userProfile";
export const USER_CARDS = "userCards";
export const USER_ACTIVITIES = "userActivities";
export const USER_LANGUAGE = "userLanguage";
export const SPENDING = "Spending"
export const SAVINGS = "Savings"
export const SHARED = "Shared"
export const FaceID = "Face ID"
export const TouchID = "Touch ID"
export const Biometric = "Biometric"
export const FingerPrint = "Fingerprint Recognition"
export const APP_BG_TIME = "APP_BG_TIME";
export const APP_BG_TIME_IN_SECS = 1800;
export const CHECKING = "Checking";
export const AMOUNT_MAX_FILTERS = 500;
export const AMOUNT_MIN_FILTERS = 0;
export const TIME_MAX_FILTERS = 6;
export const TIME_MIN_FILTERS = 0;
export const SLIDER_MIN_POSITION = -10;
export const SLIDER_MAX_POSITION = 230;
export const SPENT_AMOUNT_DEFAULT_DAYS = 7;
export const MIN_PASSWORD_LENGTH = 1
export const PENDING = "Pending"
export const DEVICE_VERIFICATION = "DeviceVerification";
export const LOGIN = "Login";
export const MAX_RETRY_COUNT = 3;
export const TNC = "TnC";
export const PRIVACY_POLICY = "PrivacyPolicy";
export const ESIGN = "ESign";



