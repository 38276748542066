import React, { useState, useEffect, useRef } from "react";
import CustomInput from "../../CustomComponents/FormInputs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
// import { LuClock3 } from "react-icons/lu"; 
import Check from '../../../assets/images/Check.png';
import Error from '../../../assets/images/Error.png';
import { useNavigate } from 'react-router-dom';
import TimerIcon from '../../../assets/images/timer.png';
import Oops from '../../../assets/images/Oops.png';


//redux 
import app_env from '../../../utils/app-config.json';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, PostRequest } from '../../../network/NetworkUtils';
import { useSelector, connect, useDispatch } from 'react-redux';
import { STATUS_SUCCESS_CODE, SIGNUP_VERIFY_NEW_ACCOUNT, EMAIL_OTP_GENERATION, VERIFY_OTP_EXISTING_PHONE_EMAIL_NUMBER, RESEND_EXISTING_PHONE_EMAIL_NUMBER, OTP_USERS, RESENT_OTP_CHANGE_PIN, VERIFY_CHANGE_PIN, VERIFY_OTP_VIEW_CARD, RESEND_OTP_VIEW_CARD, VERIFY_OTP_ACTIVATE_CARD, RESEND_ACTIVATE_CARD_OTP, VERIFY_UPDATE_ADDRESS_OTP, VERIFY_UPDATE_EMAIL_OTP, VERIFY_OTP_UPDATE_PHONE_NUMBER, RESEND_UPDATE_PHONE_NUMBER, RESEND_UPDATE_EMAIL_OTP, RESEND_UPDATE_ADDRESS_OTP, VERIFY_OTP_CHANGE_PASSWORD, RESEND_CHANGE_PASSWORD_TOP, RESEND_OTP_ADD_AUTH_USER, VERIFY_OTP_ADD_AUTH_USER, VERIFY_OTP_KYC_CIP, GENERATE_EXISTING_PHONE_EMAIL_OTP, VERIFY_OTP_UNLOCK_CARD, RESEND_UNLOCK_CARD_OTP, GENERATE_OTP_KYC_CIP, RESEND_OTP_ADD_ACH_ACCOUNT, VERIFY_OTP_ADD_ACH_ACCOUNT } from '../../../network/URLConstants';
import Loader from "../../CustomComponents/Loader";
import { displayErrorMessage } from '../../../utils';
import { retrieveData } from '../../../helpers/utils/Utils';
import { fetchConfigarationData } from "../../../helpers/utils/Utils";
import { useKeyDown } from "../../../hooks/useKeyDown";
import { LOGIN_SESSION_KEY } from "../../../Redux/reducers";
import logFirebaseEvents from "../../LogFirebaseEvents";


const appConfigData = await fetchConfigarationData();
var verifyResponse = {}
export const Custom2FA = (props) => {
    const { openPhoneOTPModal, onClose, verificationSuccess, verificationFailure, type, email, phoneNumber, address } = props
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cards = useSelector(state => JSON.parse(state.userCards));
    const selectedItem = (cards != null && cards != undefined && cards.length != 0) ? cards[0] : {}
    const { t } = useTranslation();
    const accessTokenToApi = useSelector(state => state.accessToken);
    const sessionId = useSelector(state => state.sessionId);
    const userId = useSelector(state => state.userId);
    const [isValid, setvalidCode] = useState(false);
    const [verifyCode, setCode] = useState(''); // State variable to store the input value
    const [localSessionId, setlocalSessionId] = useState('');
    const [message, setMessage] = useState('');
    // const [verifyResponse, setVerifyResponse] = useState({});
    const [resendCount, setResendCount] = useState(1);
    const sessionKey = useSelector(state => state.loggedSessionKey);
    //  const deviceId = useSelector(state => state.deviceId);
    const cardDetails = useSelector(state => state.selectedCardDetails);
    const inputRef = useRef(null);
    const submitButtonRef = useRef(null);
    const [timer, setTimer] = useState(30);
    const [startTimer, setStartTimer] = useState(false);

    useEffect(() => {
        if (startTimer) {
            if (timer > 0) {
                setTimeout(() => {
                    setTimer(prev => prev - 1);
                }, 1000)
            }
            else setStartTimer(false);
        }
    }, [timer, startTimer])

    useEffect(() => {
        if (props?.type == t('fraud_protection')) setStartTimer(true)
    }, [])


    const schema = yup.object().shape({
        verifyCode: yup
            .string()
            .required(t('otp_required'))
            .matches(/^\d{6}$/, t('otp_required')),
    });
    useEffect(() => {
        if (sessionId != null && sessionId != '' && userId != null && userId != '') {
        }
    }, [sessionId, userId])

    const [isLoading, setIsLoading] = useState(false);
    const handleVerifyCodeChange = async (event) => {
        setMessage('');
        const { name, value } = event.target;
        setCode(value);
        setvalidCode(false);
        try {
            await schema.validate({ [name]: value });
            setvalidCode(true);
            setCode(value);
            setMessage(''); // Clear the message when input value is valid
        } catch (error) {
            setvalidCode(false);
        }
    };
    const {
        register,
        formState: { errors },
        setValue
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        if (verifyCode.length === 6 && submitButtonRef.current) {
            //     // Focus on the submit button when 6 digits are entered
            submitButtonRef.current.focus();
        }

    }, [verifyCode])
    // useKeyDown(() => {
    //     handleVerify()
    // }, ["Enter"]);

    const getHeadersWithBearerOrWithoutBearer = async () => {

        const deviceId = await retrieveData('updatedDeviceId')
        let headers;

        switch (type) {

            case t('view_card_details'):
            case t('unlock_card'):
            case t('change_pin'):
            case t('add_external_account'):
            case t('old_mobile'):
            case t('old_email'):
            case t('old_address'):
            case t('activate_card'):
            case t('new_mobile'):
            case t('new_email'):
            case t('new_address'):
            case t('change_password'):
            case t('google'):
            case t('facebook'):
            case t('microsoft'):
            case t('add_auth_user'):
            case t('apple_id'):
                headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
                break;
            case t('fraud_protection'):
                headers = await GetApiHeadersWithOutBearer();
                break;
            default:
                headers = await GetApiHeadersWithOutBearer();
                break;
        }

        return headers;
    }
    const onSubmit = async (data) => {
        setIsLoading(true)

        let headers = await getHeadersWithBearerOrWithoutBearer(type);
        let requestObj;
        let url;
        switch (type) {
            case t('fraud_protection'):
                requestObj = {
                    "lang": "en",
                    "appID": appConfigData.CLIENT_ID,
                    "otp": data.verifyCode,
                    "code": props?.accessParam,
                    "sessionkey": sessionKey,
                }
                url = VERIFY_OTP_KYC_CIP
                break;
            case t('unlock_card'):
                requestObj = {
                    "code": data.verifyCode,
                    "sessionId": sessionKey,
                    "cardKey": cardDetails.CardKey
                }
                url = VERIFY_OTP_UNLOCK_CARD
                break;
            case t('change_password'):
                requestObj = {
                    "mobileNumber": phoneNumber,
                    "cardKey": selectedItem.CardKey,
                    "sessionId": sessionKey,
                    "code": data.verifyCode

                }
                url = VERIFY_OTP_CHANGE_PASSWORD
                break;

            case t('old_mobile'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                    "action": "mobile"
                }
                url = VERIFY_OTP_EXISTING_PHONE_EMAIL_NUMBER
                break;
            case t('new_mobile'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                    "mobileNumber": phoneNumber
                }
                url = VERIFY_OTP_UPDATE_PHONE_NUMBER
                break;
            case t('old_email'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                    "action": "email"
                }
                url = VERIFY_OTP_EXISTING_PHONE_EMAIL_NUMBER
                break;
            case t('new_email'):
                requestObj = {
                    "email": email,
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                }
                url = VERIFY_UPDATE_EMAIL_OTP
                break;
            case t('old_address'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                    "action": "address"
                }
                url = VERIFY_OTP_EXISTING_PHONE_EMAIL_NUMBER
                break;
            case t('new_address'):
                var object = {};
                object = address;
                object.sessionId = sessionKey;
                // object.cardKey = selectedItem.CardKey;
                requestObj = object;
                url = VERIFY_UPDATE_ADDRESS_OTP
                break;
            case t('add_external_account'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                    "action": "mobile"
                }
                url = VERIFY_OTP_ADD_ACH_ACCOUNT
                break;
            case t('change_pin'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                }
                url = VERIFY_CHANGE_PIN
                break;
            case t('view_card_details'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                }
                url = VERIFY_OTP_VIEW_CARD
                break;
            case t('activate_card'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                }
                url = VERIFY_OTP_ACTIVATE_CARD
                break;
            case t('google'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "applicationId": appConfigData.CLIENT_ID,
                    "language": 'en'
                }
                url = OTP_USERS + userId + '/emails/' + sessionKey + '/verify'
                break;
            case t('facebook'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "applicationId": appConfigData.CLIENT_ID,
                    "language": 'en'
                }
                url = OTP_USERS + userId + '/emails/' + sessionKey + '/verify'
                break;
            case t('microsoft'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "applicationId": appConfigData.CLIENT_ID,
                    "language": 'en'
                }
                url = OTP_USERS + userId + '/emails/' + sessionKey + '/verify'
                break;
            case t('add_auth_user'):

                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "cardKey": selectedItem.CardKey,
                }
                url = VERIFY_OTP_ADD_AUTH_USER
                break;
            case t('apple_id'):
                requestObj = {
                    "sessionId": sessionKey,
                    "code": data.verifyCode,
                    "applicationId": appConfigData.CLIENT_ID,
                    "language": 'en'
                }
                url = OTP_USERS + userId + '/emails/' + sessionKey + '/verify'
                break;
            default:

                break;
        }
        var response = await PostRequest(url, requestObj, headers);
        let responseObj = JSON.parse(response);
        if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {

            setIsLoading(false)
            verifyResponse = responseObj
            return true;
        } else {
            setIsLoading(false)
            var arrMessages = responseObj.Messages;
            if (arrMessages.length > 0) {
                var messageObject = arrMessages[0];
                const message = messageObject.DisplayText;
                const formattedMessage = "<span className='font-hanken-grotesk text-md leading-7 text-oops_error pr-2'>Oops! </span>" + message;
                setMessage(message);
                setvalidCode(false);
            }
        }
    };

    const handleVerify = async () => {
        logFirebaseEvents("Personal_Information", "Verify_Otp_Clicked", "Verify_Otp_Clicked", "","");
        if (verifyCode.length == 6 && isValid !== false) {
            const success = await onSubmit({ verifyCode }); // Call onSubmit function and pass the input value 
            if (success) {
                const twoFactAuthenticationDone = { twoFactAuthenticationDone: true }
                dispatch({ type: 'TWOFACT_DONE', payload: twoFactAuthenticationDone });
                onClose();
                setTimeout(() => {
                    verificationSuccess(type, verifyResponse);
                    verifyResponse = {}
                }, 500);


            } else {
                verificationFailure(type, message);
            }
        } else {
            setMessage(t('otp_required'))
        }
    };

    const resendVerificationCode = async () => {
        setValue('verifyCode', '');
        const otpresendNumber = resendCount;
        if (otpresendNumber >= 4) {
            setMessage(t('exceeded_max_limit'))
            return;
        }
        setIsLoading(true)
        let headers = await getHeadersWithBearerOrWithoutBearer(type);
        let requestObj;
        let url;
        switch (type) {
            case t('fraud_protection'):
                requestObj = {
                    "lang": "en",
                    "appID": appConfigData.CLIENT_ID,
                    "code": props?.accessParam,
                    "sessionkey": sessionKey,
                }
                url = GENERATE_OTP_KYC_CIP
                break;
            case t('add_external_account'):
                requestObj = {
                    "applicationId": appConfigData.CLIENT_ID,
                    "language": "en"
                }
                url = RESEND_OTP_ADD_ACH_ACCOUNT.replace('{CARDKEY}', selectedItem.CardKey)
                break;
            case t('old_mobile'):
                requestObj = {
                    // "applicationId": appConfigData.CLIENT_ID,
                    // "language": "en",
                    "cardKey": selectedItem.CardKey,
                    "action": "mobile"
                }
                url = RESEND_EXISTING_PHONE_EMAIL_NUMBER
                break;
            case t('unlock_card'):
                requestObj = {}
                url = RESEND_UNLOCK_CARD_OTP.replace('{cardKey}', `${cards?.[0]?.CardKey}`)
                break;
            case t('old_email'):
                requestObj = {
                    // "applicationId": appConfigData.CLIENT_ID,
                    "cardKey": selectedItem.CardKey,
                    "action": "email"
                    // "language": "en"
                }

                url = RESEND_EXISTING_PHONE_EMAIL_NUMBER
                break;
            case t('new_mobile'):
                requestObj = { "cardKey": selectedItem.CardKey }
                url = RESEND_UPDATE_PHONE_NUMBER
                break;
            case t('new_email'):
                requestObj = { "cardKey": selectedItem.CardKey }
                url = RESEND_UPDATE_EMAIL_OTP
                break;
            case t('old_address'):
                // requestObj = {
                //     "cardKey": selectedItem.CardKey,
                //     "action": "mobile"
                // }
                // url = RESEND_EXISTING_PHONE_EMAIL_NUMBER;
                requestObj = {
                    "action": "address",
                    "cardKey": selectedItem.CardKey
                }
                url = GENERATE_EXISTING_PHONE_EMAIL_OTP
                break;
            case t('new_address'):
                requestObj = { "cardKey": selectedItem.CardKey }
                url = RESEND_UPDATE_ADDRESS_OTP
                break;
            case t('change_pin'):
                requestObj = { "cardKey": selectedItem.CardKey }
                url = RESENT_OTP_CHANGE_PIN
                break;
            case t('view_card_details'):
                requestObj = { "cardKey": cardDetails.CardKey }
                url = RESEND_OTP_VIEW_CARD
                break;
            case t('activate_card'):
                requestObj = { "cardKey": selectedItem.CardKey }
                url = RESEND_ACTIVATE_CARD_OTP
                break;
            case t('add_auth_user'):
                url = RESEND_OTP_ADD_AUTH_USER.replace('{CARD_KEY}', selectedItem.CardKey)
                break
            case t('change_password'):
                requestObj = {}
                url = RESEND_CHANGE_PASSWORD_TOP.replace('{cardKey}', selectedItem.CardKey);
            default:

                break;
        }

        PostRequest(url, requestObj, headers).then((response) => {
            let parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                let sessionIdPayload;
                // const sessionIdPayload = { loggedSessionKey: parsedResponse.Response.sessionId }
                if (props?.type == t('fraud_protection')) sessionIdPayload = { loggedSessionKey: parsedResponse.Response.sessionKey }
                else sessionIdPayload = { loggedSessionKey: parsedResponse.Response.sessionId }
                // dispatch({ type: 'SESSION_ID', payload: sessionIdPayload });
                dispatch({ type: LOGIN_SESSION_KEY, payload: sessionIdPayload });
                setTimeout(() => {
                    const resendNumber = resendCount + 1;
                    setResendCount(resendNumber);
                    setMessage('')
                    setIsLoading(false)
                }, 500);

            } else {
                setTimeout(() => {
                    var arrMessages = parsedResponse.Messages;
                    var message = arrMessages[0];
                    var displayText = message.DisplayText;
                    setMessage(displayText);
                    setIsLoading(false)
                }, 500);

            }
        })

    }

    const handleResendCodeForCIP = () => {
        resendVerificationCode();
        setTimer(30);
        setStartTimer(true)
    }
    const handleSubmit = (event) => {

        if (verifyCode.length == 6) {
            event.preventDefault();
            handleVerify();
        } else {
            setMessage(t('otp_required'))
        }
    };

    return (
        <>
            <div className="flex flex-row sm:w-[322px] w-full items-center">
                <div className="w-1/2">
                    <CustomInput
                        name="verifyCode"
                        value={verifyCode}
                        label="6-digit code"
                        register={register}
                        error={errors.verifyCode}
                        autoFocus={true}
                        type="6Code"
                        onChange={handleVerifyCodeChange}
                        isValid={isValid}
                        ref={inputRef}
                    // onKeyDownFun={(event) => { if (event.key == "Enter") { event.preventDefault(); handleVerifyCodeChange(); event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); } }}
                    />
                </div>
                <div div className="w-1/2 mb-4">
                    <button
                        type={'submit'}
                        ref={submitButtonRef}
                        className="font-hanken-grotesk lg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px] w-full bg-submit-btn hover:bg-submit-btn text-white py-2 px-4 font-medium lg:h-[60px] md:h-[50px] sm:h-[45px] h-[50px]"
                        onClick={handleVerify}
                        onKeyDown={(event) => {
                            if (event.key == "Enter") {
                                event.preventDefault();
                                handleVerify();
                                event.target.style.opacity = 0.8;
                                setTimeout(() => {
                                    event.target.style.opacity = 1;
                                }, 200);
                            }
                        }}
                    >
                        {t('verify')}
                    </button>
                </div>
                {/* <div className="w-[10%] ml-4 mb-4">
                    {isValid === true ? (
                        <img src={Check} size={20} alt="Check" />
                    ) : (
                        isValid === false && <img src={Error} size={20} alt="Error" />
                    )}
                </div> */}

                <div className="w-[10%] ml-4 mb-4">
                    {isValid === true ? (
                        <img src={Check} size={20} alt="Check" />
                    ) : (
                        verifyCode.length != 0 && <img src={Error} size={20} alt="Error" />
                    )}
                </div>
            </div>
            {message && props?.type !== t('fraud_protection') &&
                <div className='mb-2'>
                    {displayErrorMessage(message)}
                </div>
            }

            {props?.type === t('fraud_protection') &&
                <>
                    {message &&
                        <div className="flex sm:mx-0 mx-4">
                            <img src={Oops} alt="oops" className="md:w-[20px] md:h-[20px] w-[14px]
        h-[14px] mt-1" />
                            <p className="font-hanken-grotesk md:font-[700] sm:font-[600] font-[500] text-[16px]
       leading-[24px] md:text-[18px] md:leading-[28px] sm:text-[12px] 
       sm:leading-[18px] text-secondary-3 ">
                                <span className="text-[#30BC89] font-[600] ml-2 
        lg:font-[600]"> {t("oops_exclamation")} </span>{message}</p>
                        </div>
                    }
                    {!startTimer &&
                        <div className=" flex flex-row items-center gap-2">
                            <span className=" text-secondary">
                                <img src={TimerIcon} className="md:w-[20px] md:h-[20px] sm:w-[14px] sm:h-[14px] w-[14px] h-[14px]" />
                            </span>
                            <button className="font-hanken-grotesk font-[600] text-[16px] sm:text-[12px]
             leading-[24px] md:text-[18px] sm:leading-[18px]
             md:leading-[28px] text-[#2965FB] self-start"
                                onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; handleResendCodeForCIP(); setTimeout(() => { event.target.style.opacity = 1; }, 200); } }} onClick={(event) => { event.target.style.opacity = 0.8; handleResendCodeForCIP(); setTimeout(() => { event.target.style.opacity = 1; }, 200); }}
                            >{t("cip_resend_code")}</button>
                        </div>
                    }
                </>

            }
            <div className=" flex flex-row gap-3 items-center md:mb-28 sm:mb-12 mb-28">
                <span className={`text-secondary ${props?.type === t('fraud_protection') && !startTimer && 'hidden'}`} >
                    <img src={TimerIcon} className="md:w-[20px] md:h-[20px] sm:w-[14px] sm:h-[14px] w-[14px] h-[14px]" />
                </span>
                <p className="font-hanken-grotesk lg:text-md md:text-[14px] sm:text-[12px] text-[14px] leading-7 text-secondary-3 font-medium cursor-pointer pointer-events-none">
                    {props?.type === t('fraud_protection') ? ((startTimer) ? `Resend code in: 00:${String(timer).padStart(2, '0')}` : '') : t("security_check_notification")}
                </p>
            </div>
            {!(props?.accessParam) &&
                <p className="font-hanken-grotesk lg:text-md md:text-[14px] sm:text-[12px] text-[16px]  leading-7 text-secondary-3 font-medium">
                    {props?.selected2FAType ? (props?.selected2FAType == 'Email') ? t("didnt_recieve_email") : ((props?.selected2FAType == 'Text') && t("security_check_subheading2")) : t("security_check_subheading2")}{" "}
                    <button className='font-hanken-grotesk text-[#2965FB] font-semibold cursor-pointer' onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; resendVerificationCode(); setTimeout(() => { event.target.style.opacity = 1; }, 200); } }} onClick={(event) => { event.target.style.opacity = 0.8; resendVerificationCode(); setTimeout(() => { event.target.style.opacity = 1; }, 200); }} >{t("try_again")}<span className="dot"></span></button>
                </p>
            }
            {isLoading && <Loader />}
        </>
    );
};

export default Custom2FA;
