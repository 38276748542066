import React, { useEffect, useState, useRef } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HomeLayout from '../../Layout/HomeLayout';
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../../CustomComponents/DynamicCard';
import FindATM from '../../../assets/images/illo-ATM.png'
import { useNavigate } from 'react-router';
import Accordion from '../../CustomComponents/Accordation';
import CustomDropdown from '../../CustomComponents/CustomDropDown';
import CustomTextInput from '../../CustomComponents/CustomtextInput';
import { AcceptEsignAgreement, CHECKING, EsignAgreement, EsignStatus, displayErrorMessage, getUserProfileInfo, retrieveData } from '../../../utils';
import { width } from '../../CustomComponents/DynamicCard/DynamicCardStyles';
import CustomTextArea from '../../CustomComponents/CustomTextArea';
import { contactUsTypes } from '../../../utils';
import { CREATE_TICKET, STATUS_SUCCESS_CODE } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetApiHeadersWithBearerForSupport, PostRequest, getRequestfreshDeskDomian } from '../../../network/NetworkUtils';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import Loader from '../../CustomComponents/Loader';
import { HelpSidebar } from '../../../utils';
import logFirebaseEvents from '../../LogFirebaseEvents';


const ContactUs = () => {
    const { t } = useTranslation();
    const [types, setTypes] = useState();
    const [SelectedChoiceType, setSelectedChoiceType] = useState({ Name: "" });
    const [TypeValidationError, setTypeValidationError] = useState('');
    const [CommnetsValidationError, setCommnetsValidationError] = useState('');
    const [comments, setComments] = useState('');
    const accessToken = useSelector(state => state.accessToken);
    const [showIframe, setIframe] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const [AgreementTittle, setAgreementTittle] = useState('');
    const iframeSrc = `data:text/html;charset=utf-8,${encodeURIComponent(htmlContent)}`;
    const [customerCareNumber, setCustomerCareNumber] = useState('')
    const [customerCareEmail, setCustomerCareEmail] = useState('')
    const [customerCareNumberTitle, setCustomerCareNumberTitle] = useState('')
    const [customerCareEmailTitle, setCustomerCareEmailTitle] = useState('')
    const [toNavigate, setToNavigate] = useState('');

    const [AcceptStatus, setAcceptStatus] = useState(false);
    const [ContentId, setContentId] = useState('');
    const [CreateRequestObject, setCreateRequestObject] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    ///alert modals

    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px]  font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none leading-[30px]";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [modelImage, setModelImage] = useState('')
    const [showModel, setShowModel] = useState(false);
    const [loading, setLoading] = useState(false)


    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    const closeCustomAlertModal = () => {
        setShowModel(false);
    }

    const onBackBtnClick = () => {
        if (btn1Text == t('conact_button')) {
            setShowModel(false);
            setSelectedChoiceType({ Name: "" });
            setComments('');
        }
    }




    const cards = useSelector(state => JSON.parse(state.userCards));

    const initialCards = useRef(cards); // Store initial cards

    useEffect(() => {
        if (initialCards?.current?.length > 0) {
            const freshDeskDomain = initialCards.current[0].FreshDeskDomain;
            const freshDeskAPIKey = initialCards.current[0].FreshDeskAPIKey;
            const freshDeskCategoryID = initialCards.current[0].FreshDeskCategoryID;

            callCategoriesAPI(freshDeskDomain, freshDeskCategoryID, freshDeskAPIKey)

            contactUsTypes(freshDeskDomain, freshDeskAPIKey)
                .then(response => {
                    const tempChoiceTypes = response.map(item => ({ Name: item }));
                    setTypes(tempChoiceTypes)
                })
                .catch(error => {
                    console.error("Error fetching Conatct us Types:", error);
                });
        }
    }, []);

    useEffect(() => {
    }, [customerCareEmailTitle, customerCareNumberTitle])

    const callCategoriesAPI = async (freshDeskDomain, freshDeskCategoryID, freshDeskAPIKey) => {
        setLoading(true)
        var url = "https://" + freshDeskDomain + "/api/v2/solutions/categories/" + freshDeskCategoryID + "/folders";
        let headers = await GetApiHeadersWithBearerForSupport(freshDeskAPIKey)
        var response = await getRequestfreshDeskDomian(url, headers)
        var responseObj = JSON.parse(response);
        setLoading(false)
        setTimeout(() => {

            if (responseObj != undefined && responseObj.length > 0) {
                var listItems = [];
                let contactId = '';
                listItems = responseObj;
                for (var i = 0; i < listItems.length; i++) {
                    if (listItems[i].name == t('contact')) {
                        contactId = listItems[i].id
                    }
                }
                getContactDetails(contactId, freshDeskDomain, freshDeskAPIKey)
            }
        }, 1000);
    }
    const getContactDetails = async (contactId, freshDeskDomain, freshDeskAPIKey) => {

        var url = "https://" + freshDeskDomain + "/api/v2/solutions/folders/" + contactId + "/articles?per_page=40";
        let headers = await GetApiHeadersWithBearerForSupport(freshDeskAPIKey)

        var response = await getRequestfreshDeskDomian(url, headers)
        var parsedResponse = JSON.parse(response);
        if (parsedResponse != undefined && parsedResponse.length > 0) {
            for (var i = 0; i < parsedResponse.length; i++) {
                if (parsedResponse[i].title.toString() == t('customer_care_new')) {
                    setCustomerCareNumber(parsedResponse[i].description_text)
                    setCustomerCareNumberTitle(parsedResponse[i].title)

                } else {
                    setCustomerCareEmail(parsedResponse[i].description_text)
                    setCustomerCareEmailTitle(parsedResponse[i].title)
                }
            }
        }
    }


    //validate 
    const validateType = () => {
        if (!SelectedChoiceType.Name) {
            setTypeValidationError('Please select a type');
            return false;
        }
        setTypeValidationError('');
        return true;
    };

    const validateComments = () => {
        if (!comments) {
            setCommnetsValidationError('Please enter comments');
            return false;
        } else if (comments.length < 15) {
            setCommnetsValidationError('Comments must be at least 15 characters');
            return false;
        }

        setCommnetsValidationError('');
        return true;
    };

    //on submit
    const handleFormSubmit = (e) => {
        logFirebaseEvents("Contact_Us", "Submit_FeedBack_Clicked", "Submit_FeedBack_Clicked","", "")
        e.preventDefault();
        if (!validateType()) return;
        if (!validateComments()) return;
        //  debugger;
        setLoading(true)

        const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;
        EsignStatus(isPrimrayKey, accessToken).then(response => {

            if (response === false) {

                ////call esign agreement and show the agreement
                EsignAgreement(isPrimrayKey, accessToken).then(response => {
                    //    debugger;
                    //setLoading(true)
                    setAgreementTittle(response.Title);
                    setContentId(response.ProgramContentID);
                    setIframe(true);
                    setLoading(false)
                    if (response?.Content != undefined) {
                        setHtmlContent(response.Content)
                    }
                });
            } else {

                setLoading(true)
                ////get user profile info details
                getUserProfileInfo(isPrimrayKey, accessToken).then(response => {


                    var RequestObject = {
                        description: comments,
                        subject: SelectedChoiceType.Name + "-Review Request",
                        email: response.EmailID,
                        priority: 3,
                        status: 2,
                        type: SelectedChoiceType.Name,
                        custom_fields: {
                            first_name: response.FirstName,
                            last_name: response.LastName,
                            last_4_digits_of_card: cards[0].CardDisplayNumber.replace(/x/g, ''),
                            program_id: cards[0].ProgramKey,
                            card_id: isPrimrayKey
                        }

                    }

                    setCreateRequestObject(RequestObject);
                    submitFeedback(RequestObject);


                }).catch(error => {
                });


            }

        })
            .catch(error => {
            });
    };

    const goBack = () => {
        setIframe(false);
    }

    const onAccept = () => {
        setLoading(true);
        const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;
        AcceptEsignAgreement(isPrimrayKey, accessToken, ContentId).then(response => {

            if (response.ResponseCode == STATUS_SUCCESS_CODE) {
                setIframe(false);
                setLoading(false)
            } else {
                setIframe(true);
                setLoading(false)
            }

        }).catch(error => {
        });

    }

    ////create ticked on submit
    const submitFeedback = async (RequestObject) => {
       
        if (cards == null || cards == undefined) {
            return
        }
        setLoading(true)

        const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const url = CREATE_TICKET(isPrimrayKey);
        const response = await PostRequest(url, RequestObject, headers);
        const responseJson = JSON.parse(response);
        if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
            setLoading(false)
            showAlertModal(true, t('success'), t('conactus_msg'), '', true, false, t('conact_button'), t('Cancel'), true, '');

        } else {

            setLoading(false)
            showAlertModal(true, t('oops'), t('contact_error'), '', true, false, t('conact_button'), t('Cancel'), true, '');

        }
    }

    const customercaresec = 'sm:w-[50%] w-full flex flex-col font-hanken-grotesk';
    const customercaretext = 'text-[#262B3A] uppercase lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]  font-[600] tracking-[2px] opacity-[50%]'
    const customercarenum = 'sm:pt-10 pt-6 lg:text-[16px] md:text-[15px] sm:text-[13px] text-[13px] text-[#2965FB] leading-[16px] font-hanken-grotesk font-bold'

    return (
        <>
            <HomeLayout>
                <HelpSidebar HelpselectedIndex={4} />
                {/* <LeftBar leftBarType='component'
                    isPrimary={true} leftBarComp={leftBarComp()} /> */}
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight '>


                    <DynamicCard
                        header={true}
                        CardRadius={true}
                        cardWidth={width.homeCard}
                        HeaderRadius={true}
                        HeaderContent={false}
                        isWhite={true}
                        footer={true}
                    >
                        {
                            !showIframe ? (
                                <div className='w-full'>
                                    <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] md:font-[500] sm:font-[600] 
                       lg:text-[26px] md:text-[22px] 
                       sm:text-[15px] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">
                                        {(t('contact_us'))}
                                    </div>

                                    <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4'>
                                        <div className='w-full sm:flex justify-center md:block'>
                                            <div className='lg:w-[90%] md:w-[93%] sm:w-[85%] pt-10 '>
                                                <p className='md:text-[20px]  sm:text-[12px] text-[14px]
                                             font-hanken-grotesk font-[400] sm:font-medium md:leading-[30px] sm:leading-[18px] leading-[20px'>
                                                    {(t('any_question'))}  </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex justify-center sm:w-full sm:mx-0 mx-4'>
                                        <div className='md:w-[70%] sm:w-[85%] w-full'>
                                            <form className='md:mt-16 md:mb-16 sm:mb-10 mb-12' onSubmit={handleFormSubmit}>
                                                <div className='md:mt-16 mt-12 mb-12'>
                                                    <CustomDropdown
                                                        options={types}
                                                        title={t('selectType')}
                                                        tabIndex={0}
                                                        onSelect={(option) => {
                                                            setShowModel(false); setSelectedChoiceType(option); setTypeValidationError('');
                                                        }}
                                                        selectedAccountType={SelectedChoiceType}
                                                    />
                                                    {TypeValidationError && displayErrorMessage(TypeValidationError)}
                                                </div>
                                                <div className='md:mt-16 md:mb-8 sm:mt-10 sm:mb-8 mt-16 mb-8'>
                                                    <CustomTextArea
                                                        name={t('comments')}
                                                        value={comments}
                                                        //rows={comments.split('\n').length || 1}
                                                        multilineEnabled={true}
                                                        onChange={(e) => { setComments(e.target.value); setCommnetsValidationError(''); }}
                                                    />
                                                    {CommnetsValidationError && displayErrorMessage(CommnetsValidationError)}
                                                </div>

                                                <div className='flex justify-end'>
                                                    <button type='submit' className='text-[#2965FB] 
                                                    font-hanken-grotesk  lg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px]
                                                    font-bold cursor-pointer '>
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4 border-opacity-[50%]'></div>

                                    <div className='sm:flex block md:pt-16 sm:pt-10 md:pb-20 sm:pb-16 py-14 sm:px-0 px-4'>
                                        <div className={customercaresec}>
                                            <span className={customercaretext}> {(customerCareNumberTitle)}</span>
                                            <span className={customercarenum}> {(customerCareNumber)}</span>
                                            <span className='pt-4 md:text-[14px] sm:text[13px] text-[14px] leading-[22px] 
                                text-[#262B3A] font-medium'> {(t('avaiable24_7'))}</span>
                                        </div>
                                        <div className={`${customercaresec} sm:mt-0 mt-14`}>
                                            <span className={customercaretext}> {(customerCareEmailTitle)}</span>
                                            <span className={customercarenum}> {(customerCareEmail)}</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                      lg:text-[26px] md:text-[22px] 
                      sm:text-[20px] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">
                                        {AgreementTittle}
                                    </div>
                                    <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4'></div>

                                    <iframe src={htmlContent} title="Esign Agreement" width="100%" height="850px"
                                        style={{ overflow: 'hidden' }}></iframe>

                                    <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4 mb-4'></div>

                                    <div className='flex md:pb-20 pb-4 justify-between'>
                                        <span className='text-[#2965FB] font-hanken-grotesk md:text-[18px] font-bold cursor-pointer' onClick={goBack}>Skip</span>
                                        <span className='text-[#2965FB] font-hanken-grotesk md:text-[18px] font-bold cursor-pointer' onClick={onAccept}>Accept</span>
                                    </div>
                                </>
                            )}

                    </DynamicCard>

                </div>

                {loading && <Loader />}
            </HomeLayout >
            <CustomAlertModel
                isOpen={showModel}
                isBorderNone={true}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text}
                btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={() => { onBackBtnClick() }}
            />

        </>

    )
}

export default withOidcSecure(ContactUs)