

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from '../../../CustomComponents/FormInputs'
import { useNavigate } from 'react-router'
import { withOidcSecure } from '@axa-fr/react-oidc';
import Modal from "../../../CustomComponents/Modal";
import ClimbIcon from '../../../../assets/images/illo-climb.png';
import { useDispatch, useSelector, connect } from 'react-redux';
import { SAVING, retrieveData } from '../../../../helpers/utils/Utils';
import { STATUS_SUCCESS_CODE, GET_CLIMB_FEATURES, CLIMB_OPT_IN_OUT, AGREEMENTS, SAVING_CARD_CODE } from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import Loader from '../../../CustomComponents/Loader';
import Tickicon from '../../../../assets/images/Check_white.png';
import SpendIcon1 from '../../../../assets/images/illo-spendsense.png';
import SpendIcon2 from '../../../../assets/images/spend_alert_2.png';
import SavingHabitIcon1 from '../../../../assets/images/savings-habits-1.png';
import RadialFill from '../../../../assets/images/Checkbox-blue.png'
import RadialNull from '../../../../assets/images/Checkbox-light.png'

import CoveredByKudzuIcon1 from '../../../../assets/images/savings-habits.png';
import LineBreaks, { addLineBreaks, displayErrorMessage } from "../../../../utils";
import CustomAlertModel from "../../../CustomComponents/CustomAlertModel";
import Arrow from '../../../../assets/images/arrow.png';
import { useKeyDown } from "../../../../hooks/useKeyDown";
import debounce from 'lodash/debounce';
import logFirebaseEvents from "../../../LogFirebaseEvents";





const CoveredByKudzu = () => {

    const navigate = useNavigate();
    const [infoMessage, setinfoMessage] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isClimbModalOpen, setIsClimbModalOpen] = useState(true);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessToken = useSelector(state => state.accessToken);
    const [isClimbings, setisClimbings] = useState([]);
    const [disclosureAgrmt, setDisclosureAgrmt] = useState(false);
    const [showPolicys, setShowPolicys] = useState('terms');
    const [renderModel, setRenderModel] = useState('');
    const [policyURL, setPolicyURL] = useState('');
    const [showAgreementsModel, setShowAgreementsModel] = useState(false);

    //Covered By Kudzu
    const [IsCoveredByKudzuModalOpen1, setIsCoveredByKudzuModalOpen1] = useState(false);
    const [isCoveredByKudzuModalOpen2, setIsCoveredByKudzuModalOpen2] = useState(false);
    const [isCoveredByKudzuModalOpen3, setIsCoveredByKudzuModalOpen3] = useState(false);
    const [isCoveredByKudzuDiscloserOpen, setIsCoveredByKudzuDiscloserOpen] = useState(false);
    const [isCoveredByKudzuOptInSuccess, setIsCoveredByKudzuOptInSuccess] = useState(false);
    const [isCoveredByKudzuIneligible, setIsCoveredByKudzuIneligible] = useState(false);
    const [isCoveredByKudzuIneligibleSetupSavings, setIsCoveredByKudzuIneligibleSetupSavings] = useState(false);
    const [isCoveredByKudzuIneligibleSetupSavingsPostPopup, setIsCoveredByKudzuIneligibleSetupSavingsPostPopup] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [type, setType] = useState('')
    const [footerMsg, setFooterMsg] = useState('');

    const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;
    const hasSavingsAccount = cards?.some(item => item.AccountTypeName === "Savings");
    const SavingsAccount = cards?.find(({AccountTypeBit63}) => AccountTypeBit63 === SAVING_CARD_CODE);

    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const savingsWithBalanceOver50 = cards.find(
        obj => obj.AccountTypeBit63 == 32 && obj.Balance >= 50
      );
    // const isMobile = false





    const SubHeadingStyles = "font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-3";
    const SubHeadingStyles2 = "font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-2 pl-1 pr-1 mb-4";
    const SubHeadingStyles4 = "font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-2 mb-4";
    const SubHeadingStyles3 = "font-hanken-grotesk modal-title sm:text-[18px] lg:text-[20px] md:text-[20px] text-[20px] sm:leading-[30px] lg:leading-[38px] md:leading-[30px] leading-[30px] font-normal text-secondary-3";
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    //close modal redirection to manage cards


    const handleClick = (type) => {  
        logFirebaseEvents("Covered_By_Kudzu", "Terms_Condition_Covered_By_Kudzu_Clicked", "Terms_Condition_Covered_By_Kudzu_Clicked","", "");
        setShowPolicys((prevState) => {
            if (type === 'userAgreement') {
                LinkClick("user agreement");
            }
        });

    };

    const LinkClick = (href) => {
        // e.preventDefault();
        // e.stopPropagation();

        switch (href) {
            case "user agreement":
                getAgreementsBasedOnType("CoveredByKudzuTerms")
                // setShowAgreementsModel(true);
                break;

            default:
                break;
        }
    };

    const getAgreementsBasedOnType = async (url) => {
        if (isPrimrayKey == undefined || isPrimrayKey == null) {
            return
        }
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        setIsLoading(true)

        // let finalUrl = (PROGRAM_CONTENT + "?accountType=64&language=en&contentType=" + url + "&programKey=200")
        let finalUrl = AGREEMENTS.replace('{contentType}', url).replace('{cardKey}', isPrimrayKey)
        var response = await GetRequest(finalUrl, headers)
        var responseObj = JSON.parse(response)
        setIsLoading(false)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setRenderModel(responseObj.Response.RenderModel);
            setPolicyURL(responseObj.Response.Content);
            setShowAgreementsModel(true);
        } else {
            setTimeout(() => {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                // setError(displayText)
            }, 500);
        }
    }




    const closeSkipModal = () => {
        logFirebaseEvents("Covered_By_Kudzu", "Skip_Covered_By_Kudzu_Clicked", "Skip_Covered_By_Kudzu_Clicked","", "");

        if (!hasSavingsAccount) {
            {
                setIsCoveredByKudzuModalOpen1(false);
                setIsCoveredByKudzuModalOpen2(false);
                setIsCoveredByKudzuModalOpen3(false);
                setIsCoveredByKudzuIneligible(false)
                setIsCoveredByKudzuDiscloserOpen(false)
                setIsCoveredByKudzuIneligibleSetupSavings(true)
                setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false)
            }
        }else if (hasSavingsAccount && savingsWithBalanceOver50 == undefined) {
            {
                setIsCoveredByKudzuModalOpen1(false);
                setIsCoveredByKudzuModalOpen2(false);
                setIsCoveredByKudzuModalOpen3(false);
                setIsCoveredByKudzuDiscloserOpen(false)
                setIsCoveredByKudzuIneligibleSetupSavings(false)
                setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false)
                setIsCoveredByKudzuIneligible(true)
            }
        }
        else {
            if (SavingsAccount.OTB >= 50) {
                setIsCoveredByKudzuModalOpen1(false);
                setIsCoveredByKudzuModalOpen2(false);
                setIsCoveredByKudzuModalOpen3(false);
                setIsCoveredByKudzuIneligibleSetupSavings(false)
                setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false)
                setIsCoveredByKudzuIneligible(false)
                setIsCoveredByKudzuDiscloserOpen(true)
            } else {

                setIsCoveredByKudzuModalOpen1(false);
                setIsCoveredByKudzuModalOpen2(false);
                setIsCoveredByKudzuModalOpen3(false);
                setIsCoveredByKudzuDiscloserOpen(false)
                setIsCoveredByKudzuIneligibleSetupSavings(false)
                setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false)
                setIsCoveredByKudzuIneligible(true)
            }
        }
    };



    const coveredByKudzuOptIn = async (item) => {
        if (cards == null || cards == undefined) {
            return
        }

        setIsCoveredByKudzuModalOpen1(false);
        setIsCoveredByKudzuModalOpen2(false);
        setIsCoveredByKudzuModalOpen3(false);
        setIsCoveredByKudzuDiscloserOpen(false);

        setIsLoading(true);
        const url = CLIMB_OPT_IN_OUT.replace('{CARD_KEY}', cards[0].CardKey) + 'CoveredByKudzu' + '/opt-in'
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const response = await PostRequest(url, {}, headers)

        setTimeout(() => {
            setIsLoading(false)
        }, 500);

        const responseObj = JSON.parse(response);

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            setIsCoveredByKudzuOptInSuccess(true);
        } else {
            setIsCoveredByKudzuDiscloserOpen(false);
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("covered_by_cudzu"), displayText, '', true, false, t("home"), '', true, require('../../../../assets/images/warning.png'), false, false)
        }

    }

    const MangageSpendAlerts = () => {
        // alert('HIASHS');
        navigate('/Climb/ClimbMenu');
    }

    // Covered By Kudzu 
    const handleCoveredByKudzuIntro1=()=>{
        setIsCoveredByKudzuModalOpen1(true);
    }
    const handleCoveredByKudzuIntro2 = () => { 
        logFirebaseEvents("Covered_By_Kudzu", "Learn_More_Covered_By_Kudzu_Clicked", "Learn_More_Covered_By_Kudzu_Clicked","", "");
        setIsCoveredByKudzuModalOpen1(false);
        setIsCoveredByKudzuModalOpen2(true);
        setIsCoveredByKudzuModalOpen3(false);
        setIsCoveredByKudzuDiscloserOpen(false)
        setIsCoveredByKudzuIneligibleSetupSavings(false)
        setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false)
        setIsCoveredByKudzuIneligible(false)
    }

    const handleCoveredByKudzuIntro3 = () => {
        logFirebaseEvents("Covered_By_Kudzu", "Continue_Covered_By_Kudzu_Clicked", "Continue_Covered_By_Kudzu_Clicked","", "");
        setIsCoveredByKudzuModalOpen1(false);
        setIsCoveredByKudzuModalOpen2(false);
        setIsCoveredByKudzuModalOpen3(true);
        setIsCoveredByKudzuDiscloserOpen(false)
        setIsCoveredByKudzuIneligibleSetupSavings(false)
        setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false)
        setIsCoveredByKudzuIneligible(false)
    };
    const handleCoveredIntro3Skipped = () => {
        setIsCoveredByKudzuModalOpen1(false);
        setIsCoveredByKudzuModalOpen2(false);
        setIsCoveredByKudzuModalOpen3(false);
        setIsCoveredByKudzuDiscloserOpen(false)
        setIsCoveredByKudzuIneligibleSetupSavings(false)
        setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false)
        setIsCoveredByKudzuIneligible(false)
    };

    useEffect(() => { 
        // setTimeout(()=>handleCoveredByKudzuIntro1() ,100)
        // handleCoveredByKudzuIntro1() 
        setTimeout(() => {
            setIsCoveredByKudzuModalOpen1(true);
          }, 100)
    }, [])
    // useEffect(() => { debounce(handleCoveredByKudzuIntro1, 100) }, [])



    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    const closeCustomAlertModal = () => {

        setIsCoveredByKudzuDiscloserOpen(false);
        setShowModel(false);
        // if (highlightMsg == t('covered_by_cudzu')) { 
        navigate('/Climb/ClimbMenu');
        //  }
    }
    const onAcptBtnClick = async () => {
        navigate('/home')

    }
    const onCancelBtnClick = () => {

    }

    useKeyDown(() => {
        if (isCoveredByKudzuIneligibleSetupSavings) {
            setIsCoveredByKudzuIneligibleSetupSavings(false);
            setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true);
        } else if (isCoveredByKudzuIneligibleSetupSavingsPostPopup) {
            navigate('/SetupSavingsAccount');
            dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('climb_savings_enrollment') } });
        }
    }, ["Enter"]);





    return (
        <>

            <>
                {/* //Covered By Kudzu intro 1 */}

                {IsCoveredByKudzuModalOpen1 &&<Modal
                    isOpen={IsCoveredByKudzuModalOpen1}
                    onClose={closeCustomAlertModal}
                    // onClose={debounce(closeCustomAlertModal, 100)}
                    isClimbg={true}
                    subtitle={<>
                        <span className={SubHeadingStyles}>{t("opt_in_to")}</span>
                        <span className={SubHeadingStyles2}>{t("kudzu_star")}</span>
                        <span className={SubHeadingStyles}>{t("to_make_sure_your_covered_with_money")}</span>
                    </>
                    }
                    content={<div className="pt-10 pb-10">
                        <img src={CoveredByKudzuIcon1} className="block sm:m-auto mx-auto lg:w-[220px] lg:h-[220px] 
          md:w-[170px] md:h-[170px] w-[129px] h-[129px] my-16" />
                        <p className="font-hanken-grotesk font-[400]
                                   text-[12px] leading-[18px]
                                 text-[#4B70B9] mb-16">{t("cbk_intro2_subtext")}</p>
                        {/* <div className="sm:absolute md:right-[-50px] sm:right-[0px] bottom-[0.5rem] w-full"> */}
                            <div className="flex sm:justify-end justify-between md:gap-14 sm:gap-8">

                                <button
                                    type="button"
                                    className="font-hanken-grotesk sm:font-bold font-[600]
                                  lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                                 text-[#99AED7] flex items-center disabled"
                                    onClick={debounce(closeSkipModal, 100)}
                                >
                                    {t('skip')}
                                </button>
                                <button
                                    type="button"
                                    autoFocus
                                    className="focus:outline-none   font-hanken-grotesk sm:font-bold font-[600]
                                lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB]
                                 cursor-pointer flex items-center"
                                    onClick={debounce(handleCoveredByKudzuIntro2, 100)}
                                >
                                    {t('learn_more')}<img
                                        src={Arrow}
                                        className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px]
                     h-[14px] ml-1"
                                        alt="Arrow" />
                                </button>
                            </div>
                        {/* </div> */}
                    </div>}
                />}


                {/* //Covered By Kudzu intro 2 */}
                {isCoveredByKudzuModalOpen2 &&<Modal
                    isOpen={isCoveredByKudzuModalOpen2}
                    onClose={debounce(closeCustomAlertModal, 100)}
                    isClimbg={true}
                    subtitle={<>
                        <p className={`${SubHeadingStyles} block`}>{t("covered_by_kudzu2_heading1")}
                            <span className={SubHeadingStyles2}>{t("covered_by_kudzu2_heading2")}</span></p>

                    </>
                    }
                    content={<div className=" m-auto md:pt-16 pt-8 pb-5 h-auto w-full flex flex-col">
                        <img src={require('../../../../assets/images/overageMonth.png')}
                            className="block sm:m-auto mx-auto lg:w-[300px] lg:h-[280px] 
                            md:w-[320px] md:h-[313px] sm:[240px] sm:h-[220px] w-[250px] h-[230px]" />
                        <div className="w-full flex flex-row justify-between lg:mt-32 md:mt-20 mt-20">
                            <div>
                                <button
                                    type="button"
                                    className="sm:block hidden font-hanken-grotesk sm:font-bold font-[600]
                                 lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]"
                                >
                                    {t('questions_see_our')} <button onClick={() => { navigate('/Help/FAQ');  logFirebaseEvents("Covered_By_Kudzu", "Faq_Covered_By_Kudzu_Clicked", "Faq_Covered_By_Kudzu_Clicked","", ""); }}
                                        className="text-[#99AED7]">{t('faq')}</button>
                                </button>

                                <button
                                    type="button"
                                    className="sm:hidden block font-hanken-grotesk sm:font-bold font-[600]
                                 lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]"
                                >  <button onClick={() => { navigate('/Help/FAQ') }}
                                    className="text-[#99AED7]">{t('faq')}</button>
                                </button>
                            </div>

                            <div className="">
                                <div className="sm:absolute md:right-[-6rem] sm:right-[-4rem] flex sm:justify-end justify-end md:gap-14 sm:gap-8">
                                    <button type="button" className="sm:flex hidden font-hanken-grotesk sm:font-bold font-[600]
 lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
 text-[#99AED7]  items-center disabled"  onClick={debounce(closeSkipModal, 100)}> {t('skip')}</button>

                                    <button type="button" className="font-hanken-grotesk sm:font-bold font-[600]
 lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB]
 cursor-pointer flex items-center focus:outline-none  " onClick={debounce(handleCoveredByKudzuIntro3, 100)} autoFocus> {t('continue')}<img src={Arrow} className="md:w-[14px] sm:w-[11px]
  md:h-[14px] sm:h-[11px] w-[14px] h-[14px] ml-1" alt="Arrow" /> </button></div></div>

                        </div>
                    </div>}
                />}

                {/* //Covered By Kudzu intro 3 */}

                {isCoveredByKudzuModalOpen3 && <Modal
                    isOpen={isCoveredByKudzuModalOpen3}
                    onClose={debounce(closeCustomAlertModal, 100)}
                    isClimbg={true}
                    subtitle={<>
                        <span className={SubHeadingStyles} style={{ width: '453px' }}>{(t('to_opt_in_you_just_need'))} </span>
                        <span className={SubHeadingStyles2}>{t('a_minimum_of_$50_available')}</span>
                        <span className={SubHeadingStyles}>{t("in_your_savings_account")}</span>
                    </>
                    }
                    content={
                        <div className="m-auto pt-5 pb-5 h-auto w-full flex flex-col">
                            <img src={require('../../../../assets/images/kudzu_50$.png')}
                                className="block sm:m-auto mx-auto lg:w-[220px] lg:h-[220px] 
          md:w-[170px] md:h-[170px] w-[129px] h-[129px] my-16" />
                            <div className="w-full flex flex-row justify-between mt-20">
                                <button
                                    type="button"
                                    className="sm:block hidden font-hanken-grotesk sm:font-bold font-[600]
                                     lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]">
                                    {t('questions_see_our')} <button onClick={() => { navigate('/Help/FAQ') }}
                                        className="text-[#99AED7]">{t('faq')}</button>
                                </button>
                                <button
                                    type="button"
                                    className="sm:hidden block font-hanken-grotesk sm:font-bold font-[600] 
                                    lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-[#000000]" >
                                    <button onClick={() => { navigate('/Help/FAQ'); logFirebaseEvents("Covered_By_Kudzu", "Faq_Covered_By_Kudzu_Clicked", "Faq_Covered_By_Kudzu_Clicked","", "");  }}
                                        className="text-[#99AED7]">{t('faq')}</button>
                                </button>
                                <button
                                    type="button"
                                    autoFocus
                                    className="focus:outline-none   font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px]
                                     md:text-[17px] sm:text-[13px] text-[16px] text-[#2965FB] cursor-pointer flex items-center"
                                    onClick={
                                        debounce(() => {

                                            if (!hasSavingsAccount) {
                                                {
                                                    setIsCoveredByKudzuModalOpen1(false);
                                                    setIsCoveredByKudzuModalOpen2(false);
                                                    setIsCoveredByKudzuModalOpen3(false);
                                                    setIsCoveredByKudzuIneligibleSetupSavings(true)
                                                }
                                            }
                                            else {

                                                if (SavingsAccount.Balance >= 50) {
                                                    setIsCoveredByKudzuModalOpen1(false);
                                                    setIsCoveredByKudzuModalOpen2(false);
                                                    setIsCoveredByKudzuModalOpen3(false);
                                                    setIsCoveredByKudzuDiscloserOpen(true)
                                                } else {
                                                    setIsCoveredByKudzuModalOpen1(false);
                                                    setIsCoveredByKudzuModalOpen2(false);
                                                    setIsCoveredByKudzuModalOpen3(false);
                                                    setIsCoveredByKudzuIneligible(true)
                                                }
                                            }; logFirebaseEvents("Covered_By_Kudzu", "Get_Start_Covered_By_Kudzu_Clicked", "Get_Start_Covered_By_Kudzu_Clicked","", ""); 
                                        }
                                            , 100)
                                    }
                                >
                                    {t('get_started')}<img
                                        src={Arrow}
                                        className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px] h-[14px] ml-1"
                                        alt="Arrow" />
                                </button>
                            </div>
                        </div>}
                />}

                {/* //Covered By Kudzu Discloser agreement */}

                {isCoveredByKudzuDiscloserOpen &&<Modal
                    isOpen={isCoveredByKudzuDiscloserOpen}
                    Fullheight={true}
                    onClose={debounce(closeCustomAlertModal, 100)}
                    isClimbg={true}
                    subtitle={
                        <span className={SubHeadingStyles}>{t("coveredby_kudzu_terms")}</span>
                    }
                    content={<div className="md:pt-5 sm:pt-1 pb-2">
                        {/* <div className="font-hanken-grotesk lg:text-[14px] md:text-[13px] sm:text-[12]
                         text-[13px] font-normal leading-[24px] my-20">
                            <p className="md:pt-5 pt-3">
                                {t('lorem_ipsum_dolor')}</p>
                            <p className="md:pt-5 pt-3">
                                {t('duis_aute_irure_dolor')}
                            </p>
                            <p className="md:pt-5 pt-3 sm:block hidden">
                                {t('lorem_ipsum_dolor_sit_amet')}</p>
                        </div> */}
                        <p className="font-hanken-grotesk lg:text-[16px] md:text-[16px] sm:text-[10] sm:leading-[16px]
                         text-[14px] leading-[20px] font-[500] sm:font-normal md:leading-[24px] mt-8 mb-16">{t("cbk_please_review")}</p>
                        <div className='flex flex-row gap-2 cursor-pointer mt-8'
                        >
                            <img src={disclosureAgrmt ? RadialFill : RadialNull} alt="Fill"
                                className={`${'h-[25px] w-[25px] sm:h-[23px] sm:w-[23px]  md:h-[25px] md:w-[25px]'}`}
                                onClick={debounce(() => { setDisclosureAgrmt(!disclosureAgrmt); if (!disclosureAgrmt) { setinfoMessage('') } }, 100)}
                                tabIndex={0}
                                onKeyDown={(e) => {
                                    if (e.key) setDisclosureAgrmt(!disclosureAgrmt);
                                    if (!disclosureAgrmt) {
                                        { setinfoMessage('') }
                                    }
                                }
                                }
                            />
                            <p className='sm:w-[23rem] w-full font-hanken-grotesk font-[500] sm:font-[700] text-[14px] 
                            leading-[20px] sm:text-[12px] sm:leading-[20px] md:text-[16px] md:leading-[24px]
                             text-secondary-3'>{t('i_have_read_and_agree')}
                                <span onClick={() => { handleClick('userAgreement') }} className="text-[#2965FB] font-hanken-grotesk"> {t('coveredby_kudzu_terms')}</span></p>
                        </div>
                        {infoMessage &&
                            <div className='mb-2 w-4/5 self-center justify-center'>
                                {displayErrorMessage(infoMessage)}
                            </div>
                        }
                        <div className="flex justify-center text-center w-full items-center lg:mt-14 mt-12">
                            <button
                                type="button"
                                className="w-full font-hanken-grotesk sm:font-bold font-[600] 
                                lg:text-[18px] md:text-[17px] sm:text-[13px] text-[16px] text-white
                                 bg-[#2965FB] cursor-pointer flex justify-center items-center p-4 focus:outline-none  "
                                 onClick={debounce(() => {if (disclosureAgrmt) {setinfoMessage('');coveredByKudzuOptIn();} else {setinfoMessage(t('please_accept_coveredbykudzu_terms'));}logFirebaseEvents("Covered_By_Kudzu", "Activated_Covered_By_Kudzu_Clicked", "Activated_Covered_By_Kudzu_Clicked","", ""); }, 100)}
                                // onClick={() => { if (disclosureAgrmt) { setinfoMessage(''); debounce(coveredByKudzuOptIn, 100) } else { setinfoMessage(t('please_accept_coveredbykudzu_terms')) } }}
                                autoFocus
                            >
                                {'Activate Covered by Kudzu'}
                            </button>
                        </div>
                    </div>}
                />}

                {/* //Covered By Kudzu opt in success */}

                {isCoveredByKudzuOptInSuccess && <Modal
                    isOpen={isCoveredByKudzuOptInSuccess}
                    onClose={debounce(closeCustomAlertModal, 100)}
                    isClimbg={true}
                    Fullheight={true}
                    subtitle={<div className=" sm:flex sm:flex-col">
                        <span className={SubHeadingStyles2}>{t('congrats')}</span>
                        <span className={SubHeadingStyles}>{'You are now Covered by Kudzu.'}</span>
                        <p className='md:w-auto sm:w-[310px] w-auto font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px]  text-secondary-3 pt-5'>{t("covered_by_kudzu_content2")}</p>
                    </div>
                    }
                    content={<div className=" pb-5">
                        <img src={require('../../../../assets/images/covered_by_kudzu_success.png')} className="block m-auto h-2/5 w-2/5 py-5" />
                        <div className="flex justify-center text-center w-full items-center">
                            <button
                                type="button"
                                className="focus:outline-none   w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                                onClick={() => { navigate('/Climb/ClimbMenu');logFirebaseEvents("Covered_By_Kudzu", "BackTo_Climb_Covered_By_Kudzu_Clicked", "BackTo_Climb_Covered_By_Kudzu_Clicked","", ""); }}
                                autoFocus
                            >
                                {'Back to Climb'}
                            </button>
                        </div>
                    </div>}
                />}

                {/* //Covered By Kudzu ineligible */}

                {isCoveredByKudzuIneligible &&<Modal
                    isOpen={isCoveredByKudzuIneligible}
                    onClose={debounce(closeCustomAlertModal, 100)}
                    isClimbg={true}
                    Fullheight={true}
                    subtitle={<div className="">
                        <span className={SubHeadingStyles4}>{t('sorry')}, </span>
                        <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
                        <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                            {t("you_need_minimum_50")}</p>
                    </div>
                    }
                    content={<div>

                        <img src={require('../../../../assets/images/covered_by_kudzu_ineligible.png')}
                            className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                        <div className="flex flex-col justify-center items-center gap-8 w-full">
                            <button
                                type="button"
                                className="focus:outline-none   w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                                onClick={() => { navigate('/AccountServices/Spending/TransferMoney') }}
                                autoFocus
                            >
                                {'Add Money to Savings'}
                            </button>
                            <button
                                type="button"
                                className="w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center border-[3px] border-[#2965FB] sm:border-0 p-4"
                                onClick={debounce(() => {setIsCoveredByKudzuIneligible(false);navigate('/Climb/ClimbMenu');}, 100)}>
                                {/* {'Maybe later'} */}
                                {t('maybe_later')}
                            </button>
                        </div>
                    </div>}
                />}
                {isCoveredByKudzuIneligibleSetupSavings &&<Modal
                    isOpen={isCoveredByKudzuIneligibleSetupSavings}
                    onClose={debounce(closeCustomAlertModal, 100)}
                    isClimbg={true}
                    Fullheight={true}
                    subtitle={<div className="">
                        <span className={SubHeadingStyles2}>{t('sorry')}, </span>
                        <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
                        <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                            {LineBreaks(t("you_need_savings_account"))}</p>
                    </div>
                    }
                    content={<div>

                        <img src={require('../../../../assets/images/covered_by_kudzu_ineligible.png')}
                            className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                        <div className="flex flex-col justify-center items-center gap-8 w-full">
                            <button
                                type="button"
                                className=" w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                                onClick={debounce(() => { setIsCoveredByKudzuIneligibleSetupSavings(false); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true); }, 100)}
                                autoFocus
                                onKeyDown={(event) => {
                                    debounce(() => {
                                        if (event.key === "Enter") {
                                            setIsCoveredByKudzuIneligibleSetupSavings(false);
                                            setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true);
                                            event.stopPropagation();
                                        }
                                    }, 100)(); // Invoke the debounced function immediately
                                }}
                            >
                                {t('enroll_in_savings_account')}
                            </button>
                            <button
                                type="button"
                                className={`w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center p-4 ${isMobile && "sm:border-0 border-[#2965FB] border-[2.5px]"}`}
                                onClick={() => {
                                    setIsCoveredByKudzuIneligibleSetupSavings(false);
                                    navigate('/Climb/ClimbMenu');
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        setIsCoveredByKudzuIneligibleSetupSavings(false);
                                        navigate('/Climb/ClimbMenu');
                                        event.stopPropagation();
                                    }
                                }}
                            >
                                {t('back_to_climb')}
                            </button>
                        </div>
                    </div>}
                />}
                {isCoveredByKudzuIneligibleSetupSavingsPostPopup &&<Modal
                bgColor={'bg-[#FEFAEE]'}
                    isOpen={isCoveredByKudzuIneligibleSetupSavingsPostPopup}
                    onClose={debounce(closeCustomAlertModal, 100)}
                    isClimbg={true}
                    Fullheight={true}
                    subtitle={<div className="">
                        <span className={SubHeadingStyles2}>{t('lets_get_started').replace('!', '')} </span>
                        <span className={SubHeadingStyles}>{t("with_creating_a_new_account")}</span>
                        <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                            {t("set_aside_money_for_things_that_matter")}</p>
                    </div>
                    }
                    content={<div>

                        <img src={require('../../../../assets/images/kudzu_piggy_bank.png')}
                            className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                        <div className="flex flex-col justify-center items-center gap-8 w-full">
                            <button
                                type="button"
                                className={`w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4`}
                                onClick={() => { navigate('/SetupSavingsAccount'); dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('climb_savings_enrollment') } }); }}
                                autoFocus
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        navigate('/SetupSavingsAccount');
                                        dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('climb_savings_enrollment') } });
                                        event.stopPropagation();
                                    }
                                }}
                            >
                                {t('continue')}
                            </button>
                        </div>
                    </div>}
                />}

                {showAgreementsModel &&<Modal isIframe={true}
                    isOpen={showAgreementsModel}
                    onClose={() => setShowAgreementsModel(false)}
                    title={false}
                    content={<div className="h-[800px] w-full pt-16">
                        {(renderModel == "Link") && <iframe src={policyURL}
                            title="Embedded Website" className="w-full h-[800px] rounded-br-[4rem]"
                        />}
                        {
                            (renderModel == "HTML") && <div>{policyURL}</div>
                        }
                    </div>
                    }
                />}
            </>
            <CustomAlertModel
                isOpen={showModel}
                onClose={debounce(closeCustomAlertModal, 100)}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={debounce(onAcptBtnClick, 100)}
                onCancelBtnClick={debounce(onCancelBtnClick, 100)}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
            />
            {isLoading && <Loader />}
        </ >

    )
}

export default withOidcSecure(CoveredByKudzu)