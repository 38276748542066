import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../../CustomComponents/Loader'
import DynamicCard from '../../../../../CustomComponents/DynamicCard'
import { withOidcSecure } from '@axa-fr/react-oidc'
import CustomRadioButton from '../../../../../CustomComponents/CustomRadioButton'
import { width } from '../../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import logFirebaseEvents from "../../../../../LogFirebaseEvents/index"


const SavingHabitTypeCard = ({ cardHeader, cardFooter, onBack, onNext }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [savingsHabitTypeArray, setSavingsHabitTypeArray] = useState([{ 'displayText': t("amount_based") }, { 'displayText': t("percent_based") }])

  const savingsHabitType = useSelector(state => state.savingsHabitData.SavingHabitType)
  const hasConfigurations = useSelector(state => state.climbFeatures?.hasConfigurations);


  const setFrequencyFun = (value) => {
    dispatch({ type: 'SAVINGS_HABIT_DATA', payload: { 'key': "SavingHabitType", 'value': value.displayText } });
  }

  const handleBackClick = () => { 
    logFirebaseEvents("Savings_Habit", "Back_Saving_Habit_Type_Clicked", "Back_Saving_Habit_Type_Clicked","", ""); 
    if (hasConfigurations) navigate("/Climb/ManageSavingsHabit")
    else navigate("/Climb/ClimbMenu")
    dispatch({ type: "RESET_SAVINGS_HABIT_DATA" })
  }
  const handleNextClick = () => {
    logFirebaseEvents("Savings_Habit", "Next_Saving_Habit_Type_Clicked", "Next_Saving_Habit_Type_Clicked","", ""); 
    if (savingsHabitType === 'Percent-based (%)') onNext("percentage")
    if (savingsHabitType === 'Amount-based ($)') onNext("amount")

  }

  const children = () => {
    return (
      <>
        {cardHeader}
        <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4 sm:flex sm:flex-col '>
          <span>{t("lets_get_started")} </span>
          <span className='text-secondary-3 md:w-[456px] sm:w-[358px] w-[264px]'>{t("saving_habits_type_content1")}</span>
        </div>

        <div className='w-full flex justify-center'>
          <div className="w-full">
            <div className='md:my-32 sm:my-16 mt-10 mb-[4.5rem] sm:mx-0 mx-4 flex'>
              <div className='w-full flex flex-col lg:ml-16 md:ml-4'>
                <CustomRadioButton title={t('saving_habits_type')}
                  options={savingsHabitTypeArray} frequency={savingsHabitType}
                  onSelect={setFrequencyFun}
                  isTextBelow={true}
                  isRowOnMobileSrn={true}
                />

              </div>
            </div>
          </div>
        </div>
        {/* FOOTER */}
        {cardFooter(handleBackClick, handleNextClick)}
        {/* ---------- */}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>
      {isLoading && <Loader />}
    </>
  )
}


export default withOidcSecure(SavingHabitTypeCard)

