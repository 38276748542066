import React, { useEffect, useRef, useState } from 'react'
import { width, height } from "../../CustomComponents/Card/CardStyles";
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import right_dropdown_arrow from '../../../assets/images/right_dropdown_arrow.png'
import CustomTextInput from '../../CustomComponents/CustomtextInput';
import Loader from '../../CustomComponents/Loader';
import Modal from '../../CustomComponents/Modal';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { useNavigate } from 'react-router-dom';
import { fetchConfigarationData, retrieveData } from '../../../helpers/utils/Utils';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../Layout/HomeLayout';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { useKeyDown } from '../../../hooks/useKeyDown';
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import { displayErrorMessage } from '../../../utils';
import { DOWNLOAD_STATEMENT_OF_SELECTED_MONTH, GET_STATEMENTS_SELECTIONS, STATUS_SUCCESS_CODE, STATUS_SUCCESS_CODE_PDF_DOWNLOAD, ISDEV_ENV, GET_ACTIVITY_PRIVACY_POLICY } from '../../../network/URLConstants';
import CustomDropdown from '../../CustomComponents/CustomDropDown';
import arrow from '../../../assets/images/Caret_S.png';
import logFirebaseEvents from '../../LogFirebaseEvents';
const appConfigData = await fetchConfigarationData();



const Statements = ({ transactionDatas, onViewAllClick, viewAll }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const dispatch = useDispatch();
    const [iframeHeight, setIframeHeight] = useState(0);
    const userId = useSelector(state => state.userId);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const cardsListWithEnabledStatement = cards.filter(card => card.EnableStatements === true)
        .map(card => {
            return (
                {
                    AccountName: `${card.AccountTypeName} ..${card.CardDisplayNumber.slice(card.CardDisplayNumber.length - 4, card.CardDisplayNumber.length)}`,
                    CardKey: card.CardKey
                }
            )
        }
        );


    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const selectedItem = cards?.[0]
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HeadingStyles = "font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-[#262B3A]";
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [type, setType] = useState('')
    const [footerMsg, setFooterMsg] = useState('');
    const [pdfData, setPdfData] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState({ "CardholderStatementKey": 0, "DocumentID": '', "DisplayText": 'Select' });
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [errormessage, setErrormessage] = useState('');
    const [privacyTitle, setPrivacyTitle] = useState('');
    const [privacyContent, setPrivacyContent] = useState('');
    const [showPrivacyContent, setShowPrivacyContent] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [durationList, setDurationList] = useState([
        // {
        //     "CardholderStatementKey": 60280,
        //     "DocumentID": "09645757-3f11-4442-b932-53536e8ebdde",
        //     "DisplayText": "May 2023"
        // },
        // {
        //     "CardholderStatementKey": 60279,
        //     "DocumentID": "cc9db0f2-cb97-429d-9bd8-e17050e283db",
        //     "DisplayText": "April 2023"
        // },
        // {
        //     "CardholderStatementKey": 60278,
        //     "DocumentID": "dc4856bf-e34e-4d82-9bbd-3a0073c495d2",
        //     "DisplayText": "March 2023"
        // },
        // {
        //     "CardholderStatementKey": 60277,
        //     "DocumentID": "0cd15e46-e391-4a25-a55d-f9a32ba0acb4",
        //     "DisplayText": "February 2023"
        // },
        // {
        //     "CardholderStatementKey": 60276,
        //     "DocumentID": "fc8f6538-d2ab-4cb9-a102-95d24516ab40",
        //     "DisplayText": "January 2023"
        // },
        // {
        //     "CardholderStatementKey": 60275,
        //     "DocumentID": "03d4f4d2-be7c-44bc-95b3-4633ebf3906c",
        //     "DisplayText": "December 2022"
        // }
    ]);



    useKeyDown(() => { }, ["Enter"]);

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    useEffect(() => {
        if (selectedAccount) getStatementsListFromServer()
        // alert(cardsListWithEnabledStatement)
    }, [selectedAccount])

    // <div className='lg:w-[270px] md:w-[207px] sm:w-[159px] w-full sm:px-0 sm:py-0 px-[2.5rem] py-[2rem] pl-[2.5rem]'>
    const leftBarComp = () => {
        return (
            <div className="md:w-[293px] sm:w-[150px] w-full sm:px-0 sm:py-0 px-[2.5rem] py-[2rem]">
                <p className="text-white font-normal 
                lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] md:leading-[33px] sm:leading-[30px] leading-[31px]
                 font-serif-pro sm:border-b-[2.5px] sm:border-[#4B70B9] lg:pb-14 md:pb-[50px] sm:border-opacity-[50%] border-b-0
                 sm:pb-[2.6rem] pb-[2.6rem] mb-10">{t("view_print_and_download")}</p>

                <div className=' flex flex-col gap-10 cursor-pointer sm:pb-0 pb-10'>

                    {(cards != null && cards.length > 0 && cards[0].EnableStatements) &&
                        <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 ' onClick={() => { navigate('/Documents/Statements') }} >
                            <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] 
                    sm:text-[14px] text-[15px] leading-[20px]
                     md:font-[700] md:leading-[16px] lg:leading-[16px]
                       text-[#94C3E8]'
                                onClick={() => { navigate('/Documents/Statements') }}
                                tabIndex={0}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        navigate('/Documents/Statements');
                                    }
                                }}>{t("statements")}</p>
                            <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                        </div>
                    }
                    <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 ' onClick={() => { navigate('/Documents/Policies') }}>
                        <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] sm:text-[14px]
                     text-[15px] leading-[20px] md:font-[700]
                     md:leading-[16px] lg:leading-[16px]
                       text-[#FEFAEE]' onClick={() => { navigate('/Documents/Policies') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/Policies');
                                }
                            }}>
                            {t("privacy_and_legal")}</p>
                        <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div>


                    {/* <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[14px] sm:text-[14px] text-[15px] 
                    leading-[20px] md:font-[700] md:leading-[16px] lg:leading-[16px]
                 text-[#FEFAEE]' onClick={() => { navigate('/Documents/TaxDocuments') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/TaxDocuments');
                                }
                            }}
                        >
                            {t("tax_documents")}</p> */}



                    {/* <div className=' flex flex-row justify-between items-center border-b-[2.5px] border-[#4B70B9] border-opacity-[50%] sm:border-b-0 pb-10 sm:pb-0 ' onClick={() => { navigate('/Documents/Fees') }} >
                        <p className='font-hanken-grotesk font-[600] lg:text-[16px] md:text-[15px] 
                    sm:text-[14px] text-[15px] leading-[20px]
                     md:font-[700] md:leading-[16px] lg:leading-[16px]
                     text-[#FEFAEE]' onClick={() => { navigate('/Documents/Fees') }}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    navigate('/Documents/Fees');
                                }
                            }}>
                            {t("fees")}</p>
                            <img src={arrow} alt='arrow' className=' flex sm:hidden h-[12px] w-[7px] mr-6' />
                    </div> */}
                </div>
            </div>
        )
    }
    const headerContent = () => {
        return (
            // <div className="flex flex-row justify-start mx-10 items-center mt-10">
            //     <div className='ml-10'>
            //         {/* Left Text */}
            //         <p className="text-xmd ">{t("external_accounts")}</p>
            //     </div>
            // </div>
            <div className="flex flex-row justify-between mx-10 items-center mt-10">
                <div className='ml-10'>
                    {/* Left Text */}
                    <p className="text-xl "></p>
                </div>
                <div onClick={(event) => { event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); }}
                    className='flex flex-row justify-end items-center mr-10 '>
                    {/* Image */}
                    {/* <img
                        src={print_img}
                        // alt="Image"
                        className="w-5 h-5 mr-2"
                    /> */}

                    {/* Right Text */}
                    {/* <p className="text-18 font-bold">{t("print")}</p> */}
                </div>
            </div>
        )
    }

    const getStatementsListFromServer = async () => {
        if (cards == undefined || cards == null || selectedAccount == null) {
            return
        }
        var url = GET_STATEMENTS_SELECTIONS + selectedAccount.CardKey;
        // var url = GET_STATEMENTS_SELECTIONS + cards?.[0]?.CardKey;
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setLoading(true)
        GetRequest(url, headers).then((response) => {
            var parsedResponse = JSON.parse(response)
            if (parsedResponse.ResponseCode != STATUS_SUCCESS_CODE) {
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } else {
                let responseArr = parsedResponse.Response;
                // alert(responseArr);
                setDurationList(responseArr)
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            }
        })

    }

    const onStatementDownloadClick = async () => {
        logFirebaseEvents("Statements", "View_Statement_Clicked", "View_Statement_Clicked", "","");
        if (selectedDuration.DocumentID == undefined || selectedDuration.DocumentID == 0 || !selectedAccount) {
            return
        }
        let url = (!ISDEV_ENV ? appConfigData.APP_URL : '') + DOWNLOAD_STATEMENT_OF_SELECTED_MONTH + "cardKey=" + selectedAccount?.CardKey + "&documentId=" + selectedDuration.DocumentID
        // let url = (!ISDEV_ENV ? appConfigData.APP_URL : '') + DOWNLOAD_STATEMENT_OF_SELECTED_MONTH + "cardKey=" + cards?.[0]?.CardKey + "&documentId=" + selectedDuration.DocumentID
        let access_token = accessTokenToApi
        var headers = {
            Authorization: 'Bearer ' + access_token,
            Accept: 'application/pdf',
        };
        setLoading(true)
        try {
            const response = await fetch(url, { method: 'GET', headers });

            if (response.ok) {
                const blob = await response.blob();
                const base64Data = await blobToBase64(blob);
                const linkSource = base64Data;
                const downloadLink = document.createElement("a");
                const fileName = `${selectedDuration.DisplayText.replace(' ', '_')}_statement.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                setLoading(false)
                downloadLink.click();
            } else {
                setLoading(false)
                console.error('Network response was not ok');
                // setErrormessage(t('doc_not_available'))
                setErrormessage(response.statusText);
            }
        } catch (error) {
            setLoading(false)
            console.error("bad request", error);
            setErrormessage(t('doc_not_available'))
        }


    }
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }
    const downloadPdfFile = async (url, headers) => {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        const blob = await response.blob();
        const urlObject = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = urlObject;
        link.download = 'directdeposit_form.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Open the PDF in a new hidden iframe
        const printFrame = document.createElement('iframe');
        printFrame.style.visibility = 'hidden';
        printFrame.src = urlObject;
        document.body.appendChild(printFrame);

        // Wait for the iframe to load the PDF content before triggering print
        printFrame.onload = () => {
            // Trigger the print function on the iframe
            printFrame.contentWindow.print();

            // Clean up the iframe after printing and close the popup window
            setTimeout(() => {
                document.body.removeChild(printFrame);
            }, 1000); // Adjust the delay if needed
        };
    };
    const handleClick = () => {
        setShowPrivacyContent(false);
    }

    async function onClickRightToDispute() {
        logFirebaseEvents("Statements", "Your_Right_to_Dispute_Clicked", "Your_Right_to_Dispute_Clicked", "","");
        if (selectedItem?.CardKey == undefined) {
            return
        }
        setLoading(true);
        // var url = `${GET_ACTIVITY_PRIVACY_POLICY}?CardKey=${selectedItem?.CardKey}&ContentType=TPrivacyNoteV2`
        var url = GET_ACTIVITY_PRIVACY_POLICY + selectedItem?.CardKey + '?contentType=TPrivacyNoteV2'
        // /api/cardholders/policy/latest?CardKey=724001&ContentType=TPrivacyNoteV2
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);


        const response = await GetRequest(url, headers)

        setTimeout(() => {
            setLoading(false)
        }, 500);

        const responseObj = JSON.parse(response);
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            console.log("response getted object is", responseObj);
            let title = responseObj.Response.Title
            let content = responseObj.Response.Content
            const formattedContent = convertTextToList(content);
            setPrivacyTitle(title);
            setPrivacyContent(formattedContent);
            const contentHeight = formattedContent.split('<p>').length * 20; // Assuming 20 pixels per line
            setIframeHeight(contentHeight);
            setShowPrivacyContent(true);
        } else {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            // alert(displayText);
        }
    }
    function convertTextToList(text) {
        const lines = text.split('\n');
        let result = '';
        let inList = false;

        for (const line of lines) {
            if (line.trim().startsWith('•')) {
                if (!inList) {
                    result += '<ul>';
                    inList = true;
                }
                result += `<li>${line.trim().slice(1).trim()}</li>`;
            } else {
                if (inList) {
                    result += '</ul>';
                    inList = false;
                }
                result += `<p>${line.trim()}</p>`;
            }
        }

        if (inList) {
            result += '</ul>';
        }

        return result;
    }


    return (
        <HomeLayout>
            <LeftBar leftBarType='component' isPrimary={true} leftBarComp={leftBarComp()} />

            <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated 
            animate__fadeInRight '>
                <DynamicCard
                    cardWidth={width.allpolicies}
                    header={false}
                    footer={false}
                    headerContent={headerContent()}
                    CardRadius={true}
                    HeaderRadius={true}
                    isWhite={true}>
                    {showPrivacyContent == false && <div>
                        <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] sm:font-[600] md:font-[500]
 lg:text-[26px] md:text-[22px] 
 sm:text-[15px] text-[20px] sm:leading-[23px]
 leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
                            {t("statements")}
                        </div>

                        <div className='flex flex-col border-t-[2.5px] mb-10 border-[#A3CEBB] sm:mx-0 mx-4'></div>
                    </div>}
                    <div className='flex flex-col justify-between'>
                        <div className='flex flex-col'>
                            <div className='flex flex-col'>

                                <div className='flex sm:w-full flex-col text-[#2965FB]
                                lg:text-[26px] md:text-[22px] sm:text-[18px] sm:font-[400] text-[26px]
                            lg:leading-[36px] md:leading-[31px] sm:leading-[29px] font-serif-pro
                             font-[500] md:font-[500] leading-[36px] sm:mx-0 mx-4'>{t("looking_for_a_statement")} </div>

                                <div className='md:w-[550px] sm:w-[370px] flex flex-col text-[#262B3A] lg:text-[26px]
                                 md:text-[22px] sm:text-[20px] text-[26px]
                        lg:leading-[36px] md:leading-[31px] sm:leading-[29px] font-serif-pro 
                        font-[500] leading-[36px] sm:mx-0 mx-4 '>
                                    {t("select_the_month_and_year_of_the_statement")}
                                </div>
                            </div>

                            <div className='flex justify-center sm:mx-0 mx-4'>
                                <div className={`md:w-[70%] sm:w-[84%] w-full flex flex-col gap-8 md:my-16 mt-10
                             md:h-auto sm:h-auto h-auto sm:pb-0 mb-2`}>
                                    <div>

                                        <CustomDropdown
                                            options={cardsListWithEnabledStatement}
                                            title={t('account')}
                                            onSelect={(option) => {
                                                setErrormessage('')
                                                setSelectedAccount(option)
                                            }}
                                            selectedAccountType={selectedAccount}
                                        // hrbg='bg-dropdown-sky' 
                                        />
                                        {errormessage && <div className='mt-[10px] w-full'>
                                            {errormessage && displayErrorMessage(errormessage)}
                                        </div>}
                                    </div>
                                    <div>

                                        <CustomDropdown
                                            isScroll={false}
                                            options={durationList}
                                            title={t('year')}
                                            onSelect={(option) => {
                                                setErrormessage('')
                                                setSelectedDuration(option)
                                            }}
                                            selectedAccountType={selectedDuration} />

                                        {errormessage && <div className='mt-[10px] w-full'>
                                            {errormessage && displayErrorMessage(errormessage)}
                                        </div>}


                                        <div onClick={() => onClickRightToDispute()} className='pt-6 font-hanken-grotesk flex flex-col 
                             text-[#2965FB] lg:text-[16px] md:text-[15px] sm:text-[13px] text-[15px] font-[700] 
                             cursor-pointer sm:pb-12 pb-32'>{t('your_right_to_dispute_errors')}</div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {/* {selectedDuration.DisplayText != 'Select' && */}
                        <div className='flex flex-col w-full items-end self-center mb-14'>
                            <button className={`font-hanken-grotesk w-auto h-auto m-5
                                         ${(selectedAccount && selectedDuration.DocumentID) ? 'text-[#2965FB]' : 'text-[#94C3E8]'}  lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                                          font-[700] cursor-pointer`}
                                disabled={(selectedAccount && selectedDuration.DocumentID) ? false : true}
                                onKeyDown={(event) => {
                                    if (event.key == "Enter") {
                                        event.preventDefault(); {
                                            if (selectedDuration.DisplayText) { onStatementDownloadClick() }
                                        }
                                    }
                                }}
                                onClick={(event) => {
                                    if (selectedDuration.DisplayText) { onStatementDownloadClick() }
                                }} style={{}}>
                                {t('view_statement')}</button>
                        </div>
                        {/* } */}

                    </div>
                    {/* {showPrivacyContent == true && <div className='flex-col justify-center sm:mx-0 '>
                        <div className='w-full flex justify-center'>
                            <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                                            lg:text-[26px] md:text-[22px] 
                                            sm:text-[20px] text-[20px]
                                            leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
                                {privacyTitle}
                            </div>
                        </div>
                        <div className='sm:w-full flex justify-center sm:mx-0 mx-4'>
                            <div className='sm:w-[80%] w-full lg:mb-24 md:mb-24 sm:mb-24 mb-[7rem] lg:text-[18px] 
                                    md:text-[16px] sm:text-[14px] text-[16px] font-hanken-grotesk font-[500]'
                                dangerouslySetInnerHTML={{ __html: privacyContent.replace('<br/>', '<br/><br/>') }} >
                            </div>
                        </div>
                        <div>
                            <div  className='sm:px-9 cursor-pointer flex items-center gap-2 absolute bottom-12'
                                onClick={() => handleClick()}>
                                <div><img src={Leftarrow} alt='leftArrow' /></div>
                                <p className='lg:text-[18px] md:text-[17px] sm:text-[15px] text-[16px] font-hanken-grotesk cursor-pointer text-secondary-2 font-bold text-base'>{t('back')}</p></div>
                        </div>
                    </div>} */}
                </DynamicCard>
                {loading && <Loader />}
            </div>
            <Modal
                Fullheight={true}
                isIframe={true}
                isOpen={showPrivacyContent}
                onClose={() => { setShowPrivacyContent(false); }}
                content={
                    <div className='h-full w-full'>
                        <div className=' flex justify-center mt-20 sm:mt-0'>
                            <div className=' sm:w-[80%] w-full'>
                                <div className="font-[400]
                                     md:text-[32px] md:leading-[38px]
                                    sm:text-[18px] text-[20px] sm:leading-[24px]
                                    leading-[36px] font-serif sm:px-0 px-4 ">
                                    {privacyTitle}
                                </div>
                            </div>

                        </div>
                        <div className='sm:w-full flex justify-center w-full sm:px-0 px-4 mt-10 h-[600px] sm:h-[400px]' >
                            <div className='sm:w-[80%] h-full w-full ' >
                                <iframe srcDoc={privacyContent}
                                    title="Embedded Website" className='h-full w-full'
                                // title="Embedded Website" className='h-full w-full'
                                /></div>
                        </div>
                    </div>}
            />
        </HomeLayout >

    )
}

export default withOidcSecure(Statements);
