import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import transactionComplete from "../../../../../../assets/images/transfercomplete.png"


const ScheduledTransfer = ({ onCanelTranfer,fromAccountType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackToService = () => {
 //   navigate('/AccountServices')
    if (fromAccountType != undefined && fromAccountType != null) {
      navigate(`/AccountServices/${fromAccountType}`);
    } else {
      navigate('/AccountServices');
    }
  }
  return (
    <div className=' flex flex-col justify-center items-center w-full'>
      <p className='font-hanken-grotesk text-[16px] font-[400] sm:font-[500] leading-[24px] sm:text-[12px] sm:leading-[18px] md:text-[18px] md:leading-[28px] text-secondary-3'>{t("scheduled_fund_transfer_notify_msg")}</p>

      <img src={transactionComplete} alt="complete" className='' />
      <div className=' flex flex-col justify-center items-center w-full'>

        <div className=' flex flex-col justify-center items-center gap-4 w-full'>
          <button autoFocus class="focus:outline-none   font-hanken-grotesk  font-[500] sm:font-bold leading-[24px]
          lg:text-[18px] md:text-[17px] 
          sm:text-[15px] text-[16px] sm:leading-[12px]  md:leading-[16px] bg-[#2965FB]
            text-white cursor-pointer border-2 px-2 py-4 border-[#2965FB] w-full" onClick={handleBackToService}>{t("back_to_account_services")}</button>
          <button class="font-hanken-grotesk lg:text-[18px] md:text-[17px] 
 sm:text-[15px] text-[16px] font-[500] sm:font-bold leading-[24px] 
          sm:leading-[12px] md:leading-[16px] text-secondary-2 text-[#2965FB] cursor-pointer  px-2 py-4  w-full" onClick={() => onCanelTranfer(true)}>{t("cancel_tranfer")}</button>


        </div>
      </div>

    </div>
  )
}

export default ScheduledTransfer