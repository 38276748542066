import React, { useEffect, useState } from 'react'
import CustomTextInput from '../../../../../CustomComponents/CustomtextInput'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../../CustomComponents/Loader'
import DynamicCard from '../../../../../CustomComponents/DynamicCard'
import { width } from '../../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import { handleAmountInputLimitTo2decimal } from '../../../../../../utils'
import logFirebaseEvents from '../../../../../LogFirebaseEvents'


const AmountCard = ({ cardHeader, cardFooter, onBack, onNext }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [amountError, setAmountError] = useState()
  const [isLoading, setIsLoading] = useState(false);

  const amount = useSelector(state => state.savingsHabitData.Amount)
  const hasConfigurations = useSelector(state => state.climbFeatures?.hasConfigurations);


  const handleBackClick = () => {
    logFirebaseEvents("Savings_Habit", "Back_Amount_tnClicked", "Back_Amount_tnClicked","", ""); 
    if (hasConfigurations) navigate("/Climb/ManageSavingsHabit")
    else navigate("/Climb/ClimbMenu")
    dispatch({ type: "RESET_SAVINGS_HABIT_DATA" })

  }
  const handleNextClick = () => {
    logFirebaseEvents("Savings_Habit", "Next_Amount_Clicked", "Next_Amount_Clicked","", ""); 
    amountValidation();
    if (!amount) setAmountError('Amount Required.');
    if (amount && !amountError) onNext("frequency");
  }

  const handleOnBlur = (e) => {
    if (!amount) setAmountError('Amount Required');
    else if (amount <= 0) {
      setAmountError('Please enter amount greater than 0')
    }
    else setAmountError('')
  }

  const amountValidation = () => {
    if (amount <= 0) setAmountError('Please enter amount greater than 0')
    else setAmountError(null)

  }

  useEffect(() => {
    if (amount) amountValidation();
  }, [amount])

  const children = () => {
    return (
      <>
        {cardHeader}

        <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4 sm:flex sm:flex-col '>
          <span>{t("great_exclamation")} </span>
          <span className='text-secondary-3  md:w-[500px] sm:w-[358px] w-[264px]'>{t("saving_habits_amount_content1")}</span>
        </div>

        <div className='w-full flex justify-center'>
          <div className="md:w-[60%] w-full">
            {/* BODY */}
            <div className='md:my-32 sm:my-16 mt-10 mb-[10rem] sm:mx-0 mx-4'>
              <div className='sm:w-[60%] w-full flex flex-col justify-center'>
                <CustomTextInput name={t("amount")}
                  onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); } }}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const formattedAmount=handleAmountInputLimitTo2decimal(inputValue)
                    dispatch({ type: 'SAVINGS_HABIT_DATA', payload: { 'key': "Amount", 'value': formattedAmount.toString().replace('$', '') } })
                  }}
                  value={amount ? `$${amount}` : ''}
                  error={amountError}
                  placeholder={"$ 0"}
                  inputWidth={` w-full`}
                  autoFocus={true}
                  // maxLength={"5"}
                  onBlur={(event) => handleOnBlur(event)}

                />

              </div>
            </div>
          </div>

        </div >
        {/* FOOTER */}
        {cardFooter(handleBackClick, handleNextClick)}
        {/* ---------- */}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>
      {isLoading && <Loader />}
    </>
  )
}

export default AmountCard