import React from "react";
import { HeaderCaption, Space, Title, Subtitle, Main, CardSection, Container, CardTitle, CardTitleColor, CardFooter, CardHeader } from "./CardStyles";
import Notice from '../../OnBoard/Notice';
const Card = (props) => {
  const { CardRadius, HeaderRadius, isHeaderCaption, heading, isHome, subheading, isActive, CardSectionRadius, Cardcaption, Cardsubcaption, children, header, HeaderContent, footer, cardHeight, footerContent, cardWidth, HeaderRightRadius, isPerk, isProfile, isWhite, backgroundColor, isShowNotice } = props
  return (
    <>
      <div className="sm:block hidden">
        <HeaderCaption isHeaderCaption={isHeaderCaption}>
          <Title isprimary>
            {heading}
            <Subtitle>{subheading}</Subtitle>
          </Title>
        </HeaderCaption>
      </div>

      <Space></Space>

      <Main isHome={isHome}>
        <CardSection style={{ height: cardHeight != null ? cardHeight : '800px' }}
          CardRadius={CardRadius} isPerk={isPerk} isProfile={isProfile}
          isWhite={isWhite}>
          <CardHeader ishidden={header} backgroundColor={backgroundColor} HeaderRadius={HeaderRadius} isActive={isActive}  >
            {HeaderContent}
          </CardHeader>
          <Container style={{ width: cardWidth }}>

            <div className="sm:hidden block">
              <HeaderCaption isHeaderCaption={isHeaderCaption}>
                <Title isprimary>
                  {heading}
                  <Subtitle>{subheading}</Subtitle>
                </Title>
              </HeaderCaption>
            </div>

            <div className="sm:block hidden">
              <CardTitle CardTitleColor isPrimary>
                {Cardcaption}
              </CardTitle>
            </div>
            {children}
          </Container>
          <CardFooter ishidden={footer} {...(props?.footerBgDisabled ? {} : { backgroundColor: backgroundColor })}>
            {footerContent}
          </CardFooter>
        </CardSection>
        {(isShowNotice !== undefined && isShowNotice == true) && <Notice />}
      </Main>
    </>
  );
};

export default Card;
