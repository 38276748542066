import { setUserProperties, logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

const logFirebaseEvents = async (
  screenName,
  eventName,
  userAction,
  cardKey
) => {
  const date = new Date().getTime();
  try {
    const userProps = {
      firebase_screen:  screenName.toUpperCase(),
      firebase_screen_class: screenName,
      timeStamp: date,
    };

    setUserProperties(analytics, userProps);

    logEvent(analytics, eventName.toUpperCase(), {
      ...userProps,
    });

    logEvent(analytics, "screen_view", {
      ...userProps
    })
    


    // Log properties for debugging
    console.log("User properties and event parameters:", userProps);
  } catch (error) {
    console.warn("Analytics Error:", error.message, "Stack:", error.stack);
  }
};

export default logFirebaseEvents;
