import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../../CustomComponents/Loader'
import DynamicCard from '../../../../../CustomComponents/DynamicCard'
import { width } from '../../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import { withOidcSecure } from '@axa-fr/react-oidc'
import CustomRadioButton from '../../../../../CustomComponents/CustomRadioButton'
import CustomDatePicker from '../../../../../CustomComponents/CustomDateInput'
import { displayErrorMessage } from '../../../../../../utils'
import dayjs from 'dayjs'
import logFirebaseEvents from '../../../../../LogFirebaseEvents'

const FrequencyCard = ({ cardHeader, cardFooter, onBack, onNext }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [frequencyArray, setFrequencyeArray] = useState([{ 'displayText': 'Monthly' }, { 'displayText': 'Weekly' }])
  const [frequencyError, setFrequencyError] = useState(null)
  const [dateError, setDateError] = useState(null)


  const frequency = useSelector(state => state.savingsHabitData.Frequency)
  const selectedDate = useSelector(state => state.savingsHabitData.StartDate)


  const setFrequencyFun = (value) => {
    dispatch({ type: 'SAVINGS_HABIT_DATA', payload: { 'key': "Frequency", 'value': value.displayText } });
  }

  const handleBackClick = () => {
    logFirebaseEvents("Savings_Habit", "Back_Frequecy_Clicked", "Back_Frequecy_Clicked","", ""); 
    onBack("amount")
  }
  const handleNextClick = () => {
    logFirebaseEvents("Savings_Habit", "Next_Frequecy_Clicked", "Next_Frequecy_Clicked","", ""); 
    if (!frequency) setFrequencyError('Please select frequency.');
    else if (!selectedDate) setDateError("please select start date")
    if (frequency && !frequencyError && selectedDate && !dateError) onNext("confimation");

  }


  useEffect(() => {
    if (frequency) setFrequencyError(null)
    if (selectedDate) setDateError(null)
  }, [frequency, selectedDate])

  const onDateChange = (date) => {
    dispatch({ type: 'SAVINGS_HABIT_DATA', payload: { 'key': "StartDate", 'value': date } })
  }

  const children = () => {
    return (
      <>
        {cardHeader}
  
         <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4 sm:flex sm:flex-col '>
          <span>{t("sounds_good")} </span>
          <span className='text-secondary-3  md:w-[600px] sm:w-[358px] w-[264px]'>{t("saving_habits_frequency_content1")}</span>
        </div>

        <div className='w-full flex justify-center'>
          <div className="sm:w-[80%] w-full">


            {/* BODY */}
            <div className='md:my-32 sm:my-16 mt-10 mb-[10rem] sm:mx-0 mx-4'>
              <div className='sm:w-[80%] w-full flex flex-col justify-center'>
                <CustomRadioButton title={t('frequency')} options={frequencyArray}
                  frequency={frequency} onSelect={setFrequencyFun} error={frequencyError}
                  isTextBelow={false}
                  isRow={'row'}
                  />

                {frequency &&
                  <div className='mt-14'>
                    <CustomDatePicker
                      selectedDate={selectedDate}
                      onDateChange={(date) => onDateChange(date)}
                      minDate={new Date(new Date().toUTCString().substr(0, 25))}
                      // maxDate={''}
                      disabledDates={[]}
                      IsDefaultPlaceholder={true}
                      title="start"
                      hrbg='bg-dropdown-sky'
                    />
                    <div className='my-2 w-full'>
                      {dateError && displayErrorMessage(dateError)}
                    </div>
                  </div>
                }
              </div>
            </div>

          </div>
        </div>
        {/* FOOTER */}
        {cardFooter(handleBackClick, handleNextClick)}
        {/* ---------- */}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>
      {isLoading && <Loader />}
    </>
  )
}


export default withOidcSecure(FrequencyCard)




