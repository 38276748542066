import { withOidcSecure } from '@axa-fr/react-oidc'
import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../Layout/HomeLayout';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import Loader from '../../../CustomComponents/Loader';
import { useTranslation } from 'react-i18next';
import AmountCard from './AmountCard';
import TimeFrameCard from './TimeFrameCard';
import DateCard from './DateCard';
import NotificationTypeCard from './NotificationTypeCard';
import ConfirmationCard from './ConfirmationCard';
import RightArrow from "../../../../assets/images/RightArrow@2x.png"
import NextArrow from "../../../../assets/images/Next_Arrow.png"
import SuccessSpendAlertSet from './SuccessSpendAlertSet';
import Modal from '../../../CustomComponents/Modal';
import SpendAlertError from './SpendAlertError';
import { useDispatch } from 'react-redux'


const SpendSenseAlerts = () => {
    const HeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
    const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3 md:w-[80%]";

    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [alertCard, setAlertCard] = useState("amountCard");
    const [spendAlertStatus, setSpendAlertStatus] = useState("")
    const [isSubErrMsg, setIsSubErrMsg]=useState(true)
    const [spendingAlertErrorMsg, setSpendingAlertErrorMsg] = useState("You already have a SpendSense Alert for this amount and time frame.")

    const cardHeader = () => {
        return (
            <>
                <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500]
            lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
             font-hanken-grotesk sm:mx-0 mx-4"> {t("spending_alert_heading")}</div>
                <div class="border-[#94C3E8] border-b-[3px] sm:mx-0 mx-4"></div>
            </>
        )
    }

    const setAlertCardFun = (value) => {
        setAlertCard(value)
    }
    const setSpendAlertStatusFun = (value) => {
        if (value === "success") {
            openSuccessfullModal();
        }else{
            openErrorModal();
        }
      //  setSpendAlertStatus(value)
    }

    const setSpendingAlertErrorFun=(err)=>{
        setIsSubErrMsg(false)
        setSpendingAlertErrorMsg(err)
    }


    const cardFooter = (handleBackClick, handleNextClick, button1, button2, isNextActive=true) => {
        return (
            <div className='flex flex-row sm:justify-end justify-between items-center  md:gap-16 sm:gap-10 sm:mx-0 mx-4 lg:pb-[5rem] md:pb-[4rem] sm:pb-[3rem] pb-[3rem]'>
                <p className='font-hanken-grotesk font-[600] sm:font-[700] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                leading-[26px] sm:leading-[13px]  md:leading-[18px] 
                text-secondary-2 cursor-pointer capitalize' onClick={handleBackClick}
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") handleBackClick();
                    }}
                >{button1 || `${t("back")}`}</p>
                <div>
                    <div className='flex flex-row justify-end  gap-2 cursor-pointer items-center'>
                        <p className={`font-hanken-grotesk font-[600] sm:font-[700] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                         leading-[26px]  sm:leading-[13px] 
                          md:leading-[18px] ${!isNextActive ? 'text-[#94C3E8]' : 'text-secondary-2'} `}
                            onClick={handleNextClick}
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleNextClick();
                            }}
                        >{button2 || `${t("next")}`}</p>
                        <img src={!isNextActive ? NextArrow : RightArrow} alt="arrow" className='h-[12px] w-[12px] 
                        sm:h-[10px] sm:w-[11px] md:h-[15px] md:w-[17px]' />
                    </div>
                </div>
            </div>
        )
    }




    useEffect(() => {
        switch (alertCard) {
            case "amountCard":
                displayAmountCard();
                hideTimeFrameCard();
                hideDateCard();
                hideNotificationTypeCardCard();
                hideConfimationCard();
                break;
            case "timeFrame":
                displayTimeFrameCard();
                hideAmountCard();
                hideDateCard();
                hideNotificationTypeCardCard();
                hideConfimationCard();
                break;
            case "date":
                displayDateCard();
                hideTimeFrameCard();
                hideAmountCard();
                hideNotificationTypeCardCard();
                hideConfimationCard();
                break;
            case "notificationType":
                displayNotificationTypeCardCard();
                hideDateCard();
                hideConfimationCard();
                hideTimeFrameCard();
                hideAmountCard();
                break;
            case "confimation":
                displayConfimationCard();
                hideNotificationTypeCardCard();
                hideDateCard();
                hideTimeFrameCard();
                hideAmountCard();
                break;
        }

    }, [alertCard])

    useEffect(() => {
        switch (spendAlertStatus) {
            case "success": 
                openSuccessfullModal();
                break;
            case "error":
                openErrorModal();
                break;
        }
    }, [spendAlertStatus])



    // DISPLAY CARDS--------


    //Amount card
    const [isAmountCard, setIsAmountCard] = useState(false);

    const displayAmountCard = () => {
        setIsAmountCard(true);
    }

    const hideAmountCard = () => {
        setIsAmountCard(false);
    }

    //TimeFrame card
    const [isTimeFrameCard, setIsTimeFrameCard] = useState(false);

    const displayTimeFrameCard = () => {
        setIsTimeFrameCard(true);
    }

    const hideTimeFrameCard = () => {
        setIsTimeFrameCard(false);
    }

    //Date card
    const [isDateCard, setIsDateCard] = useState(false);

    const displayDateCard = () => {
        setIsDateCard(true);
    }

    const hideDateCard = () => {
        setIsDateCard(false);
    }

    //Notification Type card
    const [isNotificationTypeCard, setIsNotificationTypeCard] = useState(false);

    const displayNotificationTypeCardCard = () => {
        setIsNotificationTypeCard(true);
    }

    const hideNotificationTypeCardCard = () => {
        setIsNotificationTypeCard(false);
    }

    //Confimation card
    const [isConfimationCard, setIsConfimationCard] = useState(false);

    const displayConfimationCard = () => {
        setIsConfimationCard(true);
    }

    const hideConfimationCard = () => {
        setIsConfimationCard(false);
    }



    // Successfull transfer modal
    const [isSuccessfullModalOpen, setIsSuccessfullModalOpen] = useState(false);

    const openSuccessfullModal = () => {
        setIsSuccessfullModalOpen(true);
    };

    const closeSuccessfullModal = () => {
        setIsSuccessfullModalOpen(false);

    };

    // Error modal
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const openErrorModal = () => {
        setIsErrorModalOpen(true);
    };

    const closeErrorModal = () => {
        setIsErrorModalOpen(false);

    };
    return (
        <HomeLayout>

            <HomeHeader heading={t("spending_alert_left_heading")}
                isHeaderCaption={true} />

            <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
            animate__animated animate__fadeInRight '>

                {isAmountCard && <AmountCard cardHeader={cardHeader()}
                    cardFooter={cardFooter} onBack={setAlertCardFun} onNext={setAlertCardFun} />}
                {isTimeFrameCard && <TimeFrameCard cardHeader={cardHeader()}
                    cardFooter={cardFooter} onBack={setAlertCardFun} onNext={setAlertCardFun} />}
                {isDateCard && <DateCard cardHeader={cardHeader()} cardFooter={cardFooter}
                    onBack={setAlertCardFun} onNext={setAlertCardFun} />}
                {isNotificationTypeCard && <NotificationTypeCard cardHeader={cardHeader()}
                    cardFooter={cardFooter} onBack={setAlertCardFun} onNext={setAlertCardFun} />}
                {isConfimationCard && <ConfirmationCard cardHeader={cardHeader()}
                    cardFooter={cardFooter} onBack={setAlertCardFun} onEdit={setAlertCardFun}
                    onSave={setSpendAlertStatusFun} onSetSpendSenseAlertFun={setSpendingAlertErrorFun} />}
            </div>


            <Modal
                isOpen={isSuccessfullModalOpen}
                onClose={closeSuccessfullModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('success')}</p>}
                subtitle={
                    <p>
                        <span className={`${HeadingStyles} visible sm:hidden`}>{t('success')}</span><span className={SubHeadingStyles}>{t('success_spend_alert_msg')}</span>
                    </p>}
                content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6"><SuccessSpendAlertSet /></div>}
            />

            <Modal
                isOpen={isErrorModalOpen}
                onClose={closeErrorModal}
                alignItems={'items-center'}
                isClimbg={true}
                title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('oops_exclamation')}</p>}
                subtitle={
                    <p>
                        <span className={`${HeadingStyles} visible sm:hidden`}>{t('oops_exclamation')}</span><span className={SubHeadingStyles}>{spendingAlertErrorMsg}</span>
                    </p>}
                content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6"><SpendAlertError isSubErrMsg={isSubErrMsg}/></div>}
            />

            {isLoading && <Loader />}


        </HomeLayout >
    )
}

export default withOidcSecure(SpendSenseAlerts)