import { withOidcSecure } from '@axa-fr/react-oidc'
import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../../Layout/HomeLayout';
import HomeHeader from '../../../../CustomComponents/HomeHeader';
import Loader from '../../../../CustomComponents/Loader';
import { useTranslation } from 'react-i18next';
import RightArrow from "../../../../../assets/images/RightArrow@2x.png"
import Modal from '../../../../CustomComponents/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import DynamicCard from '../../../../CustomComponents/DynamicCard';
import { ClimbManageSettingsSidebar, displayErrorMessage, getFrequencyAbbreviation, handleAmountInputLimitTo2decimal, retrieveData } from '../../../../../utils';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../../network/NetworkUtils';
import { GET_ALL_SAVINGS_HABIT, GET_SAVINGS_HABIT_DETAILS, GET_SPEND_ALERT_DETAILS, REMOVE_SAVINGS_HABIT, REMOVE_SPEND_ALERT, STATUS_SUCCESS_CODE, UPDATE_SAVINGS_HABIT, UPDATE_SPEND_ALERT } from '../../../../../network/URLConstants';
import savingsHabitSuccess from "../../../../../assets/images/savings-habit-done.png"
import CustomTextInput from '../../../../CustomComponents/CustomtextInput';
import CustomRadioButton from '../../../../CustomComponents/CustomRadioButton';
import CustomDatePicker from '../../../../CustomComponents/CustomDateInput';
import dayjs from 'dayjs';
import checkbox_fill from "../../../../../assets/images/Checkbox-blue.png"
import checkbox_null from "../../../../../assets/images/Checkbox-light.png"
import confirmRemoveImg from '../../../../../assets/images/illo-spending-alert-remove.png'
import SucessRemoveImg from '../../../../../assets/images/illo-spending-alert-done.png'
import { width } from '../../../../CustomComponents/DynamicCard/DynamicCardStyles'


import moment from 'moment'
import Success from '../../Success';
import Error from '../../Error';
import logFirebaseEvents from '../../../../LogFirebaseEvents';


const EditSpendAlert = () => {

  const HeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] font-normal text-[#2965FB]";
  const SubHeadingStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] font-normal text-secondary-3 md:w-[80%]";

  const { t } = useTranslation()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  //const SpendAlertId = params.id;
  const SpendAlertId = useSelector(state => JSON.parse(state.spendsSelctedId));

  const [isLoading, setIsLoading] = useState(false);
  const [spendAlertObj, setSpendAlertObj] = useState(null)
  const [frequencyArray, setFrequencyArray] = useState([{ 'displayText': t('daily') }, { 'displayText': t('weekly') }, { 'displayText': t('monthly') }])
  // const [notificationArray, setNotificationArray] = useState([{ 'displayText': t('text') }, { 'displayText': t('email') }])
  const [amountError, setAmountError] = useState()
  const [frequencyError, setFrequencyError] = useState(null)
  const [notification, setNotification] = useState(null)
  const [dateError, setDateError] = useState(null)
  const [notificationError, setNotificationError] = useState(null)
  const [error, setError] = useState(null);
  const [updateReqObj, setupdateReqObj] = useState({})
  const [activeCount, setactiveCount] = useState(1);
  const climbFeatures = useSelector(state => state.climbFeatures);
  const enabledClimbings = climbFeatures?.filter((response) => response.enabled === true);
  const isCoveredByKudzuOptedIn = enabledClimbings?.some(item => item.code === 'CoveredByKudzu' && item.optedIn)
  let inputVal = ''


  const [notificationArray, setNotificationArray] = useState([{ 'displayText': t('text'), 'isSelected': false }, { 'displayText': t('email'), 'isSelected': false }])

  const accessToken = useSelector(state => state.accessToken);
  const cards = useSelector(state => JSON.parse(state.userCards));
  const activeAccountCount = useSelector(state => state.spendAlertCount);

  // const setNotificationFun = (value) => {
  //   // debugger;
  //   const key = (value.displayText === 'Email') ? "email" : "sms";
  //   setSpendAlertObj({
  //     ...spendAlertObj,
  //     [key]: true,
  //     [key === 'email' ? 'sms' : 'email']: false
  //   });

  //   setNotification(value.displayText);
  // }

  const setNotificationFun = (option) => {
    const selectedIndex = notificationArray.findIndex(
      (item) => item.displayText === option.displayText
    );
    notificationArray[selectedIndex] = { ...option, 'isSelected': !option?.isSelected }
    setNotificationArray([...notificationArray])
    if (option.displayText === "Email") {
      setSpendAlertObj({
        ...spendAlertObj,
        'email': !option?.isSelected,

      });
    } else {
      setSpendAlertObj({
        ...spendAlertObj,
        'sms': !option?.isSelected,

      });

    }
  }
  //API Call-------------------
  const getSpendAlertIDDetails = async () => {
    if (cards == null || cards == undefined) {
      return
    }
    try {
      setIsLoading(true);

      const deviceId = await retrieveData('updatedDeviceId')
      const cardKey = cards !== null ? cards[0].CardKey : 0;

      const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
      const url = GET_SPEND_ALERT_DETAILS.replace('{CARD_KEY}', cardKey).replace('{spendId}', SpendAlertId)
      const response = await GetRequest(url, headers);
      const responseJson = await JSON.parse(response);
      if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
        //debugger;
        const emailText = responseJson.Response.email === true ? t('email') : '';
        const smsText = responseJson.Response.sms === true ? t('text') : '';
        const notificationText = emailText || smsText || '';
        setNotification(notificationText);
        notificationArray[0] = { 'displayText': t('text'), 'isSelected': responseJson.Response.sms }
        notificationArray[1] = { 'displayText': t('email'), 'isSelected': responseJson.Response.email }
        setSpendAlertObj(responseJson.Response)
        setIsLoading(false);

      } else if (responseJson.Messages) {
        const displayErrorText = responseJson.Messages[0].DisplayText;
        setError(displayErrorText);
        setIsLoading(false);
      }

    } catch (error) {
    }
  }
  const updateSpendAlert = async (modifiedData) => { 
    logFirebaseEvents("Edit_SpendSense_Alert", "Update_SpendSense_Alerts_Clicked", "Update_SpendSense_Alerts_Clicked","", "")
    if (cards == null || cards == undefined) {
      return
    }
    try {
      setIsLoading(true);
      const deviceId = await retrieveData('updatedDeviceId')
      const cardKey = cards !== null ? cards[0].CardKey : 0;
      const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
      const url = UPDATE_SPEND_ALERT.replace('{CARD_KEY}', cardKey).replace('{spendId}', SpendAlertId)
      const body = modifiedData;
      const response = await PostRequest(url, body, headers);
      const responseJson = await JSON.parse(response);
      if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
        // alert('ss');
        setIsLoading(false);
        openSuccessfullUpdateModal();
      } else if (responseJson.Messages) {
        openErrorModal()
        const displayErrorText = responseJson.Messages[0].DisplayText;
        setIsLoading(false);
      }

    } catch (error) {
    }
  }
  const removeSpendAlert = async (payload) => { 
    logFirebaseEvents("Edit_SpendSense_Alert", "Remove_SpendSense_Alerts_Clicked", "Remove_SpendSense_Alerts_Clicked","", "")
    if (cards == null || cards == undefined) {
      return
    }
    try {
      setIsLoading(true);

      const deviceId = await retrieveData('updatedDeviceId')
      const cardKey = cards !== null ? cards[0].CardKey : 0;
      const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
      const url = REMOVE_SPEND_ALERT.replace('{CARD_KEY}', cardKey).replace('{spendId}', SpendAlertId)
      const response = await PostRequest(url, {}, headers);
      const responseJson = await JSON.parse(response);
      if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
        closeRemoveModal();
        openSuccessfullRemoveModal();
        setIsLoading(false);
      } else if (responseJson.Messages) {
        const displayErrorText = responseJson.Messages[0].DisplayText;
        openErrorModal();
        setIsLoading(false);
      }

    } catch (error) {
    }
  }


  useEffect(() => {
    getSpendAlertIDDetails();


  }, [])


  const setFrequencyFun = (value) => {
    setSpendAlertObj({ ...spendAlertObj, 'frequencyType': value.displayText })
  }

  const onDateChange = (date) => {
    //alert(JSON.stringify(spendAlertObj));
    setSpendAlertObj({ ...spendAlertObj, 'startDate': date })
  }


  const handleRemoveSpendAlert = () => { 
    logFirebaseEvents("Edit_SpendSense_Alert", "Open_SpendAlerts_Clicked", "Open_SpendAlerts_Clicked","", "")
    openRemoveModal(true);
  }


  // Successfull Update modal
  const [isSuccessfullUpdateModalOpen, setIsSuccessfullUpdateModalOpen] = useState(false);

  const openSuccessfullUpdateModal = () => {
    setIsSuccessfullUpdateModalOpen(true);
  };

  const closeSuccessfullUpdateModal = () => {
    setIsSuccessfullUpdateModalOpen(false);
    navigate("/ClimbMenu")

  };

  // Remove modal
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const openRemoveModal = () => {
    setIsRemoveModalOpen(true);
  };

  const closeRemoveModal = () => {
    logFirebaseEvents("Edit_SpendSense_Alert", "Cancel_Remove_Alert_Clicked", "Cancel_Remove_Alert_Clicked","", "")
    setIsRemoveModalOpen(false);

  };


  // Successfull Remove modal
  const [isSuccessfullRemoveModalOpen, setIsSuccessfullRemoveModalOpen] = useState(false);

  const openSuccessfullRemoveModal = () => {
 
    setIsSuccessfullRemoveModalOpen(true);
  };

  const closeSuccessfullRemoveModal = () => {
    setIsSuccessfullRemoveModalOpen(false);
    navigate("/Climb/ClimbMenu")

  };


  const handleBackToClimb = () => {
    logFirebaseEvents("Edit_SpendSense_Alert", "BackTo_Climb_SpendAlerts_Clicked", "BackTo_Climb_SpendAlerts_Clicked","", "")
    navigate("/Climb/ClimbMenu")
  }

  const handleBackSpendAlerts = () => { 
    logFirebaseEvents("Edit_SpendSense_Alert", "BackTo_Edit_SpendAlerts_Clicked", "BackTo_Edit_SpendAlerts_Clicked","", "")
    navigate("/Climb/ManageSpendAlerts")
  }




  const [isErrorModalOpen, setIsErroeModalOpen] = useState(false);

  const openErrorModal = () => {
  
    setIsErroeModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErroeModalOpen(false);

  };



  /////save details

  const handleSaveClick = () => {  
    if (!spendAlertObj.sms && !spendAlertObj.email) {
      setNotificationError('Choose Notification Type');
    } else {
      setNotificationError('');
    }

    if (!spendAlertObj.amount) setAmountError('Enter Amount');
    else setAmountError("")
    const modifiedData = {
      ...spendAlertObj,
      frequencyType: getFrequencyAbbreviation(spendAlertObj.frequencyType),
      startDate: moment(spendAlertObj.startDate).format('YYYY-MM-DD'),
    };
    console.log("modifiedData", modifiedData);
    if (spendAlertObj && spendAlertObj.amount && (spendAlertObj.sms || spendAlertObj.email)) updateSpendAlert(modifiedData);

  }

  const handleOnBlur = (e) => {
    if (!spendAlertObj.amount) setAmountError('Enter Amount');
    else if (spendAlertObj.amount <= 0) {
      setAmountError('Please enter amount greater than 0')
    }
    else setAmountError('')
  }



  const cardHeader = () => {
    return (
      <>
        <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500]
 lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
 font-hanken-grotesk sm:mx-0 mx-4"> {t("editSpendAlerts")}</div>
        <hr className=" h-[3px] bg-[#94C3E8]  rounded sm:mx-0 mx-4" />
      </>
    )
  }

  // const cardFooter = () => {
  //   return (
  //     <div className='flex flex-row sm:justify-end justify-between 
  //     items-center sm:gap-4 lg:pb-[5rem] md:pb-[4rem] sm:pb-[3rem] pb-[3rem] sm:mx-0 mx-4'>
  //       <div>
  //         <div className=' flex flex-row justify-end gap-2 cursor-pointer items-center '>
  //           <p className=' font-hanken-grotesk font-[600] sm:font-[700] lg:text-[18px] md:text-[17px] 
  //           sm:text-[15px] text-[16px]
  //           leading-[26px]  sm:leading-[13px] 
  //            md:leading-[18px] text-secondary-2 ' onClick={handleSaveClick}>{`${t("save")}`}</p>
  //           <img src={RightArrow} alt="arrow" className='h-[12px] w-[12px] sm:h-[10px]
  //            sm:w-[11px]  md:h-[15px] md:w-[17px] ' />
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  const handleBackClick = () => { 
    logFirebaseEvents("Edit_SpendSense_Alert", "BackTo_Manage_SpendAlerts_Clicked", "BackTo_Manage_SpendAlerts_Clicked","", "")
    navigate("/Climb/ManageSpendAlerts")
  }

  const cardFooter = () => {
    return (
      <div className='flex flex-row sm:justify-end justify-between 
      items-center lg:gap-12 md:gap-10 gap-9 lg:pb-[5rem] md:pb-[4rem] sm:pb-[3rem] pb-[3rem] sm:mx-0 mx-4'>

        <p className='font-hanken-grotesk font-[600] cursor-pointer sm:font-[700] lg:text-[18px] md:text-[17px] 
            sm:text-[15px] text-[16px] leading-[26px]  sm:leading-[13px] 
             md:leading-[18px] text-secondary-2 ' onClick={handleBackClick}
          tabIndex={0}
          onKeyDown={(e) => { if (e.key === "Enter") handleBackClick() }}
        >{`${t("back")}`}</p>
        <div>
          <div className='flex flex-row justify-end  gap-2 cursor-pointer items-center'>
            <p className='font-hanken-grotesk font-[600] sm:font-[700] lg:text-[18px] md:text-[17px] 
            sm:text-[15px] text-[16px] leading-[26px]  sm:leading-[13px] 
             md:leading-[18px] text-secondary-2 ' onClick={handleSaveClick}
              tabIndex={0}
              onKeyDown={(e) => { if (e.key === "Enter") handleSaveClick() }}
            >{`${t("save")}`}</p>
          </div>
        </div>
      </div>
    )
  }



  const children = () => {
    return (
      <>
        {cardHeader()}
        <div className='w-full flex justify-center'>
          <div className="sm:w-[80%] w-full">
            <div className='md:mb-12 sm:mb-12 mb-[3rem] sm:mx-0 mx-4'>
              <div className='md:w-[80%] sm:w-[86%] w-full flex flex-col justify-center'>
                {(spendAlertObj) &&
                  <div>
                    <div className='h-auto md:pt-16 sm:pt-13 pt-12'>
                      <CustomTextInput name={t("amount")}
                        onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); } }}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          const formattedAmount=handleAmountInputLimitTo2decimal(inputValue)
                          setSpendAlertObj({ ...spendAlertObj, 'amount': formattedAmount.toString().replace('$', '') })
                          if (!formattedAmount) setAmountError('Enter Amount');
                          else if (inputValue <= 0) {
                            setAmountError('Please enter amount greater than 0')
                          }
                          else setAmountError('')
                        }

                        }
                        value={spendAlertObj.amount ? `$${spendAlertObj.amount}` : ''}
                        error={amountError}
                        placeholder={"$ 0"}
                        inputWidth='w-full'
                        autoFocus={true}
                        onBlur={(event) => handleOnBlur(event)}

                      />

                    </div>

                    <div className='w-full h-auto  md:pt-16 sm:pt-13 pt-12'>
                      <CustomRadioButton title="FREQUENCY"
                        options={frequencyArray} frequency={spendAlertObj.frequencyType}
                        onSelect={setFrequencyFun} isRow={'row'} error={frequencyError}
                      />
                    </div>
                    <div className='only-of-type:h-auto  md:pt-16 sm:pt-13 pt-12'>
                      <CustomDatePicker
                        selectedDate={spendAlertObj.startDate}
                        onDateChange={(date) => onDateChange(date)}
                        minDate={new Date(new Date().toUTCString().substr(0, 25))}
                        // maxDate={''}
                        disabledDates={[]}
                        title="Start"
                        hrbg='bg-dropdown-sky'
                      />
                      <div className='my-2 w-full'>
                        {dateError && displayErrorMessage(dateError)}
                      </div>
                    </div>
                    <div className='h-auto md:pt-16 sm:pt-13 pt-12 md:w-[60%]'>
                      {spendAlertObj &&
                        <CustomRadioButton
                          title={t('spending_alert_notification_title')}
                          options={notificationArray}
                          onSelect={setNotificationFun}
                          fillIcon={checkbox_fill}
                          nullIcon={checkbox_null}
                          error={notificationError}
                        />
                      }

                      <p className="font-hanken-grotesk my-2 text-[12px] leading-[20px] 
                    font-normal">{t('sms_rating')}</p>
                    </div>
                  </div>
                }

                <p className='font-hanken-grotesk font-[600] pt-10 sm:font-[700] lg:text-[18px] 
                md:text-[17px] sm:text-[15px] text-[16px]
                 leading-[26px]  sm:leading-[13px]  
                 md:leading-[18px] text-secondary-2 cursor-pointer capitalize'
                  onClick={handleRemoveSpendAlert}
                  tabIndex={0}
                  onKeyDown={(e) => { if (e.key === "Enter") handleRemoveSpendAlert() }}
                >{t('Remove_Alert')}</p>
              </div>

            </div>
          </div>
        </div>
        {/* FOOTER */}
        {cardFooter()}
        {/* ---------- */}

      </>
    )
  }
  const ManageSettingsbuttonLabels = [
    { label: 'SpendSense Alerts', link: '/Climb/ManageSpendAlerts' },
    { label: 'Covered by Kudzu', link: isCoveredByKudzuOptedIn ? '/Climb/ManageCoveredByKudzu' : '/Climb/CoveredByKudzu' },
    { label: 'Savings Habits', link: '/Climb/ManageSavingsHabit' },
  ];


  const navigateClicked = (item) => {
    navigate(item.link)
  }
  return (
    <HomeLayout>
      <ClimbManageSettingsSidebar indexBtn={1}
        ManageSettingsbuttonLabels={ManageSettingsbuttonLabels} count={activeAccountCount} navigateClicked={navigateClicked} />

      <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] animate__animated
       animate__fadeInRight '>

        <DynamicCard
          header={true}
          CardRadius={true}
          cardWidth={width.homeCard}
          HeaderRadius={true}
          footer={true}
          HeaderContent={false} isWhite={true}
          backgroundColor={'#FFFFFF'} >
          {children()}
        </DynamicCard>

      </div>

      {/* //////update sucess modal */}

      <Modal
        isOpen={isSuccessfullUpdateModalOpen}
        onClose={closeSuccessfullUpdateModal}
        alignItems={'items-center'}
        isClimbg={true}
        title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('success')}</p>}
        subtitle={
          <p>
            <span className={`${HeadingStyles} visible sm:hidden`}>{t('success')}</span><span className={SubHeadingStyles}>Successfully updated</span>
          </p>}
        content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
          <Success btn1={t("back_to_climb")} btn1Function={handleBackToClimb} icon={savingsHabitSuccess} /></div>}
      />
      <Modal
        Fullheight={true}
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        alignItems={'items-center'}
        isClimbg={true}
        title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('removeSpednAlert')}</p>}
        subtitle={
          <p>
            <span className={`${HeadingStyles} visible sm:hidden`}>{t('removeSpednAlert')}</span><span className={SubHeadingStyles}>{t('removeSpendAlertSubHeading')}</span>
          </p>}
        content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
          <Success content1={t("removeSpendAlertMsg")} btn1={t("removeSpentAlertBtn")} btn1Function={removeSpendAlert} btn2={t("cancel")} btn2Function={closeRemoveModal} icon={confirmRemoveImg} /></div>}
      />
      <Modal
        isOpen={isSuccessfullRemoveModalOpen}
        onClose={closeSuccessfullRemoveModal}
        alignItems={'items-center'}
        isClimbg={true}
        title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('done_exclamation')}</p>}
        subtitle={
          <p>
            <span className={`${HeadingStyles} visible sm:hidden`}>{t('done_exclamation')}</span><span className={SubHeadingStyles}>{t('removeSpendSuccess')}</span>
          </p>}
        content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6">
          <Success content1={t("removeSpendSucessMsgParg")} btn1={t("back_to_climb")} btn1Function={handleBackToClimb} btn2={t("back_to_spend_alerts")} btn2Function={handleBackSpendAlerts} icon={SucessRemoveImg} /></div>}

      />



      <Modal
        Fullheight={true}
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        alignItems={'items-center'}
        isClimbg={true}
        title={<p className={`${HeadingStyles} hidden sm:flex`}>{t('oops_exclamation')}</p>}
        subtitle={
          <p>
            <span className={`${HeadingStyles} visible sm:hidden`}>{t('oops_exclamation')}</span><span className={SubHeadingStyles}>{t('SpendAlertError')}</span>
          </p>}

        content={<div class="aspect-454/253 md:mt-10 sm:mt-6 mt-6"><Error btn1={t("back_to_climb")} btn1Function={handleBackToClimb} btn2={t("resumeSpendsBtn")} btn2Function={handleBackSpendAlerts} /></div>}

      />



      {isLoading && <Loader />}


    </HomeLayout >
  )
}

export default withOidcSecure(EditSpendAlert)



