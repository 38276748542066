// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { fetchConfigarationData } from "./helpers/utils/Utils";
import { getAnalytics } from "firebase/analytics";
const appConfigData = await fetchConfigarationData(); 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: appConfigData.GA_FIREBASE_APIKEY,
  authDomain: appConfigData.GA_FIREBASE_AUTH_DOMAIN,
  projectId: appConfigData.GA_FIREBASE_PROJECT_ID,
  storageBucket:  appConfigData.GA_STORAGE_BUCKET,
  messagingSenderId:appConfigData.GA_MESSAGE_SENDER_ID,
  appId:appConfigData.GA_APP_ID,
  measurementId:appConfigData.GA_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);
export { analytics,messaging };