import React, { useState, useEffect } from 'react'
import Card from '../../CustomComponents/Card'
import { height, width } from "../../CustomComponents/Card/CardStyles";
import { useTranslation } from 'react-i18next';
import BeneficiaryListItem from '../BeneficiaryListItem';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../Layout/HomeLayout';
import LineBreaks from '../../../utils';
import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { GET_BENEFICIARIES, GENERATE_OTP_ADD_AUTH_USER, STATUS_SUCCESS_CODE } from '../../../network//URLConstants';
import { connect, useDispatch, useSelector } from 'react-redux';
import { retrieveData, storeData } from '../../../helpers/utils/Utils';
import { useNavigate } from 'react-router';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest, toUrlEncodedString } from '../../../network/NetworkUtils';
import { LOGIN_SESSION_KEY } from '../../../Redux/reducers';
import Modal from '../../CustomComponents/Modal';
import Custom2FA from '../../CustomComponents/Custom2FA';
import Loader from '../../CustomComponents/Loader';
import { displayErrorMessage, displaySuccessMessage } from '../../../utils';
import TwoFactTypeSelctionModal from '../../CustomComponents/TwoFactTypeSelctionModal';


const ManageBeneficiaries = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Headingcom = <p className='text-white'>Keep track of your beneficiary information here.</p>
    const [benficiriesList, setBenficiriesList] = useState([]);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const [isLoading, setIsLoading] = useState(false);
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none leading-[30px]";
    const [twoFactModelTitle, setTwoFactModelTitle] = useState('');
    const [twoFactModelSubTitle, setTwoFactModelSubTitle] = useState('');
    const [twoFactModelContent, setTwoFactModelContent] = useState('');
    const [showStnadradRatesMessage, setShowStnadradRatesMessage] = useState(false);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [selectedType, setSelectedType] = useState('');
    const [infoMessage, setinfoMessage] = useState('');
    const [show2faSelection, setshow2faSelection] = useState(false);
    const [otpGenerationType, setOtpGenerationType] = useState(false);

    async function getBeneficiaries() {
        const cardKey = cards !== null ? cards[0].CardKey : 0;
        var url = GET_BENEFICIARIES.replace("{cardKey}", cardKey)
        const deviceId = await retrieveData('updatedDeviceId');
        let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setIsLoading(true)
        const response = await GetRequest(url, headers)
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
        var parsedResponse = JSON.parse(response)
        setBenficiriesList(parsedResponse.Response)
    }
    const heading = () => {
        return (
            <>
                <div className='lg:w-[260px] md:w-[183px] sm:w-[164px] w-full sm:mx-0 ml-[2.5rem] sm:pb-0 pb-8'>
                    <p className="text-white font-normal lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px] lg:leading-[38px] 
                    md:leading-[33px] sm:leading-[30px] leading-[31px]
                  font-serif-pro ">{(t('add_benf_left_title'))}</p>
                </div>
            </>
        )
    }
    useEffect(() => {
        getBeneficiaries();
    }, []);

    const editBenficireyClicked = (selectedObject) => {
        const benficary = { benficaryKey: selectedObject.Id };
        dispatch({ type: 'BENFICIRIEY_KEY', payload: benficary });
        const editBenficireyform = { isEditBenificirey: true };
        dispatch({ type: 'EDIT_BENFICIRIES', payload: editBenficireyform });
        navigate('/Settings/manageBeneficiaries/editBeneficiary');
    };
    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }
    const verificationSuccess = (type) => {
        if (type == t('add_auth_user')) {
            // handleAuthUser()
            navigate("/Settings/manageBeneficiaries/addBeneficiaries")
        }
    }
    const verificationFaiure = (type, message) => {
        // setIsOTPVerifyModalOpen(false);
        setinfoMessage(message);
    }
    async function handleAuthUser(procType) {
        if (twoFactAuthenticationDone) {
            navigate("/Settings/manageBeneficiaries/addBeneficiaries")
        } else {
            if (cards == null || cards == undefined) {
                return
            }
            const deviceId = await retrieveData('updatedDeviceId');
            let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
            const cardKey = cards !== null ? cards[0].CardKey : 0;
            const url = GENERATE_OTP_ADD_AUTH_USER.replace('{CARD_KEY}', cardKey)
            setIsLoading(true);
            let object = {}
            if (procType !== undefined || procType !== null) {
                object.IsEmail = (procType == 'Email') ? true : false
            }

            const response = await PostRequest(url, object, headers)
            const parsedResponse = JSON.parse(response);
            setIsLoading(false);
            if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
                setTimeout(() => {
                    const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                    dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                    const userIdPayload = { userId: parsedResponse.Response.userId }
                    dispatch({ type: 'USER_ID', payload: userIdPayload });
                    setSelectedType(t('add_authorized_users'));
                    if (procType !== undefined || procType !== null) {
                        if (procType == 'Email') { setTwoFactModelTitle(t('great_exclamation')); setTwoFactModelSubTitle(t('we_are_sending_email_message')); }
                        else { setTwoFactModelTitle(t('great_exclamation')); setTwoFactModelSubTitle(t('we_are_sending_messsage')); }
                    } else { setTwoFactModelTitle(t('security_check')); setTwoFactModelSubTitle(t('we_are_sending_messsage')); }
                    setShowStnadradRatesMessage(true);
                    setIsOTPVerifyModalOpen(true);
                }, 1000);

            } else {
                var arrMessages = parsedResponse.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                setinfoMessage(displayText);

            }
        }
    }

    return (
        <>
            <HomeLayout>
                <LeftBar leftBarType='text' isPrimary={true} heading={heading()} />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
                 animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        CardRadius={true}
                        cardWidth={width.allpolicies}
                        HeaderRadius={true}
                        isWhite={true}
                        HeaderContent={false}>

                        <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px] leading-[36px]
                 font-hanken-grotesk sm:mx-0 mx-4">{t("manage_beneficiaries")}</div>
                        <div className='border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4'></div>

                        <div className='justify-center items-center flex flex-col lg:pb-40 md:pb-40 sm:pb-28 pb-40 sm:mx-0 mx-4'>
                            <div className='lg:w-[80%] sm:w-[84%] w-full'>
                                {(benficiriesList !== null && benficiriesList.length > 0) &&
                                    benficiriesList?.map((nameObj, index) => {
                                        return <BeneficiaryListItem nameObj={nameObj}
                                            nextButtonClicked={editBenficireyClicked}
                                            listSize={benficiriesList.length}
                                            currentIndex={index} />
                                    })}
                                {(benficiriesList == undefined || benficiriesList == null || benficiriesList.length === 0) &&
                                    <div className='font-hanken-grotesk font-[500] lg:text-[20px] 
                                    md:text-[17px] sm:text-[16px] 
                                    text-[18px] flex justify-center items-center lg:leading-[30px]
                                     md:leading-[28px] leading-[25px]'>
                                        <p className='mt-10 md:w-[533px] w-full'>{(t("about_benficiriey_title"))}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* paragraph */}
                        <div class="border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4 border-opacity-[50%]"></div>
                        <div className='flex flex-col justify-center items-center md:py-20 sm:py-16 py-20 gap-8 sm:px-0 px-4'>
                            <button
                                onClick={() => {
                                    if (twoFactAuthenticationDone) {
                                        navigate("/Settings/manageBeneficiaries/addBeneficiaries")
                                    } else {
                                        setshow2faSelection(true)
                                    }
                                }}
                                className={`lg:w-[80%] sm:w-[84%] w-full h-[60px] border-[3px] 
                                border-border-submit font-hanken-grotesk text-secondary-2
                                 font-bold text-sm leading-4 ${(benficiriesList !== null && benficiriesList.length == 5) ? 'opacity-[0.5]' : 'opacity-1'}`} disabled={(benficiriesList !== null && benficiriesList.length == 5) ? true : false} >
                                {t("add_beneficiary")}
                            </button>
                            {(infoMessage) &&
                                <div className='mb-2 mt-2'>
                                    {displayErrorMessage(infoMessage)}
                                </div>
                            }

                            <div className='font-hanken-grotesk font-[500] lg:text-[16px] md:text-[15px] 
                            sm:text-[14px] text-[15px] 
                            leading-[24px] flex justify-center items-center'>
                                <p className='lg:w-[80%] sm:w-[84%] w-full'>
                                    {t("manage_beneficiaries_footer")}</p>
                            </div>
                        </div>
                    </DynamicCard>

                </div>

            </HomeLayout>
            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={closeOTPVerifyModal}
                isLogo={false}
                title={<p className={HighlightMsgStyles}> {twoFactModelTitle}</p>}
                subtitle={<><p className={HeaderMsgStyles}>{twoFactModelSubTitle}</p> {showStnadradRatesMessage && <p className="font-hanken-grotesk my-3 text-[#262B3A] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[27px] lg:leading-[30px] md:leading-[30px] leading-[30px] w-full">{t('standrd_message_rates_apply')}</p>}</>}
                content={<div className="mt-10"><Custom2FA selected2FAType={otpGenerationType} phoneNumber={""} email={""} address={{}} type={selectedType} onClose={closeOTPVerifyModal} verificationFailure={verificationFaiure} verificationSuccess={verificationSuccess} /></div>}
            />
            <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false) }} onClickNext={(object) => { setOtpGenerationType(object.proc); setshow2faSelection(false); handleAuthUser(object.proc) }} />
            {isLoading && <Loader />}
        </>

    )
}

export default withOidcSecure(ManageBeneficiaries)