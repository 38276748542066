import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomTextInput from '../../../../../CustomComponents/CustomtextInput';
import { useTranslation } from 'react-i18next'
import CustomRadioButton from '../../../../../CustomComponents/CustomRadioButton';
import CustomDropdown from '../../../../../CustomComponents/CustomDropDown';
import CustomDatePicker from '../../../../../CustomComponents/CustomDateInput';
import Calender from '../../../../../../assets/images/calender_3x.png'
import dayjs from 'dayjs'
import { handleAmountInputLimitTo2decimal } from '../../../../../../utils';


const ConfirmationModuleSubItem = ({ data, onEdit, isActive }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editedValue, setEditedValue] = useState(data.value);
  const [isEditing, setIsEditing] = useState(false);
  const [frequencyArray, setFrequencyArray] = useState([{ 'Type': t('daily'), 'Name': t('daily') }, { 'Type': t('weekly'), 'Name': t('weekly') }, { 'Type': t('monthly'), 'Name': t('monthly') }])
  const [frequency, setFrequency] = useState(null)
  const [error, setError] = useState('');
  const errorMessages = {
    amount: 'Amount is required',
    frequencyType: 'Frequency type is required',
    startDate: 'Start date is required'
  };
  let inputVal = ''




  const handleSave = () => {
    debugger;
    if (!editedValue) {
      setError(errorMessages[data.type]);
    } else {
      setError('');
      setIsEditing(false);
      dispatch({ type: 'ADD_SPEND_ALERTS', payload: { 'key': data.type, 'value': editedValue } });
    }
  };

  const handleOnBlur = (e) => {
    if (!editedValue) {
      setError(errorMessages[data.type]);
    }
    else if(data.type==="amount" && editedValue<=0){
      setError('Please enter amount greater than 0');}
    else {
      setError('');
    }
  }


  return (
    <>
      {data.value && (
        <div className='flex flex-col justify-center md:mt-12 sm:mt-10 mt-12'>

          {!isEditing && (
            <p className='pt-5 font-hanken-grotesk text-[#262B3A] 
            uppercase lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px]
            font-[600] tracking-[2px] md:pb-8 pb-4
            opacity-[50%]'>
              {data.title}
            </p>
          )}
          {isEditing ? (
            <>
              {data.type === 'amount' ? ( 
                <CustomTextInput
                  name={t("amount")}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      event.target.style.opacity = 0.8;
                      setTimeout(() => {
                        event.target.style.opacity = 1;
                      }, 200);
                    }
                  }}
                  value={editedValue}
                  onChange={(e) => { 
                    const inputValue = e.target.value;
                    const formattedAmount=handleAmountInputLimitTo2decimal(inputValue)
                    setEditedValue(formattedAmount.toString().replace('$', ''))
                    if (!formattedAmount) setError('Enter Amount');
                    else if (inputValue <= 0) {
                      setError('Please enter amount greater than 0')
                    }
                    else setError('')
                   }}
                  placeholder={"$ 0"}
                  onBlur={(event) => handleOnBlur(event)}
                  inputWidth='w-full'
                  error={error}
                />
              ) : data.type === 'frequencyType' ? (


                <CustomDropdown
                  isScroll={false}
                  options={frequencyArray}
                  title="FREQUENCY"
                  onSelect={(option) => { setEditedValue(option.Name) }}
                  selectedFrequency={editedValue}
                  error={error}
                />

              ) : data.type === 'startDate' ? (
                <>

                  <CustomDatePicker
                    selectedDate={data.value}
                    onDateChange={(date) => setEditedValue(date)}
                    minDate={new Date(new Date().toUTCString().substr(0, 25))}
                    //maxDate={''}
                    placeHolder={'Select Date'}
                    disabledDates={[]}
                    title="Start"
                    hrbg='bg-dropdown-sky'
                  />
                  <div>{error}</div>
                </>


              ) : null}
              <p className='pt-5 font-hanken-grotesk font-[700] cursor-pointer text-[#2965FB] lg:text-[16px] md:text-[15px] 
 sm:text-[13px] text-[13px]
 '
                onClick={handleSave}
                tabIndex={0}
                onKeyDown={(e) => { if (e.key === "Enter") handleSave() }}
              >
                {t("save")}
              </p>
            </>
          ) : (
            <>
              <p className='md:mb-6 mb-2 flex flex-row justify-between font-hanken-grotesk font-[700] lg:text-[16px] md:text-[15px] sm:text-[13px] 
 text-[14px]'>
                {data.title === 'Amount' ? `$ ${data.value}` : data.value}
                {data.title === 'Start' &&
                  <img src={Calender} alt="Calender" className='w-3 h-3 mr-2 ml-10' />
                }
              </p>
              <hr className='h-[2px] bg-[#94C3E8] border-0 rounded' />
              <p
                className='pt-5 font-hanken-grotesk font-[700] cursor-pointer text-[#2965FB] lg:text-[16px] md:text-[15px] 
                sm:text-[13px] text-[13px]
                '
                onClick={() => setIsEditing(true)}
                tabIndex={0}
                onKeyDown={(e) => { if (e.key === "Enter") setIsEditing(true) }}
              >
                {data.edit}
              </p>

            </>
          )}
        </div>
      )}

    </>


  )
}

export default ConfirmationModuleSubItem