import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withOidcSecure } from '@axa-fr/react-oidc';
import Loader from '../../../CustomComponents/Loader';
import LineBreaks, { ClimbCard, retrieveData, generateClimbData } from "../../../../utils";
import { ClimbMenuSidebar } from "../../../../utils";
import HomeLayout from "../../../Layout/HomeLayout";

import { GetApiHeadersWithBearer, GetRequest } from "../../../../network/NetworkUtils";
import { CAN_CREATE_SAVINGS_ACC, GET_CLIMB_FEATURES, SAVING_CARD_CODE, STATUS_SUCCESS_CODE } from "../../../../network/URLConstants";
import { useDispatch, useSelector, connect } from 'react-redux';
import Cards from "../../../CardServices/Cards";
import CustomAlertModel from "../../../CustomComponents/CustomAlertModel";
import Modal from "../../../CustomComponents/Modal";
import { useNavigate } from "react-router";
import reactElementToJSXString from "react-element-to-jsx-string";
import moment from "moment";


const ClimbMenu = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessToken = useSelector(state => state.accessToken);
    const [isClimbings, setisClimbings] = useState([]);
    const [isShowTick, setShowTick] = useState(false);
    const isPrimrayKey = cards !== null ? cards[0].CardKey : 0;
    const selectedItem = cards?.[0]
    const userId = useSelector(state => state.userId);
    const [loading, setLoading] = useState(false)
    const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue lg:text-[16px] md:text-[15px] text-[14px] placeholder:font-semibold font-medium focus:outline-none w-full h-[60px]";
    const SubHeadingStyles = "w-[90%] md:w-full font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-3";
    const SubHeadingStyles2 = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-2 pl-1 pr-1 mb-4";

    const [showErrorImg, setshowErrorImg] = useState(false);

    const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [showModel, setShowModel] = useState(false);
    const [modelImage, setModelImage] = useState('')
    const [savingsTheme, setSavingsTheme] = useState(false);
    const [isBorderNone, setisBorderNone] = useState(true);
    const [isSubFooter, setIsSubFooter] = useState(false);

    const accessTokenToApi = useSelector(state => state.accessToken);


    const navigate = useNavigate();
    const [isCoveredByKudzuIneligible, setIsCoveredByKudzuIneligible] = useState(false);
    const [isCoveredByKudzuIneligibleSetupSavings, setIsCoveredByKudzuIneligibleSetupSavings] = useState(false);
    const [isCoveredByKudzuIneligibleSetupSavingsPostPopup, setIsCoveredByKudzuIneligibleSetupSavingsPostPopup] = useState(false);
    const savingsWithBalanceOver50 = cards.find(
        obj => obj.AccountTypeBit63 == 32 && obj.Balance >= 50
    );



    const handleClimbFeatures = async () => {
        // debugger;
        if (isPrimrayKey == undefined || isPrimrayKey == null) {
            return
        }
        setIsLoading(true);
        const deviceId = await retrieveData('updatedDeviceId')
        const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
        const url = GET_CLIMB_FEATURES.replace('{CARD_KEY}', isPrimrayKey)
        const response = await GetRequest(url, headers);
        const responseJson = JSON.parse(response);
        if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
            // debugger;
            const enabledClimbings = responseJson.Response.filter(
                (response) => response.enabled === true

            );
            setisClimbings(enabledClimbings);
            dispatch({ type: 'UPDATE_CLIMB_FEATURE', payload: { climbFeatures: responseJson.Response } })
            setIsLoading(false);
        } else {
            return [];
        }

    }
    const closeSkipModal = () => {
        navigate('/home');
    };


    useEffect(() => {
        const savingsCardExist = cards?.some(item => item.AccountTypeName === "Savings")
        console.log(savingsCardExist, '69841684')
        handleClimbFeatures();
    }, [])

    const canCreateSavingsAccount = async () => {
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        setIsLoading(true)

        let finalUrl = CAN_CREATE_SAVINGS_ACC(cards[0].CardKey)
        var response = await GetRequest(finalUrl, headers)
        var responseObj = JSON.parse(response)
        setIsLoading(false)
        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            const canCreateNew = responseObj.Response.canCreateNew
            const eligibleDate = moment(responseObj.Response.eligibleDate).format('MM/DD/YYYY')
            if (canCreateNew) {
                enrollSavingsAccount()

            } else {
                setIsCoveredByKudzuIneligibleSetupSavings(false);
                showAlertModal(true, t("oh_no"), t('unable_to_open'), `${t('u_will_be_eligible')} ${eligibleDate}. `, true, false, t('home'), '', true, require('../../../../assets/images/illo-report.png'), false, false, true)
            }
        } else {
            setTimeout(() => {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                showAlertModal(true, t("oh_no"), displayText, '', true, false, t('home'), '', true, require('../../../../assets/images/illo-report.png'), false, false)

                // setError(displayText)
            }, 500);
        }
    }

    const subComponent = () => {
        return (
            <div className=' flex justify-start items-start'>
                <p className="font-hanken-grotesk font-semibold sm:font-[500] sm:text-[14px] md:text-[20px] text-[16px] md:leading-[30px] leading-[24px]">{t("pl_contact_customer")}<span className="text-[#2965FB] cursor-pointer" onClick={
                    () => window.open("https://www.kudzumoney.com/contact-us", '_blank')}>{t("support_email")}</span>{t("for_access")}</p>
            </div>
        )
    }

    const enrollSavingsAccount = () => {
        setIsCoveredByKudzuIneligibleSetupSavings(false);
        setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(true);
    }

    const closeCustomAlertModal = () => {
        setShowModel(false);
    }

    const onAcptBtnClick = async () => {

        setShowModel(false)
        if (btn1Text == t('home')) {
            navigate('/home')
        }
    }
    const onCancelBtnClick = () => {
        setShowModel(false)

    }

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, savingsTheme, isBorderNone, isSubFooter) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
        setSavingsTheme(savingsTheme)
        setisBorderNone(isBorderNone)
        setIsSubFooter(isSubFooter)

    }


    // debugger;

    const savingsAccount = cards.find(account => account.AccountTypeBit63 == SAVING_CARD_CODE);
    const ClimbData = isClimbings.map(item => generateClimbData(item, savingsAccount));
    const savingsCardExist = cards?.some(item => item.AccountTypeName === "Savings")

    const callIneligiblePopup = () => {
        setIsCoveredByKudzuIneligibleSetupSavings(true);
        //showAlertModal(true, t('sorry'), t("it_looks_like_your_not_eligible_right_now"), LineBreaks(t("you_need_savings_account")), true, true, t('enroll_in_savings_account'), t('back_to_climb'), true, require('../../../../assets/images/covered_by_kudzu_ineligible.png'), '', false, true)
    }

    const callAddMoneyToSavingsAccount = () => {
        setIsCoveredByKudzuIneligibleSetupSavings(false);
        setIsCoveredByKudzuIneligible(true)
        // showAlertModal(true, t('sorry'), t("it_looks_like_your_not_eligible_right_now"), LineBreaks(t("you_need_savings_account")), true, true, t('add_money_to_savings'), t('back_to_climb'), true, require('../../../../assets/images/covered_by_kudzu_ineligible.png'), '', false, true)
    }




    const climbCard = () => {
        return (
            <>
                {ClimbData.map((climb, index) => (
                    <ClimbCard
                        key={index}
                        image={climb.image}
                        savingsCardExist={savingsCardExist}
                        heading={climb.heading}
                        subheading={climb.subheading}
                        buttonText={climb.buttonText}
                        backgrounblue={index % 2 === 1 ? true : false}
                        RadiusTrue={index % 2 === 1 ? true : false}
                        disabled={climb.Disabled}
                        //    navigateClick= {  ( reactElementToJSXString(climb.subheading).includes('<p>') ) ? savingsCardExist ? !savingsWithBalanceOver50 ? callAddMoneyToSavingsAccount :  climb.link : callIneligiblePopup : climb.link}
                        navigateClick={(climb.heading == t('covered_by_cudzu')) ? savingsCardExist ? !savingsWithBalanceOver50 ? callAddMoneyToSavingsAccount : climb.link : callIneligiblePopup : climb.subheading == t('savigHbitDesc') ? savingsCardExist ? climb.link : callIneligiblePopup : climb.link}
                        // climb.subheading == t('savigHbitDesc')  ||
                        note={climb.note}
                    />
                ))}
            </>
        )
    }

    return (
        <div>
            <HomeLayout>
                <ClimbMenuSidebar />
                <div className='md:w-[150px] sm:w-[70px] w-[0px]'></div>
                <div className='flex justify-center sm:items-stretch flex-col md:w-[860px] sm:w-[464px] 
            w-[100%] animate__animated animate__fadeInRight  sm:px-0 pl-[2rem] pr-[2.5rem]'>
                    {climbCard()}
                </div>
            </HomeLayout>
            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                zIndex={300}
                highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                    <span className={`${HeaderMsgStyles} ${savingsTheme ? 'text-[#0C4437]' : 'text-black'}`}> {headerMsg}</span></p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                isBorderNone={isBorderNone}
                subFooter={isSubFooter && subComponent()}
            />

            {/* these modals im working on  */}
            <Modal
                isOpen={isCoveredByKudzuIneligibleSetupSavings}
                onClose={() => { setIsCoveredByKudzuIneligibleSetupSavings(false); }}
                isClimbg={true}
                Fullheight={true}
                subtitle={<div className="">
                    <span className={SubHeadingStyles2}>{t('sorry')}, </span>
                    <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
                    <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                        {LineBreaks(t("you_need_savings_account"))}</p>
                </div>
                }
                content={<div>

                    <img src={require('../../../../assets/images/covered_by_kudzu_ineligible.png')}
                        className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                    <div className="flex flex-col justify-center items-center gap-8 w-full">
                        <button
                            type="button"
                            className=" w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={canCreateSavingsAccount}
                            autoFocus
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    canCreateSavingsAccount()
                                    event.stopPropagation();
                                }
                            }}
                        >
                            {t('enroll_in_savings_account')}
                        </button>
                        <button
                            type="button"
                            className={`w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center p-4  sm:border-[0px]  border-[#2965FB] border-[2.5px]`}
                            onClick={() => {
                                setIsCoveredByKudzuIneligibleSetupSavings(false);
                                navigate('/Climb/ClimbMenu');
                            }}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setIsCoveredByKudzuIneligibleSetupSavings(false);
                                    navigate('/Climb/ClimbMenu');
                                    event.stopPropagation();
                                }
                            }}
                        >
                            {t('back_to_climb')}
                        </button>
                    </div>
                </div>}
            />
            <Modal
                bgColor={'bg-[#FEFAEE]'}
                isOpen={isCoveredByKudzuIneligibleSetupSavingsPostPopup}
                onClose={() => { closeSkipModal(); setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false); }}
                isClimbg={true}
                Fullheight={true}
                subtitle={<div className="">
                    <span className={SubHeadingStyles2}>{t('lets_get_started').replace('!', '')} </span>
                    <span className={SubHeadingStyles}>{t("with_creating_a_new_account")}</span>
                    <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                        {t("set_aside_money_for_things_that_matter")}</p>
                </div>
                }
                content={<div>

                    <img src={require('../../../../assets/images/kudzu_piggy_bank.png')} className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                    <div className="flex flex-col justify-center items-center gap-8 w-full">
                        <button
                            type="button"
                            className=" w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => {
                                setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false); dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('came_from_climb') } });
                                navigate('/SetupSavingsAccount');
                            }}
                            autoFocus
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    setIsCoveredByKudzuIneligibleSetupSavingsPostPopup(false);
                                    dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('came_from_climb') } });
                                    navigate('/SetupSavingsAccount');
                                    event.stopPropagation();
                                }
                            }}
                        >
                            {t('continue')}
                        </button>
                    </div>
                </div>}
            />
            <Modal
                isOpen={isCoveredByKudzuIneligible}
                onClose={closeCustomAlertModal}
                isClimbg={true}
                Fullheight={true}
                subtitle={<div className="">
                    <span className={SubHeadingStyles2}>{t('sorry')}, </span>
                    <span className={SubHeadingStyles}>{t("it_looks_like_your_not_eligible_right_now")}</span>
                    <p className='mt-4 font-hanken-grotesk font-[500] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[23px] lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-3'>
                        {t("you_need_minimum_50")}</p>
                </div>
                }
                content={<div>

                    <img src={require('../../../../assets/images/covered_by_kudzu_ineligible.png')}
                        className="block m-auto lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] lg:my-16 md:my-14 sm:my-10 my-10" />

                    <div className="flex flex-col justify-center items-center gap-8 w-full">
                        <button
                            type="button"
                            className="focus:outline-none   w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => { navigate('/AccountServices/Spending/TransferMoney') }}
                            autoFocus
                        >
                            {'Add Money to Savings'}
                        </button>
                        <button
                            type="button"
                            className="w-full font-hanken-grotesk sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                            onClick={() => {
                                setIsCoveredByKudzuIneligible(false);
                                navigate('/Climb/ClimbMenu');
                            }}>
                            {/* {'Maybe later'} */}
                            {t('back_to_climb')}
                        </button>
                    </div>
                </div>}
            />
            {isLoading && <Loader />}
        </div>

    )
}

export default withOidcSecure(ClimbMenu)