import React, { useState, useEffect, lazy, Suspense, useRef } from "react";
import { useTranslation } from "react-i18next";
// import Header from "../Header";
// import Modal from "../../CustomComponents/Modal";
// import MobileVerification from "../../OnBoard/Signup/MobileVerification";
// import ValidatePhoneOTP from "../../OnBoard/Signup/MobileVerification/ValidateOTP";
// import ValidateAuthCode from "../../OnBoard/Signup/MobileVerification/ValidateAuthCode";
// import VerifyEmail from "../../ResetPassword/VerifyEmail";
// import SecurityCheck from "../../ResetPassword/SecurityCheck";
// import ResetYourPassword from "../../ResetPassword/ResetYourPassword";
// import ResetSuccess from "../../ResetPassword/ResetSuccess";
import addLineBreaks, { delayForDemo } from "../../../utils";
import TrustDevice from "../../Login/TrustDevice";
import VerifyOtp from "../../Login/VerifyOtp";
import { v4 as uuidv4 } from "uuid";
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  retrieveData,
  storeData,
  DEVICE_VERIFICATION,
  fetchConfigarationData,
} from "../../../helpers/utils/Utils";
import {
  GetApiHeadersWithBearer,
  GetApiHeadersWithOutBearer,
  GetRequest,
  PostRequest,
} from "../../../network/NetworkUtils";
import {
  POST_LOGIN,
  POST_REGISTER_FCM_TOKEN,
  STATUS_SUCCESS_CODE,
  GENERATE_OTP,
  TWO_AUTH_VERIFY_OTP,
  PROGRAM_CONTENT,
  SAVING_CARD_CODE,
  SIGN_UP_FEATURE,
  CONTACT_US_URL,
  VERIFY_ACCESS_CODE,
} from "../../../network//URLConstants";
import { connect, useDispatch, useSelector } from "react-redux";
import Loader from "../../CustomComponents/Loader";
import { LOGIN_SESSION_KEY } from "../../../Redux/reducers";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../../assets/images/banner.png";
import report from "../../../assets/images/illo-report.png";
import Klogo from "../../../assets/images/K.png";
import NextArrow from "../../../assets/images/NextArrow.png";
import Check from "../../../assets/images/earlyAccessCheck.png";
import Trusted from "../../../assets/images/illo-trusted-devices.png";
import { useParams } from "react-router";
import { displaySignupErrorMessage } from "../../../utils";
import debounce from "lodash/debounce";
import ExistingErrorModal from "../../OnBoard/Signup/ExistingErrorModal";
import AccessCode from "../../OnBoard/Signup/AccessCode";
import { useKeyDown } from "../../../hooks/useKeyDown";
import logFirebaseEvents from "../../LogFirebaseEvents";

// import TwoFactTypeSelctionModal from "../../CustomComponents/TwoFactTypeSelctionModal";
// const Header = lazy(() => import('../Header'));
// const lazyDelayed = (path, delay = 1000) => {
//   return lazy(() => Promise.all([
//     import(path),
//     new Promise((resolve) => setTimeout(resolve, delay)) // ensures minimal delay
//   ]).then(([module]) => module));
// }

const Header = lazy(() => delayForDemo(import("../Header")));
// const Modal = lazy(() => delayForDemo(import('../../CustomComponents/Modal')));

// const Food = lazyDelayed('./components/Food')
// const Header = lazyDelayed('../Header');
const Modal = lazy(() => import("../../CustomComponents/Modal"));
const MobileVerification = lazy(() =>
  import("../../OnBoard/Signup/MobileVerification")
);
const ValidatePhoneOTP = lazy(() =>
  import("../../OnBoard/Signup/MobileVerification/ValidateOTP")
);
const ValidateAuthCode = lazy(() =>
  import("../../OnBoard/Signup/MobileVerification/ValidateAuthCode")
);
const VerifyEmail = lazy(() => import("../../ResetPassword/VerifyEmail"));
const SecurityCheck = lazy(() => import("../../ResetPassword/SecurityCheck"));
const ResetYourPassword = lazy(() =>
  import("../../ResetPassword/ResetYourPassword")
);
const ResetSuccess = lazy(() => import("../../ResetPassword/ResetSuccess"));
// const TrustDevice = lazy(() => import('../../Login/TrustDevice'));
// const VerifyOtp = lazy(() => import('../../Login/VerifyOtp'));
// const Banner = lazy(() => import('../../../assets/images/banner.png'));
// const Trusted = lazy(() => import('../../../assets/images/illo-trusted-devices.png'));
const TwoFactTypeSelctionModal = lazy(() =>
  import("../../CustomComponents/TwoFactTypeSelctionModal")
);
const appConfigData = await fetchConfigarationData();

export const ACTIVITY_TYPE_E_SIGN = "ESign";
const PreLogin = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //styles for input
  const HeadingStyles =
    "font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-[#2965FB]";
  const SubHeadingStyles =
    "w-[90%] md:w-full font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-3";
  // const SubHeadingStyles = "lg:w-[400px] md:w-[400px] sm:w-[224px] font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-3";
  const FailSubHeaderMsgStyles =
    "md:w-[317px] sm:w-[257px] font-hanken-grotesk modal-title  md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer";

  //modal styles
  const SubTittleStylesModal =
    "font-serif-pro modal-title sm:text-[18px]  lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-2";

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  // const params = useParams();
  const { forgotEmail } = useParams();

  const [isMobileVerificationModalOpen, setIsMobileVerificationModalOpen] =
    useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [loginOTP, setLoginOTP] = useState("");
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const [forgotEmailAddress, setForgotEmailAddress] = useState("");
  const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
  const [authUserCodeModelOpen, setAuthUserCodeModelOpen] = useState(false);
  const [existingErrorModalOpen, setExistingErrorModalOpen] = useState(false);
  const [isLoginTrustDeviceOpen, setLoginTrustDeviceOpen] = useState(false);
  const [actionType, setActionType] = useState(DEVICE_VERIFICATION);
  const [showPassCodeExpire, setShowPassCodeExpire] = useState(false);
  const [isTryAgain, setIsTryAgain] = useState(false);
  const [showValidationMsg, setShowValidationMessage] = useState(false);
  const [loginOtpErrorMessage, setLoginOtpErrorMessage] = useState("");
  const [loginTyagainCount, setLoginTryAgainCount] = useState(1);
  const [showAgreementsModel, setShowAgreementsModel] = useState(false);
  const [policyURL, setPolicyURL] = useState("");
  const [phScreenAgreeTerms, setphScreenAgreeTerms] = useState(false);
  const [SingupCheckBoxMessage, setSingupCheckBoxMessage] = useState("");
  const [greyBg, setGreyBg] = useState(false);
  const [show2faSelection, setshow2faSelection] = useState(false);
  const [selectedOtpType, setSelectedOtpType] = useState("");
  const [showPolicys, setShowPolicys] = useState("terms");
  const [isAccessCodeModalOpen, setIsAccessCodeModalOpen] = useState(false);
  const [isAccessCodeErrorModalOpen, setIsAccessCodeErrorModalOpen] =
    useState(false);
  const [isAccessCodeNoWorriesModalOpen, setIsAccessCodeNoWorriesModalOpen] =
    useState(false);
  const [validAccessCode, setValidAccessCode] = useState(false);
  const [callVerifyAccessCodeApi, setCallVerifyAccessCodeApi] = useState(false);

  const isLoginDeviceTrusted = useSelector(
    (state) => state.isLoginDeviceTrusted
  );
  const accessTokenToApi = useSelector((state) => state.accessToken);
  const userLanguage = useSelector((state) => state.userLanguage);
  const userProfile = useSelector((state) => state.userProfile);
  const userCards = useSelector((state) => state.userCards);
  const userActivities = useSelector((state) => state.userActivities);
  // const deviceId = useSelector(state => state.deviceId);
  const sessionKey = useSelector((state) => state.loggedSessionKey);
  const deepLinkPath = useSelector((state) => state.deepLinkPath);

  const selectedCardDetails = useSelector((state) => state.selectedCardDetails);

  const [agreementType, setAgreementType] = useState([
    { name: "Kudzu Privacy Policy", url: "PPrivacyPolicy", accType: 64 },
    { name: "SMS Services", url: "SMSTerms", accType: 0 },
    { name: "Bancorp Privacy Notice", url: "PrivacyPolicyV2", accType: 0 },
    { name: "Kudzu User Agreement", url: "TnC", accType: 64 },
  ]);

  useEffect(() => {
    debugger;
    sessionStorage.setItem("tooltipState_Spending",0); 
    sessionStorage.setItem("tooltipState_Saving",0);
    const currentPath = location.pathname;
    if (currentPath.toLowerCase() == "/forgotpassword") {
      logFirebaseEvents("Login", "Forgot_Password_Clicked", "Forgot_Password_Clicked", "");
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const emailParam = url.searchParams.get("email");
      setForgotEmailAddress(emailParam);
      setTimeout(() => {
        setIsVerifyEmailModalOpen(true);
      }, 1000);
    } else if (currentPath.toLowerCase() == "/signup") {
      logFirebaseEvents("Social_Login", "Signup_Clicked ", "Signup_Clicked ", "","");
      const currentURL = new URL(window.location.href);
      const accessParam = currentURL.searchParams.get("id");
      ;
      dispatch({
        type: "UPDATE_SIGNUP_REFERRAL_CODE",
        payload: { signUpReferralCode: accessParam },
      });

      getIsAccessCodeFeatureEnabled();
        // setIsMobileVerificationModalOpen(true);
    } else if (currentPath == "/" && accessToken == null) {
      login("/");
    } else if (currentPath.toLowerCase() == "/logout" && accessToken == null) {
      login("/");
    }
  }, [location, accessTokenToApi]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (
      accessToken !== null &&
      accessToken !== undefined &&
      accessTokenToApi != null &&
      accessTokenToApi != "" &&
      isAuthenticated &&
      currentPath.toLowerCase() !== "/forgotpassword" &&
      currentPath.toLowerCase() !== "/signup" &&
      currentPath.toLowerCase() !== "/logout"
    ) {
      registerBrowser(accessTokenToApi);
    }
  }, [accessTokenToApi]);

  const { login, logout, isAuthenticated, renewTokens, oidcUser } = useOidc();
  const { accessToken } = useOidcAccessToken();
  const isSessionExpired =
    !oidcUser || !oidcUser.access_token || oidcUser.expired;

  const OpenMobileVerificationModal = () => {
    setIsAccessCodeModalOpen(false);
    setIsMobileVerificationModalOpen(true);
  };

  const closeInitialSignupscreen = () => {
    setIsMobileVerificationModalOpen(false);
    login("/");
  };

  const closeMobileVerificationModal = () => {
    setIsMobileVerificationModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (accessToken && accessToken !== null && accessToken !== "") {
        const accessTokenPayload = { accessToken: accessToken };
        dispatch({ type: "ACCESS_TOKEN", payload: accessTokenPayload });
      }
    };
    fetchData();
  }, [accessToken, dispatch]);

  async function updateDeviceId() {
    var deviceId = await retrieveData("updatedDeviceId");
    if (deviceId == null || deviceId == "") {
      deviceId = uuidv4();
      await storeData("updatedDeviceId", deviceId);
    }
  }

  const getIsAccessCodeFeatureEnabled = async () => {
    try {
      const deviceId = await retrieveData("updatedDeviceId");
      const headers = await GetApiHeadersWithOutBearer(deviceId);
      setIsLoading(true);
      const reqObj = {
        appID: appConfigData.CLIENT_ID,
        type: "signupaccesscode",
      };
      var response = await PostRequest(SIGN_UP_FEATURE, reqObj, headers);
      var responseObj = JSON.parse(response);
      setIsLoading(false);
      if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        if (responseObj.Response.enabled) {
          setIsAccessCodeModalOpen(true);
        } else {
          setIsAccessCodeModalOpen(false);
          setIsMobileVerificationModalOpen(true);
        }
      } else {
        setTimeout(() => {
          var arrMessages = responseObj.Messages;
          var message = arrMessages[0];
          var displayText = message.DisplayText;
          // setError(displayText)
        }, 500);
      }
    } catch (error) {
      setIsMobileVerificationModalOpen(true);
    }
  };

  const getAgreementsBasedOnType = async (selectedAgreementType) => {
    const deviceId = await retrieveData("updatedDeviceId");
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    setIsLoading(true);

    let finalUrl =
      PROGRAM_CONTENT +
      "?accountType=64&language=en&contentType=" +
      selectedAgreementType.url +
      "&programKey=200";
    var response = await GetRequest(finalUrl, headers);
    var responseObj = JSON.parse(response);
    setIsLoading(false);
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      setPolicyURL(responseObj.Response.Data);
    } else {
      setTimeout(() => {
        var arrMessages = responseObj.Messages;
        var message = arrMessages[0];
        var displayText = message.DisplayText;
        // setError(displayText)
      }, 500);
    }
  };

  const openLoginModalOpen = () => {
    login("/");
  };
  const registerBrowser = async (accessToken) => {
    await updateDeviceId();
    const deviceId = await retrieveData("updatedDeviceId");
    setIsLoading(true);
    let headers = await GetApiHeadersWithBearer(deviceId, accessToken);

    var model = "";
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes("macintosh")) {
      model = "MacBook";
    } else if (userAgent.includes("windows")) {
      model = "Windows PC";
    } else if (userAgent.includes("android")) {
      model = "Android";
    } else if (userAgent.includes("ios")) {
      model = "ios";
    }

    let request = {
      DeviceID: deviceId,
      DeviceToken: deviceId,
      DeviceOS: "web",
      Model: model,
    };

    var response = await PostRequest(POST_REGISTER_FCM_TOKEN, request, headers);
    let responseObj = JSON.parse(response);

    if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
      if (responseObj.Response.IsVerified) {
        callPostLoginAPI(true);
      } else {
        setIsLoading(false);
        setTimeout(() => {
          setshow2faSelection(true);
        }, 1000);
        // callGenerateOtp();
        closeResetSuccessModal();
      }
    } else {
      setIsLoading(false);
      var arrMessages = responseObj.Messages;
      if (arrMessages.length > 0) {
        var messageObject = arrMessages[0];
        const message = messageObject.DisplayText;
      }
    }
  };

  async function callGenerateOtp(otpGeneratiionType) {
    if (loginTyagainCount > 4) {
      setIsLoginModalOpen(false);
      setTimeout(() => {
        setLoginOtpErrorMessage(t("exceeded_max_limit"));
        setIsLoginModalOpen(true);
      }, 200);

      return;
    }
    setIsLoading(true);
    const deviceId = await retrieveData("updatedDeviceId");
    var headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    var cardKey = 0;
    var finalUrl = GENERATE_OTP + "?CardKey=" + cardKey;
    var object = {
      SessionKey: sessionKey,
      ActionType: actionType,
      DeviceID: deviceId,
    };
    if (otpGeneratiionType !== undefined && otpGeneratiionType !== null) {
      object.IsEmail = otpGeneratiionType == "Email" ? true : false;
    }

    const response = await PostRequest(finalUrl, object, headers);
    let responseObj = JSON.parse(response);
    if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
      setLoginOtpErrorMessage("");

      setIsLoginModalOpen(true);
      setTimeout(() => {
        // setSessionKey(responseObj.Response.SessionKey)
        setIsLoginModalOpen(true);
        const loginSessionPayLoad = {
          loggedSessionKey: responseObj.Response.SessionKey,
        };
        dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
        const tryAgainAccount = loginTyagainCount + 1;
        setLoginTryAgainCount(tryAgainAccount);
        setShowPassCodeExpire(true);
        setShowValidationMessage(false);
        setIsLoading(false);
        showTryAgain();
      }, 1000);
    } else {
      setIsLoading(false);
      var arrMessages = responseObj.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;

      setLoginOtpErrorMessage(displayText);
      setIsLoginModalOpen(true);
    }
  }

  async function verifyOtp(isTrusted, otp) {
    var object = undefined;
    setIsLoading(true);
    let otpValue = otp;
    const deviceId = await retrieveData("updatedDeviceId");
    object = {
      ActionType: actionType,
      SessionKey: sessionKey,
      OTP: otp,
      DeviceID: deviceId,
      IsTrustedDevice: isTrusted,
    };

    var headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    var cardKey = 0;
    var finalUrl = TWO_AUTH_VERIFY_OTP + "?CardKey=" + cardKey;
    PostRequest(finalUrl, object, headers).then((response) => {
      var responseObj = JSON.parse(response);

      if (responseObj.ResponseCode !== STATUS_SUCCESS_CODE) {
        var arrMessages = responseObj.Messages;
        const message = arrMessages[0];
        const displayText = message.DisplayText;
        setIsLoading(false);
        setLoginOtpErrorMessage(displayText);
        setIsLoginModalOpen(true);
      } else {
        const twoFactAuthenticationDone = { twoFactAuthenticationDone: true };
        dispatch({ type: "TWOFACT_DONE", payload: twoFactAuthenticationDone });

        callPostLoginAPI(isTrusted);
      }
    });
  }
  function showTryAgain() {
    setTimeout(() => {
      setIsTryAgain(true);
    }, 30000);
  }

  async function callPostLoginAPI(isTrusted) {
    setIsLoading(true);
    var url = POST_LOGIN + "includeCards=true";
    try {
      // });
      const deviceId = await retrieveData("updatedDeviceId");
      let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
      const response = await GetRequest(url, headers);
      if (response) {
        var responseObj = JSON.parse(response);

        if (responseObj.ResponseCode !== STATUS_SUCCESS_CODE) {
          setIsLoading(false);
          var arrMessages = responseObj.Messages;
          var message = arrMessages[0];
          var displayText = message.DisplayText;
          setLoginOtpErrorMessage(displayText);
          setIsLoginModalOpen(true);
          // show error to user
        } else {
          global.isLoggedIn = true;
          if (responseObj.Response.Profile !== undefined) {
            var language = responseObj.Response.Profile.PreferredLanguage;
            const userLanguagePayload = { userLanguage: language };
            dispatch({
              type: "UPDATE_USER_LANGUAGE",
              payload: userLanguagePayload,
            });

            var userpreferredName = responseObj.Response.Profile.PreferredName;
            console.log("userpreferredName",userpreferredName)
            dispatch({ type: "UPDATE_PREFERRED_NAME", payload: { preferredName: userpreferredName } })

            const UserProfilePayload = { userProfile: JSON.stringify(responseObj.Response.Profile) };
            dispatch({ type: 'UPDATE_USER_PROFILE', payload: UserProfilePayload });
          }
          if (
            responseObj.Response.Cards !== undefined &&
            responseObj.Response.Cards.length > 0
          ) {
            const userCardsPayload = {
              userCards: JSON.stringify(responseObj.Response.Cards),
            };
            dispatch({ type: "UPDATE_USER_CARDS", payload: userCardsPayload });
            if (selectedCardDetails == null) {
              dispatch({
                type: "SELECTED_USER_CARD_DETAILS",
                payload: { selectedCardDetails: responseObj.Response.Cards[0] },
              });
            }
          }
          if (
            responseObj.Response.Activities !== undefined &&
            responseObj.Response.Activities.length > 0
          ) {
            const userActivitiesPayload = {
              userActivities: JSON.stringify(responseObj.Response.Activities),
            };
            dispatch({
              type: "UPDATE_USER_ACTIVITIES",
              payload: userActivitiesPayload,
            });
          }
          let cards = responseObj.Response.Cards;
          if (cards.find((item) => item.AccountTypeBit63 == SAVING_CARD_CODE)) {
            const savingsAvailablePayload = { savingsAccontAvailable: true };
            dispatch({
              type: "SAVINSGS_AVAILABLE",
              payload: savingsAvailablePayload,
            });
          } else {
            const savingsAvailablePayload = { savingsAccontAvailable: false };
            dispatch({
              type: "SAVINSGS_AVAILABLE",
              payload: savingsAvailablePayload,
            });
          }

          const loginPayload = { isLoggedIn: true };
          dispatch({ type: "LOG_IN", payload: loginPayload });

          if (isTrusted) {
            const trustedDevicePayload = { trustedDevice: true };
            dispatch({ type: "TRUSTED_DEVICE", payload: trustedDevicePayload });
          }
          setIsLoading(false);
          const sessionTimeoutDurationMinutes = 60;
          sessionStorage.setItem(
            "sessionTimeoutDuration",
            String(sessionTimeoutDurationMinutes)
          );
          window.history.replaceState(null, "", "");
          if (
            responseObj.Response.Activities != undefined &&
            responseObj.Response.Activities.length > 0
          ) {
            responseObj.Response.Activities.sort(function (a, b) {
              return parseInt(a.Order) - parseInt(b.Order);
            });
            switch (responseObj.Response.Activities[0].ActivityTypeCode) {
              case ACTIVITY_TYPE_E_SIGN:
                const isAcceptAgreement = { isAcceptAgreement: true };
                dispatch({
                  type: "IS_ACCEPT_AGREEMENT",
                  payload: isAcceptAgreement,
                });
                navigate("/AcceptTerms");
                return;
              default:
                break;
            }
          }
          const isAcceptAgreement = { isAcceptAgreement: false };
          dispatch({ type: "IS_ACCEPT_AGREEMENT", payload: isAcceptAgreement });
          // if (deepLinkPath === '') {
          // navigate('/home');
          checkAndNavigateToPath();

          // } else {
          //   navigate(deepLinkPath);
          // }
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error in API call:", error);
    }
  }

  async function checkAndNavigateToPath() {
    const path = await retrieveData("DEEPLINK_PATH");
    if (path != "" && path != null && path.toLowerCase() != "/home") {
      storeData("DEEPLINK_PATH", "");
      const savedDeeplinkPath = { deepLinkPath: "" };
      dispatch({ type: "DEEPLINK_PATH", payload: savedDeeplinkPath });
      if (
        path.toLowerCase().includes("viewcard") ||
        path.toLowerCase().includes("changepin")
      ) {
        navigate("/CardServices/CardActionScreen");
      } else {
        navigate(path);
      }
    } else {
      navigate("/home");
    }
  }
  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const closeTrustDeviceLoginModal = () => {
    setLoginTrustDeviceOpen(false);
    setIsLoginModalOpen(true);
  };

  const openOTPVerifyModal = () => {
    closeMobileVerificationModal();
    setIsOTPVerifyModalOpen(true);
  };

  const closeOTPVerifyModal = () => {
    setIsOTPVerifyModalOpen(false);
  };
  const closeAuthModal = () => {
    setAuthUserCodeModelOpen(false);
  };
  const closeExistingErrModal = () => {
    setExistingErrorModalOpen(false);
  };
  //auth user flow
  const handleAuthUserFlow = (code) => {
    setIsOTPVerifyModalOpen(false);
    setAuthUserCodeModelOpen(true);
  };
  const handleExistingErrorModal = () => {
    setIsOTPVerifyModalOpen(false);
    setExistingErrorModalOpen(true);
  };
  const clickedOnSignupCheckbox = (messgage) => {
    setSingupCheckBoxMessage(messgage);
  };
  const handleUserSuccess = () => {
    setAuthUserCodeModelOpen(false);
    const isMobileVerifiedPayload = { isMobileVerifed: true };
    dispatch({ type: "MOBILE_VERIFIED", payload: isMobileVerifiedPayload });
    navigate("/Onboard");
  };

  const handleTryAgainExistingErr = () => {
    setphScreenAgreeTerms(false);
    setExistingErrorModalOpen(false);
    setIsMobileVerificationModalOpen(true);
  };
  ////login flow
  const handleLoginVerifyDeviceClick = (code) => {
    logFirebaseEvents("Pre_Login", "Verify_Btn_Clicked", "Verify_Btn_Clicked", "");
    setIsLoginModalOpen(false);
    setLoginOTP(code);
    setLoginTrustDeviceOpen(true);
  };
  const loginTrustDeviceClicked = (isTrusted) => {
    const isDeviceTrustedPayload = { isLoginDeviceTrusted: isTrusted };
    if (isTrusted) {
      logFirebaseEvents("Trust_and_Non Trust", "Trust_Cliked", "Trust_Cliked", "","");
    } else {
      logFirebaseEvents(
        "Trust_and_Non Trust",
        "Don'tTrust_Cliked",
        "Don'tTrust_Cliked",
        ""
      );
    }
    dispatch({
      type: "UPDATE_LOGIN_DEVICE_TRUSTED",
      payload: isDeviceTrustedPayload,
    });

    setIsLoginModalOpen(false);
    setLoginTrustDeviceOpen(false);
    verifyOtp(isTrusted, loginOTP);
  };
  const handleLoginOtpTryAgain = (otp) => {
    logFirebaseEvents("Pre_Login", "Try_Again_Clicked_In_Verify", "Try_Again_Clicked_In_Verify", "");
    setLoginOTP(otp);
    setIsLoginModalOpen(false);
    setshow2faSelection(false);
    callGenerateOtp(undefined);
  };

  ////forgorpasswrd flow

  // Verify Email Modal

  const openVerifyEmailModal = () => {
    // closeLoginModal();
    setIsVerifyEmailModalOpen(true);
  };

  const closeForgotPasswordInitialScreen = () => {
    logFirebaseEvents("Reset_Password", "Close_Clicked", "Close_Clicked", "","");
    setIsVerifyEmailModalOpen(false);  
    login("/");
  };
  const closeVerifyEmailModal = () => {
    setIsVerifyEmailModalOpen(false);
  };

  const handleModalOnImReadyButtonClick = () => {
    logFirebaseEvents("Reset_Password", "Email_Submited", "Email_Submited", "","");
    closeVerifyEmailModal();
    openSecurityCheckModal();
  };
  const handleSignUpClickInEmailModal = () => {
    logFirebaseEvents("Reset_Password", "SignUp_Clicked", "SignUp_Clicked", "","");
    closeVerifyEmailModal();
    OpenMobileVerificationModal();
  };

  // Security check modal
  const [isSecurityCheckModalOpen, setIsSecurityCheckModalOpen] =
    useState(false);

  const openSecurityCheckModal = () => {
    setIsSecurityCheckModalOpen(true);
  };

  const closeSecurityCheckModal = () => {
    logFirebaseEvents("Reset_Password", "VerifyOTP_Close", "VerifyOTP_Close", "","");
    setIsSecurityCheckModalOpen(false);
  };

  const handleModalOnVerifyClick = () => {
    closeSecurityCheckModal();
    openResetYourPassModal();
  };

  const handleSignUpClickInSecurityCheckModal = () => {
    logFirebaseEvents("Reset_Password", "SignUp_Clicked", "SignUp_Clicked", "","");
    closeSecurityCheckModal();
    OpenMobileVerificationModal();
  };

  // Reset Password modal
  const [isResetYourPassModalOpen, setIsResetYourPassModalOpen] =
    useState(false);

  const openResetYourPassModal = () => {
    setIsResetYourPassModalOpen(true);
  };

  const closeResetYourPassModal = () => {
    setIsResetYourPassModalOpen(false);
  };

  const handleModalOnLoginClick = () => {
    closeResetYourPassModal();
    openResetSuccessModal();
  };

  // Reset Success modal
  const [isResetSuccessModalOpen, setIsResetSuccessModalOpen] = useState(false);

  const openResetSuccessModal = () => {
    setIsResetSuccessModalOpen(true);
  };

  const closeResetSuccessModal = () => {
    setIsResetSuccessModalOpen(false);
  };

  const openAccessCodeModal = () => {
    setIsAccessCodeModalOpen(true);
  };

  const closeAccessCodeModal = () => {
    // setAccessCode("")
    setIsAccessCodeModalOpen(false);
    login("/");
  };
  const openAccessCodeErrorModal = () => {
    setIsAccessCodeErrorModalOpen(true);
    setIsAccessCodeModalOpen(false);
  };

  const closeAccessCodeErrorModal = () => {
    // login("/")
    setIsAccessCodeErrorModalOpen(false);
    setIsAccessCodeModalOpen(true);
  };
  const openAccessCodeNoWorriesModal = () => {
    logFirebaseEvents("Access_code", "Don'Don't_Have_Access_Clicked", "Don'Don't_Have_Access_Clicked","", "");
    setIsAccessCodeNoWorriesModalOpen(true);
    setIsAccessCodeModalOpen(false);
  };

  const closeAccessCodeNoWorriesModal = () => {
    // login("/")
    setIsAccessCodeNoWorriesModalOpen(false);
    setIsAccessCodeModalOpen(true);
  };

  const handleNavigation = () => {
    const url = "https://share.hsforms.com/12cQSOZyLRMqVP5qxsAg3zgorzxu"
    window.location.replace(url);
  };

  const BannerImage = {
    height: "100vh",
    backgroundImage: `url(${Banner})`,
    backgroundSize: "cover", // This makes the image cover the entire container
    backgroundPosition: "center",
    maxWidth: "100%",
  };
  const BannerBackground = {
    background: "#262B3A",
    backgroundSize: "cover", // This makes the image cover the entire container
    backgroundPosition: "center",
    maxWidth: "100%",
  };

  const handleClick = (type) => {
    setShowPolicys((prevState) => {
      if (type === "privacy") {
        return "privacy";
      } else if (type === "agreement") {
        return "agreement";
      } else if (type === "deposit") {
        return "deposit";
      } else if (type === "savings") {
        return "savings";
      } else if (type === "electronic") {
        return "electronic";
      } else {
        return "terms";
      }
    });
  };

  const [accessCode, setAccessCode] = useState("");
  const [accessCodeError, setAccessCodeError] = useState("");
  const [isVerifiedCode, setIsVerifiedCode] = useState(false);

  const verifyAccessCode = async () => {
    try {
      const deviceId = await retrieveData("updatedDeviceId");
      const headers = await GetApiHeadersWithOutBearer(deviceId);
      setIsLoading(true);
      const reqObj = {
        appID: appConfigData.CLIENT_ID,
        code: accessCode.toUpperCase(),
      };
      var response = await PostRequest(VERIFY_ACCESS_CODE, reqObj, headers);
      var responseObj = JSON.parse(response);
      setIsLoading(false);
      if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        if (responseObj.Response.Success) {
          dispatch({
            type: "UPDATE_SIGNUP_ACCESS_CODE",
            payload: { signUpAccessCode: accessCode.toUpperCase() },
          });
          setIsVerifiedCode(true);
          OpenMobileVerificationModal();
        }
      } else {
        setTimeout(() => {
          var arrMessages = responseObj.Messages;
          var message = arrMessages[0];
          var displayText = message.DisplayText;
          setAccessCodeError(displayText);
          openAccessCodeErrorModal();
        }, 500);
      }
    } catch (error) {
      setAccessCodeError(error.message);
    }
  };

  return (
    <>
      <div className="animate__animated animate__fadeIn ">
        <Header />
        <div className="h-[100vh]" style={BannerImage} />
      </div>

      {/* Access code  modal */}
      {isAccessCodeModalOpen && <Modal
        isOpen={isAccessCodeModalOpen}//isLoginTrustDeviceOpen
        onClose={debounce(closeAccessCodeModal, 100)}
        isLogo={false}
        alignItems={'items-center'}
        isFooter={true}
        button1={
          <p
            className="focus:outline-none  md:w-[700px] sm:w-[450px] w-[100%] mx-auto h-full font-hanken-grotesk  text-white bg-[#30BC89] cursor-pointer flex justify-center sm:justify-between items-center p-4"

          >
            <p className={`${isVerifiedCode ? 'flex' : 'invisible'} flex-row justify-center items-center`} >
              <img src={Check} alt='Early access' className='sm:mr-4 mr-1 h-[20px] w-[20px]' /><span className="sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]">{t('early_access')}</span>
            </p>
            <button
              type={'submit'}
              onClick={() => {
                if (accessCode.length >= 1) verifyAccessCode()
              }
              }

              className={`${!isVerifiedCode ? 'flex' : 'hidden sm:flex'} flex-row justify-center items-center`} >
              <span className={`sm:font-bold font-[600] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] ${accessCode.length === 0 && 'opacity-50'} `}>{t('payPerksBtn')}</span><img src={NextArrow} alt='next' className={`${accessCode.length === 0 && 'opacity-50'} sm:ml-2 ml-1`} />
            </button>
          </p>

        }
        // title={<p className={`${HeadingStyles} sm:block hidden`}>{t('welcome')}</p>}
        title={<p className={`${HeadingStyles} sm:block hidden`}>{t('access_code')}</p>}
        subtitle={
          <div>
            {/* <span className={`${HeadingStyles} sm:hidden`}>{t('welcome')}</span> */}
            <span className={SubHeadingStyles}>{t('access_code_text')}</span>
          </div>}
        content={<div className="md:pt-5 lg:pb-3 md:pb-8 pt-4 pb-4">
          <AccessCode
            openVerificationModal={debounce(OpenMobileVerificationModal, 100)}
            openAccessCodeErrorModal={debounce(openAccessCodeErrorModal, 100)}
            openAccessCodeNoWorriesModal={debounce(openAccessCodeNoWorriesModal, 100)}
            onAccessCodeValid={(val) => setValidAccessCode(val)}
            onAccessCode={(val) => setAccessCode(val)}
            onAccessCodeError={accessCodeError}
            verifyAccessCode={verifyAccessCode}
          />
        </div>}
      />}

      {/* Access code error modal */}
      {isAccessCodeErrorModalOpen && (
        <Modal
          isOpen={isAccessCodeErrorModalOpen} //isLoginTrustDeviceOpen
          onClose={debounce(closeAccessCodeErrorModal, 100)}
          isLogo={false}
          alignItems={"items-center"}
          title={
            <p className={`${HeadingStyles} sm:block hidden`}>{t("oh_no")}</p>
          }
          subtitle={
            <div>
              <span className={`${HeadingStyles} sm:hidden`}>{t("oh_no")}</span>
              <span className={SubHeadingStyles}>{t("invalid_code")}</span>
            </div>
          }
          content={
            <div className="md:pt-5 lg:pb-3 md:pb-8 pt-4 pb-4">
              <div className="flex flex-col gap-20 justify-center ">
                <p className={FailSubHeaderMsgStyles}>
                  {t("please_contact")}
                  {
                    <button
                      onClick={() => {
                        window.open(CONTACT_US_URL, "_blank");
                      }}
                      className="font-hanken-grotesk lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] text-[#2965FB] font-semibold cursor-pointer"
                    >
                      {t("signup_customer_care")}
                    </button>
                  }
                  {t("for_access")}
                </p>
                <div className="flex flex-col justify-center items-center w-full">
                  <img
                    src={report}
                    alt="Early access"
                    className="sm:ml-4 ml-1 h-[123px] w-[123px]"
                  />
                </div>
                <button
                  type="button"
                  className="focus:outline-none  w-full font-hanken-grotesk font-[400] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                  onClick={() => {
                    handleNavigation();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleNavigation();
                      event.stopPropagation();
                    }
                  }}
                  autoFocus
                >
                  {t("join_the_waitlist")}
                </button>
              </div>
            </div>
          }
        />
      )}

      {/* Access Code No worries modal */}
      {isAccessCodeNoWorriesModalOpen && (
        <Modal
          isOpen={isAccessCodeNoWorriesModalOpen} //isLoginTrustDeviceOpen
          onClose={debounce(closeAccessCodeNoWorriesModal, 100)}
          isLogo={false}
          alignItems={"items-center"}
          title={
            <p className={`${HeadingStyles} sm:block hidden`}>
              {t("no_worries")}
            </p>
          }
          subtitle={
            <div>
              <span className={`${HeadingStyles} sm:hidden`}>
                {t("no_worries")}
              </span>
              <span className={SubHeadingStyles}>
                {t("kudzu_will_available")}
              </span>
            </div>
          }
          content={
            <div className="md:pt-5 lg:pb-3 md:pb-8 pt-4 pb-4">
              <div className="flex flex-col gap-20 justify-center ">
                <p className="font-hanken-grotesk font-semibold sm:font-[500] sm:text-[14px] md:text-[20px] text-[16px] md:leading-[30px] leading-[24px]">
                  {t("pl_join_our1")}
                  <span
                    className="text-[#2965FB] cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://www.kudzumoney.com/contact-us",
                        "_blank"
                      );
                    logFirebaseEvents("Join_the_waitlist ", "Contact_Customer_Clicked", "Contact_Customer_Clicked", "","");
                    }}
                  >
                    {t("support_email")}
                  </span>
                  {t("pl_join_our2")}
                </p>
                <div className="flex flex-col justify-center items-center w-full">
                  <img
                    src={Klogo}
                    alt="Early access"
                    className="sm:ml-4 ml-1 h-[105px] w-[96px]"
                  />
                </div>
                <button
                  type="button"
                  className="focus:outline-none  w-full font-hanken-grotesk font-[400] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] text-white bg-[#2965FB] cursor-pointer flex justify-center items-center p-4"
                  onClick={() => {
                    handleNavigation();
                    logFirebaseEvents("Join_the_waitlist ", "Join_Wait_List_Clicked", "Join_Wait_List_Clicked", "");
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleNavigation();
                      event.stopPropagation();
                    }
                  }}
                  autoFocus
                >
                  {t("join_the_waitlist")}
                </button>
              </div>
            </div>
          }
        />
      )}

      {/* Mobile Verfication  MODAL */}

      {isMobileVerificationModalOpen && (
        <Modal
          isOpen={isMobileVerificationModalOpen}
          onClose={closeInitialSignupscreen}
          defaultCornerRadius={false}
          isLogo={true}
          Fullheight={true}
          title={<p className={HeadingStyles}>{t("phoneverify_letstart")}</p>}
          subtitle={
            <>
              <p className={SubHeadingStyles}>
                {addLineBreaks(t("phoneverify_tittle"))}
              </p>
              <p className="sm:pt-5 pt-2 lg:text-[12px] md:text-[11px] sm:text-[10px] text-[12px] font-hanken-grotesk font-semibold sm:leading-[20px] leading-[18px]">
                {t("By_providing")}
                <span
                  tabIndex="0"
                  onClick={() => {
                    logFirebaseEvents("Phone_Number_Verification", "Kudzu_Privacy_Policy_Clicked", "Kudzu_Privacy_Policy_Clicked", "","");
                    getAgreementsBasedOnType(agreementType[0]);
                    setShowAgreementsModel(true);
                    setIsMobileVerificationModalOpen(false);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      getAgreementsBasedOnType(agreementType[0]);
                      setShowAgreementsModel(true);
                      setIsMobileVerificationModalOpen(false);
                    }
                  }}
                  className="text-[#2965FB] cursor-pointer"
                >
                  {t("kudzu_privacy_policy")}
                </span>
                {", "}
                <span
                  tabIndex="0"
                  onClick={() => {
                    logFirebaseEvents("Phone_Number_Verification", "Kudzu_Terms_Of_Services_Clicked", "Kudzu_Terms_Of_Services_Clicked", "","");
                    getAgreementsBasedOnType(agreementType[1]);
                    setShowAgreementsModel(true);
                    setIsMobileVerificationModalOpen(false);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      getAgreementsBasedOnType(agreementType[1]);
                      setShowAgreementsModel(true);
                      setIsMobileVerificationModalOpen(false);
                    }
                  }}
                  className="text-[#2965FB] cursor-pointer"
                >
                  {t("sms_terms_of_service")}
                </span>
                {", "}
                <span
                  tabIndex="0"
                  onClick={() => {
                    logFirebaseEvents("Phone_Number_Verification", "Kudzu_User_Agreement_Clicked", "Kudzu_User_Agreement_Clicked", "","");
                    getAgreementsBasedOnType(agreementType[3]);
                    setShowAgreementsModel(true);
                    setIsMobileVerificationModalOpen(false);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      getAgreementsBasedOnType(agreementType[3]);
                      setShowAgreementsModel(true);
                      setIsMobileVerificationModalOpen(false);
                    }
                  }}
                  className="text-[#2965FB] cursor-pointer pr-1"
                >
                  Kudzu User Agreement
                </span>
                {t("and")}
                <span
                  tabIndex="0"
                  onClick={() => {
                    logFirebaseEvents("Phone_Number_Verification", "Bancorp_Notice_Clicked", "Bancorp_Notice_Clicked", "","");
                    getAgreementsBasedOnType(agreementType[2]);
                    setShowAgreementsModel(true);
                    setIsMobileVerificationModalOpen(false);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      getAgreementsBasedOnType(agreementType[2]);
                      setShowAgreementsModel(true);
                      setIsMobileVerificationModalOpen(false);
                    }
                  }}
                  className="cursor-pointer text-[#2965FB] pl-1"
                >
                  {t("bancorp_Notice")}
                </span>
              </p>

              <p className="md:pt-5 pt-2 lg:text-[12px] md:text-[11px] sm:text-[10px] text-[11px] font-hanken-grotesk font-[400px] sm:font-medium md:leading-[20px] leading-[17px]">
                {t("banking_services")}
              </p>

              <p className="md:pt-5 pt-2 lg:text-[12px] md:text-[11px] sm:text-[10px] text-[11px] font-hanken-grotesk font-[400px] sm:font-medium  md:leading-[20px] leading-[17px]">
                {t("standard_message")}
                <span className="text-[#30BC89]"> {t("help")} </span>
                {t("help_number")}
                <span className="text-[#30BC89]"> {t("stop")} </span>
                {t("Message_frequency_varies")}
              </p>

              <div className="sm:pt-5 md:pt-6  pt-8 flex flex-row justify-start ">
                <input
                  tabIndex="0"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setphScreenAgreeTerms(!phScreenAgreeTerms);
                    }
                  }}
                  type="checkbox"
                  className="checkbox-round"
                  value=""
                  checked={phScreenAgreeTerms}
                  onChange={() => {
                    setphScreenAgreeTerms(!phScreenAgreeTerms);
                    setIsLoading(false);
                  }}
                />
                <label
                  htmlFor="agree"
                  className=" font-hanken-grotesk md:ml-6 ml-4 md:text-sm sm:text-[12px] text-[14px] font-[400] sm:font-medium text-gray-900 dark:text-gray-300 "
                >
                  {t("i_agree")}
                </label>
              </div>
              {SingupCheckBoxMessage && (
                <div className="mt-4">
                  {displaySignupErrorMessage(SingupCheckBoxMessage, true)}
                </div>
              )}
            </>
          }
          content={
            <div className="md:pt-5 lg:pb-3 md:pb-8 pt-4 pb-4">
              <MobileVerification
                updatemobileTermsChekbox={clickedOnSignupCheckbox}
                openPhoneOTPModal={debounce(openOTPVerifyModal, 100)}
                phScreenAgreeTerms={phScreenAgreeTerms}
              />
            </div>
          }
        />
      )}

      {/* RESET SUCCESS MODAL */}
      <Modal
        isIframe={true}
        isOpen={showAgreementsModel}
        onClose={() => {
          setShowAgreementsModel(false);
          setIsMobileVerificationModalOpen(true);
        }}
        title={false}
        content={
          <div
            className="w-full h-full   md:pt-0 sm:pt-6  pt-16"
            style={{ height: window.innerHeight }}
          >
            <iframe
              src={policyURL}
              id="policyIframe"
              title="Embedded Website"
              className="w-full"
              style={{ height: window.innerHeight }}
            />
          </div>
        }
      />

      {/* {open Phone OTP verify modal} */}

      {isOTPVerifyModalOpen && (
        <Modal
          isOpen={isOTPVerifyModalOpen}
          onClose={debounce(closeOTPVerifyModal, 100)}
          title={<p className={HeadingStyles}> {t("great_exclamation")}</p>}
          subtitle={
            <>
              <p className={`${SubHeadingStyles} lg:w-[321px] sm:w-[204px]`}>
                {t("we_are_sending_text")}
              </p>
              <p
                className="font-hanken-grotesk my-3 text-[#262B3A] lg:text-[20px] md:text-[17px] sm:text-[12px] text-[16px]
        lg:leading-[30px] sm:leading-[18px] leading-[22px] font-[500] w-full"
              >
                {t("standrd_message_rates_apply")}
              </p>
            </>
          }
          content={
            <div className="mt-10">
              <ValidatePhoneOTP
                enableAuthorizedUserflow={debounce(handleAuthUserFlow, 100)}
                // existingErrorModalDisplay={handleExistingErrorModal}
                existingErrorModalDisplay={debounce(
                  handleExistingErrorModal,
                  100
                )}
              />
            </div>
          }
        />
      )}

      {authUserCodeModelOpen && (
        <Modal
          isOpen={authUserCodeModelOpen}
          onClose={debounce(closeAuthModal, 100)}
          isLogo={true}
          title={<p className={HeadingStyles}> {t("one_more_thing")}</p>}
          subtitle={
            <>
              <p className={SubHeadingStyles}>{t("provide_invite_code")}</p>
            </>
          }
          content={
            <div className="mt-10">
              <ValidateAuthCode
                handleAuthUserLogin={debounce(handleUserSuccess, 100)}
              />
            </div>
          }
        />
      )}
      {existingErrorModalOpen && (
        <Modal
          isOpen={existingErrorModalOpen}
          onClose={debounce(closeExistingErrModal, 100)}
          isLogo={false}
          title={<p className={HeadingStyles}> {t("oops_exclamation")}</p>}
          subtitle={
            <>
              <p className={SubHeadingStyles}>{t("you_already_had_account")}</p>
            </>
          }
          content={
            <ExistingErrorModal
              onLoginClick={debounce(() => navigate("/"), 100)}
              onTryAgainClick={debounce(handleTryAgainExistingErr, 100)}
            />
          }
        />
      )}
      {/* Login verify device  MODAL */}

      <Modal
        isOpen={isLoginModalOpen}
        onClose={debounce(closeLoginModal, 100)}
        isLogo={false}
        title={
          <p className={`${HeadingStyles} hidden sm:flex`}>
            {t("great_exclamation")}
          </p>
        }
        subtitle={
          <p>
            <span className={`${HeadingStyles} visible sm:hidden`}>
              {t("great_exclamation")}
            </span>
            <span className={SubHeadingStyles}>
              {selectedOtpType === "Email"
                ? t("we_are_sending_email_message")
                : t("we_are_sending_you_text_with_verification_code")}
            </span>
          </p>
        }
        // subtitle={<p className={SubHeadingStyles}>{ selectedOtpType === 'Email' ? t('we_are_sending_email_message') : t('we_are_sending_you_text_with_verification_code')}</p>}
        content={
          <div class="aspect-454/253 mt-5">
            <>
              {/* <p className="font-hanken-grotesk lg:text-[20px] md:text-[18px] sm:text-[14px] 
            text-[16px] lg:leading-[30px] md:leading-[30px] sm:leading-[26px] leading-[24px]
             text-secondary-3 font-medium">{t('send_verify_code')}</p> */}
              {selectedOtpType !== "Email" && (
                <p
                  className="font-hanken-grotesk lg:text-[20px] md:text-[18px] sm:text-[14px]
             text-[16px] lg:leading-[30px] md:leading-[30px] sm:leading-[26px] leading-[24px]
              text-secondary-3 font-medium"
                >
                  {" "}
                  {t("standard_message")}{" "}
                </p>
              )}
              {/*  md:w-[392px] sm:w-[289px] w-full */}
            </>

            <VerifyOtp
              selectedOtpType={selectedOtpType}
              loginOtpErrorMessage={loginOtpErrorMessage}
              handleTryAgain={debounce(handleLoginOtpTryAgain, 100)}
              handleloginVerifyOtp={handleLoginVerifyDeviceClick}
              onCancelClick={debounce(closeLoginModal, 100)}
            />
            {/* <VerifyOtp selectedOtpType={selectedOtpType} loginOtpErrorMessage={loginOtpErrorMessage} handleTryAgain={debounce(handleLoginOtpTryAgain, 100)} handleloginVerifyOtp={debounce(handleLoginVerifyDeviceClick, 100)} onCancelClick={debounce(closeLoginModal, 100)} /> */}
          </div>
        }
      />

      {/* Login trust device  or not MODAL */}
      {isLoginTrustDeviceOpen && (
        <Modal
          isOpen={isLoginTrustDeviceOpen} //isLoginTrustDeviceOpen
          onClose={debounce(closeTrustDeviceLoginModal, 100)}
          isLogo={false}
          alignItems={"items-center"}
          title={<p className={HeadingStyles}>{t("success_heading")}</p>}
          subtitle={
            <p className={SubHeadingStyles}>{t("like_to_add_device")}</p>
          }
          content={
            <div class="aspect-454/253 mt-10">
              <div className="flex justify-center">
                <img
                  src={Trusted}
                  className="lg:w-[120px] md:w-[100px] sm:w-[92px] w-[100px] 
            lg:my-4 md:my-2 sm:my-4 my-6"
                ></img>
              </div>
              <TrustDevice
                loginTrustDeviceClicked={debounce(loginTrustDeviceClicked, 100)}
              />
            </div>
          }
        />
      )}

      {/* VERIFY EMAIL MODAL */}
      <Modal
        isOpen={isVerifyEmailModalOpen}
        onClose={debounce(closeForgotPasswordInitialScreen, 100)}
        title={
          <p className={`${HeadingStyles} text-secondary-3`}>
            {t("reset_password_heading1")}
          </p>
        }
        subtitle={
          <div className="">
            <p
              className="font-hanken-grotesk modal-title mt-3
            lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[26px] lg:leading-[30px]
             md:leading-[30px] leading-[24px]
             font-[500] text-secondary-3 w-full"
            >
              {t("verify_email_parg1")}
              <span
                className="md:w-[420px] sm:w-[257px] font-hanken-grotesk modal-title mt-3 
          lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[26px] lg:leading-[30px] md:leading-[30px] leading-[24px]
            font-[500] text-secondary-3 my-5"
              >
                {t("verify_email_parg2_part1")}
                <span
                  tabIndex={0}
                  className="font-hanken-grotesk font-normal lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[20px] 
              lg:leading-[30px] md:leading-[30px] leading-[24px] text-secondary-2 underline cursor-pointer"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSignUpClickInEmailModal();
                    }
                  }}
                  onClick={debounce(handleSignUpClickInEmailModal, 100)}
                >
                  {t("sign_up_lowercase")}
                </span>
                <span> {t("verify_email_parg2_part2")}</span>
              </span>
            </p>
          </div>
        }
        content={
          <div class="aspect-454/253 px-[19px] sm:px-0 mt-20">
            <VerifyEmail
              emailAdress={forgotEmailAddress}
              handleModalOnClick={debounce(
                handleModalOnImReadyButtonClick,
                100
              )}
            />
          </div>
        }
      />

      {/* SECURITY CHECK MODAL */}
      <Modal
        isOpen={isSecurityCheckModalOpen}
        onClose={debounce(closeSecurityCheckModal, 100)}
        title={
          <p
            className={`${HeadingStyles} text-secondary-3 md:mb-5 sm:mb-2 mb-4`}
          >
            {t("reset_password_heading1")}
          </p>
        }
        subtitle={
          <p
            className="font-hanken-grotesk modal-title mt-3
        lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[26px] lg:leading-[30px] md:leading-[30px] leading-[24px]
         font-[500] text-secondary-3 w-full md:w-[395px] sm:w-[313px]"
          >
            {t("security_check_subheading1")}{" "}
            <p
              className="font-hanken-grotesk modal-title mt-3
         lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[26px] lg:leading-[30px] md:leading-[30px] leading-[24px]
          font-[500] text-secondary-3 w-full md:w-[395px] sm:w-[313px]"
            >
              {" "}
              {t("standrd_message_rates_apply")}
            </p>
          </p>
        }
        content={
          <div class="aspect-454/253 mt-10">
            <SecurityCheck
              handleModalOnClick={debounce(handleModalOnVerifyClick, 100)}
              handleSignUpClickInSecurityCheckModal={debounce(
                handleSignUpClickInSecurityCheckModal,
                100
              )}
            />
          </div>
        }
      />

      {/* RESET YOUR PASSWORD MODAL */}
      <Modal
        Fullheight={true}
        isOpen={isResetYourPassModalOpen}
        onClose={debounce(closeResetYourPassModal, 100)}
        title={
          <p
            className={`${HeadingStyles} text-secondary-3 md:mb-5 sm:mb-2 mb-4`}
          >
            {t("reset_password_heading1")}
          </p>
        }
        subtitle={
          <p
            className="font-hanken-grotesk modal-title md:mt-3 sm:mt-0 mt-3
        lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] sm:leading-[26px] lg:leading-[30px] md:leading-[30px] leading-[24px] font-[500] text-secondary-3 
          sm:w-[450px]"
          >
            {t("reset_password_subheading1")}
          </p>
        }
        content={
          <div class="aspect-454/253 px-[19px] sm:px-0 mt-10">
            <ResetYourPassword
              handleModalOnClick={debounce(handleModalOnLoginClick, 100)}
            />
          </div>
        }
      />

      {/* RESET SUCCESS MODAL */}
      <Modal
        isOpen={isResetSuccessModalOpen}
        onClose={debounce(closeResetSuccessModal, 100)}
        title={
          <p className={HeadingStyles}>
            {t("success_pullstop")} <br />{" "}
            <span className=" text-secondary-3">{t("success_msg")}</span>
          </p>
        }
        content={
          <div class="aspect-454/253 mt-10">
            <ResetSuccess openLoginModal={openLoginModalOpen} />
          </div>
        }
      />

      {/*2fa type selction modal*/}
      <TwoFactTypeSelctionModal
        highlightedText={t("using_new_device_title")}
        headerText={t("you_ll_need_to_verify_this_action")}
        isOpen={show2faSelection}
        onClose={() => {
          setshow2faSelection(false);
        }}
        onClickNext={(object) => {
          setshow2faSelection(false);
          setSelectedOtpType(object.proc);
          callGenerateOtp(object.proc);
        }}
      />

      {isLoading && <Loader />}
    </>
  );
};

export default PreLogin;
