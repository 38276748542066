import React from 'react';
import completeProfile from '../../../../assets/images/savings_optin_success.png'
//savings_optin_successpng.png
import { useTranslation } from "react-i18next";
import logFirebaseEvents from '../../../LogFirebaseEvents';

const RegisterSuccessModal = (props) => {
    const { t } = useTranslation();
    return (
        <>
        <p className='sm:w-auto w-[200px]'>
            <span className='font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-secondary-2'>{t("success")}</span>
            <span className=' font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-secondary-1'>{t("ur_acc_complt")}</span>

        </p>

            {/* <p className='mt-5 text-[15px] sm:text-[18px] font-[500]'>Your spending and savings accounts are approved and ready to use.</p> */}
             <p className='mt-5 sm:w-auto w-[200px] lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] sm:font-[500] font-[400]'>{props.subtitle}</p>

            <div className='my-[1rem] relative flex justify-center items-center'>
                <img src={completeProfile} alt='completeProfile' className=' ' />
                {/* <div className='text-[68px] absolute font-[700] leading-[36px] ml-[-35px] mt-[-30px] top-1/2 left-1/2 transform-translate-[-50%,-50%]'>FPO</div> */}
            </div>

            <a autoFocus href="/"><button className="focus:outline-none   bg-[#2965FB] text-[16px] w-full 
text-[#fff] font-[600] h-[60px] border-2 border-[#2965FB]" onClick={()=>{logFirebaseEvents("Kurdzu_account_complete", "Home_clicked", "Home_clicked","", "");}}>
              {/* {t('home')}  */}
              {t('login')} 
            </button></a>
        </>
    )
}

export default RegisterSuccessModal;