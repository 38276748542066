import React, { useEffect, useState } from 'react'
import CustomTextInput from '../../../../../CustomComponents/CustomtextInput'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../../CustomComponents/Loader'
import DynamicCard from '../../../../../CustomComponents/DynamicCard'
import { width } from '../../../../../CustomComponents/DynamicCard/DynamicCardStyles'
import { withOidcSecure } from '@axa-fr/react-oidc'
import logFirebaseEvents from '../../../../../LogFirebaseEvents'

const PercentageCard = ({ cardHeader, cardFooter, onBack, onSave }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [percentError, setPercentError] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [onSaveVal, setOnSaveVal]= useState(false)

  const percent = useSelector(state => state.savingsHabitData.Percent)
  const hasConfigurations = useSelector(state => state.climbFeatures?.hasConfigurations);




  const handleBackClick = () => {
    logFirebaseEvents("Savings_Habit", "BackTo_Manage_Saving_Habit_Clicked", "BackTo_Manage_Saving_Habit_Clicked","", ""); 
    if (hasConfigurations) navigate("/Climb/ManageSavingsHabit")
    else navigate("/Climb/ClimbMenu")
    dispatch({ type: "RESET_SAVINGS_HABIT_DATA" })

  }
  const handleSaveClick = () => {
    logFirebaseEvents("Savings_Habit", "Save_Percentage_Card_Clicked", "Save_Percentage_Card_Clicked","", "");
    if (!percent) setPercentError('Percentage Required.');
    if (percent && !percentError){
      onSave(!onSaveVal);
      setOnSaveVal(prev=>!prev)
      // onSave(true);
    } 

  }

  const handleOnBlur=(e)=>{
    if (!percent) setPercentError('Percentage Required.');
    else if (percent <= 0 || percent > 100) setPercentError('Please enter percent between 0 to 100')
    else setPercentError('')

  }

  const percentValidation = () => {
    if (percent <= 0 || percent > 100) setPercentError('Please enter percent between 0 to 100')
    else setPercentError('')

  }

  useEffect(() => {
    if(percent) percentValidation();
  }, [percent])

  const children = () => {
    return (
      <>
        {cardHeader}
        <div className='sm:w-[auto] w-[257px] font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
         sm:text-[22px] text-[25px] leading-[32px] sm:leading-[30px] md:leading-[36px] 
              text-secondary-2 mt-6 sm:mx-0 mx-4 sm:flex sm:flex-col '>
          <span>{t("send_email_verify_code_great")} </span>
          <span className='text-secondary-3 md:w-[424px] sm:w-[358px] w-[264px]'>{t("add_savings_habit_percent_content1")}</span>
        </div>

        <div className='w-full flex justify-center'>
          <div className="sm:w-[80%] w-full">
            {/* BODY */}
            <div className='md:my-32 sm:my-16 mt-10 mb-[10rem] sm:mx-0 mx-4'>
              <div className='sm:w-[80%] w-full flex flex-col justify-center'>

                <CustomTextInput name='percent'
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      event.preventDefault();
                      event.target.style.opacity = 0.8;
                      setTimeout(() => {
                        event.target.style.opacity = 1;
                      }, 200);
                    }
                  }}
                  onChange={(event) => {
                    var value = event.target.value.replace('%', '')
                                    
                    dispatch({ type: 'SAVINGS_HABIT_DATA', payload: { 'key': "Percent", 'value': value } })
                  }}
                  value={percent ? `${percent}%` : ''}
                  error={percentError}
                  placeholder={"%"}
                  inputWidth={`lg:w-[61%] md:w-[74%] w-full`}
                  autoFocus={true}
                  maxLength="3"
                  onBlur={(event)=> handleOnBlur(event)}

                />
                <p className='lg:w-[290px] md:w-[256px] w-full font-hanken-grotesk text-[#262B3A] lg:text-[16px] md:text-[14px] 
                sm:text-[13px] text-[16px] md:leading-[24px] font-normal md:font-[500] sm:mt-4 mt-8'>{t("add_savings_habit_percent_content2")}</p>
              </div>
            </div>
          </div>

        </div>
        {/* FOOTER */}
        {cardFooter(handleBackClick, handleSaveClick, false, `${t("save_saving_habits")}`)}
        {/* ---------- */}
      </>
    )
  }
  return (
    <>
      <DynamicCard
        header={true}
        CardRadius={true}
        cardWidth={width.homeCard}
        HeaderRadius={true}
        footer={true}
        HeaderContent={false} isWhite={true}
        backgroundColor={'#FFFFFF'} >
        {children()}
      </DynamicCard>
      {isLoading && <Loader />}
    </>
  )
}

export default withOidcSecure(PercentageCard)

