// reducers.js
import dayjs from "dayjs";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
export const LOGIN_SESSION_KEY = "LOGINSESSION_KEY";
export const PROVIDER = "PROVIDER";
export const PROVIDER_IDENTIFIER = "PROVIDER_IDENTIFIER";

const initialState = {
  showTooltip:true,
  showTooltipForSavings:true,
  showSearchOnHome:false,
  SearchFiltervalue:'',
  deviceId: null,
  payperksDays: null,
  payperksURL: null,
  payperksError: null,
  isLoginDeviceTrusted: false,
  accessToken: null,
  userLanguage: null,
  userProfile: null,
  userCards: null,
  userActivities: null,
  isDeviceVerifiedCalled: null,
  sessionId: null,
  userId: null,
  loggedSessionKey: "0",
  isMobileVerifed: false,
  userEmail: null,
  isLoggedIn: false,
  deepLinkPath: "",
  signUpMobile: "",
  twoFactAuthenticationDone: false,
  userAlerts: null,
  spendAlertCount: null,
  spendsSelctedId: null,
  savingsHABSelectedId: null,
  documentAgreementType: null,
  appConfigData: null,
  preferredName: "",
  savingsAccontAvailable: null,
  savingsNewClicked: false,
  signUpReferralCode: null,
  signUpAccessCode: null,
  transferMoneyPayload: {
    FromAccount: null,
    ToAccount: null,
    Amount: null,
    Frequency: { displayText: "" },
    FrequencyType: null,
    TransferDate: null,
    startDate: null,
    EndDate: null,
    Memo: null,
  },
  spendAlertsPayload: {
    amount: null,
    frequencyType: null,
    startDate: null,
    email: false,
    sms: true,
    push: true,
  },
  notifications: null,
  notificationCountPerPage: 8,
  notificationUnreadCount: null,
  savingsHabitData: {
    SavingHabitType: "Percent-based (%)",
    Percent: null,
    Amount: null,
    Frequency: null,
    StartDate: null,
  },
  activeSavingsHabitCount: 0,
  climbFeatures: null,
  isEditBenificirey: null,
  benficaryKey: null,
  addedBenficaryDetails: {
    firstName: "",
    lastName: "",
    ssn: "",
    apt_etc: "",
    selectedDobToServer: "",
    birthDay: "",
    formattedAddress: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    countryCode: "",
    country: "",
    cardKey: "",
  },
  isAcceptAgreement: false,
  addedAuthorizedUserDetails: {
    firstName: "",
    lastName: "",
    ssn: "",
    apt_etc: "",
    selectedDobToServer: "",
    birthDay: "",
    formattedAddress: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    countryCode: "",
    country: "",
    cardKey: "",
    phoneNumber: "",
    emailAddress: "",
  },
  signupTermsAndConditionCheckbox: {
    privacy_user: false,
    deposit_savings: false,
    electronic_aggrement: false,
    savings_account_aggrement: false,
    penalty_perjury: false
  },
  selectedCardDetails: null,
  ssnScreenThrough: "",
  savingsTermsConditions: false,
  selectedSubIndex: "",
  signUpPersonelInfo: null,
  signUpTermsAndConditions: null,
  transferAccounts: null,
  cancelTransactionDetails: null,
  selectedCardTab: '',
  successfulTransferResponse: null,
  studentEmailAddress:"",
  studentUniversityCode:"",
  studentUniversityName:"",
  graduationDate:"",
  closeIconFromStore:false
};

// //  showSearchOnHome:false,
// SearchFiltervalue:'',

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_TOOLTIP":
      return {
        ...state,
        showTooltip: action.payload.showTooltip,
    };
    case "CLOSE_ICON_FROM_STORE":
      return {
        ...state,
        closeIconFromStore: action.payload.closeIconFromStore,
    };
    case "SHOW_TOOLTIP_FOR_SAVING":
      return {
        ...state,
        showTooltipForSavings: action.payload.showTooltipForSavings,
    };
    case "SHOW_SEARCH_ON_HOME":
      return {
        ...state,
        showSearchOnHome: action.payload.showSearchOnHome,
    };
    case "SEARCH_FILTER_VALUE":
      return {
        ...state,
        SearchFiltervalue: action.payload.SearchFiltervalue,
    };
    case "DEVICE_ID":
      return {
        ...state,
        deviceId: action.payload.deviceId,
      };
    case "PAYPERKS_DAYS":
      return {
        ...state,
        payperksDays: action.payload.payperksDays,
      };
    case "UPDATE_PREFERRED_NAME":
      return {
        ...state,
        preferredName: action.payload.preferredName,
      };
    case "PAYPERKS_DASHBOARD_URL":
      return {
        ...state,
        payperksURL: action.payload.payperksURL,
      };
    case "PAYPERKS_ERROR":
      return {
        ...state,
        payperksError: action.payload.payperksError,
      };
    case "ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };

    case LOGIN_SESSION_KEY:
      return {
        ...state,
        loggedSessionKey: action.payload.loggedSessionKey,
      };

    case "SAVINSGS_AVAILABLE":
      return {
        ...state,
        savingsAccontAvailable: action.payload.savingsAccontAvailable,
      };
    case "UPDATE_SIGNUP_REFERRAL_CODE":
      return {
        ...state,
        signUpReferralCode:action.payload.signUpReferralCode,
      }
    case "UPDATE_SIGNUP_ACCESS_CODE":
      return {
        ...state,
        signUpAccessCode:action.payload.signUpAccessCode,
      }
    case "SAVINSGS_DISABLED":
      return {
        ...state,
        savingsNewClicked: action.payload.savingsNewClicked,
      };

    case "DEVICE_VERIFIED_CALLED":
      return {
        ...initialState,
        isDeviceVerifiedCalled: action.payload.isDeviceVerifiedCalled,
      };
    case "EDIT_BENFICIRIES":
      return {
        ...state, // Keep the existing state properties
        isEditBenificirey: action.payload.isEditBenificirey,
      };
    case "BENFICIRIEY_KEY":
      return {
        ...state, // Keep the existing state properties
        benficaryKey: action.payload.benficaryKey,
      };
    case "UPDATE_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };
    case "UPDATE_LOGIN_DEVICE_TRUSTED":
      return {
        ...state,
        isLoginDeviceTrusted: action.payload.isLoginDeviceTrusted,
      };
    case "USER_ALERTS":
      return {
        ...state,
        userAlerts: action.payload.userAlerts,
      };
    case "TRANSFER_MONEY_PAYLOAD":
      return {
        ...state,
        transferMoneyPayload: {
          ...state.transferMoneyPayload,
          [action.payload.key]: action.payload.value,
        },
      };
    case "RESET_TRANSFER_PAYLOAD":
      return {
        ...state,
        transferMoneyPayload: initialState.transferMoneyPayload,
      };
    case "SAVINGS_HABIT_DATA":
      return {
        ...state,
        savingsHabitData: {
          ...state.savingsHabitData,
          [action.payload.key]: action.payload.value,
        },
      };
    case "SAVINGS_HABIT_ACTIVE_COUNT":
      return {
        ...state,
        activeSavingsHabitCount: action.payload.activeCount,
      };
    case "UPDATE_AGREEMENT_TYPE":
      return {
        ...state,
        documentAgreementType: action.payload.documentAgreementType,
      };
    case "RESET_AGREEMENT_TYPE":
      return {
        ...state,
        documentAgreementType: initialState.documentAgreementType,
      };

    case "UPDATE_CLIMB_FEATURE":
      return {
        ...state,
        climbFeatures: action.payload.climbFeatures,
      };
    case "UPDATE_CLIMB_FEATURE_ITEM":
      const code = action.payload.code;
      const featueObjIndex = state.climbFeatures.findIndex(
        (item) => item.code === code
      );
      const updateObj = {
        ...state.climbFeatures[featueObjIndex],
        [action.payload.key]: action.payload.value,
      };
      state.climbFeatures[featueObjIndex] = updateObj;
      return {
        ...state,
        // climbFeatures: [action.payload.climbFeatures],
      };
    case "RESET_SAVINGS_HABIT_DATA":
      return {
        ...state,
        savingsHabitData: initialState.savingsHabitData,
      };
    case "ADD_SPEND_ALERTS":
      return {
        ...state,
        spendAlertsPayload: {
          ...state.spendAlertsPayload,
          [action.payload.key]: action.payload.value,
        },
      };
    case "RESET_SAVINGS_HABIT_DATA":
      return {
        ...state,
        savingsHABSelectedId: initialState.savingsHABSelectedId,
      };

    case "RESET_ADD_SPEND_ALERTS":
      return {
        ...state,
        spendAlertsPayload: initialState.spendAlertsPayload,
      };
    case "UPDATE_USER_LANGUAGE":
      return {
        ...state,
        userLanguage: action.payload.userLanguage,
      };
    case "UPDATE_USER_PROFILE":
      return {
        ...state,
        userProfile: action.payload.userProfile,
      };
    case "UPDATE_USER_CARDS":
      return {
        ...state,
        userCards: action.payload.userCards,
      };
    case "UPDATE_USER_CARD_BALANCE":
      const balance = action.payload.balance;
      const cardType = action.payload.cardType;
      const updatedUserCard =
        cardType === "Checking"
          ? [
            { ...state.userCards[0], Balance: balance },
            { ...state.userCards[1] },
          ]
          : [
            { ...state.userCards[0] },
            { ...state.userCards[1], Balance: balance },
          ];
      return {
        ...state,
        userCards: updatedUserCard,
      };
    case "SELECTED_USER_CARD_DETAILS":
      return {
        ...state,
        selectedCardDetails: action.payload.selectedCardDetails,
      };
    case "SUCCESSFUL_TRANSFER_RESPONSE":
      return {
        ...state,
        successfulTransferResponse: action.payload.successfulTransferResponse,
      };


    case "ADD_SPEND_ID":
      return {
        ...state,
        spendsSelctedId: action.payload.spendsSelctedId,
      };
    case "ADD_SAVNIGS_HAB_ID":
      return {
        ...state,
        savingsHABSelectedId: action.payload.savingsHABSelectedId,
      };
    case "UPDATE_USER_ACTIVITIES":
      return {
        ...state,
        userActivities: action.payload.userActivities,
      };
    case "UPDATE_USER_EMAIL":
      return {
        ...state,
        userEmail: action.payload.userEmail,
      };
    case "UPDATE_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload.notifications,
      };
    case "UPDATE_NOTIFICATIONS_COUNT_PER_PAGE":
      return {
        ...state,
        notificationCountPerPage: action.payload.notificationCountPerPage,
      };
    case "UPDATE_NOTIFICATIONS_UNREAD_COUNT":
      return {
        ...state,
        notificationUnreadCount: action.payload.notificationUnreadCount,
      };
    case "SESSION_ID":
      return {
        ...state,
        sessionId: action.payload.sessionId,
      };
    case "USER_ID":
      return {
        ...state,
        userId: action.payload.userId,
      };
    case "MOBILE_VERIFIED":
      return {
        ...state,
        isMobileVerifed: action.payload.isMobileVerifed,
      };
    case "LOG_IN":
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
      };
    case "DEEPLINK_PATH":
      return {
        ...state,
        deepLinkPath: action.payload.deepLinkPath,
      };
    case "TWOFACT_DONE":
      return {
        ...state,
        twoFactAuthenticationDone: action.payload.twoFactAuthenticationDone,
      };
    case "ADDED_BENFICARYDETAILS":
      return {
        ...state,
        addedBenficaryDetails: action.payload.addedBenficaryDetails,
      };
    case "ADDED_AUTHORZIED_USER":
      return {
        ...state,
        addedAuthorizedUserDetails: action.payload.addedAuthorizedUserDetails,
      };
    case "SIGNUP_MOBILE":
      return {
        ...state,
        signUpMobile: action.payload.signUpMobile,
      };
    case "STORE_APPCONFIGDATA":
      return {
        ...state,
        appConfigData: action.payload.appConfigData,
      };
    case "LOGOUT":
      return {
        ...state,
        isLoginDeviceTrusted: false,
        signUpMobile: "",
        accessToken: null,
        userLanguage: null,
        userProfile: null,
        // userCards: null,
        userActivities: null,
        isDeviceVerifiedCalled: null,
        sessionId: null,
        userId: null,
        loggedSessionKey: "0",
        isMobileVerifed: false,
        userEmail: null,
        isLoggedIn: false,
        deepLinkPath: "",
        twoFactAuthenticationDone: false,
        isEditBenificirey: null,
        isAcceptAgreement: false,
        spendAlertCount: null,
        appConfigData: null,
        ssnScreenThrough: "",
        savingsTermsConditions: false,
        selectedSubIndex: "",
        signUpPersonelInfo: null,
        savingsAccontAvailable: null,
        signUpTermsAndConditions: null,
        transferAccounts: null,
        cancelTransactionDetails: null,
        successfulTransferResponse: null,
        studentEmailAddress:"",
        studentUniversityCode:"",
        studentUniversityName:"",
        graduationDate:""
      };
    case PROVIDER_IDENTIFIER:
      return {
        ...state,
        providerIdentifier: action.payload.providerIdentifier,
      };
    case PROVIDER:
      return {
        ...state,
        provider: action.payload.provider,
      };
    case "IS_ACCEPT_AGREEMENT":
      return {
        ...state,
        isAcceptAgreement: action.payload.isAcceptAgreement,
      };
    case "SPENDS_ALERT_COUNT":
      return {
        ...state,
        spendAlertCount: action.payload.spendAlertCount,
      };
    case "SSN_SCREEN_THROUGH":
      return {
        ...state,
        ssnScreenThrough: action.payload.ssnScreenThrough,
      };
    case "SAVINGS_TERMS_CONDITIONS":
      return {
        ...state,
        savingsTermsConditions: action.payload.savingsTermsConditions,
      };
    case "SELECTED_SUB_INDEX":
      return {
        ...state,
        selectedSubIndex: action.payload.selectedSubIndex,
      };
    case "SIGN_UP_PERSONEL_INFO":
      return {
        ...state,
        signUpPersonelInfo: action.payload.signUpPersonelInfo,
      };
    case "SIGN_UP_TERMS_AND_CONDITIONS":
      return {
        ...state,
        signUpTermsAndConditions: action.payload.signUpTermsAndConditions,
      };
    case "UPDATE_SIGN_UP_TERMS_CHECKBOX":
      return {
        ...state,
        signupTermsAndConditionCheckbox: {
          ...state.signupTermsAndConditionCheckbox,
          [action.payload.key]: action.payload.value
        }
      }
    case "RESET_SIGN_UP_TERMS_CHECKBOX":
      return {
        ...state,
        signupTermsAndConditionCheckbox: initialState.signupTermsAndConditionCheckbox,
      };

    case "TRANSFER_ACCOUNTS":
      return {
        ...state,
        transferAccounts: action.payload.transferAccounts,
      };
    case "SELECTED_CARD_TAB":
      return {
        ...state,
        selectedCardTab: action.payload.selectedCardTab,
      };
    case "CANCEL_TRANSFER_DETAILS":
      return {
        ...state,
        cancelTransactionDetails: action.payload.cancelTransactionDetails,
      };
    case "STUDENT_EMAIL_VALUE":
      return {
        ...state,
        studentEmailAddress: action.payload.studentEmailAddress,
      };
    case "STUDENT_UNIVERSITY_CODE":
      return {
        ...state,
        studentUniversityCode: action.payload.studentUniversityCode,
      };
    case "STUDENT_UNIVERSITY_NAME":
      return {
        ...state,
        studentUniversityName: action.payload.studentUniversityName,
      };
    case "GRADUATION_DATE":
      return {
        ...state,
        graduationDate: action.payload.graduationDate,
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage: storageSession, // Use session storage to persist the state
  whitelist: [
    "accessToken",
    "userProfile",
    "userCards",
    "isLoggedIn",
    "selectedCardDetails",
    "spendAlertsPayload",
    "climbFeatures",
    "deepLinkPath",
    "ssnScreenThrough",
    "savingsTermsConditions",
    "selectedSubIndex",
    "cancelTransactionDetails"
  ], // Specify the state keys you want to persist
};

export default persistReducer(persistConfig, rootReducer);
