import React from 'react'

const ConfirmationModuleSubItem = ({ data }) => {
  return (
    <>
      {data.value &&
        <div className='flex flex-col justify-center md:pt-16 pt-10'>
          <p className='font-hanken-grotesk text-[#262B3A] uppercase 
 lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px] 
 font-[600] tracking-[2px] md:pb-8 pb-4 
 opacity-[50%]'>{data.title}</p>
          <p className='font-hanken-grotesk lg:text-[16px] md:text-[15px] 
 sm:text-[13px] text-[13px] font-[500]'>{data.title === "Amount" ? `$ ${data.value}` : data.value}</p>
        </div>
      }

    </>


  )
}

export default ConfirmationModuleSubItem