import React from "react";
import { useTranslation } from "react-i18next";
const Notice = () => {
    const { t } = useTranslation();
    return (

        <div className="sm:px-[6%] px-[2.5rem] w-full mx-auto sm:max-w-[616px] text-center">
            <div className="mx-auto">
                <div className="max-w-[616px]">
                    <p className="md:pt-12 pt-14 md:text-xs sm:text-[10px] text-[12px] text-left 
                    text-ImportantInfoTextColor opacity-[50%]">
                        {t("notice_1")}
                    </p>
                    <p className="pt-6 md:text-xs sm:text-[10px] text-[12px] text-justify text-ImportantInfoTextColor opacity-[50%]">
                        {t("notice_parg_1")}
                    </p>
                    <p className="pt-6 sm:pb-0 pb-14 md:text-xs sm:text-[10px] text-[12px] text-justify text-ImportantInfoTextColor opacity-[50%]">
                        {t("notice_parg_2")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Notice;