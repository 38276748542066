import React, { useState } from 'react'
import DynamicCard from '../../../../../CustomComponents/DynamicHomeCard'
import { width } from "../../../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import Loader from '../../../../../CustomComponents/Loader/index.js'
import { useTranslation } from 'react-i18next'
import ConfirmationModuleSubItem from './ConfirmationModuleSubItem/index.js'
import Accept from "../../../../../../assets/images/Checkbox-blue.png"
import NotAccept from "../../../../../../assets/images/Checkbox-light.png"
import { dateComparison, retrieveData } from '../../../../../../utils/index.js'
import { GetApiHeadersWithBearer, PostRequest } from '../../../../../../network/NetworkUtils.js'
import { STATUS_SUCCESS_CODE, TRANSFER_FUNDS } from '../../../../../../network/URLConstants.js'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { displayErrorMessage } from '../../../../../../utils/index.js';
import moment from 'moment';
import logFirebaseEvents from '../../../../../LogFirebaseEvents/index.js';


const TransferMoneyConfirm = (props) => {
    const { datas, transferFundPayload, onSetStatus, onSetErrorMsg, onCancelSelect, onSetTransactionId } = props
    const { t } = useTranslation();
    const [isAccept, setIsAccept] = useState(false);
    const [accpetErrorMessage, setAccpetErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const cardDetails = useSelector(state => state.selectedCardDetails);




    const accessToken = useSelector(state => state.accessToken);
    const cards = useSelector(state => JSON.parse(state.userCards));

    const transferFund = async () => {
        try {
            setIsLoading(true);

            const deviceId = await retrieveData('updatedDeviceId')
            const cardKey = cards !== null ? cards[0].CardKey : 0;

            const headers = await GetApiHeadersWithBearer(deviceId, accessToken);
            const url = TRANSFER_FUNDS.replace('{CARD_KEY}', cardKey)
            const body = transferFundPayload;
            const response = await PostRequest(url, body, headers);
            const responseJson = await JSON.parse(response);
            if (responseJson.ResponseCode === STATUS_SUCCESS_CODE) {
                console.log(responseJson,"responseJson of transfermoney++++++++++++++")
                const ACHTransactionId = responseJson.Response.ACHTransactionId
                const TransactionId = responseJson.Response.TransactionId
                const setTransactionIdObj = ACHTransactionId ? { "achTransactionId": ACHTransactionId } : TransactionId ? { "internalTransactionId": TransactionId } : {}
                if (responseJson?.Response?.ScheduleTransactionId) { setTransactionIdObj.scheduleTransactionId = responseJson?.Response?.ScheduleTransactionId }

                onSetTransactionId(setTransactionIdObj);
                props?.setScheduledTransactionId(transferFundPayload, responseJson.Response)
                if (transferFundPayload.TransferDate == moment().format('YYYY-MM-DD')) {
                    const fromBalance = responseJson.Response.FromAccountBalance;
                    const toBalance = responseJson.Response.ToAccountBalance;
                    if (transferFundPayload.IsScheduledTransfer === false) updateUserCardBalance(fromBalance, toBalance)

                }
                debugger;
                modalLogic(transferFundPayload?.IsScheduledTransfer);
                setIsLoading(false);
                dispatch({ type: "RESET_TRANSFER_PAYLOAD" })
                dispatch({ type: 'SUCCESSFUL_TRANSFER_RESPONSE', payload: { successfulTransferResponse: responseJson } });

            } else if (responseJson.Messages) {
                // alert(datas[8].value.AccountType);
                setIsLoading(false);
                const isChecking = ["Checking", "Spending"].includes(datas[8].value.AccountType);
                const displayErrorText = responseJson.Messages[0].DisplayText;
                onSetErrorMsg(displayErrorText, isChecking == true ? 'Spending' : 'Savings');
                onSetStatus("error", isChecking == true ? 'Spending' : 'Savings');
                dispatch({ type: "RESET_TRANSFER_PAYLOAD" })
            }

        } catch (error) {
        }
    }

    const updateUserCardBalance = (fromBalance, toBalance) => {
        const isFromInternal = datas[8].value.ACHAccountInternalId === 0
        const isToInternal = datas[9].value.ACHAccountInternalId === 0

        //checking / saving
        if (isFromInternal) {
            const isChecking = ["Checking", "Spending"].includes(datas[8].value.AccountType);
            const updatedCardData = [...cards]; // Create a copy of the array
            if (isChecking) {
                updatedCardData[0].Balance = fromBalance; // Update the Balance property of the checking object
            }
            else {
                updatedCardData[1].Balance = fromBalance; // Update the Balance property of the saving object
            }
            dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedCardData) } });
        }
        //checking / saving
        if (isToInternal) {
            //  const isChecking = datas[9].value.AccountType === "Checking"
            const isChecking = ["Checking", "Spending"].includes(datas[9].value.AccountType);
            const updatedCardData = [...cards]; // Create a copy of the array
            if (isChecking) {
                updatedCardData[0].Balance = toBalance; // Update the Balance property of the checking object
            }
            else {
                updatedCardData[1].Balance = toBalance; // Update the Balance property of the saving object
            }
            dispatch({ type: 'UPDATE_USER_CARDS', payload: { userCards: JSON.stringify(updatedCardData) } });

        }

    }

    const handleConfirm = () => {
        logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Transfer_Money", cardDetails.AccountTypeName+"_"+"Confirm_Clicked", "Confirm_Clicked","", "");
        if (!isAccept) {
            setAccpetErrorMessage(t('please_select_authorization'));
            return;
        }
        transferFund();
    }

    const modalLogic = (isScheduleTransfer) => {
        debugger;
        const isChecking = ["Checking", "Spending"].includes(datas[8].value.AccountType);
        var transferDateStatus = dateComparison(datas[6].value)
        if (transferFundPayload.Frequency == 'Recurring' || transferFundPayload.Frequency == 'RC') {
            transferDateStatus = "Future"
        }
        // const isInternal = datas[8].value.ACHAccountInternalId === 0    
        if ( transferDateStatus === "Today" ){
             onSetStatus("success", isChecking == true ? 'Spending' : 'Savings')
             }
        else if ((transferDateStatus === "Future") || ((transferDateStatus === "Future" || transferDateStatus === "Today"))) {
             onSetStatus("scheduled", isChecking == true ? 'Spending' : 'Savings')
            }
    }





    const children = () => {
        return (

            <>
                <div class=" lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
 lg:text-[26px] md:text-[22px] 
 sm:text-[20px] text-[20px]
 leading-[36px] font-hanken-grotesk sm:mx-0 mx-4"> {t("transfer_money")}</div>
                <div className='border-t-[2.5px] border-[#A3CEBB] 
                        flex justify-center sm:mx-0 mx-4'></div>

                <p className='mt-4 font-serif-pro font-[400] lg:text-[26px] md:text-[24px]
                         sm:text-[22px] text-[25px]
                     leading-[32px] sm:leading-[24px] 
                      md:leading-[36px] text-secondary-2 sm:mx-0 mx-4'>{t("transfer_confirm_subheading1")}</p>
                <p className='pb-6 font-serif-pro font-[400] lg:text-[26px]
                         md:text-[24px] sm:text-[22px] text-[25px] leading-[32px] 
                   sm:leading-[24px] md:leading-[36px] 
                    text-secondary-3 sm:mx-0 mx-4'>{t("transfer_confirm_subheading2")}</p>


                <div className='flex justify-center sm:mx-0 mx-4'>
                    <div className='sm:w-[80%] w-full'>


                        {datas &&
                            datas.slice(0, 6).map((data) => {
                                return (
                                    <ConfirmationModuleSubItem data={data} />
                                )
                            })
                        }

                        <p className='font-hanken-grotesk font-[500]
                                         sm:font-[500] md:font-[700] text-[14px] leading-[20px] 
                                         sm:text-[12px] sm:leading-[18px] md:text-[16px] md:leading-[24px] 
                                         text-secondary-3 pb-4 pt-10'>{t("auth_confirmation")}</p>

                        <div onClick={() => {
                            setAccpetErrorMessage('')
                            setIsAccept(prev => !prev)
                            logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Transfer_Money", cardDetails.AccountTypeName+"_"+"Accept_Authority_Clicked", "Accept_Authority_Clicked","", "");
                        }
                        } className='flex py-6 w-fit'>
                            {isAccept ?
                                <button>
                                    <img src={Accept} alt="accept" />
                                </button>
                                :
                                <button>
                                    <img src={NotAccept} alt="not accept" />
                                </button>
                            } <p className='font-hanken-grotesk font-[500] sm:font-[700] 
                            md:font-[500] text-[14px] leading-[20px] sm:text-[12px]
                             sm:leading-[20px] md:text-[16px] md:leading-[24px] 
                             text-secondary-2'>{t("accept_auth")}</p>

                        </div>
                        {accpetErrorMessage &&
                            <div className='mb-2'>
                                {displayErrorMessage(accpetErrorMessage)}
                            </div>
                        }

                        <div className=' hidden sm:flex sm:flex-col pb-20 pt-5'>
                            <button class="font-hanken-grotesk text-[16px] font-[500] sm:font-bold 
                            leading-[24px] sm:text-[12px] sm:leading-[12px] md:text-[16px] 
                            md:leading-[16px] bg-[#2965FB] text-white cursor-pointer 
                            border-2 px-2 py-4 border-[#2965FB] w-full"
                                onClick={handleConfirm}>Confirm</button>

                            <button className='font-hanken-grotesk mt-3 text-[16px] font-[500] sm:font-bold 
                            leading-[24px] sm:text-[12px] sm:leading-[12px] md:text-[16px] 
                            md:leading-[16px]  text-[#2965FB] cursor-pointer 
                            px-2 py-4  w-full'
                                onClick={() => {onCancelSelect(false);   logFirebaseEvents(cardDetails.AccountTypeName+"_"+"Transfer_Money", cardDetails.AccountTypeName+"_"+"Cancel_Clicked", "Cancel_Clicked","", "");}}>{t("cancel")}</button>
                        </div>

                        <div className='flex sm:hidden justify-between md:pb-20 sm:pb-10 pb-20  pt-10 gap-11 sm:mx-0 mx-4'>
                            <button className='font-hanken-grotesk flex items-center font-[600] md:font-[700] 
                         leading-[26px] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                          md:leading-[13px] 
                          lg:leading-[18px] text-secondary-2 cursor-pointer ' onClick={() => onCancelSelect(false)}>{t("cancel")}</button>
                            <button className=' font-hanken-grotesk font-[600]
                                     md:font-[700] leading-[26px] 
                                     lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                                      md:leading-[13px]
                                      lg:leading-[18px] text-secondary-2 cursor-pointer 
                                      capitalize' onClick={handleConfirm}>Confirm</button>


                        </div>
                    </div>

                </div>

            </>
        )
    }

    return (
        <>

            <DynamicCard
                header={false}
                CardRadius={true}
                cardWidth={width.allpolicies}
                HeaderRadius={true}
                isWhite={true}
                HeaderContent={false}>
                {children()}
            </DynamicCard>

            {isLoading && <Loader />}
        </>
    )
}

export default withOidcSecure(TransferMoneyConfirm) 