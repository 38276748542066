import React, { useState, useEffect } from 'react'
import { withOidcSecure } from '@axa-fr/react-oidc'
import { width } from "../../CustomComponents/Card/CardStyles";
import { useTranslation } from 'react-i18next';
import HomeLayout from '../../Layout/HomeLayout';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
// import Deviceremoved from '../../../assets/images/device_removed.png';
import Deviceremoved from '../../../assets/images/illo-trusted-device-remove.png';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { useNavigate } from 'react-router';
import { SecuritySidebar, formatDateForTrustDevice } from '../../../utils';
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from '../../CustomComponents/Loader';
import { retrieveData, storeData } from '../../../helpers/utils/Utils';
import { GET_TRUSTED_DEVICES, UNTRUSTED_DEVICES, CLIENT_ID, CLIENT_SECRET, isDEV, REDIRECT_URL, STATUS_SUCCESS_CODE, USER_SCOPE, GENERATE_OTP, TWO_AUTH_VERIFY_OTP, GENERATE_OTP_CHANGE_PASSWORD } from '../../../network//URLConstants';
import { GetApiHeadersWithBearer, GetApiHeadersWithOutBearer, GetRequest, PostRequest, toUrlEncodedString } from '../../../network/NetworkUtils';
import { useKeyDown } from '../../../hooks/useKeyDown';
import Modal from '../../CustomComponents/Modal';
import Custom2FA from '../../CustomComponents/Custom2FA';
import { LOGIN_SESSION_KEY } from '../../../Redux/reducers';
import LineBreaks from '../../../utils';
import TwoFactTypeSelctionModal from "../../CustomComponents/TwoFactTypeSelctionModal";

import LeftBar from '../../CustomComponents/DynamicComponent/LeftBar';
import logFirebaseEvents from '../../LogFirebaseEvents';
const TrustedDevice = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px]  font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px]  font-normal cursor-pointer pointer-events-none text-black";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[15px] font-normal cursor-pointer pointer-events-none leading-[30px]";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [modelImage, setModelImage] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [turstDeviceList, setTrustedDeviceList] = useState([]);
    const [deviceChoosed, setDeviceChoosed] = useState();
    const [deviceId, setDeviceId] = useState("");
    const [selectedType, setSelectedType] = useState('');
    const accessTokenToApi = useSelector(state => state.accessToken);
    const [twoFactModelTitle, setTwoFactModelTitle] = useState('');
    const [twoFactModelSubTitle, setTwoFactModelSubTitle] = useState('');
    const [twoFactModelContent, setTwoFactModelContent] = useState('');
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [showStnadradRatesMessage, setShowStnadradRatesMessage] = useState(false);
    const [loading, setLoading] = useState(false)
    const [responseCode, setResponseCode] = useState('');
    const [infoMessage, setinfoMessage] = useState('');
    const dispatch = useDispatch();
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [deviceIdFromLocal, setDeviceIdFromLocal] = useState("");
    const [show2faSelection, setshow2faSelection] = useState(false);
    const [selectedOtpType, setSelectedOtpType] = useState('');


    const cards = useSelector(state => JSON.parse(state.userCards));
    // var deviceIdFromlocal = "";


    const [showModel, setShowModel] = useState(false);

    const showAlertModal = (isShowModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        setShowModel(isShowModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }
    const closeCustomAlertModal = () => { 
        setShowModel(false);
    }

   
    const onAcptBtnClick = () => {
        logFirebaseEvents("Trusted_Devices", "Remove_Clicked", "Remove_Clicked", "","");
        if (showModel) {
            setShowModel(false)
           
            //alert('accept clicked')
            removeDeviceFromList(deviceChoosed)
        }


    }
    const onCancelBtnClick = () => {
        logFirebaseEvents("Trusted_Devices", "Close_Clicked", "Close_Clicked", "","");
        setShowModel(false)

    }

    const removeDevice = (selectedDevice) => {

        setDeviceChoosed(selectedDevice);
        showAlertModal(true, t('are_you_sure'), t('this_device_removed'),
            'Remove the device will not deactivate or delete your account.', true,
            true, t('remove_device'), t('Cancel'),
            true, Deviceremoved);
    }

    useEffect(() => {

        fetchTrustedDevices();
    }, []);
    async function fetchTrustedDevices() {
        setIsLoading(true)
        const storedDeviceId = await retrieveData('updatedDeviceId');
        setDeviceIdFromLocal(storedDeviceId);
        const deviceId = await retrieveData('updatedDeviceId');
        let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let url = GET_TRUSTED_DEVICES;
        var response = await GetRequest(url, headers);

        var responseObj = JSON.parse(response);

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            const trustList = responseObj.Response.Devices;
            const sortedDevices = trustList.sort((a, b) => new Date(b.LastLoginTime) - new Date(a.LastLoginTime));
            setTrustedDeviceList(sortedDevices);
            setTimeout(() => {
            }, 2000);

            setIsLoading(false);

        } else {

            setIsLoading(false);

        }
    }

    async function removeDeviceFromList(items) {
        setShowModel(false);
        const deviceId = await retrieveData('updatedDeviceId');
        let headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        let url = UNTRUSTED_DEVICES.replace("{deviceId}", items.DeviceId);
        setIsLoading(true)
        var response = await PostRequest(url, {}, headers);
        // setShowAlert(false);

        var responseObj = JSON.parse(response);

        if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
            const newDevices = turstDeviceList.filter(
                (deviceChoosed) => deviceChoosed.DeviceId !== items.DeviceId
            );
            setTrustedDeviceList(newDevices);
            setIsLoading(false);
            setShowModel(false);
            //  setShowAlert(false);

        } else {
            setTimeout(() => {
                setIsLoading(false);
                setShowModel(false);
                //  setShowAlert(false);
            }, 500);
        }
    }

    // useKeyDown(() => {
    //     if (showModel) {


    //     }
    // }, ["Enter"]);

    const leftBarComp = () => {
        return (
            <div>
                <p className="text-white font-normal text-[20px] leading-[30px] md:text-[18px] md:leading-[24px] lg:text-[32px] lg:leading-[38px] font-serif-pro ">{t("account_secure")}</p>
                <hr className=" h-[2.5px] mx-auto my-4 bg-[#4B70B9] bg-opacity-50 border-0 rounded md:my-10" />

                <div className=' flex flex-col gap-10 cursor-pointer'>
                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#FEFAEE]' onClick={() => navigate("/Security/TrustedDevice")}>{t("trusted_devices")}</p>
                    <p className='font-hanken-grotesk font-[600] text-[14px] leading-[20px] md:font-[700] md:text-[11px] md:leading-[16px] lg:text-[16px] lg:leading-[16px] text-[#FEFAEE]' onClick={() => navigate("/Security/ChangePassword")}>{t("change_password")}</p>
                </div>
            </div>
        )
    }
    const handleChangePasswordClick = () => {

        if (twoFactAuthenticationDone) {
            navigate('/Security/ChangePassword');
        } else {
            // alert("change password need to do")
           // generate2FAOtp(t('change_password'))
           setshow2faSelection(true);
        }
    };
    const closeOTPVerifyModal = () => { setIsOTPVerifyModalOpen(false) }
    const generate2FAOtp = async (otpType,procType) => {
        if (cards == null || cards == undefined){
            return
        }
        const deviceId = await retrieveData('updatedDeviceId');
        const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
        var object = {};
        var url = "";
        const isEmail = procType === 'Email' ? true : false;
        if (otpType == t('change_password')) {
            object = {
                "IsEmail": isEmail
            }
            url = GENERATE_OTP_CHANGE_PASSWORD.replace('{CARD_KEY}', cards[0].CardKey);
        }


        setLoading(true);
        try {
            const response = await PostRequest(url, object, headers);
            

            const parsedResponse = JSON.parse(response);
            if (parsedResponse.ResponseCode === STATUS_SUCCESS_CODE) {
                //adding sessionid to redux
                const loginSessionPayLoad = { loggedSessionKey: parsedResponse.Response.sessionId }
                dispatch({ type: LOGIN_SESSION_KEY, payload: loginSessionPayLoad });
                //adding userid to redux
                const userIdPayload = { userId: parsedResponse.Response.userId }
                dispatch({ type: 'USER_ID', payload: userIdPayload });
                if (otpType == t('change_password')) {
                    setSelectedType(t('change_password'));
                    setTwoFactModelTitle(t('great_exclamation'));
                    setTwoFactModelSubTitle(procType == 'Email' ? t('we_are_sending_email_message') : t('we_are_sending_messsage'));
                    setShowStnadradRatesMessage(procType == 'Email' ? false : true);
                    setIsOTPVerifyModalOpen(true);
                }


            } else {
                const arrMessages = parsedResponse.Messages;
                const message = arrMessages[0];
                const displayText = message.DisplayText;
                setinfoMessage(displayText);
                setResponseCode(parsedResponse.ResponseCode);
                setTimeout(() => {
                    setinfoMessage('');
                    setResponseCode('');
                }, 5000);

            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    };
    const verificationSuccess = (type) => {
        if (type == t('change_password')) {
            navigate('/Security/ChangePassword');
        }

    }
    const verificationFaiure = (type, message) => {
        // alert(message)

    }

    return (
        <>
            <HomeLayout>
                <SecuritySidebar selectedIndex={2} changePasswordClicked={handleChangePasswordClick} />
                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%] 
      animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        CardRadius={true}
                        cardWidth={width.allpolicies}
                        HeaderRadius={true}
                        backgroundColor={'pink'}
                        isWhite={true}
                        HeaderContent={false}>

                        <div class="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                       lg:text-[26px] md:text-[22px] 
                       sm:text-[20px] text-[20px] leading-[36px] font-hanken-grotesk 
                        sm:mx-0 mx-4">{t("trusted_devices")}</div>

                        <div className='sm:w-[100%] border-t-[2.5px] border-[#A3CEBB] sm:mx-0 mx-4'></div>

                        {/* <div className='w-full justify-center items-center flex flex-col pb-40'>
                            <div className='w-[80%]'>
                                <p class="sm:block hidden font-hanken-grotesk text-[#262B3A] 
                uppercase  lg:text-[13px] md:text-[12px] sm:text-[9px] text-[12px] font-[600] 
                    tracking-[2px] opacity-[50%] mt-20">{t("device_name")}</p>
                                <div className='h-[146px] items-center sm:flex border-b-[3px] border-[#A3CEBB]
                                justify-between border-opacity-50'>
                                    <div>
                                        <p className='my-3 font-hanken-grotesk font-bold
                                         text-[16px] leading-4'>
                                            Sam’s MacBook Pro (This Device)</p>
                                        <p className='my-3 font-hanken-grotesk font-medium 
                                        text-[12px] leading-4'>Last logged in June 26, 2023 at 1:00 PM</p>
                                    </div>
                                    <div className='font-hanken-grotesk font-bold 
                                    text-[16px] leading-4 text-[#2965FB] cursor-pointer' 
                                    onClick={() => { removeDevice() }}>
                                        {t("remove_device")}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='sm:w-full justify-center items-center flex flex-col pb-40 sm:mx-0 mx-4'>

                            <div className='md:w-[80%] w-full' >
                                {turstDeviceList.length > 0 ? <>

                                    <p className="block font-hanken-grotesk text-[#262B3A] uppercase 
                                     lg:text-[13px] md:text-[12px] sm:text-[12px] text-[13px] font-[600]
                                      tracking-[2px] opacity-[50%] lg:mt-20 md:mt-16 sm:mt-12 mt-10">
                                        {t("device_name")}
                                    </p>

                                    {turstDeviceList.map((device, index) => (
                                        <div key={index} className='lg:h-[146px] md:h-[120px] sm:h-[100px] h-auto items-center
                                         sm:flex sm:border-b-[3px] border-[#A3CEBB] justify-between border-opacity-50'>
                                            <div className=' border-[#A3CEBB] sm:flex justify-between block w-full sm:py-0 py-3'>
                                                <div className='py-6 sm:border-b-[0px] border-b-[3px]'>
                                                    <p className='font-hanken-grotesk font-bold
                                                     lg:text-[16px] md:text-[15px] sm:text-[13px] text-[14px] leading-4'>
                                                        {device.Model || "Unknown Device"}{" "}
                                                        {device.DeviceId == deviceIdFromLocal ? "(This Device)" : ""}
                                                    </p>
                                                    <p className='my-3 font-hanken-grotesk font-medium 
                                                    md:text-[12px] sm:text-[11px] text-[11px] leading-4'>
                                                        {t('last_logged_in')}  {formatDateForTrustDevice(device.LastLoginTime).toLocaleString()}
                                                    </p>
                                                </div>
                                                <button
                                                    tabIndex={0}
                                                    onKeyDown={(event) => {
                                                        if (event.key === "Enter") {
                                                            event.preventDefault();
                                                            removeDevice(device);
                                                        }
                                                    }}
                                                    className='font-hanken-grotesk my-3  sm:mt-0 mt-[1.75rem]  font-bold lg:text-[16px] md:text-[15px] sm:text-[13px] text-[14px] leading-4 
                                                    text-[#2965FB] cursor-pointer'
                                                    onClick={() => {
                                                        removeDevice(device);
                                                    }}
                                                >
                                                    {t("remove_device")}
                                                </button>
                                            </div>
                                        </div>
                                    ))}

                                </> : <p className="sm:block hidden font-hanken-grotesk text-[#262B3A] 
                                uppercase g:text-[13px] md:text-[12px] sm:text-[11px] text-[13px] font-[600] tracking-[2px] opacity-[50%] mt-20">
                                    {t("no_trust_devices")}
                                </p>
                                }
                            </div>
                        </div>


                    </DynamicCard>
                </div>
            </HomeLayout>
            <CustomAlertModel isOpen={showModel} 
                isBorderNone={false}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
                    <span className={HeaderMsgStyles}> {headerMsg}</span></p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                onAcptBtnClick={onAcptBtnClick}
                
                onCancelBtnClick={onCancelBtnClick}
            />
            <Modal
                isOpen={isOTPVerifyModalOpen}
                onClose={closeOTPVerifyModal}
                isLogo={false}
                title={<p className={HighlightMsgStyles}> {LineBreaks(twoFactModelTitle)}</p>}
                subtitle={<><p className={HeaderMsgStyles}>{LineBreaks(twoFactModelSubTitle)}</p> {showStnadradRatesMessage && <p className="font-hanken-grotesk my-3 text-[#262B3A]  lg:text-[20px] md:text-[18px] sm:text-[15px] text-[18px] leading-[30px] font-[500]  w-full">{t('standrd_message_rates_apply')}</p>}</>}
                content={<div className="mt-10"><Custom2FA  selected2FAType={selectedOtpType} phoneNumber={""} email={""} address={{}} type={selectedType} onClose={closeOTPVerifyModal} verificationFailure={verificationFaiure} verificationSuccess={verificationSuccess} /></div>}
            />
            <TwoFactTypeSelctionModal isOpen={show2faSelection} onClose={() => { setshow2faSelection(false) }}
                onClickNext={(object) => {
                    setshow2faSelection(false);
                    setSelectedOtpType(object.proc)
                    generate2FAOtp(t('change_password'),object.proc)
                }} />
            {isLoading && <Loader />}

        </>

    )
}

// export default withOidcSecure(TrustedDevice)
export default TrustedDevice