import styled from "styled-components";
import tw from "twin.macro";

// Font-Family 
const primaryFont = "Source Serif Pro, serif";
const secondaryFont = "Hanken Grotesk, sans-serif";
// Font-Size

const fontSizes = {
  extralarge: "32px",
  large: "24px",
  medium: "18px",
  small: "16px",
  semiSmall: "14px",
};

export const height = {
  personal_info: '1024px',
  privacy_policy: '980px',
  default_card: '800px',
  terms_conditions: '846px',
  account_registration: '856px',
  terms_conditions: '846px',
  terms_notes: '980px',
  home_card: '817px',
  perks_profile: '402px',
  notification: '937px'

}
export const width = {
  allpolicies: '85%',
  homeCard: '85%',
  defaultWidth: '56.75%',
  notication_congig: "860px"
}

export const Space = styled.div
  `${tw`w-[112px]`}`;

export const Title = styled.div`
  ${tw`text-white md:text-[31px] md:leading-[38px] sm:text-[18px] sm:leading-[24px] text-[25px] leading-[31px]`}`;


export const Main = styled.div`
${tw`w-[100%]`}
`;
 

export const Container = styled.div
  `${tw`w-[86%]  md:w-[56.75%]`}`;

export const CardTitle = styled.div
  `${tw`text-blue-500 md:leading-[36px] md:leading-[24px] leading-[31px]  font-[400] md:text-[26px] sm:text-[18px] text-[25px] `}
font-family: ${(props) => (props.isprimary ? primaryFont : secondaryFont)};
`;

export const CardSection = styled.div`
  ${tw`w-full relative items-center flex flex-col mb-[0px]`}
  background-color: ${props => {
    if (props.isPerk) {
      return 'var(--card-bg-perks)';
    } else if (props.isProfile) {
      return 'var(--card-bg-profile)';
    } else if (props.isWhite) {
      return '#FFFFFF'
    }
    else {
      return 'var(--card-bg)';
    }
  }};
  border-radius: ${props =>
    props.CardRadius ? '0 4rem 0 4rem' : '4rem 0 4rem 0'}; 

    @media (max-width: 699px) {
      border-radius: ${props => props.mblCardRadius ? '0' : '0 4rem 0 0rem'};
    } 
`;



export const CardHeader = styled.div`
  ${tw`w-full h-[122px]`};
  background-color: ${props => props.backgroundColor ? props.backgroundColor :
    props.isActive ? 'var(--card-header-bg-active)' : 'var(--card-header-bg-in-active)'};
    border-radius: ${props =>
    props.HeaderRadius ? '0 4rem 0 0' : '4rem 0 0 0'};
    
`;


export const CardFooter = styled.div`
  ${tw` w-full md:h-[155px] h-[120px] h-[100px] sm:rounded-bl-[4rem]`}
  background-color: ${props => {
    if (props.backgroundColor) {
      return props.backgroundColor;
    }
    else {
      return 'none';
    }
  }};
`;

export const HeaderCaption = styled.div`

`;
