import React, { useEffect, useState, useRef } from 'react';
import Loader from '../../CustomComponents/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { CHECKING_CARD_CODE, retrieveData } from '../../../helpers/utils/Utils';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { STATUS_SUCCESS_CODE, TRANSACTIONS, CHECKING, GET_DDA_DETAILS, LOCK_CARD_API, UNLOCK_CARD_API, GENERATE_UNLOCK_CARD_OTP, GENERATE_OTP_VIEW_CARD, CANCEL_UPCOMING_TRANSACTION, CONTACT_US_URL } from '../../../network/URLConstants';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import DynamicCard from '../../CustomComponents/DynamicCard';
import { width } from '../../CustomComponents/DynamicCard/DynamicCardStyles';
import TransactionItemWidget from '../../CustomComponents/TransactionItemWidget';
import { Tabs, Tab } from '../../CustomComponents/TabsComponent';
import { CheckingAndSavingsHeader, AccountDetailsHeder, copyToClipboard, formatDateToMMDDYYYY, SPENDING, addLineBreaks } from '../../../utils';
import { useTranslation } from 'react-i18next';
import FilterSlider from '../../CustomComponents/Slider';
import { useNavigate, useLocation } from 'react-router-dom';
import { set } from 'date-fns';
import { STATUS_CODE, SUBSTATUS_CODE } from '../../CardServices/CardMenu';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { withOidcSecure, useOidcUser, useOidcAccessToken, useOidc } from '@axa-fr/react-oidc'
import { LOGIN_SESSION_KEY } from '../../../Redux/reducers';
import Modal from '../../CustomComponents/Modal';
import Custom2FA from '../../CustomComponents/Custom2FA';
import lockCardGreenTick from '../../../assets/images/lock_green_tick.png'
import lockCardGreen from '../../../assets/images/lock_green.png'
import unLockCardGreen from '../../../assets/images/unlock_green.png'
import unLockCardGreenTick from '../../../assets/images/unlock_green_tick.png'
import warningImg from '../../../assets/images/warning.png'
import FooterContent from '../../CustomComponents/Footer';
import ClimbImg from '../../../assets/images/illo-climb.png';
import TwoFactTypeSelctionModal from '../../CustomComponents/TwoFactTypeSelctionModal';
import moment from 'moment';
import TermsArrow from '../../../assets/images/terms_arrow.png';
import left_arrow_green from '../../../assets/images/left_arrow_green.png';
import report_a_problem_flag from '../../../assets/images/report_a_problem_flag.png';
import { string } from 'yup';




const TransactionCard = (props) => {
  const { hideLayout, Radius } = props

  const currentPath = window.location.pathname;
  const location = useLocation();
  const pageSize = location.pathname === '/home' ? 5 : 10;

  const dispatch = useDispatch();
  const isMountedRef = useRef(false);
  const navigate = useNavigate();
  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setMonth(currentDate.getMonth() - 6); // Minimum date is 6 months ago
  const maxDate = new Date(); // Maximum date is today
  const { t } = useTranslation(); // Hook to access translation functions
  const userProfile = useSelector(state => JSON.parse(state.userProfile));
  const CardButton = 'font-hanken-grotesk lg:text-[16px] md:text-[14px] sm:text-[11px] md:leading-[39.71px] sm:leading-[25.8px] w-[24%] md:h-[50px] sm:h-[35px] inline-block rounded-full border-[3px] border-[#30BC89] text-kudzu-text text-xs font-semibold leading-normal text-success transition duration-150 ease-in-out';
  const accessTokenToApi = useSelector(state => state.accessToken);
  const cards = useSelector(state => JSON.parse(state.userCards));
  const transactionDetails = useSelector(state => JSON.parse(state.cancelTransactionDetails));
  const selectedItem = cards?.[0]
  const [cardData, setCardData] = useState([]);
  const defaultFilterData = { minAmnt: '0', maxAmnt: '500', debit: true, credit: true, minDate: '', maxDate: '' };
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [totalPages, setTotalPages] = useState('');
  const [CardNumber, setCardNumber] = useState('');
  const [CardBalance, setCardBalance] = useState('');
  const [showItems, setShowItems] = useState(false);
  const [CardText, setCardText] = useState('');
  const [checkingAccountNumber, setCheckingAccountNumber] = useState('*********'); //Account Number
  const [checkingRoutingNumber, setCheckingRoutingNumber] = useState('*********');
  const [accountCopied, setAccountCopied] = useState(false);
  const [routingCopied, setRoutingCopied] = useState(false);
  //const [pageSize, setPageSize] = useState(5);
  const [isViewAllClicked, setIsViewAllClicked] = useState(false);
  const [showFilterOverlay, setShowFilterOverlay] = useState(false);
  const [userName, setUserName] = useState('');
  const [cardOptionsList, setCardOptionsList] = useState([])
  const [showModel, setShowModel] = useState(false);
  const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const styleAll = `font-hanken-grotesk  lg:text-[18px] md:text-[15px] sm:text-[14px] text-[16px] leading-[18px] font-[500] break-words text-black`
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [modelImage, setModelImage] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [btn1Focus, setBtn1Focus] = useState(false);
  const [btn2Focus, setBtn2Focus] = useState(false);
  const [quickActions, setQuickActions] = useState(true)
  const SelectedCardDetails = useSelector(state => state.selectedCardDetails);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [showErrorImg, setshowErrorImg] = useState(false);
  const [cardDetails, setCardDetails] = useState({})
  const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
  const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
  const [selectedOption, setSelectedOption] = useState('')
  const [show2faSelection, setshow2faSelection] = useState(false);
  const [otpGenerationType, setOtpGenerationType] = useState(false);
  const isMobile = window.innerWidth < 768





  // Effect for fetching data userProfile
  useEffect(() => {
  }, []);
  useEffect(() => {
    if (SelectedCardDetails != null) { }
  }, [SelectedCardDetails, cardDetails])

  const footerStyles = "underline font-hanken-grotesk cursor-pointer text-center pt-8 text-[#262B3A] font-bold lg:text-[18px] md:text-[17px] sm:text-[14px] text-[14px] leading-[18px] absolute md:bottom-24 bottom-[3rem] left-1/2 -translate-x-1/2 cursor-pointer";
  const footerStylesNoRecords = "font-hanken-grotesk cursor-pointer text-center pt-8 text-[#30BC89] font-bold  lg:text-[18px] md:text-[17px] sm:text-[14px] text-[14px] leading-[18px] absolute md:bottom-32 bottom-[3rem] left-1/2 -translate-x-1/2";



  const closeModel = () => {
    setShowModel(false);
  }


  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, footerMsg, btn1Focus, btn2Focus, isBorderNone) => {
    // setshowErrorImg(showErrorImg)
    // setisBorderNone(isBorderNone)
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setFooterMsg(footerMsg)
    setBtn1Focus(btn1Focus)
    setBtn2Focus(btn2Focus)
    setisBorderNone(isBorderNone)
  }
  const onCancelBtnClick = () => {
    if (btn2Text == t('cancel_series')) { closeModel() }
    else if (btn2Text == t('cancel_next_transfer')) { closeModel() }
    else if (btn2Text == t('cancel_transfer')) { closeModel() }
    else { closeModel() }
  }
  const onAcptBtnClick = () => {
    if (btn1Text == t('cancel_series')) { closeModel(); onCancelTransfer(t('cancel_series')) }
    else if (btn1Text == t('cancel_next_transfer')) { closeModel(); onCancelTransfer(t('cancel_next_transfer')) }
    else if (btn1Text == t('cancel_transfer')) { closeModel(); onCancelTransfer(t('cancel_transfer')) }
    else if (btn1Text == t('back_to_account')) {
      // dispatch({ type: 'SELECTED_CARD_TAB', payload: { selectedCardTab: t('UpcomingTab') } });
      dispatch({ type: 'SELECTED_CARD_TAB', payload: { selectedCardTab: t('ActivityTab') } });
      closeModel(); navigate(-1);
    }
    else { closeModel() }
  }

  const onCancelTransfer = async (type) => {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    let url
    const object = {}
    const cardKey = transactionDetails?.cardDetails?.CardKey
    if (type == t('cancel_series')) {
      url = CANCEL_UPCOMING_TRANSACTION.replace('{transactionId}', transactionDetails?.TransactionId) + `?cardKey=${cardKey}&all=true`
    } else if (type == t('cancel_next_transfer')) {
      url = CANCEL_UPCOMING_TRANSACTION.replace('{transactionId}', transactionDetails?.TransactionId) + `?cardKey=${cardKey}&all=false`
    } else if (type == t('cancel_transfer')) {
      url = CANCEL_UPCOMING_TRANSACTION.replace('{transactionId}', transactionDetails?.TransactionId) + `?cardKey=${cardKey}&all=false`
    }
    setIsLoading(true)
    const response = await PostRequest(url, object, headers)
    setTimeout(() => {
      setIsLoading(false)
    }, 500);
    const responseObj = JSON.parse(response)
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
      setTimeout(() => {
        if (type == t('cancel_series')) {
          showAlertModal(true, t("success"), isMobile ? addLineBreaks(t("this_scheduled_transfer_series_cancelled_mobile")) : addLineBreaks(t("this_scheduled_transfer_series_cancelled")), '', true, false, t('back_to_account'), '', true, require('../../../assets/images/illo_transaction_cancel.png'), '', true, false)
        } else if (type == t('cancel_next_transfer')) {
          showAlertModal(true, t("success"), isMobile ? addLineBreaks(t("this_upcoming_transfer_cancelled_mobile")) : addLineBreaks(t("this_upcoming_transfer_cancelled")), '', true, false, t('back_to_account'), '', true, require('../../../assets/images/illo_transaction_cancel.png'), '', true, false)
        } else {
          showAlertModal(true, t("success"), isMobile ? addLineBreaks(t("your_transfer_cancelled_mobile")) : addLineBreaks(t("your_transfer_cancelled")), '', true, false, t('back_to_account'), '', true, require('../../../assets/images/illo_transaction_cancel.png'), '', true, false)
        }
      }, 1000);
    } else {
      var arrMessages = responseObj.Messages;
      var message = arrMessages[0];
      var displayText = message.DisplayText;
      showAlertModal(true, t("oops"), '', displayText, true, false, t('back_to_account'), '', true, require('../../../assets/images/illo_transaction_cancel.png'), '', true, false)
      return
    }
  };
  const onCancelClick = (type) => {
    if (type == t('cancel_series')) {
      showAlertModal(true, t("are_you_sure"), isMobile ? t("please_confirm_cancel_series_mobile") : addLineBreaks(t("please_confirm_cancel_series")), '', true, true, t('cancel_series'), t('never_mind'), true, require('../../../assets/images/illo_transaction_cancel.png'), '', false, true)
    } else if (type == t('cancel_next_transfer')) {
      showAlertModal(true, t("are_you_sure"), isMobile ? t("please_confirm_cancel_next_transfer_mobile") : addLineBreaks(t("please_confirm_cancel_next_transfer")), '', true, true, t('cancel_next_transfer'), t('never_mind'), true, require('../../../assets/images/illo_transaction_cancel.png'), '', false, true)
    } else if (type == t('cancel_transfer')) {
      showAlertModal(true, t("are_you_sure"), isMobile ? t("please_confirm_cancel_this_transfer_mobile") : addLineBreaks(t("please_confirm_cancel_this_transfer")), '', true, true, t('cancel_transfer'), t('never_mind'), true, require('../../../assets/images/illo_transaction_cancel.png'), '', false, true)
    }
  }

  const handleReportAProblem=()=>{
    showAlertModal(true, isMobile ? t("need_to") : '', isMobile ? t("we_r_here_to_help") : addLineBreaks(t("need_to_here_to_help")), <p>{t("call")} <span className='font-[600px] text-[#2965FB]'>{t('1-855-751-0025')}</span> {t('to_report')} <span className='font-[600px] text-[#2965FB] cursor-pointer pointer-events-auto' onClick={()=>navigate('/Help/ContactUs')} >{t('customer_care')}</span> {t('team_to_help')}</p>, true, true, t('back_to_account'), t('cancel'), true, require('../../../assets/images/illo-report.png'), '', false, true, true)

  }



  ///home header 
  const getHeaderText = () => {
    return (
      <>
        {(addLineBreaks(t('here_are_details_for_scheduled_transfer')))}
      </>
    )
    // return (`${t('hello_user').replace("{USER}", userName)}\n${t('basic_account_sideHeading').replace("{TYPE}", 'spending')}`)
  };

  const maskedCardNumber = () => {
    return (
      <span className="inline-flex items-center text-[12px] font-[700]">
        <span className="w-5 rounded-full flex items-center justify-center relative top-[2px]">
          <span className="text-black font-bold">• •</span>
        </span>
        <span style={{ fontFamily: 'Space Mono', fontWeight: '700' }}>{JSON.stringify(transactionDetails?.cardDetails?.CardKey).slice(2)}</span>
      </span>
    )
  };

  return (
    <div>
      <>
        <HomeLayout>
          <>
            <HomeHeader heading={
              <>
                <div className='flex sm:hidden'>
                  <div onClick={() => {
                    dispatch({ type: 'SELECTED_CARD_TAB', payload: { selectedCardTab: t('UpcomingTab') } });
                    navigate(-1);
                  }} className='mb-5 text-[14px] cursor-pointer font-[600] font-hanken-grotesk flex items-center'>
                    <img src={left_arrow_green} alt='leftArrow' className='mr-1' />
                    <span className='text-[#559C7E]'>{t('back')}</span>
                  </div>
                </div>
                {getHeaderText()}
              </>

            } isHeaderCaption={true} />
          </>
          <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
          animate__animated animate__fadeInRight '>
            <DynamicCard
              header={false}
              footer={true}
              CardRadius={true}
              cardWidth={width.homeCard}
              HeaderRadius={true}
              backgroundColor={'#30BC89'}
              isWhite={true}
              HeaderContent={
                <div className='sm:block hidden'>
                  <div className='sm:flex block justify-between flex-nowrap items-center md:my-[47px] sm:my-[25px] my-[20px] md:mx-[4%] sm:ml-[6%] sm:mr-[8%] mr-[2.5rem] ml-[2.5rem] cursor-pointer'>
                    <div onClick={() => {
                      dispatch({ type: 'SELECTED_CARD_TAB', payload: { selectedCardTab: t('UpcomingTab') } });
                      navigate(-1);
                    }} className='lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px]  cursor-pointer text-[#FFFFFF] font-[500] md:font-[500] font-hanken-grotesk flex items-center'>
                      <img src={TermsArrow} alt='leftArrow' style={{ filter: 'brightness(0) invert(1)' }} className='mr-1' />
                      <span>{transactionDetails?.cardDetails?.AccountTypeName}</span>
                    </div>
                  </div>
                </div>
              } >

              <div className='md:pb-[9rem] pb-[5rem] mt-6 sm:px-0 px-4'>
                {/* Transactions */}
                <div className='mb-10'>
                  <p className='font-hanken-grotesk text-[#262B3A]
                     uppercase lg:text-[13px] md:text-[13px] sm:text-[13px] text-[10px]
                      font-[600] tracking-[2px] opacity-[50%]'>{t('transactions')}</p>
                </div>
                <div className='flex flex-row sm:items-center justify-between w-full'>
                  <div className='md:w-[10%] sm:w-[20%]'>
                    <img src={transactionDetails?.IconUri} alt="Icon" className='lg:h-[44px] md:w-[38px] md:h-[38px] sm:h-[30px] sm:w-[30px] h-[40px] w-[40px] lg:w-[44px]' />
                  </div>
                  <div className={`lg:w-1/4 md:w-[30%] sm:w-1/2 w-[57%] ${styleAll} `}>
                    <p>{transactionDetails?.Description}</p>
                    <p className='block sm:hidden font-hanken-grotesk text-[#262B3A] lg:text-[13px] md:text-[13px] sm:text-[13px] text-[14px] font-[400] mt-1 opacity-[50%]'>{moment(transactionDetails?.TransactionDate).format('MMM DD')}</p>
                  </div>
                  <div className={`hidden sm:block sm:w-1/4 text-center ${styleAll}`}>
                    <p>{moment(transactionDetails?.TransactionDate).format('MMM DD')}</p>
                  </div>
                  <div className={`sm:w-1/5 ${styleAll}`}>
                    <p className='flex justify-end'>{parseInt(transactionDetails?.Amount)}.{(Math.abs(transactionDetails?.Amount) % 1).toFixed(2).slice(2)}</p>
                  </div>
                </div>
                <div className='border-b-[2.5px] opacity-50 border-[#A3CEBB] mt-6'></div>

                <div class="flex justify-center flex-col items-center">

                  <div class="md:w-[75%] w-full sm:mt-14 mt-8">
                    <p className='mb-8 font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[13px] sm:text-[11px] text-[10px] font-[600] tracking-[2px] opacity-[50%]'>{t('from')}</p>
                    <p className={`${styleAll}`}>{transactionDetails?.From}</p>
                  </div>

                  <div class="sm:flex md:w-[75%] w-full sm:mt-14 mt-8">
                    <div className='sm:w-[70%]'>
                      <p className='mb-8 font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[13px] sm:text-[11px] text-[10px] font-[600] tracking-[2px] opacity-[50%]'>{t('merchant')}</p>
                      <p className={`mb-8 sm:mb-0 ${styleAll}`}>{transactionDetails?.Description}</p>
                    </div>
                    {/* <div className='sm:w-[70%]'>
                      <p className='mb-8 font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[13px] sm:text-[11px] text-[10px] font-[600] tracking-[2px] opacity-[50%]'>{t('to')}</p>
                      <p className={`mb-8 sm:mb-0 ${styleAll}`}>{transactionDetails?.To}</p>
                    </div> */}
                    <div className='sm:w-[30%]'>
                      <p className='mb-8 font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[13px] sm:text-[11px] text-[10px] font-[600] tracking-[2px] opacity-[50%]'>{t('amount')}</p>
                      <p className={`mb-8 sm:mb-0 ${styleAll}`}>{parseInt(transactionDetails?.Amount)}.{(Math.abs(transactionDetails?.Amount) % 1).toFixed(2).slice(2)}</p>
                    </div>
                  </div>
                  {transactionDetails?.FrequencyCode != "OT" ?
                    <>
                      <div class="sm:flex md:w-[75%] w-full sm:mt-14">
                        <div className='sm:w-[70%]'>
                          <p className='mb-8 font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[13px] sm:text-[11px] text-[10px] font-[600] tracking-[2px] opacity-[50%]'>{t('start_date')}</p>
                          <p className={`mb-8 sm:mb-0 ${styleAll}`}>{moment(transactionDetails?.StartDate).format('DD-MM-YYYY') || 'Not Specified'}</p>
                        </div>
                        <div className='sm:w-[30%]'>
                          <p className='mb-8 font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[13px] sm:text-[11px] text-[10px] font-[600] tracking-[2px] opacity-[50%]'>{t('end_date')}</p>
                          <p className={`mb-8 sm:mb-0 ${styleAll}`}>{transactionDetails?.EndDate == "Not Specified" ? transactionDetails?.EndDate : moment(transactionDetails?.EndDate).format('DD-MM-YYYY')}</p>
                        </div>
                      </div>

                      <div class="sm:flex md:w-[75%] w-full sm:mt-14">
                        <div className='sm:w-[70%]'>
                          <p className='mb-8 font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[13px] sm:text-[11px] text-[10px] font-[600] tracking-[2px] opacity-[50%]'>{t('remaining_payments')}</p>
                          <p className={`mb-8 sm:mb-0 ${styleAll}`}>{transactionDetails?.PaymentsRemaining}</p>
                        </div>
                        <div className='sm:w-[30%]'>
                          <p className='mb-8 font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[13px] sm:text-[11px] text-[10px] font-[600] tracking-[2px] opacity-[50%]'>{t('frequency')}</p>
                          <p className={`mb-8 sm:mb-0 ${styleAll}`}>{transactionDetails?.FrequencyValue}</p>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div class="sm:flex md:w-[75%] w-full sm:mt-14">
                        <div className='sm:w-[70%]'>
                          <p className='mb-8 font-hanken-grotesk text-[#262B3A] uppercase lg:text-[13px] md:text-[13px] sm:text-[11px] text-[10px] font-[600] tracking-[2px] opacity-[50%]'>{t('transfer_date')}</p>
                          <p className={`mb-8 sm:mb-0 ${styleAll}`}>{moment(transactionDetails?.StartDate).format('DD-MM-YYYY') || 'Not Specified'}</p>
                        </div>
                        <div className='sm:w-[30%]'>
                          <div className="  flex flex-row cursor-pointer items-center pt-10" onClick={() => { handleReportAProblem() }}>
                            <img onClick={() => { handleReportAProblem() }} src={report_a_problem_flag} className='w-[10px] h-[12px] sm:w-[11px] sm:h-[14px] mr-3' alt="arrow" />
                            <p className="font-hanken-grotesk text-[14px] leading-[14px] sm:font-[700] sm:text-[12px] sm:leading-[20px] font-[600] md:text-[16px] md:leading-[24px] text-secondary-2"
                              tabIndex={0}
                              onClick={() => { handleReportAProblem() }}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  handleReportAProblem()
                                }
                              }}
                            >{t('report_a_problem')}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  }


                  <div class="md:w-[75%] w-full sm:mt-14 mt-2">


                    {transactionDetails?.FrequencyCode != "OT" ?
                      <>
                        <div className="  flex flex-row cursor-pointer items-center mb-5" onClick={() => { handleReportAProblem() }}>
                          <img onClick={() => { handleReportAProblem() }} src={report_a_problem_flag} className='w-[10px] h-[12px] sm:w-[11px] sm:h-[14px] mr-3' alt="arrow" />
                          <p className="font-hanken-grotesk text-[14px] leading-[14px] sm:font-[700] sm:text-[12px] sm:leading-[20px] font-[600] md:text-[16px] md:leading-[24px] text-secondary-2"
                            tabIndex={0}
                            onClick={() => { handleReportAProblem() }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                handleReportAProblem()
                              }
                            }}
                          >{t('report_a_problem')}</p>
                        </div>
                        <button
                          onKeyDown={(event) => { if (event.key === "Enter") { event.preventDefault(); onCancelClick(t('cancel_next_transfer')) } }}
                          className={`w-full mt-8 lg:h-[60px] md:h-[55px] sm:h-[50px] h-[55px] font-hanken-grotesk font-bold lg:text-[16px] md:text-[14px] sm:text-[13px] text-[14px] leading-[16px] border-[#2965FB] border-[2.5px] text-[#2965FB]`}
                          onClick={(event) => { event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); onCancelClick(t('cancel_next_transfer')) }}>
                          {t('cancel_next_transfer')}
                        </button>
                        <button
                          onKeyDown={(event) => { if (event.key === "Enter") { event.preventDefault(); onCancelClick(t('cancel_series')) } }}
                          className={`w-full mt-8 lg:h-[60px] md:h-[55px] sm:h-[50px] h-[55px] font-hanken-grotesk font-bold lg:text-[16px] md:text-[14px] sm:text-[13px] text-[14px] leading-[16px] border-[#2965FB] border-[2.5px] text-[#2965FB]`}
                          onClick={(event) => { event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); onCancelClick(t('cancel_series')) }}>
                          {t('cancel_series')}
                        </button>
                      </> :
                      <button
                        onKeyDown={(event) => { if (event.key === "Enter") { event.preventDefault(); onCancelClick(t('cancel_transfer')) } }}
                        className={`w-full mt-2 lg:h-[60px] md:h-[55px] sm:h-[50px] h-[55px] font-hanken-grotesk font-bold lg:text-[16px] md:text-[14px] sm:text-[13px] text-[14px] leading-[16px] border-[#2965FB] border-[2.5px] text-[#2965FB]`}
                        onClick={(event) => { event.target.style.opacity = 0.8; setTimeout(() => { event.target.style.opacity = 1; }, 200); onCancelClick(t('cancel_transfer')) }}>
                        {t('cancel_transfer')}
                      </button>}
                  </div>

                </div>
              </div>

            </DynamicCard>
          </div>
        </HomeLayout>
      </>
      <>
        {showModel &&
          <CustomAlertModel
            isOpen={showModel}
            onClose={closeModel}
            image={modelImage}
            highlightMsg={isMobile ? <span className={HighlightMsgStyles}>{highlightMsg}</span> : <p className={HighlightMsgStyles}>{highlightMsg}</p>}
            HeaderMsg={isMobile ? <span className={HeaderMsgStyles}>{headerMsg}</span> : <p className={HeaderMsgStyles}>{headerMsg}</p>}
            subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
            btn1Exists={btn1Exists}
            btn2Exists={btn2Exists}
            btn1Text={btn1Text}
            btn2Text={btn2Text}
            btn1TypeAcpt={btn1TypeAcpt}
            footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
            onAcptBtnClick={onAcptBtnClick}
            onCancelBtnClick={onCancelBtnClick}
            // showErrorImg={showErrorImg}
            isBorderNone={isBorderNone}
            btn1Focus={btn1Focus}
            btn2Focus={btn2Focus}
          />
        }
      </>
      {isLoading && <Loader />}
    </div >
  );
};

export default withOidcSecure(TransactionCard);
