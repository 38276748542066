import styled from "styled-components";
import tw from "twin.macro";

// Font-Family 
const primaryFont = "Source Serif Pro, serif";
const secondaryFont = "Hanken Grotesk, sans-serif";
// Font-Size

const fontSizes = {
  extralarge: "32px",
  large: "24px",
  medium: "18px",
  small: "16px",
  semiSmall: "14px",
};

export const height = {
  personal_info: '1100px',
  privacy_policy: '980px',
  default_card: '800px',
  terms_conditions: '846px',
  account_registration: '856px',
  terms_conditions: '1306px',
  terms_notes: '980px',
  home_card: '817px',
  home_card_savings: '681px',
  perks_profile: '402px',
  full_card_expanded: '1243px',
  filterheight: '1138px',
}

export const width = {
  allpolicies: '85%',
  homeCard: '100%',
  defaultWidth: '56.75%',
  FilterhomeCard: '100%',
}



export const Space = styled.div
  `${tw`lg:w-[112px] sm:w-[25px] sm:mt-[.5rem]`}`;

export const Title = styled.div`
  ${tw`sm:text-white text-[var(--text-clr-secondary2)] font-serif-pro lg:text-[31px] md:text-[26px] sm:text-[22px] text-[25px]
   lg:leading-[38px] md:leading-[33px] sm:leading-[29px] leading-[31px]`}`;
//font-family: ${(props) => (props.isPrimary ? primaryFont : secondaryFont)};

export const Subtitle = styled.span
  `${tw`sm:text-[var(--text-clr-secondary)] text-[var(--text-clr-secondary3)]`}`;

export const Main = styled.div`
  ${tw`md:w-[778px] sm:w-[465px] w-full sm:mt-[.5rem] `};
  width: ${props => (props.isHome ? '100%' : '778px')};
`;


export const Container = styled.div
  //`${tw`w-[86%] sm:w-[56.75%] md:w-[56.75%] h-[41px] mt-[100px]`}`;
  `${tw`w-[100%] sm:w-[68%] md:w-[56.75%] sm:mt-[5rem] mt-[3.5rem] sm:px-0 px-[2.5rem]`}`;

export const CardTitle = styled.div
  `${tw`text-[#262B3A] font-serif-pro md:leading-[36px] md:leading-[24px] 
  leading-[31px] font-[400] lg:text-[32px] md:text-[29px] sm:text-[22px] text-[25px] `}  
`;
//font-family: ${(props) => (props.isprimary ? primaryFont : secondaryFont)};
export const CardSection = styled.div`
  ${tw`w-full relative items-center flex flex-col mb-[0px]`}
  background-color: ${props => {
    if (props.isPerk) {
      return 'var(--card-bg-perks)';
    } else if (props.isProfile) {
      return 'var(--card-bg-profile)';
    } else if (props.isWhite) {
      return '#FFFFFF'
    }
    else {
      return 'var(--card-bg)';
    }
  }};
  border-radius: ${props =>
    props.CardRadius ? '0 4rem 0 4rem' : '4rem 0 4rem 0'};  
    @media (max-width: 699px) {
      border-radius: 0;  
  }  
`;


export const CardHeader = styled.div`
  ${tw`absolute w-full top-0 md:h-[122px] sm:h-[70px] h-[140px]`};
  background-color: ${props =>
    props.isActive ? 'var(--card-header-bg-active)' : props.backgroundColor ? props.backgroundColor : 'var(--card-header-bg-in-active)'};
  display: ${props => (props.ishidden ? 'none' : 'block')}; 
  border-radius: ${props =>
    props.HeaderRadius ? '0 4rem 0 0' : '4rem 0 0 0'};
    @media (max-width: 699px) {
      border-radius: 0;  
  }  
`;




export const CardFooter = styled.div`
  ${tw`absolute w-full bottom-0 h-[155px] rounded-bl-[4rem]`}
  display: ${(props) => (props.ishidden ? "none" : "block")};
  background-color: ${props => {
    if (props.backgroundColor) {
      return props.backgroundColor;
    }
    else {
      return 'none';
    }
  }};
`;


export const HeaderCaption = styled.div`
  ${tw`md:w-[293px] sm:w-[178px] sm:h-[199px]`};
  display: ${(props) => (props.isHeaderCaption ? "none" : "block")};
  
`;