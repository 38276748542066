import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../CustomComponents/Card';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import right_dropdown_arrow from '../../../../assets/images/right_dropdown_arrow.png'
import CustomTextInput from '../../../CustomComponents/CustomtextInput';
import Loader from '../../../CustomComponents/Loader';
import Modal from '../../../CustomComponents/Modal';
import { GET_ACCOUNTS_FOR_TRANSFER, GET_ACCOUNT_TYPES, STATUS_SUCCESS_CODE, VERIFY_EXTERNAL_ACCOUNT } from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../../network/NetworkUtils';
import CustomDropDown from '../../../CustomComponents/CustomDropDown';
import { retrieveData } from '../../../../helpers/utils/Utils';
import { displayErrorMessage } from '../../../../utils';
import blue_drop_down from '../../../../assets/images/blue_dropdown_arrow.png'
import { useLocation, useNavigate } from 'react-router-dom';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
import { withOidcSecure } from '@axa-fr/react-oidc';
import Footer from '../../../Layout/Footer';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';

import { width, height } from "../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import HomeHeader from '../../../CustomComponents/HomeHeader';
import HomeLayout from '../../../Layout/HomeLayout';

const VerifyExternalAccount = (props) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const selectedItem = cards[0]
    const [generateBtnClicked, setGenerateBtnClicked] = useState(true); //Routing Number
    const [showModel, setShowModel] = useState(false);
    const divRef = useRef(null);
    const [showValidity, setShowValidity] = useState(false)
    const [loading, setLoading] = useState(false)
    const [firstDeposit, setFirstDeposit] = useState(false)
    const [firstDepositeError, setfirstDepositeError] = useState("")

    const [firstDepositDetails, setFirstDepositDetails] = useState({ firstDeposit: '', firstDepositTouched: false, firstDepositValid: false })
    const [secondDepositDetails, setSecondDepositDetails] = useState({ secondDeposit: '', secondDepositTouched: false, secondDepositValid: false })
    const [infoMessage, setinfoMessage] = useState('');
    const isInitialRenderRef = useRef(true);
    const location = useLocation();
    const params = location.state;
    const externalAccountDetails = params ? params.externalAccountDetails : null;
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal";
    const [modelImage, setModelImage] = useState('')
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const navigate = useNavigate();
    const regexPattern = /^-?\d+(\.\d+)?$/;
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);

    // acts as componentDidMount
    useEffect(() => {
        // acquireAccountTypes()
    }, []);
    const headerContent = () => {
        return (
            <div className="flex flex-row justify-start mx-10 items-center mt-10">
                <div className='ml-10'>
                    {/* Left Text */}
                    <p className="text-xmd ">{t("external_accounts")}</p>
                </div>
            </div>
        )
    }
    useEffect(() => {
        if (firstDepositDetails.firstDepositTouched && secondDepositDetails.secondDepositTouched) {
            if ((Number(firstDepositDetails.firstDeposit) + Number(secondDepositDetails.secondDeposit) == 1) && Number(firstDepositDetails.firstDeposit) && Number(secondDepositDetails.secondDeposit)) {
                setFirstDepositDetails(prevState => ({ ...prevState, firstDepositTouched: true, firstDepositValid: true }))
                setSecondDepositDetails(prevState => ({ ...prevState, secondDepositTouched: true, secondDepositValid: true }))
                setShowValidity(true)
                setinfoMessage(t(''))
            } else {
                setFirstDepositDetails(prevState => ({ ...prevState, firstDepositTouched: true, firstDepositValid: false }))
                setSecondDepositDetails(prevState => ({ ...prevState, secondDepositTouched: true, secondDepositValid: false }))
                setShowValidity(true)
            }
        }
    }, [firstDepositDetails.firstDeposit, secondDepositDetails.secondDeposit]);
    useKeyDown(() => {
        onConfirm();
    }, ["Enter"]);
    const onConfirm = async () => {
        if ((Number(firstDepositDetails.firstDeposit) + Number(secondDepositDetails.secondDeposit) > 0) && (Number(firstDepositDetails.firstDeposit) + Number(secondDepositDetails.secondDeposit) < 1) && Number(firstDepositDetails.firstDeposit) && Number(secondDepositDetails.secondDeposit)) {
            const deviceId = await retrieveData('updatedDeviceId');
            const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
            const url = VERIFY_EXTERNAL_ACCOUNT.replace('{cardKey}', selectedItem.CardKey)
            const object = {
                "amount1": Number(firstDepositDetails.firstDeposit),
                "amount2": Number(secondDepositDetails.secondDeposit),
                "achaccountInternalId": externalAccountDetails.ACHAccountInternalId
            }
            setLoading(true)

            const response = await PostRequest(url, object, headers)

            setTimeout(() => {
                setLoading(false)
            }, 500);
            const responseObj = JSON.parse(response)
            if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
                setTimeout(() => {
                    setinfoMessage('')
                    showAlertModal(true, t("success_heading"), t("your_account_has_been_verified"), t("your_acc_is_now_linked"), true, false, 'Ok', '', true, require('../../../../assets/images/verified_img.png'), '', false, false)
                }, 1000);
            } else {
                var arrMessages = responseObj.Messages;
                var message = arrMessages[0];
                var displayText = message.DisplayText;
                setinfoMessage(displayText)
                return
            }
        } else {
            setinfoMessage(t('the_amount_you_entered_is_incorrect_try_again'))
        }
    };

    const handleOnBlurEvent = (e) => {
        if ((Number(firstDepositDetails.firstDeposit) + Number(secondDepositDetails.secondDeposit) > 0) && (Number(firstDepositDetails.firstDeposit) + Number(secondDepositDetails.secondDeposit) < 1) && Number(firstDepositDetails.firstDeposit) && Number(secondDepositDetails.secondDeposit)) {
            setinfoMessage('')
        } else {
            setinfoMessage(t('the_amount_you_entered_is_incorrect_try_again'))
        }
    }

    const handleFirstDepositeOnBlurEvent = (e) => {
        if (e.target.value === "") setfirstDepositeError('Please enter first deposit amount')
        else setfirstDepositeError('')
    }
    const onAcptBtnClick = async () => {
        if (headerMsg == t("your_account_has_been_verified")) { navigate('/Settings/ManageExternalAccounts') }
        else if (btn1Text == t("home")) { navigate('/home') }
        else if (btn1Text === 'Ok') { navigate('/Settings/ManageExternalAccounts') }
    }
    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, showErrorImg, isBorderNone) => {
        setshowErrorImg(showErrorImg)
        setisBorderNone(isBorderNone)
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }


    const closeCustomAlertModal = () => {
        setShowModel(false);
    }
    const onCancelBtnClick = () => {
        if (btn2Text == t('back_to_ext_accounts')) {
            navigate('/Settings/ManageExternalAccounts')
        } else if (btn2Text == t("back_to_account_services")) { navigate('/AccountServices') }
    }
    return (
        <>
            <HomeLayout>
                <HomeHeader heading={t('lets_verify_your_external_account')} isHeaderCaption={true} />

                <div className='flex flex-col md:w-[860px] sm:w-[464px] 
                w-[100%] animate__animated animate__fadeInRight '>
                    <DynamicCard
                        cardWidth={width.homeCard}
                        header={false}
                        footer={false}
                        headerContent={headerContent()}
                        footerContent={() => { }}
                        CardRadius={true}
                        HeaderRadius={true}>
                        <div class="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                 lg:text-[26px] md:text-[22px] sm:text-[20px] text-[20px]
                        leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">{t("verify_account")}</div>
                        <div className="bg-[#A3CEBB] h-0.5 min-h-1 mt-4 sm:mx-0 mx-4"></div>

                        <div className="flex flex-col h-full mt-5 mb-10 sm:mx-0 mx-4">
                            <div className='md:w-4/5 sm:w-[87%] w-full flex flex-col self-center'>
                                <p className='font-hanken-grotesk text-[#262B3A] uppercase 
                                lg:text-[13px] md:text-[12px]
                                 sm:text-[12px] text-[13px] font-[600] tracking-[2px] opacity-[50%] md:pt-8 pt-4'>
                                    {t("account_name")}
                                </p>

                                <div className='flex flex-row justify-between md:mt-8 mt-6'>
                                    <p className="lg:text-[16px] md:text-[15px] sm:text-[13px] text-[14px]
                                     font-semibol font-medium mr-2">
                                        {externalAccountDetails?.AccountName?.replace(" Account", "") || ''}
                                    </p>
                                </div>
                            </div>

                            <div className='md:w-4/5 sm:w-[87%] w-full flex flex-col self-center'>
                                <p className="lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px]
                                  font-hanken-grotesk mt-10 lg:w-[483px] md:w-[436px] w-full font-[500]">
                                    {t("kudzu_will_credit_two")}</p>
                                <p className="lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px]
                                 font-hanken-grotesk mt-5 lg:w-[483px] md:w-[436px] w-full font-[600]">
                                    {t("enter_the_deposit_amounts_below")}</p>
                            </div>
                            <div className='flex flex-col justify-center'>
                                <div className='flex flex-col md:w-4/5 sm:w-[87%] w-full  self-center justify-center'>
                                    <div className='sm:w-2/4 w-full flex mt-10'><p className='h-[60px] text-16 bg-[#eaf0f3]  md:mt-9 sm:mt-[31px] mt-8 flex flex-col text-center self-center justify-center items-center font-hanken-grotesk text-[#2965FB] pl-2'>{firstDepositDetails.firstDeposit ? '$' : ' '}</p>
                                        <CustomTextInput placeholder={t("enter_amount")}
                                            autoFocus={true} valid={firstDepositDetails.firstDepositValid}
                                            name={t("first_deposit")}
                                            onChange={(event) => { setinfoMessage(''); setfirstDepositeError(""); if (/^\d*\.?\d*$/.test(event.target.value) || event.target.value === '') { setFirstDepositDetails((prevState) => ({ ...prevState, firstDeposit: event.target.value, firstDepositTouched: true, })); } }}
                                            onBlur={(e) => handleFirstDepositeOnBlurEvent(e)}
                                            value={firstDepositDetails.firstDeposit} />
                                    </div>
                                </div>
                                    {firstDepositeError &&
                                        <div className='mb-2 md:w-4/5 sm:w-[87%] w-full  self-center justify-center'>
                                            {displayErrorMessage(firstDepositeError)}
                                        </div>
                                    }
                                <div className='flex flex-col md:w-4/5 sm:w-[87%] w-full  self-center justify-center'>
                                    <div className='sm:w-2/4 w-full flex mt-10'><p className='h-[60px] text-16 bg-[#eaf0f3]  md:mt-9 sm:mt-[31px] mt-8 flex flex-col text-center self-center justify-center items-center font-hanken-grotesk text-[#2965FB] pl-2'>{secondDepositDetails.secondDeposit ? '$' : ' '}</p><CustomTextInput placeholder={t("enter_amount")} valid={secondDepositDetails.secondDepositValid} name={t("second_deposit")}
                                        onChange={(event) => {
                                            setinfoMessage('');
                                            setfirstDepositeError("");
                                            if (/^\d*\.?\d*$/.test(event.target.value) || event.target.value === '') { setSecondDepositDetails((prevState) => ({ ...prevState, secondDeposit: event.target.value, secondDepositTouched: true, })); }
                                        }
                                        }
                                        onBlur={(e) => handleOnBlurEvent(e)}
                                        value={secondDepositDetails.secondDeposit} /></div>
                                </div>
                                {infoMessage &&
                                    <div className='mb-2 md:w-4/5 sm:w-[87%] w-full  self-center justify-center'>
                                        {displayErrorMessage(infoMessage)}
                                    </div>
                                }

                                <div className='flex flex-row sm:justify-end justify-between w-full md:mt-20 mt-16 mb-5 '>
                                    <button className='font-hanken-grotesk text-[#2965FB] lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px]
                                     font-semibold sm:ml-10' onClick={(event) => { event.target.style.opacity = 0.8; navigate('/Settings/ManageExternalAccounts'); setTimeout(() => { event.target.style.opacity = 1; }, 200); }} onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); event.target.style.opacity = 0.8; navigate('/Settings/ManageExternalAccounts'); setTimeout(() => { event.target.style.opacity = 1; }, 200); } }} style={{}}>{t("back")}</button>
                                    <button className='font-hanken-grotesk text-[#2965FB] lg:text-[18px] md:text-[17px] sm:text-[15px] 
                                    text-[16px] font-semibold ml-10' onClick={(event) => { onConfirm(); }} onKeyDown={(event) => { if (event.key == "Enter") { event.preventDefault(); onConfirm(); } }} style={{ opacity: (firstDepositDetails.firstDeposit && secondDepositDetails.secondDeposit) ? 1 : 0.5 }}>{"Confirm"}</button>
                                </div>
                            </div>
                        </div>
                    </DynamicCard>

                </div>
                {loading && <Loader />}
            </HomeLayout>
            <CustomAlertModel isOpen={showModel}
                onClose={closeCustomAlertModal}
                image={modelImage}
                highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
                HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
                subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
                btn1Exists={btn1Exists}
                btn2Exists={btn2Exists}
                btn1Text={btn1Text} btn2Text={btn2Text}
                btn1TypeAcpt={btn1TypeAcpt}
                verificationFailure={() => { }}
                onAcptBtnClick={onAcptBtnClick}
                onCancelBtnClick={onCancelBtnClick}
                showErrorImg={showErrorImg}
                isBorderNone={isBorderNone}
            />
        </>
    )
}

export default withOidcSecure(VerifyExternalAccount);
