import React, { useCallback, useEffect, useState } from "react";
import GoogleIcon from "../../../../assets/images/google.png";
import AppleIcon from "../../../../assets/images/apple.png";
import FbIcon from "../../../../assets/images/fb.png";
import MicrosoftIcon from "../../../../assets/images/microsoft.png";
import EmailIcon from "../../../../assets/images/email.png";
import { useTranslation } from "react-i18next";
import Card from "../../../CustomComponents/Card";
import LineBreaks, { displayErrorMessage } from "../../../../utils";
import Notice from "../../Notice";
import {
  SocialLoginButtons,
  GoogleButton,
  LoginButtonImage,
  AppleLoginButton,
  EmaillLoginButtonImage,
  FBLoginButton,
  MicrosoftLoginButton,
  EmailloginBtn,
} from "./SocailStyles";
import DynamicCard from "../../../CustomComponents/DynamicCard";
import { useKeyDown } from "../../../../hooks/useKeyDown";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialApple,
  LoginSocialMicrosoft,
} from "reactjs-social-login";
import {
  APP_ENV,
  fetchConfigarationData,
  retrieveData,
} from "../../../../helpers/utils/Utils";
import { async } from "q";
import {
  GetApiHeadersWithOutBearer,
  PostRequest,
} from "../../../../network/NetworkUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  EMAIL_OTP_GENERATION,
  ISDEV_ENV,
  OTP_USERS,
  STATUS_SUCCESS_CODE,
} from "../../../../network/URLConstants";
import Modal from "../../../CustomComponents/Modal";
import Custom2FA from "../../../CustomComponents/Custom2FA";
import CustomAlertModel from "../../../CustomComponents/CustomAlertModel";
import {
  LOGIN_SESSION_KEY,
  PROVIDER,
  PROVIDER_IDENTIFIER,
} from "../../../../Redux/reducers";
import jwt_decode from "jwt-decode";
import MicrosoftLogin from "react-microsoft-login";
import Loader from "../../../CustomComponents/Loader";
import { useNavigate } from "react-router-dom";
import MicrosoftLoginAuth from "./MicrosoftLoginAuth";
import logFirebaseEvents from "../../../LogFirebaseEvents";

const appConfigData = await fetchConfigarationData();
const REDIRECT_URI = appConfigData.REDIRECT_URI;
export const SocailLogins = ({
  onSocailSignupClick,
  color,
  onSocialAuthClick,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const signupWalkYou = t("sign_up_walk_you");
  const SignupCaption = t("sigIn_method");

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
  const HeadingStyles =
    "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB]";
  const SubHeadingStyles =
    "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-secondary-3";
  const HighlightMsgStyles =
    "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles =
    "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles =
    "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [infoMessage, setinfoMessage] = useState("");

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [highlightMsg, setHighlightMsg] = useState(false);
  const [headerMsg, setheaderMsg] = useState(false);
  const [subHeaderMsg, setSubHeaderMsg] = useState(false);
  const [btn1Exists, setBtn1Exists] = useState(false);
  const [btn2Exists, setBtn2Exists] = useState(false);
  const [btn1Text, setBtn1Text] = useState(false);
  const [btn2Text, setBtn2Text] = useState(false);
  const [footerMsg, setFooterMsg] = useState("");
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false);
  const [modelImage, setModelImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [providerIdentifierId, setProviderIdentifierId] = useState("");

  // const sessionKey = useSelector(state => state.loggedSessionKey);
  const userId = useSelector((state) => state.userId);

  useEffect(() => {
    logFirebaseEvents("Social_Logins", "Signup_Screen_Visted", "Signup_ScreenVisted", "");
  }, []);

  const handleSignup = () => {
    logFirebaseEvents(
      "Social_Logins",
      "Signup_Btn_Clicked",
      "Signup_Btn_Clicked",
      ""
    );
    dispatch({
      type: PROVIDER_IDENTIFIER,
      payload: { providerIdentifier: null },
    });
    dispatch({ type: PROVIDER, payload: { provider: null } });
    onSocailSignupClick();
  };
  // useKeyDown(() => {

  //   if (email == "") {
  //     onSocailSignupClick();
  //   } else {
  //     onSocialAuthClick(email)
  //   }
  // }, ["Enter"]);

  ///GOOGLE LOGIN
  const handleGoogleLoginSuccess = async (response) => {
    const userData = response;
    setEmail(userData.data.email);
    setProviderIdentifierId(userData.data.sub);
    setSelectedType(t("google").toLowerCase());
    dispatch({
      type: PROVIDER_IDENTIFIER,
      payload: { providerIdentifier: userData.data.sub },
    });
    dispatch({
      type: PROVIDER,
      payload: { provider: t("google").toLowerCase() },
    });
    generateVerificationCodeForOAuth(
      userData.data.email,
      t("google").toLowerCase(),
      userData.data.sub
    );
  };

  //fb login
  const handleFacebbokLoginSuccess = (response) => {
    // debugger;
    const userData = response;
    setEmail(response.data.email);
    setProviderIdentifierId(response.data.id);
    setSelectedType(t("facebook").toLowerCase());
    dispatch({
      type: PROVIDER_IDENTIFIER,
      payload: { providerIdentifier: response.data.id },
    });
    dispatch({
      type: PROVIDER,
      payload: { provider: t("facebook").toLowerCase() },
    });
    generateVerificationCodeForOAuth(
      response.data.email,
      t("facebook").toLowerCase(),
      response.data.id
    );
  };

  //microsoft login success
  // const handleMicrosoftLoginSuccess = (response) => {
  //   // debugger;
  //  }

  const handleMicrosoftLoginSuccess = (err, data) => {
    if (data != null && data != undefined) {
      if (data.mail != undefined && data.mail != null && data.mail != "") {
        setEmail(data.mail);
        setSelectedType(t("microsoft").toLowerCase());
        /*Changed data.account.idTokenClaims.sub to data.id */
        setProviderIdentifierId(data.id);
        dispatch({
          type: PROVIDER_IDENTIFIER,
          payload: { providerIdentifier: data.id },
        });
        dispatch({
          type: PROVIDER,
          payload: { provider: t("microsoft").toLowerCase() },
        });
        generateVerificationCodeForOAuth(
          data.mail,
          t("microsoft").toLowerCase(),
          data.id
        );
      }
    } else {
      setIsLoading(false);
    }
  };

  //apple login success
  const handleAppleLoginSuccess = async (response) => {
    // debugger;
    // const userData = response.data;
    // var localresponse = {
    //   "provider": "apple",
    //   "data": {
    //     "authorization": {
    //       "code": "cd7f865fead4747cd9d2befd185c0dbee.0.ryvy.gCpXFgu5YOJV29MyRXqBAw",
    //       "id_token": "eyJraWQiOiJZdXlYb1kiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLmt1ZHp1bW9uZXlsbGMua3VkenUud2ViIiwiZXhwIjoxNjkyODczNjQ3LCJpYXQiOjE2OTI3ODcyNDcsInN1YiI6IjAwMDg1OC5kOGJlYTE4M2RiMjc0YTU4YWEzMDQxN2U0ZjdjNGUzNC4wOTI2IiwiY19oYXNoIjoiNHNaUExoNEhGRVlpb3RITmRBeXE2USIsImVtYWlsIjoiYnZ2NWdzYmpteEBwcml2YXRlcmVsYXkuYXBwbGVpZC5jb20iLCJlbWFpbF92ZXJpZmllZCI6InRydWUiLCJpc19wcml2YXRlX2VtYWlsIjoidHJ1ZSIsImF1dGhfdGltZSI6MTY5Mjc4NzI0Nywibm9uY2Vfc3VwcG9ydGVkIjp0cnVlfQ.ofxBB3BpiNB-eAKK8OEcGZA5_hi0Ch_gFbkzfObkPconOFQOOCOS44YvIflwMpTZx6S2HfhehWl5MQp9unkODPcbT8UR9QlyFTGtHbANkRGWBEmCWxLjbxHsAfKL3Ty0d9YxSaFHfm6QA-tmH0zNtYxR7X-IchO9UE04E1VbfzYPDmxiom8cFRD_xn2-RfybYvF_T9DEdO4tzLAnLsgEmeubjGkbGdnzSdXt-biVErxkUov1sFYLfuMfkEKfV98aD3qXRrECke5vYf5GPc8oaA-dSZ7UPQBvtfbDsCd1xIWfsSLljH5Ho0I_ZohiZbIkqmQm3DSy4VUAO1xIl7igxg",
    //       "state": "origin:web"
    //     }
    //   }
    // }
    // const appleAuthResponse = await jwt_decode(localresponse.data.authorization.id_token);
    const appleAuthResponse = await jwt_decode(
      response.data.authorization.id_token
    );
    setEmail(appleAuthResponse.email);
    setSelectedType(t("apple").toLowerCase());
    setProviderIdentifierId(appleAuthResponse.sub);
    dispatch({
      type: PROVIDER_IDENTIFIER,
      payload: { providerIdentifier: appleAuthResponse.sub },
    });
    dispatch({
      type: PROVIDER,
      payload: { provider: t("apple").toLowerCase() },
    });
    generateVerificationCodeForOAuth(
      appleAuthResponse.email,
      t("apple").toLowerCase(),
      appleAuthResponse.sub
    );
  };

  const generateVerificationCodeForOAuth = async (
    email,
    provider,
    providerId
  ) => {
    setIsLoading(true);
    const deviceId = await retrieveData("updatedDeviceId");
    let headers = await GetApiHeadersWithOutBearer(deviceId);

    var object = {
      applicationId: appConfigData.CLIENT_ID,
      language: "en",
      username: email,
      provider: provider,
      providerId: providerId,
    };
    // var url = OTP_USERS + userId + '/emails';
    var url = EMAIL_OTP_GENERATION + userId + "/validate";
    const response = await PostRequest(url, object, headers);
    setIsLoading(false);
    let parsedResponse = JSON.parse(response);
    var arrMessages = parsedResponse.Messages;
    var message,
      displayText,
      messageOrField = "";
    if (arrMessages.length > 0) {
      message = arrMessages[0];
      displayText = message.DisplayText;
      messageOrField = message.MessageOrField;
    }

    if (parsedResponse.ResponseCode == STATUS_SUCCESS_CODE) {
      onSocailSignupClick(email);
      // const sessionIdPayload = { loggedSessionKey: parsedResponse.Response.sessionId }
      // dispatch({ type: LOGIN_SESSION_KEY, payload: sessionIdPayload });
      // setShowModal(true)
    } else {
      showCustomAlertModal(
        true,
        "Alert",
        displayText,
        "",
        true,
        true,
        t("login"),
        t("try_again"),
        true,
        ""
      );
    }
  };

  const closeOTPVerifyModal = () => {
    setIsOTPVerifyModalOpen(false);
    setShowModal(false);
  };

  const verificationFaiure = (type, message) => {
    setinfoMessage(message);
  };

  const verificationSuccess = (type) => {
    // return
    if (type == t("google")) {
      
      onSocialAuthClick(email);
    } else if (type == t("facebook")) {
      onSocialAuthClick(email);
    } else if (type == t("apple_id")) {
      onSocialAuthClick(email);
    } else if (type == t("microsoft")) {
      onSocialAuthClick(email);
    }
  };

  const showCustomAlertModal = (
    showModel,
    highlightMsg,
    headerMsg,
    subHeaderMsg,
    btn1Exists,
    btn2Exists,
    btn1Text,
    btn2Text,
    btn1TypeAcpt,
    modelImage
  ) => {
    setShowAlertModal(showModel);
    setHighlightMsg(highlightMsg);
    setheaderMsg(headerMsg);
    setSubHeaderMsg(subHeaderMsg);
    setBtn1Exists(btn1Exists);
    setBtn2Exists(btn2Exists);
    setBtn1Text(btn1Text);
    setBtn2Text(btn2Text);
    setBtn1TypeAcpt(btn1TypeAcpt);
    setModelImage(modelImage);
  };

  const closeCustomAlertModal = () => {
    setShowAlertModal(false);
  };
  const onAcptBtnClick = async () => {
    setShowAlertModal(false);
    navigate("/");
    window.location.href = window.location.origin;
  };
  const onCancelBtnClick = () => {
    setShowAlertModal(false);
  };

  return (
    <>
      <div className="sm:px-[6%] md:py-10 sm:py-12 flex flex-wrap sm:flex-nowrap">
        <Card
          isShowNotice={true}
          heading={t("sign_easy")}
          subheading={t(signupWalkYou)}
          Cardcaption={LineBreaks(SignupCaption)}
          header={true}
          footer={true}
          isNoticeTrue={true}
          CardRadius={true}
        >
          {/* ///socail signup form */}
          <SocialLoginButtons>
            <LoginSocialGoogle
              client_id={appConfigData.GOOGLE_CLIENT_ID}
              redirect_uri={appConfigData.APPLE_REDIRECT_URI}
              onResolve={handleGoogleLoginSuccess}
              scope="profile email"
              onReject={(err) => {
                setIsLoading(false);
              }}
              tabindex="1"
            >
              <GoogleButton type="button" fontWeight="bold">
                <span className="relative right-[4px]">
                  <LoginButtonImage src={GoogleIcon} alt="Google Login" />
                </span>
                <span className="relative left-[12px]">{t("google")}</span>
              </GoogleButton>
            </LoginSocialGoogle>
            <LoginSocialApple
              client_id={appConfigData.APPLE_TEAM_ID}
              redirect_uri={appConfigData.APPLE_REDIRECT_URI}
              onResolve={handleAppleLoginSuccess}
              scope="name email"
              onReject={(err) => {
                setIsLoading(false);
              }}
              tabindex="2"
            >
              <AppleLoginButton type="button" fontWeight="bold">
                <span className="relative right-[0px]">
                  <LoginButtonImage src={AppleIcon} alt="Apple Login" />
                </span>
                <span className="relative left-[17px]">{t("apple_id")} </span>
              </AppleLoginButton>
            </LoginSocialApple>
            {/* <LoginSocialFacebook
              appId={appConfigData.FACEBOOK_CLIENT_ID}
              autoLoad={true}
              redirect_uri={ISDEV_ENV ? 'https://localhost:3000' : appConfigData.APPLE_REDIRECT_URI}
              fields="name,email,picture"
              onResolve={handleFacebbokLoginSuccess}
              onReject={err => {
                setIsLoading(false)
              }}
              tabindex="3"
            >
              <FBLoginButton type="button" fontWeight="bold">
                <span className="relative left-[5px]">
                  <LoginButtonImage src={FbIcon} alt="Facebook Login" />
                </span>
                <span className="relative left-[20px]"> {t('facebook')} </span>
              </FBLoginButton>
            </LoginSocialFacebook> */}

            {/* <LoginSocialMicrosoft 
              client_id='6fc7aec8-3510-416d-a4b5-de2f34ea7562'
              redirect_uri='https://localhost:3000'
              // scope='openid profile User.Read'
              onResolve={handleMicrosoftLoginSuccess}
              onReject={(err) => {
              }}>
              <MicrosoftLoginButton type="button" fontWeight="bold">
                <span className="relative left-[5px]">
                  <LoginButtonImage src={MicrosoftIcon} alt="Microsoft Login" />
                </span>
                <span className="relative left-[20px]"> {t('microsoft')} </span>
              </MicrosoftLoginButton>
            </LoginSocialMicrosoft> */}
            {/* <MicrosoftLogin
              clientId={appConfigData.MICROSOFT_CLIENT_ID}
              authCallback={handleMicrosoftLoginSuccess}
              tenantUrl={appConfigData.MICROSOFT_TENANT_URL}
              redirectUri={ISDEV_ENV ? 'https://localhost:3000' : appConfigData.APPLE_REDIRECT_URI}
              withUserData={true}
              tabindex="4"
              prompt='consent'
            >
              <MicrosoftLoginButton type="button" fontWeight="bold">
                <span className="relative left-[5px]">
                  <LoginButtonImage src={MicrosoftIcon} alt="Microsoft Login" />
                </span>
                <span className="relative left-[20px]"> {t('microsoft')} </span>
              </MicrosoftLoginButton>
            </MicrosoftLogin> */}
            <MicrosoftLoginAuth
              clientId={appConfigData.MICROSOFT_CLIENT_ID}
              authCallback={handleMicrosoftLoginSuccess}
              tenantUrl={appConfigData.MICROSOFT_TENANT_URL}
              graphScopes={["user.read"]}
              // graphScopes={["user.read", "mail", "profile", "openid", "offline_access"]}
              redirectUri={
                ISDEV_ENV
                  ? "https://localhost:3000/Onboard"
                  : appConfigData.APPLE_REDIRECT_URI
              }
              withUserData={true}
              tabindex="4"
              prompt="consent"
            >
              <MicrosoftLoginButton type="button" fontWeight="bold">
                <span className="relative left-[5px]">
                  <LoginButtonImage src={MicrosoftIcon} alt="Microsoft Login" />
                </span>
                <span className="relative left-[20px]"> {t("microsoft")} </span>
              </MicrosoftLoginButton>
            </MicrosoftLoginAuth>

            <button
              className="mt-[50px] bg-[#D6E2E8] text-blue-500 h-[60px] inline-flex justify-center items-center gap-3 lg:text-[15px] md:text-[14px] sm:text-[14px] text-[15px] font-hanken-grotesk font-bold "
              onClick={handleSignup}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSignup();
              }}
              fontWeight="bold"
            >
              <EmaillLoginButtonImage src={EmailIcon} alt="SignUp" />
              {t("signup_btn_text")}
            </button>
          </SocialLoginButtons>
        </Card>
        <Modal
          isOpen={showModal}
          onClose={closeOTPVerifyModal}
          isLogo={false}
          //NEED TO CHECK////------>    // subHeaderMsg={<p className="font-hanken-grotesk modal-title lg:text-[18px] md:text-[17px] sm:text-[15px] text-[14px] font-normal cursor-grab pointer-events-none">{t("standard_msg_and_data_rates_may_apply")}</p>}
          title={
            <p className={HeadingStyles}> {t("security_check_custom2fa")}</p>
          }
          subtitle={
            <>
              <p className={SubHeadingStyles}>{t("we_are_sending_text")}</p>
              <p
                className="font-hanken-grotesk my-3 text-[#262B3A] lg:text-[20px] md:text-[17px] sm:text-[12px] text-[16px]
        leading-[30px] font-[500]  w-full"
              >
                {t("standrd_message_rates_apply")}
              </p>{" "}
            </>
          }
          content={
            <div className="mt-10">
              <Custom2FA
                phoneNumber={""}
                email={email}
                address={""}
                type={selectedType}
                onClose={closeOTPVerifyModal}
                verificationFailure={verificationFaiure}
                verificationSuccess={verificationSuccess}
              />
            </div>
          }
        />

        {infoMessage != "" && (
          <div className="mb-2">{displayErrorMessage(infoMessage)}</div>
        )}
        {isLoading && <Loader />}

        <CustomAlertModel
          isOpen={showAlertModal}
          onClose={closeCustomAlertModal}
          image={modelImage}
          highlightMsg={<p className={HighlightMsgStyles}>{highlightMsg}</p>}
          HeaderMsg={<p className={HeaderMsgStyles}>{headerMsg}</p>}
          subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
          btn1Exists={btn1Exists}
          btn2Exists={btn2Exists}
          btn1Text={btn1Text}
          btn2Text={btn2Text}
          btn1TypeAcpt={btn1TypeAcpt}
          verificationFailure={() => {}}
          footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
          onAcptBtnClick={onAcptBtnClick}
          onCancelBtnClick={onCancelBtnClick}
        />
      </div>
    </>
  );
};

export default SocailLogins;
