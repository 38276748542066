import React, { useRef, useState, useEffect } from 'react';
import SearchIcon from '../../assets/images/Search.png'
import ClearIcon from '../../assets/images/ClearIcon.png'
import GoBtnIcon from '../../assets/images/GoBtn.png'
import CloseIcon from '../../assets/images/close.png';
import GraySearchIcon from '../../assets/images/graySearchIcon.png';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SearchInput = ({
  placeholderText = "Search...",
  isEnabled,
  handleSearchClick,
  hideTootipGotIt,
  isInputVisible,
  isTooltipVisible,
  onInputChange,
  handleClear,
  showCloseIcon,
  handleArrowClick,
  isTooltipVisibleForSaving,
  selectedTab,
  labelsShow,
  hideTootipGotItForSaving,
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();
  const SearchFiltervalue = useSelector(state => state.SearchFiltervalue);
  const previousTabRef = useRef();

  const dispatch = useDispatch()


  useEffect(() => {
    if (SearchFiltervalue) {
      setInputValue(SearchFiltervalue);
      onInputChange(SearchFiltervalue);
    }
  }, [SearchFiltervalue]);


  useEffect(() => {
    if (isInputVisible) {
      inputRef.current?.focus();
    }
  }, [isInputVisible]);

  const handleSearchChange = (e) => {
    dispatch({ type: 'CLOSE_ICON_FROM_STORE', payload: { closeIconFromStore: false } });
    const newValue = e.target.value;
    setInputValue(newValue);
    onInputChange(newValue); // Call the parent handler with the new value
  };

  useEffect(() => {
    if (SearchFiltervalue) {
      handleSearchClick()
    }
  }, [])

  

  useEffect(() => {
    if (previousTabRef.current && previousTabRef.current !== selectedTab) {
      global.searchValueFromGlobal = '';
      setInputValue('');
    }
    previousTabRef.current = selectedTab;
  }, [selectedTab]);


  const CloseIconClick = () => {
    global.searchValueFromGlobal = '';
    setInputValue('');
    onInputChange('');
  }



  console.log(SearchFiltervalue, "SearchFiltervalue jdeseara")


  return (


    <div className="relative flex items-center">
      {/* Search Icon */}
      {!isInputVisible && (
        <span
        onClick={isEnabled ? handleSearchClick : null}
                  className={`${isEnabled ? 'text-[#2965FB] cursor-pointer ' : 'text-[#262B3A] opacity-50 cursor-not-allowed'} flex justify-between ml-auto space-x-1 lg:text-[18px] md:text-[18px] sm:text-[11px] text-[14px] font-[600] sm:font-[700] items-center`}
        >
          <img
            src={isEnabled ? SearchIcon : GraySearchIcon}
             alt="Search Icon"
            className=" lg:w-[17px] lg:h-[17px] md:w-[11px] md:h-[11px] "
          />
          <span className="hidden md:block">{t('SearchBtn')}</span>
        </span>
      )}

      {/* Input Field */}
      {isInputVisible && (
        <div className={` ${labelsShow ? '':'mr-4'} relative`}>
          <input
            type="text"
            ref={inputRef}
            value={inputValue}
            onChange={handleSearchChange}
            className="transition-all duration-400 w-[100%] ease-in-out px-8 md:py-2 py-1 font-[18px] placeholder-[#262B3A] placeholder:opacity-50 placeholder:font-semibold text-[#262B3A] border-[2.5px] border-[#2965FB] rounded-[59.524px] focus:border-[#2965FB] focus:outline-none focus:ring-0.5 focus:ring-[#2965FB]"
            placeholder={placeholderText}
          />
          {isTooltipVisible && (
            <div className="mt-6 font-hanken-grotesk max-w-[132px] leading-4 font-bold absolute top-full left-0  p-[16px] bg-[#2965FB] text-white text-[12px] rounded-xl shadow-lg  z-10">
              <div className="tooltip-arrow"></div>
              {t('transactionSearchBoxMessageTip')}
              <div className='flex justify-end pt-1'>
                <span className="underline cursor-pointer pt-2 pb-0" onClick={hideTootipGotIt}>{t("GotIt")}</span>
              </div>
            </div>
          )}

          {isTooltipVisibleForSaving && (
            <div className="mt-6 font-hanken-grotesk max-w-[132px] leading-4 font-bold absolute top-full left-0  p-[16px] bg-[#2965FB] text-white text-[12px] rounded-xl shadow-lg  z-10">
              <div className="tooltip-arrow"></div>
              {t('transactionSearchBoxMessageTip')}
              <div className='flex justify-end pt-1'>
                <span className="underline cursor-pointer pt-2 pb-0" onClick={hideTootipGotItForSaving}>{t("GotIt")}</span>
              </div>
            </div>
          )}
          <img src={SearchIcon} className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
          {inputValue.length === 0 && (
            <button
              type="button"
              onClick={handleClear}
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            >
              <img src={ClearIcon} className="w-4 h-4 text-gray-400" />
            </button>
          )}
          {inputValue.length > 0  && !showCloseIcon && (
            <button
              type="button"
              onClick={handleArrowClick}
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            >
              <img src={GoBtnIcon} className="w-4 h-4 text-gray-400" />
            </button>
          )}
          {inputValue && showCloseIcon && (
            <button
              type="button"
              onClick={CloseIconClick}
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            >
              <img src={CloseIcon} className="w-4 h-4 text-gray-400" />
            </button>
          )}
        </div>
      )}

    </div>
  );
};
export default SearchInput;
