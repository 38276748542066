import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../../Slider/Slider.css';
import { SAVINGS, SPENDING } from '../../../../utils';
import logFirebaseEvents from '../../../LogFirebaseEvents';

const AmountRangeSlider = ({ selectedMinAmount, selectedMaxAmount, onAmountChangeProp }) => {
  const currentPath = window.location.pathname;
  const minAmount = 0;
  const maxAmount = 500;
  const [amountRange, setAmountRange] = useState([selectedMinAmount, selectedMaxAmount]);

  const onAmountChange = (values) => {

    if(currentPath === '/' + SPENDING){
      logFirebaseEvents("Spending", "Filter_by_amount", "Filter_by_amount","", "");
  }
else if(currentPath === '/' + SAVINGS){
  logFirebaseEvents("Savings", "Filter_by_amount", "Filter_by_amount","", "");
}

    let [value1, value2] = values;

    // Enforce the constraint: maxAmount should not cross minAmount
    if (value2 < value1) {
      // Swap values to prevent reversing
      [value1, value2] = [value2, value1];
    }

    // Enforce the constraint: maxAmount should not exceed 500
    const constrainedValue2 = Math.min(value2, maxAmount);

    setAmountRange([value1, constrainedValue2]);
    onAmountChangeProp(value1, constrainedValue2);
  };

  useEffect(() => {
    setAmountRange([selectedMinAmount, selectedMaxAmount]);
  }, [selectedMinAmount, selectedMaxAmount]);

  useEffect(() => {
    setAmountRange([selectedMinAmount, selectedMaxAmount]);
  }, [selectedMinAmount, selectedMaxAmount]);

  useEffect(() => {
    // Update the text values whenever amountRange changes
    const [amount1, amount2] = amountRange;
    setMinAmountText(`$${amount1}`);
    setMaxAmountText(`$${amount2}`);
  }, [amountRange]);

  const [minAmountText, setMinAmountText] = useState(`$${selectedMinAmount}`);
  const [maxAmountText, setMaxAmountText] = useState(`$${selectedMaxAmount}`);

  const handleReset = () => {
    setAmountRange([minAmount, maxAmount]);
  };

  const handleStyle = {
    borderColor: '#2965FB',
    backgroundColor: '#2965FB',
    // borderColor: '#007bff',
    // backgroundColor: '#007bff',
  };

  return (
    <div className='pl-4 pr-8'>
      <div className=' flex flex-row gap-0 items-center'>
        <div className=' w-[14px] h-[14px] -mr-1 rounded-full bg-[#94C3E8]' />
      <Slider
        min={minAmount}
        max={maxAmount}
        value={[selectedMinAmount, selectedMaxAmount]}
        onChange={onAmountChange}
        step={1}
        range
        allowCross={false}
        handleStyle={[handleStyle, handleStyle]} // Custom handle styles
        railStyle={{ backgroundColor: '#94C3E8' }} // Custom rail color 
        reverse={false}
        //   dots // Enable dots
        // dotStyle={true} // Custom dot style
        keyboard={true}
      />
        <div className=' w-[14px] h-[14px] -ml-1 rounded-full bg-[#94C3E8]' />

      </div>
      <div className="slider-tooltips">
        {/* Custom tooltips for the slider handles */}
        <div className="flex pt-4 justify-between">
          {/* <div className="font-hanken-grotesk text-[14px] font-normal slider-tooltip" style={{ left: `${(amountRange[0] / 500) * 100}%`, ...tooltipFormatter(amountRange[0], 0) }}>
            {tooltipValues[0] !== null && `$${formatAmount(amountRange[0])}`}
          </div>
          <div className="font-hanken-grotesk tooltip-text block w-[50%] text-[14px] font-medium slider-tooltip" style={{ left: `${(amountRange[1] / 500) * 100}%`, ...tooltipFormatter(amountRange[1], 1) }}>
            {tooltipValues[1] !== null && `$${formatAmount(amountRange[1])}`}
          </div> */}
          <div className="font-hanken-grotesk tooltip-text block text-[14px] font-medium ml-[-10px]" >${(amountRange[0])}</div>
          <div className="font-hanken-grotesk tooltip-text block text-[14px] font-medium mr-[-21px]">
            ${(amountRange[1]=='500' )? `${amountRange[1]}+` : amountRange[1]}
          </div>
        </div>
      </div>
    </div>

  );
};

export default AmountRangeSlider;
