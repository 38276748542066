import React from 'react';

const Tooltip = ({ steps, onNext, onPrevious, onGotIt, currentStep }) => {
  if (currentStep >= steps.length) return null;

  return (
    <div className="tooltip">
      <div className={`${currentStep > 0 ? 'md:right-[2.5rem] sm:right-[0.1rem] right-[2.1rem]' : 'md:right-[8.5rem] sm:right-[2rem] right-[2rem]'} z-10 font-hanken-grotesk md:w-[150px] sm:w-[150px] w-[150px] leading-4 font-bold absolute top-[4rem] p-[16px] bg-[#2965FB] text-white text-[12px] rounded-xl shadow-lg `}>
        {steps[currentStep]} 
        <div className={` ${currentStep > 0 ? 'top-[-15px] sm:left-[55px] left-[90px]' : 'top-[-15px] sm:left-[55px] left-[42px]'} border-l-[18px] border-l-transparent border-r-[18px] border-r-transparent border-b-[18px] border-b-[#2965FB] absolute`}></div>
        <div className="flex justify-between pt-1">
          {currentStep > 0 && (
            <button className="underline cursor-pointer pt-2 pb-0" onClick={onPrevious}>
              Back
            </button>
          )}
          {currentStep < steps.length - 1 ? (
            <button className="underline cursor-pointer pt-2 pb-0" onClick={onNext}>
              Next
            </button>
          ) : (
            <span className="underline cursor-pointer pt-2 pb-0" onClick={onGotIt}>
              Got it
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
