import React, { useState, useEffect, useRef } from 'react'
// import Card from '../../../CustomComponents/Card';
import DynamicCard from '../../../CustomComponents/DynamicHomeCard';
import { width } from "../../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import Loader from '../../../CustomComponents/Loader';
import CustomTextInput from '../../../CustomComponents/CustomtextInput';
import {
    GENERATE_EXISTING_PHONE_EMAIL_OTP,
    UPDATE_PHONE_NUMBER,
    GENERATE_UPDATE_EMAIL_OTP,
    GENERATE_UPDATE_ADDRESS_OTP,
    GET_USER_PROFILE_V2,
    GET_USER_PROFILE_LANGUGAGE_LIST,
    GET_USER_PROFILE_TIMEZONE_LIST,
    GENERATE_OTP_CHANGE_PIN, STATUS_SUCCESS_CODE,
    OTP_USERS, POST_UPDATE_USER_PROFILE,
    GOOGLE_PLACE_DETAILS_WITH_PLACE_ID,
    GOOGLE_PLACES_AUTO_COMPLETE,
    VERIFY_UPDATE_ADDRESS_OTP,
    SAVINGS_VALIDATE_SSN,
    CREATE_SAVINGS_ACCOUNT,
    GET_CARDHOLDER_CARDS,
    REGISTER_USER,
    CONTACT_US_URL
} from '../../../../network/URLConstants';
import { GetApiHeadersWithBearer, PostRequest, GetRequest, GetApiHeadersWithOutBearer } from '../../../../network/NetworkUtils';
import { displayErrorMessage, displaySuccessMessage, parseGoogleLocationResponse, isEmailValid, validateMobileNumber, formatDateYYYYMMDD, LineBreaksSpan } from '../../../../utils';
import LineBreaks from '../../../../utils';
import { retrieveData } from '../../../../helpers/utils/Utils';
import { personalFormatPhoneNumber } from '../../../../utils';
import HomeLayout from '../../../Layout/HomeLayout';
import HomeHeader from '../../../CustomComponents/HomeHeader';
import CustomDropdown from '../../../CustomComponents/CustomDropDown';
import Custom2FA from '../../../CustomComponents/Custom2FA';
// import DynamicCard from '../../CustomComponents/DynamicCard';
import { LOGIN_SESSION_KEY } from '../../../../Redux/reducers';
import Modal from '../../../CustomComponents/Modal';
import { set, useForm } from 'react-hook-form';
import { fetchConfigarationData } from '../../../../helpers/utils/Utils';
import { type } from '@testing-library/user-event/dist/type';
import CustomAlertModel from '../../../CustomComponents/CustomAlertModel';
// import address_update_Succss from '../../../../assets/images/address_update_Succss.png';
import address_update_Succss from '../../../../assets/images/address-updated.png';
import email_update_success from '../../../../assets/images/email_update_success.png';
import updatedphone_success from '../../../../assets/images/updatedphone_success.png';
import fb from '../../../../assets/images/fb.png';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import { useLocation, useNavigate } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import TwoFactTypeSelctionModal from "../../../CustomComponents/TwoFactTypeSelctionModal";
import ShowSSNPic from '../../../../assets/images/showpassword.png'
import HideSSNPic from '../../../../assets/images/hidepassword.png'
import { yupResolver } from '@hookform/resolvers/yup';
import Arrow from '../../../../assets/images/arrow.png';
import RegisterSuccessModal from '../RegisterSuccessModal';
import transactionErrorIcon from "../../../../assets/images/transfer-error.png"
import pendingfile from '../../../../assets/images/pendingfile.png'
import profileError from '../../../../assets/images/profileError.png'
import Check from '../../../../assets/images/Check.png';
import logFirebaseEvents from '../../../LogFirebaseEvents';



const appConfigData = await fetchConfigarationData();



const FinalRegistrationThroughSSN = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(); // Hook to access translation functions
    const cards = useSelector(state => JSON.parse(state.userCards));
    const [isLoading, setIsLoading] = useState(false);
    const accessTokenToApi = useSelector(state => state.accessToken);
    const personalInfo = useSelector(state => state.signUpPersonelInfo);
    const signUpReferralCode = useSelector(state => state.signUpReferralCode);
    const signUpAccessCode = useSelector(state => state.signUpAccessCode);
    const ssnScreenThrough = useSelector(state => state.ssnScreenThrough);
    
    const savingsTermsConditions = useSelector(state => state.savingsTermsConditions);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    //universities
    const studentEmailAddress = useSelector(state => state.studentEmailAddress);
    const studentUniversityCode = useSelector(state => state.studentUniversityCode);
    const studentUniversityName = useSelector(state => state.studentUniversityName);
    const graduationDate = useSelector(state => state.graduationDate);

    const [showModel, setShowModel] = useState(false);
    const [modelImage, setModelImage] = useState('')
    const selectedItem = cards?.[0]
    const userId = useSelector(state => state.userId);
    const [loading, setLoading] = useState(false)
    const [SSNBackupSecondary, setSSNBackupSecondary] = useState('')

    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none text-black";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title  md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const InputStyles = "font-hanken-grotesk  text-secondary-2 px-4 bg-text-btn placeholder-kudzu-blue lg:text-[16px] md:text-[15px] text-[14px] placeholder:font-semibold font-medium focus:outline-none w-full h-[60px]";
    const SubHeadingStyles = "w-[90%] md:w-full font-serif-pro modal-title sm:text-[18px] lg:text-[32px] md:text-[28px] text-[25px] sm:leading-[24px] lg:leading-[38px] md:leading-[33px] leading-[31px] font-normal text-secondary-3";
    const FailSubHeaderMsgStyles = "md:w-[317px] sm:w-[257px] font-hanken-grotesk modal-title  md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer";

    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const [isBorderNone, setisBorderNone] = useState(true);
    const [showErrorImg, setshowErrorImg] = useState(false);
    const [isCursorVisible, setCursorVisibility] = useState(false);
    const [showSSN, setShowSSN] = useState(true);
    const ssnRef = React.createRef();
    const [SSNoError, setSSNoError] = useState('');
    const [ssnBackup, setSSnBackUp] = useState('');
    const [isSSNValid, setIsSSNValid] = useState(false);
    const [isSSNTouched, setIsSSNTouched] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [infoMessage, setinfoMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const checkbox1Ref = useRef(null);
    const button1Ref = useRef(null);
    const [isRegisterSucessModalOpen, setRegisterSucessModalModalOpen] = useState(false);
    const [cipStatus, setCipStatus] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);



    // useEffect(() => {
    //     const handleKeyPress = (event) => {
    //         if (event.key === 'Enter') {
    //             const elements = [checkbox1Ref, button1Ref, ssnRef];
    //             const isAnyElementFocused = elements.some(ref => ref.current === document.activeElement);
    //             if (!isAnyElementFocused) { handleConfirm() }
    //         }
    //     };

    //     document.addEventListener('keydown', handleKeyPress);
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, []);

    const openRegisterSuccessModalOpen = () => {
        setRegisterSucessModalModalOpen(true);

    };

    const closeRegisterSuccessModal = () => {
        setRegisterSucessModalModalOpen(false);
    };



    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        address: '',
        apt_etc: '',
        ssn_no: ''
    });
    const [ssn, setSSN] = useState('')





    useEffect(() => {
        handleSSNValue(ssnBackup)
    }, [showSSN])
    function formatValue(value) {
        const formattedValue = value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d{2})(\d{1,2})/, '$1 - $2 - $3');

        return formattedValue;
    }

    function formatSecuredValue(value) {
        const securedValue = value
            .replace(/[^0-9•]/g, '')
            .replace(/((?:\d|.){3})((?:\d|.){2})((?:\d|.){1,2})/, '$1 - $2 - $3')
            .replace(/[0-9]/g, '•');

        return securedValue;
    }
    const validationSchema = yup.object().shape({
        ssn_no: yup.string().required('Social Security Number (SSN) is required')
    });
    const footerContent = () => {
        return (
            <div>
            </div>
        )

    }



    const onAcptBtnClick = async () => {
        setShowModel(false)
        if (btn1Text == t("join_waitlist")) {
            window.location.replace('https://www.kudzumoney.com')
            return null;

        }

        else if (cipStatus == 'Refer' || cipStatus == 'Success' || subHeaderMsg == t('please_contact')) {
            window.location.replace('/');
        }


    }
    const onCancelBtnClick = () => {
        setShowModel(false)
        navigate('/signup');
    }
    const closeModel = () => {
        setShowModel(false);
        window.location.replace('/');
    }

    const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage) => {
        setShowModel(showModel)
        setHighlightMsg(highlightMsg)
        setheaderMsg(headerMsg)
        setSubHeaderMsg(subHeaderMsg)
        setBtn1Exists(btn1Exists)
        setBtn2Exists(btn2Exists)
        setBtn1Text(btn1Text)
        setBtn2Text(btn2Text)
        setBtn1TypeAcpt(btn1TypeAcpt)
        setModelImage(modelImage)
    }

    useEffect(() => { console.log('87451', ssnScreenThrough) }, [ssnScreenThrough])


    useKeyDown(() => {


    }, ["Enter"]);

    const finalApiHit = async () => {
        debugger;
        const deviceId = await retrieveData('updatedDeviceId');
        let headers = await GetApiHeadersWithOutBearer(deviceId);
        setIsLoading(true);

        let finalHitObj = personalInfo;
        finalHitObj.ssn = ssnBackup 
        finalHitObj.sEmail = studentEmailAddress 
        finalHitObj.universityCode = studentUniversityCode 
        finalHitObj.university = studentUniversityName 
        finalHitObj.graduationDate = graduationDate 
        if (signUpReferralCode) finalHitObj.referralCode = signUpReferralCode;
        if (signUpAccessCode) finalHitObj.accessCode = signUpAccessCode;

        var response = await PostRequest(REGISTER_USER(userId), finalHitObj, headers);
        let responseObj = JSON.parse(response);
        if (responseObj.ResponseCode === STATUS_SUCCESS_CODE) {
            global.inviteCode = null;
            setIsLoading(false);
            setCipStatus(responseObj.Response.cipStatus);
            if (responseObj.Response.cipStatus == 'Success') {
                setIsRegistered(true);
                openRegisterSuccessModalOpen();
            } else if (responseObj.Response.cipStatus == 'Refer') {
                setIsRegistered(true);
                showAlertModal(true, t('thanks'), t('reviewing_profile'), t('notify_via_email'), true, false, t('login'), "", false, pendingfile);
                //  openRegisterSuccessModalOpen();
            }

        } else if (responseObj.ResponseCode === 4122) {
            setIsLoading(false);
            var arrMessages = responseObj.Messages;
            var messageObject = arrMessages[0];
            const message = messageObject.MessageOrField;
            if (message == 'AccountEnrollmentFailed') {
                setIsRegistered(true);
                setErrorMessage(message);
                showAlertModal(true, t('we_are_sorry'), t('unable_to_approve'), t('please_contact'), true, false, t('login'), '', false, profileError);
            } else {
                const displayText = messageObject.DisplayText;
                showAlertModal(true, t('oops_exclamation'), displayText, "", true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
            }
        }
        else if (responseObj.ResponseCode === 4120) {
            setIsLoading(false);
            var arrMessages = responseObj.Messages;
            var messageObject = arrMessages[0];
            const message = messageObject.MessageOrField;
            if (message == 'SignUpFailed') {
                const displayText = messageObject.DisplayText;
                // setIsRegistered(true);
                setErrorMessage(message);
                showAlertModal(true, t('oops_exclamation'), displayText, t('please_join'), true, false, t('join_waitlist'), '', true, transactionErrorIcon);
            } else {
                const displayText = messageObject.DisplayText;
                showAlertModal(true, t('oops_exclamation'), displayText, "", true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
            }
        }

        else {
            setIsLoading(false);
            var arrMessages = responseObj.Messages;
            if (arrMessages.length > 0) {
                var messageObject = arrMessages[0];
                const message = messageObject.DisplayText;
                // const formattedMessage = "<span className='font-hanken-grotesk text-md leading-7 text-oops_error pr-2'>Oops! </span>" + message;
                // alert(message);
                setIsRegistered(true);
                setErrorMessage(message);
                showAlertModal(true, t('oops_exclamation'), message, "", true, true, t('auth_tryAgain'), t('Cancel'), true, transactionErrorIcon);
            }
        }
    };

    const toggleCursor = () => {
        setCursorVisibility(!isCursorVisible);
    };
    const togglePasswordVisibility = () => {
        setShowSSN(!showSSN);
    };
    const { register, formState: { errors }, setError, setValue, clearErrors, getValues } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
    });
    const handleSSNChange = (event) => {
        //debugger;
        clearErrors();
        const ssnNumber = event.target.value;
        // Remove all non-digit characters from the input
        const ssnNum = ssnNumber.replace(/[*-]/g, '').replace(/\D/g, '');
        // const ssnNum = ssnNumber.length != 9 ? ssnNumber.replace(/[*-]/g, '').replace(/\D/g, '') : ssnNumber;
        // const value = inputValue.replace(/\D/g, '')
        setSSnBackUp(ssnNum)
        handleSSNValue(ssnNum)
        clearErrors();
        setinfoMessage('');
        setIsSSNTouched(true)
        if (event.target.value.length < 10) { setSSNBackupSecondary(event.target.value.replace(' ', "").replace('-', "").replace(' ', "")) }
        // Remove dashes from the input 
        if (ssnNum.length === 9) {
            // setError('ssn_no', { type: 'manual', message: 'Social Security Number (SSN) should be 9 digits' });
            //return;
            clearErrors();
            setinfoMessage('');
            handleInputChange(event);
            setIsSSNValid(true)
            // validateSSN(ssnNum)
            // Update the form values
            // ValidateSSN(ssnNo, formValues); // Pass the SSN value and form values to the ValidateSSN function
        } else { 
            setIsSSNValid(false)
        }
    };
    const handleInputChange = (event) => {
        clearErrors();

        setinfoMessage('');
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };
    function handleSSNValue(value) {
        const formatted = showSSN ? formatSecuredValue(value) : formatValue(value)
        setSSN(formatted)
    }
    const handleConfirm = () => {
        logFirebaseEvents("SSN_Screen","Continue_Clicked", "Continue_Clicked","", "");
        if (ssnBackup == '' || !isSSNValid ) {
            // setError("ssn_no", "Please enter valid SSN no.")
            setError('ssn_no', { type: 'manual', message: 'Please enter valid SSN.' });

            // setinfoMessage(t('please_enter_valid_ssn')); 
            setErrorMessage(t(''));
            return;
        }
        // else if (ssnBackup != '' && (savingsTermsConditions == false)) { setinfoMessage(''); setErrorMessage(t(t('please_review_certify_under_penalty_of_perjury'))); }
        else if (isSSNValid) {

            if (termsAccepted && personalInfo?.CreateSavings) { finalApiHit() }
            else if (!termsAccepted && !personalInfo?.CreateSavings) { finalApiHit() }
            else {
                setErrorMessage(t('please_review_certify_under_penalty_of_perjury'))
            }
        }
        // else if () {}

    }
    const handlePaste = (event) => {
        event.preventDefault();
        return false;
    };

    return (
        <>
            <HomeLayout>

                <div className='sm:flex hidden'>
                    <HomeHeader heading={<p className={`sm:mt-0 mt-8 text-white md:text-[32px] sm:text-[18px] text-[27px] font-[400] md:leading-[38px] sm:leading-[24px] leading-[31px] font-family: Hanken Grotesk`}>{!personalInfo?.CreateSavings ? t('almost_done_title') : t('finally_comma')} <span className={`text-[#94C3E8] md:text-[32px] sm:text-[18px] text-[27px] font-[400] md:leading-[38px] sm:leading-[24px] leading-[31px] font-family: Hanken Grotesk`}>{personalInfo?.CreateSavings ? (t('please_confirm_your_social_security_number')) : t('enter_ssn_subheadingTitle')}</span></p>} isHeaderCaption={true} />
                </div>

                <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
                    <DynamicCard
                        header={false}
                        footer={false}
                        CardRadius={true}
                        HeaderRadius={true}
                        mblCardRadius={true}
                        isWhite={false}
                        cardWidth={width.homeCard}>
                        {/* style={{ display: 'flex', flexDirection: 'column', minHeight: '560px', width: '75%', justifyContent: 'center', alignItems: 'start', alignSelf: 'center' }}  */}
                        <div className='sm:d-flex flex-col sm:min-h-[560px] sm:w-[75%] sm:justify-center sm:items-center sm:self-center mx-auto mt-10'>
                            <div className='flex flex-col justify-between' style={{ minHeight: '560px', width: "100%" }}>


                                <div className="sm:hidden block lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] lg:text-[32px] md:text-[28px]
                                 sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[32px] font-serif-pro w-[260px] 
                                 h-auto mb-[2rem] sm:px-0 px-4">
                                    <p className='text-[#2965FB]'>{!personalInfo?.CreateSavings ? t('great_exclamation') : t('finally_comma')}
                                        <span className='text-[#262B3A]'> {personalInfo?.CreateSavings ? (t('please_confirm_your_social_security_number')) : t('enter_ssn_subheadingTitle')}</span>
                                    </p>
                                </div>

                                <div className="sm:block hidden lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
                     lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-serif-pro w-full 
                        sm:mx-0 mx-4"> {t('social_security_number')}</div>





                                {/* <div className='border-t-[2.5px] w-full border-[#A3CEBB] 
                        flex justify-center sm:mx-0 mx-4'></div> */}
                                <div className='flex flex-row w-full'>
                                    <div className="sm:w-full md:w-4/5 lg:w-4/5 mt-[4rem] w-full"
                                        onFocus={() => { toggleCursor() }}
                                        onBlur={() => { toggleCursor() }}
                                    >
                                        <div className="flex items-center relative">
                                            <span
                                                type="button"
                                                className="text-gray-500 absolute right-4 top-5"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showSSN ? <img src={ShowSSNPic} className='w-[20px] h-[20px] cursor-pointer' /> : <img src={HideSSNPic} className='w-[20px] h-[20px] cursor-pointer' />}
                                            </span>
                                            <input
                                                ref={ssnRef}
                                                onPaste={handlePaste}
                                                id={'ssn_no'}
                                                {...register('ssn_no')}
                                                value={SSNBackupSecondary}
                                                placeholder={'Social Security Number (SSN)'}
                                                className={`${InputStyles} ${showSSN ? 'password-input' : ''}`}
                                                autoComplete="off"
                                                onChange={handleSSNChange}
                                                maxLength={9}
                                                style={{ color: 'transparent', caretColor: 'transparent' }}
                                                
                                            // value={ssn}
                                            // onKeyUp={handleInputChange}
                                            >
                                            </input>
                                        </div>
                                        <div className='flex z-[999] opacity-100 relative top-[-51px] h-[60px] w-[80%] 
            bg-transparent items-center'

                                            style={{ position: 'relative', top: -48 }}
                                            onClick={() => {
                                                const input = document.getElementById("ssn_no");
                                                input.focus()
                                            }}>
                                            <p className='h-[50px] no-underline ml-[15px] top-[10] text-[#2965FB]' >{ssn}{isCursorVisible && <span style={{ display: 'inline-block', animation: isCursorVisible ? 'blink 0.8s step-end infinite' : 'none', verticalAlign: 'text-bottom', color: '#2965FB', fontWeight: '200', fontSize: '15px' }}>|</span>}</p>
                                        </div>

                                    </div>
                                    <style>{`@keyframes blink {0%, 100% {opacity: 1;}50% {opacity: 0;}}`}</style>
                                    <div className="w-[10%] ml-4 flex items-center align-center">{(isSSNValid && isSSNTouched) && <img className='w-[15px] h-[15px]' src={Check} alt="Check" />}</div>
                                </div>

                                {errors.ssn_no &&
                                    <div className={` -mt-16 sm:-mt-32 md:-mt-16 mb-2 pb-[3rem]`}>
                                        {displayErrorMessage(errors.ssn_no.message)}
                                    </div>
                                }
                                {personalInfo?.CreateSavings &&
                                    <div>
                                        <div class="flex lg:mb-[2rem] md:mb-[2rem] mb-[2rem]" >
                                            <input
                                                id="deposit-savings-checkbox"
                                                ref={checkbox1Ref}
                                                type="checkbox"
                                                class="checkbox-round md:mt-[6px] mt-[4px]"
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        event.preventDefault();
                                                        setTermsAccepted(!termsAccepted); if (!termsAccepted) { setErrorMessage(''); button1Ref.current.focus(); } }
                                                }}
                                                value=""
                                                tabIndex="0"
                                                checked={termsAccepted}
                                                onChange={(event) => { setTermsAccepted(!termsAccepted); if (!termsAccepted) { setErrorMessage(''); button1Ref.current.focus(); }; }}
                                            />
                                            <label for="deposit-savings-checkbox" class="font-hanken-grotesk ml-4 sm:ml-2 md:ml-4 md:text-sm sm:text-[12px] text-[14px] font-medium">{t('under_penalty_of_perjury_iwill_clarify')}
                                            </label>
                                        </div>
                                        <p className='ml-14 md:text-sm sm:text-[12px] text-[14px] font-hanken-grotesk font-medium ' dangerouslySetInnerHTML={{ __html: t('under_penalty_of_perjury_ssn').replace(/\n/g, "<br />") }}></p>
                                        {/* <style>
                                            {`p br {display: block;margin-bottom: 100px;}`}
                                        </style> */}
                                    </div>
                                }
                                {errorMessage &&
                                    <div className={`mt-2 mb-2 pb-[2rem]`}>
                                        {displayErrorMessage(errorMessage)}
                                    </div>
                                }
                                {personalInfo?.CreateSavings &&
                                    <p className="lg:my-[4rem] mt-20 md:my-[4rem] my-[4rem] md:text-sm sm:text-[12px] text-[14px] font-hanken-grotesk  sm:mx-0 mx-4 sm:font-medium font-[600] sm:w-auto w-[290px]">
                                        {t('by_tapping_continue_i_understand')}
                                    </p>
                                }
                                <div className='lg:my-[4rem] md:my-[4rem] my-[4rem] flex flex-row justify-end lg:mt-20 md:mt-16 sm:mt-6 sm:mx-0 mx-4 lg:mb-10:mb-6 mb-5'>
                                    <button
                                        type="button"
                                        // autoFocus
                                        ref={button1Ref}
                                        // tabIndex={1} 
                                        className={`font-hanken-grotesk sm:font-bold font-[600]
                                lg:text-[18px] md:text-[17px] sm:text-[14px] text-[16px] ${isSSNValid ? 'text-[#2965FB]' : 'text-[#94C3E8]'}
                                 cursor-pointer flex items-center`}
                                        onClick={() => { handleConfirm() }}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.preventDefault();
                                                handleConfirm()
                                            }
                                        }}
                                    >
                                        {t('continue')}<img
                                            src={Arrow}
                                            className="md:w-[14px] sm:w-[11px] md:h-[14px] sm:h-[11px] w-[14px] h-[14px] ml-1"
                                            style={{ opacity: isSSNValid ? 1 : 0.5 }}
                                            alt="Arrow" />
                                    </button>
                                </div>
                            </div>
                        </div>

                    </DynamicCard>

                </div>
                <Modal
                    isOpen={isRegisterSucessModalOpen}
                    onClose={closeRegisterSuccessModal}
                    isLogo={false}
                    title={null}
                    subtitle={null}
                    content={<div><RegisterSuccessModal subtitle={personalInfo?.CreateSavings == true ? t('spending_saving_account_ready') : t('spending_account_ready')} /></div>}
                />
                <CustomAlertModel
                    isOpen={showModel}
                    onClose={closeModel}
                    image={modelImage}

                    highlightMsg={headerMsg == t('reviewing_profile') ?
                        <p className='font-serif-pro modal-title  sm:flex block flex-col
                lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px]
                 sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none'>{highlightMsg}

                            {headerMsg == t('reviewing_profile') ? <span className='md:w-[287px] sm:w-[200px] font-serif-pro modal-title
                lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px]
                leading-[37px] font-normal cursor-pointer pointer-events-none text-[#262B3A]'> {headerMsg}</span> : <span className={HeaderMsgStyles}>
                                {headerMsg}</span>} </p> :
                        <p className={HighlightMsgStyles}>{highlightMsg}</p>}


                    HeaderMsg={headerMsg != t('reviewing_profile') && <p className={`${HeaderMsgStyles} md:w-[357px] sm:w-[335px]`}>{headerMsg}</p>}

                    subHeaderMsg={
                        <>
                            {subHeaderMsg == t('please_contact') ?
                                <p className={FailSubHeaderMsgStyles}>{t("please_contact")}
                                    {<button onClick={() => {
                                        window.open(CONTACT_US_URL, '_blank');
                                    }} className='font-hanken-grotesk lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] text-[#2965FB] font-semibold cursor-pointer'>
                                        {t("signup_customer_care")}
                                    </button>}
                                    {t("for_access")}
                                </p>
                                :

                                subHeaderMsg == t('please_join') ?
                                    <p className={FailSubHeaderMsgStyles}>{t("please_join")}
                                        {<button onClick={() => {
                                            window.open(CONTACT_US_URL, '_blank');
                                        }} className='font-hanken-grotesk lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] text-[#2965FB] font-semibold cursor-pointer'>
                                            {t("signup_customer_care")}
                                        </button>}
                                        {t("for_access")}
                                    </p>
                                    :
                                    <p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>

                            }
                        </>
                    }

                    btn1Exists={btn1Exists}
                    btn2Exists={btn2Exists}
                    btn1Text={btn1Text} btn2Text={btn2Text}
                    btn1TypeAcpt={btn1TypeAcpt}
                    verificationFailure={() => { }}
                    footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
                    onAcptBtnClick={onAcptBtnClick}
                    onCancelBtnClick={onCancelBtnClick}
                />
                {isLoading && <Loader />}
            </HomeLayout>
        </>
    )
}

export default FinalRegistrationThroughSSN;
