import React, { useState, useEffect } from 'react'
// import Card from '../../CustomComponents/Card';
import DynamicCard from '../../CustomComponents/DynamicHomeCard';
import { width } from "../../CustomComponents/DynamicHomeCard/DynamicHomeCardStyles";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../CustomComponents/Loader';
import CustomTextInput from '../../CustomComponents/CustomtextInput';
import { UPDATE_CARDNAME, STATUS_SUCCESS_CODE, CAN_CREATE_SAVINGS_ACC } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, GetRequest, PostRequest } from '../../../network/NetworkUtils';
import { displayErrorMessage, displaySuccessMessage, MoreServices } from '../../../utils';
import { retrieveData } from '../../../helpers/utils/Utils';
import LineBreaks from '../../../utils';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import checkIcon from '../../../assets/images/Error.png';
import error_img from '../../../assets/images/error_3x.png'
import check_img from '../../../assets/images/check_3x.png'
import beneficiarysaving from '../../../assets/images/beneficiarysaving.png';
import ClimbImg from '../../../assets/images/illo-climb.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import FooterContent from '../../CustomComponents/Footer';
import logFirebaseEvents from '../../LogFirebaseEvents';


// import DynamicCard from '../../../../CustomComponents/DynamicCard';

const ManageAccounts = ({ transactionDatas, onViewAllClick, viewAll }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook to access translation functions
  const cards = useSelector(state => JSON.parse(state.userCards));
  const savingsCardAvailable = useSelector(state => state.savingsAccontAvailable);
  const accessTokenToApi = useSelector(state => state.accessToken);
  const [editClicked, setEditClicked] = useState(false);
  const [showCheckImage, setshowCheckImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectedItem = cards?.[0]
  const [newName, setNewName] = useState((selectedItem != undefined && selectedItem != null) ? selectedItem.NickName : "");

  const [loading, setLoading] = useState(false)
  const [infoMessage, setinfoMessage] = useState('');
  const [responseCode, setResponseCode] = useState('');
  const HighlightMsgStyles = "sm:w-auto w-[253px] font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
  const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[31px] font-normal cursor-pointer pointer-events-none";
  const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
  const [highlightMsg, setHighlightMsg] = useState('')
  const [headerMsg, setheaderMsg] = useState('')
  const [subHeaderMsg, setSubHeaderMsg] = useState('')
  const [btn1Exists, setBtn1Exists] = useState(false)
  const [btn2Exists, setBtn2Exists] = useState(false)
  const [btn1Text, setBtn1Text] = useState(false)
  const [btn2Text, setBtn2Text] = useState('')
  const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
  const [footerMsg, setFooterMsg] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [modelImage, setModelImage] = useState('')
  const [savingsTheme, setSavingsTheme] = useState(false);
  const [isBorderNone, setisBorderNone] = useState(true);
  const [isSubFooter, setIsSubFooter] = useState(false);
  const moreServicesArray = [
    { name: 'Configure_Notifications', actionType: '', path: '/Settings/notificationConfigure' },
    { name: 'Manage_External_Accounts', actionType: '', path: '/Settings/ManageExternalAccounts' }
  ];
  const subObjectsArray = cards?.map(card => {
    return {
      AccountTypeName: card.AccountTypeName,
      CardKey: card.CardKey,
      OpenDate: card.ApplicationDate
    };
  });
  if (savingsCardAvailable == false) {
    const staticObject = {
      AccountTypeName: t('menu_savings'),
      CardKey: '',
      OpenDate: 'empty'
    };
    // subObjectsArray?.push(staticObject);
    subObjectsArray?.splice(1, 0, staticObject);
  }


  const handleRevertClick = () => {
    setinfoMessage('');
    setEditClicked(false);
  };


  const canCreateSavingsAccount = async () => {
    const deviceId = await retrieveData('updatedDeviceId');
    const headers = await GetApiHeadersWithBearer(deviceId, accessTokenToApi);
    setIsLoading(true)

    let finalUrl = CAN_CREATE_SAVINGS_ACC(cards[0].CardKey)
    var response = await GetRequest(finalUrl, headers)
    var responseObj = JSON.parse(response)
    setIsLoading(false)
    if (responseObj.ResponseCode == STATUS_SUCCESS_CODE) {
        const canCreateNew = responseObj.Response.canCreateNew
        const eligibleDate = moment(responseObj.Response.eligibleDate).format('MM/DD/YYYY')
        if (canCreateNew) {
          enrollSavingsAccount()
          
        } else {
            showAlertModal(true, t("oh_no"), t('unable_to_open'), `${t('u_will_be_eligible')} ${eligibleDate}. `, true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false, true)
        }
    } else {
        setTimeout(() => {
            var arrMessages = responseObj.Messages;
            var message = arrMessages[0];
            var displayText = message.DisplayText;
            showAlertModal(true, t("oh_no"), displayText, '', true, false, t('home'), '', true, require('../../../assets/images/illo-report.png'), false, false)

            // setError(displayText)
        }, 500);
    }
}

const subComponent = () => {
  return (
      <div className=' flex justify-start items-start'>
          <p className="font-hanken-grotesk font-semibold sm:font-[500] sm:text-[14px] md:text-[20px] text-[16px] md:leading-[30px] leading-[24px]">{t("pl_contact_customer")}<span className="text-[#2965FB] cursor-pointer" onClick={
              () => window.open("https://www.kudzumoney.com/contact-us", '_blank')}>{t("support_email")}</span>{t("for_access")}</p>
      </div>
  )
}

  const enrollSavingsAccount = () => {

    const savingsNewPayload = { savingsNewClicked: true };
    dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
    showAlertModal(true, '', t('set_aside_money_for_the_things_that_matter'), t('add_savings_account_subTitle'), true, true, t('enroll'), t('may_be_later'), true, beneficiarysaving, true, true);
  }



  useEffect(() => {
  }, [cards, dispatch]);

  const closeCustomAlertModal = () => {

    setShowModel(false);
  }

  const onAcptBtnClick = async () => {
    setShowModel(false)
    if (btn1Text == t("enroll")) {
      navigate('/SetupSavingsAccount')
      dispatch({ type: 'SSN_SCREEN_THROUGH', payload: { ssnScreenThrough: t('account_services_savings_enrollment') } });
    }else if (btn1Text == t('home')) {
      navigate('/home')
    }

  }
  const onCancelBtnClick = () => {
    setShowModel(false)
    showAlertModal(true, '', t('savings_no_problem_title'), t('via_account_services_title'), true, false, t('okay_got_it'), '', true, beneficiarysaving, true, true);
  }
  const showAlertModal = (showModel, highlightMsg, headerMsg, subHeaderMsg, btn1Exists, btn2Exists, btn1Text, btn2Text, btn1TypeAcpt, modelImage, savingsTheme, isBorderNone, isSubFooter) => {
    setShowModel(showModel)
    setHighlightMsg(highlightMsg)
    setheaderMsg(headerMsg)
    setSubHeaderMsg(subHeaderMsg)
    setBtn1Exists(btn1Exists)
    setBtn2Exists(btn2Exists)
    setBtn1Text(btn1Text)
    setBtn2Text(btn2Text)
    setBtn1TypeAcpt(btn1TypeAcpt)
    setModelImage(modelImage)
    setSavingsTheme(savingsTheme)
    setisBorderNone(isBorderNone)
    setIsSubFooter(isSubFooter)
  }

  const footerContent = () => {
    return (
      < FooterContent
        highlightText={t("climb_heading")}
        subHeaderText={t("climb_subheading")} image={ClimbImg}
        buttonEnabled={true} buttonText={t("climb_btn")} isSpace={true} footerBtnAction={() => { navigate('/Climb');logFirebaseEvents("Manage_Account", "Explore_Climb_Clicked", "Explore_Climb_Clicked", "",""); }} borderDirection={cards?.[0]?.AllowPayPerks ? (cards?.length % 2 == 0) ? 'odd' : 'even' : (cards?.length % 2 == 0) ? 'even' : 'odd'} />
    )

  }
  const enrollSavingsFooterContent = () => {
    return (
      < FooterContent
        cardHeight={'200px'}
        backgroundColor={'#D3D3D3'}
        btnBgColor={'#30BC89'}
        btnTextColor={'#FFFFFF'}
        isSubheadingwidth={true}
        highlightTextColor={'#0C4437'}
        subHeaderTextColor={'#0C4437'}
        highlightText={t("grow_urMoney_withSavings")}
        subHeaderText={LineBreaks(t("set_aside_funds_savings"))}
        image={beneficiarysaving}
        buttonEnabled={true}
        buttonText={t("enroll")} isSpace={true}
        type={'climbFooterHome'}
        footerBtnAction={() => {
          const savingsNewPayload = { savingsNewClicked: true };
          dispatch({ type: 'SAVINSGS_DISABLED', payload: savingsNewPayload });
          showAlertModal(true, '', t('set_aside_money_for_the_things_that_matter'), t('add_savings_account_subTitle'), true, true, t('enroll'), t('may_be_later'), true, beneficiarysaving, true, true);
        }}
        borderDirection={cards?.[0]?.AllowPayPerks ? (cards?.length % 2 == 0) ? 'odd' : 'even' : (cards?.length % 2 == 0) ? 'even' : 'odd'}
      />
    )
  }

  return (

    <HomeLayout>
      <HomeHeader heading={LineBreaks(t("here_kudzu_accounts"))} isHeaderCaption={true} />
      <div className='flex flex-col lg:w-[860px] md:w-[860px] sm:w-[464px] w-[100%]
       animate__animated animate__fadeInRight '>
        <DynamicCard
          header={false}
          footer={false}
          CardRadius={true}
          HeaderRadius={true}
          isWhite={true}
          cardWidth={width.homeCard}
        >

          <div className="lg:my-[2rem] md:my-[1.5rem] my-[1.5rem] font-[500] 
 lg:text-[26px] md:text-[22px] 
 sm:text-[20px] text-[20px]
 leading-[36px] font-hanken-grotesk sm:mx-0 mx-4">
            {t('manage_accounts')}
          </div>

          <div className='border-t-[2.5px] border-[#A3CEBB]
                     flex justify-center flex-col sm:mx-0 mx-4'>

            {subObjectsArray.map((item, index) => (
              <div className='md:h-[180px] sm:h-[135px] h-[155px] border-b-[1px] 
                                     flex justify-center border-[#D1E7DD] items-center sm:mx-0 mx-4'>
                <div className='sm:w-[83%] w-full'>
                  <div key={index}>
                    <p className='font-hanken-grotesk text-[#262B3A] 
                                        uppercase lg:text-[13px] md:text-[12px] 
                                        sm:text-[11px] text-[13px]  font-[600] tracking-[2px] md:pb-8 pb-5 opacity-[50%] '>
                      {t("account_name").toLocaleUpperCase()}</p>
                    <div className='flex flex-row justify-between sm:w-auto w-full'>

                      <div className='flex flex-row sm:items-start sm:w-auto w-full items-center sm:pb-0 pb-4
                                             sm:justify-self-auto justify-between'>

                        <div>
                          <p className="font-[700] md:text-[16px] sm:text-[10px] text-[14px] mr-2 
                                             cursor-pointer pointer-events-none">
                            {item.AccountTypeName.replace(" Account", "")}
                            {item.CardKey != '' && <span className={`md:text-[12px] sm:text-[11px] before-dots font-[700]`}>{item.CardKey}</span>}
                          </p>

                          {(item.ApplicationDate != undefined && item.ApplicationDate != null && item.ApplicationDate != '9999-12-31' && item.CardKey != 'empty') && <p className='my-3 font-hanken-grotesk font-medium text-[12px] leading-4'>{t("account_opend")} <span>{moment(item.ApplicationDate).format('MM/DD/YYYY')}</span> </p>}
                          {item.OpenDate == 'empty' && <p className='my-3 font-hanken-grotesk font-medium text-[12px] leading-4'>
                            {t("open_savings_today")} </p>}

                        </div>
                        {item.CardKey != '' && <img src={check_img} className="lg:w-[20px] lg:h-[20px] md:w-[16px] md:h-[16px] sm:w-[13px] sm:h-[13px] w-[15px] h-[15px] sm:ml-5" />}

                      </div>
                      {item.CardKey == '' &&
                        <div
                          className={`bg-[#FFFFF] flex items-center text-[#2965FB] border-[#2965FB] border-[2px]
                   justify-center text-center w-auto px-[15px] md:h-[25px] sm:h-[20px] h-[20px] rounded-[89px]
                   lg:text-[16px] md:text-[12px] sm:text-[12px] text-[12px] font-[400] font-hanken-grotesk cursor-pointer`}
                          onClick={canCreateSavingsAccount}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              canCreateSavingsAccount()
                            }
                          }}
                          tabIndex={0} // Set tabIndex here for the button
                        >
                          Enroll
                        </div>
                      }

                    </div>

                    {/* {(item.ApplicationDate != undefined && item.ApplicationDate != null  && item.ApplicationDate != '9999-12-31' && item.CardKey != 'empty') && <p className='my-3 font-hanken-grotesk font-medium text-[12px] leading-4'>{t("account_opend")} <span>{moment(item.OpenDate).format('MM/DD/YYYY')}</span> </p>}   */}


                  </div>
                </div>
              </div>
            ))}
            <div className="border-t-[2.5px] border-[#D1E7DD] flex flex-col justify-center sm:mx-0 mx-4">
              <MoreServices actionsArray={moreServicesArray} isMoresetting={true} />
            </div>


          </div>




          {loading && <Loader />}
        </DynamicCard>
        {savingsCardAvailable == false ? enrollSavingsFooterContent() : footerContent()}
      </div>
      <CustomAlertModel isOpen={showModel}
        modalBg={!isSubFooter && 'bg-[#E4F1EB]'}
        btn1BorderColor={!isSubFooter && '#30BC89'}
        btn1TextColor={!isSubFooter && '#FEFAEE'}
        btn1BrExist={!isSubFooter && true}
        btn2TextColor={!isSubFooter && '#559C7E'}
        btn1BgColor={!isSubFooter && '#30BC89'}
        onClose={closeCustomAlertModal}
        image={modelImage}
        highlightMsg={<p className={`${HighlightMsgStyles} sm:flex block flex-col`}>{highlightMsg}
          <span className={`${HeaderMsgStyles} ${savingsTheme ? 'text-[#0C4437]' : 'text-[#0C4437]'}`}> {headerMsg}</span></p>}
        subHeaderMsg={<p className={SubHeaderMsgStyles}>{subHeaderMsg}</p>}
        btn1Exists={btn1Exists}
        btn2Exists={btn2Exists}
        btn1Text={btn1Text} btn2Text={btn2Text}
        btn1TypeAcpt={btn1TypeAcpt}
        verificationFailure={() => { }}
        footerMsg={<p className={SubHeaderMsgStyles}>{footerMsg}</p>}
        onAcptBtnClick={onAcptBtnClick}
        onCancelBtnClick={onCancelBtnClick}
        isBorderNone={isBorderNone}
        subFooter={isSubFooter && subComponent()}
      />
         {isLoading && <Loader />}
    </HomeLayout>


  )
}

export default ManageAccounts;
