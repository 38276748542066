import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loader from '../../CustomComponents/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { withOidcSecure } from '@axa-fr/react-oidc';
import HomeLayout from '../../Layout/HomeLayout';
import HomeHeader from '../../CustomComponents/HomeHeader';
import { useKeyDown } from '../../../hooks/useKeyDown';
import lock_card_on from '../../../assets/images/lock_card_on.png'
import Mbllock_card_off from '../../../assets/images/mbllock.png'
import lock_card_off from '../../../assets/images/card_service_lock.png'
import kudzu_green_card from '../../../assets/images/kudzu_green_card.png'
import kudzu_blue_card from '../../../assets/images/kudzu_blue_card.png'
import { LOCK_CARD_API, STATUS_SUCCESS_CODE, UNLOCK_CARD_API } from '../../../network/URLConstants';
import { GetApiHeadersWithBearer, PostRequest } from '../../../network/NetworkUtils';
import CustomAlertModel from '../../CustomComponents/CustomAlertModel';
import { retrieveData } from '../../../helpers/utils/Utils';
import RightArrow from '../../../assets/images/right_dropdown_arrow.png';
import Downarrow from '../../../assets/images/white_down_arrow.png';
import UpArrow from '../../../assets/images/upArrow.png';
import Mobilelockon from '../../../assets/images/mobilelockon.png';


export const STATUS_CODE = {
    INA: 'INA'
};

export const SUBSTATUS_CODE = {
    ACP: 'ACP',
    LST: 'LST',
    STL: 'STL',
    SUS: 'SUS'
};
//export const REPORT_LOST = 1
const REPLACE_CARD = 2
export const LOCK_CARD = 3
export const UNLOCK_CARD = 6
//export const REPORT_STOLEN = 7
export const SAVINGS_CARD = 32;




const CardMenu = ({ handleCardAction }) => {

    const { t } = useTranslation(); // Hook to access translation functions
    const [height, setHeight] = useState(0);
    const cards = useSelector(state => JSON.parse(state.userCards));
    const accessTokenToApi = useSelector(state => state.accessToken);
    const twoFactAuthenticationDone = useSelector(state => state.twoFactAuthenticationDone);
    const [generateBtnClicked, setGenerateBtnClicked] = useState(true); //Routing Number
    const [isOTPVerifyModalOpen, setIsOTPVerifyModalOpen] = useState(false);
    const [isMobileVerificationModalOpen, setisMobileVerificationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const HighlightMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal text-[#2965FB] cursor-pointer pointer-events-none";
    const HeaderMsgStyles = "font-serif-pro modal-title lg:text-[32px] md:text-[28px] sm:text-[23px] text-[27px] lg:leading-[38px] md:leading-[32px] sm:leading-[30px] leading-[37px] font-normal cursor-pointer pointer-events-none";
    const SubHeaderMsgStyles = "font-hanken-grotesk modal-title lg:text-[20px] md:text-[18px] sm:text-[14px] text-[16px] font-normal cursor-pointer pointer-events-none";
    const [highlightMsg, setHighlightMsg] = useState('')
    const [headerMsg, setheaderMsg] = useState('')
    const [subHeaderMsg, setSubHeaderMsg] = useState('')
    const [btn1Exists, setBtn1Exists] = useState(false)
    const [btn2Exists, setBtn2Exists] = useState(false)
    const [btn1Text, setBtn1Text] = useState(false)
    const [btn2Text, setBtn2Text] = useState('')
    const [modelImage, setModelImage] = useState('')
    const [cardOptionsList, setCardOptionsList] = useState([])
    const [btn1TypeAcpt, setBtn1TypeAcpt] = useState(false)
    const [marginExist, setMarginExist] = useState(false)
    const [footerMsg, setFooterMsg] = useState('');
    const location = useLocation();
    const params = location.state;
    const cardDetails = useSelector(state => state.selectedCardDetails);
    //for sidemenu
    const isCardActionScreen = location.pathname === '/CardServices/CardActionScreen';
    const [showMenuList, setshowMenuList] = useState('');

    const path = location.pathname;
    const parts = path.split('/');
    const lastNameWithSpace = parts[3];


    useKeyDown(() => { }, ["Enter"]);
    const onAcptBtnClick = () => {
        if (btn1Text == t('home')) {
            navigate('/home')
        }
    }
    const closeModel = () => {
        setShowModel(false);
    }
    useEffect(() => { if (cardDetails != null) { getCardActions() } }, [cardDetails])
    const getCardActions = async (index) => {
        // debugger;
        const selectedCard = cardDetails;
        const { StatusCode, SubStatusCode, IsReplaced } = selectedCard;
        let cardOptionsList = [];

        switch (StatusCode) {
            case STATUS_CODE.INA:
                switch (SubStatusCode) {
                    case SUBSTATUS_CODE.ACP:
                        cardOptionsList.push({
                            name: t('lock_card')
                        }, {
                            name: t('view_card_details'),
                        }, {
                            name: t('activate_card')
                        });
                        break;
                    case SUBSTATUS_CODE.LST:
                    case SUBSTATUS_CODE.STL:
                        cardOptionsList.push({
                            name: t('lock_card')
                        }, {
                            name: t('view_card_details'),
                        })
                        // do nothing
                        break;
                    case SUBSTATUS_CODE.SUS:
                        cardOptionsList.push({
                            name: t('unlock_card')
                        }, {
                            name: t('view_card_details'),
                        }, {
                            name: t('change_pin')
                        }, {
                            name: t('replace_card')
                        });
                        break;
                    default:
                        cardOptionsList.push({
                            name: t('lock_card')
                        }, {
                            name: t('view_card_details'),
                        }, {
                            name: t('change_pin')
                        }, {
                            name: t('replace_card')
                        });
                        break;
                }
                break;
            default:
                if (!IsReplaced) {
                    cardOptionsList.push({
                        name: t('lock_card')
                    }, {
                        name: t('view_card_details'),
                    }, {
                        name: t('change_pin')
                    }, {
                        name: t('replace_card')
                    });
                } else {
                    cardOptionsList.push({
                        name: t('lock_card')
                    }, {
                        name: t('view_card_details'),
                    }, {
                        name: t('change_pin')
                    });
                }
                break;
        }

        // cardOptionsList.push({
        //     name: t('change_pin')
        // }, {
        //     name: t('change_limits')
        // }, {
        //     name: t('travel_notices')
        // });
        setCardOptionsList(cardOptionsList)
        // alert(cardOptionsList);
        //  debugger;
        if (cardOptionsList.length >= 4) { setMarginExist(false) } else { setMarginExist(true) }
    }
    return (
        <div className='sm:w-auto w-full'>

            <div className={`flex flex-col sm:bg-[#30BC89] justify-between font-hanken-grotesk 
        text-20 text-white lg:w-[292px] md:w-[260px] sm:w-[153px] w-full md:rounded-[30px]
        sm:rounded-[16px] rounded-tr-[4rem]
        ${isCardActionScreen ? 'bg-[#FFFFFF]' : 'bg-transparent'}`}>
                <div className='sm:hidden block'>
                    <div className={`${isCardActionScreen
                        ? 'w-full'
                        : 'mt-5 px-[2.5rem]'
                        }`}>
                        {isCardActionScreen ? (
                            <>
                                <div className='px-10 mt-10 text-[26px] text-[#262B3A] font-serif-pro leading-[32px]'>
                                    {t('manage_your_Kudzu_debit_card')}
                                </div>

                                <div className='px-10 py-5 text-[16px] font-[400] text-[#262B3A] font-hanken-grotesk leading-[24px]'>
                                    {t('have_a_physical_Kudzu_debit_card')}
                                </div>
                            </>

                        ) : (
                            <button className="md:w-[227px] sm:w-[145px] w-full
                        md:mb-[1.5rem] sm:mb-[1rem] mb-[1.5rem] 
                        md:h-[50px] sm:h-[40px] h-[48px] rounded-[36px] md:pl-[30px]
                        sm:pl-[13px] px-[25px] flex 
                        items-center md:text-[16px] sm:text-[13px] 
                        text-[14px] font-[700] font-hanken-grotesk leading-[16px] 
                        transition duration-150 ease-in-out cursor-pointer bg-[#30BC89]
                        border-[3px] border-[#30BC89] text-white hover:text-white justify-between"
                                type="button"
                                onClick={() => {
                                    setshowMenuList(!showMenuList)
                                }}>
                                {t('card_actions')}
                                {showMenuList ?
                                    <img src={UpArrow} alt="UpArrow" className="w-[auto] cursor-pointer" /> :
                                    <img src={Downarrow} alt="Downarrow" className="w-[auto] cursor-pointer" />}
                            </button>
                        )}
                    </div>
                </div>

                <div className={`${marginExist ? 'sm:w-4/5 w-full sm:px-0 px-[2.5rem] flex flex-col h-full self-center lg:mb-10 md:mb-9 sm:mb-5 mb-10' :
                    'sm:w-4/5 w-full sm:px-0 px-[2.5rem] flex flex-col h-full self-center lg:mb-10 md:mb-9 sm:mb-6 mb-10 justify-evenly'
                    } ${isCardActionScreen ? '' : 'px-[2.5rem]'}
                    ${isCardActionScreen ? '' : (showMenuList ? 'block' : 'sm:block hidden')}
                    `}>
                    <div className={isCardActionScreen ? '' : (showMenuList ? 'block' : 'sm:block hidden')}>
                        {cardOptionsList.map((item, index) => (

                            <div key={index} className={`${marginExist ? 'lg:mt-6 md:mt-5 sm:mt-1 mt-5' : 'lg:mt-6 md:mt-5 sm:mt-1 mt-5'}`}>
                                {((item.name === t('lock_card') || item.name === t('unlock_card'))) ? (
                                    <button
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault();
                                                const focused = document.activeElement;
                                                event.target.style.opacity = 0.8;
                                                handleCardAction(item.name);
                                                setTimeout(() => {
                                                    event.target.style.opacity = 1;
                                                }, 200);
                                                if (focused && typeof focused.blur === 'function') { focused.blur(); };
                                            }
                                        }} className='w-full'>
                                         <div className='w-full flex flex-row justify-between cursor-pointer items-center'>
                                          {/*  <div className={`lg:text-[16px] md:text-[15px] sm:text-[11px] text-[14px]
                                         font-hanken-grotesk font-[700] leading-[16px]
                                         ${isCardActionScreen ? 'sm:text-[#FFFFFF] text-[#262B3A]' : 'text-[#FFFFFF] sm:pl-0 pl-[25px]'}`}
                                          
                                         > */}
                                          <div 
                                            className={`lg:text-[16px] md:text-[15px] sm:text-[11px] text-[14px]
                                                 font-hanken-grotesk font-[700] leading-[16px] 
                                            ${isCardActionScreen ? 'sm:text-[#FFFFFF] text-[#262B3A]' : 'text-[#FFFFFF] sm:pl-0 pl-[25px]'}`}
                                            onClick={() => handleCardAction(item.name)}
                                            >
                                                {item.name}
                                            </div>
                                            <div className='sm:block hidden'>
                                                <img
                                                    onClick={() => handleCardAction(item.name)}
                                                    className={(cardDetails?.StatusCode === STATUS_CODE.INA && cardDetails?.SubStatusCode === SUBSTATUS_CODE.SUS) ? 'md:w-[66.33px] sm:w-[50.33px] w-[75px]' : 'md:w-auto sm:w-[30.33px]'}
                                                    src={(cardDetails?.StatusCode === STATUS_CODE.INA && cardDetails?.SubStatusCode === SUBSTATUS_CODE.SUS) ? lock_card_on : lock_card_off} />
                                            </div>

                                            <div className='sm:hidden block'>
                                                <img
                                                    onClick={() => handleCardAction(item.name)}
                                                    className={(cardDetails?.StatusCode === STATUS_CODE.INA && cardDetails?.SubStatusCode === SUBSTATUS_CODE.SUS) ? '' : ''}
                                                    src={(cardDetails?.StatusCode === STATUS_CODE.INA && cardDetails?.SubStatusCode === SUBSTATUS_CODE.SUS) ? Mobilelockon : Mbllock_card_off} />
                                            </div>

                                        </div>
                                    </button>
                                ) : (
                                    <div className='sm:block flex justify-between self-center' onClick={() => handleCardAction(item.name)} >
                                        <button
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    event.preventDefault();
                                                    handleCardAction(item.name);
                                                    event.target.style.opacity = 0.8;
                                                    setTimeout(() => {
                                                        event.target.style.opacity = 1;
                                                    }, 200);
                                                }
                                            }}

                                            className={`lg:text-[16px] md:text-[15px] sm:text-[11px] text-[14px]
                                        font-hanken-grotesk font-[700] leading-[16px]
                                        ${item.name.replace(" ", '').includes(lastNameWithSpace) ? 'sm:text-white text-[#94C3E8] sm:pl-0 pl-[25px]' : (isCardActionScreen ? 'sm:text-[#FFFFFF] text-[#262B3A]' : 'text-[#FFFFFF] sm:pl-0 pl-[25px]')}
                                        `}>
                                            {item.name}
                                        </button>
                                        <span className='sm:hidden block'>
                                            <img src={RightArrow} className='w-[7px] mr-[10px]' alt='RightArrow' />
                                        </span>
                                    </div>
                                )}
                                <div className={`sm:hidden block  my-5 
                            ${isCardActionScreen ? 'border-b-[3px] border-[#A3CEBB] border-opacity-[50%] ' : 'border-[#4B70B9] border-b-[2px] border-opacity-[25%] sm:ml-0 ml-[25px]'}`}></div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

        </div >
    )
}

export default withOidcSecure(CardMenu);




